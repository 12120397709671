import { TabProps } from "@mui/material/Tab";
import { ReactElement, useContext } from "react";
import { BacklogContext, TabContext, TableContext } from "../../context";
import { IAdapterFolder } from "@river/interfaces";
import { useGridActions as useCommonGridActions } from "../backlog-ui-service/grid-actions";
import { useSynchronizeWorkOrdersAction } from "./grid-actions";
import { useTranslation } from "@river/common-ui";
import {
  TabsProvider,
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  ReactElementProvider,
} from "../ui-service.types";
import {
  BacklogUiService,
  useBacklogWorkOrders,
  useBacklogOperations,
  useBacklogResources,
  useBacklogInstances,
  BacklogTabId,
  BacklogAction,
  IUseBacklogCurrentTableProps,
  IGetDefaultWorkOrderColumnsProps,
  BacklogTabKeyColumnMap,
} from "../backlog-ui-service";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { IWoMassUpdateDialogProps, IOpMassUpdateDialogProps } from "../shared";
import { OracleCloudWoMassUpdateDialog } from "./oracle-cloud-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useAllowedAction } from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";

const DEFAULT_ORACLE_CLOUD_BACKLOG_TAB: BacklogTabId = BacklogTabId.WORKORDERS;

export class OracleCloudBacklogUiService extends BacklogUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const backlogContext = useContext(BacklogContext);
    const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      removeFromScheduleAction,
    } = useCommonGridActions();
    const { synchronizeWorkOrdersAction } = useSynchronizeWorkOrdersAction();

    return () => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: BacklogAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: BacklogAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: synchronizeWorkOrdersAction.title,
              startIcon: synchronizeWorkOrdersAction.icon,
              onClick: synchronizeWorkOrdersAction.onClick,
              action: BacklogAction.WO_SYNCHRONIZE,
            },
            {
              title: removeFromScheduleAction.title,
              startIcon: removeFromScheduleAction.icon,
              onClick: removeFromScheduleAction.onClick,
              action: BacklogAction.SCHEDULE,
              disabled: !selectedIds || !currentSchedule,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { downloadAttachmentsAction, materialRequirementsAction } =
      useCommonGridActions();

    return () => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: BacklogAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const backlogContext = useContext(BacklogContext);
      const currentTab = tabContext?.selectedTab;
      const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
      const { renderRemoveFromScheduleAction } = useCommonGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          {currentSchedule && renderRemoveFromScheduleAction()}
        </>
      );

      return (
        <>
          {currentTab === BacklogTabId.WORKORDERS && renderWorkOrderActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.backlog:tab.work_orders.title"),
        /*icon: <DynamicFeedIcon />,*/
        value: BacklogTabId.WORKORDERS,
      },
      {
        label: t("module.backlog:tab.operations.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.OPERATIONS,
      },
      {
        label: t("module.backlog:tab.resources.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.RESOURCES,
      },
      {
        label: t("module.backlog:tab.instances.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.INSTANCES,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_ORACLE_CLOUD_BACKLOG_TAB;

  useCurrentTable = (
    props: IUseBacklogCurrentTableProps
  ): EntityTableProvider => {
    const { backlogContext } = props;
    return () => {
      const currentTab = this.getCurrentTab()();
      const isActionAllowed = useAllowedAction();
      const workOrdersTable: IUseEntityTable = useBacklogWorkOrders({
        backlogContext,
        draggable: isActionAllowed(ModuleKey.BACKLOG, BacklogAction.SCHEDULE),
      });
      const operationsTable: IUseEntityTable = useBacklogOperations({
        backlogContext,
      });
      const resourcesTable: IUseEntityTable = useBacklogResources({
        backlogContext,
      });
      const instancesTable: IUseEntityTable = useBacklogInstances({
        backlogContext,
      });
      if (currentTab === BacklogTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === BacklogTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === BacklogTabId.RESOURCES) {
        return resourcesTable;
      } else if (currentTab === BacklogTabId.INSTANCES) {
        return instancesTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: string = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === BacklogTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === BacklogTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.workorder:workorder.WorkOrderNumber"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "WorkOrderDescription",
          name: t("entity.workorder:workorder.WorkOrderDescription"),
          editor: TextEditor,
        },
        {
          key: "WorkOrderTypeCode",
          name: t("entity.workorder:workorder.WorkOrderTypeCode"),
        },
        {
          key: "WorkOrderSubTypeCode",
          name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
        },
        {
          key: "AssetNumber",
          name: t("entity.workorder:workorder.AssetNumber"),
        },
        {
          key: "WorkOrderPriority",
          name: t("entity.workorder:workorder.WorkOrderPriority"),
        },
        {
          key: "WorkOrderStatusCode",
          name: t("entity.workorder:workorder.WorkOrderStatusCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.workorder:workorder.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.workorder:workorder.PlannedCompletionDate"),
        },
        {
          key: "__folder.folder",
          name: t("entity.folder:folder.folder"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrder.AssetNumber",
          name: t("entity.operation:operation_workorder.AssetNumber"),
        },
        {
          key: "WorkOrder.WorkOrderStatusCode",
          name: t("entity.operation:operation_workorder.WorkOrderStatusCode"),
        },
        {
          key: "WorkCenterName",
          name: t("entity.operation:operation.WorkCenterName"),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation:operation_resource.ResourceCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkCenterName",
          name: t(
            "entity.operation_resource:operation_resource.WorkCenterName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationResource.AssignedUnits",
          name: t("entity.operation_resource:operation_resource.AssignedUnits"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.LaborInstanceName",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.LaborInstanceName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
      ];
    };
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleCloudWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <></>;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.resource",
      "entity.operation_resource",
      "entity.operation_resource_instance",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
    ];
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTabKeyColumnMap = (): BacklogTabKeyColumnMap => {
    const woKey: string = "WorkOrderNumber";
    const opKey: string = "OperationSequenceNumber";
    const resKey: string = "WorkOrderOperationResource.ResourceSequenceNumber";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.INSTANCES]: [{ sourceField: woKey, targetField: woKey }],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.RESOURCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
      [BacklogTabId.INSTANCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
