import { IUseEntityTable } from "../ui-service.types";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { IUseEntity, useEntity } from "../../hooks";
import { uiConstants } from "../../helpers";
import { useSupervisorScheduleValidationRules } from "./use-supervisor-schedule-validation-rules";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "./supervisor-schedule-utilization-ui-service";
import { IAdapterFolder } from "@river/interfaces";
import { IUseTable, useTable } from "../../components/shared";

export const useSupervisorScheduleUtilizationValidationRules =
  (): IUseEntityTable => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const adapterContext: IAdapterUiContextState | null =
      useContext(AdapterUiContext);
    const uiService: SupervisorScheduleUtilizationUiService =
      adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
    const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
    const currentTab: SupervisorScheduleUtilizationTabId =
      uiService.getCurrentTab()();

    const site: IUserContextSite =
      useContext(AdapterUserContext)?.userProperties[
        AdapterUserContextProp.SITE
      ];

    const { getFetchRulesFn } = useSupervisorScheduleValidationRules();

    const entity: IUseEntity = useEntity({ entityName: "rule" });

    const table: IUseTable = useTable({
      columns: [],
      fetchFunction: getFetchRulesFn(),
      dependencies: [
        !!site,
        currentTab === SupervisorScheduleUtilizationTabId.VALIDATION_RULES,
      ],
      fetchOn:
        currentTab === SupervisorScheduleUtilizationTabId.VALIDATION_RULES,
      fetchTriggers: [currentSchedule],
      rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
      useAdvancedFilters: false,
      infiniteScrolling: true,
    });

    return {
      entity,
      table,
    };
  };

export type IUseSupervisorScheduleUtilizationValidationRules = ReturnType<
  typeof useSupervisorScheduleUtilizationValidationRules
>;
