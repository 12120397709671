import {
  ScheduleReportUiService,
  QueryProvider,
  ReactElementProvider,
  ICraftSelectorProps,
} from "..";
import { OracleCloudCraftSelector } from "./oracle-cloud-shared/oracle-cloud-craft-selector";
import { ReactElement } from "react";

export class OracleCloudScheduleReportUiService extends ScheduleReportUiService {
  getProgressFilters(): QueryProvider {
    return () => undefined;
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <></>;
  }

  getCraftLabelPropertyValue(props: any) {
    return props.ResourceName;
  }

  getProgressHeaderActionsRenderer(): () => ReactElementProvider {
    return () => () => <></>;
  }

  getErpSpecificI18nNamespaces() {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.resource",
      "entity.resource_utilization",
      "entity.resource_ref",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.operation_resource",
      "entity.operation_resource_instance",
    ];
  }

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <OracleCloudCraftSelector {...props} />
  );
}
