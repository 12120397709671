import React, { ReactElement, useContext } from "react";
import { IAdapterLookup } from "@river/interfaces";
import {
  GridHeader,
  GridActions,
  DeleteAction,
  FilterDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { LookupsAction } from "../lookups";
import { ModuleKey } from "../../sidebar-menu";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";

interface GridHeaderProps {
  moduleKey: ModuleKey;
  lookupType: string;
  className?: string;
  selectedLookup: IAdapterLookup;
}

export const LookupsGridHeader: React.FC<GridHeaderProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const deleteSelectedLookup = async (): Promise<any> => {
    if (props.selectedLookup) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteLookup(props.selectedLookup._id);
        tableContext?.table.fetch();
        deleteLookupConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteLookupConfirmation = useSimpleDialog({
    title: t("module.lookups:message.delete_lookup.title"),
    message: t("module.lookups:message.confirm_lookup_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedLookup,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={props.moduleKey}
            action={LookupsAction.DELETE}
          >
            <DeleteAction onClick={() => deleteLookupConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteLookupConfirmation.render()}
    </>
  );
};
