// ------------------
/** Implements similar behaviour as in C# "format" function */
export const formatString = (str: string, ...args: string[]): string => {
  return str.replace(/{(\d+)}/g, (match, index) => args[index] || "");
};

/** Replaces named arguments with actual values from the values object */
export const formatDynamicString = (template: string, values: any): string => {
  //
  for (let k of Object.keys(values)) {
    template = template.split(`{${k}}`).join(values[k]);
  }

  return template;
};
