import React, { ReactElement } from "react";
import { PageHeader } from "../../page-header";
import { useNavigate } from "react-router";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "@river/common-ui";
import styles from "./schedule-header.module.scss";

export const ScheduleHeader: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderBreadcrumb = (): ReactElement => (
    <div className={styles.breadcrumb}>
      <ArrowForwardIosIcon className={styles.forwardArrow} />
      <span
        className={styles.scheduleList}
        onClick={() => navigate("/schedules")}
      >
        Schedule List
      </span>
    </div>
  );

  return (
    <PageHeader
      pageTitle={t("module.schedule:module.title")}
      siteSelectorDisabled={true}
    >
      {renderBreadcrumb()}
    </PageHeader>
  );
};
