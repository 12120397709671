import { useEffect } from "react";
// -----------
export const AbsoluteRedirect = (props) => {
    //
    const absoluteRedirect = async () => {
        window.location.href = props.url;
    };
    useEffect(() => {
        absoluteRedirect();
    });
    return null;
};
