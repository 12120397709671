import {
  IUseRiverAsyncProgress,
  useRiverAsyncProgress,
} from "./river-async-progress";
import { IAdapterAsyncAction, IAdapterFolder, IQuery } from "@river/interfaces";
import { IUseTable } from "../river-data-grid";
import {
  INotificationMessage,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { useContext, useState } from "react";
import { AdapterUiContext, TableContext } from "../../../context";
import { AdapterService, ObjectType } from "../../../services";
import { uiConstants } from "../../../helpers";

interface IUseAsyncScheduleProps {
  onComplete?: (action: IAdapterAsyncAction) => void;
  showTotalScheduleWOCount?: boolean;
}

export const useAsyncSchedule = (props?: IUseAsyncScheduleProps) => {
  const { t } = useTranslation();
  const actionT = (actionTranslationKey: string, opts?: any): string =>
    t(`shared.async_actions:schedule.${actionTranslationKey}`, opts);
  const notify = useNotification();
  const tableContext = useContext(TableContext);
  const [table, setTable] = useState<IUseTable>();
  const adapterContext = useContext(AdapterUiContext);
  const [schedule, setSchedule] = useState<IAdapterFolder>();

  const getSuccessNotification = (opts: {
    action: IAdapterAsyncAction;
  }): INotificationMessage => {
    let notification: INotificationMessage;
    const { action } = opts;
    const { total_records } = action;
    if (total_records) {
      notification = {
        title: actionT("work_orders_added.total_num.notification_title"),
        message: props?.showTotalScheduleWOCount
          ? actionT(
              "work_orders_added.total_num.notification_message.wo_count",
              {
                folder: schedule?.folder,
                work_order_num: total_records,
                workorder_count: schedule?.workorder_count,
              }
            )
          : actionT("work_orders_added.total_num.notification_message", {
              folder: schedule?.folder,
              work_order_num: total_records,
            }),
      };
    } else {
      notification = {
        message: actionT("work_orders_added", {
          folder: schedule?.folder,
        }),
      };
    }
    return notification;
  };

  const scheduleProgress: IUseRiverAsyncProgress = useRiverAsyncProgress({
    title: actionT("progress_title"),
    onSuccess: (action: IAdapterAsyncAction) =>
      notify.info(
        getSuccessNotification({
          action,
        })
      ),
    onComplete: (action: IAdapterAsyncAction) => {
      table!.refresh();
      props?.onComplete?.(action);
    },
  });

  // Invoked when scheduling while Select All is checked, processing large number of records with progress dialog
  const doAsyncSchedule = async (opts: {
    schedule: IAdapterFolder;
    date?: Date;
    table?: IUseTable;
  }): Promise<void> => {
    const { schedule, date } = opts;
    const currentTable: IUseTable = opts.table || tableContext?.table!;
    const lastRanQuery: IQuery = currentTable.lastRanQueryDto!;
    const adapter: AdapterService = adapterContext!.service.getAdapterService();
    const entity_name: string = currentTable.entities[0][
      uiConstants.fields.rowType
    ] as ObjectType;

    try {
      const asyncAction: IAdapterAsyncAction = await adapter.scheduleAsync({
        entity_name,
        folder_id: schedule._id,
        start_date: date,
        query: {
          ...lastRanQuery,
          $limit: 0,
        },
      });
      setTable(currentTable);
      setSchedule(schedule);
      scheduleProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    scheduleProgress,
    doAsyncSchedule,
  };
};

export type IUseAsyncSchedule = ReturnType<typeof useAsyncSchedule>;
