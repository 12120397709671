import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { ITablePreferences, IColumnLayout } from "../../../interfaces";
import { IUseTable } from "../index";
import { userPreferencesService } from "../../../services";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { Column } from "react-data-grid";
import { useTranslation } from "@river/common-ui";

interface ISaveLayoutDialogProps {
  open: boolean;
  onClose: (success?: boolean) => void;
  onCreate?: (layout: IColumnLayout) => void;
  table: IUseTable;
  currentColumns: Column<any>[];
  currentLayout: IColumnLayout;
}

export const SaveLayoutDialog: React.FC<ISaveLayoutDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const [layoutName, setLayoutName] = useState<string>("");
  const notify = useNotification();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const layoutNameRef = useRef<HTMLInputElement>();

  const createLayout = async (): Promise<void> => {
    if (layoutName) {
      const tableSaveKey: string = props.table.dbSaveKey;
      let tablePreferences: ITablePreferences =
        await userPreferencesService.getTablePreferences({
          adapter: adapterContext!.service.getAdapterService(),
          tableSaveKey,
        });
      if (!tablePreferences) {
        tablePreferences = props.table.getDefaultTablePreferences();
      }

      const layouts: IColumnLayout[] = tablePreferences.layouts || [
        props.table.getDefaultLayout(),
      ];
      const newLayout: IColumnLayout = {
        name: layoutName,
        columns: props.currentColumns,
      };
      const existingLayoutIndex: number = layouts.findIndex(
        (layout) => layout.name === layoutName
      );
      if (existingLayoutIndex !== -1) {
        layouts.splice(existingLayoutIndex, 1, newLayout);
      } else {
        layouts.push(newLayout);
      }
      tablePreferences.layouts = layouts;
      try {
        await userPreferencesService.setTablePreferences({
          adapter: adapterContext!.service.getAdapterService(),
          tableSaveKey,
          tablePreferences,
        });
        props.table.applyTablePreferences(tablePreferences);
        const success = true;
        closeDialog(success);
        notify.success(
          t("shared.column_selector:notification.column_layout_saved")
        );
        props.onCreate?.(newLayout);
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const closeDialog = (success?: boolean): void => {
    setLayoutName("");
    props.onClose(success);
  };

  useEffect(() => {
    setLayoutName(props.currentLayout?.name);
    setTimeout(() => {
      layoutNameRef.current?.select();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <RiverDialog
      title={t("shared.column_selector:dialog.save_layout.title")}
      open={props.open}
      onClose={closeDialog}
      showActionsDivider={false}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={() => closeDialog()}
            text={t("common.button:close")}
          />
          <RiverDialogActionButton
            onClick={createLayout}
            disabled={!layoutName}
            text={t("common.button:save")}
          />
        </>
      }
    >
      <RiverTextInput
        inputRef={(obj) => (layoutNameRef.current = obj!)}
        id={"layoutName"}
        label={t("shared.column_selector:label.layout_name")}
        value={layoutName}
        onChange={(value) => setLayoutName(value)}
        fullWidth
      />
    </RiverDialog>
  );
};
