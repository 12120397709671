import React, { useContext, ReactElement } from "react";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { TabContext } from "../../../context";
import styles from "./river-tabs.module.scss";
import clsx from "clsx";
import { RiverFormSelect, IUseEnv, useEnv } from "@river/common-ui";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

const DEFAULT_USE_DROPDOWN_ON_MOBILE = true;

interface IRiverTabs {
  tabs: TabProps[];
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  useDropdownOnMobile?: boolean;
  classes?: {
    desktop?: {
      tabsRoot?: string;
      tabsFlexContainer?: string;
      tabsIndicator?: string;
      tabRoot?: string;
      selectedTab?: string;
    };
    mobile?: {};
  };
}

export const RiverTabs: React.FC<IRiverTabs> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const useDropdownOnMobile: boolean =
    props.useDropdownOnMobile ?? DEFAULT_USE_DROPDOWN_ON_MOBILE;

  const tabContext = useContext(TabContext);
  const isUsingContext: boolean = typeof props.value === "undefined";
  const value: string | undefined = isUsingContext
    ? tabContext?.selectedTab
    : props.value;

  const renderDesktopView = (): ReactElement => {
    const classes = props.classes?.desktop;

    return (
      <>
        {value && (
          <Tabs
            value={value}
            onChange={(event, value) => {
              isUsingContext && tabContext?.setSelectedTab(value);
              props.onChange && props.onChange(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            className={props.className}
            classes={{
              root: clsx([styles.desktopRoot, classes?.tabsRoot]),
              scroller: styles.scroller,
              flexContainer: clsx([
                styles.flexContainer,
                classes?.tabsFlexContainer,
              ]),
              indicator: clsx([styles.tabIndicator, classes?.tabsIndicator]),
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {props.tabs.map((tabProps, index) => (
              <Tab
                key={index}
                classes={{
                  root: clsx([styles.tabRoot, classes?.tabRoot]),
                  selected: clsx([styles.selectedTab, classes?.selectedTab]),
                }}
                {...tabProps}
              />
            ))}
          </Tabs>
        )}
      </>
    );
  };

  const renderMobileView = (): ReactElement => {
    const onChange = async (
      event: SelectChangeEvent<{ name?: string; value: unknown }>
    ): Promise<void> => {
      const itemValue: string = event.target.value as string;
      isUsingContext && tabContext?.setSelectedTab(itemValue);
      props.onChange && props.onChange(itemValue);
    };

    return (
      <>
        <RiverFormSelect
          label={""}
          id={""}
          items={props.tabs.map((tab) => {
            return { value: tab.value, text: String(tab.label) };
          })}
          onChangeEvent={onChange}
          value={value}
          className={clsx([props.className, styles.mobileRoot])}
          menuProps={{
            classes: {
              paper: styles.menu,
              list: styles.list,
            },
          }}
          selectProps={{
            classes: {
              select: styles.itemSelect,
              icon: styles.itemSelectIcon,
            },
          }}
          formControlProps={{
            variant: "standard",
          }}
        />
      </>
    );
  };

  const useDesktopView: boolean =
    !isMobile || (isMobile && !useDropdownOnMobile);

  return (
    <>
      {useDesktopView && renderDesktopView()}
      {!useDesktopView && renderMobileView()}
    </>
  );
};
