import React, { SVGProps } from "react";

export const FolderForwardIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 14 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.3333 2.00002H6.99992L5.66659 0.666687H1.66659C0.933252 0.666687 0.339919 1.26669 0.339919 2.00002L0.333252 10C0.333252 10.7334 0.933252 11.3334 1.66659 11.3334H12.3333C13.0666 11.3334 13.6666 10.7334 13.6666 10V3.33335C13.6666 2.60002 13.0666 2.00002 12.3333 2.00002ZM12.3333 10H1.66659V3.33335H12.3333V10ZM6.99325 4.00002L6.05325 4.94002L7.10659 6.00002H4.33325V7.33335H7.10659L6.04659 8.39335L6.99325 9.33335L9.66659 6.67335L6.99325 4.00002Z" />
    </svg>
  );
};
