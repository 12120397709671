import React, { ReactElement, useContext } from "react";
import {
  TableContext,
  BacklogContext,
  AdapterUiContext,
} from "../../../context";
import { PageHeader } from "../../page-header";
import { IAdapterFolder } from "@river/interfaces";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "@river/common-ui";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import styles from "./backlog-header.module.scss";
import { BacklogUiService } from "../../../services";

export const BacklogHeader: React.FC = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const adapterContext = useContext(AdapterUiContext);
  const backlogContext = useContext(BacklogContext);
  const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
  const backlogUiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const backlogRoute: string = backlogUiService.getBacklogRoute()();

  const renderBacklogBreadcrumb = (): ReactElement => (
    <span
      className={headerStyles.numberOfEntries}
    >{`(${tableContext?.table.entities?.length})`}</span>
  );

  const renderSelectedScheduleBreadcrumb = (): ReactElement => (
    <>
      {currentSchedule && (
        <div className={styles.selectedScheduleBreadcrumb}>
          <ArrowForwardIosIcon className={styles.forwardArrow} />
          <span className={styles.scheduleName}>{currentSchedule.folder}</span>
          <span className={headerStyles.numberOfEntries}>
            ({currentSchedule.workorder_count})
          </span>
        </div>
      )}
    </>
  );

  return (
    <PageHeader
      pageTitle={t("module.backlog:module.title")}
      pageTitleLink={backlogRoute}
    >
      {renderBacklogBreadcrumb()}
      {renderSelectedScheduleBreadcrumb()}
    </PageHeader>
  );
};
