import { ComponentType, FC, useContext, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IScheduleReportContext,
  ScheduleReportContext,
} from "../../../context";
import { IAdapterBaseline, IAdapterShift } from "@river/interfaces";
import {
  IRiverListPopupItem,
  POPUP_SEPARATOR,
  RiverDropdownList,
} from "../../shared";
import { BaselineCheckmarkIcon } from "../../../icons/baseline-checkmark-icon";
import { useCreateBaselineAction } from "../../../services/schedule-ui-service/schedule-actions";
import { useNotification, useTranslation } from "@river/common-ui";
import styles from "./report-baseline-selector.module.scss";

const NO_BASELINE_COLOR = "#F1F1F1";

export const ReportBaselineSelector: FC = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [baselineList, setBaselineList] = useState<IAdapterBaseline[]>([]);
  const scheduleReportContext: IScheduleReportContext = useContext(
    ScheduleReportContext
  )!;
  const { currentSchedule, selectedBaseline, setSelectedBaseline } =
    scheduleReportContext;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { createBaselineAction } = useCreateBaselineAction();

  const fetchBaselines = async () => {
    try {
      const result: IAdapterShift[] = await adapterContext!.service
        .getAdapterService()
        .fetchFolderBaselines(currentSchedule?._id!);
      setBaselineList(
        result.map((line) => line as unknown as IAdapterBaseline)
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const getPopupItems = (): IRiverListPopupItem[] => {
    let popupItems: IRiverListPopupItem[] = [
      {
        title: t("module.schedule_report:baseline_selector.no_baseline"),
        startIcon: getBaselineStartIcon(NO_BASELINE_COLOR),
        onClick: () => setSelectedBaseline(undefined),
        endIcon: selectedBaseline ? BaselineCheckmarkIcon : undefined,
      },
    ];
    if (baselineList.length !== 0) {
      popupItems.push(POPUP_SEPARATOR);
    }

    baselineList.forEach((item) =>
      popupItems.push({
        title: item.baseline,
        startIcon: getBaselineStartIcon(item?.display_options!),
        onClick: () => setSelectedBaseline(item),
        disabled: false,
        isSeparator: false,
        endIcon:
          item._id === selectedBaseline?._id
            ? BaselineCheckmarkIcon
            : undefined,
      })
    );
    return popupItems;
  };

  const getBaselineStartIcon =
    (color: string): ComponentType<any> =>
    (props: any) => <div style={{ backgroundColor: color }} {...props}></div>;

  const title: string = selectedBaseline
    ? selectedBaseline?.baseline
    : t("module.schedule_report:baseline_selector.label");

  return (
    <>
      <RiverDropdownList
        title={title}
        tooltip={t("module.schedule_report:baseline_selector.tooltip")}
        onClick={async (dropdown) => {
          await fetchBaselines();
          dropdown.open();
        }}
        classes={{
          popup: styles.popup,
          item: styles.item,
          startIconClass: styles.baselineColorIcon,
          endIconClass: styles.baselineCheckedIcon,
        }}
        items={getPopupItems()}
      />
      {createBaselineAction.renderDialog()}
    </>
  );
};
