import { ReactElement, useContext } from "react";
import { IAdapterFolder } from "@river/interfaces";
import { TabProps } from "@mui/material/Tab";
import {
  BacklogAction,
  BacklogTabId,
  BacklogTabKeyColumnMap,
  BacklogUiService,
  IGetDefaultWorkOrderColumnsProps,
  IUseBacklogCurrentTableProps,
  useBacklogAssignments,
  useBacklogOperations,
  useBacklogWorkOrders,
  IWoMassUpdateDialogProps,
  IOpMassUpdateDialogProps,
} from "../../services";
import { useTranslation } from "@river/common-ui";
import { BacklogContext, TabContext, TableContext } from "../../context";
import { useGridActions } from "../backlog-ui-service/grid-actions";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useRiverSelectColumn,
  useUserStatusAction,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { SapWoMassUpdateDialog, SapOpMassUpdateDialog } from "./sap-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useAllowedAction } from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";

const DEFAULT_SAP_BACKLOG_TAB: BacklogTabId = BacklogTabId.WORKORDERS;

export class SapBacklogUiService extends BacklogUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const backlogContext = useContext(BacklogContext);
    const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      checkMaterialShortageAction,
      jobPriorityScoreAction,
      removeFromScheduleAction,
    } = useGridActions();

    const { userStatusAction } = useUserStatusAction({
      entity_name: "workorder",
    });
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: BacklogAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: userStatusAction.title,
              startIcon: userStatusAction.icon,
              onClick: userStatusAction.onClick,
              action: BacklogAction.WO_USER_STATUS,
              disabled: !selectedIds,
            },
            {
              title: checkMaterialShortageAction.title,
              startIcon: checkMaterialShortageAction.icon,
              onClick: checkMaterialShortageAction.onClick,
              action: BacklogAction.WO_MATERIAL_SHORTAGE,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: BacklogAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: removeFromScheduleAction.title,
              startIcon: removeFromScheduleAction.icon,
              onClick: removeFromScheduleAction.onClick,
              action: BacklogAction.SCHEDULE,
              disabled: !selectedIds || !currentSchedule,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
        {userStatusAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { downloadAttachmentsAction, materialRequirementsAction } =
      useGridActions();

    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: BacklogAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { opMassUpdateAction } = useGridActions();
    const { userStatusAction } = useUserStatusAction({
      entity_name: "operation",
    });
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: opMassUpdateAction.title,
              startIcon: opMassUpdateAction.icon,
              onClick: opMassUpdateAction.onClick,
              action: BacklogAction.OP_EDIT,
              disabled: !selectedIds,
            },
            {
              title: userStatusAction.title,
              startIcon: userStatusAction.icon,
              onClick: userStatusAction.onClick,
              action: BacklogAction.OP_USER_STATUS,
              disabled: !selectedIds,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {userStatusAction.renderDialog()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const backlogContext = useContext(BacklogContext);
      const currentTab = tabContext?.selectedTab;
      const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
      const { renderRemoveFromScheduleAction, renderUnassignAction } =
        useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          {currentSchedule && renderRemoveFromScheduleAction()}
        </>
      );
      const renderOperationActions = (): ReactElement =>
        renderOperationEditActions();
      const renderAssignmentsActions = (): ReactElement =>
        renderUnassignAction();

      return (
        <>
          {currentTab === BacklogTabId.WORKORDERS && renderWorkOrderActions()}
          {currentTab === BacklogTabId.OPERATIONS && renderOperationActions()}
          {currentTab === BacklogTabId.ASSIGNMENTS &&
            renderAssignmentsActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.backlog:tab.work_orders.title"),
        /*icon: <DynamicFeedIcon />,*/
        value: BacklogTabId.WORKORDERS,
      },
      {
        label: t("module.backlog:tab.operations.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.OPERATIONS,
      },
      {
        label: t("module.backlog:tab.assignments.title"),
        /*icon: <LibraryBooksIcon />,*/
        value: BacklogTabId.ASSIGNMENTS,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_SAP_BACKLOG_TAB;

  useCurrentTable = (
    props: IUseBacklogCurrentTableProps
  ): EntityTableProvider => {
    const { backlogContext } = props;
    return () => {
      const currentTab = this.getCurrentTab()();
      const isActionAllowed = useAllowedAction();
      const workOrdersTable: IUseEntityTable = useBacklogWorkOrders({
        backlogContext,
        draggable: isActionAllowed(ModuleKey.BACKLOG, BacklogAction.SCHEDULE),
      });
      const operationsTable: IUseEntityTable = useBacklogOperations({
        backlogContext,
      });
      const assignmentsTable: IUseEntityTable = useBacklogAssignments({
        backlogContext,
      });
      if (currentTab === BacklogTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === BacklogTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === BacklogTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: string = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === BacklogTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === BacklogTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const tableCellRenderers = useTableCellRenderers();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "Orderid",
          name: t("entity.workorder:workorder.Orderid"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "ShortText",
          name: t("entity.workorder:workorder.ShortText"),
          editor: TextEditor,
        },
        {
          key: "OrderType",
          name: t("entity.workorder:workorder.OrderType"),
        },
        {
          key: "Plant",
          name: t("entity.workorder:workorder.Plant"),
        },
        {
          key: "Equipment",
          name: t("entity.workorder:workorder.Equipment"),
        },
        {
          key: "FunctLoc",
          name: t("entity.workorder:workorder.FunctLoc"),
        },
        {
          key: "MnWkCtr",
          name: t("entity.workorder:workorder.MnWkCtr"),
        },
        {
          key: "Priority",
          name: t("entity.workorder:workorder.Priority"),
          editor: TextEditor,
        },
        {
          key: "Userstatus",
          name: t("entity.workorder:workorder.Userstatus"),
        },
        {
          key: "ProductionStartDate",
          name: t("entity.workorder:workorder.ProductionStartDate"),
          width: 160,
        },
        {
          key: "ProductionFinishDate",
          name: t("entity.workorder:workorder.ProductionFinishDate"),
          width: 160,
        },
        {
          key: "__folder.folder",
          name: t("entity.folder:folder.folder"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "Orderid",
          name: t("entity.operation:operation.Orderid"),
          width: 105,
        },
        {
          key: "Description",
          name: t("entity.operation:operation.Description"),
        },
        {
          key: "Activity",
          name: t("entity.operation:operation.Activity"),
        },
        {
          key: "WorkCntr",
          name: t("entity.operation:operation.WorkCntr"),
        },
        {
          key: "EarlSchedStartDate",
          name: t("entity.operation:operation.EarlSchedStartDate"),
        },
        {
          key: "EarlSchedFinDate",
          name: t("entity.operation:operation.EarlSchedFinDate"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => [];
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => [];
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <SapWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <SapOpMassUpdateDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "shared.op_mass_update_dialog",
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_component",
      "entity.operation_material",
      "entity.pm_activity_type",
      "entity.planner_group",
      "entity.priority",
      "entity.revision",
      "entity.system_condition",
      "entity.wbs",
    ];
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTabKeyColumnMap = (): BacklogTabKeyColumnMap => {
    const woKey: string = "Orderid";
    const opKey: string = "Activity";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woKey, targetField: woKey },
        ],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.ASSIGNMENTS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "to_Assignment.Orderid",
          name: t("entity.assignment:label.Orderid"),
          width: 105,
        },
        {
          key: "to_Assignment.Activity",
          name: t("entity.assignment:label.Activity"),
        },
        {
          key: "to_Assignment.PersNo",
          name: t("entity.assignment:label.PersNo"),
        },
        {
          key: "to_Assignment.PersonName",
          name: t("entity.assignment:label.PersonName"),
        },
        {
          key: "to_Assignment.SapEarlyStartDate",
          name: t("entity.assignment:label.SapEarlyStartDate"),
        },
        {
          key: "to_Assignment.SapEarlyEndDate",
          name: t("entity.assignment:label.SapEarlyEndDate"),
        },
      ];
    };
  }
}
