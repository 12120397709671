import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { UsersUiService } from "../users-ui.service";

export class JdeUsersUiService extends UsersUiService {
  protected getUserEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    //
    return {
      entityName: "user",
      columns: [
        { key: "F0092_USER", name: t("entity.user:user.F0092_USER") },
        { key: "F0101_ALPH", name: t("entity.user:user.F0101_ALPH") },
        { key: "F0092_AN8", name: t("entity.user:user.F0092_AN8") },
        { key: "F0092_UGRP", name: t("entity.user:user.F0092_UGRP") },
      ],
    };
  };

  getUserNameKey = () => "F0092_USER";

  getErpSpecificI18nNamespaces = () => [];
}
