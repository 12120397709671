import React, { useState, ReactElement, useEffect, useContext } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Box, Container, Grid, Button } from "@mui/material";
import { AuthDto, IAccessTokenPayload } from "@river/interfaces";
import {
  authService,
  RiverPasswordInput,
  RiverTextInput,
  IValidationErrors,
  useValidation,
  IUseValidation,
  getErrorMessageFromObject,
  AbsoluteRedirect,
  IUseEnv,
  useEnv,
  ITranslationProps,
} from "@river/common-ui";
import { LanguageSelector } from "../language-selector";
import LanguageIcon from "@mui/icons-material/Language";
import loginBanner from "./login-banner.jpg";
import { LoginLogo } from "./login-logo";

import { Constants } from "@river/constants";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useNavigate, useLocation } from "react-router";
import Turnstile, { useTurnstile } from "react-turnstile";
import { Config } from "@river/config";

import styles from "./adapter-login.module.scss";
import clsx from "clsx";

type AdapterLoginRouteState = {
  error: string;
  noPass: boolean;
};

interface IAdapterLoginProps extends ITranslationProps {
  apiUrl: string;
  bannerUrl?: string;
  logoUrl?: string;
  onLoggedIn: (userInfo: IAccessTokenPayload | null) => void;
  authType: string;
}

const AdapterLogin: React.FC<IAdapterLoginProps> = (props) => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const validation: IUseValidation = useValidation();
  const [auth, setAuth] = useState<AuthDto>(new AuthDto());
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const location = useLocation();
  const navigate = useNavigate();
  const routeState: AdapterLoginRouteState = (location.state ||
    {}) as AdapterLoginRouteState;
  const [message, setMessage] = useState<string>(routeState.error || "");
  const [isRouteStateError, setIsRouteStateError] = useState<boolean>(
    !!routeState.error
  );

  const { t } = useTranslation("component.login");
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const turnstile = useTurnstile();
  const [captchaVerified, setCaptchaVerified] = useState<boolean>(true);

  useEffect(() => {
    if (isProcessing) {
      setIsRouteStateError(false);
      setMessage(t("component.login:label.authenticating"));
    }
    // eslint-disable-next-line
  }, [isProcessing]);

  useEffect(() => {
    if (isAuthenticated() && !routeState.noPass) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPropertyChange: React.ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget: input,
  }): Promise<void> => {
    const newAuth: AuthDto = {
      ...auth,
      [input.name]: input.value,
    };
    Object.setPrototypeOf(newAuth, Object.getPrototypeOf(auth));
    setAuth(newAuth);

    const newErrors: IValidationErrors = await validation.validateProperty(
      newAuth,
      input.name,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const handleLogin = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const errors: IValidationErrors = await validation.validateFields(auth);
    setValidationErrors(errors);

    if (errors.list.length > 0) {
      return;
    }

    try {
      setIsProcessing(true);
      setIsLoginError(false);
      const user = await authService.login(props.apiUrl, auth);
      await props.onLoggedIn(user);
      const { state } = location as any;
      const redirectTo = state?.from?.pathname || "/";
      navigate(redirectTo, { replace: true });
    } catch (error) {
      setIsProcessing(false);
      const msg: string = getErrorMessageFromObject({ message: error });
      setIsLoginError(true);
      setMessage(msg);
    }
  };

  const isAuthenticated = (): boolean => !!authService.getCurrentUser();

  const getBannerUrl = (): string => loginBanner;
  const renderBanner = (): ReactElement => (
    <div
      className={styles.banner}
      style={{ backgroundImage: `url(${getBannerUrl()})` }}
    />
  );

  const renderLandingMessage = (): ReactElement => (
    <div className={styles.landingMessage}>
      {t("component.login:label.login_to_account")}
    </div>
  );

  const renderProcessInfo = (): ReactElement => {
    if (!message) return <></>;
    return (
      <div className={styles.infoContainer}>
        <span
          className={clsx([
            styles.processInfo,
            {
              [styles.error]: isLoginError || isRouteStateError,
            },
            { [styles.authenticating]: isProcessing },
          ])}
        >
          {message}
        </span>
      </div>
    );
  };

  const renderCaptcha = (): ReactElement => (
    <Turnstile
      sitekey={Config.web.REACT_APP_CAPTCHA_SITE_KEY}
      onVerify={(token) => {
        adapterContext?.service
          .getAdapterService()
          .verifyCaptcha({
            token,
          })
          .then((response) => {
            if (response.success) {
              setCaptchaVerified(true);
            } else {
              turnstile.reset();
            }
          });
      }}
      onError={() => turnstile.reset()}
    />
  );

  const renderEmailAdressField = (): ReactElement => (
    <RiverTextInput
      className={styles.username}
      id={"username"}
      value={auth.username}
      onChangeEvent={(event) => onPropertyChange(event)}
      label={t("component.login:label.username")}
      fullWidth
      disabled={isProcessing}
      validationErrors={validationErrors?.fields["username"]}
    />
  );

  const renderPasswordField = (): ReactElement => (
    <RiverPasswordInput
      id={"password"}
      value={auth.password}
      label={t("component.login:label.password")}
      onChangeEvent={(event) => onPropertyChange(event)}
      className={styles.password}
      fullWidth
      disabled={isProcessing}
      validationErrors={validationErrors?.fields["password"]}
    />
  );

  const renderLanguageSelector = (): ReactElement => (
    <div className={styles.languageSelectorContainer}>
      <LanguageSelector
        icon={LanguageIcon}
        classes={{
          select: {
            select: styles.languageLabel,
            dropdownIcon: styles.languageIcon,
          },
        }}
      />
    </div>
  );

  const renderMainButtons = (): ReactElement => (
    <Box className={styles.mainButtons}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={styles.mainButton}
        classes={{
          root: styles.login,
          disabled: styles.disabled,
        }}
        disabled={isProcessing}
      >
        {t("component.login:button.login")}
      </Button>
    </Box>
  );

  const renderLoginForm = (): ReactElement => (
    <form onSubmit={handleLogin} autoComplete="off">
      {renderEmailAdressField()}
      {renderPasswordField()}
      {renderProcessInfo()}
      {renderMainButtons()}
    </form>
  );

  const renderAdapterLogin = (): ReactElement => (
    <>
      {props.tReady && (
        <>
          {renderLanguageSelector()}
          <Container
            maxWidth="lg"
            classes={{
              root: clsx([
                styles.loginContainer,
                { [styles.mobile]: isMobile },
              ]),
            }}
          >
            <Grid container style={{ flexWrap: "nowrap" }}>
              <Grid item xs={false} sm={6}>
                {renderBanner()}
              </Grid>
              <Grid item xs={12} sm={6} className={styles.rightGrid}>
                <Box
                  className={styles.flexWrapper}
                  minWidth={{ xs: "auto", sm: "400px" }}
                  maxWidth={{ xs: "100%", sm: "514px" }}
                  padding={{ xs: "35px 15px 0", sm: "150px 0 0 0" }}
                >
                  <Box
                    className={styles.formContainer}
                    maxWidth="328px"
                    width={{ xs: "100%", sm: "328px" }}
                  >
                    <LoginLogo />
                    {renderLandingMessage()}
                    {captchaVerified ? renderLoginForm() : renderCaptcha()}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );

  return (
    <>
      {props.authType === Constants.authentication_type.basic &&
        renderAdapterLogin()}
      {props.authType === Constants.authentication_type.oauth2 && (
        <AbsoluteRedirect
          url={`${adapterContext?.service
            .getAdapterService()
            .getApiOAuth2Url()}`}
        />
      )}
      {props.authType === Constants.authentication_type.saml2 && (
        <AbsoluteRedirect
          url={`${adapterContext?.service
            .getAdapterService()
            .getApiSaml2Url()}`}
        />
      )}
    </>
  );
};

export default withTranslation([
  "component.login",
  "shared.error_messages",
  "common.button",
  "common.label",
])(AdapterLogin);
