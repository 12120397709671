import { AdapterUiService } from "../adapter-ui.service";
import { SetStateAction } from "react";
import {
  IAdapterFolder,
  IEntityObject,
  QueryAttributeDto,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import { TFunction } from "i18next";
import { ITableFetchFunctionProps } from "../../components/shared";
import { IExecutionContext } from "../../context";
import {
  ColumnsProvider,
  EntityTableProvider,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import { IRiverPopupMenuItem } from "../../components/shared/river-popup-menu";
import { useSearchParams } from "react-router-dom";
import { ReactElement } from "react";
import { ExecutionMaterialRequirements } from "./execution-material-requirements";
import { IExecutionAction } from "../../components/execution/execution";
import { IWoMassUpdateDialogProps } from "../shared";

export interface IGetInitialQueryProps {
  currentSchedule: IAdapterFolder | null;
  currentWorkOrderRef: IEntityObject | null;
}

export interface IUseCurrentTableProps {
  executionContext: IExecutionContext;
}

export interface IFetchExecutionOperationsProps {
  initialQuery: QueryAttributeGroupDto;
  fetchProps: ITableFetchFunctionProps;
}

export type ExecutionOperationsProvider = () => (
  props: IFetchExecutionOperationsProps
) => Promise<any>;

export interface IExecutionActions {
  setCurrentOperation: (value: SetStateAction<IEntityObject | null>) => void;
  setTimecardDialogOpened: (value: SetStateAction<boolean>) => void;
  createExecution: (workOrder: IEntityObject) => void;
  deleteExecution: (workOrder: IEntityObject) => void;
  stopExecution: (workOrder: IEntityObject) => void;
  isExecutionOngoing: (workOrder: IEntityObject) => boolean;
}

export interface IWoPaneMenuItems {
  executionContext: IExecutionContext | null;
  workOrder: IEntityObject;
}

export interface IGetInitialInstancesQueryAttributesProps {
  currentWorkOrder: IEntityObject;
}

export interface IGetDefaultOperationColumnsProps {
  operationActions: IExecutionAction[];
}

export interface IGetDefaultInstancesColumnsProps {
  instancesActions: IExecutionAction[];
}

export interface IGetDefaultMaterialRequirementsColumnsProps {
  materialRequirementActions: IExecutionAction[];
}

export interface IGetDefaulTimecardColumnsProps {
  timecardActions: IExecutionAction[];
}

export interface IGetOperationActionsProps {
  executionContext?: IExecutionContext;
}
export enum ExecutionTabId {
  DETAILS = "details",
  OPERATIONS = "operations",
  ASSIGNMENTS = "assignments",
  MATERIAL = "material",
  TIMECARDS = "timecards",
  INSTANCES = "instances",
}
export const EXECUTION_DETAILS_TABLE_SAVE_KEY = "execution.details";
export const EXECUTION_OPERATIONS_TABLE_SAVE_KEY = "execution.operations";
export const EXECUTION_ASSIGNMENTS_TABLE_SAVE_KEY = "execution.assignments";
export const EXECUTION_MATERIAL_TABLE_SAVE_KEY = "execution.material";
export const EXECUTION_TIMECARDS_TABLE_SAVE_KEY = "execution.timecards";
export const EXECUTION_INSTANCES_TABLE_SAVE_KEY = "execution.instances";

export const DEFAULT_EXECUTION_TAB = ExecutionTabId.DETAILS;

export const ExecutionTabURLParamName = "tab";

export abstract class ExecutionUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.river_table_selector",
      "shared.lookup",
      "shared.grid_header",
      "shared.column_selector",
      "shared.error_messages",
      "common.button",
      "common.label",
      "module.execution",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "entity.workorder",
      "entity.operation",
      "entity.timecard",
      "entity.folder",
      "entity.availability_header",
      "shared.download_dialog",
      "shared.action",
      "shared.completion_percentage",
      "shared.wo_mass_update_dialog",
      "shared.column_selector",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }
  //-----------------------
  // ERP-specific
  //-----------------------

  getCurrentTab = (): (() => ExecutionTabId) => {
    return (): ExecutionTabId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(ExecutionTabURLParamName) ||
        DEFAULT_EXECUTION_TAB) as ExecutionTabId;
    };
  };

  abstract getWoPaneMenuItems(
    props: IWoPaneMenuItems
  ): () => IRiverPopupMenuItem[];

  abstract useCurrentTable(props: IUseCurrentTableProps): EntityTableProvider;

  abstract renderGridActions(): ReactElementProvider;

  abstract getTabs(): TabsProvider;

  abstract getIsTableView(): () => boolean;

  abstract getErpSpecificI18nNamespaces(): string[];

  abstract getWoItemPropLabelMap(t: TFunction): Map<string, string>;

  abstract getWoPropsToRender(): string[];

  abstract getDefaultOperationColumns(
    props: IGetDefaultOperationColumnsProps
  ): ColumnsProvider;

  abstract getDefaultInstancesColumns(
    props: IGetDefaultInstancesColumnsProps
  ): ColumnsProvider;

  abstract getDefaultMaterialRequirementsColumns(
    props: IGetDefaultMaterialRequirementsColumnsProps
  ): ColumnsProvider;

  abstract getInitialInstancesQueryAttributes(
    props: IGetInitialInstancesQueryAttributesProps
  ): QueryAttributeDto[];

  abstract getInitialQuery(
    props: IGetInitialQueryProps
  ): QueryAttributeGroupDto;

  abstract getExecutionFetchOperations(): ExecutionOperationsProvider;

  abstract getExecutionTabOperationEntity(): string;

  abstract getExecutionTabMaterialRequirementsEntity(): string;

  abstract getOperationAttributeValuePath(): string;

  abstract getDefaultTimecardColumns(
    props: IGetDefaulTimecardColumnsProps
  ): ColumnsProvider;

  abstract getOperationActions(
    props: IGetOperationActionsProps
  ): () => IExecutionAction[];

  abstract renderWoMassUpdateDialog(
    props: IWoMassUpdateDialogProps
  ): ReactElement;

  abstract getDefaultDetailsColumns(): ColumnsProvider;

  renderExecutionMaterialRequirements = (): ReactElement => {
    return <ExecutionMaterialRequirements />;
  };

  abstract getDefaultAssignmentColumns(
    assignmentActions: IExecutionAction[]
  ): ColumnsProvider;
}
