import { IRelationRendererProps } from "../../supervisor-schedule-operations-gantt-overlay";
import {
  supervisorGanttAbsoluteConstants,
  IRelationConnectorEvents,
  IVerticalLine,
  IHorizontalLine,
} from "../../render-utils";
import { MouseEventHandler, useContext, useState } from "react";
import { IOperationPosition } from "../../use-supervisor-schedule-operations-overlay";
import { SupervisorScheduleContext } from "../../../../../../../context";
import { IUseSupervisorScheduleGantt } from "../../../../use-supervisor-schedule-gantt";

export interface IUseFinishToFinish extends IRelationConnectorEvents {
  predecessorFinish?: IHorizontalLine;
  vertical?: IVerticalLine;
  successorFinish?: IHorizontalLine;
  selected: boolean;
}

export const useFinishToFinish = (
  props: IRelationRendererProps
): IUseFinishToFinish => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const gantt: IUseSupervisorScheduleGantt = scheduleContext!.ganttRef.current!;
  let preRightStartXPercent: number;
  let preRightEndXPercent: number;
  let succRightStartXPercent: number;
  let succRightEndXPercent: number;

  const {
    MIN_HORIZONTAL_CONNECTOR_LENGTH: MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
    MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH:
      MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT,
  } = gantt.ganttRatios!;
  const { OUTWARD_CONNECTOR_Y_OFFSET, INWARD_CONNECTOR_Y_OFFSET } =
    supervisorGanttAbsoluteConstants;

  const [selected, setSelected] = useState<boolean>(false);

  const onConnectorMouseOver: MouseEventHandler = (event): void => {
    setSelected(true);
  };

  const onConnectorMouseOut: MouseEventHandler = (event): void => {
    setSelected(false);
  };

  const onConnectorClick: MouseEventHandler = (event): void => {
    event.stopPropagation();
    props.editRelation();
  };

  let hookValue: IUseFinishToFinish = {
    selected,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  if (!props.predecessor || !props.successor) {
    const pos: IOperationPosition = props.predecessor
      ? props.predecessorPos!
      : props.successorPos!;
    const line: IHorizontalLine = {
      yPos: pos.yPos,
      startXPercent: pos.endXPercent,
      endXPercent: pos.endXPercent + MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
    };
    const lineProp: string = props.predecessor
      ? "predecessorFinish"
      : "successorFinish";
    hookValue = {
      ...hookValue,
      [lineProp]: line,
    };
  } else {
    // predecessor horizontal start connector position
    preRightStartXPercent = props.predecessorPos!.endXPercent;
    preRightEndXPercent =
      props.successorPos!.endXPercent +
        MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT <=
      props.predecessorPos!.endXPercent +
        MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT
        ? props.predecessorPos!.endXPercent +
          MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT
        : props.successorPos!.endXPercent +
          MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT;
    const preY: number =
      props.predecessorPos!.yPos + OUTWARD_CONNECTOR_Y_OFFSET;
    // successor horizontal start connector position
    succRightStartXPercent = props.successorPos!.endXPercent;
    succRightEndXPercent = preRightEndXPercent;

    const succY: number = props.successorPos!.yPos + INWARD_CONNECTOR_Y_OFFSET;

    hookValue = {
      ...hookValue,
      predecessorFinish: {
        startXPercent: preRightStartXPercent,
        endXPercent: preRightEndXPercent,
        yPos: preY,
      },
      vertical: {
        yStart: preY,
        yEnd: succY,
        xPercent: preRightEndXPercent,
      },
      successorFinish: {
        yPos: succY,
        startXPercent: succRightStartXPercent,
        endXPercent: succRightEndXPercent,
      },
    };
  }

  return hookValue;
};
