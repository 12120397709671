import { FC, useState, MouseEvent } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { Popover } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "@river/common-ui";
import styles from "./river-calendar-selector.module.scss";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  handleGoForward(): void;
  handleGoBackward(): void;
  handleOnAccept(v: dayjs.Dayjs | null): void;
  value: Date;
}

export const RiverCalendarSelector: FC<IProps> = (props) => {
  const { handleGoForward, value, handleGoBackward, handleOnAccept } = props;
  const { t } = useTranslation();
  const [anchorElCalendar, setAnchorElCalendar] =
    useState<HTMLDivElement | null>(null);

  const handleClickCalendar = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorElCalendar(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorElCalendar(null);
  };

  const openCalendar = !!anchorElCalendar;
  const idCalendar = openCalendar ? "river-calendar-popover" : undefined;
  return (
    <>
      <div className={styles.root}>
        <div className={styles.icon_container} onClick={handleGoBackward}>
          <ChevronLeftOutlinedIcon fontSize="small" />
        </div>
        <div
          aria-describedby={idCalendar}
          className={styles.icon_container}
          onClick={handleClickCalendar}
        >
          <CalendarMonthRoundedIcon fontSize="small" />
        </div>
        <Popover
          id={idCalendar}
          open={openCalendar}
          anchorEl={anchorElCalendar}
          onClose={handleCloseCalendar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StaticDatePicker
            onAccept={handleOnAccept}
            className={styles.calendar}
            timezone={"UTC"}
            slotProps={{
              toolbar: { className: styles.calendar_toolbar },
              day: {
                classes: {
                  selected: styles.calendar_selected_day,
                  today: styles.calendar_today,
                },
              },
              actionBar: {
                classes: {
                  root: styles.calendar_action_btn,
                },
              },
            }}
            localeText={{
              cancelButtonLabel: t("common.button:cancel"),
              okButtonLabel: t("common.button:apply"),
              toolbarTitle: t("common.label:select_date"),
            }}
            defaultValue={dayjs(value)}
            onClose={handleCloseCalendar}
          />
        </Popover>
        <div className={styles.icon_container} onClick={handleGoForward}>
          <ChevronRightOutlinedIcon fontSize="small" />
        </div>
      </div>
    </>
  );
};
