import { IsNotEmpty } from "class-validator";
import { useTranslation } from "@river/common-ui";
import { RiverFormInstance, useRiverForm } from "../../../hooks";

export interface IStandaloneValidator {
  action: WoScheduleAction;
  scheduleName: string;
  scheduleId: string;
}

class StandaloneValidator {
  constructor(obj: IStandaloneValidator) {
    Object.assign(this, obj);
  }
  @IsNotEmpty()
  scheduleId!: string;
}

export enum WoScheduleAction {
  REMOVE_FROM_SCHEDULE = "remove_from_schedule",
  MOVE_TO_ANOTHER_SCHEDULE = "move_to_another_schedule",
}

export const useMoveWorkOrdersForm = (): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, StandaloneValidator>({
    standalone: {
      fields: new StandaloneValidator({
        action: WoScheduleAction.REMOVE_FROM_SCHEDULE,
        scheduleName: "",
        scheduleId: "",
      }),
      fieldDefs: [],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },
    labels: {
      [WoScheduleAction.REMOVE_FROM_SCHEDULE]: t(
        "shared.move_work_orders_dialog:action_label.remove_from_schedule"
      ),
      [WoScheduleAction.MOVE_TO_ANOTHER_SCHEDULE]: t(
        "shared.move_work_orders_dialog:action_label.move_to_another_schedule"
      ),
      scheduleId: t("shared.move_work_orders_dialog:label.select_schedule"),
    },
  });
};
