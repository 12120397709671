import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUserContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { useContext } from "react";
import { IEntityObject, QueryAttributeDto } from "@river/interfaces";
import { AdapterService } from "../../../../../services";

export function useResponsibilityLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const adapterService: AdapterService =
      adapterContext?.service!.getAdapterService();
    const userNameKey: string = adapterContext.service
      .getUsersUiService()
      .getUserNameKey();

    const getSelectedUserId = async (): Promise<string> => {
      const selectedUsers: IEntityObject[] =
        (await adapterService.searchEntityData("user", {
          query: {
            $and: [
              {
                attribute_name: userNameKey,
                attribute_value: {
                  operator: "$eq",
                  value: Array.from(table.selectedRowIds)[0],
                },
              },
            ],
          },
        })) as IEntityObject[];
      return selectedUsers[0].USER_ID as string;
    };

    const getInitialQueryAttributes = async (): Promise<
      QueryAttributeDto[]
    > => {
      const userIdQueryAttribute: QueryAttributeDto = {
        attribute_name: "USER_ID",
        attribute_value: {
          operator: "$eq",
          value: await getSelectedUserId(),
        },
      };
      return [userIdQueryAttribute].concat(customFilters || []);
    };

    return {
      title: t("shared.lookup:responsibility.title"),
      rowKey: "RESPONSIBILITY_ID",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "RESPONSIBILITY_NAME",
            name: t("entity.responsibility:responsibility.RESPONSIBILITY_NAME"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterService.searchEntityData("responsibility", {
            ...fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: await getInitialQueryAttributes(),
            }),
          });
        },
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "responsibility",
      },
    };
  };
}

export type IUseResponsibilityLookupDef = ReturnType<
  typeof useResponsibilityLookupDef
>;
