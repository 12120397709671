import { IAdapterUiContextState, IChart, ISidebarContext } from "../context";
import { IKpiParams } from "@river/interfaces";
import { statsHelper } from "../helpers";
import { IUseTable } from "../components/shared";
import { IAvailabilityUtilizationResult } from "../components/backlog/availability-helpers";

export const KPI_LABEL_COLOR = "#c1c4c9";

export const loadKPIs = async (
  adapterContext: IAdapterUiContextState,
  sidebarContext: ISidebarContext,
  table: IUseTable,
  tab: string,
  availabilityUtilization: IAvailabilityUtilizationResult
) => {
  if (!table) {
    return;
  }

  const orderTypeField = "OrderType";
  const priorityField = "Priority";
  const workCenterField = "WorkCntr";

  let kpis: IChart[] = [];

  const kpiStartDate = sidebarContext?.kpiParams.startDate ?? new Date();
  const kpiStateParams = sidebarContext?.kpiParams;

  switch (tab) {
    case "operations":
      {
        const kpiParams: (IKpiParams & { code: string; name: string })[] = [
          {
            code: "FOUR_WEEK_WEEKLY_WC_UTILIZATION",
            name: `Weekly ${kpiStateParams?.workCenter} Utilization`,
            start_date: kpiStartDate,
            WorkCenter: kpiStateParams?.workCenter ?? "",
          },
          {
            code: "ONE_WEEK_DAILY_WC_UTILIZATION",
            name: `Daily ${kpiStateParams?.workCenter} Utilization`,
            start_date: kpiStartDate,
            WorkCenter: kpiStateParams?.workCenter ?? "",
          },
        ];

        for (const kpiParam of kpiParams) {
          const kpi = await adapterContext.service
            .getAdapterService()
            .getKPIData(kpiParam.code, {
              start_date: kpiParam.start_date,
              WorkCenter: kpiParam.WorkCenter,
            });
          if (kpi) {
            const chart = statsHelper.createKPIFromKPIData(
              {
                name: kpiParam.name,
                colors: ["#A7E8BD", "#FFD972", "#EFA7A7"],
              },
              kpi
            );
            kpis.push(chart);
          }
        }
      }
      break;
    case "assignments":
      {
        const kpiParams: (IKpiParams & { code: string; name: string })[] = [
          {
            code: "FOUR_WEEK_WEEKLY_PERSON_UTILIZATION",
            name: `Weekly ${kpiStateParams?.personName} Utilization`,
            start_date: kpiStartDate,
            PersonNumber: kpiStateParams?.personNumber ?? "",
          },
          {
            code: "ONE_WEEK_DAILY_PERSON_UTILIZATION",
            name: `Daily ${kpiStateParams?.personName} Utilization`,
            start_date: kpiStartDate,
            PersonNumber: kpiStateParams?.personNumber ?? "",
          },
        ];

        for (const kpiParam of kpiParams) {
          const kpi = await adapterContext.service
            .getAdapterService()
            .getKPIData(kpiParam.code, {
              start_date: kpiParam.start_date,
              PersonNumber: kpiParam.PersonNumber,
            });
          if (kpi) {
            const chart = statsHelper.createKPIFromKPIData(
              {
                name: kpiParam.name,
                colors: ["#A7E8BD", "#FFD972", "#EFA7A7"],
              },
              kpi
            );
            kpis.push(chart);
          }
        }
      }
      break;
  }

  switch (tab) {
    case "workorders":
      sidebarContext?.setStats({
        charts: [
          {
            title: "WorkOrders by Type",
            chart: {
              type: "pie",
              data: statsHelper.createPieChartData({
                rows: table.entities,
                labelField: orderTypeField,
                seed: "Order_type1",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          {
            title: "WorkOrders by Priority",
            chart: {
              type: "pie",
              data: statsHelper.createPieChartData({
                rows: table.entities,
                labelField: priorityField,
                seed: "Priority",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
        ],
      });
      break;
    case "operations":
      sidebarContext?.setStats({
        charts: [
          {
            title: "Operations by WorkCenter",
            chart: {
              type: "bar",
              data: statsHelper.createBarChartData({
                rows: table.entities,
                labelField: workCenterField,
                seed: "WorkCenter",
                label: "Operations",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                  x: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          {
            title: "Hours by WorkCenter",
            chart: {
              type: "bar",
              data: statsHelper.createBarChartData({
                rows: table.entities,
                labelField: workCenterField,
                valueField: "DurationNormal",
                seed: "WorkCenterHr",
                label: "Hours",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                  x: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          {
            title: "WorkCenter Utilization",
            chart: {
              type: "bar",
              data: statsHelper.createAvailabilityUtilizationData({
                seed: "PersonUtilization1",
                availabilityUtilization: availabilityUtilization ?? {
                  type: "",
                  resources: [],
                  records: [],
                },
              }),
              options: {
                scales: {
                  y: {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      color: KPI_LABEL_COLOR,
                    } as any,
                  },
                  x: {
                    stacked: true,
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          ...kpis,
        ],
      });
      break;
    case "assignments":
      sidebarContext?.setStats({
        charts: [
          {
            title: "Assignments by Person",
            chart: {
              type: "bar",
              data: statsHelper.createBarChartData({
                rows: table.entities,
                labelField: "to_Operation.to_Assignment.PersonName",
                seed: "PersonName",
                label: "Assignments",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                  x: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          {
            title: "Hours by Person",
            chart: {
              type: "bar",
              data: statsHelper.createBarChartData({
                rows: table.entities,
                labelField: "to_Operation.to_Assignment.PersonName",
                valueField: "to_Operation.to_Assignment.DurNorm",
                seed: "PersonHours",
                label: "Hours",
              }),
              options: {
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                  x: {
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          {
            title: "Person Utilization",
            chart: {
              type: "bar",
              data: statsHelper.createAvailabilityUtilizationData({
                seed: "PersonUtilization1",
                availabilityUtilization: availabilityUtilization ?? {
                  type: "",
                  resources: [],
                  records: [],
                },
              }),
              options: {
                scales: {
                  y: {
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      color: KPI_LABEL_COLOR,
                    } as any,
                  },
                  x: {
                    stacked: true,
                    ticks: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
                plugins: {
                  legend: {
                    labels: {
                      color: KPI_LABEL_COLOR,
                    },
                  },
                },
              },
            },
          },
          ...kpis,
        ],
      });
      break;
  }
};
