import { useEffect, useRef, useState } from "react";

import { IUseScheduleGantt } from "./use-schedule-gantt";

interface IUseGanttRenderChecksProps {
  gantt: IUseScheduleGantt;
  containerId: string;
}

export function useGanttRenderChecks(props: IUseGanttRenderChecksProps) {
  const { gantt } = props;
  const { numberOfPeriods, frequencyDef, skipRenderChecksRef } = gantt;
  const [shouldVerifyContainerSize, setShouldVerifyContainerSize] =
    useState<boolean>(true);
  const inspectionInterval = useRef<number>();
  const [containerSizeVerified, setContainerSizeVerified] =
    useState<boolean>(false);

  useEffect(() => {
    if (shouldVerifyContainerSize) {
      inspectionInterval.current = window.setInterval(() => {
        const el: HTMLElement = document.querySelector(
          `div#${props.containerId}`
        )!;
        if (el) {
          const styles: CSSStyleDeclaration = getComputedStyle(el);
          const minWidth: string = styles.minWidth;
          const expectedMinWidth: number =
            numberOfPeriods * frequencyDef.minPeriodSize;
          if (minWidth === `${expectedMinWidth}px`) {
            window.clearInterval(inspectionInterval.current);
            setContainerSizeVerified(true);
            setShouldVerifyContainerSize(false);
          } else {
            setContainerSizeVerified(false);
          }
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldVerifyContainerSize]);

  useEffect(() => {
    return () => {
      window.clearInterval(inspectionInterval.current);
    };
  }, []);

  useEffect(() => {
    if (!skipRenderChecksRef.current) {
      setShouldVerifyContainerSize(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfPeriods]);

  const shouldRender: boolean =
    containerSizeVerified || skipRenderChecksRef.current;

  return {
    shouldRender,
  };
}

export type IUseGanttRenderChecks = ReturnType<typeof useGanttRenderChecks>;
