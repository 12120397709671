import React, { ReactElement, useState } from "react";
import { Lookups, ILookups } from "./lookups";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

interface IModuleWrapperProps extends ITranslationProps, ILookupsModuleProps {}

const ModuleWrapper: React.FC<IModuleWrapperProps> = (
  props: IModuleWrapperProps
): ReactElement => <>{props.tReady && <Lookups {...props} />}</>;

interface ILookupsModuleProps extends ILookups {}

export const LookupsModule: React.FC<ILookupsModuleProps> = (
  props
): ReactElement => {
  const namespaces: string[] = [
    "module.lookups",
    "shared.planning_plant_selector",
    "shared.user_menu",
    "shared.column_selector",
    "shared.river_color_picker",
    "shared.grid_header",
    "shared.advanced_filters",
    "shared.saved_filters_dialog",
    "shared.error_messages",
    "shared.river_table_selector",
    "common.button",
    "common.label",
    "entity.lookup",
    "entity.lookup_action_ref",
    "shared.river_data_grid",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component {...props} />;
};
