import React, { ReactElement, useContext } from "react";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiService,
  PersonUiService,
  userPreferencesService,
} from "../../../../services";
import { ITableFetchFunctionProps, RiverTableSelector } from "../../../shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { Column } from "react-data-grid";
import { fetchHelpers, uiConstants } from "../../../../helpers";
import { useTranslation } from "@river/common-ui";

interface ISupervisorResourceSelectorProps {
  open: boolean;
  onClose: () => void;
  selectedIds: Set<string>;
  onChangeSelectedResources: (selectedResourceIds: Set<string>) => void;
}

export const SupervisorResourceSelector: React.FC<
  ISupervisorResourceSelectorProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: PersonUiService =
    adapterContext?.service!.getPersonUiService()!;

  const service: AdapterUiService = adapterContext?.service!;
  const notify = useNotification();

  const fetchObjects = async (fetchProps: ITableFetchFunctionProps) => {
    return await service
      .getAdapterService()
      .fetchCraftPeople(fetchHelpers.getTableQuery({ fetchProps }));
  };

  const onChangeSelectedResources = async (
    selectedResourceIds: Set<string>
  ): Promise<void> => {
    try {
      await userPreferencesService.setSupervisorUtilizationPeople(
        service.getAdapterService(),
        Array.from(selectedResourceIds)
      );
      props.onChangeSelectedResources(selectedResourceIds);
    } catch (message) {
      notify.error({ message });
    }
  };

  const columns: Column<any>[] = uiService.getPersonColumns()();

  return (
    <RiverTableSelector
      open={props.open}
      onClose={props.onClose}
      onChangeSelectedIds={onChangeSelectedResources}
      selectedIds={props.selectedIds}
      dialogTitle={t("shared.resource_selector:resources.title")}
      availableTableTitle={t(
        "shared.resource_selector:label.available_resources"
      )}
      assignedTableTitle={t(
        "shared.resource_selector:label.selected_resources"
      )}
      entityName={"availability_header"}
      rowKey={uiConstants.fields._id}
      columns={columns}
      fetchObjects={fetchObjects}
      saveKeyAvailable={"utilization.resources.available"}
      saveKeyAssigned={"utilization.resources.selected"}
    />
  );
};
