import React from "react";

export type SetTabFn = (tabId: string) => void;

export interface ITabContext {
  selectedTab: string;
  setSelectedTab: SetTabFn;
}

export const TabContext = React.createContext<ITabContext | null>(null);
