import { FC, ReactElement, useCallback, useContext } from "react";
import {
  RiverDateInput,
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
} from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { ITimeCardDialogProps } from "../../../supervisor-timecard-ui.service";
import { useOracleEbsSupervisorResourceTimecardForm } from "./use-oracle-ebs-supervisor-resource-timecard-form";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { IEntityObject, OracleEbsAdapterTimeCardDto } from "@river/interfaces";
import { debounce } from "ts-debounce";
import { LookupType, RiverLookup } from "../../../../components/shared";
import styles from "./oracle-ebs-supervisor-resource-timecard-dialog.module.scss";

export const OracleEbsSupervisorResourceTimeCardDialog: FC<
  ITimeCardDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const { form } = useOracleEbsSupervisorResourceTimecardForm({
    folderId: props.folderId,
    operation: props.operation,
    onCreate: () => {
      props.onCreate();
      closeAndRefresh();
    },
  });

  const {
    onStandalonePropertyChange,
    setStandaloneFields,
    getStandaloneValidatorInstance,
    validateStandaloneField,
    setDto,
    submit,
    resetForm,
    isProcessing,
  } = form;

  const timecardDto: OracleEbsAdapterTimeCardDto =
    form.dto as OracleEbsAdapterTimeCardDto;

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const fetchPersonByNumber = async (
    laborInstanceName: string
  ): Promise<void> => {
    const resource: IEntityObject = await fetchPersonByAttribute(
      "INSTANCE_NAME",
      laborInstanceName
    );

    const instanceName: string = (resource?.INSTANCE_NAME as string) || "";

    const instanceId: number | undefined =
      (resource?.INSTANCE_ID as number) || undefined;

    setDto(
      Object.assign(new OracleEbsAdapterTimeCardDto(), {
        ...timecardDto,
        INSTANCE_ID: instanceId,
        INSTANCE_NAME: instanceName,
      })
    );
    validateStandaloneField("laborInstanceName", laborInstanceName);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchPersonByNumber = useCallback(
    debounce((text) => {
      fetchPersonByNumber(text);
    }, 600),
    [timecardDto]
  );

  const fetchPersonByAttribute = async (
    attribute_name: string,
    attribute_value: string
  ): Promise<IEntityObject> => {
    const resources: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .fetchCraftPeople({
        query: {
          $and: [
            {
              attribute_name: "Resources",
              attribute_value: {
                operator: "$elemMatch",
                value: {
                  DEPARTMENT_ID: (
                    props.operation?.WorkOrderOperationResource as IEntityObject
                  )?.DEPARTMENT_ID,
                  RESOURCE_ID: (
                    props.operation?.WorkOrderOperationResource as IEntityObject
                  )?.RESOURCE_ID,
                },
              },
            },
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return resources[0] as IEntityObject;
  };

  const renderLaborInstanceNameLookup = (): ReactElement => (
    <RiverLookup
      id="laborInstanceName"
      lookup={{
        type: LookupType.PERSONS,
        customFilters: [
          {
            attribute_name: "Resources",
            attribute_value: {
              operator: "$elemMatch",
              value: {
                DEPARTMENT_ID: (
                  props.operation?.WorkOrderOperationResource as IEntityObject
                )?.DEPARTMENT_ID,
                RESOURCE_ID: (
                  props.operation?.WorkOrderOperationResource as IEntityObject
                )?.RESOURCE_ID,
              },
            },
          },
        ],
      }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchPersonByNumber(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        const resource: IEntityObject = selectedObject as IEntityObject;
        const { INSTANCE_ID, INSTANCE_NAME } = resource;
        setDto(
          Object.assign(new OracleEbsAdapterTimeCardDto(), {
            ...timecardDto,
            INSTANCE_ID,
            INSTANCE_NAME,
          })
        );
        setStandaloneFields(
          getStandaloneValidatorInstance!({
            laborInstanceName: INSTANCE_NAME,
          })
        );
        validateStandaloneField("laborInstanceName", INSTANCE_NAME);
      }}
      className={styles.field}
    />
  );

  const renderTimeCardDateField = (): ReactElement => (
    <RiverDateInput id="timecard_date" className={styles.dateField} />
  );

  const renderTimeCardTimeField = (): ReactElement => (
    <RiverTextInput
      id="timecard_hours"
      className={styles.hoursField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderTimeCardTimeFields = (): ReactElement => (
    <div className={styles.timeFields}>
      {renderTimeCardDateField()}
      {renderTimeCardTimeField()}
    </div>
  );

  const renderContent = (): ReactElement => (
    <>
      {renderLaborInstanceNameLookup()}
      {renderTimeCardTimeFields()}
    </>
  );

  return (
    <RiverDialog
      title={t("common.label:timecard")}
      open={props.open}
      onClose={close}
      actionButtonText={t("common.button:create")}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderContent()}
    </RiverDialog>
  );
};
