import { EditorProps, FormatterProps } from "react-data-grid";
import { TableContext } from "../../../context";
import { Constants } from "@river/constants";
import { RiverDefaultTextFormatter } from "./cell-formatters";
import {
  RiverDefaultNumberEditor,
  RiverDefaultCheckboxEditor,
  RiverDefaultValueListEditor,
  RiverDefaultMultiSelectEditor,
  RiverDefaultTextEditor,
} from "./cell-editors";
import { ReactElement } from "react";

type FormatterFunction = (formatterProps: FormatterProps<any>) => ReactElement;

export function useFieldEditor() {
  const getFieldEditor = (
    editorProps: EditorProps<any>
  ): JSX.Element | null => {
    const { column } = editorProps;

    return (
      <TableContext.Consumer>
        {(tableContext) => {
          const attribute = tableContext?.entity.attributesByName.get(
            column.key
          );
          const formatterProps: FormatterProps<any> =
            editorProps as unknown as FormatterProps<any>;

          // First try to render editor based on input_type, if specified
          if (
            attribute?.input_type &&
            attribute?.input_type !== Constants.input_type.none
          ) {
            switch (attribute.input_type as keyof typeof Constants.input_type) {
              case Constants.input_type.checkbox:
                return <RiverDefaultCheckboxEditor editorProps={editorProps} />;
              case Constants.input_type.dropdown:
              case Constants.input_type.value_list:
                return (
                  <RiverDefaultValueListEditor editorProps={editorProps} />
                );
              case Constants.input_type.multi_select:
                return (
                  <RiverDefaultMultiSelectEditor editorProps={editorProps} />
                );
              case Constants.input_type.text:
                return <RiverDefaultTextEditor editorProps={editorProps} />;
              case Constants.input_type.number:
                return <RiverDefaultNumberEditor editorProps={editorProps} />;
              default:
                return (
                  <RiverDefaultTextFormatter formatterProps={formatterProps} />
                );
            }
          } else {
            // Fallback to column formatter
            return (column.formatter as FormatterFunction)({
              ...formatterProps,
              // @ts-ignore
              isEditor: true,
            });
          }
        }}
      </TableContext.Consumer>
    );
  };

  return {
    getFieldEditor,
  };
}

export type IUseFieldEditor = ReturnType<typeof useFieldEditor>;
