import { useRef, useState } from "react";

const DEFAULT_DRAWER_WIDTH: number = 300;

interface IRiverDrawerProps {
  width: number;
  drawerGUID?: string;
}

export const useRiverDrawer = () => {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [drawerWidth, setDrawerWidth] = useState<number>(DEFAULT_DRAWER_WIDTH);
  const [drawerGUID, setDrawerGUID] = useState<string>();
  const portalRef = useRef<any>();

  const openDrawer = (props?: IRiverDrawerProps): void => {
    if (props?.drawerGUID && props?.drawerGUID !== drawerGUID) {
      setDrawerGUID(props?.drawerGUID);
    }
    setDrawerWidth(props?.width || drawerWidth);
    setDrawerOpened(true);
  };

  const closeDrawer = (): void => setDrawerOpened(false);

  return {
    drawerOpened,
    openDrawer,
    closeDrawer,
    drawerWidth,
    setDrawerWidth,
    drawerGUID,
    portalRef,
  };
};

export type IUseRiverDrawer = ReturnType<typeof useRiverDrawer>;
