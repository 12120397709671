import { TabProps } from "@mui/material/Tab";
import {
  ColumnsProvider,
  EntityTableProvider,
  ExecutionUiService,
  IUseCurrentTableProps,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
  IWoMassUpdateDialogProps,
} from "../../services";
import { TFunction } from "i18next";
import { fetchHelpers } from "../../helpers";
import {
  ExecutionTabId,
  IFetchExecutionOperationsProps,
  IGetInitialQueryProps,
  IWoPaneMenuItems,
  ExecutionOperationsProvider,
  IGetDefaultMaterialRequirementsColumnsProps,
  IGetDefaultOperationColumnsProps,
  IGetDefaulTimecardColumnsProps,
  IGetOperationActionsProps,
  useExecutionDetails,
} from "../execution-ui-service";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
  IExecutionContext,
  TabContext,
} from "../../context";
import {
  useExecutionAssignments,
  useExecutionTimecards,
  useExecutionMaterialRequirements,
  useExecutionOperations,
} from "../execution-ui-service";
import { IRiverPopupMenuItem } from "../../components/shared/river-popup-menu";
import { getFolderQueryGroup } from "@river/util";
import { ExecutionActionsFormatter } from "../../components/execution/execution-actions-formatter";
import { useTableCellRenderers } from "../../hooks";
import {
  FilterDropdown,
  RiverCheckmarkFormatter,
  RiverDropdownActions,
  useUserStatusAction,
  useWoMassUpdateAction,
} from "../../components/shared";
import { ModuleKey } from "../../components/sidebar-menu";
import {
  ExecutionActions,
  IExecutionAction,
} from "../../components/execution/execution";
import { SapWoMassUpdateDialog } from "./sap-shared";

interface SapIGetOperationActionsProps extends IGetOperationActionsProps {
  executionContext: IExecutionContext;
}

export class SapExecutionUiService extends ExecutionUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_component",
      "shared.user_status_dialog",
      "entity.workcenter",
      "entity.planner_group",
      "entity.revision",
      "entity.system_condition",
      "entity.priority",
      "entity.wbs",
    ];
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.execution:tab.details.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.DETAILS,
      },
      {
        label: t("module.execution:tab.operations.title"),
        icon: <DeviceHubIcon />,
        value: ExecutionTabId.OPERATIONS,
      },
      {
        label: t("module.execution:tab.assignments.title"),
        icon: <LibraryBooksIcon />,
        value: ExecutionTabId.ASSIGNMENTS,
      },
      {
        label: t("module.execution:tab.material.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.MATERIAL,
      },
      {
        label: t("module.execution:tab.timecards.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.TIMECARDS,
      },
    ];
  };

  getWoPaneMenuItems = (
    props: IWoPaneMenuItems
  ): (() => IRiverPopupMenuItem[]) => {
    return () => {
      const { executionContext, workOrder } = props;
      const { t } = useTranslation();

      const tabContext = useContext(TabContext);
      return [
        {
          label: t("module.execution:tab.details.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.DETAILS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.operations.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.OPERATIONS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.assignments.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.ASSIGNMENTS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.material.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.MATERIAL);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.timecards.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.TIMECARDS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
      ];
    };
  };

  useCurrentTable = (props: IUseCurrentTableProps): EntityTableProvider => {
    return () => {
      const { executionContext } = props;

      const detailsTable: IUseEntityTable = useExecutionDetails({
        executionContext,
      });
      const operationsTable: IUseEntityTable = useExecutionOperations({
        executionContext,
      });
      const assignmentsTable: IUseEntityTable = useExecutionAssignments({
        executionContext,
      });
      const materialTable: IUseEntityTable = useExecutionMaterialRequirements({
        executionContext,
      });
      const completionTable: IUseEntityTable = useExecutionTimecards({
        executionContext,
      });

      const currentTab: ExecutionTabId = this.getCurrentTab()();
      if (currentTab === ExecutionTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === ExecutionTabId.DETAILS) {
        return detailsTable;
      } else if (currentTab === ExecutionTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else if (currentTab === ExecutionTabId.MATERIAL) {
        return materialTable;
      } else if (currentTab === ExecutionTabId.TIMECARDS) {
        return completionTable;
      } else {
        return operationsTable;
      }
    };
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const executionContext = useContext(ExecutionContext);
      const folderId: string = executionContext?.currentSchedule?._id!;
      const currentWorkOrderId: string = executionContext?.currentWorkOrder
        ?._id as string;

      const completionPercentageDialogAction =
        executionContext?.completionPercentageDialogAction!;

      const { userStatusAction } = useUserStatusAction({
        entity_name: "workorder",
        entity_ids: [currentWorkOrderId],
        enableOn: true,
      });
      const { woMassUpdateAction } = useWoMassUpdateAction({
        renderWoMassUpdateDialog: this.renderWoMassUpdateDialog,
        disabled: false,
        workorderIds: [currentWorkOrderId],
      });
      return (
        <>
          {currentTab === ExecutionTabId.DETAILS && (
            <>
              <RiverDropdownActions
                module={ModuleKey.EXECUTION}
                items={[
                  {
                    title: woMassUpdateAction.title,
                    startIcon: woMassUpdateAction.icon,
                    onClick: woMassUpdateAction.onClick,
                    action: ExecutionActions.WO_EDIT,
                  },
                  {
                    title: userStatusAction.title,
                    startIcon: userStatusAction.icon,
                    onClick: userStatusAction.onClick,
                    action: ExecutionActions.WO_USER_STATUS,
                  },

                  {
                    title: completionPercentageDialogAction.title,
                    startIcon: completionPercentageDialogAction.icon,
                    onClick: completionPercentageDialogAction.onClick,
                    action: ExecutionActions.WO_COMPLETION_PCT,
                  },
                ]}
              />
              {woMassUpdateAction.renderDialog()}
              {userStatusAction.renderDialog()}
              {completionPercentageDialogAction.renderDialog({
                entity_name: "workorder",
                entity_ids: [currentWorkOrderId],
                folderId,
              })}
            </>
          )}
          {currentTab === ExecutionTabId.OPERATIONS && (
            <>
              <FilterDropdown />
              {completionPercentageDialogAction.renderDialog({
                entity_name: "operation",
                folderId,
              })}
            </>
          )}
          {currentTab === ExecutionTabId.ASSIGNMENTS && <FilterDropdown />}
          {currentTab === ExecutionTabId.MATERIAL && <FilterDropdown />}
          {currentTab === ExecutionTabId.TIMECARDS && <FilterDropdown />}
        </>
      );
    };
  }

  getIsTableView(): () => boolean {
    return (): boolean => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      return (
        currentTab === ExecutionTabId.DETAILS ||
        currentTab === ExecutionTabId.OPERATIONS ||
        currentTab === ExecutionTabId.ASSIGNMENTS ||
        currentTab === ExecutionTabId.MATERIAL ||
        currentTab === ExecutionTabId.TIMECARDS
      );
    };
  }

  getWoItemPropLabelMap = (t: TFunction) =>
    new Map([
      ["Orderid", t("entity.workorder:workorder.Orderid")],
      ["ShortText", t("entity.workorder:workorder.ShortText")],
      ["FunctLoc", t("entity.workorder:workorder.FunctLoc")],
      ["Equipment", t("entity.workorder:workorder.Equipment")],
    ]);

  getWoPropsToRender = () => ["Orderid", "ShortText", "FunctLoc", "Equipment"];

  getDefaultOperationColumns(
    props: IGetDefaultOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "Activity",
          name: t("entity.operation:operation.Activity"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.operationActions}
            />
          ),
        },
        {
          key: "SubActivity",
          name: t("entity.operation:operation.SubActivity"),
        },
        {
          key: "Description",
          name: t("entity.operation:operation.Description"),
        },
        {
          key: "WorkCntr",
          name: t("entity.operation:operation.WorkCntr"),
        },
        {
          key: "WorkHrs",
          name: t("entity.operation:operation.WorkHrs"),
        },
        {
          key: "NumberOfCapacities",
          name: t("entity.operation:operation.NumberOfCapacities"),
        },
        {
          key: "EarlSchedStartDate",
          name: t("entity.operation:operation.EarlSchedStartDate"),
        },
        {
          key: "EarlSchedFinDate",
          name: t("entity.operation:operation.EarlSchedFinDate"),
        },
        {
          key: "ActWorkHrs",
          name: t("entity.operation:operation.ActWorkHrs"),
        },
      ];
    };
  }

  getDefaultInstancesColumns = (): ColumnsProvider => () => [];
  getInitialInstancesQueryAttributes = (): QueryAttributeDto[] => [];

  getInitialQuery = (props: IGetInitialQueryProps): QueryAttributeGroupDto => {
    const { currentSchedule, currentWorkOrderRef } = props;
    return {
      $and: [
        getFolderQueryGroup(currentSchedule!, ""),
        {
          attribute_name: "Orderid",
          attribute_value: {
            operator: "$eq",
            value: currentWorkOrderRef!.Orderid,
          },
        },
      ],
    };
  };

  getExecutionFetchOperations = (): ExecutionOperationsProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return (props: IFetchExecutionOperationsProps): Promise<any> => {
        const { fetchProps, initialQuery } = props;
        return adapterContext!.service.getAdapterService().fetchOperations({
          ...fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributeGroup: initialQuery,
          }),
        });
      };
    };
  };

  getExecutionTabOperationEntity = () => "operation";

  getDefaultMaterialRequirementsColumns(
    props: IGetDefaultMaterialRequirementsColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "Activity",
          name: t("entity.wo_component:wo_component.Activity"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.materialRequirementActions}
            />
          ),
        },
        {
          key: "ItemNumber",
          name: t("entity.wo_component:wo_component.ItemNumber"),
        },
        {
          key: "MatlDesc",
          name: t("entity.wo_component:wo_component.MatlDesc"),
        },
        {
          key: "RequirementQuantity",
          name: t("entity.wo_component:wo_component.RequirementQuantity"),
        },
        {
          key: "RequirementQuantityUnit",
          name: t("entity.wo_component:wo_component.RequirementQuantityUnit"),
        },
        {
          key: "ReqDate",
          name: t("entity.wo_component:wo_component.ReqDate"),
        },
        {
          key: "WithdQuan",
          name: t("entity.wo_component:wo_component.WithdQuan"),
        },
      ];
    };
  }

  getExecutionTabMaterialRequirementsEntity = () => "wo_component";

  getOperationAttributeValuePath = () => "to_Operation._id";

  getDefaultTimecardColumns(
    props: IGetDefaulTimecardColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "Activity",
          name: t("entity.timecard:timecard.Activity"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.timecardActions}
            />
          ),
        },
        {
          key: "SubActivity",
          name: t("entity.timecard:timecard.SubActivity"),
        },
        {
          key: "PersonNumber",
          name: t("entity.timecard:timecard.PersonNumber"),
        },
        { key: "PersonName", name: t("entity.timecard:timecard.PersonName") },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "is_confirmed",
          name: t("module.execution:label.is_confirmed"),
          formatter: (formatterProps) => {
            const isConfirmed: boolean = (formatterProps.row as any)
              .is_confirmed as boolean;
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: <RiverCheckmarkFormatter checked={isConfirmed} />,
                })}
              </>
            );
          },
        },
      ];
    };
  }

  getOperationActions = (props: SapIGetOperationActionsProps) => {
    return () => {
      const { t } = useTranslation();
      const { executionContext } = props;
      return [
        {
          label: t("module.execution:dropdown.operation_action_user_status"),
          onClick: (operation: any) => {
            executionContext?.userStatusAction.onClick([operation._id]);
          },
          module: ModuleKey.EXECUTION,
          action: ExecutionActions.OP_USER_STATUS,
        },
      ];
    };
  };

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <SapWoMassUpdateDialog {...props} />;

  getDefaultDetailsColumns = (): ColumnsProvider => {
    const { t } = useTranslation();
    return () => [
      {
        key: "Plangroup",
        name: t("entity.workorder:workorder.Plangroup"),
      },
      {
        key: "MnWkCtr",
        name: t("entity.workorder:workorder.MnWkCtr"),
      },
      {
        key: "Priority",
        name: t("entity.workorder:workorder.Priority"),
      },
      {
        key: "Pmacttype",
        name: t("entity.workorder:workorder.Pmacttype"),
      },
      {
        key: "Systcond",
        name: t("entity.workorder:workorder.Systcond"),
      },
      {
        key: "SysStatus",
        name: t("entity.workorder:workorder.SysStatus"),
      },
      {
        key: "Userstatus",
        name: t("entity.workorder:workorder.Userstatus"),
      },
      {
        key: "Basicstart",
        name: t("entity.workorder:workorder.Basicstart"),
      },
      {
        key: "BasicFin",
        name: t("entity.workorder:workorder.BasicFin"),
      },
      {
        key: "StartDate",
        name: t("entity.workorder:workorder.StartDate"),
      },
      {
        key: "FinishDate",
        name: t("entity.workorder:workorder.FinishDate"),
      },
    ];
  };

  getDefaultAssignmentColumns(
    assignmentActions: IExecutionAction[]
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "to_Assignment.Activity",
          name: t("entity.assignment:label.Activity"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={assignmentActions}
            />
          ),
        },
        {
          key: "to_Assignment.SubActivity",
          name: t("entity.assignment:label.SubActivity"),
        },
        {
          key: "Description",
          name: t("entity.operation:operation.Description"),
        },
        {
          key: "to_Assignment.WctrName",
          name: t("entity.assignment:label.WctrName"),
        },
        {
          key: "to_Assignment.WorkHrs",
          name: t("entity.assignment:label.WorkHrs"),
        },
        {
          key: "to_Assignment.SapEarlyStartDate",
          name: t("entity.assignment:label.SapEarlyStartDate"),
        },
        {
          key: "to_Assignment.SapEarlyEndDate",
          name: t("entity.assignment:label.SapEarlyEndDate"),
        },
        {
          key: "to_Assignment.PersNo",
          name: t("entity.assignment:label.PersNo"),
        },
        {
          key: "to_Assignment.PersonName",
          name: t("entity.assignment:label.PersonName"),
        },
      ];
    };
  }
}
