import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSpinner,
  RiverTextInput,
  useTranslation,
} from "@river/common-ui";
import { FC, useContext } from "react";
import { SystemCronTasksContext, TableContext } from "../../../../context";
import { IProperty, ISystemCronTaskWithEnv } from "@river/interfaces";
import { useSystemCronTasksEditOptionsDialogForm } from "./use-system-cron-tasks-edit-options-dailog-form";
import { Box } from "@mui/material";
import styles from "./system-cron-tasks-edit-options-dailog.module.scss";

export const SystemCronTasksEditOptionsDialog: FC = () => {
  const { t } = useTranslation();
  const systemCronTasksContext = useContext(SystemCronTasksContext);
  const tableContext = useContext(TableContext);
  const table = tableContext!.table;

  function handleCloseModal() {
    resetForm();
    systemCronTasksContext?.setOpenOptionsDialog(false);
  }

  function handleCloseAndRefresh() {
    tableContext?.table.refresh();
    handleCloseModal();
  }

  const selectedItemId = Array.from(table.selectedRowIds)[0]!;
  const selectedItem = table.entities.find(
    (item) => item._id === selectedItemId
  )!;

  const form = useSystemCronTasksEditOptionsDialogForm({
    //@ts-ignore
    cron: selectedItem as ISystemCronTaskWithEnv,
    onUpdate: handleCloseAndRefresh,
  });
  const { submit, resetForm, isProcessing, dto, setDto } = form;

  const handleSetDto = (values: ISystemCronTaskWithEnv) => {
    setDto(values);
  };

  const renderOptionRow = (data: IProperty, index: number) => {
    return (
      <Box className={styles.row} key={index}>
        <RiverTextInput
          fullWidth={true}
          label={t("module.cron:cron_entity.name")}
          className={styles.nameField}
          inputProps={{
            readOnly: true,
          }}
          value={data.name}
          id={`name_${index}`}
        />
        <RiverTextInput
          fullWidth={true}
          inputProps={{
            required: true,
          }}
          value={data.value}
          id={`value_${index}`}
          label={t("module.cron:cron_entity.value")}
          onChange={(value) => {
            handleSetDto({
              ...formData,
              options:
                formData?.options?.map((item) => {
                  if (item.name === data.name) {
                    return {
                      name: data.name,
                      value,
                    };
                  }

                  return item;
                }) || [],
            });
          }}
        />
      </Box>
    );
  };

  const formData = dto as ISystemCronTaskWithEnv;
  return (
    <RiverDialog
      classes={{
        paper: styles.paper,
      }}
      form={form}
      title={t("module.cron:dialog.system_cron.edit_options")}
      open={!!systemCronTasksContext?.openOptionsDialog}
      actionButtonText={t("common.button:submit")}
      closeButtonText={t("common.button:save")}
      onSubmit={submit}
      onClose={handleCloseModal}
      showActionsDivider={true}
      dialogProps={{
        maxWidth: false,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={handleCloseModal}
            text={t("common.button:close")}
          />
          <RiverDialogActionButton
            onClick={submit}
            text={t("common.button:save")}
          />
        </>
      }
    >
      <RiverSpinner show={isProcessing} />
      <Box className={styles.root}>
        {formData?.options?.map((data, index) => renderOptionRow(data, index))}
      </Box>
    </RiverDialog>
  );
};
