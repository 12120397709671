import {
  ReactElement,
  useEffect,
  useContext,
  FC,
  useState,
  useCallback,
} from "react";
import { debounce } from "ts-debounce";
import {
  IUseValidation,
  IValidationErrors,
  RiverDialog,
  RiverTextInput,
  useNotification,
  useValidation,
} from "@river/common-ui";
import { RiverFormInstance, useTableCellRenderers } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import {
  CraftsUiService,
  ExternalResourcesUiService,
  IRenderCreateResourcesDialogProps,
} from "../../../../services";
import { IEntityObject } from "@river/interfaces";
import { Column } from "react-data-grid";
import { IColumn } from "../../../../interfaces";
import { LookupType, RiverLookup } from "../../../../components/shared";
import { RiverTableLookup } from "../../../../components/shared/river-table-lookup";
import styles from "./jde-availability-create-resource-dialog.module.scss";
import {
  IStandaloneValidatorJdeAvailabilityCreateResourceDialog,
  useJdeAvailabilityCreateResourceDialogForm,
} from "./use-jde-availability-create-resource-dialog-form";

export const JdeAvailabilityCreateResourceDialog: FC<
  IRenderCreateResourcesDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();
  const notify = useNotification();
  const validation: IUseValidation = useValidation();
  const [selectedAddressBook, setSelectedAddressBook] =
    useState<IEntityObject | null>(null);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const externalResourcesUiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;

  const craftsArrayKey: string = externalResourcesUiService.craftsArrayKey;

  const form: RiverFormInstance = useJdeAvailabilityCreateResourceDialogForm({
    data: selectedAddressBook,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
  });

  const {
    setDto,
    isReadOnly,
    resetForm,
    validationErrors,
    setValidationErrors,
    onStandalonePropertyChange,
    validateStandaloneField,
    setStandaloneFields,
  } = form;
  const fields =
    form.standalone as IStandaloneValidatorJdeAvailabilityCreateResourceDialog;

  const dto: IEntityObject = form.dto as IEntityObject;

  const crafts: IEntityObject[] = dto[craftsArrayKey] as IEntityObject[];

  const getCraftsColumns = (): IColumn[] => {
    return [
      {
        key: "F30006_MCU",
        name: t("entity.workcenter:workcenter.F30006_MCU"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "F30006_MMCU",
        name: t("entity.workcenter:workcenter.F30006_MMCU"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
    ];
  };

  const columns: Column<any>[] = getCraftsColumns();

  const closeDialog = (success: boolean): void => {
    resetForm();
    setSelectedAddressBook(null);
    props.onClose(success);
  };

  const onDialogSubmit = async () => await form.submit();

  const dialogTitle: string = t("module.availability:dialog.create_resource");

  const actionButtonText: string = t("common.button:save");

  const validateCraftsArray = async (newDto: IEntityObject): Promise<void> => {
    const newErrors: IValidationErrors = await validation.validateProperty(
      newDto,
      craftsArrayKey,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const onSelectCraft = async (selection: IEntityObject[]): Promise<void> => {
    const selectedCraft: IEntityObject = selection[0];
    const alreadySelected: boolean = !!crafts?.find(
      (craft) =>
        craftsUiService.craftKeyGetter(craft) ===
        craftsUiService.craftKeyGetter(selectedCraft)
    );
    if (alreadySelected) {
      notify.error(t("module.external_resource:resource_already_selected"));
      return;
    }

    const { F30006_MCU, F30006_MMCU } = selectedCraft;
    const newDto: IEntityObject = {
      ...dto,
      [craftsArrayKey]: [
        ...crafts,
        {
          F30006_MCU,
          F30006_MMCU,
        },
      ],
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    await validateCraftsArray(newDto);
  };

  const onDeleteCraft = async (deletedCraft: IEntityObject): Promise<void> => {
    const newDto: IEntityObject = {
      ...dto,
      [craftsArrayKey]: crafts?.filter(
        (craft) =>
          craftsUiService.craftKeyGetter(craft) !==
          craftsUiService.craftKeyGetter(deletedCraft)
      ),
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    await validateCraftsArray(newDto);
  };

  // fetch calendar object with calendar id or calendar name
  const fetchAddressBook = async (
    attribute_value: string,
    attribute_name: string
  ): Promise<IEntityObject> => {
    const calendars: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .searchEntityData("address_book", {
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return calendars[0] as IEntityObject;
  };

  const handleSetStandaloneFields = (data: IEntityObject) => {
    const { F0101_AN8, F0101_ALPH } = data;
    setStandaloneFields({
      ...fields,
      F0101_AN8,
      F0101_ALPH,
    });
    setSelectedAddressBook(data);
  };

  const fetchAddressBookByName = async (name: string): Promise<void> => {
    try {
      const data: IEntityObject = await fetchAddressBook(name, "F0101_AN8");
      handleSetStandaloneFields(data);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchAddressBookByName = useCallback(
    debounce((text) => {
      fetchAddressBookByName(text);
    }, 600),
    [validationErrors]
  );

  const renderAddressField = (): ReactElement => (
    <RiverLookup
      id="F0101_AN8"
      inputProps={{ required: true }}
      lookup={{ type: LookupType.JDE_ADDRESS_BOOK }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchAddressBookByName(event.target.value);
      }}
      onSelect={(data: IEntityObject) => {
        handleSetStandaloneFields(data);
      }}
    />
  );

  const renderContent = (): ReactElement => (
    <>
      {renderAddressField()}
      <RiverTextInput
        id="F0101_ALPH"
        fullWidth
        inputProps={{ readOnly: true }}
      />
      <RiverTableLookup
        title={t("shared.crafts:label.crafts")}
        buttonLabel={t("shared.crafts:button.add_craft")}
        rows={crafts}
        columns={columns}
        lookup={{
          type: LookupType.CRAFTS,
        }}
        singleSelect={true}
        onSelect={onSelectCraft}
        onDelete={onDeleteCraft}
        isReadOnly={isReadOnly}
        fieldId={craftsArrayKey}
      />
    </>
  );

  useEffect(() => {
    if (fields?.F0101_AN8) {
      validateStandaloneField("F0101_AN8");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddressBook]);

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={actionButtonText}
      onSubmit={onDialogSubmit}
      showActionsDivider={false}
      form={form}
      classes={{
        paper: styles.paper,
      }}
    >
      {renderContent()}
    </RiverDialog>
  );
};
