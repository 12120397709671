import { IUseEntityTable } from "../ui-service.types";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "./supervisor-schedule-utilization-ui-service";
import { IAdapterBaseline, IAdapterFolder } from "@river/interfaces";
import {
  IUseTable,
  TableFetchFunction,
  useTable,
} from "../../components/shared";
import { getBlankEntity } from "../../hooks";

export const useSupervisorScheduleUtilizationKpis = (): IUseEntityTable => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService = adapterContext!.service.getAdapterService();
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const selectedBaseline: IAdapterBaseline = scheduleContext?.selectedBaseline!;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();

  const fetchFunction: TableFetchFunction<any> = async (): Promise<any> => {
    return await adapterService.fetchFolderSchedulingCompliance(
      currentSchedule._id,
      selectedBaseline?._id || null
    );
  };

  const table: IUseTable = useTable({
    columns: [],
    fetchFunction,
    dependencies: [currentTab === SupervisorScheduleUtilizationTabId.METRICS],
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.METRICS,
    fetchTriggers: [currentSchedule],
    useAdvancedFilters: false,
  });

  return {
    entity: getBlankEntity(),
    table,
  };
};

export type IUseSupervisorScheduleUtilizationKpis = ReturnType<
  typeof useSupervisorScheduleUtilizationKpis
>;
