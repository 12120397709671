import { FC, useState, useContext, ChangeEvent } from "react";
import { AdapterUiContext, TableContext } from "../../../context";
import { IAdapterShift, IEntityObject } from "@river/interfaces";
import { Box } from "@mui/material";
import { GenericAvatarIcon } from "../../../icons";
import {
  RiverFormSelect,
  IRiverSimpleSelectItem,
  RiverTextInput,
  useNotification,
  RiverModalDrawerButton,
  RiverModalDrawerActionButton,
  RiverModalDrawer,
} from "@river/common-ui";
import { addDays } from "date-fns";
import { useTranslation } from "@river/common-ui";
import styles from "./supervisor-crew-editor-drawer.module.scss";

interface ISupervisorCrewEditorDrawerProps {
  activeRow: any;
  shifts: IAdapterShift[];
  startDate: Date;
  index: number;
  onClose(): void;
  open: boolean;
}

export const SupervisorCrewEditorDrawer: FC<
  ISupervisorCrewEditorDrawerProps
> = (props) => {
  const { activeRow, shifts, startDate, index, onClose, open } = props;
  const adapterContext = useContext(AdapterUiContext);
  const uiService = adapterContext!.service.getAvailabilityUiService();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedShiftId, setSelectedShiftId] = useState<string>(
    activeRow.availability[index]?.shift_id ?? ""
  );
  const [editorState, setEditorState] = useState<IEntityObject>(
    activeRow.availability[index]
  );

  const notify = useNotification();

  const getAvailabilityDate = () => addDays(startDate, index);

  const getShift = (shiftId: string) => {
    return shifts.filter((shift) => shift._id === shiftId)[0];
  };

  return (
    <TableContext.Consumer>
      {(tableContext) => {
        const createAvailability = async (data: any) => {
          const header = activeRow;
          const shift: IAdapterShift = getShift(data.shift_id)!;
          const availability = {
            header_id: header._id,
            CompCode: header.CompCode,
            Plant: header.Plant,
            PlanningPlant: header.PlanningPlant,
            WorkCenterId: header.WorkCenterId,
            CapacityCategory: header.CapacityCategory || "",
            PersonNumber: header.PersonNumber,
            availability_date: data.availability_date,
            shift_id: shift?._id || null,
            shift: shift?.shift || "",
            start_hours: shift?.start_hours || 0,
            start_minutes: shift?.start_minutes || 0,
            end_hours: shift?.end_hours || 0,
            end_minutes: shift?.end_minutes || 0,
            available_hours: shift?.duration_hours || 0,
          };
          return await adapterContext!.service
            .getAdapterService()
            .createEntityData("availability", availability)
            .then((result) => {
              tableContext?.table.refresh();
            })
            .catch((message) => {
              notify.error(message.response.data.message);
            });
        };

        const updateAvailability = async (id: string, data: any) => {
          return await adapterContext!.service
            .getAdapterService()
            .updateEntityData("availability", id, data)
            .then((result) => {
              tableContext?.table.refresh();
            })
            .catch((message) => {
              notify.error({ message });
            });
        };

        const saveShiftChange = async () => {
          const availability = activeRow.availability[index];

          if (availability) {
            const shiftObj: IAdapterShift = getShift(selectedShiftId)!;
            const data: IEntityObject = {
              shift_id: shiftObj?._id || null,
              shift: shiftObj?.shift || "",
              start_hours: shiftObj?.start_hours || 0,
              start_minutes: shiftObj?.start_minutes || 0,
              end_hours: shiftObj?.end_hours || 0,
              end_minutes: shiftObj?.end_minutes || 0,
              available_hours: shiftObj?.duration_hours || 0,
            };

            const id = availability._id;
            await updateAvailability(id, data);
          } else {
            const availability_date: Date = getAvailabilityDate();
            await createAvailability({
              shift_id: selectedShiftId,
              availability_date,
            });
          }
        };

        const onAvailableHoursChange = async (
          event: ChangeEvent<HTMLInputElement>
        ) => {
          const available_hours = event.target.value as string;
          const availability: IEntityObject = Object.assign(
            {},
            {
              ...editorState,
              available_hours: available_hours,
            }
          );
          setEditorState(availability);
        };

        const onShiftChange = async (event: ChangeEvent<HTMLInputElement>) => {
          setSelectedShiftId(event.target.value);
          const shift = shifts.find(
            (shift) => shift._id === event.target.value
          );
          const availability: IEntityObject = Object.assign(
            {},
            {
              ...editorState,
              available_hours: shift?.duration_hours || 0,
            }
          );
          setEditorState(availability);
        };

        const mppedShifts: IRiverSimpleSelectItem[] = shifts.map((shift) => {
          return { value: shift._id!, text: shift.shift! };
        });

        const handleSave = async () => {
          setProcessing(true);
          await saveShiftChange();
          await updateAvailableHours();
          setProcessing(false);
          onClose();
        };

        const updateAvailableHours = async () => {
          const id = editorState._id as string;
          const available_hours = Number(editorState.available_hours);
          await updateAvailability(id, { available_hours });
        };

        const formatDate = (dateString: string): string => {
          const date = new Date(dateString);
          return new Intl.DateTimeFormat(navigator.language, {
            timeZone: "UTC",
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(date);
        };

        const renderDrawerActions = () => {
          return (
            <Box className={styles.drawerActionsContainer}>
              <RiverModalDrawerButton
                onClick={onClose}
                className={styles.drawerCancelBtn}
                disabled={processing}
              >
                {t("common.button:cancel")}
              </RiverModalDrawerButton>
              <RiverModalDrawerActionButton
                disabled={processing}
                className={styles.drawerSaveBtn}
                onClick={handleSave}
              >
                {t("common.button:save_changes")}
              </RiverModalDrawerActionButton>
            </Box>
          );
        };

        const entries: IRiverSimpleSelectItem[] = [
          { value: "", text: `- ${t("common.label:none")} -` },
        ].concat(mppedShifts);
        return (
          <RiverModalDrawer
            titleContent={
              <>
                <Box className={styles.header}>
                  <GenericAvatarIcon />
                  <p className={styles.availabilityName}>
                    {activeRow?.[uiService.getEditAvailabilityHeaderKey()]}
                  </p>
                </Box>
                <br></br>
                <p className={styles.availabilityDate}>
                  {formatDate(editorState.availability_date as string)}
                </p>
              </>
            }
            classes={{ title: styles.title }}
            showTitleDivider={true}
            onClose={onClose}
            closeOnBackdropClick={true}
            open={open}
            actionsContent={renderDrawerActions()}
            children={
              <div className={styles.root}>
                <RiverFormSelect
                  label={t("entity.shift:shift.shift")}
                  id="shift"
                  fullWidth
                  items={entries}
                  onChangeEvent={onShiftChange}
                  value={selectedShiftId || ""}
                  formControlProps={{
                    variant: "outlined",
                  }}
                />
                <RiverTextInput
                  label={t("common.label:hours")}
                  id="available_hours"
                  value={editorState?.available_hours?.toString()}
                  className={styles.availableHoursEditor}
                  onChangeEvent={onAvailableHoursChange}
                  inputProps={{
                    type: "number",
                    inputProps: { min: 0 },
                  }}
                />
              </div>
            }
          />
        );
      }}
    </TableContext.Consumer>
  );
};
