import React, { ReactElement } from "react";
import { RiverDialog, RiverTextInput, useNotification } from "@river/common-ui";
import { LookupType, RiverLookup } from "../../shared";
import { IEntityObject } from "@river/interfaces";
import { useNotificationForm } from "./use-notification-form";
import styles from "./notification-dialog.module.scss";
import { RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";

interface INotificationDialogProps {
  open: boolean;
  notification: IEntityObject | null;
  onClose: (success: boolean) => void;
}

export const NotificationDialog: React.FC<INotificationDialogProps> = (
  props
): ReactElement => {
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useNotificationForm({
    notification: props.notification,
    onCreate: (notificationNumber) => {
      closeAndRefresh();
      notify.success({
        message: `${t(
          "module.notifications:notification.success_create_number",
          { number: notificationNumber }
        )}`,
      });
    },
    onUpdate: () => closeAndRefresh(),
  });

  const notificationDto = form.dto as IEntityObject;
  const { validationErrors, onPropertyChange, submit, setDto, resetForm } =
    form;

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const renderNotificationTypeField = (): ReactElement => (
    <RiverTextInput
      id={"NotifType"}
      label={t("entity.notification:notification.NotifType")}
      value={String(notificationDto.NotifType)}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["NotifType"]}
      fullWidth
    />
  );

  const renderEquipmentField = (): ReactElement => (
    <RiverLookup
      id={"Equipment"}
      fullWidth
      lookup={{ type: LookupType.EQUIPMENT }}
      onSelect={(selectedObject: IEntityObject) => {
        const { Equipment } = selectedObject;
        const newState: IEntityObject = { ...notificationDto };
        Object.assign(newState, { Equipment });
        setDto(newState);
      }}
    />
  );

  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id={"ShortText"}
      fullWidth
      className={styles.descriptionField}
    />
  );

  const renderPlannerGroupField = (): ReactElement => (
    <RiverLookup
      id={"Plangroup"}
      fullWidth
      lookup={{ type: LookupType.PLANNER_GROUPS }}
      onSelect={(selectedGroup: IEntityObject) => {
        const { Plangroup } = selectedGroup;
        const newState: IEntityObject = { ...notificationDto };
        Object.assign(newState, {
          Plangroup,
        });
        setDto(newState);
      }}
    />
  );

  const renderWorkCenterField = (): ReactElement => (
    <RiverLookup
      id={"MainWorkCenter"}
      fullWidth
      lookup={{ type: LookupType.CRAFTS }}
      onSelect={(selectedWc: IEntityObject) => {
        const { WorkCenterId, WorkCenter } = selectedWc;
        const newState: IEntityObject = { ...notificationDto };
        Object.assign(newState, {
          PmWkctr: WorkCenterId,
          MainWorkCenter: WorkCenter,
        });
        setDto(newState);
      }}
    />
  );

  const renderPriorityField = (): ReactElement => (
    <RiverLookup
      id={"Priority"}
      fullWidth
      lookup={{ type: LookupType.PRIORITY }}
      onSelect={(selectedPriority: IEntityObject) => {
        const { Priority } = selectedPriority;
        const newState: IEntityObject = { ...notificationDto };
        Object.assign(newState, {
          Priority,
        });
        setDto(newState);
      }}
    />
  );

  const getDialogTitle = (): string =>
    props.notification
      ? t("module.notifications:dialog.update_notification")
      : t("module.notifications:dialog.create_notification");
  const getActionButtonText = (): string =>
    props.notification ? t("common.button:save") : t("common.button:create");
  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={getActionButtonText()}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      {props.notification ? null : renderNotificationTypeField()}
      {renderEquipmentField()}
      {renderDescriptionField()}
      {renderPlannerGroupField()}
      {renderWorkCenterField()}
      {renderPriorityField()}
    </RiverDialog>
  );
};
