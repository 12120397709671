import React, { ReactElement, useContext } from "react";
import { useNotification } from "@river/common-ui";
import { CraftsUiService, userPreferencesService } from "../../../../services";
import {
  ITableFetchFunctionProps,
  RiverCustomSelector,
  useRiverSelectColumn,
} from "../../../../components/shared";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { Column } from "react-data-grid";
import { fetchHelpers } from "../../../../helpers";
import { useTranslation } from "@river/common-ui";
import { ICraftSelectorProps } from "../../../crafts-ui.service";
import {
  CRAFT_ROW_KEY_SEPARATOR,
  IEbsCraftKey,
} from "../../oracle-ebs-crafts-ui.service";
import { QueryAttributeGroupDto } from "@river/interfaces";

export const OracleEbsCraftSelector: React.FC<ICraftSelectorProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const notify = useNotification();

  const convertRowKeyToCraftKey = (rowKey: string): IEbsCraftKey => {
    const [DEPARTMENT_ID, RESOURCE_ID] = rowKey.split(CRAFT_ROW_KEY_SEPARATOR);
    return {
      DEPARTMENT_ID: Number(DEPARTMENT_ID),
      RESOURCE_ID: Number(RESOURCE_ID),
    };
  };

  const entityName: string = uiService.getCraftEntity();
  const columns: Column<any>[] = uiService.getCraftsSelectorColumns(
    t,
    RiverSelectColumn
  );
  const assignedColumns: Column<any>[] =
    uiService.getAssignedCraftsSelectorColumns(t, RiverSelectColumn);

  const selectedCraftKeys: IEbsCraftKey[] = Array.from(props.selectedIds).map(
    (stringifiedKey) => {
      return JSON.parse(stringifiedKey) as IEbsCraftKey;
    }
  );

  const saveSelectedCrafts = async (
    craftKeys: IEbsCraftKey[]
  ): Promise<void> => {
    try {
      const value: string[] = craftKeys.map((craftKey) =>
        JSON.stringify(craftKey)
      );
      if (props.isSupervisor) {
        await userPreferencesService.setSupervisorUtilizationCrafts(
          adapterContext!.service.getAdapterService(),
          value
        );
      } else {
        await userPreferencesService.setUtilizationCrafts(
          adapterContext!.service.getAdapterService(),
          value
        );
      }
      if (props.onChangeSelectedCrafts) {
        props.onChangeSelectedCrafts(new Set(value));
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const fetchAvailableCrafts = async (fetchProps: ITableFetchFunctionProps) => {
    const createKeyQuery = (key: IEbsCraftKey): QueryAttributeGroupDto => {
      return {
        $or: [
          {
            attribute_name: "DEPARTMENT_ID",
            attribute_value: { operator: "$ne", value: key.DEPARTMENT_ID },
          },
          {
            attribute_name: "RESOURCE_ID",
            attribute_value: { operator: "$ne", value: key.RESOURCE_ID },
          },
        ],
      };
    };

    return await adapterContext!.service.getAdapterService().fetchCrafts(
      fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: {
          $and: selectedCraftKeys.map((key) => createKeyQuery(key)),
        },
      })
    );
  };

  const fetchAssignedCrafts = async (fetchProps: ITableFetchFunctionProps) => {
    const createKeyQuery = (key: IEbsCraftKey): QueryAttributeGroupDto => {
      return {
        $and: [
          {
            attribute_name: "DEPARTMENT_ID",
            attribute_value: { operator: "$eq", value: key.DEPARTMENT_ID },
          },
          {
            attribute_name: "RESOURCE_ID",
            attribute_value: { operator: "$eq", value: key.RESOURCE_ID },
          },
        ],
      };
    };

    const getSelectedCraftsQueryGroup = (): QueryAttributeGroupDto => {
      let query: QueryAttributeGroupDto;
      if (selectedCraftKeys.length) {
        query = {
          $or: selectedCraftKeys.map((key) => createKeyQuery(key)),
        };
      } else {
        const emptyResultsQuery: QueryAttributeGroupDto = {
          $and: [
            {
              attribute_name: "RESOURCE_ID",
              attribute_value: { operator: "$eq", value: -1 },
            },
          ],
        };
        query = emptyResultsQuery;
      }
      return query;
    };

    return await adapterContext!.service.getAdapterService().fetchCrafts(
      fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getSelectedCraftsQueryGroup(),
      })
    );
  };

  const assignCrafts = async (assignedRowKeys: Set<string>): Promise<any> => {
    const assignedCraftKeys: IEbsCraftKey[] = Array.from(assignedRowKeys).map(
      (rowKey) => convertRowKeyToCraftKey(rowKey)
    );
    const newCraftKeys: IEbsCraftKey[] =
      selectedCraftKeys.concat(assignedCraftKeys);
    await saveSelectedCrafts(newCraftKeys);
  };

  const unassignCrafts = async (
    unassignedRowKeys: Set<string>
  ): Promise<any> => {
    const unassignedCraftKeys: IEbsCraftKey[] = Array.from(
      unassignedRowKeys
    ).map((rowKey) => convertRowKeyToCraftKey(rowKey));

    const newCraftKeys: IEbsCraftKey[] = selectedCraftKeys.filter((key) => {
      return (
        unassignedCraftKeys.findIndex(
          (unassigned) =>
            unassigned.DEPARTMENT_ID === key.DEPARTMENT_ID &&
            unassigned.RESOURCE_ID === key.RESOURCE_ID
        ) === -1
      );
    });
    await saveSelectedCrafts(newCraftKeys);
  };

  return (
    <RiverCustomSelector
      isDialog={props.isDialog}
      open={props.open}
      onClose={props.onClose}
      dialogTitle={t("shared.crafts:title")}
      availableEntityName={entityName}
      assignedEntityName={entityName}
      availableTableColumns={columns}
      assignedTableColumns={assignedColumns}
      availableTableRowKeyGetter={uiService.craftKeyGetter}
      assignedTableRowKeyGetter={uiService.craftKeyGetter}
      fetchAvailableObjects={fetchAvailableCrafts}
      fetchAssignedObjects={fetchAssignedCrafts}
      assignAction={assignCrafts}
      unassignAction={unassignCrafts}
      refreshTriggers={[JSON.stringify(Array.from(props.selectedIds))]}
    />
  );
};
