import React, { ReactElement, useContext, useState } from "react";
import {
  RiverDialog,
  RiverSpinner,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { Column } from "react-data-grid";
import {
  AdapterUserStatusActionDto,
  AdapterUserStatusDto,
  AdapterUserStatusEditActionDto,
  IAdapterUserStatusActionResponse,
  IEntityObject,
} from "@river/interfaces";
import {
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../../shared";
import { getBlankEntity } from "../../../hooks";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import styles from "./user-status-dialog.module.scss";
import clsx from "clsx";
import { SapAdapterService } from "../../../services/sap";
import { BacklogUiService } from "../../../services";

export interface IUserStatusDialogProps {
  open: boolean;
  onClose: () => void;
  entity_ids?: string[];
  entity_name?: string;
}

export const UserStatusDialog: React.FC<IUserStatusDialogProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;

  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const [updatingUserStatus, setUpdatingUserStatus] = useState<boolean>(false);

  const tableContext = useContext(TableContext);
  const notify = useNotification();

  //
  const orderIds: string[] =
    props.entity_ids || Array.from(tableContext?.table.selectedRowIds!);

  const entityName: string =
    props.entity_name || uiService.getUserStatusResponseEntityName();

  const columnsWithNumber: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "status_number",
      name: t("shared.user_status_dialog:with_number.column.status_number"),
      width: 100,
    },
    {
      key: "user_status",
      name: t("shared.user_status_dialog:with_number.column.user_status"),
      width: 120,
    },
    {
      key: "description",
      name: t("shared.user_status_dialog:with_number.column.description"),
    },
  ];
  const columnsWithoutNumber: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "user_status",
      name: t("shared.user_status_dialog:without_number.column.user_status"),
      width: 100,
    },
    {
      key: "description",
      name: t("shared.user_status_dialog:without_number.column.description"),
    },
  ];
  const fetchUserStatusWithNumber = async () => {
    if (entityName) {
      const payload: AdapterUserStatusActionDto = {
        entity_ids: orderIds,
        entity_name: entityName,
      };
      const responseObject: IAdapterUserStatusActionResponse = await (
        adapterContext!.service.getAdapterService() as SapAdapterService
      ).getUserStatusResponse(payload);
      const userStatus: IEntityObject[] =
        responseObject.with_number as unknown as IEntityObject[];
      getInitialFetchWithNumber(userStatus);
      return userStatus;
    } else {
      return [];
    }
  };

  const fetchUserStatusWithoutNumber = async () => {
    if (entityName) {
      const payload: AdapterUserStatusActionDto = {
        entity_ids: orderIds,
        entity_name: entityName,
      };
      const responseObject: IAdapterUserStatusActionResponse = await (
        adapterContext!.service.getAdapterService() as SapAdapterService
      ).getUserStatusResponse(payload);
      const userStatus: IEntityObject[] =
        responseObject.without_number as unknown as IEntityObject[];
      getInitialFetchWithoutNumber(userStatus);
      return userStatus;
    } else {
      return [];
    }
  };
  const getInitialFetchWithNumber = (userStatus: IEntityObject[]): void => {
    let uniqueId: string = "";
    userStatus.forEach((e) => {
      if (e.active) {
        uniqueId = String(e.user_status);
      }
    });
    if (!!uniqueId) {
      tableWithNumber.setSelectedRowIds(new Set([uniqueId]));
    }
  };

  const getInitialFetchWithoutNumber = (userStatus: IEntityObject[]): void => {
    const selectedIds: Set<string> = new Set();
    userStatus.forEach((e) => {
      if (e.active) {
        const uniqueId = String(e.user_status);
        selectedIds.add(uniqueId);
      }
    });
    tableWithoutNumber.setSelectedRowIds(selectedIds);
  };

  const updateUserStatus = async () => {
    setUpdatingUserStatus(true);
    for (let id of orderIds) {
      if (entityName === "workorder" || entityName === "operation") {
        const payload: AdapterUserStatusEditActionDto = {
          entity_name: entityName,
          entity_id: String(id),
          with_number: withStatusNumberPayload(),
          without_number: withoutNumberStatusPayload(),
        };
        await (adapterContext!.service.getAdapterService() as SapAdapterService)
          .updateUserStatus(payload)
          .then(() => {
            tableContext?.table.refresh();
          })
          .catch((message) => {
            notify.error({ message });
          });
      }
    }
    setUpdatingUserStatus(false);
    props.onClose();
    tableWithNumber.setSelectedRowIds(new Set());
  };

  const withStatusNumberPayload = (): AdapterUserStatusDto[] => {
    const selectedId = Array.from(tableWithNumber.selectedRowIds)[0];
    if (typeof selectedId === "string") {
      return [
        {
          user_status: selectedId,
          active: true,
        },
      ];
    } else {
      return [];
    }
  };

  const withoutNumberStatusPayload = (): AdapterUserStatusDto[] => {
    let arrayWithoutNumberObject: AdapterUserStatusDto[] = [];
    // adding all ids to an array that are checked
    for (let id of Array.from(tableWithoutNumber.selectedRowIds)) {
      const withoutNumberObject: AdapterUserStatusDto = {
        user_status: id,
        active: true,
      };
      arrayWithoutNumberObject.push(withoutNumberObject);
    }
    // all user_statuses ids
    let allUserStatuses: string[] = [];
    for (let id of tableWithoutNumber.entities) {
      allUserStatuses.push(String(id.user_status));
    }

    //diffrence of selectedIds and all user_statuses to get all unselected ids
    let diffrence = allUserStatuses
      .filter((x) => !Array.from(tableWithoutNumber.selectedRowIds).includes(x))
      .concat(
        Array.from(tableWithoutNumber.selectedRowIds).filter(
          (x) => !allUserStatuses.includes(x)
        )
      );
    // adding all ids to an array that are unchecked
    for (let userStatusFalse of diffrence) {
      const withoutNumberObject: AdapterUserStatusDto = {
        user_status: userStatusFalse,
        active: false,
      };
      arrayWithoutNumberObject.push(withoutNumberObject);
    }
    return arrayWithoutNumberObject;
  };

  const tableWithNumber: IUseTable = useTable({
    columns: columnsWithNumber,
    fetchFunction: fetchUserStatusWithNumber,
    fetchOn: props.open,
    infiniteScrolling: true,
  });

  const tableWithoutNumber: IUseTable = useTable({
    columns: columnsWithoutNumber,
    fetchFunction: fetchUserStatusWithoutNumber,
    fetchOn: props.open,
    infiniteScrolling: true,
  });

  const isLoading: boolean =
    tableWithNumber.isLoading ||
    tableWithoutNumber.isLoading ||
    updatingUserStatus;
  return (
    <>
      <RiverDialog
        title={t("shared.user_status_dialog:title")}
        open={props.open}
        onClose={() => {
          tableWithNumber.setSelectedRowIds(new Set());
          props.onClose();
        }}
        actionButtonText={t("common.button:update")}
        onSubmit={updateUserStatus}
        showActionsDivider={false}
        classes={{
          paper: styles.paper,
          content: styles.content,
        }}
        dialogProps={{
          maxWidth: false,
        }}
      >
        <RiverSpinner show={isLoading} />
        <TableContext.Provider
          value={{
            table: tableWithNumber,
            entity: getBlankEntity(),
          }}
        >
          <div className={styles.tableContainer}>
            <div className={styles.tableTitle}>
              {t("shared.user_status_dialog:with_number.header")}
            </div>

            <RiverDataGrid
              disableSelectAll={true}
              singleSelect={true}
              className={clsx([styles.grid, styles.statusWithNumberGrid])}
              columns={tableWithNumber.columns}
              rows={tableWithNumber.entities}
              rowKeyGetter={(row) => String(row.user_status)}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
          </div>
        </TableContext.Provider>
        <TableContext.Provider
          value={{
            table: tableWithoutNumber,
            entity: getBlankEntity(),
          }}
        >
          <div className={styles.tableContainer}>
            <div className={styles.tableTitle}>
              {t("shared.user_status_dialog:without_number.header")}
            </div>
            <RiverDataGrid
              disableSelectAll={true}
              columns={tableWithoutNumber.columns}
              rows={tableWithoutNumber.entities}
              rowKeyGetter={(row) => String(row.user_status)}
              className={styles.grid}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
          </div>
        </TableContext.Provider>
      </RiverDialog>
    </>
  );
};
