import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface ISapWorkOrderMassUpdate {
  Pmacttype?: string;
  MnWkCtr?: string;
  Plangroup?: string;
  Revision?: string;
  Systcond?: string;
  Priority?: string;
  WBSElementExternalID?: string;
  WbsElem?: string;
}

export class SapWorkOrderMassUpdate {
  constructor(obj: ISapWorkOrderMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useSapWoMassUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, SapWorkOrderMassUpdate>({
    standalone: {
      fields: new SapWorkOrderMassUpdate({
        Pmacttype: "",
        MnWkCtr: "",
        Plangroup: "",
        Revision: "",
        Systcond: "",
        Priority: "",
        WBSElementExternalID: "",
        WbsElem: "",
      }),
      fieldDefs: [
        { fieldName: "Pmacttype", dataType: "string" },
        { fieldName: "MnWkCtr", dataType: "string" },
        { fieldName: "Plangroup", dataType: "string" },
        { fieldName: "Revision", dataType: "string" },
        { fieldName: "Systcond", dataType: "string" },
        { fieldName: "Priority", dataType: "string" },
        { fieldName: "WbsElem", dataType: "string" },
      ],
    },
    submit: props.submit,
    labels: {
      Pmacttype: t("entity.workorder:workorder.Pmacttype"),
      MnWkCtr: t("entity.workorder:workorder.MnWkCtr"),
      Plangroup: t("entity.workorder:workorder.Plangroup"),
      Revision: t("entity.workorder:workorder.Revision"),
      Systcond: t("entity.workorder:workorder.Systcond"),
      Priority: t("entity.workorder:workorder.Priority"),
      WBSElementExternalID: t("entity.workorder:workorder.WbsElem"),
    },
  });
};
