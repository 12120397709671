import { FC } from "react";
import { Switch, SwitchProps } from "@mui/material";
import styles from "./river-switch.module.scss";
import clsx from "clsx";

export const RiverSwitch: FC<SwitchProps> = (props) => (
  <Switch
    className={clsx([styles.root, props.className])}
    disableRipple
    {...props}
  />
);
