import React from "react";
import { WoCompletionMap } from "./oracle-ebs-wo-completion-dialog";

export interface IWoCompletionContext {
  completionStatusMap: WoCompletionMap;
  selectedWorkOrders: any[];
  woCompletions: any[];
  setWoCompletions: (completions: any[]) => void;
  closeDialog: (props: { success: boolean }) => void;
}

export const WoCompletionContext =
  React.createContext<IWoCompletionContext | null>(null);
