import React, { useContext } from "react";
import {
  GridHeader,
  GridActions,
  DeleteAction,
  IUseTable,
  EditAction,
  FilterDropdown,
} from "../../shared";
import { ModuleKey } from "../../sidebar-menu";
import { UsersAction } from "../users";
import ProtectedAction from "../../protected-action";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import { fetchHelpers } from "../../../helpers";
import { useTranslation } from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import { UsersUiService } from "../../../services";

interface IUsersGridHeader {
  openUserRoleDialog: () => void;
}

export const UsersGridHeader: React.FC<IUsersGridHeader> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const usersUiService: UsersUiService =
    adapterContext?.service!.getUsersUiService()!;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const notify = useNotification();
  const { t } = useTranslation();

  const deleteSelectedUsers = async (): Promise<any> => {
    if (table.selectedRowIds.size > 0) {
      try {
        table.forceLoadingState(true);
        const selectedUsers = (await adapterContext
          ?.service!.getAdapterService()
          .searchEntityData(
            "user",
            fetchHelpers.getTableQuery({
              fetchProps: {
                ids: Array.from(table.selectedRowIds),
                columnFilters: [],
                sortColumns: [],
              },
              idPropName: usersUiService.getUserNameKey(),
            })
          )) as unknown as IEntityObject[];
        for await (const user of selectedUsers) {
          await usersUiService.deleteUser(user);
        }
        tableContext?.table.fetch();
      } catch (message) {
        notify.error({ message });
      } finally {
        table.forceLoadingState(false);
      }
    }
  };

  const deleteUserDeleteConfirmation = useSimpleDialog({
    title: t("module.users:dialog.delete_users"),
    message: t("module.users:message.confirm_users_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedUsers,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction module={ModuleKey.USERS} action={UsersAction.UPDATE}>
            <EditAction onClick={props.openUserRoleDialog} />
          </ProtectedAction>
          <ProtectedAction module={ModuleKey.USERS} action={UsersAction.DELETE}>
            <DeleteAction onClick={() => deleteUserDeleteConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteUserDeleteConfirmation.render()}
    </>
  );
};
