import { jsx as _jsx } from "react/jsx-runtime";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormField } from "../../../hooks";
import { Switch } from "@mui/material";
import styles from "./river-switch.module.scss";
import clsx from "clsx";
const DEFAULT_LABEL_PLACEMENT = "start";
export const RiverSwitch = (props) => {
    const { label, checked, disabled, onChange } = useFormField(props);
    const labelPlacement = props.labelPlacement || DEFAULT_LABEL_PLACEMENT;
    return (_jsx(FormControlLabel, { labelPlacement: labelPlacement, control: _jsx(Switch, { className: styles.root, disableRipple: true, checked: checked, onChange: onChange, name: props.id }), label: label, classes: {
            root: clsx([styles.formControl, props.className]),
            label: styles.label,
        }, disabled: disabled }));
};
