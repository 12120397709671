import React, { ReactElement, useState } from "react";
import { DataDict } from "./data-dict";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

const ModuleWrapper: React.FC<ITranslationProps> = (
  props: ITranslationProps
): ReactElement => <>{props.tReady && <DataDict />}</>;

export const DataDictModule: React.FC = (props): ReactElement => {
  const namespaces: string[] = [
    "module.data_dictionary",
    "shared.grid_header",
    "shared.error_messages",
    "shared.user_menu",
    "common.button",
    "common.label",
    "shared.lookup",
    "shared.river_data_grid",
    "shared.river_color_picker",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
