import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IExecutionContext,
} from "../../context";
import { useContext, useEffect } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  ExecutionUiService,
  EXECUTION_MATERIAL_TABLE_SAVE_KEY,
} from "./execution-ui.service";
import { useTranslation } from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import { IExecutionAction } from "../../components/execution/execution";
import { MaterialRequirementsUiService } from "../material-requirements-ui.service";

export interface IUseExecutionMaterialRequirementsProps {
  executionContext: IExecutionContext;
}

export const useExecutionMaterialRequirements = (
  props: IUseExecutionMaterialRequirementsProps
): IUseEntityTable => {
  const { currentWorkOrder, setCurrentActions } = props.executionContext;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const entityHelpers = useEntityHelpers();
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const matReqUiService: MaterialRequirementsUiService =
    adapterContext?.service.getMaterialRequirementsUiService()!;
  const workOrderNumberColumn = matReqUiService.getWorkOrderNumberColumn();
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();

  const entityName: string =
    uiService.getExecutionTabMaterialRequirementsEntity();

  const materialRequirementActions: IExecutionAction[] = [
    {
      label: t("module.execution:dropdown.material_requirement_action_issue"),
      onClick: () => {
        console.log("Material Issue");
      },
    },
    {
      label: t("module.execution:dropdown.material_requirement_action_return"),
      onClick: () => {
        console.log("Material Return");
      },
    },
  ];

  useEffect(() => {
    if (currentTab === ExecutionTabId.MATERIAL) {
      setCurrentActions(materialRequirementActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const columns: IColumn[] = [
    ...uiService.getDefaultMaterialRequirementsColumns({
      materialRequirementActions,
    })(),
  ];

  const fetchMaterialRequirements = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrder) return [];
    const requirements: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .fetchMaterial(
        [currentWorkOrder![workOrderNumberColumn] as string],
        fetchHelpers.getTableQuery({ fetchProps })
      );

    requirements.forEach((mat) => {
      entityHelpers.setAttributeValue(
        mat,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(mat, "Activity") +
          "_" +
          entityHelpers.getAttributeValue(mat, "ItemNumber")
      );
    });

    return requirements;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: EXECUTION_MATERIAL_TABLE_SAVE_KEY,
    columns,
    fetchFunction: fetchMaterialRequirements,
    dependencies: [currentTab === ExecutionTabId.MATERIAL],
    fetchOn: currentTab === ExecutionTabId.MATERIAL,
    fetchTriggers: [currentWorkOrder],
    clearBeforeFetch: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
