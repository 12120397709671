import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IExecutionContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  ExecutionUiService,
  EXECUTION_DETAILS_TABLE_SAVE_KEY,
} from "./execution-ui.service";
import { IEntityObject, QueryDto } from "@river/interfaces";
import { IColumn } from "../../interfaces";
import { TableUiService } from "../table-ui.service";

interface IUseExecutionDetailsProps {
  executionContext: IExecutionContext;
}

export const useExecutionDetails = (
  props: IUseExecutionDetailsProps
): IUseEntityTable => {
  const { currentWorkOrder } = props.executionContext;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service.getExecutionUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();
  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];
  const entityHelpers = useEntityHelpers();
  const workOrderRowIdAttribute: string = tableUiService.getRowIdAttribute(
    uiConstants.rowType.workOrder
  );

  const entityName: string = "workorder";

  const columns: IColumn[] = uiService.getDefaultDetailsColumns()();
  const fetchWorkorders = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: {
          $and: [
            {
              attribute_name: "_id",
              attribute_value: {
                operator: "$eq",
                value: currentWorkOrder?._id,
              },
            },
          ],
        },
      }),
    };

    if (!currentWorkOrder) return [];

    const workorders: IEntityObject[] = await adapterContext?.service
      .getAdapterService()
      .fetchWorkorders(query)!;

    workorders.forEach((wo) => {
      try {
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(wo, workOrderRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing WorkOrder _id: ${wo._id}`);
        console.error(e);
      }
    });

    return workorders as IEntityObject[];
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: EXECUTION_DETAILS_TABLE_SAVE_KEY,
    columns,
    fetchFunction: fetchWorkorders,
    dependencies: [!!site, currentTab === ExecutionTabId.DETAILS],
    fetchOn: currentTab === ExecutionTabId.DETAILS,
    fetchTriggers: [site, currentWorkOrder],
    clearBeforeFetch: true,
    useAdvancedFilters: false,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
