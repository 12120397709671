import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { QueryAttributeDto } from "@river/interfaces";

export function useOracleEbsWoStatusLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:wo_status.title"),
      rowKey: "STATUS_ID",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "WORK_ORDER_STATUS",
            name: t("entity.wo_status:wo_status.WORK_ORDER_STATUS"),
          },
          {
            key: "SYSTEM_STATUS_DESC",
            name: t("entity.wo_status:wo_status.SYSTEM_STATUS_DESC"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          // const getInitialQueryAttributes = (): QueryAttributeDto[] => {
          //   return [
          //     {
          //       attribute_name: "STATUS_ID",
          //       attribute_value: {
          //         operator: "$nin",
          //         value: [4, 5],
          //       },
          //     },
          //   ];
          // };

          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "wo_status",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [...(customFilters || [])],
              })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "wo_status",
      },
    };
  };
}

export type IUseOracleEbsWoStatusLookupDef = ReturnType<
  typeof useOracleEbsWoStatusLookupDef
>;
