import React, { ReactElement, useContext, useState } from "react";
import { Schedule } from "./schedule";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { ScheduleUiService } from "../../services";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <Schedule />}</>
);

export const ScheduleModule: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext!.service.getScheduleUiService();
  const namespaces: string[] = uiService.getI18nNamespaces();

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
