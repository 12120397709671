import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface IOracleEbsWorkOrderUncomplete {
  USER_DEFINED_STATUS_ID?: number | null;
  userStatusName?: number | null;
}

export class OracleCloudWorkOrderUncomplete {
  constructor(obj: IOracleEbsWorkOrderUncomplete) {
    Object.assign(this, obj);
  }
}

export const useOracleEbsWoUncompleteForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, OracleCloudWorkOrderUncomplete>({
    standalone: {
      fields: new OracleCloudWorkOrderUncomplete({}),
      fieldDefs: [
        { fieldName: "USER_DEFINED_STATUS_ID", dataType: "number" },
        { fieldName: "userStatusName", dataType: "number" },
      ],
    },
    submit: props.submit,
    labels: {
      userStatusName: t("entity.workorder:workorder.WORK_ORDER_STATUS"),
    },
  });
};
