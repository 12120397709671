import React, { useContext } from "react";
import { Column, FormatterProps } from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { IEntityObject } from "@river/interfaces";
import { NotificationsHeader } from "./notifications-header";
import { NotificationsSubHeader } from "./notifications-subheader";
import { NotificationsGridHeader } from "./notifications-grid-header";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import {
  useEntity,
  IUseEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { fetchHelpers } from "../../helpers";
import { SapAdapterService } from "../../services/sap";
import { useTranslation } from "@river/common-ui";
import { commonModuleActions } from "../../hooks";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";

enum ModuleAction {
  CREATE = "CREATE_WORK_REQUEST",
  UPDATE = "UPDATE_WORK_REQUEST",
}

export type WorkRequestsAction =
  | ModuleAction
  | commonModuleActions.GlobalFilterAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkRequestsAction = {
  ...ModuleAction,
  ...commonModuleActions.GlobalFilterAction,
};

export const Notifications: React.FC = () => {
  usePageCleanup();

  const entityName: string = "notification";
  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity: IUseEntity = useEntity({ entityName });

  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  const openEntityInErp = async (record: IEntityObject, entityName: string) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, entityName);
  };

  const getNotificationNumberFormatter = (
    formatterProps: FormatterProps<any>
  ) => {
    const row: IEntityObject = formatterProps.row;
    const columnKey: string = formatterProps.column.key;
    const notificationNumber: string = row[columnKey] as string;
    return (
      <>
        {renderCell({
          formatterProps,
          content: (
            <div
              className={dataGridStyles.dataGridLink}
              onClick={() => openEntityInErp(row, "notification")}
            >
              {renderCellText({ formatterProps, value: notificationNumber })}
            </div>
          ),
        })}
      </>
    );
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "NotifNo",
      name: t("entity.notification:notification.NotifNo"),
      formatter: getNotificationNumberFormatter,
    },
    { key: "ShortText", name: t("entity.notification:notification.ShortText") },
    { key: "NotifType", name: t("entity.notification:notification.NotifType") },
    { key: "Orderid", name: t("entity.notification:notification.Orderid") },
    { key: "FunctLoc", name: t("entity.notification:notification.FunctLoc") },
    { key: "Equipment", name: t("entity.notification:notification.Equipment") },
    {
      key: "MainWorkCenter",
      name: t("entity.notification:notification.MainWorkCenter"),
    },
    { key: "Plangroup", name: t("entity.notification:notification.Plangroup") },
    { key: "Priority", name: t("entity.notification:notification.Priority") },
    {
      key: "SystemStatus",
      name: t("entity.notification:notification.SystemStatus"),
    },
    {
      key: "UserStatus",
      name: t("entity.notification:notification.UserStatus"),
    },
    { key: "Breakdown", name: t("entity.notification:notification.Breakdown") },
    {
      key: "Strmlfndate",
      name: t("entity.notification:notification.Strmlfndate"),
      width: 120,
    },
    {
      key: "Endmlfndate",
      name: t("entity.notification:notification.Endmlfndate"),
      width: 120,
    },
  ];

  const fetchNotifications = async (fetchProps: ITableFetchFunctionProps) => {
    return await (
      adapterContext!.service.getAdapterService() as SapAdapterService
    ).fetchNotifications(fetchHelpers.getTableQuery({ fetchProps }));
  };

  const table: IUseTable = useTable({
    entityName,
    columns,
    saveKey: "notifications",
    fetchFunction: fetchNotifications,
    dependencies: [!!site],
    fetchOn: true,
    fetchTriggers: [site],
    infiniteScrolling: true,
  });

  const getSelectedNotification = (): IEntityObject => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity["NotifNo"] as string)
    )[0];
    return selected as unknown as IEntityObject;
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <NotificationsHeader />
        <NotificationsSubHeader />
        <NotificationsGridHeader
          selectedNotification={getSelectedNotification()}
        />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
          rowKeyGetter={(row) => row["NotifNo"]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
        />
      </TableContext.Provider>
    </>
  );
};
