import React, { ReactElement } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { FormControl } from "@mui/material";
import {
  IUseValidation,
  IValidationErrors,
  RiverFormFieldError,
  useFormField,
  useValidation,
} from "@river/common-ui";
import { RiverFormInstance } from "../../../hooks";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface IRiverDateTimeInputProps {
  id: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

function isInvalidDate(date: Date): boolean {
  return !(date instanceof Date) || isNaN(date.getTime());
}

export const RiverDateTimeInput: React.FC<IRiverDateTimeInputProps> = (
  props
): ReactElement => {
  const {
    label,
    value,
    disabled,
    hasErrors,
    isDtoField,
    isStandaloneField,
    form,
  } = useFormField(props);
  const { setDto, validationErrors, setValidationErrors } =
    form as RiverFormInstance;

  const dto: any = form!.dto as any;
  const { id, className, fullWidth } = props;
  const validation: IUseValidation = useValidation();

  const onChange = async (value: any) => {
    const newValue: Date | null = value || null;
    if (newValue && isInvalidDate(value?.toDate())) return;

    if (isDtoField) {
      const newDto = {
        ...dto,
        [id]: value?.toDate() || null,
      };
      Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
      setDto(newDto);
      const newErrors: IValidationErrors = await validation.validateProperty(
        newDto as unknown as object,
        id,
        validationErrors
      );
      setValidationErrors(newErrors);
    } else if (isStandaloneField) {
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={className}
      error={hasErrors}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <DateTimePicker
        label={label}
        disabled={disabled}
        onChange={onChange}
        value={value ? dayjs(value) : null}
        timezone={"UTC"}
        closeOnSelect={false}
        slotProps={{
          actionBar: {
            actions: ["today", "clear", "accept"],
          },
        }}
      />
      <RiverFormFieldError id={id} />
    </FormControl>
  );
};
