import React, { ReactElement, useState } from "react";
import { Notifications } from "./notifications";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

const ModuleWrapper: React.FC<ITranslationProps> = (
  props: ITranslationProps
): ReactElement => <>{props.tReady && <Notifications />}</>;

export const NotificationsModule: React.FC = (props): ReactElement => {
  const namespaces: string[] = [
    "entity.planning_plant",
    "module.notifications",
    "shared.planning_plant_selector",
    "shared.user_menu",
    "shared.grid_header",
    "shared.column_selector",
    "shared.advanced_filters",
    "shared.saved_filters_dialog",
    "shared.save_filters_dialog",
    "shared.error_messages",
    "shared.lookup",
    "shared.river_table_selector",
    "common.button",
    "common.label",
    "entity.notification",
    "entity.equipment",
    "entity.planner_group",
    "entity.priority",
    "entity.workcenter",
    "shared.river_data_grid",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
