import { TFunction } from "i18next";
import { Column } from "react-data-grid";
import {
  AvailabilityUiService,
  IRenderCreateResourcesDialogProps,
} from "../availability-ui.service";
import { GenericAvatarIcon } from "../../icons";
import { useTableCellRenderers } from "../../hooks";
import availabilityStyles from "../../components/availability/availability.module.scss";
import clsx from "clsx";

export class OracleEbsAvailabilityUiService extends AvailabilityUiService {
  getAvailabilityResourcesColumns = (t: TFunction): Column<any>[] => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return [
      {
        key: "INSTANCE_NAME",
        name: t("entity.availability_header:availability_header.INSTANCE_NAME"),
        width: 190,
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={clsx([availabilityStyles.personCell])}>
                  <GenericAvatarIcon />
                  <div>{renderCellText({ formatterProps })}</div>
                </div>
              ),
            })}
          </>
        ),
      },
      {
        key: "SERIAL_NUMBER",
        name: t("entity.availability_header:availability_header.SERIAL_NUMBER"),
        width: 160,
      },
      {
        key: "Resources.DEPARTMENT_CODE",
        name: t("entity.availability_header:resource_ref.DEPARTMENT_CODE"),
        width: 160,
      },
      {
        key: "Resources.RESOURCE_CODE",
        name: t("entity.availability_header:resource_ref.RESOURCE_CODE"),
        width: 160,
      },
      {
        key: "crews.crew",
        name: t("entity.availability_header:availability_header.crews"),
        width: 160,
      },
      {
        key: "calendar",
        name: t("entity.availability_header:availability_header.calendar"),
        width: 120,
      },
      {
        key: "calendar_start_date",
        name: t(
          "entity.availability_header:availability_header.calendar_start_date"
        ),
        width: 140,
      },
      {
        key: "calendar_end_date",
        name: t(
          "entity.availability_header:availability_header.calendar_end_date"
        ),
        width: 140,
      },
    ];
  };

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.organization"];
  }

  getEditAvailabilityHeaderKey(): string {
    return "INSTANCE_NAME";
  }

  renderCreateResourcesDialog(props: IRenderCreateResourcesDialogProps) {
    return <></>;
  }
}
