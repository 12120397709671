import React, {
  MouseEvent,
  ReactElement,
  RefObject,
  useContext,
  useRef,
} from "react";
import { ScheduleContext } from "../../../../../context";
import { ScheduleAction } from "../../../../../services";
import { useAllowedAction } from "../../../../protected-action";
import { ModuleKey } from "../../../../sidebar-menu";
import { DEFAULT_HEADER_ROW_HEIGHT } from "../../../../shared";
import { RelationType } from "../../schedule-gantt-overlay";
import styles from "./gantt-connector.module.scss";
import clsx from "clsx";

export enum GanttConnectorPosition {
  LEFT = "left",
  RIGHT = "right",
}

interface IGanttConnectorProps {
  taskXPercent: number;
  taskWidthPercent: number;
  position: GanttConnectorPosition;
  taskRef: RefObject<HTMLDivElement>;
  row: any;
}

const positionToCssClassMap: { [key in GanttConnectorPosition]: string } = {
  [GanttConnectorPosition.LEFT]: styles.leftConnector,
  [GanttConnectorPosition.RIGHT]: styles.rightConnector,
};

export const GanttConnector: React.FC<IGanttConnectorProps> = (
  props
): ReactElement => {
  const { taskXPercent, taskWidthPercent, position, taskRef } = props;
  const scheduleContext = useContext(ScheduleContext);
  const operationsOverlay = scheduleContext?.operationsOverlayRef.current;
  const isScheduleActionAllowed = useAllowedAction()(
    ModuleKey.SCHEDULES,
    ScheduleAction.SCHEDULE
  );
  const ganttContainerRectRef = useRef<DOMRect>();

  function getGanttXPercentFromMouseEvent(
    event: globalThis.MouseEvent | MouseEvent
  ): number {
    const { left: ganttXPos, width: ganttWidth } =
      ganttContainerRectRef.current!;
    const { clientX: eventXPos } = event;
    return ((eventXPos - ganttXPos) / ganttWidth) * 100;
  }

  const getYOffsetFromMouseEvent = (
    event: globalThis.MouseEvent | MouseEvent,
    taskEl: HTMLDivElement
  ): number => {
    let { top: gridTop } =
      taskEl.parentElement!.parentElement!.parentElement!.parentElement!.parentElement!.getBoundingClientRect();
    const yOffset: number =
      event.clientY - (gridTop + DEFAULT_HEADER_ROW_HEIGHT);
    return yOffset;
  };

  const onDragVirtualRelation = (event: globalThis.MouseEvent) => {
    const scrollOffset: number =
      scheduleContext?.ganttOverlayScrollAreaRef!.current?.scrollTop || 0;
    const xPercent: number = getGanttXPercentFromMouseEvent(event);
    const yPos =
      Math.max(getYOffsetFromMouseEvent(event, taskRef.current!), 0) +
      scrollOffset;
    operationsOverlay?.setDraggedObject({
      xPercent,
      yPos,
      virtual: true,
    });
  };

  const onStopDragVirtualRelation = () => {
    operationsOverlay?.removeVirtualRelation();
    document.onmousemove = null;
    document.onmouseup = null;
    ganttContainerRectRef.current = undefined;
  };

  const onLeftConnectorMouseUp = () => {
    const operationsOverlay = scheduleContext?.operationsOverlayRef.current;
    operationsOverlay?.createDependencyFromVirtualRelation(props.row, "start");
  };

  const onRightConnectorMouseUp = () => {
    const operationsOverlay = scheduleContext?.operationsOverlayRef.current;
    operationsOverlay?.createDependencyFromVirtualRelation(props.row, "end");
  };

  const onMouseDown = (): void => {
    const taskEl = taskRef.current!;
    ganttContainerRectRef.current =
      taskEl!.parentElement!.getBoundingClientRect();

    const relationType: RelationType =
      position === GanttConnectorPosition.LEFT
        ? RelationType.START_TO_START
        : RelationType.FINISH_TO_START;
    operationsOverlay?.addVirtualRelation(props.row, relationType);

    document.onmousemove = onDragVirtualRelation;
    document.onmouseup = onStopDragVirtualRelation;
  };

  const onMouseUp = (): void => {
    position === GanttConnectorPosition.LEFT
      ? onLeftConnectorMouseUp()
      : onRightConnectorMouseUp();
  };

  const shouldRender: boolean =
    isScheduleActionAllowed &&
    (position === GanttConnectorPosition.LEFT
      ? operationsOverlay!.hasLeftConnector(props.row)
      : operationsOverlay!.hasRightConnector(props.row));

  const leftCSS: string =
    position === GanttConnectorPosition.LEFT
      ? `calc(${taskXPercent}% - 12px)`
      : `calc(${taskXPercent + taskWidthPercent}% - 6px)`;

  return (
    <>
      {shouldRender && (
        <div
          className={clsx([styles.root, positionToCssClassMap[position]])}
          style={{
            left: leftCSS,
          }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          <div className={clsx([styles.circle])} />
        </div>
      )}
    </>
  );
};
