import React, { ReactElement, useContext, useState } from "react";
import { IEntityObject, QueryAttributeDto } from "@river/interfaces";
import {
  StartToStartRelationRenderer,
  StartToFinishRelationRenderer,
  FinishToStartRelationRenderer,
  FinishToFinishRelationRenderer,
} from "./renderers";
import {
  IOperationPosition,
  IUseScheduleOperationsOverlay,
  IDraggedObject,
} from "./use-schedule-operations-overlay";
import {
  DependencyDialog,
  IOperationDependency,
} from "../../../dependency-dialog";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TableContext,
} from "../../../../../context";
import { fetchHelpers } from "../../../../../helpers";
import { TaskHelpersUiService } from "../../../../../services";
import { getFolderQueryGroup } from "@river/util";
import { IUseScheduleGantt } from "../../use-schedule-gantt";
import styles from "./schedule-operations-gantt-overlay.module.scss";

export enum RelationType {
  START_TO_START = "SS",
  START_TO_FINISH = "SF",
  FINISH_TO_START = "FS",
  FINISH_TO_FINISH = "FF",
}

export interface IRelationRendererProps {
  relation: IEntityObject;
  predecessor: IEntityObject | undefined;
  successor: IEntityObject | undefined;
  predecessorPos: IOperationPosition | undefined;
  successorPos: IOperationPosition | undefined;
  editRelation: () => void;
}

export type RelationRenderer = (
  overlayProps: IRelationRendererProps
) => ReactElement;

interface IScheduleOperationsGanttOverlayProps {
  overlay: IUseScheduleOperationsOverlay;
}

export const ScheduleOperationsGanttOverlay: React.FC<
  IScheduleOperationsGanttOverlayProps
> = (props): ReactElement => {
  const overlay: IUseScheduleOperationsOverlay = props.overlay;
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const [dependencyToEdit, setDependencyToEdit] =
    useState<IOperationDependency>();
  const [dependencyDialogOpened, setDependencyDialogOpened] =
    useState<boolean>(false);

  const gantt: IUseScheduleGantt = scheduleContext!.ganttRef.current!;
  const { MIN_SCHEDULE_TASK_WIDTH: MIN_SCHEDULE_TASK_WIDTH_PERCENT } =
    gantt.ganttRatios!;

  // Adjust operation position when dragging and for minimum task width
  const adjustPosition = (
    operation: IEntityObject,
    pos: IOperationPosition
  ): IOperationPosition => {
    let newPosition: IOperationPosition = { ...pos };
    const draggedObject: IDraggedObject = props.overlay.draggedObject!;
    const isDragged = (op: IEntityObject): boolean => {
      const draggedOperation: IEntityObject =
        draggedObject?.object as IEntityObject;
      return (
        draggedOperation && props.overlay.operationsEqual(draggedOperation, op)
      );
    };

    if (isDragged(operation)) {
      newPosition = {
        ...pos,
        startXPercent: draggedObject.xPercent!,
        endXPercent:
          draggedObject.xPercent! + (pos.endXPercent - pos.startXPercent),
      };
    }

    // Adjust minimum width
    newPosition = {
      ...newPosition,
      endXPercent:
        newPosition.endXPercent - newPosition.startXPercent >=
        MIN_SCHEDULE_TASK_WIDTH_PERCENT
          ? newPosition.endXPercent
          : newPosition.startXPercent + MIN_SCHEDULE_TASK_WIDTH_PERCENT,
    };

    return newPosition;
  };

  const renderRelation = (
    relation: IEntityObject,
    index: number
  ): ReactElement => {
    let predecessor: IEntityObject | undefined;
    let predecessorPos: IOperationPosition | undefined;
    let successor: IEntityObject | undefined;
    let successorPos: IOperationPosition | undefined;

    const {
      OrderPredecessorProp,
      OrderSuccessorProp,
      OperationPredecessorProp,
      OperationSuccessorProp,
      RelationTypeProp,
    } = getOperationsOverlayPropertyNames();

    predecessor = overlay.getOperationByOrderIdAndActivity(
      relation[OrderPredecessorProp] as string,
      relation[OperationPredecessorProp] as string
    );
    if (predecessor) {
      predecessorPos = {
        startXPercent: overlay.getStartXPercent(predecessor),
        endXPercent: overlay.getEndXPercent(predecessor),
        yPos: overlay.getYPos(predecessor),
      };
      predecessorPos = adjustPosition(predecessor, predecessorPos);
    }

    if (relation[OperationSuccessorProp] === "virtual") {
      let draggedObject: IDraggedObject = props.overlay.draggedObject!;
      successorPos = {
        startXPercent: draggedObject.xPercent!,
        endXPercent: draggedObject.xPercent!,
        yPos: draggedObject.yPos!,
      };
    } else {
      successor = overlay.getOperationByOrderIdAndActivity(
        relation[OrderSuccessorProp] as string,
        relation[OperationSuccessorProp] as string
      );
      if (successor) {
        successorPos = {
          startXPercent: overlay.getStartXPercent(successor),
          endXPercent: overlay.getEndXPercent(successor),
          yPos: overlay.getYPos(successor),
        };
        successorPos = adjustPosition(successor, successorPos);
      }
    }

    const relationType: RelationType = relation[
      RelationTypeProp
    ] as RelationType;

    const CurrentRelationRenderer: RelationRenderer = {
      [RelationType.START_TO_START]: StartToStartRelationRenderer,
      [RelationType.START_TO_FINISH]: StartToFinishRelationRenderer,
      [RelationType.FINISH_TO_START]: FinishToStartRelationRenderer,
      [RelationType.FINISH_TO_FINISH]: FinishToFinishRelationRenderer,
    }[relationType];

    const rendererProps: IRelationRendererProps = {
      relation,
      predecessor,
      predecessorPos,
      successor,
      successorPos,
      editRelation: (): void => {
        openRelationDialog({
          predecessor: predecessor!,
          successor: successor!,
          relation,
        });
      },
    };

    return (
      <>
        {(predecessor || successor) && (
          <CurrentRelationRenderer {...rendererProps} key={index} />
        )}
      </>
    );
  };

  const fetchOperation = async (
    operationOrderId: string,
    operationActivity: string
  ): Promise<any> => {
    const getInitialQueryAttributes = (): QueryAttributeDto[] => {
      return [
        {
          attribute_name: "Orderid",
          attribute_value: {
            operator: "$eq",
            value: operationOrderId,
          },
        },
        {
          attribute_name: "Activity",
          attribute_value: {
            operator: "$eq",
            value: operationActivity,
          },
        },
      ];
    };

    const results: any[] = await adapterContext!.service
      .getAdapterService()
      .fetchOperations({
        ...fetchHelpers.getTableQuery({
          fetchProps: {
            columnFilters: [],
            sortColumns: [],
            query: getFolderQueryGroup(scheduleContext!.currentSchedule!, ""),
          },
          initialQueryAttributes: getInitialQueryAttributes(),
        }),
        $unwind: ["__folder"],
      });
    return results[0] as IEntityObject;
  };

  const openRelationDialog = async (
    operationDependency: IOperationDependency
  ): Promise<void> => {
    const dependency: IOperationDependency = { ...operationDependency };
    if (!dependency?.predecessor || !dependency.successor) {
      const relation: IEntityObject = dependency?.relation!;
      const {
        OrderPredecessorProp,
        OrderSuccessorProp,
        OperationPredecessorProp,
        OperationSuccessorProp,
      } = getOperationsOverlayPropertyNames();
      try {
        tableContext?.table.forceLoadingState(true);
        if (!dependency?.predecessor) {
          dependency.predecessor = await fetchOperation(
            String(relation[OrderPredecessorProp]),
            String(relation[OperationPredecessorProp])
          );
        } else {
          dependency.successor = await fetchOperation(
            String(relation[OrderSuccessorProp]),
            String(relation[OperationSuccessorProp])
          );
        }
        setDependencyToEdit(dependency);
        setDependencyDialogOpened(true);
      } catch (message) {
        notify.error({ message });
      } finally {
        tableContext?.table.forceLoadingState(false);
      }
    } else {
      setDependencyToEdit(dependency);
      setDependencyDialogOpened(true);
    }
  };

  return (
    <div className={styles.root}>
      {overlay.relations.map((relation, index) => (
        <div key={relation["_id"] as string}>
          {renderRelation(relation, index)}
        </div>
      ))}
      <DependencyDialog
        open={dependencyDialogOpened}
        onClose={() => {
          setDependencyDialogOpened(false);
        }}
        dependency={dependencyToEdit}
      />
    </div>
  );
};
