import React, { ReactElement, useContext } from "react";
import {
  GridHeader,
  ColumnSelectorDropdown,
  GridActions,
  FilterDropdown,
  ReportsDropdown,
} from "../../shared";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { BacklogUiService } from "../../../services";

export const BacklogGridHeader: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;

  return (
    <GridHeader>
      <GridActions>
        <ColumnSelectorDropdown />
        <FilterDropdown
          dynamicFilterActions={uiService.getDynamicFilterActionsForCurrentTab()()}
        />
        {uiService.renderGridActions()()}
        <ReportsDropdown />
      </GridActions>
    </GridHeader>
  );
};
