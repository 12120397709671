import { useContext, useState } from "react";
import { IRelationRendererProps } from "../../schedule-operations-gantt-overlay";
import {
  ganttAbsoluteConstants,
  IRelationConnectorEvents,
  IVerticalLine,
  IHorizontalLine,
} from "../../render-utils";
import { MouseEventHandler } from "react";
import { IOperationPosition } from "../../use-schedule-operations-overlay";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
} from "../../../../../../../context";
import { TaskHelpersUiService } from "../../../../../../../services";
import { IUseScheduleGantt } from "../../../../use-schedule-gantt";

export interface IUseFinishToStart extends IRelationConnectorEvents {
  predecessorFinish?: IHorizontalLine;
  vertical?: {
    straight: IVerticalLine;
    curved: {
      top: IVerticalLine;
      middle: IHorizontalLine;
      bottom: IVerticalLine;
    };
  };
  successorStart?: IHorizontalLine;
  isVerticalConnectorStraight?: () => boolean;
  selected: boolean;
}

const VIRTUAL_Y_OFFSET: number = -4;

export const useFinishToStart = (
  props: IRelationRendererProps
): IUseFinishToStart => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();
  const { OperationSuccessorProp } = getOperationsOverlayPropertyNames();

  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt = scheduleContext!.ganttRef.current!;
  const {
    MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH:
      MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT,
    MIN_HORIZONTAL_CONNECTOR_LENGTH: MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
    FINISH_TO_START_VIRTUAL_X_OFFSET: VIRTUAL_X_OFFSET_PERCENT,
  } = gantt.ganttRatios!;
  const { OUTWARD_CONNECTOR_Y_OFFSET, INWARD_CONNECTOR_Y_OFFSET } =
    ganttAbsoluteConstants;

  const [selected, setSelected] = useState<boolean>(false);

  const onConnectorMouseOver: MouseEventHandler = (event): void => {
    setSelected(true);
  };

  const onConnectorMouseOut: MouseEventHandler = (event): void => {
    setSelected(false);
  };

  const onConnectorClick: MouseEventHandler = (event): void => {
    event.stopPropagation();
    props.editRelation();
  };

  let hookValue: IUseFinishToStart = {
    selected,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const isVirtual: boolean =
    props.relation[OperationSuccessorProp] === "virtual";

  if (!isVirtual && (!props.predecessor || !props.successor)) {
    let line: IHorizontalLine;
    let pos: IOperationPosition;
    if (props.predecessor) {
      pos = props.predecessorPos!;
      line = {
        yPos: pos.yPos,
        startXPercent: pos.endXPercent,
        endXPercent: pos.endXPercent + MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
      };
    } else {
      pos = props.successorPos!;
      line = {
        yPos: pos.yPos,
        startXPercent:
          pos.startXPercent - MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
        endXPercent: pos.startXPercent,
      };
    }

    const lineProp: string = props.predecessor
      ? "predecessorFinish"
      : "successorStart";
    hookValue = {
      ...hookValue,
      [lineProp]: line,
    };
  } else {
    // predecessor horizontal finish connector position
    const preRightStartXPercent: number = props.predecessorPos!.endXPercent;
    const preRightEndXPercent: number =
      preRightStartXPercent + MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT;

    const preY: number =
      props.predecessorPos!.yPos + OUTWARD_CONNECTOR_Y_OFFSET;

    const isVerticalConnectorStraight = (): boolean =>
      !isVirtual
        ? preRightEndXPercent <=
          props.successorPos!.startXPercent -
            MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT
        : preRightEndXPercent + VIRTUAL_X_OFFSET_PERCENT <=
          props.successorPos!.startXPercent -
            MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT;

    // successor horizontal start connector position
    const succLeftStartXPercent: number = isVerticalConnectorStraight()
      ? preRightEndXPercent
      : props.successorPos!.startXPercent -
        MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT;
    const succLeftEndXPercent: number = props.successorPos!.startXPercent;
    const succY: number = props.successorPos!.yPos + INWARD_CONNECTOR_Y_OFFSET;

    const existingRelation: IUseFinishToStart = {
      ...hookValue,
      predecessorFinish: {
        yPos: preY,
        startXPercent: preRightStartXPercent,
        endXPercent: preRightEndXPercent,
        startXAbsoluteOffset: -1,
      },
      vertical: {
        straight: {
          yStart: preY + 1,
          yEnd: succY,
          xPercent: preRightEndXPercent,
        },
        curved: {
          top: {
            yStart: preY + 1,
            yEnd: preY + (succY - preY) / 2,
            xPercent: preRightEndXPercent,
          },
          middle: {
            yPos: preY + (succY - preY) / 2,
            startXPercent: succLeftStartXPercent,
            endXPercent: preRightEndXPercent,
          },
          bottom: {
            yStart: preY + (succY - preY) / 2,
            yEnd: succY,
            xPercent: succLeftStartXPercent,
          },
        },
      },
      successorStart: {
        yPos: succY,
        startXPercent: succLeftStartXPercent,
        endXPercent: succLeftEndXPercent,
        endXAbsoluteOffset: -1,
      },
      isVerticalConnectorStraight,
    };

    const virtualRelation: IUseFinishToStart = {
      ...hookValue,
      predecessorFinish: {
        yPos: preY + VIRTUAL_Y_OFFSET,
        startXPercent: preRightStartXPercent + VIRTUAL_X_OFFSET_PERCENT,
        endXPercent: preRightEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
      },
      vertical: {
        straight: {
          yStart: preY + 1 + VIRTUAL_Y_OFFSET,
          yEnd: succY,
          xPercent: preRightEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
        },
        curved: {
          top: {
            yStart: preY + VIRTUAL_Y_OFFSET,
            yEnd: preY + (succY - preY) / 2,
            xPercent: preRightEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
          },
          middle: {
            yPos: preY + (succY - preY) / 2,
            startXPercent: succLeftStartXPercent - VIRTUAL_X_OFFSET_PERCENT,
            endXPercent: preRightEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
          },
          bottom: {
            yStart: preY + (succY - preY) / 2,
            yEnd: succY,
            xPercent: succLeftStartXPercent - VIRTUAL_X_OFFSET_PERCENT,
          },
        },
      },
      successorStart: {
        yPos: succY,
        startXPercent: isVerticalConnectorStraight()
          ? succLeftStartXPercent + VIRTUAL_X_OFFSET_PERCENT
          : succLeftStartXPercent - VIRTUAL_X_OFFSET_PERCENT,
        endXPercent: succLeftEndXPercent - VIRTUAL_X_OFFSET_PERCENT,
      },
      isVerticalConnectorStraight,
    };

    hookValue = isVirtual ? virtualRelation : existingRelation;
  }

  return hookValue;
};
