import React, { ReactElement } from "react";
import { IUseEnv, useEnv } from "@river/common-ui";
import { ColumnSelectorAction } from "../../../common-header";
import {
  GridHeader,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
} from "../../../grid-header";
import styles from "./lookup-grid-header.module.scss";
import clsx from "clsx";

interface ILookupGridHeader {
  className?: string;
}

export const LookupGridHeader: React.FC<ILookupGridHeader> = (
  props
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  return (
    <GridHeader className={clsx([styles.root, { [styles.mobile]: isMobile }])}>
      <GridActions className={styles.entityActions}>
        {!isMobile && <ColumnSelectorDropdown />}
        <FilterDropdown />
        {isMobile && (
          <ColumnSelectorAction className={styles.columnManagerIcon} />
        )}
      </GridActions>
    </GridHeader>
  );
};
