import { ICustomerInfo, ILanguage } from "@river/interfaces";
import { createContext } from "react";

export interface IApplication {
  languages: ILanguage[];
  reloadLanguages: () => void;
  customerInfo: ICustomerInfo | undefined;
  applicationVersion: string;
}

export const ApplicationContext = createContext<IApplication | null>(null);
