import { QueryAttributeDto } from "@river/interfaces";
import {
  ICraftSelectorProps,
  IGetProgressFiltersProps,
  ProgressSummaryFilterID,
  QueryProvider,
  ReactElementProvider,
  ScheduleReportUiService,
} from "..";
import TuneIcon from "@mui/icons-material/Tune";
import { OracleEbsScheduleReportProgressFilters } from "./oracle-ebs-schedule-report-utilization-ui-service/oracle-ebs-schedule-progress-filters";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useContext } from "react";
import { GenericAction } from "../../components/shared";
import { ScheduleReportContext } from "../../context";
import { OracleEbsCraftSelector } from "./oracle-ebs-shared/oracle-ebs-craft-selector";
import { IOracleEbsScheduleProgressFiltersDto } from "./oracle-ebs-schedule-utilization-ui-service";

export class OracleEbsScheduleReportUiService extends ScheduleReportUiService {
  renderProgressFilters(): ReactElementProvider {
    return () => <OracleEbsScheduleReportProgressFilters />;
  }

  getProgressFilters(props: IGetProgressFiltersProps): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];
      //@ts-ignore
      let woTypeFilters: any[] =
        (props.progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.WO_TYPES
        ] || [];
      //@ts-ignore
      let departmentFilters: any[] =
        (props.progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.DEPARTMENTS
        ] || [];
      //@ts-ignore
      let resourceFilters: any[] =
        (props.progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.RESOURCES
        ] || [];
      if (!!woTypeFilters.length) {
        queryAttributes.push({
          attribute_name: "WORK_ORDER_TYPE",
          attribute_value: {
            operator: "$in",
            value: woTypeFilters.map((wo_type) => wo_type.LOOKUP_CODE),
          },
        });
      }

      if (!!departmentFilters.length) {
        queryAttributes.push({
          attribute_name: "DEPARTMENT_ID",
          attribute_value: {
            operator: "$in",
            value: departmentFilters.map(
              (department) => department.DEPARTMENT_ID
            ),
          },
        });
      }

      if (!!resourceFilters.length) {
        queryAttributes.push({
          attribute_name: "RESOURCE_ID",
          attribute_value: {
            operator: "$in",
            value: resourceFilters.map((resource) => resource.RESOURCE_ID),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  getErpSpecificI18nNamespaces() {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.department_resource",
      "entity.resource_utilization",
      "entity.resource_ref",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.operation_resource",
      "entity.operation_resource_instance",
      "entity.crew",
      "entity.instance",
      "entity.operation_workorder",
      "entity.person_utilization",
      "entity.wo_priority",
      "entity.wo_shutdown_type",
      "entity.wo_planner",
      "entity.wo_operation_inventory_item",
      "entity.wo_operation_direct_item",
      "entity.department",
    ];
  }

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <OracleEbsCraftSelector {...props} />
  );

  getCraftLabelPropertyValue(props: any) {
    return `${props.DEPARTMENT_CODE} / ${props.RESOURCE_CODE}`;
  }

  getProgressHeaderActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const scheduleReportContext = useContext(ScheduleReportContext);
      const { progressFiltersOpened, setProgressFiltersOpened } =
        scheduleReportContext!;
      return () => (
        <GenericAction
          icon={TuneIcon}
          title={t("module.schedule_report:progress_filters_toggle")}
          onClick={() => {
            setProgressFiltersOpened(!progressFiltersOpened);
          }}
        />
      );
    };
  }
}
