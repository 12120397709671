import { ReactElement } from "react";
import {
  IRenderTimecardDialogProps,
  SupervisorTimeCardUiService,
} from "../../supervisor-timecard-ui.service";
import { SapSupervisorOperationTimeCardDialog } from "./sap-supervisor-operation-timecard-dialog";

export class SapSupervisorTimeCardUiService extends SupervisorTimeCardUiService {
  renderOperationTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <SapSupervisorOperationTimeCardDialog {...props} />;

  renderAssignmentTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <SapSupervisorOperationTimeCardDialog {...props} />;

  renderResourceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderInstanceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;
}
