import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IBacklogContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { AdapterService } from "../adapter.service";
import { BacklogTabId, BacklogUiService } from "./index";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import { IAdapterFolder, QueryDto } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { IColumn } from "../../interfaces";
import { getFolderQueryGroup } from "@river/util";

interface IUseBacklogAssignmentsProps {
  backlogContext: IBacklogContext;
}

export const useBacklogAssignments = (
  props: IUseBacklogAssignmentsProps
): IUseEntityTable => {
  const assignmentsTabId: BacklogTabId = BacklogTabId.ASSIGNMENTS;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const backlogUiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const backlogContext: IBacklogContext = props.backlogContext;
  const currentSchedule: IAdapterFolder = backlogContext.currentSchedule!;
  const currentTab: BacklogTabId = backlogUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];
  const { t } = useTranslation();
  const entityHelpers = useEntityHelpers();

  const defaultAssignmentColumns: IColumn[] =
    backlogUiService.getDefaultAssignmentColumns()();

  const fetchAssignments = async (fetchProps: ITableFetchFunctionProps) => {
    //
    const query: QueryDto = fetchHelpers.getTableQuery({
      fetchProps,
      ...(currentSchedule && {
        initialQueryAttributeGroup: getFolderQueryGroup(currentSchedule, ""),
      }),
    });

    table.setLastRanQueryDto(query);

    const assignments = await adapterService.fetchAssignments(query);
    const assignmentRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.assignment
    );

    assignments.forEach((as) => {
      try {
        entityHelpers.setAttributeValue(
          as,
          uiConstants.fields.rowType,
          uiConstants.rowType.assignment
        );
        entityHelpers.setAttributeValue(
          as,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(as, assignmentRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing WorkOrder Assignment _id: ${as._id}`);
        console.error(e);
      }
    });

    return assignments;
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "operation",
      columns: defaultAssignmentColumns,
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    entityName,
    saveKey: `backlog.assignments`,
    columns,
    fetchFunction: fetchAssignments,
    dependencies: [!!site],
    fetchOn: currentTab === assignmentsTabId,
    initialSimpleFilters:
      backlogUiService.getInitialTabSimpleFilters(assignmentsTabId),
    initialQuery: backlogUiService.getInitialTabQuery(assignmentsTabId),
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    keepSelection: true,
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      backlogUiService.initialFiltersOverrides[assignmentsTabId] = null;
      backlogUiService.initialQueryOverrides[assignmentsTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
