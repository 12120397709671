import { RiverDialog, RiverSpinner, RiverTextInput } from "@river/common-ui";
import {
  AdapterBaselineDto,
  IAdapterShift,
  QueryAttributeDto,
  QueryAttributeSortDto,
  QueryDto,
} from "@river/interfaces";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ScheduleContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../context";
import { RiverFormInstance } from "../../../hooks";
import { useBaselineForm } from "./use-baseline-form";
import { RiverColorPicker } from "../../shared/river-color-picker";

import styles from "./baseline-dialog.module.scss";
import { useTranslation } from "@river/common-ui";

interface IBaselineDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate?: () => void;
}

export const BaselineDialog: React.FC<IBaselineDialogProps> = (props) => {
  const { t } = useTranslation();
  const scheduleContext = useContext(ScheduleContext);
  const [retrievedBaselineName, setRetrievedBaselineName] = useState<
    string | null
  >(null);

  const form: RiverFormInstance = useBaselineForm({
    retrievedBaselineName: retrievedBaselineName!,
    schedule: scheduleContext?.currentSchedule!,
    onCreate: () => {
      closeDialog();
      props.onCreate?.();
    },
  });
  const baselineDto = form.dto as AdapterBaselineDto;
  const standalone: any = form.standalone as any;

  const {
    validateStandaloneField,
    validationErrors,
    onStandalonePropertyChange,
    setDto,
    create,
    resetForm,
    isProcessing,
  } = form;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    validateStandaloneField("baseline");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedBaselineName]);

  const baselineQuery = (baselineValue: string): QueryDto => {
    const attributes: QueryAttributeDto[] = [];
    const sort: QueryAttributeSortDto[] = [];
    attributes.push(
      {
        attribute_name: "folder_id",
        attribute_value: {
          operator: "$eq",
          value: scheduleContext?.currentSchedule?._id,
        },
      },
      {
        attribute_name: "baseline",
        attribute_value: {
          operator: "$eq",
          value: baselineValue,
        },
      }
    );

    return {
      query: {
        $and: attributes,
      },
      sort,
    };
  };

  const fetchBaselinesByName = async (
    inputBaselineName: string
  ): Promise<void> => {
    if (inputBaselineName !== "") {
      const result: IAdapterShift[] = await adapterContext!.service
        .getAdapterService()
        .fetchFolderBaselines(
          scheduleContext?.currentSchedule?._id as string,
          baselineQuery(inputBaselineName)
        );
      if (result.length > 0) {
        setRetrievedBaselineName(inputBaselineName);
      } else {
        setRetrievedBaselineName(null);
      }
    } else {
      setRetrievedBaselineName(null);
      validateStandaloneField("baseline", inputBaselineName);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchBaselinesByName = useCallback(
    debounce((text) => {
      fetchBaselinesByName(text);
    }, 600),
    [validationErrors]
  );

  const closeDialog = (): void => {
    resetDialogState();
    props.onClose();
  };

  const resetDialogState = (): void => {
    resetForm();
    setRetrievedBaselineName("");
  };

  useEffect(() => {
    const newState: AdapterBaselineDto = Object.assign(
      new AdapterBaselineDto(),
      {
        ...baselineDto,
        baseline: standalone.baseline,
      }
    );
    setDto(newState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standalone.baseline]);

  const renderBaselineField = (): ReactElement => (
    <RiverTextInput
      id={"baseline"}
      label={t("entity.baseline:baseline.baseline")}
      fullWidth
      value={standalone.baseline}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchBaselinesByName(event.target.value);
      }}
      validationErrors={validationErrors?.fields["baseline"]}
    />
  );

  const renderBaselineColorPicker = (): ReactElement => {
    return (
      <RiverColorPicker
        title={t("module.schedule:baseline_dialog.label.baseline_color")}
        dialogTitle={t(
          "module.schedule:baseline_dialog.color_picker.dialog_title"
        )}
        initialColor={baselineDto.display_options!}
        onSelectColor={(selectedColor: string) => {
          const newState: AdapterBaselineDto = Object.assign(
            new AdapterBaselineDto(),
            {
              ...baselineDto,
              display_options: selectedColor,
            }
          );
          setDto(newState);
        }}
        showCopyButton={false}
      />
    );
  };

  return (
    <RiverDialog
      title={t("module.schedule:baseline_dialog.title")}
      open={props.open}
      onClose={closeDialog}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      actionButtonText={t("common.button:create")}
      onSubmit={create}
    >
      <RiverSpinner show={isProcessing} />
      {renderBaselineField()}
      {renderBaselineColorPicker()}
    </RiverDialog>
  );
};
