import React, { useState, ReactElement, useContext } from "react";
import { ColumnsSelector } from "../index";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import { TableContext } from "../../../context";
import { ColumnLayoutDropdown } from "./column-layout-dropdown";
import { useTranslation } from "@river/common-ui";

export const ColumnSelectorDropdown: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const [columnSelectorOpened, setColumnSelectorOpened] =
    useState<boolean>(false);
  const tableContext = useContext(TableContext);
  return (
    <>
      <ColumnLayoutDropdown
        defaultAction={{
          title: t("shared.grid_header:label.manage_columns"),
          startIcon: SpaceDashboardOutlinedIcon,
          onClick: () => setColumnSelectorOpened(true),
        }}
        table={tableContext?.table!}
      />
      {columnSelectorOpened && (
        <ColumnsSelector
          open={columnSelectorOpened}
          onClose={() => setColumnSelectorOpened(false)}
        />
      )}
    </>
  );
};
