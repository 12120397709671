import {
  RiverDialog,
  RiverDialogButton,
  RiverDialogActionButton,
  useTranslation,
} from "@river/common-ui";
import React, { useEffect } from "react";
import { LinearProgress, Box } from "@mui/material";
import styles from "./river-download-dialog.module.scss";
import { Constants } from "@river/constants";

interface IRiverDownloadDialogProps {
  title: string;
  open: boolean;
  status: DownloadStatusType;
  downloadMessage?: string;
  downloadButtonText?: string;
  skipConfirmation?: boolean;
  onClose: () => void;
  onDownloadClick: () => void;
}

export type DownloadStatusType =
  | { type: "generating"; message?: string }
  | { type: "downloading"; message?: string }
  | { type: "complete"; message?: string }
  | { type: "error"; message?: string }
  | null;

export const RiverDownloadDialog: React.FC<IRiverDownloadDialogProps> = (
  props
) => {
  const {
    title,
    downloadMessage,
    status,
    open,
    downloadButtonText,
    onClose,
    onDownloadClick,
  } = props;

  const { t } = useTranslation();

  const isDownloadDisabled =
    status?.type === "generating" ||
    status?.type === "downloading" ||
    status?.type === "complete" ||
    status?.type === "error";

  const renderDefaultState = () => {
    return <>{downloadMessage || t("shared.download_dialog:label.download")}</>;
  };

  const getUIStateMessage = (defaultMessage: string) => {
    return status?.message || defaultMessage;
  };

  const renderGeneratingState = () => {
    return (
      <>
        {getUIStateMessage(t("shared.download_dialog:label.generating"))}
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>
    );
  };

  const renderLoadingState = () => {
    return (
      <>
        {getUIStateMessage(t("shared.download_dialog:label.downloading"))}
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>
    );
  };

  const renderCompleteState = () => {
    return (
      <>
        {getUIStateMessage(t("shared.download_dialog:label.default_complete"))}
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={100} color="success" />
        </Box>
      </>
    );
  };

  const renderErrorState = () => {
    return (
      <div className={styles.error}>
        {getUIStateMessage(t("shared.download_dialog:label.error"))}
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={100} color="error" />
        </Box>
      </div>
    );
  };

  useEffect(() => {
    if (props.skipConfirmation && open) {
      props.onDownloadClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.skipConfirmation, open]);

  useEffect(() => {
    if (props.status?.type === Constants.report_status.complete) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <RiverDialog
      title={title}
      open={open}
      onClose={onClose}
      showActionsDivider={false}
      closeOnBackdropClick={false}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={onClose}
            text={
              status?.type === "complete"
                ? t("common.button:close")
                : t("common.button:cancel")
            }
          />
          {!props.skipConfirmation && (
            <RiverDialogActionButton
              onClick={onDownloadClick}
              text={downloadButtonText || t("common.button:download")}
              disabled={isDownloadDisabled}
            />
          )}
        </>
      }
      dialogProps={{
        maxWidth: false,
      }}
    >
      {status === null && renderDefaultState()}
      {status?.type === "generating" && renderGeneratingState()}
      {status?.type === "downloading" && renderLoadingState()}
      {status?.type === "complete" && renderCompleteState()}
      {status?.type === "error" && renderErrorState()}
    </RiverDialog>
  );
};
