import { FC } from "react";
import { GridHeader, GridActions, FilterDropdown } from "../../shared";

export const SupervisorCrewsGridHeader: FC = () => {
  return (
    <GridHeader>
      <GridActions>
        <FilterDropdown />
      </GridActions>
    </GridHeader>
  );
};
