import {
  DependencyList,
  DragEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { gridHelpers } from "./grid-helpers";
import {
  Column,
  DataGridHandle,
  HeaderRendererProps,
  SELECT_COLUMN_KEY,
  SortColumn,
} from "react-data-grid";
import { Guid } from "@river/util";
import {
  IAdapterRule,
  IEntityObject,
  IQueryAttributeGroup,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import {
  IColumnFilter,
  IColumnLayout,
  ITablePreferences,
  ITableFilter,
} from "../../../interfaces";
import { Constants } from "@river/constants";
import { useNotification } from "@river/common-ui";
import { RiverGridHeader } from "./draggable-header";
import { uiConstants } from "../../../helpers";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import {
  ColumnFiltersProvider,
  QueryAttributeGroupProvider,
  userPreferencesService,
} from "../../../services";
import { useTranslation } from "@river/common-ui";
import { AttributeGroupOperator } from "../../shared";
import { useFieldFormatter } from "./use-field-formatter";
import { useFieldEditor } from "./use-field-editor";

// User Preferences table key suffix
const SAVE_KEY_SUFFIX = ".table";

export const STATIC_COLUMN_KEYS = [SELECT_COLUMN_KEY];
const OVERRIDABLE_COLUMN_PROPS = ["name", "width", "frozen"];

export type TablePageSizeType = 200 | 100 | 75 | 50 | 25;

const INITIAL_PAGE_SIZE: TablePageSizeType = 100;
const DEFAULT_PAGE_SIZE: TablePageSizeType = 50;
const DEFAULT_USE_ADVANCED_FILTERS: boolean = true;
const DEFAULT_KEEP_SELECTION_ON_REFRESH: boolean = false;
const DEFAULT_UNSELECT_ROWS_ON_ROW_UPDATE: boolean = true;

export interface IUseTableProps {
  entityName?: string;

  // local storage key for saving table preferences
  saveKey?: string;

  // row ID column key getter
  rowKeyGetter?: (row: IEntityObject) => string;

  columns: Column<any>[];
  fetchFunction: TableFetchFunction;

  // Each time fetchOn becomes true (switches from false to true), useTable hook will run fetch()
  fetchOn?: boolean;

  // Invoked after fetch() triggered by fetchOn
  onInitialFetch?: () => void;

  // Each time any value in dependencies list is changed, useTable hook will run fetch()
  fetchTriggers?: DependencyList;

  // If any item in the list is false, useTable hook will not run fetch()
  dependencies?: boolean[];

  // If clearBeforeFetch is true, useTable hook will clear entities array before running fetch()
  clearBeforeFetch?: boolean;

  onDrag?: (event: DragEvent, row: IEntityObject) => void;

  enableColumnReorder?: boolean;

  // enables infinite scrolling
  infiniteScrolling?: boolean;

  // enables pagination
  pagination?: boolean;

  // page size for pagination or infinite scrolling
  pageSize?: TablePageSizeType;

  // initial page size for infity scrolling
  initialPageSize?: TablePageSizeType;

  // keep previous selection when fetchOn is triggered
  keepSelection?: boolean;

  // Column filters applied when fetchOn is triggered
  initialSimpleFilters?: IColumnFilter[] | ColumnFiltersProvider;

  // Query applied when fetchOn is triggered
  initialQuery?: QueryAttributeGroupDto | QueryAttributeGroupProvider | null;

  onChangeSelectedRowIds?: (selectedRowIds: Set<string>) => void;

  useAdvancedFilters?: boolean;

  onClearFilters?: () => void;
}

export interface ITableFetchFunctionProps {
  columnFilters: IColumnFilter[];
  query?: QueryAttributeGroupDto;
  sortColumns: SortColumn[];
  ids?: string[];
  nin?: string[];
  limit?: number;
  skip?: number;
}

export type TableFetchFunction<T = IEntityObject> = (
  props: ITableFetchFunctionProps
) => Promise<T[]>;

interface IUseTableFetchOptions {
  newColumnFilters?: IColumnFilter[];
  newQuery?: QueryAttributeGroupDto;
  isScrolling?: boolean;
  page?: number;
  pageSize?: TablePageSizeType;
  keepSelection?: boolean;
  skipLoadingPreferences?: boolean;
}

export const getBlankQuery = (): QueryAttributeGroupDto => ({ $and: [] });

export const getBlankTableFilter = (): ITableFilter => ({
  name: "",
  query: getBlankQuery(),
  simpleFilters: [],
});

export function useTable(props: IUseTableProps) {
  const { t } = useTranslation();
  const domRef = useRef<DataGridHandle>();
  const { getFieldFormatter } = useFieldFormatter();
  const { getFieldEditor } = useFieldEditor();
  const { hasAppliedAttribute, mergeColumnFilters } = gridHelpers;
  const DEFAULT_LAYOUT_NAME = t("shared.column_selector:label.default_layout");
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const [columns, setColumns] = useState<Column<any>[]>(props.columns);
  const internalColumnsRef = useRef<Column<any>[]>();
  const [layouts, setLayouts] = useState<IColumnLayout[]>([]);

  const [currentLayout, setCurrentLayout] = useState<IColumnLayout>();
  const [defaultFilterName, setDefaultFilterName] = useState<string>();
  const skipSavePreference = useRef<boolean>(false);
  const [tablePreferencesLoaded, setTablePreferencesLoaded] =
    useState<boolean>(false);
  const [isOnLastPage, setIsOnLastPage] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<TablePageSizeType>(
    props?.pageSize || DEFAULT_PAGE_SIZE
  );

  const [colorRules, setColorRules] = useState<IAdapterRule[]>([]);

  const [lastRanQueryDto, setLastRanQueryDto] = useState<QueryDto>();

  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(
    () => new Set()
  );

  useEffect(() => {
    if (
      columns.slice(-1)[0]?.key === uiConstants.utilityColumnTypes.selectRow
    ) {
      setColumns(props.columns);
      notify.info(t("shared.river_data_grid:notification.table_reset_default"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const fetchEntityColorRules = async (): Promise<void> => {
    if (!props.entityName) return;
    const query: IQueryAttributeGroup = {
      [AttributeGroupOperator.AND]: [
        {
          attribute_name: "entity_name",
          attribute_value: { value: props.entityName, operator: "$eq" },
        },
      ],
    };
    const colorRules: IAdapterRule[] = await adapterContext!.service
      .getAdapterService()
      .fetchRules(
        [
          Constants.rule_type.table_cell_color_rule,
          Constants.rule_type.table_row_color_rule,
        ],
        { query }
      );
    setColorRules(colorRules);
  };

  const pagination: boolean = !!props.pagination;
  const infiniteScrolling: boolean = !!props.infiniteScrolling && !pagination;
  const isPaginating: boolean = pagination || infiniteScrolling;

  const notify = useNotification();

  const [selectedRowIdsBySelectionOrder, setSelectedRowIdsBySelectionOrder] =
    useState<string[]>([]);

  useEffect(() => {
    props.onChangeSelectedRowIds?.(selectedRowIds);
    let added: string[] = [];
    let removed: string[] = [];
    const selected: string[] = Array.from(selectedRowIds);
    const ordered: string[] = selectedRowIdsBySelectionOrder;
    selected.forEach((id) => {
      if (ordered.indexOf(id) === -1) {
        added.push(id);
      }
    });
    ordered.forEach((id) => {
      if (selected.indexOf(id) === -1) {
        removed.push(id);
      }
    });
    if (added.length > 1 || removed.length > 1) {
      setSelectedRowIdsBySelectionOrder([]);
      return;
    }

    if (removed.length) {
      const removedIndex: number = ordered.indexOf(removed[0]);
      ordered.splice(removedIndex, 1);
    } else if (added.length) {
      ordered.push(added[0]);
    }
    setSelectedRowIdsBySelectionOrder(ordered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(Array.from(selectedRowIds))]);

  const getCSSClassFromSaveKey = (): string =>
    props.saveKey ? props.saveKey!.replaceAll(".", "-") : "";

  const draggableColumns = useMemo<Column<any>[]>(() => {
    function HeaderRenderer(headerRendererProps: HeaderRendererProps<any>) {
      return (
        <RiverGridHeader
          {...headerRendererProps}
          enableColumnReorder={props.enableColumnReorder}
          onColumnsReorder={handleColumnsReorder}
        />
      );
    }

    function handleColumnsReorder(sourceKey: string, targetKey: string) {
      const columns: Column<any>[] = internalColumnsRef.current!;
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns: Column<any>[] = [...columns];
      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );
      setColumns(reorderedColumns);
    }

    return columns.map((column) => {
      if (column.key === uiConstants.utilityColumnTypes.selectRow)
        return column;
      return {
        editor: getFieldEditor,
        formatter: getFieldFormatter,
        headerRenderer: HeaderRenderer,
        ...column,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const [isLoading, setIsLoading] = useState(false);
  const forceLoadingState = (loading: boolean): void => setIsLoading(loading);
  const [entities, setEntities] = useState<IEntityObject[]>([]);

  // ----------------------------------------------------------------------------------------------
  // Pass-through state variables, just stored in useTable(), not used for anything inside the table
  // ----------------------------------------------------------------------------------------------
  const [
    // filter displayed when opening filters drawer
    filterToEdit,
    setFilterToEdit,
  ] = useState<ITableFilter | null>(null);
  const [
    // user-applied filters via "Apply" button from filters drawer
    appliedTableFilter,
    setAppliedTableFilter,
  ] = useState<ITableFilter | null>(null);
  // ----------------------------------------------------------------------------------------------

  const [tableFilters, setTableFilters] = useState<ITableFilter[]>([]);
  const [columnFilters, setColumnFilters] = useState<IColumnFilter[]>([]);
  const [query, setQuery] = useState<IQueryAttributeGroup>(getBlankQuery());
  const [lastFetchColumnFilters, setLastFetchColumnFilters] = useState<
    IColumnFilter[]
  >([]);
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActiveGuid, setIsActiveGuid] = useState(Guid.newGuid());

  /** Updates columns from an external column list. */
  const updateColumnsFromExternalSource = (
    cols: Column<any>[],
    options?: {
      overrideSorting?: boolean;
    }
  ) => {
    const newColumns = [...cols];

    // Ensure static columns aren't ever removed.
    for (const staticColumnKey of STATIC_COLUMN_KEYS) {
      if (newColumns.findIndex((col) => col.key === staticColumnKey) === -1) {
        const column = props.columns.find((col) => col.key === staticColumnKey);
        if (column) {
          newColumns.splice(0, 0, column);
        }
      }
    }

    // Pass original column props into column. This maintains custom renderers, width, etc...
    for (const column of newColumns) {
      const columnDef = props.columns.find((col) => col.key === column.key);
      if (columnDef) {
        const overrideProps: { [propName: string]: any } = {};
        OVERRIDABLE_COLUMN_PROPS.forEach((propName) => {
          // @ts-ignore
          overrideProps[propName] = column[propName];
        });
        Object.assign(column, columnDef, overrideProps);
      }
    }

    if (options?.overrideSorting) {
      setColumns(newColumns);
    } else {
      // Sort existing and added columns.

      // Existing columns keep their positions.
      const sortedExistingColumns: Column<any>[] = newColumns
        .filter(
          (col) => columns.find((column) => column.key === col.key) != null
        )
        .sort((left, right) => {
          const leftIndex = columns.findIndex(
            (column) => column.key === left.key
          );
          const rightIndex = columns.findIndex(
            (column) => column.key === right.key
          );

          return Math.sign(leftIndex - rightIndex);
        });

      // New columns are added to the end sorted alphanumerically.
      const sortedAddedColumns = newColumns
        .filter(
          (col) => columns.find((column) => column.key === col.key) == null
        )
        .sort((left, right) => {
          return left.key.localeCompare(right.key);
        });

      setColumns([...sortedExistingColumns, ...sortedAddedColumns]);
    }
  };

  const getInitialColumnFilters = (): IColumnFilter[] =>
    !props.initialSimpleFilters
      ? []
      : Array.isArray(props.initialSimpleFilters)
        ? props.initialSimpleFilters
        : props.initialSimpleFilters();

  const getInitialQuery = (): QueryAttributeGroupDto =>
    !props.initialQuery
      ? getBlankQuery()
      : typeof props.initialQuery === "function"
        ? props.initialQuery()
        : props.initialQuery;

  const getDefaultTableFilter = async (): Promise<ITableFilter> => {
    let defaultFilter: ITableFilter;
    let filterName: string | undefined = defaultFilterName;
    let localFilters: ITableFilter[] = tableFilters;
    if (!tablePreferencesLoaded) {
      const tablePreferences: ITablePreferences =
        (await loadTablePreferences()) as ITablePreferences;
      if (tablePreferences) {
        filterName = tablePreferences.defaultFilterName;
        localFilters = tablePreferences.filters || [];
      }
    }
    if (filterName) {
      defaultFilter = localFilters!.find(
        (filter) => filter.name === filterName
      )!;
      if (defaultFilter) {
        return defaultFilter;
      } else {
        const globalPreferences: ITablePreferences =
          await userPreferencesService.getTablePreferences({
            adapter: adapterContext!.service.getAdapterService(),
            tableSaveKey,
            isGlobal: true,
          });
        defaultFilter = globalPreferences!.filters?.find(
          (filter) => filter.name === filterName
        )!;
        if (defaultFilter) {
          return defaultFilter;
        }
      }
    }
    return getBlankTableFilter();
  };

  // When fetchOn changes to true, force reload...
  useEffect(() => {
    const doFetch = async (): Promise<void> => {
      if (props.fetchOn) {
        fetchEntityColorRules();
        const initialColumnFilters: IColumnFilter[] = getInitialColumnFilters();
        const initialQuery: QueryAttributeGroupDto = getInitialQuery();
        const defaultTableFilter: ITableFilter = await getDefaultTableFilter();

        let newColumnFilters: IColumnFilter[] =
          defaultTableFilter.simpleFilters || [];
        let newQuery: QueryAttributeGroupDto = defaultTableFilter.query;

        // merge initial column filters and initial query with default filter
        if (initialColumnFilters.length) {
          newColumnFilters = mergeColumnFilters(
            newColumnFilters,
            initialColumnFilters
          );
        }
        newQuery.$and = newQuery.$and || [];
        // avoid circular dependency
        if (newQuery !== initialQuery) {
          newQuery.$and.push(initialQuery);
        }

        setAppliedTableFilter({
          name: "",
          simpleFilters: newColumnFilters,
          query: hasAppliedAttribute(newQuery) ? newQuery : getBlankQuery(),
        });

        await fetch({
          keepSelection: !!props.keepSelection,
          newColumnFilters,
          newQuery,
          skipLoadingPreferences: true,
        });

        props.onInitialFetch?.();
      }
    };
    doFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchOn]);

  useEffect(() => {
    if (!!internalColumnsRef.current && !skipSavePreference.current) {
      saveTablePreferences();
    }
    internalColumnsRef.current = columns;
    if (skipSavePreference.current) {
      skipSavePreference.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    // reset refs to initial values to prevent StrictMode issues
    return () => {
      internalColumnsRef.current = undefined;
      skipSavePreference.current = false;
      initialRender.current = true;
    };
  }, []);

  useEffect(() => {
    setColumns(props.columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns.length]);

  const tableSaveKey = `${props.saveKey}${SAVE_KEY_SUFFIX}`;

  const generateTablePreferences = (): ITablePreferences => {
    const columnsToSave: Column<any>[] = columns.map((column: Column<any>) => {
      const columnToSave: Column<any> = { ...column };
      if (columnToSave.key === SELECT_COLUMN_KEY) {
        // @ts-ignore
        delete columnToSave["cellClass"];
        // @ts-ignore
        delete columnToSave["headerCellClass"];
      }
      return columnToSave;
    });

    const generatedPreferences: ITablePreferences = {
      columns: columnsToSave,
      filters: tableFilters,
    };

    if (currentLayout) {
      generatedPreferences.currentLayoutName = currentLayout.name;
      currentLayout.columns = columnsToSave;
      const layoutIndex: number = layouts.findIndex(
        (layout) => layout.name === currentLayout.name
      );
      if (layoutIndex !== -1) {
        layouts.splice(layoutIndex, 1, currentLayout);
      }
    }
    if (layouts.length) {
      generatedPreferences.layouts = layouts;
    }
    return generatedPreferences;
  };

  const getDefaultLayout = (): IColumnLayout => {
    return {
      columns: props.columns,
      name: DEFAULT_LAYOUT_NAME,
    };
  };

  const getDefaultTablePreferences = (): ITablePreferences => {
    const defaultLayout: IColumnLayout = getDefaultLayout();
    return {
      columns,
      layouts: [defaultLayout],
      currentLayoutName: defaultLayout.name,
      filters: [],
    };
  };

  const getDefaultGlobalPreferences = (): ITablePreferences => ({
    columns: [],
    filters: [],
  });

  const saveTablePreferences = () => {
    if (props.saveKey) {
      const tablePreferences: ITablePreferences = generateTablePreferences();
      userPreferencesService.setTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
        tablePreferences,
      });
    }
  };

  const applyTablePreferences = (preferences: ITablePreferences) => {
    if (!props.saveKey) return;

    // retrieve saved layouts
    if (!preferences.layouts || preferences.layouts.length === 0) {
      const defaultLayout: IColumnLayout = getDefaultLayout();
      setLayouts([defaultLayout]);
      setCurrentLayout(defaultLayout);
    } else {
      setLayouts(preferences.layouts);
      if (preferences.currentLayoutName) {
        setCurrentLayout(
          preferences.layouts.find(
            (layout) => layout.name === preferences.currentLayoutName
          )!
        );
      }
    }
    // retrieve saved table filters
    setTableFilters(preferences.filters || []);
    setDefaultFilterName(preferences.defaultFilterName);

    skipSavePreference.current = true;
    updateColumnsFromExternalSource(preferences.columns, {
      overrideSorting: true,
    });
  };

  const loadTablePreferences = async (): Promise<ITablePreferences | void> => {
    if (!props.saveKey) return;

    let preferences: ITablePreferences =
      await userPreferencesService.getTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
      });

    if (!preferences) {
      preferences = getDefaultTablePreferences();
    }

    applyTablePreferences(preferences);
    setTablePreferencesLoaded(true);
    return preferences;
  };

  const tableFetchTriggers = [
    ...(props.fetchTriggers ?? []),
    isActiveGuid,
    sortColumns,
  ];

  const initialRender = useRef<boolean>(true);
  // Fetch data when table dependencies change.
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, tableFetchTriggers);

  const fetch = async (opts?: IUseTableFetchOptions) => {
    if (!opts?.isScrolling) {
      domRef?.current?.element?.scrollTo({ top: 0 });
    }

    if (props.clearBeforeFetch) clear();
    if (
      Array.isArray(props.dependencies) &&
      props.dependencies.indexOf(false) !== -1
    ) {
      return;
    }
    const fetchColumnFilters: IColumnFilter[] =
      opts?.newColumnFilters || columnFilters;

    setColumnFilters(fetchColumnFilters);

    const fetchQuery: IQueryAttributeGroup =
      opts?.newQuery || query || getBlankQuery();
    setQuery(fetchQuery);

    setIsLoading(true);

    if (!opts?.keepSelection) {
      setSelectedRowIds(new Set());
    }

    let skip: number = 0;
    let limit: number = opts?.pageSize || pageSize;

    if (isPaginating) {
      preparePagination();
    }

    if (!tablePreferencesLoaded && !opts?.skipLoadingPreferences) {
      loadTablePreferences();
    }

    try {
      const fetchProps: ITableFetchFunctionProps = {
        columnFilters: fetchColumnFilters,
        query: fetchQuery,
        sortColumns,
        skip,
        limit,
      };

      const newEntities = await props.fetchFunction(fetchProps);

      if (isPaginating) {
        if (opts?.pageSize) {
          setPageSize(opts.pageSize);
        }
        finalizePagination(newEntities, opts?.pageSize || pageSize);
      } else {
        setEntities(newEntities);
      }
      setLastFetchColumnFilters([...fetchColumnFilters]);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }

    function preparePagination(): void {
      // reset paging in case of new filters
      if (
        fetchColumnFilters.length > 0 &&
        JSON.stringify(fetchColumnFilters) !==
          JSON.stringify(lastFetchColumnFilters)
      ) {
        skip = 0;
        setPage(1);
        setSelectedRowIds(new Set());
      } else if (pagination) {
        const newPage: number = opts?.page || page;
        skip = (newPage - 1) * pageSize;
        setPage(newPage);
      } else if (infiniteScrolling) {
        if (opts?.isScrolling) {
          skip = page * pageSize;
          setPage(page + 1);
        } else {
          // configure initial page
          const initialPageSize: TablePageSizeType =
            props.initialPageSize || INITIAL_PAGE_SIZE;
          const initialPage: number = initialPageSize / DEFAULT_PAGE_SIZE;

          skip = 0;
          limit = initialPageSize;
          setPage(initialPage);
        }
      }
    }

    function finalizePagination(
      newEntities: IEntityObject[],
      pageSize: TablePageSizeType
    ) {
      // check if there are more entities to load after this fetch
      if (pageSize <= newEntities.length) {
        setIsOnLastPage(false);
      } else {
        setIsOnLastPage(true);
      }

      if (infiniteScrolling && opts?.isScrolling) {
        let newArray = [];
        if (skip === 0) {
          newArray = [...newEntities];
        } else {
          newArray = [...entities, ...newEntities];
        }
        setEntities(newArray);
      } else {
        setEntities(newEntities);
      }
    }
  };

  const refresh = async (opts?: { keepSelection?: boolean }): Promise<void> => {
    fetch({
      newColumnFilters: lastFetchColumnFilters,
      keepSelection: opts?.keepSelection ?? DEFAULT_KEEP_SELECTION_ON_REFRESH,
    });
  };

  const fetchSpecificRows = async (
    ids: string[],
    replaceExisting?: boolean
  ) => {
    try {
      setIsLoading(true);
      const loadedEntities = await props.fetchFunction({
        columnFilters,
        query,
        sortColumns,
        ids: ids,
      });
      if (replaceExisting) {
        setEntities(loadedEntities);
      } else {
        // update existing matching rows
        const newEntities = [...entities];
        for (const updatedEntity of loadedEntities) {
          const id = updatedEntity[uiConstants.fields.rowId] as string;
          const index = newEntities.findIndex(
            (entity) => entity[uiConstants.fields.rowId] === id
          );
          if (index !== -1) {
            newEntities[index] = updatedEntity;
          } else {
            // TODO: What happens in this case?
          }
        }
        setEntities(newEntities);
      }
      setLastFetchColumnFilters([...columnFilters]);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const clear = (): void => {
    setEntities([]);
  };

  const clearFilters = async () => {
    props.onClearFilters?.();
    setFilterToEdit(null);
    setAppliedTableFilter(null);
    await fetch({ newColumnFilters: [], newQuery: getBlankQuery() });
  };

  const getSelectedRows = (): IEntityObject[] => {
    const selectedIds: string[] = Array.from(selectedRowIds).map((id) =>
      String(id)
    );
    return entities.filter((object) => {
      const rowId: string = String(props.rowKeyGetter!(object));
      return selectedIds.includes(rowId);
    });
  };

  return {
    domRef,
    tablePreferencesLoaded,
    isLoading,
    forceLoadingState,
    entities,
    fetch,
    fetchSpecificRows,
    saveKey: props.saveKey,
    dbSaveKey: props.saveKey ? tableSaveKey : "",
    rowKeyGetter: props.rowKeyGetter,
    refresh,
    clear,
    columns: draggableColumns,
    setColumns: updateColumnsFromExternalSource,
    defaultColumns: props.columns,
    sortColumns,
    setSortColumns,
    setColumnSize: (index: number, newWidth: number) => {
      const newColumns = [...columns];
      const col = { ...newColumns[index] };
      col["width"] = newWidth;
      newColumns[index] = col;
      setColumns(newColumns);
    },
    updateRow: (updateRowProps: {
      rowId: string;
      updatedRow: Partial<IEntityObject>;
      unselectRows?: boolean;
    }) => {
      const { rowId, updatedRow } = updateRowProps;
      const unselectRows: boolean =
        updateRowProps.unselectRows ?? DEFAULT_UNSELECT_ROWS_ON_ROW_UPDATE;
      const rowIndex = entities.findIndex(
        (entity) => entity[uiConstants.fields.rowId] === rowId
      );
      if (rowIndex !== -1) {
        const row = { ...entities[rowIndex] };
        Object.assign(row, updatedRow);

        const newEntities = [...entities];
        newEntities[rowIndex] = row;

        if (unselectRows) {
          setSelectedRowIds(new Set());
        }
        setEntities(newEntities);
      } else {
        console.error("updateRow(): Updated record not found");
      }
    },
    updateRows: (updateRowsProps: {
      rows: { rowId: string; updatedRow: Partial<IEntityObject> }[];
      unselectRows?: boolean;
    }) => {
      const { rows } = updateRowsProps;
      const unselectRows: boolean =
        updateRowsProps.unselectRows ?? DEFAULT_UNSELECT_ROWS_ON_ROW_UPDATE;
      const newEntities = [...entities];

      rows.forEach((updatedRowObj) => {
        const { rowId, updatedRow } = updatedRowObj;
        const rowIndex = entities.findIndex(
          (entity) => entity[uiConstants.fields.rowId] === rowId
        );
        if (rowIndex !== -1) {
          const row = { ...entities[rowIndex] };
          Object.assign(row, updatedRow);
          newEntities[rowIndex] = row;
        } else {
          console.error("updateRow(): Updated record not found");
        }
      });

      if (unselectRows) {
        setSelectedRowIds(new Set());
      }
      setEntities(newEntities);
    },
    query,
    columnFilters,
    setColumnFilters,
    lastFetchColumnFilters,
    clearFilters,
    selectedRowIds,
    setSelectedRowIds,
    selectedRowIdsBySelectionOrder,
    getSelectedRows,
    isOnLastPage,
    infiniteScrolling,
    pagination,
    page,
    pageSize,
    onDrag: props.onDrag ?? (() => {}),
    getCSSClassFromSaveKey,
    loadTablePreferences,
    applyTablePreferences,
    getDefaultTablePreferences,
    getDefaultGlobalPreferences,
    getDefaultLayout,
    layouts,
    currentLayout,
    DEFAULT_LAYOUT_NAME,
    tableFilters,
    defaultFilterName,
    filterToEdit,
    setFilterToEdit,
    appliedTableFilter,
    setAppliedTableFilter,
    useAdvancedFilters:
      props.useAdvancedFilters ?? DEFAULT_USE_ADVANCED_FILTERS,
    colorRules,
    /* If you need to use lastRanQueryDto you need to explicitly call setLastRanQueryDto
       from actual fetchFunction like we do for tables using Excel Export action  */
    lastRanQueryDto,
    setLastRanQueryDto,
  };
}

export type IUseTable = ReturnType<typeof useTable>;
