import React, { useEffect, useRef, useState, ReactElement } from "react";
import {
  AnchorEl,
  IRiverListPopupItem,
  RiverListPopup,
  Icon,
} from "../river-list-popup";
import { ModuleKey } from "../../sidebar-menu";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropDownUp from "@mui/icons-material/ArrowDropUp";
import { useAllowedAction } from "../../protected-action";
import { GenericAction } from "../grid-header";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import styles from "./river-dropdown-actions.module.scss";
import clsx from "clsx";

const DEFAULT_SHOW_DEFAULT_ITEM = false;

interface IRiverDropdownActions {
  items: IRiverDropdownActionsItem[];
  module?: ModuleKey;
  onClose?: () => void;
  showDefaultDropDownItem?: boolean;
}

export interface IRiverDropdownActionsItem extends IRiverListPopupItem {
  startIcon: Icon;
  action?: string;
  hidden?: boolean;
}

export const DROPDOWN_SEPARATOR: IRiverDropdownActionsItem = {
  title: "",
  startIcon: () => <></>,
  onClick: () => {},
  isSeparator: true,
};

export const RiverDropdownActions: React.FC<IRiverDropdownActions> = (
  props
) => {
  const [anchorElement, setAnchorElement] = useState<AnchorEl>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const opened = Boolean(anchorElement);
  const isActionAllowed = useAllowedAction();
  const [filteredItems, setFilteredItems] = useState<
    IRiverDropdownActionsItem[]
  >([]);
  const [filteredDropDownItems, setFilteredDropDownItems] = useState<
    IRiverDropdownActionsItem[]
  >([]);
  const defaultItem: IRiverDropdownActionsItem | undefined = filteredItems[0];
  const showDefaultDropDownItem: boolean =
    props.showDefaultDropDownItem ?? DEFAULT_SHOW_DEFAULT_ITEM;

  const filterItems = (): IRiverDropdownActionsItem[] => {
    return props.items.filter((item) => {
      if (props.module && item.action) {
        return !item.hidden && isActionAllowed(props.module, item.action);
      }
      return true;
    });
  };

  const filterDropDownItems = (): IRiverDropdownActionsItem[] => {
    return filteredItems.filter(
      (item, index) => !(index === 0 && !showDefaultDropDownItem)
    );
  };

  useEffect(() => {
    setFilteredItems(filterItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  useEffect(() => {
    setFilteredDropDownItems(filterDropDownItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItems]);

  const DropdownIcon = opened ? ArrowDropDownUp : ArrowDropDown;

  const renderAnchor = (): ReactElement => (
    <>
      {filteredItems.length > 0 && (
        <div
          ref={anchorRef}
          className={clsx([
            styles.root,
            { [styles.singleItem]: filteredItems.length === 1 },
            { [styles.popupOpened]: !!anchorElement },
          ])}
        >
          {defaultItem && (
            <>
              {filteredItems.length > 0 && (
                <GenericAction
                  icon={defaultItem.startIcon}
                  title={defaultItem.title}
                  onClick={defaultItem.onClick!}
                  enableOn={!defaultItem.disabled}
                />
              )}
              {filteredItems.length > 1 && (
                <div
                  className={styles.dropdownIconContainer}
                  onClick={() => setAnchorElement(anchorRef.current)}
                >
                  <DropdownIcon
                    className={clsx([
                      headerStyles.actionIcon,
                      styles.dropdownIcon,
                    ])}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  const renderPopup = (): ReactElement => (
    <RiverListPopup
      items={filteredDropDownItems}
      anchorEl={anchorElement}
      popover={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      onClose={() => {
        setAnchorElement(null);
        props.onClose?.();
      }}
    />
  );

  return (
    <>
      {renderAnchor()}
      {renderPopup()}
    </>
  );
};
