import { AdapterUiService } from "../adapter-ui.service";
import { AdapterService } from "../adapter.service";
import { OracleCloudAdapterService } from "./oracle-cloud-adapter.service";

import { OracleCloudExternalResourcesUiService } from "./oracle-cloud-external-resources-ui-service";
import { OracleCloudSitesUiService } from "./oracle-cloud-sites-ui.service";
import { OracleCloudAvailabilityUiService } from "./oracle-cloud-availability-ui.service";
import { OracleCloudPersonUiService } from "./oracle-cloud-person-ui.service";
import { OracleCloudMaterialRequirementsUiService } from "./oracle-cloud-material-requirements-ui.service";
import { OracleCloudBacklogUiService } from "./oracle-cloud-backlog-ui.service";
import { OracleCloudUsersUiService } from "./oracle-cloud-users-ui.service";
import { OracleCloudScheduleUiService } from "./oracle-cloud-schedule-ui.service";
import { OracleCloudSchedulesUiService } from "./oracle-cloud-schedules-ui.service";
import { OracleCloudScheduleUtilizationUiService } from "./oracle-cloud-schedule-utilization-ui-service";
import { OracleCloudTaskHelpersUiService } from "./oracle-cloud-task-helpers-ui.service";
import { OracleCloudCraftsUiService } from "./oracle-cloud-crafts-ui.service";
import { OracleCloudOperationsUiService } from "./oracle-cloud-operations-ui-service";
import { OracleCloudExecutionUiService } from "./oracle-cloud-execution-ui.service";
import { OracleCloudShiftsUiService } from "./oracle-cloud-shifts-ui.service";
import { OracleCloudCalendarsUiService } from "./oracle-cloud-calendars-ui.service";
import { OracleCloudValidationDashboardUiService } from "./oracle-cloud-validation-dashboard-ui.service";
import { OracleCloudTimecardUiService } from "./oracle-cloud-timecard-ui.service";
import { OracleCloudCrewsUiService } from "./oracle-cloud-crews-ui.service";
import { OracleCloudTableUiService } from "./oracle-cloud-table-ui.service";
import { OracleCloudUserRolesUiService } from "./oracle-cloud-user-roles-ui-service";
import { OracleCloudSupervisorScheduleUiService } from "./oracle-cloud-supervisor-schedule-ui.service";
import { OracleCloudSupervisorSchedulesUiService } from "./oracle-cloud-supervisor-schedules-ui-service";
import { OracleCloudSupervisorScheduleUtilizationUiService } from "./oracle-cloud-supervisor-schedule-utilization-ui.service";
import { OracleCloudSupervisorTimeCardUiService } from "./oracle-cloud-supervisor-timecard-ui-service";
import { OracleCloudScheduleReportUiService } from "./oracle-cloud-schedule-report-ui-service";

export class OracleCloudAdapterUiService extends AdapterUiService {
  private sitesUiService: OracleCloudSitesUiService | null = null;
  private backlogUiService: OracleCloudBacklogUiService | null = null;
  private schedulesUiService: OracleCloudSchedulesUiService | null = null;
  private scheduleUiService: OracleCloudScheduleUiService | null = null;
  private scheduleUtilizationUiService: OracleCloudScheduleUtilizationUiService | null =
    null;
  private usersUiService: OracleCloudUsersUiService | null = null;
  private availabilityUiService: OracleCloudAvailabilityUiService | null = null;
  private externalResourcesUiService: OracleCloudExternalResourcesUiService | null =
    null;
  private materialRequirementsUiService: OracleCloudMaterialRequirementsUiService | null =
    null;
  private personUiService: OracleCloudPersonUiService | null = null;
  private taskHelpersUiService: OracleCloudTaskHelpersUiService | null = null;
  private craftsUiService: OracleCloudCraftsUiService | null = null;
  private operationsUiService: OracleCloudOperationsUiService | null = null;
  private executionUiService: OracleCloudExecutionUiService | null = null;
  private shiftsUiService: OracleCloudShiftsUiService | null = null;
  private calendarsUiService: OracleCloudCalendarsUiService | null = null;
  private validationDashboardUiService: OracleCloudValidationDashboardUiService | null =
    null;
  private timecardUiService: OracleCloudTimecardUiService | null = null;
  private crewsUiService: OracleCloudCrewsUiService | null = null;
  private tableUiService: OracleCloudTableUiService | null = null;
  private userRolesUiService: OracleCloudUserRolesUiService | null = null;
  private supervisorScheduleUiService: OracleCloudSupervisorScheduleUiService | null =
    null;
  private supervisorSchedulesUiService: OracleCloudSupervisorSchedulesUiService | null =
    null;
  private supervisorTimeCardUiService: OracleCloudSupervisorTimeCardUiService | null =
    null;
  private supervisorScheduleUtilizationUiService: OracleCloudSupervisorScheduleUtilizationUiService | null =
    null;
  private scheduleReportUiService: OracleCloudScheduleReportUiService | null =
    null;

  protected createAdapterService(): AdapterService {
    return new OracleCloudAdapterService(this.customerInfo);
  }

  getSitesUiService(): OracleCloudSitesUiService {
    if (!this.sitesUiService) {
      this.sitesUiService = new OracleCloudSitesUiService(this);
    }
    return this.sitesUiService;
  }

  getBacklogUiService(): OracleCloudBacklogUiService {
    if (!this.backlogUiService) {
      this.backlogUiService = new OracleCloudBacklogUiService(this);
    }
    return this.backlogUiService;
  }

  getScheduleUiService(): OracleCloudScheduleUiService {
    if (!this.scheduleUiService) {
      this.scheduleUiService = new OracleCloudScheduleUiService(this);
    }
    return this.scheduleUiService;
  }

  getSchedulesUiService(): OracleCloudSchedulesUiService {
    if (!this.schedulesUiService) {
      this.schedulesUiService = new OracleCloudSchedulesUiService(this);
    }
    return this.schedulesUiService;
  }

  getScheduleUtilizationUiService(): OracleCloudScheduleUtilizationUiService {
    if (!this.scheduleUtilizationUiService) {
      this.scheduleUtilizationUiService =
        new OracleCloudScheduleUtilizationUiService(this);
    }
    return this.scheduleUtilizationUiService;
  }

  getUsersUiService(): OracleCloudUsersUiService {
    if (!this.usersUiService) {
      this.usersUiService = new OracleCloudUsersUiService(this);
    }
    return this.usersUiService;
  }

  getAvailabilityUiService(): OracleCloudAvailabilityUiService {
    if (!this.availabilityUiService) {
      this.availabilityUiService = new OracleCloudAvailabilityUiService(this);
    }
    return this.availabilityUiService;
  }

  getExternalResourcesUiService(): OracleCloudExternalResourcesUiService {
    if (!this.externalResourcesUiService) {
      this.externalResourcesUiService =
        new OracleCloudExternalResourcesUiService(this);
    }
    return this.externalResourcesUiService;
  }

  getMaterialRequirementsUiService(): OracleCloudMaterialRequirementsUiService {
    if (!this.materialRequirementsUiService) {
      this.materialRequirementsUiService =
        new OracleCloudMaterialRequirementsUiService(this);
    }
    return this.materialRequirementsUiService;
  }

  getPersonUiService(): OracleCloudPersonUiService {
    if (!this.personUiService) {
      this.personUiService = new OracleCloudPersonUiService(this);
    }
    return this.personUiService;
  }

  getTaskHelpersUiService(): OracleCloudTaskHelpersUiService {
    if (!this.taskHelpersUiService) {
      this.taskHelpersUiService = new OracleCloudTaskHelpersUiService(this);
    }
    return this.taskHelpersUiService;
  }

  getCraftsUiService(): OracleCloudCraftsUiService {
    if (!this.craftsUiService) {
      this.craftsUiService = new OracleCloudCraftsUiService(this);
    }
    return this.craftsUiService;
  }

  getOperationsUiService(): OracleCloudOperationsUiService {
    if (!this.operationsUiService) {
      this.operationsUiService = new OracleCloudOperationsUiService(this);
    }
    return this.operationsUiService;
  }

  getExecutionUiService(): OracleCloudExecutionUiService {
    if (!this.executionUiService) {
      this.executionUiService = new OracleCloudExecutionUiService(this);
    }
    return this.executionUiService;
  }

  getShiftsUiService(): OracleCloudShiftsUiService {
    if (!this.shiftsUiService) {
      this.shiftsUiService = new OracleCloudShiftsUiService(this);
    }
    return this.shiftsUiService;
  }

  getCalendarsUiService(): OracleCloudCalendarsUiService {
    if (!this.calendarsUiService) {
      this.calendarsUiService = new OracleCloudCalendarsUiService(this);
    }
    return this.calendarsUiService;
  }

  getValidationDashboardUiService(): OracleCloudValidationDashboardUiService {
    if (!this.validationDashboardUiService) {
      this.validationDashboardUiService =
        new OracleCloudValidationDashboardUiService(this);
    }
    return this.validationDashboardUiService;
  }

  getScheduleReportUiService(): OracleCloudScheduleReportUiService {
    if (!this.scheduleReportUiService) {
      this.scheduleReportUiService = new OracleCloudScheduleReportUiService(
        this
      );
    }
    return this.scheduleReportUiService;
  }

  getTimeCardUiService(): OracleCloudTimecardUiService {
    if (!this.timecardUiService) {
      this.timecardUiService = new OracleCloudTimecardUiService(this);
    }
    return this.timecardUiService;
  }

  getCrewsUiService(): OracleCloudCrewsUiService {
    if (!this.crewsUiService) {
      this.crewsUiService = new OracleCloudCrewsUiService(this);
    }
    return this.crewsUiService;
  }

  getTableUiService(): OracleCloudTableUiService {
    if (!this.tableUiService) {
      this.tableUiService = new OracleCloudTableUiService(this);
    }
    return this.tableUiService;
  }

  getUserRolesUiService(): OracleCloudUserRolesUiService {
    if (!this.userRolesUiService) {
      this.userRolesUiService = new OracleCloudUserRolesUiService(this);
    }
    return this.userRolesUiService;
  }

  getSupervisorScheduleUiService(): OracleCloudSupervisorScheduleUiService {
    if (!this.supervisorScheduleUiService) {
      this.supervisorScheduleUiService =
        new OracleCloudSupervisorScheduleUiService(this);
    }
    return this.supervisorScheduleUiService;
  }

  getSupervisorSchedulesUiService(): OracleCloudSupervisorSchedulesUiService {
    if (!this.supervisorSchedulesUiService) {
      this.supervisorSchedulesUiService =
        new OracleCloudSupervisorSchedulesUiService(this);
    }
    return this.supervisorSchedulesUiService;
  }

  getSupervisorScheduleUtilizationUiService(): OracleCloudSupervisorScheduleUtilizationUiService {
    if (!this.supervisorScheduleUtilizationUiService) {
      this.supervisorScheduleUtilizationUiService =
        new OracleCloudSupervisorScheduleUtilizationUiService(this);
    }
    return this.supervisorScheduleUtilizationUiService;
  }

  getSupervisorTimeCardUiService(): OracleCloudSupervisorTimeCardUiService {
    if (!this.supervisorTimeCardUiService) {
      this.supervisorTimeCardUiService =
        new OracleCloudSupervisorTimeCardUiService(this);
    }
    return this.supervisorTimeCardUiService;
  }
}
