export namespace svgHelpers {
  /**
   * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
   *
   * @param {String} text The text to be rendered.
   * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
   *
   * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
   */
  export const getTextWidth = (text: string, font: string): number => {
    // re-use canvas object for better performance
    let canvas: HTMLCanvasElement | null = document.getElementById(
      "canvas"
    ) as HTMLCanvasElement | null;
    if (!canvas) {
      canvas = document.createElement("canvas");
      canvas.id = "canvas";
    }
    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("No Context");
      // return 0;
    }
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };
}
