import React, { ReactElement, useContext } from "react";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  IUseTable,
  FilterDropdown,
} from "../../../../shared";
import { IColumnLayout, ITablePreferences } from "../../../../../interfaces";
import { Column } from "react-data-grid";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import { userPreferencesService } from "../../../../../services";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";

interface ILayoutManagerGridHeader {
  entityTable: IUseTable;
  onDeleteLayouts?: (deletedLayoutNames: string[]) => void;
}

export const LayoutManagerGridHeader: React.FC<ILayoutManagerGridHeader> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  const deleteLayouts = async (): Promise<void> => {
    const entityTable: IUseTable = props.entityTable;
    const table: IUseTable = tableContext?.table!;

    const selectedLayoutIds: Set<string> = new Set(table.selectedRowIds!);
    // Prevent deleting Default Layout
    selectedLayoutIds.delete(table.DEFAULT_LAYOUT_NAME);

    const selectedLayoutNames: string[] = Array.from(selectedLayoutIds);

    if (selectedLayoutNames.length) {
      let newColumns: Column<any>[] = entityTable.columns;
      const newLayouts: IColumnLayout[] = entityTable.layouts.filter(
        (layout) => selectedLayoutNames.indexOf(layout.name) === -1
      );

      let currentLayoutName: string = entityTable.currentLayout?.name || "";
      if (currentLayoutName) {
        if (selectedLayoutNames.indexOf(currentLayoutName) !== -1) {
          const defaultLayout: IColumnLayout = entityTable.getDefaultLayout();
          currentLayoutName = defaultLayout.name;
          newColumns = defaultLayout.columns;
        }
      }

      const newTablePreferences: ITablePreferences = {
        columns: newColumns,
        currentLayoutName,
        layouts: newLayouts,
      };
      try {
        await userPreferencesService.setTablePreferences({
          adapter: adapterContext!.service.getAdapterService(),
          tableSaveKey: entityTable.dbSaveKey!,
          tablePreferences: newTablePreferences,
        });
        props.onDeleteLayouts?.(selectedLayoutNames);
        notify.info(
          t("shared.column_selector:notification.column_layout_deletion")
        );
        tableContext?.table.refresh();
        entityTable.loadTablePreferences();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteLayoutsConfirmation = useSimpleDialog({
    title: t("shared.column_selector:message.delete_layout.title"),
    message: t("shared.column_selector:message.confirm_layout_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteLayouts,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <DeleteAction onClick={() => deleteLayoutsConfirmation.open()} />
        </GridActions>
      </GridHeader>
      {deleteLayoutsConfirmation.render()}
    </>
  );
};
