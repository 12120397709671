import { FC, ReactElement } from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverFormSelect,
  IRiverSimpleSelectItem,
} from "@river/common-ui";
import { IAdapterRule } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useAttachmentSelectionRulesForm } from "./use-attachment-selection-rules-form";
import { useTranslation } from "@river/common-ui";
import styles from "./attachment-selection-rules-dialog.module.scss";
import clsx from "clsx";

interface IAttachmentSelectionRulesDialogProps {
  open: boolean;
  rule: IAdapterRule | null;
  onClose: (success: boolean) => void;
}

export const AttachmentSelectionRulesDialog: FC<
  IAttachmentSelectionRulesDialogProps
> = (props): ReactElement => {
  const form: RiverFormInstance = useAttachmentSelectionRulesForm({
    rule: props.rule,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });
  const { submit, resetForm, isCreate } = form;
  const { t } = useTranslation();

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    close(requireRefresh);
  };

  const close = (requiresRefresh?: boolean): void => {
    resetForm();
    props.onClose(!!requiresRefresh);
  };

  const renderEntityNameField = (): ReactElement => {
    const entityNames: string[] = ["workorder"];
    const items: IRiverSimpleSelectItem[] = entityNames.map((e) => {
      return { value: e, text: e };
    });
    return <RiverFormSelect id="entity_name" items={items} fullWidth />;
  };

  const renderExpressionField = (): ReactElement => (
    <RiverTextInput
      id="expression"
      label={t("entity.rule:rule.expression")}
      fullWidth
      inputProps={{
        multiline: true,
        minRows: 6,
        maxRows: 12,
      }}
    />
  );

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.attachment-selection-rules:label.create_rule")
      : t("module.attachment-selection-rules:label.edit_rule");
  const getActionButtonText = (): string =>
    isCreate ? t("common.button:add") : t("common.button:save");

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={close}
      actionButtonText={getActionButtonText()}
      showActionsDivider={false}
      onSubmit={submit}
      classes={{
        paper: styles.paper,
        content: clsx([styles.content]),
      }}
      form={form}
    >
      <RiverTextInput id="rule" fullWidth />
      {renderEntityNameField()}
      {renderExpressionField()}
    </RiverDialog>
  );
};
