import React, { ReactElement, useContext } from "react";
import {
  DayPeriodDateText,
  frequencyDefsByGanttTimeOptionGroupID,
  HourPeriodDateText,
  IFrequencyDef,
  IPeriod,
} from "../../use-supervisor-schedule-gantt";
import { SupervisorScheduleContext } from "../../../../../context";
import {
  ISupervisorScheduleGanttTimeOption,
  SupervisorScheduleGanttTimeOptionGroupID,
} from "../../../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import { ModuleKey } from "../../../../sidebar-menu";
import { SupervisorScheduleAction } from "../../../../../services";
import { useAllowedAction } from "../../../../protected-action";
import styles from "./supervisor-gantt-header-period.module.scss";
import clsx from "clsx";

interface ISupervisorGanttHeaderPeriodProps {
  period: IPeriod;
}

const ganttTimeOptionClassMap: {
  [key in SupervisorScheduleGanttTimeOptionGroupID]: string;
} = {
  [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: styles.hourPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: styles.dayPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: styles.weekPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: styles.monthPeriod,
};

export const SupervisorGanttHeaderPeriod: React.FC<
  ISupervisorGanttHeaderPeriodProps
> = (props): ReactElement => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const ganttTimeOption: ISupervisorScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;
  const periodFrequencyDef: IFrequencyDef =
    frequencyDefsByGanttTimeOptionGroupID[ganttTimeOption.groupId];

  const isActionAllowed = useAllowedAction();

  const { period } = props;

  const periodGroupClass: string =
    ganttTimeOptionClassMap[ganttTimeOption.groupId];

  const onGanntHeaderCellClick = (period: IPeriod) => {
    if (
      isActionAllowed(
        ModuleKey.SUPERVISOR_SCHEDULES,
        SupervisorScheduleAction.SCHEDULE
      )
    ) {
      scheduleContext?.scheduleFn(period.startDate);
    }
  };

  const renderHourPeriod = (): ReactElement => {
    const periodText: HourPeriodDateText = JSON.parse(period.dateText);
    return (
      <>
        <div
          className={clsx([styles.element, styles.date])}
          onClick={() => onGanntHeaderCellClick(period)}
        >
          {periodText.date}
        </div>
        <div
          className={clsx([styles.element, styles.time])}
          onClick={() => onGanntHeaderCellClick(period)}
        >
          {periodText.time}
        </div>
      </>
    );
  };

  const renderDayPeriod = (): ReactElement => {
    const periodText: DayPeriodDateText = JSON.parse(period.dateText);
    return (
      <>
        <div className={clsx([styles.element, styles.day])}>
          {periodText.day}
        </div>
        <div
          className={clsx([styles.element, styles.date])}
          onClick={() => onGanntHeaderCellClick(period)}
        >
          {periodText.date}
        </div>
      </>
    );
  };

  const renderDefaultPeriod = (): ReactElement => (
    <div
      className={clsx([styles.element, styles.date])}
      onClick={() => onGanntHeaderCellClick(period)}
    >
      {period.dateText}
    </div>
  );

  const renderPeriod = (): ReactElement => {
    let periodElement: ReactElement = <></>;
    const timeGroup: SupervisorScheduleGanttTimeOptionGroupID =
      ganttTimeOption?.groupId!;
    if (timeGroup === SupervisorScheduleGanttTimeOptionGroupID.HOURS) {
      periodElement = renderHourPeriod();
    } else if (timeGroup === SupervisorScheduleGanttTimeOptionGroupID.DAYS) {
      periodElement = renderDayPeriod();
    } else {
      periodElement = renderDefaultPeriod();
    }
    return periodElement;
  };

  return (
    <div
      className={clsx([styles.root, periodGroupClass])}
      style={{
        minWidth: `${periodFrequencyDef.minPeriodSize}px`,
      }}
    >
      {renderPeriod()}
    </div>
  );
};
