import { TFunction, useTranslation } from "react-i18next";
import { AdapterUiService } from "./adapter-ui.service";
import { ITableFetchFunctionProps, useTable } from "../components/shared";
import { fetchHelpers } from "../helpers";
import { useEntity } from "../hooks";
import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";

export const DEFAULT_TABLE_SAVE_KEY = "material_requirements";

interface IUseMaterialRequirementsProps {
  workOrderNumbers: string[];
  tableSaveKey?: string;
  fetchOn?: boolean;
}

export abstract class MaterialRequirementsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useMaterialRequirements = (
    props: IUseMaterialRequirementsProps
  ): EntityTableProvider => {
    return () => {
      const { t } = useTranslation();
      const { entityName, columns } =
        this.getMaterialRequirementsTableParams(t);
      const fetchMaterialRequirements = async (
        fetchProps: ITableFetchFunctionProps
      ) =>
        await this.adapterUiService
          .getAdapterService()
          .fetchMaterial(
            props.workOrderNumbers,
            fetchHelpers.getTableQuery({ fetchProps })
          );

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          columns,
          fetchFunction: fetchMaterialRequirements,
          fetchOn: !!props.fetchOn,
          fetchTriggers: [],
          saveKey: props.tableSaveKey || DEFAULT_TABLE_SAVE_KEY,
          useAdvancedFilters: false,
        }),
      };
    };
  };

  protected abstract getMaterialRequirementsTableParams(
    t: TFunction
  ): IUseEntityTableParams;

  abstract getWorkOrderNumberColumn(): string;
}
