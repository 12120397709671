import React, { SVGProps } from "react";

export const UpDownArrowsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      {...props}
    >
      <mask
        id="mask0_964_12714"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_964_12714)">
        <path
          d="M10 17L6.625 13.625L7.6875 12.5625L10 14.875L12.3125 12.5625L13.375 13.625L10 17Z"
          fill="#454746"
        />
        <path
          d="M6.625 6.375L7.6875 7.4375L10 5.125L12.3125 7.4375L13.375 6.375L10 3L6.625 6.375Z"
          fill="#454746"
        />
      </g>
    </svg>
  );
};
