import React, { useContext, useState } from "react";
import { IRiverIconButtonProps } from "../../shared";
import { PrimaryButton } from "@river/common-ui";
import { EntityDialog } from "../entity-dialog";
import { IEntity } from "@river/interfaces";
import { DataDictDetailsTabId } from "../entity-dialog/entity-dialog-grid-header";

import { TableContext } from "../../../context";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { DataDictionaryAction } from "../data-dict";
import { ModuleKey } from "../../sidebar-menu";

interface DataDictSubheaderProps {
  actions?: IRiverIconButtonProps[];
}

export const DataDictSubHeader: React.FC<DataDictSubheaderProps> = (props) => {
  const { t } = useTranslation();

  const [entityDialogOpened, setEntityDialogOpened] = useState<boolean>(false);
  const [entity, setEntity] = useState<IEntity | null>();
  const tableContext = useContext(TableContext);
  const [entityDialogTab, setEntityDialogTab] =
    useState<DataDictDetailsTabId | null>();
  const refreshTable = () => tableContext?.table.refresh();

  const closeEntityDialog = () => {
    setEntityDialogOpened(false);
    setEntity(null);
    setEntityDialogTab(null);
  };

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.DATA_DICTIONARY}
          action={DataDictionaryAction.CREATE_ENTITY}
        >
          <PrimaryButton
            onClick={() => {
              tableContext?.table.setSelectedRowIds(new Set());
              setEntityDialogOpened(true);
            }}
            text={t("module.data_dictionary:button.add_entity")}
          />
        </ProtectedAction>
      </div>
      <EntityDialog
        entity={entity}
        open={entityDialogOpened}
        tab={entityDialogTab}
        onClose={closeEntityDialog}
        onCreate={(entity: IEntity) => {
          refreshTable();
          setEntity(entity);
          setEntityDialogTab(DataDictDetailsTabId.ATTRIBUTES);
        }}
      />
    </>
  );
};
