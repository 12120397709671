import React, { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
} from "../../../context";
import { GridHeader, GridActions, ColumnSelectorDropdown } from "../../shared";
import { IEntityObject } from "@river/interfaces";
import { useEnv, IUseEnv } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { ExecutionUiService, TaskHelpersUiService } from "../../../services";
import styles from "./execution-grid-header.module.scss";
import clsx from "clsx";

export const ExecutionGridHeader: React.FC = (): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const executionContext = useContext(ExecutionContext);
  const currentWorkOrder: IEntityObject = executionContext?.currentWorkOrder!;

  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();
  const { OrderNumberProp } = getOperationsOverlayPropertyNames();

  const isTableView = uiService.getIsTableView()();

  return (
    <GridHeader className={clsx([styles.root, { [styles.mobile]: isMobile }])}>
      {currentWorkOrder && (
        <div className={styles.workOrderInfo}>
          <div> {t("common.label:workorders")}:</div>
          <div className={styles.id}>
            {currentWorkOrder[OrderNumberProp]?.toString()}
          </div>
        </div>
      )}
      {!isMobile && (
        <GridActions>
          {isTableView && (
            <>
              <ColumnSelectorDropdown />
            </>
          )}
          {uiService.renderGridActions()()}
        </GridActions>
      )}
    </GridHeader>
  );
};
