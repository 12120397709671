import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import { IAdapterUiContextState } from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { OracleEbsAdapterService } from "../../../../../../services/oracle-ebs";
import { QueryAttributeDto } from "@river/interfaces";

export function useOracleEbsResolutionCodeLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    return {
      title: t("shared.lookup:resolution_code.title"),
      rowKey: "RESOLUTION_CODE",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "RESOLUTION_CODE",
            name: t("entity.resolution_code:resolution_code.RESOLUTION_CODE"),
          },
          {
            key: "DESCRIPTION",
            name: t("entity.resolution_code:resolution_code.DESCRIPTION"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as OracleEbsAdapterService
          ).searchEntityData(
            "resolution_code",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [...(customFilters || [])],
            })
          );
        },
        saveKey: "resolution_code.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "resolution_code",
      },
    };
  };
}

export type IUseOracleEbsResolutionCodeLookupDef = ReturnType<
  typeof useOracleEbsResolutionCodeLookupDef
>;
