import React, { ReactElement, useContext, useState, useEffect } from "react";

import {
  GridHeader,
  GridActions,
  DeleteAction,
  GenericAction,
  FilterDropdown,
} from "../../../shared";
import {
  TableContext,
  TabContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../../context";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AddIcon from "@mui/icons-material/Add";
import {
  IEntity,
  IAttribute,
  IRelation,
  IIndex,
  IEntityObject,
} from "@river/interfaces";
import { EntityAttributeDialog } from "../../entity-attribute-dialog";
import { EntityRelationDialog } from "../../entity-relation-dialog";
import { EntityIndexDialog } from "../../entity-index-dialog";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import { uiConstants } from "../../../../helpers";
import { RiverTabs } from "../../../shared";
import styles from "./entity-dialog-grid-header.module.scss";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../../protected-action";
import { ModuleKey } from "../../../sidebar-menu";
import { DataDictionaryAction } from "../../data-dict";

export enum DataDictDetailsTabId {
  ENTITY_DETAILS = "entity_details",
  ATTRIBUTES = "attributes",
  RELATIONS = "relations",
  INDEXES = "indexes",
}
export const DEFAULT_DATA_DICT_DETAILS_TAB_ID =
  DataDictDetailsTabId.ENTITY_DETAILS;

interface IEntityDialogGridHeaderProps {
  className?: string;
  entity?: IEntity | null;
}

export const EntityDialogGridHeader: React.FC<IEntityDialogGridHeaderProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();

  const tableContext = useContext(TableContext);
  const tabContext = useContext(TabContext);
  const notify = useNotification();
  const [showEntityAttributeDialog, setShowEntityAttributeDialog] =
    useState<boolean>(false);
  const [showEntityRelationDialog, setShowEntityRelationDialog] =
    useState<boolean>(false);
  const [showEntityIndexDialog, setShowEntityIndexDialog] =
    useState<boolean>(false);
  const [selectedEntityObject, setSelectedEntityObject] =
    useState<IEntityObject | null>(null);

  const clearSelectedObject = (): void => {
    setSelectedEntityObject(null);
  };

  useEffect(() => {
    if (tableContext?.table) {
      clearSelectedObject();
      const selectedId: string = Array.from(
        tableContext?.table.selectedRowIds
      )[0];
      if (selectedId) {
        const selectedObject: IEntityObject =
          tableContext?.table.entities.filter(
            (row) => row[uiConstants.fields._id] === selectedId
          )[0];
        setSelectedEntityObject(selectedObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContext?.table.selectedRowIds]);

  const deleteSelectedAttribute = async (): Promise<void> => {
    const selectedAttribute: IAttribute =
      selectedEntityObject as any as IAttribute;
    try {
      await adapterContext!.service
        .getAdapterService()
        .deleteEntityAttributeDefinition(
          selectedAttribute.entity_name,
          selectedAttribute._id
        );
      tableContext?.table.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      deleteAttributeConfirmationDialog.close();
    }
  };

  const deleteSelectedRelation = async (): Promise<void> => {
    const selectedRelation: IRelation =
      selectedEntityObject as any as IRelation;
    try {
      await adapterContext!.service
        .getAdapterService()
        .deleteEntityRelationDefinition(
          selectedRelation.entity_name,
          selectedRelation._id
        );
      tableContext?.table.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      deleteRelationConfirmationDialog.close();
    }
  };

  const deleteSelectedIndex = async (): Promise<void> => {
    const selectedIndex: IIndex = selectedEntityObject as any as IIndex;
    try {
      await adapterContext!.service
        .getAdapterService()
        .deleteEntityIndexDefinition(
          selectedIndex.entity_name,
          selectedIndex._id
        );
      tableContext?.table.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      deleteIndexConfirmationDialog.close();
    }
  };

  const deleteAttributeConfirmationDialog = useSimpleDialog({
    title: t("module.data_dictionary:message.delete_attribute.title"),
    message: t("module.data_dictionary:message.confirm_attribute_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedAttribute,
  });

  const deleteRelationConfirmationDialog = useSimpleDialog({
    title: t("module.data_dictionary:message.delete_relation.title"),
    message: t("module.data_dictionary:message.confirm_relation_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedRelation,
  });

  const deleteIndexConfirmationDialog = useSimpleDialog({
    title: t("module.data_dictionary:message.delete_index.title"),
    message: t("module.data_dictionary:message.confirm_index_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedIndex,
  });
  return (
    <>
      <GridHeader className={styles.root}>
        <RiverTabs
          tabs={[
            {
              label: t("module.data_dictionary:tab.entity_details.title"),
              icon: <ImportContactsIcon />,
              value: DataDictDetailsTabId.ENTITY_DETAILS,
            },
          ].concat(
            props.entity
              ? [
                  {
                    label: t("module.data_dictionary:tab.attributes.title"),
                    icon: <FontDownloadIcon />,
                    value: DataDictDetailsTabId.ATTRIBUTES,
                  },
                  {
                    label: t("module.data_dictionary:tab.relations.title"),
                    icon: <SwapHorizIcon />,
                    value: DataDictDetailsTabId.RELATIONS,
                  },
                  {
                    label: t("module.data_dictionary:tab.indexes.title"),
                    icon: <PermDeviceInformationIcon />,
                    value: DataDictDetailsTabId.INDEXES,
                  },
                ]
              : []
          )}
          onChange={() => {
            tableContext?.table.setSelectedRowIds(new Set());
          }}
        />

        <GridActions>
          {tabContext?.selectedTab !== DataDictDetailsTabId.ENTITY_DETAILS && (
            <FilterDropdown />
          )}
          {tabContext?.selectedTab === DataDictDetailsTabId.ATTRIBUTES && (
            <>
              <ProtectedAction
                module={ModuleKey.DATA_DICTIONARY}
                action={DataDictionaryAction.CREATE_ATTRIBUTE}
              >
                <GenericAction
                  icon={AddIcon}
                  title={t("module.data_dictionary:label.new_attribute")}
                  onClick={() => {
                    clearSelectedObject();
                    tableContext?.table.setSelectedRowIds(new Set());
                    setShowEntityAttributeDialog(true);
                  }}
                />
              </ProtectedAction>
              <ProtectedAction
                module={ModuleKey.DATA_DICTIONARY}
                action={DataDictionaryAction.DELETE_ATTRIBUTE}
              >
                <DeleteAction
                  onClick={() => deleteAttributeConfirmationDialog.open()}
                />
              </ProtectedAction>
            </>
          )}
          {tabContext?.selectedTab === DataDictDetailsTabId.RELATIONS && (
            <>
              {props.entity?.is_schema && (
                <>
                  <ProtectedAction
                    module={ModuleKey.DATA_DICTIONARY}
                    action={DataDictionaryAction.CREATE_RELATION}
                  >
                    <GenericAction
                      icon={AddIcon}
                      title={t("module.data_dictionary:label.new_relation")}
                      onClick={() => {
                        clearSelectedObject();
                        tableContext?.table.setSelectedRowIds(new Set());
                        setShowEntityRelationDialog(true);
                      }}
                    />
                  </ProtectedAction>
                  <ProtectedAction
                    module={ModuleKey.DATA_DICTIONARY}
                    action={DataDictionaryAction.DELETE_RELATION}
                  >
                    <DeleteAction
                      onClick={() => deleteRelationConfirmationDialog.open()}
                    />
                  </ProtectedAction>
                </>
              )}
            </>
          )}
          {tabContext?.selectedTab === DataDictDetailsTabId.INDEXES && (
            <>
              {!!props.entity?.collection_name && (
                <>
                  <ProtectedAction
                    module={ModuleKey.DATA_DICTIONARY}
                    action={DataDictionaryAction.CREATE_INDEX}
                  >
                    <GenericAction
                      icon={AddIcon}
                      title={t("module.data_dictionary:label.new_index")}
                      onClick={() => {
                        clearSelectedObject();
                        tableContext?.table.setSelectedRowIds(new Set());
                        setShowEntityIndexDialog(true);
                      }}
                    />
                  </ProtectedAction>
                  <ProtectedAction
                    module={ModuleKey.DATA_DICTIONARY}
                    action={DataDictionaryAction.DELETE_INDEX}
                  >
                    <DeleteAction
                      onClick={() => deleteIndexConfirmationDialog.open()}
                    />
                  </ProtectedAction>
                </>
              )}
            </>
          )}
        </GridActions>
      </GridHeader>

      <EntityRelationDialog
        open={showEntityRelationDialog}
        entityName={props.entity?.entity_name!}
        relation={selectedEntityObject as any as IRelation}
        onClose={(success) => {
          setShowEntityRelationDialog(false);
          if (success) {
            tableContext?.table.refresh();
          }
        }}
      />
      <EntityAttributeDialog
        open={showEntityAttributeDialog}
        entityName={props.entity?.entity_name!}
        attribute={selectedEntityObject as any as IAttribute}
        onClose={(success) => {
          setShowEntityAttributeDialog(false);
          if (success) {
            tableContext?.table.refresh();
          }
        }}
      />
      <EntityIndexDialog
        open={showEntityIndexDialog}
        entityName={props.entity?.entity_name!}
        index={selectedEntityObject as any as IIndex}
        onClose={(success) => {
          setShowEntityIndexDialog(false);
          if (success) {
            tableContext?.table.refresh();
          }
        }}
      />
      {deleteAttributeConfirmationDialog.render()}
      {deleteRelationConfirmationDialog.render()}
      {deleteIndexConfirmationDialog.render()}
    </>
  );
};
