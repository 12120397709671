import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";

export function useSystemConditionsLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:system_condition.title"),
      rowKey: "Systcond",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "Systcond",
            name: t("entity.system_condition:system_condition.Systcond"),
          },
          {
            key: "PmReserved",
            name: t("entity.system_condition:system_condition.PmReserved"),
          },
          {
            key: "Description",
            name: t("entity.system_condition:system_condition.Description"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).fetchSystemConditions(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        saveKey: "system_conditions.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "system_condition",
      },
    };
  };
}

export type IUseSystemConditionsLookupDef = ReturnType<
  typeof useSystemConditionsLookupDef
>;
