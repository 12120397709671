export enum ScheduleGanttTimeOptionGroupID {
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
}

export enum ScheduleGanttTimeOptionID {
  _24HOURS = "24hours",
  _48HOURS = "48hours",
  _72HOURS = "72hours",
  _7DAYS = "7days",
  _14DAYS = "14days",
  _4WEEKS = "4weeks",
  _8WEEKS = "8weeks",
  _3MONTHS = "3months",
  _6MONTHS = "6months",
  _12MONTHS = "12months",
}

export interface IScheduleGanttTimeOptionGroup {
  id: ScheduleGanttTimeOptionGroupID;
  label: string;
  options: IScheduleGanttTimeOption[];
}

export interface IScheduleGanttTimeOption {
  groupId: ScheduleGanttTimeOptionGroupID;
  id: ScheduleGanttTimeOptionID;
  value: number;
  label: string;
}
