import React, { ReactElement } from "react";
import { RiverDropdownActions } from "../../../components/shared";
import {
  useCreateDependenciesAction,
  useCreateSingleDependencyAction,
  useDeleteDependenciesAction,
} from "./index";
import { ModuleKey } from "../../../components/sidebar-menu";

export const useDependenciesDropdownActions = () => {
  const { createDependenciesAction } = useCreateDependenciesAction();
  const { deleteDependenciesAction } = useDeleteDependenciesAction();
  const { createSingleDependencyAction } = useCreateSingleDependencyAction();

  const renderDependenciesDropdownActions = (): ReactElement => (
    <>
      <RiverDropdownActions
        module={ModuleKey.SCHEDULES}
        items={[
          {
            title: createDependenciesAction.title,
            startIcon: createDependenciesAction.icon,
            onClick: createDependenciesAction.onClick,
          },
          {
            title: deleteDependenciesAction.title,
            startIcon: deleteDependenciesAction.icon,
            onClick: deleteDependenciesAction.onClick,
          },
          {
            title: createSingleDependencyAction.title,
            startIcon: createSingleDependencyAction.icon,
            onClick: createSingleDependencyAction.onClick,
          },
        ]}
      />
      {createSingleDependencyAction.renderDialog()}
    </>
  );

  return {
    renderDependenciesDropdownActions,
  };
};

export type IUseDependenciesDropdownActions = ReturnType<
  typeof useDependenciesDropdownActions
>;
