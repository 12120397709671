import React, { ReactElement, MouseEventHandler } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import styles from "./schedule-operations-gantt-overlay.module.scss";
import clsx from "clsx";

export enum GanttConstantID {
  MIN_HORIZONTAL_CONNECTOR_LENGTH = "MIN_HORIZONTAL_CONNECTOR_LENGTH",
  MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH = "MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH",
  OUTWARD_CONNECTOR_Y_OFFSET = "OUTWARD_CONNECTOR_Y_OFFSET",
  INWARD_CONNECTOR_Y_OFFSET = "INWARD_CONNECTOR_Y_OFFSET",
  MIN_SCHEDULE_TASK_WIDTH = "MIN_SCHEDULE_TASK_WIDTH",
  FINISH_TO_START_VIRTUAL_X_OFFSET = "FINISH_TO_START_VIRTUAL_X_OFFSET",
  START_TO_START_VIRTUAL_X_OFFSET = "START_TO_START_VIRTUAL_X_OFFSET",
}

export type GanttConstants = { [key in GanttConstantID]: number };

export const ganttAbsoluteConstants: GanttConstants = {
  [GanttConstantID.MIN_HORIZONTAL_CONNECTOR_LENGTH]: 9,
  [GanttConstantID.MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH]: 12,
  [GanttConstantID.OUTWARD_CONNECTOR_Y_OFFSET]: 4,
  [GanttConstantID.INWARD_CONNECTOR_Y_OFFSET]: -1,
  [GanttConstantID.MIN_SCHEDULE_TASK_WIDTH]: 10,
  [GanttConstantID.FINISH_TO_START_VIRTUAL_X_OFFSET]: 6,
  [GanttConstantID.START_TO_START_VIRTUAL_X_OFFSET]: -8,
};

export interface IRelationConnectorEvents {
  onConnectorMouseOver?: MouseEventHandler;
  onConnectorMouseOut?: MouseEventHandler;
  onConnectorClick?: MouseEventHandler;
}

export enum LineArrow {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface IHorizontalLine extends IRelationConnectorEvents {
  yPos: number;
  startXPercent: number;
  endXPercent: number;
  startXAbsoluteOffset?: number;
  endXAbsoluteOffset?: number;
  arrow?: LineArrow.LEFT | LineArrow.RIGHT;
}

export interface IVerticalLine extends IRelationConnectorEvents {
  xPercent: number;
  xAbsoluteOffset?: number;
  yStart: number;
  yEnd: number;
}

const renderHorizontalArrow = (props: IHorizontalLine): ReactElement => {
  const isLeft: boolean = props.arrow === LineArrow.LEFT;
  const isRight: boolean = props.arrow === LineArrow.RIGHT;
  const Arrow = isLeft ? ArrowLeftIcon : ArrowRightIcon;
  return (
    <>
      {props.arrow && (
        <Arrow
          className={clsx([
            styles.arrow,
            {
              [styles.left]: isLeft,
              [styles.right]: isRight,
            },
          ])}
        />
      )}
    </>
  );
};

export const renderHorizontalLine = (props: IHorizontalLine): ReactElement => {
  const {
    startXPercent,
    endXPercent,
    startXAbsoluteOffset = 0,
    endXAbsoluteOffset = 0,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
    yPos,
  } = props;

  const leftCss: string = `calc(
    ${startXPercent}% + ${startXAbsoluteOffset}px
  )`;
  const widthCss: string = `calc(
    (${endXPercent}% + ${endXAbsoluteOffset}px) - 
    (${startXPercent}% + ${startXAbsoluteOffset}px))
  `;

  return (
    <>
      <div
        className={clsx([styles.connectorLine, styles.horizontal])}
        style={{
          top: yPos,
          left: leftCss,
          width: widthCss,
        }}
      >
        {renderHorizontalArrow(props)}
      </div>
      <div
        className={clsx([styles.connectorLineBackground, styles.horizontal])}
        style={{
          top: yPos - 1,
          left: leftCss,
          width: widthCss,
        }}
        onMouseOver={onConnectorMouseOver}
        onMouseOut={onConnectorMouseOut}
        onClick={onConnectorClick}
      />
    </>
  );
};

export const renderVerticalLine = (props: IVerticalLine): ReactElement => {
  const { yStart, yEnd, xPercent, xAbsoluteOffset = 0 } = props;
  const top: number = Math.min(yStart, yEnd);
  const height: number = Math.abs(yEnd - yStart);
  return (
    <>
      <div
        className={clsx([styles.connectorLine, styles.vertical])}
        style={{
          top,
          height,
          left: `calc(${xPercent}% + ${xAbsoluteOffset}px)`,
        }}
      />
      <div
        className={clsx([styles.connectorLineBackground, styles.vertical])}
        style={{
          top,
          height,
          left: `calc(${xPercent}% + ${xAbsoluteOffset - 1}px)`,
        }}
        onMouseOver={props.onConnectorMouseOver}
        onMouseOut={props.onConnectorMouseOut}
        onClick={props.onConnectorClick}
      />
    </>
  );
};
