import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { supervisorUtilizationHelpers } from "../../components/supervisor-schedule/supervisor-schedule-utilization/supervisor-utilization-helpers";
import * as ganttUtils from "../../components/supervisor-schedule/supervisor-schedule-gantt/supervisor-schedule-gantt-utils";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "./supervisor-schedule-utilization-ui-service";
import {
  IAdapterFolder,
  UtilizationPeriodType,
  IEntityObject,
} from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";

interface IUseSupervisorScheduleUtilizationPersonsProps {
  selectedPersonIds: Set<string>;
}

export const useSupervisorScheduleUtilizationPersons = (
  props: IUseSupervisorScheduleUtilizationPersonsProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const utilizationPeriodType: UtilizationPeriodType =
    supervisorUtilizationHelpers.getUtilizationPeriodType(scheduleContext!);
  const utilizationPeriods: number =
    supervisorUtilizationHelpers.getUtilizationPeriods(scheduleContext!);

  const fetchPersonUtilization = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    const wcLabor: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .fetchCraftPeople(
        fetchHelpers.getTableQuery({
          fetchProps: {
            columnFilters: [],
            sortColumns: [],
            ids: Array.from(props.selectedPersonIds),
          },
        })
      );
    const resources: Set<string> = new Set();
    wcLabor.forEach((row) => {
      resources.add(String(row[uiConstants.fields._id]));
    });

    fetchProps.ids = Array.from(resources);

    return await adapterContext!.service
      .getAdapterService()
      .fetchResourceUtilization(
        currentSchedule._id,
        null, // baseline id
        ganttUtils.getGanttStart(
          scheduleContext?.selectedGanttDate!,
          scheduleContext?.ganttTimeOption?.groupId!,
          new Date(scheduleContext?.currentSchedule?.start_date!)
        ),
        utilizationPeriodType,
        utilizationPeriods,
        fetchHelpers.getTableQuery({
          fetchProps,
          idPropName: uiConstants.fields._id,
        })
      );
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "person_utilization",
      columns: uiService.getDefaultPersonUtilizationColumns()(),
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    entityName,
    saveKey: "supervisor.schedule.utilization.persons",
    columns,
    fetchFunction: fetchPersonUtilization,
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.PERSONS,
    dependencies: [
      !!site,
      !!utilizationPeriodType,
      !!currentSchedule,
      currentTab === SupervisorScheduleUtilizationTabId.PERSONS,
    ],
    clearBeforeFetch: true,
    fetchTriggers: [
      site,
      utilizationPeriodType,
      utilizationPeriods,
      currentSchedule,
      props.selectedPersonIds,
      scheduleContext?.selectedGanttDate,
    ],
    useAdvancedFilters: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
