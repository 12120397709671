import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ProgressSummaryFilterID } from "../../../../schedule-utilization-ui-service";
import { QueryAttributeGroupDto } from "@river/interfaces";

interface IOracleEbsScheduleProgressResourceFiltersProps {
  rows: any[];
  onSelect: (selectedObjects: any[], filterId: ProgressSummaryFilterID) => void;
  onDelete: (selectedObject: any, filterId: ProgressSummaryFilterID) => void;
}

export const OracleEbsScheduleProgressResourceFilters: React.FC<
  IOracleEbsScheduleProgressResourceFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const createCraftExclusionQuery = (craft: any): QueryAttributeGroupDto => ({
    $or: [
      {
        attribute_name: "DEPARTMENT_ID",
        attribute_value: { operator: "$ne", value: craft.DEPARTMENT_ID },
      },
      {
        attribute_name: "RESOURCE_ID",
        attribute_value: { operator: "$ne", value: craft.RESOURCE_ID },
      },
    ],
  });

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "RESOURCE_CODE",
          name: t(
            "entity.department_resource:department_resource.RESOURCE_CODE"
          ),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.CRAFTS,
        customQuery: {
          $and: props.rows.map((craft) => createCraftExclusionQuery(craft)),
        },
      }}
      onDelete={(selected) =>
        props.onDelete(selected, ProgressSummaryFilterID.RESOURCES)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ProgressSummaryFilterID.RESOURCES);
      }}
      addFilterLabel={t(
        "module.schedule:utilization_filters.add_resource_filter"
      )}
    />
  );
};
