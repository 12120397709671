import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import { IAdapterCalendar } from "@river/interfaces";
import {
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";

export function useCalendarLookupDef() {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:calendar.title"),
      rowKey: "calendar",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "calendar",
            name: t("entity.calendar:calendar.calendar"),
            width: 120,
          },
          {
            key: "description",
            name: t("entity.calendar:calendar.description"),
            width: 300,
          },
          {
            key: "num_of_days",
            name: t("module.calendars:label.number_of_days"),
            formatter: (formatterProps) => (
              <>
                {renderTextCell({
                  formatterProps,
                  value: (formatterProps.row as IAdapterCalendar).template
                    ?.length,
                })}
              </>
            ),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .fetchCalendars(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        saveKey: "calendar.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "calendar",
      },
    };
  };
}
export type IUseCalendarLookupDef = ReturnType<typeof useCalendarLookupDef>;
