import { SitesUiService } from "../sites-ui.service";
import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { IUserContextSite } from "../../context";

export class SapSitesUiService extends SitesUiService {
  protected getSiteEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "planning_plant",
      columns: [
        {
          key: "PlanningPlant",
          name: t("entity.planning_plant:planning_plant.PlanningPlant"),
        },
        {
          key: "CompCode",
          name: t("entity.planning_plant:planning_plant.CompCode"),
        },
        {
          key: "CountryCode",
          name: t("entity.planning_plant:planning_plant.CountryCode"),
        },
        { key: "Name1", name: t("entity.planning_plant:planning_plant.Name1") },
      ],
    };
  };

  getSitePropertyFromErpRecord = (siteRec: any): IUserContextSite => {
    if (!siteRec) {
      return {
        site_id: "",
        site_code: "",
        description: "",
      };
    }
    const { PlanningPlant, Name1 } = siteRec;
    return {
      site_id: PlanningPlant,
      site_code: PlanningPlant,
      description: Name1,
    };
  };

  protected getSiteRowKey = (siteRec: any): any => {
    return siteRec[this.getSiteKeyColumn()];
  };

  getSiteKeyColumn = (): string => "PlanningPlant";
}
