import React from "react";
import {
  RiverSpinner,
  RiverTextInput,
  RiverCheckbox,
  RiverFormContext,
} from "@river/common-ui";
import { RiverFormInstance } from "../../../../hooks";
import styles from "./entity-details-tab.module.scss";

export interface IEntityDetailsTabProps {
  form: RiverFormInstance;
}

export const EntityDetailsTab: React.FC<IEntityDetailsTabProps> = (props) => {
  const form: RiverFormInstance = props.form;
  const { isProcessing } = form;

  return (
    <RiverFormContext.Provider value={{ form }}>
      <div className={styles.root}>
        <RiverSpinner show={isProcessing} />
        <div className={styles.leftSection}>
          <RiverTextInput id={"entity_name"} />
          <RiverTextInput
            id="description"
            inputProps={{
              multiline: true,
              minRows: 4,
              maxRows: 6,
            }}
          />
          <RiverTextInput id="collection_name" />
        </div>
        <div className={styles.rightSection}>
          <RiverCheckbox id={"is_readonly"} />
          <RiverCheckbox id={"is_schema"} />
          <RiverCheckbox id={"add_timestamps"} />
          <RiverCheckbox id={"add_id"} />
        </div>
      </div>
    </RiverFormContext.Provider>
  );
};
