import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { CronTaskAuthDto } from "@river/interfaces";
import { AdapterUiContext } from "../../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";

export const useCronAuthenticationDialogForm = (props: {
  cronAuth: CronTaskAuthDto | null;
  onUpdate(): void;
  onCreate(): void;
}): RiverFormInstance => {
  const { cronAuth, onUpdate, onCreate } = props;
  const { t } = useTranslation();
  const adapterContext = useContext(AdapterUiContext);

  return useRiverForm<CronTaskAuthDto, CronTaskAuthDto, Object>({
    initialDto: Object.assign(new CronTaskAuthDto(), {
      username: "",
      password: "",
    }),
    instanceToEdit: cronAuth,
    onUpdate,
    update: async (dto) => {
      await adapterContext!.service
        .getAdapterService()
        .updateCronTaskAuthForEnvironment(dto);
    },
    onCreate,
    create: async (dto) => {
      await adapterContext!.service
        .getAdapterService()
        .updateCronTaskAuthForEnvironment(dto);

      return dto;
    },
    labels: {
      cron: t("module.cron:cron_authentication.edit"),
    },
  });
};
