import { useContext } from "react";
import {
  IUseRiverAsyncProgress,
  useRiverAsyncProgress,
} from "./river-async-progress";
import {
  AdapterPostActionDto,
  IAdapterAsyncAction,
  IAdapterFolder,
  AdapterFolderStatusDto,
  StatusDto,
} from "@river/interfaces";
import { useNotification, useTranslation } from "@river/common-ui";
import { Constants } from "@river/constants";
import { AdapterUiContext, ScheduleContext } from "../../../context";
import { AdapterService } from "../../../services";

export const useAsyncPost = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const scheduleContext = useContext(ScheduleContext)!;
  const schedule: IAdapterFolder = scheduleContext.currentSchedule!;
  const statusCode: string = schedule.status.code;
  const table = scheduleContext?.currentTasksTableRef.current!;
  const adapterContext = useContext(AdapterUiContext);
  const adapter: AdapterService = adapterContext!.service.getAdapterService();

  const asyncPostProgress: IUseRiverAsyncProgress = useRiverAsyncProgress({
    title: t("shared.async_actions:post.progress_title"),
    onSuccess: async () => {
      await table.refresh();
      await table?.clearFilters();
      scheduleContext.refreshCurrentSchedule();

      if (statusCode === Constants.schedule_statuses.draft) {
        table?.forceLoadingState(true);
        await adapter.updateFolderStatus(schedule._id, {
          status: {
            code: Constants.schedule_statuses.committed,
          } as StatusDto,
        } as AdapterFolderStatusDto);
        table?.forceLoadingState(false);
        scheduleContext.refreshCurrentSchedule();
      }
    },
  });

  const doAsyncPost = async (): Promise<void> => {
    try {
      const asyncAction: IAdapterAsyncAction = await adapter.post({
        folder_id: schedule._id,
      } as AdapterPostActionDto);
      asyncPostProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    asyncPostProgress,
    doAsyncPost,
  };
};

export type IUseAsyncPost = ReturnType<typeof useAsyncPost>;
