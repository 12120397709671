import React, { SVGProps } from "react";

export const UnlockIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_1931_625"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_1931_625)">
        <path d="M16.8 16.8C17.3 16.8 17.725 16.625 18.075 16.275C18.425 15.925 18.6 15.5 18.6 15C18.6 14.5 18.425 14.075 18.075 13.725C17.725 13.375 17.3 13.2 16.8 13.2C16.3 13.2 15.875 13.375 15.525 13.725C15.175 14.075 15 14.5 15 15C15 15.5 15.175 15.925 15.525 16.275C15.875 16.625 16.3 16.8 16.8 16.8ZM16.8 20.4C17.4333 20.4 18.0208 20.2404 18.5625 19.9212C19.1041 19.6021 19.5416 19.1783 19.875 18.65C19.4083 18.35 18.9185 18.1167 18.4057 17.95C17.8929 17.7834 17.3596 17.7 16.8057 17.7C16.2519 17.7 15.7166 17.7834 15.2 17.95C14.6833 18.1167 14.1916 18.35 13.725 18.65C14.0583 19.1783 14.4958 19.6021 15.0375 19.9212C15.5791 20.2404 16.1666 20.4 16.8 20.4ZM12 21.6C9.78328 21.1 7.92912 19.8459 6.43745 17.8375C4.94578 15.8292 4.19995 13.5917 4.19995 11.125V5.40002L12 2.40002L19.8 5.40002V10.925C19.4333 10.7584 19.1083 10.625 18.825 10.525C18.5416 10.425 18.2666 10.3584 18 10.325V6.62502L12 4.32502L5.99995 6.62502V11.125C5.99995 11.9417 6.10412 12.7417 6.31245 13.525C6.52078 14.3084 6.82495 15.0459 7.22495 15.7375C7.62495 16.4292 8.11245 17.0625 8.68745 17.6375C9.26245 18.2125 9.90829 18.7167 10.625 19.15C10.7583 19.5334 10.9708 19.9375 11.2625 20.3625C11.5541 20.7875 11.875 21.1834 12.225 21.55L12 21.6ZM16.7943 21.6C15.4647 21.6 14.3333 21.1315 13.4 20.1944C12.4666 19.2573 12 18.1239 12 16.7944C12 15.4648 12.4685 14.3334 13.4056 13.4C14.3427 12.4667 15.476 12 16.8056 12C18.1352 12 19.2666 12.4686 20.2 13.4057C21.1333 14.3428 21.6 15.4761 21.6 16.8057C21.6 18.1352 21.1314 19.2667 20.1943 20.2C19.2572 21.1334 18.1239 21.6 16.7943 21.6Z" />
      </g>
    </svg>
  );
};
