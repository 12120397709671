import React, { SVGProps } from "react";

export const CalendarStarIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1931_2083"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_1931_2083)">
      <path d="M12.0001 18.9L10.8751 16.425L8.4001 15.3L10.8751 14.175L12.0001 11.7L13.1251 14.175L15.6001 15.3L13.1251 16.425L12.0001 18.9ZM5.4001 21.6C4.9051 21.6 4.48135 21.4209 4.12885 21.0625C3.77635 20.7042 3.6001 20.2834 3.6001 19.8V6.60002C3.6001 6.11669 3.77635 5.69586 4.12885 5.33752C4.48135 4.97919 4.9051 4.80002 5.4001 4.80002H7.2001V2.40002H9.0001V4.80002H15.0001V2.40002H16.8001V4.80002H18.6001C19.0951 4.80002 19.5188 4.97919 19.8713 5.33752C20.2238 5.69586 20.4001 6.11669 20.4001 6.60002V19.8C20.4001 20.2834 20.2238 20.7042 19.8713 21.0625C19.5188 21.4209 19.0951 21.6 18.6001 21.6H5.4001ZM5.4001 19.8H18.6001V10.8H5.4001V19.8ZM5.4001 9.00002H18.6001V6.60002H5.4001V9.00002Z" />
    </g>
  </svg>
);
