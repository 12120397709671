import {
  IUseRiverAsyncProgress,
  useRiverAsyncProgress,
  IUseTable,
} from "../../../components/shared";
import { IAdapterAsyncAction, IAdapterFolder } from "@river/interfaces";
import { useNotification, useTranslation } from "@river/common-ui";
import { useContext, useState } from "react";
import { AdapterUiContext } from "../../../context";
import { AdapterService } from "../../../services";

export const useAsyncAutoSchedule = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [taskTable, setTaskTable] = useState<IUseTable>();
  const [utilizationTable, setUtilizationTable] = useState<IUseTable>();
  const adapterContext = useContext(AdapterUiContext);

  const autoScheduleProgress: IUseRiverAsyncProgress = useRiverAsyncProgress({
    title: t("shared.async_actions:auto_schedule.progress_title"),
    onSuccess: () =>
      notify.success(t("module.schedule:notification.auto_schedule_completed")),
    onComplete: () => {
      taskTable!.refresh();
      utilizationTable!.setSelectedRowIds(new Set());
      utilizationTable!.refresh();
    },
  });

  const doAutoSchedule = async (opts: {
    schedule: IAdapterFolder;
    taskTable: IUseTable;
    utilizationTable: IUseTable;
  }): Promise<void> => {
    const {
      schedule: currentSchedule,
      taskTable: currentTaskTable,
      utilizationTable: currentUtilizationTable,
    } = opts;
    const adapter: AdapterService = adapterContext!.service.getAdapterService();

    try {
      currentTaskTable.forceLoadingState(true);
      const asyncAction: IAdapterAsyncAction = await adapter.autoSchedule(
        currentSchedule._id
      );
      setTaskTable(currentTaskTable);
      setUtilizationTable(currentUtilizationTable);
      autoScheduleProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    autoScheduleProgress,
    doAutoSchedule,
  };
};

export type IUseAsyncAutoSchedule = ReturnType<typeof useAsyncAutoSchedule>;
