import { useContext, FC } from "react";
import { RiverSpinner } from "@river/common-ui";
import { useEntity, useTableCellRenderers } from "../../hooks";
import { useNavigate } from "react-router";
import { Column } from "react-data-grid";
import { IAdapterFolder, IEntityObject } from "@river/interfaces";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useRiverSelectColumn,
  useTable,
} from "../shared";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  AdapterUiContext,
} from "../../context";
import { SupervisorSchedulesHeader } from "./supervisor-schedules-header";
import { SupervisorSchedulesGridHeader } from "./supervisor-schedules-grid-header";
import { useTranslation } from "@river/common-ui";
import { Constants } from "@river/constants";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./supervisor-schedules.module.scss";

export const SupervisorSchedules: FC = () => {
  const entityName: string = "folder";
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const userContext = useContext(AdapterUserContext);
  const adapterContext = useContext(AdapterUiContext);

  const site = userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity = useEntity({ entityName });
  const navigate = useNavigate();

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "folder",
      name: t("entity.folder:folder.folder"),
      width: 170,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  navigate(
                    `/supervisor/schedules/${
                      formatterProps.row[uiConstants.fields._id]
                    }`
                  );
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "start_date",
      name: t("entity.folder:folder.start_date"),
      width: 160,
    },
    { key: "end_date", name: t("entity.folder:folder.end_date"), width: 160 },
    { key: "duration", name: t("entity.folder:folder.duration"), width: 90 },
    { key: "status.code", name: t("entity.status_ref:status_ref.code") },
    { key: "workorder_count", name: t("entity.folder:folder.workorder_count") },
    { key: "operation_count", name: t("entity.folder:folder.operation_count") },
    { key: "completed_pct", name: t("entity.folder:folder.completed_pct") },
  ];

  const fetchSchedules = async (fetchProps: ITableFetchFunctionProps) => {
    return await adapterContext!.service.getAdapterService().fetchFolders(
      fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributes: [
          {
            attribute_name: "status.code",
            attribute_value: {
              operator: "$ne",
              value: Constants.folder_status.draft,
            },
          },
        ],
      })
    );
  };

  const table = useTable({
    entityName,
    saveKey: "supervisor.schedules",
    columns,
    fetchFunction: fetchSchedules,
    dependencies: [!!site],
    fetchOn: true,
    fetchTriggers: [site],
    infiniteScrolling: true,
  });

  const getSelectedSchedule = () => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterFolder;
  };

  const isLoading = table.isLoading || !site;
  return (
    <div className={styles.container}>
      <TableContext.Provider value={{ table, entity }}>
        <div className={styles.root}>
          <SupervisorSchedulesHeader />
          <SupervisorSchedulesGridHeader
            selectedSchedule={getSelectedSchedule()}
          />
          <RiverSpinner show={isLoading} />
          <RiverDataGrid
            disableSelectAll={true}
            singleSelect={true}
            columns={table.columns}
            rows={table.entities}
            rowKeyGetter={(row) => row[uiConstants.fields._id]}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            sortColumns={table.sortColumns}
            onSortColumnsChange={(e) => {
              table.setSortColumns(e);
            }}
          />
        </div>
      </TableContext.Provider>
    </div>
  );
};
