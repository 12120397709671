import { ISystemCronTaskWithEnv, ISystemEnvCronTask } from "@river/interfaces";
import { createContext } from "react";

export interface ISystemCronTasksContext {
  openDialog: boolean;
  setOpenDialog(v: boolean): void;
  openOptionsDialog: boolean;
  setOpenOptionsDialog(v: boolean): void;
  selected: ISystemCronTaskWithEnv | null;
  setSelected(v: ISystemCronTaskWithEnv | null): void;
}

export const SystemCronTasksContext =
  createContext<ISystemCronTasksContext | null>(null);

export interface ICustomCronTasksContext {
  openDialog: boolean;
  setOpenDialog(v: boolean): void;
  selected: ISystemEnvCronTask | null;
  setSelected(v: ISystemEnvCronTask | null): void;
}

export const CustomCronTasksContext =
  createContext<ICustomCronTasksContext | null>(null);
