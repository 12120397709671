import { Constants } from "@river/constants";
import { IAttribute } from "@river/interfaces";
import { FormatterProps, EditorProps } from "react-data-grid";
import { TableContext } from "../../../context";
import {
  RiverDefaultTextFormatter,
  RiverDefaultBooleanFormatter,
} from "./cell-formatters";
import {
  RiverDefaultCheckboxEditor,
  RiverDefaultValueListEditor,
  RiverDefaultMultiSelectEditor,
} from "./cell-editors";

export function useFieldFormatter() {
  const getFieldFormatter = (
    formatterProps: FormatterProps<any>
  ): JSX.Element | null => {
    const { column } = formatterProps;
    return (
      <TableContext.Consumer>
        {(tableContext) => {
          const attribute: IAttribute =
            tableContext?.entity.attributesByName.get(column.key)!;
          const editorProps: EditorProps<any> =
            formatterProps as unknown as EditorProps<any>;

          // First try to render formatter based on input_type, if specified
          if (
            attribute?.input_type &&
            attribute?.input_type !== Constants.input_type.none
          ) {
            switch (attribute.input_type as keyof typeof Constants.input_type) {
              case Constants.input_type.checkbox:
                return <RiverDefaultCheckboxEditor editorProps={editorProps} />;
              case Constants.input_type.dropdown:
              case Constants.input_type.value_list:
                return (
                  <RiverDefaultValueListEditor editorProps={editorProps} />
                );
              case Constants.input_type.multi_select:
                return (
                  <RiverDefaultMultiSelectEditor editorProps={editorProps} />
                );
              case Constants.input_type.text:
              case Constants.input_type.number:
              default:
                return (
                  <RiverDefaultTextFormatter formatterProps={formatterProps} />
                );
            }
          } else {
            // If no input_type specified, render formatter based on data_type
            switch (attribute?.data_type) {
              case Constants.data_type.boolean:
                return (
                  <RiverDefaultBooleanFormatter
                    formatterProps={formatterProps}
                  />
                );
              default:
                return (
                  <RiverDefaultTextFormatter formatterProps={formatterProps} />
                );
            }
          }
        }}
      </TableContext.Consumer>
    );
  };
  return {
    getFieldFormatter,
  };
}

export type IUseFieldFormatter = ReturnType<typeof useFieldFormatter>;
