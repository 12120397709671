import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";

export function useOracleEbsWoShutdownTypeLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:wo_shutdown_type.title"),
      rowKey: "MEANING",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "MEANING",
            name: t("entity.wo_shutdown_type:wo_shutdown_type.MEANING"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "wo_shutdown_type",
              fetchHelpers.getTableQuery({ fetchProps })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "wo_shutdown_type",
      },
    };
  };
}

export type IUseOracleEbsWoShutdownTypeLookupDef = ReturnType<
  typeof useOracleEbsWoShutdownTypeLookupDef
>;
