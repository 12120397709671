import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import { RiverColourCellFormatter } from "../../../river-colour-cell-formatter";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";

export function useBaselineLookupDef() {
  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    folderId: string
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:baseline.title"),
      rowKey: "_id",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "baseline",
            name: t("entity.baseline:baseline.baseline"),
            width: 200,
          },
          {
            key: "description",
            name: t("entity.baseline:baseline.description"),
          },
          {
            key: "display_options",
            name: t("entity.baseline:baseline.display_options"),
            formatter: (formatterProps) => (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <RiverColourCellFormatter
                      display_options={formatterProps.row.display_options}
                    />
                  ),
                })}
              </>
            ),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .fetchFolderBaselines(
              folderId,
              fetchHelpers.getTableQuery({ fetchProps })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "baseline",
      },
    };
  };
}

export type IUseBaselineLookupDef = ReturnType<typeof useBaselineLookupDef>;
