import { ReactElement } from "react";
import {
  AdapterTimeCardDto,
  IAdapterTimeCard,
  IEntityObject,
} from "@river/interfaces";
import { AdapterUiService } from "./adapter-ui.service";

export interface ITimeCardDialogProps {
  open: boolean;
  folderId: string;
  operation: IEntityObject;
  onClose: (success: boolean) => void;
  onCreate: () => void;
}

export interface IUseTimeCardFormProps {
  timecard?: IAdapterTimeCard;
  folderId: string;
  operation: IEntityObject;
  onCreate?: (timecard: IAdapterTimeCard) => void;
  onUpdate?: () => void;
  initialValues?: Partial<AdapterTimeCardDto> | null;
}

export interface IRenderTimecardDialogProps extends IUseTimeCardFormProps {
  open: boolean;
  onClose(): void;
  onCreate(): void;
}
export abstract class SupervisorTimeCardUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  abstract renderOperationTimecardDialog(
    props: ITimeCardDialogProps
  ): ReactElement;

  abstract renderAssignmentTimecardDialog(
    props: ITimeCardDialogProps
  ): ReactElement;

  abstract renderResourceTimecardDialog(
    props: ITimeCardDialogProps
  ): ReactElement;

  abstract renderInstanceTimecardDialog(
    props: ITimeCardDialogProps
  ): ReactElement;
}
