import React, { ReactElement, useState } from "react";
import WebIcon from "@mui/icons-material/Web";
import { ColumnsSelector } from "../../columns-selector";
import headerStyles from "../common-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

export interface IColumnSelectorAction {
  className?: string;
}

export const ColumnSelectorAction: React.FC<IColumnSelectorAction> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const [showColumnsSelector, setShowColumnsSelector] =
    useState<boolean>(false);

  return (
    <>
      <WebIcon
        titleAccess={t("shared.common_header:label.column_manager")}
        onClick={() => {
          setShowColumnsSelector(true);
        }}
        className={clsx([headerStyles.actionIcon, props.className])}
      />
      <ColumnsSelector
        open={showColumnsSelector}
        onClose={() => setShowColumnsSelector(false)}
      />
    </>
  );
};
