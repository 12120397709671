import React, { ReactElement, useContext } from "react";
import { IAdapterRule } from "@river/interfaces";
import {
  DeleteAction,
  FilterDropdown,
  GridActions,
  GridHeader,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { ValidationRulesAction } from "../validation-rules";
import { ModuleKey } from "../../sidebar-menu";
import {
  useNotification,
  useSimpleDialog,
  useTranslation,
} from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";

interface IValidationRulesGridHeaderProps {
  className?: string;
  selectedRule: IAdapterRule;
}

export const ValidationRulesGridHeader: React.FC<
  IValidationRulesGridHeaderProps
> = (props): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const deleteSelectedRule = async (): Promise<any> => {
    if (props.selectedRule) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteRule(props.selectedRule._id);
        tableContext?.table.fetch();
        deleteRuleConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteRuleConfirmation = useSimpleDialog({
    title: t("module.rules:message.delete_rule.title"),
    message: t("module.rules:message.confirm_rule_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedRule,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.VALIDATION_RULES}
            action={ValidationRulesAction.DELETE}
          >
            <DeleteAction onClick={() => deleteRuleConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteRuleConfirmation.render()}
    </>
  );
};
