import React from "react";
import { FilterDropdown, GridActions, GridHeader } from "../../shared";

interface ILanguageGridHeader {}

export const LanguageGridHeader: React.FC<ILanguageGridHeader> = (props) => {
  return (
    <GridHeader>
      <GridActions>
        <FilterDropdown />
      </GridActions>
    </GridHeader>
  );
};
