import React, { ReactElement, useContext } from "react";
import { TableContext } from "../../../../context";
import headerStyles from "../../common-header/common-header.module.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

export interface IRefreshAction {
  className?: string;
  onClick?: () => void;
}

export const RefreshAction: React.FC<IRefreshAction> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);

  return (
    <RefreshIcon
      titleAccess={t("common.button:refresh")}
      onClick={() => {
        tableContext?.table.fetch();
        props.onClick?.();
      }}
      className={clsx([headerStyles.actionIcon, props.className])}
    />
  );
};
