import React, { ReactElement, useContext } from "react";
import { RiverDialog, RiverSpinner } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useTable,
} from "../../../../components/shared";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../../context";
import { OracleEbsMaterialRequirementsDialogGridHeader } from "./oracle-ebs-material-requirements-dialog-grid-header";
import { fetchHelpers, uiConstants } from "../../../../helpers";
import { IMaterialRequirementsDialogProps } from "../../../shared";
import { useTranslation } from "@river/common-ui";
import { AdapterUiService } from "../../../../services";
import { IUseEntity, useEntity } from "../../../../hooks";
import { OracleEbsMaterialRequirementsUiService } from "../../oracle-ebs-material-requirements-ui.service";
import { Column } from "react-data-grid";
import styles from "./oracle-ebs-material-requirements-dialog.module.scss";
import { QueryDto } from "@river/interfaces";
import { OracleEbsAdapterService } from "../../oracle-ebs-adapter.service";

export const OracleEbsMaterialRequirementsDialog: React.FC<
  IMaterialRequirementsDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const service: AdapterUiService = adapterContext?.service!;
  const uiService =
    service.getMaterialRequirementsUiService()! as OracleEbsMaterialRequirementsUiService;

  const tableContext = useContext(TableContext);
  const workOrderNumberColumn = uiService.getWorkOrderNumberColumn();

  const getSelectedWorkorderNumbers = (): string[] => {
    const orderIds = Array.from(tableContext?.table.selectedRowIds!);
    const workorderNumbers = tableContext?.table.entities
      .filter((e) => orderIds.includes(e[uiConstants.fields._id] as string))
      .map((e) => e[workOrderNumberColumn] as string);
    return workorderNumbers || [];
  };

  const fetchInventoryItems = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = fetchHelpers.getTableQuery({ fetchProps });
    inventoryItemsTable.setLastRanQueryDto(query);
    const selectedWorkOrderNumbers: string[] = getSelectedWorkorderNumbers();
    return await (
      adapterContext!.service.getAdapterService() as OracleEbsAdapterService
    ).fetchInventoryItems(selectedWorkOrderNumbers, query);
  };

  const fetchDirectPurchases = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = fetchHelpers.getTableQuery({ fetchProps });
    directPurchasesTable.setLastRanQueryDto(query);
    const selectedWorkOrderNumbers: string[] = getSelectedWorkorderNumbers();
    return await (
      adapterContext!.service.getAdapterService() as OracleEbsAdapterService
    ).fetchDirectItems(selectedWorkOrderNumbers, query);
  };

  const defaultInventoryItemsColumns: Column<any>[] = [
    {
      key: "WIP_ENTITY_NAME",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.WIP_ENTITY_NAME"
      ),
    },
    {
      key: "OPERATION_SEQ_NUM",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.OPERATION_SEQ_NUM"
      ),
    },
    {
      key: "CONCATENATED_SEGMENTS",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.CONCATENATED_SEGMENTS"
      ),
    },
    {
      key: "ITEM_DESCRIPTION",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.ITEM_DESCRIPTION"
      ),
    },
    {
      key: "REQUIRED_QUANTITY",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.REQUIRED_QUANTITY"
      ),
    },
    {
      key: "AVAILABLE_QUANTITY",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.AVAILABLE_QUANTITY"
      ),
    },
    {
      key: "QUANTITY_ISSUED",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.QUANTITY_ISSUED"
      ),
    },
    {
      key: "DATE_REQUIRED",
      name: t(
        "entity.wo_operation_inventory_item:wo_operation_inventory_item.DATE_REQUIRED"
      ),
    },
  ];

  const defaultDirectPurchasesColumns: Column<any>[] = [
    {
      key: "WIP_ENTITY_NAME",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.WIP_ENTITY_NAME"
      ),
    },
    {
      key: "OPERATION_SEQ_NUM",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.OPERATION_SEQ_NUM"
      ),
    },
    {
      key: "DIRECT_ITEM_TYPE",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.DIRECT_ITEM_TYPE"
      ),
    },
    {
      key: "ITEM_DESCRIPTION",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.ITEM_DESCRIPTION"
      ),
    },
    {
      key: "DESCRIPTION",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.DESCRIPTION"
      ),
    },
    {
      key: "UOM_CODE",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.UOM_CODE"
      ),
    },
    {
      key: "REQUIRED_QUANTITY",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.REQUIRED_QUANTITY"
      ),
    },
    {
      key: "DATE_REQUIRED",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.DATE_REQUIRED"
      ),
    },
    {
      key: "QUANTITY_RECEIVED",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.QUANTITY_RECEIVED"
      ),
    },
    {
      key: "PR_PO_NUMBER",
      name: t(
        "entity.wo_operation_direct_item:wo_operation_direct_item.PR_PO_NUMBER"
      ),
    },
  ];

  const inventoryItemsTable: IUseTable = useTable({
    columns: defaultInventoryItemsColumns,
    fetchFunction: fetchInventoryItems,
    fetchOn: props.open,
    fetchTriggers: [],
    saveKey: "material_requirements.inventory_items",
    useAdvancedFilters: false,
  });

  const directPurchasesTable: IUseTable = useTable({
    columns: defaultDirectPurchasesColumns,
    fetchFunction: fetchDirectPurchases,
    fetchOn: props.open,
    fetchTriggers: [],
    saveKey: "material_requirements.direct_purchases",
    useAdvancedFilters: false,
  });

  const inventoryItemsEntity: IUseEntity = useEntity({
    entityName: uiService.getWorkOrderInventoryItemEntityName(),
  });

  const directItemsEntity: IUseEntity = useEntity({
    entityName: uiService.getWorkOrderDirectItemEntityName(),
  });

  const renderInventoryItemsSection = (): ReactElement => (
    <TableContext.Provider
      value={{
        table: inventoryItemsTable,
        entity: inventoryItemsEntity,
      }}
    >
      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Inventory Items</p>
        <OracleEbsMaterialRequirementsDialogGridHeader />
        <RiverDataGrid
          disableSelectAll={true}
          className={styles.grid}
          columns={inventoryItemsTable.columns}
          rows={inventoryItemsTable.entities}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onSelectedRowsChange={() => {}}
          onRowClick={() => {}}
          rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
          sortColumns={inventoryItemsTable.sortColumns}
          onSortColumnsChange={(e) => {
            inventoryItemsTable.setSortColumns(e);
          }}
        />
      </div>
    </TableContext.Provider>
  );

  const renderDirectPurchasesSection = (): ReactElement => (
    <TableContext.Provider
      value={{
        table: directPurchasesTable,
        entity: directItemsEntity,
      }}
    >
      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Direct Purchases</p>
        <OracleEbsMaterialRequirementsDialogGridHeader />
        <RiverDataGrid
          disableSelectAll={true}
          className={styles.grid}
          columns={directPurchasesTable.columns}
          rows={directPurchasesTable.entities}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onSelectedRowsChange={() => {}}
          onRowClick={() => {}}
          rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
          sortColumns={directPurchasesTable.sortColumns}
          onSortColumnsChange={(e) => {
            directPurchasesTable.setSortColumns(e);
          }}
        />
      </div>
    </TableContext.Provider>
  );

  const isLoading: boolean =
    inventoryItemsTable.isLoading || directPurchasesTable.isLoading;

  return (
    <RiverDialog
      title={t("shared.material_req:dialog.material_req.title")}
      open={props.open}
      onClose={() => props.onClose()}
      closeButtonText={t("common.button:close")}
      showActionsDivider={false}
      classes={{ paper: styles.paper }}
      dialogProps={{ maxWidth: false }}
    >
      <div className={styles.root}>
        <RiverSpinner show={isLoading} />
        {renderInventoryItemsSection()}
        {renderDirectPurchasesSection()}
      </div>
    </RiverDialog>
  );
};
