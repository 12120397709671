import { TabProps } from "@mui/material/Tab";
import {
  ColumnsProvider,
  EntityTableProvider,
  ExecutionOperationsProvider,
  ExecutionUiService,
  IGetDefaultInstancesColumnsProps,
  IGetInitialInstancesQueryAttributesProps,
  IUseCurrentTableProps,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
  IWoMassUpdateDialogProps,
} from "../../../services";
import { TFunction } from "i18next";
import {
  ExecutionTabId,
  IFetchExecutionOperationsProps,
  IGetDefaultMaterialRequirementsColumnsProps,
  IGetDefaultOperationColumnsProps,
  IGetDefaulTimecardColumnsProps,
  IGetInitialQueryProps,
  IWoPaneMenuItems,
  useExecutionDetails,
} from "../../execution-ui-service";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { fetchHelpers } from "../../../helpers";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../context";
import {
  useExecutionTimecards,
  useExecutionInstances,
  useExecutionMaterialRequirements,
  useExecutionOperations,
} from "../../execution-ui-service";
import { IRiverPopupMenuItem } from "../../../components/shared/river-popup-menu";
import { getFolderQueryGroup } from "@river/util";
import { OracleEbsExecutionMaterialRequirements } from "./oracle-ebs-execution-material-requirements";
import { ExecutionActionsFormatter } from "../../../components/execution/execution-actions-formatter";
import { useTableCellRenderers } from "../../../hooks";
import {
  FilterDropdown,
  RiverCheckmarkFormatter,
  RiverDropdownActions,
  useWoMassUpdateAction,
  useEbsWoUncompleteAction,
} from "../../../components/shared";
import { useEbsWoCompletionAction } from "../grid-actions";
import { ModuleKey } from "../../../components/sidebar-menu";
import {
  ExecutionActions,
  IExecutionAction,
} from "../../../components/execution/execution";
import { OracleEbsWoMassUpdateDialog } from "../oracle-ebs-shared";

export class OracleEbsExecutionUiService extends ExecutionUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.operation_resource",
      "entity.operation_resource_instance",
      "entity.person_utilization",
      "entity.resource_ref",
      "entity.wo_operation_inventory_item",
      "entity.wo_operation_direct_item",
      "entity.wo_priority",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.wo_shutdown_type",
      "entity.wo_planner",
      "entity.wo_completion",
      "entity.completion_subinventory",
      "entity.reconciliation_code",
      "entity.failure_code",
      "entity.cause_code",
      "entity.resolution_code",
      "shared.wo_completion",
      "shared.wo_uncomplete_dialog",
    ];
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.execution:tab.details.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.DETAILS,
      },
      {
        label: t("module.execution:tab.operations.title"),
        icon: <DeviceHubIcon />,
        value: ExecutionTabId.OPERATIONS,
      },
      {
        label: t("module.execution:tab.instances.title"),
        icon: <LibraryBooksIcon />,
        value: ExecutionTabId.INSTANCES,
      },
      {
        label: t("module.execution:tab.material.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.MATERIAL,
      },
      {
        label: t("module.execution:tab.timecards.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.TIMECARDS,
      },
    ];
  };

  getWoPaneMenuItems = (
    props: IWoPaneMenuItems
  ): (() => IRiverPopupMenuItem[]) => {
    return () => {
      const { executionContext, workOrder } = props;
      const { t } = useTranslation();

      const tabContext = useContext(TabContext);
      return [
        {
          label: t("module.execution:tab.details.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.DETAILS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.operations.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.OPERATIONS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.instances.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.INSTANCES);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.material.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.MATERIAL);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.timecards.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.TIMECARDS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
      ];
    };
  };

  useCurrentTable = (props: IUseCurrentTableProps): EntityTableProvider => {
    return () => {
      const { executionContext } = props;

      const detailsTable: IUseEntityTable = useExecutionDetails({
        executionContext,
      });
      const operationsTable: IUseEntityTable = useExecutionOperations({
        executionContext,
      });
      const instancesTable: IUseEntityTable = useExecutionInstances({
        executionContext,
      });
      const materialTable: IUseEntityTable = useExecutionMaterialRequirements({
        executionContext,
      });
      const completionTable: IUseEntityTable = useExecutionTimecards({
        executionContext,
      });

      const currentTab: ExecutionTabId = this.getCurrentTab()();
      if (currentTab === ExecutionTabId.MATERIAL) {
        return materialTable;
      } else if (currentTab === ExecutionTabId.DETAILS) {
        return detailsTable;
      } else if (currentTab === ExecutionTabId.INSTANCES) {
        return instancesTable;
      } else if (currentTab === ExecutionTabId.TIMECARDS) {
        return completionTable;
      } else {
        return operationsTable;
      }
    };
  };

  renderExecutionMaterialRequirements = (): ReactElement => {
    return <OracleEbsExecutionMaterialRequirements />;
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const executionContext = useContext(ExecutionContext);
      const folderId: string = executionContext?.currentSchedule?._id!;
      const currentWorkOrder: any = executionContext?.currentWorkOrder;
      const currentWorkOrderId: string = currentWorkOrder?._id as string;
      const completionPercentageDialogAction =
        executionContext?.completionPercentageDialogAction!;

      const { woMassUpdateAction } = useWoMassUpdateAction({
        renderWoMassUpdateDialog: this.renderWoMassUpdateDialog,
        disabled: false,
        workorderIds: [currentWorkOrderId],
      });
      const { ebsWoCompletionAction } = useEbsWoCompletionAction({
        enableOn: true,
      });

      const { ebsWoUncompleteAction } = useEbsWoUncompleteAction({
        enableOn: true,
      });

      return (
        <>
          {currentTab === ExecutionTabId.DETAILS && (
            <>
              <RiverDropdownActions
                module={ModuleKey.EXECUTION}
                items={[
                  {
                    title: woMassUpdateAction.title,
                    startIcon: woMassUpdateAction.icon,
                    onClick: woMassUpdateAction.onClick,
                    action: ExecutionActions.WO_EDIT,
                  },

                  {
                    title: completionPercentageDialogAction.title,
                    startIcon: completionPercentageDialogAction.icon,
                    onClick: completionPercentageDialogAction.onClick,
                    action: ExecutionActions.WO_COMPLETION_PCT,
                  },
                  {
                    title: ebsWoCompletionAction.title,
                    startIcon: ebsWoCompletionAction.icon,
                    onClick: ebsWoCompletionAction.onClick,
                    action: ExecutionActions.WO_COMPLETION,
                  },
                  {
                    title: ebsWoUncompleteAction.title,
                    startIcon: ebsWoUncompleteAction.icon,
                    onClick: ebsWoUncompleteAction.onClick,
                    action: ExecutionActions.WO_UNCOMPLETE,
                  },
                ]}
              />
              {woMassUpdateAction.renderDialog()}
              {completionPercentageDialogAction.renderDialog({
                entity_name: "workorder",
                entity_ids: [currentWorkOrderId],
                folderId,
              })}
              {currentWorkOrder &&
                ebsWoCompletionAction.renderDialog({
                  workOrders: [currentWorkOrder],
                })}
              {ebsWoUncompleteAction.renderDialog({
                workorderIds: [currentWorkOrderId],
              })}
            </>
          )}
          {currentTab === ExecutionTabId.OPERATIONS && (
            <>
              <FilterDropdown />
              {completionPercentageDialogAction.renderDialog({
                entity_name: "operation",
                folderId,
              })}
            </>
          )}
          {currentTab === ExecutionTabId.INSTANCES && <FilterDropdown />}
          {currentTab === ExecutionTabId.MATERIAL && <FilterDropdown />}
          {currentTab === ExecutionTabId.TIMECARDS && <FilterDropdown />}
        </>
      );
    };
  }

  getIsTableView(): () => boolean {
    return (): boolean => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      return (
        currentTab === ExecutionTabId.DETAILS ||
        currentTab === ExecutionTabId.OPERATIONS ||
        currentTab === ExecutionTabId.INSTANCES ||
        currentTab === ExecutionTabId.MATERIAL ||
        currentTab === ExecutionTabId.TIMECARDS
      );
    };
  }

  getWoItemPropLabelMap = (t: TFunction) =>
    new Map([
      ["WIP_ENTITY_NAME", t("entity.workorder:workorder.WIP_ENTITY_NAME")],
      ["DESCRIPTION", t("entity.workorder:workorder.DESCRIPTION")],
      ["INSTANCE_NUMBER", t("entity.workorder:workorder.INSTANCE_NUMBER")],
      ["WORK_ORDER_TYPE", t("entity.workorder:workorder.WORK_ORDER_TYPE")],
    ]);

  getWoPropsToRender = () => [
    "WIP_ENTITY_NAME",
    "DESCRIPTION",
    "INSTANCE_NUMBER",
    "WORK_ORDER_TYPE",
  ];
  getDefaultOperationColumns(
    props: IGetDefaultOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "OPERATION_SEQ_NUM",
          name: t("entity.operation:operation.OPERATION_SEQ_NUM"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.operationActions}
            />
          ),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.operation:operation.DESCRIPTION"),
        },
        {
          key: "WorkOrderOperationResource.OPERATION_SEQ_NUM",
          name: t(
            "entity.operation_resource:operation_resource.OPERATION_SEQ_NUM"
          ),
        },
        {
          key: "WorkOrderOperationResource.WIP_ENTITY_NAME",
          name: t(
            "entity.operation_resource:operation_resource.WIP_ENTITY_NAME"
          ),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_CODE",
          name: t("entity.operation_resource:operation_resource.RESOURCE_CODE"),
        },
        {
          key: "WorkOrderOperationResource.USAGE_RATE_OR_AMOUNT",
          name: t(
            "entity.operation_resource:operation_resource.USAGE_RATE_OR_AMOUNT"
          ),
        },
        {
          key: "WorkOrderOperationResource.UOM_CODE",
          name: t("entity.operation_resource:operation_resource.UOM_CODE"),
        },
        {
          key: "WorkOrderOperationResource.ASSIGNED_UNITS",
          name: t(
            "entity.operation_resource:operation_resource.ASSIGNED_UNITS"
          ),
        },
        {
          key: "WorkOrderOperationResource.START_DATE",
          name: t("entity.operation_resource:operation_resource.START_DATE"),
        },
        {
          key: "WorkOrderOperationResource.COMPLETION_DATE",
          name: t(
            "entity.operation_resource:operation_resource.COMPLETION_DATE"
          ),
        },
      ];
    };
  }

  getInitialQuery = (props: IGetInitialQueryProps): QueryAttributeGroupDto => {
    const { currentSchedule, currentWorkOrderRef } = props;
    return {
      $and: [
        getFolderQueryGroup(currentSchedule!, ""),
        {
          attribute_name: "WIP_ENTITY_NAME",
          attribute_value: {
            operator: "$eq",
            value: currentWorkOrderRef!.WIP_ENTITY_NAME,
          },
        },
      ],
    };
  };

  getExecutionFetchOperations = (): ExecutionOperationsProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return (props: IFetchExecutionOperationsProps): Promise<any> => {
        const { initialQuery, fetchProps } = props;
        return adapterContext!.service.getAdapterService().fetchOperations({
          ...fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributeGroup: initialQuery,
          }),
          $unwind: ["WorkOrderOperationResource"],
        });
      };
    };
  };

  getExecutionTabOperationEntity = () => "operation";

  getDefaultMaterialRequirementsColumns(
    props: IGetDefaultMaterialRequirementsColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.materialRequirementActions}
            />
          ),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationMaterial.MaterialSequenceNumber",
          name: t("module.execution:label.MaterialSequenceNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.InventoryItemNumber",
          name: t("module.execution:label.InventoryItemNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.Quantity",
          name: t("module.execution:label.Quantity"),
        },
        {
          key: "WorkOrderOperationMaterial.UnitOfMeasure",
          name: t("module.execution:label.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationMaterial.RequiredDate",
          name: t("module.execution:label.RequiredDate"),
        },
        {
          key: "WorkOrderOperationMaterial.ReservedQuantity",
          name: t("module.execution:label.ReservedQuantity"),
        },
        {
          key: "WorkOrderOperationMaterial.IssuedQuantity",
          name: t("module.execution:label.IssuedQuantity"),
        },
      ];
    };
  }

  getDefaultInstancesColumns(
    props: IGetDefaultInstancesColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "OPERATION_SEQ_NUM",
          name: t(
            "entity.operation_resource:operation_resource.OPERATION_SEQ_NUM"
          ),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.instancesActions}
            />
          ),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.operation_resource:operation_resource.DESCRIPTION"),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_SEQ_NUM",
          name: t(
            "entity.operation_resource:operation_resource.RESOURCE_SEQ_NUM"
          ),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_CODE",
          name: t("entity.operation_resource:operation_resource.RESOURCE_CODE"),
        },
        {
          key: "WorkOrderOperationResource.USAGE_RATE_OR_AMOUNT",
          name: t(
            "entity.operation_resource:operation_resource.USAGE_RATE_OR_AMOUNT"
          ),
        },
        {
          key: "WorkOrderOperationResource.UOM_CODE",
          name: t("entity.operation_resource:operation_resource.UOM_CODE"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.INSTANCE_NAME",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.INSTANCE_NAME"
          ),
        },
        {
          key: "WorkOrderOperationResource.START_DATE",
          name: t("entity.operation_resource:operation_resource.START_DATE"),
        },
        {
          key: "WorkOrderOperationResource.COMPLETION_DATE",
          name: t(
            "entity.operation_resource:operation_resource.COMPLETION_DATE"
          ),
        },
      ];
    };
  }

  getInitialInstancesQueryAttributes = (
    props: IGetInitialInstancesQueryAttributesProps
  ): QueryAttributeDto[] => {
    const { currentWorkOrder } = props;
    return [
      {
        attribute_name: "ORGANIZATION_ID",
        attribute_value: {
          operator: "$eq",
          value: currentWorkOrder!.ORGANIZATION_ID,
        },
      },
      {
        attribute_name: "WIP_ENTITY_NAME",
        attribute_value: {
          operator: "$eq",
          value: currentWorkOrder!.WIP_ENTITY_NAME,
        },
      },
    ];
  };

  getExecutionTabMaterialRequirementsEntity = () => "operation";

  getOperationAttributeValuePath = () => "_id";

  getDefaultTimecardColumns(
    props: IGetDefaulTimecardColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "OPERATION_SEQ_NUM",
          name: t("entity.timecard:timecard.OPERATION_SEQ_NUM"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.timecardActions}
            />
          ),
        },
        {
          key: "RESOURCE_SEQ_NUM",
          name: t("entity.timecard:timecard.RESOURCE_SEQ_NUM"),
        },
        {
          key: "INSTANCE_NAME",
          name: t("entity.timecard:timecard.INSTANCE_NAME"),
        },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "is_confirmed",
          name: t("module.execution:label.is_confirmed"),
          formatter: (formatterProps) => {
            const isConfirmed: boolean = (formatterProps.row as any)
              .is_confirmed as boolean;
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: <RiverCheckmarkFormatter checked={isConfirmed} />,
                })}
              </>
            );
          },
        },
      ];
    };
  }

  getOperationActions = () => {
    return () => [];
  };

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleEbsWoMassUpdateDialog {...props} />;

  getDefaultDetailsColumns = (): ColumnsProvider => {
    const { t } = useTranslation();
    return () => [
      {
        key: "WIP_ENTITY_NAME",
        name: t("entity.workorder:workorder.WIP_ENTITY_NAME"),
      },
      {
        key: "DESCRIPTION",
        name: t("entity.workorder:workorder.DESCRIPTION"),
      },
      {
        key: "INSTANCE_NUMBER",
        name: t("entity.workorder:workorder.INSTANCE_NUMBER"),
      },
      {
        key: "WORK_ORDER_TYPE",
        name: t("entity.workorder:workorder.WORK_ORDER_TYPE"),
      },
      {
        key: "WORK_ORDER_TYPE_DISP",
        name: t("entity.workorder:workorder.WORK_ORDER_TYPE_DISP"),
      },
      {
        key: "STATUS_TYPE",
        name: t("entity.workorder:workorder.STATUS_TYPE"),
      },
      {
        key: "WORK_ORDER_TYPE_DISP",
        name: t("entity.workorder:workorder.WORK_ORDER_TYPE_DISP"),
      },
      {
        key: "SCHEDULED_START_DATE",
        name: t("entity.workorder:workorder.SCHEDULED_START_DATE"),
      },
      {
        key: "SCHEDULED_COMPLETION_DATE",
        name: t("entity.workorder:workorder.SCHEDULED_COMPLETION_DATE"),
      },
    ];
  };

  getDefaultAssignmentColumns(
    assignmentActions: IExecutionAction[]
  ): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
