import React, { ReactElement, useState } from "react";
import {
  IconButton,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEnv, IUseEnv } from "@river/common-ui";
import styles from "./river-popup-menu.module.scss";
import clsx from "clsx";

const DEFAULT_TOGGLE_ICON: React.FC = MoreVertIcon;

type Icon = React.FC<{ className?: string }>;

export interface IRiverPopupMenuItem {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface IRiverPopupMenuProps {
  items: IRiverPopupMenuItem[];
  disabled?: boolean;
  toggleIcon?: Icon;
  onToggle?: (open: boolean) => void;
  classes?: {
    toggleButton?: string;
    toggleIcon?: string;
    menu?: string;
  };
}

export const RiverPopupMenu: React.FC<IRiverPopupMenuProps> = (
  props
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const ToggleIcon: Icon = props.toggleIcon || DEFAULT_TOGGLE_ICON;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const onClickAway = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    closePopup();
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      closePopup();
    } else if (event.key === "Escape") {
      closePopup();
    }
  };

  const closePopup = () => {
    setOpen(false);
    props.onToggle?.(false);
  };

  const openPopup = () => {
    setOpen(true);
    props.onToggle?.(true);
  };
  return (
    <>
      <IconButton
        disabled={props.disabled}
        ref={anchorRef}
        onClick={() => openPopup()}
        className={clsx([
          styles.toggle,
          styles.toggleButton,
          props.classes?.toggleButton,
          { [styles.mobile]: isMobile },
        ])}
      >
        <ToggleIcon
          className={clsx([styles.toggleIcon, props.classes?.toggleIcon])}
        />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal={false}
        className={clsx([
          styles.menu,
          props.classes?.menu,
          { [styles.mobile]: isMobile },
        ])}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {props.items.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => {
                        event.stopPropagation();
                        closePopup();
                        item.onClick();
                      }}
                      className={clsx([{ [styles.disabled]: item.disabled }])}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
