import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import styles from "./river-radio-group.module.scss";
import clsx from "clsx";
import { useFormField } from "../../../hooks";
export const RiverRadioGroup = (props) => {
    var _a;
    const { label, value, disabled, onChange, radioOptions } = useFormField({
        id: props.id,
        label: props.label,
        value: props.value,
        radioOptionIds: props.options,
        onChangeEvent: props.onChangeEvent,
        disabled: props.disabled,
    });
    return (_jsxs(FormControl, { classes: {
            root: clsx([styles.root, props.className]),
        }, disabled: disabled, children: [label && (_jsx(FormLabel, { className: clsx([styles.groupLabel, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.groupLabel]), children: label })), _jsx(RadioGroup, { onChange: onChange, name: props.id, color: "primary", value: value, row: props.inline, children: radioOptions.map((option) => (_jsx(FormControlLabel, { id: option.id, value: option.id, control: _jsx(Radio, {}), label: option.label, classes: {
                        label: styles.optionLabel,
                    } }, option.id))) })] }));
};
