import { useContext, FC } from "react";
import { TableContext } from "../../../../context";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UpDownArrowsIcon } from "../../../../icons/up-down-arrows-icon";
import { TablePageSizeType } from "../useTable";
import { RiverSelect } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import styles from "./river-paginator.module.scss";
import clsx from "clsx";

export const RiverPaginator: FC = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const table = tableContext?.table!;

  const renderPreviousPageButton = (): JSX.Element => (
    <Button
      variant="text"
      disabled={table.page === 1 || table.isLoading}
      onClick={() => changePage(-1)}
      className={styles.button}
    >
      <ArrowBackIosIcon className={styles.arrow} />
    </Button>
  );

  const renderNextPageButton = (): JSX.Element => (
    <Button
      variant="text"
      disabled={table.isOnLastPage || table.isLoading}
      onClick={() => changePage(1)}
      className={styles.button}
    >
      <ArrowForwardIosIcon className={styles.arrow} />
    </Button>
  );

  const renderCurrentPageNumber = (): JSX.Element => (
    <div className={clsx([styles.pageNum, styles.text])}>{`${table.page}`}</div>
  );

  const changePage = (changePage: number) => {
    if (!table.isLoading) {
      table.fetch({
        page: table.page + changePage,
      });
    }
  };

  const handlePageSizeChange = (pageSize: TablePageSizeType) => {
    table.fetch({ pageSize });
  };

  const renderPageSizeSection = () => {
    const pageSizeValues = [100, 75, 50, 25] as TablePageSizeType[];
    return (
      <div className={styles.pageSizeContainer}>
        <span className={styles.text}>
          {t("shared.river_data_grid:label.show")}
        </span>
        <RiverSelect
          className={styles.customSelect_root}
          classes={{
            select: {
              select: styles.customSelect_select,
              dropdownIcon: styles.customSelect_dropdownIcon,
            },
            menuItem: {
              root: styles.customSelectMenuItem_root,
            },
          }}
          items={pageSizeValues.map((value) => ({
            value: `${value}`,
            label: `${value}`,
          }))}
          icon={UpDownArrowsIcon}
          value={`${table.pageSize}`}
          onChange={(value) =>
            handlePageSizeChange(+value as TablePageSizeType)
          }
        />
        <span className={styles.text}>
          {t("shared.river_data_grid:label.per_page")}
        </span>
      </div>
    );
  };

  return (
    <>
      {table.pagination && (
        <div className={styles.root}>
          {renderPageSizeSection()}
          <div className={styles.pagerContainer}>
            {renderPreviousPageButton()}
            {renderCurrentPageNumber()}
            {renderNextPageButton()}
          </div>
        </div>
      )}
    </>
  );
};
