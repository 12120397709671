import React, { useContext, useState } from "react";
import { PrimaryButton, useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { ValidationRulesAction } from "../validation-rules";
import { ValidationRuleDialog } from "../validation-rule-dialog";
import { TableContext } from "../../../context";
import headerStyles from "../../shared/common-header/common-header.module.scss";

export const ValidationRulesSubheader: React.FC = () => {
  const [ruleDialogOpened, setRuleDialogOpened] = useState<boolean>(false);
  const openDialog = (): void => {
    setRuleDialogOpened(true);
  };
  const closeDialog = (): void => setRuleDialogOpened(false);
  const tableContext = useContext(TableContext);
  const { t } = useTranslation();

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.VALIDATION_RULES}
          action={ValidationRulesAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.rules:button.add_rule")}
          />
        </ProtectedAction>
      </div>
      <ValidationRuleDialog
        open={ruleDialogOpened}
        rule={null}
        onClose={(success) => {
          closeDialog();
          if (success) {
            tableContext?.table.fetch();
          }
        }}
      />
    </>
  );
};
