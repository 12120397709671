import React, { ReactElement, useContext, useState } from "react";
import { IEntityObject } from "@river/interfaces";
import { TableContext } from "../../../../context";
import { IUseTable } from "../../../shared";
import { Column } from "react-data-grid";
import { Button } from "@mui/material";
import {
  IRiverPopupMenuItem,
  RiverPopupMenu,
} from "../../../shared/river-popup-menu";
import { IUseEnv, useEnv } from "@river/common-ui";
import { IExecutionAction } from "../../execution";
import styles from "./execution-card-view-item.module.scss";
import clsx from "clsx";

export enum ActionsLayout {
  MENU = "menu",
  BUTTONS = "buttons",
}

const DEFAULT_ACTIONS_LAYOUT: ActionsLayout = ActionsLayout.MENU;

export enum ExecutionCardViewItemColor {
  RED = "red",
  BLUE = "blue",
  GREEN = "green",
  PURPLE = "purple",
  ORANGE = "orange",
  LIGHT_BLUE = "lightBlue",
  GRASS_GREEN = "grassGreen",
  LIGHT_PURPLE = "lightPurple",
}
export const randomExecutionCardViewItemColors: ExecutionCardViewItemColor[] = [
  ExecutionCardViewItemColor.RED,
  ExecutionCardViewItemColor.BLUE,
  ExecutionCardViewItemColor.GREEN,
  ExecutionCardViewItemColor.PURPLE,
  ExecutionCardViewItemColor.ORANGE,
  ExecutionCardViewItemColor.LIGHT_BLUE,
  ExecutionCardViewItemColor.GRASS_GREEN,
  ExecutionCardViewItemColor.LIGHT_PURPLE,
];
const DEFAULT_COLOR: ExecutionCardViewItemColor =
  ExecutionCardViewItemColor.RED;

export interface IExecutionCardViewItem {
  item: IEntityObject;
  color?: ExecutionCardViewItemColor;
  actions?: IExecutionAction[];
  actionsLayout?: ActionsLayout;
}

export const ExecutionCardViewItem: React.FC<IExecutionCardViewItem> = (
  props
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const actionsLayout: ActionsLayout =
    props.actionsLayout || DEFAULT_ACTIONS_LAYOUT;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const columns: Column<any>[] = table.columns;

  const [isItemSelected] = useState<boolean>(false);

  const onItemClick = (e: React.MouseEvent<any>): void => {
    e.preventDefault();
    e.stopPropagation();
    //...
  };

  const isPropertyExcluded = (column: Column<any>): boolean =>
    column.key === "actions";

  const renderItemProperty = (
    column: Column<any>,
    index: number
  ): ReactElement => {
    const getColumnValue = () => {
      const keyFragments: string[] = column.key.split(".");
      let currentObj: IEntityObject = props.item;
      keyFragments.forEach((key) => {
        currentObj = currentObj[key] as IEntityObject;
      });
      return String(currentObj || "");
    };

    return (
      <div className={styles.propRow} key={index}>
        <div className={styles.propLabel}>{column.name}</div>
        <div className={styles.propValue}>{getColumnValue()}</div>
      </div>
    );
  };

  const renderProps = (): ReactElement => {
    const els: ReactElement[] = [];
    columns.forEach((column, index) => {
      if (!isPropertyExcluded(column)) {
        els.push(renderItemProperty(column, index));
      }
    });
    return <div className={styles.props}>{els}</div>;
  };

  const onActionClick = (
    e: React.MouseEvent<any>,
    action: IExecutionAction
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    action.onClick(props.item);
  };

  const renderActionButtons = (): ReactElement => (
    <>
      {props.actions && (
        <div className={styles.actions}>
          {props.actions?.map((action, index) => {
            if (action?.isHidden?.(props.item)) return <></>;
            return (
              <Button
                variant={"text"}
                onClick={(e) => onActionClick(e, action)}
                key={index}
                className={styles.actionButton}
                disabled={action.isDisabled?.(props.item)}
              >
                {action.label}
              </Button>
            );
          })}
        </div>
      )}
    </>
  );

  const renderActionMenu = (): ReactElement => {
    const menuItems: IRiverPopupMenuItem[] = [];
    props.actions?.forEach((action) => {
      if (!action.isHidden?.(props.item)) {
        menuItems.push({
          label: action.label,
          onClick: () => action.onClick(props.item),
          disabled: action.isDisabled?.(props.item),
        });
      }
    });

    return (
      <>
        {menuItems.length && (
          <div className={styles.menu}>
            <RiverPopupMenu items={menuItems} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={clsx([
          styles.root,
          props.color || DEFAULT_COLOR,
          { [styles.selected]: isItemSelected, [styles.mobile]: isMobile },
        ])}
        onClick={onItemClick}
      >
        <div className={styles.colorMarker} />
        <div className={styles.body}>
          {renderProps()}
          {actionsLayout === ActionsLayout.BUTTONS && renderActionButtons()}
        </div>
        {actionsLayout === ActionsLayout.MENU && renderActionMenu()}
      </div>
    </>
  );
};
