import { TabProps } from "@mui/material/Tab";
import {
  ColumnsProvider,
  EntityTableProvider,
  ExecutionUiService,
  IUseCurrentTableProps,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
  IWoMassUpdateDialogProps,
} from "../../services";
import { TFunction } from "i18next";
import { fetchHelpers } from "../../helpers";
import {
  ExecutionTabId,
  IFetchExecutionOperationsProps,
  IGetInitialQueryProps,
  IWoPaneMenuItems,
  ExecutionOperationsProvider,
  IGetDefaultMaterialRequirementsColumnsProps,
  IGetDefaultOperationColumnsProps,
  IGetDefaulTimecardColumnsProps,
  useExecutionDetails,
} from "../execution-ui-service";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
} from "../../context";
import {
  useExecutionAssignments,
  useExecutionTimecards,
  useExecutionMaterialRequirements,
  useExecutionOperations,
} from "../execution-ui-service";
import { IRiverPopupMenuItem } from "../../components/shared/river-popup-menu";
import { getFolderQueryGroup } from "@river/util";
import { ExecutionActionsFormatter } from "../../components/execution/execution-actions-formatter";
import { useTableCellRenderers } from "../../hooks";
import {
  FilterDropdown,
  RiverCheckmarkFormatter,
} from "../../components/shared";
import { JdeWoMassUpdateDialog } from "./jde-shared";
import { IExecutionAction } from "../../components/execution/execution";

export class JdeExecutionUiService extends ExecutionUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_part",
      "entity.workorder",
      "entity.timecard",
    ];
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.execution:tab.details.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.DETAILS,
      },
      {
        label: t("module.execution:tab.operations.title"),
        icon: <DeviceHubIcon />,
        value: ExecutionTabId.OPERATIONS,
      },
      {
        label: t("module.execution:tab.assignments.title"),
        icon: <LibraryBooksIcon />,
        value: ExecutionTabId.ASSIGNMENTS,
      },
      {
        label: t("module.execution:tab.material.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.MATERIAL,
      },
      {
        label: t("module.execution:tab.timecards.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.TIMECARDS,
      },
    ];
  };

  getWoPaneMenuItems = (
    props: IWoPaneMenuItems
  ): (() => IRiverPopupMenuItem[]) => {
    return () => {
      const { executionContext, workOrder } = props;
      const { t } = useTranslation();

      const tabContext = useContext(TabContext);
      return [
        {
          label: t("module.execution:tab.details.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.DETAILS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.operations.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.OPERATIONS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.assignments.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.ASSIGNMENTS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.material.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.MATERIAL);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.timecards.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.TIMECARDS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
      ];
    };
  };

  useCurrentTable = (props: IUseCurrentTableProps): EntityTableProvider => {
    return () => {
      const { executionContext } = props;

      const detailsTable: IUseEntityTable = useExecutionDetails({
        executionContext,
      });
      const operationsTable: IUseEntityTable = useExecutionOperations({
        executionContext,
      });
      const assignmentsTable: IUseEntityTable = useExecutionAssignments({
        executionContext,
      });
      const materialTable: IUseEntityTable = useExecutionMaterialRequirements({
        executionContext,
      });
      const completionTable: IUseEntityTable = useExecutionTimecards({
        executionContext,
      });

      const currentTab: ExecutionTabId = this.getCurrentTab()();
      if (currentTab === ExecutionTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === ExecutionTabId.DETAILS) {
        return detailsTable;
      } else if (currentTab === ExecutionTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else if (currentTab === ExecutionTabId.MATERIAL) {
        return materialTable;
      } else if (currentTab === ExecutionTabId.TIMECARDS) {
        return completionTable;
      } else {
        return operationsTable;
      }
    };
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      return (
        <>
          {currentTab === ExecutionTabId.DETAILS && <></>}
          {currentTab === ExecutionTabId.OPERATIONS && <FilterDropdown />}
          {currentTab === ExecutionTabId.ASSIGNMENTS && <FilterDropdown />}
          {currentTab === ExecutionTabId.MATERIAL && <FilterDropdown />}
          {currentTab === ExecutionTabId.TIMECARDS && <FilterDropdown />}
        </>
      );
    };
  }

  getIsTableView(): () => boolean {
    return (): boolean => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      return (
        currentTab === ExecutionTabId.DETAILS ||
        currentTab === ExecutionTabId.OPERATIONS ||
        currentTab === ExecutionTabId.ASSIGNMENTS ||
        currentTab === ExecutionTabId.MATERIAL ||
        currentTab === ExecutionTabId.TIMECARDS
      );
    };
  }

  getWoItemPropLabelMap = (t: TFunction) =>
    new Map([
      ["F4801_DOCO", t("entity.workorder:workorder.F4801_DOCO")],
      ["F4801_DL01", t("entity.workorder:workorder.F4801_DL01")],
      ["F4801_LOCN", t("entity.workorder:workorder.F4801_LOCN")],
      ["parts", t("entity.workorder:workorder.parts")],
    ]);

  getWoPropsToRender = () => [
    "F4801_DOCO",
    "F4801_DL01",
    "F4801_LOCN",
    "parts",
  ];

  getDefaultOperationColumns(
    props: IGetDefaultOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "F3112_DOCO",
          name: t("entity.operation:operation.F3112_DOCO"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.operationActions}
            />
          ),
        },
        {
          key: "MCU",
          name: t("entity.operation:operation.MCU"),
        },
        {
          key: "F3112_MCU",
          name: t("entity.operation:operation.F3112_MCU"),
        },
        {
          key: "F3112_MCU_desc",
          name: t("entity.operation:operation.F3112_MCU_desc"),
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.operation:operation.F3112_OPSQ"),
        },
        {
          key: "F3112_MMCU",
          name: t("entity.operation:operation.F3112_MMCU"),
        },
        {
          key: "F3112_DSC1",
          name: t("entity.operation:operation.F3112_DSC1"),
        },
        {
          key: "F3112_AN8",
          name: t("entity.operation:operation.F3112_AN8"),
        },
        {
          key: "F3112_AN8_desc",
          name: t("entity.operation:operation.F3112_AN8_desc"),
        },
      ];
    };
  }

  getDefaultInstancesColumns = (): ColumnsProvider => () => [];
  getInitialInstancesQueryAttributes = (): QueryAttributeDto[] => [];

  getInitialQuery = (props: IGetInitialQueryProps): QueryAttributeGroupDto => {
    const { currentSchedule, currentWorkOrderRef } = props;
    return {
      $and: [
        getFolderQueryGroup(currentSchedule!, ""),
        {
          attribute_name: "F4801_DOCO",
          attribute_value: {
            operator: "$eq",
            value: currentWorkOrderRef!.Orderid,
          },
        },
      ],
    };
  };

  getExecutionFetchOperations = (): ExecutionOperationsProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return (props: IFetchExecutionOperationsProps): Promise<any> => {
        const { fetchProps, initialQuery } = props;
        return adapterContext!.service.getAdapterService().fetchOperations({
          ...fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributeGroup: initialQuery,
          }),
        });
      };
    };
  };

  getExecutionTabOperationEntity = () => "operation";

  getDefaultMaterialRequirementsColumns(
    props: IGetDefaultMaterialRequirementsColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "F3111_DOCO",
          name: t("entity.wo_part:wo_part.F3111_DOCO"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.materialRequirementActions}
            />
          ),
        },
        {
          key: "F3111_OPSQ",
          name: t("entity.wo_part:wo_part.F3111_OPSQ"),
        },
        {
          key: "F3111_TBM",
          name: t("entity.wo_part:wo_part.F3111_TBM"),
        },
        {
          key: "F3111_CPNT",
          name: t("entity.wo_part:wo_part.F3111_CPNT"),
        },
        {
          key: "F3111_CPIT",
          name: t("entity.wo_part:wo_part.F3111_CPIT"),
        },
        {
          key: "F3111_DSC1",
          name: t("entity.wo_part:wo_part.F3111_DSC1"),
        },
        {
          key: "F3111_DRQJ",
          name: t("entity.wo_part:wo_part.F3111_DRQJ"),
        },
        {
          key: "F3111_TRQT",
          name: t("entity.wo_part:wo_part.F3111_TRQT"),
        },
        {
          key: "F3111_UM",
          name: t("entity.wo_part:wo_part.F3111_UM"),
        },
        {
          key: "F3111_UORG",
          name: t("entity.wo_part:wo_part.F3111_UORG"),
        },
        {
          key: "F3111_SOCN",
          name: t("entity.wo_part:wo_part.F3111_SOCN"),
        },
        {
          key: "F3111_SOBK",
          name: t("entity.wo_part:wo_part.F3111_SOBK"),
        },
        {
          key: "F3111_QNTA",
          name: t("entity.wo_part:wo_part.F3111_QNTA"),
        },
        {
          key: "F3111_UPMJ",
          name: t("entity.wo_part:wo_part.F3111_UPMJ"),
        },
        {
          key: "F3111_VEND",
          name: t("entity.wo_part:wo_part.F3111_VEND"),
        },
      ];
    };
  }

  getExecutionTabMaterialRequirementsEntity = () => "wo_part";

  getOperationAttributeValuePath = () => "to_Operation._id";

  getDefaultTimecardColumns(
    props: IGetDefaulTimecardColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "F3112_DOCO",
          name: t("entity.timecard:timecard.F3112_DOCO"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.timecardActions}
            />
          ),
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.timecard:timecard.F3112_OPSQ"),
        },
        {
          key: "F3112_MCU",
          name: t("entity.timecard:timecard.F3112_MCU"),
        },
        {
          key: "F0101_AN8",
          name: t("entity.timecard:timecard.F0101_AN8"),
        },
        {
          key: "F0101_ALPH",
          name: t("entity.timecard:timecard.F0101_ALPH"),
        },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "approved_hours",
          name: t("entity.timecard:timecard.approved_hours"),
        },
        {
          key: "is_confirmed",
          name: t("module.timecard:timecard.is_confirmed"),
          formatter: (formatterProps) => {
            const isConfirmed: boolean = (formatterProps.row as any)
              .is_confirmed as boolean;
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: <RiverCheckmarkFormatter checked={isConfirmed} />,
                })}
              </>
            );
          },
        },
      ];
    };
  }
  getOperationActions = () => {
    return () => [];
  };

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <JdeWoMassUpdateDialog {...props} />;

  getDefaultDetailsColumns = (): ColumnsProvider => {
    const { t } = useTranslation();
    return () => [
      {
        key: "_id",
        name: t("entity.workorder:workorder._id"),
      },
      {
        key: "MCU",
        name: t("entity.workorder:workorder.MCU"),
      },
      {
        key: "F4801_DOCO",
        name: t("entity.workorder:workorder.F4801_DOCO"),
      },
      {
        key: "F4801_DCTO",
        name: t("entity.workorder:workorder.F4801_DCTO"),
      },
      {
        key: "F4801_LNID",
        name: t("entity.workorder:workorder.F4801_LNID"),
      },
      {
        key: "F4801_TYPS",
        name: t("entity.workorder:workorder.F4801_TYPS"),
      },
      {
        key: "F4801_PRTS_desc",
        name: t("entity.workorder:workorder.F4801_PRTS_desc"),
      },
      {
        key: "F4801_DL01",
        name: t("entity.workorder:workorder.F4801_DL01"),
      },
      {
        key: "F4801_MCU",
        name: t("entity.workorder:workorder.F4801_MCU"),
      },
      {
        key: "F4801_LOCN",
        name: t("entity.workorder:workorder.F4801_LOCN"),
      },
      {
        key: "F4801_SRST",
        name: t("entity.workorder:workorder.F4801_SRST"),
      },
      {
        key: "F4801_AN8",
        name: t("entity.workorder:workorder.F4801_AN8"),
      },
      {
        key: "F4801_TRDJ",
        name: t("entity.workorder:workorder.F4801_TRDJ"),
      },
      {
        key: "F4801_STRT",
        name: t("entity.workorder:workorder.F4801_STRT"),
      },
    ];
  };

  getDefaultAssignmentColumns(
    assignmentActions: IExecutionAction[]
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "F3112_DOCO",
          name: t("entity.assignment:assignment.F3112_DOCO"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={assignmentActions}
            />
          ),
        },
        {
          key: "F3112_OPSQ",
          name: t("entity.assignment:assignment.F3112_OPSQ"),
        },
        {
          key: "F3112_MCU",
          name: t("entity.assignment:assignment.F3112_MCU"),
        },
        {
          key: "F0101_AN8",
          name: t("entity.assignment:assignment.F0101_AN8"),
        },
        {
          key: "F0101_ALPH",
          name: t("entity.assignment:assignment.F0101_ALPH"),
        },
        {
          key: "F3112_RUNL",
          name: t("entity.assignment:assignment.F3112_RUNL"),
        },
        {
          key: "F3112_STRT",
          name: t("entity.assignment:assignment.F3112_STRT"),
        },
        {
          key: "F3112_DRQJ",
          name: t("entity.assignment:assignment.F3112_DRQJ"),
        },
      ];
    };
  }
}
