import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUserContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";

export function useApplicationLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    return {
      title: t("shared.lookup:application.title"),
      rowKey: "APPLICATION_ID",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "APPLICATION_NAME",
            name: t("entity.application:application.APPLICATION_NAME"),
          },
          {
            key: "APPLICATION_SHORT_NAME",
            name: t("entity.application:application.APPLICATION_SHORT_NAME"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "application",
              fetchHelpers.getTableQuery({ fetchProps })
            );
        },
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "application",
      },
    };
  };
}

export type IUseApplicationLookupDef = ReturnType<
  typeof useApplicationLookupDef
>;
