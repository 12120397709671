import React from "react";
import { useTranslation } from "@river/common-ui";
import { PageHeader } from "../../page-header";

export const LanguagesHeader: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      showSiteSelector={false}
      pageTitle={t("module.languages:module.title")}
    />
  );
};
