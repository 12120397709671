import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverCheckbox,
  useNotification,
  RiverDateInput,
} from "@river/common-ui";
import {
  AdapterTimelineDto,
  IAdapterCalendar,
  IAdapterTimeline,
  IEntityObject,
} from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTimelineForm } from "./use-timeline-form";
import { LookupType, RiverLookup } from "../../shared";
import { debounce } from "ts-debounce";
import { formatDynamicString } from "@river/util";
import * as dateFns from "date-fns";

interface ICrewDialogProps {
  open: boolean;
  timeline?: IAdapterTimeline;
  onClose: (crew: IAdapterTimeline | null) => void;
}

export const TimelinesDialog: React.FC<ICrewDialogProps> = (
  props
): ReactElement => {
  const notify = useNotification();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const [selectedCalendar, setSelectedCalendar] =
    useState<IAdapterCalendar | null>(null);
  const form: RiverFormInstance = useTimelineForm({
    timeline: props.timeline,
    selectedCalendar,
    onCreate: (timeline) => {
      props.onClose(timeline);
      resetForm();
      table?.refresh();
    },
    onUpdate: () => {
      props.onClose(null);
      resetForm();
      table?.refresh();
    },
  });
  const { t } = useTranslation();

  const {
    submit,
    resetForm,
    onStandalonePropertyChange,
    onStandaloneLookupSelect,
    validateStandaloneField,
    getStandaloneValidatorInstance,
    setStandaloneFields,
    validationErrors,
  } = form;
  const timelineDto = form.dto as AdapterTimelineDto;
  const dialogTitle: string = !props.timeline
    ? t("module.timelines:button.add_timeline")
    : t("module.timelines:button.update_timeline");

  useEffect(() => {
    if (props.open && timelineDto.calendar_id) {
      const fetchData = async () => {
        const calendar: IAdapterCalendar = await fetchCalendar(
          timelineDto.calendar_id as string,
          "_id"
        );
        const newStandaloneFields = getStandaloneValidatorInstance!({
          calendarName: calendar.calendar,
        });
        setStandaloneFields(newStandaloneFields);
        setSelectedCalendar(calendar);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, timelineDto.calendar_id]);

  useEffect(() => {
    validateStandaloneField("calendarName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendar]);

  const fetchCalendar = async (
    attribute_value: string,
    attribute_name: string
  ): Promise<IAdapterCalendar> => {
    const calendars: IAdapterCalendar[] = await adapterContext!.service
      .getAdapterService()
      .fetchCalendars({
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return calendars[0] as IAdapterCalendar;
  };

  const fetchCalendarByName = async (calendarName: string): Promise<void> => {
    try {
      const calendar: IAdapterCalendar = await fetchCalendar(
        calendarName,
        "calendar"
      );
      setSelectedCalendar(calendar);
      validateStandaloneField("calendarName", calendarName);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchCalendarByName = useCallback(
    debounce((text) => {
      fetchCalendarByName(text);
    }, 600),
    [validationErrors]
  );

  const renderCalendarField = (): ReactElement => (
    <RiverLookup
      id={"calendarName"}
      lookup={{ type: LookupType.CALENDAR }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchCalendarByName(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        onStandaloneLookupSelect("calendar", "calendarName")(selectedObject);
        setSelectedCalendar(selectedObject as IAdapterCalendar);
      }}
    />
  );

  const renderTemplateExampleField = (): ReactElement => (
    <>
      {timelineDto.timeline !== "" && timelineDto.start_date !== null && (
        <div>
          <p>Template Example:</p>
          <p>
            {formatDynamicString(timelineDto.folder_name_template, {
              timeline: timelineDto.timeline,
              year: dateFns.format(timelineDto.start_date, "yyyy"),
              month: dateFns.format(timelineDto.start_date, "MM"),
              day: dateFns.format(timelineDto.start_date, "dd"),
              week: dateFns.format(timelineDto.start_date, "ww"),
            })}
          </p>
        </div>
      )}
    </>
  );

  const renderStartDate = (): ReactElement => (
    <RiverDateInput
      id={"start_date"}
      fullWidth
      inputProps={{
        type: "date",
      }}
    />
  );

  const renderDurationDays = (): ReactElement => (
    <RiverTextInput
      id={"folder_duration_days"}
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        props.onClose(null);
        resetForm();
      }}
      actionButtonText={t("common.button:save")}
      onSubmit={submit}
      showActionsDivider={false}
      fullWidth
      form={form}
    >
      <RiverTextInput id={"timeline"} fullWidth />
      <RiverTextInput id={"description"} fullWidth />
      {renderStartDate()}
      {renderDurationDays()}
      <RiverTextInput id={"folder_name_template"} fullWidth />
      {renderTemplateExampleField()}
      {renderCalendarField()}
      <RiverCheckbox id={"use_avail_to_schedule_assignments"} />
    </RiverDialog>
  );
};
