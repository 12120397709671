import { ColumnsProvider, CrewsUiService, FetchFunctionProvider } from "..";
import { useTranslation } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import clsx from "clsx";
import styles from "../../components/shared/river-lookup/lookup-dialog/defs/person-lookup/person-lookup.module.scss";
import { Avatar } from "../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import { fetchHelpers } from "../../helpers";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useContext } from "react";
import { IEntityObject, JdeAdapterCrewCraftDto } from "@river/interfaces";
import { useTableCellRenderers } from "../../hooks";
import { RiverDefaultNumberEditor } from "../../components/shared/river-data-grid/cell-editors";

const WORKCENTER_BRANCH_SEPARATOR: string = ".WORKCENTER_BRANCH_SEPARATOR.";

export class JdeCrewsUiService extends CrewsUiService {
  getAvailableCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    const { renderCell } = useTableCellRenderers();
    return [
      RiverSelectColumn,
      {
        key: "F0101_ALPH",
        name: t("entity.availability_header:availability_header.F0101_ALPH"),
        formatter: (formatterProps: any) => {
          return (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div className={clsx([styles.person])}>
                    <Avatar
                      url={utilizationHelpers.getRandomGanttAvatarURL(
                        Number(formatterProps.row.F0101_ALPH)
                      )}
                    />
                    <div>{formatterProps.row[formatterProps.column.key]}</div>
                  </div>
                ),
              })}
            </>
          );
        },
      },
      {
        key: "F0101_AN8",
        name: t("entity.availability_header:availability_header.F0101_AN8"),
      },
      {
        key: "WorkCenters.F30006_MCU",
        name: t("entity.availability_header:availability_header.WorkCenters"),
      },
    ];
  };

  getAssignedCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { renderCell } = useTableCellRenderers();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "F0101_ALPH",
        name: t("entity.crew_person:crew_person.F0101_ALPH"),
        formatter: (formatterProps: any) => {
          return (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div className={clsx([styles.person])}>
                    <Avatar
                      url={utilizationHelpers.getRandomGanttAvatarURL(
                        Number(formatterProps.row.F0101_AN8)
                      )}
                    />
                    <div>{formatterProps.row[formatterProps.column.key]}</div>
                  </div>
                ),
              })}
            </>
          );
        },
      },
      {
        key: "F0101_AN8",
        name: t("entity.crew_person:crew_person.F0101_AN8"),
      },
      {
        key: "WorkCenters.F30006_MCU",
        name: t("entity.crew_person:crew_person.WorkCenters"),
      },
    ];
  };

  getAvailableCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "F30006_MCU",
        name: t("entity.workcenter:workcenter.F30006_MCU"),
        width: 200,
      },
      {
        key: "F30006_MMCU",
        name: t("entity.workcenter:workcenter.F30006_MMCU"),
      },
      // {
      //   key: "number_of_units",
      //   name: t("entity.crew_workcenter:crew_workcenter.number_of_units"),
      // },
    ];
  };

  getAssignedCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "F30006_MCU",
        name: t("entity.crew_workcenter:crew_workcenter.F30006_MCU"),
        width: 150,
      },
      {
        key: "F30006_MMCU",
        name: t("entity.crew_workcenter:crew_workcenter.F30006_MMCU"),
        width: 150,
      },
      {
        key: "number_of_units",
        name: t("entity.crew_workcenter:crew_workcenter.number_of_units"),
        width: 150,
        editor: (props) => <RiverDefaultNumberEditor editorProps={props} />,
      },
    ];
  };

  resourceNumberField = "F0101_AN8";
  availableCrewCraftsEntityName = "workcenter";
  availableCrewCraftsRowKeyGetter = (row: any): string =>
    `${row["F30006_MCU"]}${WORKCENTER_BRANCH_SEPARATOR}${row["F30006_MMCU"]}`;

  assignedCrewCraftsEntityName = "crew_workcenter";

  getAvailableCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return async (fetchProps: ITableFetchFunctionProps) => {
        const craftIdKey: string = "F30006_MCU";
        const assignedCraftIds: string[] = [];
        const assignedCrafts: IEntityObject[] = await adapterContext!.service
          .getAdapterService()
          .searchEntityData("crew_workcenter", {
            query: {
              $and: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            },
          });
        assignedCrafts.forEach((craft) =>
          assignedCraftIds.push(craft[craftIdKey] as string)
        );

        return await adapterContext!.service.getAdapterService().fetchCrafts(
          fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributes: [
              {
                attribute_name: craftIdKey,
                attribute_value: {
                  operator: "$nin",
                  value: assignedCraftIds,
                },
              },
            ],
          })
        );
      };
    };
  };

  getAssignedCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return async (fetchProps: ITableFetchFunctionProps) => {
        return await adapterContext!.service
          .getAdapterService()
          .searchEntityData(
            "crew_workcenter",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            })
          );
      };
    };
  };
  getAssignCrewCraftsFn = (crew: string) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return (selectedIds: Set<string>): Promise<any> => {
        const promises: Promise<void>[] = [];
        selectedIds.forEach((selectedId) => {
          const selectedIDSegments: string[] = selectedId.split(
            WORKCENTER_BRANCH_SEPARATOR
          );

          const [F30006_MCU, F30006_MMCU] = selectedIDSegments;
          const dto: JdeAdapterCrewCraftDto = {
            crew,
            F30006_MCU,
            F30006_MMCU,
            number_of_units: 1,
          };
          promises.push(
            adapterContext!.service.getAdapterService().createCrewCraft(dto)
          );
        });
        return Promise.all(promises);
      };
    };
  };

  getErpSpecificI18nNamespaces(): string[] {
    return [];
  }
}
