import React from "react";
import { useTranslation } from "@river/common-ui";
import { PageHeader } from "../../page-header";

export const ValidationDashboardHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageHeader pageTitle={t("module.validation_dashboard:module.title")} />
  );
};
