import React, { SyntheticEvent, useEffect, useState } from "react";
import { EditorProps, FormatterProps } from "react-data-grid";
import { IRiverListPopupItem } from "../../../river-list-popup";
import { IAttribute, IAttributeOption } from "@river/interfaces";
import { ReactElement, useContext } from "react";
import { AdapterUiContext, TableContext } from "../../../../../context";
import { useNotification } from "@river/common-ui";
import { Constants } from "@river/constants";
import { RiverDropdownList } from "../../../../shared";
import {
  IRenderCellContentProps,
  useTableCellRenderers,
} from "../../../../../hooks";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import BackspaceIcon from "@mui/icons-material/Backspace";
import styles from "./river-default-value-list-editor.module.scss";
import clsx from "clsx";

interface IRiverDefaultValueListEditorProps {
  editorProps: EditorProps<any>;
}

interface IEditorProps {
  editorProps: EditorProps<any>;
  attribute: IAttribute;
  fontColor?: string;
}

export const Editor: React.FC<IEditorProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();

  const [attributeOption, setAttributeOption] =
    useState<IAttributeOption | null>(null);

  const useStyles = createUseStyles({
    title: {
      color: props.fontColor,
    },
  });
  const dynamicClasses = useStyles();

  useEffect(() => {
    const attributeValue =
      props.editorProps?.row[props.attribute?.attribute_name!];
    if (attributeValue) {
      const selectedOption = props.attribute?.options?.find(
        (item) => item.value === attributeValue
      );

      if (selectedOption) {
        setAttributeOption(selectedOption);
      } else {
        setAttributeOption(null);
      }
    } else {
      setAttributeOption(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table?.refresh, props.attribute]);

  const onDeleteValue = async (e: SyntheticEvent) => {
    e.stopPropagation();
    try {
      table?.forceLoadingState(true);
      const id = props.editorProps?.row._id;
      await adapterContext!.service
        .getAdapterService()
        .updateEntityData(props.attribute?.entity_name!, id, {
          [props.attribute?.attribute_name!]: null,
        });
      table?.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };

  const getObjectTypePayload = (option: IAttributeOption) => ({
    [props.attribute?.attribute_name!]: option.value,
  });

  const getStartIcon =
    (option: IAttributeOption): React.ComponentType<any> =>
    (startIconProps: any): ReactElement => {
      if (props.attribute?.input_type === Constants.input_type.value_list) {
        return (
          <div
            style={{ backgroundColor: option.color }}
            {...startIconProps}
          ></div>
        );
      } else {
        return <></>;
      }
    };

  const handlePopupItemClick = async (option: IAttributeOption) => {
    try {
      table?.forceLoadingState(true);
      const id = props.editorProps?.row._id;
      await adapterContext!.service
        .getAdapterService()
        .updateEntityData(
          props.attribute?.entity_name || "",
          id,
          getObjectTypePayload(option)
        );
      table?.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };

  const getPopupItems = (): IRiverListPopupItem[] =>
    (props.attribute?.options || []).map((option) => ({
      title: option.value,
      onClick: () => handlePopupItemClick(option),
      ...(props.attribute?.input_type === Constants.input_type.value_list && {
        startIcon: getStartIcon(option),
      }),
    }));

  const renderToggle = (): ReactElement => (
    <>
      {attributeOption && props.attribute?.attribute_name! && (
        <>
          {attributeOption.color && (
            <div className={styles.titleColorContainer}>
              <div
                style={{ backgroundColor: attributeOption.color }}
                className={styles.titleColor}
              />
            </div>
          )}
          <p className={clsx([styles.title, dynamicClasses.title])}>
            {attributeOption.value}
          </p>
          <BackspaceIcon
            titleAccess={t("shared.river_data_grid:label.delete_value")}
            color={"primary"}
            onClick={onDeleteValue}
            classes={{
              root: styles.clearIcon,
              colorPrimary: dynamicClasses.title,
            }}
            fontSize="small"
          />
        </>
      )}
    </>
  );

  return (
    <RiverDropdownList
      toggle={renderToggle()}
      items={getPopupItems()}
      openOnDoubleClick={true}
      classes={{
        toggle: styles.toggle,
        item: styles.item,
        startIconClass: styles.startIcon,
        endIconClass: styles.endIcon,
      }}
    />
  );
};

export const RiverDefaultValueListEditor: React.FC<
  IRiverDefaultValueListEditorProps
> = (props: IRiverDefaultValueListEditorProps) => {
  const { editorProps } = props;
  const { column } = editorProps;
  const { renderCell } = useTableCellRenderers();

  return (
    <>
      {renderCell({
        formatterProps: editorProps as unknown as FormatterProps<any>,
        content: (cellContentProps: IRenderCellContentProps) => (
          <TableContext.Consumer>
            {(tableContext) => {
              const attribute = tableContext?.entity.attributesByName.get(
                column.key
              );

              return (
                <Editor
                  editorProps={editorProps}
                  attribute={attribute!}
                  fontColor={cellContentProps.fontColor}
                />
              );
            }}
          </TableContext.Consumer>
        ),
      })}
    </>
  );
};
