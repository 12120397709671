import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from "react";
import { IScheduleGanttTimeOption } from "../components/schedule/schedule-tasks/schedule-gantt-header-options";
import { IUseTable } from "../components/shared";
import { SetTabFn } from "../context";
import {
  IAdapterBaseline,
  IAdapterFolder,
  IAdapterRule,
} from "@river/interfaces";
import { IUseScheduleGantt } from "../components/schedule/schedule-gantt/use-schedule-gantt";
import { IUseScheduleOperationsOverlay } from "../components/schedule/schedule-gantt";
import { DataGridHandle } from "react-data-grid";

export interface IScheduleContext {
  currentSchedule: IAdapterFolder | undefined;
  refreshCurrentSchedule: () => void;

  selectedGanttDate: Date | undefined;
  setSelectedGanttDate: (date: Date) => void;

  ganttTimeOption: IScheduleGanttTimeOption | undefined;
  setGanttTimeOption: (options: IScheduleGanttTimeOption) => void;

  ganttSplitterRef: RefObject<HTMLDivElement> | undefined;
  setGanttSplitterRef: (ref: RefObject<HTMLDivElement>) => void;

  utilizationSplitterRef: RefObject<HTMLDivElement> | undefined;
  setUtilizationSplitterRef: (ref: RefObject<HTMLDivElement>) => void;

  entityTableRef: RefObject<DataGridHandle> | undefined;
  setEntityTableRef: (ref: RefObject<DataGridHandle>) => void;

  ganttScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setGanttScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  ganttOverlayScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setGanttOverlayScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  utilizationScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setUtilizationScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  currentTasksTableRef: MutableRefObject<IUseTable | undefined>;
  ganttRef: MutableRefObject<IUseScheduleGantt | undefined>;
  utilizationGanttRef: MutableRefObject<IUseScheduleGantt | undefined>;
  operationsOverlayRef: MutableRefObject<
    IUseScheduleOperationsOverlay | undefined
  >;

  forceScheduleRender: () => void;

  scheduleUtilizationCurrentTableState: IUseTable | undefined;
  setScheduleUtilizationCurrentTableState: (table: IUseTable) => void;

  scheduleFn: (date: Date, ids?: string[]) => void;

  selectedValidationRule: IAdapterRule | null;
  setSelectedValidationRule: (rule: IAdapterRule | null) => void;

  selectedBaseline: IAdapterBaseline | undefined;
  setSelectedBaseline: Dispatch<SetStateAction<IAdapterBaseline | undefined>>;

  scheduleTasksExpanded: boolean;
  setScheduleTasksExpanded: (expanded: boolean) => void;

  setCurrentScheduleTasksTabFnRef: MutableRefObject<SetTabFn | undefined>;
}

export const ScheduleContext = React.createContext<IScheduleContext | null>(
  null
);
