import React, { ReactElement, useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { TableContext } from "../../../../context";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useGridActionHelpers } from "../../../../helpers";
import { gridHelpers } from "../../river-data-grid";
import headerStyles from "../../common-header/common-header.module.scss";
import clsx from "clsx";

export const useFilterAction = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);

  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("shared.grid_header:label.filter");

  const onClick = (): void => {
    tableContext?.table.fetch();
  };

  const renderFilterAction = (props?: any): ReactElement => {
    const tableHasFiltersApplied = gridHelpers.hasFiltersApplied(
      tableContext?.table
    );
    const shouldBlink = tableHasFiltersApplied;
    const className = clsx(headerStyles.actionIcon, {
      [headerStyles.blinker]: shouldBlink,
    });

    return (
      <FilterAltOutlinedIcon
        titleAccess={title}
        onClick={onClick}
        {...props}
        className={className}
      />
    );
  };

  return {
    renderFilterAction,
    filterAction: {
      icon: withSanitizedProps(renderFilterAction),
      title,
      onClick,
    },
  };
};

export type IUseFilterAction = ReturnType<typeof useFilterAction>;
