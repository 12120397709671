import React, { useState, useContext } from "react";
import { Column } from "react-data-grid";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { fetchHelpers, uiConstants } from "../../helpers";
import { RolesHeader } from "./roles-header";
import { RiverSpinner } from "@river/common-ui";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { IAdapterRole, IEntityObject } from "@river/interfaces";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { RolesGridHeader } from "./roles-grid-header";
import { RolesSubHeader } from "./roles-subheader";
import { EditRoleDialog } from "./edit-role-dialog";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";

export enum RolesAction {
  CREATE = "CREATE_ROLE",
  UPDATE = "UPDATE_ROLE",
  DELETE = "DELETE_ROLE",
}

export const Roles: React.FC = () => {
  usePageCleanup();

  const entityName: string = "role";
  const { t } = useTranslation();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const [editRoleDialogOpened, setEditRoleDialogOpened] =
    useState<boolean>(false);
  const [roleToEdit, setRoleToEdit] = useState<IAdapterRole | null>();
  const entity: IUseEntity = useEntity({ entityName });

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "role",
      name: t("entity.role:role.role"),
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  table.setSelectedRowIds(new Set([]));
                  openEditDialog(formatterProps.row as IAdapterRole);
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "description", name: t("entity.role:role.description") },
    { key: "license", name: t("entity.role:role.license") },
    { key: "created_at", name: t("entity.role:role.created_at") },
    { key: "updated_at", name: t("entity.role:role.updated_at") },
  ];

  const fetchRoles = async (fetchProps: ITableFetchFunctionProps) => {
    let roles = await adapterContext!.service
      .getAdapterService()
      .fetchRoles(fetchHelpers.getTableQuery({ fetchProps }));
    return roles.map((x) => {
      return { ...x };
    });
  };

  const table: IUseTable = useTable({
    entityName,
    columns,
    fetchFunction: fetchRoles,
    fetchOn: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const openEditDialog = (role: IAdapterRole): void => {
    setRoleToEdit(role);
    setEditRoleDialogOpened(true);
  };

  const getSelectedRole = (selectedRoleIds: Set<any>): IAdapterRole => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      selectedRoleIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterRole;
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <RolesHeader />
        <RolesSubHeader openEditDialog={openEditDialog} />
        <RolesGridHeader
          setEditRoleDialogOpened={setEditRoleDialogOpened}
          roleToEdit={roleToEdit!}
        />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSelectedRowsChange={(selectedRoleIds) =>
            setRoleToEdit(getSelectedRole(selectedRoleIds))
          }
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
        <EditRoleDialog
          open={editRoleDialogOpened}
          role={roleToEdit!}
          onClose={(success) => {
            setEditRoleDialogOpened(false);
            if (success) table.refresh();
          }}
        />
      </TableContext.Provider>
    </>
  );
};
