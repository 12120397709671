import React, { useContext, ReactElement, MouseEventHandler } from "react";
import { IconButton } from "@mui/material";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
  TableContext,
} from "../../../../../context";
import { WoCompletionContext } from "../wo-completion-context";
import { WoCompletionStatus } from "../oracle-ebs-wo-completion-dialog";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IEntityObject } from "@river/interfaces";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IUseTable } from "../../../../../components/shared";
import { TableUiService } from "../../../../table-ui.service";
import styles from "./wo-completion-tabs.module.scss";
import clsx from "clsx";

interface IWoCompletionTabs {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
}

interface ITabContentProps {
  index: number;
  woNum: string;
  tabWorkOrder: IEntityObject;
  completionStatus: WoCompletionStatus;
}

const TabContent = (props: ITabContentProps) => {
  const tabContext = useContext(TabContext);

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getRowsFromObjectIds = tableUiService.getRowsFromObjectIds();

  const tableContext = useContext(TableContext);
  const woCompletionContext = useContext(WoCompletionContext)!;
  const { completionStatusMap, woCompletions, setWoCompletions, closeDialog } =
    woCompletionContext;

  const { index, woNum, tabWorkOrder, completionStatus } = props;
  const woStatus: string =
    completionStatus?.workOrder?.["WORK_ORDER_STATUS"] ??
    (tabWorkOrder["WORK_ORDER_STATUS"] as string);
  const isSuccess: boolean = completionStatus?.status === "success";
  const isError: boolean = completionStatus?.status === "error";

  const preventDefault: MouseEventHandler = (e): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const closeTab: MouseEventHandler = (e): void => {
    preventDefault(e);
    const woId: string = tabWorkOrder["_id"] as string;

    // update selected rows
    const table: IUseTable = tableContext?.table!;
    table.selectedRowIds.delete(woId);
    table.setSelectedRowIds(new Set(table.selectedRowIds));

    // if closing the last tab, also close the dialog
    if (table.selectedRowIds.size === 0) {
      closeDialog({ success: true });
      return;
    }

    // update wo completions
    const newCompletions: any[] = woCompletions.filter((completion) => {
      return completion["_id"] !== woId;
    });
    setWoCompletions([...newCompletions]);

    // update completion status map
    delete completionStatusMap[woId];

    // if closing currently selected tab, select the first tab in the list
    if (woNum === tabContext?.selectedTab) {
      const newWoId: string = Array.from(table.selectedRowIds)[0];
      const newSelectedWo: IEntityObject = getRowsFromObjectIds(table, [
        newWoId,
      ])[0];
      const newWoNum: string = newSelectedWo["WIP_ENTITY_NAME"] as string;
      tabContext?.setSelectedTab(newWoNum);
    }
  };

  return (
    <div className={clsx([styles.tabContent])}>
      <div className={styles.tabInfo}>
        <div className={styles.tabHeader}>
          <span className={styles.index}>{index + 1}.</span>
          <span className={styles.title}>WO# {woNum}</span>
        </div>
        <div className={styles.tabSubHeader}>Status: {woStatus}</div>
      </div>
      <div className={styles.statusIconContainer}>
        {isSuccess && <CheckIcon className={styles.tabStatusIcon} />}
        {isError && <CloseIcon className={styles.tabStatusIcon} />}
      </div>
      <IconButton
        onClick={closeTab}
        onMouseDown={preventDefault}
        onMouseUp={preventDefault}
        className={styles.closeTabButton}
      >
        <CloseIcon className={styles.icon} />
      </IconButton>
    </div>
  );
};

export const WoCompletionTabs: React.FC<IWoCompletionTabs> = (
  props
): ReactElement => {
  const tabContext = useContext(TabContext);
  const woCompletionContext = useContext(WoCompletionContext)!;
  const { completionStatusMap, selectedWorkOrders } = woCompletionContext;

  const tabs: TabProps[] = selectedWorkOrders.map((wo) => {
    return { value: wo["WIP_ENTITY_NAME"] as string };
  });

  const isUsingContext: boolean = typeof props.value === "undefined";
  const value: string | undefined = isUsingContext
    ? tabContext?.selectedTab
    : props.value;

  const renderTab = (tabProps: TabProps, index: number): ReactElement => {
    const tabWorkOrder: IEntityObject = selectedWorkOrders.find(
      (wo) => wo["WIP_ENTITY_NAME"] === tabProps.value
    );
    const woId: string = tabWorkOrder["_id"] as string;
    const completionStatus: WoCompletionStatus = completionStatusMap[woId]!;
    const isSuccess: boolean = completionStatus?.status === "success";
    const isError: boolean = completionStatus?.status === "error";

    return (
      <Tab
        key={index}
        classes={{
          root: clsx([
            styles.tabRoot,
            {
              [styles.success]: isSuccess,
              [styles.error]: isError,
            },
          ]),
          selected: styles.selectedTab,
        }}
        {...tabProps}
        label={
          <TabContent
            index={index}
            woNum={tabProps.value}
            tabWorkOrder={tabWorkOrder}
            completionStatus={completionStatus}
          />
        }
      />
    );
  };

  return (
    <>
      {value && (
        <Tabs
          value={value}
          onChange={(event, value) => {
            isUsingContext && tabContext?.setSelectedTab(value);
            props.onChange && props.onChange(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          className={props.className}
          classes={{
            root: styles.root,
            scroller: styles.scroller,
            flexContainer: styles.flexContainer,
            indicator: styles.tabIndicator,
          }}
          variant="scrollable"
          scrollButtons="auto"
          slots={{
            StartScrollButtonIcon: ArrowBackIosNewIcon,
            EndScrollButtonIcon: ArrowForwardIosIcon,
          }}
        >
          {tabs.map(renderTab)}
        </Tabs>
      )}
    </>
  );
};
