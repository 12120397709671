import { TabProps } from "@mui/material/Tab";
import {
  ColumnsProvider,
  EntityTableProvider,
  ExecutionOperationsProvider,
  ExecutionUiService,
  IGetDefaultInstancesColumnsProps,
  IGetInitialInstancesQueryAttributesProps,
  IUseCurrentTableProps,
  IUseEntityTable,
  ReactElementProvider,
  TabsProvider,
  IWoMassUpdateDialogProps,
} from "../../services";
import { TFunction } from "i18next";
import {
  ExecutionTabId,
  IFetchExecutionOperationsProps,
  IGetDefaulTimecardColumnsProps,
  IGetDefaultMaterialRequirementsColumnsProps,
  IGetDefaultOperationColumnsProps,
  IGetInitialQueryProps,
  IWoPaneMenuItems,
  useExecutionDetails,
} from "../execution-ui-service";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { fetchHelpers } from "../../helpers";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
  TabContext,
} from "../../context";
import {
  useExecutionTimecards,
  useExecutionInstances,
  useExecutionMaterialRequirements,
  useExecutionOperations,
} from "../execution-ui-service";
import { IRiverPopupMenuItem } from "../../components/shared/river-popup-menu";
import { getFolderQueryGroup } from "@river/util";
import { useTableCellRenderers } from "../../hooks";
import { ExecutionActionsFormatter } from "../../components/execution/execution-actions-formatter";
import {
  FilterDropdown,
  RiverCheckmarkFormatter,
  RiverDropdownActions,
  useWoMassUpdateAction,
} from "../../components/shared";
import { ModuleKey } from "../../components/sidebar-menu";
import {
  ExecutionActions,
  IExecutionAction,
} from "../../components/execution/execution";
import { OracleCloudWoMassUpdateDialog } from "./oracle-cloud-shared";

export class OracleCloudExecutionUiService extends ExecutionUiService {
  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
    ];
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.execution:tab.details.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.DETAILS,
      },
      {
        label: t("module.execution:tab.operations.title"),
        icon: <DeviceHubIcon />,
        value: ExecutionTabId.OPERATIONS,
      },
      {
        label: t("module.execution:tab.instances.title"),
        icon: <LibraryBooksIcon />,
        value: ExecutionTabId.INSTANCES,
      },
      {
        label: t("module.execution:tab.material.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.MATERIAL,
      },
      {
        label: t("module.execution:tab.timecards.title"),
        icon: <DynamicFeedIcon />,
        value: ExecutionTabId.TIMECARDS,
      },
    ];
  };

  getWoPaneMenuItems = (
    props: IWoPaneMenuItems
  ): (() => IRiverPopupMenuItem[]) => {
    return () => {
      const { executionContext, workOrder } = props;
      const { t } = useTranslation();

      const tabContext = useContext(TabContext);
      return [
        {
          label: t("module.execution:tab.details.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.DETAILS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.operations.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.OPERATIONS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.instances.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.INSTANCES);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.material.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.MATERIAL);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
        {
          label: t("module.execution:tab.timecards.title"),
          onClick: () => {
            tabContext?.setSelectedTab(ExecutionTabId.TIMECARDS);
            executionContext?.setCurrentWorkOrder(workOrder);
          },
        },
      ];
    };
  };

  useCurrentTable = (props: IUseCurrentTableProps): EntityTableProvider => {
    return () => {
      const { executionContext } = props;

      const detailsTable: IUseEntityTable = useExecutionDetails({
        executionContext,
      });
      const operationsTable: IUseEntityTable = useExecutionOperations({
        executionContext,
      });
      const instancesTable: IUseEntityTable = useExecutionInstances({
        executionContext,
      });
      const materialTable: IUseEntityTable = useExecutionMaterialRequirements({
        executionContext,
      });
      const completionTable: IUseEntityTable = useExecutionTimecards({
        executionContext,
      });

      const currentTab: ExecutionTabId = this.getCurrentTab()();
      if (currentTab === ExecutionTabId.MATERIAL) {
        return materialTable;
      } else if (currentTab === ExecutionTabId.DETAILS) {
        return detailsTable;
      } else if (currentTab === ExecutionTabId.INSTANCES) {
        return instancesTable;
      } else if (currentTab === ExecutionTabId.TIMECARDS) {
        return completionTable;
      } else {
        return operationsTable;
      }
    };
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const executionContext = useContext(ExecutionContext);
      const folderId: string = executionContext?.currentSchedule?._id!;
      const currentWorkOrderId: string = executionContext?.currentWorkOrder
        ?._id as string;

      const completionPercentageDialogAction =
        executionContext?.completionPercentageDialogAction!;

      const { woMassUpdateAction } = useWoMassUpdateAction({
        renderWoMassUpdateDialog: this.renderWoMassUpdateDialog,
        disabled: false,
        workorderIds: [currentWorkOrderId],
      });
      return (
        <>
          {currentTab === ExecutionTabId.DETAILS && (
            <>
              <RiverDropdownActions
                module={ModuleKey.EXECUTION}
                items={[
                  {
                    title: woMassUpdateAction.title,
                    startIcon: woMassUpdateAction.icon,
                    onClick: woMassUpdateAction.onClick,
                    action: ExecutionActions.WO_EDIT,
                  },

                  {
                    title: completionPercentageDialogAction.title,
                    startIcon: completionPercentageDialogAction.icon,
                    onClick: completionPercentageDialogAction.onClick,
                    action: ExecutionActions.WO_COMPLETION_PCT,
                  },
                ]}
              />
              {woMassUpdateAction.renderDialog()}
              {completionPercentageDialogAction.renderDialog({
                entity_name: "workorder",
                entity_ids: [currentWorkOrderId],
                folderId,
              })}
            </>
          )}
          {currentTab === ExecutionTabId.OPERATIONS && (
            <>
              <FilterDropdown />
              {completionPercentageDialogAction.renderDialog({
                entity_name: "operation",
                folderId,
              })}
            </>
          )}
          {currentTab === ExecutionTabId.INSTANCES && <FilterDropdown />}
          {currentTab === ExecutionTabId.MATERIAL && <FilterDropdown />}
          {currentTab === ExecutionTabId.TIMECARDS && <FilterDropdown />}
        </>
      );
    };
  }

  getIsTableView(): () => boolean {
    return (): boolean => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      return (
        currentTab === ExecutionTabId.DETAILS ||
        currentTab === ExecutionTabId.OPERATIONS ||
        currentTab === ExecutionTabId.INSTANCES ||
        currentTab === ExecutionTabId.MATERIAL ||
        currentTab === ExecutionTabId.TIMECARDS
      );
    };
  }

  getWoItemPropLabelMap = (t: TFunction) =>
    new Map([
      ["WorkOrderNumber", t("entity.workorder:workorder.WorkOrderNumber")],
      [
        "WorkOrderDescription",
        t("entity.workorder:workorder.WorkOrderDescription"),
      ],
      ["AssetNumber", t("entity.workorder:workorder.AssetNumber")],
      ["WorkOrderTypeCode", t("entity.workorder:workorder.WorkOrderTypeCode")],
    ]);

  getWoPropsToRender = () => [
    "WorkOrderNumber",
    "WorkOrderDescription",
    "AssetNumber",
    "WorkOrderTypeCode",
  ];

  getDefaultOperationColumns(
    props: IGetDefaultOperationColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
          width: 80,
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.operationActions}
            />
          ),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t("module.execution:label.ResourceSequenceNumber"),
        },
        {
          key: "WorkOrderOperationResource.WorkCenterName",
          name: t("module.execution:label.WorkCenterName"),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("module.execution:label.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("module.execution:label.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("module.execution:label.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationResource.AssignedUnits",
          name: t("module.execution:label.AssignedUnits"),
        },
        {
          key: "WorkOrderOperationResource.PlannedStartDate",
          name: t("module.execution:label.PlannedStartDate"),
        },
        {
          key: "WorkOrderOperationResource.PlannedCompletionDate",
          name: t("module.execution:label.PlannedCompletionDate"),
        },
      ];
    };
  }

  getInitialQuery = (props: IGetInitialQueryProps): QueryAttributeGroupDto => {
    const { currentSchedule, currentWorkOrderRef } = props;
    return {
      $and: [
        getFolderQueryGroup(currentSchedule!, ""),
        {
          attribute_name: "WorkOrderNumber",
          attribute_value: {
            operator: "$eq",
            value: currentWorkOrderRef!.WorkOrderNumber,
          },
        },
      ],
    };
  };

  getExecutionFetchOperations = (): ExecutionOperationsProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return (props: IFetchExecutionOperationsProps): Promise<any> => {
        const { initialQuery, fetchProps } = props;
        return adapterContext!.service.getAdapterService().fetchOperations({
          ...fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributeGroup: initialQuery,
          }),
          $unwind: ["WorkOrderOperationResource"],
        });
      };
    };
  };

  getExecutionTabOperationEntity = () => "operation";

  getDefaultMaterialRequirementsColumns(
    props: IGetDefaultMaterialRequirementsColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
          width: 80,
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.materialRequirementActions}
            />
          ),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationMaterial.MaterialSequenceNumber",
          name: t("module.execution:label.MaterialSequenceNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.InventoryItemNumber",
          name: t("module.execution:label.InventoryItemNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.Quantity",
          name: t("module.execution:label.Quantity"),
        },
        {
          key: "WorkOrderOperationMaterial.UnitOfMeasure",
          name: t("module.execution:label.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationMaterial.RequiredDate",
          name: t("module.execution:label.RequiredDate"),
        },
        {
          key: "WorkOrderOperationMaterial.ReservedQuantity",
          name: t("module.execution:label.ReservedQuantity"),
        },
        {
          key: "WorkOrderOperationMaterial.IssuedQuantity",
          name: t("module.execution:label.IssuedQuantity"),
        },
      ];
    };
  }

  getDefaultInstancesColumns(
    props: IGetDefaultInstancesColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { instancesActions } = props;
      return [
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={instancesActions}
            />
          ),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t("module.execution:label.ResourceSequenceNumber"),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("module.execution:label.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("module.execution:label.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("module.execution:label.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.LaborInstanceName",
          name: t("module.execution:label.LaborInstanceName"),
        },
        {
          key: "WorkOrderOperationResource.PlannedStartDate",
          name: t("module.execution:label.PlannedStartDate"),
        },
        {
          key: "WorkOrderOperationResource.PlannedCompletionDate",
          name: t("module.execution:label.PlannedCompletionDate"),
        },
      ];
    };
  }

  getInitialInstancesQueryAttributes = (
    props: IGetInitialInstancesQueryAttributesProps
  ): QueryAttributeDto[] => {
    const { currentWorkOrder } = props;
    return [
      {
        attribute_name: "OrganizationId",
        attribute_value: {
          operator: "$eq",
          value: currentWorkOrder!.OrganizationId,
        },
      },
      {
        attribute_name: "WorkOrderNumber",
        attribute_value: {
          operator: "$eq",
          value: currentWorkOrder!.WorkOrderNumber,
        },
      },
    ];
  };

  getExecutionTabMaterialRequirementsEntity = () => "operation";

  getOperationAttributeValuePath = () => "_id";

  getDefaultTimecardColumns(
    props: IGetDefaulTimecardColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "LaborInstanceName",
          name: t("entity.timecard:timecard.LaborInstanceName"),
          formatter: (formatterProps) => (
            <ExecutionActionsFormatter
              formatterProps={formatterProps}
              actions={props.timecardActions}
            />
          ),
        },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "is_confirmed",
          name: t("module.execution:label.is_confirmed"),
          formatter: (formatterProps) => {
            const isConfirmed: boolean = (formatterProps.row as any)
              .is_confirmed as boolean;
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: <RiverCheckmarkFormatter checked={isConfirmed} />,
                })}
              </>
            );
          },
        },
      ];
    };
  }

  getOperationActions = () => {
    return () => [];
  };

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleCloudWoMassUpdateDialog {...props} />;

  getDefaultDetailsColumns = (): ColumnsProvider => {
    const { t } = useTranslation();
    return () => [
      {
        key: "WorkOrderNumber",
        name: t("entity.workorder:workorder.WorkOrderNumber"),
      },
      {
        key: "WorkOrderDescription",
        name: t("entity.workorder:workorder.WorkOrderDescription"),
      },
      {
        key: "AssetNumber",
        name: t("entity.workorder:workorder.AssetNumber"),
      },
      {
        key: "WorkOrderTypeCode",
        name: t("entity.workorder:workorder.WorkOrderTypeCode"),
      },
      {
        key: "WorkOrderSubTypeCode",
        name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
      },
      {
        key: "WorkOrderStatusCode",
        name: t("entity.workorder:workorder.WorkOrderStatusCode"),
      },
      {
        key: "WorkOrderPriority",
        name: t("entity.workorder:workorder.WorkOrderPriority"),
      },
      {
        key: "WorkOrderSubTypeCode",
        name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
      },
      {
        key: "PlannedStartDate",
        name: t("entity.workorder:workorder.PlannedStartDate"),
      },
      {
        key: "PlannedCompletionDate",
        name: t("entity.workorder:workorder.PlannedCompletionDate"),
      },
    ];
  };

  getDefaultAssignmentColumns(
    assignmentActions: IExecutionAction[]
  ): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
