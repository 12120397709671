import { AdapterUiService } from "./adapter-ui.service";
import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { fetchHelpers } from "../helpers";
import { useEntity } from "../hooks";
import { IAdapterUserContextProperties } from "@river/interfaces";
import { AdapterUserContextProp, IUserContextSite } from "../context";
import { DEFAULT_LANGUAGE } from "../i18n";

const DEFAULT_TABLE_SAVE_KEY = "sites";

interface IUseSitesProps {
  tableSaveKey?: string;
}

export abstract class SitesUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useSites = (props?: IUseSitesProps): EntityTableProvider => {
    return () => {
      const { t } = useTranslation();
      const { entityName, columns } = this.getSiteEntityTableParams(t);
      const { RiverSelectColumn } = useRiverSelectColumn();

      const fetchSites = async (fetchProps: ITableFetchFunctionProps) =>
        await this.adapterUiService
          .getAdapterService()
          .fetchSites(fetchHelpers.getTableQuery({ fetchProps }));

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchSites,
          fetchOn: true,
          fetchTriggers: [],
          saveKey: props?.tableSaveKey || DEFAULT_TABLE_SAVE_KEY,
          rowKeyGetter: this.getSiteRowKey,
          useAdvancedFilters: false,
        }),
      };
    };
  };

  getDefaultUserProperties =
    async (): Promise<IAdapterUserContextProperties> => {
      return {
        [AdapterUserContextProp.SITE]: await this.getDefaultSite(),
        [AdapterUserContextProp.LANGUAGE]: DEFAULT_LANGUAGE,
      };
    };

  getDefaultSite = async (): Promise<IUserContextSite> => {
    const sites = await this.adapterUiService
      .getAdapterService()
      .fetchSites({ $limit: 1 });
    return this.getSitePropertyFromErpRecord(sites[0]);
  };

  hasAccessToSite = async (siteId: string): Promise<boolean> => {
    const siteKeyColumn: string = this.getSiteKeyColumn();
    const result = await this.adapterUiService.getAdapterService().fetchSites({
      query: {
        $and: [
          {
            attribute_name: siteKeyColumn,
            attribute_value: {
              operator: "$eq",
              value: siteId,
            },
          },
        ],
      },
    });
    return result.length === 1;
  };

  protected abstract getSiteEntityTableParams(
    t: TFunction
  ): IUseEntityTableParams;

  protected abstract getSiteRowKey(siteRec: any): any;

  abstract getSitePropertyFromErpRecord(siteRec: any): IUserContextSite;

  abstract getSiteKeyColumn(): string;
}
