import React from "react";
import { PrimaryButton } from "@river/common-ui";

import headerStyles from "../../shared/common-header/common-header.module.scss";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { UsersAction } from "../users";

interface IUsersSubHeaderProps {
  openAddUserDialog: () => void;
}

export const UsersSubHeader: React.FC<IUsersSubHeaderProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction module={ModuleKey.USERS} action={UsersAction.UPDATE}>
          <PrimaryButton
            onClick={props.openAddUserDialog}
            text={t("module.users:button.add_user")}
          />
        </ProtectedAction>
      </div>
    </>
  );
};
