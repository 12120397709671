import React from "react";
import {
  IAccessTokenPayload,
  IAdapterUserContextProperties,
  IModuleSummary,
} from "@river/interfaces";

export enum AdapterUserContextProp {
  SITE = "site",
  LANGUAGE = "language",
}

export interface IUserContextSite {
  site_id: string | number;
  site_code: string;
  description?: string;
}

export interface IAdapterUserContext {
  user: IAccessTokenPayload;
  userProperties: IAdapterUserContextProperties;
  saveUserProperties: (properties: IAdapterUserContextProperties) => void;
  updateUserProperty: (
    prop: AdapterUserContextProp,
    value: any
  ) => Promise<void>;
  updateUserProperties: (properties: Partial<AdapterUserContextProp>) => void;
  onLoggedIn: (userInfo: IAccessTokenPayload | null) => void;
  userModules?: IModuleSummary[];
  fetchUserModules: () => Promise<void>;
}

export const AdapterUserContext =
  React.createContext<IAdapterUserContext | null>(null);
