import React, { ReactElement, useCallback, useContext, useEffect } from "react";
import { debounce } from "ts-debounce";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import { OracleEbsAdapterWorkorderCompletionDto } from "@river/interfaces";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";

interface IUseWoStatusLookupProps {
  form: RiverFormInstance;
  selectedWoStatus: any;
  setSelectedWoStatus: (selectedWoStatus: any) => void;
}

export const useWoStatusLookup = (props: IUseWoStatusLookupProps) => {
  const notify = useNotification();

  const { form, selectedWoStatus, setSelectedWoStatus } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    if ((form.standalone as any)["workOrderStatus"]) {
      validateStandaloneField("workOrderStatus");
    }

    const SYSTEM_STATUS = selectedWoStatus?.["SYSTEM_STATUS"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { STATUS_TYPE: SYSTEM_STATUS }
    );
    form.setDto(newDto);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWoStatus]);

  const fetchWoStatus = async (woStatusName: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("wo_status", {
          query: {
            $and: [
              {
                attribute_name: "WORK_ORDER_STATUS",
                attribute_value: {
                  operator: "$eq",
                  value: woStatusName,
                },
              },
            ],
          },
        });
      const woStatus: any = results[0];
      setSelectedWoStatus(woStatus);
      validateStandaloneField("workOrderStatus", woStatusName);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchWoStatus = useCallback(
    debounce((text) => {
      fetchWoStatus(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"workOrderStatus"}
      fullWidth
      lookup={{
        type: LookupType.ORACLE_EBS_WO_STATUS,
        customFilters: [
          {
            attribute_name: "SYSTEM_STATUS",
            attribute_value: {
              operator: "$in",
              value: [4, 5],
            },
          },
        ],
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchWoStatus(event.target.value);
      }}
      onSelect={(selectedStatus) => {
        const { SYSTEM_STATUS, WORK_ORDER_STATUS } = selectedStatus;
        const currentDto: OracleEbsAdapterWorkorderCompletionDto =
          form.dto as OracleEbsAdapterWorkorderCompletionDto;

        form.setDto({
          ...currentDto,
          STATUS_TYPE: SYSTEM_STATUS,
        });

        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          workOrderStatus: WORK_ORDER_STATUS,
        });

        setSelectedWoStatus(selectedStatus);
      }}
    />
  );

  return {
    render,
  };
};

export type IUseWoStatusLookup = ReturnType<typeof useWoStatusLookup>;
