import React, { useContext, useRef, ForwardedRef } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto"; //ChartJS to register its necessary elements
import { IAdapterSchedulingComplianceSummary } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  TableContext,
} from "../../../../context";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useTranslation } from "@river/common-ui";
import { RiverSpinner } from "@river/common-ui";
import styles from "./supervisor-scheduling-compliance.module.scss";
import { SupervisorScheduleUtilizationUiService } from "../../../../services";

export const SupervisorSchedulingCompliance: React.FC = () => {
  //
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const scheduleContext = useContext(SupervisorScheduleContext);
  const currentBaseline = scheduleContext?.selectedBaseline;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;

  const schedulingComplianceRef = useRef<ChartJSOrUndefined>();
  const planningEffectivenessRef = useRef<ChartJSOrUndefined>();

  // ---------------------
  /*
  const onChartClick = (
    chartRef: React.MutableRefObject<ChartJSOrUndefined>,
    event: React.MouseEvent<HTMLCanvasElement>
  ) => {
    const points = getElementAtEvent(chartRef.current!, event);

    if (points.length) {
      const references = getReferences(getComplianceInfo());

      const firstPoint = points[0];
      switch (firstPoint.index) {
        case 0: //completed
          const completedOperationIds = references.completed_references.map(
            (ref) => ref.operation_id
          );
          applyFilter(completedOperationIds);
          break;

        case 1: //non-completed
          const nonCompletedOperationIds =
            references.non_completed_references.map((ref) => ref.operation_id);
          applyFilter(nonCompletedOperationIds);
          break;
      }
    }
  };

  // -----------------
  const applyFilter = (operationIds: string[]): void => {
    const filterField = "to_Operation._id";

    const table: IUseTable = scheduleContext?.currentTasksTableRef.current!;
    const taskFilter: IColumnFilter = {
      field: filterField,
      operator: "$in",
      value: operationIds,
    };

    // TODO => place this piece of code into a util file
    const filters: IColumnFilter[] = table.columnFilters;
    const filterIndex = filters.findIndex(
      (filter) => filter.field === filterField
    );
    if (filterIndex !== -1) {
      filters.splice(filterIndex, 1, taskFilter);
    } else {
      filters.push(taskFilter);
    }

    table.fetch({ newColumnFilters: filters });
  };
*/
  // -----------------
  const getComplianceInfo = ():
    | IAdapterSchedulingComplianceSummary
    | undefined => {
    const summary = tableContext?.table
      .entities! as unknown as IAdapterSchedulingComplianceSummary[];

    return summary[0];
  };

  // -------------
  const getHours = (
    compliance?: IAdapterSchedulingComplianceSummary
  ): {
    scheduled_hours: number;
    timecard_hours: number;
    actual_hours: number;
  } => {
    let [scheduled_hours, timecard_hours, actual_hours] = [0, 0, 0];
    if (compliance) {
      ({ scheduled_hours, timecard_hours, actual_hours } = compliance);
    }

    return {
      scheduled_hours,
      timecard_hours,
      actual_hours,
    };
  };

  // --------------
  const getCounts = (
    compliance?: IAdapterSchedulingComplianceSummary
  ): {
    scheduled_workorders: number;
    completed_workorders: number;
    compliant_workorders: number;
    // actual_references: any[];
    // non_actual_references: any[];
  } => {
    //
    let [
      scheduled_workorders,
      completed_workorders,
      compliant_workorders,
      // actual_references,
      // non_actual_references,
    ] = [0, 0, 0]; //[[] as any[], [] as any[], [] as any[], [] as any[]];

    if (compliance) {
      ({
        scheduled_workorders,
        completed_workorders,
        compliant_workorders,
        // non_completed_references,
        // actual_references,
        // non_actual_references,
      } = compliance);
    }

    return {
      scheduled_workorders,
      completed_workorders,
      compliant_workorders,
    };
  };

  // -----------------
  const getSchedulingCompliancePercentage = (
    type: "completed" | "actual"
  ): number => {
    const compliance = getComplianceInfo();
    const { scheduled_hours, timecard_hours, actual_hours } =
      getHours(compliance);

    const hrs = type === "completed" ? timecard_hours : actual_hours;

    return scheduled_hours === 0 ? 0 : (hrs * 100) / scheduled_hours;
  };

  // ----------------
  const getPlanningEffectivenessPercentage = (
    type: "completed" | "compliant"
  ): number => {
    const compliance = getComplianceInfo();
    const {
      scheduled_workorders,
      completed_workorders,
      compliant_workorders,
      // non_completed_references,
      // actual_references,
      // non_actual_references,
    } = getCounts(compliance);

    const totalRefCount = scheduled_workorders;
    // type === "completed"
    //   ? completed_references.length + non_completed_references.length
    //   : actual_references.length + non_actual_references.length;

    const complRefCount =
      type === "completed" ? completed_workorders : compliant_workorders;

    return totalRefCount === 0 ? 0 : (complRefCount * 100) / totalRefCount;
  };

  // ----------------
  const buildSchedulingCompliance = (): any => {
    const { scheduled_hours, timecard_hours, actual_hours } =
      getHours(getComplianceInfo());

    const nonCompletedHours = Math.max(scheduled_hours - timecard_hours, 0);
    const nonActualHours = Math.max(scheduled_hours - actual_hours, 0);

    return {
      labels: [
        t("module.supervisor_schedule:compliance_chart.label.completed_hours"),
        t(
          "module.supervisor_schedule:compliance_chart.label.not_completed_hours"
        ),
        t("module.supervisor_schedule:compliance_chart.label.actual_hours"),
        t("module.supervisor_schedule:compliance_chart.label.non_actual_hours"),
      ],
      datasets: [
        {
          data: [timecard_hours, nonCompletedHours],
          backgroundColor: [
            currentBaseline?.display_options || "red",
            "silver",
          ],
        },
        {
          data: [actual_hours, nonActualHours],
          backgroundColor: [
            currentBaseline?.display_options || "blue",
            "silver",
          ],
        },
      ],
    };
  };

  // ---------------
  const buildPlanningEffectiveness = (): any => {
    const {
      scheduled_workorders,
      completed_workorders,
      compliant_workorders,
      // non_completed_references,
      // actual_references,
      // non_actual_references,
    } = getCounts(getComplianceInfo());

    return {
      labels: [
        t(
          "module.supervisor_schedule:compliance_chart.label.completed_workorders"
        ),
        t(
          "module.supervisor_schedule:compliance_chart.label.not_completed_workorders"
        ),
        t(
          "module.supervisor_schedule:compliance_chart.label.compliant_workorders"
        ),
        t(
          "module.supervisor_schedule:compliance_chart.label.not_compliant_workorders"
        ),
      ],
      datasets: [
        {
          data: [
            completed_workorders,
            Math.max(scheduled_workorders - completed_workorders, 0),
          ],
          backgroundColor: [
            currentBaseline?.display_options || "red",
            "silver",
          ],
        },
        {
          data: [
            compliant_workorders,
            Math.max(scheduled_workorders - compliant_workorders, 0),
          ],
          backgroundColor: [
            currentBaseline?.display_options || "blue",
            "silver",
          ],
        },
      ],
    };
  };

  return (
    <div className={styles.root}>
      <RiverSpinner show={tableContext?.table.isLoading!} />
      <div key={"1"} className={styles.column}>
        <Doughnut
          ref={
            schedulingComplianceRef as ForwardedRef<
              ChartJSOrUndefined<"doughnut">
            >
          }
          //onClick={(event) => onChartClick(schedulingComplianceRef, event)}
          data={buildSchedulingCompliance()}
          options={{
            rotation: 270, // start angle in degrees
            circumference: 180, // sweep angle in
            responsive: true,
            maintainAspectRatio: false,
            layout: { padding: 30 },
            plugins: {
              title: {
                display: true,
                text: t(
                  "module.supervisor_schedule:compliance_chart.label.scheduling_compliance"
                ),
                position: "top",
                // font: {
                //   size: 20,
                // },
              },
              subtitle: {
                display: true,
                text: `${getSchedulingCompliancePercentage("actual").toFixed(
                  2
                )}%`,
                position: "bottom",
                font: {
                  weight: "bold",
                  size: 20,
                },
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    let labels = context.chart?.data?.labels || [];
                    const labelIndex =
                      context.datasetIndex * 2 + context.dataIndex;

                    return `${labels[labelIndex] || ""} (${
                      context.dataset?.data[context.dataIndex]
                    })` as string;
                  },
                },
              },
            },
          }}
        />
      </div>
      <div key={"2"} className={styles.column}>
        <Doughnut
          ref={
            planningEffectivenessRef as ForwardedRef<
              ChartJSOrUndefined<"doughnut">
            >
          }
          //onClick={(event) => onChartClick(planningEffectivenessRef, event)}
          data={buildPlanningEffectiveness()}
          options={{
            rotation: 270, // start angle in degrees
            circumference: 180, // sweep angle in
            responsive: true,
            maintainAspectRatio: false,
            layout: { padding: 30 },
            plugins: {
              title: {
                display: true,
                text: t(
                  "module.supervisor_schedule:compliance_chart.label.planning_effectiveness"
                ),
                position: "top",
                // font: {
                //   size: 20,
                // },
              },
              subtitle: {
                display: true,
                text: `${getPlanningEffectivenessPercentage(
                  "compliant"
                ).toFixed(2)}%`,
                position: "bottom",
                font: {
                  weight: "bold",
                  size: 20,
                },
              },
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    let labels = context.chart?.data?.labels || [];
                    const labelIndex =
                      context.datasetIndex * 2 + context.dataIndex;

                    return `${labels[labelIndex] || ""} (${
                      context.dataset?.data[context.dataIndex]
                    })` as string;
                  },
                },
              },
            },
          }}
        />
      </div>
      {uiService.renderComplianceFilters()()}
    </div>
  );
};
