import { RefObject, createContext } from "react";

export interface IAvailabilityContext {
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date) => void;

  splitterRef: RefObject<HTMLDivElement> | undefined;
  setSplitterRef: (ref: RefObject<HTMLDivElement>) => void;

  calendarScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setCalendarScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;
}

export const AvailabilityContext = createContext<IAvailabilityContext | null>(
  null
);
