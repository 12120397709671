import React, { ReactElement, useContext } from "react";
import { IEntityObject } from "@river/interfaces";
import { IUseTable } from "../../shared";
import { TableContext } from "../../../context";
import {
  ExecutionCardViewItem,
  ActionsLayout as CardActionLayout,
  ExecutionCardViewItemColor,
  randomExecutionCardViewItemColors,
} from "./execution-card-view-item";
import { uiConstants } from "../../../helpers";
import {
  EXECUTION_ASSIGNMENTS_TABLE_SAVE_KEY,
  EXECUTION_MATERIAL_TABLE_SAVE_KEY,
  EXECUTION_OPERATIONS_TABLE_SAVE_KEY,
  EXECUTION_TIMECARDS_TABLE_SAVE_KEY,
} from "../../../services";
import { IExecutionAction } from "../execution";
import styles from "./execution-card-view.module.scss";
import clsx from "clsx";

export interface IExecutionCardView {
  actions?: IExecutionAction[];
}

export const ExecutionCardView: React.FC<IExecutionCardView> = (
  props
): ReactElement => {
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const items: IEntityObject[] = table.entities!;
  const actionLayouts: { [tableSaveKey: string]: CardActionLayout } = {
    [EXECUTION_OPERATIONS_TABLE_SAVE_KEY]: CardActionLayout.MENU,
    [EXECUTION_ASSIGNMENTS_TABLE_SAVE_KEY]: CardActionLayout.BUTTONS,
    [EXECUTION_MATERIAL_TABLE_SAVE_KEY]: CardActionLayout.BUTTONS,
    [EXECUTION_TIMECARDS_TABLE_SAVE_KEY]: CardActionLayout.BUTTONS,
  };
  const actionsLayout: CardActionLayout = actionLayouts[table.saveKey!];

  const getRandomItemColor = (index: number): ExecutionCardViewItemColor =>
    randomExecutionCardViewItemColors[
      index % randomExecutionCardViewItemColors.length
    ];

  const getItemKey = (item: IEntityObject): string =>
    item[uiConstants.fields.rowId] as string;

  return (
    <>
      <div className={clsx([styles.root])}>
        <div className={clsx([styles.scrollableContainer])}>
          {items.map((item, index) => (
            <ExecutionCardViewItem
              item={item}
              key={getItemKey(item)}
              color={getRandomItemColor(index)}
              actions={props.actions}
              actionsLayout={actionsLayout}
            />
          ))}
        </div>
      </div>
    </>
  );
};
