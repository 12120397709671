import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { AdapterCalendarDto, IAdapterCalendar } from "@river/interfaces";
import { useContext } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsInt, IsNotEmpty, Max, Min } from "class-validator";
import { Constants } from "@river/constants";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import { CalendarsAction } from "../calendars";
import { useAllowedAction } from "../../protected-action";

const DEFAULT_NUMBER_OF_DAYS: number = 0;
export const MAX_NUMBER_OF_DAYS: number = 364;

interface IStandaloneCalendarValidator {
  numberOfTemplateDays: number;
}

export class StandaloneCalendarValidator {
  @IsNotEmpty()
  @IsInt()
  @Min(1, {
    message: (opts) =>
      `module.calendars:validation.number_of_days_validator.less_days?days=${opts.constraints[0]}`,
  })
  @Max(MAX_NUMBER_OF_DAYS, {
    message: (opts) =>
      `module.calendars:validation.number_of_days_validator.more_days?days=${opts.constraints[0]}`,
  })
  numberOfTemplateDays!: number;

  constructor(obj: IStandaloneCalendarValidator) {
    Object.assign(this, obj);
  }
}

export const useCalendarForm = (props: {
  calendar: IAdapterCalendar | null;
  onCreate: (calendar: IAdapterCalendar) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  return useRiverForm<
    AdapterCalendarDto,
    IAdapterCalendar,
    StandaloneCalendarValidator
  >({
    initialDto: Object.assign(new AdapterCalendarDto(), {
      calendar: "",
      description: "",
      reference_date: null,
      template: [],
    }),
    entityName: "calendar",
    instanceToEdit: props.calendar,
    standalone: {
      fields: new StandaloneCalendarValidator({
        numberOfTemplateDays:
          props.calendar?.template.length || DEFAULT_NUMBER_OF_DAYS,
      }),
      fieldDefs: [
        {
          fieldName: "numberOfTemplateDays",
          dataType: Constants.data_type.double,
        },
      ],
    },
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterCalendarDto): Promise<IAdapterCalendar> => {
      if (typeof dto.reference_date === "string" && dto.reference_date === "") {
        dto.reference_date = null;
      }
      return await adapterContext!.service
        .getAdapterService()
        .createCalendar(dto);
    },
    update: async (dto: AdapterCalendarDto): Promise<void> => {
      if (typeof dto.reference_date === "string" && dto.reference_date === "") {
        dto.reference_date = null;
      }
      await adapterContext!.service
        .getAdapterService()
        .updateCalendar(props.calendar!._id!, dto);
    },
    labels: {
      calendar: t("entity.calendar:calendar.calendar"),
      description: t("entity.calendar:calendar.description"),
      reference_date: t("module.calendars:label.start_date"),
      numberOfTemplateDays: t("module.calendars:label.number_of_days"),
    },
    readOnly: !isActionAllowed(ModuleKey.CALENDARS, CalendarsAction.UPDATE),
  });
};
