import { ColumnsProvider, CrewsUiService, FetchFunctionProvider } from "..";
import { useTranslation } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import clsx from "clsx";
import styles from "../../components/shared/river-lookup/lookup-dialog/defs/person-lookup/person-lookup.module.scss";
import { Avatar } from "../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import { fetchHelpers } from "../../helpers";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useContext } from "react";
import { IEntityObject, SapAdapterCrewCraftDto } from "@river/interfaces";
import { useTableCellRenderers } from "../../hooks";
import { RiverDefaultNumberEditor } from "../../components/shared/river-data-grid/cell-editors";

export class SapCrewsUiService extends CrewsUiService {
  getAvailableCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    const { renderCell } = useTableCellRenderers();
    return [
      RiverSelectColumn,
      {
        key: "PersonName",
        name: t("entity.availability_header:availability_header.PersonName"),
        formatter: (formatterProps: any) => {
          return (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div className={clsx([styles.person])}>
                    <Avatar
                      url={utilizationHelpers.getRandomGanttAvatarURL(
                        Number(formatterProps.row.PersonNumber)
                      )}
                    />
                    <div>{formatterProps.row[formatterProps.column.key]}</div>
                  </div>
                ),
              })}
            </>
          );
        },
      },
      {
        key: "PersonNumber",
        name: t("entity.availability_header:availability_header.PersonNumber"),
      },
      {
        key: "WorkCenters.WorkCenter",
        name: t("entity.availability_header:availability_header.WorkCenters"),
      },
    ];
  };

  getAssignedCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { renderCell } = useTableCellRenderers();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "PersonName",
        name: t("entity.crew_person:crew_person.PersonName"),
        formatter: (formatterProps: any) => {
          return (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div className={clsx([styles.person])}>
                    <Avatar
                      url={utilizationHelpers.getRandomGanttAvatarURL(
                        Number(formatterProps.row.PersonNumber)
                      )}
                    />
                    <div>{formatterProps.row[formatterProps.column.key]}</div>
                  </div>
                ),
              })}
            </>
          );
        },
      },
      {
        key: "PersonNumber",
        name: t("entity.crew_person:crew_person.PersonNumber"),
      },
      {
        key: "WorkCenters.WorkCenter",
        name: t("entity.crew_person:crew_person.WorkCenters"),
      },
    ];
  };

  getAvailableCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "WorkCenter",
        name: t("entity.workcenter:workcenter.WorkCenter"),
        width: 150,
      },
      {
        key: "CapacityCategory",
        name: t("entity.workcenter:workcenter.CapacityCategory"),
      },
      // {
      //   key: "number_of_units",
      //   name: t("entity.crew_workcenter:crew_workcenter.number_of_units"),
      // },
    ];
  };

  getAssignedCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "WorkCenter",
        name: t("entity.crew_workcenter:crew_workcenter.WorkCenter"),
        width: 150,
      },
      {
        key: "CapacityCategory",
        name: t("entity.crew_workcenter:crew_workcenter.CapacityCategory"),
        width: 150,
      },
      {
        key: "number_of_units",
        name: t("entity.crew_workcenter:crew_workcenter.number_of_units"),
        width: 150,
        editor: (props) => <RiverDefaultNumberEditor editorProps={props} />,
      },
    ];
  };

  resourceNumberField = "PersonNumber";
  availableCrewCraftsEntityName = "workcenter";
  availableCrewCraftsRowKeyGetter = (row: any): string => row["WorkCenterId"];
  assignedCrewCraftsEntityName = "crew_workcenter";

  getAvailableCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return async (fetchProps: ITableFetchFunctionProps) => {
        const craftIdKey: string = "WorkCenterId";
        const assignedCraftIds: string[] = [];
        const assignedCrafts: IEntityObject[] = await adapterContext!.service
          .getAdapterService()
          .searchEntityData("crew_workcenter", {
            query: {
              $and: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            },
          });
        assignedCrafts.forEach((craft) =>
          assignedCraftIds.push(craft[craftIdKey] as string)
        );

        return await adapterContext!.service.getAdapterService().fetchCrafts(
          fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributes: [
              {
                attribute_name: craftIdKey,
                attribute_value: {
                  operator: "$nin",
                  value: assignedCraftIds,
                },
              },
            ],
          })
        );
      };
    };
  };

  getAssignedCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return async (fetchProps: ITableFetchFunctionProps) => {
        return await adapterContext!.service
          .getAdapterService()
          .searchEntityData(
            "crew_workcenter",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            })
          );
      };
    };
  };
  getAssignCrewCraftsFn = (crew: string) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return (selectedIds: Set<string>): Promise<any> => {
        const promises: Promise<void>[] = [];
        selectedIds.forEach((WorkCenterId) => {
          const dto: SapAdapterCrewCraftDto = {
            crew,
            WorkCenterId,
            number_of_units: 1,
          };
          promises.push(
            adapterContext!.service.getAdapterService().createCrewCraft(dto)
          );
        });
        return Promise.all(promises);
      };
    };
  };

  getErpSpecificI18nNamespaces(): string[] {
    return [];
  }
}
