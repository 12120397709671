import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ProgressSummaryFilterID } from "../../../../schedule-utilization-ui-service";

interface ISapScheduleProgressWorkcenterFiltersProps {
  rows: any[];
  onSelect: (selectedObjects: any[], filterId: ProgressSummaryFilterID) => void;
  onDelete: (selectedObject: any, filterId: ProgressSummaryFilterID) => void;
}

export const SapScheduleProgressWorkcenterFilters: React.FC<
  ISapScheduleProgressWorkcenterFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const workcenterLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((workcenter) => workcenter["WorkCenterId"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "WorkCenter",
          name: t("entity.workcenter:workcenter.WorkCenter"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.CRAFTS,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "WorkCenterId",
            attribute_value: {
              operator: "$nin",
              value: workcenterLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selected) =>
        props.onDelete(selected, ProgressSummaryFilterID.WORKCENTER)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ProgressSummaryFilterID.WORKCENTER);
      }}
      addFilterLabel={t(
        "module.schedule:utilization_filters.add_workcenter_filter"
      )}
    />
  );
};
