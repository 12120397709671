import { FC, useContext } from "react";
import {
  GridHeader,
  ColumnSelectorDropdown,
  GridActions,
  GenericAction,
  FilterDropdown,
  RiverCalendarSelector,
} from "../../shared";
import { Box, Divider } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SyncIcon from "@mui/icons-material/Sync";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { SupervisorCrewAction } from "../supervisor-crew";
import { AdapterUiContext, TableContext } from "../../../context";
import { useNotification } from "@river/common-ui";
import dayjs from "dayjs";
import styles from "./supervisor-crew-grid-header.module.scss";

interface ISupervisorCrewGridHeaderProps {
  startDate: Date;
  onStartDateChange: (date: Date) => void;
  setAvailabilityDialogOpened(v: boolean): void;
}

export const SupervisorCrewGridHeader: FC<ISupervisorCrewGridHeaderProps> = (
  props
) => {
  const { startDate, onStartDateChange, setAvailabilityDialogOpened } = props;

  const calStartDate = dayjs(startDate)
    .add(startDate.getTimezoneOffset(), "minutes")
    .toDate();

  const { t } = useTranslation();
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();

  const openAvailabilityDialog = () => setAvailabilityDialogOpened(true);

  const synchronizeResources = async (): Promise<void> => {
    try {
      tableContext?.table.forceLoadingState(true);
      await adapterContext!.service.getAdapterService().synchronizeResources();
      tableContext?.table.refresh();
      notify.success(
        t("module.availability:notification.synchronize_resources_success")
      );
    } catch (message) {
      notify.error({ message });
    } finally {
      tableContext?.table.forceLoadingState(false);
    }
  };

  function dateToString(value: Date) {
    //Wrong...
    // return `${value.getUTCDate()}.${
    //   value.getUTCMonth() + 1
    // }.${value.getUTCFullYear()}`;

    return new Intl.DateTimeFormat(navigator.language, {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(value);
  }

  function handleGoBackwardCalendar() {
    const newDate = dayjs(calStartDate)
      .subtract(7, "days")
      .subtract(calStartDate.getTimezoneOffset(), "minutes")
      .toDate();
    onStartDateChange(newDate);
  }

  function handleGoForwardCalendar() {
    let newDate = dayjs(calStartDate)
      .add(7, "days")
      .subtract(calStartDate.getTimezoneOffset(), "minutes")
      .toDate();
    onStartDateChange(newDate);
  }

  function handleOnAcceptCalendar(value: dayjs.Dayjs | null) {
    if (value) {
      onStartDateChange(
        value.subtract(calStartDate.getTimezoneOffset(), "minutes").toDate()
      );
    }
  }

  return (
    <>
      <GridHeader className={styles.mainContainer}>
        <GridActions className={styles.actionsContainer}>
          <Box className={styles.root}>
            <Box className={styles.leftSection}>
              <ColumnSelectorDropdown />
              <FilterDropdown />
              <ProtectedAction
                module={ModuleKey.AVAILABILITY}
                action={SupervisorCrewAction.SYNC_RESOURCES}
              >
                <GenericAction
                  icon={EventAvailableIcon}
                  onClick={openAvailabilityDialog}
                  title={t(
                    "module.availability:dialog.generate_availability.title"
                  )}
                  enableOnSelection={true}
                />
                <GenericAction
                  icon={SyncIcon}
                  onClick={synchronizeResources}
                  title={t("module.availability:label.synchronize_resources")}
                />
              </ProtectedAction>
            </Box>
            <Box className={styles.calendarContainer}>
              <span className={styles.calendarValue}>
                {`${t("module.availability:label.start_date")}: ${dateToString(
                  startDate
                )}`}
              </span>
              <Divider orientation="vertical" className={styles.divider} />
              <RiverCalendarSelector
                value={calStartDate}
                handleGoBackward={handleGoBackwardCalendar}
                handleGoForward={handleGoForwardCalendar}
                handleOnAccept={handleOnAcceptCalendar}
              />
            </Box>
          </Box>
        </GridActions>
      </GridHeader>
    </>
  );
};
