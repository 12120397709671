import React, { ReactElement } from "react";
import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { TFunction } from "react-i18next";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers, IGetTableQueryProps } from "../../helpers";
import {
  CraftsUiService,
  ICraftSelectorProps,
  IUseCraftLookupProps,
} from "../crafts-ui.service";
import { Column } from "react-data-grid";
import { OracleEbsCraftSelector } from "./oracle-ebs-shared/oracle-ebs-craft-selector";
import { QueryAttributeGroupDto } from "@river/interfaces";

export const CRAFT_ROW_KEY_SEPARATOR: string = ".SEPARATOR.";

export interface IEbsCraftKey {
  DEPARTMENT_ID: number;
  RESOURCE_ID: number;
}

export class OracleEbsCraftsUiService extends CraftsUiService {
  getCraftEntity = () => "department_resource";
  craftKeyGetter = (row: any): string =>
    `${row["DEPARTMENT_ID"]}${CRAFT_ROW_KEY_SEPARATOR}${row["RESOURCE_ID"]}`;

  getSelectedCraftsQuery = (
    selectedCraftIds: string[] | number[]
  ): QueryAttributeGroupDto => {
    const createKeyQuery = (
      stringifiedCraftKey: string
    ): QueryAttributeGroupDto => {
      const key: IEbsCraftKey = JSON.parse(stringifiedCraftKey);
      return {
        $and: [
          {
            attribute_name: "DEPARTMENT_ID",
            attribute_value: { operator: "$eq", value: key.DEPARTMENT_ID },
          },
          {
            attribute_name: "RESOURCE_ID",
            attribute_value: { operator: "$eq", value: key.RESOURCE_ID },
          },
        ],
      };
    };

    const selectedCraftsQuery: QueryAttributeGroupDto = {
      $or: selectedCraftIds.map((craftKey) =>
        createKeyQuery(craftKey as string)
      ),
    };

    const emptyResultsQuery: QueryAttributeGroupDto = {
      $and: [
        {
          attribute_name: "RESOURCE_ID",
          attribute_value: { operator: "$eq", value: -1 },
        },
      ],
    };

    return selectedCraftIds.length ? selectedCraftsQuery : emptyResultsQuery;
  };

  getCraftLookupDefinition = (
    props: IUseCraftLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: props.t("shared.lookup:craft.title"),
      rowKeyGetter: this.craftKeyGetter,
      useTableProps: {
        columns: this.getCraftsSelectorColumns(
          props.t,
          props.RiverSelectColumn
        ),
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          const getTableQueryProps: IGetTableQueryProps = {
            fetchProps,
            initialQueryAttributes: [
              {
                attribute_name: "ORGANIZATION_ID",
                attribute_value: { operator: "$eq", value: site.site_id },
              },
              ...(props.customFilters || []),
            ],
          };
          if (props.customQuery) {
            getTableQueryProps.initialQueryAttributeGroup = props.customQuery;
          }
          return await props
            .adapterContext!.service.getAdapterService()
            .fetchCrafts(fetchHelpers.getTableQuery(getTableQueryProps));
        },
        dependencies: [!!site],
        saveKey: this.getCraftsLookupTableSaveKey(),
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: this.getCraftEntity(),
      },
    };
  };
  getCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => [
    RiverSelectColumn,
    {
      key: "RESOURCE_CODE",
      name: t("entity.department_resource:department_resource.RESOURCE_CODE"),
      width: 150,
    },
    {
      key: "DESCRIPTION",
      name: t("entity.department_resource:department_resource.DESCRIPTION"),
    },
    {
      key: "RESOURCE_TYPE_DISP",
      name: t(
        "entity.department_resource:department_resource.RESOURCE_TYPE_DISP"
      ),
    },
    {
      key: "UNIT_OF_MEASURE",
      name: t("entity.department_resource:department_resource.UNIT_OF_MEASURE"),
    },
    {
      key: "DEPARTMENT_CODE",
      name: t("entity.department_resource:department_resource.DEPARTMENT_CODE"),
    },
  ];

  getAssignedCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => this.getCraftsSelectorColumns(t, RiverSelectColumn);

  getCraftsUtilizationIds = (selectedCraftIds: string[]): string[] =>
    selectedCraftIds;

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <OracleEbsCraftSelector {...props} />
  );
}
