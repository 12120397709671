import React, { useState, useMemo, useEffect, useContext, useRef } from "react";
import SplitterLayout from "react-splitter-layout";
import { DataGridHandle, FormatterProps } from "react-data-grid";

import { RiverDataGrid } from "../../../shared";
import { useSupervisorScheduleGantt } from "../../supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { SupervisorScheduleUtilizationFormatter } from "../supervisor-schedule-utilization-formatter";
import { SupervisorScheduleUtilizationHeaderRenderer } from "../supervisor-schedule-utilization-header-renderer";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  TableContext,
} from "../../../../context";
import { DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE } from "../../supervisor-schedule";
import { ISupervisorScheduleGanttTimeOption } from "../../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { userPreferencesService } from "../../../../services";
import styles from "./supervisor-schedule-utilization-tables.module.scss";

export const SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID: string =
  "supervisorScheduleUtilizationGanttContainer";

export const SupervisorScheduleUtilizationTables: React.FC = () => {
  const splitterContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<DataGridHandle>(null);
  const utilizationDatesRef = useRef<DataGridHandle>(null);
  const utilizationScrollAreaRef = useRef<HTMLDivElement>(null);

  const [ganttContentRef, setGanttContentRef] = useState<HTMLDivElement | null>(
    null
  );
  const [splitterWidth, setSplitterWidth] = useState<number | null>(null);

  const tableContext = useContext(TableContext);
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  useEffect(() => {
    const setInitialLeftPaneWidth = async () => {
      try {
        scheduleContext?.currentTasksTableRef.current?.forceLoadingState(true);
        const preferenceWidth: number | null =
          await userPreferencesService.getVerticalScheduleSplitterPosition(
            adapterContext!.service.getAdapterService()
          );
        const initialWidth =
          preferenceWidth ?? DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE;
        setSplitterWidth(initialWidth);
      } catch (message) {
        notify.error({ message });
      } finally {
        scheduleContext?.currentTasksTableRef.current?.forceLoadingState(false);
      }
    };

    scheduleContext?.setUtilizationSplitterRef(splitterContainerRef);
    scheduleContext?.setUtilizationScrollAreaRef(utilizationScrollAreaRef);

    setInitialLeftPaneWidth();

    return () => {
      delete scheduleContext?.utilizationGanttRef.current;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ganttTimeOption: ISupervisorScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;

  const onTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    utilizationDatesRef.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const onUtilizationVerticalScroll = (
    event: React.UIEvent<HTMLDivElement>
  ) => {
    tableRef.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const onUtilizationHorizontalScroll = (
    event: React.UIEvent<HTMLDivElement>
  ) => {
    scheduleContext!.ganttScrollAreaRef?.current?.scrollTo({
      left: event.currentTarget.scrollLeft,
    });
  };

  const gantt = useSupervisorScheduleGantt({
    timeOption: ganttTimeOption,
    parentElement: ganttContentRef,
    scheduleFn: scheduleContext?.scheduleFn!,
  });
  scheduleContext!.utilizationGanttRef.current = gantt;

  const saveSplitterWidth = async (
    leftPaneEl: HTMLDivElement
  ): Promise<void> => {
    try {
      const widthPercentage = calculateSplitterWidthPercentage(leftPaneEl);
      await userPreferencesService.setVerticalScheduleSplitterPosition(
        adapterContext!.service.getAdapterService(),
        widthPercentage
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const calculateSplitterWidthPercentage = (
    leftPaneEl: HTMLDivElement
  ): number => {
    const containerWidth: number =
      splitterContainerRef.current!.getBoundingClientRect().width;
    const elementWidth: number = leftPaneEl.offsetWidth;
    return (elementWidth * 100) / (containerWidth || 1);
  };

  const onSplitterDragEnd = (): void => {
    const leftPaneEl: HTMLDivElement =
      splitterContainerRef.current!.querySelectorAll(
        ".layout-pane"
      )[0] as HTMLDivElement;
    saveSplitterWidth(leftPaneEl);

    const ganttLeftPaneEl: HTMLDivElement =
      scheduleContext?.ganttSplitterRef?.current?.querySelectorAll(
        ".layout-pane"
      )[0] as HTMLDivElement;
    if (ganttLeftPaneEl) {
      ganttLeftPaneEl.style.width = leftPaneEl.offsetWidth + "px";
    }
  };

  const utilizationColumns = useMemo(
    () => [
      {
        key: "utilization_hours",
        name: "utilization_hours",
        headerRenderer: () => <SupervisorScheduleUtilizationHeaderRenderer />,
        formatter: (formatterProps: FormatterProps<any>) => (
          <SupervisorScheduleUtilizationFormatter {...formatterProps} />
        ),
      },
    ],
    []
  );

  const minGanttContainerWidth: number =
    gantt.periods.length * gantt.frequencyDef.minPeriodSize;

  useEffect(() => {
    gantt.skipRenderChecksRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContext?.table!.entities]);

  const isReady: boolean =
    splitterWidth !== null && !!scheduleContext?.ganttTimeOption;

  return (
    <>
      {isReady && (
        <div className={styles.root} ref={splitterContainerRef}>
          <RiverSpinner show={tableContext?.table.isLoading!} />
          <SplitterLayout
            percentage={true}
            primaryIndex={1}
            secondaryInitialSize={splitterWidth!}
            onDragEnd={onSplitterDragEnd}
          >
            <RiverDataGrid
              innerRef={tableRef}
              columns={tableContext?.table.columns!}
              sortColumns={tableContext?.table!.sortColumns}
              onSortColumnsChange={tableContext?.table!.setSortColumns}
              onColumnResize={tableContext?.table!.setColumnSize}
              rows={tableContext?.table!.entities!}
              onSelectedRowsChange={(rowKeys) =>
                tableContext?.table!.setSelectedRowIds(rowKeys)
              }
              selectedRows={tableContext?.table!.selectedRowIds}
              rowKeyGetter={tableContext?.table!.rowKeyGetter}
              style={{ borderRight: "none" }}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              onScroll={onTableScroll}
            />
            <div
              ref={utilizationScrollAreaRef}
              className={styles.utilizationScrollArea}
              onScroll={onUtilizationHorizontalScroll}
            >
              <div
                id={SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID}
                className={styles.utilizationContainer}
                style={{ minWidth: minGanttContainerWidth }}
                ref={(obj) => setGanttContentRef(obj)}
              >
                <RiverDataGrid
                  innerRef={utilizationDatesRef}
                  className={styles.utilizationDataGrid}
                  columns={utilizationColumns}
                  rows={tableContext?.table!.entities!}
                  style={{ borderLeft: "none" }}
                  onScroll={onUtilizationVerticalScroll}
                />
              </div>
            </div>
          </SplitterLayout>
        </div>
      )}
    </>
  );
};
