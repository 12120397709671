import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { UsersUiService } from "../users-ui.service";

export class OracleEbsUsersUiService extends UsersUiService {
  protected getUserEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "user",
      columns: [
        { key: "USER_NAME", name: t("entity.user:user.USER_NAME") },
        { key: "DISPLAY_NAME", name: t("entity.user:user.DISPLAY_NAME") },
        { key: "EMAIL_ADDRESS", name: t("entity.user:user.EMAIL_ADDRESS") },
      ],
    };
  };

  getUserNameKey = () => "USER_NAME";

  getErpSpecificI18nNamespaces = () => [
    "entity.application",
    "entity.responsibility",
    "shared.lookup",
  ];
}
