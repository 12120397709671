import { ITranslationProps } from "@river/common-ui";
import { FC, useContext, useState, ComponentType } from "react";
import { withTranslation } from "react-i18next";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { CrewsUiService } from "../../services";
import { SupervisorCrews } from "./supervisor-crews";

const ModuleWrapper: FC<ITranslationProps> = (props) => (
  <>{props.tReady && <SupervisorCrews />}</>
);

export const SupervisorCrewsModule: FC = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService = adapterContext!.service.getCrewsUiService();
  const namespaces: string[] = uiService.getI18nNamespaces();

  const [Component] = useState<ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );
  return <Component />;
};
