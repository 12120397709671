import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";

export function useEquipmentLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:equipment.title"),
      rowKey: "Equipment",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "Equipment",
            name: t("entity.equipment:equipment.Equipment"),
            width: 120,
          },
          {
            key: "EquipmentDescription",
            name: t("entity.equipment:equipment.EquipmentDescription"),
            width: 300,
          },
          {
            key: "EquipmentCategory",
            name: t("entity.equipment:equipment.EquipmentCategory"),
          },
          {
            key: "EquipmentType",
            name: t("entity.equipment:equipment.EquipmentType"),
          },
          {
            key: "EquipmentTechnicalId",
            name: t("entity.equipment:equipment.EquipmentTechnicalId"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).fetchEquipment(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        saveKey: "equipment.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "equipment",
      },
    };
  };
}

export type IUseEquipmentLookupDef = ReturnType<typeof useEquipmentLookupDef>;
