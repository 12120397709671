import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { AdapterRoleDto, IAdapterRole } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const useRoleForm = (props: {
  role: IAdapterRole | undefined;
  onCreate: (role: IAdapterRole) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<AdapterRoleDto, IAdapterRole, Object>({
    initialDto: new AdapterRoleDto(),
    entityName: "role",
    instanceToEdit: props.role,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterRoleDto): Promise<IAdapterRole> => {
      return await adapterContext!.service.getAdapterService().createRole(dto);
    },
    update: async (dto: AdapterRoleDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateRole(props.role!._id!, dto);
    },
    labels: {
      role: t("entity.role:role.role"),
      description: t("entity.role:role.description"),
      license: t("entity.role:role.license"),
    },
  });
};
