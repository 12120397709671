import React, { ReactElement, useContext } from "react";
import {
  IUseValidation,
  IValidationErrors,
  RiverDialog,
  RiverTextInput,
  useNotification,
  useValidation,
} from "@river/common-ui";
import { RiverFormInstance, useTableCellRenderers } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import {
  CraftsUiService,
  ExternalResourcesUiService,
  IExternalResource,
  IExternalResourceDialogProps,
} from "../../../../services";
import { useOracleEbsExternalResourceForm } from "./use-oracle-ebs-external-resource-form";
import { IEntityObject } from "@river/interfaces";
import { Column } from "react-data-grid";
import { IColumn } from "../../../../interfaces";
import { LookupType } from "../../../../components/shared";
import { RiverTableLookup } from "../../../../components/shared/river-table-lookup";
import styles from "./oracle-ebs-external-resource-dialog.module.scss";

export const OracleEbsExternalResourceDialog: React.FC<
  IExternalResourceDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotification();
  const validation: IUseValidation = useValidation();
  const { renderTextCell } = useTableCellRenderers();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const externalResourcesUiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;

  const craftsArrayKey: string = externalResourcesUiService.craftsArrayKey;

  const form: RiverFormInstance = useOracleEbsExternalResourceForm({
    resource: props.resource,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
    onUpdate: () => {
      const success = true;
      closeDialog(success);
    },
  });

  const {
    setDto,
    isReadOnly,
    resetForm,
    validationErrors,
    setValidationErrors,
  } = form;

  const dto: IExternalResource = form.dto as IExternalResource;

  const crafts: IEntityObject[] = dto[craftsArrayKey] as IEntityObject[];

  const getCraftsColumns = (): IColumn[] => {
    return [
      {
        key: "RESOURCE_CODE",
        name: t("entity.department_resource:department_resource.RESOURCE_CODE"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "DESCRIPTION",
        name: t("entity.department_resource:department_resource.DESCRIPTION"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "RESOURCE_TYPE_DISP",
        name: t(
          "entity.department_resource:department_resource.RESOURCE_TYPE_DISP"
        ),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "UNIT_OF_MEASURE",
        name: t(
          "entity.department_resource:department_resource.UNIT_OF_MEASURE"
        ),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "DEPARTMENT_CODE",
        name: t(
          "entity.department_resource:department_resource.DEPARTMENT_CODE"
        ),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
    ];
  };

  const columns: Column<any>[] = getCraftsColumns();

  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  const onDialogSubmit = async () => await form.submit();

  const dialogTitle: string = form.isCreate
    ? t("module.external_resource:dialog.create_resource")
    : t("module.external_resource:dialog.edit_resource");

  const actionButtonText: string = props.resource
    ? t("common.button:save")
    : t("common.button:add");

  const validateCraftsArray = async (
    newDto: IExternalResource
  ): Promise<void> => {
    const newErrors: IValidationErrors = await validation.validateProperty(
      newDto,
      craftsArrayKey,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const onSelectCraft = async (selection: IEntityObject[]): Promise<void> => {
    const selectedCraft: IEntityObject = selection[0];
    const alreadySelected: boolean = !!crafts?.find(
      (craft) =>
        craftsUiService.craftKeyGetter(craft) ===
        craftsUiService.craftKeyGetter(selectedCraft)
    );
    if (alreadySelected) {
      notify.error(t("module.external_resource:resource_already_selected"));
      return;
    }

    const {
      RESOURCE_CODE,
      RESOURCE_TYPE_DISP,
      UNIT_OF_MEASURE,
      RESOURCE_ID,
      ORGANIZATION_ID,
      DEPARTMENT_ID,
      DEPARTMENT_CODE,
    } = selectedCraft;
    const newDto: IExternalResource = {
      ...dto,
      [craftsArrayKey]: [
        ...crafts,
        {
          RESOURCE_CODE,
          RESOURCE_TYPE_DISP,
          UNIT_OF_MEASURE,
          RESOURCE_ID,
          ORGANIZATION_ID,
          DEPARTMENT_ID,
          DEPARTMENT_CODE,
        },
      ],
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    await validateCraftsArray(newDto);
  };

  const onDeleteCraft = async (deletedCraft: IEntityObject): Promise<void> => {
    const newDto: IExternalResource = {
      ...dto,
      [craftsArrayKey]: crafts?.filter(
        (craft) =>
          craftsUiService.craftKeyGetter(craft) !==
          craftsUiService.craftKeyGetter(deletedCraft)
      ),
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    await validateCraftsArray(newDto);
  };

  const renderContent = (): ReactElement => (
    <>
      <RiverTextInput id={"Agreement"} fullWidth />
      <RiverTextInput id={"INSTANCE_NAME"} fullWidth />
      <RiverTableLookup
        title={t("shared.crafts:label.crafts")}
        buttonLabel={t("shared.crafts:button.add_craft")}
        rows={crafts}
        columns={columns}
        lookup={{
          type: LookupType.CRAFTS,
        }}
        singleSelect={true}
        onSelect={onSelectCraft}
        onDelete={onDeleteCraft}
        isReadOnly={isReadOnly}
        fieldId={craftsArrayKey}
      />
    </>
  );

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={actionButtonText}
      onSubmit={onDialogSubmit}
      showActionsDivider={false}
      form={form}
      classes={{
        paper: styles.paper,
      }}
    >
      {renderContent()}
    </RiverDialog>
  );
};
