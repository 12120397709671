import React, {
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  ReactElement,
  useRef,
  useState,
} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { AnchorEl, IRiverListPopupItem, RiverListPopup } from "../../shared";
import styles from "./river-dropdown-list.module.scss";
import clsx from "clsx";

interface IRiverDropdownListProps {
  toggle?: ReactElement | (() => ReactElement);
  title?: string | (() => string);
  tooltip?: string | (() => string);
  onClick?: (props: { open: () => void }) => void;
  onDoubleClick?: (props: { open: () => void }) => void;
  onItemClickPreventClose?: boolean;
  items: IRiverListPopupItem[];
  openOnDoubleClick?: boolean;
  onClose?: () => void;
  classes?: {
    toggle?: string;
    popup?: string;
    item?: string;
    separator?: string;
    startIconClass?: string;
    endIconClass?: string;
  };
}

export const RiverDropdownList: React.FC<IRiverDropdownListProps> = (
  props: IRiverDropdownListProps
): ReactElement => {
  const [anchorElement, setAnchorElement] = useState<AnchorEl>(null);
  const anchorRef: MutableRefObject<HTMLDivElement | null> =
    useRef<HTMLDivElement>(null);

  const openDropdown = () => {
    setAnchorElement(anchorRef.current);
  };

  const closeDropdown = () => {
    setAnchorElement(null);
    props.onClose?.();
  };

  const getCommonToggleAttributes = (): {
    ref:
      | MutableRefObject<HTMLDivElement | null>
      | ((el: HTMLDivElement) => void);
    onClick: MouseEventHandler<HTMLDivElement>;
    onDoubleClick: MouseEventHandler<HTMLDivElement>;
  } => {
    const onClick: MouseEventHandler<HTMLDivElement> = async () => {
      if (props.onClick) {
        props.onClick({ open: openDropdown });
      } else if (!props.openOnDoubleClick) {
        openDropdown();
      }
    };
    const onDoubleClick: MouseEventHandler<HTMLDivElement> = async (
      e: MouseEvent
    ) => {
      e.preventDefault();
      e.stopPropagation();

      if (props.onDoubleClick) {
        props.onDoubleClick({ open: openDropdown });
      } else if (props.openOnDoubleClick) {
        openDropdown();
      }
    };
    return {
      ref: anchorRef,
      onClick,
      onDoubleClick,
    };
  };

  const renderToggle = (): ReactElement => {
    if (props.toggle) {
      return (
        <div {...getCommonToggleAttributes()} className={props.classes?.toggle}>
          {typeof props.toggle === "function" ? props.toggle() : props.toggle}
        </div>
      );
    } else {
      return renderDefaultToggle();
    }
  };

  const renderDefaultToggle = (): ReactElement => {
    const title: string =
      typeof props.title === "function" ? props.title() : props.title || "";
    const tooltip: string =
      typeof props.tooltip === "function"
        ? props.tooltip()
        : props.tooltip || "";

    const DropdownIcon = !!anchorElement ? ArrowDropUpIcon : ArrowDropDownIcon;
    return (
      <div
        title={tooltip}
        {...getCommonToggleAttributes()}
        className={clsx([
          styles.toggle,
          props.classes?.toggle,
          {
            [styles.popupOpened]: !!anchorElement,
          },
        ])}
      >
        <span>{title}</span>
        <DropdownIcon className={styles.dropdownIcon} />
      </div>
    );
  };

  const renderPopup = (): ReactElement => (
    <RiverListPopup
      items={props.items}
      anchorEl={anchorElement}
      onClose={closeDropdown}
      onItemClickPreventClose={props.onItemClickPreventClose}
      popover={{
        transformOrigin: {
          vertical: -3,
          horizontal: 0,
        },
      }}
      classes={{
        root: clsx([styles.popup, props.classes?.popup]),
        item: clsx([styles.item, props.classes?.item]),
        separator: clsx([styles.separator, props.classes?.separator]),
        startIconClass: props.classes?.startIconClass,
        endIconClass: props.classes?.endIconClass,
      }}
    />
  );

  return (
    <>
      {renderToggle()}
      {renderPopup()}
    </>
  );
};
