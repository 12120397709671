import React, { ReactElement, useContext } from "react";
import { fetchHelpers, uiConstants } from "../../../../helpers";
import {
  ITableFetchFunctionProps,
  RiverTableSelector,
  useRiverSelectColumn,
} from "../../../shared";
import { Column } from "react-data-grid";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { useTranslation } from "@river/common-ui";

interface IScheduleSelector {
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
  selectedIds: Set<string>;
  onChangeSelectedIds: (selectedObjectIds: Set<string>) => void;
}

export const ScheduleSelector: React.FC<IScheduleSelector> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const fetchObjects = async (fetchProps: ITableFetchFunctionProps) => {
    return await adapterContext!.service
      .getAdapterService()
      .fetchFolders(fetchHelpers.getTableQuery({ fetchProps }));
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "folder",
      name: t("shared.schedule_selector:column.folder"),
      width: 170,
    },
    {
      key: "start_date",
      name: t("shared.schedule_selector:column.start_date"),
      width: 160,
    },
    {
      key: "end_date",
      name: t("shared.schedule_selector:column.end_date"),
      width: 160,
    },
    {
      key: "duration",
      name: t("shared.schedule_selector:column.duration"),
      width: 90,
    },
    { key: "status.code", name: t("shared.schedule_selector:column.status") },
    {
      key: "workorder_count",
      name: t("shared.schedule_selector:column.workorder_count"),
    },
    {
      key: "operation_count",
      name: t("shared.schedule_selector:column.operation_count"),
    },
  ];
  return (
    <RiverTableSelector
      isDialog={props.isDialog}
      open={props.open}
      onClose={props.onClose}
      onChangeSelectedIds={props.onChangeSelectedIds}
      selectedIds={props.selectedIds}
      dialogTitle={t("shared.schedule_selector:title")}
      availableTableTitle={t(
        "shared.schedule_selector:label.available_schedules"
      )}
      assignedTableTitle={t(
        "shared.schedule_selector:label.selected_schedules"
      )}
      entityName={"folder"}
      columns={columns}
      rowKey={uiConstants.fields._id}
      fetchObjects={fetchObjects}
      saveKeyAvailable={"backlog.schedules.available"}
      saveKeyAssigned={"backlog.schedules.selected"}
    />
  );
};
