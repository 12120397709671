import React, { useContext, useState } from "react";
import { Column } from "react-data-grid";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../context";
import { DataDictHeader } from "./data-dict-header";
import { DataDictSubHeader } from "./data-dict-subheader";
import { DataDictGridHeader } from "./data-dict-grid-header";
import { EntityDialog } from "./entity-dialog";
import { IEntity, IEntityObject } from "@river/interfaces";
import { entityDef } from "./entity-dialog/defs/entity-def";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { fetchHelpers, uiConstants } from "../../helpers";
import { RiverSpinner } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./data-dict.module.scss";

export enum DataDictionaryAction {
  CREATE_ENTITY = "CREATE_ENTITY",
  UPDATE_ENTITY = "UPDATE_ENTITY",
  DELETE_ENTITY = "DELETE_ENTITY",
  CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE",
  UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE",
  DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE",
  CREATE_RELATION = "CREATE_RELATION",
  UPDATE_RELATION = "UPDATE_RELATION",
  DELETE_RELATION = "DELETE_RELATION",
  CREATE_INDEX = "CREATE_INDEX",
  UPDATE_INDEX = "UPDATE_INDEX",
  DELETE_INDEX = "DELETE_INDEX",
}

export const DataDict: React.FC = () => {
  usePageCleanup();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  const [entityDialogOpened, setEntityDialogOpened] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<IEntity | null>(null);

  const openEntityDetailsDialog = (entity: IEntity): void => {
    setSelectedEntity(entity);
    setEntityDialogOpened(true);
  };

  const closeEntityDialog = (): void => {
    setEntityDialogOpened(false);
    setSelectedEntity(null);
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "entity_name",
      name: t("module.data_dictionary:label.entity_name"),
      width: 230,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  table.setSelectedRowIds(new Set());
                  openEntityDetailsDialog(formatterProps.row as IEntity);
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "description",
      name: t("module.data_dictionary:label.description"),
      width: 270,
    },
    {
      key: "collection_name",
      name: t("module.data_dictionary:label.collection_name"),
      width: 166,
    },
    {
      key: "external_url",
      name: t("module.data_dictionary:label.external_url"),
      width: 400,
    },
    {
      key: "is_custom",
      name: t("module.data_dictionary:label.is_custom"),
    },
  ];

  const entity: IUseEntity = useEntity({
    entityName: "entity",
    definition: entityDef,
  });

  const fetchEntities = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const entities: any[] = await adapterContext!.service
      .getAdapterService()
      .getEntityDefinitions(fetchHelpers.getTableQuery({ fetchProps }));
    return entities as IEntityObject[];
  };

  const table: IUseTable = useTable({
    saveKey: "data_dict",
    columns,
    fetchFunction: fetchEntities,
    fetchOn: true,
    infiniteScrolling: true,
    useAdvancedFilters: false,
  });

  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <DataDictHeader />
        <DataDictSubHeader />
        <DataDictGridHeader />
        <RiverSpinner show={table.isLoading} />
        <RiverDataGrid
          className={styles.grid}
          columns={table.columns}
          rows={table.entities}
          disableSelectAll={true}
          singleSelect={true}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
        <EntityDialog
          entity={selectedEntity!}
          open={entityDialogOpened}
          onClose={closeEntityDialog}
          onUpdate={table.refresh}
        />
      </TableContext.Provider>
    </>
  );
};
