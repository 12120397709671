import React, { useState, ReactElement } from "react";
import {
  IFieldValidationErrors,
  useFormField,
  RiverFormFieldError,
} from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import {
  FormControl,
  IconButton,
  InputLabel,
  InputProps,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LookupDialog, ILookupProps } from "./lookup-dialog";
import styles from "./river-lookup.module.scss";
import clsx from "clsx";

interface IRiverLookupProps {
  id: string;
  name?: string;
  value?: string | number;
  label?: string;
  inputProps?: InputProps;
  onSelect?: (selectedObject: IEntityObject) => void;
  onChangeEvent?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors?: IFieldValidationErrors;
  helperText?: string;
  error?: boolean;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  lookup: ILookupProps;
}

export const RiverLookup: React.FC<IRiverLookupProps> = (
  props
): ReactElement => {
  const { label, value, disabled, onChange, hasErrors } = useFormField(props);

  const [lookupDialogOpened, setLookupDialogOpened] = useState<boolean>(false);

  const onDialogSubmit = (lookupObject?: IEntityObject): void => {
    setLookupDialogOpened(false);
    if (lookupObject) {
      if (props.onSelect) {
        props.onSelect(lookupObject);
      }
    }
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={clsx([styles.root, props.className])}
        error={hasErrors}
        fullWidth={props.fullWidth}
        disabled={disabled}
      >
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <OutlinedInput
          classes={{ root: styles.outlinedInput, input: styles.input }}
          id={props.id}
          name={props.name || props.id}
          value={value}
          onChange={onChange}
          label={label}
          aria-describedby={`${props.id}-helper-text`}
          {...props.inputProps}
        />
        {!disabled && (
          <IconButton
            className={styles.lookupButton}
            aria-label="open lookup dialog"
            onClick={() => {
              setLookupDialogOpened(true);
            }}
            disabled={disabled}
            size="large"
          >
            <SearchIcon />
          </IconButton>
        )}
        <RiverFormFieldError id={props.id} />
      </FormControl>
      <LookupDialog
        open={lookupDialogOpened}
        onClose={() => setLookupDialogOpened(false)}
        singleSelect={true}
        onSubmit={(selection) => {
          let selected: IEntityObject;
          if (selection.length) {
            selected = selection[0];
            onDialogSubmit(selected);
          }
        }}
        lookup={props.lookup}
      />
    </>
  );
};
