import React, { useContext, useState } from "react";
import {
  RiverDateInput,
  useNotification,
  PrimaryButton,
} from "@river/common-ui";
import PieChartIcon from "@mui/icons-material/PieChart";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import { SidebarContext } from "../../../context";
import { useTranslation } from "@river/common-ui";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import styles from "./backlog-sub-header.module.scss";
import clsx from "clsx";
import { ModuleKey } from "../../sidebar-menu";
import { BacklogAction } from "../../../services";
import ProtectedAction from "../../protected-action";

export const BacklogSubHeader: React.FC = (props) => {
  const { t } = useTranslation();
  const [scheduleDate, setScheduleDate] = useState<Date>();
  const notify = useNotification();
  const sidebarContext = useContext(SidebarContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ChartToggleIcon = sidebarContext?.isShowingStats
    ? VerticalSplitIcon
    : PieChartIcon;

  return (
    <div className={headerStyles.commonSubheader}>
      <ProtectedAction
        module={ModuleKey.BACKLOG}
        action={BacklogAction.SCHEDULE}
      >
        <RiverDateInput
          id={"schedule_date"}
          label={""}
          className={styles.scheduleDate}
          value={scheduleDate?.toISOString().substr(0, 10)}
          onChangeEvent={(event) => {
            setScheduleDate(new Date(event.target.value));
          }}
        />
        <PrimaryButton
          onClick={() => {
            notify.info(
              t("shared.error_messages:notification.feature_not_implemented")
            );
          }}
          text={t("module.backlog:button.schedule")}
        />
      </ProtectedAction>
      <div className={clsx([headerStyles.subheaderActions])}>
        {/*Temporarily commented KPI components*/}
        {/*<ChartToggleIcon
          titleAccess={
            sidebarContext?.isShowingStats ? "Hide Stats" : "Show Stats"
          }
          classes={{
            root: clsx([
              headerStyles.subheaderAction,
              { [styles.sidebarIcon]: sidebarContext?.isShowingStats },
            ]),
          }}
          onClick={() => {
            const isShowingStats = sidebarContext?.isShowingStats;
            if (!isShowingStats && sidebarContext?.collapsed) {
              sidebarContext?.setCollapsed(false);
            }
            sidebarContext?.setIsShowingStats(!isShowingStats);
          }}
        />*/}
      </div>
    </div>
  );
};
