import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface IJdeOperationMassUpdate {
  F3112_OPST?: string;
  F3112_RUNL?: string;
  F3112_RUNM?: string;
  F3112_SETL?: string;
  F3112_QUED?: string;
  F3112_MOVD?: string;
  F3112_SETC?: string;
  F3112_AN8?: string;
}

export class JdeOperationMassUpdate {
  constructor(obj: IJdeOperationMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useJdeOperationUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, JdeOperationMassUpdate>({
    standalone: {
      fields: new JdeOperationMassUpdate({
        F3112_OPST: "",
        F3112_RUNL: "",
        F3112_RUNM: "",
        F3112_SETL: "",
        F3112_QUED: "",
        F3112_MOVD: "",
        F3112_SETC: "",
        F3112_AN8: "",
      }),
      fieldDefs: [
        { fieldName: "F3112_OPST", dataType: "string" },
        { fieldName: "F3112_RUNL", dataType: "double" },
        { fieldName: "F3112_RUNM", dataType: "double" },
        { fieldName: "F3112_SETL", dataType: "double" },
        { fieldName: "F3112_QUED", dataType: "double" },
        { fieldName: "F3112_MOVD", dataType: "double" },
        { fieldName: "F3112_SETC", dataType: "double" },
        { fieldName: "F3112_AN8", dataType: "double" },
      ],
    },
    submit: props.submit,
    labels: {
      F3112_OPST: t("entity.operation:operation.F3112_OPST"),
      F3112_RUNL: t("entity.operation:operation.F3112_RUNL"),
      F3112_RUNM: t("entity.operation:operation.F3112_RUNM"),
      F3112_SETL: t("entity.operation:operation.F3112_SETL"),
      F3112_QUED: t("entity.operation:operation.F3112_QUED"),
      F3112_MOVD: t("entity.operation:operation.F3112_MOVD"),
      F3112_SETC: t("entity.operation:operation.F3112_SETC"),
      F3112_AN8: t("entity.operation:operation.F3112_AN8"),
    },
  });
};
