import { AdapterUiService } from "./adapter-ui.service";
import { IGetProgressFiltersProps } from "./schedule-utilization-ui-service";
import { QueryProvider, ReactElementProvider } from "./ui-service.types";
import { ICraftSelectorProps } from "./crafts-ui.service";
import { ReactElement } from "react";
export abstract class ScheduleReportUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "module.schedules",
      "module.schedule",
      "module.schedule_report",
      "shared.crafts",
      "shared.lookup",
      "shared.schedule_kpis",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.column_selector",
      "shared.river_custom_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.river_data_grid",
      "shared.schedule_status",
      "common.label",
      "common.button",
      "entity.folder",
      "entity.status_ref",
      "entity.calendar",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  abstract getProgressFilters(props: IGetProgressFiltersProps): QueryProvider;
  abstract renderProgressFilters(): ReactElementProvider;
  abstract getProgressHeaderActionsRenderer(): () => ReactElementProvider;
  abstract renderCraftsSelector(props: ICraftSelectorProps): ReactElement;
  abstract getCraftLabelPropertyValue(props: any): string;
  abstract getErpSpecificI18nNamespaces(): string[];
}
