import React, { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TableContext,
} from "../../../context";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";

export const useUnassignAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);

  const scheduleContext = useContext(ScheduleContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("module.schedule:action_label.unassign");

  const onClick = (): void => unassignConfirmationDialog.open();

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={EventBusyIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderDialog = (): ReactElement => unassignConfirmationDialog.render();

  const renderUnassignAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  const unassignTask = async (): Promise<void> => {
    const assingmentsIds = Array.from(tableContext?.table.selectedRowIds!);
    let isError = false;
    let hasSuccess = false;

    tableContext?.table?.forceLoadingState(true);
    for (let id of assingmentsIds) {
      try {
        await adapterContext!.service.getAdapterService().unassign(id);
        hasSuccess = true;
      } catch (message) {
        notify.error({ message });
        isError = true;
        break;
      }
    }
    tableContext?.table?.forceLoadingState(false);

    if (hasSuccess) {
      tableContext?.table.refresh();
      scheduleContext!.scheduleUtilizationCurrentTableState?.refresh();
    }

    if (!isError) {
      notify.success(t("module.schedule:notification.unassign_successful"));
    }
  };

  const unassignConfirmationDialog = useSimpleDialog({
    title: t("module.schedule:message.unassign_task.title"),
    message: t("module.schedule:message.confirm_unassign"),
    confirmButtonText: t("common.button:unassign"),
    onConfirm: unassignTask,
  });

  return {
    renderUnassignAction,
    unassignAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseUnassignAction = ReturnType<typeof useUnassignAction>;
