import { ColumnsProvider, CrewsUiService, FetchFunctionProvider } from "..";
import { useTranslation } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useContext } from "react";
import { fetchHelpers } from "../../helpers";
import {
  IEntityObject,
  OracleCloudAdapterCrewCraftDto,
} from "@river/interfaces";
import { RiverDefaultNumberEditor } from "../../components/shared/river-data-grid/cell-editors";

export class OracleCloudCrewsUiService extends CrewsUiService {
  getAvailableCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "LaborInstanceName",
        name: t(
          "entity.availability_header:availability_header.LaborInstanceName"
        ),
      },
      {
        key: "Resources.ResourceCode",
        name: t("entity.availability_header:availability_header.Resources"),
      },
    ];
  };

  getAssignedCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "LaborInstanceName",
        name: t("entity.crew_person:crew_person.LaborInstanceName"),
      },
      {
        key: "Resources.ResourceCode",
        name: t("entity.crew_person:crew_person.Resources"),
      },
    ];
  };

  getAvailableCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "ResourceName",
        name: t("entity.resource:resource.ResourceName"),
      },
      {
        key: "ResourceDescription",
        name: t("entity.resource:resource.ResourceDescription"),
      },
      {
        key: "ResourceType",
        name: t("entity.resource:resource.ResourceType"),
      },
      {
        key: "ResourceCode",
        name: t("entity.resource:resource.ResourceCode"),
      },
    ];
  };

  getAssignedCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "ResourceName",
        name: t("entity.crew_resource:crew_resource.ResourceName"),
      },
      {
        key: "ResourceDescription",
        name: t("entity.crew_resource:crew_resource.ResourceDescription"),
      },
      {
        key: "ResourceType",
        name: t("entity.crew_resource:crew_resource.ResourceType"),
      },
      {
        key: "number_of_units",
        name: t("entity.crew_resource:crew_resource.number_of_units"),
        width: 150,
        editor: (props) => <RiverDefaultNumberEditor editorProps={props} />,
      },
    ];
  };

  resourceNumberField = "LaborInstanceId";
  availableCrewCraftsEntityName = "resource";
  availableCrewCraftsRowKeyGetter = (row: any): string => row["ResourceId"];
  assignedCrewCraftsEntityName = "crew_resource";

  getAvailableCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return async (fetchProps: ITableFetchFunctionProps) => {
        const craftIdKey: string = "ResourceId";
        const assignedCraftIds: string[] = [];
        const assignedCrafts: IEntityObject[] = await adapterContext!.service
          .getAdapterService()
          .searchEntityData("crew_resource", {
            query: {
              $and: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            },
          });
        assignedCrafts.forEach((craft) =>
          assignedCraftIds.push(craft[craftIdKey] as string)
        );

        return await adapterContext!.service.getAdapterService().fetchCrafts(
          fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributes: [
              {
                attribute_name: craftIdKey,
                attribute_value: {
                  operator: "$nin",
                  value: assignedCraftIds,
                },
              },
            ],
          })
        );
      };
    };
  };

  getAssignedCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return async (fetchProps: ITableFetchFunctionProps) => {
        return await adapterContext!.service
          .getAdapterService()
          .searchEntityData(
            "crew_resource",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            })
          );
      };
    };
  };

  getAssignCrewCraftsFn = (crew: string) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return (selectedIds: Set<string>): Promise<any> => {
        const promises: Promise<void>[] = [];
        selectedIds.forEach((ResourceId) => {
          const dto: OracleCloudAdapterCrewCraftDto = {
            crew,
            ResourceId: Number(ResourceId),
            number_of_units: 1,
          };
          promises.push(
            adapterContext!.service.getAdapterService().createCrewCraft(dto)
          );
        });
        return Promise.all(promises);
      };
    };
  };

  getErpSpecificI18nNamespaces(): string[] {
    return [];
  }
}
