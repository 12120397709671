import React, { ReactElement } from "react";
import styles from "./river-colour-cell-formatter.module.scss";

interface IRiverColourCellFormatterProps {
  display_options: string;
}
export const RiverColourCellFormatter: React.FC<
  IRiverColourCellFormatterProps
> = (props): ReactElement => {
  return (
    <div className={styles.container}>
      <div
        className={styles.colour}
        style={{ backgroundColor: `${props.display_options}` }}
      />
    </div>
  );
};
