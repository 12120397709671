import { useContext, FC } from "react";
import { PageHeader } from "../../page-header";
import { TableContext } from "../../../context";
import { useTranslation } from "@river/common-ui";
import headerStyles from "../../shared/common-header/common-header.module.scss";

export const SupervisorSchedulesHeader: FC = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);

  return (
    <PageHeader pageTitle={t("module.supervisor_schedules:module.title")}>
      {!tableContext?.table.isLoading && (
        <span className={headerStyles.numberOfEntries}>
          {`(${tableContext?.table.entities?.length})`}
        </span>
      )}
    </PageHeader>
  );
};
