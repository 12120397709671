import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const EditIcon: React.FC<RiverSVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill="currentColor"
      {...sanitizeSvgProps(props)}
    >
      <title>{props.title}</title>
      <mask
        id="mask0_1395_7241"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1395_7241)">
        <path d="M4.16667 15.8327H5.33333L12.5208 8.64518L11.3542 7.47852L4.16667 14.666V15.8327ZM16.0833 7.43685L12.5417 3.93685L13.7083 2.77018C14.0278 2.45074 14.4203 2.29102 14.8858 2.29102C15.3508 2.29102 15.7431 2.45074 16.0625 2.77018L17.2292 3.93685C17.5486 4.25629 17.7153 4.64185 17.7292 5.09352C17.7431 5.54463 17.5903 5.9299 17.2708 6.24935L16.0833 7.43685ZM14.875 8.66602L6.04167 17.4993H2.5V13.9577L11.3333 5.12435L14.875 8.66602Z" />
      </g>
    </svg>
  );
};
