import { AdapterUiService } from "./adapter-ui.service";

export abstract class ValidationDashboardUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "module.validation_dashboard",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.schedule_selector",
      "shared.column_selector",
      "shared.river_table_selector",
      "shared.grid_header",
      "common.button",
      "common.label",
      "shared.river_data_grid",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getErpSpecificI18nNamespaces(): string[];
}
