import { FC, useContext } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SidebarContext } from "../../../context";
import { useEnv } from "@river/common-ui";
import styles from "./sidebar-toggle.module.scss";
import clsx from "clsx";

interface ISidebarToggle {
  className?: string | undefined;
}

export const SidebarToggle: FC<ISidebarToggle> = (props) => {
  const env = useEnv();
  const { isMobile } = env;

  const sidebarContext = useContext(SidebarContext);
  const commonProps = {
    className: props.className,
    classes: {
      root: clsx([
        styles.root,
        { [styles.mobile]: isMobile },
        { [styles.collapsed]: sidebarContext?.collapsed },
      ]),
    },
  };

  return (
    <>
      {sidebarContext?.collapsed ? (
        <ArrowForwardIosIcon
          {...commonProps}
          onClick={() => sidebarContext?.setCollapsed({ collapsed: false })}
        />
      ) : (
        <ArrowBackIosIcon
          {...commonProps}
          onClick={() => sidebarContext?.setCollapsed({ collapsed: true })}
        />
      )}
    </>
  );
};
