import { FC, useContext } from "react";
import { AdapterUiContext } from "../../../../context";
import styles from "./schedule-progress-chart-header.module.scss";

export const ScheduleProgressChartHeader: FC = () => {
  const adapterContext = useContext(AdapterUiContext);
  const uiService = adapterContext?.service.getScheduleReportUiService();
  const progressHeaderActionsRenderer =
    uiService?.getProgressHeaderActionsRenderer()();

  return <div className={styles.root}>{progressHeaderActionsRenderer?.()}</div>;
};
