import { FC, useState, ComponentType } from "react";
import { CronTasks } from "./cron-tasks";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

const ModuleWrapper: FC<ITranslationProps> = (props) => (
  <>{props.tReady && <CronTasks />}</>
);

export const CronTasksModule: FC = () => {
  const namespaces = [
    "module.cron",
    "shared.lookup",
    "shared.grid_header",
    "shared.advanced_filters",
    "shared.saved_filters_dialog",
    "shared.planning_plant_selector",
    "shared.error_messages",
    "shared.user_menu",
    "common.button",
    "common.label",
    "status_ref",
    "shared.river_data_grid",
    "entity.rule_action_ref",
  ];

  const [Component] = useState<ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
