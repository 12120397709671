import React, { ReactElement, useContext } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TableContext } from "../../../../../context";
import { PrimaryButton } from "@river/common-ui";
import { IUseEnv, useEnv } from "@river/common-ui";
import {
  GridHeader,
  GridActions,
  GenericAction,
  IGridHeaderStyles,
  FilterDropdown,
} from "../../../index";
import styles from "../river-selector-grid-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

export interface IAssignedObjectsGridHeaderStyles {
  gridHeader?: IGridHeaderStyles;
}

interface IAssignedObjectsGridHeader {
  title: string;
  classes?: IAssignedObjectsGridHeaderStyles;
  unassignSelectedObjects: () => void;
  customGridActions_primary?: ReactElement;
  customGridActions_secondary?: ReactElement;
}

export const AssignedObjectsGridHeader: React.FC<IAssignedObjectsGridHeader> = (
  props
) => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const tableContext = useContext(TableContext);

  const renderRemoveArrow = (): ReactElement => (
    <GenericAction
      icon={ArrowBackIcon}
      onClick={() => {
        if (tableContext?.table.selectedRowIds.size !== 0) {
          props.unassignSelectedObjects();
        }
      }}
      title={t("shared.river_table_selector:label.remove_selected")}
      customProps={{
        color: "primary",
      }}
      enableOnSelection={true}
    />
  );

  const renderRemoveButton = (): ReactElement => (
    <PrimaryButton
      onClick={() => {
        if (tableContext?.table.selectedRowIds.size !== 0) {
          props.unassignSelectedObjects();
        }
      }}
      text={t("common.button:remove")}
      className={styles.selectionButton}
      disabled={tableContext?.table.selectedRowIds.size === 0}
    />
  );

  return (
    <GridHeader
      title={props.title}
      classes={props.classes?.gridHeader}
      className={clsx([styles.root, { [styles.mobile]: isMobile }])}
    >
      <GridActions className={styles.entityActions}>
        {!isMobile && renderRemoveArrow()}
        <FilterDropdown />
        {props.customGridActions_primary}
        {isMobile && renderRemoveButton()}
      </GridActions>

      {!isMobile && (
        <GridActions>{props.customGridActions_secondary}</GridActions>
      )}
    </GridHeader>
  );
};
