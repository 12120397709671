import React, {
  RefObject,
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from "react";
import {
  IAdapterFolder,
  IEntityObject,
  IAdapterRule,
  IAdapterBaseline,
} from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  SetTabFn,
  SidebarContext,
} from "../../context";
import { SupervisorScheduleHeader } from "./supervisor-schedule-header";
import { SupervisorScheduleStatusHeader } from "./supervisor-schedule-status-header";
import { SupervisorScheduleTasks } from "./supervisor-schedule-tasks";
import { SupervisorScheduleUtilization } from "./supervisor-schedule-utilization";
import { useParams } from "react-router";
import { ISupervisorScheduleGanttTimeOption } from "./supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import { IUseTable } from "../shared";
import { IUseSupervisorScheduleGantt } from "./supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { uiConstants } from "../../helpers";
import { useNotification } from "@river/common-ui";
import { IUseSupervisorScheduleOperationsOverlay } from "./supervisor-schedule-gantt";
import {
  ObjectType,
  TableUiService,
  userPreferencesService,
} from "../../services";
import { isAfter, isBefore, isEqual } from "date-fns";
import { usePageCleanup } from "../../hooks";
import SplitterLayout from "react-splitter-layout";
import styles from "./supervisor-schedule.module.scss";
import clsx from "clsx";
import { DataGridHandle } from "react-data-grid";

export const DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE = 40;

const DEFAULT_TASK_PANE_HEIGHT_PERCENTAGE = 60;
const INITIAL_SCHEDULE_TASKS_EXPANDED: boolean = false;

export const SupervisorSchedule: React.FC = () => {
  usePageCleanup();
  const [splitterHeight, setSplitterHeight] = useState<number>(
    DEFAULT_TASK_PANE_HEIGHT_PERCENTAGE
  );
  const splitterContainerRef = useRef<HTMLDivElement>(null);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();
  const getRowsFromObjectIds = tableUiService.getRowsFromObjectIds();
  const [splitterTransitionActive, setSplitterTransitionActive] =
    useState<boolean>(true);

  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const [currentSchedule, setCurrentSchedule] = useState<IAdapterFolder>();
  const [selectedGanttDate, setSelectedGanttDate] = useState<Date>();
  const [ganttTimeOption, setGanttTimeOption] =
    useState<ISupervisorScheduleGanttTimeOption>();
  const [entityTableRef, setEntityTableRef] =
    useState<RefObject<DataGridHandle>>();
  const [ganttSplitterRef, setGanttSplitterRef] =
    useState<RefObject<HTMLDivElement>>();
  const [utilizationSplitterRef, setUtilizationSplitterRef] =
    useState<RefObject<HTMLDivElement>>();
  const [ganttScrollAreaRef, setGanttScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [ganttOverlayScrollAreaRef, setGanttOverlayScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [utilizationScrollAreaRef, setUtilizationScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [
    scheduleUtilizationCurrentTableState,
    setScheduleUtilizationCurrentTableState,
  ] = useState<IUseTable>();
  const [selectedValidationRule, setSelectedValidationRule] =
    useState<IAdapterRule | null>(null);
  const sidebarContext = useContext(SidebarContext);
  const currentTasksTableRef = useRef<IUseTable>();
  const setCurrentScheduleTasksTabFnRef = useRef<SetTabFn>();
  const ganttRef = useRef<IUseSupervisorScheduleGantt>();
  const utilizationGanttRef = useRef<IUseSupervisorScheduleGantt>();
  const operationsOverlayRef =
    useRef<IUseSupervisorScheduleOperationsOverlay>();
  const notify = useNotification();

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const timeoutRef = useRef<number>();
  const [scheduleTasksExpanded, setScheduleTasksExpanded] = useState<boolean>(
    INITIAL_SCHEDULE_TASKS_EXPANDED
  );
  const [selectedBaseline, setSelectedBaseline] = useState<
    IAdapterBaseline | undefined
  >();

  const loadCurrentSchedule = async (props?: { updateGanttDate?: boolean }) => {
    try {
      const schedule = await adapterContext!.service
        .getAdapterService()
        .getFolderById(scheduleId);

      if (props?.updateGanttDate) {
        setSelectedGanttDate(new Date(schedule.start_date));
      }
      setCurrentSchedule(schedule);
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    const setInitialScheduleTaskExpanded = async () => {
      try {
        const value = await userPreferencesService.getScheduleTaskExpanded(
          adapterContext!.service.getAdapterService()
        );
        const initialTasksExpandedValue = value ?? scheduleTasksExpanded;
        setScheduleTasksExpanded(initialTasksExpandedValue);
      } catch (message) {
        notify.error({ message });
      }
    };

    const setInitialSplitterHeight = async () => {
      try {
        const preferenceHeight: number | null =
          await userPreferencesService.getHorizontalScheduleSplitterPosition(
            adapterContext!.service.getAdapterService()
          );
        const initialHeight = preferenceHeight ?? splitterHeight;
        setSplitterHeight(initialHeight);
      } catch (message) {
        notify.error({ message });
      }
    };

    setInitialScheduleTaskExpanded();
    setInitialSplitterHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCurrentSchedule({ updateGanttDate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);

  useEffect(() => {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      forceUpdate();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarContext?.collapsed]);

  const scheduleFn = async (date: Date, ids?: string[]): Promise<void> => {
    const start_date = new Date(currentSchedule?.start_date!);
    const end_date = new Date(currentSchedule?.end_date!);

    if (
      !(
        (isEqual(start_date, date) || isBefore(start_date, date)) &&
        (isEqual(end_date, date) || isAfter(end_date, date))
      )
    ) {
      return;
    }

    const table = currentTasksTableRef.current;
    if (!ids) {
      ids = getSelectedRowsObjectIds(table!);
    }
    if (!ids.length) return;

    const objs: IEntityObject[] = getRowsFromObjectIds(table!, ids);
    const rowType = objs[0][uiConstants.fields.rowType] as ObjectType;

    try {
      table!.forceLoadingState(true);
      // additional boolean parameter - do not remove records from other folders
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .schedule({
          folder_id: scheduleId || "",
          entity_name: rowType,
          entity_ids: ids,
          start_date: date,
          auto_post: true,
        });
      table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, rowType),
          updatedRow,
        })),
      });
      scheduleUtilizationCurrentTableState!.fetch();
    } catch (message) {
      notify.error({ message });
    } finally {
      table!.forceLoadingState(false);
    }
  };

  const calculateSplitterHeightPercentage = (): number => {
    const containerEl = splitterContainerRef.current!;
    const paneEl = containerEl.querySelectorAll(
      ".layout-pane"
    )[0] as HTMLDivElement;
    const containerHeight: number = containerEl.getBoundingClientRect().height;
    return (paneEl.offsetHeight * 100) / (containerHeight || 1);
  };

  const saveSplitterHeight = async () => {
    try {
      const splitterHeightPercentage: number =
        calculateSplitterHeightPercentage();
      await userPreferencesService.setHorizontalScheduleSplitterPosition(
        adapterContext!.service.getAdapterService(),
        splitterHeightPercentage
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const handleSpitterOnDragEnd = () => {
    setSplitterTransitionActive(true);
    saveSplitterHeight();
  };

  return (
    <>
      {!!currentSchedule && (
        <SupervisorScheduleContext.Provider
          value={{
            currentSchedule,
            refreshCurrentSchedule: loadCurrentSchedule,
            selectedGanttDate,
            setSelectedGanttDate,
            entityTableRef,
            setEntityTableRef,
            currentTasksTableRef,
            ganttSplitterRef,
            setGanttSplitterRef,
            utilizationSplitterRef,
            setUtilizationSplitterRef,
            ganttScrollAreaRef,
            setGanttScrollAreaRef,
            ganttOverlayScrollAreaRef,
            setGanttOverlayScrollAreaRef,
            utilizationScrollAreaRef,
            setUtilizationScrollAreaRef,
            ganttTimeOption,
            setGanttTimeOption,
            forceScheduleRender: forceUpdate,
            scheduleUtilizationCurrentTableState,
            setScheduleUtilizationCurrentTableState,
            selectedValidationRule,
            setSelectedValidationRule,
            scheduleFn,
            ganttRef,
            utilizationGanttRef,
            operationsOverlayRef,
            selectedBaseline,
            setSelectedBaseline,
            scheduleTasksExpanded,
            setScheduleTasksExpanded,
            setCurrentScheduleTasksTabFnRef,
          }}
        >
          <div className={clsx([styles.root])} ref={splitterContainerRef}>
            <SupervisorScheduleHeader />
            <SplitterLayout
              vertical={true}
              percentage={true}
              primaryIndex={1}
              secondaryInitialSize={splitterHeight!}
              customClassName={clsx([
                styles.splitterLayout,
                {
                  [styles.splitterTransitionActive]: splitterTransitionActive,
                  [styles.scheduleTasksExpanded]: scheduleTasksExpanded,
                },
              ])}
              onDragStart={() => {
                setSplitterTransitionActive(false);
              }}
              onDragEnd={handleSpitterOnDragEnd}
            >
              <div className={styles.topContainer}>
                <SupervisorScheduleStatusHeader />
                <SupervisorScheduleTasks />
              </div>
              <SupervisorScheduleUtilization />
            </SplitterLayout>
          </div>
        </SupervisorScheduleContext.Provider>
      )}
    </>
  );
};
