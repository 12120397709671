import React, { ReactElement, useContext } from "react";
import { RhineRiverHeaderLogo } from "./rhine-river-header-logo";
import { ColoradoRiverHeaderLogo } from "./colorado-river-header-logo";
import { Constants } from "@river/constants";
import { ApplicationContext } from "../../../context";
import { ICustomer } from "@river/interfaces";
import { Link } from "react-router-dom";
import styles from "./header-logo.module.scss";

const adapterTypeLogoMap: { [adapterType: string]: React.FC } = {
  [Constants.adapter_type.sap]: RhineRiverHeaderLogo,
  [Constants.adapter_type.oracle_cloud]: ColoradoRiverHeaderLogo,
  [Constants.adapter_type.oracle_ebs]: ColoradoRiverHeaderLogo,
  [Constants.adapter_type.jde]: ColoradoRiverHeaderLogo,
};

export const HeaderLogo: React.FC = (): ReactElement => {
  const applicationContext = useContext(ApplicationContext);
  const customer: ICustomer = applicationContext?.customerInfo?.customer!;
  const adapterType: string = customer?.adapter_type;
  const Logo: React.FC = adapterTypeLogoMap[adapterType];

  return (
    <>
      {customer && (
        <Link to="/home" className={styles.root}>
          <Logo />
        </Link>
      )}
    </>
  );
};
