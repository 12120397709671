import { Column } from "react-data-grid";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { TableContext } from "../../../../context";
import dataGridStyles from "../../../shared/river-data-grid/river-data-grid.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../hooks";
import { useRiverSelectColumn } from "../../../shared";

export function useIndexColumns() {
  const [indexColumnsDef, setIndexColumnsDef] = useState<Column<any>[]>(
    [] as any[]
  );
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  let columns: Column<any>[];
  const indexColumns = (
    onIndexNameClick: (index: IEntityObject) => void
  ): Column<any>[] => {
    columns = [
      RiverSelectColumn,
      {
        key: "index_name",
        name: t("module.data_dictionary:dialog.entity_index.index_name"),
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <TableContext.Consumer>
                  {(tableContext) => (
                    <div
                      className={dataGridStyles.dataGridLink}
                      onClick={() => {
                        tableContext?.table.setSelectedRowIds(new Set());
                        onIndexNameClick(formatterProps.row);
                      }}
                    >
                      {renderCellText({ formatterProps })}
                    </div>
                  )}
                </TableContext.Consumer>
              ),
            })}
          </>
        ),
      },
      {
        key: "index_type",
        name: t("module.data_dictionary:dialog.entity_index.index_type"),
      },
    ];
    return indexColumnsDef;
  };
  useEffect(() => {
    setIndexColumnsDef(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { indexColumns };
}
export type IUseIndexColumns = ReturnType<typeof useIndexColumns>;

// indexes meta entity def
export const indexEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "",
    entity_name: "indexes_meta",
  },
  attributes: [
    {
      _id: "index_name",
      adapter_type: "",
      entity_name: "indexes_meta",
      attribute_name: "index_name",
      data_type: Constants.data_type.string,
      description: "Index",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "index_type",
      adapter_type: "",
      entity_name: "indexes_meta",
      attribute_name: "index_type",
      data_type: Constants.data_type.string,
      description: "Index Type",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
