import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import MultiLoadBackend, {
  MultiloadBackendOptions,
} from "i18next-multiload-backend-adapter";
import { initReactI18next } from "react-i18next";

export const DEFAULT_LANGUAGE = "en";
export const FALLBACK_LANGUAGE = "en";

interface IInitI18nProps {
  baseUrl: string;
  language?: string;
}

export const init18n = (props: IInitI18nProps): void => {
  i18n
    .use(MultiLoadBackend)
    .use(initReactI18next) // bind react-i18next to the instance
    .init<MultiloadBackendOptions>({
      lng: props.language || DEFAULT_LANGUAGE,
      fallbackLng: FALLBACK_LANGUAGE,
      //debug: true,
      nsSeparator: ":",
      //defaultNS: "login",
      returnNull: false,
      maxParallelReads: 40,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      backend: {
        //loadPath: `${baseUrl}/api/adapter/i18n/resources/{{lng}}/{{ns}}`,
        backend: HttpBackend,
        backendOption: {
          loadPath: `${props.baseUrl}/api/adapter/i18n/resources?languages={{lng}}&namespaces={{ns}}`,
          allowMultiLoading: true,
        },
        //"https://dev-customer1.local.test:3002/api/adapter/i18n/resources/{{lng}}/{{ns}}",
      },

      react: {
        useSuspense: false,
      },
      // react i18next special options (optional)
      // override if needed - omit if ok with defaults
      /*
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        useSuspense: true,
      }
      */
    });
};

export default i18n;
