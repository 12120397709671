import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleUtilizationContext,
} from "../../../../context";
import {
  IScheduleProgressFilters,
  ProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import {
  OracleEbsScheduleProgressWOTypeFilters,
  OracleEbsScheduleProgressDepartmentFilters,
  OracleEbsScheduleProgressResourceFilters,
} from "./filters";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters";

export interface IOracleEbsScheduleProgressFiltersDto
  extends IScheduleProgressFilters {
  [ProgressSummaryFilterID.WO_TYPES]: any[];
  [ProgressSummaryFilterID.DEPARTMENTS]: any[];
  [ProgressSummaryFilterID.RESOURCES]: any[];
}

export const OracleEbsScheduleProgressFilters: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();
  const scheduleUtilizationContext = useContext(ScheduleUtilizationContext);
  const {
    progressFiltersOpened,
    progressSummaryFilters,
    setProgressSummaryFilters,
  } = scheduleUtilizationContext!;
  const fetchProgressSummaryFilters = async () => {
    try {
      setLoading(true);
      const result: IOracleEbsScheduleProgressFiltersDto =
        await userPreferencesService.getScheduleProgressFilters(
          adapterContext!.service.getAdapterService()
        );
      if (result) {
        setProgressSummaryFilters(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteProgressSummaryFilter = async (
    selectedObject: any,
    filterId: ProgressSummaryFilterID
  ) => {
    let columnId: string;
    if (filterId === ProgressSummaryFilterID.WO_TYPES) {
      columnId = "LOOKUP_CODE";
    } else if (filterId === ProgressSummaryFilterID.DEPARTMENTS) {
      columnId = "DEPARTMENT_ID";
    } else {
      columnId = "RESOURCE_ID";
    }

    const newDto: IOracleEbsScheduleProgressFiltersDto = {
      ...(progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto),
      [filterId]: (progressSummaryFilters as any)[filterId]?.filter(
        (selected: any) => selected[columnId] !== selectedObject[columnId]
      ),
    };

    try {
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        newDto
      );
      setProgressSummaryFilters(newDto);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onSelectProgressSummaryFilters = async (
    selectedObjects: any[],
    filterId: ProgressSummaryFilterID
  ) => {
    try {
      const payload: IOracleEbsScheduleProgressFiltersDto = {
        ...(progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto),
        [filterId]: [
          ...(progressSummaryFilters as any)[filterId]!,
          ...selectedObjects,
        ],
      };
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        payload
      );
      setProgressSummaryFilters(payload);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProgressSummaryFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWOTypeFilters = () => (
    <OracleEbsScheduleProgressWOTypeFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.WO_TYPES
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  const renderDepartmentFilters = (): ReactElement => (
    <OracleEbsScheduleProgressDepartmentFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.DEPARTMENTS
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  const renderResourceFilters = (): ReactElement => (
    <OracleEbsScheduleProgressResourceFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleProgressFiltersDto)[
          ProgressSummaryFilterID.RESOURCES
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  return (
    <Collapse
      in={progressFiltersOpened}
      orientation={"horizontal"}
      timeout={300}
    >
      <ChartFiltersContainer>
        {renderWOTypeFilters()}
        {renderDepartmentFilters()}
        {renderResourceFilters()}
        <RiverSpinner show={loading} />
      </ChartFiltersContainer>
    </Collapse>
  );
};
