import { createContext } from "react";
import { IAdapterFolder, IEntityObject } from "@river/interfaces";
import { IExecutionAction } from "../components/execution/execution";
import { IExecutionActions } from "../services";

export interface IExecutionContext {
  initialized: boolean;
  executionActions: IExecutionActions;
  currentSchedule: IAdapterFolder | null;
  setCurrentSchedule: (schedule: IAdapterFolder | null) => void;
  currentWorkOrder: IEntityObject | null;
  setCurrentWorkOrder: (operation: IEntityObject | null) => void;
  currentActions: IExecutionAction[] | [];
  setCurrentActions: (actions: IExecutionAction[] | []) => void;
  completionPercentageDialogAction: any;
  userStatusAction: any;
}

export const ExecutionContext = createContext<IExecutionContext | null>(null);
