import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  QueryAttributeDto,
} from "@river/interfaces";

interface IUseResolutionCodeLookupProps {
  form: RiverFormInstance;
  selectedResolutionCode: any;
  setSelectedResolutionCode: (causeCode: any) => void;
}

export function useResolutionCodeLookup(props: IUseResolutionCodeLookupProps) {
  const notify = useNotification();

  const { form, selectedResolutionCode, setSelectedResolutionCode } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const getCustomFilters = (): QueryAttributeDto[] => [
    {
      attribute_name: "SET_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["FAILURE_SET_ID"] as string,
      },
    },
    {
      attribute_name: "INVENTORY_ITEM_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["INVENTORY_ITEM_ID"] as string,
      },
    },
    {
      attribute_name: "FAILURE_CODE",
      attribute_value: {
        operator: "$eq",
        value: currentDto["FAILURE_CODE"] as string,
      },
    },
    {
      attribute_name: "CAUSE_CODE",
      attribute_value: {
        operator: "$eq",
        value: currentDto["CAUSE_CODE"] as string,
      },
    },
  ];

  useEffect(() => {
    validateStandaloneField("resolutionCodeDisplay");

    const RESOLUTION_CODE = selectedResolutionCode?.["RESOLUTION_CODE"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { RESOLUTION_CODE }
    );
    form.setDto(newDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResolutionCode]);

  const fetchResolutionCode = async (text: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("resolution_code", {
          query: {
            $and: [
              ...getCustomFilters(),
              {
                attribute_name: "RESOLUTION_CODE",
                attribute_value: {
                  operator: "$eq",
                  value: text,
                },
              },
            ],
          },
        });
      const causeCode: any = results[0] || null;
      setSelectedResolutionCode(causeCode);
      validateStandaloneField("resolutionCodeDisplay", text);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchResolutionCode = useCallback(
    debounce((text) => {
      fetchResolutionCode(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"resolutionCodeDisplay"}
      fullWidth
      //@ts-ignore
      disabled={currentDto["FAILURE_CODE_READONLY"]}
      lookup={{
        type: LookupType.ORACLE_EBS_RESOLUTION_CODE,
        customFilters: getCustomFilters(),
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchResolutionCode(event.target.value);
      }}
      onSelect={(selectedResolutionCode) => {
        const { RESOLUTION_CODE } = selectedResolutionCode;
        const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
          new OracleEbsAdapterWorkorderCompletionDto(),
          { ...currentDto },
          { RESOLUTION_CODE }
        );
        form.setDto(newDto);
        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          resolutionCodeDisplay: RESOLUTION_CODE,
        });
        setSelectedResolutionCode(selectedResolutionCode);
      }}
    />
  );

  return {
    render,
    reFetch: async () => {
      const standalone: IOracleEbsWoCompletionStandaloneFields =
        form.standalone as IOracleEbsWoCompletionStandaloneFields;
      const resolutionCodeDisplay: string = standalone.resolutionCodeDisplay!;
      await fetchResolutionCode(resolutionCodeDisplay);
    },
  };
}

export type IUseResolutionCodeLookup = ReturnType<
  typeof useResolutionCodeLookup
>;
