import React, { ReactElement, useEffect, useRef } from "react";

import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverFormSelect,
  RiverTextInput,
  RiverCheckbox,
  IRiverSimpleSelectItem,
  IFieldValidationErrors,
} from "@river/common-ui";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  AttributeDto,
  AttributeOptionDto,
  IAttribute,
} from "@river/interfaces";
import { PropertyChangeHandler, RiverFormInstance } from "../../../hooks";
import { Constants } from "@river/constants";
import { useEntityAttributeForm } from "./use-entity-attribute-form";
import styles from "./entity-attribute-dialog.module.scss";
import { useTranslation } from "@river/common-ui";
import { LookupType, RiverLookup } from "../../shared";
import { IStandaloneFields } from "./use-entity-attribute-form";
import { RiverColorPicker } from "../../shared/river-color-picker";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface IEntityAttributeDialogProps {
  open: boolean;
  entityName: string;
  onClose: (success: boolean) => void;
  attribute?: IAttribute | null;
}

const { attribute_cardinality, input_type, data_type } = Constants;
const DEFAULT_OPTION_COLOR = "#ffff00";

const VALID_DATA_TYPE_BY_INPUT_TYPE = {
  [input_type.checkbox]: [data_type.boolean],
  [input_type.number]: [data_type.double],
  [input_type.text]: [data_type.string],
  [input_type.dropdown]: [data_type.string],
  [input_type.multi_select]: [data_type.string],
  [input_type.value_list]: [data_type.string],
  [input_type.none]: [...Object.values(data_type)],
};

const VALID_CARDINALITY_BY_INPUT_TYPE = {
  [input_type.checkbox]: [attribute_cardinality.one],
  [input_type.number]: [attribute_cardinality.one],
  [input_type.text]: [attribute_cardinality.one],
  [input_type.dropdown]: [attribute_cardinality.one],
  [input_type.multi_select]: [attribute_cardinality.many],
  [input_type.value_list]: [attribute_cardinality.one],
  [input_type.none]: [attribute_cardinality.one, attribute_cardinality.many],
};

export const EntityAttributeDialog: React.FC<IEntityAttributeDialogProps> = (
  props
) => {
  const { t } = useTranslation();
  const entityNameRef = useRef<string>("");
  const attribute: IAttribute = props.attribute!;

  if (attribute?.data_type?.startsWith("entity:")) {
    entityNameRef.current = props.attribute!.data_type.split(":")[1];
    props.attribute!.data_type = "entity";
  }

  const form: RiverFormInstance = useEntityAttributeForm({
    entityName: props.entityName,
    attribute: props.attribute!,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
    onUpdate: () => {
      const success = true;
      closeDialog(success);
    },
  });
  const attributeDto = form.dto as AttributeDto;

  const filteredCardinalityOptions = [
    input_type.multi_select,
    input_type.dropdown,
    input_type.value_list,
  ].includes(attributeDto.input_type!);
  const {
    resetForm,
    submit,
    setStandaloneFields,
    onStandaloneLookupSelect,
    getArrayPropertyChangeHandler,
    getArrayValidationErrors,
    setDto,
  } = form;
  const standalone: IStandaloneFields = form.standalone as IStandaloneFields;
  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  const dialogTitle = !form.isReadOnly
    ? props.attribute
      ? t("module.data_dictionary:label.edit_attribute")
      : t("module.data_dictionary:label.create_attribute")
    : t("module.data_dictionary:label.attribute_details");
  const actionButtonText = props.attribute
    ? t("common.button:save")
    : t("common.button:create");

  useEffect(() => {
    let newSelectedEntityNameValue: string = "";

    if (attributeDto.data_type !== Constants.data_type.entity) {
      newSelectedEntityNameValue = "placeholder";
    } else if (entityNameRef.current !== "") {
      newSelectedEntityNameValue = entityNameRef.current;
      entityNameRef.current = "";
    } else {
      newSelectedEntityNameValue = "";
    }

    const newStandaloneFields: IStandaloneFields = {
      ...standalone,
      selectedEntityName: newSelectedEntityNameValue,
    };
    Object.setPrototypeOf(
      newStandaloneFields,
      Object.getPrototypeOf(standalone)
    );
    setStandaloneFields(newStandaloneFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeDto.data_type]);

  const getValidCardinalityOptions = (
    inputType: string
  ): IRiverSimpleSelectItem[] =>
    VALID_CARDINALITY_BY_INPUT_TYPE[inputType].map((value) => ({
      value,
      text: value,
    }));

  const getValidDataTypeOptions = (
    inputType: string
  ): IRiverSimpleSelectItem[] =>
    VALID_DATA_TYPE_BY_INPUT_TYPE[inputType].map((value) => ({
      value,
      text: value,
    }));

  const renderAttributeOptionField = (
    option: AttributeOptionDto,
    index: number
  ) => {
    const onAttributeOptionsPropertyChange: PropertyChangeHandler =
      getArrayPropertyChangeHandler("options", option, index);

    const getOptionFieldValidationErrors = (
      indexOption: string
    ): IFieldValidationErrors =>
      getArrayValidationErrors("options", index, indexOption);

    const updateAttributeOptionColor = (colorHex: string) => {
      let updated: AttributeOptionDto[] = [...(attributeDto.options || [])];
      updated[index].color = colorHex;

      setDto({
        ...attributeDto,
        options: updated,
      });
    };

    return (
      <>
        {index === 0 && <p className={styles.optionsTitle}>Options</p>}
        <div key={index} className={styles.attributeOptionField}>
          <RiverTextInput
            id={"value"}
            className={styles.attributeName}
            label={t(
              "module.data_dictionary:dialog.entity_attribute.attribute.option.value"
            )}
            value={option.value}
            onChangeEvent={onAttributeOptionsPropertyChange}
            validationErrors={getOptionFieldValidationErrors("value")}
            disabled={form.isReadOnly}
          />
          {attributeDto.input_type === Constants.input_type.value_list && (
            <RiverColorPicker
              className={styles.optionColorPicker}
              initialColor={option.color!}
              onSelectColor={(color: string) =>
                updateAttributeOptionColor(color)
              }
              renderPreview={(color: string): ReactElement => (
                <div
                  className={styles.colorTile}
                  style={{ background: color }}
                />
              )}
              withoutTitle={true}
            />
          )}
          {!form.isReadOnly && (
            <CancelIcon
              className={styles.deleteOptionIcon}
              onClick={() => {
                removeAttributeOption(index);
              }}
            />
          )}
        </div>
      </>
    );
  };

  const addAttributeOption = () => {
    if (form.isReadOnly) return;
    const isValueList: boolean =
      attributeDto.input_type === Constants.input_type.value_list;

    const updated: AttributeDto = {
      ...attributeDto,
      options: [
        ...(attributeDto.options || []),
        {
          value: "",
          ...(isValueList && {
            color: DEFAULT_OPTION_COLOR,
          }),
        },
      ],
    };
    Object.setPrototypeOf(updated, Object.getPrototypeOf(attributeDto));

    setDto(updated);
  };

  const removeAttributeOption = (index: number) => {
    if (form.isReadOnly) return;
    const updated: AttributeDto = {
      ...attributeDto,
      options: attributeDto.options?.filter((_, i) => i !== index) || [],
    };
    Object.setPrototypeOf(updated, Object.getPrototypeOf(attributeDto));

    setDto(updated);
  };

  const updateDataType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (form.isReadOnly) return;
    const updated: AttributeDto = {
      ...attributeDto,
      options: [],
    };
    Object.setPrototypeOf(updated, Object.getPrototypeOf(attributeDto));

    setDto({ ...updated, data_type: event.target.value });
  };

  const updateInputTypeField = (value: string) => {
    if (form.isReadOnly) return;
    let updated = { ...attributeDto };
    Object.setPrototypeOf(updated, Object.getPrototypeOf(attributeDto));

    let updatedCardinality: string = "one";

    if (value === input_type.multi_select) {
      updatedCardinality = "many";
    }

    const updatedDataType =
      value === input_type.checkbox
        ? Constants.data_type.boolean
        : value === input_type.number
          ? Constants.data_type.double
          : Constants.data_type.string;

    setDto({
      ...updated,
      cardinality: updatedCardinality,
      data_type: updatedDataType,
      input_type: value,
      options: [],
    });
  };

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      onSubmit={submit}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={() => {
              const success = false;
              closeDialog(success);
            }}
            text={t("common.button:close")}
          />
          {!form.isReadOnly && (
            <RiverDialogActionButton onClick={submit} text={actionButtonText} />
          )}
        </>
      }
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      <>
        <div className={styles.textFields}>
          <RiverTextInput id={"attribute_name"} />
          <RiverTextInput id={"description"} />
          <RiverFormSelect
            id={"input_type"}
            items={[
              ...Object.values(Constants.input_type).map((text, index) => {
                return { text, value: text };
              }),
            ]}
            onChangeEvent={(event) => updateInputTypeField(event.target.value)}
          />
          <RiverFormSelect
            id={"data_type"}
            items={getValidDataTypeOptions(
              attributeDto.input_type ?? Constants.input_type.none
            )}
            onChangeEvent={updateDataType}
          />
          {attributeDto.data_type === Constants.data_type.entity && (
            <RiverLookup
              id={"selectedEntityName"}
              lookup={{ type: LookupType.ENTITIES }}
              onSelect={onStandaloneLookupSelect(
                "entity_name",
                "selectedEntityName"
              )}
              inputProps={{
                disabled: true,
              }}
            />
          )}
          <RiverFormSelect
            id={"cardinality"}
            items={getValidCardinalityOptions(
              attributeDto.input_type ?? Constants.input_type.none
            )}
          />
          {attributeDto?.options?.map((option, index) =>
            renderAttributeOptionField(option, index)
          )}
          {filteredCardinalityOptions && (
            <Button
              variant="text"
              color="primary"
              className={styles.addActionButton}
              startIcon={<AddCircleIcon />}
              onClick={addAttributeOption}
            >
              {t("module.data_dictionary:label.add_action")}
            </Button>
          )}
        </div>
        <div className={styles.checkboxes}>
          <RiverCheckbox id={"is_primary_key"} />
          <RiverCheckbox id={"is_required"} />
          <RiverCheckbox id={"is_persistent"} />
        </div>
      </>
    </RiverDialog>
  );
};
