import { FC } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { helpers } from "../../../../../helpers";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useTranslation } from "@river/common-ui";
import styles from "./supervisor-schedule-gantt-toggle.module.scss";

export enum SupervisorScheduleTasksViewId {
  LIST = "list",
  GANTT = "gantt",
}
export const SupervisorScheduleTasksViewURLParamName = "view";
export const DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW =
  SupervisorScheduleTasksViewId.GANTT;

export const SupervisorScheduleGanttToggle: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedView =
    searchParams.get(SupervisorScheduleTasksViewURLParamName) ||
    DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW;

  function handleOnIconClick(viewId: SupervisorScheduleTasksViewId) {
    navigate(
      helpers.replaceOrSetUrlParam(
        location.pathname,
        searchParams,
        SupervisorScheduleTasksViewURLParamName,
        viewId
      )
    );
  }

  return (
    <div className={styles.root}>
      {selectedView === SupervisorScheduleTasksViewId.GANTT && (
        <TableChartOutlinedIcon
          className={styles.icon}
          onClick={() => handleOnIconClick(SupervisorScheduleTasksViewId.LIST)}
          fontSize="small"
          titleAccess={t("module.supervisor_schedule:gantt_toggle.list")}
        />
      )}
      {selectedView === SupervisorScheduleTasksViewId.LIST && (
        <AccountTreeOutlinedIcon
          className={styles.icon}
          onClick={() => handleOnIconClick(SupervisorScheduleTasksViewId.GANTT)}
          fontSize="small"
          titleAccess={t("module.supervisor_schedule:gantt_toggle.gantt")}
        />
      )}
    </div>
  );
};
