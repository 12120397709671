import React, { ReactElement, useContext } from "react";
import { RiverDialog, RiverDialogButton, RiverSpinner } from "@river/common-ui";
import { IColumnFilter } from "../../../../interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../../river-data-grid";
import { ITablePreferences, IColumnLayout } from "../../../../interfaces";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { LayoutManagerGridHeader } from "./layout-manager-grid-header";
import { Column, SortColumn } from "react-data-grid";
import { userPreferencesService } from "../../../../services";
import { IUseEntity, useEntity } from "../../../../hooks";
import styles from "./layout-manager.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

interface ILayoutManager {
  open: boolean;
  onClose: () => void;
  onDeleteLayouts: (deletedLayoutNames: string[]) => void;
}

const columnLayoutEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "sap",
    entity_name: "column_layout",
  },
  attributes: [
    {
      _id: "attribute_name",
      adapter_type: "sap",
      entity_name: "column_layout",
      attribute_name: "name",
      data_type: Constants.data_type.string,
      description: "Layout Name",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
export const LayoutManager: React.FC<ILayoutManager> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const entityTable: IUseTable = tableContext?.table!;

  const fetchLayouts = async (
    props: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!entityTable.dbSaveKey) return [];

    let preferences: ITablePreferences =
      await userPreferencesService.getTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey: entityTable.dbSaveKey!,
      });
    let layouts: IColumnLayout[] = preferences?.layouts || [];

    // Don't display Default Layout
    const defaultLayoutIndex: number = layouts.findIndex(
      (layout) => layout.name === entityTable.DEFAULT_LAYOUT_NAME
    );
    layouts.splice(defaultLayoutIndex, 1);

    layouts = layouts.filter((layout) => {
      const filter: IColumnFilter = props.columnFilters.find(
        (filter) => filter.field === "name"
      )!;
      if (filter) {
        return (
          layout.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
        );
      }
      return true;
    });
    if (props.sortColumns.length) {
      const sortColumn: SortColumn = props.sortColumns.find(
        (sortColumn) => sortColumn.columnKey === "name"
      )!;
      if (sortColumn) {
        layouts.sort((la, lb) => {
          const a = la.name;
          const b = lb.name;
          if (a === b) return 0;
          if (sortColumn.direction === "ASC") {
            return a < b ? -1 : 1;
          } else {
            return a < b ? 1 : -1;
          }
        });
      }
    }
    return layouts as any as IEntityObject[];
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "name",
      name: t("shared.column_selector:label.layout_name"),
    },
  ];

  const entity: IUseEntity = useEntity({
    entityName: "column_layout",
    definition: columnLayoutEntityDef,
  });

  const layoutsTable: IUseTable = useTable({
    columns,
    fetchFunction: fetchLayouts,
    fetchOn: props.open,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const isLoading: boolean = layoutsTable.isLoading;

  const renderContent = (): ReactElement => (
    <>
      <TableContext.Provider value={{ table: layoutsTable, entity }}>
        <RiverSpinner show={isLoading} />
        <LayoutManagerGridHeader
          entityTable={entityTable}
          onDeleteLayouts={props.onDeleteLayouts}
        />
        <RiverDataGrid
          className={styles.grid}
          columns={layoutsTable.columns}
          rows={layoutsTable.entities}
          rowKeyGetter={(row) => row["name"]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={layoutsTable.sortColumns}
          onSortColumnsChange={(e) => {
            layoutsTable.setSortColumns(e);
          }}
        />
      </TableContext.Provider>
    </>
  );

  return (
    <>
      <RiverDialog
        title={t("shared.column_selector:dialog.column_layouts.title")}
        open={props.open}
        onClose={props.onClose}
        actionsContent={
          <>
            <RiverDialogButton
              text={t("common.button:close")}
              onClick={props.onClose}
            />
          </>
        }
        classes={{
          paper: clsx([styles.paper]),
          title: styles.title,
          content: styles.content,
          actions: styles.actions,
        }}
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
      >
        {renderContent()}
      </RiverDialog>
    </>
  );
};
