import { ReactElement } from "react";
import { TFunction } from "i18next";
import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import {
  CraftsUiService,
  ICraftSelectorProps,
  IUseCraftLookupProps,
} from "../crafts-ui.service";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers } from "../../helpers";
import { Column } from "react-data-grid";
import { OracleCloudCraftSelector } from "./oracle-cloud-shared/oracle-cloud-craft-selector";

export class OracleCloudCraftsUiService extends CraftsUiService {
  getCraftEntity = () => "resource";
  craftKeyGetter = (row: any): string => row["ResourceId"];

  getCraftLookupDefinition = (
    props: IUseCraftLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: props.t("shared.lookup:craft.title"),
      rowKeyGetter: this.craftKeyGetter,
      useTableProps: {
        columns: this.getCraftsSelectorColumns(
          props.t,
          props.RiverSelectColumn
        ),
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await props
            .adapterContext!.service.getAdapterService()
            .fetchCrafts(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        saveKey: this.getCraftsLookupTableSaveKey(),
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: this.getCraftEntity(),
      },
    };
  };
  getCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => [
    RiverSelectColumn,
    {
      key: "ResourceName",
      name: t("entity.resource:resource.ResourceName"),
    },
    {
      key: "ResourceDescription",
      name: t("entity.resource:resource.ResourceDescription"),
    },
    {
      key: "ResourceType",
      name: t("entity.resource:resource.ResourceType"),
    },
    {
      key: "UnitOfMeasure",
      name: t("entity.resource:resource.UnitOfMeasure"),
    },
    {
      key: "ResourceCode",
      name: t("entity.resource:resource.ResourceCode"),
    },
  ];

  getAssignedCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => this.getCraftsSelectorColumns(t, RiverSelectColumn);

  getCraftsUtilizationIds = (selectedCraftIds: string[]): number[] =>
    selectedCraftIds.map((id) => Number(id));

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <OracleCloudCraftSelector {...props} />
  );
}
