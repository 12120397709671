import { FC, ReactElement } from "react";
import { RiverTextInput, RiverDialog, RiverSpinner } from "@river/common-ui";
import { Box } from "@mui/material";
import { AdapterShiftDto, IAdapterShift } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useShiftForm } from "./use-shift-form";
import styles from "./shift-dialog.module.scss";
import { useTranslation } from "@river/common-ui";
import { RiverColorPicker } from "../../shared/river-color-picker";
import { DEFAULT_SHIFT_CARD_ICONS_LIST, ShiftCard } from "../shift-card";
import { RiverIconPicker } from "../../shared/river-icon-picker";

interface ShiftDialogProps {
  open: boolean;
  shift: IAdapterShift | null;
  onClose: (success: boolean) => void;
}

export const ShiftDialog: FC<ShiftDialogProps> = (props): ReactElement => {
  const { t } = useTranslation();

  const form: RiverFormInstance = useShiftForm({
    shift: props.shift,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });

  const { submit, resetForm, isCreate, isProcessing, setDto } = form;
  const formDto = form.dto as IAdapterShift;

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const renderStartHoursField = (): ReactElement => (
    <RiverTextInput
      id="start_hours"
      label={t("common.label:hours")}
      className={styles.timeField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 0,
          max: 23,
        },
      }}
    />
  );

  const renderStartMinutesField = (): ReactElement => (
    <RiverTextInput
      id="start_minutes"
      className={styles.timeField}
      label={t("common.label:minutes")}
      inputProps={{
        type: "number",
        inputProps: {
          min: 0,
          max: 59,
        },
      }}
    />
  );

  const renderEndHoursField = (): ReactElement => (
    <RiverTextInput
      id="end_hours"
      label={t("common.label:hours")}
      className={styles.timeField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 0,
          max: 23,
        },
      }}
    />
  );

  const renderEndMinutesField = (): ReactElement => (
    <RiverTextInput
      id="end_minutes"
      label={t("common.label:minutes")}
      className={styles.timeField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 0,
          max: 59,
        },
      }}
    />
  );

  const renderTimeFieldsDelimiter = (): ReactElement => (
    <div className={styles.hourMinuteDelimiter}>:</div>
  );

  const renderStartTimeFields = (): ReactElement => (
    <Box className={styles.timeBox}>
      <span className={styles.timeLabel}>
        {t("module.shifts:label.start_time")}
      </span>
      {renderStartHoursField()}
      {renderTimeFieldsDelimiter()}
      {renderStartMinutesField()}
    </Box>
  );

  const renderEndTimeFields = (): ReactElement => (
    <Box className={styles.timeBox}>
      <span className={styles.timeLabel}>
        {t("module.shifts:label.end_time")}
      </span>
      {renderEndHoursField()}
      {renderTimeFieldsDelimiter()}
      {renderEndMinutesField()}
    </Box>
  );

  const renderShiftTime = (): ReactElement => (
    <Box className={styles.shiftTime}>
      {renderStartTimeFields()}
      {renderEndTimeFields()}
    </Box>
  );

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.shifts:dialog.create_shift")
      : t("module.shifts:dialog.update_shift");

  const getActionButtonText = (): string =>
    isCreate ? t("common.button:create") : t("common.button:save");

  const updateShiftColor = (newOptions: { color: string }) => {
    setDto(
      Object.assign(new AdapterShiftDto(), {
        ...formDto,
        color: newOptions.color,
      }),
    );
  };

  const updateShiftIcon = (icon: string) => {
    setDto(
      Object.assign(new AdapterShiftDto(), {
        ...formDto,
        icon,
      }),
    );
  };

  const renderShiftColorPicker = () => {
    return (
      <RiverColorPicker
        title={t("module.shifts:dialog.color")}
        classes={{ previewContainer: styles.colorPickerPreview }}
        dialogTitle={t("module.shifts:dialog.color_picker_dialog.title")}
        initialColor={formDto.color}
        onSelectColor={(color: string) => updateShiftColor({ color })}
        renderPreview={(color: string) => (
          <ShiftCard
            shiftData={{ ...formDto, color, icon: formDto.icon }}
            editFlag={true}
          />
        )}
      />
    );
  };

  const renderShiftIconPicker = () => {
    return (
      <RiverIconPicker
        className={styles.iconPicker}
        title={t("module.shifts:dialog.icon")}
        dialogTitle={t("module.shifts:dialog.icon_picker_dialog.title")}
        iconList={DEFAULT_SHIFT_CARD_ICONS_LIST}
        initialIcon={formDto.icon}
        onSelect={(icon: string) => updateShiftIcon(icon)}
      />
    );
  };

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={close}
      actionButtonText={getActionButtonText()}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      <RiverTextInput id="shift" fullWidth inputProps={{ required: true }} />
      <Box className={styles.row}>
        {renderShiftIconPicker()}
        {renderShiftColorPicker()}
      </Box>
      {renderShiftTime()}
    </RiverDialog>
  );
};
