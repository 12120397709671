import { ChangeEvent, FC, ReactElement, useContext, useEffect } from "react";
import {
  RiverDialog,
  RiverFormSelect,
  RiverTextInput,
  IFieldValidationErrors,
  RiverDialogButton,
  RiverDialogActionButton,
  RiverAutocomplete,
  IRiverAutocompleteOption,
} from "@river/common-ui";
import { IndexDto, IIndex, IndexAttributeDto } from "@river/interfaces";
import { RiverFormInstance, PropertyChangeHandler } from "../../../hooks";
import { Constants } from "@river/constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import { useEntityIndexForm } from "./use-entity-index-form";
import { useTranslation, useNotification } from "@river/common-ui";
import { EntityDialogContext } from "../../../context";
import styles from "./entity-index-dialog.module.scss";
import clsx from "clsx";

const DEFAULT_INDEX_FIELD_ATTRIBUTE_SORT_TYPE: string =
  Constants.index_sort_type.ascending;

export interface IEntityIndexDialogProps {
  open: boolean;
  entityName: string;
  onClose: (success: boolean) => void;
  index?: IIndex | null;
}

export const EntityIndexDialog: FC<IEntityIndexDialogProps> = (props) => {
  const entityDialogContext = useContext(EntityDialogContext);
  const attributesTable = entityDialogContext?.attributesTable;
  const form: RiverFormInstance = useEntityIndexForm({
    entityName: props.entityName,
    index: props.index!,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
    onUpdate: () => {
      const success = true;
      closeDialog(success);
    },
  });
  const { t } = useTranslation();
  const notify = useNotification();
  const indexDto = form.dto as IndexDto;
  const {
    validationErrors,
    setValidationErrors,
    resetForm,
    setDto,
    getArrayPropertyChangeHandler,
    getArrayValidationErrors,
    submit,
  } = form;

  const handleSubmit = () => {
    try {
      if (!!indexDto.options) {
        JSON.parse(indexDto.options);
      }
      submit();
    } catch (e) {
      console.error(e);
      notify.error({
        message: t("module.data_dictionary:dialog.options_validation_message"),
      });
    }
  };

  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  const clearIndexFieldError = (fieldIndex: number): void => {
    const fieldErrors = validationErrors.fields["fields"]?.childrenByIndex;
    if (fieldErrors) {
      delete fieldErrors[fieldIndex];
      setValidationErrors(validationErrors);
    }
  };

  const deleteIndexField = async (fieldIndex: number): Promise<void> => {
    if (form.isReadOnly) return;
    const updated: IndexDto = Object.assign({}, indexDto);
    Object.setPrototypeOf(updated, Object.getPrototypeOf(indexDto));
    updated.fields.splice(fieldIndex, 1);
    clearIndexFieldError(fieldIndex);
    setDto(updated);
  };

  const getBlankIndexAttribute = (): IndexAttributeDto => {
    const dto: IndexAttributeDto = new IndexAttributeDto();
    Object.assign(dto, {
      attribute_name: "",
      sort_type: DEFAULT_INDEX_FIELD_ATTRIBUTE_SORT_TYPE,
    });
    return dto;
  };

  const addIndexField = (): void => {
    if (form.isReadOnly) return;
    const updated: IndexDto = Object.assign({}, indexDto);
    Object.setPrototypeOf(updated, Object.getPrototypeOf(indexDto));
    updated.fields.push(getBlankIndexAttribute());
    setDto(updated);
  };

  const renderIndexField = (
    field: IndexAttributeDto,
    fieldIndex: number
  ): ReactElement => {
    const onIndexFieldPropertyChange: PropertyChangeHandler =
      getArrayPropertyChangeHandler("fields", field, fieldIndex);
    const getIndexFieldValidationErrors = (
      indexField: string
    ): IFieldValidationErrors =>
      getArrayValidationErrors("fields", fieldIndex, indexField);

    const options: IRiverAutocompleteOption[] | undefined =
      attributesTable &&
      attributesTable.entities.map((attribute) => ({
        label: attribute.attribute_name as string,
        value: attribute.attribute_name as string,
      }));

    return (
      <div key={fieldIndex} className={styles.indexField}>
        {options && (
          <RiverAutocomplete
            disabled={form.isReadOnly}
            id="attribute_name"
            className={styles.attributeName}
            label={t(
              "module.data_dictionary:dialog.entity_index.attribute_name"
            )}
            noOptionsText={t(
              "shared.advanced_filters:label.no_available_columns"
            )}
            value={
              options.find((option) => option.value === field.attribute_name)!
            }
            onChange={(event, value) => {
              if (value) {
                field.attribute_name = (
                  value as IRiverAutocompleteOption
                ).value;

                onIndexFieldPropertyChange(
                  event as ChangeEvent<HTMLInputElement>
                );
              }
            }}
            options={options}
          />
        )}

        <RiverFormSelect
          id="sort_type"
          className={styles.sortType}
          label={t("module.data_dictionary:dialog.entity_index.sort_type")}
          items={Object.values(Constants.index_sort_type).map((value) => {
            return { text: value, value };
          })}
          onChangeEvent={(event) => {
            onIndexFieldPropertyChange(
              event as React.ChangeEvent<HTMLInputElement>
            );
          }}
          value={field.sort_type}
          validationErrors={getIndexFieldValidationErrors("sort_type")}
          disabled={form.isReadOnly}
        />
        {!form.isReadOnly && (
          <CancelIcon
            className={clsx([styles.deleteFieldIcon])}
            onClick={() => {
              deleteIndexField(fieldIndex);
            }}
          />
        )}
      </div>
    );
  };

  const dialogTitle = !form.isReadOnly
    ? props.index
      ? t("module.data_dictionary:label.edit_index")
      : t("module.data_dictionary:label.create_index")
    : t("module.data_dictionary:label.index_details");
  const actionButtonText = props.index
    ? t("common.button:save")
    : t("common.button:create");

  const renderIndexFields = (): ReactElement => (
    <div className={styles.indexFieldsSection}>
      <div className={styles.sectionTitle}>
        {t("module.data_dictionary:label.index_fields")}
      </div>
      <div className={styles.indexFields}>
        {indexDto.fields.map((field, fieldIndex) => {
          return renderIndexField(field, fieldIndex);
        })}
      </div>
      {!form.isReadOnly && (
        <Button
          variant="text"
          color="primary"
          className={styles.addFieldButton}
          startIcon={<AddCircleIcon />}
          onClick={addIndexField}
        >
          {t("module.data_dictionary:label.add_field")}
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    entityDialogContext?.attributesTable.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      onSubmit={handleSubmit}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={() => {
              const success = false;
              closeDialog(success);
            }}
            text={t("common.button:close")}
          />
          {!form.isReadOnly && (
            <RiverDialogActionButton
              onClick={handleSubmit}
              text={actionButtonText}
            />
          )}
        </>
      }
      showActionsDivider={false}
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      {props.open && (
        <>
          <RiverTextInput id="index_name" />
          <RiverFormSelect
            id="index_type"
            items={Object.values(Constants.index_type).map((value) => {
              return { text: value, value };
            })}
          />
          {renderIndexFields()}
          <RiverTextInput id="options" />
        </>
      )}
    </RiverDialog>
  );
};
