import { FC, useContext } from "react";
import {
  GridHeader,
  GridActions,
  FilterDropdown,
  DeleteAction,
} from "../../../shared";
import {
  PrimaryButton,
  useNotification,
  useSimpleDialog,
  useTranslation,
} from "@river/common-ui";
import { ModuleKey } from "../../../sidebar-menu";
import { ModuleActionCustomCronTasks } from "../custom-cron-tasks";
import ProtectedAction from "../../../protected-action";
import {
  AdapterUiContext,
  CustomCronTasksContext,
  TableContext,
} from "../../../../context";

export const CustomCronTasksGridHeader: FC = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const customCronTasksContext = useContext(CustomCronTasksContext);
  const tableContext = useContext(TableContext);
  const adapterContext = useContext(AdapterUiContext);
  const table = tableContext?.table;

  const handleOnConfirmDeleteAction = async () => {
    if (table?.selectedRowIds) {
      try {
        const row = table.entities.find(
          (entity) => entity._id === Array.from(table.selectedRowIds)[0]
        );
        const task_code = row!.task_code as string;
        if (task_code) {
          await adapterContext!.service
            .getAdapterService()
            .deleteCustomCronTask(task_code);
          tableContext?.table.refresh();
        }
      } catch (message) {
        notify.error({ message });
      } finally {
        deleteConfirmationDialog.close();
      }
    }
  };

  const deleteConfirmationDialog = useSimpleDialog({
    title: t("module.cron:dialog.custom_cron.delete_cron"),
    message: t("module.cron:dialog.custom_cron.confirm_cron_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: handleOnConfirmDeleteAction,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <span>{t("module.cron:custom_cron_tasks.title")}</span>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.CRON_TASKS}
            action={ModuleActionCustomCronTasks.CREATE_CUSTOM_CRON_TASKS}
          >
            <PrimaryButton
              onClick={() => customCronTasksContext?.setOpenDialog(true)}
              text={t("module.cron:dialog.custom_cron.create_cron")}
            />
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.CRON_TASKS}
            action={ModuleActionCustomCronTasks.DELETE_CUSTOM_CRON_TASKS}
          >
            <DeleteAction onClick={() => deleteConfirmationDialog.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteConfirmationDialog.render()}
    </>
  );
};
