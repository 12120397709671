import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from "clsx";
import { Typography, IconButton, Button, Divider, Drawer, } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { RiverFormContext } from "../../context";
import { useEnv } from "../../hooks";
import styles from "./river-modal-drawer.module.scss";
const DEFAULT_SHOW_XCLOSE = true;
const DEFAULT_SHOW_ACTIONS_DIVIDER = true;
const DEFAULT_SHOW_TITLE_DIVIDER = false;
const DEFAULT_CLOSE_ON_BACKDROP_CLICK = false;
const DEFAULT_ANCHOR = "right";
export const RiverModalDrawerButton = (props) => {
    return (_jsx(Button, Object.assign({ classes: { root: clsx([styles.button, styles.dimmed]) } }, props, { children: props.children || props.text })));
};
export const RiverModalDrawerActionButton = (props) => (_jsx(Button, Object.assign({ classes: { root: clsx([styles.button, styles.action]) }, variant: "contained", color: "primary" }, props, { children: props.children || props.text })));
export const RiverModalDrawer = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation("common.button");
    const DEFAULT_CLOSE_BUTTON_TEXT = t("common.button:cancel", {
        defaultValue: "Cancel",
    });
    const env = useEnv();
    const { isMobile } = env;
    const showXClose = (_a = props.showXClose) !== null && _a !== void 0 ? _a : DEFAULT_SHOW_XCLOSE;
    const showActionsDivider = (_b = props.showActionsDivider) !== null && _b !== void 0 ? _b : DEFAULT_SHOW_ACTIONS_DIVIDER;
    const showTitleDivider = (_c = props.showTitleDivider) !== null && _c !== void 0 ? _c : DEFAULT_SHOW_TITLE_DIVIDER;
    const closeOnBackdropClick = (_d = props.closeOnBackdropClick) !== null && _d !== void 0 ? _d : DEFAULT_CLOSE_ON_BACKDROP_CLICK;
    const anchor = (_f = (_e = props.drawerProps) === null || _e === void 0 ? void 0 : _e.anchor) !== null && _f !== void 0 ? _f : DEFAULT_ANCHOR;
    const drawerProps = Object.assign(Object.assign({}, props.drawerProps), { anchor });
    const handleClose = () => {
        props.onClose();
    };
    const handleSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };
    const onClose = (event, reason) => {
        if (reason === "backdropClick") {
            closeOnBackdropClick && handleClose();
        }
        else if (reason === "escapeKeyDown") {
            handleClose();
            setTimeout(() => {
                var _a;
                (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.blur();
            }, 0);
        }
    };
    const renderCloseButton = () => (_jsx(RiverModalDrawerButton, { onClick: handleClose, text: props.closeButtonText || DEFAULT_CLOSE_BUTTON_TEXT }));
    const renderActionButton = () => (_jsx(RiverModalDrawerActionButton, { onClick: handleSubmit, text: props.actionButtonText }));
    const renderDrawerTitle = () => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, { component: (e) => _jsx("div", Object.assign({}, e)), classes: {
                        root: clsx([styles.drawerTitle, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.title]),
                    }, children: [(_b = props === null || props === void 0 ? void 0 : props.titleContent) !== null && _b !== void 0 ? _b : (_jsx(Typography, { classes: { root: styles.title }, children: props.title })), showXClose && (_jsx(IconButton, { classes: { root: styles.closeButton }, onClick: () => {
                                handleClose();
                            }, size: "large", children: _jsx(CloseIcon, { fontSize: "inherit" }) }))] }), showTitleDivider && _jsx(Divider, { className: styles.titleDivider })] }));
    };
    const renderDrawerContent = () => {
        var _a;
        return (_jsxs(DialogContent, { classes: { root: clsx([styles.drawerContent, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.content]) }, children: [props.form && (_jsx(RiverFormContext.Provider, { value: { form: props.form }, children: props.children })), !props.form && props.children] }));
    };
    const renderDrawerActions = () => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [showActionsDivider && _jsx(Divider, {}), _jsx(DialogActions, { classes: { root: clsx([styles.drawerActions, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.actions]) }, children: props.actionsContent || (_jsxs(_Fragment, { children: [renderCloseButton(), !((_b = props.form) === null || _b === void 0 ? void 0 : _b.isReadOnly) && renderActionButton()] })) })] }));
    };
    return (_jsxs(Drawer, Object.assign({ open: props.open, onClose: onClose, classes: {
            paper: clsx([
                styles.paper,
                (_g = props.classes) === null || _g === void 0 ? void 0 : _g.paper,
                { [styles.mobile]: isMobile },
            ]),
        } }, drawerProps, { children: [renderDrawerTitle(), renderDrawerContent(), renderDrawerActions()] })));
};
