import React, { ReactElement, useContext } from "react";
import {
  DayPeriodDateText,
  frequencyDefsByGanttTimeOptionGroupID,
  HourPeriodDateText,
  IFrequencyDef,
  IPeriod,
} from "../../../supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { SupervisorScheduleContext } from "../../../../../context";
import {
  ISupervisorScheduleGanttTimeOption,
  SupervisorScheduleGanttTimeOptionGroupID,
} from "../../../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import styles from "./supervisor-utilization-gantt-header-period.module.scss";
import clsx from "clsx";

interface ISupervisorUtilizationGanttHeaderPeriodProps {
  period: IPeriod;
}

const ganttTimeOptionClassMap: {
  [key in SupervisorScheduleGanttTimeOptionGroupID]: string;
} = {
  [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: styles.hourPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: styles.dayPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: styles.weekPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: styles.monthPeriod,
};

export const SupervisorUtilizationGanttHeaderPeriod: React.FC<
  ISupervisorUtilizationGanttHeaderPeriodProps
> = (props): ReactElement => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const ganttTimeOption: ISupervisorScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;
  const periodFrequencyDef: IFrequencyDef =
    frequencyDefsByGanttTimeOptionGroupID[ganttTimeOption.groupId];

  const { period } = props;

  const periodGroupClass: string =
    ganttTimeOptionClassMap[ganttTimeOption.groupId];

  const renderHourPeriod = (): ReactElement => {
    const periodText: HourPeriodDateText = JSON.parse(period.dateText);
    return (
      <>
        <div
          className={clsx([styles.element, styles.date])}
          onClick={() => {
            scheduleContext?.scheduleFn(period.startDate);
          }}
        >
          {periodText.date}
        </div>
        <div
          className={clsx([styles.element, styles.time])}
          onClick={() => {
            scheduleContext?.scheduleFn(period.startDate);
          }}
        >
          {periodText.time}
        </div>
      </>
    );
  };

  const renderDayPeriod = (): ReactElement => {
    const periodText: DayPeriodDateText = JSON.parse(period.dateText);
    return (
      <>
        <div className={clsx([styles.element, styles.day])}>
          {periodText.day}
        </div>
        <div className={clsx([styles.element, styles.date])}>
          {periodText.date}
        </div>
        {renderLegend()}
      </>
    );
  };

  const renderDefaultPeriod = (): ReactElement => (
    <>
      <div className={clsx([styles.element, styles.date])}>
        {period.dateText}
      </div>
      {renderLegend()}
    </>
  );

  const renderLegend = (): ReactElement => (
    <div className={clsx([styles.element, styles.legend])}>
      <div className={styles.segment}>Sch</div>
      <div className={styles.splitter}>/</div>
      <div className={styles.segment}>Avail</div>
    </div>
  );

  const renderPeriod = (): ReactElement => {
    let timeElement: ReactElement = <></>;
    const timeGroup: SupervisorScheduleGanttTimeOptionGroupID =
      ganttTimeOption?.groupId!;
    if (timeGroup === SupervisorScheduleGanttTimeOptionGroupID.HOURS) {
      timeElement = renderHourPeriod();
    } else if (timeGroup === SupervisorScheduleGanttTimeOptionGroupID.DAYS) {
      timeElement = renderDayPeriod();
    } else {
      timeElement = renderDefaultPeriod();
    }
    return <>{timeElement}</>;
  };

  return (
    <div
      className={clsx([styles.root, periodGroupClass])}
      style={{
        minWidth: `${periodFrequencyDef.minPeriodSize}px`,
      }}
    >
      <>{renderPeriod()}</>
    </div>
  );
};
