import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useNotification } from "@river/common-ui";
import { IAdapterFolder } from "@river/interfaces";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  ScheduleContext,
  ScheduleUtilizationContext,
  TableContext,
  TabContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
  IRiverDrawerContext,
  RiverDrawerContext,
} from "../../../context";
import {
  ScheduleUtilizationUiService,
  ScheduleUtilizationTabId,
  userPreferencesService,
  ScheduleUtilizationTabURLParamName,
  IScheduleProgressFilters,
  IScheduleComplianceFilters,
} from "../../../services";
import { ScheduleUtilizationGridHeader } from "./schedule-utilization-grid-header";
import { ScheduleUtilizationTables } from "./schedule-utilization-tables";
import { ScheduleBacklog } from "./schedule-backlog";
import { useNavigate, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { helpers } from "../../../helpers";
import { UtilizationValidationRuleTiles } from "./utilization-validation-rule-tiles";
import { ScheduleProgress } from "./schedule-progress";
import { SchedulingCompliance } from "./scheduling-compliance";
import styles from "./schedule-utilization.module.scss";
import clsx from "clsx";

interface IScheduleUtilization {
  className?: string;
}

export const ScheduleUtilization: React.FC<IScheduleUtilization> = (
  props
): ReactElement => {
  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const notify = useNotification();
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const tab: ScheduleUtilizationTabId = uiService.getCurrentTab()();

  const drawerContext: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { drawerOpened, closeDrawer } = drawerContext.drawer;

  const scheduleContext = useContext(ScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;

  const currentBaseline = scheduleContext?.selectedBaseline;

  const [selectedCraftIds, setSelectedCraftIds] = useState<Set<string>>(
    new Set()
  );
  const [selectedResourceIds, setSelectedResourceIds] = useState<Set<string>>(
    new Set()
  );
  const [selectedCrewIds, setSelectedCrewIds] = useState<Set<string>>(
    new Set()
  );

  const [progressFiltersOpened, setProgressFiltersOpened] =
    useState<boolean>(false);

  const [complianceFiltersOpened, setComplianceFiltersOpened] =
    useState<boolean>(false);

  const blankProgressSummaryOptions = (): IScheduleProgressFilters =>
    uiService.getBlankProgressFilters();
  const [progressSummaryFilters, setProgressSummaryFilters] =
    useState<IScheduleProgressFilters>(blankProgressSummaryOptions());

  const blankComplianceOptions = (): IScheduleComplianceFilters =>
    uiService.getBlankComplianceFilters();
  const [complianceSummaryFilters, setComplianceSummaryFilters] =
    useState<IScheduleComplianceFilters>(blankComplianceOptions());
  const loadSelectedCraftIds = async () => {
    try {
      const craftIds: string[] =
        await userPreferencesService.getUtilizationCrafts(
          adapterContext!.service.getAdapterService()
        );
      setSelectedCraftIds(new Set(craftIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadSelectedResourceIds = async () => {
    try {
      const resourceIds: string[] =
        await userPreferencesService.getUtilizationPeople(
          adapterContext!.service.getAdapterService()
        );
      setSelectedResourceIds(new Set(resourceIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadSelectedCrewIds = async () => {
    try {
      const crewIds: string[] =
        await userPreferencesService.getUtilizationCrews(
          adapterContext!.service.getAdapterService()
        );
      setSelectedCrewIds(new Set(crewIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    loadSelectedCraftIds();
    loadSelectedResourceIds();
    loadSelectedCrewIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { entity: currentEntity, table: currentTable } =
    uiService.useCurrentTable({
      selectedCraftIds,
      selectedResourceIds,
      selectedCrewIds,
      currentBaseline,
      progressSummaryFilters,
      complianceSummaryFilters,
    })();
  const currentEntityName =
    currentEntity?.entityDefinition?.entity.entity_name || "workorder";

  useEffect(() => {
    if (!currentTable?.useAdvancedFilters && drawerOpened) {
      closeDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable?.saveKey]);

  useEffect(() => {
    scheduleContext?.setScheduleUtilizationCurrentTableState(currentTable!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTable?.saveKey,
    currentTable?.selectedRowIds,
    currentTable?.entities,
    site,
    currentSchedule,
  ]);

  if (!currentSchedule || !currentTable) {
    return <></>;
  }

  return (
    <ScheduleUtilizationContext.Provider
      value={{
        progressSummaryFilters,
        setProgressSummaryFilters,
        complianceSummaryFilters,
        setComplianceSummaryFilters,
        progressFiltersOpened,
        setProgressFiltersOpened,
        complianceFiltersOpened,
        setComplianceFiltersOpened,
      }}
    >
      <TableContext.Provider
        value={{
          entityName: currentEntityName,
          table: currentTable,
          entity: currentEntity!,
        }}
      >
        <TabContext.Provider
          value={{
            selectedTab: tab,
            setSelectedTab: (tabId: string) => {
              navigate(
                helpers.replaceOrSetUrlParam(
                  location.pathname,
                  params,
                  ScheduleUtilizationTabURLParamName,
                  tabId
                )
              );
            },
          }}
        >
          <div className={clsx([styles.root, props.className])}>
            <ScheduleUtilizationGridHeader
              selectedCraftIds={selectedCraftIds}
              onChangeSelectedCrafts={setSelectedCraftIds}
              selectedResourceIds={selectedResourceIds}
              selectedCrewIds={selectedCrewIds}
              onChangeSelectedResources={setSelectedResourceIds}
              onChangeSelectedCrews={setSelectedCrewIds}
            />
            {tab === ScheduleUtilizationTabId.BACKLOG && (
              <ScheduleBacklog selectedCraftIds={selectedCraftIds} />
            )}
            {[
              ScheduleUtilizationTabId.CRAFTS,
              ScheduleUtilizationTabId.PERSONS,
              ScheduleUtilizationTabId.CREWS,
            ].includes(tab) && <ScheduleUtilizationTables />}
            {tab === ScheduleUtilizationTabId.VALIDATION_RULES && (
              <UtilizationValidationRuleTiles />
            )}
            {tab === ScheduleUtilizationTabId.PROGRESS && <ScheduleProgress />}
            {tab === ScheduleUtilizationTabId.COMPLIANCE && (
              <SchedulingCompliance />
            )}
          </div>
        </TabContext.Provider>
      </TableContext.Provider>
    </ScheduleUtilizationContext.Provider>
  );
};
