import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSpinner,
  RiverPasswordInput,
  RiverTextInput,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { FC, useEffect, useContext, useState } from "react";
import { Box } from "@mui/material";
import styles from "./cron-authentication-dialog.module.scss";
import { CronTaskAuthDto } from "@river/interfaces";
import { AdapterUiContext } from "../../../context";
import { useCronAuthenticationDialogForm } from "./use-cron-authentication-dialog-form";

interface ICronAuthenticationDialogProps {
  onClose(): void;
  open: boolean;
}

export const CronAuthenticationDialog: FC<ICronAuthenticationDialogProps> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const adapterContext = useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(true);

  const handleCloseAndRefresh = () => {
    resetForm();
    onClose();
  };

  const form = useCronAuthenticationDialogForm({
    cronAuth: null,
    onUpdate: handleCloseAndRefresh,
    onCreate: handleCloseAndRefresh,
  });
  const { submit, isProcessing, setDto, dto, resetForm } = form;

  const handleSetDto = (values: CronTaskAuthDto) => {
    setDto(values);
  };

  const handleGetCronTaskAuthForEnvironment = async () => {
    try {
      const data = await adapterContext!.service
        .getAdapterService()
        .getCronTaskAuthForEnvironment();

      if (!!data) {
        handleSetDto(data);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCronTaskAuthForEnvironment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = dto as CronTaskAuthDto;

  return (
    <RiverDialog
      classes={{
        paper: styles.paper,
      }}
      form={form}
      title={t("module.cron:cron_authentication.edit")}
      open={open}
      actionButtonText={t("common.button:submit")}
      closeButtonText={t("common.button:save")}
      onSubmit={submit}
      onClose={handleCloseAndRefresh}
      showActionsDivider={true}
      dialogProps={{
        maxWidth: false,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={handleCloseAndRefresh}
            text={t("common.button:close")}
          />
          <RiverDialogActionButton
            onClick={submit}
            disabled={!formData.username || !formData.password}
            text={t("common.button:save")}
          />
        </>
      }
    >
      <RiverSpinner show={isProcessing || loading} />
      <Box className={styles.root}>
        {!loading && (
          <Box className={styles.row}>
            <RiverTextInput
              fullWidth={true}
              id="username"
              label={t("module.cron:cron_authentication.username")}
              className={styles.usernameField}
              inputProps={{
                required: true,
              }}
            />

            <RiverPasswordInput
              fullWidth={true}
              id="password"
              onChangeEvent={(event) => {
                setDto({
                  ...formData,
                  password: event.currentTarget.value,
                });
              }}
              value={formData.password}
              inputProps={{
                required: true,
              }}
              label={t("module.cron:cron_authentication.password")}
            />
          </Box>
        )}
      </Box>
    </RiverDialog>
  );
};
