import React, { ReactElement, useContext } from "react";
import {
  RiverSpinner,
  RiverDialog,
  RiverDialogButton,
  RiverDialogActionButton,
} from "@river/common-ui";
import { AdapterUserDto, IAdapterUser, IEntityObject } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../../shared";
import { Column } from "react-data-grid";
import { IUseEntity, RiverFormInstance, useEntity } from "../../../hooks";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../context";
import { fetchHelpers, uiConstants } from "../../../helpers";
import { useTranslation } from "@river/common-ui";
import { UserRolesUiService } from "../../../services";
import { UserRolesDialogGridHeader } from "./user-roles-dialog-grid-header";
import styles from "./user-roles-dialog.module.scss";
import clsx from "clsx";

interface IUserRolesDialogProps {
  userRole: AdapterUserDto | null;
  open: boolean;
  onClose: (requiresRefresh: boolean) => void;
  onSubmit: () => void;
}

export const UserRolesDialog: React.FC<IUserRolesDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const userRolesUiService: UserRolesUiService =
    adapterContext?.service!.getUserRolesUiService()!;

  const roleEntity: IUseEntity = useEntity({
    entityName: "role",
  });

  const roleColumns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "role",
      name: t("entity.role:role.role"),
    },
    { key: "description", name: t("entity.role:role.description") },
    { key: "created_at", name: t("entity.role:role.created_at") },
    { key: "updated_at", name: t("entity.role:role.updated_at") },
  ];

  const fetchAvailableRoles = async (fetchProps: ITableFetchFunctionProps) => {
    let rolesFromService = await adapterContext!.service
      .getAdapterService()
      .fetchRoles(fetchHelpers.getTableQuery({ fetchProps }));

    const rolesList = props.userRole?.roles;
    const matchingIds = rolesFromService
      .filter((obj) => rolesList?.includes(obj.role))
      .map((obj) => obj._id);

    rolesTable.setSelectedRowIds(new Set<any>(matchingIds));

    return rolesFromService.map((x) => {
      return { ...x } as IEntityObject;
    });
  };

  const rolesTable: IUseTable = useTable({
    columns: roleColumns,
    fetchFunction: fetchAvailableRoles,
    rowKeyGetter: (row) => row[uiConstants.fields._id] as string,
    fetchOn: props.open,
    onChangeSelectedRowIds: () => updateSelectedRoles(),
    useAdvancedFilters: false,
  });

  const form: RiverFormInstance = userRolesUiService.getUserRolesForm({
    userRole: props.userRole,
    onUpdate: () => {
      const requiresRefresh = true;
      closeDialog(requiresRefresh);
    },
  })();
  const { setDto } = form;
  const dto: IAdapterUser = form.dto as IAdapterUser;

  const updateSelectedRoles = (): void => {
    const roles: string[] = rolesTable
      .getSelectedRows()
      .map((role) => role.role) as string[];
    const newDto: IAdapterUser = {
      ...dto,
      roles,
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
  };

  const resetDialogState = (): void => {
    rolesTable.setSelectedRowIds(new Set());
    form.resetForm();
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetDialogState();
    props.onClose(requiresRefresh);
  };

  const onClose = () => {
    const requiresRefresh = false;
    closeDialog(requiresRefresh);
  };

  const onSubmit = () => {
    form.submit();
  };

  const isLoading: boolean = rolesTable.isLoading || form.isProcessing;

  return (
    <>
      <RiverDialog
        title={t("module.users:dialog.role.title")}
        open={props.open}
        closeButtonText={t("common.button:close")}
        actionButtonText={t("common.button:add")}
        onClose={onClose}
        classes={{
          paper: styles.paper,
          content: styles.content,
        }}
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
        actionsContent={
          <>
            <RiverDialogButton
              onClick={onClose}
              text={t("common.button:close")}
            />
            <RiverDialogActionButton
              onClick={onSubmit}
              text={t("common.button:add")}
              disabled={rolesTable.selectedRowIds.size === 0}
            />
          </>
        }
      >
        <RiverSpinner show={isLoading} />
        {form.render()}
        <TableContext.Provider
          value={{
            table: rolesTable,
            entity: roleEntity,
          }}
        >
          <UserRolesDialogGridHeader className={styles.rolesGridHeader} />
          <RiverDataGrid
            columns={rolesTable.columns}
            rows={rolesTable.entities}
            rowKeyGetter={rolesTable.rowKeyGetter}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            sortColumns={rolesTable.sortColumns}
            onSortColumnsChange={(e) => {
              rolesTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </RiverDialog>
    </>
  );
};
