import {
  useCreateBaselineAction,
  useCommitScheduleAction,
  useAutoScheduleAction,
} from "./";

export const useScheduleActions = () => {
  const { renderCreateBaselineAction, createBaselineAction } =
    useCreateBaselineAction();
  const { renderCommitScheduleAction } = useCommitScheduleAction();
  const { renderAutoScheduleAction, autoScheduleAction } =
    useAutoScheduleAction();

  return {
    renderCreateBaselineAction,
    createBaselineAction,
    renderCommitScheduleAction,
    renderAutoScheduleAction,
    autoScheduleAction,
  };
};

export type IUseSubHeaderActions = ReturnType<typeof useScheduleActions>;
