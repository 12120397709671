import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  RefObject,
  ReactElement,
} from "react";
import {
  CalculatedColumn,
  Column,
  DataGridHandle,
  FormatterProps,
  HeaderRendererProps,
} from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  useRiverSelectColumn,
  RiverIconButton,
  IRiverIconButtonProps,
} from "../shared";
import { AvailabilityHeader } from "./availability-header";
import { AvailabilityGridHeader } from "./availability-grid-header";
import { AdapterAvailabilityUpdateDto, IAdapterShift } from "@river/interfaces";
import { fetchHelpers, uiConstants } from "../../helpers";
import { useEntity, usePageCleanup, useTableCellRenderers } from "../../hooks";
import {
  GenerateAvailabilityDialog,
  useAsyncGenerateAvailability,
} from "./generate-availability-dialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box } from "@mui/material";

import {
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  AdapterUiContext,
  AvailabilityContext,
} from "../../context";
import { addDays } from "date-fns";
import {
  RiverSpinner,
  useNotification,
  useSimpleDialog,
  useTranslation,
} from "@river/common-ui";
import { commonModuleActions } from "../../hooks";
import styles from "./availability.module.scss";
import clsx from "clsx";
import SplitterLayout from "react-splitter-layout";
import { userPreferencesService } from "../../services";
import { ShiftCard } from "../shifts/shift-card";
import { AvailabilityScheduleForDialog } from "./availability-schedule-for-dialog";
import { AvailabilitySubHeader } from "./availability-subheader";

const DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE = 36.2;
const AVAILABILITY_SHIFT_GRID_NUMBER_OF_COLUMNS = 7;

enum ModuleAction {
  APPLY_CALENDAR = "APPLY_CALENDAR",
  SYNC_RESOURCES = "SYNC_RESOURCES",
  UPDATE_AVAILABILITY = "UPDATE_AVAILABILITY",
  CREATE_RESOURCE = "CREATE_RESOURCE",
  DELETE_RESOURCE = "DELETE_RESOURCE",
}

export type AvailabilityAction =
  | ModuleAction
  | commonModuleActions.GlobalFilterAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailabilityAction = {
  ...ModuleAction,
  ...commonModuleActions.GlobalFilterAction,
};

export const Availability: FC = () => {
  usePageCleanup();
  const calendarTableRef = useRef<DataGridHandle>(null);
  const entityTableRef = useRef<DataGridHandle>(null);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [splitterRef, setSplitterRef] = useState<RefObject<HTMLDivElement>>();
  const [calendarScrollAreaRef, setCalendarScrollAreaRef] =
    useState<RefObject<HTMLDivElement>>();
  const [splitterTransitionActive, setSplitterTransitionActive] =
    useState<boolean>(true);
  const [splitterWidth, setSplitterWidth] = useState<number | null>(null);
  const splitterContainerRef = useRef<HTMLDivElement>(null);
  const notify = useNotification();
  const entityName: string = "availability_header";

  const [availabilityObjectToUpdate, setAvailabilityObjectToUpdate] =
    useState<AdapterAvailabilityUpdateDto | null>(null);
  const [
    availabilityScheduleForDialogOpened,
    setAvailabilityScheduleForDialogOpened,
  ] = useState<boolean>(false);

  const [availabilityDialogOpened, setAvailabilityDialogOpened] =
    useState<boolean>(false);
  const userContext = useContext(AdapterUserContext);
  const adapterContext = useContext(AdapterUiContext);
  const uiService = adapterContext?.service.getAvailabilityUiService()!;

  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const site = userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity = useEntity({
    entityName,
  });
  const startDateRef = useRef<Date>(new Date());
  const shiftsRef = useRef<IAdapterShift[]>([]);

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  function handleReset() {
    table.setSelectedRowIds(new Set());
  }

  async function deleteSelectedAvailability(
    formatterProps: FormatterProps<any>
  ) {
    const { row, column } = formatterProps;
    const availabilityDate: Date = getColumnDate(column.idx);
    try {
      await adapterContext!.service.getAdapterService().updateAvailability({
        shift_id: null,
        start_date: availabilityDate,
        end_date: availabilityDate,
        available_hours: 0,
        availability_header_id: row._id,
      });
      deleteAvailabilityConfirmation.close();
      handleReset();
      table.refresh();
    } catch (message) {
      notify.error({ message });
    }
  }

  const deleteAvailabilityConfirmation = useSimpleDialog({
    title: t("module.availability:dialog.delete_availability"),
    message: t("module.availability:message.confirm_availability_deletion"),
    confirmButtonText: t("common.button:delete"),
    onDecline: handleReset,
  });

  const fetchShifts = async () => {
    shiftsRef.current = await adapterContext!.service
      .getAdapterService()
      .fetchShifts();
    forceUpdate();
  };

  useEffect(() => {
    const setInitialLeftPaneWidth = async () => {
      try {
        const preferenceWidth =
          await userPreferencesService.getVerticalAvailabilitySplitterPosition(
            adapterContext!.service.getAdapterService()
          );
        const initialWidth =
          preferenceWidth ?? DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE;
        setSplitterWidth(initialWidth);
      } catch (message) {
        notify.error({ message });
      }
    };

    setInitialLeftPaneWidth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (site) {
      fetchShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const getShift = (shiftId: string) => {
    return shiftsRef.current.filter((shift) => shift._id === shiftId)[0];
  };

  const formatHeaderDate = (index: number) => {
    const date: Date = addDays(startDateRef.current, index);
    return new Intl.DateTimeFormat(navigator.language, {
      timeZone: "UTC",
      month: "short",
      day: "2-digit",
    }).format(date);
  };

  const formatHeaderDayName = (index: number) => {
    const date: Date = addDays(startDateRef.current, index);
    return new Intl.DateTimeFormat(navigator.language, {
      timeZone: "UTC",
      weekday: "short",
    }).format(date);
  };

  const checkIsToday = (index: number) => {
    const date: Date = addDays(startDateRef.current, index);
    return date.toDateString() === new Date().toDateString();
  };

  const AvailabilityColumnHeaderRenderer = (
    headerRendererProps: HeaderRendererProps<any, any>
  ): ReactElement => {
    const { column } = headerRendererProps;
    const { idx } = column;
    return (
      <div className={styles.date}>
        <span className={styles.dateMonthValue}>{formatHeaderDate(idx)}</span>
        <span className={styles.dateDayShortName}>
          {formatHeaderDayName(idx)}
          {checkIsToday(idx) && <span className={styles.today}></span>}
        </span>
      </div>
    );
  };

  const AvailabilityColumnFormatter = (
    formatterProps: FormatterProps<any, any>
  ): ReactElement => {
    const { column } = formatterProps;
    const { idx: index } = column;
    const columnDateAsString = getCompareDateFormat(getColumnDate(index));

    let data = formatterProps.row.availability.find(
      (a: any) =>
        getCompareDateFormat(new Date(a.availability_date)) ===
        columnDateAsString
    );
    let shiftData: IAdapterShift | null = null;
    let shiftCardActions: IRiverIconButtonProps[] = [];
    if (data && data.shift_id) {
      shiftData = getShift(data.shift_id);
      const availableHours = data.available_hours ?? data.duration_hours;
      shiftData = {
        ...shiftData,
        availableHours:
          availableHours !== undefined && availableHours !== null
            ? String(availableHours)
            : "0",
      };

      shiftCardActions = [
        {
          label: "",
          icon: EditOutlinedIcon,
          onClick: () => handleOpenScheduleForDialog(formatterProps),
        },
        {
          label: "",
          icon: DeleteOutlineIcon,
          onClick: () => {
            handleOpenDeleteConfirmation(formatterProps);
          },
        },
      ];
    }
    return (
      <>
        {renderCell({
          riverCellPaddingDisabled: true,
          formatterProps,
          content: (
            <>
              {shiftData && (
                <Box className={styles.calendarCellWithShift}>
                  <ShiftCard actions={shiftCardActions} shiftData={shiftData} />
                </Box>
              )}
              {!shiftData && (
                <Box className={styles.emptyBoxContainer}>
                  <RiverIconButton
                    className={styles.editIconButton}
                    icon={EditOutlinedIcon}
                    label=""
                    onClick={() => handleOpenScheduleForDialog(formatterProps)}
                  />
                </Box>
              )}
            </>
          ),
        })}
      </>
    );
  };

  const generateAvailabilityColumn = (index: number): Column<any> => {
    return {
      key: `${index}`,
      name: "",
      width: `${100 / AVAILABILITY_SHIFT_GRID_NUMBER_OF_COLUMNS}%`,
      minWidth: 160,
      headerCellClass: styles.availabilityHeaderCell,
      cellClass: () => {
        const rowDate: Date = addDays(startDateRef.current, index);
        return [6, 0].includes(rowDate.getDay()) ? styles.weekendCell : "";
      },
      headerRenderer: AvailabilityColumnHeaderRenderer,
      formatter: AvailabilityColumnFormatter,
    };
  };

  const onCalendarVerticalScroll = (event: React.UIEvent<HTMLDivElement>) => {
    entityTableRef?.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
    calendarScrollAreaRef?.current?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const saveSplitterWidth = async (
    leftPaneEl: HTMLDivElement
  ): Promise<void> => {
    try {
      const widthPercentage = calculateSplitterWidthPercentage(leftPaneEl);
      await userPreferencesService.setVerticalAvailabilitySplitterPosition(
        adapterContext!.service.getAdapterService(),
        widthPercentage
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const onTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    calendarTableRef.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const calculateSplitterWidthPercentage = (leftPaneEl: HTMLDivElement) => {
    const containerWidth =
      splitterContainerRef.current!.getBoundingClientRect().width;
    const elementWidth = leftPaneEl.offsetWidth;
    return (elementWidth * 100) / (containerWidth || 1);
  };

  const onSplitterDragEnd = () => {
    const leftPaneEl = splitterContainerRef.current!.querySelectorAll(
      ".layout-pane"
    )[0] as HTMLDivElement;
    saveSplitterWidth(leftPaneEl);
  };

  const generateAdditionalColumns = (): Column<any>[] => {
    const availabilityColumns: Column<any>[] = [];
    for (let i = 0; i < 7; i++)
      availabilityColumns.push(generateAvailabilityColumn(i));
    return availabilityColumns;
  };

  const fetchAvailability = async (fetchProps: ITableFetchFunctionProps) =>
    await adapterContext!.service
      .getAdapterService()
      .fetchAvailability(
        startDateRef.current,
        fetchHelpers.getTableQuery({ fetchProps })
      );

  const table = useTable({
    entityName,
    saveKey: "availability",
    columns: uiService.getAvailabilityResourcesColumns(t) || [],
    fetchFunction: fetchAvailability,
    dependencies: [!!site],
    fetchOn: true,
    infiniteScrolling: true,
    rowKeyGetter: (row) => row[uiConstants.fields._id] as string,
    fetchTriggers: [site, startDateRef.current],
  });

  const { generateAvailabilityProgress, doGenerateAvailability } =
    useAsyncGenerateAvailability({ table });

  const getColumnDate = (index: number): Date => {
    const date = addDays(startDateRef.current, index);
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );
  };

  function getCompareDateFormat(value: Date) {
    const tmp = new Date(value);
    return `${tmp.getFullYear()}-${tmp.getMonth() + 1}-${tmp.getDate()}`;
  }
  const sortAvailabilitySortedByDate = (availabilityArray: any[]) =>
    availabilityArray.sort(
      (b, a) =>
        new Date(b.availability_date).getTime() -
        new Date(a.availability_date).getTime()
    );

  function handleOpenDeleteConfirmation(formatterProps: FormatterProps<any>) {
    handleSetSelectedRow(formatterProps);
    deleteAvailabilityConfirmation.open({
      onConfirm: () => deleteSelectedAvailability(formatterProps),
    });
  }

  function getAvailabilityIndex(row: any, column: CalculatedColumn<any, any>) {
    const columnDate = getColumnDate(Number(column.key));
    row.availability = sortAvailabilitySortedByDate(row.availability);
    return row.availability
      .map((a: { availability_date: Date }) =>
        getCompareDateFormat(a.availability_date)
      )
      .indexOf(getCompareDateFormat(columnDate));
  }

  function handleSetSelectedRow(formatterProps: FormatterProps<any>) {
    const { row } = formatterProps;
    table.setSelectedRowIds(new Set([row._id]));
  }

  function handleOpenScheduleForDialog(formatterProps: FormatterProps<any>) {
    handleSetSelectedRow(formatterProps);

    const { row, column } = formatterProps;
    const availabilityDate: Date = getColumnDate(column.idx);
    const availabilityIndex: number = getAvailabilityIndex(row, column);
    const availabilityObject: any = row.availability[availabilityIndex!];

    setAvailabilityObjectToUpdate({
      shift_id: availabilityObject?.["shift_id"] || null,
      available_hours: availabilityObject?.["available_hours"] || 0,
      start_date: availabilityDate,
      end_date: availabilityDate,
      availability_header_id: row._id,
    });
    setAvailabilityScheduleForDialogOpened(true);
  }

  const closeAvailabilityDialog = () => {
    table.setSelectedRowIds(new Set());
    setAvailabilityDialogOpened(false);
  };

  const closeAvailabilityScheduleForDialog = (closeProps?: {
    refresh?: boolean;
  }) => {
    if (closeProps?.refresh) {
      table.refresh();
    }
    handleReset();
    setAvailabilityScheduleForDialogOpened(false);
  };

  const isLoading = table.isLoading;
  const isReady = splitterWidth !== null;

  const renderGenerateAvailabilityDialog = (): ReactElement => (
    <GenerateAvailabilityDialog
      open={availabilityDialogOpened}
      onClose={closeAvailabilityDialog}
      doGenerateAvailability={doGenerateAvailability}
    />
  );

  const renderAvailabilityScheduleForDialog = (): ReactElement => {
    const activeRow: any = table.getSelectedRows()[0];
    return (
      <>
        {availabilityScheduleForDialogOpened && (
          <AvailabilityScheduleForDialog
            open={availabilityScheduleForDialogOpened}
            shifts={shiftsRef.current}
            onClose={closeAvailabilityScheduleForDialog}
            scheduleFor={activeRow?.[uiService.getEditAvailabilityHeaderKey()]}
            availability={availabilityObjectToUpdate}
          />
        )}
      </>
    );
  };

  const renderTablePane = (): ReactElement => (
    <RiverDataGrid
      innerRef={entityTableRef}
      rowHeight={64}
      style={{ borderRight: "none" }}
      columns={table.columns.concat([RiverSelectColumn])}
      rows={table.entities}
      defaultColumnOptions={{
        sortable: true,
        resizable: true,
      }}
      rowKeyGetter={table.rowKeyGetter}
      sortColumns={table.sortColumns}
      onSortColumnsChange={(e) => {
        table.setSortColumns(e);
      }}
      onScroll={onTableScroll}
    />
  );

  const renderCalendarPane = (): ReactElement => (
    <RiverDataGrid
      innerRef={calendarTableRef}
      style={{
        borderRight: "none",
      }}
      rowClass={() => styles.calendarGridRow}
      rowHeight={64}
      columns={generateAdditionalColumns()}
      rows={table.entities}
      defaultColumnOptions={{
        sortable: true,
        resizable: false,
      }}
      rowKeyGetter={table.rowKeyGetter}
      sortColumns={table.sortColumns}
      onSortColumnsChange={(e) => {
        table.setSortColumns(e);
      }}
      onScroll={onCalendarVerticalScroll}
    />
  );

  return (
    <>
      {isReady && (
        <TableContext.Provider value={{ table, entity }}>
          <AvailabilityContext.Provider
            value={{
              selectedDate,
              setSelectedDate,
              splitterRef,
              setSplitterRef,
              calendarScrollAreaRef,
              setCalendarScrollAreaRef,
            }}
          >
            <AvailabilityHeader />
            <AvailabilitySubHeader />
            <AvailabilityGridHeader
              setAvailabilityDialogOpened={setAvailabilityDialogOpened}
              startDate={startDateRef.current}
              onStartDateChange={(date) => {
                startDateRef.current = date;
                forceUpdate();
              }}
            />
            <RiverSpinner show={isLoading} />
            <div className={styles.main} ref={splitterContainerRef}>
              <SplitterLayout
                percentage={true}
                primaryIndex={1}
                secondaryInitialSize={splitterWidth}
                customClassName={clsx([
                  styles.splitterLayout,
                  {
                    [styles.splitterTransitionActive]: splitterTransitionActive,
                  },
                ])}
                onDragStart={() => {
                  setSplitterTransitionActive(false);
                }}
                onDragEnd={onSplitterDragEnd}
              >
                {renderTablePane()}
                {renderCalendarPane()}
              </SplitterLayout>
            </div>
            {generateAvailabilityProgress.renderDialog()}
            {renderGenerateAvailabilityDialog()}
            {renderAvailabilityScheduleForDialog()}
            {deleteAvailabilityConfirmation.render()}
          </AvailabilityContext.Provider>
        </TableContext.Provider>
      )}
    </>
  );
};
