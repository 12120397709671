import React, { ReactElement } from "react";
import { IAdapterFolder, IAdapterRuleSummary } from "@river/interfaces";
import { useNavigate } from "react-router";
import { useEntityHelpers } from "../../../helpers";
import { RuleTile } from "../../rule-tile";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./schedule-validation-rules.module.scss";
import clsx from "clsx";

interface IScheduleValidationRulesProps {
  schedule: IAdapterFolder;
  rules: IAdapterRuleSummary[] | undefined;
}

export const ScheduleValidationRules: React.FC<
  IScheduleValidationRulesProps
> = (props): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const entityHelpers = useEntityHelpers();

  const onScheduleNameClick = (): void =>
    navigate(
      `/schedules/${props.schedule._id}?utilization_tab=validation_rules`
    );

  const formatDate = (value: Date): string => {
    const dateTimeValues = Array.isArray(value) ? value : [value];
    return (dateTimeValues as string[])
      .map((v) => entityHelpers.formatDateStringAsUTC(v))
      .join(", ");
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div
          className={clsx([
            styles.scheduleDetails,
            dataGridStyles.dataGridLink,
          ])}
          onClick={onScheduleNameClick}
        >
          <div className={styles.scheduleName}>{props.schedule.folder}</div>
          <div className={styles.woCount}>
            ({props.schedule.workorder_count})
          </div>
        </div>

        <div className={styles.scheduleDates}>
          {formatDate(props.schedule.start_date)}-
          {formatDate(props.schedule.end_date)}
        </div>
      </div>
      <div className={styles.rules}>
        {props.rules?.length === 0 && (
          <div className={styles.noRules}>
            {t("module.validation_dashboard:label.no_rules")}
          </div>
        )}
        {props.rules?.map((rule, index) => (
          <RuleTile key={rule._id} rule={rule} entityCount={rule.count} />
        ))}
      </div>
    </div>
  );
};
