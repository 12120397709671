import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { attributeEntityDef } from "../entity-dialog/defs/attribute-def";
import { AttributeDto, IAttribute } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { Constants } from "@river/constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsNotEmpty } from "class-validator";

export interface IStandaloneFields {
  selectedEntityName: string;
}
class StandaloneFields {
  @IsNotEmpty()
  // @ts-ignore
  selectedEntityName: string;

  constructor(obj: IStandaloneFields) {
    Object.assign(this, obj);
  }
}

interface IUseEntityAttributeFormProps {
  entityName: string;
  attribute: IAttribute | null;
  onCreate?: (attribute: IAttribute) => void;
  onUpdate?: () => void;
}

export const useEntityAttributeForm = (
  props: IUseEntityAttributeFormProps
): RiverFormInstance => {
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const isCustomAttribute: boolean =
    !props.attribute || !!props.attribute?.is_custom;

  const generateEntityName = (entityName: string) => `entity:${entityName}`;

  const form: RiverFormInstance = useRiverForm<
    AttributeDto,
    IAttribute,
    StandaloneFields
  >({
    initialDto: Object.assign(new AttributeDto(), {
      attribute_name: "",
      description: "",
      options: [],
    }),
    entityName: "attributes_meta",
    entityDefinition: attributeEntityDef,
    instanceToEdit: props.attribute,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AttributeDto): Promise<IAttribute> => {
      const { selectedEntityName } = form.standalone as StandaloneFields;
      const payload: AttributeDto = { ...dto };
      if (dto.data_type === Constants.data_type.entity) {
        payload.data_type = generateEntityName(selectedEntityName);
      }
      return await adapterContext!.service
        .getAdapterService()
        .createEntityAttributeDefinition(props.entityName, payload);
    },
    update: async (dto: AttributeDto): Promise<void> => {
      const { selectedEntityName } = form.standalone as StandaloneFields;
      const payload: AttributeDto = { ...dto };
      if (dto.data_type === Constants.data_type.entity) {
        payload.data_type = generateEntityName(selectedEntityName);
      }
      await adapterContext!.service
        .getAdapterService()
        .updateEntityAttributeDefinition(
          props.attribute!.entity_name!,
          props.attribute!._id,
          payload
        );
    },
    standalone: {
      fields: new StandaloneFields({
        selectedEntityName: "placeholder",
      }),
      fieldDefs: [
        {
          fieldName: "selectedEntityName",
          dataType: Constants.data_type.string,
        },
      ],
    },
    labels: {
      attribute_name: t(
        "module.data_dictionary:dialog.entity_attribute.attribute_name"
      ),
      description: t(
        "module.data_dictionary:dialog.entity_attribute.description"
      ),
      is_primary_key: t(
        "module.data_dictionary:dialog.entity_attribute.is_primary_key"
      ),
      is_required: t(
        "module.data_dictionary:dialog.entity_attribute.is_required"
      ),
      is_persistent: t(
        "module.data_dictionary:dialog.entity_attribute.is_persistent"
      ),
      data_type: t("module.data_dictionary:dialog.entity_attribute.data_type"),
      input_type: t(
        "module.data_dictionary:dialog.entity_attribute.input_type"
      ),
      cardinality: t(
        "module.data_dictionary:dialog.entity_attribute.cardinality"
      ),
      selectedEntityName: t("module.data_dictionary:label.entity_name"),
    },
    readOnly: !isCustomAttribute,
  });
  return form;
};
