import React, { ReactElement } from "react";
import { IUseEntityTableParams } from "../../ui-service.types";
import { FormatterProps } from "react-data-grid";
import { TFunction } from "react-i18next";
import {
  ExternalResourcesUiService,
  IExternalResourceDialogProps,
} from "../../external-resources-ui.service";
import { JdeExternalResourceDialog } from "./jde-external-resource-dialog";
import { useTableCellRenderers } from "../../../hooks";
import dataGridStyles from "../../../components/shared/river-data-grid/river-data-grid.module.scss";

export class JdeExternalResourcesUiService extends ExternalResourcesUiService {
  craftsArrayKey = "WorkCenters";

  getTableParams = (
    onExternalResourceClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return {
      entityName: "external_resource",
      columns: [
        {
          key: "F0101_ALPH",
          name: t("entity.external_resource:external_resource.F0101_ALPH"),
          formatter: (formatterProps: any) => (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => {
                      onExternalResourceClick(formatterProps);
                    }}
                  >
                    {renderCellText({ formatterProps })}
                  </div>
                ),
              })}
            </>
          ),
        },
        {
          key: "F0101_AN8",
          name: t("entity.external_resource:external_resource.F0101_AN8"),
        },
        {
          key: "WorkCenters.F30006_MCU",
          name: t("entity.external_resource:external_resource.WorkCenters"),
        },
        {
          key: "Agreement",
          name: t("entity.external_resource:external_resource.Agreement"),
        },
      ],
    };
  };

  renderExternalResourceDialog = (
    props: IExternalResourceDialogProps
  ): ReactElement => <JdeExternalResourceDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.business_unit"];
  }
}
