import { AdapterUiService } from "../adapter-ui.service";
import { AdapterService } from "../adapter.service";
import { SapAdapterService } from "./sap-adapter.service";
import { SapExternalResourcesUiService } from "./sap-external-resources-ui-service";
import { SapSitesUiService } from "./sap-sites-ui.service";
import { SapPersonUiService } from "./sap-person-ui.service";
import { SapAvailabilityUiService } from "./sap-availability-ui.service";
import { SapMaterialRequirementsUiService } from "./sap-material-requirements-ui.service";
import { SapBacklogUiService } from "./sap-backlog-ui.service";
import { SapSchedulesUiService } from "./sap-schedules-ui.service";
import { SapScheduleUiService } from "./sap-schedule-ui.service";
import { SapScheduleUtilizationUiService } from "./sap-schedule-utilization-ui-service";
import { SapUsersUiService } from "./sap-users-ui.service";
import { SapTaskHelpersUiService } from "./sap-task-helpers-ui.service";
import { SapCraftsUiService } from "./sap-crafts-ui-service";
import { SapOperationsUiService } from "./sap-operations-ui-service";
import { SapExecutionUiService } from "./sap-execution-ui.service";
import { SapShiftsUiService } from "./sap-shifts-ui.service";
import { SapCalendarsUiService } from "./sap-calendars-ui.service";
import { SapValidationDashboardUiService } from "./sap-validation-dashboard-ui.service";
import { SapTimecardUiService } from "./sap-timecard-ui.service";
import { SapCrewsUiService } from "./sap-crews-ui.service";
import { SapTableUiService } from "./sap-table-ui.service";
import { SapUserRolesUiService } from "./sap-user-roles-ui- service";
import { SapSupervisorScheduleUiService } from "./sap-supervisor-schedule-ui.service";
import { SapSupervisorScheduleUtilizationUiService } from "./sap-supervisor-schedule-utilization-ui-service/sap-supervisor-schedule-utilization-ui.service";
import { SapSupervisorSchedulesUiService } from "./sap-supervisor-schedules-ui-service";
import { SapSupervisorTimeCardUiService } from "./sap-supervisor-timecard-ui-service";
import { SapScheduleReportUiService } from "./sap-schedule-report-ui-service";

export class SapAdapterUiService extends AdapterUiService {
  private supervisorScheduleUiService: SapSupervisorScheduleUiService | null =
    null;
  private supervisorTimeCardUiService: SapSupervisorTimeCardUiService | null =
    null;
  private supervisorScheduleUtilizationUiService: SapSupervisorScheduleUtilizationUiService | null =
    null;
  private sitesUiService: SapSitesUiService | null = null;
  private backlogUiService: SapBacklogUiService | null = null;
  private schedulesUiService: SapSchedulesUiService | null = null;
  private supervisorSchedulesUiService: SapSupervisorSchedulesUiService | null =
    null;
  private scheduleUiService: SapScheduleUiService | null = null;
  private scheduleUtilizationUiService: SapScheduleUtilizationUiService | null =
    null;
  private usersUiService: SapUsersUiService | null = null;
  private availabilityUiService: SapAvailabilityUiService | null = null;
  private externalResourcesUiService: SapExternalResourcesUiService | null =
    null;
  private materialRequirementsUiService: SapMaterialRequirementsUiService | null =
    null;
  private personUiService: SapPersonUiService | null = null;
  private taskHelpersUiService: SapTaskHelpersUiService | null = null;
  private craftsUiService: SapCraftsUiService | null = null;
  private operationsUiService: SapOperationsUiService | null = null;
  private executionUiService: SapExecutionUiService | null = null;
  private shiftsUiService: SapShiftsUiService | null = null;
  private calendarsUiService: SapCalendarsUiService | null = null;
  private validationDashboardUiService: SapValidationDashboardUiService | null =
    null;
  private timecardUiService: SapTimecardUiService | null = null;
  private crewsUiService: SapCrewsUiService | null = null;
  private tableUiService: SapTableUiService | null = null;
  private userRolesUiService: SapUserRolesUiService | null = null;
  private scheduleReportUiService: SapScheduleReportUiService | null = null;

  protected createAdapterService(): AdapterService {
    return new SapAdapterService(this.customerInfo);
  }

  getSitesUiService(): SapSitesUiService {
    if (!this.sitesUiService) {
      this.sitesUiService = new SapSitesUiService(this);
    }
    return this.sitesUiService;
  }

  getSupervisorSchedulesUiService(): SapSupervisorSchedulesUiService {
    if (!this.supervisorSchedulesUiService) {
      this.supervisorSchedulesUiService = new SapSupervisorSchedulesUiService(
        this
      );
    }
    return this.supervisorSchedulesUiService;
  }

  getBacklogUiService(): SapBacklogUiService {
    if (!this.backlogUiService) {
      this.backlogUiService = new SapBacklogUiService(this);
    }
    return this.backlogUiService;
  }

  getSchedulesUiService(): SapSchedulesUiService {
    if (!this.schedulesUiService) {
      this.schedulesUiService = new SapSchedulesUiService(this);
    }
    return this.schedulesUiService;
  }

  getScheduleUiService(): SapScheduleUiService {
    if (!this.scheduleUiService) {
      this.scheduleUiService = new SapScheduleUiService(this);
    }
    return this.scheduleUiService;
  }

  getScheduleUtilizationUiService(): SapScheduleUtilizationUiService {
    if (!this.scheduleUtilizationUiService) {
      this.scheduleUtilizationUiService = new SapScheduleUtilizationUiService(
        this
      );
    }
    return this.scheduleUtilizationUiService;
  }

  getUsersUiService(): SapUsersUiService {
    if (!this.usersUiService) {
      this.usersUiService = new SapUsersUiService(this);
    }
    return this.usersUiService;
  }

  getAvailabilityUiService(): SapAvailabilityUiService {
    if (!this.availabilityUiService) {
      this.availabilityUiService = new SapAvailabilityUiService(this);
    }
    return this.availabilityUiService;
  }

  getExternalResourcesUiService(): SapExternalResourcesUiService {
    if (!this.externalResourcesUiService) {
      this.externalResourcesUiService = new SapExternalResourcesUiService(this);
    }
    return this.externalResourcesUiService;
  }

  getMaterialRequirementsUiService(): SapMaterialRequirementsUiService {
    if (!this.materialRequirementsUiService) {
      this.materialRequirementsUiService = new SapMaterialRequirementsUiService(
        this
      );
    }
    return this.materialRequirementsUiService;
  }

  getPersonUiService(): SapPersonUiService {
    if (!this.personUiService) {
      this.personUiService = new SapPersonUiService(this);
    }
    return this.personUiService;
  }

  getTaskHelpersUiService(): SapTaskHelpersUiService {
    if (!this.taskHelpersUiService) {
      this.taskHelpersUiService = new SapTaskHelpersUiService(this);
    }
    return this.taskHelpersUiService;
  }

  getCraftsUiService(): SapCraftsUiService {
    if (!this.craftsUiService) {
      this.craftsUiService = new SapCraftsUiService(this);
    }
    return this.craftsUiService;
  }

  getOperationsUiService(): SapOperationsUiService {
    if (!this.operationsUiService) {
      this.operationsUiService = new SapOperationsUiService(this);
    }
    return this.operationsUiService;
  }

  getExecutionUiService(): SapExecutionUiService {
    if (!this.executionUiService) {
      this.executionUiService = new SapExecutionUiService(this);
    }
    return this.executionUiService;
  }

  getShiftsUiService(): SapShiftsUiService {
    if (!this.shiftsUiService) {
      this.shiftsUiService = new SapShiftsUiService(this);
    }
    return this.shiftsUiService;
  }

  getCalendarsUiService(): SapCalendarsUiService {
    if (!this.calendarsUiService) {
      this.calendarsUiService = new SapCalendarsUiService(this);
    }
    return this.calendarsUiService;
  }

  getValidationDashboardUiService(): SapValidationDashboardUiService {
    if (!this.validationDashboardUiService) {
      this.validationDashboardUiService = new SapValidationDashboardUiService(
        this
      );
    }
    return this.validationDashboardUiService;
  }

  getTimeCardUiService(): SapTimecardUiService {
    if (!this.timecardUiService) {
      this.timecardUiService = new SapTimecardUiService(this);
    }
    return this.timecardUiService;
  }

  getCrewsUiService(): SapCrewsUiService {
    if (!this.crewsUiService) {
      this.crewsUiService = new SapCrewsUiService(this);
    }
    return this.crewsUiService;
  }

  getTableUiService(): SapTableUiService {
    if (!this.tableUiService) {
      this.tableUiService = new SapTableUiService(this);
    }
    return this.tableUiService;
  }

  getUserRolesUiService(): SapUserRolesUiService {
    if (!this.userRolesUiService) {
      this.userRolesUiService = new SapUserRolesUiService(this);
    }
    return this.userRolesUiService;
  }

  getSupervisorScheduleUiService(): SapSupervisorScheduleUiService {
    if (!this.supervisorScheduleUiService) {
      this.supervisorScheduleUiService = new SapSupervisorScheduleUiService(
        this
      );
    }
    return this.supervisorScheduleUiService;
  }

  getSupervisorScheduleUtilizationUiService(): SapSupervisorScheduleUtilizationUiService {
    if (!this.supervisorScheduleUtilizationUiService) {
      this.supervisorScheduleUtilizationUiService =
        new SapSupervisorScheduleUtilizationUiService(this);
    }
    return this.supervisorScheduleUtilizationUiService;
  }

  getSupervisorTimeCardUiService(): SapSupervisorTimeCardUiService {
    if (!this.supervisorTimeCardUiService) {
      this.supervisorTimeCardUiService = new SapSupervisorTimeCardUiService(
        this
      );
    }
    return this.supervisorTimeCardUiService;
  }

  getScheduleReportUiService(): SapScheduleReportUiService {
    if (!this.scheduleReportUiService) {
      this.scheduleReportUiService = new SapScheduleReportUiService(this);
    }
    return this.scheduleReportUiService;
  }
}
