import { useEffect, useRef, useState } from "react";
import {
  addDays,
  addHours,
  addMinutes,
  addMilliseconds,
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import {
  ScheduleGanttTimeOptionGroupID,
  IScheduleGanttTimeOption,
  ScheduleGanttTimeOptionID,
  useScheduleGanttTime,
} from "../schedule-tasks/schedule-gantt-header-options";
import * as ganttUtils from "./schedule-gantt-utils";
import { useContext } from "react";
import { ScheduleContext } from "../../../context";
import { usePrevious } from "../../../hooks";
import {
  GanttConstantID,
  ganttAbsoluteConstants,
} from "./schedule-gantt-overlay/schedule-operations-gantt-overlay/render-utils";

interface IUseGanttProps {
  timeOption: IScheduleGanttTimeOption;
  parentElement: HTMLDivElement | null;
  scheduleFn: (date: Date, ids: string[]) => void;
}

export interface IPeriod {
  startDate: Date;
  endDate: Date;
  dateText: string;
  index: number;
}

export interface IFrequencyDef {
  dateAdd: typeof addHours;
  dateText: (start: Date, end: Date) => string;
  minPeriodSize: number;
  diff: typeof differenceInHours;
  startOf: typeof startOfMonth;
  endOf: typeof endOfMonth;
  lesserFrequency?: string;
}

export type HourPeriodDateText = {
  date: string;
  time: string;
};

export type DayPeriodDateText = {
  date: string;
  day: string;
  dayIndex: number;
};

export const frequencyDefinitions: { [index: string]: IFrequencyDef } = {
  hour: {
    dateText: (start: Date, end: Date): string => {
      const UTCDate: Date = new Date(
        start.getUTCFullYear(),
        start.getUTCMonth(),
        start.getUTCDate(),
        start.getUTCHours(),
        start.getUTCMinutes()
      );
      const dateTime: HourPeriodDateText = {
        date: format(UTCDate, "MMM dd"),
        time: format(UTCDate, "HH:mm"),
      };
      return JSON.stringify(dateTime);
    },
    dateAdd: addHours,
    minPeriodSize: 40,
    diff: differenceInHours,
    startOf: startOfHour,
    endOf: endOfHour,
  },
  day: {
    dateText: (start: Date, end: Date): string => {
      const UTCDate: Date = new Date(
        start.getUTCFullYear(),
        start.getUTCMonth(),
        start.getUTCDate()
      );
      const dateTime: DayPeriodDateText = {
        date: format(UTCDate, "MMM dd"),
        day: format(UTCDate, "EEE"),
        dayIndex: start.getUTCDay(),
      };
      return JSON.stringify(dateTime);
    },
    dateAdd: (d: number | Date, amount: number): Date => {
      const d1 = new Date(d);
      const d2 = addDays(d1, amount);
      return addMinutes(
        d2,
        Math.abs(d2.getTimezoneOffset() - d1.getTimezoneOffset())
      );
    },
    minPeriodSize: 100,
    diff: differenceInDays,
    startOf: startOfDay,
    endOf: endOfDay,
    lesserFrequency: "hour",
  },
  week: {
    dateText: (start: Date, end: Date): string => {
      return format(
        new Date(
          start.getUTCFullYear(),
          start.getUTCMonth(),
          start.getUTCDate()
        ),
        "MMM dd"
      );
    },
    dateAdd: (d: number | Date, amount: number): Date => {
      const d1 = new Date(d);
      return new Date(
        Date.UTC(
          d1.getUTCFullYear(),
          d1.getUTCMonth(),
          d1.getUTCDate() + 7 * amount
        )
      );
    },
    minPeriodSize: 90,
    diff: differenceInWeeks,
    startOf: startOfWeek,
    endOf: endOfWeek,
    lesserFrequency: "day",
  },
  month: {
    dateText: (start: Date, end: Date): string => {
      return format(
        new Date(
          start.getUTCFullYear(),
          start.getUTCMonth(),
          start.getUTCDate()
        ),
        "MMM"
      );
    },
    dateAdd: (d: number | Date, amount: number): Date => {
      const d1 = new Date(d);
      return new Date(
        Date.UTC(
          d1.getUTCFullYear(),
          d1.getUTCMonth() + amount,
          d1.getUTCDate()
        )
      );
    },
    minPeriodSize: 100,
    diff: differenceInMonths,
    startOf: startOfMonth,
    endOf: endOfMonth,
    lesserFrequency: "day",
  },
  year: {
    dateText: (start: Date, end: Date): string => {
      return format(
        new Date(
          start.getUTCFullYear(),
          start.getUTCMonth(),
          start.getUTCDate()
        ),
        "MMM"
      );
    },
    dateAdd: (d: number | Date, amount: number): Date => {
      const d1 = new Date(d);
      return new Date(
        Date.UTC(
          d1.getUTCFullYear() + amount,
          d1.getUTCMonth(),
          d1.getUTCDate()
        )
      );
    },
    minPeriodSize: 100,
    diff: differenceInYears,
    startOf: startOfYear,
    endOf: endOfYear,
    lesserFrequency: "month",
  },
};

export const frequencyDefsByGanttTimeOptionGroupID: {
  [key in ScheduleGanttTimeOptionGroupID]: IFrequencyDef;
} = {
  [ScheduleGanttTimeOptionGroupID.HOURS]: frequencyDefinitions.hour,
  [ScheduleGanttTimeOptionGroupID.DAYS]: frequencyDefinitions.day,
  [ScheduleGanttTimeOptionGroupID.WEEKS]: frequencyDefinitions.week,
  [ScheduleGanttTimeOptionGroupID.MONTHS]: frequencyDefinitions.month,
};

export type GanttRatioConstantID =
  | GanttConstantID.MIN_HORIZONTAL_CONNECTOR_LENGTH
  | GanttConstantID.MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH
  | GanttConstantID.MIN_SCHEDULE_TASK_WIDTH
  | GanttConstantID.FINISH_TO_START_VIRTUAL_X_OFFSET
  | GanttConstantID.START_TO_START_VIRTUAL_X_OFFSET;
export type GanttRatioConstants = { [key in GanttRatioConstantID]: number };

export function useScheduleGantt(props: IUseGanttProps) {
  const { ganttTimeOptions } = useScheduleGanttTime();
  const scheduleContext = useContext(ScheduleContext);

  const ganttTimeOption: IScheduleGanttTimeOption =
    props.timeOption || ganttTimeOptions[ScheduleGanttTimeOptionID._7DAYS];
  const timeOptionGroupID: ScheduleGanttTimeOptionGroupID =
    ganttTimeOption.groupId;
  const frequencyDef: IFrequencyDef =
    frequencyDefsByGanttTimeOptionGroupID[timeOptionGroupID];
  const [ganttRatios, setGanttRatios] = useState<GanttRatioConstants>({
    MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH: 0,
    MIN_HORIZONTAL_CONNECTOR_LENGTH: 0,
    MIN_SCHEDULE_TASK_WIDTH: 0,
    FINISH_TO_START_VIRTUAL_X_OFFSET: 0,
    START_TO_START_VIRTUAL_X_OFFSET: 0,
  });

  const ganttStart: Date = ganttUtils.getGanttStart(
    scheduleContext?.selectedGanttDate!,
    ganttTimeOption.groupId,
    new Date(scheduleContext?.currentSchedule?.start_date!)
  );
  const dateAdd = frequencyDef.dateAdd;
  let ganttEnd: Date = dateAdd(ganttStart, ganttTimeOption.value);

  const numberOfPeriods = ganttTimeOption.value;
  const periodWidthPercent = 100 / numberOfPeriods;

  const periods: IPeriod[] = [];

  let start = ganttStart;
  for (let i = 0; i < numberOfPeriods; i++) {
    let end = dateAdd(start, 1);

    if (frequencyDef.lesserFrequency) {
      end = addSeconds(end, -1);
    }

    periods.push({
      startDate: start,
      endDate: end,
      dateText: frequencyDef.dateText(start, end),
      index: i,
    });
    start = dateAdd(start, 1);
  }

  const getXPercentFromDate = (date: Date): number => {
    let percent: number = 0;
    const totalX: number = ganttEnd.getTime() - ganttStart.getTime();
    const xOffset: number = date.getTime() - ganttStart.getTime();
    percent = (xOffset / totalX) * 100;
    return percent;
  };

  const getDateFromXPercent = (xPercent: number): Date => {
    const ganttStartTime: number = ganttStart.getTime();
    const ganttEndTime: number = ganttEnd.getTime();
    const ganttTotalTime: number = ganttEndTime - ganttStartTime;
    const percentageTime: number = ganttTotalTime * (xPercent / 100);
    return addMilliseconds(ganttStartTime, percentageTime);
  };

  const skipRenderChecksRef = useRef<boolean>(false);
  const previousNumberOfPeriods: number = usePrevious<number>(numberOfPeriods)!;

  useEffect(() => {
    if (!!previousNumberOfPeriods) {
      skipRenderChecksRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfPeriods]);

  const calculateGanttConstantsRatios = (): void => {
    const containerWidth: number = props.parentElement!.offsetWidth;
    const getRatio = (value: number): number => (value / containerWidth) * 100;
    // @ts-ignore
    const ratios: GanttRatioConstants = {};
    const setRatioConstant = (id: GanttRatioConstantID) =>
      (ratios[id] = getRatio(ganttAbsoluteConstants[id]));
    const ratiosToCalculate: GanttRatioConstantID[] = [
      GanttConstantID.MIN_HORIZONTAL_CONNECTOR_LENGTH,
      GanttConstantID.MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH,
      GanttConstantID.MIN_SCHEDULE_TASK_WIDTH,
      GanttConstantID.FINISH_TO_START_VIRTUAL_X_OFFSET,
      GanttConstantID.START_TO_START_VIRTUAL_X_OFFSET,
    ];
    ratiosToCalculate.forEach((id) => setRatioConstant(id));
    setGanttRatios(ratios!);
  };

  useEffect(() => {
    if (props.parentElement) {
      calculateGanttConstantsRatios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!props.parentElement]);

  return {
    ganttStart,
    ganttEnd,
    numberOfPeriods,
    dateAdd,
    periodWidthPercent,
    periods,
    frequencyDef,
    getXPercentFromDate,
    getDateFromXPercent,
    skipRenderChecksRef,
    scheduleFn: props.scheduleFn,
    ganttRatios,
  };
}

export type IUseScheduleGantt = ReturnType<typeof useScheduleGantt>;
