import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap/sap-adapter.service";
import { useTranslation } from "@river/common-ui";

export function useRevisionsLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:revision.title"),
      rowKey: "Revision",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "Revision",
            name: t("entity.revision:revision.Revision"),
            width: 110,
          },
          {
            key: "CompCode",
            name: t("entity.revision:revision.CompCode"),
            width: 105,
          },
          {
            key: "PlanningPlant",
            name: t("entity.revision:revision.PlanningPlant"),
            width: 105,
          },
          {
            key: "Description",
            name: t("entity.revision:revision.Description"),
            width: 217,
          },
          {
            key: "BeginDate",
            name: t("entity.revision:revision.BeginDate"),
            width: 115,
          },
          {
            key: "BeginTime",
            name: t("entity.revision:revision.BeginTime"),
            width: 115,
          },
          {
            key: "EndDate",
            name: t("entity.revision:revision.EndDate"),
            width: 115,
          },
          {
            key: "EndTime",
            name: t("entity.revision:revision.EndTime"),
            width: 115,
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).fetchRevisions(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        saveKey: "revisions.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "revision",
      },
    };
  };
}

export type IUseRevisionsLookupDef = ReturnType<typeof useRevisionsLookupDef>;
