import { useRiverAsyncProgress, IUseTable } from "../../../components/shared";
import { useNotification, useTranslation } from "@river/common-ui";
import { useContext, useState } from "react";
import { AdapterUiContext } from "../../../context";

export const useAsyncJobValidation = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const [woTable, setWoTable] = useState<IUseTable>();
  const [rulesTable, setRulesTable] = useState<IUseTable>();
  const adapterContext = useContext(AdapterUiContext);

  const asyncJobValidationProgress = useRiverAsyncProgress({
    title: t("shared.async_actions:job_validation.title"),
    onSuccess: () =>
      notify.success(t("shared.async_actions:job_validation.completed")),
    onComplete: () => {
      woTable!.refresh();
      rulesTable!.refresh({ keepSelection: true });
    },
  });

  const doAsyncJobValidation = async (opts: {
    woTable: IUseTable;
    rulesTable: IUseTable;
    jobValidationActionProps: {
      entityName: string;
      entityIds: string[];
      ruleId: string;
      actionId: string;
      folderId?: string;
    };
  }): Promise<void> => {
    const {
      jobValidationActionProps,
      woTable: currentWoTable,
      rulesTable: currentRulesTable,
    } = opts;
    const adapter = adapterContext!.service.getAdapterService();

    try {
      const { entityName, entityIds, ruleId, actionId, folderId } =
        jobValidationActionProps;

      const asyncAction = await adapter.jobValidationAction(
        entityName,
        entityIds,
        ruleId,
        actionId,
        folderId
      );
      setWoTable(currentWoTable);
      setRulesTable(currentRulesTable);
      asyncJobValidationProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    asyncJobValidationProgress,
    doAsyncJobValidation,
  };
};

export type IUseAsyncJobValidation = ReturnType<typeof useAsyncJobValidation>;
