import { ColumnsProvider, CrewsUiService, FetchFunctionProvider } from "..";
import { useTranslation } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useContext } from "react";
import { fetchHelpers } from "../../helpers";
import {
  IEntityObject,
  OracleEbsAdapterCrewCraftDto,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import { RiverDefaultNumberEditor } from "../../components/shared/river-data-grid/cell-editors";

const DEPARTMENT_RESOURCE_ID_SEPARATOR: string =
  ".DEPARTMENT_RESOURCE_ID_SEPARATOR.";

export class OracleEbsCrewsUiService extends CrewsUiService {
  getAvailableCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "INSTANCE_NAME",
        name: t("entity.availability_header:availability_header.INSTANCE_NAME"),
      },
      {
        key: "SERIAL_NUMBER",
        name: t("entity.availability_header:availability_header.SERIAL_NUMBER"),
      },
      {
        key: "Resources.RESOURCE_CODE",
        name: t("entity.availability_header:availability_header.Resources"),
      },
    ];
  };

  getAssignedCrewResourcesColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "INSTANCE_NAME",
        name: t("entity.crew_person:crew_person.INSTANCE_NAME"),
      },
      {
        key: "SERIAL_NUMBER",
        name: t("entity.crew_person:crew_person.SERIAL_NUMBER"),
      },
      {
        key: "Resources.RESOURCE_CODE",
        name: t("entity.crew_person:crew_person.Resources"),
      },
    ];
  };

  getAvailableCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "RESOURCE_CODE",
        name: t("entity.department_resource:department_resource.RESOURCE_CODE"),
      },
      {
        key: "DESCRIPTION",
        name: t("entity.department_resource:department_resource.DESCRIPTION"),
      },
      {
        key: "RESOURCE_TYPE_DISP",
        name: t(
          "entity.department_resource:department_resource.RESOURCE_TYPE_DISP"
        ),
      },
      {
        key: "DEPARTMENT_CODE",
        name: t(
          "entity.department_resource:department_resource.DEPARTMENT_CODE"
        ),
      },
    ];
  };

  getAssignedCrewCraftsColumns: ColumnsProvider = () => {
    const { t } = useTranslation();
    const { RiverSelectColumn } = useRiverSelectColumn();
    return [
      RiverSelectColumn,
      {
        key: "RESOURCE_CODE",
        name: t("entity.crew_resource:crew_resource.RESOURCE_CODE"),
      },
      {
        key: "RESOURCE_TYPE_DISP",
        name: t("entity.crew_resource:crew_resource.RESOURCE_TYPE_DISP"),
      },
      {
        key: "DEPARTMENT_CODE",
        name: t(
          "entity.department_resource:department_resource.DEPARTMENT_CODE"
        ),
      },
      {
        key: "number_of_units",
        name: t("entity.crew_resource:crew_resource.number_of_units"),
        width: 150,
        editor: (props) => <RiverDefaultNumberEditor editorProps={props} />,
      },
    ];
  };

  resourceNumberField = "INSTANCE_ID"; //TODO => serial number should be a part of the key
  availableCrewCraftsEntityName = "department_resource";
  availableCrewCraftsRowKeyGetter = (row: any): string =>
    `${row["RESOURCE_ID"]}${DEPARTMENT_RESOURCE_ID_SEPARATOR}${row["DEPARTMENT_ID"]}`;
  assignedCrewCraftsEntityName = "crew_resource";

  getAvailableCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return async (fetchProps: ITableFetchFunctionProps) => {
        const craftResourceKey: string = "RESOURCE_ID";
        const craftDepartmentKey: string = "DEPARTMENT_ID";

        const assignedCrafts: IEntityObject[] = await adapterContext!.service
          .getAdapterService()
          .searchEntityData("crew_resource", {
            query: {
              $and: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            },
          });

        const createKeyQuery = (
          craft: IEntityObject
        ): QueryAttributeGroupDto => {
          return {
            $or: [
              {
                attribute_name: craftDepartmentKey,
                attribute_value: {
                  operator: "$ne",
                  value: craft.DEPARTMENT_ID,
                },
              },
              {
                attribute_name: craftResourceKey,
                attribute_value: { operator: "$ne", value: craft.RESOURCE_ID },
              },
            ],
          };
        };

        return await adapterContext!.service.getAdapterService().fetchCrafts(
          fetchHelpers.getTableQuery({
            fetchProps,
            initialQueryAttributeGroup: {
              $and: assignedCrafts.map((craft) => createKeyQuery(craft)),
            },
          })
        );
      };
    };
  };

  getAssignedCrewCraftsFetchFn = (crew: string): FetchFunctionProvider => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      return async (fetchProps: ITableFetchFunctionProps) => {
        return await adapterContext!.service
          .getAdapterService()
          .searchEntityData(
            "crew_resource",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [
                {
                  attribute_name: "crew",
                  attribute_value: {
                    operator: "$eq",
                    value: crew,
                  },
                },
              ],
            })
          );
      };
    };
  };

  getAssignCrewCraftsFn = (crew: string) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      return (selectedIds: Set<string>): Promise<any> => {
        const promises: Promise<void>[] = [];
        selectedIds.forEach((selectedId) => {
          const selectedIDSegments: string[] = selectedId.split(
            DEPARTMENT_RESOURCE_ID_SEPARATOR
          );
          const [resourceId, departmentId] = selectedIDSegments;
          const dto: OracleEbsAdapterCrewCraftDto = {
            crew,
            RESOURCE_ID: Number(resourceId),
            DEPARTMENT_ID: Number(departmentId),
            number_of_units: 1,
          };
          promises.push(
            adapterContext!.service.getAdapterService().createCrewCraft(dto)
          );
        });
        return Promise.all(promises);
      };
    };
  };

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.department_resource"];
  }
}
