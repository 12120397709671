import React, { ReactElement, useContext } from "react";
import { FormatterProps } from "react-data-grid";
import { TableContext, ITableContext } from "../../../context";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import { IExecutionAction } from "../execution";
import {
  RiverPopupMenu,
  IRiverPopupMenuItem,
} from "../../shared/river-popup-menu";
import { IUseTable } from "../../shared";
import { uiConstants } from "../../../helpers";
import styles from "./execution-actions-formatter.module.scss";
import { useTableCellRenderers } from "../../../hooks";
import { useAllowedAction } from "../../protected-action";

export interface ExecutionActionsFormatterProps {
  formatterProps: FormatterProps<any, any>;
  actions: IExecutionAction[];
}

export const ExecutionActionsFormatter: React.FC<
  ExecutionActionsFormatterProps
> = (props): ReactElement => {
  const tableContext = useContext(TableContext);
  const { renderCell } = useTableCellRenderers();

  const isActionAllowed = useAllowedAction();
  const menuItems: IRiverPopupMenuItem[] = [];

  props.actions.forEach((action) => {
    if (action.module && action.action) {
      if (isActionAllowed(action.module, action.action)) {
        if (!action.isHidden?.(props.formatterProps.row)) {
          menuItems.push({
            label: action.label,
            onClick: () => {
              action.onClick(props.formatterProps.row);
            },
            disabled: action.isDisabled?.(props.formatterProps.row),
          });
        }
      }
    } else {
      if (!action.isHidden?.(props.formatterProps.row)) {
        menuItems.push({
          label: action.label,
          onClick: () => {
            action.onClick(props.formatterProps.row);
          },
          disabled: action.isDisabled?.(props.formatterProps.row),
        });
      }
    }
  });

  const selectRow = (
    row: any,
    select: boolean,
    tableContext?: ITableContext
  ): void => {
    const table: IUseTable = tableContext?.table!;
    const rowId: string = row[uiConstants.fields.rowId];
    const selectedRowId: string = Array.from(table.selectedRowIds)[0];

    if (select) {
      table.setSelectedRowIds(new Set([rowId]));
    } else if (selectedRowId === rowId) {
      table.setSelectedRowIds(new Set([]));
    }
  };

  return (
    <>
      {renderCell({
        formatterProps: props.formatterProps,
        content: (
          <div className={styles.root}>
            <RiverPopupMenu
              items={menuItems}
              toggleIcon={CallToActionIcon}
              classes={{
                toggleButton: styles.rowActionButton,
                toggleIcon: styles.toggleIcon,
                menu: styles.menu,
              }}
              onToggle={(open) => {
                selectRow(props.formatterProps.row, open, tableContext!);
              }}
            />
            {props.formatterProps.row[props.formatterProps.column.key]}
          </div>
        ),
      })}
    </>
  );
};
