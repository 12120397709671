import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const DeleteIcon: React.FC<RiverSVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 1 17 17"
      fill="currentColor"
      {...sanitizeSvgProps(props)}
    >
      <title>{props.title}</title>
      <mask
        id="mask0_1395_5682"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1395_5682)">
        <path d="M6.5 17C6.0875 17 5.73438 16.8531 5.44062 16.5594C5.14687 16.2656 5 15.9125 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.491C15 15.9137 14.8531 16.2708 14.5594 16.5625C14.2656 16.8542 13.9125 17 13.5 17H6.5ZM13.5 5.5H6.5V15.5H13.5V5.5ZM8 14H9.5V7H8V14ZM10.5 14H12V7H10.5V14Z" />
      </g>
    </svg>
  );
};
