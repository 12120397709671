import React, { ReactElement, useContext } from "react";
import { IEntityObject } from "@river/interfaces";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  FilterDropdown,
  ColumnSelectorDropdown,
} from "../../shared";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { ExternalResourceAction } from "../external-resources";
import { useTranslation } from "@river/common-ui";
import { IUseTable } from "../../shared";
import { uiConstants } from "../../../helpers";

export const ExternalResourcesGridHeader: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const notify = useNotification();

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const selectedResource: IEntityObject = table.entities.filter((entity) =>
    table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
  )[0];

  const deleteSelectedResource = async (): Promise<any> => {
    if (selectedResource) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteExternalResource(selectedResource._id as string);
        tableContext?.table.fetch();
        deleteShiftConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteShiftConfirmation = useSimpleDialog({
    title: t("module.external_resource:dialog.delete_external_resources"),
    message: t(
      "module.external_resource:message.confirm_external_resources_deletion"
    ),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedResource,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.EXTERNAL_RESOURCES}
            action={ExternalResourceAction.DELETE}
          >
            <DeleteAction onClick={() => deleteShiftConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteShiftConfirmation.render()}
    </>
  );
};
