import React, { PropsWithChildren } from "react";
import { SchedulesPane } from "../schedules-pane";
import { Box } from "@mui/material";
import styles from "./backlog-body.module.scss";

interface IBacklogBodyProps extends PropsWithChildren {
  setIsLoading: (isLoading: boolean) => void;
}

export const BacklogBody: React.FC<IBacklogBodyProps> = (props) => (
  <Box className={styles.root}>
    <SchedulesPane setIsLoading={props.setIsLoading} />
    <Box className={styles.backlogContent}>{props.children}</Box>
  </Box>
);
