import React, { ReactElement, ReactNode, useContext } from "react";
import { createPortal } from "react-dom";
import {
  Typography,
  IconButton,
  Button,
  ButtonProps,
  Divider,
} from "@mui/material";
import { IUseEnv, useEnv } from "@river/common-ui";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { IRiverDrawerContext, RiverDrawerContext } from "../../../context";
import styles from "./river-drawer-content.module.scss";
import clsx from "clsx";

const DEFAULT_SHOW_XCLOSE = true;
const DEFAULT_SHOW_ACTIONS_DIVIDER = true;
const DEFAULT_SHOW_TITLE_DIVIDER = false;

export interface IRiverDrawerContentProps {
  content: ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  showXClose?: boolean;
  showTitleDivider?: boolean;
  showActionsDivider?: boolean;
  closeButtonText?: string;
  actionButtonText?: string;
  title?: string;
  actionsContent?: ReactNode;
  classes?: {
    paper?: string;
    title?: string;
    content?: string;
    actions?: string;
  };
}

interface RiverDrawerButtonProps extends ButtonProps {
  text?: string;
  icon?: React.ComponentType<any>;
}

export const RiverDrawerButton: React.FC<RiverDrawerButtonProps> = (
  props
): ReactElement => {
  const Icon = props.icon!;
  return (
    <Button classes={{ root: clsx([styles.button]) }} {...props}>
      {props.children || (
        <>
          {props.icon && <Icon className={styles.icon} />}
          {props.text}
        </>
      )}
    </Button>
  );
};

export const RiverDrawerActionButton: React.FC<RiverDrawerButtonProps> = (
  props
): ReactElement => (
  <Button
    classes={{ root: clsx([styles.button, styles.action]) }}
    variant={"contained"}
    color={"primary"}
    {...props}
  >
    {props.children || props.text}
  </Button>
);

export const RiverDrawerContent: React.FC<IRiverDrawerContentProps> = (
  props: IRiverDrawerContentProps
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const { drawer }: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { closeDrawer, portalRef } = drawer;

  const { t } = useTranslation("common.button");
  const DEFAULT_CLOSE_BUTTON_TEXT = t("common.button:cancel", {
    defaultValue: "Cancel",
  });

  const showXClose: boolean = props.showXClose ?? DEFAULT_SHOW_XCLOSE;
  const showActionsDivider: boolean =
    props.showActionsDivider ?? DEFAULT_SHOW_ACTIONS_DIVIDER;
  const showTitleDivider: boolean =
    props.showTitleDivider ?? DEFAULT_SHOW_TITLE_DIVIDER;

  const handleClose = (): void => {
    closeDrawer();
    props.onClose?.();
  };

  const handleSubmit = (): void => {
    props.onSubmit?.();
  };

  const renderCloseButton = (): ReactElement => (
    <RiverDrawerButton
      onClick={handleClose}
      text={props.closeButtonText || DEFAULT_CLOSE_BUTTON_TEXT}
    />
  );

  const renderActionButton = (): ReactElement => (
    <RiverDrawerActionButton
      onClick={handleSubmit}
      text={props.actionButtonText}
    />
  );

  const renderDrawerTitle = (): ReactElement => (
    <>
      <DialogTitle
        classes={{
          root: clsx([styles.drawerTitle, props.classes?.title]),
        }}
      >
        <Typography classes={{ root: styles.title }}>{props.title}</Typography>
        {showXClose && (
          <IconButton
            classes={{ root: styles.closeButton }}
            onClick={() => {
              handleClose();
            }}
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )}
      </DialogTitle>
      {showTitleDivider && <Divider className={styles.titleDivider} />}
    </>
  );

  const renderDrawerContent = (): ReactElement => (
    <DialogContent
      classes={{
        root: clsx([styles.drawerContent, props.classes?.content]),
      }}
    >
      {props.content}
    </DialogContent>
  );

  const renderDrawerActions = (): ReactElement => (
    <>
      {showActionsDivider && <Divider />}
      <DialogActions
        classes={{
          root: clsx([styles.drawerActions, props.classes?.actions]),
        }}
      >
        {props.actionsContent || (
          <>
            {renderCloseButton()}
            {renderActionButton()}
          </>
        )}
      </DialogActions>
    </>
  );

  return (
    <>
      {createPortal(
        <div className={clsx([styles.root, { [styles.mobile]: isMobile }])}>
          {renderDrawerTitle()}
          {renderDrawerContent()}
          {renderDrawerActions()}
        </div>,
        portalRef.current
      )}
    </>
  );
};
