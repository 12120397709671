import { FC, useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import {
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import {
  useCompletionPercentageForm,
  ICompletionPercentageValidator,
} from "./use-completion-percentage-form";
import { RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";

export type CompletionPercentageDialogEntityNameType =
  | "workorder"
  | "operation";

export interface CompletionPercentageDialogProps {
  entity_name: CompletionPercentageDialogEntityNameType;
  open: boolean;
  onClose: (success: boolean) => void;
  folderId: string;

  entity_ids?: string[];
}

export const CompletionPercentageDialog: FC<CompletionPercentageDialogProps> = (
  props
) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useCompletionPercentageForm({
    submit: () => onSubmit(),
  });

  const { submit, isProcessing } = form;
  const completionPercentageFields: ICompletionPercentageValidator =
    form.standalone as ICompletionPercentageValidator;
  const resetDialogState = (): void => {
    form.resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    props.onClose(success);
  };

  const onSubmit = async (): Promise<void> => {
    const entity_ids: string[] = [];
    tableContext?.table.selectedRowIds.forEach((id: string) => {
      entity_ids.push(id);
    });
    const dtoData: {
      folder_id: string;
      entity_name: CompletionPercentageDialogEntityNameType;
      entity_ids: string[];
      completed_pct: number | null;
    } = {
      folder_id: props.folderId,
      entity_name: props.entity_name,
      entity_ids: props.entity_ids || entity_ids,
      completed_pct:
        completionPercentageFields.completed_pct !== undefined
          ? +completionPercentageFields.completed_pct
          : null,
    };

    try {
      await adapterContext!.service
        .getAdapterService()
        .setCompletePercent(dtoData);
      tableContext?.table.refresh();
      closeDialog(true);
    } catch (message) {
      notify.error({
        title: t(
          "shared.wo_mass_update_dialog:notification.mass_update_failed"
        ),
        message,
      });
    }
  };

  return (
    <RiverDialog
      title={t("shared.completion_percentage:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:save")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      <RiverTextInput
        id="completed_pct"
        fullWidth
        inputProps={{
          inputProps: {
            min: 0,
            max: 100,
          },
          type: "number",
        }}
      />
    </RiverDialog>
  );
};
