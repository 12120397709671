import React, { ReactElement, useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { BacklogUiService } from "../../../services";
import { TabHeader } from "../../shared";

export const BacklogTabHeader: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;

  return <TabHeader tabs={uiService.getTabs()()} />;
};
