import { ReactElement, useState } from "react";
import {
  ListIcon,
  CalendarStarIcon,
  UnlockIcon,
  UserPlusIcon,
  UsersIcon,
  CalendarIcon,
  SegmentIcon,
  GroupIcon,
} from "../../icons";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HardwareOutlinedIcon from "@mui/icons-material/HardwareOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useTranslation } from "@river/common-ui";
import styles from "./sidebar-menu.module.scss";

export enum ModuleKey {
  WORK_REQUESTS = "WORK_REQUESTS",
  BACKLOG = "BACKLOG",
  PLANNER_BACKLOG = "PLANNER_BACKLOG",
  SCHEDULES = "SCHEDULES",
  SCHEDULE_REPORT = "SCHEDULE_REPORT",
  SHIFTS = "SHIFTS",
  CALENDARS = "CALENDARS",
  AVAILABILITY = "AVAILABILITY",
  EXTERNAL_RESOURCES = "EXTERNAL_RESOURCES",
  ROLES = "ROLES",
  USERS = "USERS",
  DATA_DICTIONARY = "DATA_DICTIONARY",
  JOB_PRIORITY_RULES = "JOB_PRIORITY_RULES",
  ATTACHMENT_SELECTION_RULES = "ATTACHMENT_SELECTION_RULES",
  VALIDATION_RULES = "VALIDATION_RULES",
  VISUAL_INDICATORS_RULES = "VISUAL_INDICATORS_RULES",
  VALIDATION_DASHBOARD = "VALIDATION_DASHBOARD",
  EXECUTION = "EXECUTION",
  LANGUAGES = "LANGUAGES",
  CREWS = "CREWS",
  CRON_TASKS = "CRON_TASKS",
  TIMELINES = "TIMELINES",
  SUPERVISOR_SCHEDULES = "SUPERVISOR_SCHEDULES",
  SUPERVISOR_CREWS = "SUPERVISOR_CREWS",
  REASON_CODE_LOOKUPS = "REASON_CODE_LOOKUPS",
}

export enum MenuItemID {
  SCHEDULER = "scheduler",
  WORK_REQUESTS = "work_requests",
  PLANNER_BACKLOG = "planner_backlog",
  SCHEDULER_BACKLOG = "scheduler_backlog",
  SCHEDULER_REPORT = "scheduler_report",
  SCHEDULES = "schedules",
  SCHEDULING_VALIDATION_DASHBOARD = "scheduling_validation_dashboard",
  RESOURCES_ROOT = "resources_root",
  RESOURCES_SHIFTS = "resources_shifts",
  RESOURCES_CALENDARS = "resources_calendars",
  RESOURCES_AVAILABILITY = "resources_availability",
  RESOURCES_EXTERNAL_RESOURCES = "resources_external_resources",
  RESOURCES_CREWS = "crews",
  SECURITY_ROOT = "security_root",
  SECURITY_USERS = "security_users",
  SECURITY_ROLES = "security_roles",
  CONFIGURATION_ROOT = "configuration_root",
  CONFIGURATION_DATA_DICTIONARY = "configuration_data_dictionary",
  CONFIGURATION_JOB_PRIORITY_RULES = "configuration_job_priority_rules",
  CONFIGURATION_ATTACHMENT_SELECTION_RULES = "configuration_attachment_selection_rules",
  CONFIGURATION_VALIDATION_RULES = "configuration_validation_rules",
  CONFIGURATION_VISUAL_INDICATORS_RULES = "configuration_visual_indicators_rules",
  EXECUTION = "execution",
  LANGUAGES = "languages",
  CRON_TASKS = "cron_tasks",
  TIMELINES = "timelines",
  SUPERVISOR_ROOT = "supervisor_root",
  SUPERVISOR_SCHEDULES = "supervisor_schedules",
  SUPERVISOR_CREWS = "supervisor_crews",
  SUPERVISOR = "supervisor",
  PLANNER = "planner",
  REASON_CODE_LOOKUPS = "reason_code_lookups",
}

export interface IMenuItem {
  id: MenuItemID;
  moduleKey?: ModuleKey;
  render?: () => ReactElement;
  renderIcon?: () => ReactElement;
  show?: boolean;
  title?: string;
  linkTo?: string;
  children?: IMenuItem[];
  parent?: IMenuItem;
  action?: () => void;
  onExpand?: () => void;
  onCollapse?: () => void;
  enabled?: boolean;
  description?: string;
}

interface ISidebarMenuDefProps {}

export function useSidebarMenuDef(props: ISidebarMenuDefProps) {
  const { t } = useTranslation();
  const [menuItems] = useState<IMenuItem[]>([
    {
      id: MenuItemID.SCHEDULER,
      moduleKey: ModuleKey.SCHEDULES,
      title: t("component.sidebar:menu_item.scheduler"),
      renderIcon: () => <CalendarStarIcon className={styles.sidebarIcon} />,
      linkTo: "/schedules",
      enabled: false,
      show: false,
      description: t("component.sidebar:menu_item.desc.schedules"),
      children: [
        {
          id: MenuItemID.TIMELINES,
          moduleKey: ModuleKey.TIMELINES,
          title: t("component.sidebar:menu_item.timelines"),
          renderIcon: () => <TimelineIcon className={styles.sidebarIcon} />,
          linkTo: "/timelines",
          enabled: false,
        },
        {
          id: MenuItemID.SCHEDULER_BACKLOG,
          moduleKey: ModuleKey.BACKLOG,
          title: t("component.sidebar:menu_item.backlog"),
          renderIcon: () => (
            <InsightsOutlinedIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/backlog",
          enabled: false,
        },
        {
          id: MenuItemID.SCHEDULES,
          title: t("component.sidebar:menu_item.scheduling"),
          moduleKey: ModuleKey.SCHEDULES,
          renderIcon: () => <CalendarStarIcon className={styles.sidebarIcon} />,
          linkTo: "/schedules",
          enabled: false,
        },
        {
          id: MenuItemID.EXECUTION,
          moduleKey: ModuleKey.EXECUTION,
          title: t("component.sidebar:menu_item.execution"),
          renderIcon: () => (
            <HardwareOutlinedIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/execution",
          enabled: false,
        },
        {
          id: MenuItemID.WORK_REQUESTS,
          moduleKey: ModuleKey.WORK_REQUESTS,
          title: t("component.sidebar:menu_item.notifications"),
          renderIcon: () => (
            <NotificationsNoneOutlinedIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/notifications",
          enabled: false,
          description: t("component.sidebar:menu_item.desc.notifications"),
        },
        {
          id: MenuItemID.SCHEDULING_VALIDATION_DASHBOARD,
          moduleKey: ModuleKey.VALIDATION_DASHBOARD,
          title: t("component.sidebar:menu_item.dashboard"),
          renderIcon: () => <CalendarStarIcon className={styles.sidebarIcon} />,
          linkTo: "/validation-dashboard",
          enabled: false,
          description: t("component.sidebar:menu_item.desc.dashboard"),
        },
        {
          id: MenuItemID.SCHEDULER_REPORT,
          moduleKey: ModuleKey.SCHEDULE_REPORT,
          title: t("component.sidebar:menu_item.schedule_report"),
          renderIcon: () => (
            <AssessmentOutlinedIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/report",
          enabled: false,
          description: t("component.sidebar:menu_item.desc.schedule_report"),
        },
      ],
    },
    {
      id: MenuItemID.PLANNER,
      title: t("component.sidebar:menu_item.planner"),
      renderIcon: () => <InsightsOutlinedIcon className={styles.sidebarIcon} />,
      linkTo: "/planner/backlog",
      enabled: false,
      show: false,
      description: t("component.sidebar:menu_item.desc.backlog"),
      children: [
        {
          id: MenuItemID.PLANNER_BACKLOG,
          moduleKey: ModuleKey.PLANNER_BACKLOG,
          title: t("component.sidebar:menu_item.backlog"),
          renderIcon: () => (
            <InsightsOutlinedIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/planner/backlog",
          enabled: false,
        },
      ],
    },
    {
      id: MenuItemID.SUPERVISOR_ROOT,
      title: t("component.sidebar:menu_item.supervisor"),
      renderIcon: () => <GroupIcon className={styles.sidebarIcon} />,
      description: t("component.sidebar:menu_item.desc.supervisor"),
      linkTo: "/supervisor/schedules",
      show: false,
      enabled: false,
      children: [
        {
          id: MenuItemID.SUPERVISOR_CREWS,
          title: t("component.sidebar:menu_item.supervisor_crews"),
          moduleKey: ModuleKey.SUPERVISOR_CREWS,
          renderIcon: () => <GroupsIcon className={styles.sidebarIcon} />,
          linkTo: "/supervisor/crews",
          enabled: false,
        },
        {
          id: MenuItemID.SUPERVISOR_SCHEDULES,
          title: t("component.sidebar:menu_item.supervisor_schedules"),
          moduleKey: ModuleKey.SUPERVISOR_SCHEDULES,
          renderIcon: () => <GroupsIcon className={styles.sidebarIcon} />,
          linkTo: "/supervisor/schedules",
          enabled: false,
        },
      ],
    },
    {
      id: MenuItemID.RESOURCES_ROOT,
      title: t("component.sidebar:menu_item.resources"),
      renderIcon: () => <GroupIcon className={styles.sidebarIcon} />,
      description: t("component.sidebar:menu_item.desc.resources"),
      linkTo: "/external-resources",
      show: false,
      enabled: false,
      children: [
        {
          id: MenuItemID.RESOURCES_EXTERNAL_RESOURCES,
          title: t("component.sidebar:menu_item.external"),
          moduleKey: ModuleKey.EXTERNAL_RESOURCES,
          renderIcon: () => (
            <EventAvailableIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/external-resources",
          enabled: false,
        },
        {
          id: MenuItemID.RESOURCES_SHIFTS,
          title: t("component.sidebar:menu_item.shifts"),
          moduleKey: ModuleKey.SHIFTS,
          renderIcon: () => <SegmentIcon className={styles.sidebarIcon} />,
          linkTo: "/shifts",
          enabled: false,
        },
        {
          id: MenuItemID.RESOURCES_CALENDARS,
          title: t("component.sidebar:menu_item.calendars"),
          moduleKey: ModuleKey.CALENDARS,
          renderIcon: () => <CalendarIcon className={styles.sidebarIcon} />,
          linkTo: "/calendars",
          enabled: false,
        },
        {
          id: MenuItemID.RESOURCES_AVAILABILITY,
          title: t("component.sidebar:menu_item.availability"),
          moduleKey: ModuleKey.AVAILABILITY,
          renderIcon: () => (
            <EventAvailableIcon className={styles.sidebarIcon} />
          ),
          linkTo: "/labor",
          enabled: false,
        },

        {
          id: MenuItemID.RESOURCES_CREWS,
          title: t("component.sidebar:menu_item.crews"),
          moduleKey: ModuleKey.CREWS,
          renderIcon: () => <GroupsIcon className={styles.sidebarIcon} />,
          linkTo: "/crews",
          enabled: false,
        },
      ],
    },
    {
      id: MenuItemID.SECURITY_ROOT,
      title: t("component.sidebar:menu_item.security"),
      renderIcon: () => <UnlockIcon className={styles.sidebarIcon} />,
      description: t("component.sidebar:menu_item.desc.security"),
      linkTo: "/users",
      show: false,
      enabled: false,
      children: [
        {
          id: MenuItemID.SECURITY_USERS,
          title: t("component.sidebar:menu_item.users"),
          moduleKey: ModuleKey.USERS,
          renderIcon: () => <UserPlusIcon className={styles.sidebarIcon} />,
          linkTo: "/users",
          enabled: false,
        },
        {
          id: MenuItemID.SECURITY_ROLES,
          title: t("component.sidebar:menu_item.roles"),
          moduleKey: ModuleKey.ROLES,
          renderIcon: () => <UsersIcon className={styles.sidebarIcon} />,
          linkTo: "/roles",
          enabled: false,
        },
      ],
    },
    {
      id: MenuItemID.CONFIGURATION_ROOT,
      title: t("component.sidebar:menu_item.configuration"),
      renderIcon: () => <SettingsOutlinedIcon className={styles.sidebarIcon} />,
      description: t("component.sidebar:menu_item.desc.configuration"),
      linkTo: "/dd",
      show: false,
      enabled: false,
      children: [
        {
          id: MenuItemID.CONFIGURATION_DATA_DICTIONARY,
          title: t("component.sidebar:menu_item.data_dictionary"),
          moduleKey: ModuleKey.DATA_DICTIONARY,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/dd",
          enabled: false,
        },
        {
          id: MenuItemID.CONFIGURATION_JOB_PRIORITY_RULES,
          title: t("component.sidebar:menu_item.job_priority_rules"),
          moduleKey: ModuleKey.JOB_PRIORITY_RULES,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/job-priority-rules",
          enabled: false,
        },
        {
          id: MenuItemID.CONFIGURATION_ATTACHMENT_SELECTION_RULES,
          title: t("component.sidebar:menu_item.attachment_selection_rules"),
          moduleKey: ModuleKey.ATTACHMENT_SELECTION_RULES,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/attachment_selection_rules",
          enabled: false,
        },
        {
          id: MenuItemID.CONFIGURATION_VALIDATION_RULES,
          title: t("component.sidebar:menu_item.validation_rules"),
          moduleKey: ModuleKey.VALIDATION_RULES,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/validation-rules",
          enabled: false,
        },
        {
          id: MenuItemID.CONFIGURATION_VISUAL_INDICATORS_RULES,
          title: t("component.sidebar:menu_item.gantt_chart_color_rules"),
          moduleKey: ModuleKey.VISUAL_INDICATORS_RULES,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/visual-indicators-rules",
          enabled: false,
        },
        {
          id: MenuItemID.LANGUAGES,
          title: t("component.sidebar:menu_item.languages"),
          moduleKey: ModuleKey.LANGUAGES,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/languages",
          enabled: false,
        },
        {
          id: MenuItemID.CRON_TASKS,
          title: t("component.sidebar:menu_item.cron_task"),
          moduleKey: ModuleKey.CRON_TASKS,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/cron-tasks",
          enabled: false,
        },
        {
          id: MenuItemID.REASON_CODE_LOOKUPS,
          title: t("component.sidebar:menu_item.reason_codes"),
          moduleKey: ModuleKey.REASON_CODE_LOOKUPS,
          renderIcon: () => <ListIcon className={styles.sidebarIcon} />,
          linkTo: "/reason-code-lookups",
          enabled: false,
        },
      ],
    },
  ]);
  return {
    menuItems,
  };
}

export type IUseSidebarMenuDef = ReturnType<typeof useSidebarMenuDef>;
