import { FC, useContext, useState, ComponentType } from "react";
import { SupervisorCrew } from "./supervisor-crew";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";
import { AdapterUiContext } from "../../context";

const ModuleWrapper: FC<ITranslationProps> = (props) => (
  <>{props.tReady && <SupervisorCrew />}</>
);

export const SupervisorCrewModule: FC = () => {
  const adapterContext = useContext(AdapterUiContext);
  const uiService = adapterContext!.service.getAvailabilityUiService();
  const namespaces = uiService.getI18nNamespaces();

  const [Component] = useState<ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
