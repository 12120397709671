import { FC } from "react";
import { CraftStat, ICraftStatProps } from "./craft-stat";
import { Box } from "@mui/material";
import { useTranslation } from "@river/common-ui";
import styles from "./craft-stats.module.scss";
import clsx from "clsx";

interface ICraftStatsProps {
  items: Array<ICraftStatProps>;
  className?: string;
}

export const CraftStats: FC<ICraftStatsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box className={clsx([styles.root, props.className])}>
      {props.items.map((item, index) => (
        <CraftStat key={index} {...item} />
      ))}
      {!props.items.length && (
        <div className={styles.noCrafts}>
          {t("shared.craft_stats:label.no_selected_crafts")}
        </div>
      )}
    </Box>
  );
};
