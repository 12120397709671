import { ReactElement, useContext, useState } from "react";
import { GenericAction, useAdvancedFiltersAction } from "../..";
import { useTranslation } from "@river/common-ui";
import { TableContext } from "../../../../context";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useGridActionHelpers } from "../../../../helpers";
import { SavedFiltersDialog } from "../../saved-filters-dialog";
import { ITableFilter } from "../../../../interfaces";

export const useSavedFiltersAction = () => {
  const { t } = useTranslation();
  const { advancedFiltersAction } = useAdvancedFiltersAction();
  const { onClick: openAdvancedFiltersDrawer } = advancedFiltersAction;

  const { withSanitizedProps } = useGridActionHelpers();
  const tableContext = useContext(TableContext);

  const [isSavedFiltersDialogOpen, setIsSavedFiltersDialogOpen] =
    useState<boolean>(false);

  const title: string = t("shared.grid_header:label.saved_filters");

  const renderDrawer = (): ReactElement => advancedFiltersAction.renderDrawer();

  const onClick = (): void => openDialog();

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={FilterListIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const openDialog = (): void => {
    setIsSavedFiltersDialogOpen(true);
  };

  const closeDialog = (): void => {
    setIsSavedFiltersDialogOpen(false);
  };

  const renderDialog = (): ReactElement => (
    <SavedFiltersDialog
      open={isSavedFiltersDialogOpen}
      onClose={closeDialog}
      onNewFilter={() => {
        tableContext?.table.setFilterToEdit(null);
        closeDialog();
        openAdvancedFiltersDrawer();
      }}
      onShowFilter={(filter: ITableFilter) => {
        tableContext?.table.setFilterToEdit(filter);
        closeDialog();
        openAdvancedFiltersDrawer();
      }}
      onFilterApplied={(filter: ITableFilter) => {
        tableContext?.table.setFilterToEdit(filter);
      }}
    />
  );

  const renderActionBody = (): ReactElement => (
    <>
      {renderDialog()}
      {renderDrawer()}
    </>
  );

  const renderSavedFiltersAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
      {renderDrawer()}
    </>
  );

  return {
    renderSavedFiltersAction,
    savedFiltersAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      renderDrawer,
      renderActionBody,
      title,
      onClick,
    },
  };
};

export type IUseSavedFiltersAction = ReturnType<typeof useSavedFiltersAction>;
