import { TFunction } from "i18next";
import { MaterialRequirementsUiService } from "../material-requirements-ui.service";
import { IUseEntityTableParams } from "../ui-service.types";

export class OracleEbsMaterialRequirementsUiService extends MaterialRequirementsUiService {
  getMaterialRequirementsTableParams = (
    t: TFunction
  ): IUseEntityTableParams => ({ columns: [], entityName: "" });

  getWorkOrderNumberColumn = () => "WIP_ENTITY_NAME";

  getWorkOrderInventoryItemEntityName = (): string =>
    "wo_operation_inventory_item";

  getWorkOrderDirectItemEntityName = (): string => "wo_operation_direct_item";
}
