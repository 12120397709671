import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { useContext } from "react";
import { IsPersonKeyExists } from "../../../supervisor-schedule-ui-service";
import { IUseTimeCardFormProps } from "../../../supervisor-timecard-ui.service";
import { IAdapterTimeCard, JdeAdapterTimeCardDto } from "@river/interfaces";
import { IsNotEmpty } from "class-validator";

interface IStandaloneValidator {
  personNumber: string;
}

export const useJdeSupervisorOperationTimecardForm = (
  props: IUseTimeCardFormProps
) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  class StandaloneValidator {
    constructor(obj: StandaloneValidator) {
      Object.assign(this, obj);
    }

    @IsNotEmpty()
    @IsPersonKeyExists({ context: { adapterContext } })
    // @ts-ignore
    personNumber: string;
  }

  const form: RiverFormInstance = useRiverForm<
    JdeAdapterTimeCardDto,
    IAdapterTimeCard,
    Object
  >({
    initialDto: Object.assign(new JdeAdapterTimeCardDto(), {
      folder_id: props.folderId,
      operation_id: props.operation?._id,
      PersonNumber: props.initialValues?.PersonNumber || "",
      PersonName: props.initialValues?.PersonName || "",
      timecard_date: props.initialValues?.timecard_date || new Date(),
      timecard_hours: props.initialValues?.timecard_hours || 1,
      is_final: !!props.initialValues?.is_final,
      remaining_hours: props.initialValues?.remaining_hours || 0,
    }),
    standalone: {
      fields: new StandaloneValidator({
        personNumber: "",
      }),
      fieldDefs: [
        {
          fieldName: "personNumber",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },

    entityName: "timecard",
    instanceToEdit: props.timecard,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: JdeAdapterTimeCardDto): Promise<IAdapterTimeCard> => {
      return await adapterContext!.service
        .getAdapterService()
        .createTimeCard(dto);
    },
    update: async (dto: JdeAdapterTimeCardDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateTimeCard(props.timecard!._id!, dto);
    },
    labels: {
      personNumber: t("entity.timecard:timecard.PersonNumber"),
      PersonName: t("entity.timecard:timecard.PersonName"),
      timecard_date: t("entity.timecard:timecard.timecard_date"),
      timecard_hours: t("entity.timecard:timecard.timecard_hours"),
      remaining_hours: t("entity.timecard:timecard.remaining_hours"),
      is_final: t("entity.timecard:timecard.is_final"),
    },
  });

  return {
    form,
  };
};

export type IUseJdeSupervisorOperationTimecardForm = ReturnType<
  typeof useJdeSupervisorOperationTimecardForm
>;
