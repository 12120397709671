import React, { ReactElement } from "react";
import { ILookupProps } from "../../../../shared";
import AddIcon from "@mui/icons-material/Add";
import {
  AddButtonPosition,
  RiverTableLookup,
} from "../../../../shared/river-table-lookup";
import { IEntityObject } from "@river/interfaces";
import { Column } from "react-data-grid";
import styles from "./chart-lookup-filter.module.scss";

const DEFAULT_SINGLE_SELECT: boolean = false;

interface IChartLookupFilterProps {
  rows: any[];
  lookup: ILookupProps;
  columns: Column<any>[];
  addFilterLabel: string;
  onSelect: (selected: IEntityObject[]) => void;
  onDelete?: (object: IEntityObject) => void;
  singleSelect?: boolean;
}

export const ChartLookupFilter: React.FC<IChartLookupFilterProps> = (
  props
): ReactElement => {
  const singleSelect: boolean = props.singleSelect ?? DEFAULT_SINGLE_SELECT;

  return (
    <RiverTableLookup
      rows={props.rows}
      columns={props.columns}
      lookup={props.lookup}
      singleSelect={singleSelect}
      onDelete={props.onDelete}
      onSelect={props.onSelect}
      buttonLabel={props.addFilterLabel}
      classes={{
        root: styles.root,
        addButton: styles.addFilterButton,
        addButtonIcon: styles.addFilterIcon,
        grid: styles.grid,
        deleteCell: styles.deleteCell,
      }}
      addButtonIcon={AddIcon}
      addButtonPosition={AddButtonPosition.TOP}
    />
  );
};
