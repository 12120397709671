import React, { ReactElement, useContext, PropsWithChildren } from "react";
import { ModuleKey } from "./sidebar-menu";
import { SidebarContext } from "../context";
import { AdapterUserContext } from "../context";
import { IModuleActionSummary, IModuleSummary } from "@river/interfaces";

interface IProtectedActionProps extends PropsWithChildren {
  module: ModuleKey;
  action: string;
}

export const useAllowedAction = (): ((
  moduleKey: ModuleKey,
  requestedAction: string
) => boolean) => {
  const sidebarContext = useContext(SidebarContext);
  const userContext = useContext(AdapterUserContext);
  return (moduleKey: ModuleKey, requestedAction: string): boolean => {
    let isAllowed = false;
    if (sidebarContext && !!requestedAction) {
      const module: IModuleSummary | undefined =
        userContext?.userModules!.filter(
          (module) => module.module === moduleKey
        )[0];
      if (module) {
        const allowedActions: IModuleActionSummary[] | undefined =
          module.actions;
        if (allowedActions) {
          isAllowed =
            allowedActions.filter((action) => action.action === requestedAction)
              .length > 0;
        }
      }
    }
    return isAllowed;
  };
};

const ProtectedAction: React.FC<IProtectedActionProps> = ({
  module,
  action,
  children,
}): ReactElement => {
  const isActionAllowed = useAllowedAction();
  return <>{isActionAllowed(module, action) && children}</>;
};

export default ProtectedAction;
