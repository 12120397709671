import React, { ReactElement, useContext } from "react";
import { RiverSelect, IRiverSelectClasses } from "@river/common-ui";
import {
  ApplicationContext,
  AdapterUserContext,
  AdapterUserContextProp,
} from "../../context";
import { DEFAULT_LANGUAGE } from "../../i18n";

interface ILanguageSelectorProps {
  className?: string;
  classes?: IRiverSelectClasses;
  icon?: React.ElementType<any>;
}

export const LanguageSelector: React.FC<ILanguageSelectorProps> = (
  props
): ReactElement => {
  const applicationContext = useContext(ApplicationContext);
  const userContext = useContext(AdapterUserContext);
  const selectedLanguage: string =
    userContext?.userProperties[AdapterUserContextProp.LANGUAGE] ||
    DEFAULT_LANGUAGE;

  return (
    <RiverSelect
      items={applicationContext!.languages.map((item) => {
        return {
          label: item.name,
          value: item.language,
        };
      })}
      value={selectedLanguage}
      onChange={(language) => {
        userContext?.updateUserProperty(
          AdapterUserContextProp.LANGUAGE,
          language
        );
      }}
      className={props.className}
      classes={props.classes}
      icon={props.icon}
      minWidth={"500px"}
      selectorAutoWidth={true}
    />
  );
};
