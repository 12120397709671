import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { QueryAttributeDto } from "@river/interfaces";

export function useOracleEbsWoTypeLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:wo_type.title"),
      rowKey: "MEANING",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "MEANING",
            name: t("entity.wo_type:wo_type.MEANING"),
          },
          {
            key: "DESCRIPTION",
            name: t("entity.wo_type:wo_type.DESCRIPTION"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "wo_type",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: customFilters,
              })
            );
        },
        dependencies: [!!site],
      },
      useEntityProps: {
        entityName: "wo_type",
      },
    };
  };
}

export type IUseOracleEbsWoTypeLookupDef = ReturnType<
  typeof useOracleEbsWoTypeLookupDef
>;
