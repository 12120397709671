/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, MouseEventHandler, useContext } from "react";
import { FormatterProps } from "react-data-grid";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../../../context";
import { IUseTable } from "../../../shared";
import { IColumnFilter } from "../../../../interfaces";
import { uiConstants } from "../../../../helpers";
import { useParams } from "react-router";
import { IEntityObject } from "@river/interfaces";
import { useNotification } from "@river/common-ui";
import { IUseSupervisorScheduleGantt } from "../../supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import {
  SupervisorScheduleUtilizationTabId,
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleAction,
  SupervisorScheduleTasksTabId,
  SupervisorScheduleUiService,
  TableUiService,
} from "../../../../services";
import { useTranslation } from "@river/common-ui";
import { useAllowedAction } from "../../../protected-action";
import { ModuleKey } from "../../../sidebar-menu";
import { SupervisorScheduleUtilizationPeriod } from "./supervisor-schedule-utilization-period";
import { useSupervisorGanttRenderChecks } from "../../supervisor-schedule-gantt";
import { SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID } from "../supervisor-schedule-utilization-tables";
import styles from "./supervisor-schedule-utilization-formatter.module.scss";
import clsx from "clsx";

export interface ISupervisorScheduleUtilizationFormatter
  extends FormatterProps<any> {}

export const SupervisorScheduleUtilizationFormatter: React.FC<
  ISupervisorScheduleUtilizationFormatter
> = (props) => {
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const scheduleContext = useContext(SupervisorScheduleContext);
  const gantt: IUseSupervisorScheduleGantt =
    scheduleContext?.utilizationGanttRef.current!;
  const { shouldRender } = useSupervisorGanttRenderChecks({
    gantt,
    containerId: SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID,
  });
  const { periods } = gantt;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const scheduleUiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const scheduleUtilizationUiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentScheduleTasksTab: SupervisorScheduleTasksTabId =
    scheduleUiService.getCurrentTab()();
  const currentScheduleUtilizationTab: SupervisorScheduleUtilizationTabId =
    scheduleUtilizationUiService.getCurrentTab()();
  const isTasksTabAssignable: boolean =
    scheduleUiService.isTasksTabAssignable()();
  const notify = useNotification();

  const getObjectId = tableUiService.getObjectId()();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();

  const isScheduleActionAllowed = useAllowedAction()(
    ModuleKey.SUPERVISOR_SCHEDULES,
    SupervisorScheduleAction.SCHEDULE
  );

  const scheduleUtilizationTable: IUseTable =
    scheduleContext?.scheduleUtilizationCurrentTableState!;
  const { t } = useTranslation();

  const isAvailableHoursClickEnabled = (): boolean => {
    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    return (
      scheduleTasksTable.selectedRowIds.size > 0 &&
      isScheduleActionAllowed &&
      (currentScheduleUtilizationTab ===
        SupervisorScheduleUtilizationTabId.CREWS ||
        (currentScheduleUtilizationTab ===
          SupervisorScheduleUtilizationTabId.PERSONS &&
          isTasksTabAssignable))
    );
  };

  const onScheduledHoursClick = (index: number): void => {
    const taskIds = props.row["utilization"][index]["references"] as {
      workorder_id: string;
      operation_id: string;
      assignment_id: string;
      operation_resource_id: string;
      resource_instance_id: string;
    }[];
    let rowIdName: string;
    let values: string[];
    switch (currentScheduleTasksTab) {
      case SupervisorScheduleTasksTabId.OPERATIONS:
        rowIdName = "_id";
        values = taskIds.map((id) => id.operation_id);
        break;

      case SupervisorScheduleTasksTabId.ASSIGNMENTS:
        rowIdName = "to_Assignment._id";
        values = taskIds.map((id) => id.assignment_id);
        break;

      case SupervisorScheduleTasksTabId.RESOURCES:
        rowIdName = "WorkOrderOperationResource._id";
        values = taskIds.map((id) => id.operation_resource_id);
        break;
      case SupervisorScheduleTasksTabId.INSTANCES:
        rowIdName =
          "WorkOrderOperationResource.WorkOrderOperationResourceInstance._id";
        values = taskIds.map((id) => id.resource_instance_id);
        break;

      default:
        rowIdName = uiConstants.fields._id;
        values = taskIds.map((id) => id.workorder_id);
        break;
    }

    const taskFilter: IColumnFilter = {
      field: rowIdName,
      operator: "$in",
      value: values,
    };

    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    const filters: IColumnFilter[] = scheduleTasksTable.columnFilters;
    const filterIndex = filters.findIndex(
      (filter) => filter.field === rowIdName
    );
    if (filterIndex !== -1) {
      filters.splice(filterIndex, 1, taskFilter);
    } else {
      filters.push(taskFilter);
    }

    scheduleTasksTable.fetch({ newColumnFilters: filters });
  };

  const assignOperation = (selectedTasks: IEntityObject[], startDate: Date) => {
    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    const operationIds: string[] = selectedTasks.map((task) =>
      getObjectId(task)
    );

    const { _id: personId } = props.row;

    scheduleUtilizationTable.forceLoadingState(true);
    adapterContext!.service
      .getAdapterService()
      .assign(scheduleId, operationIds, personId, startDate)
      .then((result) => {
        scheduleTasksTable.refresh();
        scheduleUtilizationTable.refresh();
        notify.success(
          t(
            "module.supervisor_schedule:notification.assignment_creation_success"
          )
        );
      })
      .catch((error) => {
        notify.error(error.response.data.message);
        scheduleUtilizationTable.forceLoadingState(false);
      });
  };

  const assignResource = (selectedTasks: IEntityObject[], startDate: Date) => {
    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    const operationIds: string[] = selectedTasks.map((task) =>
      getObjectId(task)
    );

    const { _id: personId } = props.row;

    scheduleUtilizationTable.forceLoadingState(true);
    adapterContext!.service
      .getAdapterService()
      .assign(scheduleId, operationIds, personId, startDate)
      .then((result) => {
        scheduleTasksTable.refresh();
        scheduleUtilizationTable.refresh();
        notify.success(
          t("module.supervisor_schedule:notification.instance_creation_success")
        );
      })
      .catch((error) => {
        notify.error(error.response.data.message);
        scheduleUtilizationTable.forceLoadingState(false);
      });
  };

  const scheduleCrew = (startDate: Date) => {
    const { crew } = props.row;
    const entityTypes: { [key in SupervisorScheduleTasksTabId]?: string } = {
      [SupervisorScheduleTasksTabId.WORKORDERS]: uiConstants.rowType.workOrder,
      [SupervisorScheduleTasksTabId.OPERATIONS]: uiConstants.rowType.operation,
      [SupervisorScheduleTasksTabId.ASSIGNMENTS]:
        uiConstants.rowType.assignment,
      [SupervisorScheduleTasksTabId.RESOURCES]:
        uiConstants.rowType.operation_resource,
      [SupervisorScheduleTasksTabId.INSTANCES]:
        uiConstants.rowType.resource_instance,
    };
    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    const selectedRowObjectIds: string[] =
      getSelectedRowsObjectIds(scheduleTasksTable);
    adapterContext!.service
      .getAdapterService()
      .schedule({
        folder_id: scheduleId,
        entity_name: entityTypes[currentScheduleTasksTab]!,
        entity_ids: selectedRowObjectIds,
        start_date: startDate,
        assignment: null,
        crew,
        auto_post: true,
      })
      .then((result) => {
        scheduleTasksTable.refresh();
        scheduleUtilizationTable.refresh();
        notify.success(
          t("module.supervisor_schedule:notification.crew_schedule_success")
        );
      })
      .catch((error) => {
        notify.error(error.response.data.message);
        scheduleUtilizationTable.forceLoadingState(false);
      });
  };

  const onAvailableHoursClick = async (index: number): Promise<any> => {
    if (!isAvailableHoursClickEnabled()) return;

    const scheduleTasksTable: IUseTable =
      scheduleContext?.currentTasksTableRef.current!;
    const selectedRowIds: string[] = Array.from(
      scheduleTasksTable.selectedRowIds
    );

    const selectedTasks: IEntityObject[] = scheduleTasksTable.entities.filter(
      (row) => {
        const rowId: string = row[uiConstants.fields.rowId] as string;
        return selectedRowIds.indexOf(rowId) !== -1;
      }
    );

    const startDate: Date = periods[index].startDate;

    if (
      currentScheduleUtilizationTab ===
      SupervisorScheduleUtilizationTabId.PERSONS
    ) {
      if (currentScheduleTasksTab === SupervisorScheduleTasksTabId.OPERATIONS) {
        assignOperation(selectedTasks, startDate);
      } else if (
        currentScheduleTasksTab === SupervisorScheduleTasksTabId.RESOURCES
      ) {
        assignResource(selectedTasks, startDate);
      }
    } else if (
      currentScheduleUtilizationTab === SupervisorScheduleUtilizationTabId.CREWS
    ) {
      scheduleCrew(startDate);
    }
  };

  const onAvailableHoursMouseEnter: MouseEventHandler = (event) => {
    const el: HTMLDivElement = event.currentTarget as HTMLDivElement;
    const isLinkEnabled: boolean = isAvailableHoursClickEnabled();
    el.classList.toggle(styles.link, isLinkEnabled);
  };

  const renderPeriods = (): ReactElement => {
    const row: any = props.row["utilization"];
    return (
      <div className={styles.periods}>
        {periods.map((period) => {
          const scheduleHrs: number =
            Math.round(row[period.index]?.scheduled_hours * 100) / 100 || 0;
          const availableHrs: number =
            Math.round(row[period.index]?.available_hours * 100) / 100 || 0;
          return (
            <SupervisorScheduleUtilizationPeriod
              period={period}
              key={period.index}
              isOverload={scheduleHrs > availableHrs}
            >
              <div
                className={clsx([
                  styles.periodSegment,
                  styles.scheduledHours,
                  styles.link,
                ])}
                onClick={() => onScheduledHoursClick(period.index)}
              >
                {scheduleHrs}
              </div>
              <div className={styles.periodSegmentSeparator}>/</div>
              <div
                className={clsx([styles.periodSegment, styles.availableHours])}
                onClick={() => onAvailableHoursClick(period.index)}
                onMouseEnter={onAvailableHoursMouseEnter}
              >
                {availableHrs}
              </div>
            </SupervisorScheduleUtilizationPeriod>
          );
        })}
      </div>
    );
  };

  return (
    <>{shouldRender && <div className={styles.root}>{renderPeriods()}</div>}</>
  );
};
