import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { AdapterCrewDto, IAdapterCrew } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import { CrewsAction } from "../../../services";
import { useAllowedAction } from "../../protected-action";

export const useCrewForm = (props: {
  crew: IAdapterCrew | undefined;
  onCreate: (crew: IAdapterCrew) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<AdapterCrewDto, IAdapterCrew, Object>({
    initialDto: new AdapterCrewDto(),
    entityName: "crew",
    instanceToEdit: props.crew,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterCrewDto): Promise<IAdapterCrew> => {
      return await adapterContext!.service.getAdapterService().createCrew(dto);
    },
    update: async (dto: AdapterCrewDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateCrew(props.crew!._id!, dto);
    },
    labels: {
      crew: t("entity.crew:crew.crew"),
      description: t("entity.crew:crew.description"),
    },
    readOnly: !isActionAllowed(ModuleKey.CREWS, CrewsAction.UPDATE),
  });
};
