import { FC, useContext, useRef, MouseEvent } from "react";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import "chart.js/auto"; //ChartJS to register its necessary elements
import { IAdapterProgressSummary, IEntityObject } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleReportContext,
  TableContext,
} from "../../../context";
import { uiConstants, useEntityHelpers } from "../../../helpers";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { IUseTable } from "../../shared";
import { IColumnFilter } from "../../../interfaces";
import { useTranslation } from "@river/common-ui";
import { ScheduleReportUiService } from "../../../services";
import { RiverSpinner } from "@river/common-ui";
import { ScheduleProgressChartHeader } from "./schedule-progress-chart-header";
import styles from "./schedule-progress-chart.module.scss";

export const ScheduleProgressChart: FC = () => {
  //
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const scheduleReportContext = useContext(ScheduleReportContext);
  const currentBaseline = scheduleReportContext?.selectedBaseline;
  const entityHelpers = useEntityHelpers();
  const entities: IEntityObject[] = tableContext?.table?.entities ?? [];
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleReportUiService =
    adapterContext?.service.getScheduleReportUiService()!;
  const chartRef = useRef<ChartJSOrUndefined<"bar">>();

  // ---------------
  const sCurve = (numbers: Array<number>) => {
    let init = 0;
    return numbers.map((n) => {
      init = init + n;
      return init;
    });
  };

  // ---------------
  const buildSummary = () => {
    const summary = entities;

    const labels: string[] = summary.map((s) =>
      entityHelpers.formatDateStringAsUTC(s.date as string)
    );
    const baselineHrs = summary.map(
      (s) => (s as unknown as IAdapterProgressSummary).baseline_hours
    );
    const scheduledHrs = summary.map(
      (s) => (s as unknown as IAdapterProgressSummary).scheduled_hours
    );
    let completedHrs = summary.map(
      (s) => (s as unknown as IAdapterProgressSummary).completed_hours
    );

    let actualHrs = summary.map(
      (s) => (s as unknown as IAdapterProgressSummary).actual_hours
    );

    // remove zeros from the end
    while (completedHrs.length && completedHrs[completedHrs.length - 1] === 0) {
      completedHrs.pop();
    }

    // remove zeros from the end
    while (actualHrs.length && actualHrs[actualHrs.length - 1] === 0) {
      actualHrs.pop();
    }

    const baselineSCurve = sCurve(baselineHrs);
    const scheduledSCurve = sCurve(scheduledHrs);
    const completedSCurve = sCurve(completedHrs);
    const actualSCurve = sCurve(actualHrs);

    return {
      labels,
      datasets: [
        {
          type: "bar" as const,
          label: t("module.schedule_report:progress_chart.label.baseline"),
          backgroundColor:
            currentBaseline?.display_options || "rgb(255, 99, 132)",
          data: baselineHrs,
          borderColor: "white",
          borderWidth: 3,
        },
        {
          type: "line" as const,
          label: t(
            "module.schedule_report:progress_chart.label.baseline_s_curve"
          ),
          borderColor: currentBaseline?.display_options || "rgb(255, 99, 132)", // the colour shoud come from the baseline definition
          borderWidth: 2,
          fill: false,
          data: baselineSCurve,
          cubicInterpolationMode: "monotone" as const,
          borderDash: [5, 5],
        },
        {
          type: "bar" as const,
          label: t(
            "module.schedule_report:progress_chart.label.scheduled_hours"
          ),
          backgroundColor: "rgb(53, 162, 235)",
          data: scheduledHrs,
          borderColor: "white",
          borderWidth: 3,
        },
        {
          type: "line" as const,
          label: t(
            "module.schedule_report:progress_chart.label.scheduled_s_curve"
          ),
          borderColor: "rgb(53, 162, 235)",
          borderWidth: 2,
          fill: false,
          data: scheduledSCurve,
          cubicInterpolationMode: "monotone" as const,
        },
        {
          type: "bar" as const,
          label: t(
            "module.schedule_report:progress_chart.label.completed_hours"
          ),
          backgroundColor: "rgb(75, 192, 192)",
          data: completedHrs,
          borderColor: "white",
          borderWidth: 3,
        },
        {
          type: "line" as const,
          label: t(
            "module.schedule_report:progress_chart.label.completed_s_curve"
          ),
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 2,
          fill: false,
          data: completedSCurve,
          cubicInterpolationMode: "monotone" as const,
        },

        {
          type: "bar" as const,
          label: t("module.schedule_report:progress_chart.label.actual_hours"),
          backgroundColor: "rgb(255, 184, 77)",
          data: actualHrs,
          borderColor: "white",
          borderWidth: 3,
        },
        {
          type: "line" as const,
          label: t(
            "module.schedule_report:progress_chart.label.actual_s_curve"
          ),
          borderColor: "rgb(255, 184, 77)",
          borderWidth: 2,
          fill: false,
          data: actualSCurve,
          cubicInterpolationMode: "monotone" as const,
        },
      ],
    };
  };

  // -----------------
  const applyFilter = (references: any[]) => {
    let rowIdName: string;
    let values: string[];
    rowIdName = uiConstants.fields._id;
    values = references.map((ref) => ref.workorder_id);

    const table: IUseTable = scheduleReportContext?.progressTableRef.current!;
    const taskFilter: IColumnFilter = {
      field: rowIdName,
      operator: "$in",
      value: values,
    };

    const filters: IColumnFilter[] = table.columnFilters;
    const filterIndex = filters.findIndex(
      (filter) => filter.field === rowIdName
    );
    if (filterIndex !== -1) {
      filters.splice(filterIndex, 1, taskFilter);
    } else {
      filters.push(taskFilter);
    }

    table.fetch({ newColumnFilters: filters });
  };

  // ---------------------
  const onChartClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const summary = tableContext?.table.entities!;
    const points = getElementAtEvent(chartRef.current!, event);

    if (points.length) {
      const firstPoint = points[0];

      switch (firstPoint.datasetIndex) {
        case 0: //baseline hours
          //
          applyFilter(
            (summary[firstPoint.index] as unknown as IAdapterProgressSummary)
              .baseline_references
          );
          break;

        case 2: //scheduled hours
          applyFilter(
            (summary[firstPoint.index] as unknown as IAdapterProgressSummary)
              .scheduled_references
          );
          break;

        case 4: //completed hours
          applyFilter(
            (summary[firstPoint.index] as unknown as IAdapterProgressSummary)
              .completed_references
          );
          break;

        case 5: //actual hours
          applyFilter(
            (summary[firstPoint.index] as unknown as IAdapterProgressSummary)
              .actual_references
          );
          break;
      }
    }
  };

  return (
    <div className={styles.root}>
      <RiverSpinner show={tableContext?.table.isLoading!} />
      <ScheduleProgressChartHeader />
      <div className={styles.content}>
        <div className={styles.chartContainer}>
          <Chart
            ref={chartRef}
            onClick={onChartClick}
            type="bar"
            data={buildSummary()}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: t("module.schedule_report:progress_chart.label.title"),
                },
              },
            }}
            className={styles.chart}
          />
        </div>
        {uiService.renderProgressFilters()()}
      </div>
    </div>
  );
};
