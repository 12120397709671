import React, { ReactElement, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ModuleKey, IMenuItem } from "./sidebar-menu";
import {
  IAdapterUserContextProperties,
  IModuleSummary,
} from "@river/interfaces";
import {
  SidebarContext,
  AdapterUserContext,
  RiverModuleContext,
} from "../context";
import { authService } from "@river/common-ui";
import { SplashScreen } from "./splash-screen";

interface IProtectedRouteProps {
  children: JSX.Element;
  module?: ModuleKey;
}

export const Protected: React.ComponentType<IProtectedRouteProps> = (
  props
): ReactElement => {
  const userContext = useContext(AdapterUserContext);
  const sidebarContext = useContext(SidebarContext);
  const availableModules: IModuleSummary[] = userContext?.userModules!;
  const isLoggedIn = authService.isLoggedIn();
  const location = useLocation();
  const menuItemsByModuleKey: Map<ModuleKey, IMenuItem> =
    sidebarContext?.menuItemsByModuleKey!;
  const menuItems: IMenuItem[] = menuItemsByModuleKey
    ? Array.from(menuItemsByModuleKey, ([name, value]) => value)
    : [];

  const userProperties: IAdapterUserContextProperties =
    userContext?.userProperties!;
  const userPropertiesLoaded: boolean =
    !!userProperties?.site && !!userProperties?.language;

  const LoginRedirect = (stateProps?: object): ReactElement => (
    <Navigate to={"/login"} state={{ from: location, ...stateProps }} />
  );

  const ModuleRedirect = (link: string): ReactElement => (
    <Navigate to={link} state={{ from: location }} />
  );

  const hasModuleAccess = (): boolean => {
    //console.log(`[Protected]: requesting access to ${props.module} module`);
    let hasAccess: boolean = false;
    if (props.module) {
      hasAccess = !!availableModules?.filter(
        (module) => module.module === props.module
      ).length;
    } else {
      hasAccess = true;
    }
    return hasAccess;
  };

  // Returns first item from itemsToCheck the user has access to.
  const getFirstItemWithAccess = (
    itemsToCheck: IMenuItem[]
  ): IMenuItem | null => {
    let accessItem: IMenuItem | null = null;
    (itemsToCheck || []).forEach((item) => {
      if (!accessItem) {
        availableModules.forEach((module) => {
          if (module.module === item.moduleKey) {
            if (!accessItem) {
              accessItem = item;
              return;
            }
          }
        });
      } else return;
    });
    return accessItem;
  };

  useEffect(() => {
    if (!availableModules && isLoggedIn) {
      userContext?.fetchUserModules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedIn) {
    //console.log('[Protected]: not logged in - redirecting to login');
    return LoginRedirect();
  } else {
    //console.log('[Protected]: logged in');
    if (!availableModules || !userPropertiesLoaded) {
      //console.log('[Protected]: user modules or user properties not loaded yet - rendering splash screen');
      return <SplashScreen />;
    }
    if (!props.module) {
      //console.log('[Protected]: no module specified - rendering wrapped element(s)');
      return props.children;
    } else {
      if (hasModuleAccess()) {
        //console.log(`[Protected]: granted access to ${props.module} module`);
        return (
          <RiverModuleContext.Provider value={{ moduleKey: props.module }}>
            {props.children}
          </RiverModuleContext.Provider>
        );
      } else {
        //console.log(`[Protected]: denied access to ${props.module} module - search for next available module`);
        let firstAvailableItem: IMenuItem | null = null;

        // First look for accessible item from the same menu group...
        let parentItem: IMenuItem | undefined = menuItemsByModuleKey.get(
          props.module
        )?.parent;
        if (parentItem) {
          firstAvailableItem = getFirstItemWithAccess(parentItem.children!);
        }
        // ...then from the general menu list
        if (!firstAvailableItem) {
          firstAvailableItem = getFirstItemWithAccess(menuItems);
        }

        if (firstAvailableItem) {
          //console.log(`[Protected]: redirecting to (first available) ${moduleMenuItem.moduleKey} module path`);
          return ModuleRedirect(firstAvailableItem?.linkTo!);
        } else {
          //console.log(`[Protected]: no available modules found, redirecting to login with a message`);
          return LoginRedirect({
            error:
              "You need access to at least one application module.\nPlease contact your system administrator.",
            noPass: true,
          });
        }
      }
    }
  }
};
