import { ReactElement, useContext, useState } from "react";
import TopicIcon from "@mui/icons-material/Topic";
import { useTranslation } from "@river/common-ui";
import { TableContext } from "../../../context";
import { useGridActionHelpers } from "../../../helpers";
import { GenericAction } from "../../../components/shared";
import { OracleEbsWoCompletionDialog } from "../oracle-ebs-shared";

export interface IUseEbsWoCompletionActionProps {
  enableOn?: boolean;
}

export const useEbsWoCompletionAction = (
  props?: IUseEbsWoCompletionActionProps
) => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("shared.wo_completion:dialog.title");

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (iconProps?: any): ReactElement => (
    <GenericAction
      icon={TopicIcon}
      enableOn={props?.enableOn}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...iconProps}
    />
  );

  const renderDialog = (dialogProps?: { workOrders?: any[] }): ReactElement => {
    return (
      <OracleEbsWoCompletionDialog
        open={dialogOpened}
        onClose={(success) => {
          setDialogOpened(false);
          if (success) {
            tableContext?.table.refresh();
          }
        }}
        workOrders={dialogProps?.workOrders}
      />
    );
  };

  return {
    ebsWoCompletionAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseEbsWoCompletionAction = ReturnType<
  typeof useEbsWoCompletionAction
>;
