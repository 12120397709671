import { IUseEntityTable } from "../ui-service.types";
import { uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IExecutionContext,
  IUserContextSite,
} from "../../context";
import { useContext, useEffect } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  ExecutionUiService,
  EXECUTION_OPERATIONS_TABLE_SAVE_KEY,
  IFetchExecutionOperationsProps,
} from "./execution-ui.service";
import { useTranslation } from "@river/common-ui";
import { IEntityObject, QueryAttributeGroupDto } from "@river/interfaces";
import { Column } from "react-data-grid";
import {
  ExecutionActions,
  IExecutionAction,
} from "../../components/execution/execution";
import { ModuleKey } from "../../components/sidebar-menu";

interface IUseExecutionOperationsProps {
  executionContext: IExecutionContext;
}

export const useExecutionOperations = (
  props: IUseExecutionOperationsProps
): IUseEntityTable => {
  const {
    currentSchedule,
    currentWorkOrder,
    setCurrentActions,
    executionActions,
  } = props.executionContext;
  const {
    setCurrentOperation,
    setTimecardDialogOpened,
    createExecution,
    deleteExecution,
    stopExecution,
    isExecutionOngoing,
  } = executionActions;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service.getExecutionUiService()!;
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();
  const entityHelpers = useEntityHelpers();
  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const entityName: string = uiService.getExecutionTabOperationEntity();

  let operationActions: IExecutionAction[] = [
    {
      label: t("common.label:timecard"),
      onClick: (operation) => {
        setCurrentOperation(operation);
        setTimecardDialogOpened(true);
      },
    },
    {
      label: t("common.button:start"),
      onClick: createExecution,
      isHidden: (workOrder: IEntityObject) => isExecutionOngoing(workOrder),
    },
    {
      label: t("common.button:cancel"),
      onClick: deleteExecution,
      isHidden: (workOrder: IEntityObject) => !isExecutionOngoing(workOrder),
    },
    {
      label: t("common.button:stop"),
      onClick: (workOrder) => {
        stopExecution(workOrder);
      },
      isDisabled: (workOrder: IEntityObject) => !isExecutionOngoing(workOrder),
    },
    {
      label: t("shared.completion_percentage:title"),
      onClick: (operation) => {
        setCurrentOperation(operation);
        props.executionContext.completionPercentageDialogAction.onClick([
          operation._id,
        ]);
      },
      module: ModuleKey.EXECUTION,
      action: ExecutionActions.OP_COMPLETION_PCT,
    },
  ];

  //concat actions from ui service
  operationActions = uiService
    .getOperationActions({ executionContext: props.executionContext })()
    .concat(operationActions);

  const fetchExecutionOperations: (
    props: IFetchExecutionOperationsProps
  ) => Promise<any> = uiService.getExecutionFetchOperations()();

  useEffect(() => {
    if (currentTab === ExecutionTabId.OPERATIONS) {
      setCurrentActions(operationActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const columns: Column<any>[] = [
    ...uiService.getDefaultOperationColumns({ operationActions })(),
  ];
  const getInitialQuery = (): QueryAttributeGroupDto => {
    if (!currentSchedule) {
      return {};
    } else {
      return uiService.getInitialQuery({
        currentSchedule: currentSchedule,
        currentWorkOrderRef: currentWorkOrder,
      });
    }
  };
  const fetchOperations = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrder) return [];
    const operations = await fetchExecutionOperations({
      initialQuery: getInitialQuery(),
      fetchProps,
    });
    operations.forEach((op: IEntityObject) => {
      entityHelpers.setAttributeValue(
        op,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(
          op,
          uiService.getOperationAttributeValuePath()
        )
      );
    });
    return operations;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: EXECUTION_OPERATIONS_TABLE_SAVE_KEY,
    columns,
    fetchFunction: fetchOperations,
    dependencies: [!!site, currentTab === ExecutionTabId.OPERATIONS],
    fetchOn: currentTab === ExecutionTabId.OPERATIONS,
    fetchTriggers: [site, currentWorkOrder],
    clearBeforeFetch: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
