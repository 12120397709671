import { ReactElement, useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import RuleIcon from "@mui/icons-material/Rule";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ISupervisorScheduleContext,
  SupervisorScheduleContext,
} from "../../../context";
import headerStyles from "../../../components/shared/common-header/common-header.module.scss";
import { IUseTable } from "../../../components/shared";

export const useAutoScheduleAction = () => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const scheduleContext: ISupervisorScheduleContext = useContext(
    SupervisorScheduleContext
  )!;
  const table: IUseTable = scheduleContext?.currentTasksTableRef.current!;
  const notify = useNotification();

  const title: string = t(
    "module.supervisor_schedule:message.auto_schedule.title"
  );

  const onClick = (): void => autoScheduleConfirmationDialog.open();

  const renderIcon = (props?: any): ReactElement => (
    <RuleIcon
      titleAccess={title}
      className={headerStyles.actionIcon}
      onClick={onClick}
      {...props}
    />
  );

  const renderDialog = (): ReactElement =>
    autoScheduleConfirmationDialog.render();

  const renderAutoScheduleAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  const autoSchedule = async (): Promise<void> => {
    const scheduleId: string = scheduleContext?.currentSchedule?._id!;

    if (scheduleId) {
      try {
        table.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .autoSchedule(scheduleId);

        await table.refresh();

        scheduleContext.scheduleUtilizationCurrentTableState?.setSelectedRowIds(
          new Set()
        );
        await scheduleContext.scheduleUtilizationCurrentTableState?.refresh();

        notify.success(
          t("module.supervisor_schedule:notification.auto_schedule_completed")
        );
      } catch (message) {
        notify.error({ message });
        table.forceLoadingState(false);
      }
    }
  };

  const autoScheduleConfirmationDialog = useSimpleDialog({
    title: t("module.supervisor_schedule:message.auto_schedule.title"),
    message: t("module.supervisor_schedule:message.confirm_auto_schedule"),
    confirmButtonText: t("common.button:confirm"),
    onConfirm: autoSchedule,
  });

  return {
    renderAutoScheduleAction,
    autoScheduleAction: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
    },
  };
};

export type IUseAutoScheduleAction = ReturnType<typeof useAutoScheduleAction>;
