import { TFunction } from "i18next";
import { MaterialRequirementsUiService } from "../material-requirements-ui.service";
import { IUseEntityTableParams } from "../ui-service.types";

export class JdeMaterialRequirementsUiService extends MaterialRequirementsUiService {
  getMaterialRequirementsTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      columns: [
        {
          key: "F3111_DOCO",
          name: t("entity.wo_part:wo_part.F3111_DOCO"),
          width: 120,
        },
        {
          key: "F3111_OPSQ",
          name: t("entity.wo_part:wo_part.F3111_OPSQ"),
          width: 120,
        },
        {
          key: "F3111_TBM",
          name: t("entity.wo_part:wo_part.F3111_TBM"),
          width: 120,
        },
        {
          key: "F3111_CPNT",
          name: t("entity.wo_part:wo_part.F3111_CPNT"),
          width: 120,
        },
        {
          key: "F3111_CPIT",
          name: t("entity.wo_part:wo_part.F3111_CPIT"),
          width: 120,
        },
        {
          key: "F3111_DSC1",
          name: t("entity.wo_part:wo_part.F3111_DSC1"),
          width: 120,
        },
        {
          key: "F3111_DRQJ",
          name: t("entity.wo_part:wo_part.F3111_DRQJ"),
          width: 120,
        },
        {
          key: "F3111_TRQT",
          name: t("entity.wo_part:wo_part.F3111_TRQT"),
          width: 120,
        },
        {
          key: "F3111_UM",
          name: t("entity.wo_part:wo_part.F3111_UM"),
          width: 120,
        },
        {
          key: "F3111_UORG",
          name: t("entity.wo_part:wo_part.F3111_UORG"),
          width: 120,
        },
        {
          key: "F3111_SOCN",
          name: t("entity.wo_part:wo_part.F3111_SOCN"),
          width: 120,
        },
        {
          key: "F3111_SOBK",
          name: t("entity.wo_part:wo_part.F3111_SOBK"),
          width: 120,
        },
        {
          key: "F3111_QNTA",
          name: t("entity.wo_part:wo_part.F3111_QNTA"),
          width: 120,
        },
        {
          key: "F3111_UPMJ",
          name: t("entity.wo_part:wo_part.F3111_UPMJ"),
          width: 120,
        },
        {
          key: "F3111_VEND",
          name: t("entity.wo_part:wo_part.F3111_VEND"),
          width: 120,
        },
      ],
      entityName: "wo_part",
    };
  };

  getWorkOrderNumberColumn = () => "F4801_DOCO";
}
