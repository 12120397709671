import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../../../../context";
import { languageEntityDef } from "../../../../languages/languages";
import { useTranslation } from "@river/common-ui";

export function useLanguageLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:language.title"),
      rowKey: "language",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "language",
            name: t("module.languages:languages_entity.language"),
            width: 120,
          },
          { key: "name", name: t("module.languages:languages_entity.name") },
          {
            key: "enabled",
            name: t("module.languages:languages_entity.enabled"),
            width: 80,
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .getLanguages(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "languages",
        definition: languageEntityDef,
      },
    };
  };
}

export type IUseLanguageLookupDef = ReturnType<typeof useLanguageLookupDef>;
