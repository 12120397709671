import { FC, ReactElement, useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { ExecutionUiService } from "../../../services";
import { TabHeader } from "../../shared";

export const ExecutionTabHeader: FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;

  return <TabHeader tabs={uiService.getTabs()()} />;
};
