import { createTheme } from "@mui/material/styles";
export const defaultTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "rgba(0, 0, 0, 0.12)",
                },
                input: {
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "120%",
                    letterSpacing: "0.15px",
                    color: "#333333",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    "&$shrink": {
                        transform: "translate(14px, -7px) scale(0.82)",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "120%",
                    letterSpacing: "0.15px",
                    color: "#828282",
                    "&$focused": {
                        color: "#828282",
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "0 !important",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "13px",
                    lineHeight: "120%",
                    letterSpacing: "1.25px",
                },
            },
        },
    },
    palette: {
        primary: {
            light: "#2196F3",
            main: "#0071CE",
            dark: "#0D47A1",
        },
    },
});
