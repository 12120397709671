import { FC, PropsWithChildren } from "react";
import { Card, CardContent } from "@mui/material";
import styles from "./report-chart-card.module.scss";

export const ReportChartCard: FC<PropsWithChildren<{}>> = (props) => {
  return (
    <Card className={styles.root}>
      <CardContent className={styles.cardContent}>{props.children}</CardContent>
    </Card>
  );
};
