import React, { ReactElement, useContext } from "react";
import { TableContext } from "../../../../context";
import headerStyles from "../../common-header/common-header.module.scss";
import clsx from "clsx";

export interface IGenericAction {
  icon: React.ComponentType<any>;
  onClick: (event: React.MouseEvent<any>) => void;
  className?: string;
  enableOnSelection?: boolean;
  enableOn?: boolean;
  customProps?: any;
  title: string;
}

export const GenericAction: React.FC<IGenericAction> = (
  props
): ReactElement => {
  const tableContext = useContext(TableContext);

  let disabled: boolean = false;
  if (typeof props.enableOn === "boolean") {
    disabled = !props.enableOn;
  } else if (props.enableOnSelection) {
    disabled = tableContext?.table.selectedRowIds.size === 0;
  }

  const Icon = props.icon;

  return (
    <Icon
      onClick={(event: React.MouseEvent<any>) => {
        if (!disabled) {
          props.onClick(event);
        }
      }}
      className={clsx([
        headerStyles.actionIcon,
        props.className,
        { [headerStyles.disabled]: disabled },
      ])}
      title={props.title}
      titleAccess={props.title}
      {...props.customProps}
    />
  );
};
