import { TFunction } from "i18next";
import { Column } from "react-data-grid";

import {
  AvailabilityUiService,
  IRenderCreateResourcesDialogProps,
} from "../availability-ui.service";
import { GenericAvatarIcon } from "../../icons";

import availabilityStyles from "../../components/availability/availability.module.scss";
import clsx from "clsx";
import { useTableCellRenderers } from "../../hooks";

export class SapAvailabilityUiService extends AvailabilityUiService {
  getAvailabilityResourcesColumns = (t: TFunction): Column<any>[] => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return [
      {
        key: "PersonName",
        name: t("entity.availability_header:availability_header.PersonName"),
        width: 190,
        formatter: (formatterProps) => (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={clsx([availabilityStyles.personCell])}>
                  <GenericAvatarIcon />
                  <div>{renderCellText({ formatterProps })}</div>
                </div>
              ),
            })}
          </>
        ),
      },
      {
        key: "WorkCenters.WorkCenter",
        name: t("entity.availability_header:workcenter_ref.WorkCenter"),
        width: 200,
      },
      {
        key: "crews.crew",
        name: t("entity.availability_header:availability_header.crews"),
        width: 200,
      },
      {
        key: "calendar",
        name: t("entity.availability_header:availability_header.calendar"),
        width: 120,
      },
      {
        key: "calendar_start_date",
        name: t(
          "entity.availability_header:availability_header.calendar_start_date"
        ),
        width: 140,
      },
      {
        key: "calendar_end_date",
        name: t(
          "entity.availability_header:availability_header.calendar_end_date"
        ),
        width: 140,
      },
    ];
  };

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.planning_plant"];
  }
  getEditAvailabilityHeaderKey(): string {
    return "PersonName";
  }

  renderCreateResourcesDialog(props: IRenderCreateResourcesDialogProps) {
    return <></>;
  }
}
