import { TabProps } from "@mui/material/Tab";
import { ReactElement, useContext } from "react";
import { BacklogContext, TabContext, TableContext } from "../../context";
import { IAdapterFolder } from "@river/interfaces";
import { useGridActions as useCommonGridActions } from "../backlog-ui-service/grid-actions";
import { useTranslation } from "@river/common-ui";
import {
  TabsProvider,
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTable,
  ReactElementProvider,
} from "../ui-service.types";
import {
  BacklogUiService,
  useBacklogWorkOrders,
  useBacklogOperations,
  useBacklogResources,
  useBacklogInstances,
  BacklogTabId,
  BacklogAction,
  IUseBacklogCurrentTableProps,
  IGetDefaultWorkOrderColumnsProps,
  BacklogTabKeyColumnMap,
} from "../backlog-ui-service";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import {
  IMaterialRequirementsDialogProps,
  IWoMassUpdateDialogProps,
  IOpMassUpdateDialogProps,
} from "../shared";
import {
  OracleEbsMaterialRequirementsDialog,
  OracleEbsWoMassUpdateDialog,
} from "./oracle-ebs-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useAllowedAction } from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";

const DEFAULT_ORACLE_EBS_BACKLOG_TAB: BacklogTabId = BacklogTabId.WORKORDERS;

export class OracleEbsBacklogUiService extends BacklogUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const backlogContext = useContext(BacklogContext);
    const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      removeFromScheduleAction,
    } = useCommonGridActions();

    return () => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: BacklogAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: BacklogAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: removeFromScheduleAction.title,
              startIcon: removeFromScheduleAction.icon,
              onClick: removeFromScheduleAction.onClick,
              action: BacklogAction.SCHEDULE,
              disabled: !selectedIds || !currentSchedule,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      downloadAttachmentsAction,
      materialRequirementsAction,
      checkMaterialShortageAction,
    } = useCommonGridActions();

    return () => (
      <>
        <RiverDropdownActions
          module={ModuleKey.BACKLOG}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },

            {
              title: checkMaterialShortageAction.title,
              startIcon: checkMaterialShortageAction.icon,
              onClick: checkMaterialShortageAction.onClick,
              action: BacklogAction.WO_MATERIAL_SHORTAGE,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: BacklogAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const backlogContext = useContext(BacklogContext);
      const currentTab = tabContext?.selectedTab;
      const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
      const { renderRemoveFromScheduleAction } = useCommonGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          {currentSchedule && renderRemoveFromScheduleAction()}
        </>
      );

      return (
        <>
          {currentTab === BacklogTabId.WORKORDERS && renderWorkOrderActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.backlog:tab.work_orders.title"),
        /*icon: <DynamicFeedIcon />,*/
        value: BacklogTabId.WORKORDERS,
      },
      {
        label: t("module.backlog:tab.operations.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.OPERATIONS,
      },
      {
        label: t("module.backlog:tab.resources.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.RESOURCES,
      },
      {
        label: t("module.backlog:tab.instances.title"),
        /*icon: <DeviceHubIcon />,*/
        value: BacklogTabId.INSTANCES,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_ORACLE_EBS_BACKLOG_TAB;

  useCurrentTable = (
    props: IUseBacklogCurrentTableProps
  ): EntityTableProvider => {
    const { backlogContext } = props;
    return () => {
      const currentTab = this.getCurrentTab()();
      const isActionAllowed = useAllowedAction();
      const workOrdersTable: IUseEntityTable = useBacklogWorkOrders({
        backlogContext,
        draggable: isActionAllowed(ModuleKey.BACKLOG, BacklogAction.SCHEDULE),
      });
      const operationsTable: IUseEntityTable = useBacklogOperations({
        backlogContext,
      });
      const resourcesTable: IUseEntityTable = useBacklogResources({
        backlogContext,
      });
      const instancesTable: IUseEntityTable = useBacklogInstances({
        backlogContext,
      });
      if (currentTab === BacklogTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === BacklogTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === BacklogTabId.RESOURCES) {
        return resourcesTable;
      } else if (currentTab === BacklogTabId.INSTANCES) {
        return instancesTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: string = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === BacklogTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === BacklogTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WIP_ENTITY_NAME",
          name: t("entity.workorder:workorder.WIP_ENTITY_NAME"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.workorder:workorder.DESCRIPTION"),
          editor: TextEditor,
        },
        {
          key: "OWNING_DEPARTMENT_CODE",
          name: t("entity.workorder:workorder.OWNING_DEPARTMENT_CODE"),
        },
        {
          key: "INSTANCE_NUMBER",
          name: t("entity.workorder:workorder.INSTANCE_NUMBER"),
        },
        {
          key: "SHUTDOWN_TYPE_DISP",
          name: t("entity.workorder:workorder.SHUTDOWN_TYPE_DISP"),
        },
        {
          key: "WORK_ORDER_STATUS",
          name: t("entity.workorder:workorder.WORK_ORDER_STATUS"),
        },
        {
          key: "PRIORITY_DISP",
          name: t("entity.workorder:workorder.PRIORITY_DISP"),
        },
        {
          key: "WORK_ORDER_TYPE_DISP",
          name: t("entity.workorder:workorder.WORK_ORDER_TYPE_DISP"),
        },
        {
          key: "ACTIVITY_TYPE_DISP",
          name: t("entity.workorder:workorder.ACTIVITY_TYPE_DISP"),
        },
        {
          key: "ACTIVITY_CAUSE_DISP",
          name: t("entity.workorder:workorder.ACTIVITY_CAUSE_DISP"),
        },
        {
          key: "ACTIVITY_SOURCE_MEANING",
          name: t("entity.workorder:workorder.ACTIVITY_SOURCE_MEANING"),
        },
        {
          key: "SCHEDULED_START_DATE",
          name: t("entity.workorder:workorder.SCHEDULED_START_DATE"),
        },
        {
          key: "SCHEDULED_COMPLETION_DATE",
          name: t("entity.workorder:workorder.SCHEDULED_COMPLETION_DATE"),
        },
      ];
    };
  }

  getDefaultOperationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WIP_ENTITY_NAME",
          name: t("entity.operation:operation.WIP_ENTITY_NAME"),
          width: 105,
        },
        {
          key: "OPERATION_SEQ_NUM",
          name: t("entity.operation:operation.OPERATION_SEQ_NUM"),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.operation:operation.DESCRIPTION"),
        },
        {
          key: "DEPARTMENT_CODE",
          name: t("entity.operation:operation.DEPARTMENT_CODE"),
        },
        {
          key: "SHUTDOWN_TYPE_DISP",
          name: t("entity.operation:operation.SHUTDOWN_TYPE_DISP"),
        },
        {
          key: "WorkOrder.INSTANCE_NUMBER",
          name: t(
            "entity.operation_workorder:operation_workorder.INSTANCE_NUMBER"
          ),
        },
        {
          key: "WorkOrder.WORK_ORDER_STATUS",
          name: t(
            "entity.operation_workorder:operation_workorder.WORK_ORDER_STATUS"
          ),
        },
        {
          key: "FIRST_UNIT_START_DATE",
          name: t("entity.operation:operation.FIRST_UNIT_START_DATE"),
        },
        {
          key: "LAST_UNIT_COMPLETION_DATE",
          name: t("entity.operation:operation.LAST_UNIT_COMPLETION_DATE"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WIP_ENTITY_NAME",
          name: t(
            "entity.operation_resource:operation_resource.WIP_ENTITY_NAME"
          ),
          width: 105,
        },
        {
          key: "OPERATION_SEQ_NUM",
          name: t(
            "entity.operation_resource:operation_resource.OPERATION_SEQ_NUM"
          ),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_SEQ_NUM",
          name: t(
            "entity.operation_resource:operation_resource.RESOURCE_SEQ_NUM"
          ),
        },
        {
          key: "DESCRIPTION",
          name: t("entity.operation_resource:operation_resource.DESCRIPTION"),
        },
        {
          key: "DEPARTMENT_CODE",
          name: t(
            "entity.operation_resource:operation_resource.DEPARTMENT_CODE"
          ),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_CODE",
          name: t("entity.operation_resource:operation_resource.RESOURCE_CODE"),
        },
        {
          key: "WorkOrderOperationResource.USAGE_RATE_OR_AMOUNT",
          name: t(
            "entity.operation_resource:operation_resource.USAGE_RATE_OR_AMOUNT"
          ),
        },
        {
          key: "WorkOrderOperationResource.ASSIGNED_UNITS",
          name: t(
            "entity.operation_resource:operation_resource.ASSIGNED_UNITS"
          ),
        },
        {
          key: "WorkOrderOperationResource.START_DATE",
          name: t("entity.operation_resource:operation_resource.START_DATE"),
        },
        {
          key: "WorkOrderOperationResource.COMPLETION_DATE",
          name: t(
            "entity.operation_resource:operation_resource.COMPLETION_DATE"
          ),
        },
      ];
    };
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WIP_ENTITY_NAME",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.WIP_ENTITY_NAME"
          ),
          width: 105,
        },
        {
          key: "OPERATION_SEQ_NUM",
          name: t("entity.operation:operation.OPERATION_SEQ_NUM"),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_SEQ_NUM",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.RESOURCE_SEQ_NUM"
          ),
        },
        {
          key: "DESCRIPTION",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.DESCRIPTION"
          ),
        },
        {
          key: "DEPARTMENT_CODE",
          name: t("entity.instance:instance.DEPARTMENT_CODE"),
        },
        {
          key: "WorkOrderOperationResource.RESOURCE_CODE",
          name: t("entity.instance:instance.RESOURCE_CODE"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.INSTANCE_NAME",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.INSTANCE_NAME"
          ),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.SERIAL_NUMBER",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.SERIAL_NUMBER"
          ),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.START_DATE",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.START_DATE"
          ),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.COMPLETION_DATE",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.COMPLETION_DATE"
          ),
        },
      ];
    };
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleEbsWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <></>;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.department_resource",
      "entity.operation_resource",
      "entity.operation_resource_instance",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.crew_resource",
      "entity.instance",
      "entity.operation_workorder",
      "entity.wo_priority",
      "entity.wo_shutdown_type",
      "entity.wo_planner",
      "entity.wo_operation_inventory_item",
      "entity.wo_operation_direct_item",
    ];
  }

  renderMaterialRequirementsDialog = (
    props: IMaterialRequirementsDialogProps
  ): ReactElement => <OracleEbsMaterialRequirementsDialog {...props} />;

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTabKeyColumnMap = (): BacklogTabKeyColumnMap => {
    const woKey: string = "WIP_ENTITY_ID";
    const opKey: string = "OPERATION_SEQ_NUM";
    const resKey: string = "WorkOrderOperationResource.RESOURCE_SEQ_NUM";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.INSTANCES]: [{ sourceField: woKey, targetField: woKey }],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.RESOURCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
      [BacklogTabId.INSTANCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
