import React, { ReactElement, useContext, useState } from "react";
import {
  AnchorEl,
  IUseTable,
  RiverListPopup,
} from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TableContext,
} from "../../../context";
import { GenericAction } from "../../../components/shared";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { useGridActionHelpers } from "../../../helpers";
import { IAdapterRule } from "@river/interfaces";
import { TableUiService } from "../../table-ui.service";
import { useAsyncJobValidation } from "../../../components/shared/river-async/use-async-job-validation";

export const useValidationRulePopupAction = () => {
  const { t } = useTranslation();
  const asyncJobValidation = useAsyncJobValidation();
  const { asyncJobValidationProgress, doAsyncJobValidation } =
    asyncJobValidation;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();

  const tableContext = useContext(TableContext);
  const scheduleContext = useContext(ScheduleContext);
  const selectedRule: IAdapterRule = scheduleContext?.selectedValidationRule!;
  const { withSanitizedProps } = useGridActionHelpers();

  const [ruleActionsPopupAnchor, setRuleActionsPopupAnchor] =
    useState<AnchorEl>(null);

  const title: string = t(
    "module.schedule:action_label.validation_rule_actions"
  );

  const onClick = (event: React.MouseEvent<any>) =>
    setRuleActionsPopupAnchor(event.currentTarget);

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={PlayCircleFilledWhiteIcon}
      title={title}
      enableOn={
        tableContext?.table!.selectedRowIds.size !== 0 && !!selectedRule
      }
      onClick={onClick}
      {...props}
    />
  );

  const renderPopup = (): ReactElement => (
    <RiverListPopup
      title={t("module.schedule:action_label.rule_actions")}
      items={
        selectedRule?.actions?.map((action) => {
          return {
            title: action.description,
            onClick: async () => {
              const woTable: IUseTable = tableContext?.table!;
              const rulesTable: IUseTable =
                scheduleContext?.scheduleUtilizationCurrentTableState!;
              doAsyncJobValidation({
                woTable,
                rulesTable,
                jobValidationActionProps: {
                  entityName: "workorder",
                  entityIds: getSelectedRowsObjectIds(tableContext?.table!),
                  ruleId: scheduleContext?.selectedValidationRule?._id!,
                  actionId: action._id,
                  folderId: scheduleContext?.currentSchedule?._id,
                },
              });
            },
          };
        }) || []
      }
      anchorEl={ruleActionsPopupAnchor}
      onClose={() => setRuleActionsPopupAnchor(null)}
    />
  );

  const renderValidationRulePopupAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderPopup()}
      {asyncJobValidationProgress.renderDialog()}
    </>
  );

  return {
    renderValidationRulePopupAction,
    validationRulePopupAction: {
      icon: withSanitizedProps(renderIcon),
      renderPopup,
      title,
      onClick,
    },
  };
};

export type IUseValidationRulePopupAction = ReturnType<
  typeof useValidationRulePopupAction
>;
