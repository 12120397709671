import { TFunction } from "react-i18next";
import { Column } from "react-data-grid";
import { AdapterUiService } from "./adapter-ui.service";
import { ILookupDefinition } from "../components/shared";
import { IAdapterUiContextState, IAdapterUserContext } from "../context";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";
import { ReactElement } from "react";
import { ScheduleUtilizationUiService } from "./schedule-utilization-ui-service";

export interface IUseCraftLookupProps {
  userContext: IAdapterUserContext;
  adapterContext: IAdapterUiContextState;
  t: TFunction;
  RiverSelectColumn: Column<any, any>;
  customFilters?: QueryAttributeDto[];
  customQuery?: QueryAttributeGroupDto;
}

export interface ICraftSelectorProps {
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
  selectedIds: Set<string>;
  onChangeSelectedCrafts: (selectedCraftIds: Set<string>) => void;
  isSupervisor?: boolean;
}

export abstract class CraftsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getSelectedCraftsQuery = (
    selectedCraftIds: string[] | number[]
  ): QueryAttributeGroupDto => {
    const scheduleUtilizationUiService: ScheduleUtilizationUiService =
      this.adapterUiService.getScheduleUtilizationUiService();
    return {
      $and: [
        {
          attribute_name:
            scheduleUtilizationUiService.getCraftUtilizationKeyColumn(),
          attribute_value: {
            operator: "$in",
            value: selectedCraftIds,
          },
        },
      ],
    };
  };

  useCraftsLookup =
    (props: IUseCraftLookupProps): (() => ILookupDefinition) =>
    () =>
      this.getCraftLookupDefinition(props);

  protected getCraftsLookupTableSaveKey = (): string => "crafts.lookup";

  abstract craftKeyGetter(row: any): string;

  abstract getCraftsSelectorColumns(
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ): Column<any>[];
  abstract getAssignedCraftsSelectorColumns(
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ): Column<any>[];
  abstract getCraftEntity(): string;
  abstract getCraftsUtilizationIds(
    selectedCraftIds: string[]
  ): string[] | number[];

  abstract renderCraftsSelector(props: ICraftSelectorProps): ReactElement;

  protected abstract getCraftLookupDefinition(
    props: IUseCraftLookupProps
  ): ILookupDefinition;
}
