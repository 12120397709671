import {
  registerDecorator,
  ValidationOptions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from "class-validator";
import { useTranslation } from "@river/common-ui";
import { MutableRefObject } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { OracleEbsAdapterWorkorderCompletionDto } from "@river/interfaces";

@ValidatorConstraint({ async: true })
export class IsCauseCodeRequiredConstraint
  implements ValidatorConstraintInterface
{
  validate(value: any, args: ValidationArguments) {
    const params: {
      formRef: MutableRefObject<RiverFormInstance>;
      referenceField: string;
    } = args.constraints[0];
    const { formRef, referenceField } = params;
    const form: RiverFormInstance = formRef.current;
    const dto: OracleEbsAdapterWorkorderCompletionDto =
      form.dto as OracleEbsAdapterWorkorderCompletionDto;
    // @ts-ignore
    const referenceValue: string = dto[referenceField]!;
    return !!referenceValue ? !!value : true;
  }
}

export function IsConditionallyRequired(validationOptions?: ValidationOptions) {
  const { t } = useTranslation();
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: Object.assign(validationOptions || {}, {
        message:
          validationOptions?.message ||
          ((args: ValidationArguments) => {
            return t("shared.error_messages:validation.required_field", {
              number: args.value,
            });
          }),
      }),
      constraints: [validationOptions?.context],
      validator: IsCauseCodeRequiredConstraint,
    });
  };
}
