import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { QueryAttributeDto } from "@river/interfaces";

export function useOracleEbsDepartmentLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:department.title"),
      rowKey: "DEPARTMENT_ID",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "DEPARTMENT_CODE",
            name: t("entity.department:department.DEPARTMENT_CODE"),
          },
          {
            key: "DESCRIPTION",
            name: t("entity.department:department.DESCRIPTION"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "department",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [
                  {
                    attribute_name: "ORGANIZATION_ID",
                    attribute_value: { operator: "$eq", value: site.site_id },
                  },
                  ...(customFilters || []),
                ],
              })
            );
        },
        dependencies: [!!site],
      },
      useEntityProps: {
        entityName: "department",
      },
    };
  };
}

export type IUseOracleEbsDepartmentLookupDef = ReturnType<
  typeof useOracleEbsDepartmentLookupDef
>;
