import React, { useState, ReactElement, useContext } from "react";
import { RiverIconButton } from "../shared";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import { SiteDialog } from "./site-dialog";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { useTranslation } from "@river/common-ui";
import { SitesUiService } from "../../services";
import styles from "./site-selector.module.scss";

interface ISiteSelectorProps {
  disabled?: boolean;
}

export const SiteSelector: React.FC<ISiteSelectorProps> = (
  props: ISiteSelectorProps
): ReactElement => {
  const { t } = useTranslation();
  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;
  const [showPlantDialog, setShowPlantDialog] = useState<boolean>(false);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  const openPlantDialog = (): void => setShowPlantDialog(true);

  const closePlantDialog = (siteRec?: any): void => {
    setShowPlantDialog(false);
    if (siteRec) {
      userContext?.updateUserProperty(
        AdapterUserContextProp.SITE,
        uiService.getSitePropertyFromErpRecord(siteRec)
      );
    }
  };

  const siteCode: string = site?.site_code;
  const selectorButtonLabel: string = siteCode
    ? `${t("shared.planning_plant_selector:button.current_plant")} ${
        site?.site_code
      }`
    : t("shared.planning_plant_selector:button.select_plant");

  return (
    <div className={styles.root}>
      <RiverIconButton
        label={selectorButtonLabel}
        icon={FactoryOutlinedIcon}
        onClick={() => openPlantDialog()}
        disabled={props.disabled}
      />
      {showPlantDialog && (
        <SiteDialog open={showPlantDialog} onClose={closePlantDialog} />
      )}
    </div>
  );
};
