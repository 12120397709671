import React, { ReactElement, useContext, useState } from "react";
import { EditorProps, FormatterProps } from "react-data-grid";
import { useTableCellRenderers } from "../../../../../hooks";
import { AdapterUiContext, TableContext } from "../../../../../context";
import { IAttribute } from "@river/interfaces";
import { RiverTextInput, useNotification } from "@river/common-ui";
import styles from "./river-default-text-editor.module.scss";
import clsx from "clsx";

interface IRiverDefaultTextFormatterProps {
  editorProps: EditorProps<any>;
}

interface IEditorProps {
  editorProps: EditorProps<any>;
  attribute: IAttribute;
}

const SAVE_KEYBOARD_KEYS = ["Enter", "NumpadEnter"];

const Editor: React.FC<IEditorProps> = (props): ReactElement => {
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();
  const [inputValue, setInputValue] = useState<string>(
    props.editorProps?.row[props.attribute?.attribute_name!] || ""
  );

  const updateInputValue = async (): Promise<any> => {
    try {
      table?.forceLoadingState(true);
      const id = props.editorProps?.row._id;
      await adapterContext!.service
        .getAdapterService()
        .updateEntityData(props.attribute?.entity_name || "", id, {
          [props.attribute?.attribute_name!]: inputValue,
        });
      table?.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };
  return (
    <RiverTextInput
      id={"river_cell_text"}
      value={inputValue}
      onChangeEvent={(event) => {
        setInputValue(event.target.value);
      }}
      className={clsx(["rdg-cell-river-textbox", styles.root])}
      inputProps={{
        onBlur: updateInputValue,
        onKeyDown: async (event) => {
          if (SAVE_KEYBOARD_KEYS.indexOf(event.code) !== -1) {
            await updateInputValue();
          }
        },
        autoFocus: true,
        className: clsx([styles.input]),
      }}
    />
  );
};

export const RiverDefaultTextEditor: React.FC<
  IRiverDefaultTextFormatterProps
> = (props) => {
  const { renderCell } = useTableCellRenderers();
  const { editorProps } = props;
  const { column } = editorProps;
  return (
    <>
      {renderCell({
        formatterProps: editorProps as unknown as FormatterProps<any>,
        content: (
          <TableContext.Consumer>
            {(tableContext) => {
              const attribute = tableContext?.entity.attributesByName.get(
                column.key
              );

              return (
                <Editor
                  editorProps={editorProps as unknown as EditorProps<any>}
                  attribute={attribute!}
                />
              );
            }}
          </TableContext.Consumer>
        ),
      })}
    </>
  );
};
