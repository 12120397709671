import { FC, useContext, useEffect, useState } from "react";
import { SystemCronTasksContext, TableContext } from "../../../../context";
import { isValidCron } from "cron-validator";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSwitch,
  RiverSpinner,
  RiverTextInput,
  useTranslation,
} from "@river/common-ui";
import { useSystemCronTaskForm } from "./use-system-cron-task-form";
import { Box } from "@mui/material";
import { ISystemCronTaskWithEnv } from "@river/interfaces";
import styles from "./system-cron-task-dialog.module.scss";

interface ICronFrequency {
  minutes: string;
  hours: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
}

export const SystemCronTaskDialog: FC = () => {
  const { t } = useTranslation();
  const systemCronTasksContext = useContext(SystemCronTasksContext);
  const tableContext = useContext(TableContext);
  const [cronFrequency, setCronFrequency] = useState<ICronFrequency | null>(
    null
  );

  function handleSetInitCronFrequency() {
    const tmpSelected = systemCronTasksContext?.selected;
    const tmpValues = tmpSelected?.frequency?.split(" ") ?? "";
    setCronFrequency({
      minutes: tmpValues?.[0] ?? "*",
      hours: tmpValues?.[1] ?? "*",
      dayOfMonth: tmpValues?.[2] ?? "*",
      month: tmpValues?.[3] ?? "*",
      dayOfWeek: tmpValues?.[4] ?? "*",
    });
  }

  function getFrequencyInputValue() {
    return `${cronFrequency?.minutes ?? "*"} ${cronFrequency?.hours ?? "*"} ${
      cronFrequency?.dayOfMonth ?? "*"
    } ${cronFrequency?.month ?? "*"} ${cronFrequency?.dayOfWeek ?? "*"}`;
  }

  function handleCloseModal() {
    resetForm();
    systemCronTasksContext?.setOpenDialog(false);
  }

  function handleCloseAndRefresh() {
    tableContext?.table.refresh();
    handleCloseModal();
  }

  function isCronFrequencyValid(cronValue: string) {
    try {
      return isValidCron(cronValue);
    } catch {
      return false;
    }
  }

  const form = useSystemCronTaskForm({
    cron: systemCronTasksContext?.selected!,
    onUpdate: handleCloseAndRefresh,
  });
  const { submit, resetForm, isProcessing, dto, setDto } = form;

  const handleSetDto = (values: ISystemCronTaskWithEnv) => {
    setDto(values);
  };

  const renderCronFrequencyRow = (key: keyof ICronFrequency, name: string) => {
    return (
      <>
        <Box className={styles.frequencyContainerSetupRow}>
          <RiverTextInput
            className={styles.frequencyContainerSetupRowInput}
            descriptionClassName={
              styles.frequencyContainerSetupRowInputDescription
            }
            description={`${t(
              "module.cron:form_input_description.allowed_chars"
            )}: ${t(
              `module.cron:form_input_description.allowed_chars.${name}`
            )}`}
            value={cronFrequency ? cronFrequency[key] : ""}
            label={t(`module.cron:form_label.${name}`)}
            inputProps={{
              required: true,
            }}
            onChange={(value) => {
              if (cronFrequency) {
                setCronFrequency({
                  ...cronFrequency,
                  [key]: value,
                });
              }
            }}
            id={`frequency_${name}`}
          />
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (formData && cronFrequency) {
      handleSetDto({
        ...formData,
        frequency: `${cronFrequency.minutes} ${cronFrequency.hours} ${cronFrequency.dayOfMonth} ${cronFrequency.month} ${cronFrequency.dayOfWeek}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronFrequency]);

  useEffect(() => {
    handleSetInitCronFrequency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedItem = systemCronTasksContext?.selected;
  const formData = dto as ISystemCronTaskWithEnv;
  return (
    <RiverDialog
      classes={{
        paper: styles.paper,
      }}
      form={form}
      title={t("module.cron:dialog.system_cron.edit_cron")}
      open={!!systemCronTasksContext?.openDialog}
      actionButtonText={t("common.button:submit")}
      closeButtonText={t("common.button:save")}
      onSubmit={submit}
      onClose={handleCloseModal}
      showActionsDivider={true}
      dialogProps={{
        maxWidth: false,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={handleCloseModal}
            text={t("common.button:close")}
          />
          <RiverDialogActionButton
            onClick={submit}
            disabled={!isCronFrequencyValid(formData.frequency)}
            text={t("common.button:save")}
          />
        </>
      }
    >
      <RiverSpinner show={isProcessing} />
      <Box className={styles.root}>
        <Box className={styles.row}>
          <Box className={styles.valueContainer}>
            <span className={styles.customLabel}>
              {t("module.cron:cron_entity.task_code")}
            </span>
            <span className={styles.customValue}>{selectedItem?.name}</span>
          </Box>
        </Box>

        <Box className={styles.row}>
          <Box className={styles.descContainer}>
            <RiverTextInput
              fullWidth={true}
              multiline={true}
              rows={1}
              id="description"
              label={t("module.cron:cron_entity.description")}
            />
          </Box>
          <Box className={styles.enabledContainer}>
            <span className={styles.customValue}>
              {formData.enabled
                ? t("module.cron:from.enabled")
                : t("module.cron:from.disabled")}
            </span>
            <RiverSwitch
              id="enabled"
              checked={formData.enabled}
              onChangeEvent={() => {
                handleSetDto({
                  ...formData,
                  enabled: !formData.enabled,
                });
              }}
            />
          </Box>
        </Box>

        <Box className={styles.frequencyContainer}>
          <span className={styles.customLabel}>
            {t("module.cron:cron_entity.frequency")}
          </span>
          <RiverTextInput
            value={getFrequencyInputValue()}
            fullWidth={true}
            error={!isCronFrequencyValid(formData.frequency)}
            inputProps={{ readOnly: true }}
            id="frequency"
            label=""
          />

          <Box className={styles.frequencyContainerSetup}>
            {renderCronFrequencyRow("minutes", "minute")}
            {renderCronFrequencyRow("hours", "hour")}
            {renderCronFrequencyRow("dayOfMonth", "day")}
            {renderCronFrequencyRow("month", "month")}
            {renderCronFrequencyRow("dayOfWeek", "weekday")}
          </Box>
        </Box>
      </Box>
    </RiverDialog>
  );
};
