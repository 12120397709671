import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import {
  ISystemCronTaskWithEnv,
  SystemEnvCronTaskDto,
} from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { systemCronTasksEntityDef } from "../system-cron-task-def";

export const useSystemCronTaskForm = (props: {
  cron: ISystemCronTaskWithEnv | null;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { cron, onUpdate } = props;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<SystemEnvCronTaskDto, ISystemCronTaskWithEnv, Object>({
    initialDto: Object.assign(new SystemEnvCronTaskDto(), {
      frequency: "* * * * *",
      enabled: true,
      description: "",
    }),
    entityName: "system_cron_tasks",
    entityDefinition: systemCronTasksEntityDef,
    instanceToEdit: cron,
    onUpdate: onUpdate,
    update: async (dto: SystemEnvCronTaskDto) => {
      await adapterContext!.service
        .getAdapterService()
        .updateSystemCronTask(cron?.task_code!, dto);
    },
    labels: {
      cron: t("module.cron:cron.edit"),
    },
  });
};
