import { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../context";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import {
  SupervisorScheduleTasksTabId,
  SupervisorScheduleUiService,
} from "../supervisor-schedule-ui-service";
import { useShowTabAction } from "../../shared";
import { IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";

export const useShowOperationsTabAction = () => {
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext!.service.getSupervisorScheduleUiService();

  const isEnabled = (): boolean => {
    let enabled: boolean = false;
    const workOrdersTable: IUseTable =
      uiService.taskTableRefs[SupervisorScheduleTasksTabId.WORKORDERS]!;
    enabled = workOrdersTable?.selectedRowIds.size > 0;
    return enabled;
  };

  const {
    renderShowTabAction: renderShowOperationsTabAction,
    showTabAction: showOperationsTabAction,
  } = useShowTabAction({
    title: t("module.supervisor_schedule:action_label.show_operations_tab"),
    icon: DeviceHubIcon,
    enabled: isEnabled(),
    tabKey: SupervisorScheduleTasksTabId.OPERATIONS,
    onClick: () =>
      tabContext?.setSelectedTab(SupervisorScheduleTasksTabId.OPERATIONS),
  });

  return {
    renderShowOperationsTabAction,
    showOperationsTabAction,
  };
};
