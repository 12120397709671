import { IUseEntityTable } from "../ui-service.types";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  ScheduleContext,
} from "../../context";
import { useContext } from "react";
import { IUseEntity, useEntity } from "../../hooks";
import { uiConstants } from "../../helpers";
import {
  ScheduleUtilizationUiService,
  ScheduleUtilizationTabId,
  useScheduleValidationRules,
} from "../../services";
import { IAdapterFolder } from "@river/interfaces";
import { IUseTable, useTable } from "../../components/shared";

export const useScheduleUtilizationValidationRules = (): IUseEntityTable => {
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: ScheduleUtilizationTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const { getFetchRulesFn } = useScheduleValidationRules();

  const entity: IUseEntity = useEntity({ entityName: "rule" });

  const table: IUseTable = useTable({
    columns: [],
    fetchFunction: getFetchRulesFn(),
    dependencies: [
      !!site,
      currentTab === ScheduleUtilizationTabId.VALIDATION_RULES,
    ],
    fetchOn: currentTab === ScheduleUtilizationTabId.VALIDATION_RULES,
    fetchTriggers: [currentSchedule],
    rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity,
    table,
  };
};

export type IUseScheduleUtilizationValidationRules = ReturnType<
  typeof useScheduleUtilizationValidationRules
>;
