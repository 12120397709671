import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  QueryAttributeDto,
} from "@river/interfaces";

interface IUseCauseCodeLookupProps {
  form: RiverFormInstance;
  selectedCauseCode: any;
  setSelectedCauseCode: (causeCode: any) => void;
}

export function useCauseCodeLookup(props: IUseCauseCodeLookupProps) {
  const notify = useNotification();

  const { form, selectedCauseCode, setSelectedCauseCode } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const getCustomFilters = (): QueryAttributeDto[] => [
    {
      attribute_name: "SET_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["FAILURE_SET_ID"] as string,
      },
    },
    {
      attribute_name: "INVENTORY_ITEM_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["INVENTORY_ITEM_ID"] as string,
      },
    },
    {
      attribute_name: "FAILURE_CODE",
      attribute_value: {
        operator: "$eq",
        value: currentDto["FAILURE_CODE"] as string,
      },
    },
  ];

  useEffect(() => {
    validateStandaloneField("causeCodeDisplay");

    const CAUSE_CODE = selectedCauseCode?.["CAUSE_CODE"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { CAUSE_CODE }
    );
    form.setDto(newDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCauseCode]);

  const fetchCauseCode = async (text: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("cause_code", {
          query: {
            $and: [
              ...getCustomFilters(),
              {
                attribute_name: "CAUSE_CODE",
                attribute_value: {
                  operator: "$eq",
                  value: text,
                },
              },
            ],
          },
        });
      const causeCode: any = results[0] || null;
      setSelectedCauseCode(causeCode);
      validateStandaloneField("causeCodeDisplay", text);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchCauseCode = useCallback(
    debounce((text) => {
      fetchCauseCode(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"causeCodeDisplay"}
      fullWidth
      //@ts-ignore
      disabled={currentDto["FAILURE_CODE_READONLY"]}
      lookup={{
        type: LookupType.ORACLE_EBS_CAUSE_CODE,
        customFilters: getCustomFilters(),
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchCauseCode(event.target.value);
      }}
      onSelect={(selectedCauseCode) => {
        const { CAUSE_CODE } = selectedCauseCode;
        const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
          new OracleEbsAdapterWorkorderCompletionDto(),
          { ...currentDto },
          { CAUSE_CODE }
        );
        form.setDto(newDto);
        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          causeCodeDisplay: CAUSE_CODE,
        });
        setSelectedCauseCode(selectedCauseCode);
      }}
    />
  );

  return {
    render,
    reFetch: async () => {
      const standalone: IOracleEbsWoCompletionStandaloneFields =
        form.standalone as IOracleEbsWoCompletionStandaloneFields;
      const causeCodeDisplay: string = standalone.causeCodeDisplay!;
      await fetchCauseCode(causeCodeDisplay);
    },
  };
}

export type IUseCauseCodeLookup = ReturnType<typeof useCauseCodeLookup>;
