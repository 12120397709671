import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { AdapterLookupDto, IAdapterLookup } from "@river/interfaces";
import { useContext } from "react";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const useLookupForm = (props: {
  lookup_type: string;
  lookup: IAdapterLookup | null;
  onCreate: (lookup: IAdapterLookup) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  return useRiverForm<AdapterLookupDto, IAdapterLookup, Object>({
    initialDto: Object.assign(new AdapterLookupDto(), {
      value: "",
      description: "",
      lookup_type: props.lookup_type,
    }),
    entityName: "lookup",
    instanceToEdit: props.lookup,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterLookupDto): Promise<IAdapterLookup> => {
      return await adapterContext!.service
        .getAdapterService()
        .createLookup(dto);
    },
    update: async (dto: AdapterLookupDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateLookup(props.lookup!._id!, dto);
    },
    labels: {
      value: t("entity.lookup:lookup.value"),
      description: t("entity.lookup:lookup.description"),
    },
  });
};
