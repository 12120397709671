import React, { ReactElement, PropsWithChildren, useContext } from "react";
import {
  DayPeriodDateText,
  IPeriod,
  IFrequencyDef,
  frequencyDefsByGanttTimeOptionGroupID,
} from "../../../supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { SupervisorScheduleContext } from "../../../../../context";
import {
  ISupervisorScheduleGanttTimeOption,
  SupervisorScheduleGanttTimeOptionGroupID,
} from "../../../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import styles from "./supervisor-schedule-utilization-period.module.scss";
import clsx from "clsx";

interface ISupervisorScheduleUtilizationPeriodProps extends PropsWithChildren {
  period: IPeriod;
  isOverload: boolean;
}

export const getWeekDayClass = (dayIndex: number): string => {
  return [
    styles.sunday,
    styles.monday,
    styles.tuesday,
    styles.wednesday,
    styles.thursday,
    styles.friday,
    styles.saturday,
  ][dayIndex];
};

const ganttTimeOptionClassMap: {
  [key in SupervisorScheduleGanttTimeOptionGroupID]: string;
} = {
  [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: styles.hourPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: styles.dayPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: styles.weekPeriod,
  [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: styles.monthPeriod,
};

export const SupervisorScheduleUtilizationPeriod: React.FC<
  ISupervisorScheduleUtilizationPeriodProps
> = (props): ReactElement => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const ganttTimeOption: ISupervisorScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;

  const { period } = props;
  const periodFrequencyDef: IFrequencyDef =
    frequencyDefsByGanttTimeOptionGroupID[ganttTimeOption.groupId];

  const periodGroupClass: string =
    ganttTimeOptionClassMap[ganttTimeOption.groupId];

  const getPeriodSpecificClasses = (): string => {
    let classNames: string[] = [];
    const isDayPeriod: boolean =
      ganttTimeOption.groupId === SupervisorScheduleGanttTimeOptionGroupID.DAYS;

    // Day Period classes
    if (isDayPeriod) {
      const dayPeriod: DayPeriodDateText = JSON.parse(period.dateText);
      const weekDayClass: string = getWeekDayClass(dayPeriod.dayIndex);
      classNames.push(weekDayClass);
    }

    return classNames.join(" ");
  };

  const periodSpecificClass: string = getPeriodSpecificClasses();

  return (
    <div
      className={clsx([
        styles.root,
        periodGroupClass,
        periodSpecificClass,
        { [styles.scheduleOverload]: props.isOverload },
      ])}
      style={{
        minWidth: `${periodFrequencyDef.minPeriodSize}px`,
      }}
    >
      {props.children}
    </div>
  );
};
