import React, { ReactElement, useContext } from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverFormSelect,
  IRiverSimpleSelectItem,
  IRiverAutocompleteOption,
} from "@river/common-ui";
import { AdapterRuleDto, IAdapterRule } from "@river/interfaces";
import { Constants } from "@river/constants";
import { RiverColorPicker } from "../../shared/river-color-picker";
import { RiverFormInstance } from "../../../hooks";
import { useVisualIndicatorsRuleForm } from "./use-visual-indicators-rule-form";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { RiverAutocompleteAsync } from "../../shared/river-autocomplete-async";
import { colorHelpers } from "../../../helpers";
import styles from "./visual-indicators-rule-dialog.module.scss";
import clsx from "clsx";

interface IVisualIndicatorsRuleDialogProps {
  open: boolean;
  rule: IAdapterRule | null;
  onClose: (success: boolean) => void;
}

export const VisualIndicatorsRuleDialog: React.FC<
  IVisualIndicatorsRuleDialogProps
> = (props): ReactElement => {
  const form: RiverFormInstance = useVisualIndicatorsRuleForm({
    rule: props.rule,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const ruleDto = form.dto as AdapterRuleDto;
  const { setDto, submit, resetForm, isCreate, onPropertyChange } = form;
  const { t } = useTranslation();

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    close(requireRefresh);
  };

  const close = (requiresRefresh?: boolean): void => {
    resetForm();
    props.onClose(!!requiresRefresh);
  };

  const renderEntityNameField = (): ReactElement => {
    const entityNames: string[] = ["workorder", "operation"];
    const items: IRiverSimpleSelectItem[] = entityNames.map((e) => {
      return { value: e, text: e };
    });
    return <RiverFormSelect id={"entity_name"} items={items} fullWidth />;
  };

  const renderRuleTypeField = (): ReactElement => {
    const ruleTypeItems: IRiverSimpleSelectItem[] = [
      {
        value: Constants.rule_type.gantt_color_rule,
        text: t("module.rules:label.gantt_color_rule"),
      },
      {
        value: Constants.rule_type.table_row_color_rule,
        text: t("module.rules:label.table_row_color_rule"),
      },
      {
        value: Constants.rule_type.table_cell_color_rule,
        text: t("module.rules:label.table_cell_color_rule"),
      },
    ];

    return (
      <RiverFormSelect
        id={"rule_type"}
        items={ruleTypeItems}
        onChangeEvent={onPropertyChange}
        fullWidth
      />
    );
  };

  const handleOnAttributeAutoCompleteSearch = async (): Promise<
    IRiverAutocompleteOption[]
  > => {
    try {
      const attributes = await adapterContext!.service
        .getAdapterService()
        .getEntityAttributeDefinitions(ruleDto.entity_name!);
      return attributes.map((attribute) => ({
        label: attribute.attribute_name,
        value: attribute.attribute_name,
      }));
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  const renderAttributeField = (): ReactElement => {
    return (
      <RiverAutocompleteAsync
        id={"attribute"}
        className={styles.attribute_name}
        label={t("entity.rule:rule.attribute")}
        noOptionsText={t("common.label:no_options_available")}
        initialValue={ruleDto.attribute}
        onChange={(value) => {
          setDto({ ...ruleDto, attribute: value?.value });
        }}
        onSearch={handleOnAttributeAutoCompleteSearch}
      />
    );
  };

  const renderColorHelperField = (): ReactElement => {
    const renderDefaultPreviewContent = (color: string): ReactElement => (
      <div
        className={styles.sampleTextPreview}
        style={{
          color: colorHelpers.getHighContrastFontColor({
            backgroundColor: color,
          }),
        }}
      >
        {t("module.rules:visual_indicators_rules.color_preview_sample_text")}
      </div>
    );
    return (
      <RiverColorPicker
        initialColor={JSON.parse(ruleDto.display_options!).color}
        onSelectColor={(color: string) => updateRuleDisplayOptions({ color })}
        className={styles.colorHelper}
        defaultPreviewContent={(color: string) => (
          <>
            {shouldRenderSampleTextPreview &&
              renderDefaultPreviewContent(color)}
          </>
        )}
      />
    );
  };

  const renderExpressionField = (): ReactElement => (
    <RiverTextInput
      id={"expression"}
      label={t("entity.rule:rule.expression")}
      fullWidth
      inputProps={{
        multiline: true,
        minRows: 6,
        maxRows: 12,
      }}
    />
  );

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.rules:label.create_rule")
      : t("module.rules:label.edit_rule");
  const getActionButtonText = (): string =>
    isCreate ? t("common.button:add") : t("common.button:save");

  const isTableCellColorRule: boolean =
    ruleDto.rule_type === Constants.rule_type.table_cell_color_rule;
  const shouldRenderSampleTextPreview: boolean =
    ruleDto.rule_type !== Constants.rule_type.gantt_color_rule;

  const updateRuleDisplayOptions = (newOptions: object): void => {
    const ruleDisplayOptions: object = JSON.parse(ruleDto.display_options!);
    Object.assign(ruleDisplayOptions, newOptions);
    const display_options: string = JSON.stringify(ruleDisplayOptions);
    setDto(
      Object.assign(new AdapterRuleDto(), {
        ...ruleDto,
        display_options,
      })
    );
  };

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={close}
      actionButtonText={getActionButtonText()}
      showActionsDivider={false}
      onSubmit={submit}
      classes={{
        paper: styles.paper,
        content: clsx([
          styles.content,
          { [styles.readOnly]: form?.isReadOnly },
        ]),
      }}
      form={form}
    >
      <RiverTextInput id={"rule"} fullWidth />
      {renderEntityNameField()}
      {renderRuleTypeField()}
      {isTableCellColorRule && renderAttributeField()}
      {renderColorHelperField()}
      {renderExpressionField()}
    </RiverDialog>
  );
};
