import React, { ReactElement, useContext } from "react";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSpinner,
  useEnv,
  IUseEnv,
} from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { RiverDataGrid } from "../../shared";
import { SiteDialogGridHeader } from "./site-dialog-grid-header";
import { SitesUiService } from "../../../services";
import { useTranslation } from "@river/common-ui";
import styles from "./site-dialog.module.scss";
import clsx from "clsx";
import { IEntityObject } from "@river/interfaces";

interface ISiteDialog {
  open: boolean;
  onClose: (erpSiteRec?: any) => void;
}

export const SiteDialog: React.FC<ISiteDialog> = (props): ReactElement => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;

  const { entity, table } = uiService.useSites()();

  const submit = (): void => {
    const selected = table.entities.filter((e: IEntityObject) =>
      table.selectedRowIds.has(table.rowKeyGetter!(e))
    )[0];
    props.onClose(selected);
  };

  const isLoading: boolean = table.isLoading;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <RiverDialog
        title={t("shared.planning_plant_selector:dialog.select_plant.title")}
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        classes={{
          paper: clsx([styles.paper, { [styles.mobile]: isMobile }]),
          content: styles.content,
          title: styles.dialogTitle,
          actions: styles.actions,
        }}
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
        actionsContent={
          <>
            <RiverDialogButton
              onClick={() => props.onClose()}
              text={t("common.button:cancel")}
            />
            <RiverDialogActionButton
              disabled={!table.selectedRowIds.size}
              onClick={submit}
              text={t("common.button:select")}
            />
          </>
        }
      >
        <RiverSpinner show={isLoading} />
        <SiteDialogGridHeader />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          className={styles.grid}
          columns={table.columns}
          rows={table.entities}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={table.rowKeyGetter}
        />
      </RiverDialog>
    </TableContext.Provider>
  );
};
