import React, { ReactElement, useContext } from "react";
import { CrewsUiService } from "../../../services";
import { RiverCustomSelector, TableFetchFunction } from "../../shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { IAdapterCrew } from "@river/interfaces";

const DEFAULT_IS_DIALOG: boolean = false;

interface ICrewCraftSelectorProps {
  crew: IAdapterCrew;
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export const CrewCraftSelector: React.FC<ICrewCraftSelectorProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService =
    adapterContext?.service!.getCrewsUiService()!;
  const { availableTableColumns, assignedTableColumns } =
    uiService.getCrewCraftSelectorColumns()();

  const fetchAvailableCrewCrafts: TableFetchFunction =
    uiService.getAvailableCrewCraftsFetchFn(props.crew.crew)();
  const fetchAssignedCrewCrafts: TableFetchFunction =
    uiService.getAssignedCrewCraftsFetchFn(props.crew.crew)();
  const assignCrewCrafts = uiService.getAssignCrewCraftsFn(props.crew.crew)();
  const unassignCrewCrafts = uiService.getUnassignCrewCraftsFn()();

  const isDialog: boolean = props.isDialog ?? DEFAULT_IS_DIALOG;

  return (
    <RiverCustomSelector
      isDialog={isDialog}
      open={props.open}
      onClose={props.onClose}
      dialogTitle={t("shared.crew_craft_selector:title")}
      availableEntityName={uiService.availableCrewCraftsEntityName}
      availableTableColumns={availableTableColumns}
      fetchAvailableObjects={fetchAvailableCrewCrafts}
      availableTableRowKeyGetter={uiService.availableCrewCraftsRowKeyGetter}
      assignedEntityName={uiService.assignedCrewCraftsEntityName}
      assignedTableColumns={assignedTableColumns}
      fetchAssignedObjects={fetchAssignedCrewCrafts}
      assignAction={assignCrewCrafts}
      unassignAction={unassignCrewCrafts}
    />
  );
};
