import React, { ReactElement, useContext, useState } from "react";
import {
  GridHeader,
  RiverTabs,
  ColumnSelectorDropdown,
  RefreshAction,
  GenericAction,
  GridActions,
  FilterDropdown,
} from "../../../shared";
import {
  SupervisorScheduleContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../../context";
import { SupervisorResourceSelector } from "../supervisor-resource-selector";
import { SupervisorCrewSelector } from "../supervisor-crew-selector";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "@river/common-ui";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
  CraftsUiService,
} from "../../../../services";
import styles from "./supervisor-schedule-utilization-grid-header.module.scss";

interface ISupervisorScheduleUtilizationGridHeaderProps {
  className?: string;
  selectedCraftIds: Set<string>;
  onChangeSelectedCrafts: (selectedCraftds: Set<string>) => void;
  selectedResourceIds: Set<string>;
  selectedCrewIds: Set<string>;
  onChangeSelectedResources: (selectedResourceIds: Set<string>) => void;
  onChangeSelectedCrews: (selectedCrewIds: Set<string>) => void;
}

export const SupervisorScheduleUtilizationGridHeader: React.FC<
  ISupervisorScheduleUtilizationGridHeaderProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const utilizationUiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;

  const [craftSelectorOpened, setCraftSelectorOpened] =
    useState<boolean>(false);
  const [resourceSelectorOpened, setResourceSelectorOpened] =
    useState<boolean>(false);
  const [crewSelectorOpened, setCrewSelectorOpened] = useState<boolean>(false);
  const scheduleContext = useContext(SupervisorScheduleContext);
  const currentTab: SupervisorScheduleUtilizationTabId =
    utilizationUiService.getCurrentTab()();

  const progressGridActionsRenderer =
    utilizationUiService.getProgressGridActionsRenderer()();
  const complianceGridActionsRenderer =
    utilizationUiService.getComplianceGridActionsRenderer()();

  return (
    <>
      <GridHeader>
        <RiverTabs
          tabs={utilizationUiService.getTabs()()}
          onChange={() => scheduleContext?.setSelectedValidationRule(null)}
        />
        <GridActions className={styles.gridActions}>
          {currentTab === SupervisorScheduleUtilizationTabId.METRICS && (
            <RefreshAction />
          )}
          {[
            SupervisorScheduleUtilizationTabId.BACKLOG,
            SupervisorScheduleUtilizationTabId.CRAFTS,
            SupervisorScheduleUtilizationTabId.PERSONS,
            SupervisorScheduleUtilizationTabId.CREWS,
          ].includes(currentTab) && (
            <>
              <ColumnSelectorDropdown />
              <FilterDropdown />
            </>
          )}
          {currentTab === SupervisorScheduleUtilizationTabId.CRAFTS && (
            <GenericAction
              icon={SettingsIcon}
              title={t("module.supervisor_schedule:action_label.crafts")}
              onClick={() => setCraftSelectorOpened(true)}
            />
          )}
          {currentTab === SupervisorScheduleUtilizationTabId.PERSONS && (
            <GenericAction
              icon={SettingsIcon}
              title={t(
                "module.supervisor_schedule:action_label.resource_selector"
              )}
              onClick={() => setResourceSelectorOpened(true)}
            />
          )}
          {currentTab === SupervisorScheduleUtilizationTabId.CREWS && (
            <GenericAction
              icon={SettingsIcon}
              title={t("module.supervisor_schedule:action_label.crew_selector")}
              onClick={() => setCrewSelectorOpened(true)}
            />
          )}
          {currentTab ===
            SupervisorScheduleUtilizationTabId.VALIDATION_RULES && (
            <RefreshAction
              onClick={() => scheduleContext?.setSelectedValidationRule(null)}
            />
          )}
          {currentTab === SupervisorScheduleUtilizationTabId.PROGRESS &&
            progressGridActionsRenderer()}
          {currentTab === SupervisorScheduleUtilizationTabId.COMPLIANCE &&
            complianceGridActionsRenderer()}
        </GridActions>
      </GridHeader>
      {craftsUiService.renderCraftsSelector({
        open: craftSelectorOpened,
        onClose: () => setCraftSelectorOpened(false),
        onChangeSelectedCrafts: props.onChangeSelectedCrafts,
        selectedIds: props.selectedCraftIds,
        isSupervisor: true,
      })}
      <SupervisorResourceSelector
        open={resourceSelectorOpened}
        onClose={() => setResourceSelectorOpened(false)}
        onChangeSelectedResources={props.onChangeSelectedResources}
        selectedIds={props.selectedResourceIds}
      />
      <SupervisorCrewSelector
        open={crewSelectorOpened}
        onClose={() => setCrewSelectorOpened(false)}
        onChangeSelectedCrews={props.onChangeSelectedCrews}
        selectedIds={props.selectedCrewIds}
      />
    </>
  );
};
