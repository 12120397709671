import { createContext } from "react";
import { IAdapterFolder } from "@river/interfaces";

export interface IBacklogContext {
  schedulePaneRefreshGuid: string | undefined;
  refreshSchedulePane: () => void;
  currentSchedule: IAdapterFolder | null;
  refreshCurrentTable: () => void;
}

export const BacklogContext = createContext<IBacklogContext | null>(null);
