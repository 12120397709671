import { DragEvent, useCallback } from "react";

export namespace helpers {
  export function replaceOrSetUrlParam(
    baseUrl: string,
    searchParams: URLSearchParams,
    replacementKey: string,
    value: string
  ): string {
    const searchParamsCopy = new URLSearchParams(searchParams);

    searchParamsCopy.set(replacementKey, value);

    let url = baseUrl;

    const searchParamsString = searchParamsCopy.toString();
    if (searchParamsString) {
      url += `?${searchParamsString}`;
    }

    return url;
  }

  export function parseDragData(dragEvent: DragEvent<any>): {
    [index: string]: any;
  } {
    try {
      if (!dragEvent.dataTransfer) {
        return {};
      }
      const value = dragEvent.dataTransfer.getData("text");
      return JSON.parse(value);
    } catch (err) {
      return {};
    }
  }

  export function formatTime(hours: number, minutes: number): string {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  // Stolen from: https://github.com/adazzle/react-data-grid/blob/ec2805865dab79f7abcda0208d87f12a5019dda4/src/hooks/useCombinedRefs.ts#L3
  export function useCombinedRefs<T>(...refs: readonly React.Ref<T>[]) {
    return useCallback(
      (handle: T | null) => {
        for (const ref of refs) {
          if (typeof ref === "function") {
            ref(handle);
          } else if (ref !== null && "current" in ref) {
            // @ts-expect-error: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
            ref["current"] = handle;
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      refs
    );
  }

  export function clone<Type>(query: Type): Type {
    return JSON.parse(JSON.stringify(query)) as Type;
  }
}
