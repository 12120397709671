import React, {
  MouseEvent,
  ReactElement,
  useContext,
  useRef,
  useState,
} from "react";
import { GanttConnector, GanttConnectorPosition } from "../gantt-connector";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TabContext,
  TableContext,
} from "../../../../../context";
import { useEntityHelpers } from "../../../../../helpers";
import styles from "./gantt-task.module.scss";
import clsx from "clsx";
import {
  ScheduleAction,
  ScheduleTasksTabId,
  TableUiService,
  TaskHelpersUiService,
} from "../../../../../services";
import { IUseScheduleGantt } from "../../use-schedule-gantt";
import { useAllowedAction } from "../../../../protected-action";
import { ModuleKey } from "../../../../sidebar-menu";

const DEFAULT_TASK_COLOR: string = "#63778d";

interface IGanttTaskProps {
  row: any;
  getTaskColor: (row: any) => string;
}

export const GanttTask: React.FC<IGanttTaskProps> = (props): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const scheduleContext = useContext(ScheduleContext);
  const tableContext = useContext(TableContext)!;
  const tabContext = useContext(TabContext);

  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const entityHelpers = useEntityHelpers();

  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const {
    getStartDateFieldName,
    getEndDateFieldName,
    getGanttText,
    getTaskStartDate,
    getTaskEndDate,
  } = taskHelpers.getHelpers()();

  const isScheduleActionAllowed = useAllowedAction()(
    ModuleKey.SCHEDULES,
    ScheduleAction.SCHEDULE
  );

  const isOperationsTab = (): boolean =>
    tabContext?.selectedTab === ScheduleTasksTabId.OPERATIONS;

  const gantt: IUseScheduleGantt = scheduleContext?.ganttRef.current!;
  const { MIN_SCHEDULE_TASK_WIDTH: MIN_SCHEDULE_TASK_WIDTH_PERCENT } =
    gantt.ganttRatios!;
  const operationsOverlay = scheduleContext?.operationsOverlayRef.current;

  const taskRef = useRef<HTMLDivElement>(null);
  const { row, getTaskColor } = props;

  const [dragPositionPercent, setDragPositionPercent] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const isDragAllowed = (): boolean => isScheduleActionAllowed;

  const taskColor: string = getTaskColor!(props.row) || DEFAULT_TASK_COLOR;
  const taskName: string = getGanttText(props.row) ?? "";

  const taskStartDate: Date = new Date(getTaskStartDate(props.row));
  const taskEndDate: Date =
    new Date(getTaskEndDate(props.row)) || taskStartDate;

  const taskXPercent: number = isDragging
    ? dragPositionPercent
    : gantt.getXPercentFromDate(taskStartDate ?? new Date());

  const taskWidthPercent: number = Math.max(
    gantt.getXPercentFromDate(taskEndDate ?? new Date()) -
      gantt.getXPercentFromDate(taskStartDate ?? new Date()),
    MIN_SCHEDULE_TASK_WIDTH_PERCENT
  );

  const renderConnector = (
    connectorPosition: GanttConnectorPosition
  ): ReactElement => (
    <GanttConnector
      taskRef={taskRef}
      taskXPercent={taskXPercent}
      taskWidthPercent={taskWidthPercent}
      position={connectorPosition}
      row={row}
    />
  );

  const getTaskDatesTooltip = (): string => {
    const startDateFieldName: string = getStartDateFieldName(row);
    const endDateFieldName: string = getEndDateFieldName(row);
    const startDate: string = entityHelpers.getFormattedEntityAttribute(
      row,
      startDateFieldName,
      tableContext!
    );
    const endDate: string = entityHelpers.getFormattedEntityAttribute(
      row,
      endDateFieldName,
      tableContext!
    );
    return `${startDate} - ${endDate}`;
  };

  const onMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (!isDragAllowed()) return;

    const taskEl = taskRef.current!;
    const ganttContainerRect: DOMRect =
      taskEl!.parentElement!.getBoundingClientRect();

    setIsDragging(true);

    const initialEventXPercent: number = getGanttXPercentFromMouseEvent({
      event,
      taskEl,
      ganttContainerRect,
    });
    const taskXOffsetPercent: number = initialEventXPercent - taskXPercent;

    setDragPositionPercent(taskXPercent);

    document.onmousemove = (event) => {
      const pointerGantXPercent: number = getGanttXPercentFromMouseEvent({
        event,
        taskEl,
        ganttContainerRect,
      });
      const newTaskXPercent: number = pointerGantXPercent - taskXOffsetPercent;

      setDragPositionPercent(newTaskXPercent);

      if (isOperationsTab()) {
        operationsOverlay?.setDraggedObject({
          object: props.row,
          xPercent: newTaskXPercent,
        });
      }
    };

    document.onmouseup = (event) => {
      setIsDragging(false);
      const pointerGantXPercent: number = getGanttXPercentFromMouseEvent({
        event,
        taskEl,
        ganttContainerRect,
      });
      const newTaskXPercent: number = pointerGantXPercent - taskXOffsetPercent;

      const newDate: Date = gantt.getDateFromXPercent(newTaskXPercent);
      const roundedMinutes = Math.round(newDate.getMinutes() / 30) * 30;
      newDate.setMinutes(roundedMinutes);
      newDate.setSeconds(0);
      if (newDate) {
        gantt.skipRenderChecksRef.current = true;
        gantt.scheduleFn(newDate, [getObjectId(props.row)]);
      }
      document.onmousemove = null;
      document.onmouseup = null;
      if (isOperationsTab()) {
        operationsOverlay?.setDraggedObject(null);
      }
      document.onmousemove = null;
      document.onmouseup = null;
    };

    function getGanttXPercentFromMouseEvent(props: {
      event: globalThis.MouseEvent | MouseEvent;
      taskEl: HTMLDivElement;
      ganttContainerRect?: DOMRect; // pass where possible to avoid performance hits by calls to getBoundingClientRect()
    }): number {
      const { event, taskEl, ganttContainerRect } = props;
      const ganttRect: DOMRect =
        ganttContainerRect ?? taskEl!.parentElement!.getBoundingClientRect();
      const { left: ganttXPos, width: ganttWidth } = ganttRect;
      const { clientX: eventXPos } = event;
      return ((eventXPos - ganttXPos) / ganttWidth) * 100;
    }
  };

  const renderTask = (): ReactElement => (
    <div
      ref={taskRef}
      className={clsx([styles.task, { [styles.movable]: isDragAllowed }])}
      style={{
        left: `${taskXPercent}%`,
        width: `${taskWidthPercent}%`,
        backgroundColor: taskColor,
      }}
      onMouseDown={onMouseDown}
      title={getTaskDatesTooltip()}
    >
      <div className={styles.label}>{taskName}</div>
    </div>
  );

  return (
    <div className={styles.taskContainer}>
      {renderConnector(GanttConnectorPosition.LEFT)}
      {renderTask()}
      {renderConnector(GanttConnectorPosition.RIGHT)}
    </div>
  );
};
