import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { IEntityObject } from "@river/interfaces";
import { useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { SapAdapterService } from "../../../services/sap";
import { useTranslation } from "@river/common-ui";
import { useAllowedAction } from "../../protected-action";
import { WorkRequestsAction } from "../notifications";
import { ModuleKey } from "../../sidebar-menu";

export const useNotificationForm = (props: {
  notification: IEntityObject | null;
  onCreate: (notification: IEntityObject | null) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<IEntityObject, IEntityObject, Object>({
    initialDto: Object.assign({
      NotifType: "M1",
      Equipment: "",
      ShortText: "",
      Priority: "",
      Plangroup: "",
      PmWkctr: "",
      MainWorkCenter: "",
    }),
    instanceToEdit: props.notification,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: IEntityObject): Promise<any> => {
      const createDto = {
        ...dto,
      };

      delete createDto.MainWorkCenter;
      return await (
        adapterContext!.service.getAdapterService() as SapAdapterService
      ).createNotification(createDto);
    },
    update: async (dto: IEntityObject): Promise<void> => {
      const updateDto: any = {
        NotifNo: props.notification?.NotifNo,
        ...dto,
      };
      delete updateDto.NotifType;
      delete updateDto.MainWorkCenter;
      await (
        adapterContext!.service.getAdapterService() as SapAdapterService
      ).updateNotification(updateDto);
    },
    labels: {
      Equipment: t("entity.notification:notification.Equipment"),
      ShortText: t("entity.notification:notification.ShortText"),
      Plangroup: t("entity.notification:notification.Plangroup"),
      MainWorkCenter: t("entity.notification:notification.MainWorkCenter"),
      Priority: t("entity.notification:notification.Priority"),
    },
    readOnly: !isActionAllowed(
      ModuleKey.WORK_REQUESTS,
      WorkRequestsAction.UPDATE
    ),
  });
};
