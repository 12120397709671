import { ReactElement, useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import RuleIcon from "@mui/icons-material/Rule";
import { useSimpleDialog } from "@river/common-ui";
import { IScheduleContext, ScheduleContext } from "../../../context";
import { useAsyncAutoSchedule } from "../../../components/shared";
import headerStyles from "../../../components/shared/common-header/common-header.module.scss";

export const useAutoScheduleAction = () => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();
  const scheduleContext: IScheduleContext = useContext(ScheduleContext)!;

  const asyncAutoSchedule = useAsyncAutoSchedule();
  const { doAutoSchedule, autoScheduleProgress } = asyncAutoSchedule;

  const title: string = t("module.schedule:message.auto_schedule.title");

  const onClick = (): void => autoScheduleConfirmationDialog.open();

  const renderIcon = (props?: any): ReactElement => (
    <RuleIcon
      titleAccess={title}
      className={headerStyles.actionIcon}
      onClick={onClick}
      {...props}
    />
  );

  const renderConfirmationDialog = (): ReactElement =>
    autoScheduleConfirmationDialog.render();

  const renderAutoScheduleAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderConfirmationDialog()}
      {autoScheduleProgress.renderDialog()}
    </>
  );

  const autoScheduleConfirmationDialog = useSimpleDialog({
    title: t("module.schedule:message.auto_schedule.title"),
    message: t("module.schedule:message.confirm_auto_schedule"),
    confirmButtonText: t("common.button:confirm"),
    onConfirm: () => {
      const {
        currentSchedule,
        currentTasksTableRef,
        scheduleUtilizationCurrentTableState,
      } = scheduleContext;
      doAutoSchedule({
        schedule: currentSchedule!,
        taskTable: currentTasksTableRef.current!,
        utilizationTable: scheduleUtilizationCurrentTableState!,
      });
    },
  });

  const renderDialogs = (): ReactElement => (
    <>
      {renderConfirmationDialog()}
      {autoScheduleProgress.renderDialog()}
    </>
  );

  return {
    renderAutoScheduleAction,
    autoScheduleAction: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
      renderDialogs,
    },
  };
};

export type IUseAutoScheduleAction = ReturnType<typeof useAutoScheduleAction>;
