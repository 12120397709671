import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverFormSelect,
  useNotification,
} from "@river/common-ui";
import { IAdapterRole, LicenseDto } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useRoleForm } from "./use-role-form";
import styles from "./role-dialog.module.scss";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";

interface RoleDialogProps {
  open: boolean;
  role?: IAdapterRole;
  onClose: (role: IAdapterRole | null) => void;
}

export const RoleDialog: React.FC<RoleDialogProps> = (props): ReactElement => {
  const form: RiverFormInstance = useRoleForm({
    role: props.role,
    onCreate: (role) => {
      props.onClose(role);
      resetForm();
    },
    onUpdate: () => props.onClose(null),
  });
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();
  const { t } = useTranslation();

  const { submit, resetForm } = form;

  const [licenses, setLicenses] = useState<LicenseDto[]>([]);

  const getLicenses = async () => {
    try {
      const result = await adapterContext?.service
        .getAdapterService()
        .fetchLicenses();
      if (result) {
        setLicenses(result);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    getLicenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RiverDialog
      classes={{
        paper: styles.root,
      }}
      title={t("module.roles:dialog.create_role.title")}
      open={props.open}
      onClose={() => props.onClose(null)}
      actionButtonText={t("common.button:next")}
      onSubmit={submit}
      showActionsDivider={false}
      fullWidth
      form={form}
    >
      <RiverTextInput id={"role"} fullWidth />
      <RiverTextInput id={"description"} fullWidth />
      <RiverFormSelect
        id={"license"}
        items={licenses.map((item) => {
          return {
            text: `${item.license} (${item.count})`,
            value: item.license,
          };
        })}
        fullWidth
      />
    </RiverDialog>
  );
};
