import { useTranslation } from "@river/common-ui";
import {
  ColumnsProvider,
  RiverFormInstanceProvider,
} from "../../ui-service.types";
import {
  IUseUserRolesFormProps,
  UserRolesUiService,
} from "../../user-roles-ui.service";
import { AdapterUserDto, IEntityObject } from "@river/interfaces";
import { useSapUserRoleForm } from "./use-sap-user-role-form";
import { useTableCellRenderers } from "../../../hooks";

export class SapUserRolesUiService extends UserRolesUiService {
  getUserRolesTableColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderTextCell } = useTableCellRenderers();

      return [
        { key: "user_name", name: t("entity.user_role:user_role.user_name") },
        { key: "first_name", name: t("entity.user_role:user_role.first_name") },
        { key: "last_name", name: t("entity.user_role:user_role.last_name") },
        {
          key: "display_name",
          name: t("entity.user_role:user_role.display_name"),
        },
        {
          key: "roles",
          name: t("entity.user_role:user_role.roles"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: (formatterProps.row.roles as [])?.join(", "),
              })}
            </>
          ),
        },
        { key: "created_at", name: t("entity.user_role:user_role.created_at") },
        { key: "updated_at", name: t("entity.user_role:user_role.updated_at") },
        {
          key: "is_config_admin",
          name: t("entity.user_role:user_role.is_config_admin"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: formatterProps.row.is_config_admin
                  ? t("common.button:yes")
                  : t("common.button:no"),
              })}
            </>
          ),
        },
      ];
    };
  }

  protected getCreateUserRoleDto = (user: IEntityObject): AdapterUserDto =>
    ({
      user_name: user.UserName,
      email: user.EMail,
      first_name: user.FirstName,
      last_name: user.LastName,
      display_name: user.FullName,
      roles: [],
    }) as AdapterUserDto;

  getUserRolesForm =
    (props: IUseUserRolesFormProps): RiverFormInstanceProvider =>
    () =>
      useSapUserRoleForm(props);
}
