import { MutableRefObject, useEffect, useState } from "react";
import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  IOracleEbsAdapterWorkorderCompletion,
} from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { Equals, IsNotEmpty } from "class-validator";
import { IsConditionallyRequired } from "./validators";
import {
  useWoStatusLookup,
  useReconciliationCodeLookup,
  useFailureCodeLookup,
  useCauseCodeLookup,
  useResolutionCodeLookup,
  useLocatorLookup,
  useLotLookup,
  useSubinventoryLookup,
} from "./lookups";

export interface IOracleEbsWoCompletionStandaloneFields {
  workOrderStatus?: string;
  reconciliationMeaning?: string;
  subinventoryDisplay?: string;
  locatorCode?: string;
  lotDisplay?: string;
  failureCodeDisplay?: string;
  causeCodeDisplay?: string;
  resolutionCodeDisplay?: string;
}

interface IUseEbsWoCompletionFormProps {
  formRef: MutableRefObject<RiverFormInstance | null>;
}

export function useEbsWoCompletionForm(props: IUseEbsWoCompletionFormProps) {
  const { t } = useTranslation();
  const { formRef } = props;
  const [selectedWoStatus, setSelectedWoStatus] = useState<any>(null);
  const [selectedReconciliationCode, setSelectedReconciliationCode] =
    useState<any>(null);
  const [selectedSubinventory, setSelectedSubinventory] = useState<any>(null);
  const [selectedFailureCode, setSelectedFailureCode] = useState<any>(null);
  const [selectedCauseCode, setSelectedCauseCode] = useState<any>(null);
  const [selectedResolutionCode, setSelectedResolutionCode] =
    useState<any>(null);
  const [selectedLocator, setSelectedLocator] = useState<any>(null);
  const [selectedLot, setSelectedLot] = useState<any>(null);

  class OracleEbsWoCompletionStandaloneValidator {
    constructor(obj: IOracleEbsWoCompletionStandaloneFields) {
      Object.assign(this, obj);
    }

    @Equals(selectedWoStatus?.["WORK_ORDER_STATUS"] || "", {
      message: (opts): string => `shared.error_messages:validation.wo_status`,
    })
    @IsNotEmpty()
    // @ts-ignore
    workOrderStatus: string;

    @Equals(selectedReconciliationCode?.["MEANING"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    // @ts-ignore
    reconciliationMeaning: string;

    @Equals(selectedFailureCode?.["FAILURE_CODE"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    @IsConditionallyRequired({
      context: { formRef, referenceField: "FAILURE_CODE_REQUIRED" },
    })
    // @ts-ignore
    failureCodeDisplay: string;

    @Equals(selectedCauseCode?.["CAUSE_CODE"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    @IsConditionallyRequired({
      context: { formRef, referenceField: "FAILURE_CODE" },
    })
    // @ts-ignore
    causeCodeDisplay: string;

    @Equals(selectedResolutionCode?.["RESOLUTION_CODE"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    @IsConditionallyRequired({
      context: { formRef, referenceField: "FAILURE_CODE" },
    })
    // @ts-ignore
    resolutionCodeDisplay: string;

    @IsConditionallyRequired({
      context: { formRef, referenceField: "FAILURE_CODE" },
    })
    // @ts-ignore
    FAILURE_DATE: string;

    @Equals(selectedLocator?.["LOCATOR_CODE"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    // @ts-ignore
    locatorCode: string;

    @Equals(selectedLot?.["LOT_NUMBER"] || "", {
      message: (opts): string => `shared.error_messages:validation.invalid_lot`,
    })
    // @ts-ignore
    lotDisplay: string;

    @Equals(selectedSubinventory?.["SUBINVENTORY_CODE"] || "", {
      message: (opts): string =>
        `shared.error_messages:validation.invalid_code`,
    })
    // @ts-ignore
    subinventoryDisplay: string;
  }

  const form: RiverFormInstance = useRiverForm<
    OracleEbsAdapterWorkorderCompletionDto,
    IOracleEbsAdapterWorkorderCompletion,
    Object
  >({
    initialDto: Object.assign(new OracleEbsAdapterWorkorderCompletionDto(), {
      STATUS_TYPE: "",
      ACTUAL_START_DATE: "",
      SHUTDOWN_START_DATE: "",
      ACTUAL_END_DATE: "",
      SHUTDOWN_END_DATE: "",
      SUBINVENTORY_CODE: "",
      LOCATOR_ID: "",
      LOT_NUMBER: "",
      RECONCILIATION_CODE: "",
      COMPLETION_COMMENTS: "",
      FAILURE_CODE: "",
      FAILURE_DATE: "",
      CAUSE_CODE: "",
      FAILURE_COMMENTS: "",
      RESOLUTION_CODE: "",
      METER_READINGS: [],
    }),
    entityName: "wo_completion",
    labels: {
      FAILURE_CODE_REQUIRED: t(
        t("entity.wo_completion:wo_completion.FAILURE_CODE_REQUIRED")
      ),
      ACTUAL_START_DATE: t(
        t("entity.wo_completion:wo_completion.ACTUAL_START_DATE")
      ),
      SHUTDOWN_START_DATE: t(
        t("entity.wo_completion:wo_completion.SHUTDOWN_START_DATE")
      ),
      ACTUAL_END_DATE: t(
        t("entity.wo_completion:wo_completion.ACTUAL_END_DATE")
      ),
      SHUTDOWN_END_DATE: t(
        t("entity.wo_completion:wo_completion.SHUTDOWN_END_DATE")
      ),
      SUBINVENTORY_CODE: t(
        t("entity.wo_completion:wo_completion.SUBINVENTORY_CODE")
      ),
      LOCATOR_ID: t(t("entity.wo_completion:wo_completion.LOCATOR_ID")),
      LOT_NUMBER: t(t("entity.wo_completion:wo_completion.LOT_NUMBER")),
      RECONCILIATION_CODE: t(
        t("entity.wo_completion:wo_completion.RECONCILIATION_CODE")
      ),
      COMPLETION_COMMENTS: t(
        t("entity.wo_completion:wo_completion.COMPLETION_COMMENTS")
      ),
      FAILURE_CODE: t(t("entity.wo_completion:wo_completion.FAILURE_CODE")),
      CAUSE_CODE: t(t("entity.wo_completion:wo_completion.CAUSE_CODE")),
      RESOLUTION_CODE: t(
        t("entity.wo_completion:wo_completion.RESOLUTION_CODE")
      ),
      FAILURE_DATE: t(t("entity.wo_completion:wo_completion.FAILURE_DATE")),
      FAILURE_COMMENTS: t(
        t("entity.wo_completion:wo_completion.FAILURE_COMMENTS")
      ),
      workOrderStatus: t("entity.wo_completion:wo_completion.STATUS_TYPE"),
      reconciliationMeaning: t(
        "entity.wo_completion:wo_completion.RECONCILIATION_CODE"
      ),
      subinventoryDisplay: t(
        "entity.wo_completion:wo_completion.SUBINVENTORY_CODE"
      ),
      locatorCode: t("entity.wo_completion:wo_completion.LOCATOR_CODE"),
      lotDisplay: t("entity.wo_completion:wo_completion.LOT_NUMBER"),
      failureCodeDisplay: t("entity.wo_completion:wo_completion.FAILURE_CODE"),
      causeCodeDisplay: t("entity.wo_completion:wo_completion.CAUSE_CODE"),
      resolutionCodeDisplay: t(
        "entity.wo_completion:wo_completion.RESOLUTION_CODE"
      ),
      // External labels
      "WO.WIP_ENTITY_NAME": t("entity.workorder:workorder.WIP_ENTITY_NAME"),
      "WO.DESCRIPTION": t("entity.workorder:workorder.DESCRIPTION"),
      "WO.ASSET_NUMBER": t("entity.workorder:workorder.ASSET_NUMBER"),
      "WO.ASSET_DESCRIPTION": t("entity.workorder:workorder.ASSET_DESCRIPTION"),
      "WO.WORK_ORDER_STATUS": t("entity.workorder:workorder.WORK_ORDER_STATUS"),
    },
    standalone: {
      fields: new OracleEbsWoCompletionStandaloneValidator({
        workOrderStatus: "",
        reconciliationMeaning: "",
        subinventoryDisplay: "",
        locatorCode: "",
        lotDisplay: "",
        failureCodeDisplay: "",
        causeCodeDisplay: "",
        resolutionCodeDisplay: "",
      }),
      fieldDefs: [
        { fieldName: "workOrderStatus", dataType: "string" },
        { fieldName: "reconciliationMeaning", dataType: "string" },
        { fieldName: "subinventoryDisplay", dataType: "string" },
        { fieldName: "locatorCode", dataType: "string" },
        { fieldName: "lotDisplay", dataType: "string" },
        { fieldName: "failureCodeDisplay", dataType: "string" },
        { fieldName: "causeCodeDisplay", dataType: "string" },
        { fieldName: "resolutionCodeDisplay", dataType: "string" },
      ],
      getValidatorInstance: (obj: IOracleEbsWoCompletionStandaloneFields) =>
        new OracleEbsWoCompletionStandaloneValidator(obj),
    },
  });

  formRef.current = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;
  const standalone: IOracleEbsWoCompletionStandaloneFields =
    form.standalone as IOracleEbsWoCompletionStandaloneFields;

  const { render: renderWoStatusLookup } = useWoStatusLookup({
    form,
    selectedWoStatus,
    setSelectedWoStatus,
  });

  const { render: renderReconciliationCodeLookup } =
    useReconciliationCodeLookup({
      form,
      selectedReconciliationCode,
      setSelectedReconciliationCode,
    });

  const { render: renderFailureCodeLookup } = useFailureCodeLookup({
    form,
    selectedFailureCode,
    setSelectedFailureCode,
  });

  const { render: renderCauseCodeLookup, reFetch: refetchCauseCodeLookup } =
    useCauseCodeLookup({
      form,
      selectedCauseCode,
      setSelectedCauseCode,
    });

  const {
    render: renderResolutionCodeLookup,
    reFetch: refetchResolutionCodeLookup,
  } = useResolutionCodeLookup({
    form,
    selectedResolutionCode,
    setSelectedResolutionCode,
  });

  useEffect(() => {
    const causeCodeDisplay: string = standalone.causeCodeDisplay!;
    const resolutionCodeDisplay: string = standalone.resolutionCodeDisplay!;
    if (causeCodeDisplay) {
      refetchCauseCodeLookup();
    }
    if (resolutionCodeDisplay) {
      refetchResolutionCodeLookup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDto.FAILURE_CODE]);

  useEffect(() => {
    const resolutionCodeDisplay: string = standalone.resolutionCodeDisplay!;
    if (resolutionCodeDisplay) {
      refetchResolutionCodeLookup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDto.CAUSE_CODE]);

  const { render: renderLocatorLookup } = useLocatorLookup({
    form,
    selectedLocator,
    setSelectedLocator,
  });

  const { render: renderLotLookup } = useLotLookup({
    form,
    selectedLot,
    setSelectedLot,
  });

  const { render: renderSubinventoryLookup } = useSubinventoryLookup({
    form,
    selectedSubinventory,
    setSelectedSubinventory,
  });

  return {
    form,
    renderWoStatusLookup,
    renderReconciliationCodeLookup,
    renderFailureCodeLookup,
    renderCauseCodeLookup,
    renderResolutionCodeLookup,
    renderLocatorLookup,
    renderLotLookup,
    renderSubinventoryLookup,
    setSelectedFailureCode,
    setSelectedCauseCode,
    setSelectedResolutionCode,
  };
}
