import React, {
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { ReportChartCard } from "./report-chart-card";
import {
  IReportProgressCardAttr,
  ReportProgressCard,
} from "./report-progress-card";
import { ScheduleReportHeader } from "./schedule-report-header";
import {
  RiverSpinner,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import {
  IAdapterBaseline,
  IAdapterFolder,
  IAdapterSchedulingComplianceSummary,
  IEntityObject,
} from "@river/interfaces";
import {
  AdapterUiContext,
  ScheduleReportContext,
  TableContext,
} from "../../context";
import { IUseTable, LookupDialog, LookupType } from "../shared";
import { ReportBaselineSelector } from "./report-baseline-selector";
import { ScheduleProgressChart } from "./schedule-progress-chart";
import { useScheduleReportUtilizationProgressSummary } from "../../services/schedule-report-utilization-ui";
import { ScheduleResourcesChart } from "./schedule-resources-chart";
import { ScheduleKpis } from "../shared/schedule-kpis";
import {
  IScheduleProgressFilters,
  userPreferencesService,
} from "../../services";
import { Constants } from "@river/constants";
import { useEntityHelpers } from "../../helpers";
import styles from "./schedule-report.module.scss";
import clsx from "clsx";

export interface IScheduleReportDetail {
  label: string;
  value: string;
}

export interface IScheduleReportKpi {
  label: string;
  value: string;
}

export const scheduleReportStatus = {
  [Constants.schedule_statuses.draft]: {
    statusText: "shared.schedule_status:draft",
  },
  [Constants.schedule_statuses.committed]: {
    statusText: "shared.schedule_status:committed",
  },
  [Constants.schedule_statuses.in_progress]: {
    statusText: "shared.schedule_status:in_progress",
  },
  [Constants.schedule_statuses.complete]: {
    statusText: "shared.schedule_status:complete",
  },
  [Constants.schedule_statuses.closed]: {
    statusText: "shared.schedule_status:closed",
  },
};

export const ScheduleReport: FC = () => {
  const { t } = useTranslation();
  const entityHelpers = useEntityHelpers();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const adapterContext = useContext(AdapterUiContext);
  const progressTableRef = useRef<IUseTable>();
  const [showChangeScheduleDialog, setShowChangeDialog] =
    useState<boolean>(false);
  const [currentSchedule, setCurrentSchedule] = useState<
    IAdapterFolder | undefined
  >(undefined);
  const [selectedBaseline, setSelectedBaseline] = useState<
    IAdapterBaseline | undefined
  >();
  const [detailsInfo, setDetailsInfo] = useState<IScheduleReportDetail[]>([]);
  const [complianceData, setComplianceData] =
    useState<IAdapterSchedulingComplianceSummary | null>(null);

  const blankProgressSummaryOptions = (): IScheduleProgressFilters => {
    return { wo_types: [], departments: [], resources: [] };
  };
  const [progressSummaryFilters, setProgressSummaryFilters] =
    useState<IScheduleProgressFilters>(blankProgressSummaryOptions());
  const [progressFiltersOpened, setProgressFiltersOpened] =
    useState<boolean>(false);
  const notify = useNotification();

  const scheduleReportProgressSummaryTable =
    useScheduleReportUtilizationProgressSummary({
      currentBaseline: selectedBaseline,
      progressSummaryFilters,
      currentSchedule,
    });

  function resetData() {
    setSelectedBaseline(undefined);
    setDetailsInfo([]);
    setComplianceData(null);
  }

  async function fetchComplianceData() {
    try {
      if (!!currentSchedule) {
        const data = await adapterContext!.service
          .getAdapterService()
          .fetchFolderSchedulingCompliance(
            currentSchedule._id,
            selectedBaseline?._id || null
          );

        //@ts-ignore
        setComplianceData(data[0]);
      }
    } catch (message) {
      notify.error({ message });
    }
  }
  const getPreferredScheduleId = async (): Promise<string> =>
    await userPreferencesService.getReportScheduleId(
      adapterContext!.service.getAdapterService()
    );

  const setPreferredScheduleId = async (scheduleId: string): Promise<string> =>
    await userPreferencesService.setReportScheduleId(
      adapterContext!.service.getAdapterService(),
      scheduleId
    );

  async function loadCurrentSchedule(scheduleId: string) {
    try {
      setIsLoading(true);
      const schedule = await adapterContext!.service
        .getAdapterService()
        .getFolderById(scheduleId);
      setCurrentSchedule(schedule);
      setPreferredScheduleId(scheduleId);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  }

  function renderTitleSection(
    title: string,
    renderAdditionalContent?: () => ReactNode
  ) {
    return (
      <Box className={styles.titleSection}>
        <Typography className={styles.title}>{title}</Typography>
        {!!renderAdditionalContent && (
          <Box className={styles.titleSectionAdditional}>
            {renderAdditionalContent()}
          </Box>
        )}
      </Box>
    );
  }

  const renderWorkCompletionHeaderOptions = () => {
    return currentSchedule ? (
      <Box className={styles.workCompletionHeaderOptions}>
        <ReportBaselineSelector />
      </Box>
    ) : (
      <></>
    );
  };

  function renderDetailListItem(item: IScheduleReportDetail, index: number) {
    return (
      <ListItem className={styles.listItem} key={index}>
        <Box className={styles.listItemLabel}>
          <Typography>{item.label}</Typography>
        </Box>
        <Box className={styles.listItemValue}>
          <Typography>{item.value}</Typography>
        </Box>
      </ListItem>
    );
  }

  const renderScheduleSelector = () => {
    const onSubmit = async (schedules: IEntityObject[]) => {
      if (schedules.length) {
        const schedule = schedules[0];
        if (schedule._id) {
          await loadCurrentSchedule(`${schedule._id}`);
          setSelectedBaseline(undefined);
        }
      }
      setShowChangeDialog(false);
    };

    return (
      <>
        <IconButton onClick={() => setShowChangeDialog(true)}>
          <SearchIcon className={styles.lookupIcon} />
        </IconButton>
        <LookupDialog
          lookup={{
            type: LookupType.SCHEDULES,
            selectedRowIds: currentSchedule?._id ? [currentSchedule._id] : [],
          }}
          singleSelect={true}
          open={showChangeScheduleDialog}
          onClose={() => setShowChangeDialog(false)}
          onSubmit={onSubmit}
        />
      </>
    );
  };

  const renderScheduleNameSection = () => (
    <div className={styles.scheduleNameContainer}>
      <div className={styles.scheduleNameLabel}>
        {currentSchedule?.folder
          ? currentSchedule.folder
          : t("module.schedule_report:schedule_selection.no_selected_schedule")}
      </div>
      {renderScheduleSelector()}
    </div>
  );

  const loadPreferredSchedule = async (): Promise<void> => {
    const preferredScheduleId: string = await getPreferredScheduleId();
    if (preferredScheduleId) {
      await loadCurrentSchedule(preferredScheduleId);
    }
  };

  useEffect(() => {
    loadPreferredSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScheduleDetails = (): IScheduleReportDetail[] => {
    const formatDate = (date: string): string =>
      entityHelpers.formatDateStringAsUTC(date);

    return [
      {
        label: t(
          "module.schedule_report:section.scheduling_summary.details.duration"
        ),
        value: currentSchedule?.duration || "",
      },
      {
        label: t(
          "module.schedule_report:section.scheduling_summary.details.start_date"
        ),
        value: currentSchedule?.start_date
          ? formatDate(currentSchedule.start_date.toString())
          : "",
      },
      {
        label: t(
          "module.schedule_report:section.scheduling_summary.details.end_date"
        ),
        value: currentSchedule?.end_date
          ? formatDate(currentSchedule.end_date.toString())
          : "",
      },
      {
        label: t(
          "module.schedule_report:section.scheduling_summary.details.status"
        ),
        value: scheduleReportStatus[currentSchedule?.status?.code!]
          ? t(scheduleReportStatus[currentSchedule!.status.code].statusText)
          : "",
      },
    ];
  };

  useEffect(() => {
    resetData();
    if (!!currentSchedule) {
      setDetailsInfo(getScheduleDetails());
      fetchComplianceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule]);

  useEffect(() => {
    fetchComplianceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBaseline]);

  const renderReportProgressCard = (props: {
    title: string;
    value: number;
    attrA: IReportProgressCardAttr;
    attrB: IReportProgressCardAttr;
  }): ReactElement => {
    const { title, value, attrA, attrB } = props;
    return (
      <Grid item xs={2} className={styles.progressCardGridItem}>
        <ReportProgressCard
          title={title}
          mainValue={+value.toFixed(2)}
          attrA={attrA}
          attrB={attrB}
        />
      </Grid>
    );
  };

  const renderPlanningEffectiveness = () => {
    const value: number = complianceData?.scheduled_workorders
      ? ((complianceData?.compliant_workorders ?? 0) * 100) /
        complianceData.scheduled_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.planning_effectiveness"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Compliant Workorders",
            value: complianceData!.compliant_workorders,
          },
          attrB: {
            tooltip: "Scheduled Workorders",
            value: complianceData!.scheduled_workorders,
          },
        })}
      </>
    );
  };

  const renderCompletionPlanningEffectiveness = () => {
    const value: number = complianceData?.scheduled_workorders
      ? ((complianceData?.completed_workorders ?? 0) * 100) /
        complianceData.scheduled_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.completion_planning_effectiveness"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Completed Workorders",
            value: complianceData!.completed_workorders,
          },
          attrB: {
            tooltip: "Scheduled Workorders",
            value: complianceData!.scheduled_workorders,
          },
        })}
      </>
    );
  };

  const renderBreakInPlanningEffectiveness = () => {
    const value: number = complianceData?.scheduled_workorders
      ? ((complianceData?.breakin_workorders ?? 0) * 100) /
        complianceData.scheduled_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.break_in_planning_effectiveness"
    );
    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Break-In Workorders",
            value: complianceData!.breakin_workorders,
          },
          attrB: {
            tooltip: "Scheduled Workorders",
            value: complianceData!.scheduled_workorders,
          },
        })}
      </>
    );
  };

  const renderBaselinePlanningEffectiveness = () => {
    const value: number = complianceData?.baseline_workorders
      ? ((complianceData?.baseline_compliant_workorders ?? 0) * 100) /
        complianceData.baseline_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.baseline_planning_effectiveness"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Baseline Compliant Workorders",
            value: complianceData!.baseline_compliant_workorders,
          },
          attrB: {
            tooltip: "Baseline Workorders",
            value: complianceData!.baseline_workorders,
          },
        })}
      </>
    );
  };

  const renderBaselineCompletionPlanningEffectiveness = () => {
    const value: number = complianceData?.baseline_workorders
      ? ((complianceData?.baseline_completed_workorders ?? 0) * 100) /
        complianceData.baseline_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.baseline_completion_planning_effectiveness"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Baseline Completed Workorders",
            value: complianceData!.baseline_completed_workorders,
          },
          attrB: {
            tooltip: "Baseline Workorders",
            value: complianceData!.baseline_workorders,
          },
        })}
      </>
    );
  };

  const renderBaselineBreakInPlanningEffectiveness = () => {
    const value: number = complianceData?.baseline_workorders
      ? ((complianceData?.breakin_workorders ?? 0) * 100) /
        complianceData.baseline_workorders
      : 0;
    const title: string = t(
      "module.schedule_report:planning_effectiveness_chart.label.baseline_break_in_planning_effectiveness"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Break-In Workorders",
            value: complianceData!.breakin_workorders,
          },
          attrB: {
            tooltip: "Baseline Workorders",
            value: complianceData!.baseline_workorders,
          },
        })}
      </>
    );
  };

  const renderSchedulingCompliance = () => {
    const value: number = complianceData?.scheduled_hours
      ? ((complianceData?.timecard_hours ?? 0) * 100) /
        complianceData.scheduled_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.scheduling_compliance"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Timecard Hours",
            value: complianceData!.timecard_hours,
          },
          attrB: {
            tooltip: "Scheduled Hours",
            value: complianceData!.scheduled_hours,
          },
        })}
      </>
    );
  };

  const renderCompletionCompliance = () => {
    const value: number = complianceData?.scheduled_hours
      ? ((complianceData?.actual_hours ?? 0) * 100) /
        complianceData.scheduled_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.completion_compliance"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Actual Hours",
            value: complianceData!.actual_hours,
          },
          attrB: {
            tooltip: "Scheduled Hours",
            value: complianceData!.scheduled_hours,
          },
        })}
      </>
    );
  };

  const renderBreakInCompliance = () => {
    const value: number = complianceData?.scheduled_hours
      ? ((complianceData?.breakin_hours ?? 0) * 100) /
        complianceData.scheduled_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.break_in_hours"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Break-In Hours",
            value: complianceData!.breakin_hours,
          },
          attrB: {
            tooltip: "Scheduled Hours",
            value: complianceData!.scheduled_hours,
          },
        })}
      </>
    );
  };

  const renderBaselineSchedulingCompliance = () => {
    const value: number = complianceData?.baseline_hours
      ? ((complianceData?.baseline_timecard_hours ?? 0) * 100) /
        complianceData.baseline_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.baseline_scheduling_compliance"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Baseline Timecard Hours",
            value: complianceData!.baseline_timecard_hours,
          },
          attrB: {
            tooltip: "Baseline Hours",
            value: complianceData!.baseline_hours,
          },
        })}
      </>
    );
  };

  const renderBaselineCompletionCompliance = () => {
    const value: number = complianceData?.baseline_hours
      ? ((complianceData?.baseline_actual_hours ?? 0) * 100) /
        complianceData.baseline_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.baseline_completion_compliance"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Baseline Actual Hours",
            value: complianceData!.baseline_actual_hours,
          },
          attrB: {
            tooltip: "Baseline Hours",
            value: complianceData!.baseline_hours,
          },
        })}
      </>
    );
  };

  const renderBaselineBreakInHoursCompliance = () => {
    const value: number = complianceData?.baseline_hours
      ? ((complianceData?.breakin_hours ?? 0) * 100) /
        complianceData.baseline_hours
      : 0;
    const title: string = t(
      "module.schedule_report:compliance_chart.label.baseline_break_in_hours"
    );

    return (
      <>
        {renderReportProgressCard({
          title,
          value,
          attrA: {
            tooltip: "Break-In Hours",
            value: complianceData!.breakin_hours,
          },
          attrB: {
            tooltip: "Baseline Hours",
            value: complianceData!.baseline_hours,
          },
        })}
      </>
    );
  };

  const renderSchedulingSummarySection = (): ReactElement => (
    <>
      {currentSchedule && (
        <div className={styles.reportSection}>
          {renderTitleSection(
            t("module.schedule_report:section.scheduling_summary.title"),
            renderScheduleNameSection
          )}
          <Grid container spacing={2}>
            {renderDetailsSubSection()}
            {renderScheduleKPIsSubSection()}
          </Grid>
        </div>
      )}
    </>
  );

  const renderDetailsSubSection = (): ReactElement => (
    <Grid item sm={8} xs={6}>
      <Card className={clsx([styles.customCard, styles.details])}>
        <CardContent>
          <Typography className={styles.subtitle}>
            {t(
              "module.schedule_report:section.scheduling_summary.details.title"
            )}
          </Typography>
          <List className={styles.detailsList}>
            {detailsInfo.map((item, index) =>
              renderDetailListItem(item, index)
            )}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );

  const renderScheduleKPIsSubSection = (): ReactElement => (
    <Grid item sm={4} xs={6}>
      <Card className={clsx([styles.customCard, styles.kpis])}>
        <CardContent>
          <Box>
            <Typography className={styles.subtitle}>
              {t("module.schedule_report:section.scheduling_summary.kpi.title")}
            </Typography>
          </Box>
          <Divider className={styles.kpiDivider} />
          <ScheduleKpis
            schedule={currentSchedule!}
            complianceData={complianceData!}
          />
        </CardContent>
      </Card>
    </Grid>
  );

  const renderWorkCompletionSection = (): ReactElement => (
    <>
      {currentSchedule && (
        <div className={styles.reportSection}>
          <Box className={styles.workCompletionHeader}>
            {renderTitleSection(
              t("module.schedule_report:section.work_completion.title"),
              renderWorkCompletionHeaderOptions
            )}
          </Box>
          <TableContext.Provider value={scheduleReportProgressSummaryTable}>
            <ReportChartCard>
              <ScheduleProgressChart />
            </ReportChartCard>
          </TableContext.Provider>
        </div>
      )}
    </>
  );

  const renderPlanningEffectivenessSection = (): ReactElement => (
    <>
      {complianceData && (
        <div className={styles.reportSection}>
          {renderTitleSection(
            t("module.schedule_report:section.planning_effectiveness.title")
          )}
          <Grid container spacing={3}>
            {renderPlanningEffectiveness()}
            {renderCompletionPlanningEffectiveness()}
            {renderBreakInPlanningEffectiveness()}
            {renderBaselinePlanningEffectiveness()}
            {renderBaselineCompletionPlanningEffectiveness()}
            {renderBaselineBreakInPlanningEffectiveness()}
          </Grid>
        </div>
      )}
    </>
  );

  const renderComplianceSection = (): ReactElement => (
    <>
      {complianceData && (
        <div className={styles.reportSection}>
          {renderTitleSection(
            t("module.schedule_report:section.compliance.title")
          )}
          <Grid container spacing={3}>
            {renderSchedulingCompliance()}
            {renderCompletionCompliance()}
            {renderBreakInCompliance()}
            {renderBaselineSchedulingCompliance()}
            {renderBaselineCompletionCompliance()}
            {renderBaselineBreakInHoursCompliance()}
          </Grid>
        </div>
      )}
    </>
  );

  const renderResourcesSection = (): ReactElement => (
    <>
      {currentSchedule && (
        <div className={styles.reportSection}>
          {renderTitleSection(
            t("module.schedule_report:section.resources.title")
          )}
          <ReportChartCard>
            <ScheduleResourcesChart />
          </ReportChartCard>
        </div>
      )}
    </>
  );

  return (
    <>
      <ScheduleReportContext.Provider
        value={{
          currentSchedule,
          selectedBaseline,
          setSelectedBaseline,
          progressTableRef,
          progressFiltersOpened,
          setProgressFiltersOpened,
          progressSummaryFilters,
          setProgressSummaryFilters,
        }}
      >
        <ScheduleReportHeader />
        <Box className={styles.root}>
          {!currentSchedule && renderScheduleNameSection()}
          {renderSchedulingSummarySection()}
          {renderWorkCompletionSection()}
          {renderPlanningEffectivenessSection()}
          {renderComplianceSection()}
          {renderResourcesSection()}
          <RiverSpinner show={isLoading} />
        </Box>
      </ScheduleReportContext.Provider>
    </>
  );
};
