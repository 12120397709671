import { FC, useContext, useEffect, useState } from "react";
import { AdapterUiContext, ScheduleReportContext } from "../../../context";
import { Bar } from "react-chartjs-2";
import { useNotification, useTranslation } from "@river/common-ui";
import { GenericAction } from "../../shared";
import SettingsIcon from "@mui/icons-material/Settings";
import { userPreferencesService } from "../../../services";
import styles from "./schedule-resources-chart.module.scss";

interface ICraftInfo {
  label: string;
  available_hours: number;
  scheduled_hours: number;
}

export const ScheduleResourcesChart: FC = () => {
  const scheduleReportContext = useContext(ScheduleReportContext);
  const currentSchedule = scheduleReportContext?.currentSchedule;
  const adapterContext = useContext(AdapterUiContext);
  const craftsUiService = adapterContext?.service!.getCraftsUiService()!;
  const [selectedCraftIds, setSelectedCraftIds] = useState<Set<string>>(
    new Set()
  );
  const [selectedCraftData, setSelectedCraftData] = useState<ICraftInfo[]>([]);
  const notify = useNotification();
  const uiService = adapterContext?.service.getScheduleReportUiService();
  const [craftSelectorOpened, setCraftSelectorOpened] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("module.schedule_report:resource_chart.title"),
      },
    },
    maintainAspectRatio: false,
  };

  const labels = selectedCraftData.map((item) => item.label);

  const data = {
    labels,
    datasets: [
      {
        label: t("module.schedule_report:resource_chart.available"),
        data: selectedCraftData.map((item) => item.available_hours),
        backgroundColor: "#2843c8",
        barThickness: 18,
      },
      {
        label: t("module.schedule_report:resource_chart.scheduled"),
        data: selectedCraftData.map((item) => item.scheduled_hours),
        backgroundColor: "#d5d9f2",
        barThickness: 18,
      },
    ],
  };

  const loadChartData = async () => {
    try {
      if (!!currentSchedule) {
        const queryWithIds = {
          query: craftsUiService.getSelectedCraftsQuery(
            Array.from(selectedCraftIds)
          ),
        };
        const data = await adapterContext!.service
          .getAdapterService()
          .fetchCraftUtilization(
            currentSchedule._id,
            null,
            currentSchedule.start_date,
            "FOLDER",
            0,
            queryWithIds
          );

        setSelectedCraftData(
          data.map((item) => {
            const label: string = uiService?.getCraftLabelPropertyValue(item)!;
            const available_hours: number = item.utilization[0].available_hours;
            const scheduled_hours: number = item.utilization[0].scheduled_hours;
            const mappedItem = {
              label,
              available_hours,
              scheduled_hours,
            };
            return mappedItem;
          })
        );
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadSelectedCraftIds = async () => {
    try {
      const craftIds: string[] =
        await userPreferencesService.getUtilizationCrafts(
          adapterContext!.service.getAdapterService()
        );
      setSelectedCraftIds(new Set(craftIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    loadSelectedCraftIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule, selectedCraftIds]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.actionsContainer}>
          {currentSchedule && (
            <GenericAction
              icon={SettingsIcon}
              title={t("module.schedule:action_label.crafts")}
              onClick={() => setCraftSelectorOpened(true)}
            />
          )}
        </div>

        <div className={styles.chartContainer}>
          <Bar options={options} data={data} className={styles.chart} />
        </div>
      </div>
      {uiService?.renderCraftsSelector({
        open: craftSelectorOpened,
        onClose: () => setCraftSelectorOpened(false),
        onChangeSelectedCrafts: setSelectedCraftIds,
        selectedIds: selectedCraftIds,
      })}
    </>
  );
};
