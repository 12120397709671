/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  IAdapterFolder,
  IEntityObject,
  QueryAttributeDto,
} from "@river/interfaces";
import { InputAdornment } from "@mui/material";
import {
  WoPaneItem,
  WoPaneItemColor,
  randomWoPaneItemColors,
} from "./wo-pane-item";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  ExecutionContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../../context";
import {
  RiverTextInput,
  useNotification,
  useEnv,
  IUseEnv,
  RiverSpinner,
} from "@river/common-ui";
import { LookupType, RiverLookup } from "../../shared";
import SearchIcon from "@mui/icons-material/Search";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { debounce } from "ts-debounce";
import { useTranslation } from "@river/common-ui";
import { ExecutionUiService } from "../../../services";
import { getFolderQueryGroup } from "@river/util";
import styles from "./wo-pane.module.scss";
import clsx from "clsx";

interface WoPaneProps {
  className?: string;
}

export const WoPane: React.FC<WoPaneProps> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const { t } = useTranslation();

  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const executionContext = useContext(ExecutionContext);
  const currentSchedule: IAdapterFolder = executionContext?.currentSchedule!;
  const [workOrders, setWorkOrders] = useState<IEntityObject[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const notify = useNotification();
  const searchForm: RiverFormInstance = useRiverForm<Object, Object, Object>({
    standalone: {
      fields: { workOrderSearchValue: "" },
      fieldDefs: [],
    },
  });
  const searchText: string = (searchForm.standalone as any)
    .workOrderSearchValue;

  const doFetch = async (queryAttrs: QueryAttributeDto[]): Promise<any> =>
    await adapterContext!.service.getAdapterService().fetchWorkorders({
      query: {
        $and: [
          getFolderQueryGroup(currentSchedule, ""),
          {
            $or: queryAttrs,
          },
        ],
      },
    });

  const fetchWorkOrders = async (searchText?: string) => {
    const propsToSearch: string[] = uiService.getWoPropsToRender();
    const queryAttrs: QueryAttributeDto[] = searchText
      ? propsToSearch.map((propName) => {
          return {
            attribute_name: propName,
            attribute_value: {
              operator: "$regex",
              value: searchText,
            },
          };
        })
      : [];

    try {
      if (currentSchedule && adapterContext) {
        setIsLoading(true);
        const results = await doFetch(queryAttrs);
        setWorkOrders(results);
        if (!isMobile) {
          executionContext?.setCurrentWorkOrder(
            results.length ? results[0] : null
          );
        }
      }
      setCollapsed(false);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!site) {
      fetchWorkOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, currentSchedule]);

  const debounceSearchWorkOrders = useCallback(
    debounce((searchText) => fetchWorkOrders(searchText), 600),
    [currentSchedule]
  );

  useEffect(() => {
    if (!!site) {
      debounceSearchWorkOrders(searchText);
    }
  }, [searchText]);

  const getRandomItemColor = (index: number): WoPaneItemColor =>
    randomWoPaneItemColors[index % randomWoPaneItemColors.length];

  const renderScheduleLookup = (): ReactElement => (
    <RiverLookup
      id={"schedule"}
      label={t("entity.folder:folder.folder")}
      value={
        currentSchedule?.folder ||
        t("module.execution:lookup.no_selected_schedule")
      }
      lookup={{ type: LookupType.SCHEDULES }}
      inputProps={{
        disabled: true,
      }}
      onSelect={(selectedObject: IEntityObject) => {
        if (selectedObject) {
          executionContext?.setCurrentSchedule(
            selectedObject as IAdapterFolder
          );
        }
      }}
      className={styles.scheduleLookup}
    />
  );

  const renderWorkOrderSearch = (): ReactElement => (
    <RiverTextInput
      id={"workOrderSearchValue"}
      label={""}
      value={searchText}
      onChangeEvent={searchForm.onStandalonePropertyChange()}
      fullWidth
      className={styles.workOrderSearch}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start" className={styles.iconContainer}>
            <SearchIcon className={styles.searchIcon} />
          </InputAdornment>
        ),
        placeholder: t("common.button:search"),
      }}
    />
  );

  const renderHeader = (): ReactElement => (
    <div className={styles.header}>
      {renderScheduleHeader()}
      {currentSchedule && renderWorkOrdersHeader()}
    </div>
  );

  const renderScheduleHeader = (): ReactElement => (
    <>
      <div className={styles.title}>{t("entity.folder:folder.folder")}</div>
      {renderScheduleLookup()}
    </>
  );

  const renderWorkOrdersHeader = (): ReactElement => (
    <>
      <div className={styles.title}>{t("common.label:workorders")}</div>
      {renderWorkOrderSearch()}
    </>
  );

  const renderWorkOrdersList = (): ReactElement => (
    <>
      <div className={styles.list}>
        {workOrders.map((workOrder, index) => (
          <WoPaneItem
            key={index}
            workOrder={workOrder}
            color={getRandomItemColor(index)}
          />
        ))}
      </div>
    </>
  );

  return (
    <>
      <div
        className={clsx([
          styles.root,
          props.className,
          {
            [styles.collapsed]: collapsed,
            [styles.mobile]: isMobile,
            [styles.woSelected]: !!executionContext?.currentWorkOrder,
          },
        ])}
      >
        {executionContext?.initialized && (
          <>
            {renderHeader()}
            {renderWorkOrdersList()}
            <RiverSpinner show={isLoading} />
          </>
        )}
      </div>
    </>
  );
};
