import React, { ReactElement } from "react";
import { RiverDialog, useTranslation } from "@river/common-ui";
import { RiverProgressBar } from "../../../../../components/shared";
import styles from "./wo-completion-progress.module.scss";

export interface IWoCompletionProgressProps {
  open: boolean;
  onClose: () => void;
  processed?: number;
  total?: number;
}

export const WoCompletionProgress: React.FC<IWoCompletionProgressProps> = (
  props: IWoCompletionProgressProps
): ReactElement => {
  const { t } = useTranslation();

  const closeDialog = (): void => {
    props.onClose();
  };

  const render = (): ReactElement => {
    const { processed, total } = props;
    const title: string = `Processed ${processed} of ${total} workorders`;
    return (
      <RiverProgressBar title={title} processed={processed} total={total} />
    );
  };

  return (
    <RiverDialog
      open={props.open}
      title={"Completion Progress"}
      showTitleDivider={false}
      showActionsDivider={false}
      closeButtonText={t("common.label:close")}
      onClose={closeDialog}
      showXClose={false}
      classes={{ content: styles.content }}
      actionsContent={<></>}
    >
      {render()}
    </RiverDialog>
  );
};
