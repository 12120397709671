import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { QueryAttributeDto } from "@river/interfaces";
import { useTableCellRenderers } from "../../../../../hooks";

export function useSchedulesLookupDef() {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:schedule.title"),
      rowKey: "_id",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          { key: "folder", name: t("entity.folder:folder.folder"), width: 170 },
          {
            key: "start_date",
            name: t("entity.folder:folder.start_date"),
            width: 160,
          },
          {
            key: "end_date",
            name: t("entity.folder:folder.end_date"),
            width: 160,
          },
          {
            key: "duration",
            name: t("entity.folder:folder.duration"),
            width: 90,
          },
          {
            key: "status",
            name: t("entity.folder:folder.status"),
            formatter: (formatterProps) => (
              <>
                {renderTextCell({
                  formatterProps,
                  value: formatterProps.row[formatterProps.column.key]?.code,
                })}
              </>
            ),
          },
          {
            key: "workorder_count",
            name: t("entity.folder:folder.workorder_count"),
          },
          {
            key: "operation_count",
            name: t("entity.folder:folder.operation_count"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .fetchFolders({
              ...fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: customFilters,
              }),
            });
        },
        dependencies: [!!site],
        saveKey: "schedules.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "folder",
      },
    };
  };
}

export type IUseSchedulesLookupDef = ReturnType<typeof useSchedulesLookupDef>;
