export const stringifyDate = (props) => {
    const { value, inputType } = props;
    if (!!value && String(value) !== "Invalid Date") {
        if (value instanceof Date) {
            if (inputType === "date") {
                return value.toISOString().substring(0, 10);
            }
            else {
                // input type 'datetime-local'
                const year = String(value.getFullYear());
                const month = String(value.getMonth() + 1).padStart(2, "0");
                const date = String(value.getDate()).padStart(2, "0");
                const hours = String(value.getHours()).padStart(2, "0");
                const minutes = String(value.getMinutes()).padStart(2, "0");
                const dateTimeLocal = `${year}-${month}-${date}T${hours}:${minutes}`;
                return dateTimeLocal;
            }
        }
        else {
            if (inputType === "date") {
                return value.substring(0, 10);
            }
            else {
                // input type 'datetime-local'
                return value.substring(0, 16);
            }
        }
    }
    else {
        return "";
    }
};
