import React, { ReactElement, useContext } from "react";
import { RhineRiverLoginLogo } from "./rhine-river-login-logo";
import { ColoradoRiverLoginLogo } from "./colorado-river-login-logo";
import { Constants } from "@river/constants";
import { ApplicationContext } from "../../../context";
import { ICustomer } from "@river/interfaces";

const adapterTypeLogoMap: { [adapterType: string]: React.FC } = {
  [Constants.adapter_type.sap]: RhineRiverLoginLogo,
  [Constants.adapter_type.oracle_cloud]: ColoradoRiverLoginLogo,
  [Constants.adapter_type.oracle_ebs]: ColoradoRiverLoginLogo,
  [Constants.adapter_type.jde]: ColoradoRiverLoginLogo,
};

export const LoginLogo: React.FC = (): ReactElement => {
  const applicationContext = useContext(ApplicationContext);
  const customer: ICustomer = applicationContext?.customerInfo?.customer!;
  const adapterType: string = customer?.adapter_type;
  const Logo: React.FC = adapterTypeLogoMap[adapterType];

  return <>{customer && <Logo />}</>;
};
