import React, { ReactElement } from "react";
import { RiverSpinner } from "@river/common-ui";
import styles from "./async-progress-spinner.module.scss";

interface IAsyncProgressSpinnerProps {
  title: string;
}
export const AsyncProgressSpinner: React.FC<IAsyncProgressSpinnerProps> = (
  props: IAsyncProgressSpinnerProps
): ReactElement => (
  <div className={styles.root}>
    <div className={styles.title}>{props.title}</div>
    <div className={styles.body}>
      <RiverSpinner show={true} />
    </div>
  </div>
);
