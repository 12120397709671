import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  EnvLanguageStatusDto,
  IEntityObject,
  ILanguage,
} from "@river/interfaces";
import React, { ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  ApplicationContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  FilterDropdown,
} from "../../shared";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";

import headerStyles from "../../shared/common-header/common-header.module.scss";
import clsx from "clsx";
import { uiConstants } from "../../../helpers";
import { useTranslation } from "@river/common-ui";
import i18n from "i18next";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { LanguagesAction } from "../languages";

export const LanguagesGridHeader: React.FC = (props): ReactElement => {
  const tableContext = useContext(TableContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const applicationContext = useContext(ApplicationContext);
  const notify = useNotification();
  const { t } = useTranslation();

  const getSelectedLanguage = (): ILanguage | undefined => {
    let selected: IEntityObject | undefined =
      tableContext?.table.entities.filter((entity) =>
        tableContext?.table.selectedRowIds.has(
          entity[uiConstants.fields._id] as string
        )
      )[0];
    return selected as unknown as ILanguage;
  };
  const selectedLanguage: ILanguage = getSelectedLanguage()!;

  const deleteLanguage = async (): Promise<void> => {
    try {
      await adapterContext?.service
        .getAdapterService()
        .deleteEnvironmentLanguage(selectedLanguage.language);
      tableContext?.table.refresh();
      notify.success(t("module.languages:notification.language_deletion"));
    } catch (message) {
      notify.error({ message });
    }
  };

  const deleteLanguageConfirmationDialog = useSimpleDialog({
    title: t("module.languages:message.delete_language.title"),
    message: t("module.languages:message.confirm_language_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteLanguage,
  });

  const changeLanguageStatus = async () => {
    try {
      await adapterContext?.service
        .getAdapterService()
        .environmentLanguageStatus(selectedLanguage.language, {
          enabled: !selectedLanguage.enabled,
        } as EnvLanguageStatusDto);
      tableContext?.table.refresh();
      applicationContext?.reloadLanguages();
      notify.success(t("module.languages:notification.reloaded_language"));
    } catch (message) {
      notify.error({ message });
    }
  };

  const changeLanguageStatusConfirmationDialog = useSimpleDialog({
    title: t("module.languages:message.language_change_status.title"),
    message: t("module.languages:message.confirm_language_status_change"),
    confirmButtonText: t("module.languages:button.change_status"),
    onConfirm: changeLanguageStatus,
  });

  const resetEnvironmentLanguage = async (): Promise<any> => {
    try {
      await adapterContext?.service
        .getAdapterService()
        .resetEnvLanguageTranslations(selectedLanguage.language);

      if (i18n.language === selectedLanguage.language) {
        const loadedNamespaces = i18n.reportNamespaces.getUsedNamespaces();
        await i18n.reloadResources(selectedLanguage.language, loadedNamespaces);
        window.location.reload();
      }
      tableContext?.table.refresh();
      notify.success(t("module.languages:notification.language_reset"));
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderChangeLanguageStatusAction = (): ReactElement => (
    <CheckIcon
      titleAccess={t("module.languages:label.change_language_status")}
      className={clsx([
        headerStyles.actionIcon,
        { [headerStyles.disabled]: !selectedLanguage },
      ])}
      onClick={() => {
        if (selectedLanguage) {
          changeLanguageStatusConfirmationDialog.open();
        }
      }}
    />
  );

  const renderReloadSelectedLanguageAction = (): ReactElement => (
    <CachedIcon
      titleAccess={t("module.languages:label.reload_language_status")}
      className={clsx([
        headerStyles.actionIcon,
        { [headerStyles.disabled]: !selectedLanguage },
      ])}
      onClick={() => {
        if (selectedLanguage) {
          resetEnvironmentLanguage();
        }
      }}
    />
  );

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.LANGUAGES}
            action={LanguagesAction.DELETE}
          >
            <DeleteAction
              onClick={() => deleteLanguageConfirmationDialog.open()}
            />
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.LANGUAGES}
            action={LanguagesAction.STATUS}
          >
            {renderChangeLanguageStatusAction()}
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.LANGUAGES}
            action={LanguagesAction.RELOAD}
          >
            {renderReloadSelectedLanguageAction()}
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteLanguageConfirmationDialog.render()}
      {changeLanguageStatusConfirmationDialog.render()}
    </>
  );
};
