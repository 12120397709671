import { FC, useContext, useEffect, useState, ComponentType } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { AdapterUserContext, SidebarContext } from "../../context";
import { useSidebarMenu } from "../sidebar-menu/use-sidebar-menu";
import { Link } from "react-router-dom";
import { IMenuItem } from "../sidebar-menu";
import { ITranslationProps } from "@river/common-ui";
import { HomeHeader } from "./home-header";
import styles from "./home.module.scss";

const HomeComponent: FC = () => {
  const { t } = useTranslation();
  const userContext = useContext(AdapterUserContext);
  const user = userContext?.user!;
  const menu = useSidebarMenu();
  const sidebarContext = useContext(SidebarContext);
  const { menuItems } = menu;

  const renderItem = (item: IMenuItem, index: number) => (
    <div className={styles.item_wrapper} key={index}>
      <Link to={item.linkTo!} className={styles.item}>
        <div className={styles.item_icon_container}>{item.renderIcon?.()}</div>
        <span className={styles.item_title}>{item.title}</span>
        <span className={styles.item_desc}>{item?.description}</span>
      </Link>
    </div>
  );

  useEffect(() => {
    const originalSidebarState: boolean = sidebarContext?.collapsed!;
    const restoreSidebarState = () =>
      sidebarContext?.setCollapsed({
        collapsed: originalSidebarState,
        skipSave: true,
      });

    sidebarContext?.setCollapsed({ collapsed: true, skipSave: true });

    return () => restoreSidebarState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HomeHeader />
      <div className={styles.main}>
        <span className={styles.welcome_back_title}>
          {`${t("common.label:welcome_back")}, ${user.display_name}!`}
        </span>
        <div className={styles.main_container}>
          {menuItems
            .filter((item) => item?.enabled && item?.linkTo)
            .map((item, index) => renderItem(item, index))}
        </div>
      </div>
    </>
  );
};

const ComponentWrapper: FC<ITranslationProps> = ({ tReady }) => (
  <>{tReady && <HomeComponent />}</>
);

export const Home: FC = () => {
  const namespaces: string[] = [
    "entity.planning_plant",
    "common.label",
    "component.sidebar",
    "shared.planning_plant_selector",
    "shared.user_menu",
  ];

  const [Component] = useState<ComponentType<any>>(() =>
    withTranslation(namespaces)(ComponentWrapper)
  );

  return <Component />;
};
