import React, { ReactElement, useContext } from "react";
import { TableContext } from "../../../../context";
import { EditIcon } from "../../../../icons";
import { useTranslation } from "@river/common-ui";
import headerStyles from "../../common-header/common-header.module.scss";
import clsx from "clsx";

export interface IEditAction {
  title?: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

export const EditAction: React.FC<IEditAction> = (props): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const disabled: boolean =
    props.disabled !== undefined
      ? props.disabled
      : tableContext?.table.selectedRowIds.size === 0;

  const DEFAULT_TITLE = t("shared.grid_header:label.edit");

  return (
    <EditIcon
      title={props.title || DEFAULT_TITLE}
      onClick={() => {
        if (!disabled) {
          props.onClick();
        }
      }}
      className={clsx([
        headerStyles.actionIcon,
        { [headerStyles.disabled]: disabled },
        props.className,
      ])}
    />
  );
};
