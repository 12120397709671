import React, { ReactElement } from "react";
import {
  RelationRenderer,
  IRelationRendererProps,
} from "../../schedule-operations-gantt-overlay";
import {
  IRelationConnectorEvents,
  LineArrow,
  renderHorizontalLine,
  renderVerticalLine,
} from "../../render-utils";
import { IUseFinishToStart, useFinishToStart } from "./use-finish-to-start";
import genericStyles from "../../schedule-operations-gantt-overlay.module.scss";
import clsx from "clsx";

export const FinishToStartRelationRenderer: RelationRenderer = (
  props: IRelationRendererProps
): ReactElement => {
  const finishToStart: IUseFinishToStart = useFinishToStart(props);
  const {
    predecessorFinish,
    vertical,
    successorStart,
    isVerticalConnectorStraight,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
    selected,
  } = finishToStart;

  const connectorsEventHandlers: IRelationConnectorEvents = {
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const renderPredecessorFinishConnector = (): ReactElement => (
    <>
      {predecessorFinish &&
        renderHorizontalLine({
          ...predecessorFinish,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderSuccessorStartConnector = (): ReactElement => (
    <>
      {successorStart &&
        renderHorizontalLine({
          ...successorStart,
          ...connectorsEventHandlers,
          arrow: LineArrow.RIGHT,
        })}
    </>
  );

  const renderVerticalConnector = (): ReactElement => (
    <>
      {vertical && (
        <>
          {isVerticalConnectorStraight!() && (
            <>
              {renderVerticalLine({
                ...vertical.straight,
                ...connectorsEventHandlers,
              })}
            </>
          )}

          {!isVerticalConnectorStraight!() && (
            <>
              {renderVerticalLine({
                ...vertical.curved.top,
                ...connectorsEventHandlers,
              })}
              {renderHorizontalLine({
                ...vertical.curved.middle,
                ...connectorsEventHandlers,
              })}
              {renderVerticalLine({
                ...vertical.curved.bottom,
                ...connectorsEventHandlers,
              })}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <div
      className={clsx([
        genericStyles.relation,
        {
          [genericStyles.selected]: selected,
        },
      ])}
    >
      {renderPredecessorFinishConnector()}
      {renderVerticalConnector()}
      {renderSuccessorStartConnector()}
    </div>
  );
};
