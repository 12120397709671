import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import {
  AdapterTimelineDto,
  IAdapterCalendar,
  IAdapterTimeline,
} from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import { useAllowedAction } from "../../protected-action";
import { Equals } from "class-validator";
import { TimelinesAction } from "../timelines";

interface IStandaloneValidator {
  calendarName: string;
}

export const useTimelineForm = (props: {
  timeline: IAdapterTimeline | undefined;
  selectedCalendar: IAdapterCalendar | null;
  onCreate: (timeline: IAdapterTimeline) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  class StandaloneValidator {
    constructor(obj: IStandaloneValidator) {
      Object.assign(this, obj);
    }
    @Equals(props.selectedCalendar?.calendar || "", {
      message: (opts): string =>
        `shared.error_messages:validation.calendar_name?name=${opts.value}`,
    })
    // @ts-ignore
    calendarName: string;
  }

  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<AdapterTimelineDto, IAdapterTimeline, Object>({
    initialDto: Object.assign(new AdapterTimelineDto(), {
      timeline: props.timeline?.timeline || "",
      description: props.timeline?.description || "",
      start_date: props.timeline?.start_date || null,
      folder_duration_days: props.timeline?.folder_duration_days || 7,
      calendar_id: props.timeline?.calendar_id || "",
      use_avail_to_schedule_assignments:
        props.timeline?.use_avail_to_schedule_assignments || false,
    }),
    standalone: {
      fields: new StandaloneValidator({
        calendarName: "",
      }),
      fieldDefs: [
        {
          fieldName: "calendarName",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },
    entityName: "timeline",
    instanceToEdit: props.timeline,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterTimelineDto): Promise<IAdapterTimeline> => {
      if (props.selectedCalendar) {
        dto.calendar_id = props.selectedCalendar._id;
      }
      return await adapterContext!.service
        .getAdapterService()
        .createTimeline(dto);
    },
    update: async (dto: AdapterTimelineDto): Promise<void> => {
      dto.calendar_id = props.selectedCalendar?._id || null;
      await adapterContext!.service
        .getAdapterService()
        .updateTimeline(props.timeline!._id!, dto);
    },
    labels: {
      timeline: t("entity.timeline:timeline.timeline"),
      description: t("entity.timeline:timeline.description"),
      start_date: t("entity.timeline:timeline.start_date"),
      folder_duration_days: t("entity.timeline:timeline.folder_duration_days"),
      folder_name_template: t("entity.timeline:timeline.folder_name_template"),
      calendarName: t("entity.timeline:timeline.calendar_id"),
      use_avail_to_schedule_assignments: t(
        "entity.timeline:timeline.use_avail_to_schedule_assignments"
      ),
    },
    readOnly:
      !isActionAllowed(ModuleKey.TIMELINES, TimelinesAction.UPDATE) ||
      !isActionAllowed(ModuleKey.TIMELINES, TimelinesAction.CREATE),
  });
};
