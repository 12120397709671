import { useContext } from "react";
import {
  GenericAction,
  IGenericAction,
  IUseTable,
} from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { AdapterService } from "../..";
import { useNotification } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import { IAdapterBreakinAction } from "@river/interfaces";
import { useParams } from "react-router";

export const useResetBreakInAction = () => {
  const { t } = useTranslation();
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext!.table;
  const notify = useNotification();
  const entityName: string =
    tableContext?.entity?.entityDefinition?.entity.entity_name ||
    tableContext?.entityName!;
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("module.schedule:action_label.breakin_reset");

  const disabled: boolean = table.selectedRowIds.size === 0;

  const resetBreakIn = async (): Promise<any> => {
    try {
      const data: IAdapterBreakinAction = {
        folder_id: scheduleId,
        breakin: false,
        entity_name: entityName,
        entity_ids: Array.from(table.selectedRowIds!),
      };
      await adapterService.setBreakin(data);

      table.refresh();
      notify.success(t("module.schedule:action.breakin_reset.success"));
    } catch (message) {
      notify.error({ message });
    }
  };

  const onClick = () => resetBreakIn();

  const renderIcon = (props?: IGenericAction) => (
    <GenericAction
      icon={ClearIcon}
      enableOn={!disabled}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderResetBreakInAction = () => <>{renderIcon()}</>;

  return {
    renderResetBreakInAction,
    resetBreakInAction: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
      disabled,
    },
  };
};

export type IUseResetBreakInAction = ReturnType<typeof useResetBreakInAction>;
