import {
  IQuerySimple,
  IQuery,
  IQueryAttribute,
  IQueryAttributeSort,
  TQueryOperator,
  IQueryAttributeGroup,
  IAdapterFolder,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import { Constants } from "@river/constants";

// ----------------
export const upgradeQuery = (query: IQuerySimple): IQuery => {
  const { $skip, $limit, $search, $expand, $unwind, $suppress, ...rest } =
    query;

  const resultQuery: IQueryAttributeGroup = { $and: [] };
  const sort: IQueryAttributeSort[] = [];

  Object.keys(rest).forEach((k) => {
    //sort
    const SORT_STR = "$sort.";
    if (k.startsWith(SORT_STR)) {
      const sortAttribute = k.split(SORT_STR)[1];
      sort.push({
        attribute_name: sortAttribute,
        sort: query[k],
      });
      return;
    }

    // attributes
    let operator: TQueryOperator = "$eq";
    let attrName = k;
    [".$eq", ".$lt", ".$lte", ".$gt", ".$gte", ".$ne", ".$regex"].forEach(
      (op) => {
        if (k.endsWith(op)) {
          attrName = k.split(op)[0];
          operator = op.substring(1) as TQueryOperator; //eliminate "."
        }
      }
    );

    let attribute = resultQuery.$and!.find(
      (a: IQueryAttributeGroup | IQueryAttribute) =>
        a.attribute_name == attrName
    );
    if (!attribute) {
      attribute = {
        attribute_name: attrName,
        attribute_value: {
          operator,
          value: rest[k],
        },
      };
      resultQuery.$and!.push(attribute);
    }

    // const parts: string[] = Array.isArray(rest[k]) ? rest[k] : [rest[k]];
    // parts.forEach((p) => {
    //   attribute!.attribute_value.push({
    //     operator: operator as TQueryOperator,
    //     value: p,
    //   });
    // });
  });

  return {
    $skip,
    $limit,
    $search,
    $expand,
    $unwind,
    $suppress,
    query: resultQuery,
    sort,
  };
};

// --------------
export const getFolderQueryGroup = (
  folder: IAdapterFolder,
  attributePrefix: string = ""
): QueryAttributeGroupDto => {
  //
  const { folder_type, timeline, start_date, end_date } = folder;

  if (folder_type == Constants.folder_type.project) {
    //
    return {
      $and: [
        {
          attribute_name: `${attributePrefix}__folder.folder_id`,
          attribute_value: {
            operator: "$eq",
            value: folder._id,
          },
        },
      ],
    };
  }

  const timelineId = timeline?.timeline_id || "000000000000000000000000";

  return {
    $or: [
      {
        attribute_name: `${attributePrefix}__folder.folder_id`,
        attribute_value: {
          operator: "$eq",
          value: folder._id,
        },
      },
      {
        $and: [
          {
            attribute_name: `${attributePrefix}__folder.timelines.timeline_id`,
            attribute_value: {
              operator: "$eq",
              value: timelineId,
            },
          },
          {
            attribute_name: `${attributePrefix}__folder.end_date`,
            attribute_value: {
              operator: "$gte",
              value: start_date,
            },
          },
          {
            attribute_name: `${attributePrefix}__folder.start_date`,
            attribute_value: {
              operator: "$lt",
              value: end_date,
            },
          },
        ],
      },
    ],
  };
};

// --------------
export const mergeQueries = (
  operator: string = "$and",
  ...queries: QueryAttributeGroupDto[]
): QueryAttributeGroupDto => {
  //
  return {
    [operator]: {
      ...queries,
    },
  };
};
