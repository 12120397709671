import React, { useContext } from "react";
import { Column } from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { IEntityDefinition } from "@river/interfaces";
import { LanguagesHeader } from "./languages-header";
import { LanguagesSubHeader } from "./languages-subheader";
import { fetchHelpers, uiConstants } from "../../helpers";
import { LanguagesGridHeader } from "./languages-grid-header";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import {
  useEntity,
  IUseEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { Constants } from "@river/constants";
import { useNavigate } from "react-router";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";

export enum LanguagesAction {
  CREATE = "ADD_LANGUAGE",
  DELETE = "DELETE_LANGUAGE",
  STATUS = "LANGUAGE_STATUS",
  RELOAD = "RELOAD_LANGUAGE",
  UPDATE_TRANSLATION = "UPDATE_TRANSLATION",
}

export const languageEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "sap",
    entity_name: "languages",
  },
  attributes: [
    {
      _id: "language",
      adapter_type: "sap",
      entity_name: "languages",
      attribute_name: "language",
      data_type: Constants.data_type.string,
      description: "Language",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "name",
      adapter_type: "sap",
      entity_name: "languages",
      attribute_name: "name",
      data_type: Constants.data_type.string,
      description: "Name",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "enabled",
      adapter_type: "sap",
      entity_name: "languages",
      attribute_name: "enabled",
      data_type: Constants.data_type.boolean,
      description: "Language enabled",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};

export const Languages: React.FC = () => {
  usePageCleanup();

  const entityName: string = "languages";
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const entity: IUseEntity = useEntity({
    entityName,
    definition: languageEntityDef,
  });

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "language",
      name: t("module.languages:languages_entity.language"),
      width: 150,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  navigate(
                    `/languages/${formatterProps.row[uiConstants.fields._id]}`
                  );
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "name", name: t("module.languages:languages_entity.name") },
    {
      key: "enabled",
      name: t("module.languages:languages_entity.enabled"),
      width: 100,
    },
  ];

  const fetchLanguages = async (fetchProps: ITableFetchFunctionProps) => {
    return await adapterContext!.service
      .getAdapterService()
      .getEnvironmentLanguages(fetchHelpers.getTableQuery({ fetchProps }));
  };
  const table: IUseTable = useTable({
    entityName,
    saveKey: "languages",
    columns,
    fetchFunction: fetchLanguages,
    fetchOn: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const isLoading: boolean = table.isLoading;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <LanguagesHeader />
        <LanguagesSubHeader />
        <LanguagesGridHeader />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
      </TableContext.Provider>
    </>
  );
};
