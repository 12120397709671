import React, { ReactElement, useContext } from "react";
import { RiverSpinner } from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useTable,
} from "../../../../components/shared";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  ExecutionContext,
} from "../../../../context";
import { OracleEbsExecutionMaterialRequirementsGridHeader } from "./oracle-ebs-execution-material-requirements-grid-header";
import { fetchHelpers, uiConstants } from "../../../../helpers";
import { useTranslation } from "@river/common-ui";
import { IUseEntity, useEntity } from "../../../../hooks";
import { OracleEbsMaterialRequirementsUiService } from "../../oracle-ebs-material-requirements-ui.service";
import { Column } from "react-data-grid";
import { IEntityObject } from "@river/interfaces";
import { OracleEbsAdapterService } from "../../oracle-ebs-adapter.service";
import styles from "./oracle-ebs-execution-material-requirements.module.scss";

export const OracleEbsExecutionMaterialRequirements: React.FC =
  (): ReactElement => {
    const { t } = useTranslation();
    const executionContext = useContext(ExecutionContext);
    const adapterContext: IAdapterUiContextState | null =
      useContext(AdapterUiContext);
    const adapterService: OracleEbsAdapterService =
      adapterContext!.service.getAdapterService() as OracleEbsAdapterService;
    const materialRequirementsUiService =
      adapterContext?.service!.getMaterialRequirementsUiService()! as OracleEbsMaterialRequirementsUiService;

    const workOrderNumberColumn =
      materialRequirementsUiService.getWorkOrderNumberColumn();
    const currentWorkOrder: IEntityObject = executionContext?.currentWorkOrder!;
    const currentWorkOrderNumber: string = currentWorkOrder?.[
      workOrderNumberColumn
    ] as string;

    const fetchInventoryItems = async (
      fetchProps: ITableFetchFunctionProps
    ): Promise<any> =>
      await adapterService.fetchInventoryItems(
        [currentWorkOrderNumber],
        fetchHelpers.getTableQuery({ fetchProps })
      );

    const fetchDirectPurchases = async (
      fetchProps: ITableFetchFunctionProps
    ): Promise<any> =>
      await adapterService.fetchDirectItems(
        [currentWorkOrderNumber],
        fetchHelpers.getTableQuery({ fetchProps })
      );

    const defaultInventoryItemsColumns: Column<any>[] = [
      {
        key: "WIP_ENTITY_NAME",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.WIP_ENTITY_NAME"
        ),
      },
      {
        key: "OPERATION_SEQ_NUM",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.OPERATION_SEQ_NUM"
        ),
      },
      {
        key: "CONCATENATED_SEGMENTS",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.CONCATENATED_SEGMENTS"
        ),
      },
      {
        key: "ITEM_DESCRIPTION",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.ITEM_DESCRIPTION"
        ),
      },
      {
        key: "REQUIRED_QUANTITY",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.REQUIRED_QUANTITY"
        ),
      },
      {
        key: "AVAILABLE_QUANTITY",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.AVAILABLE_QUANTITY"
        ),
      },
      {
        key: "QUANTITY_ISSUED",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.QUANTITY_ISSUED"
        ),
      },
      {
        key: "DATE_REQUIRED",
        name: t(
          "entity.wo_operation_inventory_item:wo_operation_inventory_item.DATE_REQUIRED"
        ),
      },
    ];

    const defaultDirectPurchasesColumns: Column<any>[] = [
      {
        key: "WIP_ENTITY_NAME",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.WIP_ENTITY_NAME"
        ),
      },
      {
        key: "OPERATION_SEQ_NUM",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.OPERATION_SEQ_NUM"
        ),
      },
      {
        key: "DIRECT_ITEM_TYPE",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.DIRECT_ITEM_TYPE"
        ),
      },
      {
        key: "ITEM_DESCRIPTION",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.ITEM_DESCRIPTION"
        ),
      },
      {
        key: "DESCRIPTION",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.DESCRIPTION"
        ),
      },
      {
        key: "UOM_CODE",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.UOM_CODE"
        ),
      },
      {
        key: "REQUIRED_QUANTITY",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.REQUIRED_QUANTITY"
        ),
      },
      {
        key: "DATE_REQUIRED",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.DATE_REQUIRED"
        ),
      },
      {
        key: "QUANTITY_RECEIVED",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.QUANTITY_RECEIVED"
        ),
      },
      {
        key: "PR_PO_NUMBER",
        name: t(
          "entity.wo_operation_direct_item:wo_operation_direct_item.PR_PO_NUMBER"
        ),
      },
    ];

    const inventoryItemsTable: IUseTable = useTable({
      saveKey: "execution.material_requirements.inventory_items",
      columns: defaultInventoryItemsColumns,
      fetchFunction: fetchInventoryItems,
      fetchOn: true,
      dependencies: [!!currentWorkOrder],
      fetchTriggers: [currentWorkOrder],
      useAdvancedFilters: false,
    });

    const directPurchasesTable: IUseTable = useTable({
      saveKey: "execution.material_requirements.direct_purchases",
      columns: defaultDirectPurchasesColumns,
      fetchFunction: fetchDirectPurchases,
      fetchOn: true,
      dependencies: [!!currentWorkOrder],
      fetchTriggers: [currentWorkOrder],
      useAdvancedFilters: false,
    });

    const inventoryItemsEntity: IUseEntity = useEntity({
      entityName:
        materialRequirementsUiService.getWorkOrderInventoryItemEntityName(),
    });

    const directItemsEntity: IUseEntity = useEntity({
      entityName:
        materialRequirementsUiService.getWorkOrderDirectItemEntityName(),
    });

    const renderInventoryItemsSection = (): ReactElement => (
      <TableContext.Provider
        value={{
          table: inventoryItemsTable,
          entity: inventoryItemsEntity,
        }}
      >
        <div className={styles.sectionContainer}>
          <p className={styles.sectionTitle}>Inventory Items</p>
          <OracleEbsExecutionMaterialRequirementsGridHeader />
          <RiverDataGrid
            disableSelectAll={true}
            columns={inventoryItemsTable.columns}
            rows={inventoryItemsTable.entities}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onSelectedRowsChange={() => {}}
            onRowClick={() => {}}
            rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
            sortColumns={inventoryItemsTable.sortColumns}
            onSortColumnsChange={(e) => {
              inventoryItemsTable.setSortColumns(e);
            }}
          />
        </div>
      </TableContext.Provider>
    );

    const renderDirectPurchasesSection = (): ReactElement => (
      <TableContext.Provider
        value={{
          table: directPurchasesTable,
          entity: directItemsEntity,
        }}
      >
        <div className={styles.sectionContainer}>
          <p className={styles.sectionTitle}>Direct Purchases</p>
          <OracleEbsExecutionMaterialRequirementsGridHeader />
          <RiverDataGrid
            disableSelectAll={true}
            columns={directPurchasesTable.columns}
            rows={directPurchasesTable.entities}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onSelectedRowsChange={() => {}}
            onRowClick={() => {}}
            rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
            sortColumns={directPurchasesTable.sortColumns}
            onSortColumnsChange={(e) => {
              directPurchasesTable.setSortColumns(e);
            }}
          />
        </div>
      </TableContext.Provider>
    );

    const isLoading: boolean =
      inventoryItemsTable.isLoading || directPurchasesTable.isLoading;

    return (
      <div className={styles.root}>
        <RiverSpinner show={isLoading} />
        {renderInventoryItemsSection()}
        {renderDirectPurchasesSection()}
      </div>
    );
  };
