import { FC, useContext, useState } from "react";
import { Column } from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { IAdapterLookup, IEntityObject } from "@river/interfaces";
import { LookupsHeader } from "./lookups-header";
import { LookupsSubHeader } from "./lookups-subheader";
import { fetchHelpers, uiConstants } from "../../helpers";
import { LookupsGridHeader } from "./lookups-grid-header";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { useEntity, IUseEntity, useTableCellRenderers } from "../../hooks";
import { ModuleKey } from "../sidebar-menu";
import { Constants } from "@river/constants";
import { LookupDialog } from "./lookup-dialog";
import { useTranslation } from "@river/common-ui";
import { useAllowedAction } from "../protected-action";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./lookups.module.scss";
import clsx from "clsx";

export enum LookupsAction {
  CREATE = "CREATE_LOOKUP",
  UPDATE = "UPDATE_LOOKUP",
  DELETE = "DELETE_LOOKUP",
}

export interface ILookups {
  moduleKey: ModuleKey;
  lookupType: string;
}

export const Lookups: FC<ILookups> = (props) => {
  const entityName: string = "lookup";
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const isActionAllowed = useAllowedAction();
  const hasUpdateAction: boolean = isActionAllowed(
    props.moduleKey,
    LookupsAction.UPDATE
  );
  const notify = useNotification();
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity: IUseEntity = useEntity({ entityName });
  const [lookupToEdit, setLookupToEdit] = useState<IAdapterLookup | null>();
  const [lookupDialogOpened, setLookupDialogOpened] = useState<boolean>(false);
  const openLookupDialog = (lookup: IAdapterLookup): void => {
    setLookupToEdit(lookup);
    setLookupDialogOpened(true);
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "value",
      name: t("entity.lookup:lookup.value"),
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.lookupNameCell}>
                <div
                  className={clsx([
                    { [dataGridStyles.dataGridLink]: hasUpdateAction },
                  ])}
                  onClick={() => {
                    if (!hasUpdateAction) return;
                    table.setSelectedRowIds(new Set());
                    openLookupDialog(formatterProps.row as IAdapterLookup);
                  }}
                >
                  {renderCellText({ formatterProps })}
                </div>
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "description", name: t("entity.lookup:lookup.description") },
    { key: "created_by", name: t("entity.lookup:lookup.created_by") },
    { key: "updated_at", name: t("entity.lookup:lookup.updated_at") },
  ];

  const fetchLookups = async (fetchProps: ITableFetchFunctionProps) => {
    let data = [];

    try {
      data = await adapterContext!.service
        .getAdapterService()
        .fetchLookups(
          props.lookupType,
          fetchHelpers.getTableQuery({ fetchProps })
        );
    } catch (message) {
      notify.error({ message });
    }

    return data;
  };

  const table: IUseTable = useTable({
    entityName,
    columns,
    fetchFunction: fetchLookups,
    fetchOn: true,
    dependencies: [!!site],
    fetchTriggers: [site],
    infiniteScrolling: true,
    useAdvancedFilters: false,
  });

  const getSelectedLookup = (): IAdapterLookup => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterLookup;
  };

  const pageTitle: string = {
    [Constants.lookup_type.availability_exception_reason]: t(
      "module.lookups:reason_codes_lookups.title"
    ),
  }[props.lookupType];

  const isLoading: boolean = table.isLoading || !site;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <LookupsHeader pageTitle={pageTitle} />
        <LookupsSubHeader
          moduleKey={props.moduleKey}
          lookupType={props.lookupType}
        />
        <LookupsGridHeader
          moduleKey={props.moduleKey}
          lookupType={props.lookupType}
          selectedLookup={getSelectedLookup()}
        />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
        <LookupDialog
          lookup_type={props.lookupType}
          open={lookupDialogOpened}
          lookup={lookupToEdit!}
          onClose={(success) => {
            setLookupDialogOpened(false);
            if (success) table.fetch();
          }}
        />
      </TableContext.Provider>
    </>
  );
};
