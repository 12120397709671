import React, { ReactElement, useState } from "react";
import { IAdapterAsyncAction } from "@river/interfaces";
import {
  RiverAsyncProgress,
  IRiverAsyncProgressProps,
} from "./river-async-progress";

type UseRiverAsyncProgressProps = Omit<
  IRiverAsyncProgressProps,
  "action" | "onClose"
> & {
  onClose?: () => void;
};

export const useRiverAsyncProgress = (props: UseRiverAsyncProgressProps) => {
  const [action, setAction] = useState<IAdapterAsyncAction | null>(null);

  const renderDialog = (): ReactElement => {
    const { title, onClose, onSuccess, onComplete, onError } = props;
    return (
      <>
        {action && (
          <RiverAsyncProgress
            title={title}
            action={action}
            onClose={() => {
              setAction(null);
              onClose?.();
            }}
            onSuccess={onSuccess}
            onError={onError}
            onComplete={onComplete}
          />
        )}
      </>
    );
  };

  return {
    setAction,
    renderDialog,
  };
};

export type IUseRiverAsyncProgress = ReturnType<typeof useRiverAsyncProgress>;
