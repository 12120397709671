import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { SupervisorProgressSummaryFilterID } from "../../../../supervisor-schedule-utilization-ui-service";

interface IOracleEbsSupervisorScheduleProgressWOTypeFiltersProps {
  rows: any[];
  onSelect: (
    selectedObjects: any[],
    filterId: SupervisorProgressSummaryFilterID
  ) => void;
  onDelete: (
    selectedObject: any,
    filterId: SupervisorProgressSummaryFilterID
  ) => void;
}

export const OracleEbsSupervisorScheduleProgressWOTypeFilters: React.FC<
  IOracleEbsSupervisorScheduleProgressWOTypeFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const woTypeLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((woType) => woType["LOOKUP_CODE"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "MEANING",
          name: t("entity.wo_type:wo_type.MEANING"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.ORACLE_EBS_WO_TYPE,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "LOOKUP_CODE",
            attribute_value: {
              operator: "$nin",
              value: woTypeLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selectedObject) =>
        props.onDelete(
          selectedObject,
          SupervisorProgressSummaryFilterID.WO_TYPES
        )
      }
      onSelect={(selectedObjects) => {
        props.onSelect(
          selectedObjects,
          SupervisorProgressSummaryFilterID.WO_TYPES
        );
      }}
      addFilterLabel={t(
        "module.supervisor_schedule:utilization_filters.add.wo_type"
      )}
    />
  );
};
