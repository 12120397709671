import React, { useContext, useState } from "react";
import { PrimaryButton, useNotification } from "@river/common-ui";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { LookupDialog, LookupType } from "../../shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { EnvLanguageDto } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { LanguagesAction } from "../languages";

export const LanguagesSubHeader: React.FC = () => {
  const { t } = useTranslation();

  const [languagesDialogOpened, setLanguagesDialogOpened] =
    useState<boolean>(false);
  const openDialog = (): void => {
    setLanguagesDialogOpened(true);
  };
  const closeDialog = (): void => {
    setLanguagesDialogOpened(false);
  };
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.LANGUAGES}
          action={LanguagesAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.languages:button.add_language")}
          />
        </ProtectedAction>
      </div>
      <LookupDialog
        open={languagesDialogOpened}
        onClose={closeDialog}
        singleSelect={true}
        onSubmit={async (selection) => {
          if (selection.length) {
            try {
              await adapterContext?.service
                .getAdapterService()
                .addEnvironmentLanguage({
                  language: selection[0].language,
                } as EnvLanguageDto);
              notify.success(
                t("module.languages:notification.language_added_to_env")
              );
              tableContext?.table.refresh();
            } finally {
              closeDialog();
            }
          }
        }}
        lookup={{ type: LookupType.LANGUAGES }}
      />
    </>
  );
};
