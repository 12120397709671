import React from "react";
import {
  SupervisorScheduleUtilizationTabId,
  SupervisorScheduleUtilizationUiService,
  IGetDefaultSupervisorScheduleBacklogColumnsProps,
  ISupervisorScheduleProgressFilters,
  ISupervisorScheduleComplianceFilters,
} from "../supervisor-schedule-utilization-ui-service";
import {
  ColumnsProvider,
  QueryProvider,
  ReactElementProvider,
  TabsProvider,
} from "../ui-service.types";
import { TabProps } from "@mui/material/Tab";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useTranslation } from "@river/common-ui";
import { Avatar } from "../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import {
  RefreshAction,
  getWorkOrderIdFormatter,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTableCellRenderers } from "../../hooks";
import styles from "../../components/schedule/schedule-utilization/schedule-utilization-tables/defaultColumns.module.scss";
import clsx from "clsx";

const DEFAULT_ORACLE_CLOUD_SCHEDULE_UTILIZATION_TAB: SupervisorScheduleUtilizationTabId =
  SupervisorScheduleUtilizationTabId.METRICS;

export class OracleCloudSupervisorScheduleUtilizationUiService extends SupervisorScheduleUtilizationUiService {
  getTabs(): TabsProvider {
    return (): TabProps[] => {
      const { t } = useTranslation();
      return [
        {
          label: t("module.supervisor_schedule:tab.metrics.title"),
          icon: <LeaderboardIcon />,
          value: SupervisorScheduleUtilizationTabId.METRICS,
        },
        {
          label: t("module.supervisor_schedule:tab.backlog.title"),
          icon: <DynamicFeedIcon />,
          value: SupervisorScheduleUtilizationTabId.BACKLOG,
        },
        {
          label: t("module.supervisor_schedule:tab.craft.title"),
          icon: <CenterFocusWeakIcon />,
          value: SupervisorScheduleUtilizationTabId.CRAFTS,
        },
        {
          label: t("module.supervisor_schedule:tab.people.title"),
          icon: <PersonIcon />,
          value: SupervisorScheduleUtilizationTabId.PERSONS,
        },
        {
          label: t("module.supervisor_schedule:tab.crews.title"),
          icon: <GroupsIcon />,
          value: SupervisorScheduleUtilizationTabId.CREWS,
        },
        {
          label: t("module.supervisor_schedule:tab.validation_rules.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.VALIDATION_RULES,
        },
        {
          label: t("module.supervisor_schedule:tab.progress.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.PROGRESS,
        },
        {
          label: t("module.supervisor_schedule:tab.compliance.title"),
          icon: <PlaylistAddCheckIcon />,
          value: SupervisorScheduleUtilizationTabId.COMPLIANCE,
        },
      ];
    };
  }

  getDefaultTab(): SupervisorScheduleUtilizationTabId {
    return DEFAULT_ORACLE_CLOUD_SCHEDULE_UTILIZATION_TAB;
  }

  getDefaultBacklogColumns(
    props: IGetDefaultSupervisorScheduleBacklogColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.workorder:workorder.WorkOrderNumber"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "WorkOrderDescription",
          name: t("entity.workorder:workorder.WorkOrderDescription"),
          editor: TextEditor,
        },
        {
          key: "WorkOrderTypeCode",
          name: t("entity.workorder:workorder.WorkOrderTypeCode"),
        },
        {
          key: "WorkOrderSubTypeCode",
          name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
        },
        {
          key: "AssetNumber",
          name: t("entity.workorder:workorder.AssetNumber"),
        },
        {
          key: "WorkOrderPriority",
          name: t("entity.workorder:workorder.WorkOrderPriority"),
        },
        {
          key: "WorkOrderStatusCode",
          name: t("entity.workorder:workorder.WorkOrderStatusCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.workorder:workorder.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.workorder:workorder.PlannedCompletionDate"),
        },
        {
          key: "__folder.folder",
          name: t("entity.folder:folder.folder"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultCraftUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "ResourceName",
          name: t(
            "entity.resource_utilization:resource_utilization.ResourceName"
          ),
          width: 140,
        },
      ];
    };
  }

  getCraftUtilizationEntityName(): string {
    return "resource_utilization";
  }

  getDefaultPersonUtilizationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderCell } = useTableCellRenderers();
      return [
        {
          key: "LaborInstanceName",
          name: t(
            "entity.person_utilization:person_utilization.LaborInstanceName"
          ),
          width: 165,
          formatter: (formatterProps) => {
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <div className={clsx([styles.person])}>
                      <Avatar
                        url={utilizationHelpers.getRandomGanttAvatarURL(
                          Number(formatterProps.row.Resources)
                        )}
                      />
                      <div>
                        {formatterProps.row[
                          formatterProps.column.key
                        ]?.toString()}
                      </div>
                    </div>
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "Resources.ResourceName",
          name: t("entity.resource_ref:resource_ref.ResourceName"),
          width: 100,
        },
      ];
    };
  }
  getCraftUtilizationKeyColumn(): string {
    return "ResourceId";
  }

  getCraftUtilizationStatLabelColumn(): string {
    return "ResourceName";
  }

  getProgressGridActionsRenderer(): () => ReactElementProvider {
    return () => () => <RefreshAction />;
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <></>;
  }

  getComplianceGridActionsRenderer(): () => ReactElementProvider {
    return () => () => <RefreshAction />;
  }

  renderComplianceFilters(): ReactElementProvider {
    return () => <></>;
  }

  getComplianceFilters(): QueryProvider {
    return () => undefined;
  }

  getProgressFilters(): QueryProvider {
    return () => undefined;
  }

  getBlankProgressFilters(): ISupervisorScheduleProgressFilters {
    return {};
  }
  getBlankComplianceFilters(): ISupervisorScheduleComplianceFilters {
    return {};
  }
}
