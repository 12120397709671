import { ReactElement } from "react";
import {
  IRenderTimecardDialogProps,
  SupervisorTimeCardUiService,
} from "../../supervisor-timecard-ui.service";
import { JdeSupervisorOperationTimeCardDialog } from "./jde-supervisor-operation-timecard-dialog";

export class JdeSupervisorTimeCardUiService extends SupervisorTimeCardUiService {
  renderOperationTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <JdeSupervisorOperationTimeCardDialog {...props} />;

  renderAssignmentTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <JdeSupervisorOperationTimeCardDialog {...props} />;

  renderResourceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderInstanceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;
}
