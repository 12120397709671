import React, { ReactElement, useContext } from "react";
import { IAdapterShift } from "@river/interfaces";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  IUseTable,
  FilterDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { ShiftsAction } from "../shifts";
import { ModuleKey } from "../../sidebar-menu";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { uiConstants } from "../../../helpers";

export const ShiftsGridHeader: React.FC = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const selectedShift: IAdapterShift = table.entities.filter((entity) =>
    table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
  )[0] as IAdapterShift;

  const deleteSelectedShift = async (): Promise<any> => {
    if (selectedShift) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteShift(selectedShift._id);
        tableContext?.table.fetch();
        deleteShiftConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteShiftConfirmation = useSimpleDialog({
    title: t("module.shifts:dialog.delete_shift"),
    message: t("module.shifts:message.confirm_shift_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedShift,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.SHIFTS}
            action={ShiftsAction.DELETE}
          >
            <DeleteAction onClick={() => deleteShiftConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteShiftConfirmation.render()}
    </>
  );
};
