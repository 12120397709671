import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleUtilizationContext,
} from "../../../../context";
import {
  ComplianceSummaryFilterID,
  IScheduleComplianceFilters,
  userPreferencesService,
} from "../../../../services";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters/chart-filters-container/chart-filters-container";
import { SapSchedulingComplianceWOTypeFilters } from "./filters";

export interface ISapScheduleComplianceFiltersDto
  extends IScheduleComplianceFilters {
  [ComplianceSummaryFilterID.PMACTTYPE]: any[];
}

export const SapSchedulingComplianceFilters: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();

  const scheduleUtilizationContext = useContext(ScheduleUtilizationContext);
  const {
    complianceFiltersOpened,
    complianceSummaryFilters,
    setComplianceSummaryFilters,
  } = scheduleUtilizationContext!;

  const fetchComplianceFilters = async () => {
    try {
      setLoading(true);
      const result: IScheduleComplianceFilters =
        await userPreferencesService.getScheduleComplianceFilters(
          adapterContext!.service.getAdapterService()
        );
      if (result) {
        setComplianceSummaryFilters(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteComplianceFilter = async (
    selectedObject: any,
    filterId: ComplianceSummaryFilterID
  ) => {
    let columnId: string;
    if (filterId === ComplianceSummaryFilterID.PMACTTYPE) {
      columnId = "Pmacttype";
    }

    const newDto: IScheduleComplianceFilters = {
      ...complianceSummaryFilters,
      [filterId]: (complianceSummaryFilters as any)[filterId]?.filter(
        (selected: any) => selected[columnId] !== selectedObject[columnId]
      ),
    };

    try {
      setLoading(true);
      await userPreferencesService.setScheduleComplianceFilters(
        adapterContext!.service.getAdapterService(),
        newDto
      );
      setComplianceSummaryFilters(newDto);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onSelectComplianceFilters = async (
    selectedObjects: any[],
    filterId: ComplianceSummaryFilterID
  ) => {
    try {
      const payload: IScheduleComplianceFilters = {
        ...(complianceSummaryFilters as ISapScheduleComplianceFiltersDto),
        [filterId]: [
          ...(complianceSummaryFilters as any)[filterId],
          ...selectedObjects,
        ],
      };
      setLoading(true);
      await userPreferencesService.setScheduleComplianceFilters(
        adapterContext!.service.getAdapterService(),
        payload
      );
      setComplianceSummaryFilters(payload);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWOTypeFilters = (): ReactElement => (
    <SapSchedulingComplianceWOTypeFilters
      rows={
        (complianceSummaryFilters as ISapScheduleComplianceFiltersDto)[
          ComplianceSummaryFilterID.PMACTTYPE
        ]
      }
      onDelete={onDeleteComplianceFilter}
      onSelect={onSelectComplianceFilters}
    />
  );

  return (
    <Collapse
      in={complianceFiltersOpened}
      orientation={"horizontal"}
      timeout={300}
    >
      <ChartFiltersContainer>
        {renderWOTypeFilters()}
        <RiverSpinner show={loading} />
      </ChartFiltersContainer>
    </Collapse>
  );
};
