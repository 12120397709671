import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import { IColumnFilter } from "../../../interfaces";
import { RowsChangeData, TextEditor } from "react-data-grid";
import {
  ITableFetchFunctionProps,
  RiverDropdownActions,
  RiverTableSelector,
  STATIC_COLUMN_KEYS,
  useRiverSelectColumn,
} from "../";
import { LayoutManager } from "./layout-manager";
import { IColumnLayout, ITablePreferences } from "../../../interfaces";
import { IUseTable } from "../../shared";
import { Column } from "react-data-grid";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { attributeEntityDef } from "../../data-dict/entity-dialog/defs/attribute-def";
import {
  IUseEnv,
  useEnv,
  RiverDialogButton,
  RiverDialogActionButton,
  useNotification,
  RiverSelect,
  IRiverSelectItem,
  RiverCheckboxFormatter,
} from "@river/common-ui";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { SaveIcon } from "../../../icons";
import { ResetIcon } from "../../../icons";
import { LayoutIcon } from "../../../icons";
import { SaveLayoutDialog } from "./save-layout-dialog";
import { userPreferencesService } from "../../../services";
import { useTranslation } from "@river/common-ui";
import headerStyles from "../common-header/common-header.module.scss";
import styles from "./column-selector.module.scss";
import clsx from "clsx";

enum Direction {
  UP = "UP",
  DOWN = "DOWN",
}

interface IColumnsSelector {
  open: boolean;
  onClose: () => void;
}

export const ColumnsSelector: React.FC<IColumnsSelector> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const [initialSelectedColumnKeys, setInitialSelectedColumnKeys] =
    useState<Set<string>>();
  const selectedColumnKeys = useRef<Set<string>>();
  const notify = useNotification();
  const tableContext = useContext(TableContext);
  const entityName: string =
    tableContext?.entity?.entityDefinition?.entity.entity_name!;
  const [currentColumns, setCurrentColumns] = useState<Column<any>[]>([]);
  const [currentLayout, setCurrentLayout] = useState<IColumnLayout>();
  const [layoutManagerOpened, setLayoutManagerOpened] =
    useState<boolean>(false);
  const [layoutDialogOpened, setLayoutDialogOpened] = useState<boolean>(false);

  const filterObjects = (
    attrs: IEntityObject[],
    filters: IColumnFilter[]
  ): IEntityObject[] =>
    (attrs as any).filter((attr: IEntityObject) => {
      return !filters.filter((filter) => {
        const fieldName = filter.field;
        const fieldValue = String(attr[fieldName]).toLowerCase();
        const filterValue = String(filter.value).toLowerCase();
        return fieldValue.indexOf(filterValue) === -1;
      }).length;
    });

  const fetchObjects = async (fetchProps: ITableFetchFunctionProps) => {
    let attrs: IEntityObject[] = [];

    const applyCustomValues = (attribute: IEntityObject): void => {
      const columnKey: string = attribute.attribute_name as string;
      // Apply translations
      let description: string = attribute["ml_resource_id"]
        ? t(`entity.${entityName}:` + attribute["ml_resource_id"])
        : (attribute["description"] as string);
      let is_frozen: boolean = false;
      // For Selected Columns use custom values
      if (
        !!fetchProps.ids?.length &&
        selectedColumnKeys.current!.has(columnKey)
      ) {
        const column: Column<any> = currentColumns.filter(
          (column) => column.key === columnKey
        )[0];
        if (column && typeof column.name === "string") {
          description = column.name;
          is_frozen = !!column.frozen;
        }
      }
      attribute.description = description;
      attribute.is_frozen = is_frozen;
    };

    const processAttributes = (attributes: IEntityObject[]): void => {
      let attributeNames: string[] = [];
      if (fetchProps.ids) {
        attributeNames = fetchProps.ids.slice();
      } else {
        const filter: IColumnFilter = fetchProps.columnFilters.find(
          (filter) =>
            filter.field === "attribute_name" && filter.operator === "$nin"
        )!;
        if (filter) {
          attributeNames = filter.value;
        }
      }

      for (let attribute of attributes) {
        if (!!fetchProps.ids) {
          // Fetching selected columns
          if (attributeNames.indexOf(String(attribute.attribute_name)) !== -1) {
            applyCustomValues(attribute);
            attrs.push(attribute);
          }
        } else {
          // Fetching available columns
          if (
            attributeNames.indexOf(String(attribute.attribute_name)) === -1 &&
            fetchProps.nin?.indexOf(String(attribute.attribute_name)) === -1
          ) {
            applyCustomValues(attribute);
            attrs.push(attribute);
          }
        }
      }
    };

    const entityDef: IEntityDefinition = await adapterContext!.service
      .getAdapterService()
      .getEntityInfo(entityName);
    processAttributes(entityDef.attributes as any as IEntityObject[]);

    const filteredObjects: IEntityObject[] = filterObjects(
      attrs,
      fetchProps.columnFilters
    );
    const sortColumn = fetchProps.sortColumns?.[0];

    if (sortColumn) {
      const { columnKey, direction } = sortColumn;
      const sortOrder = direction === "ASC" ? 1 : -1;

      return filteredObjects.sort((a, b) => {
        const aValue = String(a[columnKey]);
        const bValue = String(b[columnKey]);

        return aValue.localeCompare(bValue) * sortOrder;
      });
    } else {
      return filteredObjects;
    }
  };

  const removeStaticColumnKeysFromSet = (keys: Set<string>): void => {
    STATIC_COLUMN_KEYS.forEach((key) => keys.delete(key));
  };

  useEffect(() => {
    if (props.open) {
      const tableColumns: Column<any>[] = tableContext?.table.columns!;
      const initialColumnKeys: Set<string> = new Set(
        tableColumns.map((column) => column.key)
      );
      removeStaticColumnKeysFromSet(initialColumnKeys);
      setInitialSelectedColumnKeys(initialColumnKeys);
      selectedColumnKeys.current = initialColumnKeys;
      setCurrentColumns(tableColumns);
      setCurrentLayout(tableContext?.table.currentLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (selectedColumnKeys.current?.size === 0) {
      let defaultColumnsSet: Set<string> = new Set();
      tableContext?.table.defaultColumns.forEach((column) => {
        defaultColumnsSet.add(column.key);
      });
      removeStaticColumnKeysFromSet(defaultColumnsSet);
      setInitialSelectedColumnKeys(defaultColumnsSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContext?.table.columns]);

  const onChangeSelectedAttributes = (newAttributes: IEntityObject[]): void => {
    // make sure this change is not caused by column filtering
    if (newAttributes.length !== selectedColumnKeys.current?.size) {
      return;
    }

    const tableColumns: Column<any>[] = tableContext?.table.columns!;
    const columns: Column<any>[] = newAttributes.map((attr) => {
      const columnIndex: number = tableColumns.findIndex(
        (column) => column.key === attr.attribute_name
      );
      const column: Column<any> = tableColumns[columnIndex];

      let newColumn: Column<any> = {
        key: attr.attribute_name as string,
        name: attr.description as string,
      };
      if (column) {
        newColumn = {
          ...newColumn,
          width: column.width,
          frozen: column.frozen,
        };
      }
      return newColumn;
    });
    setCurrentColumns(columns);
  };

  const onChangeSelectedColumnKeys = (keys: Set<string>): void => {
    const columnKeys: Set<string> = new Set(keys);
    removeStaticColumnKeysFromSet(columnKeys);
    selectedColumnKeys.current = columnKeys;
  };

  const onUpdateColumn = (rows: any[], rowData: RowsChangeData<any>) => {
    const updatedAttribute: IEntityObject = rows[rowData.indexes[0]];
    const column: Column<any> = {
      ...currentColumns.find(
        (column) => column.key === updatedAttribute.attribute_name
      )!,
    };

    // @ts-ignore
    column["name"] = updatedAttribute.description! as string;
    // @ts-ignore
    column["frozen"] = updatedAttribute.is_frozen as boolean;

    const newCurrentColumns: Column<any>[] = [...currentColumns];
    const updatedColumnIndex: number = newCurrentColumns.findIndex(
      (col) => col.key === column.key
    );
    newCurrentColumns.splice(updatedColumnIndex, 1, column);

    const updatedLayout: IColumnLayout = Object.assign({}, currentLayout!, {
      columns: [...newCurrentColumns],
    });
    loadLayout(updatedLayout);
  };

  const assignedTableSort = (
    entities: IEntityObject[],
    selectedIds: Set<string>
  ) => {
    entities.sort((a, b) => {
      return (
        Array.from(selectedIds).indexOf(a.attribute_name as string) -
        Array.from(selectedIds).indexOf(b.attribute_name as string)
      );
    });
    const frozenColumns: Set<string> = new Set();
    const regularColumns: Set<string> = new Set();
    entities.forEach((e) => {
      if (e.is_frozen) {
        frozenColumns.add(e.attribute_name as string);
      } else {
        regularColumns.add(e.attribute_name as string);
      }
    });
    const sortedColumns: Set<string> = new Set([
      ...Array.from(frozenColumns),
      ...Array.from(regularColumns),
    ]);
    // this is sorting with the same order as in the table with frozen column being infront
    entities.sort((a, b) => {
      return (
        Array.from(sortedColumns).indexOf(a.attribute_name as string) -
        Array.from(sortedColumns).indexOf(b.attribute_name as string)
      );
    });
  };

  const availableTableColumns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "description",
      name: t("shared.column_selector:columns_selector_entity.description"),
    },
    {
      key: "attribute_name",
      name: t("shared.column_selector:columns_selector_entity.attribute_name"),
    },
  ];
  if (!isMobile) {
    availableTableColumns.push({
      key: "data_type",
      name: t("shared.column_selector:columns_selector_entity.data_type"),
    });
  }

  const assignedTableColumns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "description",
      name: t("shared.column_selector:columns_selector_entity.description"),
      editor: TextEditor,
    },
    {
      key: "attribute_name",
      name: t("shared.column_selector:columns_selector_entity.attribute_name"),
    },
  ];
  if (!isMobile) {
    assignedTableColumns.push({
      key: "data_type",
      name: t("shared.column_selector:columns_selector_entity.data_type"),
    });
  }
  assignedTableColumns.push({
    key: "is_frozen",
    name: t("shared.column_selector:columns_selector_entity.is_frozen"),
    formatter: ({ row, onRowChange }) => {
      return (
        <RiverCheckboxFormatter
          id="frozen"
          onChange={() => {
            onRowChange({ ...row, is_frozen: !row.is_frozen });
          }}
          checked={row.is_frozen}
        />
      );
    },
  });

  const moveSelectedColumns = (
    direction: Direction,
    assignedTable: IUseTable
  ): void => {
    const array: IEntityObject[] = assignedTable.entities;
    const selectedIds: string[] = Array.from(assignedTable.selectedRowIds);

    const isFirstElementSelected: boolean =
      selectedIds.indexOf(array[0]["attribute_name"] as string) !== -1;
    const isLastElementSelected: boolean =
      selectedIds.indexOf(
        array[array.length - 1]["attribute_name"] as string
      ) !== -1;

    if (
      selectedIds.length === 0 ||
      (isFirstElementSelected && direction === Direction.UP) ||
      (isLastElementSelected && direction === Direction.DOWN)
    ) {
      return;
    }

    // chunks of selected column indexes
    const chunks: number[][] = [];
    let chunk: number[] = [];
    array.forEach((obj, index) => {
      if (selectedIds.includes(obj["attribute_name"] as string)) {
        chunk.push(index);
      } else if (chunk) {
        chunks.push(chunk);
        chunk = [];
      }
    });
    if (chunk.length) {
      chunks.push(chunk);
    }

    // swap-drive first external element through to the opposite side of the chunk
    if (direction === Direction.DOWN) {
      chunks.forEach((chunk) => {
        for (let i = chunk.length - 1; i >= 0; i--) {
          const index = chunk[i];
          [array[index], array[index + 1]] = [array[index + 1], array[index]];
        }
      });
    }
    if (direction === Direction.UP) {
      chunks.forEach((chunk) => {
        for (let i = 0; i < chunk.length; i++) {
          const index = chunk[i];
          [array[index - 1], array[index]] = [array[index], array[index - 1]];
        }
      });
    }

    onChangeSelectedAttributes(array);
  };

  const renderMoveDownAction = (table: IUseTable): ReactElement => {
    const array: IEntityObject[] = table.entities;
    const selectedIds: string[] = Array.from(table.selectedRowIds);
    const isLastElementSelected: boolean =
      !!array.length &&
      selectedIds.indexOf(
        array[array.length - 1]["attribute_name"] as string
      ) !== -1;
    return (
      <ArrowDownwardIcon
        titleAccess={t("shared.column_selector:label.move_down")}
        onClick={() => moveSelectedColumns(Direction.DOWN, table)}
        className={clsx([
          headerStyles.actionIcon,
          {
            [headerStyles.disabled]:
              selectedIds.length === 0 || isLastElementSelected,
          },
        ])}
      />
    );
  };

  const renderMoveUpAction = (table: IUseTable): ReactElement => {
    const array: IEntityObject[] = table.entities;
    const selectedIds: string[] = Array.from(table.selectedRowIds);
    const isFirstElementSelected: boolean =
      !!array.length &&
      selectedIds.indexOf(array[0]["attribute_name"] as string) !== -1;
    return (
      <ArrowUpwardIcon
        titleAccess={t("shared.column_selector:label.move_up")}
        onClick={() => moveSelectedColumns(Direction.UP, table)}
        className={clsx([
          headerStyles.actionIcon,
          {
            [headerStyles.disabled]:
              selectedIds.length === 0 || isFirstElementSelected,
          },
        ])}
      />
    );
  };

  const resetDefaultLayout = async (): Promise<void> => {
    const table: IUseTable = tableContext?.table!;
    const layouts: IColumnLayout[] = table.layouts;
    const initialDefaultLayout: IColumnLayout = table.getDefaultLayout();
    const defaultLayoutIndex: number = layouts.findIndex(
      (layout) => layout.name === table.DEFAULT_LAYOUT_NAME
    );
    if (defaultLayoutIndex !== -1) {
      layouts.splice(defaultLayoutIndex, 1);
    }
    layouts.unshift(initialDefaultLayout);

    let newColumns: Column<any>[] = table.columns;
    if (table.currentLayout?.name === table.DEFAULT_LAYOUT_NAME) {
      newColumns = initialDefaultLayout.columns;
    }

    const newTablePreferences: ITablePreferences = {
      layouts,
      columns: newColumns,
      currentLayoutName: table.currentLayout?.name || "",
    };

    try {
      await userPreferencesService.setTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey: table.dbSaveKey,
        tablePreferences: newTablePreferences,
      });
      await table.loadTablePreferences();
      loadLayoutByName(currentLayout!.name);
      notify.info(
        t("shared.column_selector:notification.default_layout_reset")
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const loadLayoutByName = (layoutName: string): void => {
    const table: IUseTable = tableContext?.table!;
    const layoutToLoad: IColumnLayout =
      table.layouts.find((layout) => layout.name === layoutName) ||
      table.getDefaultLayout();
    loadLayout(layoutToLoad);
  };

  const loadLayout = (layout: IColumnLayout): void => {
    setCurrentLayout(layout);
    setCurrentColumns(layout.columns);
    setInitialSelectedColumnKeys(
      new Set(layout.columns.map((column) => column.key))
    );
  };

  const renderLayoutSelector = (): ReactElement => {
    const layouts: IRiverSelectItem[] =
      tableContext?.table.layouts.map((layout) => {
        return {
          label: layout.name,
          value: layout.name,
        };
      }) || [];
    return (
      <>
        {!!currentLayout && (
          <RiverSelect
            items={layouts}
            value={currentLayout?.name!}
            onChange={(newLayoutName) => loadLayoutByName(newLayoutName)}
          />
        )}
      </>
    );
  };

  const renderAssignedTableCustomGridActions_primary = (
    columnSelectorTable: IUseTable
  ): ReactElement => {
    return (
      <>
        {renderMoveDownAction(columnSelectorTable)}
        {renderMoveUpAction(columnSelectorTable)}
        <RiverDropdownActions
          items={[
            {
              title: t("shared.column_selector:label.save_layout"),
              startIcon: SaveIcon,
              onClick: () => setLayoutDialogOpened(true),
            },
            {
              title: t("shared.column_selector:label.reset_default_layout"),
              startIcon: ResetIcon,
              onClick: resetDefaultLayout,
            },
            {
              title: t("shared.column_selector:label.manage_layouts"),
              startIcon: LayoutIcon,
              onClick: () => setLayoutManagerOpened(true),
            },
          ]}
        />
      </>
    );
  };

  const renderAssignedTableCustomGridActions_secondary = (): ReactElement => (
    <>{renderLayoutSelector()}</>
  );

  const onApply = async () => {
    const table: IUseTable = tableContext?.table!;
    const tableSaveKey: string = table.dbSaveKey;
    const layouts: IColumnLayout[] = table.layouts || [];
    const layoutToUpdate: IColumnLayout | undefined =
      currentLayout || tableContext?.table.currentLayout;
    if (layoutToUpdate) {
      layoutToUpdate.columns = currentColumns;
      const index: number = layouts.findIndex(
        (layout) => layout.name === layoutToUpdate.name
      );
      if (index !== -1) {
        layouts.splice(index, 1, layoutToUpdate);
      }
    }

    const tablePreferences: ITablePreferences =
      await userPreferencesService.getTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
      });

    const newTablePreferences: ITablePreferences = {
      ...tablePreferences,
      columns: currentColumns,
      layouts,
      currentLayoutName: layoutToUpdate?.name || "",
    };

    try {
      await userPreferencesService.setTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
        tablePreferences: newTablePreferences,
      });
      table.applyTablePreferences(newTablePreferences);
    } catch (message) {
      notify.error({ message });
    }
  };

  const onDeleteLayouts = (deletedLayoutNames: string[]): void => {
    if (deletedLayoutNames.indexOf(currentLayout!.name) !== -1) {
      const defaultLayout: IColumnLayout =
        tableContext?.table.getDefaultLayout()!;
      loadLayout(defaultLayout);
    }
  };

  const renderFooterButtons = (): ReactElement => (
    <>
      <RiverDialogButton
        onClick={props.onClose}
        text={t("common.button:close")}
      />
      <RiverDialogActionButton
        onClick={onApply}
        text={t("common.button:apply")}
      />
    </>
  );

  return (
    <>
      {entityName && (
        <RiverTableSelector
          open={props.open}
          onClose={props.onClose}
          onChangeSelectedIds={onChangeSelectedColumnKeys}
          onChangeSelectedObjects={onChangeSelectedAttributes}
          onAssignedTableRowsChange={onUpdateColumn}
          selectedIds={initialSelectedColumnKeys!}
          dialogTitle={t("shared.column_selector:dialog_column_selector.title")}
          availableTableTitle={
            isMobile
              ? t("shared.column_selector:dialog.column_selector.available")
              : t(
                  "shared.column_selector:dialog.column_selector.available_columns"
                )
          }
          assignedTableTitle={
            isMobile
              ? t("shared.column_selector:dialog.column_selector.selected")
              : t(
                  "shared.column_selector:dialog.column_selector.selected_columns"
                )
          }
          entityName={"attributes_meta"}
          entityDef={attributeEntityDef}
          columns={availableTableColumns}
          assignedTableColumns={assignedTableColumns}
          rowKey={"attribute_name"}
          fetchObjects={fetchObjects}
          infiniteScrolling={false}
          assignedTableSort={assignedTableSort}
          renderAssignedTableCustomGridActions_primary={
            renderAssignedTableCustomGridActions_primary
          }
          renderAssignedTableCustomGridActions_secondary={
            renderAssignedTableCustomGridActions_secondary
          }
          footerContent={renderFooterButtons()}
          classes={{
            availableTableGridHeaderStyles: {
              gridHeader: {
                root: styles.gridHeader,
                title: styles.title,
              },
            },
            assignedTableGridHeaderStyles: {
              gridHeader: {
                root: styles.gridHeader,
                title: styles.title,
              },
            },
          }}
        />
      )}
      <LayoutManager
        open={layoutManagerOpened}
        onClose={() => setLayoutManagerOpened(false)}
        onDeleteLayouts={onDeleteLayouts}
      />
      <SaveLayoutDialog
        open={layoutDialogOpened}
        onClose={() => setLayoutDialogOpened(false)}
        onCreate={(layout) => setCurrentLayout(layout)}
        table={tableContext?.table!}
        currentColumns={currentColumns}
        currentLayout={currentLayout!}
      />
    </>
  );
};
