import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IBacklogContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { BacklogTabId, BacklogUiService } from "./backlog-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  IAdapterFolder,
  QueryAttributeDto,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import { AdapterService } from "../adapter.service";
import { getFolderQueryGroup } from "@river/util";
import { SitesUiService } from "../sites-ui.service";

interface IUseBacklogResourcesProps {
  backlogContext: IBacklogContext;
}

export const useBacklogResources = (
  props: IUseBacklogResourcesProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const resourcesTabId: BacklogTabId = BacklogTabId.RESOURCES;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const backlogUiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const sitesUiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const entityHelpers = useEntityHelpers();
  const userContext: IAdapterUserContext = useContext(AdapterUserContext)!;
  const organizationId: string | number =
    (
      userContext?.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id || 0;

  const backlogContext: IBacklogContext = props.backlogContext;
  const currentSchedule: IAdapterFolder = backlogContext.currentSchedule!;
  const currentTab: BacklogTabId = backlogUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getInitialQueryAttributeGroup = (): QueryAttributeGroupDto => {
    const organizationQueryAttribute: QueryAttributeDto = {
      attribute_name: sitesUiService.getSiteKeyColumn(),
      attribute_value: {
        operator: "$eq",
        value: organizationId,
      },
    };
    const elements: (QueryAttributeDto | QueryAttributeGroupDto)[] = [
      organizationQueryAttribute,
    ];
    if (currentSchedule) {
      elements.push(getFolderQueryGroup(currentSchedule, ""));
    }
    return {
      $and: elements,
    };
  };

  const fetchResources = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getInitialQueryAttributeGroup(),
      }),
      $unwind: ["WorkOrderOperationResource"],
    };

    table.setLastRanQueryDto(query);

    const resources = await adapterService.searchEntityData("operation", query);
    const resourceRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.operation_resource
    );

    resources.forEach((res) => {
      try {
        entityHelpers.setAttributeValue(
          res,
          uiConstants.fields.rowType,
          uiConstants.rowType.operation_resource
        );
        entityHelpers.setAttributeValue(
          res,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(res, resourceRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing resource _id: ${res._id}`);
        console.error(e);
      }
    });
    return resources;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `backlog.resources`,
    columns: backlogUiService.getDefaultResourcesColumns()(),
    fetchFunction: fetchResources,
    dependencies: [!!site],
    fetchOn: currentTab === resourcesTabId,
    initialSimpleFilters:
      backlogUiService.getInitialTabSimpleFilters(resourcesTabId),
    initialQuery: backlogUiService.getInitialTabQuery(resourcesTabId),
    keepSelection: true,
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      backlogUiService.initialFiltersOverrides[resourcesTabId] = null;
      backlogUiService.initialQueryOverrides[resourcesTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
