import React, { DependencyList, ReactElement } from "react";
import {
  RiverSpinner,
  RiverDialog,
  RiverDialogButton,
  IUseEnv,
  useEnv,
  useNotification,
} from "@river/common-ui";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import {
  TableFetchFunction,
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
} from "../river-data-grid";
import { Column, RowsChangeData } from "react-data-grid";
import { IUseEntity, useEntity } from "../../../hooks";
import { TableContext } from "../../../context";
import {
  AvailableObjectsGridHeader,
  IAvailableObjectsGridHeaderStyles,
} from "./grid-headers/available-objects-grid-header";
import {
  AssignedObjectsGridHeader,
  IAssignedObjectsGridHeaderStyles,
} from "./grid-headers/assigned-objects-grid-header";
import { useTranslation } from "@river/common-ui";
import styles from "./river-custom-selector.module.scss";
import clsx from "clsx";
import { uiConstants } from "../../../helpers";

export interface IRiverCustomSelectorStyles {
  availableTableGridHeaderStyles?: IAvailableObjectsGridHeaderStyles;
  assignedTableGridHeaderStyles?: IAssignedObjectsGridHeaderStyles;
}

interface IRiverCustomSelector {
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
  dialogTitle?: string;
  availableTableColumns: Column<any>[];
  assignedTableColumns: Column<any>[];
  unassignAction: (selectedObjectIds: Set<string>) => Promise<any>;
  assignAction: (selectedObjectIds: Set<string>) => Promise<any>;
  onAvailableTableRowsChange?: (
    rows: any[],
    rowData: RowsChangeData<any>
  ) => void;
  onAssignedTableRowsChange?: (
    rows: any[],
    rowData: RowsChangeData<any>
  ) => void;
  availableTableTitle?: string;
  assignedTableTitle?: string;
  availableEntityName: string;
  assignedEntityName: string;
  availableEntityDef?: IEntityDefinition;
  assignedEntityDef?: IEntityDefinition;
  availableTableRowKey?: string;
  availableTableRowKeyGetter?: (row: IEntityObject) => string;
  assignedTableRowKey?: string;
  assignedTableRowKeyGetter?: (row: IEntityObject) => string;
  fetchAvailableObjects: TableFetchFunction;
  fetchAssignedObjects: TableFetchFunction;
  saveKeyAvailable?: string;
  saveKeyAssigned?: string;
  footerContent?: ReactElement;
  classes?: IRiverCustomSelectorStyles;
  refreshTriggers?: DependencyList;
}

const DEFAULT_IS_DIALOG: boolean = true;
const DEFAULT_AVAILABLE_TABLE_TITLE: string =
  "shared.river_custom_selector:available.title";
const DEFAULT_ASSIGNED_TABLE_TITLE: string =
  "shared.river_custom_selector:assigned.title";
const DEFAULT_ROW_KEY: string = uiConstants.fields._id;

export const RiverCustomSelector: React.FC<IRiverCustomSelector> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const notify = useNotification();

  const isDialog: boolean = props.isDialog ?? DEFAULT_IS_DIALOG;
  const availableEntity: IUseEntity = useEntity({
    entityName: props.availableEntityName,
    definition: props.availableEntityDef,
  });
  const assignedEntity: IUseEntity = useEntity({
    entityName: props.assignedEntityName,
    definition: props.assignedEntityDef,
  });

  const fetchAvailableObjects = async (
    tableProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => await props.fetchAvailableObjects(tableProps);

  const fetchAssignedObjects = async (
    tableProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => await props.fetchAssignedObjects(tableProps);

  const availableTable: IUseTable = useTable({
    saveKey: props.saveKeyAvailable,
    columns: props.availableTableColumns,
    infiniteScrolling: true,
    dependencies: [isDialog ? !!props.open : true],
    rowKeyGetter:
      props.availableTableRowKeyGetter ||
      ((row: IEntityObject) =>
        String(row[props.availableTableRowKey || DEFAULT_ROW_KEY])),
    fetchFunction: fetchAvailableObjects,
    fetchOn: isDialog ? !!props.open : true,
    fetchTriggers: props.refreshTriggers,
    useAdvancedFilters: false,
  });

  const assignedTable: IUseTable = useTable({
    saveKey: props.saveKeyAssigned,
    columns: props.assignedTableColumns,
    infiniteScrolling: true,
    dependencies: [isDialog ? !!props.open : true],
    rowKeyGetter:
      props.assignedTableRowKeyGetter ||
      ((row: IEntityObject) =>
        String(row[props.assignedTableRowKey || DEFAULT_ROW_KEY])),
    fetchFunction: fetchAssignedObjects,
    fetchOn: isDialog ? !!props.open : true,
    fetchTriggers: props.refreshTriggers,
    useAdvancedFilters: false,
  });

  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  const assignSelectedObjects = async (): Promise<any> => {
    try {
      availableTable.forceLoadingState(true);
      await props.assignAction(availableTable.selectedRowIds);
      if (!props.refreshTriggers) {
        await availableTable.refresh();
        await assignedTable.refresh();
      }
      availableTable.setSelectedRowIds(new Set([]));
    } catch (message) {
      availableTable.forceLoadingState(false);
      notify.error({ message });
    }
  };

  const unassignSelectedObjects = async (): Promise<void> => {
    try {
      availableTable.forceLoadingState(true);
      await props.unassignAction(assignedTable.selectedRowIds);
      if (!props.refreshTriggers) {
        await availableTable.refresh();
        await assignedTable.refresh();
      }
      assignedTable.setSelectedRowIds(new Set([]));
    } catch (message) {
      availableTable.forceLoadingState(false);
      notify.error({ message });
    }
  };

  const isLoading: boolean =
    assignedTable.isLoading || availableTable.isLoading;
  const availableTableTitle: string =
    props.availableTableTitle || t(DEFAULT_AVAILABLE_TABLE_TITLE);
  const assignedTableTitle: string =
    props.assignedTableTitle || t(DEFAULT_ASSIGNED_TABLE_TITLE);

  const renderContent = (): ReactElement => (
    <>
      <RiverSpinner show={isLoading} />
      <div className={clsx([styles.tableContainer, styles.availableObjects])}>
        <TableContext.Provider
          value={{
            table: availableTable,
            entity: availableEntity,
          }}
        >
          <AvailableObjectsGridHeader
            title={availableTableTitle}
            assignSelectedObjects={assignSelectedObjects}
            classes={props.classes?.availableTableGridHeaderStyles}
          />
          <RiverDataGrid
            columns={availableTable.columns}
            rows={availableTable.entities}
            rowKeyGetter={availableTable.rowKeyGetter}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            selectedRows={availableTable.selectedRowIds}
            onSelectedRowsChange={(rowKeys) =>
              availableTable.setSelectedRowIds(rowKeys)
            }
            onRowsChange={props.onAvailableTableRowsChange}
            sortColumns={availableTable.sortColumns}
            onSortColumnsChange={(e) => {
              availableTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </div>
      <div className={clsx([styles.tableContainer, styles.assignedObjects])}>
        <TableContext.Provider
          value={{
            table: assignedTable,
            entity: assignedEntity,
          }}
        >
          <AssignedObjectsGridHeader
            title={assignedTableTitle}
            unassignSelectedObjects={unassignSelectedObjects}
            classes={props.classes?.assignedTableGridHeaderStyles}
          />
          <RiverDataGrid
            columns={assignedTable.columns}
            rows={assignedTable.entities}
            rowKeyGetter={assignedTable.rowKeyGetter}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            selectedRows={assignedTable.selectedRowIds}
            onSelectedRowsChange={(rowKeys) =>
              assignedTable.setSelectedRowIds(rowKeys)
            }
            onRowsChange={props.onAssignedTableRowsChange}
            sortColumns={assignedTable.sortColumns}
            onSortColumnsChange={(e) => {
              assignedTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </div>
    </>
  );
  return (
    <>
      {isDialog && (
        <RiverDialog
          title={props.dialogTitle}
          open={props.open!}
          onClose={handleClose}
          actionsContent={
            props.footerContent || (
              <RiverDialogButton
                onClick={handleClose}
                text={t("common.button:close")}
              />
            )
          }
          classes={{
            paper: clsx([styles.paper, { [styles.mobile]: isMobile }]),
            title: styles.title,
            content: styles.content,
            actions: styles.actions,
          }}
          showActionsDivider={false}
          dialogProps={{
            maxWidth: false,
          }}
        >
          {renderContent()}
        </RiverDialog>
      )}
      {!isDialog && renderContent()}
    </>
  );
};
