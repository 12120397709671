import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { IAdapterCalendar } from "@river/interfaces";
import { Constants } from "@river/constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Equals, IsNotEmpty } from "class-validator";
import { useTranslation } from "@river/common-ui";

export interface ICalendarAssignForm {
  calendarName: string;
  calendar_id: string;
}

interface UseCalendarAssignFormProps {
  selectedCalendar: IAdapterCalendar | null;
}

export const useCalendarAssignForm = (
  props: UseCalendarAssignFormProps
): RiverFormInstance => {
  const { t } = useTranslation();

  class CalendarAssignFormValidator {
    constructor(obj: ICalendarAssignForm) {
      Object.assign(this, obj);
    }

    @Equals(props.selectedCalendar?.calendar || "", {
      message: (opts): string =>
        `shared.error_messages:validation.calendar_name?name=${opts.value}`,
    })
    @IsNotEmpty()
    // @ts-ignore
    calendarName: string;

    // @ts-ignore
    calendar_id: string;
  }

  return useRiverForm<Object, Object, CalendarAssignFormValidator>({
    standalone: {
      fields: new CalendarAssignFormValidator({
        calendarName: "",
        calendar_id: "",
      }),
      fieldDefs: [
        {
          fieldName: "calendarName",
          dataType: Constants.data_type.string,
        },
        {
          fieldName: "calendar_id",
          dataType: Constants.data_type.string,
        },
      ],
      getValidatorInstance: (obj: ICalendarAssignForm) =>
        new CalendarAssignFormValidator(obj),
    },
    labels: {
      calendarName: t("shared.lookup:calendar.title"),
    },
  });
};
