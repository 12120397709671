import { useContext } from "react";
import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IScheduleContext,
  IUserContextSite,
} from "../../context";
import { AdapterService } from "../adapter.service";
import { ScheduleUiService, ScheduleTasksTabId } from "./index";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import { IAdapterFolder, QueryDto } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { IColumn } from "../../interfaces";
import { getFolderQueryGroup } from "@river/util";

interface IUseScheduleAssignmentsProps {
  scheduleContext: IScheduleContext;
}

export const useScheduleAssignments = (
  props: IUseScheduleAssignmentsProps
): IUseEntityTable => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const scheduleUiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const assignmentsTabId: ScheduleTasksTabId = ScheduleTasksTabId.ASSIGNMENTS;

  const scheduleContext: IScheduleContext = props.scheduleContext;
  const currentSchedule: IAdapterFolder = scheduleContext.currentSchedule!;

  const currentTab: ScheduleTasksTabId = scheduleUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];
  const { t } = useTranslation();
  const entityHelpers = useEntityHelpers();

  const defaultAssignmentColumns: IColumn[] =
    scheduleUiService.getDefaultAssignmentColumns()();

  const fetchAssignments = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = fetchHelpers.getTableQuery({
      fetchProps,
      ...(currentSchedule && {
        initialQueryAttributeGroup: getFolderQueryGroup(currentSchedule, ""),
      }),
    });

    table.setLastRanQueryDto(query);

    const assignments = await adapterService.fetchAssignments(query);
    const assignmentRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.assignment
    );

    assignments.forEach((as) => {
      try {
        entityHelpers.setAttributeValue(
          as,
          uiConstants.fields.rowType,
          uiConstants.rowType.assignment
        );
        entityHelpers.setAttributeValue(
          as,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(as, assignmentRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing Assignment _id: ${as._id}`);
        console.error(e);
      }
    });

    return assignments;
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "operation",
      columns: defaultAssignmentColumns,
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    saveKey: `schedule.assignments`,
    columns,
    fetchFunction: fetchAssignments,
    dependencies: [!!site, currentTab === assignmentsTabId],
    fetchOn: currentTab === assignmentsTabId,
    initialSimpleFilters:
      scheduleUiService.getInitialTabSimpleFilters(assignmentsTabId),
    initialQuery: scheduleUiService.getInitialTabQuery(assignmentsTabId),
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    keepSelection: true,
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      scheduleUiService.taskInitialFiltersOverrides[assignmentsTabId] = null;
      scheduleUiService.taskInitialQueryOverrides[assignmentsTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
