import { IAdapterBaseline, IAdapterFolder, QueryDto } from "@river/interfaces";
import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { useNotification, useTranslation } from "@river/common-ui";
import { useContext } from "react";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../context";
import {
  IUseTable,
  TableFetchFunction,
  useTable,
} from "../../components/shared";
import { TFunction } from "i18next";
import { uiConstants } from "../../helpers";
import { useEntity } from "../../hooks";
import { IScheduleProgressFilters } from "../schedule-utilization-ui-service";

export interface IScheduleReportProgressSummaryProps {
  currentBaseline?: IAdapterBaseline;
  progressSummaryFilters: IScheduleProgressFilters;
  currentSchedule?: IAdapterFolder;
}

export const useScheduleReportUtilizationProgressSummary = (
  props: IScheduleReportProgressSummaryProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService = adapterContext?.service.getScheduleReportUiService()!;
  const notify = useNotification();
  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const fetchProgressSummary: TableFetchFunction = async () => {
    const customFilters: QueryDto = await uiService.getProgressFilters({
      adapterContext,
      progressSummaryFilters: props.progressSummaryFilters,
    })()!;
    let result = [];
    try {
      if (props?.currentSchedule?._id) {
        result = await adapterContext!.service
          .getAdapterService()
          .fetchFolderProgressSummary(
            props.currentSchedule._id,
            props.currentBaseline?._id || null,
            customFilters
          );
      }
    } catch (message) {
      notify.error({ message });
    }

    return result;
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "progress_summary",
      columns: [],
    };
  };

  const { entityName } = getTableParams(t);

  const table: IUseTable = useTable({
    saveKey: "utilization.progress",
    columns: [],
    fetchFunction: fetchProgressSummary,
    dependencies: [!!site],
    fetchOn: true,
    fetchTriggers: [
      site,
      props.currentBaseline,
      props.progressSummaryFilters,
      props.currentSchedule,
    ],
    rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
