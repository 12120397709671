import React, { ReactElement, useContext } from "react";
import { Drawer } from "@mui/material";
import { RiverDrawerContext } from "../../../context";
import styles from "./river-drawer.module.scss";

export const RiverDrawer: React.FC = (): ReactElement => {
  const { drawer } = useContext(RiverDrawerContext)!;
  const { drawerOpened, drawerWidth, portalRef } = drawer;

  const drawerPixelWidth: string = `${drawerWidth}px`;

  return (
    <Drawer
      open={drawerOpened}
      variant={"persistent"}
      anchor={"right"}
      sx={{
        width: drawerPixelWidth,
        "& .MuiDrawer-paper": {
          width: drawerPixelWidth,
        },
      }}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.portal} ref={portalRef} />
    </Drawer>
  );
};
