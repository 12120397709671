import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { useContext } from "react";
import { IsPersonKeyExists } from "../../../supervisor-schedule-ui-service";
import { IUseTimeCardFormProps } from "../../../supervisor-timecard-ui.service";
import {
  IAdapterTimeCard,
  IEntityObject,
  OracleEbsAdapterTimeCardDto,
} from "@river/interfaces";
import { IsNotEmpty } from "class-validator";

interface IStandaloneValidator {
  laborInstanceName: string;
}

export const useOracleCloudSupervisorOperationTimecardForm = (
  props: IUseTimeCardFormProps
) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  class StandaloneValidator {
    constructor(obj: StandaloneValidator) {
      Object.assign(this, obj);
    }
    @IsNotEmpty()
    @IsPersonKeyExists({ context: { adapterContext } })
    // @ts-ignore
    laborInstanceName: string;
  }

  const form: RiverFormInstance = useRiverForm<
    OracleEbsAdapterTimeCardDto,
    IAdapterTimeCard,
    Object
  >({
    initialDto: Object.assign(new OracleEbsAdapterTimeCardDto(), {
      folder_id: props.folderId,
      operation_id: props.operation?._id,
      operation_resource_id: (
        props.operation?.WorkOrderOperationResource as IEntityObject
      )?._id,
      LaborInstanceName: props.initialValues?.LaborInstanceName || "",
      timecard_date: props.initialValues?.timecard_date || new Date(),
      timecard_hours: props.initialValues?.timecard_hours || 1,
    }),
    standalone: {
      fields: new StandaloneValidator({
        laborInstanceName: "",
      }),
      fieldDefs: [
        {
          fieldName: "laborInstanceName",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },

    entityName: "timecard",
    instanceToEdit: props.timecard,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (
      dto: OracleEbsAdapterTimeCardDto
    ): Promise<IAdapterTimeCard> => {
      return await adapterContext!.service
        .getAdapterService()
        .createTimeCard(dto);
    },
    update: async (dto: OracleEbsAdapterTimeCardDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateTimeCard(props.timecard!._id!, dto);
    },
    labels: {
      laborInstanceName: t("entity.timecard:timecard.LaborInstanceName"),
      timecard_date: t("entity.timecard:timecard.timecard_date"),
      timecard_hours: t("entity.timecard:timecard.timecard_hours"),
    },
  });

  return {
    form,
  };
};

export type IUseOracleCloudSupervisorOperationTimecardForm = ReturnType<
  typeof useOracleCloudSupervisorOperationTimecardForm
>;
