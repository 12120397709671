import React, { ReactElement, useContext, useEffect } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import { RiverDialog, RiverSpinner, useNotification } from "@river/common-ui";
import { LookupType, RiverLookup } from "../../../../components/shared";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { fetchHelpers, uiConstants } from "../../../../helpers";
import { TableUiService } from "../../../table-ui.service";
import {
  IOracleEbsWorkOrderUncomplete,
  useOracleEbsWoUncompleteForm,
} from "./use-oracle-ebs-wo-uncomplete-form";
import {
  IEntityObject,
  OracleEbsAdapterWorkorderUncompletionDto,
} from "@river/interfaces";

interface IOracleEbsWoUncompleteDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
  workorderIds?: string[];
}

export const OracleEbsWoUncompleteDialog: React.FC<
  IOracleEbsWoUncompleteDialogProps
> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useOracleEbsWoUncompleteForm({
    submit: async () => {
      const updatedRows = await submitUseUncomplete();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.workOrder),
          updatedRow,
        })),
      });
    },
  });

  const { submit, isProcessing, setStandaloneFields, standalone } = form;
  const uncompleteFields: IOracleEbsWorkOrderUncomplete =
    form.standalone as IOracleEbsWorkOrderUncomplete;

  const resetDialogState = (): void => {
    form.resetForm();
    fetchDefaultStatus();
  };

  const fetchDefaultStatus = async () => {
    const defaultStatus: IEntityObject[] =
      (await adapterContext?.service.getAdapterService().searchEntityData(
        "wo_status",
        fetchHelpers.getTableQuery({
          fetchProps: { columnFilters: [], sortColumns: [] },
          initialQueryAttributes: [
            {
              attribute_name: "STATUS_ID",
              attribute_value: {
                operator: "$eq",
                value: 3,
              },
            },
          ],
        })
      )) || [];

    if (defaultStatus.length > 0) {
      setStandaloneFields({
        ...(standalone as IOracleEbsWorkOrderUncomplete),
        userStatusName: defaultStatus[0].WORK_ORDER_STATUS,
        USER_DEFINED_STATUS_ID: defaultStatus[0].STATUS_ID,
      });
    }
  };

  useEffect(() => {
    fetchDefaultStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = (success: boolean) => {
    resetDialogState();
    props.onClose(success);
  };

  const submitUseUncomplete = async (): Promise<any[]> => {
    const promises: Promise<void>[] = [];
    const errors: any[] = [];
    if (props.workorderIds) {
      props.workorderIds.forEach((id: string) => {
        promises.push(
          adapterContext!.service
            .getAdapterService()
            .uncompleteWorkorder({
              _id: id,
              STATUS_TYPE: uncompleteFields.USER_DEFINED_STATUS_ID,
            } as OracleEbsAdapterWorkorderUncompletionDto)
            .catch((err) => {
              errors.push(err);
            })
        );
      });
    } else {
      tableContext?.table.selectedRowIds.forEach((id: string) => {
        promises.push(
          adapterContext!.service
            .getAdapterService()
            .uncompleteWorkorder({
              _id: id,
              STATUS_TYPE: uncompleteFields.USER_DEFINED_STATUS_ID,
            } as OracleEbsAdapterWorkorderUncompletionDto)
            .catch((err) => {
              errors.push(err);
            })
        );
      });
    }
    return Promise.all(promises).finally(() => {
      if (errors.length) {
        notify.error({
          title: t(
            "shared.wo_uncomplete_dialog:notification.wo_uncomplete_failed"
          ),
          message: errors[0],
        });
      } else {
        const success = true;
        closeDialog(success);
      }
    }) as Promise<any>;
  };
  const renderWorkOrderStatusField = (): ReactElement => (
    <RiverLookup
      id={"userStatusName"}
      fullWidth
      lookup={{
        type: LookupType.ORACLE_EBS_WO_STATUS,
        customFilters: [
          {
            attribute_name: "SYSTEM_STATUS",
            attribute_value: {
              operator: "$eq",
              value: 3,
            },
          },
        ],
      }}
      onSelect={(selectedPriority) => {
        setStandaloneFields({
          ...(standalone as IOracleEbsWorkOrderUncomplete),
          userStatusName: selectedPriority.WORK_ORDER_STATUS,
          USER_DEFINED_STATUS_ID: selectedPriority.STATUS_ID,
        });
      }}
    />
  );

  return (
    <RiverDialog
      title={t("shared.wo_uncomplete_dialog:title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderWorkOrderStatusField()}
    </RiverDialog>
  );
};
