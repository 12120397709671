import { IScheduleContext } from "../../../context";
import { UtilizationPeriodType } from "@river/interfaces";
import { ScheduleGanttTimeOptionGroupID } from "../schedule-tasks/schedule-gantt-header-options";

export namespace utilizationHelpers {
  export const getUtilizationPeriodType = (
    scheduleContext: IScheduleContext
  ): UtilizationPeriodType => {
    let utilizationPeriodType: UtilizationPeriodType;
    const timeGroupId: ScheduleGanttTimeOptionGroupID =
      scheduleContext?.ganttTimeOption?.groupId!;
    if (timeGroupId) {
      utilizationPeriodType = {
        [ScheduleGanttTimeOptionGroupID.HOURS]: "HOUR",
        [ScheduleGanttTimeOptionGroupID.DAYS]: "DAY",
        [ScheduleGanttTimeOptionGroupID.WEEKS]: "WEEK",
        [ScheduleGanttTimeOptionGroupID.MONTHS]: "MONTH",
      }[timeGroupId] as UtilizationPeriodType;
    }
    return utilizationPeriodType;
  };

  export const getUtilizationPeriods = (
    scheduleContext: IScheduleContext
  ): number => {
    return scheduleContext?.ganttTimeOption?.value || 0;
  };

  export const getRandomGanttAvatarURL = (index: number): string =>
    `/sample-avatars/avatar${(index % 4) + 1}.svg`;
}
