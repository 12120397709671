import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IExecutionContext,
} from "../../context";
import { useContext, useEffect } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  EXECUTION_TIMECARDS_TABLE_SAVE_KEY,
  ExecutionUiService,
} from "./execution-ui.service";
import { useTranslation } from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import { IExecutionAction } from "../../components/execution/execution";

interface IuseExecutionTimecardsProps {
  executionContext: IExecutionContext;
}

export const useExecutionTimecards = (
  props: IuseExecutionTimecardsProps
): IUseEntityTable => {
  const { currentWorkOrder, setCurrentActions } = props.executionContext;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;

  const entityHelpers = useEntityHelpers();
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();

  const entityName: string = "timecard";

  const timecardActions: IExecutionAction[] = [
    {
      label: t("common.button:confirm"),
      onClick: () => {
        console.log("Timecard Confirm");
      },
    },
    {
      label: t("common.button:cancel"),
      onClick: () => {
        console.log("Timecard Cancel");
      },
    },
  ];

  useEffect(() => {
    if (currentTab === ExecutionTabId.TIMECARDS) {
      setCurrentActions(timecardActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const columns: IColumn[] = uiService.getDefaultTimecardColumns({
    timecardActions,
  })();

  const fetchTimecards = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrder) return [];
    const timecards: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .searchEntityData(
        "timecard",
        fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: [
            {
              attribute_name: "workorder_id",
              attribute_value: {
                operator: "$eq",
                value: currentWorkOrder!._id,
              },
            },
          ],
        })
      );

    timecards.forEach((tc) => {
      entityHelpers.setAttributeValue(
        tc,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(tc, "_id")
      );
    });

    return timecards;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: EXECUTION_TIMECARDS_TABLE_SAVE_KEY,
    columns,
    fetchFunction: fetchTimecards,
    dependencies: [currentTab === ExecutionTabId.TIMECARDS],
    fetchOn: currentTab === ExecutionTabId.TIMECARDS,
    fetchTriggers: [currentWorkOrder],
    clearBeforeFetch: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
