import { FC } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import styles from "./report-progress-card.module.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export enum EIndicatorType {
  PLAIN,
  ABOVE_LIMIT,
  UNDER_LIMIT,
}

export interface IReportProgressCardAttr {
  value: number;
  tooltip: string;
}

interface IProps {
  title: string;
  mainValue: number;
  indicatorValue?: string;
  /**
   * @description if indicatorValue is passed you can use this property to show is it above or under some limit, by default is equal to PLAIN (0) which means that shown value will be presented as plain indicator
   * @default EIndicatorType.PLAIN (0)
   */
  indicatorType?: EIndicatorType;
  attrA: IReportProgressCardAttr;
  attrB: IReportProgressCardAttr;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E8E8E8",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: "#2843C8",
  },
}));

export const ReportProgressCard: FC<IProps> = (props) => {
  const { title, mainValue, indicatorValue, indicatorType, attrA, attrB } =
    props;

  function getIndicatorStyle() {
    switch (indicatorType) {
      case EIndicatorType.ABOVE_LIMIT:
        return styles.above_indicator;
      case EIndicatorType.UNDER_LIMIT:
        return styles.under_indicator;
      default:
      case EIndicatorType.PLAIN:
        return styles.plain_indicator;
    }
  }

  function getIndicatorIcon() {
    switch (indicatorType) {
      case EIndicatorType.ABOVE_LIMIT:
        return <ArrowUpwardIcon />;
      case EIndicatorType.UNDER_LIMIT:
        return <ArrowDownwardIcon />;
      default:
      case EIndicatorType.PLAIN:
        return null;
    }
  }

  function roundValue(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  return (
    <Card className={styles.root}>
      <CardContent className={styles.content}>
        <Box className={styles.titleSection}>
          <Typography className={styles.title}>{title}</Typography>
        </Box>
        <Box className={styles.valueSection}>
          <Box className={styles.percentageSection}>
            <Typography className={styles.mainValue}>{mainValue} %</Typography>
            {indicatorValue !== undefined && (
              <Box
                className={clsx([
                  styles.indicatorContainer,
                  getIndicatorStyle(),
                ])}
              >
                {getIndicatorIcon()}
                <Typography className={styles.indicator}>
                  {indicatorValue}
                </Typography>
              </Box>
            )}
          </Box>
          <div className={styles.legendSection}>
            <span className={styles.attribute} title={attrA.tooltip}>
              {roundValue(attrA.value)}
            </span>
            <span className={styles.slash}>/</span>
            <span className={styles.attribute} title={attrB.tooltip}>
              {roundValue(attrB.value)}
            </span>
          </div>
        </Box>
        <BorderLinearProgress variant="determinate" value={mainValue} />
      </CardContent>
    </Card>
  );
};
