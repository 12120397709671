import React, { ReactElement } from "react";
import { GenericAction } from "../../components/shared";
import { useGridActionHelpers } from "../../helpers";

interface IUseShowTabActionProps {
  title: string;
  tabKey: string;
  icon: React.ComponentType<any>;
  enabled?: boolean;
  onClick: (event: React.MouseEvent<any>) => void;
}

export const useShowTabAction = (props: IUseShowTabActionProps) => {
  const { withSanitizedProps } = useGridActionHelpers();

  const renderIcon = (iconProps?: any): ReactElement => (
    <GenericAction
      icon={props.icon}
      title={props.title}
      enableOn={props.enabled}
      onClick={props.onClick}
      {...iconProps}
    />
  );

  const renderShowTabAction = (): ReactElement => <>{renderIcon()}</>;

  return {
    renderShowTabAction,
    showTabAction: {
      icon: withSanitizedProps(renderIcon),
      title: props.title,
      onClick: props.onClick,
    },
  };
};

export type IUseShowTabAction = ReturnType<typeof useShowTabAction>;
