import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { AdapterUserDto } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useContext } from "react";
import { AdapterUiService } from "../../adapter-ui.service";
import { AdapterService } from "../../adapter.service";

export interface IUseOracleCloudUserRoleFormProps {
  userRole: AdapterUserDto | null;
  onUpdate?: () => void;
}

export const useOracleCloudUserRoleForm = (
  props: IUseOracleCloudUserRoleFormProps
): RiverFormInstance => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterUiService: AdapterUiService = adapterContext?.service!;
  const adapterService: AdapterService = adapterUiService.getAdapterService();

  const getInitialDto = (): AdapterUserDto =>
    Object.assign(new AdapterUserDto(), {
      user_name: "",
      email: "",
      first_name: "",
      last_name: "",
      display_name: "",
      roles: [],
    });

  return useRiverForm<AdapterUserDto, AdapterUserDto, Object>({
    entityName: "user_role",
    initialDto: getInitialDto(),
    instanceToEdit: props.userRole,
    onUpdate: props.onUpdate,
    update: async (dto: AdapterUserDto): Promise<void> => {
      await adapterService.updateUser(props.userRole!["_id"], dto);
    },
    render: () => <></>,
  });
};
