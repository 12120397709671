import React, { ReactElement, useState, useContext, useEffect } from "react";
import { SupervisorScheduleContext } from "../../../../../context";
import {
  SupervisorScheduleGanttTimeOptionGroupID,
  SupervisorScheduleGanttTimeOptionID,
  ISupervisorScheduleGanttTimeOption,
  ISupervisorScheduleGanttTimeOptionGroup,
} from "./types";
import { useSupervisorScheduleGanttTime } from "./use-supervisor-schedule-gantt-time";
import { useRiverCustomPopup } from "../../../../shared";
import { Divider, FormControlLabel, RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import styles from "./supervisor-schedule-gantt-time-options.module.scss";

const DEFAULT_GANTT_TIME_OPTION = SupervisorScheduleGanttTimeOptionID._7DAYS;

export const SupervisorScheduleGanttTimeOptions: React.FC =
  (): ReactElement => {
    const scheduleContext = useContext(SupervisorScheduleContext);

    const { ganttTimeOptions, ganttTimeOptionGroups } =
      useSupervisorScheduleGanttTime();
    const [timeOption, setTimeOption] = useState<
      ISupervisorScheduleGanttTimeOption | undefined
    >(scheduleContext?.ganttTimeOption!);
    const [timeOptionGroupID, setTimeOptionGroupID] =
      useState<SupervisorScheduleGanttTimeOptionGroupID>(
        scheduleContext?.ganttTimeOption!?.groupId ||
          SupervisorScheduleGanttTimeOptionGroupID.DAYS
      );

    useEffect(() => {
      setTimeOption(scheduleContext?.ganttTimeOption);
    }, [scheduleContext?.ganttTimeOption]);

    const optionGroups: ISupervisorScheduleGanttTimeOptionGroup[] = [
      ganttTimeOptionGroups[SupervisorScheduleGanttTimeOptionGroupID.HOURS],
      ganttTimeOptionGroups[SupervisorScheduleGanttTimeOptionGroupID.DAYS],
      ganttTimeOptionGroups[SupervisorScheduleGanttTimeOptionGroupID.WEEKS],
      ganttTimeOptionGroups[SupervisorScheduleGanttTimeOptionGroupID.MONTHS],
    ];
    useEffect(() => {
      scheduleContext?.setGanttTimeOption(
        ganttTimeOptions[DEFAULT_GANTT_TIME_OPTION]
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = (): ReactElement => (
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.body}>
            <div className={styles.items}>
              <RadioGroup
                value={timeOptionGroupID}
                onChange={(event, value) => {
                  setTimeOptionGroupID(
                    value as SupervisorScheduleGanttTimeOptionGroupID
                  );
                  setTimeOption(undefined);
                }}
              >
                {optionGroups.map((group, index) => (
                  <div key={index} className={styles.item}>
                    <FormControlLabel
                      value={group.id}
                      control={
                        <Radio
                          classes={{ root: styles.radioRoot }}
                          color={"primary"}
                        />
                      }
                      label={group.label}
                      classes={{
                        root: styles.labelRoot,
                        label: styles.label,
                      }}
                    />
                  </div>
                ))}
              </RadioGroup>
            </div>
          </div>
        </div>
        <Divider className={styles.divider} flexItem />
        <div className={styles.section}>
          <div className={styles.body}>
            <div className={styles.items}>
              <RadioGroup
                value={timeOption?.id || null}
                onChange={(event, value) => {
                  const option: ISupervisorScheduleGanttTimeOption =
                    ganttTimeOptions[
                      value as SupervisorScheduleGanttTimeOptionID
                    ];
                  scheduleContext?.setGanttTimeOption(option);
                  closePopup();
                }}
              >
                {ganttTimeOptionGroups[timeOptionGroupID].options.map(
                  (option, index) => (
                    <div key={index} className={styles.item}>
                      <FormControlLabel
                        value={option.id}
                        control={
                          <Radio
                            classes={{ root: styles.radioRoot }}
                            color={"primary"}
                          />
                        }
                        label={option.label}
                        classes={{
                          root: styles.labelRoot,
                          label: styles.label,
                        }}
                      />
                    </div>
                  )
                )}
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    );

    const riverCustomPopup = useRiverCustomPopup({
      toggleLabel: scheduleContext?.ganttTimeOption?.label,
      content: renderContent(),
      classes: { toggle: styles.toggle },
    });
    const { closePopup } = riverCustomPopup;

    return <>{riverCustomPopup.render()}</>;
  };
