import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { RoleDialog } from "../role-dialog";
import { TableContext } from "../../../context";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { IAdapterRole } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { RolesAction } from "../roles";

interface RolesSubHeaderProps {
  openEditDialog(role: IAdapterRole): void;
}

export const RolesSubHeader: React.FC<RolesSubHeaderProps> = (props) => {
  const tableContext = useContext(TableContext);
  const [roleDialogOpened, setRoleDialogOpened] = useState<boolean>(false);
  const { t } = useTranslation();

  const openDialog = (): void => {
    tableContext?.table.setSelectedRowIds(new Set());
    setRoleDialogOpened(true);
  };
  const closeDialog = (): void => setRoleDialogOpened(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction module={ModuleKey.ROLES} action={RolesAction.CREATE}>
          <PrimaryButton
            onClick={openDialog}
            text={t("module.roles:button.add_role")}
          />
        </ProtectedAction>
      </div>
      <RoleDialog
        open={roleDialogOpened}
        onClose={(role) => {
          closeDialog();
          if (!!role) {
            tableContext?.table.fetch();
            props.openEditDialog(role);
          }
        }}
      />
    </>
  );
};
