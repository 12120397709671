import { useTranslation } from "@river/common-ui";
import {
  QueryProvider,
  ScheduleReportUiService,
  ReactElementProvider,
  ICraftSelectorProps,
  IGetProgressFiltersProps,
  ProgressSummaryFilterID,
} from "..";
import { QueryAttributeDto } from "@river/interfaces";
import { ReactElement, useContext } from "react";
import { ScheduleReportContext } from "../../context";
import { GenericAction } from "../../components/shared";
import TuneIcon from "@mui/icons-material/Tune";
import { JdeCraftSelector } from "./jde-shared/jde-craft-selector";
import {
  IJdeScheduleReportProgressFiltersDto,
  JdeScheduleReportProgressFilters,
} from "./jde-schedule-report-utilization-ui-service/jde-schedule-progress-filters";

export class JdeScheduleReportUiService extends ScheduleReportUiService {
  getErpSpecificI18nNamespaces() {
    return [
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_part",
      "entity.pm_activity_type",
      "entity.planner_group",
      "entity.priority",
      "entity.revision",
      "entity.system_condition",
      "entity.wbs",
      "entity.workcenter_utilization",
    ];
  }

  getProgressFilters(props: IGetProgressFiltersProps): QueryProvider {
    return async () => {
      const queryAttributes: QueryAttributeDto[] = [];

      //@ts-check
      let workcenterFilters: any[] =
        (
          props.progressSummaryFilters as IJdeScheduleReportProgressFiltersDto
        )?.[ProgressSummaryFilterID.WORKCENTER] || [];

      if (!!workcenterFilters.length) {
        queryAttributes.push({
          attribute_name: "WorkCenterId",
          attribute_value: {
            operator: "$in",
            value: workcenterFilters.map(
              (workcenter) => workcenter.WorkCenterId
            ),
          },
        });
      }

      return {
        query: { $and: queryAttributes },
      };
    };
  }

  renderProgressFilters(): ReactElementProvider {
    return () => <JdeScheduleReportProgressFilters />;
  }

  getProgressHeaderActionsRenderer(): () => ReactElementProvider {
    return () => {
      const { t } = useTranslation();
      const scheduleReportContext = useContext(ScheduleReportContext);
      const { progressFiltersOpened, setProgressFiltersOpened } =
        scheduleReportContext!;
      return () => (
        <GenericAction
          icon={TuneIcon}
          title={t("module.schedule_report:.progress_filters_toggle")}
          onClick={() => {
            setProgressFiltersOpened(!progressFiltersOpened);
          }}
        />
      );
    };
  }

  getCraftLabelPropertyValue(props: any) {
    return props.WorkCenter;
  }

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <JdeCraftSelector {...props} />
  );
}
