import { IEntityDefinition } from "@river/interfaces";
import { Constants } from "@river/constants";

export const entityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "",
    entity_name: "entity",
  },
  attributes: [
    {
      _id: "entity_name",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "entity_name",
      data_type: Constants.data_type.string,
      description: "Entity Name",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "description",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "description",
      data_type: Constants.data_type.string,
      description: "Description",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "collection_name",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "collection_name",
      data_type: Constants.data_type.string,
      description: "Collection/Table",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "external_url",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "external_url",
      data_type: Constants.data_type.string,
      description: "External URL",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_custom",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "is_custom",
      data_type: Constants.data_type.boolean,
      description: "Custom?",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_readonly",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "is_readonly",
      data_type: Constants.data_type.boolean,
      description: "Read Only",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_schema",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "is_schema",
      data_type: Constants.data_type.boolean,
      description: "Is Schema",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "add_timestamps",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "add_timestamps",
      data_type: Constants.data_type.boolean,
      description: "Add Timestamps",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "add_id",
      adapter_type: "",
      entity_name: "entity",
      attribute_name: "add_id",
      data_type: Constants.data_type.boolean,
      description: "Add Id",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
