import React, { ReactElement, useContext, useState } from "react";
import { IEntityObject } from "@river/interfaces";
import {
  GridHeader,
  EditAction,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { WorkRequestsAction } from "../notifications";
import { ModuleKey } from "../../sidebar-menu";
import { NotificationDialog } from "../notification-dialog";
import { TableContext } from "../../../context";

interface INotificationsGridHeader {
  className?: string;
  selectedNotification: IEntityObject;
}

export const NotificationsGridHeader: React.FC<INotificationsGridHeader> = (
  props
): ReactElement => {
  const [notificationToEdit, setNotificationToEdit] =
    useState<IEntityObject | null>(props.selectedNotification);
  const [notificationDialogOpened, setNotificationDialogOpened] =
    useState<boolean>(false);
  const tableContext = useContext(TableContext);

  const openEditDialog = (): void => {
    if (props.selectedNotification) {
      setNotificationToEdit(props.selectedNotification);
      setNotificationDialogOpened(true);
    }
  };
  const closeDialog = (): void => setNotificationDialogOpened(false);

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.WORK_REQUESTS}
            action={WorkRequestsAction.UPDATE}
          >
            <EditAction onClick={openEditDialog} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>

      {notificationDialogOpened && (
        <NotificationDialog
          open={notificationDialogOpened}
          notification={notificationToEdit}
          onClose={(success) => {
            closeDialog();
            if (success) tableContext?.table.fetch();
          }}
        />
      )}
    </>
  );
};
