import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { UsersUiService } from "../users-ui.service";
import { useTableCellRenderers } from "../../hooks";

export class SapUsersUiService extends UsersUiService {
  protected getUserEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    const { renderTextCell } = useTableCellRenderers();
    return {
      entityName: "user",
      columns: [
        { key: "UserName", name: t("entity.user:user.UserName") },
        { key: "FirstName", name: t("entity.user:user.FirstName") },
        { key: "LastName", name: t("entity.user:user.LastName") },
        { key: "UserGroup", name: t("entity.user:user.UserGroup") },
        { key: "UserType", name: t("entity.user:user.UserType") },
        {
          key: "ValidToday",
          name: t("entity.user:user.ValidToday"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: formatterProps.row["ValidToday"] ? "Yes" : "No",
              })}
            </>
          ),
        },
      ],
    };
  };

  getUserNameKey = () => "UserName";

  getErpSpecificI18nNamespaces = () => [];
}
