import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";

export function usePriorityLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    entityName?: string
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];
    const lookupEntityName: string = entityName || "priority";

    return {
      title: t("shared.lookup:priority.title"),
      rowKey: "Priority",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          { key: "Priority", name: t("entity.priority:priority.Priority") },
          {
            key: "PriorityType",
            name: t("entity.priority:priority.PriorityType"),
          },
          {
            key: "PriorityText",
            name: t("entity.priority:priority.PriorityText"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).searchEntityData(
            lookupEntityName,
            fetchHelpers.getTableQuery({ fetchProps })
          );
        },
        dependencies: [!!site],
        saveKey: "priorities.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: lookupEntityName,
      },
    };
  };
}

export type IUsePriorityLookupDef = ReturnType<typeof usePriorityLookupDef>;
