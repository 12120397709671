import { ReactElement, useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import CheckIcon from "@mui/icons-material/Check";
import { AdapterPostActionDto } from "@river/interfaces";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ISupervisorScheduleContext,
  SupervisorScheduleContext,
} from "../../../context";
import headerStyles from "../../../components/shared/common-header/common-header.module.scss";
import { IUseTable } from "../../../components/shared";

export const useCommitScheduleAction = () => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const scheduleContext: ISupervisorScheduleContext = useContext(
    SupervisorScheduleContext
  )!;
  const table: IUseTable = scheduleContext?.currentTasksTableRef.current!;
  const notify = useNotification();

  const title: string = t(
    "module.supervisor_schedule:action_label.commit_schedule"
  );

  const onClick = async (): Promise<void> => {
    const scheduleId: string = scheduleContext?.currentSchedule?._id!;

    if (scheduleId) {
      const entity_ids =
        scheduleContext.currentTasksTableRef.current?.entities
          .filter(
            (entity) =>
              Array.from(
                scheduleContext.currentTasksTableRef.current?.selectedRowIds ||
                  []
              ).indexOf(String(entity?.table_id)) !== -1
          )
          .map((filtered) => filtered._id as string) || [];

      const action: AdapterPostActionDto = {
        folder_id: scheduleId,
        entity_name: "workorder",
        entity_ids,
      };
      try {
        table.forceLoadingState(true);
        await adapterContext!.service.getAdapterService().post(action);

        await table.refresh();
        notify.success(
          t("module.supervisor_schedule:notification.schedule_posted")
        );
      } catch (message) {
        notify.error({ message });
        table.forceLoadingState(false);
      }
    }
  };

  const renderIcon = (props?: any): ReactElement => (
    <CheckIcon
      titleAccess={title}
      className={headerStyles.actionIcon}
      onClick={onClick}
      {...props}
    />
  );

  const renderCommitScheduleAction = (): ReactElement => <>{renderIcon()}</>;

  return {
    renderCommitScheduleAction,
    commitScheduleAction: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
    },
  };
};

export type IUseCommitScheduleAction = ReturnType<
  typeof useCommitScheduleAction
>;
