import React, { ReactElement } from "react";
import styles from "./avatar.module.scss";
import clsx from "clsx";

interface IAvatarProps {
  url: string;
  className?: string;
}

export const Avatar: React.FC<IAvatarProps> = (props): ReactElement => {
  return (
    <div
      className={clsx([styles.root, props.className])}
      style={{
        backgroundImage: `url("${props.url}")`,
      }}
    />
  );
};
