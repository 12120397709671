import { FC, useContext, useState, useEffect } from "react";
import styles from "./river-icon-picker.module.scss";
import clsx from "clsx";
import { Box } from "@mui/material";
import * as Muicon from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  RiverDialog,
  RiverFormContext,
  useTranslation,
} from "@river/common-ui";
import { RiverFormInstance } from "../../../hooks";

export interface IRiverIconPickerProps {
  iconList: string[];
  title?: string;
  dialogTitle?: string;
  initialIcon?: string;
  className?: string;
  onSelect: (color: string) => void;
  withoutTitle?: boolean;
}

export const riverGenerateIcon = (name: string, props = {}) => {
  //@ts-ignore
  const IconName = Muicon[name];
  return <IconName {...props} />;
};

export const RiverIconPicker: FC<IRiverIconPickerProps> = ({
  title,
  initialIcon,
  dialogTitle,
  iconList,
  className,
  withoutTitle,
  onSelect,
}) => {
  const { t } = useTranslation();
  const DEFAULT_TITLE = t("shared.river_icon_picker:title");
  const DEFAULT_DIALOG_TITLE = t("shared.river_icon_picker:dialog.title");
  const mainTitle: string = title || DEFAULT_TITLE;
  const mainDialogTitle: string = dialogTitle || DEFAULT_DIALOG_TITLE;

  const [icon, setIcon] = useState<string | null>(initialIcon ?? null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const form: RiverFormInstance = useContext(RiverFormContext)
    ?.form as RiverFormInstance;

  const renderDefaultPrevie = () => {
    return (
      <Box className={styles.previewBox}>
        <AddIcon style={{ width: "10px", height: "10px" }} />
        <span className={styles.previewText}>
          {t("shared.river_icon_picker:preview_text")}
        </span>
      </Box>
    );
  };

  const renderIconBox = (iconName: string, index: number) => {
    return (
      <Box
        onClick={() => setIcon(iconName)}
        className={clsx([
          styles.iconBoxContainer,
          icon === iconName ? styles.selectedIcon : null,
        ])}
        key={index}
      >
        {riverGenerateIcon(iconName)}
      </Box>
    );
  };

  const renderPreview = () => (
    <div
      className={styles.pickerContainerPreview}
      onClick={() => {
        if (!form?.isReadOnly) {
          setShowDialog(true);
        }
      }}
    >
      {icon ? riverGenerateIcon(icon) : renderDefaultPrevie()}
    </div>
  );

  useEffect(() => {
    if (initialIcon) {
      setIcon(initialIcon);
    }
  }, [initialIcon]);

  return (
    <>
      <div
        className={clsx([
          styles.root,
          className,
          { [styles.readOnly]: form?.isReadOnly },
        ])}
      >
        {!withoutTitle && (
          <div className={styles.selectorTitle}>{mainTitle}</div>
        )}

        {renderPreview()}

        <RiverDialog
          open={showDialog}
          title={mainDialogTitle}
          showTitleDivider={false}
          onClose={() => {
            setShowDialog(false);
          }}
          classes={{
            paper: styles.dialogPaper,
            content: styles.dialogContent,
          }}
          actionButtonText={t("common.button:select")}
          onSubmit={() => {
            if (!!icon && icon !== initialIcon) {
              onSelect(icon);
            }

            setShowDialog(false);
          }}
        >
          <div className={styles.iconBoxListContainer}>
            {iconList.map((iconName, index) => renderIconBox(iconName, index))}
          </div>
        </RiverDialog>
      </div>
    </>
  );
};
