import React, { ReactElement, useContext, useState } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../../context";
import { IAdapterBaseline, IAdapterShift } from "@river/interfaces";
import {
  IRiverListPopupItem,
  POPUP_SEPARATOR,
  RiverDropdownList,
} from "../../shared";
import { BaselineCheckmarkIcon } from "../../../icons/baseline-checkmark-icon";
import { useCreateBaselineAction } from "../../../services/supervisor-schedule-ui-service/schedule-actions";
import { useNotification, useTranslation } from "@river/common-ui";
import { useAllowedAction } from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { SupervisorScheduleAction } from "../../../services";
import styles from "./supervisor-baseline-selector.module.scss";

const NO_BASELINE_COLOR = "#F1F1F1";

export const SupervisorBaselineSelector: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotification();
  const isActionAllowed = useAllowedAction();
  const [baselineList, setBaselineList] = useState<IAdapterBaseline[]>([]);
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { createBaselineAction } = useCreateBaselineAction();

  const fetchBaselines = async () => {
    try {
      const result: IAdapterShift[] = await adapterContext!.service
        .getAdapterService()
        .fetchFolderBaselines(scheduleContext?.currentSchedule?._id!);
      setBaselineList(
        result.map((line) => line as unknown as IAdapterBaseline)
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const getPopupItems = (): IRiverListPopupItem[] => {
    let popupItems: IRiverListPopupItem[] = [
      {
        title: t("module.supervisor_schedule:baseline_selector.no_baseline"),
        startIcon: getBaselineStartIcon(NO_BASELINE_COLOR),
        onClick: () => scheduleContext?.setSelectedBaseline(undefined),
        endIcon: !scheduleContext?.selectedBaseline
          ? BaselineCheckmarkIcon
          : undefined,
      },
    ];
    if (baselineList.length !== 0) {
      popupItems.push(POPUP_SEPARATOR);
    }

    baselineList.forEach((item) =>
      popupItems.push({
        title: item.baseline,
        startIcon: getBaselineStartIcon(item?.display_options!),
        onClick: () => scheduleContext?.setSelectedBaseline(item),
        disabled: false,
        isSeparator: false,
        endIcon:
          item._id === scheduleContext?.selectedBaseline?._id
            ? BaselineCheckmarkIcon
            : undefined,
      })
    );
    if (
      isActionAllowed(
        ModuleKey.SUPERVISOR_SCHEDULES,
        SupervisorScheduleAction.CREATE_BASELINE
      )
    ) {
      popupItems.push(POPUP_SEPARATOR);
      popupItems.push({
        title: t("module.supervisor_schedule:baseline_selector.create"),
        onClick: () => createBaselineAction.onClick(),
        classes: {
          title: styles.createBaselineLabel,
        },
      });
    }
    return popupItems;
  };

  const getBaselineStartIcon =
    (color: string): React.ComponentType<any> =>
    (props: any): ReactElement => (
      <div style={{ backgroundColor: color }} {...props}></div>
    );

  const title: string = scheduleContext?.selectedBaseline
    ? scheduleContext?.selectedBaseline?.baseline
    : t("module.supervisor_schedule:baseline_selector.label");

  return (
    <>
      <RiverDropdownList
        title={title}
        tooltip={t("module.supervisor_schedule:baseline_selector.tooltip")}
        onClick={async (dropdown) => {
          await fetchBaselines();
          dropdown.open();
        }}
        classes={{
          popup: styles.popup,
          item: styles.item,
          startIconClass: styles.baselineColorIcon,
          endIconClass: styles.baselineCheckedIcon,
        }}
        items={getPopupItems()}
      />
      {createBaselineAction.renderDialog()}
    </>
  );
};
