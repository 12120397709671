// ------------------
import * as dateFns from "date-fns";

// ------------------
export const parseJdeDateTime = (dateValue: any, timeValue?: number): any => {
  if (dateValue instanceof Date) {
    return dateValue;
  } else if (dateValue) {
    //let newValue = dateFns.parse(dateValue, "yyyyMMdd", new Date());

    let newValue = new Date();

    const year = parseInt((dateValue as string).substring(0, 4));
    const month = parseInt((dateValue as string).substring(4, 6));
    const day = parseInt((dateValue as string).substring(6, 8));

    newValue.setUTCFullYear(year);
    newValue.setUTCMonth(month - 1); // month is zero-based
    newValue.setUTCDate(day);
    newValue.setUTCHours(0);
    newValue.setUTCMinutes(0);
    newValue.setUTCSeconds(0);
    newValue.setUTCMilliseconds(0);

    if (timeValue) {
      const hms = parseJdeTime(timeValue);
      newValue = dateFns.add(newValue, hms);
    }

    return newValue;
  }

  return dateValue;
};

// ------------------
export const parseJdeTime = (value: number): dateFns.Duration => {
  const hms = value.toString().padStart(6, "0");

  //const hms = value.split(/[PT,H,M,S]/).filter((s) => s);

  return {
    hours: parseInt(hms.substring(0, 2)),
    minutes: parseInt(hms.substring(2, 4)),
    seconds: parseInt(hms.substring(4, 6)),
  };
};

// ----------------
export const convertToJdeDate = (date: Date): string => {
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getUTCFullYear().toString().padStart(4, "0");
  return `${month}/${day}/${year}`;
};

// ----------------
export const convertToJdeTime = (date: Date): string => {
  //
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  return `${hours}${minutes}${seconds}`;
};
