import React, { useState, useEffect } from "react";
import { PrimaryButton, authService } from "@river/common-ui";
import styles from "./login-callback.module.scss";
import { useNavigate, useLocation } from "react-router";
import { IAccessTokenPayload } from "@river/interfaces";

interface ILoginCallbackProps {
  onLoggedIn: (userInfo: IAccessTokenPayload | null) => void;
}

export const LoginCallback: React.FC<ILoginCallbackProps> = (props) => {
  //
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();

  // ---------
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const accessToken = queryParams.get("access_token");
    if (!accessToken) {
      const errorMessage = queryParams.get("error");
      setErrorMessage(errorMessage || "Internal Server Error");
      setIsProcessing(false);
      return;
    }
    authService.saveAccessToken(accessToken);
    const userInfo: IAccessTokenPayload = authService.getCurrentUser()!;
    props.onLoggedIn(userInfo);

    navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, isProcessing]);

  return (
    <div className={styles.container} hidden={isProcessing}>
      {errorMessage}

      <br />
      <br />
      <PrimaryButton variant="contained" color="primary" href="/login">
        Retry...
      </PrimaryButton>
    </div>
  );
};
