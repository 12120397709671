import React, { ReactElement, useState } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IEntityObject } from "@river/interfaces";
import ReactDataGrid, { Column } from "react-data-grid";
import { ILookupProps, LookupDialog } from "../river-lookup";
import CancelIcon from "@mui/icons-material/Cancel";
import { RiverFormFieldError } from "@river/common-ui";
import dataGridStyles from "../river-data-grid/river-data-grid.module.scss";
import styles from "./river-table-lookup.module.scss";
import clsx from "clsx";

export enum AddButtonPosition {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

const DEFAULT_ADD_BUTTON_POSITION: AddButtonPosition = AddButtonPosition.BOTTOM;
const DEFAULT_START_ICON: React.ComponentType<any> = AddCircleIcon;
const DEFAULT_ALWAYS_SHOW_TABLE: boolean = false;
const DEFAULT_SINGLE_SELECT: boolean = false;

interface IRiverTableLookupProps {
  rows: any[];
  columns: Column<any>[];
  lookup: ILookupProps;
  onSelect: (selected: IEntityObject[]) => void;
  title?: string;
  buttonLabel: string;
  isReadOnly?: boolean;
  onDelete?: (object: IEntityObject) => void;
  fieldId?: string;
  alwaysShowTable?: boolean;
  addButtonPosition?: AddButtonPosition;
  addButtonIcon?: React.ComponentType<any>;
  singleSelect?: boolean;
  classes?: {
    root?: string;
    addButton?: string;
    addButtonIcon?: string;
    grid?: string;
    deleteCell?: string;
  };
}

export const RiverTableLookup: React.FC<IRiverTableLookupProps> = (props) => {
  const [lookupOpened, setLookupOpened] = useState<boolean>(false);
  const addButtonPosition: AddButtonPosition =
    props.addButtonPosition || DEFAULT_ADD_BUTTON_POSITION;
  const alwaysShowTable: boolean =
    props.alwaysShowTable ?? DEFAULT_ALWAYS_SHOW_TABLE;
  const shouldRenderTable: boolean = alwaysShowTable || props.rows?.length > 0;
  const singleSelect: boolean = props.singleSelect ?? DEFAULT_SINGLE_SELECT;

  const renderLookup = (): ReactElement => (
    <LookupDialog
      open={lookupOpened}
      onClose={() => {
        setLookupOpened(false);
      }}
      singleSelect={singleSelect}
      onSubmit={(selected) => {
        if (selected.length) {
          props.onSelect(selected);
        }
        setLookupOpened(false);
      }}
      lookup={props.lookup}
    />
  );

  const renderAddButton = (): ReactElement => {
    const Icon: React.ComponentType<any> =
      props.addButtonIcon || DEFAULT_START_ICON;
    return (
      <Button
        variant="text"
        color="primary"
        className={clsx([styles.addActionButton, props.classes?.addButton])}
        startIcon={<Icon className={props.classes?.addButtonIcon} />}
        onClick={() => {
          setLookupOpened(true);
        }}
      >
        {props.buttonLabel}
      </Button>
    );
  };

  const columns: Column<any>[] = [...props.columns];
  if (!props.isReadOnly) {
    columns.push({
      key: "removeIcon",
      name: "",
      formatter: (formatterProps) => (
        <div className={clsx([styles.deleteCell, props.classes?.deleteCell])}>
          <CancelIcon
            className={styles.deleteIcon}
            onClick={() => props.onDelete?.(formatterProps.row)}
          />
        </div>
      ),
      width: 50,
    });
  }

  return (
    <>
      <div
        className={clsx([
          styles.root,
          props.classes?.root,
          {
            [styles.addButtonOnTop]:
              addButtonPosition === AddButtonPosition.TOP,
          },
        ])}
      >
        {props.title && <div className={styles.title}>{props.title}</div>}
        {shouldRenderTable && (
          <ReactDataGrid
            rows={props.rows}
            columns={columns}
            className={clsx([
              styles.grid,
              props.classes?.grid,
              dataGridStyles.root,
            ])}
            defaultColumnOptions={{
              resizable: true,
            }}
          />
        )}
        {!props.isReadOnly && renderAddButton()}
        {props.fieldId && (
          <RiverFormFieldError id={props.fieldId} className={styles.error} />
        )}
      </div>
      {renderLookup()}
    </>
  );
};
