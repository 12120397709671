import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import { IEntityObject, QueryAttributeDto } from "@river/interfaces";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";

const COMPOUND_KEY_DELIMITER = "___";

export function useActivityTypesLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:activity_type_def.title"),
      rowKeyGetter: (row: IEntityObject) =>
        String(row["OrderType"]) +
        COMPOUND_KEY_DELIMITER +
        String(row["Pmacttype"]),
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "Pmacttype",
            name: t("entity.pm_activity_type:pm_activity_type.Pmacttype"),
            width: 120,
          },
          {
            key: "OrderType",
            name: t("entity.pm_activity_type:pm_activity_type.OrderType"),
            width: 120,
          },
          {
            key: "Description",
            name: t("entity.pm_activity_type:pm_activity_type.Description"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext.service.getAdapterService() as SapAdapterService
          ).fetchPmActivityTypes(
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: customFilters,
            })
          );
        },
        dependencies: [!!site],
        saveKey: "activity_types.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "pm_activity_type",
      },
    };
  };
}

export type IUseActivityTypesLookupDef = ReturnType<
  typeof useActivityTypesLookupDef
>;
