import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface IJdeWorkOrderMassUpdate {
  F4801_ANP?: number | null;
  F4801_PRTS?: string;
  F4801_SPRT?: string;
  F4801_STCM?: string;
  F4801_SRST?: string;
}

export class JdeWorkOrderMassUpdate {
  constructor(obj: IJdeWorkOrderMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useJdeWoMassUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, JdeWorkOrderMassUpdate>({
    standalone: {
      fields: new JdeWorkOrderMassUpdate({
        F4801_PRTS: "",
        F4801_SPRT: "",
        F4801_STCM: "",
        F4801_SRST: "",
      }),
      fieldDefs: [
        { fieldName: "F4801_ANP", dataType: "double" },
        { fieldName: "F4801_PRTS", dataType: "string" },
        { fieldName: "F4801_SPRT", dataType: "string" },
        { fieldName: "F4801_STCM", dataType: "string" },
        { fieldName: "F4801_SRST", dataType: "string" },
      ],
    },
    submit: props.submit,
    labels: {
      F4801_SRST: t("entity.workorder:workorder.F4801_SRST"),
      F4801_ANP: t("entity.workorder:workorder.F4801_ANP"),
      F4801_PRTS: t("entity.workorder:workorder.F4801_PRTS"),
      F4801_SPRT: t("entity.workorder:workorder.F4801_SPRT"),
      F4801_STCM: t("entity.workorder:workorder.F4801_STCM"),
    },
  });
};
