import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { IEntityObject } from "@river/interfaces";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "./supervisor-schedule-ui-service";

export const useSupervisorScheduleTimecards = (): IUseEntityTable => {
  const entityName: string = "timecard";
  const timecardsTabId: SupervisorScheduleTasksTabId =
    SupervisorScheduleTasksTabId.TIMECARDS;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service!.getSupervisorScheduleUiService()!;
  const scheduleContext = useContext(SupervisorScheduleContext);

  const entityHelpers = useEntityHelpers();
  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  const columns: IColumn[] = uiService.getDefaultTimecardsColumns()();

  const fetchTimecards = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const timecards: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .searchEntityData(
        "timecard",
        fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: [
            {
              attribute_name: "folder_id",
              attribute_value: {
                operator: "$eq",
                value: scheduleContext?.currentSchedule?._id,
              },
            },
          ],
        })
      );

    timecards.forEach((tc) => {
      entityHelpers.setAttributeValue(
        tc,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(tc, "_id")
      );
    });

    return timecards;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: "supervisor.schedule.timecards",
    columns,
    fetchFunction: fetchTimecards,
    dependencies: [currentTab === timecardsTabId],
    fetchOn: currentTab === timecardsTabId,
    fetchTriggers: [scheduleContext?.currentSchedule],
    clearBeforeFetch: true,
    useAdvancedFilters: true,
    infiniteScrolling: true,
    rowKeyGetter: (row) => row[uiConstants.fields.rowId] as string,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
