import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseOperationsLookupProps,
  OperationsUiService,
} from "../operations-ui.service";

export class JdeOperationsUiService extends OperationsUiService {
  getOperationsLookupDefinition = (
    props: IUseOperationsLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];
    const { t, adapterContext, RiverSelectColumn } = props;
    return {
      title: t("shared.lookup:operation.title"),
      rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "F3112_DOCO",
            name: t("entity.operation:operation.F3112_DOCO"),
          },
          {
            key: "F3112_MCU",
            name: t("entity.operation:operation.F3112_MCU"),
          },
          {
            key: "F3112_MCU_desc",
            name: t("entity.operation:operation.F3112_MCU_desc"),
          },
          {
            key: "F3112_OPSQ",
            name: t("entity.operation:operation.F3112_OPSQ"),
          },
          {
            key: "F3112_DSC1",
            name: t("entity.operation:operation.F3112_DSC1"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext.service
            .getAdapterService()
            .searchEntityData(
              "operation",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [
                  {
                    attribute_name: "MCU",
                    attribute_value: { operator: "$eq", value: site.site_id },
                  },
                ],
              })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "operation",
      },
    };
  };
}
