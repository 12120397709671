import { ILookupDefinition } from "../lookup-dialog";
import {
  IAdapterUserContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { useRiverSelectColumn } from "../../../river-data-grid";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";

export function useCraftsLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (props: {
    userContext: IAdapterUserContext;
    adapterContext: IAdapterUiContextState;
    customFilters?: QueryAttributeDto[];
    customQuery?: QueryAttributeGroupDto;
  }): ILookupDefinition => {
    const { userContext, adapterContext, customFilters, customQuery } = props;
    return adapterContext?.service.getCraftsUiService().useCraftsLookup({
      userContext,
      adapterContext,
      t,
      RiverSelectColumn,
      customFilters,
      customQuery,
    })();
  };
}

export type IUseCraftsLookupDef = ReturnType<typeof useCraftsLookupDef>;
