import React, { ReactElement, useContext } from "react";
import styles from "./gantt-baseline.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IScheduleContext,
  ScheduleContext,
} from "../../../../../context";
import { IAdapterBaseline } from "@river/interfaces";
import { TaskHelpersUiService } from "../../../../../services";
import { IUseScheduleGantt } from "../../use-schedule-gantt";

interface IGanttBaselineProps {
  row: any;
}

export const GanttBaseline: React.FC<IGanttBaselineProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;

  const { getBaselineEndDate, getBaselineStartDate } =
    taskHelpers.getHelpers()();

  let bs: Date;
  let be: Date;
  let startXPercent: number;
  let endXPercent: number;
  let widthPercent: number;
  let color: string;
  let baseline: IAdapterBaseline;
  const { row } = props;

  const scheduleContext: IScheduleContext = useContext(ScheduleContext)!;
  baseline = scheduleContext.selectedBaseline!;
  const gantt: IUseScheduleGantt = scheduleContext.ganttRef.current!;
  const { MIN_SCHEDULE_TASK_WIDTH: MIN_SCHEDULE_TASK_WIDTH_PERCENT } =
    gantt.ganttRatios!;

  if (baseline) {
    bs = new Date(getBaselineStartDate(row, baseline));
    be = new Date(getBaselineEndDate(row, baseline));
    startXPercent = gantt.getXPercentFromDate(bs);
    endXPercent = gantt.getXPercentFromDate(be);
    widthPercent = Math.max(
      endXPercent - startXPercent,
      MIN_SCHEDULE_TASK_WIDTH_PERCENT
    );
    color = baseline.display_options!;
  }

  return (
    <>
      {baseline && (
        <div
          className={styles.root}
          style={{
            left: `${startXPercent!}%`,
            width: `${widthPercent!}%`,
            backgroundColor: color!,
          }}
        />
      )}
    </>
  );
};
