import { useTranslation } from "@river/common-ui";
import { ReactElement, useState } from "react";
import { useGridActionHelpers } from "../../../../helpers";
import { EditAction } from "./edit-action";
import { IWoMassUpdateDialogProps } from "../../../../services";

interface IUseWoMassUpdateActionProps {
  renderWoMassUpdateDialog: any;
  disabled?: boolean;
  workorderIds?: string[];
}

export const useWoMassUpdateAction = (props: IUseWoMassUpdateActionProps) => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("shared.wo_mass_update_dialog:title");

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (): ReactElement => {
    return (
      <EditAction title={title} onClick={onClick} disabled={props.disabled} />
    );
  };

  const renderDialog = (): ReactElement => (
    <>
      {props.renderWoMassUpdateDialog({
        open: dialogOpened,
        workorderIds: props.workorderIds,
        onClose: () => {
          setDialogOpened(false);
        },
      } as IWoMassUpdateDialogProps)}
    </>
  );

  const renderWoMassUpdateAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderWoMassUpdateAction,
    woMassUpdateAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseWoMassUpdateAction = ReturnType<typeof useWoMassUpdateAction>;
