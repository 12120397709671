import React, { ReactElement } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./river-progress-bar.module.scss";

export interface IRiverProgressBarProps {
  title?: string;
  percentage?: number;
  processed?: number;
  total?: number;
  showValue?: boolean;
}
export const RiverProgressBar: React.FC<IRiverProgressBarProps> = (
  props: IRiverProgressBarProps
): ReactElement => {
  const { title, percentage, processed, total } = props;
  const value: number = getValue();

  function getValue(): number {
    let value: number = 0;
    if (typeof percentage !== "undefined") {
      value = percentage;
    } else {
      try {
        value = (processed! / total!) * 100;
      } catch (e) {
        console.error(e);
      }
    }
    return Math.round(value);
  }

  const renderTitle = (): ReactElement => (
    <div className={styles.title}>{title}</div>
  );

  const renderValue = (): ReactElement => (
    <div className={styles.percentage}>
      <div className={styles.value}>{value}</div>
      <div className={styles.suffix}>%</div>
    </div>
  );

  return (
    <div className={styles.root}>
      {props.title && renderTitle()}
      <div className={styles.body}>
        <LinearProgress
          className={styles.bar}
          variant={"determinate"}
          color={"primary"}
          value={value}
        />
        {props.showValue && renderValue()}
      </div>
    </div>
  );
};
