import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IExecutionContext,
  IUserContextSite,
} from "../../context";
import { useContext, useEffect } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  EXECUTION_INSTANCES_TABLE_SAVE_KEY,
  ExecutionUiService,
} from "./execution-ui.service";
import { useTranslation } from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import { IExecutionAction } from "../../components/execution/execution";

interface IUseExecutionInstancesProps {
  executionContext: IExecutionContext;
}

export const useExecutionInstances = (
  props: IUseExecutionInstancesProps
): IUseEntityTable => {
  const { currentWorkOrder, setCurrentActions, executionActions } =
    props.executionContext;
  const { setCurrentOperation, setTimecardDialogOpened } = executionActions;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service.getExecutionUiService()!;
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const instancesActions: IExecutionAction[] = [
    {
      label: t("common.label:timecard"),
      onClick: (operation) => {
        setCurrentOperation(operation);
        setTimecardDialogOpened(true);
      },
    },
  ];

  useEffect(() => {
    if (currentTab === ExecutionTabId.INSTANCES) {
      setCurrentActions(instancesActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const entityName: string = "operation";

  const fetchInstances = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrder) return [];
    return await adapterContext!.service
      .getAdapterService()
      .searchEntityData("operation", {
        ...fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: uiService.getInitialInstancesQueryAttributes({
            currentWorkOrder,
          }),
        }),
        $unwind: [
          "WorkOrderOperationResource",
          "WorkOrderOperationResource.WorkOrderOperationResourceInstance",
        ],
      });
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: EXECUTION_INSTANCES_TABLE_SAVE_KEY,
    columns: uiService.getDefaultInstancesColumns({ instancesActions })(),
    fetchFunction: fetchInstances,
    dependencies: [!!site, currentTab === ExecutionTabId.INSTANCES],
    fetchOn: currentTab === ExecutionTabId.INSTANCES,
    fetchTriggers: [site, currentWorkOrder],
    clearBeforeFetch: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
