import { AdapterUiService } from "./adapter-ui.service";
import { IEntityObject } from "@river/interfaces";
import { IUseTable } from "../components/shared";
import { ObjectType } from "./task-helpers-ui.service";
import { uiConstants, useEntityHelpers } from "../helpers";

export abstract class TableUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  /**
   * Returns the field value used for uiConstants.fields.rowId field
   *
   * @example
   *    const getObjectId = tableUiService.getObjectId()()
   *    // For rows returned by fetcher function, which have uiConstants.fields.rowType field set
   *    const rowId: string = getObjectId(row)
   *    // For rows returned by API methods, which DON'T have uiConstants.fields.rowType field set
   *    const rowId: string = getObjectId(row, rowType)
   */
  getObjectId = (): (() => (
    object: IEntityObject,
    rowType?: ObjectType
  ) => string) => {
    return () => {
      const { getAttributeValue } = useEntityHelpers();

      return (object: IEntityObject, rowType?: ObjectType): string => {
        rowType = rowType || (object[uiConstants.fields.rowType] as ObjectType);
        let rowIdAttribute: string = this.getRowIdAttribute(rowType);
        return getAttributeValue(object, rowIdAttribute);
      };
    };
  };

  // Returns name of the attribute applied to uiConstants.fields.rowId field by fetcher function
  abstract getRowIdAttribute(rowType: ObjectType): string;

  isRowSelected = (): ((table: IUseTable, row: IEntityObject) => boolean) => {
    const getSelectedRowsObjectIds: (table: IUseTable) => string[] =
      this.getSelectedRowsObjectIds();
    const getObjectId: (row: IEntityObject) => string = this.getObjectId()();

    return (table: IUseTable, row: IEntityObject): boolean => {
      const selectedRowsObjectIds: string[] = getSelectedRowsObjectIds(table);
      const objectId: string = getObjectId(row);
      return selectedRowsObjectIds.includes(objectId);
    };
  };

  getSelectedRowsObjectIds = (): ((table: IUseTable) => string[]) => {
    const getObjectId: (row: IEntityObject) => string = this.getObjectId()();

    return (table: IUseTable): string[] => {
      const objectIds: string[] = [];
      const selectedRows: IEntityObject[] = table.getSelectedRows();
      selectedRows.forEach((row) => {
        objectIds.push(getObjectId(row));
      });
      return objectIds;
    };
  };

  getRowsFromObjectIds = (): ((
    table: IUseTable,
    objectIds: string[]
  ) => IEntityObject[]) => {
    const getObjectId: (row: IEntityObject) => string = this.getObjectId()();

    return (table: IUseTable, objectIds: string[]): IEntityObject[] =>
      table.entities.filter((row) => {
        const objectId: string = getObjectId(row);
        return objectIds.includes(objectId);
      });
  };
}
