import { ExecutionTabId, RiverFormInstanceProvider } from "../../services";
import {
  OracleEbsAdapterTimeCardDto,
  IAdapterTimeCard,
  IEntityObject,
} from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import { ReactElement, useCallback, useContext } from "react";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsNotEmpty } from "class-validator";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IsPersonKeyExists } from "../../components/execution";
import { RiverFormInstance, useRiverForm } from "../../hooks";
import { LookupType, RiverLookup } from "../../components/shared";
import { debounce } from "ts-debounce";
import {
  IRenderTimeCardFieldsProps,
  IRenderTimecardDialogProps,
  ITimeCardFormProps,
  TimeCardUiService,
} from "../timecard-ui.service";
import { RiverDateInput, RiverTextInput } from "@river/common-ui";
import {
  ExecutionOperationsTimeCardDialog,
  ExecutionInstanceTimeCardDialog,
} from "../../components/execution";
import operationTimecardDialogStyles from "../../components/execution/execution-operations-timecard-dialog/execution-operations-timecard-dialog.module.scss";
import instanceTimecardDialogStyles from "../../components/execution/execution-instance-timecard-dialog/execution-instance-timecard-dialog.module.scss";

interface IStandaloneValidator {
  laborInstanceName: string;
}

export class OracleEbsTimecardUiService extends TimeCardUiService {
  getInstanceTimeCardForm = (
    props: ITimeCardFormProps
  ): RiverFormInstanceProvider => {
    return (): RiverFormInstance => {
      const { t } = useTranslation();
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      class StandaloneValidator {
        constructor(obj: StandaloneValidator) {
          Object.assign(this, obj);
        }

        @IsNotEmpty()
        @IsPersonKeyExists({ context: { adapterContext } })
        // @ts-ignore
        laborInstanceName: string;
      }

      const INSTANCE_ID =
        props.initialValues?.INSTANCE_ID ??
        props.operation?.WorkOrderOperationResource /*@ts-ignore*/
          ?.WorkOrderOperationResourceInstance?.INSTANCE_ID ??
        "";
      const INSTANCE_NAME =
        props.initialValues?.INSTANCE_NAME ??
        props.operation?.WorkOrderOperationResource /*@ts-ignore*/
          ?.WorkOrderOperationResourceInstance?.INSTANCE_NAME ??
        "";

      return useRiverForm<
        OracleEbsAdapterTimeCardDto,
        IAdapterTimeCard,
        Object
      >({
        initialDto: Object.assign(new OracleEbsAdapterTimeCardDto(), {
          folder_id: props.folderId,
          workorder_id: props.workorder?._id,
          operation_id: props.operation?._id,
          operation_resource_id: (
            props.operation?.WorkOrderOperationResource as IEntityObject
          )?._id,
          INSTANCE_ID,
          INSTANCE_NAME,
          timecard_date: props.initialValues?.timecard_date || new Date(),
          timecard_hours: props.initialValues?.timecard_hours || 1,
        }),
        standalone: {
          fields: new StandaloneValidator({
            laborInstanceName: INSTANCE_NAME,
          }),
          fieldDefs: [
            {
              fieldName: "laborInstanceName",
              dataType: "string",
            },
          ],
          getValidatorInstance: (obj: IStandaloneValidator) =>
            new StandaloneValidator(obj),
        },

        entityName: "timecard",
        instanceToEdit: props.timecard,
        onCreate: props.onCreate,
        onUpdate: props.onUpdate,
        create: async (
          dto: OracleEbsAdapterTimeCardDto
        ): Promise<IAdapterTimeCard> => {
          return await adapterContext!.service
            .getAdapterService()
            .createTimeCard(dto);
        },
        update: async (dto: OracleEbsAdapterTimeCardDto): Promise<void> => {
          await adapterContext!.service
            .getAdapterService()
            .updateTimeCard(props.timecard!._id!, dto);
        },
        labels: {
          laborInstanceName: t("entity.timecard:timecard.INSTANCE_NAME"),
          timecard_date: t("entity.timecard:timecard.timecard_date"),
          timecard_hours: t("entity.timecard:timecard.timecard_hours"),
        },
      });
    };
  };

  renderInstanceTimeCardFormFields = (props: IRenderTimeCardFieldsProps) => {
    const styles = instanceTimecardDialogStyles;
    return () => {
      const renderTimeCardDateField = (): ReactElement => (
        <RiverDateInput id="timecard_date" className={styles.dateField} />
      );

      const renderTimeCardTimeField = (): ReactElement => (
        <RiverTextInput
          id="timecard_hours"
          className={styles.hoursField}
          inputProps={{
            type: "number",
            inputProps: {
              min: 1,
            },
          }}
        />
      );

      const renderTimeCardTimeFields = (): ReactElement => (
        <div className={styles.timeFields}>
          {renderTimeCardDateField()}
          {renderTimeCardTimeField()}
        </div>
      );

      const renderLaborInstanceNameField = (): ReactElement => {
        return (
          <RiverTextInput
            disabled={true}
            id="laborInstanceName"
            fullWidth
            className={styles.field}
          />
        );
      };

      return (
        <>
          {renderLaborInstanceNameField()}
          {renderTimeCardTimeFields()}
        </>
      );
    };
  };

  getOperationsTimeCardForm = (
    props: ITimeCardFormProps
  ): RiverFormInstanceProvider => {
    return (): RiverFormInstance => {
      const { t } = useTranslation();
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      class StandaloneValidator {
        constructor(obj: StandaloneValidator) {
          Object.assign(this, obj);
        }
        @IsPersonKeyExists({ context: { adapterContext, isOptional: true } })
        laborInstanceName?: string;
      }

      const INSTANCE_ID =
        props.initialValues?.INSTANCE_ID ??
        props.operation?.WorkOrderOperationResource /*@ts-ignore*/
          ?.WorkOrderOperationResourceInstance?._id ??
        "";
      const INSTANCE_NAME =
        props.initialValues?.INSTANCE_NAME ??
        props.operation?.WorkOrderOperationResource /*@ts-ignore*/
          ?.WorkOrderOperationResourceInstance?.LaborInstanceName ??
        "";

      return useRiverForm<
        OracleEbsAdapterTimeCardDto,
        IAdapterTimeCard,
        Object
      >({
        initialDto: Object.assign(new OracleEbsAdapterTimeCardDto(), {
          folder_id: props.folderId,
          workorder_id: props.workorder?._id,
          operation_id: props.operation?._id,
          operation_resource_id: (
            props.operation?.WorkOrderOperationResource as IEntityObject
          )?._id,
          INSTANCE_ID,
          INSTANCE_NAME,
          timecard_date: props.initialValues?.timecard_date || new Date(),
          timecard_hours: props.initialValues?.timecard_hours || 1,
        }),
        standalone: {
          fields: new StandaloneValidator({
            laborInstanceName: INSTANCE_NAME,
          }),
          fieldDefs: [
            {
              fieldName: "laborInstanceName",
              dataType: "string",
            },
          ],
          getValidatorInstance: (obj: IStandaloneValidator) =>
            new StandaloneValidator(obj),
        },

        entityName: "timecard",
        instanceToEdit: props.timecard,
        onCreate: props.onCreate,
        onUpdate: props.onUpdate,
        create: async (
          dto: OracleEbsAdapterTimeCardDto
        ): Promise<IAdapterTimeCard> => {
          return await adapterContext!.service
            .getAdapterService()
            .createTimeCard(dto);
        },
        update: async (dto: OracleEbsAdapterTimeCardDto): Promise<void> => {
          await adapterContext!.service
            .getAdapterService()
            .updateTimeCard(props.timecard!._id!, dto);
        },
        labels: {
          laborInstanceName: t("entity.timecard:timecard.INSTANCE_NAME"),
          timecard_date: t("entity.timecard:timecard.timecard_date"),
          timecard_hours: t("entity.timecard:timecard.timecard_hours"),
        },
      });
    };
  };

  renderOperationsTimeCardFormFields = (props: IRenderTimeCardFieldsProps) => {
    const styles = operationTimecardDialogStyles;
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);

      const { form } = props;
      const {
        onStandalonePropertyChange,
        setStandaloneFields,
        getStandaloneValidatorInstance,
        validateStandaloneField,
        setDto,
      } = form;

      const timecardDto: OracleEbsAdapterTimeCardDto =
        form.dto as OracleEbsAdapterTimeCardDto;

      const fetchPersonByAttribute = async (
        attribute_name: string,
        attribute_value: string
      ): Promise<IEntityObject> => {
        const resources: IEntityObject[] = await adapterContext!.service
          .getAdapterService()
          .fetchCraftPeople({
            query: {
              $and: [
                {
                  attribute_name: "Resources",
                  attribute_value: {
                    operator: "$elemMatch",
                    value: {
                      DEPARTMENT_ID: (
                        props.operation
                          ?.WorkOrderOperationResource as IEntityObject
                      )?.DEPARTMENT_ID,
                      RESOURCE_ID: (
                        props.operation
                          ?.WorkOrderOperationResource as IEntityObject
                      )?.RESOURCE_ID,
                    },
                  },
                },
                {
                  attribute_name,
                  attribute_value: {
                    operator: "$in",
                    value: [attribute_value],
                  },
                },
              ],
            },
          });
        return resources[0] as IEntityObject;
      };

      const fetchPersonByNumber = async (
        laborInstanceName: string
      ): Promise<void> => {
        const resource: IEntityObject = await fetchPersonByAttribute(
          "INSTANCE_NAME",
          laborInstanceName
        );

        const instanceName: string = (resource?.INSTANCE_NAME as string) || "";

        const instanceId: number | undefined =
          (resource?.INSTANCE_ID as number) || undefined;

        setDto(
          Object.assign(new OracleEbsAdapterTimeCardDto(), {
            ...timecardDto,
            INSTANCE_ID: instanceId,
            INSTANCE_NAME: instanceName,
          })
        );
        validateStandaloneField("laborInstanceName", laborInstanceName);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const debounceFetchPersonByNumber = useCallback(
        debounce((text) => {
          fetchPersonByNumber(text);
        }, 600),
        [timecardDto]
      );

      const renderLaborInstanceNameLookup = (): ReactElement => (
        <RiverLookup
          id="laborInstanceName"
          lookup={{
            type: LookupType.PERSONS,
            customFilters: [
              {
                attribute_name: "Resources",
                attribute_value: {
                  operator: "$elemMatch",
                  value: {
                    DEPARTMENT_ID: (
                      props.operation
                        ?.WorkOrderOperationResource as IEntityObject
                    )?.DEPARTMENT_ID,
                    RESOURCE_ID: (
                      props.operation
                        ?.WorkOrderOperationResource as IEntityObject
                    )?.RESOURCE_ID,
                  },
                },
              },
            ],
          }}
          fullWidth
          onChangeEvent={(event) => {
            onStandalonePropertyChange({ noValidate: true })(event);
            debounceFetchPersonByNumber(event.target.value);
          }}
          onSelect={(selectedObject: IEntityObject) => {
            const resource: IEntityObject = selectedObject as IEntityObject;
            const { INSTANCE_ID, INSTANCE_NAME } = resource;
            setDto(
              Object.assign(new OracleEbsAdapterTimeCardDto(), {
                ...timecardDto,
                INSTANCE_ID,
                INSTANCE_NAME,
              })
            );
            setStandaloneFields(
              getStandaloneValidatorInstance!({
                laborInstanceName: INSTANCE_NAME,
              })
            );
            validateStandaloneField("laborInstanceName", INSTANCE_NAME);
          }}
          className={styles.field}
        />
      );

      const renderTimeCardDateField = (): ReactElement => (
        <RiverDateInput id="timecard_date" className={styles.dateField} />
      );

      const renderTimeCardTimeField = (): ReactElement => (
        <RiverTextInput
          id="timecard_hours"
          className={styles.hoursField}
          inputProps={{
            type: "number",
            inputProps: {
              min: 1,
            },
          }}
        />
      );

      const renderTimeCardTimeFields = (): ReactElement => (
        <div className={styles.timeFields}>
          {renderTimeCardDateField()}
          {renderTimeCardTimeField()}
        </div>
      );

      return (
        <>
          {renderLaborInstanceNameLookup()}
          {renderTimeCardTimeFields()}
        </>
      );
    };
  };

  renderTimecardDialog(
    props: IRenderTimecardDialogProps,
    activeTab: ExecutionTabId
  ) {
    return () => {
      switch (activeTab) {
        default:
        case ExecutionTabId.OPERATIONS:
          return <ExecutionOperationsTimeCardDialog {...props} />;
        case ExecutionTabId.INSTANCES:
          return <ExecutionInstanceTimeCardDialog {...props} />;
      }
    };
  }
}
