import { IAdapterRule, IEntityObject } from "@river/interfaces";
import jsonata from "jsonata";

export namespace ruleHelpers {
  export const evaluateRuleExpression = (
    rule: IAdapterRule,
    object: IEntityObject
  ): string | null | undefined => {
    let value: string | null | undefined;
    try {
      if (rule) {
        value = jsonata(rule.expression).evaluate(object);
      }
    } catch (e) {
      console.log(
        `Failed to parse "${rule.rule}" expression: "${rule.expression}"`
      );
    }
    return value;
  };
}
