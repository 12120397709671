export const useGridActionHelpers = () => {
  const ensureOnClickProp = (props: any): any =>
    Object.assign(
      { ...props },
      {
        onClick: props.onClick || (() => {}),
      }
    );

  const withSanitizedProps =
    (renderFn: React.FC<any>): React.FC<any> =>
    (props: any) => {
      const sanitizedProps: any = ensureOnClickProp(props);
      return renderFn(sanitizedProps);
    };

  return {
    withSanitizedProps,
  };
};

export type IUseGridActionHelpers = ReturnType<typeof useGridActionHelpers>;
