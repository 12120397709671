import React, { ReactElement, useState } from "react";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";
import { Timelines } from "./timelines";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <Timelines />}</>
);

export const TimelinesModule: React.FC = (): ReactElement => {
  const namespaces: string[] = [
    "shared.planning_plant_selector",
    "common.button",
    "common.label",
    "shared.grid_header",
    "entity.timeline",
    "shared.error_messages",
    "module.timelines",
    "shared.lookup",
    "entity.calendar",
    "module.calendars",
    "shared.river_data_grid",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
