import { TFunction, useTranslation } from "react-i18next";
import { AdapterUiService } from "./adapter-ui.service";
import {
  ColumnsProvider,
  EntityTableProvider,
  IUseEntityTableParams,
  RiverFormInstanceProvider,
} from "./ui-service.types";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { AdapterUserDto, IAdapterUser, IEntityObject } from "@river/interfaces";
import { useEntity } from "../hooks";
import { fetchHelpers } from "../helpers";

export interface IUseUserRolesFormProps {
  userRole: AdapterUserDto | null;
  onCreate?: (userRoles: AdapterUserDto) => void;
  onUpdate?: () => void;
}

export abstract class UserRolesUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useUserRoles = (): EntityTableProvider => {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const { entityName, columns } = this.getUserRolesEntityTableParams(t);

      const fetchUserRoles = async (fetchProps: ITableFetchFunctionProps) =>
        (await this.adapterUiService
          .getAdapterService()
          .fetchUsers(
            fetchHelpers.getTableQuery({ fetchProps })
          )) as unknown as IEntityObject[];

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchUserRoles,
          fetchOn: true,
          fetchTriggers: [],
          rowKeyGetter: (row) => String(row["user_name"]),
          useAdvancedFilters: false,
          infiniteScrolling: true,
        }),
      };
    };
  };

  protected getUserRolesEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "user_role",
      columns: this.getUserRolesTableColumns()(),
    };
  };

  createUserRole = async (user: IEntityObject): Promise<IAdapterUser> => {
    const createUserDto: AdapterUserDto = this.getCreateUserRoleDto(user);
    return await this.adapterUiService
      .getAdapterService()
      .createUser(createUserDto);
  };

  //-----------------------
  // ERP-specific
  //-----------------------
  protected abstract getCreateUserRoleDto(user: IEntityObject): AdapterUserDto;
  protected abstract getUserRolesTableColumns(): ColumnsProvider;
  abstract getUserRolesForm(
    props: IUseUserRolesFormProps
  ): RiverFormInstanceProvider;
}
