import React, { ReactElement, useContext } from "react";
import {
  RiverDialog,
  RiverSpinner,
  RiverDialogActionButton,
} from "@river/common-ui";
import { RiverDataGrid } from "../../../components/shared";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../context";
import { MaterialRequirementsDialogGridHeader } from "./material-requirements-dialog-grid-header";
import { uiConstants } from "../../../helpers";
import { IMaterialRequirementsDialogProps } from "../shared-service.types";
import styles from "./material-requirements-dialog.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiService,
  MaterialRequirementsUiService,
} from "../../../services";

export const MaterialRequirementsDialog: React.FC<
  IMaterialRequirementsDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const service: AdapterUiService = adapterContext?.service!;
  const uiService: MaterialRequirementsUiService =
    service.getMaterialRequirementsUiService()!;

  const tableContext = useContext(TableContext);
  const workOrderNumberColumn = uiService.getWorkOrderNumberColumn();

  const getSelectedWorkorderNumbers = (): string[] => {
    const orderIds = Array.from(tableContext?.table.selectedRowIds!);
    const workorderNumbers = tableContext?.table.entities
      .filter((e) => orderIds.includes(e[uiConstants.fields._id] as string))
      .map((e) => e[workOrderNumberColumn] as string);
    return workorderNumbers || [];
  };
  const { entity, table } = uiService.useMaterialRequirements({
    workOrderNumbers: getSelectedWorkorderNumbers(),
    fetchOn: props.open,
  })();

  const isLoading: boolean = table.isLoading;
  return (
    <TableContext.Provider
      value={{
        table,
        entity,
        entityName: entity.entityDefinition?.entity.entity_name,
      }}
    >
      <RiverDialog
        title={t("shared.material_req:dialog.material_req.title")}
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        actionsContent={
          <RiverDialogActionButton
            onClick={() => {
              props.onClose();
            }}
            text={t("common.button:close")}
          />
        }
        showActionsDivider={false}
        classes={{
          paper: styles.paper,
          content: styles.content,
        }}
        dialogProps={{
          maxWidth: false,
        }}
      >
        <RiverSpinner show={isLoading} />
        <MaterialRequirementsDialogGridHeader />
        <RiverDataGrid
          disableSelectAll={true}
          className={styles.grid}
          columns={table.columns}
          rows={table.entities}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onSelectedRowsChange={() => {}}
          onRowClick={() => {}}
          rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
          rowClass={(row) =>
            clsx({ [styles.missingParts]: row["MissingPart"] })
          }
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
      </RiverDialog>
    </TableContext.Provider>
  );
};
