import React, { ReactElement, useContext } from "react";
import { ScheduleGanttTimeOptions } from "./schedule-gantt-time-options";
import {
  ScheduleGanttToggle,
  ScheduleTasksViewId,
  ScheduleTasksViewURLParamName,
  DEFAULT_SCHEDULE_TASKS_VIEW,
} from "./schedule-gantt-toggle";
import { ScheduleGanttCalendarSelector } from "./schedule-gantt-calendar-selector";
import { Divider } from "@mui/material";
import { useLocation } from "react-router";
import { ScheduleContext } from "../../../../context";
import styles from "./schedule-gantt-header-options.module.scss";

export const ScheduleGanttHeaderOptions: React.FC = (): ReactElement => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const view =
    params.get(ScheduleTasksViewURLParamName) || DEFAULT_SCHEDULE_TASKS_VIEW;
  const scheduleContext = useContext(ScheduleContext);

  const renderCalendarOptions = (): ReactElement => (
    <>
      <ScheduleGanttTimeOptions />
      <ScheduleGanttCalendarSelector />
      <Divider orientation="vertical" className={styles.divider} />
    </>
  );

  const shouldRenderCalendarOptions = (): boolean => {
    let toRender: boolean = false;
    if (
      view === ScheduleTasksViewId.GANTT ||
      scheduleContext?.utilizationGanttRef.current
    ) {
      toRender = true;
    }
    return toRender;
  };

  return (
    <div className={styles.root}>
      {shouldRenderCalendarOptions() && renderCalendarOptions()}
      <ScheduleGanttToggle />
    </div>
  );
};
