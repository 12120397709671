import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { IAdapterUiContextState } from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";
import { entityDef } from "../../../../data-dict/entity-dialog/defs/entity-def";
import {
  IEntityObject,
  QueryAttributeDto,
  QueryAttributeSortDto,
  QueryDto,
} from "@river/interfaces";

export const useEntityLookupDef = () => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  return (adapterContext: IAdapterUiContextState): ILookupDefinition => {
    return {
      title: t("shared.lookup:entity.title"),
      rowKey: "entity_name",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "entity_name",
            name: t("module.data_dictionary:label.entity_name"),
          },
          {
            key: "description",
            name: t("module.data_dictionary:label.description"),
          },
        ],
        fetchFunction: async (props: ITableFetchFunctionProps) => {
          const entityQuery = (): QueryDto => {
            const attributes: QueryAttributeDto[] = [];
            const sort: QueryAttributeSortDto[] = [];
            for (const filter of props.columnFilters) {
              attributes.push({
                attribute_name: filter.field,
                attribute_value: {
                  operator: filter.operator,
                  value: filter.value,
                },
              });
            }
            for (const sortColumn of props.sortColumns) {
              sort.push({
                attribute_name: sortColumn.columnKey,
                sort: sortColumn.direction === "ASC" ? "asc" : "desc",
              });
            }
            attributes.push(
              {
                attribute_name: "is_schema",
                attribute_value: {
                  operator: "$eq",
                  value: true,
                },
              },
              {
                attribute_name: "collection_name",
                attribute_value: {
                  operator: "$eq",
                  value: null,
                },
              }
            );

            return {
              query: {
                $and: attributes,
              },
              sort,
            };
          };
          return (await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).getEntityDefinitions(entityQuery())) as unknown as Promise<
            IEntityObject[]
          >;
        },
        dependencies: [],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "entity",
        definition: entityDef,
      },
    };
  };
};

export type IUsEntitiesLookupDef = ReturnType<typeof useEntityLookupDef>;
