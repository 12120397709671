import { EntityTableProvider, IUseEntityTableParams } from "./";
import { AdapterUiService } from "./adapter-ui.service";
import { TFunction, useTranslation } from "react-i18next";
import {
  ITableFetchFunctionProps,
  IUseTableProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { IEntityObject, QueryDto } from "@river/interfaces";
import { fetchHelpers } from "../helpers";
import { useEntity } from "../hooks";

export abstract class UsersUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useUsers = (props: Partial<IUseTableProps>): EntityTableProvider => {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const { entityName, columns } = this.getUserEntityTableParams(t);

      const fetchUsers = async (fetchProps: ITableFetchFunctionProps) =>
        (await this.adapterUiService
          .getAdapterService()
          .searchEntityData(
            "user",
            fetchHelpers.getTableQuery({ fetchProps })
          )) as unknown as IEntityObject[];

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchUsers,
          fetchOn: true,
          fetchTriggers: [],
          rowKeyGetter: this.getUserRowKey,
          ...props,
          useAdvancedFilters: false,
        }),
      };
    };
  };

  deleteUser = async (userId: any): Promise<any> => {
    const adapterUsers = await this.adapterUiService
      .getAdapterService()
      .fetchUsers(this.userQuery(userId));

    if (adapterUsers.length) {
      const userId = adapterUsers[0]._id;
      await this.adapterUiService.getAdapterService().deleteUser(userId);
    }
  };

  protected userQuery = (queryValue: any): QueryDto => {
    return {
      query: {
        $and: [
          {
            attribute_name: "user_name",
            attribute_value: {
              operator: "$eq",
              value: this.getUserRowKey(queryValue),
            },
          },
        ],
      },
    };
  };

  protected getUserRowKey = (row: IEntityObject): string =>
    row[this.getUserNameKey()] as string;

  getI18nNamespaces(): string[] {
    return [
      "module.users",
      "shared.grid_header",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.error_messages",
      "shared.user_menu",
      "common.button",
      "common.label",
      "entity.user",
      "entity.role",
      "entity.user_role",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  protected abstract getUserEntityTableParams(
    t: TFunction
  ): IUseEntityTableParams;
  abstract getUserNameKey(): string;
  abstract getErpSpecificI18nNamespaces(): string[];
}
