import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { supervisorUtilizationHelpers } from "../../components/supervisor-schedule/supervisor-schedule-utilization/supervisor-utilization-helpers";
import * as ganttUtils from "../../components/supervisor-schedule/supervisor-schedule-gantt/supervisor-schedule-gantt-utils";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "./supervisor-schedule-utilization-ui-service";
import { IAdapterFolder, UtilizationPeriodType } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { fetchHelpers } from "../../helpers";

interface IUseSupervisorScheduleUtilizationCrewsProps {
  selectedCrewIds: Set<string>;
}

export const useSupervisorScheduleUtilizationCrews = (
  props: IUseSupervisorScheduleUtilizationCrewsProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const utilizationPeriodType: UtilizationPeriodType =
    supervisorUtilizationHelpers.getUtilizationPeriodType(scheduleContext!);
  const utilizationPeriods: number =
    supervisorUtilizationHelpers.getUtilizationPeriods(scheduleContext!);

  const fetchCrewUtilization = async (fetchProps: ITableFetchFunctionProps) => {
    return await adapterContext!.service
      .getAdapterService()
      .fetchCrewUtilization(
        currentSchedule._id,
        null, // baseline id
        ganttUtils.getGanttStart(
          scheduleContext?.selectedGanttDate!,
          scheduleContext?.ganttTimeOption?.groupId!,
          new Date(scheduleContext?.currentSchedule?.start_date!)
        ),
        utilizationPeriodType,
        utilizationPeriods,
        fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: [
            {
              attribute_name: "crew",
              attribute_value: {
                operator: "$in",
                value: Array.from(props.selectedCrewIds),
              },
            },
          ],
        })
      );
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "crew_utilization",
      columns: [
        {
          key: "crew",
          name: t("entity.crew_utilization:crew_utilization.crew"),
          width: 100,
        },
        {
          key: "description",
          name: t("entity.crew_utilization:crew_utilization.description"),
        },
      ],
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    entityName,
    saveKey: "supervisor.schedule.utilization.crews",
    columns,
    fetchFunction: fetchCrewUtilization,
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.CREWS,
    dependencies: [
      !!site,
      !!utilizationPeriodType,
      !!currentSchedule,
      currentTab === SupervisorScheduleUtilizationTabId.CREWS,
    ],
    clearBeforeFetch: true,
    fetchTriggers: [
      site,
      utilizationPeriodType,
      utilizationPeriods,
      currentSchedule,
      props.selectedCrewIds,
      scheduleContext?.selectedGanttDate,
    ],
    rowKeyGetter: (row) => String(row["crew"]),
    useAdvancedFilters: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
