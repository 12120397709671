import React, { ReactElement, useContext } from "react";
import {
  RiverDialog,
  RiverFormSelect,
  RiverSpinner,
  RiverTextInput,
  RiverDialogButton,
  RiverDialogActionButton,
  useSimpleDialog,
} from "@river/common-ui";
import { LookupType, RiverLookup } from "../../shared";
import { RiverFormInstance } from "../../../hooks";
import { RelationType } from "../supervisor-schedule-gantt/supervisor-schedule-gantt-overlay/supervisor-schedule-operations-gantt-overlay";
import { IEntityObject, AdapterDependencyActionDto } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useSupervisorOperationRelationForm } from "./use-supervisor-operation-relation-form";
import { useTranslation } from "@river/common-ui";
import { TaskHelpersUiService } from "../../../services";
import { SupervisorScheduleAction } from "../../../services/supervisor-schedule-ui-service";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import styles from "./supervisor-dependency-dialog.module.scss";
import clsx from "clsx";

export interface ISupervisorOperationDependency {
  predecessor: IEntityObject;
  successor: IEntityObject;
  relation: IEntityObject;
}

interface ISupervisorDependencyDialogProps {
  open: boolean;
  onClose: () => void;
  dependency?: ISupervisorOperationDependency;
}

export const SupervisorDependencyDialog: React.FC<
  ISupervisorDependencyDialogProps
> = (props) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();

  const form: RiverFormInstance = useSupervisorOperationRelationForm({
    dependency: props.dependency,
    onCreate: () => {
      refreshAndClose();
    },
    onUpdate: () => {
      refreshAndClose();
    },
    onDelete: () => {
      refreshAndClose();
    },
  });
  const {
    resetForm,
    validationErrors,
    isProcessing,
    onPropertyChange,
    onStandalonePropertyChange,
    setDto,
    setStandaloneFields,
    submit,
  } = form;
  const formDto: AdapterDependencyActionDto =
    form.dto as AdapterDependencyActionDto;
  const standalone: any = form.standalone;

  const { OrderNumberProp, OperationActivityProp } =
    getOperationsOverlayPropertyNames();

  const closeDialog = () => {
    resetForm();
    props.onClose();
  };

  const refreshAndClose = () => {
    closeDialog();
  };

  const deleteDependencyConfirmationDialog = useSimpleDialog({
    title: t("shared.dependency_dialog:delete_dependency.title"),
    message: t("shared.dependency_dialog:delete_dependency.message"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: form.delete,
  });

  const renderPredecessorFields = (): ReactElement => {
    return (
      <div className={clsx([styles.operationRow, styles.predecessor])}>
        <div className={styles.title}>Predecessor Operation:</div>
        <div className={styles.fields}>
          <RiverTextInput
            id={"OrderPredecessor"}
            label={t("shared.dependency_dialog:label.predecessor_order_id")}
            value={standalone.OrderPredecessor}
            onChangeEvent={onStandalonePropertyChange()}
            className={styles.field}
            disabled={true}
          />
          <RiverLookup
            id={"OperationPredecessor"}
            label={t("shared.dependency_dialog:label.predecessor_activity")}
            value={standalone.OperationPredecessor}
            onChangeEvent={onStandalonePropertyChange()}
            lookup={{ type: LookupType.OPERATIONS }}
            className={styles.field}
            inputProps={{
              disabled: true,
            }}
            onSelect={(operation: IEntityObject) => {
              const {
                [OperationActivityProp]: OperationPredecessor,
                [OrderNumberProp]: OrderPredecessor,
                _id: predecessor_id,
              } = operation;
              setDto(
                Object.assign(new AdapterDependencyActionDto(), {
                  ...formDto,
                  predecessor_id,
                })
              );
              setStandaloneFields({
                ...standalone,
                OrderPredecessor,
                OperationPredecessor,
              });
            }}
            disabled={form.isReadOnly}
          />
        </div>
      </div>
    );
  };

  const renderSuccessorFields = (): ReactElement => {
    return (
      <div className={clsx([styles.operationRow, styles.successor])}>
        <div className={styles.title}>Successor Operation:</div>
        <div className={styles.fields}>
          <RiverTextInput
            id={"OrderSuccessor"}
            label={t("shared.dependency_dialog:label.successor_order_id")}
            value={standalone.OrderSuccessor}
            onChangeEvent={onStandalonePropertyChange()}
            className={styles.field}
            disabled={true}
          />
          <RiverLookup
            id={"OperationSuccessor"}
            label={t("shared.dependency_dialog:label.successor_activity")}
            value={standalone.OperationSuccessor}
            onChangeEvent={onStandalonePropertyChange()}
            lookup={{ type: LookupType.OPERATIONS }}
            inputProps={{
              disabled: true,
            }}
            onSelect={(operation: IEntityObject) => {
              const {
                [OperationActivityProp]: OperationSuccessor,
                [OrderNumberProp]: OrderSuccessor,
                _id: successor_id,
              } = operation;
              setDto(
                Object.assign(new AdapterDependencyActionDto(), {
                  ...formDto,
                  successor_id,
                })
              );
              setStandaloneFields({
                ...standalone,
                OrderSuccessor,
                OperationSuccessor,
              });
            }}
            className={styles.field}
            disabled={form.isReadOnly}
          />
        </div>
      </div>
    );
  };

  const renderRelationType = (): ReactElement => {
    const display: { [key in RelationType]: string } = {
      [RelationType.START_TO_START]: t(
        "shared.dependency_dialog:label.start_to_start"
      ),
      [RelationType.START_TO_FINISH]: t(
        "shared.dependency_dialog:label.start_to_finish"
      ),
      [RelationType.FINISH_TO_START]: t(
        "shared.dependency_dialog:label.finish_to_start"
      ),
      [RelationType.FINISH_TO_FINISH]: t(
        "shared.dependency_dialog:label.finish_to_finish"
      ),
    };
    return (
      <RiverFormSelect
        id={"dependency_type"}
        label={t("shared.dependency_dialog:label.relation_type")}
        items={[
          ...Object.values(RelationType).map((value, index) => {
            return { value, text: display[value as RelationType] };
          }),
        ]}
        onChangeEvent={(event) => {
          onPropertyChange(event as React.ChangeEvent<HTMLInputElement>);
        }}
        validationErrors={validationErrors?.fields["dependency_type"]}
        value={formDto.dependency_type}
        className={styles.relationTypeField}
        disabled={form.isReadOnly}
      />
    );
  };

  const renderLagHours = (): ReactElement => (
    <RiverTextInput
      id={"lag_hours"}
      label={t("shared.dependency_dialog:label.lag_hours")}
      value={formDto.lag_hours}
      onChangeEvent={onPropertyChange}
      className={styles.lagField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 0,
        },
      }}
      disabled={form.isReadOnly}
    />
  );

  return (
    <RiverDialog
      title={t("shared.dependency_dialog:title")}
      open={props.open}
      onClose={closeDialog}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={closeDialog}
            text={t("common.button:cancel")}
          />
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.SCHEDULE}
          >
            {props.dependency && (
              <RiverDialogActionButton
                onClick={() => deleteDependencyConfirmationDialog.open()}
                text={t("common.button:delete")}
                color={"error"}
              />
            )}
            <RiverDialogActionButton
              onClick={submit}
              text={
                props.dependency
                  ? t("common.button:update")
                  : t("common.button:create")
              }
            />
          </ProtectedAction>
        </>
      }
    >
      <RiverSpinner show={isProcessing} />
      {renderPredecessorFields()}
      {renderSuccessorFields()}
      {renderRelationType()}
      {renderLagHours()}
      {deleteDependencyConfirmationDialog.render()}
    </RiverDialog>
  );
};
