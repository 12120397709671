import React, { ReactElement, useContext } from "react";
import { IUseTable, ScheduleKpis } from "../../../shared";
import { SupervisorScheduleContext, TableContext } from "../../../../context";
import {
  IAdapterFolder,
  IAdapterSchedulingComplianceSummary,
} from "@river/interfaces";
import { RiverSpinner } from "@river/common-ui";
import styles from "./supervisor-schedule-utilization-kpis.module.scss";

export const SupervisorScheduleUtilizationKpis: React.FC = (): ReactElement => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const data: IAdapterSchedulingComplianceSummary = table
    .entities[0]! as any as IAdapterSchedulingComplianceSummary;

  return (
    <>
      <ScheduleKpis
        schedule={currentSchedule}
        complianceData={data}
        classes={{
          root: styles.root,
          item: styles.item,
        }}
      />
      <RiverSpinner show={table?.isLoading!} />
    </>
  );
};
