import React, { ReactElement, useContext } from "react";
import { CrewsUiService } from "../../../services";
import { ITableFetchFunctionProps, RiverCustomSelector } from "../../shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { fetchHelpers } from "../../../helpers";
import { useTranslation } from "@river/common-ui";
import { IAdapterCrew, IEntityObject } from "@river/interfaces";

const DEFAULT_IS_DIALOG: boolean = false;

interface ICrewResourceSelectorProps {
  crew: IAdapterCrew;
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export const CrewResourceSelector: React.FC<ICrewResourceSelectorProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService =
    adapterContext?.service!.getCrewsUiService()!;
  const { availableTableColumns, assignedTableColumns } =
    uiService.getCrewResourceSelectorColumns()();

  const fetchAvailableCrewResources = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    const assignedResourceNumbers: string[] = [];
    const assignedResources: IEntityObject[] = await adapterContext!.service
      .getAdapterService()
      .fetchCrewAssignedPeople(props.crew.crew as string);
    assignedResources.forEach((person) =>
      assignedResourceNumbers.push(
        person[uiService.resourceNumberField] as string
      )
    );

    return await adapterContext!.service
      .getAdapterService()
      .fetchCrewAvailablePeople(props.crew.crew, {
        ...fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: [
            {
              attribute_name: "is_crew_resource",
              attribute_value: {
                operator: "$ne",
                value: true,
              },
            },
            {
              attribute_name: uiService.resourceNumberField,
              attribute_value: {
                operator: "$nin",
                value: assignedResourceNumbers,
              },
            },
          ],
        }),
      });
  };

  const fetchAssignedCrewResources = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    return await adapterContext!.service
      .getAdapterService()
      .fetchCrewAssignedPeople(
        props.crew.crew as string,
        fetchHelpers.getTableQuery({ fetchProps })
      );
  };

  const assignCrewResources = async (selectedIds: Set<string>) => {
    const promises: Promise<void>[] = [];
    selectedIds.forEach((id) =>
      promises.push(
        adapterContext!.service.getAdapterService().createCrewPerson({
          crew: props.crew.crew,
          availability_header_id: id,
        })
      )
    );
    return Promise.all(promises);
  };

  const unassignCrewResources = (selectedIds: Set<string>) => {
    const promises: Promise<void>[] = [];
    selectedIds.forEach((id) =>
      promises.push(
        adapterContext!.service.getAdapterService().deleteCrewPerson(id)
      )
    );
    return Promise.all(promises);
  };

  const isDialog: boolean = props.isDialog ?? DEFAULT_IS_DIALOG;

  return (
    <RiverCustomSelector
      isDialog={isDialog}
      open={props.open}
      onClose={props.onClose}
      dialogTitle={t("shared.crew_resource_selector:title")}
      availableEntityName={"availability_header"}
      assignedEntityName={"crew_person"}
      availableTableColumns={availableTableColumns}
      assignedTableColumns={assignedTableColumns}
      fetchAvailableObjects={fetchAvailableCrewResources}
      fetchAssignedObjects={fetchAssignedCrewResources}
      assignAction={assignCrewResources}
      unassignAction={unassignCrewResources}
    />
  );
};
