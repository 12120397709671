import {
  RiverSpinner,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import {
  TimelinesDialog,
  TimelinesGridHeader,
  TimelinesHeader,
  TimelinesSubHeader,
} from ".";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
  TableContext,
} from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useRiverSelectColumn,
  useTable,
} from "../shared";
import { useContext, useState } from "react";
import { Column } from "react-data-grid";
import { IAdapterTimeline } from "@river/interfaces";
import dataGridStyles from "../../components/shared/river-data-grid/river-data-grid.module.scss";

export enum TimelinesAction {
  CREATE = "CREATE_TIMELINE",
  UPDATE = "UPDATE_TIMELINE",
  DELETE = "DELETE_TIMELINE",
  GENERATE_SCHEDULES = "GENERATE_SCHEDULES",
}

export const Timelines = () => {
  usePageCleanup();

  const entityName: string = "timeline";
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const notify = useNotification();
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  const [timelineToEdit, setTimelineToEdit] = useState<
    IAdapterTimeline | undefined
  >();
  const entity: IUseEntity = useEntity({
    entityName,
  });

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "timeline",
      name: t("entity.timeline:timeline.timeline"),
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  setTimelineToEdit(formatterProps.row);
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "description", name: t("entity.timeline:timeline.description") },
    { key: "start_date", name: t("entity.timeline:timeline.start_date") },
    {
      key: "folder_duration_days",
      name: t("entity.timeline:timeline.folder_duration_days"),
    },
    {
      key: "folder_name_template",
      name: t("entity.timeline:timeline.folder_name_template"),
    },
    {
      key: "summary.last_folder",
      name: t("module.timelines:label.last_folder"),
    },
    {
      key: "summary.folders_generated",
      name: t("module.timelines:label.folders_generated"),
    },
  ];

  const fetchTimelines = async (fetchProps: ITableFetchFunctionProps) => {
    try {
      return (
        (await adapterContext?.service
          .getAdapterService()
          .fetchTimelines(fetchHelpers.getTableQuery({ fetchProps }))) || []
      );
    } catch (message) {
      notify.error({ message });
      return [];
    }
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: "timeline",
    columns,
    infiniteScrolling: true,
    fetchFunction: fetchTimelines,
    dependencies: [!!site],
    fetchOn: true,
    rowKeyGetter: (row) => row[uiConstants.fields._id] as string,
    useAdvancedFilters: false,
  });

  const openEditDialog = (timeline: IAdapterTimeline): void => {
    setTimelineToEdit(timeline);
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <TimelinesHeader />
      <TimelinesSubHeader openEditDialog={openEditDialog} />
      <TimelinesGridHeader />
      <RiverSpinner show={isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={(row) => row[uiConstants.fields._id]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
      <TimelinesDialog
        open={!!timelineToEdit}
        timeline={timelineToEdit}
        onClose={() => {
          setTimelineToEdit(undefined);
        }}
      />
    </TableContext.Provider>
  );
};
