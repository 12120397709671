import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface ISapOperationMassUpdate {
  WorkCntr?: string;
  WorkActivity?: string;
  DurationNormal?: string;
  NumberOfCapacities?: string;
  Systcond?: string;
  PersNo?: string;
}

export class SapOperationMassUpdate {
  constructor(obj: ISapOperationMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useSapOperationUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, SapOperationMassUpdate>({
    standalone: {
      fields: new SapOperationMassUpdate({
        WorkCntr: "",
        WorkActivity: "",
        DurationNormal: "",
        NumberOfCapacities: "",
        Systcond: "",
        PersNo: "",
      }),
      fieldDefs: [
        { fieldName: "WorkCntr", dataType: "string" },
        { fieldName: "WorkActivity", dataType: "string" },
        { fieldName: "DurationNormal", dataType: "string" },
        { fieldName: "NumberOfCapacities", dataType: "double" },
        { fieldName: "Systcond", dataType: "string" },
        { fieldName: "PersNo", dataType: "string" },
      ],
    },
    submit: props.submit,
    labels: {
      WorkCntr: t("entity.operation:operation.WorkCntr"),
      Systcond: t("entity.operation:operation.Systcond"),
      PersNo: t("entity.operation:operation.PersNo"),
      WorkActivity: t("entity.operation:operation.WorkActivity"),
      DurationNormal: t("entity.operation:operation.DurationNormal"),
      NumberOfCapacities: t("entity.operation:operation.NumberOfCapacities"),
    },
  });
};
