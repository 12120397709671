import React, { ReactElement } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./craft-stat.module.scss";
import clsx from "clsx";

export interface ICraftStatProps {
  label: string;
  value: number;
  outOf: number;
}

export const CraftStat: React.FC<ICraftStatProps> = (props): ReactElement => {
  const isCompleted: boolean = props.value / props.outOf === 1;

  const isOverloaded: boolean = props.value > props.outOf;

  let progressValue: number = Math.round((props.value / props.outOf) * 100);

  if (props.value > props.outOf) {
    progressValue = 100;
  }
  // check if both are zero
  if (props.value === 0 && props.outOf === 0) {
    progressValue = 0;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>{props.label}</div>
        <div className={styles.stat}>
          <div className={styles.value}>{props.value}</div>/
          <div className={styles.outOf}>{props.outOf}</div>h
        </div>
      </div>
      <div className={styles.body}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          classes={{
            root: clsx(
              [styles.progressBarRoot, { [styles.completed]: isCompleted }],
              { [styles.overload]: isOverloaded }
            ),

            bar: styles.progressBar,
            colorPrimary: styles.colorPrimary,
            barColorPrimary: styles.barColorPrimary,
          }}
        />
      </div>
    </div>
  );
};
