import { MouseEvent, FC, useState } from "react";
import { Dots3x3Icon } from "../../../icons";
import { Popover } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSidebarMenu } from "../../sidebar-menu/use-sidebar-menu";
import styles from "./module-menu.module.scss";
import clsx from "clsx";

export const ModuleMenu: FC = () => {
  const { t } = useTranslation();
  const menu = useSidebarMenu();
  const { menuItems } = menu;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);

  const onToggleClick = (event: MouseEvent<HTMLDivElement>) =>
    setMenuAnchorEl(event.currentTarget);

  const closeMenu = () => setMenuAnchorEl(null);

  const opened: boolean = !!menuAnchorEl;
  const menuId = opened ? "main-menu-popover" : undefined;

  return (
    <>
      <div
        className={clsx([styles.toggle, { [styles.opened]: opened }])}
        aria-describedby={menuId}
        onClick={onToggleClick}
      >
        <Dots3x3Icon className={styles.icon} />
      </div>
      <Popover
        id={menuId}
        open={opened}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={styles.menuItemsContainer}>
          <span className={styles.menuItemsContainerTitle}>
            {t("common.label:available_modules")}
          </span>
          <div className={styles.menuItemsContainerWrapper}>
            {menuItems
              .filter((item) => item?.enabled && item?.linkTo)
              .map(
                (item, index) =>
                  item.enabled && (
                    <Link
                      to={item.linkTo!}
                      className={styles.menuItem}
                      key={index}
                    >
                      <div className={styles.menuItemWrapper}>
                        <div className={styles.menuItemIconContainer}>
                          {item.renderIcon?.()}
                        </div>
                        <span className={styles.menuItemTitle}>
                          {item.title}
                        </span>
                      </div>
                    </Link>
                  )
              )}
          </div>
        </div>
      </Popover>
    </>
  );
};
