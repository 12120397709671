import { ScheduleGanttTimeOptionGroupID } from "../schedule-tasks/schedule-gantt-header-options";

export const getGanttStart = (
  selectedDate: Date,
  timeGroupId: ScheduleGanttTimeOptionGroupID,
  scheduleStartDate?: Date
): Date => {
  const ganttStartDate = new Date(selectedDate);

  switch (timeGroupId) {
    case ScheduleGanttTimeOptionGroupID.DAYS:
    case ScheduleGanttTimeOptionGroupID.WEEKS:
      const scheduleStartDateValue = scheduleStartDate?.getUTCDay();
      let minusDays;

      if (!!scheduleStartDateValue) {
        if (ganttStartDate.getUTCDay() >= scheduleStartDateValue) {
          minusDays = ganttStartDate.getUTCDay() - scheduleStartDateValue;
        } else {
          minusDays = 7 - (scheduleStartDateValue - ganttStartDate.getUTCDay());
        }
      } else {
        minusDays = ganttStartDate.getUTCDay();
      }

      ganttStartDate.setUTCDate(ganttStartDate.getUTCDate() - minusDays);

      return ganttStartDate;
    case ScheduleGanttTimeOptionGroupID.MONTHS:
      ganttStartDate.setUTCDate(1);
      return ganttStartDate;
  }
  return ganttStartDate;
};
