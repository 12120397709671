import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { ArrayNotEmpty, IsNotEmpty } from "class-validator";
import { useTranslation } from "@river/common-ui";
import { AdapterUserDto } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useContext } from "react";
import { AdapterUiService } from "../../adapter-ui.service";
import { AdapterService } from "../../adapter.service";
import { renderOracleEbsUserRolesForm } from "./oracle-ebs-user-roles-form-renderer";

export interface IUseOracleEbsUserRoleFormProps {
  userRole: OracleEbsUserRoleDto | null;
  onCreate?: (userRoles: OracleEbsUserRoleDto) => void;
  onUpdate?: () => void;
}

export class OracleEbsUserRoleDto extends AdapterUserDto {
  @IsNotEmpty()
  // @ts-ignore
  APPLICATION_ID: string;

  @IsNotEmpty()
  // @ts-ignore
  APPLICATION_NAME: string;

  @IsNotEmpty()
  // @ts-ignore
  RESPONSIBILITY_ID: string;

  @IsNotEmpty()
  // @ts-ignore
  RESPONSIBILITY_NAME: string;

  @ArrayNotEmpty({
    message: "Must add at least one role",
  })
  // @ts-ignore
  roles: string[];
}

export const useOracleEbsUserRoleForm = (
  props: IUseOracleEbsUserRoleFormProps
): RiverFormInstance => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterUiService: AdapterUiService = adapterContext?.service!;
  const adapterService: AdapterService = adapterUiService.getAdapterService();

  const getInitialDto = (): OracleEbsUserRoleDto =>
    Object.assign(new OracleEbsUserRoleDto(), {
      user_name: "",
      email: "",
      first_name: "",
      last_name: "",
      display_name: "",
      APPLICATION_ID: "",
      APPLICATION_NAME: "",
      RESPONSIBILITY_ID: "",
      RESPONSIBILITY_NAME: "",
      roles: [],
    });

  return useRiverForm<OracleEbsUserRoleDto, AdapterUserDto, Object>({
    entityName: "user_role",
    initialDto: getInitialDto(),
    instanceToEdit: props.userRole,
    onUpdate: props.onUpdate,
    create: async (
      dto: OracleEbsUserRoleDto
    ): Promise<OracleEbsUserRoleDto> => {
      return (await adapterService.createUser(
        dto
      )) as any as OracleEbsUserRoleDto;
    },
    update: async (dto: OracleEbsUserRoleDto): Promise<void> => {
      await adapterService.updateUser(props.userRole!["_id"], dto);
    },
    labels: {
      APPLICATION_NAME: t("entity.user_role:user_role.APPLICATION_NAME"),
      RESPONSIBILITY_NAME: t("entity.user_role:user_role.RESPONSIBILITY_NAME"),
    },
    render: (props) => renderOracleEbsUserRolesForm(props),
  });
};
