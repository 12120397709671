import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { IAdapterCalendar } from "@river/interfaces";
import { Constants } from "@river/constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Equals, IsArray, IsDate, IsNotEmpty } from "class-validator";
import { useTranslation } from "@river/common-ui";

export interface IGenerateAvailabilityForm {
  calendarName: string;
  calendar_id: string;
  start_date: Date | null;
  end_date: Date | null;
  availability_header_ids: string[];
}

interface UseGenerateAvailabilityFormProps {
  selectedCalendar: IAdapterCalendar | null;
}

export const useGenerateAvailabilityForm = (
  props: UseGenerateAvailabilityFormProps
): RiverFormInstance => {
  const { t } = useTranslation();

  class GenerateAvailabilityFormValidator {
    constructor(obj: IGenerateAvailabilityForm) {
      Object.assign(this, obj);
    }

    @Equals(props.selectedCalendar?.calendar || "", {
      message: (opts): string =>
        `shared.error_messages:validation.calendar_name?name=${opts.value}`,
    })
    @IsNotEmpty()
    // @ts-ignore
    calendarName: string;

    // @ts-ignore
    calendar_id: string;

    @IsNotEmpty()
    @IsDate()
    // @ts-ignore
    start_date: Date;

    @IsNotEmpty()
    @IsDate()
    // @ts-ignore
    end_date: Date;

    @IsNotEmpty()
    @IsArray()
    // @ts-ignore
    availability_header_ids: string[];
  }

  return useRiverForm<Object, Object, GenerateAvailabilityFormValidator>({
    standalone: {
      fields: new GenerateAvailabilityFormValidator({
        calendarName: "",
        calendar_id: "",
        start_date: new Date(),
        end_date: new Date(),
        availability_header_ids: [],
      }),
      fieldDefs: [
        {
          fieldName: "calendarName",
          dataType: Constants.data_type.string,
        },
        {
          fieldName: "calendar_id",
          dataType: Constants.data_type.string,
        },
        {
          fieldName: "start_date",
          dataType: Constants.data_type.date,
        },
        {
          fieldName: "end_date",
          dataType: Constants.data_type.date,
        },
        {
          fieldName: "availability_header_ids",
          dataType: Constants.data_type.any,
        },
      ],
      getValidatorInstance: (obj: IGenerateAvailabilityForm) =>
        new GenerateAvailabilityFormValidator(obj),
    },
    labels: {
      calendarName: t("shared.lookup:calendar.title"),
      start_date: t(
        "entity.availability_header:availability_header.calendar_start_date"
      ),
      end_date: t(
        "entity.availability_header:availability_header.calendar_end_date"
      ),
    },
  });
};
