import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const SaveIcon: React.FC<RiverSVGProps> = (props) => (
  <svg
    viewBox="0 0 17 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...sanitizeSvgProps(props)}
  >
    <title>{props.title}</title>
    <path d="M15.5 3.83333V13.8333C15.5 14.2917 15.3368 14.684 15.0104 15.0104C14.684 15.3368 14.2917 15.5 13.8333 15.5H2.16667C1.70833 15.5 1.31597 15.3368 0.989583 15.0104C0.663194 14.684 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663194 1.31597 0.989583 0.989583C1.31597 0.663194 1.70833 0.5 2.16667 0.5H12.1667L15.5 3.83333ZM8 13C8.69444 13 9.28472 12.7569 9.77083 12.2708C10.2569 11.7847 10.5 11.1944 10.5 10.5C10.5 9.80556 10.2569 9.21528 9.77083 8.72917C9.28472 8.24306 8.69444 8 8 8C7.30556 8 6.71528 8.24306 6.22917 8.72917C5.74306 9.21528 5.5 9.80556 5.5 10.5C5.5 11.1944 5.74306 11.7847 6.22917 12.2708C6.71528 12.7569 7.30556 13 8 13ZM3 6.33333H10.5V3H3V6.33333Z" />
  </svg>
);
