import React, { ReactElement, useState, useEffect, useRef } from "react";
import {
  RiverSpinner,
  RiverDialog,
  RiverDialogButton,
  IUseEnv,
  useEnv,
} from "@river/common-ui";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import {
  TableFetchFunction,
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
} from "../river-data-grid";
import { Column, RowsChangeData } from "react-data-grid";
import { IUseEntity, useEntity } from "../../../hooks";
import { TableContext } from "../../../context";
import {
  AvailableObjectsGridHeader,
  IAvailableObjectsGridHeaderStyles,
} from "./grid-headers/available-objects-grid-header";
import {
  AssignedObjectsGridHeader,
  IAssignedObjectsGridHeaderStyles,
} from "./grid-headers/assigned-objects-grid-header";
import { useTranslation } from "@river/common-ui";
import styles from "./river-table-selector.module.scss";
import clsx from "clsx";

export interface IRiverTableSelectorStyles {
  availableTableGridHeaderStyles?: IAvailableObjectsGridHeaderStyles;
  assignedTableGridHeaderStyles?: IAssignedObjectsGridHeaderStyles;
}

interface IRiverTableSelector {
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
  dialogTitle?: string;
  columns: Column<any>[];
  assignedTableColumns?: Column<any>[];
  selectedIds?: Set<string>;
  onChangeSelectedIds?: (selectedObjectIds: Set<string>) => void;
  onChangeSelectedObjects?: (selectedObjects: IEntityObject[]) => void;
  onAvailableTableRowsChange?: (
    rows: any[],
    rowData: RowsChangeData<any>
  ) => void;
  onAssignedTableRowsChange?: (
    rows: any[],
    rowData: RowsChangeData<any>
  ) => void;
  availableTableTitle: string;
  assignedTableTitle: string;
  entityName: string;
  availableEntityName?: string;
  assignedEntityName?: string;
  entityDef?: IEntityDefinition;
  rowKey?: string;
  rowKeyGetter?: (row: IEntityObject) => string;
  fetchObjects: TableFetchFunction;
  fetchAssignedObjects?: TableFetchFunction;
  fetchAvailableObjects?: TableFetchFunction;
  saveKeyAvailable?: string;
  saveKeyAssigned?: string;
  assignedTableSort?: (
    entities: IEntityObject[],
    selectedIds: Set<string>
  ) => void;
  footerContent?: ReactElement;
  renderAssignedTableCustomGridActions_primary?: (
    assignedTable: IUseTable
  ) => ReactElement;
  renderAssignedTableCustomGridActions_secondary?: (
    assignedTable: IUseTable
  ) => ReactElement;
  classes?: IRiverTableSelectorStyles;
  infiniteScrolling?: boolean;
}

const DEFAULT_IS_DIALOG: boolean = true;
const DEFAULT_INFINITE_SCROLLING: boolean = true;

export const RiverTableSelector: React.FC<IRiverTableSelector> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const isDialog: boolean = props.isDialog ?? DEFAULT_IS_DIALOG;
  const entity: IUseEntity = useEntity({
    entityName: props.availableEntityName || props.entityName,
    definition: props.entityDef,
  });
  const assignedEntity: IUseEntity = useEntity({
    entityName: props.assignedEntityName || props.entityName,
    definition: props.entityDef,
  });
  const [assignedObjectIds, setAssignedObjectIds] = useState<Set<string>>(
    props.selectedIds || new Set()
  );
  const isChangeInitiatedByUser = useRef<boolean>(false);
  const availableTableColumns: Column<any>[] = props.columns;
  const assignedTableColumns: Column<any>[] =
    props.assignedTableColumns || props.columns!;

  useEffect(() => {
    if (typeof props.selectedIds !== "undefined") {
      setAssignedObjectIds(props.selectedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIds]);

  const fetchAvailableObjects = async (
    fetchFunctionProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (props.fetchAvailableObjects) {
      return await props.fetchAvailableObjects(fetchFunctionProps);
    } else {
      fetchFunctionProps.nin = Array.from(assignedObjectIds);
      return await props.fetchObjects(fetchFunctionProps);
    }
  };

  const fetchAssignedObjects = async (
    fetchFunctionProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    let results: IEntityObject[] = [];
    if (props.fetchAssignedObjects) {
      results = await props.fetchAssignedObjects(fetchFunctionProps);
    } else {
      fetchFunctionProps.ids = Array.from(assignedObjectIds);
      results = await props.fetchObjects(fetchFunctionProps);
      props.assignedTableSort?.(results, assignedObjectIds);
    }
    return results;
  };

  const infiniteScrolling: boolean =
    props.infiniteScrolling ?? DEFAULT_INFINITE_SCROLLING;
  const availableTable: IUseTable = useTable({
    saveKey: props.saveKeyAvailable,
    columns: availableTableColumns,
    dependencies: [isDialog ? !!props.open : true, !!assignedObjectIds],
    rowKeyGetter:
      props.rowKeyGetter ||
      ((row: IEntityObject) => String(row[props.rowKey!])),
    fetchFunction: fetchAvailableObjects,
    fetchOn: assignedObjectIds && isDialog ? !!props.open : true,
    fetchTriggers: [assignedObjectIds],
    useAdvancedFilters: false,
    infiniteScrolling,
  });

  const assignedTable: IUseTable = useTable({
    saveKey: props.saveKeyAssigned,
    columns: assignedTableColumns,
    dependencies: [isDialog ? !!props.open : true, !!assignedObjectIds],
    rowKeyGetter:
      props.rowKeyGetter ||
      ((row: IEntityObject) => String(row[props.rowKey!])),
    fetchFunction: fetchAssignedObjects,
    fetchOn: assignedObjectIds && isDialog ? !!props.open : true,
    fetchTriggers: [assignedObjectIds],
    useAdvancedFilters: false,
    infiniteScrolling,
  });

  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  const assignSelectedObjects = async (): Promise<any> => {
    const newAssignedIds: Set<string> = new Set([
      ...Array.from(assignedObjectIds),
      ...Array.from(availableTable.selectedRowIds),
    ]);
    isChangeInitiatedByUser.current = true;
    setAssignedObjectIds(newAssignedIds);
    availableTable.setSelectedRowIds(new Set([]));
  };

  const unassignSelectedObjects = async (): Promise<void> => {
    const newAssignedIds: Set<string> = new Set(
      Array.from(assignedObjectIds).filter(
        (id) => !assignedTable.selectedRowIds.has(id)
      )
    );
    isChangeInitiatedByUser.current = true;
    setAssignedObjectIds(newAssignedIds);
    assignedTable.setSelectedRowIds(new Set([]));
  };

  useEffect(() => {
    if (isDialog ? !!props.open : true) {
      if (props.onChangeSelectedIds) {
        props.onChangeSelectedIds(assignedObjectIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedObjectIds]);

  useEffect(() => {
    if (
      (isDialog ? !!props.open : true) &&
      props.onChangeSelectedObjects &&
      isChangeInitiatedByUser.current
    ) {
      props.onChangeSelectedObjects(assignedTable.entities);
      isChangeInitiatedByUser.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedTable.entities]);

  const isLoading: boolean =
    assignedTable.isLoading || availableTable.isLoading;
  const renderContent = (): ReactElement => (
    <>
      <RiverSpinner show={isLoading} />
      <div className={clsx([styles.tableContainer, styles.availableObjects])}>
        <TableContext.Provider
          value={{
            table: availableTable,
            entity,
          }}
        >
          <AvailableObjectsGridHeader
            title={props.availableTableTitle}
            assignSelectedObjects={assignSelectedObjects}
            classes={props.classes?.availableTableGridHeaderStyles}
          />
          <RiverDataGrid
            columns={availableTable.columns}
            rows={availableTable.entities}
            rowKeyGetter={availableTable.rowKeyGetter}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            selectedRows={availableTable.selectedRowIds}
            onSelectedRowsChange={(rowKeys) =>
              availableTable.setSelectedRowIds(rowKeys)
            }
            onRowsChange={props.onAvailableTableRowsChange}
            sortColumns={availableTable.sortColumns}
            onSortColumnsChange={(e) => {
              availableTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </div>
      <div className={clsx([styles.tableContainer, styles.assignedObjects])}>
        <TableContext.Provider
          value={{
            table: assignedTable,
            entity: assignedEntity,
          }}
        >
          <AssignedObjectsGridHeader
            title={props.assignedTableTitle}
            unassignSelectedObjects={unassignSelectedObjects}
            customGridActions_primary={props.renderAssignedTableCustomGridActions_primary?.(
              assignedTable
            )}
            customGridActions_secondary={props.renderAssignedTableCustomGridActions_secondary?.(
              assignedTable
            )}
            classes={props.classes?.assignedTableGridHeaderStyles}
          />
          <RiverDataGrid
            columns={assignedTable.columns}
            rows={assignedTable.entities}
            rowKeyGetter={assignedTable.rowKeyGetter}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            selectedRows={assignedTable.selectedRowIds}
            onSelectedRowsChange={(rowKeys) =>
              assignedTable.setSelectedRowIds(rowKeys)
            }
            onRowsChange={props.onAssignedTableRowsChange}
            sortColumns={assignedTable.sortColumns}
            onSortColumnsChange={(e) => {
              assignedTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </div>
    </>
  );
  return (
    <>
      {isDialog && (
        <RiverDialog
          title={props.dialogTitle}
          open={props.open!}
          onClose={handleClose}
          actionsContent={
            props.footerContent || (
              <RiverDialogButton
                onClick={handleClose}
                text={t("common.button:close")}
              />
            )
          }
          classes={{
            paper: clsx([styles.paper, { [styles.mobile]: isMobile }]),
            title: styles.title,
            content: styles.content,
            actions: styles.actions,
          }}
          showActionsDivider={false}
          dialogProps={{
            maxWidth: false,
          }}
        >
          {renderContent()}
        </RiverDialog>
      )}
      {!isDialog && renderContent()}
    </>
  );
};
