import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from "react";
import { ISupervisorScheduleGanttTimeOption } from "../components/supervisor-schedule/supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";
import { IUseTable } from "../components/shared";
import { SetTabFn } from "../context";
import {
  IAdapterBaseline,
  IAdapterFolder,
  IAdapterRule,
} from "@river/interfaces";
import { IUseSupervisorScheduleGantt } from "../components/supervisor-schedule/supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { IUseSupervisorScheduleOperationsOverlay } from "../components/supervisor-schedule/supervisor-schedule-gantt";
import { DataGridHandle } from "react-data-grid";

export interface ISupervisorScheduleContext {
  currentSchedule: IAdapterFolder | undefined;
  refreshCurrentSchedule: () => void;

  selectedGanttDate: Date | undefined;
  setSelectedGanttDate: (date: Date) => void;

  ganttTimeOption: ISupervisorScheduleGanttTimeOption | undefined;
  setGanttTimeOption: (options: ISupervisorScheduleGanttTimeOption) => void;

  ganttSplitterRef: RefObject<HTMLDivElement> | undefined;
  setGanttSplitterRef: (ref: RefObject<HTMLDivElement>) => void;

  utilizationSplitterRef: RefObject<HTMLDivElement> | undefined;
  setUtilizationSplitterRef: (ref: RefObject<HTMLDivElement>) => void;

  entityTableRef: RefObject<DataGridHandle> | undefined;
  setEntityTableRef: (ref: RefObject<DataGridHandle>) => void;

  ganttScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setGanttScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  ganttOverlayScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setGanttOverlayScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  utilizationScrollAreaRef: RefObject<HTMLDivElement> | undefined;
  setUtilizationScrollAreaRef: (ref: RefObject<HTMLDivElement>) => void;

  currentTasksTableRef: MutableRefObject<IUseTable | undefined>;
  ganttRef: MutableRefObject<IUseSupervisorScheduleGantt | undefined>;
  utilizationGanttRef: MutableRefObject<
    IUseSupervisorScheduleGantt | undefined
  >;
  operationsOverlayRef: MutableRefObject<
    IUseSupervisorScheduleOperationsOverlay | undefined
  >;

  forceScheduleRender: () => void;

  scheduleUtilizationCurrentTableState: IUseTable | undefined;
  setScheduleUtilizationCurrentTableState: (table: IUseTable) => void;

  scheduleFn: (date: Date, ids?: string[]) => void;

  selectedValidationRule: IAdapterRule | null;
  setSelectedValidationRule: (rule: IAdapterRule | null) => void;

  selectedBaseline: IAdapterBaseline | undefined;
  setSelectedBaseline: Dispatch<SetStateAction<IAdapterBaseline | undefined>>;

  scheduleTasksExpanded: boolean;
  setScheduleTasksExpanded: (expanded: boolean) => void;

  setCurrentScheduleTasksTabFnRef: MutableRefObject<SetTabFn | undefined>;
}

export const SupervisorScheduleContext =
  React.createContext<ISupervisorScheduleContext | null>(null);
