import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { OracleEbsAdapterService } from "../../../../../../services/oracle-ebs";

export function useOracleEbsPriorityLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:priority.title"),
      rowKey: "MEANING",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          { key: "MEANING", name: t("entity.wo_priority:wo_priority.MEANING") },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as OracleEbsAdapterService
          ).searchEntityData(
            "wo_priority",
            fetchHelpers.getTableQuery({ fetchProps })
          );
        },
        dependencies: [!!site],
        saveKey: "wo_priority.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "wo_priority",
      },
    };
  };
}

export type IUseOracleEbsPriorityLookupDef = ReturnType<
  typeof useOracleEbsPriorityLookupDef
>;
