import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { IEntityObject } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../../../context";
import { IUseSupervisorScheduleOperationsOverlay } from "./supervisor-schedule-operations-gantt-overlay";
import { SupervisorScheduleOperationsGanttOverlay } from "./supervisor-schedule-operations-gantt-overlay";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "../../../../services";
import { IUseSupervisorScheduleGantt } from "../use-supervisor-schedule-gantt";
import { DEFAULT_ROW_HEIGHT } from "../../../shared";
import styles from "./supervisor-schedule-gantt-overlay.module.scss";

export interface ISupervisorScheduleGanttOverlay {
  operationsOverlay: IUseSupervisorScheduleOperationsOverlay;
  data: IEntityObject[];
}

export const SupervisorScheduleGanttOverlay: React.FC<
  ISupervisorScheduleGanttOverlay
> = (props): ReactElement => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const gantt: IUseSupervisorScheduleGantt = scheduleContext!.ganttRef.current!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  useEffect(() => {
    scheduleContext?.setGanttOverlayScrollAreaRef(scrollAreaRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const top: number = e.currentTarget.scrollTop;
    scheduleContext?.entityTableRef?.current?.element?.scrollTo({ top });
  };

  return (
    <div ref={scrollAreaRef} className={styles.scrollArea} onScroll={onScroll}>
      <div
        className={styles.content}
        style={{
          height: `${props.data.length * DEFAULT_ROW_HEIGHT}px`,
        }}
      >
        {currentTab === SupervisorScheduleTasksTabId.OPERATIONS &&
          !!gantt.ganttRatios && (
            <SupervisorScheduleOperationsGanttOverlay
              overlay={props.operationsOverlay}
            />
          )}
      </div>
    </div>
  );
};
