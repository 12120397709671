import React, { SVGProps } from "react";

const CompletionErrorIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#B21619" />
      <mask
        id="mask0_14_2343"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_14_2343)">
        <path
          d="M15 24C15.2833 24 15.5208 23.9042 15.7125 23.7125C15.9042 23.5208 16 23.2833 16 23C16 22.7167 15.9042 22.4792 15.7125 22.2875C15.5208 22.0958 15.2833 22 15 22C14.7167 22 14.4792 22.0958 14.2875 22.2875C14.0958 22.4792 14 22.7167 14 23C14 23.2833 14.0958 23.5208 14.2875 23.7125C14.4792 23.9042 14.7167 24 15 24ZM14 21H16V16H14V21ZM18 23H26V21H18V23ZM18 19H26V17H18V19ZM12 28C11.45 28 10.9792 27.8042 10.5875 27.4125C10.1958 27.0208 10 26.55 10 26V14C10 13.45 10.1958 12.9792 10.5875 12.5875C10.9792 12.1958 11.45 12 12 12H28C28.55 12 29.0208 12.1958 29.4125 12.5875C29.8042 12.9792 30 13.45 30 14V26C30 26.55 29.8042 27.0208 29.4125 27.4125C29.0208 27.8042 28.55 28 28 28H12ZM12 26H28V14H12V26Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CompletionErrorIcon;
