import { SitesUiService } from "../sites-ui.service";
import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { IUserContextSite } from "../../context";

export class OracleCloudSitesUiService extends SitesUiService {
  protected getSiteEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "organization",
      columns: [
        {
          key: "OrganizationCode",
          name: t("entity.organization:organization.OrganizationCode"),
        },
        {
          key: "OrganizationName",
          name: t("entity.organization:organization.OrganizationName"),
        },
        {
          key: "LegalEntityName",
          name: t("entity.organization:organization.LegalEntityName"),
        },
      ],
    };
  };

  getSitePropertyFromErpRecord = (siteRec: any): IUserContextSite => {
    if (!siteRec) {
      return {
        site_id: 0,
        site_code: "",
        description: "",
      };
    }
    const { OrganizationId, OrganizationCode, OrganizationName } = siteRec;
    return {
      site_id: OrganizationId,
      site_code: OrganizationCode,
      description: OrganizationName,
    };
  };

  protected getSiteRowKey = (siteRec: any): any => {
    return siteRec[this.getSiteKeyColumn()];
  };

  getSiteKeyColumn = (): string => "OrganizationId";
}
