import {
  IAdapterBaseline,
  IAdapterFolderRef,
  IEntityObject,
} from "@river/interfaces";
import { AdapterUiService } from "./adapter-ui.service";
import { uiConstants } from "../helpers";

export type ObjectType =
  (typeof uiConstants.rowType)[keyof typeof uiConstants.rowType];

export interface IOperationsOverlayPropertyNames {
  to_Relations_path: string;
  OrderIdProp: string;
  OrderNumberProp: string;
  OrderPredecessorProp: string;
  OrderSuccessorProp: string;
  OperationPredecessorProp: string;
  OperationSuccessorProp: string;
  RelationTypeProp: string;
  OperationActivityProp: string;
  LagHoursProp: string;
}

export interface ITaskHelpers {
  getTaskFolder(row: IEntityObject): IAdapterFolderRef;
  getTaskStartDate(row: IEntityObject): string;
  getTaskEndDate(row: IEntityObject): string;
  getBaselineStartDate(
    row: any,
    selectedBaseline: IAdapterBaseline | undefined
  ): string;
  getBaselineEndDate(
    row: any,
    selectedBaseline: IAdapterBaseline | undefined
  ): string;
  getGanttText(row: IEntityObject): string;
  getOperationsOverlayPropertyNames(): IOperationsOverlayPropertyNames;
  getStartDateFieldName(obj: IEntityObject): string;
  getEndDateFieldName(obj: IEntityObject): string;
}

export abstract class TaskHelpersUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  abstract getHelpers(): () => ITaskHelpers;
}
