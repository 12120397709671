import { useTranslation } from "@river/common-ui";
import {
  ColumnsProvider,
  RiverFormInstanceProvider,
} from "../../ui-service.types";
import {
  UserRolesUiService,
  IUseUserRolesFormProps,
} from "../../user-roles-ui.service";
import { AdapterUserDto, IEntityObject } from "@river/interfaces";
import {
  IUseOracleEbsUserRoleFormProps,
  useOracleEbsUserRoleForm,
} from "./use-oracle-ebs-user-role-form";
import { useTableCellRenderers } from "../../../hooks";

export class OracleEbsUserRolesUiService extends UserRolesUiService {
  getUserRolesTableColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { renderTextCell } = useTableCellRenderers();
      return [
        { key: "user_name", name: t("entity.user_role:user_role.user_name") },
        {
          key: "APPLICATION_NAME",
          name: t("entity.user_role:user_role.APPLICATION_NAME"),
        },
        {
          key: "RESPONSIBILITY_NAME",
          name: t("entity.user_role:user_role.RESPONSIBILITY_NAME"),
        },
        {
          key: "display_name",
          name: t("entity.user_role:user_role.display_name"),
        },
        {
          key: "roles",
          name: t("entity.user_role:user_role.roles"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: (formatterProps.row.roles as [])?.join(", "),
              })}
            </>
          ),
        },
        { key: "created_at", name: t("entity.user_role:user_role.created_at") },
        { key: "updated_at", name: t("entity.user_role:user_role.updated_at") },
        {
          key: "is_config_admin",
          name: t("entity.user_role:user_role.is_config_admin"),
          formatter: (formatterProps) => (
            <>
              {renderTextCell({
                formatterProps,
                value: formatterProps.row.is_config_admin
                  ? t("common.button:yes")
                  : t("common.button:no"),
              })}
            </>
          ),
        },
      ];
    };
  }

  protected getCreateUserRoleDto = (user: IEntityObject): AdapterUserDto =>
    ({
      user_name: user.USER_NAME,
      email: user.EMAIL_ADDRESS || "",
      first_name: "",
      last_name: "",
      display_name: user.DISPLAY_NAME,
      roles: [],
    }) as AdapterUserDto;

  getUserRolesForm =
    (props: IUseUserRolesFormProps): RiverFormInstanceProvider =>
    () =>
      useOracleEbsUserRoleForm(props as IUseOracleEbsUserRoleFormProps);
}
