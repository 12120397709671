import React, { ReactElement, useContext } from "react";
import { ScheduleContext } from "../../../../context";
import { GanttHeaderPeriod } from "./gantt-header-period";
import { IUseScheduleGantt } from "../use-schedule-gantt";
import { SCHEDULE_TASKS_GANTT_CONTAINER_ID } from "../schedule-gantt";
import { useGanttRenderChecks } from "../use-gantt-render-checks";
import styles from "./schedule-gantt-header.module.scss";
import clsx from "clsx";

export const ScheduleGanttHeaderRenderer: React.FC = (): ReactElement => {
  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt = scheduleContext?.ganttRef.current!;
  const { periods } = gantt;
  const { shouldRender } = useGanttRenderChecks({
    gantt,
    containerId: SCHEDULE_TASKS_GANTT_CONTAINER_ID,
  });

  return (
    <>
      {shouldRender && (
        <div className={clsx([styles.root])}>
          {periods.map((period) => (
            <GanttHeaderPeriod period={period} key={period.index} />
          ))}
        </div>
      )}
    </>
  );
};
