import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { IEntityObject } from "@river/interfaces";
import { useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { useTranslation } from "@river/common-ui";
import { AdapterUiService, AdapterService } from "../../../../services";

import { ArrayNotEmpty, Equals, IsNotEmpty } from "class-validator";

export interface IStandaloneValidatorJdeAvailabilityCreateResourceDialog {
  F0101_AN8: string;
  F0101_ALPH: string;
}

export const useJdeAvailabilityCreateResourceDialogForm = (props: {
  data: IEntityObject | null;
  onCreate?: (data: IEntityObject) => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  class StandaloneValidator {
    constructor(obj: IStandaloneValidatorJdeAvailabilityCreateResourceDialog) {
      Object.assign(this, obj);
    }
    @IsNotEmpty()
    @Equals(props.data?.F0101_AN8 || "", {
      message: (opts): string =>
        `shared.error_messages:validation.F0101_AN8?name=${opts.value}`,
    })
    // @ts-ignore
    F0101_AN8: string;

    // @ts-ignore
    F0101_ALPH: string;
  }

  class JdeAvailabilityCreateResourceDialogDto {
    @ArrayNotEmpty({
      message: t("module.availability:notification.needs_at_least_one_craft", {
        craft: t("shared.crafts:label.craft"),
      }),
    })

    // @ts-ignore
    WorkCenters: IEntityObject[];

    // @ts-ignore
    number_of_units: number;

    // @ts-ignore
    is_crew_workcenter: boolean;

    // Other ERP specific attributes
    [s: string]: any;
  }

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterUiService: AdapterUiService = adapterContext?.service!;
  const adapterService: AdapterService = adapterUiService.getAdapterService();

  return useRiverForm<
    JdeAvailabilityCreateResourceDialogDto,
    IEntityObject,
    StandaloneValidator
  >({
    initialDto: Object.assign(new JdeAvailabilityCreateResourceDialogDto(), {
      WorkCenters: [],
      number_of_units: 1,
      is_crew_workcenter: false,
    }),
    standalone: {
      fields: new StandaloneValidator({
        F0101_AN8: "",
        F0101_ALPH: "",
      }),
      fieldDefs: [
        {
          fieldName: "F0101_AN8",
          dataType: "string",
        },
        {
          fieldName: "F0101_ALPH",
          dataType: "string",
        },
      ],
      getValidatorInstance: (
        obj: IStandaloneValidatorJdeAvailabilityCreateResourceDialog
      ) => new StandaloneValidator(obj),
    },
    entityName: "availability",
    onCreate: props.onCreate,
    create: async (
      dto: JdeAvailabilityCreateResourceDialogDto
    ): Promise<IEntityObject> => {
      return (await adapterService.createAvailabilityResource({
        ...dto,
        F0101_AN8: props.data?.F0101_AN8,
        F0101_ALPH: props.data?.F0101_ALPH,
      })) as IEntityObject;
    },
    labels: {
      F0101_AN8: t("module.availability:label.address_number"),
      F0101_ALPH: t("module.availability:label.persone_name"),
    },
  });
};
