import { SitesUiService } from "../sites-ui.service";
import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { IUserContextSite } from "../../context";

export class OracleEbsSitesUiService extends SitesUiService {
  protected getSiteEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "organization",
      columns: [
        {
          key: "ORGANIZATION_CODE",
          name: t("entity.organization:organization.ORGANIZATION_CODE"),
        },
        {
          key: "NAME",
          name: t("entity.organization:organization.NAME"),
        },
      ],
    };
  };

  getSitePropertyFromErpRecord = (siteRec: any): IUserContextSite => {
    if (!siteRec) {
      return {
        site_id: 0,
        site_code: "",
        description: "",
      };
    }
    const { ORGANIZATION_ID, ORGANIZATION_CODE, NAME } = siteRec;
    return {
      site_id: ORGANIZATION_ID,
      site_code: ORGANIZATION_CODE,
      description: NAME,
    };
  };

  protected getSiteRowKey = (siteRec: any): any => {
    return siteRec[this.getSiteKeyColumn()];
  };

  getSiteKeyColumn = (): string => "ORGANIZATION_ID";
}
