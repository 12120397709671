import React, { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import AirplayIcon from "@mui/icons-material/Airplay";
import { uiConstants, useGridActionHelpers } from "../../../helpers";
import { useTranslation } from "@river/common-ui";

export const useOpenErpWorkOrderAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("module.schedule:action_label.open_in_erp");

  const onClick = async (): Promise<void> => {
    const selectedId = Array.from(tableContext?.table.selectedRowIds!)[0];
    const record = tableContext?.table.entities.find(
      (e) => e[uiConstants.fields.rowId] === selectedId
    );
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, "workorder");
  };

  const renderOpenErpWorkorderAction = (props?: any): ReactElement => (
    <GenericAction
      icon={AirplayIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderOpenErpWorkorderAction,
    openErpWorkorderAction: {
      icon: withSanitizedProps(renderOpenErpWorkorderAction),
      title,
      onClick,
    },
  };
};

export type IUseOpenErpWorkOrderAction = ReturnType<
  typeof useOpenErpWorkOrderAction
>;
