import React, { ReactElement, useContext } from "react";
import { IEntityObject } from "@river/interfaces";
import { Box } from "@mui/material";
import {
  RiverPopupMenu,
  IRiverPopupMenuItem,
} from "../../../shared/river-popup-menu";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../../context";
import { useEnv, IUseEnv } from "@river/common-ui";
import styles from "./wo-pane-item.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";
import { ExecutionTabId, ExecutionUiService } from "../../../../services";

export enum WoPaneItemColor {
  RED = "red",
  BLUE = "blue",
  GREEN = "green",
  PURPLE = "purple",
  ORANGE = "orange",
  LIGHT_BLUE = "lightBlue",
  GRASS_GREEN = "grassGreen",
  LIGHT_PURPLE = "lightPurple",
}
export const randomWoPaneItemColors: WoPaneItemColor[] = [
  WoPaneItemColor.RED,
  WoPaneItemColor.BLUE,
  WoPaneItemColor.GREEN,
  WoPaneItemColor.PURPLE,
  WoPaneItemColor.ORANGE,
  WoPaneItemColor.LIGHT_BLUE,
  WoPaneItemColor.GRASS_GREEN,
  WoPaneItemColor.LIGHT_PURPLE,
];
const DEFAULT_COLOR: WoPaneItemColor = WoPaneItemColor.RED;

interface WoPaneItemProps {
  workOrder: IEntityObject;
  color?: WoPaneItemColor;
}

export const WoPaneItem: React.FC<WoPaneItemProps> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;

  const executionContext = useContext(ExecutionContext);
  const woPropsToRender = uiService.getWoPropsToRender();
  const isItemSelected: boolean =
    executionContext?.currentWorkOrder?._id === props.workOrder._id;
  const tabContext = useContext(TabContext);

  const { t } = useTranslation();

  const woItemPropLabelMap: Map<string, string> =
    uiService.getWoItemPropLabelMap(t);
  const menuItems: IRiverPopupMenuItem[] = uiService.getWoPaneMenuItems({
    executionContext,
    workOrder: props.workOrder,
  })();

  const onItemClick = (e: React.MouseEvent<any>): void => {
    // pass-through when clicking on menu icon
    if ((e.target as HTMLElement).closest("button")) return;

    e.preventDefault();
    e.stopPropagation();
    if (isMobile) {
      tabContext?.setSelectedTab(ExecutionTabId.DETAILS);
    }
    executionContext?.setCurrentWorkOrder(props.workOrder);
  };

  const renderWoProperty = (propName: string, index: number): ReactElement => {
    return (
      <div className={styles.propRow} key={index}>
        <div className={styles.propLabel}>
          {woItemPropLabelMap.get(propName)}
        </div>
        <div className={styles.propValue}>
          {props.workOrder[propName]?.toString()}
        </div>
      </div>
    );
  };

  return (
    <Box
      className={clsx([
        styles.root,
        props.color || DEFAULT_COLOR,
        { [styles.selected]: isItemSelected },
        { [styles.mobile]: isMobile },
      ])}
      onClick={onItemClick}
    >
      <div className={styles.colorMarker} />
      <div className={styles.body}>
        {woPropsToRender.map((woProp, index) =>
          renderWoProperty(woProp, index)
        )}
      </div>
      <div className={styles.menu}>
        <RiverPopupMenu items={menuItems} />
      </div>
    </Box>
  );
};
