import React, { ReactElement, useContext } from "react";
import { Column } from "react-data-grid";
import styles from "./column-filter.module.scss";
import clsx from "clsx";
import { TableContext } from "../../../../context";
import {
  AttributeDataType,
  IFilterDefinition,
  IColumnFilter,
} from "../../../../interfaces";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { RiverSelect } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { uiConstants } from "../../../../helpers";

const ROW_SELECTOR_KEY = uiConstants.utilityColumnTypes.selectRow;

const FILTER_KEYBOARD_KEYS = ["Enter", "NumpadEnter"];

interface IColumnFilterProps {
  column: Column<any>;
  className?: string;
}

enum BooleanSelectorValue {
  ALL = "all",
  TRUE = "true",
  FALSE = "false",
}

export const ColumnFilter: React.FC<IColumnFilterProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const definition: IFilterDefinition | undefined =
    tableContext?.entity.filterDefinitionByAttributeName.get(props.column.key);
  const currentFilters: IColumnFilter[] = tableContext?.table.columnFilters!;
  const BLANK_FILTER: IColumnFilter = {
    value: "",
    field: props.column.key,
    operator: definition ? definition.defaultOperator! : "$eq",
  };
  const filter: IColumnFilter =
    tableContext?.table.columnFilters.find(
      (filter) => filter.field === definition?.field
    ) || BLANK_FILTER;

  const doFilter = (newFilters?: IColumnFilter[]): void => {
    tableContext?.table.fetch({ newColumnFilters: newFilters });
  };

  const handleKeyUp = (key: string) => {
    if (FILTER_KEYBOARD_KEYS.indexOf(key) !== -1) {
      doFilter();
    }
  };

  const updateTextFieldFilter = (newValue: string) => {
    const newFilters = [...currentFilters];
    const filterIndex = newFilters.findIndex(
      (filter) => filter.field === props.column.key
    );
    if (newValue === "") {
      if (filterIndex !== -1) {
        newFilters.splice(filterIndex, 1);
      }
    } else {
      const newFilter: IColumnFilter = {
        ...filter,
        value: newValue,
      };
      if (filterIndex !== -1) {
        newFilters.splice(filterIndex, 1, newFilter);
      } else {
        newFilters.push(newFilter);
      }
    }
    tableContext?.table.setColumnFilters(newFilters);
  };

  const renderTextFieldFilter = (
    inputType: React.InputHTMLAttributes<unknown>["type"]
  ): ReactElement => (
    <TextField
      type={inputType}
      classes={{ root: styles.textField }}
      InputProps={{
        className: styles.inputBase,
        classes: {
          root: styles.outlinedInputRoot,
          input: styles.inputField,
          notchedOutline: styles.notchedOutline,
        },
        startAdornment: (
          <InputAdornment position="start" className={styles.iconContainer}>
            <SearchIcon className={styles.searchIcon} />
          </InputAdornment>
        ),
        placeholder: t("common.button:search"),
      }}
      value={filter.value}
      onChange={(event) => updateTextFieldFilter(event.target.value)}
      onKeyUp={(event) => handleKeyUp(event.code)}
    />
  );

  const renderBooleanFilter = (): ReactElement => {
    const updateBooleanFilter = (value: BooleanSelectorValue) => {
      const newFilters = [...currentFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.field === props.column.key
      );
      if (value === BooleanSelectorValue.ALL) {
        if (filterIndex !== -1) {
          newFilters.splice(filterIndex, 1);
        }
      } else {
        const newFilter: IColumnFilter = {
          ...filter,
          value: JSON.parse(value),
        };
        if (filterIndex !== -1) {
          newFilters.splice(filterIndex, 1, newFilter);
        } else {
          newFilters.push(newFilter);
        }
      }
      doFilter(newFilters);
    };

    const getBooleanSelectorValue = (
      filter: IColumnFilter
    ): BooleanSelectorValue => {
      const filterValue: boolean | undefined = filter.value;
      let value: BooleanSelectorValue = BooleanSelectorValue.ALL;
      if (typeof filterValue === "boolean") {
        value = JSON.stringify(filterValue) as BooleanSelectorValue;
      }
      return value;
    };

    const booleanFilterItems: {
      value: BooleanSelectorValue;
      caption: string;
    }[] = [
      { value: BooleanSelectorValue.ALL, caption: t("common.label:all") },
      { value: BooleanSelectorValue.TRUE, caption: t("common.label:true") },
      { value: BooleanSelectorValue.FALSE, caption: t("common.label:false") },
    ];

    return (
      <>
        <SearchIcon className={styles.searchIcon} />
        <RiverSelect
          items={booleanFilterItems.map((item) => {
            return { label: item.caption, value: item.value };
          })}
          value={getBooleanSelectorValue(filter)}
          onChange={(value) =>
            updateBooleanFilter(value as BooleanSelectorValue)
          }
          classes={{
            select: {
              select: styles.select,
              dropdownIcon: styles.dropdownIcon,
            },
          }}
        ></RiverSelect>
      </>
    );
  };

  const render = (): ReactElement => {
    type RenderFn = () => ReactElement;
    const DEFAULT_DATA_TYPE = AttributeDataType.STRING;
    const DEFAULT_RENDER_FN = () => renderTextFieldFilter("text");
    const dataType: AttributeDataType =
      definition?.dataType || DEFAULT_DATA_TYPE;
    const renderFns: { [dataType in AttributeDataType]?: RenderFn } = {
      [AttributeDataType.STRING]: () => renderTextFieldFilter("text"),
      [AttributeDataType.DOUBLE]: () => renderTextFieldFilter("number"),
      [AttributeDataType.DATE]: () => renderTextFieldFilter("date"),
      [AttributeDataType.DATETIME]: () => renderTextFieldFilter("date"),
      [AttributeDataType.BOOLEAN]: renderBooleanFilter,
    };
    const renderFn: RenderFn = renderFns[dataType] || DEFAULT_RENDER_FN;
    return (
      <>
        <div
          className={clsx([styles.root, definition!.dataType, props.className])}
        >
          {renderFn()}
        </div>
      </>
    );
  };

  const applicable = (): boolean =>
    props.column.key !== ROW_SELECTOR_KEY && !!definition;
  return <>{applicable() && render()}</>;
};
