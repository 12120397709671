import React, { ReactElement } from "react";
import {
  RelationRenderer,
  IRelationRendererProps,
} from "../../schedule-operations-gantt-overlay";
import { IUseStartToStart, useStartToStart } from "./use-start-to-start";
import {
  IRelationConnectorEvents,
  LineArrow,
  renderHorizontalLine,
  renderVerticalLine,
} from "../../render-utils";
import genericStyles from "../../schedule-operations-gantt-overlay.module.scss";
import clsx from "clsx";

export const StartToStartRelationRenderer: RelationRenderer = (
  props: IRelationRendererProps
): ReactElement => {
  const startToStart: IUseStartToStart = useStartToStart(props);
  const {
    predecessorStart,
    vertical,
    successorStart,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
    selected,
  } = startToStart;

  const connectorsEventHandlers: IRelationConnectorEvents = {
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const renderPredecessorStartConnector = (): ReactElement => (
    <>
      {predecessorStart &&
        renderHorizontalLine({
          ...predecessorStart,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderVerticalConnector = (): ReactElement => (
    <>
      {vertical &&
        renderVerticalLine({
          ...vertical,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderSuccessorStartConnector = (): ReactElement => (
    <>
      {successorStart &&
        renderHorizontalLine({
          ...successorStart,
          ...connectorsEventHandlers,
          arrow: LineArrow.RIGHT,
        })}
    </>
  );

  return (
    <div
      className={clsx([
        genericStyles.relation,
        {
          [genericStyles.selected]: selected,
        },
      ])}
    >
      {renderPredecessorStartConnector()}
      {renderVerticalConnector()}
      {renderSuccessorStartConnector()}
    </div>
  );
};
