import React, { useContext, useEffect, useRef, useState } from "react";
import SplitterLayout from "react-splitter-layout";
import { DataGridHandle, FormatterProps } from "react-data-grid";
import { RiverDataGrid } from "../../shared";
import { IScheduleListProps } from "../schedule-list";
import { ScheduleGanttFormatter } from "./schedule-gantt-formatter";
import { ScheduleGanttHeaderRenderer } from "./schedule-gantt-header-renderer";
import { useScheduleGantt } from "./use-schedule-gantt";
import { uiConstants } from "../../../helpers";
import { IAdapterFolder } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
} from "../../../context";
import { IScheduleGanttTimeOption } from "../schedule-tasks/schedule-gantt-header-options";
import { DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE } from "../schedule";
import { useScheduleOperationsOverlay } from "./schedule-gantt-overlay";
import { ScheduleGanttOverlay } from "./schedule-gantt-overlay";
import { userPreferencesService } from "../../../services";
import { useNotification } from "@river/common-ui";
import styles from "./schedule-gantt.module.scss";
import clsx from "clsx";

export const SCHEDULE_TASKS_GANTT_CONTAINER_ID: string =
  "scheduleTasksGanttContainer";

export interface IScheduleGanttProps extends IScheduleListProps {
  getTaskColor: (row: any) => string;
}

export const ScheduleGantt: React.FC<IScheduleGanttProps> = (
  props: IScheduleGanttProps
) => {
  const splitterContainerRef = useRef<HTMLDivElement>(null);
  const entityTableRef = useRef<DataGridHandle>(null);
  const ganttTableRef = useRef<DataGridHandle>(null);
  const ganttScrollAreaRef = useRef<HTMLDivElement>(null);
  const [ganttContentRef, setGanttContentRef] = useState<HTMLDivElement | null>(
    null
  );
  const [splitterWidth, setSplitterWidth] = useState<number | null>(null);

  const scheduleContext = useContext(ScheduleContext);
  const schedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const ganttTimeOption: IScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  useEffect(() => {
    if (
      ganttScrollAreaRef.current &&
      scheduleContext?.utilizationScrollAreaRef?.current
    ) {
      ganttScrollAreaRef.current.scrollLeft =
        scheduleContext?.utilizationScrollAreaRef?.current!.scrollLeft!;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ganttScrollAreaRef.current]);

  useEffect(() => {
    const setInitialLeftPaneWidth = async () => {
      try {
        scheduleContext?.currentTasksTableRef.current?.forceLoadingState(true);
        const preferenceWidth: number | null =
          await userPreferencesService.getVerticalScheduleSplitterPosition(
            adapterContext!.service.getAdapterService()
          );
        const initialWidth =
          preferenceWidth ?? DEFAULT_LEFT_PANE_WIDTH_PERCENTAGE;
        setSplitterWidth(initialWidth);
      } catch (message) {
        notify.error({ message });
      } finally {
        scheduleContext?.currentTasksTableRef.current?.forceLoadingState(false);
      }
    };

    scheduleContext?.setEntityTableRef(entityTableRef);
    scheduleContext?.setGanttSplitterRef(splitterContainerRef);
    scheduleContext?.setGanttScrollAreaRef(ganttScrollAreaRef);

    setInitialLeftPaneWidth();

    return () => {
      delete scheduleContext?.ganttRef.current;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gantt = useScheduleGantt({
    timeOption: ganttTimeOption,
    parentElement: ganttContentRef,
    scheduleFn: scheduleContext?.scheduleFn!,
  });
  scheduleContext!.ganttRef.current = gantt;

  const operationsOverlay = useScheduleOperationsOverlay({
    gantt,
    data: props.data,
  });
  scheduleContext!.operationsOverlayRef.current = operationsOverlay;

  const onTableScroll = (event: React.UIEvent<HTMLDivElement>) => {
    ganttTableRef.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const onGanttVerticalScroll = (event: React.UIEvent<HTMLDivElement>) => {
    entityTableRef.current?.element?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
    scheduleContext?.ganttOverlayScrollAreaRef!.current?.scrollTo({
      top: event.currentTarget.scrollTop,
    });
  };

  const onGanttHorizontalScroll = (event: React.UIEvent<HTMLDivElement>) => {
    scheduleContext!.utilizationScrollAreaRef?.current?.scrollTo({
      left: event.currentTarget.scrollLeft,
    });
  };

  const saveSplitterWidth = async (
    leftPaneEl: HTMLDivElement
  ): Promise<void> => {
    try {
      const widthPercentage = calculateSplitterWidthPercentage(leftPaneEl);
      await userPreferencesService.setVerticalScheduleSplitterPosition(
        adapterContext!.service.getAdapterService(),
        widthPercentage
      );
    } catch (message) {
      notify.error({ message });
    }
  };

  const calculateSplitterWidthPercentage = (
    leftPaneEl: HTMLDivElement
  ): number => {
    const containerWidth: number =
      splitterContainerRef.current!.getBoundingClientRect().width;
    const elementWidth: number = leftPaneEl.offsetWidth;
    return (elementWidth * 100) / (containerWidth || 1);
  };

  const onSplitterDragEnd = (): void => {
    const leftPaneEl: HTMLDivElement =
      splitterContainerRef.current!.querySelectorAll(
        ".layout-pane"
      )[0] as HTMLDivElement;
    saveSplitterWidth(leftPaneEl);
    const utilizationLeftPaneEl: HTMLDivElement =
      scheduleContext?.utilizationSplitterRef?.current?.querySelectorAll(
        ".layout-pane"
      )[0] as HTMLDivElement;
    if (utilizationLeftPaneEl) {
      utilizationLeftPaneEl.style.width = leftPaneEl.offsetWidth + "px";
    }
  };

  const ganttTableColumns = React.useMemo(
    () => [
      {
        key: "gantt",
        name: "Gantt",
        cellClass: "ganttCell",
        headerRenderer: () => <ScheduleGanttHeaderRenderer />,
        formatter: (gridFormatterProps: FormatterProps<any>) => (
          <ScheduleGanttFormatter
            getTaskColor={props.getTaskColor}
            {...gridFormatterProps}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schedule._id, props.data, scheduleContext?.ganttTimeOption]
  );

  const minGanttContainerWidth: number =
    gantt.periods.length * gantt.frequencyDef.minPeriodSize;

  useEffect(() => {
    gantt.skipRenderChecksRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const isReady: boolean = splitterWidth !== null;
  return (
    <>
      {isReady && (
        <div className={styles.root} ref={splitterContainerRef}>
          <SplitterLayout
            percentage={true}
            primaryIndex={1}
            secondaryInitialSize={splitterWidth!}
            onDragEnd={onSplitterDragEnd}
          >
            <RiverDataGrid
              innerRef={entityTableRef}
              columns={props.columns}
              sortColumns={props.sortColumns}
              onSortColumnsChange={props.setSortColumns}
              onColumnResize={props.setColumnSize}
              rows={props.data}
              onRowsChange={props.onRowsChange}
              rowKeyGetter={(row) => row[uiConstants.fields.rowId] as string}
              style={{ borderRight: "none" }}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              onScroll={onTableScroll}
            />
            <div
              ref={ganttScrollAreaRef}
              className={clsx([
                styles.ganttScrollArea,
                {
                  [styles.withPaginator]:
                    scheduleContext?.currentTasksTableRef.current?.pagination,
                },
              ])}
              onScroll={onGanttHorizontalScroll}
            >
              <div
                id={SCHEDULE_TASKS_GANTT_CONTAINER_ID}
                className={styles.ganttContainer}
                style={{
                  minWidth: `${minGanttContainerWidth}px`,
                }}
                ref={(obj) => setGanttContentRef(obj)}
              >
                <ScheduleGanttOverlay
                  operationsOverlay={operationsOverlay}
                  data={props.data}
                />
                <RiverDataGrid
                  innerRef={ganttTableRef}
                  className={styles.ganttDataGrid}
                  columns={ganttTableColumns}
                  rows={props.data}
                  onRowsChange={props.onRowsChange}
                  style={{ borderLeft: "none" }}
                  onScroll={onGanttVerticalScroll}
                />
              </div>
            </div>
          </SplitterLayout>
        </div>
      )}
    </>
  );
};
