import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
} from "react";
import { IAdapterBaseline, IAdapterFolder } from "@river/interfaces";
import { IUseTable } from "../components/shared";
import { IScheduleProgressFilters } from "../services";

export interface IScheduleReportContext {
  currentSchedule: IAdapterFolder | undefined;

  progressTableRef: MutableRefObject<IUseTable | undefined>;

  selectedBaseline: IAdapterBaseline | undefined;
  setSelectedBaseline: Dispatch<SetStateAction<IAdapterBaseline | undefined>>;

  progressFiltersOpened: boolean;
  setProgressFiltersOpened: Dispatch<SetStateAction<boolean>>;

  progressSummaryFilters: IScheduleProgressFilters;
  setProgressSummaryFilters: Dispatch<SetStateAction<IScheduleProgressFilters>>;
}

export const ScheduleReportContext =
  createContext<IScheduleReportContext | null>(null);
