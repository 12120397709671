import React from "react";
import { IUseEntity } from "../hooks";
import { IUseTable } from "../components/shared";

export interface ITableContext {
  entityName?: string;
  table: IUseTable;
  entity: IUseEntity;
}

/** Context for tables to provide state and events to child elements. */
export const TableContext = React.createContext<ITableContext | null>(null);
