import React, { ReactElement, useContext } from "react";
import {
  DayPeriodDateText,
  IPeriod,
  IFrequencyDef,
  frequencyDefsByGanttTimeOptionGroupID,
} from "../../use-schedule-gantt";
import { ScheduleContext } from "../../../../../context";
import {
  IScheduleGanttTimeOption,
  ScheduleGanttTimeOptionGroupID,
} from "../../../schedule-tasks/schedule-gantt-header-options";
import styles from "./gantt-formatter-period.module.scss";
import clsx from "clsx";

interface IGanttPeriodProps {
  period: IPeriod;
}

export const getWeekDayClass = (dayIndex: number): string => {
  return [
    styles.sunday,
    styles.monday,
    styles.tuesday,
    styles.wednesday,
    styles.thursday,
    styles.friday,
    styles.saturday,
  ][dayIndex];
};

const ganttTimeOptionClassMap: {
  [key in ScheduleGanttTimeOptionGroupID]: string;
} = {
  [ScheduleGanttTimeOptionGroupID.HOURS]: styles.hourPeriod,
  [ScheduleGanttTimeOptionGroupID.DAYS]: styles.dayPeriod,
  [ScheduleGanttTimeOptionGroupID.WEEKS]: styles.weekPeriod,
  [ScheduleGanttTimeOptionGroupID.MONTHS]: styles.monthPeriod,
};

export const GanttFormatterPeriod: React.FC<IGanttPeriodProps> = (
  props
): ReactElement => {
  const scheduleContext = useContext(ScheduleContext);
  const ganttTimeOption: IScheduleGanttTimeOption =
    scheduleContext?.ganttTimeOption!;

  const { period } = props;
  const periodFrequencyDef: IFrequencyDef =
    frequencyDefsByGanttTimeOptionGroupID[ganttTimeOption.groupId];

  const periodGroupClass: string =
    ganttTimeOptionClassMap[ganttTimeOption.groupId];

  const getPeriodSpecificClasses = (): string => {
    let classNames: string[] = [];
    const isDayPeriod: boolean =
      ganttTimeOption.groupId === ScheduleGanttTimeOptionGroupID.DAYS;

    // Day Period classes
    if (isDayPeriod) {
      const dayPeriod: DayPeriodDateText = JSON.parse(period.dateText);
      const weekDayClass: string = getWeekDayClass(dayPeriod.dayIndex);
      classNames.push(weekDayClass);
    }

    return classNames.join(" ");
  };

  const periodSpecificClass: string = getPeriodSpecificClasses();

  return (
    <div
      className={clsx([styles.root, periodGroupClass, periodSpecificClass])}
      style={{
        minWidth: `${periodFrequencyDef.minPeriodSize}px`,
      }}
    />
  );
};
