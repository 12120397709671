import { EditorProps, FormatterProps } from "react-data-grid";
import {
  RiverCheckboxFormatter,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import {
  IRenderCellContentProps,
  useTableCellRenderers,
} from "../../../../../hooks";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../../context";
import { useContext } from "react";
import { IUseTable } from "../../useTable";
import jsonata from "jsonata";

interface IRiverDefaultCheckboxEditorProps {
  editorProps: EditorProps<any>;
}

export const RiverDefaultCheckboxEditor = (
  props: IRiverDefaultCheckboxEditorProps
) => {
  const { editorProps } = props;
  const { row, column } = editorProps;
  const formatterProps: FormatterProps<any> =
    editorProps as unknown as FormatterProps<any>;
  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  return (
    <TableContext.Consumer>
      {(tableContext) => {
        const id = row._id;
        const table: IUseTable = tableContext?.table!;
        const attribute = tableContext?.entity.attributesByName.get(column.key);
        const entityName: string = attribute!.entity_name;
        const attributeName: string = attribute!.attribute_name;
        const attributeValue: boolean = jsonata(attributeName).evaluate(row);
        const tooltip: string = attributeValue
          ? t("common.label:true")
          : t("common.label:false");

        const handleCheckboxChange = async () => {
          try {
            const newValue: boolean = !jsonata(attributeName).evaluate(row);
            if (attribute!.is_custom) {
              table.forceLoadingState(true);
              await adapterContext?.service
                .getAdapterService()
                .updateEntityData(entityName, id, {
                  [attributeName]: newValue,
                });
              await table.refresh();
            } else {
              editorProps.onRowChange({
                ...row,
                [attributeName]: newValue,
              });
            }
          } catch (message) {
            notify.error({ message });
          } finally {
            table.forceLoadingState(false);
          }
        };

        return (
          <>
            {renderCell({
              formatterProps,
              tooltip,
              content: (props: IRenderCellContentProps) => (
                <RiverCheckboxFormatter
                  id={`${id}_${attribute?.attribute_name!}`}
                  onChange={handleCheckboxChange}
                  checked={row[attribute?.attribute_name!]}
                  fontColor={props.fontColor}
                />
              ),
            })}
          </>
        );
      }}
    </TableContext.Consumer>
  );
};
