import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HardwareIcon from "@mui/icons-material/Hardware";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LanguageIcon from "@mui/icons-material/Language";
import {
  RiverSpinner,
  RiverDialog,
  RiverDialogActionButton,
  RiverTextInput,
  RiverCheckboxFormatter,
} from "@river/common-ui";
import {
  AdapterRoleDto,
  IAdapterRole,
  IEntityObject,
  IEntityDefinition,
  AdapterRoleModuleDto,
} from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
  RiverCheckmarkFormatter,
} from "../../shared";
import { fetchHelpers, uiConstants } from "../../../helpers";
import { Column } from "react-data-grid";
import { IUseEntity, useEntity, useTableCellRenderers } from "../../../hooks";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../../context";
import { Constants } from "@river/constants";
import {
  CalendarIcon,
  UserPlusIcon,
  UsersIcon,
  FolderForwardIcon,
  SegmentIcon,
  WrenchIcon,
} from "../../../icons";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import TimelineIcon from "@mui/icons-material/Timeline";
import { AvailableModulesGridHeader } from "./available-modules-grid-header";
import { AssignedModulesGridHeader } from "./assigned-modules-grid-header";
import { ModuleKey } from "../../sidebar-menu";
import styles from "./edit-role-dialog.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";
import { useAllowedAction } from "../../protected-action";
import { RolesAction } from "../roles";

const moduleIcons: { [key in ModuleKey]: ReactElement } = {
  [ModuleKey.BACKLOG]: <FolderForwardIcon className={styles.moduleIcon} />,
  [ModuleKey.PLANNER_BACKLOG]: (
    <FolderForwardIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.TIMELINES]: <TimelineIcon className={styles.moduleIcon} />,
  [ModuleKey.SCHEDULES]: <CalendarIcon className={styles.moduleIcon} />,
  [ModuleKey.LANGUAGES]: <LanguageIcon className={styles.moduleIcon} />,
  [ModuleKey.EXECUTION]: <HardwareIcon className={styles.moduleIcon} />,
  [ModuleKey.VALIDATION_DASHBOARD]: (
    <NotificationsNoneOutlinedIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.EXTERNAL_RESOURCES]: <WrenchIcon className={styles.moduleIcon} />,
  [ModuleKey.SHIFTS]: <SegmentIcon className={styles.moduleIcon} />,
  [ModuleKey.CALENDARS]: <CalendarIcon className={styles.moduleIcon} />,
  [ModuleKey.AVAILABILITY]: (
    <EventAvailableIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.CRON_TASKS]: <CalendarIcon className={styles.moduleIcon} />,
  [ModuleKey.CREWS]: <GroupsIcon className={styles.moduleIcon} />,
  [ModuleKey.SUPERVISOR_CREWS]: <GroupsIcon className={styles.moduleIcon} />,
  [ModuleKey.ROLES]: <UsersIcon className={styles.moduleIcon} />,
  [ModuleKey.USERS]: <UserPlusIcon className={styles.moduleIcon} />,
  [ModuleKey.DATA_DICTIONARY]: <SettingsIcon className={styles.moduleIcon} />,
  [ModuleKey.WORK_REQUESTS]: (
    <NotificationsNoneOutlinedIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.JOB_PRIORITY_RULES]: (
    <SettingsIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.ATTACHMENT_SELECTION_RULES]: (
    <SettingsIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.VALIDATION_RULES]: <SettingsIcon className={styles.moduleIcon} />,
  [ModuleKey.VISUAL_INDICATORS_RULES]: (
    <SettingsIcon className={styles.moduleIcon} />
  ),

  [ModuleKey.SUPERVISOR_SCHEDULES]: (
    <SettingsIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.REASON_CODE_LOOKUPS]: (
    <SettingsIcon className={styles.moduleIcon} />
  ),
  [ModuleKey.SCHEDULE_REPORT]: <AssessmentIcon className={styles.moduleIcon} />,
};

interface IEditRoleDialog {
  open: boolean;
  role: IAdapterRole;
  onClose: (success: boolean) => void;
}

export const EditRoleDialog: React.FC<IEditRoleDialog> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  const createBlankRoleDto = (): AdapterRoleDto => {
    const roleDto = new AdapterRoleDto();
    Object.assign(roleDto, {
      role: "",
      description: "",
    });
    return roleDto;
  };
  const [roleDto, setRoleDto] = useState<AdapterRoleDto>(createBlankRoleDto());
  const roleRef = useRef<IAdapterRole>();
  const actionsTableRef = useRef<IUseTable>();
  const isActionAllowed = useAllowedAction();

  const availableModuleEntityDef: IEntityDefinition = {
    entity: {
      _id: "",
      adapter_type: "sap",
      entity_name: "available_module",
    },
    attributes: [
      {
        _id: "module",
        adapter_type: "sap",
        entity_name: "available_module",
        attribute_name: "module",
        description: "Module",
        data_type: Constants.data_type.string,
        cardinality: "one",
        is_persistent: true,
      },
      {
        _id: "description",
        adapter_type: "sap",
        entity_name: "available_module",
        attribute_name: "description",
        description: "Description",
        data_type: Constants.data_type.string,
        cardinality: "one",
        is_persistent: true,
      },
    ],
    relations: [],
    indexes: [],
  };

  const moduleEntity: IUseEntity = useEntity({
    entityName: "available_module",
    definition: availableModuleEntityDef,
  });

  const actionEntity: IUseEntity = useEntity({
    entityName: "role_module_action",
  });

  useEffect(() => {
    const roleDtoState: AdapterRoleDto = createBlankRoleDto();
    if (props.role) {
      const { role, description } = props.role;
      Object.assign(roleDtoState, {
        role,
        description,
      });
    }
    setRoleDto(roleDtoState);
    roleRef.current = props.role;
  }, [props.role]);

  const assignModules = async (moduleIds: string[]): Promise<void> => {
    const roleModuleDto: AdapterRoleModuleDto = { module: moduleIds };
    await adapterContext!.service
      .getAdapterService()
      .createRoleModule(roleRef.current!._id, roleModuleDto);
    refreshTables();
  };

  const unassignModules = async (moduleIds: string[]): Promise<void> => {
    const roleModuleDto: AdapterRoleModuleDto = { module: moduleIds };

    await adapterContext!.service
      .getAdapterService()
      .deleteRoleModules(roleRef.current!._id, roleModuleDto);
    refreshTables();
  };

  const handleChangeActionEnabled = async (
    action: any,
    checked: boolean
  ): Promise<void> => {
    action.enabled = checked;
    await adapterContext!.service
      .getAdapterService()
      .updateRoleModuleAction(roleRef.current!._id, action);
    actionsTableRef.current!.refresh();
  };

  const refreshTables = (): void => {
    availableTable.refresh();
    assignedTable.refresh();
    actionsTable.refresh();
  };

  const availableModulesColumns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "module",
      name: t("module.roles:available_module.module"),
      width: 211,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.moduleCell}>
                {moduleIcons[formatterProps.row.module as ModuleKey]}
                <div className={styles.moduleName}>
                  {renderCellText({ value: formatterProps.row.module })}
                </div>
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "description",
      name: t("module.roles:available_module.description"),
      width: 242,
    },
  ];

  const assignedModulesColumns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "module",
      name: t("entity.role_module_action:role_module_action.module"),
      width: 211,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.moduleCell}>
                {moduleIcons[formatterProps.row.module as ModuleKey]}
                <div className={styles.moduleName}>
                  {renderCellText({ value: formatterProps.row.module })}
                </div>
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "description",
      name: t("entity.role_module_action:role_module_action.description"),
      width: 243,
    },
  ];

  const actionColumns: Column<any>[] = [
    {
      key: "spacer",
      name: "",
      width: 34,
      minWidth: 34,
      maxWidth: 34,
      resizable: false,
      formatter: () => <></>,
      headerRenderer: () => <></>,
    },
    {
      key: "module",
      name: t("entity.role_module_action:role_module_action.module"),
      width: 180,
      resizable: true,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.moduleCell}>
                {moduleIcons[formatterProps.row.module as ModuleKey]}
                <div className={styles.moduleName}>
                  {renderCellText({ value: formatterProps.row.module })}
                </div>
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "action",
      name: t("entity.role_module_action:role_module_action.action"),
      width: 200,
      resizable: true,
    },
    {
      key: "description",
      name: t("entity.role_module_action:role_module_action.description"),
      width: 214,
      resizable: true,
    },
    {
      key: "enabled",
      name: t("entity.role_module_action:role_module_action.enabled"),
      width: 360,
      resizable: true,
      formatter: (formatterProps) => {
        const isUpdateAllowed: boolean = isActionAllowed(
          ModuleKey.ROLES,
          RolesAction.UPDATE
        );
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={styles.permissionCell}>
                  {isUpdateAllowed && (
                    <>
                      <RiverCheckboxFormatter
                        id="enabled"
                        className={styles.booleanCellFormatter}
                        checked={formatterProps.row.enabled}
                        onChange={(e) => {
                          handleChangeActionEnabled(
                            formatterProps.row,
                            e.currentTarget.checked
                          );
                        }}
                      />
                    </>
                  )}
                  {!isUpdateAllowed && (
                    <RiverCheckmarkFormatter
                      className={styles.booleanCheckmarkFormatter}
                      checked={formatterProps.row.enabled}
                    />
                  )}
                </div>
              ),
            })}
          </>
        );
      },
    },
  ];

  const fetchAvailableModules = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    let modules = await adapterContext!.service
      .getAdapterService()
      .fetchAvailableModules(
        roleRef.current!._id,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return modules.map((x) => {
      return { ...x, status: x.status.code } as IEntityObject;
    });
  };

  const fetchAssignedModules = async (fetchProps: ITableFetchFunctionProps) => {
    let modules = await adapterContext!.service
      .getAdapterService()
      .fetchRoleModules(
        roleRef.current!._id,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return modules.map((x) => {
      return { ...x } as IEntityObject;
    });
  };

  const fetchActions = async (fetchProps: ITableFetchFunctionProps) => {
    let actions = await adapterContext!.service
      .getAdapterService()
      .fetchRoleModuleActions(
        roleRef.current!._id,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return actions.map((x) => {
      return { ...x } as IEntityObject;
    });
  };

  const availableTable: IUseTable = useTable({
    columns: availableModulesColumns,
    enableColumnReorder: false,
    fetchFunction: fetchAvailableModules,
    fetchOn: props.open,
    infiniteScrolling: true,
  });

  const assignedTable: IUseTable = useTable({
    columns: assignedModulesColumns,
    enableColumnReorder: false,
    fetchFunction: fetchAssignedModules,
    fetchOn: props.open,
    infiniteScrolling: true,
  });

  const actionsTable: IUseTable = useTable({
    columns: actionColumns,
    fetchFunction: fetchActions,
    fetchOn: props.open,
    infiniteScrolling: true,
  });
  actionsTableRef.current = actionsTable;

  const closeDialog = (success: boolean): void => {
    props.onClose(success);
  };

  const handleClose = () => {
    const success = false;
    closeDialog(success);
  };

  const assignSelectedModules = async (): Promise<any> => {
    const rowIds: string[] = Array.from(availableTable.selectedRowIds);
    const moduleIds: string[] = availableTable.entities
      .filter((row) => rowIds.indexOf(row._id as string) !== -1)
      .map((row) => row.module as string);
    if (moduleIds.length === 0) return;
    await assignModules(moduleIds);
  };

  const unassignSelectedModules = async (): Promise<void> => {
    const rowIds: string[] = Array.from(assignedTable.selectedRowIds);
    if (rowIds.length === 0) return;
    const modules: string[] = assignedTable.entities
      .filter((row) => rowIds.indexOf(row._id as string) !== -1)
      .map((row) => row.module as string);

    await unassignModules(modules);
  };

  const isLoadingAssignedModules: boolean = assignedTable.isLoading || !site;
  const isLoadingAvailableModules: boolean = availableTable.isLoading || !site;
  const isLoadingActions: boolean = availableTable.isLoading || !site;
  const isLoading =
    isLoadingAssignedModules || isLoadingAvailableModules || isLoadingActions;

  return (
    <RiverDialog
      title={t("module.roles:dialog.edit_role.title")}
      open={props.open}
      onClose={handleClose}
      actionsContent={
        <RiverDialogActionButton
          onClick={handleClose}
          text={t("common.button:close")}
        />
      }
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      showActionsDivider={false}
      dialogProps={{
        maxWidth: false,
      }}
    >
      <RiverSpinner show={isLoading} />
      <div className={styles.roleDetailsFields}>
        <RiverTextInput
          id={"role"}
          label={t("entity.role:role.role")}
          value={roleDto.role}
          className={styles.name}
          disabled={true}
        />
        <RiverTextInput
          id={"description"}
          label={t("entity.role:role.description")}
          value={roleDto.description}
          className={styles.description}
          disabled={true}
        />
      </div>
      <div className={styles.modules}>
        <div className={clsx([styles.tableContainer, styles.availableModules])}>
          <TableContext.Provider
            value={{
              table: availableTable,
              entity: moduleEntity,
            }}
          >
            <AvailableModulesGridHeader
              assignSelectedModules={assignSelectedModules}
            />
            <RiverDataGrid
              columns={availableTable.columns}
              rows={availableTable.entities}
              rowKeyGetter={(row) => row[uiConstants.fields._id]}
              className={clsx([styles.dataGrid])}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              selectedRows={availableTable.selectedRowIds}
              onSelectedRowsChange={(rowKeys) =>
                availableTable.setSelectedRowIds(rowKeys)
              }
              sortColumns={availableTable.sortColumns}
              onSortColumnsChange={(e) => {
                availableTable.setSortColumns(e);
              }}
            />
          </TableContext.Provider>
        </div>
        <div className={clsx([styles.tableContainer, styles.assignedModules])}>
          <TableContext.Provider
            value={{
              table: assignedTable,
              entity: moduleEntity,
            }}
          >
            <AssignedModulesGridHeader
              unassignSelectedModules={unassignSelectedModules}
            />
            <RiverDataGrid
              columns={assignedTable.columns}
              rows={assignedTable.entities}
              rowKeyGetter={(row) => row[uiConstants.fields._id]}
              className={clsx([styles.dataGrid])}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              selectedRows={assignedTable.selectedRowIds}
              onSelectedRowsChange={(rowKeys) =>
                assignedTable.setSelectedRowIds(rowKeys)
              }
              sortColumns={assignedTable.sortColumns}
              onSortColumnsChange={(e) => {
                assignedTable.setSortColumns(e);
              }}
            />
          </TableContext.Provider>
        </div>
      </div>
      <div
        className={clsx([styles.tableContainer, styles.actionAccessibility])}
      >
        <div className={styles.tableTitle}>
          {t("module.roles:label.action_accessibility")}
        </div>
        <TableContext.Provider
          value={{
            table: actionsTable,
            entity: actionEntity,
          }}
        >
          <RiverDataGrid
            disableSelectAll={true}
            columns={actionsTable.columns}
            rows={actionsTable.entities}
            rowKeyGetter={(row) => row[uiConstants.fields._id]}
            className={clsx([styles.dataGrid])}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onSelectedRowsChange={() => {}}
            sortColumns={actionsTable.sortColumns}
            onSortColumnsChange={(e) => {
              actionsTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </div>
    </RiverDialog>
  );
};
