import { useContext } from "react";
import {
  IUseRiverAsyncProgress,
  IUseTable,
  useRiverAsyncProgress,
} from "../../../shared";
import { useTranslation } from "react-i18next";
import { useNotification } from "@river/common-ui";
import { AdapterUiContext } from "../../../../context";
import { AdapterService } from "../../../../services";
import {
  AdapterAvailabilityGenerationDto,
  IAdapterAsyncAction,
} from "@river/interfaces";

interface IUseAsyncGenerateAvailabilityProps {
  table: IUseTable;
}

export const useAsyncGenerateAvailability = (
  props: IUseAsyncGenerateAvailabilityProps
) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const adapterContext = useContext(AdapterUiContext);
  const table = props.table;

  const generateAvailabilityProgress: IUseRiverAsyncProgress =
    useRiverAsyncProgress({
      title: t("module.availability:dialog.generate_availability.title"),
      onSuccess: () =>
        notify.success(
          t("module.availability:dialog.generate_availability_success")
        ),
      onComplete: () => {
        table?.refresh();
      },
    });

  const doGenerateAvailability = async (
    availabilityDto: AdapterAvailabilityGenerationDto
  ): Promise<void> => {
    const adapter: AdapterService = adapterContext!.service.getAdapterService();

    try {
      const asyncAction: IAdapterAsyncAction =
        await adapter.generateAvailability(availabilityDto);
      generateAvailabilityProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    generateAvailabilityProgress,
    doGenerateAvailability,
  };
};

export type IUseAsyncGenerateAvailability = ReturnType<
  typeof useAsyncGenerateAvailability
>;
