import { FC } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { helpers } from "../../../../../helpers";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useTranslation } from "@river/common-ui";
import styles from "./schedule-gantt-toggle.module.scss";

export enum ScheduleTasksViewId {
  LIST = "list",
  GANTT = "gantt",
}
export const ScheduleTasksViewURLParamName = "view";
export const DEFAULT_SCHEDULE_TASKS_VIEW = ScheduleTasksViewId.GANTT;

export const ScheduleGanttToggle: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedView =
    searchParams.get(ScheduleTasksViewURLParamName) ||
    DEFAULT_SCHEDULE_TASKS_VIEW;

  function handleOnIconClick(viewId: ScheduleTasksViewId) {
    navigate(
      helpers.replaceOrSetUrlParam(
        location.pathname,
        searchParams,
        ScheduleTasksViewURLParamName,
        viewId
      )
    );
  }

  return (
    <div className={styles.root}>
      {selectedView === ScheduleTasksViewId.GANTT && (
        <TableChartOutlinedIcon
          className={styles.icon}
          onClick={() => handleOnIconClick(ScheduleTasksViewId.LIST)}
          fontSize="small"
          titleAccess={t("module.schedule:gantt_toggle.list")}
        />
      )}
      {selectedView === ScheduleTasksViewId.LIST && (
        <AccountTreeOutlinedIcon
          className={styles.icon}
          onClick={() => handleOnIconClick(ScheduleTasksViewId.GANTT)}
          fontSize="small"
          titleAccess={t("module.schedule:gantt_toggle.gantt")}
        />
      )}
    </div>
  );
};
