import React from "react";
import {
  IScheduleComplianceFilters,
  IScheduleProgressFilters,
} from "../services";

export interface IScheduleUtilizationContext {
  progressSummaryFilters: IScheduleProgressFilters;
  setProgressSummaryFilters: React.Dispatch<
    React.SetStateAction<IScheduleProgressFilters>
  >;
  complianceSummaryFilters: IScheduleComplianceFilters;
  setComplianceSummaryFilters: React.Dispatch<
    React.SetStateAction<IScheduleComplianceFilters>
  >;

  progressFiltersOpened: boolean;
  setProgressFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>;

  complianceFiltersOpened: boolean;
  setComplianceFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScheduleUtilizationContext =
  React.createContext<IScheduleUtilizationContext | null>(null);
