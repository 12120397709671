import { ILookupDefinition } from "../lookup-dialog";
import {
  IAdapterUserContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { useEntityHelpers } from "../../../../../helpers";
import { useRiverSelectColumn } from "../../../river-data-grid";

export function useOperationsLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const entityHelpers = useEntityHelpers();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    return adapterContext?.service
      .getOperationsUiService()
      .useOperationsLookup({
        userContext,
        adapterContext,
        t,
        entityHelpers,
        RiverSelectColumn,
      })();
  };
}
export type IUseOperationsLookupDef = ReturnType<typeof useOperationsLookupDef>;
