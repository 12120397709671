import { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../context";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { BacklogTabId, BacklogUiService } from "../backlog-ui-service";
import { useShowTabAction } from "../../shared";
import { IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";

export const useShowResourcesTabAction = () => {
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext!.service.getBacklogUiService();

  const isEnabled = (): boolean => {
    let enabled: boolean = false;
    const workOrdersTable: IUseTable =
      uiService.tableRefs[BacklogTabId.WORKORDERS]!;
    const operationsTable: IUseTable =
      uiService.tableRefs[BacklogTabId.OPERATIONS]!;
    enabled =
      workOrdersTable?.selectedRowIds.size > 0 ||
      operationsTable?.selectedRowIds.size > 0;
    return enabled;
  };

  const {
    renderShowTabAction: renderShowResourcesTabAction,
    showTabAction: showResourcesTabAction,
  } = useShowTabAction({
    title: t("module.backlog:action_label.show_resources_tab"),
    icon: DeviceHubIcon,
    enabled: isEnabled(),
    tabKey: BacklogTabId.RESOURCES,
    onClick: () => tabContext?.setSelectedTab(BacklogTabId.RESOURCES),
  });

  return {
    renderShowResourcesTabAction,
    showResourcesTabAction,
  };
};
