import React, { ReactElement } from "react";
import { RiverSpinner } from "@river/common-ui";
import styles from "./splash-screen.module.scss";

export const SplashScreen: React.FC = (): ReactElement => (
  <div className={styles.root}>
    <RiverSpinner show={true} className={styles.spinner} />
    <div className={styles.message}>
      Please wait while the application loads
    </div>
  </div>
);
