import React, { ReactElement, useState, useContext, useEffect } from "react";
import { ScheduleContext } from "../../../../../context";
import {
  ScheduleGanttTimeOptionGroupID,
  ScheduleGanttTimeOptionID,
  IScheduleGanttTimeOption,
  IScheduleGanttTimeOptionGroup,
} from "./types";
import { useScheduleGanttTime } from "./use-schedule-gantt-time";
import { useRiverCustomPopup } from "../../../../shared";
import { Divider, FormControlLabel, RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import styles from "./schedule-gantt-time-options.module.scss";

const DEFAULT_GANTT_TIME_OPTION = ScheduleGanttTimeOptionID._7DAYS;

export const ScheduleGanttTimeOptions: React.FC = (): ReactElement => {
  const scheduleContext = useContext(ScheduleContext);

  const { ganttTimeOptions, ganttTimeOptionGroups } = useScheduleGanttTime();
  const [timeOption, setTimeOption] = useState<
    IScheduleGanttTimeOption | undefined
  >(scheduleContext?.ganttTimeOption!);
  const [timeOptionGroupID, setTimeOptionGroupID] =
    useState<ScheduleGanttTimeOptionGroupID>(
      scheduleContext?.ganttTimeOption!?.groupId ||
        ScheduleGanttTimeOptionGroupID.DAYS
    );

  useEffect(() => {
    setTimeOption(scheduleContext?.ganttTimeOption);
  }, [scheduleContext?.ganttTimeOption]);

  const optionGroups: IScheduleGanttTimeOptionGroup[] = [
    ganttTimeOptionGroups[ScheduleGanttTimeOptionGroupID.HOURS],
    ganttTimeOptionGroups[ScheduleGanttTimeOptionGroupID.DAYS],
    ganttTimeOptionGroups[ScheduleGanttTimeOptionGroupID.WEEKS],
    ganttTimeOptionGroups[ScheduleGanttTimeOptionGroupID.MONTHS],
  ];
  useEffect(() => {
    scheduleContext?.setGanttTimeOption(
      ganttTimeOptions[DEFAULT_GANTT_TIME_OPTION]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (): ReactElement => (
    <div className={styles.content}>
      <div className={styles.section}>
        <div className={styles.body}>
          <div className={styles.items}>
            <RadioGroup
              value={timeOptionGroupID}
              onChange={(event, value) => {
                setTimeOptionGroupID(value as ScheduleGanttTimeOptionGroupID);
                setTimeOption(undefined);
              }}
            >
              {optionGroups.map((group, index) => (
                <div key={index} className={styles.item}>
                  <FormControlLabel
                    value={group.id}
                    control={
                      <Radio
                        classes={{ root: styles.radioRoot }}
                        color={"primary"}
                      />
                    }
                    label={group.label}
                    classes={{
                      root: styles.labelRoot,
                      label: styles.label,
                    }}
                  />
                </div>
              ))}
            </RadioGroup>
          </div>
        </div>
      </div>
      <Divider className={styles.divider} flexItem />
      <div className={styles.section}>
        <div className={styles.body}>
          <div className={styles.items}>
            <RadioGroup
              value={timeOption?.id || null}
              onChange={(event, value) => {
                const option: IScheduleGanttTimeOption =
                  ganttTimeOptions[value as ScheduleGanttTimeOptionID];
                scheduleContext?.setGanttTimeOption(option);
                closePopup();
              }}
            >
              {ganttTimeOptionGroups[timeOptionGroupID].options.map(
                (option, index) => (
                  <div key={index} className={styles.item}>
                    <FormControlLabel
                      value={option.id}
                      control={
                        <Radio
                          classes={{ root: styles.radioRoot }}
                          color={"primary"}
                        />
                      }
                      label={option.label}
                      classes={{
                        root: styles.labelRoot,
                        label: styles.label,
                      }}
                    />
                  </div>
                )
              )}
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  );

  const riverCustomPopup = useRiverCustomPopup({
    toggleLabel: scheduleContext?.ganttTimeOption?.label,
    content: renderContent(),
    classes: { toggle: styles.toggle },
  });
  const { closePopup } = riverCustomPopup;

  return <>{riverCustomPopup.render()}</>;
};
