import { FC, useContext, useState, ComponentType } from "react";
import { SupervisorSchedules } from "./supervisor-schedules";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";
import { AdapterUiContext } from "../../context";
import { SupervisorSchedulesUiService } from "../../services";

const ModuleWrapper: FC<ITranslationProps> = (props) => (
  <>{props.tReady && <SupervisorSchedules />}</>
);

export const SupervisorSchedulesModule: FC = (props) => {
  const adapterContext = useContext(AdapterUiContext);
  const uiService: SupervisorSchedulesUiService =
    adapterContext!.service.getSupervisorSchedulesUiService();
  const namespaces = uiService.getI18nNamespaces();

  const [Component] = useState<ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
