import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { entityDef as definition } from "./defs/entity-def";
import { IEntity, EntityDto } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const useEntityForm = (props: {
  entity: IEntity | null;
  onCreate?: (entity: IEntity) => void;
  onUpdate?: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const isCustomEntity: boolean = !props.entity || !!props.entity?.is_custom;

  return useRiverForm<EntityDto, IEntity, Object>({
    initialDto: Object.assign(new EntityDto(), {
      entity_name: "",
      description: "",
      collection_name: "",
      external_url: "",
    }),
    entityName: "entity",
    entityDefinition: definition,
    instanceToEdit: props.entity,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: EntityDto): Promise<IEntity> => {
      return await adapterContext!.service
        .getAdapterService()
        .createEntityDefinition(dto);
    },
    update: async (dto: EntityDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateEntityDefinition(props.entity?.entity_name!, dto);
    },
    labels: {
      entity_name: t("module.data_dictionary:label.entity_name"),
      description: t("module.data_dictionary:label.description"),
      collection_name: t("module.data_dictionary:label.collection_name"),
      is_readonly: t("module.data_dictionary:label.is_readonly"),
      is_schema: t("module.data_dictionary:label.is_schema"),
      add_timestamps: t("module.data_dictionary:label.add_timestamps"),
      add_id: t("module.data_dictionary:label.add_id"),
    },
    readOnly: !isCustomEntity,
  });
};
