import { Column } from "react-data-grid";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { TableContext } from "../../../../context";
import dataGridStyles from "../../../shared/river-data-grid/river-data-grid.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../hooks";
import { useRiverSelectColumn } from "../../../shared";

export function useRelationColumns() {
  const [relationColumnsDef, setRelationColumnsDef] = useState<Column<any>[]>(
    [] as any[]
  );
  let columns: Column<any>[];

  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  const relationColumns = (
    onRelationNameClick: (relation: IEntityObject) => void
  ): Column<any>[] => {
    columns = [
      RiverSelectColumn,
      {
        key: "relation_name",
        name: t("module.data_dictionary:relation_entity.relation_name"),
        formatter: (formatterProps) => {
          return (
            <TableContext.Consumer>
              {(tableContext) => (
                <>
                  {renderCell({
                    formatterProps,
                    content: (
                      <div
                        className={dataGridStyles.dataGridLink}
                        onClick={() => {
                          tableContext?.table.setSelectedRowIds(new Set());
                          onRelationNameClick(formatterProps.row);
                        }}
                      >
                        {renderCellText({ formatterProps })}
                      </div>
                    ),
                  })}
                </>
              )}
            </TableContext.Consumer>
          );
        },
      },
      {
        key: "child_entity_name",
        name: t("module.data_dictionary:relation_entity.child_entity_name"),
      },
      {
        key: "relation_type",
        name: t("module.data_dictionary:relation_entity.relation_type"),
      },
      {
        key: "lookup",
        name: t("module.data_dictionary:relation_entity.lookup"),
      },
    ];
    return relationColumnsDef;
  };
  useEffect(() => {
    setRelationColumnsDef(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { relationColumns };
}

export type IUseRelationDef = ReturnType<typeof useRelationColumns>;

// relations meta entity def
export const relationEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "",
    entity_name: "relations_meta",
  },
  attributes: [
    {
      _id: "relation_name",
      adapter_type: "",
      entity_name: "relations_meta",
      attribute_name: "relation_name",
      data_type: Constants.data_type.string,
      description: "Relation",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "relation_type",
      adapter_type: "",
      entity_name: "relations_meta",
      attribute_name: "relation_type",
      data_type: Constants.data_type.string,
      description: "Relation Type",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "lookup",
      adapter_type: "",
      entity_name: "relations_meta",
      attribute_name: "lookup",
      data_type: Constants.data_type.string,
      description: "Lookup",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
