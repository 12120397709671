import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IScheduleContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { ScheduleUiService, ScheduleTasksTabId } from "./schedule-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  IAdapterFolder,
  QueryAttributeDto,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import { AdapterService } from "../adapter.service";
import { getFolderQueryGroup } from "@river/util";
import { SitesUiService } from "../sites-ui.service";

interface IUseScheduleInstancesProps {
  scheduleContext: IScheduleContext;
}

export const useScheduleInstances = (
  props: IUseScheduleInstancesProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const instancesTabId: ScheduleTasksTabId = ScheduleTasksTabId.INSTANCES;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const scheduleUiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const sitesUiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const entityHelpers = useEntityHelpers();
  const userContext: IAdapterUserContext = useContext(AdapterUserContext)!;
  const organizationId: string | number =
    (
      userContext?.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id || 0;

  const scheduleContext: IScheduleContext = props.scheduleContext;
  const currentSchedule: IAdapterFolder = scheduleContext.currentSchedule!;

  const currentTab: ScheduleTasksTabId = scheduleUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getInitialQuery = (): QueryAttributeGroupDto => {
    const organizationQueryAttribute: QueryAttributeDto = {
      attribute_name: sitesUiService.getSiteKeyColumn(),
      attribute_value: {
        operator: "$eq",
        value: organizationId,
      },
    };
    return {
      $and: [
        getFolderQueryGroup(currentSchedule, ""),
        organizationQueryAttribute,
      ],
    };
  };

  const fetchInstances = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getInitialQuery(),
      }),
      $unwind: [
        "WorkOrderOperationResource",
        "WorkOrderOperationResource.WorkOrderOperationResourceInstance",
        "WorkOrderOperationResource.WorkOrderOperationResourceInstance.__folder",
      ],
    };

    table.setLastRanQueryDto(query);

    const instances = await adapterService.searchEntityData("operation", query);
    const instanceRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.resource_instance
    );

    instances.forEach((inst) => {
      try {
        entityHelpers.setAttributeValue(
          inst,
          uiConstants.fields.rowType,
          uiConstants.rowType.resource_instance
        );
        entityHelpers.setAttributeValue(
          inst,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(inst, instanceRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing instance _id: ${inst._id}`);
        console.error(e);
      }
    });
    return instances;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `schedule.instances`,
    columns: scheduleUiService.getDefaultInstancesColumns()(),
    fetchFunction: fetchInstances,
    dependencies: [!!site],
    fetchOn: currentTab === instancesTabId,
    initialSimpleFilters:
      scheduleUiService.getInitialTabSimpleFilters(instancesTabId),
    initialQuery: scheduleUiService.getInitialTabQuery(instancesTabId),
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    keepSelection: true,
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      scheduleUiService.taskInitialFiltersOverrides[instancesTabId] = null;
      scheduleUiService.taskInitialQueryOverrides[instancesTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
