import React, { ReactElement, useContext } from "react";
import { useFormField } from "@river/common-ui/dist/hooks";
import Editor, { Monaco } from "@monaco-editor/react";
import { InputLabel } from "@mui/material";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { IFieldValidationErrors } from "@river/common-ui";
import styles from "./river-code-input.module.scss";

interface IRiverCodeInputProps {
  id: string;
  label?: string;
  value?: string | number;
  validationErrors?: IFieldValidationErrors;
  editorLibModel?: string;
  onChange?: (value: string) => void;
  entity_name?: string;
}

export const RiverCodeInput: React.FC<IRiverCodeInputProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState = useContext(AdapterUiContext)!;

  const { label, value, form, id } = useFormField(props);

  const onChange = (value: string | undefined): void => {
    if (props.onChange) {
      props.onChange(value!);
    } else {
      form!.getSimpleChangeHandler(id)(value!);
    }
  };

  const handleEditorOnMount = (editor: any) => {
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 500);
  };

  const getLibModel = async () => {
    const { script } = await adapterContext
      ?.service!.getAdapterService()
      .getMonacoEditorLib(props.entity_name);
    return script;
  };

  const handleEditorWillMount = (monaco: Monaco) => {
    // testLib is a string with all the typings and definitios
    monaco.editor.defineTheme("one-river", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editorLineNumber.foreground": "#C1C1C2",
        "editorGutter.background": "#FBFAFD",
      },
    });

    if (props.editorLibModel) {
      monaco.languages.typescript.javascriptDefaults.addExtraLib(
        props.editorLibModel
      );
    } else {
      getLibModel()?.then((data: string) => {
        monaco.languages.typescript.javascriptDefaults.addExtraLib(data);
      });
    }
  };

  return (
    <>
      <InputLabel className={styles.editorLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Editor
        height="200px"
        beforeMount={handleEditorWillMount}
        onMount={handleEditorOnMount}
        onChange={onChange}
        theme={"one-river"}
        options={{
          autoIndent: true,
          formatOnPaste: true,
          formatOnType: true,
          foldingStrategy: "indentation",
          minimap: { enabled: false },
        }}
        defaultLanguage="javascript"
        defaultValue={value as string}
      />
    </>
  );
};
