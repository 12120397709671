import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { RelationDto, IRelation } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const useEntityRelationForm = (props: {
  entityName: string;
  relation: IRelation | null;
  onCreate?: (relation: IRelation) => void;
  onUpdate?: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const isCustomRelation: boolean =
    !props.relation || !!props.relation?.is_custom;

  return useRiverForm<RelationDto, IRelation, Object>({
    initialDto: Object.assign(new RelationDto(), {
      relation_name: "",
      relation_type: "",
      child_entity_name: "",
      lookup: "",
    }),
    entityName: "relation",
    instanceToEdit: props.relation,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: RelationDto): Promise<IRelation> => {
      return await adapterContext!.service
        .getAdapterService()
        .createEntityRelationDefinition(props.entityName, dto);
    },
    update: async (dto: RelationDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateEntityRelationDefinition(
          props.relation!.entity_name!,
          props.relation!._id,
          dto
        );
    },
    labels: {
      relation_name: t("module.data_dictionary:relation_entity.relation_name"),
      child_entity_name: t(
        "module.data_dictionary:relation_entity.child_entity_name"
      ),
      relation_type: t("module.data_dictionary:relation_entity.relation_type"),
      lookup: t("module.data_dictionary:relation_entity.lookup"),
    },
    readOnly: !isCustomRelation,
  });
};
