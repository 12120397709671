import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GenericAction,
  LookupType,
  RiverLookup,
} from "../../../../components/shared";
import {
  IValidationErrors,
  RiverDialog,
  RiverSpinner,
  useTranslation,
} from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  AdapterService,
  ObjectType,
  ScheduleUiService,
  TableUiService,
} from "../../../index";
import { useNotification } from "@river/common-ui";
import { uiConstants, useGridActionHelpers } from "../../../../helpers";
import { IAdapterCalendar, IEntityObject } from "@river/interfaces";
import { RiverFormInstance } from "../../../../hooks";
import {
  useCalendarAssignForm,
  ICalendarAssignForm,
} from "./use-calendar-asign-form";
import { debounce } from "ts-debounce";
import { useParams } from "react-router";

export const useAssignCalendarAction = () => {
  const { t } = useTranslation();
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const entityName: string = uiService.getUserStatusResponseEntityName();
  const { withSanitizedProps } = useGridActionHelpers();

  const [selectedCalendar, setSelectedCalendar] =
    useState<IAdapterCalendar | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("module.schedule:dialog.assing_calendar.button");

  const form: RiverFormInstance = useCalendarAssignForm({
    selectedCalendar,
  });

  const fields: ICalendarAssignForm = form.standalone as ICalendarAssignForm;

  const {
    onStandalonePropertyChange,
    resetForm,
    validateForm,
    validationErrors,
    validateStandaloneField,
    setStandaloneFields,
  } = form;

  useEffect(() => {
    if (fields.calendarName) {
      validateStandaloneField("calendarName");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendar]);

  // fetch calendar object with calendar id or calendar name
  const fetchCalendar = async (
    attribute_value: string,
    attribute_name: string
  ): Promise<IAdapterCalendar> => {
    const calendars: IAdapterCalendar[] = await adapterContext!.service
      .getAdapterService()
      .fetchCalendars({
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return calendars[0] as IAdapterCalendar;
  };
  const fetchCalendarByName = async (calendarName: string): Promise<void> => {
    try {
      const calendar: IAdapterCalendar = await fetchCalendar(
        calendarName,
        "calendar"
      );
      setSelectedCalendar(calendar);
      validateStandaloneField("calendarName", calendarName);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchCalendarByName = useCallback(
    debounce((text) => {
      fetchCalendarByName(text);
    }, 600),
    [validationErrors]
  );

  const onClick = (): void => {
    tableContext?.table.forceLoadingState(true);
    adapterService
      .getUserStatusResponse({
        entity_name: entityName,
        entity_ids: Array.from(tableContext?.table.selectedRowIds!),
      })
      .then((e) => {
        tableContext?.table.forceLoadingState(false);
        setDialogOpened(true);
      })
      .catch((message) => {
        tableContext?.table.forceLoadingState(false);
        notify.error({ message });
      });
  };

  const closeDialog = (success: boolean): void => {
    resetForm();
    setDialogOpened(success);
  };

  const assignCalendar = async (): Promise<void> => {
    const errors: IValidationErrors = await validateForm();

    if (errors.list.length > 0) return;

    try {
      const rowType: ObjectType = uiConstants.rowType.operation;
      setIsLoading(true);
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .schedule({
          folder_id: scheduleId,
          entity_name: rowType,
          entity_ids: Array.from(tableContext?.table.selectedRowIds || []),
          assignment: null,
          calendar_id: selectedCalendar?._id,
        });
      updatedRows.forEach((row) => {
        const rowId: string = getObjectId(row, rowType);
        tableContext?.table?.updateRow({ rowId, updatedRow: row });
      });
      notify.success(t("module.schedule:notification.assign_calendar_success"));
      closeDialog(false);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={EditCalendarIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderCalendarField = (): ReactElement => (
    <RiverLookup
      id={"calendarName"}
      lookup={{ type: LookupType.CALENDAR }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchCalendarByName(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        const { calendar: calendarName, _id: calendar_id } = selectedObject;
        setStandaloneFields({
          ...fields,
          calendarName,
          calendar_id,
        });
        setSelectedCalendar(selectedObject as IAdapterCalendar);
      }}
    />
  );

  const renderDialog = (): ReactElement => (
    <RiverDialog
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      open={dialogOpened}
      title={title}
      actionButtonText={t("common.button:ok")}
      onSubmit={assignCalendar}
      showActionsDivider={false}
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      {renderCalendarField()}
      <RiverSpinner show={isLoading} />
    </RiverDialog>
  );

  const renderAssignCalendarAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderAssignCalendarAction,
    assignCalendarAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseAssignCalendarAction = ReturnType<
  typeof useAssignCalendarAction
>;
