import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  ISupervisorScheduleContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { AdapterService } from "../adapter.service";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "./supervisor-schedule-ui-service";
import { TableUiService } from "../table-ui.service";
import { TaskColorGetter, useEntity, useTaskColor } from "../../hooks";
import { IAdapterFolder, IEntityObject, QueryDto } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { getFolderQueryGroup } from "@river/util";

interface IUseSupervisorScheduleWorkOrdersProps {
  draggable?: boolean;
  scheduleContext: ISupervisorScheduleContext;
}

export const useSupervisorScheduleWorkOrders = (
  props: IUseSupervisorScheduleWorkOrdersProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const workOrdersTabId: SupervisorScheduleTasksTabId =
    SupervisorScheduleTasksTabId.WORKORDERS;
  const entityHelpers = useEntityHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const scheduleContext: ISupervisorScheduleContext = props.scheduleContext;
  const currentSchedule: IAdapterFolder = scheduleContext.currentSchedule!;

  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getTaskColor: TaskColorGetter = useTaskColor();

  const fetchWorkOrders = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getFolderQueryGroup(currentSchedule, ""),
      }),
    };

    const workOrders = await adapterService.fetchWorkorders(query);
    const workOrderRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.workOrder
    );

    table.setLastRanQueryDto(query);

    workOrders.forEach((wo) => {
      try {
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowType,
          uiConstants.rowType.workOrder
        );
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(wo, workOrderRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing WorkOrder _id: ${wo._id}`);
        console.error(e);
      }
    });
    return workOrders;
  };

  const openEntityInErp = async (record: IEntityObject, entityName: string) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, entityName);
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    const entityName = "workorder";
    return {
      entityName,
      columns: uiService.getDefaultWorkOrderColumns({
        onWorkOrderClick: (entity) => openEntityInErp(entity, entityName),
        getTaskColor,
        draggable: props.draggable,
      })(),
    };
  };

  const { entityName, columns } = getTableParams(t);
  const tab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  const table: IUseTable = useTable({
    entityName,
    saveKey: `supervisor.schedule.workorders`,
    columns,
    fetchFunction: fetchWorkOrders,
    dependencies: [!!site, tab === workOrdersTabId],
    fetchOn: currentTab === workOrdersTabId,
    fetchTriggers: [currentSchedule?._id, site],
    initialSimpleFilters: uiService.getInitialTabSimpleFilters(workOrdersTabId),
    initialQuery: uiService.getInitialTabQuery(workOrdersTabId),
    keepSelection: true,
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    infiniteScrolling: true,
    onClearFilters: () => {
      uiService.taskInitialFiltersOverrides[workOrdersTabId] = null;
      uiService.taskInitialQueryOverrides[workOrdersTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
