import React, { ReactElement, useContext, useState } from "react";
import { GenericAction } from "../../../components/shared";
import SubjectIcon from "@mui/icons-material/Subject";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { BacklogUiService } from "../backlog-ui-service";

export const useMaterialRequirementsAction = () => {
  const { t } = useTranslation();
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const { withSanitizedProps } = useGridActionHelpers();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext!.service.getBacklogUiService();

  const title: string = t(
    "module.backlog:action_label.show_material_requirements"
  );

  const onClick = (): void => {
    setDialogOpened(true);
  };

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={SubjectIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderDialog = (): ReactElement =>
    uiService.renderMaterialRequirementsDialog({
      open: dialogOpened,
      onClose: () => setDialogOpened(false),
    });

  const renderMaterialRequirementsAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderMaterialRequirementsAction,
    materialRequirementsAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseMaterialRequirementsAction = ReturnType<
  typeof useMaterialRequirementsAction
>;
