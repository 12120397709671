import React from "react";
import { EditorProps, Column, FormatterProps } from "react-data-grid";
import { IRiverListPopupItem } from "../../../river-list-popup";
import { IAttribute, IAttributeOption } from "@river/interfaces";
import { ReactElement, useContext, useEffect, useState } from "react";
import { AdapterUiContext, TableContext } from "../../../../../context";
import { RiverCheckbox, useNotification } from "@river/common-ui";
import { Constants } from "@river/constants";
import { RiverDropdownList } from "../../../river-dropdown-list";
import { useTableCellRenderers } from "../../../../../hooks";
import styles from "./river-default-multiselect-editor.module.scss";

interface IRiverDefaultMultiSelectEditorProps {
  editorProps?: EditorProps<any>;
}

interface IEditorProps {
  editorProps: EditorProps<any>;
  attribute?: IAttribute;
}

const Editor: React.FC<IEditorProps> = (props: IEditorProps): ReactElement => {
  const [attributeValues, setAttributeValues] = useState<IAttributeOption[]>(
    []
  );
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();

  useEffect(() => {
    const attributeValue =
      props.editorProps?.row[props.attribute?.attribute_name!];
    if (attributeValue) {
      const selectedOptions: IAttributeOption[] =
        props.attribute?.options?.filter((item) =>
          attributeValue.includes(item.value)
        ) || [];
      setAttributeValues(selectedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table?.refresh]);

  const getObjectTypePayload = (option: IAttributeOption) => {
    const attributeValueValues =
      attributeValues?.map((attributeValue) => attributeValue.value) || [];
    const isItemNameIncluded = attributeValueValues.includes(option.value);

    return {
      [props.attribute?.attribute_name!]: isItemNameIncluded
        ? attributeValueValues.filter((value) => value !== option.value)
        : [...attributeValueValues, option.value],
    };
  };

  const handleCheckboxChange = async (option: IAttributeOption) => {
    try {
      table?.forceLoadingState(true);
      const id = props.editorProps?.row._id;
      await adapterContext!.service
        .getAdapterService()
        .updateEntityData(
          props.attribute?.entity_name || "",
          id,
          getObjectTypePayload(option)
        );
      table?.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };

  const getPopupItems = (): IRiverListPopupItem[] =>
    (props.attribute?.options || []).map((option) => ({
      title: option.value,
      ...(props.attribute?.input_type === Constants.input_type.multi_select && {
        startIcon: () => (
          <RiverCheckbox
            id={""}
            checked={attributeValues?.some(
              (attributeValue) => option.value === attributeValue.value
            )}
            onChangeEvent={() => handleCheckboxChange(option)}
            smallerIcon={true}
          />
        ),
      }),
    }));

  const renderToggle = (): ReactElement => {
    return (
      attributeValues && (
        <>
          {attributeValues.map((item) => (
            <div key={item.value} className={styles.attributeOption}>
              {item.value}
            </div>
          ))}
        </>
      )
    );
  };

  return (
    <RiverDropdownList
      toggle={renderToggle()}
      items={getPopupItems()}
      openOnDoubleClick={true}
      onItemClickPreventClose={true}
      classes={{
        toggle: styles.toggle,
        item: styles.item,
      }}
    />
  );
};

export const RiverDefaultMultiSelectEditor: React.FC<
  IRiverDefaultMultiSelectEditorProps
> = (props: IRiverDefaultMultiSelectEditorProps) => {
  const { editorProps } = props;
  const column: Column<any> = editorProps?.column!;
  const { renderCell } = useTableCellRenderers();

  return (
    <>
      {renderCell({
        formatterProps: editorProps as unknown as FormatterProps<any>,
        content: (
          <TableContext.Consumer>
            {(tableContext) => {
              const attribute = tableContext?.entity.attributesByName.get(
                column.key
              );
              return (
                <Editor editorProps={editorProps!} attribute={attribute} />
              );
            }}
          </TableContext.Consumer>
        ),
      })}
    </>
  );
};
