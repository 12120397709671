import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IBacklogContext,
  IUserContextSite,
} from "../../context";
import { DragEvent, useContext } from "react";
import { AdapterService } from "../adapter.service";
import { BacklogTabId, BacklogUiService } from "./backlog-ui-service";
import { TableUiService } from "../table-ui.service";
import { TaskColorGetter, useEntity, useTaskColor } from "../../hooks";
import {
  IAdapterFolder,
  IEntityObject,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { DndUtils } from "./dnd-utils";
import { getFolderQueryGroup } from "@river/util";

interface IUseBacklogWorkOrdersProps {
  draggable?: boolean;
  backlogContext: IBacklogContext;
}

export const useBacklogWorkOrders = (
  props: IUseBacklogWorkOrdersProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const workOrdersTabId: BacklogTabId = BacklogTabId.WORKORDERS;
  const entityHelpers = useEntityHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const backlogContext: IBacklogContext = props.backlogContext;
  const currentSchedule: IAdapterFolder = backlogContext.currentSchedule!;
  const currentTab: BacklogTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getTaskColor: TaskColorGetter = useTaskColor();

  const getInitialQueryAttributeGroup = (): QueryAttributeGroupDto => {
    if (!currentSchedule) {
      return {};
    } else {
      return getFolderQueryGroup(currentSchedule, "");
    }
  };

  const fetchWorkOrders = async (fetchProps: ITableFetchFunctionProps) => {
    const initialQueryAttributeGroup: QueryAttributeGroupDto =
      getInitialQueryAttributeGroup();
    const query: QueryDto = fetchHelpers.getTableQuery({
      fetchProps,
      initialQueryAttributeGroup,
    });

    table.setLastRanQueryDto(query);

    const workOrders = await adapterService.fetchWorkorders(query);
    const workOrderRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.workOrder
    );

    workOrders.forEach((wo) => {
      try {
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowType,
          uiConstants.rowType.workOrder
        );
        entityHelpers.setAttributeValue(
          wo,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(wo, workOrderRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing WorkOrder _id: ${wo._id}`);
        console.error(e);
      }
    });
    return workOrders;
  };

  const openEntityInErp = async (record: IEntityObject, entityName: string) => {
    await adapterContext!.service
      .getAdapterService()
      .openRecordInErp(record, entityName);
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    const entityName = "workorder";
    return {
      entityName,
      columns: uiService.getDefaultWorkOrderColumns({
        onWorkOrderClick: (entity) => openEntityInErp(entity, entityName),
        getTaskColor,
        draggable: props.draggable,
      })(),
    };
  };

  const { entityName, columns } = getTableParams(t);

  const onDrag = (event: DragEvent, row: IEntityObject): void => {
    const rowId = row[uiConstants.fields.rowId] as string;
    const dragWorkOrders = table.selectedRowIds.has(rowId)
      ? Array.from(table.selectedRowIds).map((id) => {
          const wo = table.entities.find(
            (entity) => entity[uiConstants.fields.rowId] === id
          );
          if (!wo) {
            throw new Error(`Could not find WO ${id}`);
          }
          return wo;
        })
      : [row];

    const woIds = dragWorkOrders.map((wo) => wo["_id"]);
    DndUtils.dragWithCustomImage(event, `${dragWorkOrders.length} workorders`);
    event.dataTransfer.setData("text", JSON.stringify({ workOrderIds: woIds }));
    event.dataTransfer.dropEffect = "move";
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `backlog.workorders`,
    columns,
    fetchFunction: fetchWorkOrders,
    dependencies: [!!site],
    fetchOn: currentTab === workOrdersTabId,
    fetchTriggers: [currentSchedule?._id, site],
    initialSimpleFilters: uiService.getInitialTabSimpleFilters(workOrdersTabId),
    initialQuery: uiService.getInitialTabQuery(workOrdersTabId),
    keepSelection: true,
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    onDrag,
    infiniteScrolling: true,
    onClearFilters: () => {
      uiService.initialFiltersOverrides[workOrdersTabId] = null;
      uiService.initialQueryOverrides[workOrdersTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
