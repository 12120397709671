import { ReactElement } from "react";
import {
  IRenderTimecardDialogProps,
  SupervisorTimeCardUiService,
} from "../../supervisor-timecard-ui.service";
import { OracleEbsSupervisorOperationTimeCardDialog } from "./oracle-cloud-supervisor-operation-timecard-dialog";

export class OracleCloudSupervisorTimeCardUiService extends SupervisorTimeCardUiService {
  renderOperationTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderAssignmentTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderResourceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <OracleEbsSupervisorOperationTimeCardDialog {...props} />;

  renderInstanceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <OracleEbsSupervisorOperationTimeCardDialog {...props} />;
}
