import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "../../hooks";
import styles from "./river-autocomplete.module.scss";
export const RiverAutocomplete = (props) => {
    const { label, options } = props;
    const { t } = useTranslation();
    return (_jsx(Autocomplete, { id: props.id, value: props.value, className: props.className, options: options, autoComplete: true, autoHighlight: true, autoSelect: true, disableListWrap: false, disabled: props.disabled, openText: t("common.label:open"), closeText: t("common.label:close"), clearText: t("common.label:clear"), noOptionsText: props.noOptionsText || t("common.label:no_options_available"), onChange: props.onChange, renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: label })), classes: { popper: styles.popper }, componentsProps: {
            popper: {
                placement: "bottom-start",
            },
        } }));
};
