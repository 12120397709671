import React, { useContext, useState } from "react";
import { FormatterProps } from "react-data-grid";
import { RiverDataGrid } from "../shared";
import { ExternalResourcesHeader } from "./external-resources-header";
import { ExternalResourcesSubHeader } from "./external-resources-subheader";
import { ExternalResourcesGridHeader } from "./external-resources-grid-header";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import { ExternalResourcesUiService, IExternalResource } from "../../services";
import { usePageCleanup } from "../../hooks";

export enum ExternalResourceAction {
  CREATE = "CREATE_RESOURCE",
  UPDATE = "UPDATE_RESOURCE",
  DELETE = "DELETE_RESOURCE",
}

export const ExternalResources: React.FC = () => {
  usePageCleanup();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;

  const [selectedResource, setSelectedResource] =
    useState<IExternalResource | null>(null);
  const [resourceDialogOpened, setResourceDialogOpened] =
    useState<boolean>(false);

  const onExternalResourceClick = (
    formatterProps: FormatterProps<any>
  ): void => {
    table.setSelectedRowIds(new Set());
    setSelectedResource(formatterProps.row as IExternalResource);
    setResourceDialogOpened(true);
  };

  const { entity, table } = uiService.useExternalResources({
    onExternalResourceClick,
  })();

  return (
    <TableContext.Provider value={{ table, entity }}>
      <ExternalResourcesHeader />
      <ExternalResourcesSubHeader />
      <ExternalResourcesGridHeader />
      <RiverSpinner show={table.isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={table.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
      {uiService.renderExternalResourceDialog({
        open: resourceDialogOpened,
        resource: selectedResource,
        onClose: (success) => {
          setResourceDialogOpened(false);
          if (success) table.fetch();
        },
      })}
    </TableContext.Provider>
  );
};
