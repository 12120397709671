import React, { ReactElement, useContext } from "react";
import { RiverSpinner } from "@river/common-ui";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import { useNavigate } from "react-router";
import { Column } from "react-data-grid";
import { IAdapterFolder, IEntityObject } from "@river/interfaces";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { SchedulesHeader } from "./schedules-header";
import { SchedulesSubHeader } from "./schedules-subheader";
import { SchedulesGridHeader } from "./schedules-grid-header";
import { useTranslation } from "@river/common-ui";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";

export enum SchedulesAction {
  CREATE = "CREATE_SCHEDULE",
  UPDATE = "UPDATE_SCHEDULE",
  DELETE = "DELETE_SCHEDULE",
}

export const Schedules: React.FC = (): ReactElement => {
  usePageCleanup();

  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const entityName: string = "folder";
  const entity: IUseEntity = useEntity({ entityName });
  const navigate = useNavigate();

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "folder",
      name: t("entity.folder:folder.folder"),
      width: 170,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  navigate(
                    `/schedules/${formatterProps.row[uiConstants.fields._id]}`
                  );
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    {
      key: "start_date",
      name: t("entity.folder:folder.start_date"),
      width: 160,
    },
    { key: "end_date", name: t("entity.folder:folder.end_date"), width: 160 },
    { key: "duration", name: t("entity.folder:folder.duration"), width: 90 },
    { key: "status.code", name: t("entity.status_ref:status_ref.code") },
    { key: "workorder_count", name: t("entity.folder:folder.workorder_count") },
    { key: "operation_count", name: t("entity.folder:folder.operation_count") },
  ];

  const fetchSchedules = async (fetchProps: ITableFetchFunctionProps) =>
    await adapterContext!.service
      .getAdapterService()
      .fetchFolders(fetchHelpers.getTableQuery({ fetchProps }));

  const table: IUseTable = useTable({
    entityName,
    saveKey: "schedules",
    columns,
    fetchFunction: fetchSchedules,
    dependencies: [!!site],
    fetchOn: true,
    fetchTriggers: [site],
    infiniteScrolling: true,
  });

  const getSelectedSchedule = (): IAdapterFolder => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterFolder;
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <SchedulesHeader />
        <SchedulesSubHeader />
        <SchedulesGridHeader selectedSchedule={getSelectedSchedule()} />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={true}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={(row) => row[uiConstants.fields._id]}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
      </TableContext.Provider>
    </>
  );
};
