import React, { ReactElement } from "react";
import { useTranslation } from "@river/common-ui";
import { PageHeader } from "../../page-header";

export interface ExecutionHeaderProps {}

export const ExecutionHeader: React.FC<ExecutionHeaderProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();

  return <PageHeader pageTitle={t("module.execution:module.title")} />;
};
