import React, { useContext } from "react";
import { RiverDataGrid } from "../../components/shared";
import { ExecutionUiService } from "./execution-ui.service";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IExecutionContext,
  ExecutionContext,
} from "../../context";
import { ExecutionGridHeader } from "../../components/execution/execution-grid-header";
import { RiverSpinner } from "@river/common-ui";

export const ExecutionMaterialRequirements = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const executionContext: IExecutionContext = useContext(ExecutionContext)!;
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const { table: currentTable } = uiService.useCurrentTable({
    executionContext,
  })();
  const isLoading: boolean = currentTable.isLoading;

  return (
    <>
      <ExecutionGridHeader />
      <RiverDataGrid
        columns={currentTable.columns}
        rows={currentTable.entities}
        rowKeyGetter={currentTable.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={currentTable.sortColumns}
      />
      <RiverSpinner show={isLoading} />
    </>
  );
};
