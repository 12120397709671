import { FC, SyntheticEvent } from "react";
import styles from "./river-video-dialog.module.scss";
import {
  RiverDialog,
  RiverDialogButton,
  useTranslation,
} from "@river/common-ui";
import ReactPlayer from "react-player";

export interface IRiverVideoDialogProps {
  title: string;
  url: string;
  onClose: () => void;
}

export const RiverVideoDialog: FC<IRiverVideoDialogProps> = (props) => {
  const { t } = useTranslation();
  return (
    <RiverDialog
      title={props.title}
      open={!!props}
      onClose={props.onClose}
      showActionsDivider={false}
      isDraggable={false}
      actionsContent={
        <RiverDialogButton
          onClick={props.onClose}
          text={t("common.button:close")}
        />
      }
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
    >
      <div className={styles.playerWrapper}>
        <ReactPlayer
          className={styles.player}
          url={props.url}
          controls={true}
          width={"100%"}
          height={"100%"}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                disablePictureInPicture: true,
                onContextMenu: (e: SyntheticEvent) => e.preventDefault(),
              },
            },
          }}
        />
      </div>
    </RiverDialog>
  );
};
