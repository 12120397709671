import { EntityTableProvider, IUseEntityTableParams } from "./ui-service.types";
import { AdapterUiService } from "./adapter-ui.service";
import { TFunction, useTranslation } from "react-i18next";
import dataGridStyles from "../components/shared/river-data-grid/river-data-grid.module.scss";
import shiftsStyles from "../components/shifts/shifts.module.scss";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
  useTable,
} from "../components/shared";
import { IAdapterShift } from "@river/interfaces";
import { fetchHelpers, helpers, uiConstants } from "../helpers";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  IUserContextSite,
} from "../context";
import { useContext } from "react";
import { useEntity, useTableCellRenderers } from "../hooks";
import { FormatterProps } from "react-data-grid";
import { riverGenerateIcon } from "../components/shared/river-icon-picker";
import {
  DEFAULT_SHIFT_CARD_COLOR,
  DEFAULT_SHIFT_CARD_ICON,
} from "../components/shifts/shift-card";

interface IUseShiftProps {
  onShiftClick: (formatterProps: FormatterProps<any>) => void;
}

export abstract class ShiftsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  useShifts = (props: IUseShiftProps): EntityTableProvider => {
    const { onShiftClick } = props;

    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const site: IUserContextSite =
        useContext(AdapterUserContext)?.userProperties[
          AdapterUserContextProp.SITE
        ];
      const { entityName, columns } = this.getShiftsTableParams(
        onShiftClick,
        t
      );

      const fetchShifts = async (fetchProps: ITableFetchFunctionProps) =>
        await this.adapterUiService
          .getAdapterService()
          .fetchShifts(fetchHelpers.getTableQuery({ fetchProps }));

      return {
        entity: useEntity({ entityName }),
        table: useTable({
          entityName,
          columns: [RiverSelectColumn, ...columns],
          fetchFunction: fetchShifts,
          fetchOn: true,
          dependencies: [!!site],
          fetchTriggers: [site],
          rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
          useAdvancedFilters: false,
        }),
      };
    };
  };

  protected getShiftsTableParams = (
    onShiftClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams => {
    const { renderTextCell, renderCell, renderCellText } =
      useTableCellRenderers();
    return {
      entityName: "shift",
      columns: [
        {
          key: "shift",
          name: t("entity.shift:shift.shift"),
          formatter: (formatterProps) => (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => onShiftClick(formatterProps)}
                  >
                    <div className={shiftsStyles.iconNameContainer}>
                      <span
                        className={shiftsStyles.iconContainer}
                        style={{
                          background: `${
                            formatterProps.row?.color ??
                            DEFAULT_SHIFT_CARD_COLOR
                          }50`,
                          color:
                            formatterProps.row?.color ??
                            DEFAULT_SHIFT_CARD_COLOR,
                        }}
                      >
                        {riverGenerateIcon(
                          formatterProps.row.icon ?? DEFAULT_SHIFT_CARD_ICON
                        )}
                      </span>
                      {renderCellText({ formatterProps })}
                    </div>
                  </div>
                ),
              })}
            </>
          ),
        },
        {
          key: "duration_hours",
          name: t("entity.shift:shift.duration_hours"),
        },
        {
          key: "start_time",
          name: t("module.shifts:label.start_time"),
          formatter: (formatterProps) => {
            const shift = formatterProps.row as IAdapterShift;
            return (
              <>
                {renderTextCell({
                  formatterProps,
                  value: helpers.formatTime(
                    shift.start_hours,
                    shift.start_minutes
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "end_time",
          name: t("module.shifts:label.end_time"),
          formatter: (formatterProps) => {
            const shift = formatterProps.row as IAdapterShift;
            return (
              <>
                {renderTextCell({
                  formatterProps,
                  value: helpers.formatTime(shift.end_hours, shift.end_minutes),
                })}
              </>
            );
          },
        },
      ],
    };
  };

  getI18nNamespaces(): string[] {
    return [
      "module.shifts",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.grid_header",
      "shared.column_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.error_messages",
      "common.button",
      "common.label",
      "entity.shift",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getErpSpecificI18nNamespaces(): string[];
}
