import React, { ReactElement, useState } from "react";
import { Box, Card, Tooltip } from "@mui/material";
import { AdapterCalendarDay, IAdapterShift } from "@river/interfaces";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import {
  RiverFormSelect,
  IRiverSimpleSelectItem,
  RiverTextInput,
  useValidation,
  IUseValidation,
  IValidationErrors,
} from "@river/common-ui";
import styles from "./calendar-template-day.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

interface ICalendarTemplateDayProps {
  templateIndex: number;
  day: AdapterCalendarDay;
  shifts: IAdapterShift[];
  className?: string;
  onUpdate: (day: AdapterCalendarDay, templateIndex: number) => void;
}

export const CalendarTemplateDay: React.FC<ICalendarTemplateDayProps> = (
  props
): ReactElement => {
  const validation: IUseValidation = useValidation();
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const { t } = useTranslation();

  const save = (state: AdapterCalendarDay) => {
    props.onUpdate(state, props.templateIndex);
  };

  const getFieldValue = (field: string, value: string): number | string => {
    const isNumericField = (): boolean =>
      [
        "start_hours",
        "start_minutes",
        "end_hours",
        "end_minutes",
        "available_hours",
        "duration_hours",
      ].indexOf(field) !== -1;
    return isNumericField() ? Number(value) : value;
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const propName = event.target.name!;
    const propValue = event.target.value;
    const newState: AdapterCalendarDay = {
      ...props.day,
      [propName]: getFieldValue(propName, String(propValue)),
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(props.day));
    save(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const createBlankShift = (): IAdapterShift => {
    return {
      _id: "",
      PlanningPlant: "",
      shift: "",
      start_hours: 0,
      start_minutes: 0,
      end_hours: 0,
      end_minutes: 0,
      duration_hours: 0,
    };
  };

  const onShiftChange = async (
    event: SelectChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const shiftId: string = event.target.value as string;
    let shift: IAdapterShift = props.shifts.filter(
      (obj) => obj._id === shiftId
    )[0];
    shift = shift || createBlankShift();
    const newState: AdapterCalendarDay = {
      ...props.day,
      shift_id: shift._id || null,
      shift: shift ? shift.shift : "",
      start_hours: shift.start_hours,
      start_minutes: shift.start_minutes,
      end_hours: shift.end_hours,
      end_minutes: shift.end_minutes,
      duration_hours: shift.duration_hours,
      available_hours: shift.duration_hours,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(props.day));
    save(newState);
  };

  const getShiftTooltip = (): string => {
    const formatMinutes = (val: number): string =>
      String(val).length === 1 ? "0" + val : String(val);
    const formatDurationHours = (val: number): string =>
      String(Number(val).toFixed(2));
    let tooltip = "";
    if (props.day.shift) {
      const start_hours: number = props.day.start_hours;
      const start_minutes: number = props.day.start_minutes;
      const end_hours: number = props.day.end_hours;
      const end_minutes: number = props.day.end_minutes;
      const duration_hours: number = props.day.duration_hours;
      tooltip = `${start_hours}:${formatMinutes(
        start_minutes
      )} - ${end_hours}:${formatMinutes(end_minutes)} ( ${formatDurationHours(
        duration_hours
      )} ${t("common.label:hours")} )`;
    }
    return tooltip;
  };

  const renderShiftField = (): ReactElement => {
    let shifts: IRiverSimpleSelectItem[] = props.shifts.map((shift) => {
      return { value: shift._id!, text: shift.shift! };
    });
    const entries: IRiverSimpleSelectItem[] = [
      { value: "", text: `- ${t("common.label:none")} -` },
    ].concat(shifts);
    return (
      <Tooltip
        title={getShiftTooltip()}
        placement={"top-start"}
        classes={{
          popper: styles.shiftFieldTooltip,
        }}
      >
        <Box width={"100%"}>
          <RiverFormSelect
            label={t("entity.shift:shift.shift")}
            id={"shift"}
            fullWidth
            items={entries}
            onChangeEvent={onShiftChange}
            value={props.day.shift_id}
            className={styles.shiftField}
            menuProps={{
              classes: {
                paper: styles.menu,
                list: styles.list,
              },
            }}
            selectProps={{
              classes: {
                select: styles.shiftSelect,
                icon: styles.shiftSelectIcon,
              },
            }}
            labelProps={{
              classes: {
                root: styles.shiftFieldLabel,
                shrink: styles.shiftFieldLabelShrink,
                filled: styles.shiftFieldLabelFilled,
              },
            }}
            formControlProps={{
              variant: "standard",
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  const renderAvailableHoursField = (): ReactElement => (
    <Box className={styles.singleFieldBox}>
      <span className={styles.singleFieldLabel}>
        {t("module.calendars:label.available_hours")}
      </span>
      <RiverTextInput
        id={"available_hours"}
        value={props.day.available_hours!}
        className={styles.singleField}
        onChangeEvent={(event) => onPropertyChange(event)}
        error={!!validationErrors?.fields["available_hours"]}
        inputProps={{
          type: "number",
          classes: {
            input: styles.singleFieldInput,
          },
        }}
      />
    </Box>
  );

  return (
    <Card className={clsx([styles.card, props.className])}>
      {renderShiftField()}
      {renderAvailableHoursField()}
    </Card>
  );
};
