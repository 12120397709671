import React, { ReactElement, useContext } from "react";
import {
  IUseValidation,
  IValidationErrors,
  RiverDialog,
  RiverTextInput,
  useNotification,
  useValidation,
} from "@river/common-ui";
import { RiverFormInstance, useTableCellRenderers } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import {
  CraftsUiService,
  ExternalResourcesUiService,
  IExternalResource,
  IExternalResourceDialogProps,
} from "../../../../services";
import { useOracleCloudExternalResourceForm } from "./use-oracle-cloud-external-resource-form";
import { IEntityObject } from "@river/interfaces";
import { Column } from "react-data-grid";
import { IColumn } from "../../../../interfaces";
import { LookupType } from "../../../../components/shared";
import { RiverTableLookup } from "../../../../components/shared/river-table-lookup";
import styles from "./oracle-cloud-external-resource-dialog.module.scss";

export const OracleCloudExternalResourceDialog: React.FC<
  IExternalResourceDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const notify = useNotification();
  const validation: IUseValidation = useValidation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const externalResourcesUiService: ExternalResourcesUiService =
    adapterContext?.service!.getExternalResourcesUiService()!;

  const craftsArrayKey: string = externalResourcesUiService.craftsArrayKey;

  const form: RiverFormInstance = useOracleCloudExternalResourceForm({
    resource: props.resource,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
    onUpdate: () => {
      const success = true;
      closeDialog(success);
    },
  });

  const {
    setDto,
    isReadOnly,
    resetForm,
    validationErrors,
    setValidationErrors,
  } = form;

  const dto: IExternalResource = form.dto as IExternalResource;

  const crafts: IEntityObject[] = dto[craftsArrayKey] as IEntityObject[];

  const getCraftsColumns = (): IColumn[] => {
    return [
      {
        key: "ResourceName",
        name: t("entity.resource:resource.ResourceName"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "ResourceDescription",
        name: t("entity.resource:resource.ResourceDescription"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "ResourceType",
        name: t("entity.resource:resource.ResourceType"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "UnitOfMeasure",
        name: t("entity.resource:resource.UnitOfMeasure"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
      {
        key: "ResourceCode",
        name: t("entity.resource:resource.ResourceCode"),
        formatter: (formatterProps) => renderTextCell({ formatterProps }),
      },
    ];
  };

  const columns: Column<any>[] = getCraftsColumns();

  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  const onDialogSubmit = async () => await form.submit();

  const dialogTitle: string = form.isCreate
    ? t("module.external_resource:dialog.create_resource")
    : t("module.external_resource:dialog.edit_resource");

  const actionButtonText: string = props.resource
    ? t("common.button:save")
    : t("common.button:add");

  const validateCraftsArray = async (
    newDto: IExternalResource
  ): Promise<void> => {
    const newErrors: IValidationErrors = await validation.validateProperty(
      newDto,
      craftsArrayKey,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const onSelectCraft = async (selection: IEntityObject[]): Promise<void> => {
    const selectedCraft: IEntityObject = selection[0];
    const alreadySelected: boolean = !!crafts?.find(
      (craft) =>
        craftsUiService.craftKeyGetter(craft) ===
        craftsUiService.craftKeyGetter(selectedCraft)
    );
    if (alreadySelected) {
      notify.error(t("module.external_resource:resource_already_selected"));
      return;
    }

    const {
      OrganizationId,
      ResourceId,
      ResourceCode,
      ResourceName,
      ResourceDescription,
      ResourceClassCode,
      LaborInstanceId,
      LaborIdentifier,
      PersonName,
      PersonType,
      PrimaryWorkCenterId,
      PrimaryWorkCenterCode,
    } = selectedCraft;

    const newDto: IExternalResource = {
      ...dto,
      [craftsArrayKey]: [
        ...crafts,
        {
          OrganizationId,
          ResourceId,
          ResourceCode,
          ResourceName,
          ResourceDescription,
          ResourceClassCode,
          LaborInstanceId,
          LaborIdentifier,
          PersonName,
          PersonType,
          PrimaryWorkCenterId,
          PrimaryWorkCenterCode,
        },
      ],
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    await validateCraftsArray(newDto);
  };

  const onDeleteCraft = async (deletedCraft: IEntityObject): Promise<void> => {
    const newDto: IExternalResource = {
      ...dto,
      [craftsArrayKey]: crafts?.filter(
        (craft) =>
          craftsUiService.craftKeyGetter(craft) !==
          craftsUiService.craftKeyGetter(deletedCraft)
      ),
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
    setDto(newDto);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newDto,
      craftsArrayKey,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const renderContent = (): ReactElement => (
    <>
      <RiverTextInput id={"Agreement"} fullWidth />
      <RiverTextInput id={"LaborInstanceName"} fullWidth />
      <RiverTableLookup
        title={t("shared.crafts:label.crafts")}
        buttonLabel={t("shared.crafts:button.add_craft")}
        rows={crafts}
        columns={columns}
        lookup={{
          type: LookupType.CRAFTS,
        }}
        singleSelect={true}
        onSelect={onSelectCraft}
        onDelete={onDeleteCraft}
        isReadOnly={isReadOnly}
        fieldId={craftsArrayKey}
      />
    </>
  );

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={actionButtonText}
      onSubmit={onDialogSubmit}
      showActionsDivider={false}
      form={form}
      classes={{
        paper: styles.paper,
      }}
    >
      {renderContent()}
    </RiverDialog>
  );
};
