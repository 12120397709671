import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { WorkRequestsAction } from "../notifications";
import { NotificationDialog } from "../notification-dialog";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { TableContext } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const NotificationsSubHeader: React.FC = (props) => {
  const [notificationDialogOpened, setNotificationDialogOpened] =
    useState<boolean>(false);
  const openDialog = (): void => {
    setNotificationDialogOpened(true);
  };
  const { t } = useTranslation();

  const closeDialog = (): void => setNotificationDialogOpened(false);
  const tableContext = useContext(TableContext);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.WORK_REQUESTS}
          action={WorkRequestsAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.notifications:dialog.create_notification")}
          />
        </ProtectedAction>
      </div>
      <NotificationDialog
        open={notificationDialogOpened}
        notification={null}
        onClose={(success) => {
          closeDialog();
          if (success) tableContext?.table.fetch();
        }}
      />
    </>
  );
};
