import { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../context";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ScheduleTasksTabId } from "../schedule-ui-service";
import { useShowTabAction } from "../../shared";
import { ScheduleUiService } from "../schedule-ui-service";
import { IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";

export const useShowAssignmentsTabAction = () => {
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext!.service.getScheduleUiService();

  const isEnabled = (): boolean => {
    let enabled: boolean = false;
    const workOrdersTable: IUseTable =
      uiService.taskTableRefs[ScheduleTasksTabId.WORKORDERS]!;
    const operationsTable: IUseTable =
      uiService.taskTableRefs[ScheduleTasksTabId.OPERATIONS]!;
    enabled =
      workOrdersTable?.selectedRowIds.size > 0 ||
      operationsTable?.selectedRowIds.size > 0;
    return enabled;
  };

  const {
    renderShowTabAction: renderShowAssignmentsTabAction,
    showTabAction: showAssignmentsTabAction,
  } = useShowTabAction({
    title: t("module.schedule:action_label.show_assignments_tab"),
    icon: LibraryBooksIcon,
    enabled: isEnabled(),
    tabKey: ScheduleTasksTabId.ASSIGNMENTS,
    onClick: () => tabContext?.setSelectedTab(ScheduleTasksTabId.ASSIGNMENTS),
  });

  return {
    renderShowAssignmentsTabAction,
    showAssignmentsTabAction,
  };
};
