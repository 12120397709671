import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import styles from "./river-auto-complete-async.module.scss";
import { useTranslation } from "@river/common-ui";
import CircularProgress from "@mui/material/CircularProgress";

export interface IRiverAutocompleteOption {
  label: string;
  value: any;
}

export interface IRiverAutocompleteAsyncProps {
  id: string;
  className?: string;
  label: string;
  noOptionsText?: string;
  placeholder?: string;
  initialValue?: string;
  onSearch: (query?: string) => Promise<IRiverAutocompleteOption[]>;
  onChange: (value: IRiverAutocompleteOption | null) => void;
}

export function RiverAutocompleteAsync(props: IRiverAutocompleteAsyncProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<IRiverAutocompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<IRiverAutocompleteOption | null>(null);

  // const debounceSearchOptions = useCallback(
  //   debounce((query) => {
  //     fetchOptions(query);
  //   }, DEFAULT_DEBOUNCE_TIME),
  //   [inputValue, isLoading]
  // );

  const fetchOptions = async () => {
    setIsLoading(true);
    try {
      const results = await props.onSearch(inputValue);
      setOptions(results);

      if (props.initialValue && selectedOption === null) {
        const initialSelectedOption =
          results.find((option) => option.value === props.initialValue) || null;
        setSelectedOption(initialSelectedOption);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectOption = (value: IRiverAutocompleteOption | null) => {
    setSelectedOption(value);
    props.onChange(value);
  };

  useEffect(() => {
    if (open || props.initialValue) {
      fetchOptions();
    }

    // eslint-disable-next-line
  }, [open, props.initialValue]);

  return (
    <Autocomplete
      id={props.id}
      value={selectedOption}
      onChange={(_, value) => handleSelectOption(value)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      className={props.className}
      options={options}
      autoComplete={true}
      autoHighlight={true}
      autoSelect={true}
      disableListWrap={false}
      openText={t("common.label:open")}
      closeText={t("common.label:close")}
      clearText={t("common.label:clear")}
      loading={isLoading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      noOptionsText={
        props.noOptionsText || t("common.label:no_options_available")
      }
      classes={{ popper: styles.popper }}
      componentsProps={{
        popper: {
          placement: "bottom-start",
        },
      }}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
