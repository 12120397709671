import { RiverSVGProps } from "./types";

export const sanitizeSvgProps = (props: RiverSVGProps): RiverSVGProps => {
  let sanitized: RiverSVGProps = Object.assign({}, { ...props });
  const unsupportedProps: string[] = ["title", "titleAccess"];
  unsupportedProps.forEach((prop) => {
    // @ts-ignore
    delete sanitized[prop];
  });
  return sanitized;
};
