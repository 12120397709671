import { useTranslation } from "@river/common-ui";
import { ReactElement, useState } from "react";
import { useGridActionHelpers } from "../../../helpers";
import { GenericAction } from "../../../components/shared/grid-header/actions/generic-action";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { OracleEbsWoUncompleteDialog } from "../oracle-ebs-shared";

interface IUseWoUncompleteActionProps {
  enableOn?: boolean;
}

export const useEbsWoUncompleteAction = (
  props?: IUseWoUncompleteActionProps
) => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("shared.wo_uncomplete_dialog:title");

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (iconProps?: any): ReactElement => {
    return (
      <GenericAction
        icon={UnpublishedIcon}
        enableOn={props?.enableOn}
        enableOnSelection={true}
        title={title}
        onClick={onClick}
        {...iconProps}
      />
    );
  };

  const renderDialog = (dialogProps?: {
    workorderIds?: string[];
  }): ReactElement => (
    <OracleEbsWoUncompleteDialog
      open={dialogOpened}
      onClose={() => {
        setDialogOpened(false);
      }}
      workorderIds={dialogProps?.workorderIds}
    />
  );

  return {
    ebsWoUncompleteAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseWoUncompleteAction = ReturnType<
  typeof useEbsWoUncompleteAction
>;
