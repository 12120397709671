import { TFunction } from "i18next";
import { ILookupDefinition } from "../components/shared";
import { IAdapterUiContextState, IAdapterUserContext } from "../context";
import { AdapterUiService } from "./adapter-ui.service";
import { Column } from "react-data-grid";

export interface IUseOperationsLookupProps {
  userContext: IAdapterUserContext;
  adapterContext: IAdapterUiContextState;
  t: TFunction;
  entityHelpers: any;
  RiverSelectColumn: Column<any, any>;
}

export abstract class OperationsUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}
  useOperationsLookup =
    (props: IUseOperationsLookupProps): (() => ILookupDefinition) =>
    () =>
      this.getOperationsLookupDefinition(props);

  protected abstract getOperationsLookupDefinition(
    props: IUseOperationsLookupProps
  ): ILookupDefinition;
}
