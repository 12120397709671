import chroma, { contrast } from "chroma-js";

interface IMaximumContrastRatios {
  // Maximum Contrast Ratio for light font color on dark background
  maximumLightContrastRatio: number;
  // Maximum Contrast Ratio for dark font color on light background
  maximumDarkContrastRatio: number;
}

const DEFAULT_MAXIMUM_FONT_CONTRAST_RATIOS: IMaximumContrastRatios = {
  maximumLightContrastRatio: 11,
  maximumDarkContrastRatio: 8,
};

// As per WCAG2.0 standard, contrast Ratio should at least be 4.5, between text and background
// https://www.w3.org/TR/WCAG20-TECHS/G18.html
const MINIMUM_WCAG20_CONTRAST_RATIO = 4.5;

const DEFAULT_FONT_BACKGROUND_COLOR = "white";

export namespace colorHelpers {
  export const isValidContrast = (color1: string, color2: string): boolean => {
    if (!chroma.valid(color1) || !chroma.valid(color2)) {
      return false;
    }
    const contrastRatio: number = contrast(color1, color2);
    return contrastRatio >= MINIMUM_WCAG20_CONTRAST_RATIO;
  };

  export const getGreaterContrastColor = (props: {
    colorToCompare: string;
    baseLightColor?: string;
    baseDarkColor?: string;
    maximumContrastRatios?: IMaximumContrastRatios;
  }): string => {
    const DEFAULT_BASE_LIGHT_COLOR = "white";
    const DEFAULT_BASE_DARK_COLOR = "black";
    const { colorToCompare, maximumContrastRatios } = props;
    const baseLightColor: string =
      props.baseLightColor || DEFAULT_BASE_LIGHT_COLOR;
    const baseDarkColor: string =
      props.baseDarkColor || DEFAULT_BASE_DARK_COLOR;

    let currentContrast: number;
    let currentColor: string;
    let isLightColor: boolean;

    const lightContrast: number = contrast(colorToCompare, baseLightColor);
    const darkContrast: number = contrast(colorToCompare, baseDarkColor);

    if (lightContrast > darkContrast) {
      currentContrast = lightContrast;
      currentColor = baseLightColor;
      isLightColor = true;
    } else {
      currentContrast = darkContrast;
      currentColor = baseDarkColor;
      isLightColor = false;
    }
    let resultColor: string = currentColor;

    if (maximumContrastRatios) {
      reduceContrastRatio({ maximumContrastRatios });
    }

    return resultColor;

    function reduceContrastRatio(props: {
      maximumContrastRatios: IMaximumContrastRatios;
    }): void {
      const colorAdjustmentStep: number = 0.1;
      const {
        maximumContrastRatios: {
          maximumLightContrastRatio,
          maximumDarkContrastRatio,
        },
      } = props;
      while (
        currentContrast >
        (isLightColor ? maximumLightContrastRatio : maximumDarkContrastRatio)
      ) {
        currentColor = isLightColor
          ? chroma(currentColor).darken(colorAdjustmentStep).hex()
          : chroma(currentColor).brighten(colorAdjustmentStep).hex();
        currentContrast = contrast(colorToCompare, currentColor);
        resultColor = currentColor;
      }
    }
  };

  export const getHighContrastFontColor = (props: {
    backgroundColor: string;
    maximumContrastRatios?: IMaximumContrastRatios;
  }): string => {
    const { backgroundColor, maximumContrastRatios } = props;
    const validBackgroundColor = chroma.valid(backgroundColor)
      ? backgroundColor
      : DEFAULT_FONT_BACKGROUND_COLOR;

    return getGreaterContrastColor({
      colorToCompare: validBackgroundColor,
      maximumContrastRatios:
        maximumContrastRatios || DEFAULT_MAXIMUM_FONT_CONTRAST_RATIOS,
    });
  };
}
