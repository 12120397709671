import { FC, useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import {
  GridHeader,
  ColumnSelectorDropdown,
  GridActions,
  FilterDropdown,
  ReportsDropdown,
} from "../../../shared";
import { ScheduleUiService } from "../../../../services";
import { BaselineSelector } from "../../baseline-selector";
import { ScheduleGanttHeaderOptions } from "../schedule-gantt-header-options";

export const ScheduleTasksGridHeader: FC = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown
            dynamicFilterActions={uiService.getDynamicFilterActionsForCurrentTab()()}
          />
          {uiService.renderGridActions()()}
          <ReportsDropdown />
          <BaselineSelector />
        </GridActions>
        <ScheduleGanttHeaderOptions />
      </GridHeader>
    </>
  );
};
