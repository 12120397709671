import { ReactElement } from "react";
import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { TFunction } from "react-i18next";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers } from "../../helpers";
import {
  CraftsUiService,
  ICraftSelectorProps,
  IUseCraftLookupProps,
} from "../crafts-ui.service";
import { Column } from "react-data-grid";
import { JdeCraftSelector } from "./jde-shared/jde-craft-selector";

export class JdeCraftsUiService extends CraftsUiService {
  getCraftEntity = () => "workcenter";
  craftKeyGetter = (row: any): string => row["F30006_MCU"];

  getCraftLookupDefinition = (
    props: IUseCraftLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: props.t("shared.lookup:craft.title"),
      rowKeyGetter: this.craftKeyGetter,
      useTableProps: {
        columns: this.getCraftsSelectorColumns(
          props.t,
          props.RiverSelectColumn
        ),
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await props
            .adapterContext!.service.getAdapterService()
            .fetchCrafts(
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: props.customFilters,
              })
            );
        },
        dependencies: [!!site],
        saveKey: this.getCraftsLookupTableSaveKey(),
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: this.getCraftEntity(),
      },
    };
  };
  getCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => [
    RiverSelectColumn,
    {
      key: "F30006_MCU",
      name: t("entity.workcenter:workcenter.F30006_MCU"),
      width: 150,
    },
    {
      key: "F30006_MCUW",
      name: t("entity.workcenter:workcenter.F30006_MCUW"),
    },
    { key: "F30006_MMCU", name: t("entity.workcenter:workcenter.F30006_MMCU") },
    {
      key: "F30006_LOCN",
      name: t("entity.workcenter:workcenter.F30006_LOCN"),
    },
  ];

  getAssignedCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => this.getCraftsSelectorColumns(t, RiverSelectColumn);

  getCraftsUtilizationIds = (selectedCraftIds: string[]): string[] =>
    selectedCraftIds;

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <JdeCraftSelector {...props} />
  );
}
