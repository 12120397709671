import { FC, useContext, useState } from "react";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { PrimaryButton, useTranslation } from "@river/common-ui";
import { AvailabilityAction } from "../availability";
import { AvailabilityUiService } from "../../../services";

export const AvailabilitySubHeader: FC = (props) => {
  const tableContext = useContext(TableContext);
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const uiService: AvailabilityUiService =
    adapterContext?.service!.getAvailabilityUiService()!;

  const [createResourcesDialogOpened, setCreateResourcesDialogOpened] =
    useState<boolean>(false);

  const openCreateResourcesDialog = (): void => {
    setCreateResourcesDialogOpened(true);
  };
  const closeCreateResourcesDialog = (): void =>
    setCreateResourcesDialogOpened(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.AVAILABILITY}
          action={AvailabilityAction.CREATE_RESOURCE}
        >
          <PrimaryButton
            onClick={openCreateResourcesDialog}
            text={t("module.availability:label.create_resources")}
          />
        </ProtectedAction>
      </div>
      {createResourcesDialogOpened &&
        uiService.renderCreateResourcesDialog({
          open: createResourcesDialogOpened,
          onClose: (success) => {
            closeCreateResourcesDialog();
            if (success) tableContext?.table.fetch();
          },
        })}
    </>
  );
};
