import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import { OracleEbsAdapterWorkorderCompletionDto } from "@river/interfaces";

interface IUseReconciliationCodeLookupProps {
  form: RiverFormInstance;
  selectedReconciliationCode: any;
  setSelectedReconciliationCode: (selectedCode: any) => void;
}

export function useReconciliationCodeLookup(
  props: IUseReconciliationCodeLookupProps
) {
  const notify = useNotification();

  const { form, selectedReconciliationCode, setSelectedReconciliationCode } =
    props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    validateStandaloneField("reconciliationMeaning");

    const LOOKUP_CODE = selectedReconciliationCode?.["LOOKUP_CODE"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { RECONCILIATION_CODE: LOOKUP_CODE }
    );
    form.setDto(newDto);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReconciliationCode]);

  const fetchReconciliationCode = async (meaning: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("reconciliation_code", {
          query: {
            $and: [
              {
                attribute_name: "MEANING",
                attribute_value: {
                  operator: "$eq",
                  value: meaning,
                },
              },
            ],
          },
        });
      const reconciliationCode: any = results[0] || null;
      setSelectedReconciliationCode(reconciliationCode);
      validateStandaloneField("reconciliationMeaning", meaning);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchReconciliationCode = useCallback(
    debounce((text) => {
      fetchReconciliationCode(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"reconciliationMeaning"}
      fullWidth
      lookup={{ type: LookupType.ORACLE_EBS_RECONCILIATION }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchReconciliationCode(event.target.value);
      }}
      onSelect={(selectedReconciliation) => {
        const { LOOKUP_CODE, MEANING } = selectedReconciliation;
        const currentDto: OracleEbsAdapterWorkorderCompletionDto =
          form.dto as OracleEbsAdapterWorkorderCompletionDto;
        form.setDto({
          ...currentDto,
          RECONCILIATION_CODE: String(LOOKUP_CODE),
        });

        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          reconciliationMeaning: MEANING,
        });

        setSelectedReconciliationCode(selectedReconciliation);
      }}
    />
  );

  return {
    render,
  };
}

export type IUseReconciliationCodeLookup = ReturnType<
  typeof useReconciliationCodeLookup
>;
