import { TableUiService } from "../../services";
import { uiConstants } from "../../helpers";
import { ObjectType } from "../task-helpers-ui.service";

export class SapTableUiService extends TableUiService {
  getRowIdAttribute = (rowType: ObjectType): string => {
    const mapping: { [key in ObjectType]?: string } = {
      [uiConstants.rowType.workOrder]: uiConstants.fields._id,
      [uiConstants.rowType.operation]: uiConstants.fields._id,
      [uiConstants.rowType.assignment]: "to_Assignment._id",
    };
    return mapping[rowType] || uiConstants.fields._id;
  };
}
