import React, { ReactElement, useContext } from "react";
import { IAdapterFolder } from "@river/interfaces";
import {
  GridHeader,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
} from "../../shared";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";

interface ISupervisorSchedulesGridHeaderProps {
  className?: string;
  selectedSchedule: IAdapterFolder;
}

export const SupervisorSchedulesGridHeader: React.FC<
  ISupervisorSchedulesGridHeaderProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const deleteSelectedSchedule = async (): Promise<any> => {
    if (props.selectedSchedule) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteFolder(props.selectedSchedule._id);
        tableContext?.table.refresh();
        deleteScheduleConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteScheduleConfirmation = useSimpleDialog({
    title: t("module.supervisor_schedules:delete_dialog.title"),
    message: t("module.supervisor_schedules:delete_dialog.message"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedSchedule,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown />
          {/* No other actions present in the design */}
        </GridActions>
      </GridHeader>
    </>
  );
};
