import { FC, useState, useEffect, useContext } from "react";
import {
  RiverDataGrid,
  ICraftStatProps,
  CraftStats,
  IUseTable,
  useAsyncSchedule,
} from "../../../shared";
import {
  AdapterUiContext,
  ScheduleContext,
  TableContext,
} from "../../../../context";
import { CraftsUiService, ScheduleAction } from "../../../../services";
import { IAdapterFolder, QueryDto } from "@river/interfaces";
import { RiverSpinner, useNotification } from "@river/common-ui";
import ProtectedAction from "../../../protected-action";
import { uiConstants } from "../../../../helpers";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../../sidebar-menu";
import styles from "./schedule-backlog.module.scss";
import clsx from "clsx";

interface IScheduleBacklogProps {
  selectedCraftIds: Set<string>;
}

export const ScheduleBacklog: FC<IScheduleBacklogProps> = (props) => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const scheduleContext = useContext(ScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const [totalCraftItems, setTotalCraftItems] = useState<
    Array<ICraftStatProps>
  >([]);
  const adapterContext = useContext(AdapterUiContext);
  const scheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;

  const [isLoadingStats, setIsLoadingStats] = useState<boolean>(false);
  const [isStatsLoaded, setIsStatsLoaded] = useState<boolean>(false);
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const notify = useNotification();

  const asyncSchedule = useAsyncSchedule({
    onComplete: () => scheduleContext?.currentTasksTableRef.current!.refresh(),
  });
  const { scheduleProgress, doAsyncSchedule } = asyncSchedule;

  const fetchStats = async () => {
    setIsLoadingStats(true);
    try {
      const queryWithIds: QueryDto = {
        query: craftsUiService.getSelectedCraftsQuery(
          Array.from(props.selectedCraftIds)
        ),
      };

      let result = await adapterContext!.service
        .getAdapterService()
        .fetchCraftUtilization(
          currentSchedule._id,
          null,
          currentSchedule.start_date,
          "FOLDER",
          0,
          queryWithIds
        );
      let craftTotalUtilizationResults: Array<ICraftStatProps> = [];

      result.forEach((craftItem) => {
        let newObj: ICraftStatProps = {
          label:
            craftItem[
              scheduleUtilizationUiService.getCraftUtilizationStatLabelColumn()
            ],
          value: Number(
            (
              Math.round(craftItem.utilization[0].scheduled_hours * 100) / 100
            ).toFixed(2)
          ),
          outOf: Number(
            (
              Math.round(craftItem.utilization[0].available_hours * 100) / 100
            ).toFixed(2)
          ),
        };
        craftTotalUtilizationResults.push(newObj);
      });

      setTotalCraftItems(craftTotalUtilizationResults);
      setIsStatsLoaded(true);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoadingStats(false);
    }
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCraftIds, currentSchedule]);

  // Invoked when scheduling individual work orders manually selected in the table
  const doSyncSchedule = async (workOrderIds?: string[]): Promise<void> => {
    const selectedIds: string[] =
      workOrderIds || Array.from(tableContext?.table.selectedRowIds!);

    if (!!selectedIds.length) {
      const notification =
        selectedIds.length > 1
          ? {
              title: "Work Orders Added!",
              message: `${selectedIds.length} Work Orders have been added to ${currentSchedule.folder}`,
            }
          : {
              title: "Work Order Added!",
              message: `1 Work Order has been added to ${currentSchedule.folder}`,
            };
      setIsScheduling(true);
      await adapterContext!.service
        .getAdapterService()
        .schedule({
          folder_id: currentSchedule._id,
          entity_name: "workorder",
          entity_ids: selectedIds,
        })
        .then(() => {
          scheduleContext?.currentTasksTableRef.current?.refresh();
          fetchStats();
          tableContext?.table.setSelectedRowIds(new Set());
          notify.info(notification);
        })
        .catch((message) => {
          notify.error({ message });
        })
        .finally(() => {
          setIsScheduling(false);
        });
    }
  };

  const doSchedule = async (workOrderIds?: string[]) => {
    const table: IUseTable = tableContext?.table!;
    const hasSelectedObjects: boolean =
      !!workOrderIds?.length || !!table.selectedRowIds.size;
    if (hasSelectedObjects) {
      const isSelectAll: boolean =
        table.selectedRowIds.size === table.entities.length;
      if (isSelectAll) {
        doAsyncSchedule({ schedule: currentSchedule });
      } else {
        doSyncSchedule(workOrderIds);
      }
    }
  };

  const renderCraftStats = () => (
    <>
      <div
        className={clsx([
          styles.statsContainer,
          { [styles.loaded]: isStatsLoaded },
        ])}
      >
        <div className={styles.header}>
          {t("module.schedule:label.craft_stats")}
        </div>
        <CraftStats className={styles.stats} items={totalCraftItems} />
        <ProtectedAction
          module={ModuleKey.SCHEDULES}
          action={ScheduleAction.SCHEDULE}
        >
          <div className={styles.footer}>
            <Divider
              orientation="horizontal"
              classes={{ root: styles.divider }}
            />
            <div className={styles.buttonContainer}>
              <Button
                onClick={() => doSchedule()}
                classes={{
                  root: clsx([styles.footerButton, styles.scheduleButton]),
                }}
              >
                Schedule
              </Button>
            </div>
          </div>
        </ProtectedAction>
      </div>
    </>
  );

  const isLoading: boolean =
    isLoadingStats || isScheduling || tableContext?.table.isLoading!;

  return (
    <div className={styles.root}>
      {renderCraftStats()}
      <RiverDataGrid
        className={styles.grid}
        columns={tableContext?.table.columns!}
        sortColumns={tableContext?.table!.sortColumns}
        onSortColumnsChange={tableContext?.table!.setSortColumns}
        onColumnResize={tableContext?.table!.setColumnSize}
        rows={tableContext?.table!.entities!}
        onSelectedRowsChange={(rowKeys) =>
          tableContext?.table!.setSelectedRowIds(rowKeys)
        }
        selectedRows={tableContext?.table!.selectedRowIds}
        rowKeyGetter={(row) => row[uiConstants.fields.rowId]}
        style={{ borderRight: "none" }}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
      />
      <RiverSpinner show={isLoading} />
      {scheduleProgress.renderDialog()}
    </div>
  );
};
