import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import { IAdapterUiContextState } from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { QueryAttributeDto, QueryAttributeGroupDto } from "@river/interfaces";

export function useJdeUserDefinedCodeLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[],
    customQuery?: QueryAttributeGroupDto
  ): ILookupDefinition => {
    return {
      title: t("shared.lookup:user_defined_code.title"),
      rowKey: "F0005_KY",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "F0005_KY",
            name: t("entity.user_defined_code:user_defined_code.F0005_KY"),
          },
          {
            key: "F0005_DL01",
            name: t("entity.user_defined_code:user_defined_code.F0005_DL01"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "user_defined_code",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [...(customFilters || [])],
                initialQueryAttributeGroup: customQuery,
              })
            );
        },
        saveKey: "user_defined_code.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "user_defined_code",
      },
    };
  };
}
export type IUseJdeUserDefinedCodeLookupDef = ReturnType<
  typeof useJdeUserDefinedCodeLookupDef
>;
