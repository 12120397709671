import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";
import { Max, Min } from "class-validator";

export interface ICompletionPercentageValidator {
  completed_pct?: number;
}

export class CompletionPercentageValidator {
  constructor(obj: ICompletionPercentageValidator) {
    Object.assign(this, obj);
  }

  @Min(0)
  @Max(100)
  // @ts-ignore
  completed_pct: number;
}

export const useCompletionPercentageForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, CompletionPercentageValidator>({
    standalone: {
      fields: new CompletionPercentageValidator({
        completed_pct: 100,
      }),
      fieldDefs: [{ fieldName: "completed_pct", dataType: "number" }],
    },
    submit: props.submit,
    labels: {
      completed_pct: t("shared.completion_percentage:form_field.percentage"),
    },
  });
};
