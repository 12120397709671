import React, { ReactElement } from "react";
import {
  GridHeader,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
  ReportsDropdown,
} from "../../../components/shared";

export const MaterialRequirementsDialogGridHeader: React.FC =
  (): ReactElement => (
    <GridHeader>
      <GridActions>
        <ColumnSelectorDropdown />
        <FilterDropdown />
        <ReportsDropdown />
      </GridActions>
    </GridHeader>
  );
