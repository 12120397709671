import { useContext, ReactElement } from "react";
import { TabProps } from "@mui/material/Tab";
import { useTranslation } from "@river/common-ui";
import { ScheduleContext, TabContext, TableContext } from "../../context";
import ProtectedAction from "../../components/protected-action";
import { useGridActions } from "../schedule-ui-service/grid-actions";
import {
  TabsProvider,
  EntityTableProvider,
  ColumnsProvider,
  IUseEntityTable,
  ReactElementProvider,
} from "../ui-service.types";
import {
  ScheduleUiService,
  IGetDefaultScheduleWorkOrderColumnsProps,
  ScheduleTasksTabId,
  useScheduleWorkOrders,
  useScheduleOperations,
  useScheduleResources,
  useScheduleInstances,
  ScheduleAction,
  IUseScheduleCurrentTableProps,
  ScheduleTaskTabKeyColumnMap,
  BacklogTabId,
  IOpMassUpdateDialogProps,
} from "../../services";
import {
  getWorkOrderIdFormatter,
  RiverDropdownActions,
  useCompletionPercentageDialogAction,
  useRiverSelectColumn,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { IWoMassUpdateDialogProps } from "../shared";
import { OracleCloudWoMassUpdateDialog } from "./oracle-cloud-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import { useTableCellRenderers } from "../../hooks";

const DEFAULT_ORACLE_CLOUD_SCHEDULE_TAB: ScheduleTasksTabId =
  ScheduleTasksTabId.WORKORDERS;

export class OracleCloudScheduleUiService extends ScheduleUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(ScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      moveSelectedRecordsAction,
      setBreakInAction,
      resetBreakInAction,
    } = useGridActions();
    const entity_name = "workorder";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: ScheduleAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: ScheduleAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              action: ScheduleAction.SCHEDULE,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: ScheduleAction.WO_COMPLETION_PCT,
              disabled: !selectedIds,
            },
            {
              title: setBreakInAction.title,
              startIcon: setBreakInAction.icon,
              onClick: setBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: setBreakInAction.disabled,
            },
            {
              title: resetBreakInAction.title,
              startIcon: resetBreakInAction.icon,
              onClick: resetBreakInAction.onClick,
              action: ScheduleAction.WO_BREAKIN,
              disabled: resetBreakInAction.disabled,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {moveSelectedRecordsAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      materialRequirementsAction,
      checkMaterialShortageAction,
      downloadAttachmentsAction,
    } = useGridActions();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: checkMaterialShortageAction.title,
              startIcon: checkMaterialShortageAction.icon,
              onClick: checkMaterialShortageAction.onClick,
              action: ScheduleAction.WO_MATERIAL_SHORTAGE,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: ScheduleAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {downloadAttachmentsAction.renderDialog()}
        {materialRequirementsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(ScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { opMassUpdateAction, assignCalendarAction, unassignCalendarAction } =
      useGridActions();
    const entity_name = "operation";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SCHEDULES}
          items={[
            {
              title: assignCalendarAction.title,
              startIcon: assignCalendarAction.icon,
              onClick: assignCalendarAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_CALENDAR,
            },
            {
              title: unassignCalendarAction.title,
              startIcon: unassignCalendarAction.icon,
              onClick: unassignCalendarAction.onClick,
              disabled: !selectedIds,
              action: ScheduleAction.OP_CALENDAR,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: ScheduleAction.OP_COMPLETION_PCT,
              disabled: !selectedIds,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {assignCalendarAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const {
        renderValidationRulePopupAction,
        renderDependenciesDropdownActions,
        renderUnassignAction,
      } = useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={ScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
        </>
      );
      const renderOperationActions = (): ReactElement => (
        <>
          {renderOperationEditActions()}
          {renderDependenciesDropdownActions()}
          {renderValidationRulePopupAction()}
        </>
      );
      const renderInstanceActions = (): ReactElement => renderUnassignAction();
      return (
        <>
          {currentTab === ScheduleTasksTabId.WORKORDERS &&
            renderWorkOrderActions()}
          {currentTab === ScheduleTasksTabId.OPERATIONS &&
            renderOperationActions()}
          {currentTab === ScheduleTasksTabId.INSTANCES &&
            renderInstanceActions()}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.schedule:tab.work_orders.title"),
        value: ScheduleTasksTabId.WORKORDERS,
      },
      {
        label: t("module.schedule:tab.operations.title"),
        value: ScheduleTasksTabId.OPERATIONS,
      },
      {
        label: t("module.schedule:tab.resources.title"),
        value: ScheduleTasksTabId.RESOURCES,
      },
      {
        label: t("module.schedule:tab.instances.title"),
        value: ScheduleTasksTabId.INSTANCES,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_ORACLE_CLOUD_SCHEDULE_TAB;

  useCurrentTable = (
    props: IUseScheduleCurrentTableProps
  ): EntityTableProvider => {
    const { scheduleContext } = props;
    return () => {
      const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
      const workOrdersTable: IUseEntityTable = useScheduleWorkOrders({
        draggable: false,
        scheduleContext,
      });
      const operationsTable: IUseEntityTable = useScheduleOperations({
        scheduleContext,
      });
      const resourcesTable: IUseEntityTable = useScheduleResources({
        scheduleContext,
      });
      const instancesTable: IUseEntityTable = useScheduleInstances({
        scheduleContext,
      });
      if (currentTab === ScheduleTasksTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === ScheduleTasksTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === ScheduleTasksTabId.RESOURCES) {
        return resourcesTable;
      } else if (currentTab === ScheduleTasksTabId.INSTANCES) {
        return instancesTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === ScheduleTasksTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === ScheduleTasksTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultScheduleWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.workorder:workorder.WorkOrderNumber"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "WorkOrderDescription",
          name: t("entity.workorder:workorder.WorkOrderDescription"),
          editor: TextEditor,
        },
        {
          key: "WorkOrderTypeCode",
          name: t("entity.workorder:workorder.WorkOrderTypeCode"),
        },
        {
          key: "WorkOrderSubTypeCode",
          name: t("entity.workorder:workorder.WorkOrderSubTypeCode"),
        },
        {
          key: "AssetNumber",
          name: t("entity.workorder:workorder.AssetNumber"),
        },
        {
          key: "WorkOrderPriority",
          name: t("entity.workorder:workorder.WorkOrderPriority"),
        },
        {
          key: "WorkOrderStatusCode",
          name: t("entity.workorder:workorder.WorkOrderStatusCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.workorder:workorder.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.workorder:workorder.PlannedCompletionDate"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrder.AssetNumber",
          name: t("entity.operation:operation_workorder.AssetNumber"),
        },
        {
          key: "WorkOrder.WorkOrderStatusCode",
          name: t("entity.operation:operation_workorder.WorkOrderStatusCode"),
        },
        {
          key: "WorkCenterName",
          name: t("entity.operation:operation.WorkCenterName"),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation:operation_resource.ResourceCode"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkCenterName",
          name: t(
            "entity.operation_resource:operation_resource.WorkCenterName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationResource.AssignedUnits",
          name: t("entity.operation_resource:operation_resource.AssignedUnits"),
        },
        {
          key: "PlannedStartDate",
          name: t("entity.operation:operation.PlannedStartDate"),
        },
        {
          key: "PlannedCompletionDate",
          name: t("entity.operation:operation.PlannedCompletionDate"),
        },
      ];
    };
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "WorkOrderNumber",
          name: t("entity.operation:operation.WorkOrderNumber"),
          width: 105,
        },
        {
          key: "OperationSequenceNumber",
          name: t("entity.operation:operation.OperationSequenceNumber"),
        },
        {
          key: "OperationDescription",
          name: t("entity.operation:operation.OperationDescription"),
        },
        {
          key: "WorkOrderOperationResource.ResourceSequenceNumber",
          name: t(
            "entity.operation_resource:operation_resource.ResourceSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationResource.ResourceCode",
          name: t("entity.operation_resource:operation_resource.ResourceCode"),
        },
        {
          key: "WorkOrderOperationResource.WorkOrderOperationResourceInstance.LaborInstanceName",
          name: t(
            "entity.operation_resource_instance:operation_resource_instance.LaborInstanceName"
          ),
        },
        {
          key: "WorkOrderOperationResource.UsageRate",
          name: t("entity.operation_resource:operation_resource.UsageRate"),
        },
        {
          key: "WorkOrderOperationResource.UnitOfMeasure",
          name: t("entity.operation_resource:operation_resource.UnitOfMeasure"),
        },
      ];
    };
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <OracleCloudWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <></>;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.organization",
      "entity.operation_material",
      "entity.resource",
      "entity.resource_utilization",
      "entity.resource_ref",
      "entity.wo_status",
      "entity.wo_type",
      "entity.wo_subtype",
      "entity.operation_resource",
      "entity.operation_resource_instance",
    ];
  }

  isTasksTabAssignable(): () => boolean {
    return (): boolean => {
      const currentTab: ScheduleTasksTabId = this.getCurrentTab()();
      return currentTab === ScheduleTasksTabId.RESOURCES;
    };
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTaskTabKeyColumnMap = (): ScheduleTaskTabKeyColumnMap => {
    const woKey: string = "WorkOrderNumber";
    const opKey: string = "OperationSequenceNumber";
    const resKey: string = "WorkOrderOperationResource.ResourceSequenceNumber";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.INSTANCES]: [{ sourceField: woKey, targetField: woKey }],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.RESOURCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.INSTANCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
      [BacklogTabId.INSTANCES]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
        [BacklogTabId.RESOURCES]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
          { sourceField: resKey, targetField: resKey },
        ],
      },
    };
  };

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      return [];
    };
  }
}
