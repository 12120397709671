import { MouseEvent, FC, ComponentType } from "react";
import Button from "@mui/material/Button";
import styles from "./river-icon-button.module.scss";
import clsx from "clsx";

export interface IRiverIconButtonProps {
  tooltip?: string;
  label: string;
  icon: ComponentType<any>;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  classes?: {
    icon?: string;
  };
  disabled?: boolean;
}

export const RiverIconButton: FC<IRiverIconButtonProps> = (props) => {
  const Icon = props.icon;

  return (
    <Button
      title={props.tooltip}
      onClick={props.onClick}
      className={clsx([styles.root, props.className])}
      startIcon={<Icon className={clsx([styles.icon, props.classes?.icon])} />}
      disabled={props.disabled}
    >
      <span className={styles.actionLabel}>{props.label}</span>
    </Button>
  );
};
