import React, { ReactElement, useContext } from "react";
import { useNotification } from "@river/common-ui";
import { AdapterUiService, userPreferencesService } from "../../../../services";
import {
  ITableFetchFunctionProps,
  RiverTableSelector,
  useRiverSelectColumn,
} from "../../../shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { Column } from "react-data-grid";
import { fetchHelpers } from "../../../../helpers";
import { useTranslation } from "@river/common-ui";

interface ICrewSelectorProps {
  open: boolean;
  onClose: () => void;
  selectedIds: Set<string>;
  onChangeSelectedCrews: (selectedCrewIds: Set<string>) => void;
}

const CREW_KEY_COLUMN = "crew";

export const CrewSelector: React.FC<ICrewSelectorProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const service: AdapterUiService = adapterContext?.service!;
  const notify = useNotification();

  const fetchObjects = async (fetchProps: ITableFetchFunctionProps) => {
    return await service.getAdapterService().fetchCrews(
      fetchHelpers.getTableQuery({
        fetchProps,
        idPropName: CREW_KEY_COLUMN,
      })
    );
  };

  const onChangeSelectedCrews = async (
    selectedCrewIds: Set<string>
  ): Promise<void> => {
    try {
      await userPreferencesService.setUtilizationCrews(
        service.getAdapterService(),
        Array.from(selectedCrewIds)
      );
      props.onChangeSelectedCrews(selectedCrewIds);
    } catch (message) {
      notify.error({ message });
    }
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "crew",
      name: t("entity.crew:crew.crew"),
    },
    {
      key: "description",
      name: t("entity.crew:crew.description"),
    },
  ];

  return (
    <RiverTableSelector
      open={props.open}
      onClose={props.onClose}
      onChangeSelectedIds={onChangeSelectedCrews}
      selectedIds={props.selectedIds}
      dialogTitle={t("shared.crew_selector:crews.title")}
      availableTableTitle={t("shared.crew_selector:label.available_crews")}
      assignedTableTitle={t("shared.crew_selector:label.selected_crews")}
      entityName={"crew"}
      rowKey={CREW_KEY_COLUMN}
      columns={columns}
      fetchObjects={fetchObjects}
    />
  );
};
