import {
  ReactElement,
  useCallback,
  useContext,
  FC,
  useEffect,
  useState,
} from "react";
import { Box } from "@mui/material";
import {
  RiverDateInput,
  RiverDialog,
  useNotification,
  IValidationErrors,
} from "@river/common-ui";
import {
  AdapterAvailabilityGenerationDto,
  IAdapterCalendar,
  IEntityObject,
} from "@river/interfaces";
import {
  useGenerateAvailabilityForm,
  IGenerateAvailabilityForm,
} from "./use-generate-availability-form";
import { LookupType, RiverLookup } from "../../shared";
import {
  IAdapterUiContextState,
  AdapterUiContext,
  TableContext,
} from "../../../context";
import { RiverFormInstance } from "../../../hooks";
import styles from "./generate-availability-dialog.module.scss";
import { useTranslation } from "@river/common-ui";
import { debounce } from "ts-debounce";

interface GenerateAvailabilityDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
  doGenerateAvailability: (
    availabilityDto: AdapterAvailabilityGenerationDto
  ) => void;
}

export const GenerateAvailabilityDialog: FC<GenerateAvailabilityDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const [selectedCalendar, setSelectedCalendar] =
    useState<IAdapterCalendar | null>(null);
  const [isWrongDateRange, setIsWrongDateRange] = useState<boolean>(false);

  const form: RiverFormInstance = useGenerateAvailabilityForm({
    selectedCalendar,
  });

  const fields: IGenerateAvailabilityForm =
    form.standalone as IGenerateAvailabilityForm;

  const {
    onStandalonePropertyChange,
    resetForm,
    validateForm,
    validationErrors,
    validateStandaloneField,
    setStandaloneFields,
  } = form;

  const notify = useNotification();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  useEffect(() => {
    if (props.open) {
      setStandaloneFields({
        ...fields,
        availability_header_ids:
          Array.from(tableContext?.table.selectedRowIds!) || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (fields?.start_date && fields?.end_date) {
      setIsWrongDateRange(fields.start_date > fields.end_date);
    }
  }, [fields.start_date, fields.end_date]);

  useEffect(() => {
    if (fields.calendarName) {
      validateStandaloneField("calendarName");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCalendar]);

  const generateAvailability = async (): Promise<void> => {
    const errors: IValidationErrors = await validateForm();
    if (errors.list.length > 0) {
      return;
    } else if (isWrongDateRange) {
      return;
    }

    const dto: AdapterAvailabilityGenerationDto = {
      calendar_id: fields.calendar_id,
      start_date: fields.start_date!,
      end_date: fields.end_date!,
      availability_header_ids: fields.availability_header_ids,
    };

    try {
      const success = true;
      closeDialog(success);
      props.doGenerateAvailability(dto);
    } catch (message) {
      notify.error({ message });
    }
  };

  // fetch calendar object with calendar id or calendar name
  const fetchCalendar = async (
    attribute_value: string,
    attribute_name: string
  ): Promise<IAdapterCalendar> => {
    const calendars: IAdapterCalendar[] = await adapterContext!.service
      .getAdapterService()
      .fetchCalendars({
        query: {
          $and: [
            {
              attribute_name,
              attribute_value: {
                operator: "$in",
                value: [attribute_value],
              },
            },
          ],
        },
      });
    return calendars[0] as IAdapterCalendar;
  };
  const fetchCalendarByName = async (calendarName: string): Promise<void> => {
    try {
      const calendar: IAdapterCalendar = await fetchCalendar(
        calendarName,
        "calendar"
      );
      setSelectedCalendar(calendar);
      validateStandaloneField("calendarName", calendarName);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchCalendarByName = useCallback(
    debounce((text) => {
      fetchCalendarByName(text);
    }, 600),
    [validationErrors]
  );

  const renderCalendarField = (): ReactElement => (
    <RiverLookup
      id={"calendarName"}
      lookup={{ type: LookupType.CALENDAR }}
      fullWidth
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchCalendarByName(event.target.value);
      }}
      onSelect={(selectedObject: IEntityObject) => {
        const { calendar: calendarName, _id: calendar_id } = selectedObject;
        setStandaloneFields({
          ...fields,
          calendarName,
          calendar_id,
        });
        setSelectedCalendar(selectedObject as IAdapterCalendar);
      }}
    />
  );

  const renderDateRangeFields = (): ReactElement => (
    <Box className={styles.dateRangeFields}>
      <RiverDateInput
        id="start_date"
        className={styles.dateField}
        error={isWrongDateRange}
      />
      <RiverDateInput
        id="end_date"
        className={styles.dateField}
        error={isWrongDateRange}
      />
    </Box>
  );

  return (
    <RiverDialog
      title={t("module.availability:dialog.generate_availability.title")}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText={t("common.button:generate")}
      onSubmit={generateAvailability}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      showActionsDivider={false}
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      {renderCalendarField()}
      {renderDateRangeFields()}
    </RiverDialog>
  );
};
