import { useContext } from "react";
import { RiverModuleContext } from "../context";
import { ModuleKey } from "../components/sidebar-menu";
import { useAllowedAction } from "../components/protected-action";

export namespace commonModuleActions {
  export enum GlobalFilterAction {
    CREATE_GLOBAL_FILTER = "CREATE_GLOBAL_FILTER",
    UPDATE_GLOBAL_FILTER = "UPDATE_GLOBAL_FILTER",
    DELETE_GLOBAL_FILTER = "DELETE_GLOBAL_FILTER",
  }
}

export function useCurrentModule() {
  const moduleContext = useContext(RiverModuleContext);
  const moduleKey: ModuleKey = moduleContext?.moduleKey!;
  const isActionAllowed = useAllowedAction();

  return {
    currentModuleKey: moduleKey,
    isActionAllowed: (action: string) => isActionAllowed(moduleKey, action),
  };
}

export type IUseCurrentModule = ReturnType<typeof useCurrentModule>;
