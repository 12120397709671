import { useNotification, useSimpleDialog } from "@river/common-ui";
import { useTranslation } from "react-i18next";
import { ITableFilter, ITablePreferences } from "../../../interfaces";
import React, { ReactElement, useContext } from "react";
import { userPreferencesService } from "../../../services";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { IUseTable } from "../river-data-grid";
import { TableFilterScope } from "./use-advanced-filters-form";

export const useDeleteFilterConfirmation = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const renderDeleteConfirmation = (filter: ITableFilter): ReactElement => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("shared.saved_filters_dialog:delete_filter_confirmation", {
          name: filter.name,
        }),
      }}
    />
  );

  const deleteFilterConfirmation = useSimpleDialog({
    title: t("shared.saved_filters_dialog:label.delete_filter"),
    confirmButtonText: t("shared.saved_filters_dialog:label.delete_filter"),
  });

  const loadPreferences = async (props?: { global?: boolean }): Promise<any> =>
    await userPreferencesService.getTablePreferences({
      adapter: adapterContext!.service.getAdapterService(),
      tableSaveKey: table.dbSaveKey,
      isGlobal: !!props?.global,
    });

  const savePreferences = (params: {
    preferences: ITablePreferences;
    global?: boolean;
  }): Promise<void> => {
    const { preferences: tablePreferences, global } = params;
    return userPreferencesService.setTablePreferences({
      adapter: adapterContext!.service.getAdapterService(),
      tableSaveKey: table.dbSaveKey,
      tablePreferences,
      isGlobal: global,
    });
  };

  const removeFilter = (
    filterToRemove: ITableFilter,
    preferences: ITablePreferences
  ): void => {
    const filters: ITableFilter[] = preferences.filters || [];
    const index: number = filters.findIndex(
      (filter) => filter.name === filterToRemove.name
    );
    filters.splice(index, 1);
    preferences.filters = filters;
  };

  const deleteFilter = async (
    filterToDelete: ITableFilter,
    scope: TableFilterScope
  ): Promise<void> => {
    try {
      let localSaveRequired: boolean = false;
      let globalSaveRequired: boolean = false;

      const localPreferences: ITablePreferences = await loadPreferences();
      const defaultFilterName = localPreferences.defaultFilterName;
      let globalPreferences: ITablePreferences;

      if (scope === TableFilterScope.LOCAL) {
        removeFilter(filterToDelete, localPreferences);
        localSaveRequired = true;
      } else {
        globalPreferences = await loadPreferences({ global: true });
        removeFilter(filterToDelete, globalPreferences);
        globalSaveRequired = true;
      }

      if (filterToDelete.name === defaultFilterName) {
        delete localPreferences.defaultFilterName;
        localSaveRequired = true;
      }

      if (localSaveRequired) {
        await savePreferences({ preferences: localPreferences });
      }
      if (globalSaveRequired) {
        await savePreferences({
          preferences: globalPreferences!,
          global: true,
        });
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    open: (openProps: {
      filter: ITableFilter;
      scope: TableFilterScope;
      onDelete?: () => void;
    }) => {
      const { filter, scope, onDelete } = openProps;
      deleteFilterConfirmation.open({
        content: renderDeleteConfirmation(filter),
        onConfirm: async () => {
          await deleteFilter(filter, scope);
          onDelete?.();
        },
      });
    },
    render: deleteFilterConfirmation.render,
  };
};

export type IUseDeleteFilterConfirmation = ReturnType<
  typeof useDeleteFilterConfirmation
>;
