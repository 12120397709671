import React, { ReactElement, useContext } from "react";
import { PrimaryButton, useEnv, IUseEnv } from "@river/common-ui";
import {
  AdapterUiContext,
  ExecutionContext,
  IAdapterUiContextState,
} from "../../../context";
import { ColumnSelectorAction } from "../../shared";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";
import { ExecutionUiService } from "../../../services";

export interface IExecutionSubHeader {}

export const ExecutionSubHeader: React.FC<IExecutionSubHeader> = (
  props
): ReactElement => {
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const executionContext = useContext(ExecutionContext);
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const isTableView = uiService.getIsTableView()();

  const onBackToWorkOrdersList = () => {
    executionContext?.setCurrentWorkOrder(null);
  };

  return (
    <div
      className={clsx([
        headerStyles.commonSubheader,
        { [headerStyles.mobile]: isMobile },
      ])}
    >
      <PrimaryButton
        onClick={onBackToWorkOrdersList}
        text={t("common.button:back")}
      />
      {isMobile && isTableView && <ColumnSelectorAction />}
    </div>
  );
};
