import React, { ReactElement, useContext, useState } from "react";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  FilterDropdown,
  GenericAction,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverTextInput,
  useNotification,
  useSimpleDialog,
} from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { TableUiService } from "../../../services";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { TimelinesAction } from "../timelines";

export const TimelinesGridHeader: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();
  const DEFAULT_NUMBER_OF_FOLDERS = 5;
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();

  const [generateScheduleDialogOpened, setGenerateScheduleDialogOpened] =
    useState<boolean>(false);

  const [numberOfFolders, setNumberOfFolders] = useState<number>(
    DEFAULT_NUMBER_OF_FOLDERS
  );

  const deleteSelectedTimeline = async (): Promise<any> => {
    if (table?.selectedRowIds) {
      try {
        table.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .deleteTimeline(getSelectedRowsObjectIds(table)[0]);
        tableContext?.table.refresh();
        deleteTimelineConfirmation.close();
        notify.success(t("module.timelines:notification.timeline_deletion"));
      } catch (message) {
        table.forceLoadingState(false);
        notify.error({ message });
      } finally {
        table.forceLoadingState(false);
      }
    }
  };

  const deleteTimelineConfirmation = useSimpleDialog({
    title: t("module.timelines:message.delete_timeline.title"),
    message: t("module.timelines:message.confirm_timeline_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedTimeline,
  });

  const generateSchedules = async (): Promise<any> => {
    if (table?.selectedRowIds) {
      try {
        table.forceLoadingState(true);
        await adapterContext!.service
          .getAdapterService()
          .generateTimelineFolders({
            timeline_id: Array.from(table.selectedRowIds)[0],
            number_of_folders: numberOfFolders,
          });
        tableContext?.table.refresh();
        setGenerateScheduleDialogOpened(false);
        notify.success(
          t("module.timelines:notification.timeline_schedule_generation")
        );
        setNumberOfFolders(DEFAULT_NUMBER_OF_FOLDERS);
      } catch (message) {
        table.forceLoadingState(false);
        notify.error({ message });
      } finally {
        table.forceLoadingState(false);
      }
    }
  };

  const renderGenerateSchedulesDialog = (): ReactElement => {
    return (
      <RiverDialog
        onClose={() => {
          setGenerateScheduleDialogOpened(false);
        }}
        open={generateScheduleDialogOpened}
        title={t("module.timelines:dialog.generate_schedules.title")}
        showActionsDivider={false}
        actionsContent={
          <RiverDialogActionButton
            text={t("common.button:generate")}
            onClick={generateSchedules}
          />
        }
      >
        <RiverTextInput
          id={"generate_schedules"}
          label={t("module.timelines:dialog.number_of_folders.label")}
          value={numberOfFolders}
          fullWidth
          inputProps={{
            type: "number",
            inputProps: {
              min: 1,
            },
          }}
          onChangeEvent={(event) => {
            if (Number(event.target.value) === 0) {
              setNumberOfFolders(DEFAULT_NUMBER_OF_FOLDERS);
            } else setNumberOfFolders(Number(event.target.value));
          }}
        />
      </RiverDialog>
    );
  };

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.TIMELINES}
            action={TimelinesAction.DELETE}
          >
            <DeleteAction onClick={() => deleteTimelineConfirmation.open()} />
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.TIMELINES}
            action={TimelinesAction.GENERATE_SCHEDULES}
          >
            <GenericAction
              icon={EventAvailableIcon}
              onClick={() => {
                setGenerateScheduleDialogOpened(true);
              }}
              title={t("module.timelines:dialog.generate_schedules.title")}
              enableOnSelection={true}
            />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteTimelineConfirmation.render()}
      {renderGenerateSchedulesDialog()}
    </>
  );
};
