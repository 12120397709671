import React from "react";
import { IAdapterRule } from "@river/interfaces";
import { Tooltip } from "@mui/material";
import chroma, { Color } from "chroma-js";
import styles from "./rule-tile.module.scss";
import clsx from "clsx";

export const DEFAULT_RULE_TILE_COLOR = "#55a1c4";
const BACKGROUND_GRADIENT_BRIGHTEN_OFFSET: number = 1.3;
const BACKGROUND_GRADIENT_DARKEN_OFFSET: number = 1.5;

interface RuleTileProps {
  rule: IAdapterRule;
  entityCount: number;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
}

export const RuleTile: React.FC<RuleTileProps> = (props) => {
  let ruleColor: string = props.rule.display_options
    ? JSON.parse(props.rule.display_options).color
    : DEFAULT_RULE_TILE_COLOR;

  const color: Color = chroma.valid(ruleColor)
    ? chroma(ruleColor)
    : chroma(DEFAULT_RULE_TILE_COLOR);

  const firstGradientPoint = color.brighten(
    BACKGROUND_GRADIENT_BRIGHTEN_OFFSET
  );
  const secondGradientPoint = color.darken(BACKGROUND_GRADIENT_DARKEN_OFFSET);
  return (
    <>
      <div
        className={clsx([
          styles.root,
          props.className,
          { [styles.selected]: props.selected },
        ])}
        onClick={props.onClick}
      >
        <div
          style={{
            background: `linear-gradient(291.39deg, ${firstGradientPoint} 0%, ${secondGradientPoint} 98.74%)`,
          }}
          className={clsx([
            styles.content,
            { [styles.clickable]: !!props.onClick },
            { [styles.zeroCount]: !props.entityCount },
          ])}
        >
          <Tooltip
            title={props.rule.rule}
            placement="top"
            classes={{
              tooltip: styles.tooltip,
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <div className={styles.ruleTitle}>
              <span className={styles.ruleTitleValue}>{props.rule.rule}</span>
            </div>
          </Tooltip>
          <div className={styles.entityCount}>{props.entityCount}</div>
        </div>
        <div className={styles.selectionBorder} />
      </div>
    </>
  );
};
