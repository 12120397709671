import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  QueryAttributeDto,
} from "@river/interfaces";

interface IUseLocatorLookupProps {
  form: RiverFormInstance;
  selectedLocator: any;
  setSelectedLocator: (locator: any) => void;
}

export function useLocatorLookup(props: IUseLocatorLookupProps) {
  const notify = useNotification();

  const { form, selectedLocator, setSelectedLocator } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    validateStandaloneField("locatorCode");

    const LOCATOR_ID = selectedLocator?.["LOCATOR_ID"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { LOCATOR_ID }
    );
    form.setDto(newDto);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocator]);

  const getCustomFilters = (): QueryAttributeDto[] => [
    {
      attribute_name: "INVENTORY_ITEM_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["INVENTORY_ITEM_ID"] as string,
      },
    },
    {
      attribute_name: "SUBINVENTORY_CODE",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["SUBINVENTORY_CODE"] as string,
      },
    },
  ];

  const fetchLocator = async (text: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("completion_locator", {
          query: {
            $and: [
              ...getCustomFilters(),
              {
                attribute_name: "LOCATOR_CODE",
                attribute_value: {
                  operator: "$eq",
                  value: text,
                },
              },
            ],
          },
        });
      const locator: any = results[0] || null;
      setSelectedLocator(locator);
      validateStandaloneField("locatorCode", text);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchLocator = useCallback(
    debounce((text) => {
      fetchLocator(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"locatorCode"}
      fullWidth
      lookup={{
        type: LookupType.ORACLE_EBS_COMPLETION_LOCATOR,
        customFilters: [
          {
            attribute_name: "INVENTORY_ITEM_ID",
            attribute_value: {
              operator: "$eq",
              //@ts-ignore
              value: currentDto["INVENTORY_ITEM_ID"] as string,
            },
          },
          {
            attribute_name: "SUBINVENTORY_CODE",
            attribute_value: {
              operator: "$eq",
              //@ts-ignore
              value: currentDto["SUBINVENTORY_CODE"] as string,
            },
          },
        ],
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchLocator(event.target.value);
      }}
      onSelect={(selectedLocator) => {
        const { LOCATOR_ID, LOCATOR_CODE } = selectedLocator;
        const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
          new OracleEbsAdapterWorkorderCompletionDto(),
          { ...currentDto },
          { LOCATOR_ID }
        );
        form.setDto(newDto);

        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          locatorCode: LOCATOR_CODE,
        });
      }}
    />
  );

  return {
    render,
  };
}

export type IUseLocatorLookup = ReturnType<typeof useLocatorLookup>;
