import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { AdapterShiftDto, IAdapterShift } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useContext } from "react";
import { useAllowedAction } from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { ShiftsAction } from "../shifts";
import { useTranslation } from "@river/common-ui";
import {
  DEFAULT_SHIFT_CARD_COLOR,
  DEFAULT_SHIFT_CARD_ICON,
} from "../shift-card";

export const useShiftForm = (props: {
  shift: IAdapterShift | null;
  onCreate: (shift: IAdapterShift) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<AdapterShiftDto, IAdapterShift, Object>({
    initialDto: Object.assign(new AdapterShiftDto(), {
      shift: "",
      start_hours: 0,
      start_minutes: 0,
      end_hours: 0,
      end_minutes: 0,
      color: DEFAULT_SHIFT_CARD_COLOR,
      icon: DEFAULT_SHIFT_CARD_ICON,
    }),
    entityName: "shift",
    instanceToEdit: props.shift,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterShiftDto): Promise<IAdapterShift> => {
      return await adapterContext!.service.getAdapterService().createShift(dto);
    },
    update: async (dto: AdapterShiftDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateShift(props.shift!._id!, dto);
    },
    labels: {
      shift: t("entity.shift:shift.shift"),
    },
    readOnly: !isActionAllowed(ModuleKey.SHIFTS, ShiftsAction.UPDATE),
  });
};
