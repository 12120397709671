import { ITranslationProps } from "@river/common-ui";
import React, { ReactElement, useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { CrewsUiService } from "../../services";
import { Crews } from "./crews";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <Crews />}</>
);

export const CrewsModule: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService = adapterContext!.service.getCrewsUiService();
  const namespaces: string[] = uiService.getI18nNamespaces();

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );
  return <Component />;
};
