import React, { FC, ReactElement, useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import {
  GridHeader,
  ColumnSelectorDropdown,
  GridActions,
  FilterDropdown,
} from "../../../shared";
import {
  SupervisorScheduleTasksTabId,
  SupervisorScheduleUiService,
} from "../../../../services";
import { SupervisorScheduleGanttHeaderOptions } from "../supervisor-schedule-gantt-header-options";

export const SupervisorScheduleTasksGridHeader: FC = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();

  const renderCustomGridOptions = (): ReactElement => {
    if (currentTab !== SupervisorScheduleTasksTabId.TIMECARDS) {
      return <SupervisorScheduleGanttHeaderOptions />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown
            dynamicFilterActions={uiService.getDynamicFilterActionsForCurrentTab()()}
          />
          {uiService.renderGridActions()()}
        </GridActions>
        {renderCustomGridOptions()}
      </GridHeader>
    </>
  );
};
