import { Column } from "react-data-grid";
import { useEffect, useState } from "react";
import { IEntityDefinition, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { TableContext } from "../../../../context";
import dataGridStyles from "../../../shared/river-data-grid/river-data-grid.module.scss";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../hooks";
import { useRiverSelectColumn } from "../../../shared";

export function useAttributeColumns() {
  const [attributeColumnsDef, setAttributeColumnsDef] = useState<Column<any>[]>(
    [] as any[]
  );
  let columns: Column<any>[];

  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderTextCell, renderCell, renderCellText } =
    useTableCellRenderers();

  const attributeColumns = (
    onAttributeNameClick: (attribute: IEntityObject) => void
  ): Column<any>[] => {
    columns = [
      RiverSelectColumn,
      {
        key: "attribute_name",
        name: t(
          "module.data_dictionary:dialog.entity_attribute.attribute_name"
        ),
        formatter: (formatterProps) => {
          return (
            <TableContext.Consumer>
              {(tableContext) => (
                <>
                  {renderCell({
                    formatterProps,
                    content: (
                      <div
                        className={dataGridStyles.dataGridLink}
                        onClick={() => {
                          tableContext?.table.setSelectedRowIds(new Set());
                          onAttributeNameClick(formatterProps.row);
                        }}
                      >
                        {renderCellText({ formatterProps })}
                      </div>
                    ),
                  })}
                </>
              )}
            </TableContext.Consumer>
          );
        },
      },
      {
        key: "description",
        name: t("module.data_dictionary:dialog.entity_attribute.description"),
      },
      {
        key: "data_type",
        name: t("module.data_dictionary:dialog.entity_attribute.data_type"),
        formatter: (formatterProps) => {
          const columnKey = formatterProps.row[formatterProps.column.key];
          let value: string = columnKey;
          if (columnKey.includes("entity:")) {
            value = columnKey.split(":")[1];
          }
          return renderTextCell({
            formatterProps,
            value,
          });
        },
      },
      {
        key: "input_type",
        name: t("module.data_dictionary:dialog.entity_attribute.input_type"),
      },
      {
        key: "cardinality",
        name: t("module.data_dictionary:dialog.entity_attribute.cardinality"),
      },
      {
        key: "is_primary_key",
        name: t(
          "module.data_dictionary:dialog.entity_attribute.is_primary_key"
        ),
      },
      {
        key: "is_custom",
        name: t("module.data_dictionary:dialog.entity_attribute.is_custom"),
      },
    ];
    return attributeColumnsDef;
  };
  useEffect(() => {
    setAttributeColumnsDef(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { attributeColumns };
}

export type IUseAttributeDef = ReturnType<typeof useAttributeColumns>;

// attributes meta entity def
export const attributeEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "",
    entity_name: "attributes_meta",
  },
  attributes: [
    {
      _id: "attribute_name",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "attribute_name",
      data_type: Constants.data_type.string,
      description: "Attribute Name",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "description",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "description",
      data_type: Constants.data_type.string,
      description: "Description",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "data_type",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "data_type",
      data_type: Constants.data_type.string,
      description: "Data Type",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "input_type",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "input_type",
      data_type: Constants.data_type.string,
      description: "Input Type",
      cardinality: "one",
    },
    {
      _id: "cardinality",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "cardinality",
      data_type: Constants.data_type.string,
      description: "Cardinality",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_primary_key",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_primary_key",
      data_type: Constants.data_type.boolean,
      description: "Primary Key?",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "max_length",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "max_length",
      data_type: Constants.data_type.double,
      description: "Max Length",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "decimals",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "decimals",
      data_type: Constants.data_type.double,
      description: "Number of Decimals",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_primary_key",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_primary_key",
      data_type: Constants.data_type.boolean,
      description: "Primary Key",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_required",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_required",
      data_type: Constants.data_type.boolean,
      description: "Required",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_persistent",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_persistent",
      data_type: Constants.data_type.boolean,
      description: "Persistent",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_custom",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_custom",
      data_type: Constants.data_type.boolean,
      description: "Custom",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "is_frozen",
      adapter_type: "",
      entity_name: "attributes_meta",
      attribute_name: "is_frozen",
      data_type: Constants.data_type.boolean,
      description: "Frozen",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
