import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GridHeader, GridActions, GenericAction } from "../../../shared";
import ProtectedAction from "../../../protected-action";
import { useTranslation } from "@river/common-ui";
import { ModuleKey } from "../../../sidebar-menu";
import { RolesAction } from "../../roles";
import styles from "./assigned-modules-grid-header.module.scss";

interface AssignedModulesGridHeaderProps {
  unassignSelectedModules: () => void;
}

export const AssignedModulesGridHeader: React.FC<
  AssignedModulesGridHeaderProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <GridHeader className={styles.root}>
      <div className={styles.tableTitle}>
        {t("module.roles:label.assigned_modules")}
      </div>
      <GridActions>
        <ProtectedAction module={ModuleKey.ROLES} action={RolesAction.UPDATE}>
          <GenericAction
            icon={ArrowBackIcon}
            onClick={() => {
              props.unassignSelectedModules();
            }}
            className={styles.root}
            title={t("module.roles:label.unassign_selected_modules")}
            customProps={{
              color: "primary",
            }}
            enableOnSelection={true}
          />
        </ProtectedAction>
      </GridActions>
    </GridHeader>
  );
};
