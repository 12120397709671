import { IRelationRendererProps } from "../../schedule-operations-gantt-overlay";
import {
  ganttAbsoluteConstants,
  IRelationConnectorEvents,
  IVerticalLine,
  IHorizontalLine,
} from "../../render-utils";
import { MouseEventHandler, useContext, useState } from "react";
import { IOperationPosition } from "../../use-schedule-operations-overlay";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
} from "../../../../../../../context";
import { TaskHelpersUiService } from "../../../../../../../services";
import { IUseScheduleGantt } from "../../../../use-schedule-gantt";

export interface IUseStartToStart extends IRelationConnectorEvents {
  predecessorStart?: IHorizontalLine;
  vertical?: IVerticalLine;
  successorStart?: IHorizontalLine;
  selected: boolean;
}

const VIRTUAL_Y_OFFSET: number = -4;

export const useStartToStart = (
  props: IRelationRendererProps
): IUseStartToStart => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();
  const { OperationSuccessorProp } = getOperationsOverlayPropertyNames();

  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt = scheduleContext!.ganttRef.current!;
  const {
    MIN_HORIZONTAL_CONNECTOR_LENGTH: MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
    MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH:
      MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT,
    START_TO_START_VIRTUAL_X_OFFSET: VIRTUAL_X_OFFSET_PERCENT,
  } = gantt.ganttRatios!;
  const { OUTWARD_CONNECTOR_Y_OFFSET, INWARD_CONNECTOR_Y_OFFSET } =
    ganttAbsoluteConstants;

  let preLeftStartXPercent: number;
  let preLeftEndXPercent: number;
  let preY: number;
  let succY: number;

  const [selected, setSelected] = useState<boolean>(false);

  const onConnectorMouseOver: MouseEventHandler = (event): void => {
    setSelected(true);
  };

  const onConnectorMouseOut: MouseEventHandler = (event): void => {
    setSelected(false);
  };

  const onConnectorClick: MouseEventHandler = (event): void => {
    event.stopPropagation();
    props.editRelation();
  };

  let hookValue: IUseStartToStart = {
    selected,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const isVirtual: boolean =
    props.relation[OperationSuccessorProp] === "virtual";

  if (!isVirtual && (!props.predecessor || !props.successor)) {
    const pos: IOperationPosition = props.predecessor
      ? props.predecessorPos!
      : props.successorPos!;
    const line: IHorizontalLine = {
      yPos: pos.yPos,
      startXPercent:
        pos.startXPercent - MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
      endXPercent: pos.startXPercent,
    };
    const lineProp: string = props.predecessor
      ? "predecessorStart"
      : "successorStart";
    hookValue = {
      ...hookValue,
      [lineProp]: line,
    };
  } else {
    // predecessor horizontal start connector position
    preLeftStartXPercent =
      props.successorPos!.startXPercent -
        MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT <=
      props.predecessorPos!.startXPercent -
        MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT
        ? props.successorPos!.startXPercent -
          MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT
        : props.predecessorPos!.startXPercent -
          MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT;
    preLeftEndXPercent = props.predecessorPos!.startXPercent;
    preY = props.predecessorPos!.yPos + OUTWARD_CONNECTOR_Y_OFFSET;

    // successor horizontal start connector position
    const succLeftStartXPercent: number = preLeftStartXPercent;
    const succLeftEndXPercent: number = props.successorPos!.startXPercent;
    succY = props.successorPos!.yPos + INWARD_CONNECTOR_Y_OFFSET;

    const existingRelation: IUseStartToStart = {
      ...hookValue,
      predecessorStart: {
        yPos: preY,
        startXPercent: preLeftStartXPercent,
        endXPercent: preLeftEndXPercent,
        endXAbsoluteOffset: -1,
      },
      vertical: {
        yStart: preY,
        yEnd: succY,
        xPercent: preLeftStartXPercent,
      },
      successorStart: {
        yPos: succY,
        startXPercent: succLeftStartXPercent,
        endXPercent: succLeftEndXPercent,
        endXAbsoluteOffset: -1,
      },
    };

    const virtualRelation: IUseStartToStart = {
      ...hookValue,
      predecessorStart: {
        yPos: preY + VIRTUAL_Y_OFFSET,
        startXPercent: preLeftStartXPercent + VIRTUAL_X_OFFSET_PERCENT,
        endXPercent: preLeftEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
      },
      vertical: {
        yStart: preY + VIRTUAL_Y_OFFSET,
        yEnd: succY,
        xPercent: preLeftStartXPercent + VIRTUAL_X_OFFSET_PERCENT,
      },
      successorStart: {
        yPos: succY,
        startXPercent: succLeftStartXPercent + VIRTUAL_X_OFFSET_PERCENT,
        endXPercent: succLeftEndXPercent + VIRTUAL_X_OFFSET_PERCENT,
      },
    };

    hookValue = isVirtual ? virtualRelation : existingRelation;
  }

  return hookValue;
};
