import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ProgressSummaryFilterID } from "../../../../schedule-utilization-ui-service";

interface ISapScheduleProgressPmactTypeFiltersProps {
  rows: any[];
  onSelect: (selectedObjects: any[], filterId: ProgressSummaryFilterID) => void;
  onDelete: (selectedObject: any, filterId: ProgressSummaryFilterID) => void;
}

export const SapScheduleProgressPmactTypeFilters: React.FC<
  ISapScheduleProgressPmactTypeFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const pmacttypeLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((pmacttype) => pmacttype["Pmacttype"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "Pmacttype",
          name: t("entity.pm_activity_type:pm_activity_type.Pmacttype"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.ACTIVITY_TYPES,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "Pmacttype",
            attribute_value: {
              operator: "$nin",
              value: pmacttypeLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selectedObject) =>
        props.onDelete(selectedObject, ProgressSummaryFilterID.PMACTTYPE)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ProgressSummaryFilterID.PMACTTYPE);
      }}
      addFilterLabel={t("module.schedule:utilization_filters.add.pmacttype")}
    />
  );
};
