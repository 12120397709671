import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RiverDropdownActions } from "../river-dropdown-actions";
import { useCurrentModule } from "../../../hooks";
import { useTranslation } from "@river/common-ui";
import { fetchHelpers, useGridActionHelpers } from "../../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
  TabContext,
  TableContext,
} from "../../../context";
import { IUseTable, gridHelpers } from "../river-data-grid";
import {
  AdapterReportCreateDto,
  IAdapterFolder,
  IAdapterReport,
  IAdapterReportConfig,
} from "@river/interfaces";
import {
  DownloadStatusType,
  RiverDownloadDialog,
} from "../river-download-dialog";
import { ExcelIcon } from "../../../icons";
import { GenericAction } from "./actions";
import { Constants } from "@river/constants";
import { ModuleKey } from "../../sidebar-menu";

export const ReportsDropdown: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;
  const entityName: string =
    tableContext?.entity.entityDefinition?.entity.entity_name ||
    tableContext?.entityName!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tabContext = useContext(TabContext);
  const currentTab = tabContext?.selectedTab;
  const scheduleContext = useContext(ScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;

  const { currentModuleKey } = useCurrentModule();
  const [reportToDownload, setReportToDownload] =
    useState<IAdapterReport | null>(null);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const timerIdRef = useRef<number>();
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatusType>(null);
  const [availableReportConfigs, setAvailableReportConfigs] = useState<
    IAdapterReportConfig[]
  >([]);
  const [activeReportConfig, setActiveReportConfig] =
    useState<IAdapterReportConfig | null>(null);

  const title = t("shared.action:excel_export");

  const handleOnClick = (reportConfig: IAdapterReportConfig) => {
    setActiveReportConfig(reportConfig);
    setDialogOpened(true);
  };

  const getReportColumns = () => {
    return table.columns
      .filter((c) => gridHelpers.getNonUtilityColumns(table.columns))
      .map((c) => ({
        attribute: c.key,
        label: c.name as string,
      }));
  };

  const getAvailableReportConfigs = async () => {
    const availableReportsResponse = await adapterContext!.service
      .getAdapterService()
      .getReportConfigsForEntityName(entityName, currentModuleKey);
    setAvailableReportConfigs(availableReportsResponse);
  };

  const renderReportIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={ExcelIcon}
      title={title}
      onClick={handleOnClick}
      {...props}
    />
  );

  const generateReport = async () => {
    setDownloadStatus({ type: "generating" });
    try {
      let createReportDto: AdapterReportCreateDto = {
        report_type:
          activeReportConfig?.report_type || Constants.report_type.excel_basic,
        entity_name: entityName,
        module: currentModuleKey,
        query: fetchHelpers.getQueryWithoutPagination(table.lastRanQueryDto!),
      };

      if (ModuleKey.SCHEDULES === currentModuleKey) {
        createReportDto["folder_id"] = currentSchedule._id!;
      }

      if (!activeReportConfig?.is_custom) {
        createReportDto["columns"] = getReportColumns();
      }

      const reportResponse = await adapterContext!.service
        .getAdapterService()
        .generateReport(createReportDto);

      setReportToDownload(reportResponse);
    } catch (message) {
      setDownloadStatus({ type: "error" });
    }
  };

  const renderDownloadDialog = (): ReactElement => {
    const onClose = () => {
      setDialogOpened(false);
      setDownloadStatus(null);
      stopPolling();
    };

    return (
      <RiverDownloadDialog
        title={title}
        open={dialogOpened}
        status={downloadStatus}
        onDownloadClick={generateReport}
        skipConfirmation
        onClose={onClose}
      />
    );
  };

  const refreshReport = async () => {
    try {
      const reportResponse = await adapterContext!.service
        .getAdapterService()
        .getReportById(reportToDownload!._id!);

      if (reportResponse.status?.code === Constants.report_status.complete) {
        downloadReport();
        stopPolling();
      }

      if (reportResponse.status?.code === Constants.report_status.error) {
        setDownloadStatus({ type: "error" });
        stopPolling();
      }
    } catch (message) {
      setDownloadStatus({ type: "error" });
      stopPolling();
    }
  };

  const downloadReport = async () => {
    try {
      setDownloadStatus({ type: "downloading" });
      await adapterContext!.service
        .getAdapterService()
        .getReportContent(
          reportToDownload!._id!,
          reportToDownload!.result_file_name!
        );
      setDownloadStatus({
        type: "complete",
        message: t("shared.download_dialog:label.complete", {
          file_name: reportToDownload?.result_file_name,
        }),
      });
    } catch (message) {
      setDownloadStatus({ type: "error" });
      stopPolling();
    }
  };

  const startPolling = () => {
    timerIdRef.current = window.setInterval(() => refreshReport(), 1000);
  };

  const stopPolling = () => {
    window.clearInterval(timerIdRef.current);
  };

  useEffect(() => {
    getAvailableReportConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (reportToDownload) {
      startPolling();
    }

    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToDownload]);

  return (
    <>
      <RiverDropdownActions
        module={currentModuleKey}
        items={availableReportConfigs.map((reportConfig) => ({
          title: reportConfig.description!,
          startIcon: withSanitizedProps(renderReportIcon),
          onClick: () => handleOnClick(reportConfig),
        }))}
      />
      {renderDownloadDialog()}
    </>
  );
};
