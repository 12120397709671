import { ObjectType, TableUiService } from "../../services";
import { uiConstants } from "../../helpers";

export class OracleCloudTableUiService extends TableUiService {
  getRowIdAttribute = (rowType: ObjectType): string => {
    const mapping: { [key in ObjectType]?: string } = {
      [uiConstants.rowType.workOrder]: uiConstants.fields._id,
      [uiConstants.rowType.operation]: uiConstants.fields._id,
      [uiConstants.rowType.operation_resource]:
        "WorkOrderOperationResource._id",
      [uiConstants.rowType.resource_instance]:
        "WorkOrderOperationResource.WorkOrderOperationResourceInstance._id",
    };
    return mapping[rowType] || "";
  };
}
