import { ITableFetchFunctionProps } from "../components/shared";
import { uiConstants } from "./";

import {
  QueryAttributeDto,
  QueryAttributeGroupDto,
  QueryAttributeSortDto,
  QueryDto,
} from "@river/interfaces";

const DEFAULT_ID_PROP_NAME: string = uiConstants.fields._id;

export interface IGetTableQueryProps {
  fetchProps: ITableFetchFunctionProps;
  idPropName?: string;
  initialQueryAttributes?: QueryAttributeDto[];
  initialQueryAttributeGroup?: QueryAttributeGroupDto;
  withoutPagination?: boolean;
}

const getColumnFiltersQuery = (
  props: IGetTableQueryProps
): QueryAttributeGroupDto => {
  const { fetchProps, initialQueryAttributes, initialQueryAttributeGroup } =
    props;
  const elements: (QueryAttributeDto | QueryAttributeGroupDto)[] = [];

  if (initialQueryAttributes) {
    elements.push(...initialQueryAttributes);
  }

  if (initialQueryAttributeGroup) {
    elements.push(initialQueryAttributeGroup);
  }

  for (const filter of fetchProps.columnFilters) {
    elements.push({
      attribute_name: filter.field,
      attribute_value: {
        operator: filter.operator,
        value: filter.value,
      },
    });
  }

  const idPropName = props.idPropName || DEFAULT_ID_PROP_NAME;

  if (fetchProps.ids) {
    elements.push({
      attribute_name: idPropName,
      attribute_value: {
        operator: "$in",
        value: fetchProps.ids,
      },
    });
  }
  if (fetchProps.nin) {
    elements.push({
      attribute_name: idPropName,
      attribute_value: {
        operator: "$nin",
        value: fetchProps.nin,
      },
    });
  }

  return { $and: elements };
};

const mergeQueryAttributeGroups = (
  groups: (QueryAttributeGroupDto | undefined)[]
): QueryAttributeGroupDto => {
  const groupsToMerge: QueryAttributeGroupDto[] = groups.filter(
    (group) => !!group
  ) as QueryAttributeGroupDto[];

  if (groupsToMerge.length === 1) {
    return groupsToMerge[0];
  } else {
    return {
      $and: groupsToMerge,
    };
  }
};

export namespace fetchHelpers {
  export const getTableQuery = (props: IGetTableQueryProps): QueryDto => {
    const { fetchProps } = props;
    const { sortColumns } = fetchProps;
    const sort: QueryAttributeSortDto[] = [];
    const query = Object.assign({
      query: mergeQueryAttributeGroups([
        fetchProps.query,
        getColumnFiltersQuery(props),
      ]),
      sort,
    });

    for (const sortColumn of sortColumns) {
      sort.push({
        attribute_name: sortColumn.columnKey,
        sort: sortColumn.direction === "ASC" ? "asc" : "desc",
      });
    }

    if (props?.withoutPagination) {
      return query;
    }

    return Object.assign(query, {
      $skip: fetchProps.skip,
      $limit: fetchProps.limit,
    });
  };

  export const getQueryWithoutPagination = (
    _query: QueryDto
  ): QueryDto | undefined => {
    const { $skip, $limit, ...query } = _query;

    return query;
  };
}
