import {
  IAdapterBaseline,
  IAdapterFolderRef,
  IEntityObject,
} from "@river/interfaces";
import {
  TaskHelpersUiService,
  ObjectType,
  ITaskHelpers,
  IOperationsOverlayPropertyNames,
} from "../task-helpers-ui.service";
import { uiConstants, useEntityHelpers } from "../../helpers";

export class SapTaskHelpersUiService extends TaskHelpersUiService {
  getHelpers(): () => ITaskHelpers {
    return () => {
      const entityHelpers = useEntityHelpers();
      const { getAttributeValue } = entityHelpers;

      const getTaskFolder = (row: IEntityObject): IAdapterFolderRef => {
        const rowType = row[uiConstants.fields.rowType] as string;
        let folder: IAdapterFolderRef;
        if (rowType === uiConstants.rowType.workOrder) {
          folder = getAttributeValue(row, "__folder");
        } else if (rowType === uiConstants.rowType.operation) {
          folder = getAttributeValue(row, "__folder");
        } else if (rowType === uiConstants.rowType.assignment) {
          folder = getAttributeValue(row, "to_Assignment.__folder");
        }
        return folder!;
      };

      const getTaskStartDate = (row: IEntityObject): string => {
        const folder: IAdapterFolderRef = getTaskFolder(row);
        return String(folder.start_date);
      };

      const getTaskEndDate = (row: IEntityObject): string => {
        const folder: IAdapterFolderRef = getTaskFolder(row);
        return String(folder.end_date);
      };

      const getBaselineStartDate = (
        row: any,
        selectedBaseline: IAdapterBaseline | undefined
      ): string => {
        const rowType = row[uiConstants.fields.rowType] as string;
        let baselineStartDate: string = "";
        if (selectedBaseline && row) {
          if (
            rowType === uiConstants.rowType.workOrder ||
            rowType === uiConstants.rowType.operation
          ) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineStartDate = String(baseline.start_date);
              }
            });
          } else if (rowType === uiConstants.rowType.assignment) {
            row?.to_Assignment?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineStartDate = String(baseline.start_date);
              }
            });
          }
        }

        return baselineStartDate;
      };

      const getBaselineEndDate = (
        row: any,
        selectedBaseline: IAdapterBaseline | undefined
      ): string => {
        const rowType = row[uiConstants.fields.rowType] as string;
        let baselineEndDate: string = "";
        if (selectedBaseline) {
          if (
            rowType === uiConstants.rowType.workOrder ||
            rowType === uiConstants.rowType.operation
          ) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineEndDate = String(baseline.end_date);
              }
            });
          } else if (rowType === uiConstants.rowType.assignment) {
            row?.to_Assignment?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineEndDate = String(baseline.end_date);
              }
            });
          }
        }

        return baselineEndDate;
      };

      const getGanttText = (row: IEntityObject): string => {
        const renderWorkOrderText = (): string => {
          const woOrderId: string = getAttributeValue(row, "Orderid");
          const woDescription: string =
            getAttributeValue(row, "ShortText") ?? "";
          return `${woOrderId} - ${woDescription}`;
        };

        const renderOperationText = (): string => {
          const startDate: Date = new Date(
            getAttributeValue(row, "__folder.start_date")
          );
          const opStartTime = new Intl.DateTimeFormat(navigator.language, {
            timeZone: "UTC",
            timeStyle: "short",
          }).format(startDate);
          // const opStartTime: string = `${startDate.getUTCHours()}:${startDate
          //   .getUTCMinutes()
          //   .toString()
          //   .padStart(2, "0")}`;
          const opDescription: string =
            getAttributeValue(row, "Description") ?? "";
          return `${opStartTime} - ${opDescription}`;
        };

        const renderAssignmentText = (): string => {
          const asPersonName: string = getAttributeValue(
            row,
            "to_Assignment.PersonName"
          );
          return `${asPersonName}`;
        };

        const rowType = row[uiConstants.fields.rowType] as ObjectType;
        switch (rowType) {
          case uiConstants.rowType.workOrder:
            return renderWorkOrderText();
          case uiConstants.rowType.operation:
            return renderOperationText();
          case uiConstants.rowType.assignment:
            return renderAssignmentText();
        }
        return "";
      };

      const getOperationsOverlayPropertyNames =
        (): IOperationsOverlayPropertyNames => {
          return {
            to_Relations_path: "to_Relations",
            OrderIdProp: "Orderid",
            OrderNumberProp: "Orderid",
            OrderPredecessorProp: "OrderPredecessor",
            OrderSuccessorProp: "OrderSuccessor",
            OperationPredecessorProp: "OperationPredecessor",
            OperationSuccessorProp: "OperationSuccessor",
            RelationTypeProp: "RelationType",
            OperationActivityProp: "Activity",
            LagHoursProp: "DurationRelationHours",
          };
        };

      const getStartDateFieldName = (obj: IEntityObject): string => {
        const rowType = obj[uiConstants.fields.rowType] as ObjectType;
        if (rowType === "assignment") {
          return "to_Assignment.__folder.start_date";
        } else {
          return "__folder.start_date";
        }
      };

      const getEndDateFieldName = (obj: IEntityObject): string => {
        const rowType = obj[uiConstants.fields.rowType] as ObjectType;
        if (rowType === "assignment") {
          return "to_Operation.to_Assignment.__folder.end_date";
        } else {
          return "__folder.end_date";
        }
      };

      return {
        getTaskFolder,
        getTaskStartDate,
        getTaskEndDate,
        getBaselineStartDate,
        getBaselineEndDate,
        getGanttText,
        getOperationsOverlayPropertyNames,
        getStartDateFieldName,
        getEndDateFieldName,
      };
    };
  }
}
