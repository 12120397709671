import { FC, useEffect, useContext, ReactElement } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  ScheduleContext,
  SidebarContext,
  TableContext,
  TabContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../../context";
import { helpers, uiConstants } from "../../../helpers";
import { RowsChangeData } from "react-data-grid";
import { ScheduleTasksTabHeader } from "./schedule-tasks-tab-header";
import { ScheduleTasksGridHeader } from "./schedule-tasks-grid-header";
import { ScheduleList } from "../schedule-list";
import { ScheduleGantt } from "../schedule-gantt";
import { useNavigate, useLocation } from "react-router";
import {
  ScheduleUiService,
  ScheduleTasksTabURLParamName,
  ScheduleTasksTabId,
  TableUiService,
} from "../../../services";
import {
  DEFAULT_SCHEDULE_TASKS_VIEW,
  ScheduleTasksViewId,
  ScheduleTasksViewURLParamName,
} from "./schedule-gantt-header-options";
import { useTaskColor } from "../../../hooks";
import styles from "./schedule-tasks.module.scss";
import clsx from "clsx";

interface IScheduleTasksProps {
  className?: string;
}

export const ScheduleTasks: FC<IScheduleTasksProps> = (props): ReactElement => {
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const scheduleContext = useContext(ScheduleContext)!;
  const sidebarContext = useContext(SidebarContext);
  const notify = useNotification();
  const getTaskColor = useTaskColor();

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const view =
    params.get(ScheduleTasksViewURLParamName) || DEFAULT_SCHEDULE_TASKS_VIEW;
  const currentTab: ScheduleTasksTabId = uiService.getCurrentTab()();
  const { entity: currentEntity, table: currentTable } =
    uiService.useCurrentTable({ scheduleContext })();
  uiService.taskTableRefs[currentTab] = currentTable;

  const currentEntityName =
    currentEntity?.entityDefinition?.entity.entity_name || "workorder";

  useEffect(() => {
    return () => {
      sidebarContext?.setIsShowingStats(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedTab = (tabId: string): void => {
    navigate(
      helpers.replaceOrSetUrlParam(
        location.pathname,
        params,
        ScheduleTasksTabURLParamName,
        tabId
      )
    );
  };

  scheduleContext.setCurrentScheduleTasksTabFnRef.current = setSelectedTab;

  if (!currentTable) {
    return <></>;
  }

  if (scheduleContext && scheduleContext.currentTasksTableRef) {
    scheduleContext.currentTasksTableRef.current = currentTable;
  }

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const rowId = getObjectId(rows[rowData.indexes[0]]);
    const newValue = rows[rowData.indexes[0]][rowData.column.key];
    const rowType = rows[rowData.indexes[0]][uiConstants.fields.rowType];
    const update = async () => {
      try {
        currentTable.forceLoadingState(true);
        const updatedRow = await adapterContext!.service
          .getAdapterService()
          .updateEntityData(rowType, rowId, {
            [rowData.column.key]: newValue,
          });
        currentTable.updateRow({ rowId, updatedRow });
      } catch (message) {
        console.log(message);
        notify.error({ message });
      } finally {
        currentTable.forceLoadingState(false);
      }
    };

    update();
  };

  const renderView = () => {
    switch (view) {
      case ScheduleTasksViewId.LIST:
        return (
          <ScheduleList
            setColumnSize={currentTable.setColumnSize}
            columns={currentTable.columns}
            data={currentTable.entities}
            onRowsChange={onRowsChange}
            sortColumns={currentTable.sortColumns}
            setSortColumns={currentTable.setSortColumns}
            rowKeyGetter={currentTable.rowKeyGetter}
          />
        );
      case ScheduleTasksViewId.GANTT:
        return (
          <ScheduleGantt
            setColumnSize={currentTable.setColumnSize}
            columns={currentTable.columns}
            data={currentTable.entities}
            onRowsChange={onRowsChange}
            sortColumns={currentTable.sortColumns}
            setSortColumns={currentTable.setSortColumns}
            rowKeyGetter={currentTable.rowKeyGetter}
            getTaskColor={getTaskColor}
          />
        );
    }
  };

  const isLoading: boolean = currentTable.isLoading || !site;

  return (
    <TableContext.Provider
      value={{
        entityName: currentEntityName,
        table: currentTable,
        entity: currentEntity!,
      }}
    >
      <TabContext.Provider
        value={{
          selectedTab: currentTab,
          setSelectedTab,
        }}
      >
        <div className={clsx([styles.root, props.className])}>
          <ScheduleTasksTabHeader />
          <ScheduleTasksGridHeader />
          <RiverSpinner show={isLoading} />
          {renderView()}
        </div>
      </TabContext.Provider>
    </TableContext.Provider>
  );
};
