import React, { useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { ScheduleSelector } from "../../backlog/schedules-pane/schedule-selector";
import headerStyles from "../../shared/common-header/common-header.module.scss";

interface IValidationDashboardSubHeader {
  selectedScheduleIds: Set<string>;
  onChangeSelectedSchedules: (selectedScheduleIds: Set<string>) => void;
}

export const ValidationDashboardSubHeader: React.FC<
  IValidationDashboardSubHeader
> = (props) => {
  const { t } = useTranslation();
  const [schedulesSelectorOpened, setSchedulesSelectorOpened] =
    useState<boolean>(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <PrimaryButton
          onClick={() => setSchedulesSelectorOpened(true)}
          text={t("module.validation_dashboard:button.select_schedules")}
        />
      </div>
      <ScheduleSelector
        open={schedulesSelectorOpened}
        onClose={() => setSchedulesSelectorOpened(false)}
        onChangeSelectedIds={props.onChangeSelectedSchedules}
        selectedIds={props.selectedScheduleIds}
      />
    </>
  );
};
