import React, {
  ReactElement,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { Constants } from "@river/constants";
import { RiverDialog, useNotification, useTranslation } from "@river/common-ui";
import { IAdapterAsyncAction } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { AdapterService } from "../../../../services";
import { AsyncProgressSpinner } from "./async-progress-spinner";
import { RiverProgressBar } from "../../river-progress-bar";
import styles from "./river-async-progress.module.scss";
import clsx from "clsx";

const DEFAULT_PENDING_STATUS_TEXT: string =
  "shared.river_async_progress:status_pending.default_label";
const DEFAULT_ACTION_IN_PROGRESS_TEXT: string =
  "shared.river_async_progress:action_in_progress.default_label";
const ACTION_REFRESH_INTERVAL: number = 1000;

export interface IRiverAsyncProgressProps {
  title: string;
  action: IAdapterAsyncAction;
  onClose: () => void;
  onSuccess?: (action: IAdapterAsyncAction) => void;
  onError?: (action: IAdapterAsyncAction) => void;
  onComplete?: (action: IAdapterAsyncAction) => void;
  pendingStatusText?: string;
  actionInProgressText?: string;
}

const progressTypeClassMapping: { [progressType: string]: string } = {
  [Constants.async_action_progress_type.none]: styles.progressTypeNone,
  [Constants.async_action_progress_type.percentage]:
    styles.progressTypePercentage,
  [Constants.async_action_progress_type.processed_records]:
    styles.progressTypeProcessedRecords,
  [Constants.async_action_progress_type.processed_of_total_records]:
    styles.progressTypeProcessedOfTotal,
};

export const RiverAsyncProgress: React.FC<IRiverAsyncProgressProps> = (
  props: IRiverAsyncProgressProps
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapter: AdapterService = adapterContext!.service.getAdapterService();
  const [action, setAction] = useState<IAdapterAsyncAction>(props.action);
  const [dialogOpened, setDialogOpened] = useState<boolean>(true);

  const isPending: boolean =
    action.status === Constants.async_action_status.pending;
  const isRunning: boolean =
    action.status === Constants.async_action_status.running;
  const isProgressTypeNone: boolean =
    action.progress_type === Constants.async_action_progress_type.none;
  const isProgressTypePercentage: boolean =
    action.progress_type === Constants.async_action_progress_type.percentage;
  const isProgressTypeProcessedRecords: boolean =
    action.progress_type ===
    Constants.async_action_progress_type.processed_records;
  const isProgressTypeProcessedOfTotal: boolean =
    action.progress_type ===
    Constants.async_action_progress_type.processed_of_total_records;

  const intervalRef = useRef<number>();
  const notify = useNotification();

  const isActionCompleted = (action: IAdapterAsyncAction): boolean => {
    return (
      [
        Constants.async_action_status.success,
        Constants.async_action_status.error,
        Constants.async_action_status.cancelled,
      ].indexOf(action.status) !== -1
    );
  };

  const processActionComplete = (newAction: IAdapterAsyncAction): void => {
    const { status, status_description } = newAction;
    if (status === Constants.async_action_status.error) {
      notify.error(status_description!);
      props.onError?.(newAction);
    } else if (status === Constants.async_action_status.success) {
      props.onSuccess?.(newAction);
    }
    props.onComplete?.(newAction);
  };

  const processAction = (newAction: IAdapterAsyncAction): void => {
    // console.log(`processing action ${JSON.stringify(newAction)}`);
    setAction(newAction);
    if (isActionCompleted(newAction)) {
      // console.log(`clearing interval for action ${newAction._id}`);
      closeDialog();
      processActionComplete(newAction);
    }
  };

  // For testing purposes only
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mockAction = (a: IAdapterAsyncAction) => {
    /*a.status = Constants.async_action_status.pending;*/
    a.status = Constants.async_action_status.running;
    a.progress_type = Constants.async_action_progress_type.percentage;
    a.progress = 85;
    //a.total_records = 478;
  };

  const refreshActionStatus = async (): Promise<void> => {
    try {
      const newAction: IAdapterAsyncAction = await adapter.getAsyncActionStatus(
        props.action._id
      );
      // For testing purposes
      // mockAction(newAction);
      processAction(newAction);
    } catch (message) {
      notify.error({ message });
      closeDialog();
    }
  };

  useEffect(() => {
    // console.log(`starting interval for action ${action._id}`);
    intervalRef.current = window.setInterval(() => {
      refreshActionStatus();
    }, ACTION_REFRESH_INTERVAL);
    return () => {
      // console.log(`clearing interval for action ${action._id}`);
      window.clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = (): void => {
    window.clearInterval(intervalRef.current);
    setDialogOpened(false);
    props.onClose();
  };

  const renderPendingAction = (): ReactElement => {
    const pendingStatusText: string = t(
      props.pendingStatusText || DEFAULT_PENDING_STATUS_TEXT
    );
    return (
      <div className={styles.pendingAction}>
        <AsyncProgressSpinner title={t(pendingStatusText)} />
      </div>
    );
  };

  const renderProgressTypeNone = (): ReactElement => {
    const actionInProgressText: string = t(
      props.actionInProgressText || DEFAULT_ACTION_IN_PROGRESS_TEXT
    );
    return <AsyncProgressSpinner title={t(actionInProgressText)} />;
  };

  const renderProgressTypeProcessedRecords = (): ReactElement => {
    const processedRecordsText: string = t(
      "shared.river_async_progress:processed_num_records",
      { processed_num: action.total_records }
    );
    return <AsyncProgressSpinner title={processedRecordsText} />;
  };

  const renderProgressTypeProcessedOfTotal = (): ReactElement => {
    const { progress, total_records } = action;
    const title: string = t(
      "shared.river_async_progress:processed_num_records_of_total",
      { processed_num: progress, total_records }
    );
    return (
      <RiverProgressBar
        title={title}
        processed={progress}
        total={total_records}
      />
    );
  };

  const renderProgressTypePercentage = (): ReactElement => (
    <RiverProgressBar percentage={action.progress} showValue={true} />
  );

  const renderRunningAction = (): ReactElement => (
    <div
      className={clsx([
        styles.runningAction,
        progressTypeClassMapping[action.progress_type],
      ])}
    >
      {isProgressTypeNone && renderProgressTypeNone()}
      {isProgressTypeProcessedRecords && renderProgressTypeProcessedRecords()}
      {isProgressTypePercentage && renderProgressTypePercentage()}
      {isProgressTypeProcessedOfTotal && renderProgressTypeProcessedOfTotal()}
    </div>
  );

  const renderAction = (): ReactElement => (
    <div className={styles.actionContainer}>
      {isPending && renderPendingAction()}
      {isRunning && renderRunningAction()}
    </div>
  );

  return (
    <RiverDialog
      open={dialogOpened}
      title={props.title}
      showTitleDivider={false}
      showActionsDivider={false}
      closeButtonText={t("common.label:close")}
      onClose={closeDialog}
      showXClose={false}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
    >
      {renderAction()}
    </RiverDialog>
  );
};
