import { Constants } from "@river/constants";
import {
  EnvTranslatableDto,
  IEntityDefinition,
  IEntityObject,
} from "@river/interfaces";
import React, { useContext, useEffect, useState } from "react";
import { Column, RowsChangeData, TextEditor } from "react-data-grid";
import ProtectedAction from "../protected-action";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import {
  ITableFetchFunctionProps,
  RiverDataGrid,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { LanguageGridHeader } from "./language-grid-header";
import { LanguageHeader } from "./language-header";
import { LanguageSubheader } from "./language-subheader/language-subheader";
import { useParams } from "react-router";
import { useNotification } from "@river/common-ui";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation, withTranslation } from "react-i18next";
import { ModuleKey } from "../sidebar-menu";
import { LanguagesAction } from "../languages/languages";
import styles from "./language.module.scss";

export const Language: React.FC = () => {
  usePageCleanup();

  const entityName: string = "language";
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const { RiverSelectColumn } = useRiverSelectColumn();

  const languageId = useParams<{ language_id: string }>().language_id;
  const notify = useNotification();

  const [selectedLanguage, setSelectedLanguage] =
    useState<IEntityObject | null>(null);

  const getSelectedLanguage = async (): Promise<void> => {
    const environmentLanguages = await adapterContext!.service
      .getAdapterService()
      .getEnvironmentLanguages();
    environmentLanguages?.forEach((item) => {
      if (item._id === languageId) {
        setSelectedLanguage(item);
        return;
      }
    });
  };

  useEffect(() => {
    getSelectedLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const languageEntityDef: IEntityDefinition = {
    entity: {
      _id: "",
      adapter_type: "sap",
      entity_name: "language",
    },
    attributes: [
      {
        _id: "namespace",
        adapter_type: "sap",
        entity_name: "language",
        attribute_name: "namespace",
        data_type: Constants.data_type.string,
        description: "Namespace",
        cardinality: "one",
        is_persistent: true,
      },
      {
        _id: "key",
        adapter_type: "sap",
        entity_name: "language",
        attribute_name: "key",
        data_type: Constants.data_type.string,
        description: "Key",
        cardinality: "one",
        is_persistent: true,
      },
      {
        _id: "content",
        adapter_type: "sap",
        entity_name: "language",
        attribute_name: "content",
        data_type: Constants.data_type.string,
        description: "Content",
        cardinality: "one",
        is_persistent: true,
      },
      {
        _id: "is_translated",
        adapter_type: "sap",
        entity_name: "language",
        attribute_name: "is_translated",
        data_type: Constants.data_type.boolean,
        description: "Is translated?",
        cardinality: "one",
        is_persistent: true,
      },
      {
        _id: "translation",
        adapter_type: "sap",
        entity_name: "language",
        attribute_name: "translation",
        data_type: Constants.data_type.string,
        description: "Translation",
        cardinality: "one",
        is_persistent: true,
      },
    ],
    relations: [],
    indexes: [],
  };

  const entity: IUseEntity = useEntity({
    entityName,
    definition: languageEntityDef,
  });

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "namespace",
      name: t("module.languages:language_entity.namespace"),
    },
    { key: "key", name: t("module.languages:language_entity.key") },
    {
      key: "content",
      name: t("module.languages:language_entity.content"),
    },
    {
      key: "is_translated",
      name: t("module.languages:language_entity.is_translated"),
    },
    {
      key: "translation",
      name: t("module.languages:language_entity.translation"),
      editor: (formatterProps) => (
        <ProtectedAction
          module={ModuleKey.LANGUAGES}
          action={LanguagesAction.UPDATE_TRANSLATION}
        >
          {TextEditor(formatterProps)}
        </ProtectedAction>
      ),
      formatter: (formatterProps) => {
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div className={styles.formatterContainer}>
                  <p className={styles.formatterText}>
                    {formatterProps.row.translation}
                  </p>
                  <ProtectedAction
                    module={ModuleKey.LANGUAGES}
                    action={LanguagesAction.UPDATE_TRANSLATION}
                  >
                    <EditIcon className={styles.formatterIcon} />
                  </ProtectedAction>
                </div>
              ),
            })}
          </>
        );
      },
    },
  ];

  const fetchLanguageTranslations = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    return (await adapterContext!.service
      .getAdapterService()
      .getEnvLanguageTranslations(
        selectedLanguage?.language as string,
        fetchHelpers.getTableQuery({ fetchProps })
      )) as unknown as IEntityObject[];
  };

  const table: IUseTable = useTable({
    entityName,
    columns,
    fetchFunction: fetchLanguageTranslations,
    fetchOn: true,
    fetchTriggers: [selectedLanguage],
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const newValue = rows[rowData.indexes[0]][rowData.column.key];
    const selectedRow = rows[rowData.indexes[0]];

    const update = async () => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .createEnvLanguageTranslation(
            selectedLanguage?.language as string,
            { ...selectedRow, content: newValue } as EnvTranslatableDto
          );
        await table.fetch();
      } catch (message) {
        notify.error({ message });
      }
    };

    update();
  };
  return (
    <TableContext.Provider value={{ table, entity }}>
      <LanguageHeader selectedLanguage={selectedLanguage as IEntityObject} />
      <LanguageSubheader />
      <LanguageGridHeader />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={(row) => row[uiConstants.fields._id]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        onRowsChange={onRowsChange}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
    </TableContext.Provider>
  );
};

export default withTranslation([])(Language);
