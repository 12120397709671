import { IRiverDrawerContext, RiverDrawerContext } from "../context";
import { useContext, useEffect } from "react";

const useDrawerCleanup = () => {
  const { drawer }: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { closeDrawer } = drawer;
  useEffect(() => {
    return () => {
      closeDrawer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const usePageCleanup = () => {
  useDrawerCleanup();
  return {};
};

export type IUsePageCleanup = ReturnType<typeof usePageCleanup>;
