import { useContext } from "react";
import { HeaderRendererProps, HeaderRenderer } from "react-data-grid";
import { useDrag, useDrop } from "react-dnd";
import { SidebarContext } from "../../../../context";
import { ColumnFilter } from "../column-filter";
import styles from "./river-grid-header.module.scss";
import clsx from "clsx";
import { helpers } from "../../../../helpers";

const DEFAULT_ENABLE_COLUMN_REORDER = true;

interface IRiverGridHeaderProps<R> extends HeaderRendererProps<R> {
  enableColumnReorder?: boolean;
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}

export function RiverGridHeader<R>({
  onColumnsReorder,
  column,
  sortDirection,
  onSort,
  priority,
  enableColumnReorder,
}: IRiverGridHeaderProps<R>) {
  enableColumnReorder = enableColumnReorder ?? DEFAULT_ENABLE_COLUMN_REORDER;

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const sidebarContext = useContext(SidebarContext);

  return (
    <div
      className={clsx([
        styles.root,
        { [styles.isDragging]: isDragging },
        { [styles.isOver]: isOver },
      ])}
      ref={
        enableColumnReorder ? helpers.useCombinedRefs(drag, drop) : undefined
      }
    >
      <div className={styles.columnNameContainer}>
        <HeaderRenderer
          sortDirection={sortDirection}
          // Also consider Command click on OSX
          onSort={(ctrlClick: any) =>
            onSort(ctrlClick || (sidebarContext?.isCommandClicked ?? false))
          }
          priority={priority}
          column={column}
          allRowsSelected={false}
          onAllRowsSelectionChange={(checked: boolean) => {}}
          isCellSelected={false}
        />
      </div>
      <ColumnFilter className={styles.columnFilter} column={column} />
    </div>
  );
}
