import { FC } from "react";
import { PageHeader } from "../../page-header";
import { useTranslation } from "@river/common-ui";

export const AttachmentSelectionRulesHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <PageHeader
      pageTitle={t(
        "module.attachment-selection-rules:attachment-selection-rules.title"
      )}
    />
  );
};
