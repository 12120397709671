import React, { ReactElement } from "react";
import {
  IAdapterFolder,
  IAdapterSchedulingComplianceSummary,
} from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import styles from "./schedule-kpis.module.scss";
import clsx from "clsx";

interface IScheduleKpi {
  label: string;
  value: string;
}

interface IScheduleKpisProps {
  schedule: IAdapterFolder;
  complianceData?: IAdapterSchedulingComplianceSummary | null | undefined;
  className?: string;
  classes?: {
    root?: string;
    item?: string;
    itemLabel?: string;
    itemValue?: string;
  };
}

export const ScheduleKpis: React.FC<IScheduleKpisProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { schedule, complianceData } = props;

  const kpis: IScheduleKpi[] = [
    {
      label: t("shared.schedule_kpis:num_of_wo"),
      value: schedule?.workorder_count?.toString() ?? "0",
    },
    {
      label: t("shared.schedule_kpis:num_of_operations"),
      value: schedule?.operation_count?.toString() ?? "0",
    },
    {
      label: t("shared.schedule_kpis:break_in_wo"),
      value: complianceData?.breakin_workorders?.toString() ?? "0",
    },
    {
      label: t("shared.schedule_kpis:scheduled_hours"),
      value: complianceData?.scheduled_hours?.toString() ?? "0",
    },
    {
      label: t("shared.schedule_kpis:completed_hours"),
      value: complianceData?.timecard_hours?.toString() ?? "0",
    },
    {
      label: t("shared.schedule_kpis:actual_hours"),
      value: complianceData?.actual_hours?.toString() ?? "0",
    },
  ];

  function renderItem(item: IScheduleKpi, index: number) {
    return (
      <div className={clsx([styles.item, props.classes?.item])} key={index}>
        <span className={clsx([styles.itemLabel, props.classes?.itemLabel])}>
          {item.label}
        </span>
        <div className={clsx([styles.itemValue, props.classes?.itemValue])}>
          <span>{Math.round(Number(item.value) * 100) / 100}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {complianceData && (
        <div
          className={clsx([styles.root, props.className, props.classes?.root])}
        >
          {kpis.map((item, index) => renderItem(item, index))}
        </div>
      )}
    </>
  );
};
