import { AdapterUiService } from "./adapter-ui.service";

export abstract class SupervisorSchedulesUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  getI18nNamespaces(): string[] {
    return [
      "module.supervisor_schedules",
      "module.calendars",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.river_table_selector",
      "shared.grid_header",
      "shared.column_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.lookup",
      "shared.error_messages",
      "common.button",
      "common.label",
      "entity.folder",
      "entity.status_ref",
      "entity.calendar",
      "shared.river_data_grid",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }
  //-----------------------
  // ERP-specific
  //-----------------------
  abstract getErpSpecificI18nNamespaces(): string[];
}
