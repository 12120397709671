import { FC, useContext, useEffect, useState } from "react";
import { CustomCronTasksContext, TableContext } from "../../../../context";
import { isValidCron } from "cron-validator";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSwitch,
  RiverSpinner,
  RiverTextInput,
  useTranslation,
} from "@river/common-ui";
import { useCustomCronTaskForm } from "./use-custom-cron-task-form";
import { Box } from "@mui/material";
import { RiverCodeInput } from "../../../river-code-input";
import { ISystemCronTaskWithEnv } from "@river/interfaces";
import styles from "./custom-cron-task-dialog.module.scss";

interface ICronFrequency {
  minutes: string;
  hours: string;
  dayOfMonth: string;
  month: string;
  dayOfWeek: string;
}

export const CustomCronTaskDialog: FC = () => {
  const { t } = useTranslation();
  const customCronTasksContext = useContext(CustomCronTasksContext);
  const tableContext = useContext(TableContext);
  const [cronFrequency, setCronFrequency] = useState<ICronFrequency | null>(
    null
  );

  function handleSetInitCronFrequency() {
    const tmpSelected = customCronTasksContext?.selected;
    const tmpValues = tmpSelected?.frequency?.split(" ") ?? "";
    setCronFrequency({
      minutes: tmpValues?.[0] ?? "*",
      hours: tmpValues?.[1] ?? "*",
      dayOfMonth: tmpValues?.[2] ?? "*",
      month: tmpValues?.[3] ?? "*",
      dayOfWeek: tmpValues?.[4] ?? "*",
    });
  }

  function getFrequencyInputValue() {
    return `${cronFrequency?.minutes ?? "*"} ${cronFrequency?.hours ?? "*"} ${
      cronFrequency?.dayOfMonth ?? "*"
    } ${cronFrequency?.month ?? "*"} ${cronFrequency?.dayOfWeek ?? "*"}`;
  }

  function handleCloseModal() {
    resetForm();
    customCronTasksContext?.setOpenDialog(false);
    customCronTasksContext?.setSelected(null);
  }

  function handleCloseAndRefresh() {
    tableContext?.table.refresh();
    handleCloseModal();
  }

  function isCronFrequencyValid(cronValue: string) {
    try {
      return isValidCron(cronValue);
    } catch {
      return false;
    }
  }

  const form = useCustomCronTaskForm({
    cron: customCronTasksContext?.selected!,
    onUpdate: handleCloseAndRefresh,
    onCreate: handleCloseAndRefresh,
  });
  const { submit, resetForm, isProcessing, dto, setDto } = form;

  const handleSetDto = (values: ISystemCronTaskWithEnv) => {
    setDto(values);
  };

  const renderCronFrequencyRow = (key: keyof ICronFrequency, name: string) => {
    return (
      <>
        <Box className={styles.frequencyContainerSetupRow}>
          <RiverTextInput
            className={styles.frequencyContainerSetupRowInput}
            descriptionClassName={
              styles.frequencyContainerSetupRowInputDescription
            }
            description={`${t(
              "module.cron:form_input_description.allowed_chars"
            )}: ${t(
              `module.cron:form_input_description.allowed_chars.${name}`
            )}`}
            value={cronFrequency ? cronFrequency[key] : ""}
            label={t(`module.cron:form_label.${name}`)}
            inputProps={{
              required: true,
            }}
            onChange={(value) => {
              if (cronFrequency) {
                setCronFrequency({
                  ...cronFrequency,
                  [key]: value,
                });
              }
            }}
            id={`frequency_${name}`}
          />
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (formData && cronFrequency) {
      handleSetDto({
        ...formData,
        frequency: `${cronFrequency.minutes} ${cronFrequency.hours} ${cronFrequency.dayOfMonth} ${cronFrequency.month} ${cronFrequency.dayOfWeek}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronFrequency]);

  useEffect(() => {
    handleSetInitCronFrequency();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formData = dto as ISystemCronTaskWithEnv;
  const title = !!customCronTasksContext?.selected
    ? t("module.cron:dialog.custom_cron.edit_cron")
    : t("module.cron:dialog.custom_cron.create_cron");
  return (
    <RiverDialog
      classes={{
        paper: styles.paper,
      }}
      form={form}
      title={title}
      open={!!customCronTasksContext?.openDialog}
      actionButtonText={t("common.button:submit")}
      closeButtonText={t("common.button:save")}
      onSubmit={submit}
      onClose={handleCloseModal}
      showActionsDivider={true}
      dialogProps={{
        maxWidth: false,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={handleCloseModal}
            text={t("common.button:close")}
          />
          <RiverDialogActionButton
            onClick={submit}
            disabled={!isCronFrequencyValid(formData.frequency)}
            text={t("common.button:save")}
          />
        </>
      }
    >
      <RiverSpinner show={isProcessing} />
      <Box className={styles.root}>
        <Box className={styles.row}>
          <RiverTextInput id="name" fullWidth={true} />
          <RiverTextInput id="task_code" fullWidth={true} />
        </Box>
        <Box className={styles.row}>
          <RiverTextInput
            id="description"
            fullWidth={true}
            className={styles.description}
          />
          <RiverSwitch
            id="enabled"
            label={
              formData.enabled
                ? t("module.cron:from.enabled")
                : t("module.cron:from.disabled")
            }
            className={styles.enabled}
          />
        </Box>
        <RiverCodeInput id={"action"} />
        <Box className={styles.frequencyContainer}>
          <span className={styles.customLabel}>
            {t("module.cron:cron_entity.frequency")}
          </span>
          <RiverTextInput
            value={getFrequencyInputValue()}
            fullWidth={true}
            error={!isCronFrequencyValid(formData.frequency)}
            inputProps={{ readOnly: true }}
            id="frequency"
            label=""
          />

          <Box className={styles.frequencyContainerSetup}>
            {renderCronFrequencyRow("minutes", "minute")}
            {renderCronFrequencyRow("hours", "hour")}
            {renderCronFrequencyRow("dayOfMonth", "day")}
            {renderCronFrequencyRow("month", "month")}
            {renderCronFrequencyRow("dayOfWeek", "weekday")}
          </Box>
        </Box>
      </Box>
    </RiverDialog>
  );
};
