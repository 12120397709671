import { IEntityDefinition } from "@river/interfaces";
import { Constants } from "@river/constants";

export const systemCronTasksEntityDef: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "",
    entity_name: "system_cron_tasks",
  },
  attributes: [
    {
      _id: "task_code",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "task_code",
      data_type: Constants.data_type.string,
      description: "Job code",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "description",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "description",
      data_type: Constants.data_type.string,
      description: "Description",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "frequency",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "frequency",
      data_type: Constants.data_type.string,
      description: "Frequency",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "last_execution_date",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "last_execution_date",
      data_type: Constants.data_type.date,
      description: "Last Run Date",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "status",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "status",
      data_type: "entity:status_ref",
      description: "Last Run Status",
      cardinality: "one",
      is_persistent: true,
    },
    {
      _id: "enabled",
      adapter_type: "sap",
      entity_name: "system_cron_tasks",
      attribute_name: "enabled",
      data_type: Constants.data_type.boolean,
      description: "Enabled",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};
