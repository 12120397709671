import React, { ReactElement } from "react";
import {
  useTable,
  IUseTable,
  RiverDataGrid,
  RiverDefaultBooleanFormatter,
} from "../../../../components/shared";
import { TextEditor } from "react-data-grid";
import { TableContext } from "../../../../context";
import {
  getBlankEntity,
  RiverFormInstance,
  useTableCellRenderers,
} from "../../../../hooks";
import { Column, RowsChangeData } from "react-data-grid";
import {
  IOracleEbsAdapterWorkorderCompletion,
  OracleEbsAdapterWorkorderCompletionDto,
} from "@river/interfaces";
import { useEntityHelpers } from "../../../../helpers";

interface IUseMeterReadingsProps {
  form: RiverFormInstance;
  rows: any[];
}

export const useMeterReadings = (props: IUseMeterReadingsProps) => {
  const { form } = props;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;
  const { renderTextCell } = useTableCellRenderers();
  const { formatDateStringAsUTC } = useEntityHelpers();

  const columns: Column<any>[] = [
    {
      key: "METER_NAME",
      name: "Meter Name",
      width: 185,
    },
    {
      key: "EAM_REQUIRED_FLAG",
      name: "Mandatory",
      width: 70,
      formatter: (formatterProps) => {
        return (
          <RiverDefaultBooleanFormatter
            formatterProps={formatterProps}
            checked={formatterProps.row["EAM_REQUIRED_FLAG"] === "Y"}
          />
        );
      },
    },
    {
      key: "VALUE_CHANGE_DIR_MEANING",
      name: "Direction",
      width: 140,
    },
    {
      key: "METER_UOM",
      name: "UOM",
      width: 70,
    },
    {
      key: "LAST_READING_VALUE",
      name: "Last Value",
      width: 105,
    },
    {
      key: "LAST_READING_DATE",
      name: "Last Reading Date",
      width: 190,
      formatter: (formatterProps) => (
        <>
          {renderTextCell({
            formatterProps,
            value: formatDateStringAsUTC(
              formatterProps.row[formatterProps.column.key]
            ),
          })}
        </>
      ),
    },
    {
      key: "READING_VALUE",
      name: "Value",
      editor: TextEditor,
    },
  ];

  const table: IUseTable = useTable({
    columns,
    fetchFunction: async () => [...props.rows],
    fetchTriggers: [props.rows],
    fetchOn: true,
    enableColumnReorder: false,
  });

  const entity = getBlankEntity();

  const onRowChange = (rowChangeProps: {
    row: any;
    key: string;
    value: string;
  }): void => {
    const { row, key, value } = rowChangeProps;
    const meterReadings: any[] = currentDto["METER_READINGS"] ?? [];
    const rowIndex: number = meterReadings.findIndex(
      (mr) => mr["METER_ID"] === row["METER_ID"]
    );
    if (rowIndex > -1) {
      const meterReading: any = meterReadings[rowIndex];

      meterReading[key] = getMeterReadingValue();
      const newDto: IOracleEbsAdapterWorkorderCompletion = Object.assign(
        { ...currentDto },
        { METER_READINGS: [...meterReadings] }
      );
      form.setDto(newDto);
    }

    function getMeterReadingValue(): any {
      if (key === "READING_VALUE") {
        return value === "" ? null : Number(value);
      }
    }
  };

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const row: any = rows[rowData.indexes[0]];
    const key: string = rowData.column.key;
    const value: string = row[key];
    onRowChange({ row, key, value });
  };

  const renderTable = (): ReactElement => {
    return (
      <TableContext.Provider value={{ table, entity }}>
        <RiverDataGrid
          columns={table.columns}
          rows={table.entities}
          defaultColumnOptions={{
            sortable: false,
            resizable: true,
          }}
          onRowsChange={onRowsChange}
        />
      </TableContext.Provider>
    );
  };

  return {
    renderTable,
  };
};

export type IUseMeterReadings = ReturnType<typeof useMeterReadings>;
