import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ProgressSummaryFilterID } from "../../../../schedule-utilization-ui-service";

interface IOracleEbsScheduleProgressWOTypeFiltersProps {
  rows: any[];
  onSelect: (selectedObjects: any[], filterId: ProgressSummaryFilterID) => void;
  onDelete: (selectedObject: any, filterId: ProgressSummaryFilterID) => void;
}

export const OracleEbsScheduleProgressWOTypeFilters: React.FC<
  IOracleEbsScheduleProgressWOTypeFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const woTypeLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((woType) => woType["LOOKUP_CODE"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "MEANING",
          name: t("entity.wo_type:wo_type.MEANING"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.ORACLE_EBS_WO_TYPE,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "LOOKUP_CODE",
            attribute_value: {
              operator: "$nin",
              value: woTypeLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selectedObject) =>
        props.onDelete(selectedObject, ProgressSummaryFilterID.WO_TYPES)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ProgressSummaryFilterID.WO_TYPES);
      }}
      addFilterLabel={t("module.schedule:utilization_filters.add.wo_type")}
    />
  );
};
