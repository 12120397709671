import { useContext, FC, PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { IMenuItem } from "./use-sidebar-menu-def";
import { useSidebarMenu } from "./use-sidebar-menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { SidebarContext } from "../../context";
import { useEnv, useTranslation } from "@river/common-ui";
import styles from "./sidebar-menu.module.scss";
import clsx from "clsx";

const MyAccordionSummary: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AccordionSummary
      classes={{
        root: styles.accordionSummary,
        content: styles.menuItem,
      }}
    >
      {children}
    </AccordionSummary>
  );
};

interface ISidebarMenu {
  className?: string | undefined;
}

export const SidebarMenu: FC<ISidebarMenu> = (props) => {
  const { t } = useTranslation();
  const env = useEnv();
  const { isMobile } = env;
  const menu = useSidebarMenu();
  const { menuItems, currentAccordionKey } = menu;
  const sidebarContext = useContext(SidebarContext);

  const renderMenuItem = (item: IMenuItem, index: number) => {
    if (!item.enabled) return undefined;
    let element = <></>;

    const onChildItemClick = () => {
      if (isMobile) {
        sidebarContext?.setCollapsed({ collapsed: true });
      }
      if (typeof item.action === "function") {
        item.action();
      }
    };

    const renderItemBody = (isParent?: boolean) => (
      <>
        {item.renderIcon!()}
        <span className={styles.menuItemLabel}>{`${item.title}${
          isParent ? ` ${t("common.label:module")}` : ""
        }`}</span>
      </>
    );

    const renderParentItem = (containerProps: any) => {
      Object.assign(containerProps, {
        className: clsx([styles.menuItem, item.id]),
      });
      return (
        <MyAccordionSummary {...containerProps}>
          {renderItemBody(true)}
        </MyAccordionSummary>
      );
    };

    const renderCustomItem = (containerProps: any) => (
      <div {...containerProps}>{item.render!()}</div>
    );

    const renderLinkItem = (containerProps: any) => {
      Object.assign(containerProps, {
        to: item.linkTo,
        className: clsx([styles.menuItem, item.id]),
        onClick: onChildItemClick,
      });
      return <NavLink {...containerProps}>{renderItemBody()}</NavLink>;
    };

    const renderActionItem = (containerProps: any) => {
      Object.assign(containerProps, {
        onClick: onChildItemClick,
        className: clsx([styles.menuItem, item.id]),
      });

      return (
        <div className={styles.menuItem} {...containerProps}>
          {renderItemBody()}
        </div>
      );
    };

    let containerProps: any = { draggable: false };
    if (typeof index !== "undefined") {
      containerProps.key = index;
    }
    if (Array.isArray(item.children) && item.children.length > 0) {
      element = renderParentItem(containerProps);
    } else if (typeof item.render === "function") {
      element = renderCustomItem(containerProps);
    } else if (item.linkTo) {
      element = renderLinkItem(containerProps);
    } else if (typeof item.action === "function") {
      element = renderActionItem(containerProps);
    }

    return element;
  };

  return (
    <>
      <div
        className={clsx([
          styles.menu,
          props.className,
          { [styles.mobile]: isMobile },
        ])}
      >
        {menuItems
          ?.filter((item) => item.show)
          ?.map((item, menuIndex) => {
            if (!item.enabled) return undefined;
            if (item.enabled && item?.children && item.children.length) {
              // Collapsable root item with children
              return (
                <Accordion
                  expanded={currentAccordionKey === item.id}
                  disableGutters
                  key={menuIndex}
                  classes={{
                    root: styles.accordion,
                  }}
                >
                  {renderMenuItem(item, menuIndex)}
                  <AccordionDetails
                    classes={{
                      root: styles.accordionDetails,
                    }}
                  >
                    <div className={clsx([styles.accordionDetailsContainer])}>
                      {item.children.map((child, childIndex) => {
                        if (!item.enabled) return undefined;
                        return renderMenuItem(child, childIndex);
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            } else {
              // Standalone root item (w/o children)
              return (
                <div key={menuIndex} className={styles.standaloneItemWrapper}>
                  {renderMenuItem(item, menuIndex)}
                </div>
              );
            }
          })}
      </div>
    </>
  );
};
