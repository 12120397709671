import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  ScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { uiConstants } from "../../helpers";
import {
  ScheduleUtilizationUiService,
  ScheduleUtilizationTabId,
  IScheduleComplianceFilters,
} from "../../services";
import { IAdapterBaseline, IAdapterFolder, QueryDto } from "@river/interfaces";
import {
  TableFetchFunction,
  IUseTable,
  useTable,
} from "../../components/shared";

interface IUseScheduleUtilizationComplianceProps {
  currentBaseline: IAdapterBaseline | undefined;
  complianceSummaryFilters: IScheduleComplianceFilters;
}

export const useScheduleUtilizationCompliance = (
  props: IUseScheduleUtilizationComplianceProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: ScheduleUtilizationTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const fetchSchedulingCompliance: TableFetchFunction = async () => {
    const customFilters: QueryDto | undefined =
      await uiService.getComplianceFilters({
        adapterContext,
        complianceFilters: props.complianceSummaryFilters,
      })();
    return await adapterContext!.service
      .getAdapterService()
      .fetchFolderSchedulingCompliance(
        currentSchedule._id,
        props.currentBaseline?._id || null,
        customFilters
      );
  };
  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "scheduling_compliance_summary",
      columns: [],
    };
  };

  const { entityName } = getTableParams(t);

  const table: IUseTable = useTable({
    saveKey: "utilization.compliance",
    columns: [],
    fetchFunction: fetchSchedulingCompliance,
    dependencies: [!!site, currentTab === ScheduleUtilizationTabId.COMPLIANCE],
    fetchOn: currentTab === ScheduleUtilizationTabId.COMPLIANCE,
    fetchTriggers: [props.currentBaseline, props.complianceSummaryFilters],
    rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
