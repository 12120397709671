import { IEntityObject } from "@river/interfaces";
import React, { ReactElement } from "react";
import { PageHeader } from "../../page-header";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import headerStyles from "../../shared/common-header/common-header.module.scss";

import styles from "./language-header.module.scss";
import { useTranslation } from "@river/common-ui";

interface ILanguageHeader {
  selectedLanguage: IEntityObject;
}

export const LanguageHeader: React.FC<ILanguageHeader> = (props) => {
  const { t } = useTranslation();

  const renderNavigationBreadcrumbs = (): ReactElement => {
    return (
      <>
        {props.selectedLanguage && (
          <div className={styles.selectedLanguageBreadcrumb}>
            <ArrowForwardIosIcon className={styles.forwardArrow} />
            <span className={styles.languageName}>
              {props.selectedLanguage.language as string}
            </span>
            <span className={headerStyles.numberOfEntries}>
              ({props.selectedLanguage.name as string})
            </span>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <PageHeader
        pageTitle={t("module.languages:module.title")}
        pageTitleLink={"/languages"}
        showSiteSelector={false}
      >
        {renderNavigationBreadcrumbs()}
      </PageHeader>
    </>
  );
};
