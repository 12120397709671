import React, { ReactElement } from "react";
import {
  GridHeader,
  FilterDropdown,
  ColumnSelectorDropdown,
  GridActions,
} from "../../../shared";

export const SiteDialogGridHeader: React.FC = (): ReactElement => (
  <GridHeader>
    <GridActions>
      <ColumnSelectorDropdown />
      <FilterDropdown />
    </GridActions>
  </GridHeader>
);
