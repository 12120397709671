import { TFunction } from "i18next";
import { MaterialRequirementsUiService } from "../material-requirements-ui.service";
import { IUseEntityTableParams } from "../ui-service.types";

export class OracleCloudMaterialRequirementsUiService extends MaterialRequirementsUiService {
  getMaterialRequirementsTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      columns: [
        {
          key: "WorkOrderNumber",
          name: t("entity.workorder:workorder.WorkOrderNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.OperationSequenceNumber",
          name: t(
            "entity.operation_material:operation_material.OperationSequenceNumber"
          ),
        },
        {
          key: "WorkOrderOperationMaterial.ItemNumber",
          name: t("entity.operation_material:operation_material.ItemNumber"),
        },
        {
          key: "WorkOrderOperationMaterial.Quantity",
          name: t("entity.operation_material:operation_material.Quantity"),
        },
        {
          key: "WorkOrderOperationMaterial.UnitOfMeasure",
          name: t("entity.operation_material:operation_material.UnitOfMeasure"),
        },
        {
          key: "WorkOrderOperationMaterial.RequiredDate",
          name: t("entity.operation_material:operation_material.RequiredDate"),
        },
        {
          key: "WorkOrderOperationMaterial.IssuedQuantity",
          name: t(
            "entity.operation_material:operation_material.IssuedQuantity"
          ),
        },
        {
          key: "WorkOrderOperationMaterial.ReservedQuantity",
          name: t(
            "entity.operation_material:operation_material.ReservedQuantity"
          ),
        },
      ],
      entityName: "operation",
    };
  };
  getWorkOrderNumberColumn = () => "WorkOrderNumber";
}
