import React, { ReactElement, useContext } from "react";
import { TableContext } from "../../../context";
import { IEntityObject } from "@river/interfaces";
import styles from "./execution-details.module.scss";
import { useEntityHelpers } from "../../../helpers";

export const ExecutionDetails: React.FC = (): ReactElement => {
  const tableContext = useContext(TableContext);
  const currentWorkOrder: IEntityObject = tableContext?.table?.entities[0]!;
  const entityHelpers = useEntityHelpers();

  const renderWoTable = (): ReactElement => {
    return (
      <>
        {tableContext?.table.columns.map((column, index) => {
          return (
            <div className={styles.propGroup} key={index}>
              <div className={styles.propRow}>
                <div className={styles.propLabel}>{column.name}</div>
                <div className={styles.propValue}>
                  {entityHelpers.getFormattedEntityAttribute(
                    currentWorkOrder,
                    column.key,
                    tableContext
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      {currentWorkOrder && <div className={styles.root}>{renderWoTable()}</div>}
    </>
  );
};
