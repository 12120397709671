import React, { ReactElement, useContext } from "react";

import {
  useFilterAction,
  useClearFilterAction,
  useSavedFiltersAction,
  useAdvancedFiltersAction,
  useSaveFilterAction,
} from "./actions";
import {
  DROPDOWN_SEPARATOR,
  IRiverDropdownActionsItem,
  RiverDropdownActions,
} from "../river-dropdown-actions";
import { TableContext } from "../../../context";

interface IFilterDropdownProps {
  dynamicFilterActions?: IRiverDropdownActionsItem[];
}
export const FilterDropdown: React.FC<IFilterDropdownProps> = (
  props
): ReactElement => {
  const { filterAction } = useFilterAction();
  const { clearFilterAction } = useClearFilterAction();
  const { advancedFiltersAction } = useAdvancedFiltersAction();
  const { savedFiltersAction } = useSavedFiltersAction();
  const { saveFilterAction } = useSaveFilterAction();

  const tableContext = useContext(TableContext);

  const defaultItems: IRiverDropdownActionsItem[] = [
    {
      title: filterAction.title,
      startIcon: filterAction.icon,
      onClick: filterAction.onClick,
    },
    {
      title: clearFilterAction.title,
      startIcon: clearFilterAction.icon,
      onClick: clearFilterAction.onClick,
      disabled: clearFilterAction.disabled,
    },
  ];

  let advancedItems: IRiverDropdownActionsItem[] = [
    ...defaultItems,
    DROPDOWN_SEPARATOR,
    {
      title: saveFilterAction.title,
      startIcon: saveFilterAction.icon,
      onClick: saveFilterAction.onClick,
      disabled: saveFilterAction.disabled,
    },
    DROPDOWN_SEPARATOR,
    ...(props.dynamicFilterActions ? props.dynamicFilterActions : []),
    DROPDOWN_SEPARATOR,
    {
      title: advancedFiltersAction.title,
      startIcon: advancedFiltersAction.icon,
      onClick: advancedFiltersAction.onClick,
    },
    DROPDOWN_SEPARATOR,
    {
      title: savedFiltersAction.title,
      startIcon: savedFiltersAction.icon,
      onClick: savedFiltersAction.onClick,
    },
  ];

  const items = tableContext?.table.useAdvancedFilters
    ? advancedItems
    : defaultItems;

  return (
    <>
      <RiverDropdownActions items={items} />
      {advancedFiltersAction.renderDrawer()}
      {savedFiltersAction.renderActionBody()}
      {saveFilterAction.renderDialog()}
    </>
  );
};
