import React, { RefObject, ReactElement, useContext, useRef } from "react";
import ReactDataGrid, {
  Column,
  DataGridHandle,
  DataGridProps,
} from "react-data-grid";
import { TableContext } from "../../../context";
import { debounce } from "ts-debounce";
import { IUseEnv, useEnv } from "@river/common-ui";
import { RiverPaginator } from "./river-paginator";
import { IAttribute } from "@river/interfaces";
import styles from "./river-data-grid.module.scss";
import clsx from "clsx";

export enum RiverDataGridTheme {
  LIGHT = "light",
  DARK = "dark",
}

export const DEFAULT_ROW_HEIGHT = 40;
export const DEFAULT_HEADER_ROW_HEIGHT = 50;
const DEFAULT_THEME = RiverDataGridTheme.LIGHT;
const DEFAULT_DISABLE_SELECT_ALL = false;
const DEFAULT_SINGLE_SELECT = false;
const COLUMN_RESIZE_DEBOUNCE_TIME = 300;

interface IRiverDataGridProps extends DataGridProps<any, any, any> {
  theme?: RiverDataGridTheme;
  disableSelectAll?: boolean;
  singleSelect?: boolean;
  innerRef?: RefObject<DataGridHandle>;
}

export const RiverDataGrid: React.FC<IRiverDataGridProps> = (
  props
): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const tableContext = useContext(TableContext);
  const { table, entity } = tableContext!;

  const localInnerRef = useRef<DataGridHandle>(null);
  const innerRef = props.innerRef || localInnerRef;
  table.domRef.current = innerRef?.current!;

  const getThemeClass = (): string => {
    const theme: RiverDataGridTheme = props.theme || DEFAULT_THEME;
    return {
      [RiverDataGridTheme.LIGHT]: "rdg-light",
      [RiverDataGridTheme.DARK]: "rdg-dark",
    }[theme];
  };
  const isAllSelected = (): boolean => {
    const numSelected = table.selectedRowIds?.size || 0;
    const size = table.entities?.length;
    return numSelected > 0 && numSelected === size;
  };
  const getRowHeight = (): any => props.rowHeight || DEFAULT_ROW_HEIGHT;
  const getHeaderRowHeight = (): any =>
    props.headerRowHeight || DEFAULT_HEADER_ROW_HEIGHT;
  const selectAllDisabled: boolean =
    props.disableSelectAll ?? DEFAULT_DISABLE_SELECT_ALL;
  const singleSelect: boolean = props.singleSelect ?? DEFAULT_SINGLE_SELECT;
  let { ...gridProps } = props;

  const onColumnResize = debounce((idx: number, width: number) => {
    const tableColumns: Column<any>[] = table.columns!;
    const column: Column<any> = tableColumns[idx]!;
    Object.assign(column, { width });
    const newColumns: Column<any>[] = [...tableColumns];
    table.setColumns(newColumns);
  }, COLUMN_RESIZE_DEBOUNCE_TIME);

  const onInfiniteScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      table.entities.length &&
      !table.isLoading &&
      isScrollAtBottom(event) &&
      // checks if there more pages available
      !table.isOnLastPage
    ) {
      table.fetch({ isScrolling: true });
    }
  };

  function isScrollAtBottom({
    currentTarget,
  }: React.UIEvent<HTMLDivElement>): boolean {
    return (
      currentTarget.scrollTop + 10 >=
      currentTarget.scrollHeight - currentTarget.clientHeight
    );
  }

  /**
   * Maps attribute.is_persistent to column.sortable for entity columns.
   * This disables sorting for calculated columns.
   */
  const processColumns = (): Column<any>[] =>
    !entity
      ? // no entity, use columns as-is
        (props.columns as Column<any>[])
      : // fetch matching attribute if exists, and map attribute.is_persistent to column.sortable
        props.columns.map((column) => {
          const attribute: IAttribute = entity.attributesByName.get(
            column.key
          )!;
          return !attribute
            ? column
            : {
                ...column,
                // keep sortable overrides, if any
                sortable: column.sortable ?? !!attribute.is_persistent,
              };
        });

  gridProps = {
    ...gridProps,
    columns: processColumns(),
    selectedRows: table.selectedRowIds,
    className: clsx([
      table.getCSSClassFromSaveKey(),
      props.className,
      styles.root,
      { [styles.mobile]: isMobile },
      getThemeClass(),
      { [styles.allSelected]: isAllSelected() },
      { [styles.selectAllDisabled]: selectAllDisabled },
    ]),
    rowHeight: getRowHeight,
    headerRowHeight: getHeaderRowHeight(),
    onColumnResize: onColumnResize,
    onScroll: (event: React.UIEvent<HTMLDivElement>) => {
      props?.onScroll?.(event);
      if (table.infiniteScrolling) {
        onInfiniteScroll(event);
      }
    },
  };

  if (singleSelect) {
    Object.assign(gridProps, {
      onSelectedRowsChange: (selectedRows: Set<any>) => {
        let newSet: Set<any> = selectedRows;
        if (selectedRows.size > 1) {
          const currentSelectedId: any = Array.from(table.selectedRowIds)[0];
          const newSelectedId: any = Array.from(selectedRows).find(
            (id) => id !== currentSelectedId
          );
          newSet = new Set<any>([newSelectedId]);
        }
        table.setSelectedRowIds(newSet);
        props.onSelectedRowsChange?.(newSet);
      },
    });
  } else {
    Object.assign(gridProps, {
      onSelectedRowsChange: (selectedRows: Set<any>) => {
        table.setSelectedRowIds(selectedRows);
        props.onSelectedRowsChange?.(selectedRows);
      },
    });
  }

  const isTableReady: boolean = table.saveKey
    ? table.tablePreferencesLoaded
    : true;

  return (
    <div className={styles.dataGridWrapper}>
      {isTableReady && (
        <>
          <ReactDataGrid {...gridProps} ref={innerRef} />
          <RiverPaginator />
        </>
      )}
    </div>
  );
};
