import {
  IUseRiverAsyncProgress,
  useRiverAsyncProgress,
} from "./river-async-progress";
import { IAdapterAsyncAction } from "@river/interfaces";
import { IUseTable } from "../river-data-grid";
import {
  INotificationMessage,
  useNotification,
  useTranslation,
} from "@river/common-ui";
import { useContext } from "react";
import { AdapterUiContext } from "../../../context";

interface IUseAsyncResourceSyncProps {
  onComplete?: (action: IAdapterAsyncAction) => void;
}

export const useAsyncResourceSync = (props?: IUseAsyncResourceSyncProps) => {
  const { t } = useTranslation();
  const notify = useNotification();
  const adapterContext = useContext(AdapterUiContext);

  const getSuccessNotification = (opts: {
    action: IAdapterAsyncAction;
  }): INotificationMessage => {
    let notification: INotificationMessage;
    notification = {
      message: t(
        "module.availability:notification.synchronize_resources_success"
      ),
    };
    return notification;
  };

  const resourceSyncProgress: IUseRiverAsyncProgress = useRiverAsyncProgress({
    title: t("shared.async_actions:resource_sync.progress_title"),
    onSuccess: (action: IAdapterAsyncAction) =>
      notify.info(
        getSuccessNotification({
          action,
        })
      ),
    onComplete: (action: IAdapterAsyncAction) => {
      props?.onComplete?.(action);
    },
  });

  const doAsyncResourceSync = async (table?: IUseTable) => {
    try {
      const asyncAction: IAdapterAsyncAction = await adapterContext!.service
        .getAdapterService()
        .synchronizeResources();
      resourceSyncProgress.setAction(asyncAction);
    } catch (message) {
      notify.error({ message });
    }
  };

  return {
    resourceSyncProgress,
    doAsyncResourceSync,
  };
};

export type IUseAsyncResourceSync = ReturnType<typeof useAsyncResourceSync>;
