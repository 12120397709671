import { FC, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import { AdapterUiContext, ScheduleReportContext } from "../../../../context";
import {
  IScheduleProgressFilters,
  ProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import {
  OracleEbsScheduleProgressWOTypeFilters,
  OracleEbsScheduleProgressDepartmentFilters,
  OracleEbsScheduleProgressResourceFilters,
} from "./filters";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters";

export interface IOracleEbsScheduleReportProgressFiltersDto
  extends IScheduleProgressFilters {
  [ProgressSummaryFilterID.WO_TYPES]: any[];
  [ProgressSummaryFilterID.DEPARTMENTS]: any[];
  [ProgressSummaryFilterID.RESOURCES]: any[];
}

export const OracleEbsScheduleReportProgressFilters: FC = () => {
  const adapterContext = useContext(AdapterUiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const notify = useNotification();
  const scheduleReportContext = useContext(ScheduleReportContext);
  const {
    progressFiltersOpened,
    progressSummaryFilters,
    setProgressSummaryFilters,
    currentSchedule,
  } = scheduleReportContext!;
  const fetchProgressSummaryFilters = async () => {
    try {
      setLoading(true);
      const result: IOracleEbsScheduleReportProgressFiltersDto =
        await userPreferencesService.getScheduleProgressFilters(
          adapterContext!.service.getAdapterService()
        );
      if (result) {
        setProgressSummaryFilters(result);
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onDeleteProgressSummaryFilter = async (
    selectedObject: any,
    filterId: ProgressSummaryFilterID
  ) => {
    let columnId: string;
    if (filterId === ProgressSummaryFilterID.WO_TYPES) {
      columnId = "LOOKUP_CODE";
    } else if (filterId === ProgressSummaryFilterID.DEPARTMENTS) {
      columnId = "DEPARTMENT_ID";
    } else {
      columnId = "RESOURCE_ID";
    }

    const newDto: IOracleEbsScheduleReportProgressFiltersDto = {
      ...(progressSummaryFilters as IOracleEbsScheduleReportProgressFiltersDto),
      [filterId]: (progressSummaryFilters as any)[filterId]?.filter(
        (selected: any) => selected[columnId] !== selectedObject[columnId]
      ),
    };

    try {
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        newDto
      );
      setProgressSummaryFilters(newDto);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  const onSelectProgressSummaryFilters = async (
    selectedObjects: any[],
    filterId: ProgressSummaryFilterID
  ) => {
    try {
      const payload: IOracleEbsScheduleReportProgressFiltersDto = {
        ...(progressSummaryFilters as IOracleEbsScheduleReportProgressFiltersDto),
        [filterId]: [
          ...((progressSummaryFilters as any)?.[filterId] ?? []),
          ...selectedObjects,
        ],
      };
      setLoading(true);
      await userPreferencesService.setScheduleProgressFilters(
        adapterContext!.service.getAdapterService(),
        payload
      );
      setProgressSummaryFilters(payload);
    } catch (message) {
      notify.error({ message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!currentSchedule) {
      fetchProgressSummaryFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule]);

  const renderWOTypeFilters = () => (
    <OracleEbsScheduleProgressWOTypeFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleReportProgressFiltersDto)[
          ProgressSummaryFilterID.WO_TYPES
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  const renderDepartmentFilters = () => (
    <OracleEbsScheduleProgressDepartmentFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleReportProgressFiltersDto)[
          ProgressSummaryFilterID.DEPARTMENTS
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  const renderResourceFilters = () => (
    <OracleEbsScheduleProgressResourceFilters
      rows={
        (progressSummaryFilters as IOracleEbsScheduleReportProgressFiltersDto)[
          ProgressSummaryFilterID.RESOURCES
        ]
      }
      onSelect={onSelectProgressSummaryFilters}
      onDelete={onDeleteProgressSummaryFilter}
    />
  );

  return (
    <Collapse in={progressFiltersOpened} orientation="horizontal" timeout={300}>
      <ChartFiltersContainer>
        {renderWOTypeFilters()}
        {renderDepartmentFilters()}
        {renderResourceFilters()}
        <RiverSpinner show={loading} />
      </ChartFiltersContainer>
    </Collapse>
  );
};
