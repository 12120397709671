import { FC, useState } from "react";
import styles from "./cron-tasks.module.scss";
import { Grid, Box } from "@mui/material";
import { SystemCronTasks } from "./system-cron-tasks";
import { CronTasksHeader } from "./cron-tasks-header";
import { CustomCronTasks } from "./custom-cron-tasks";
import { PrimaryButton, useTranslation } from "@river/common-ui";
import { CronAuthenticationDialog } from "./cron-authentication-dialog";

export const CronTasks: FC = () => {
  const { t } = useTranslation();
  const [openCronAuthenticationDialog, setOpenCronAuthenticationDialog] =
    useState<boolean>(false);

  return (
    <>
      <CronTasksHeader />
      <Box className={styles.root}>
        <PrimaryButton
          className={styles.authenticationBtn}
          onClick={() => setOpenCronAuthenticationDialog(true)}
          text={t("module.cron:button.cron_authentication")}
        />
        <Grid container>
          <Grid item xs={12} className={styles.container}>
            <SystemCronTasks />
          </Grid>
          <Grid item xs={12} className={styles.container}>
            <CustomCronTasks />
          </Grid>
        </Grid>
        {openCronAuthenticationDialog && (
          <CronAuthenticationDialog
            open={openCronAuthenticationDialog}
            onClose={() => setOpenCronAuthenticationDialog(false)}
          />
        )}
      </Box>
    </>
  );
};
