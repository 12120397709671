import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { AdapterRuleDto, IAdapterRule } from "@river/interfaces";
import { useContext } from "react";
import { DEFAULT_RULE_TILE_COLOR } from "../../rule-tile";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { ModuleKey } from "../../sidebar-menu";
import { Constants } from "@river/constants";
import { useAllowedAction } from "../../protected-action";
import { ValidationRulesAction } from "../validation-rules";
import { useTranslation } from "@river/common-ui";

const getDefaultDisplayOptions = (): string =>
  JSON.stringify({ color: DEFAULT_RULE_TILE_COLOR });

export const useValidationRuleForm = (props: {
  rule: IAdapterRule | null;
  onCreate: (rule: IAdapterRule) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();

  const isActionAllowed = useAllowedAction();

  const readOnly: boolean = !isActionAllowed(
    ModuleKey.VALIDATION_RULES,
    ValidationRulesAction.UPDATE
  );

  return useRiverForm<AdapterRuleDto, IAdapterRule, Object>({
    initialDto: Object.assign(new AdapterRuleDto(), {
      rule: "",
      rule_type: Constants.rule_type.validation_rule,
      entity_name: "workorder",
      target_period: 0,
      expression: "",
      actions: [],
      display_options: getDefaultDisplayOptions(),
      attribute: "",
    }),
    entityName: "rule",
    instanceToEdit: props.rule,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterRuleDto): Promise<IAdapterRule> => {
      return await adapterContext!.service.getAdapterService().createRule(dto);
    },
    update: async (dto: AdapterRuleDto): Promise<void> => {
      await adapterContext!.service
        .getAdapterService()
        .updateRule(props.rule!._id!, dto);
    },
    labels: {
      rule: t("entity.rule:rule.rule"),
      entity_name: t("entity.rule:rule.entity_name"),
      expression: t("entity.rule:rule.expression"),
      target_period: t("entity.rule:rule.target_period"),
      action_script: t("entity.rule_action_ref:rule_action_ref.action_script"),
    },
    readOnly,
  });
};
