import React, { ReactElement, useState } from "react";
import { RiverDialog, RiverDialogButton } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { GridHeader, RiverTabs } from "../../shared";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { CrewResourceSelector } from "../crew-resource-selector";
import { CrewCraftSelector } from "../crew-craft-selector";
import { IAdapterCrew } from "@river/interfaces";
import styles from "./edit-crew-dialog.module.scss";

enum CraftsDetailsDialogTabId {
  PEOPLE = "people",
  CRAFTS = "crafts",
}

const DEFAULT_CREWS_DETAILS_DIALOG_TAB_ID: CraftsDetailsDialogTabId =
  CraftsDetailsDialogTabId.PEOPLE;

interface IEditCrewDialogProps {
  open: boolean;
  crew: IAdapterCrew;
  onClose: (success: boolean) => void;
}

export const EditCrewDialog: React.FC<IEditCrewDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<CraftsDetailsDialogTabId>(
    DEFAULT_CREWS_DETAILS_DIALOG_TAB_ID
  );
  const handleClose = async () => {
    const success: boolean = false;
    props.onClose(success);
  };

  return (
    <>
      <RiverDialog
        title={t("module.crews:edit_crew_dialog.title")}
        open={props.open}
        onClose={handleClose}
        actionsContent={
          <RiverDialogButton
            onClick={handleClose}
            text={t("common.button:close")}
          />
        }
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
        classes={{
          content: styles.content,
          paper: styles.paper,
        }}
      >
        <GridHeader>
          <RiverTabs
            tabs={[
              {
                label: t("module.crews:edit_crew_dialog.people_tab.title"),
                icon: <DynamicFeedIcon />,
                value: CraftsDetailsDialogTabId.PEOPLE,
              },
              {
                label: t("module.crews:edit_crew_dialog.crafts_tab.title"),
                icon: <CenterFocusWeakIcon />,
                value: CraftsDetailsDialogTabId.CRAFTS,
              },
            ]}
            value={currentTab}
            onChange={(tab) => setCurrentTab(tab as CraftsDetailsDialogTabId)}
          />
        </GridHeader>
        <div className={styles.tabContent}>
          {currentTab === CraftsDetailsDialogTabId.PEOPLE && (
            <CrewResourceSelector crew={props.crew} />
          )}
          {currentTab === CraftsDetailsDialogTabId.CRAFTS && (
            <CrewCraftSelector crew={props.crew} />
          )}
        </div>
      </RiverDialog>
    </>
  );
};
