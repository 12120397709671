import { TFunction } from "i18next";
import { MaterialRequirementsUiService } from "../material-requirements-ui.service";
import { IUseEntityTableParams } from "../ui-service.types";

export class SapMaterialRequirementsUiService extends MaterialRequirementsUiService {
  getMaterialRequirementsTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      columns: [
        {
          key: "Orderid",
          name: t("entity.wo_component:wo_component.Orderid"),
          width: 104,
        },
        {
          key: "Activity",
          name: t("entity.wo_component:wo_component.Activity"),
          width: 90,
        },
        {
          key: "ItemNumber",
          name: t("entity.wo_component:wo_component.ItemNumber"),
          width: 90,
        },
        {
          key: "Material",
          name: t("entity.wo_component:wo_component.Material"),
          width: 90,
        },
        {
          key: "MatlDesc",
          name: t("entity.wo_component:wo_component.MatlDesc"),
          width: 236,
        },
        {
          key: "ItemCat",
          name: t("entity.wo_component:wo_component.ItemCat"),
          width: 96,
        },
        {
          key: "RequirementQuantity",
          name: t("entity.wo_component:wo_component.RequirementQuantity"),
          width: 80,
        },
        {
          key: "RequirementQuantityUnit",
          name: t("entity.wo_component:wo_component.RequirementQuantityUnit"),
          width: 80,
        },
        {
          key: "ReqDate",
          name: t("entity.wo_component:wo_component.ReqDate"),
          width: 100,
        },
        {
          key: "WithdQuan",
          name: t("entity.wo_component:wo_component.WithdQuan"),
          width: 80,
        },
        {
          key: "MissingPart",
          name: t("entity.wo_component:wo_component.MissingPart"),
          width: 90,
        },
      ],
      entityName: "wo_component",
    };
  };

  getWorkOrderNumberColumn = () => "Orderid";
}
