import { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TabContext,
} from "../../../context";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "../supervisor-schedule-ui-service";
import { useShowTabAction } from "../../shared";
import { IUseTable } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";

export const useShowAssignmentsTabAction = () => {
  const { t } = useTranslation();
  const tabContext = useContext(TabContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext!.service.getSupervisorScheduleUiService();

  const isEnabled = (): boolean => {
    let enabled: boolean = false;
    const workOrdersTable: IUseTable =
      uiService.taskTableRefs[SupervisorScheduleTasksTabId.WORKORDERS]!;
    const operationsTable: IUseTable =
      uiService.taskTableRefs[SupervisorScheduleTasksTabId.OPERATIONS]!;
    enabled =
      workOrdersTable?.selectedRowIds.size > 0 ||
      operationsTable?.selectedRowIds.size > 0;
    return enabled;
  };

  const {
    renderShowTabAction: renderShowAssignmentsTabAction,
    showTabAction: showAssignmentsTabAction,
  } = useShowTabAction({
    title: t("module.supervisor_schedule:action_label.show_assignments_tab"),
    icon: LibraryBooksIcon,
    enabled: isEnabled(),
    tabKey: SupervisorScheduleTasksTabId.ASSIGNMENTS,
    onClick: () =>
      tabContext?.setSelectedTab(SupervisorScheduleTasksTabId.ASSIGNMENTS),
  });

  return {
    renderShowAssignmentsTabAction,
    showAssignmentsTabAction,
  };
};
