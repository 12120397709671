import React, { useEffect, useState, useRef, useContext } from "react";
import { ValidationDashboardHeader } from "./validation-dashboard-header";
import {
  IEntityObject,
  IAdapterFolder,
  IAdapterRuleSummary,
} from "@river/interfaces";
import { RiverSpinner, useNotification } from "@river/common-ui";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import {
  userPreferencesService,
  useScheduleValidationRules,
} from "../../services";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  ITableFetchFunctionProps,
  IUseTable,
  TableFetchFunction,
  useTable,
} from "../shared";
import { ValidationDashboardSubHeader } from "./validation-dashboard-subheader";
import { ScheduleValidationRules } from "./schedule-validation-rules";
import { usePageCleanup } from "../../hooks";
import styles from "./validation-dashboard.module.scss";

export const ValidationDashboard: React.FC = () => {
  usePageCleanup();

  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const notify = useNotification();
  const [selectedScheduleIds, setSelectedScheduleIds] = useState<Set<string>>(
    new Set()
  );
  const [isLoadingRules, setIsLoadingRules] = useState<boolean>(false);

  const { getFetchRulesFn } = useScheduleValidationRules();

  const rules = useRef<Map<string, IAdapterRuleSummary[]>>(new Map());

  const loadSelectedScheduleIds = async () => {
    try {
      const scheduleIds: string[] =
        await userPreferencesService.getValidationDashboardSchedules(
          adapterContext!.service.getAdapterService()
        );
      setSelectedScheduleIds(new Set(scheduleIds));
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    loadSelectedScheduleIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSchedules = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    fetchProps.ids = Array.from(selectedScheduleIds);
    if (!fetchProps.ids.length) return [];
    return await adapterContext!.service
      .getAdapterService()
      .fetchFolders(fetchHelpers.getTableQuery({ fetchProps }));
  };

  const schedulesTable: IUseTable = useTable({
    columns: [],
    fetchFunction: fetchSchedules,
    dependencies: [!!site],
    fetchOn: true,
    infiniteScrolling: true,
    fetchTriggers: [site, selectedScheduleIds],
  });

  const onSelectSchedules = (scheduleIds: Set<string>): void => {
    setSelectedScheduleIds(scheduleIds);
    userPreferencesService.setValidationDashboardSchedules(
      adapterContext!.service.getAdapterService(),
      Array.from(scheduleIds)
    );
  };

  useEffect(() => {
    if (schedulesTable.entities.length === 0) return;

    const promises: Promise<IAdapterRuleSummary[]>[] = [];
    setIsLoadingRules(true);
    const fetchFnProps: ITableFetchFunctionProps = {
      columnFilters: [],
      sortColumns: [],
    };
    schedulesTable.entities.forEach((schedule) => {
      const scheduleId: string = schedule[uiConstants.fields._id] as string;
      const fetchRules: TableFetchFunction<IAdapterRuleSummary> =
        getFetchRulesFn({
          scheduleId,
        });
      promises.push(
        fetchRules(fetchFnProps).then((scheduleRules) => {
          rules.current!.set(scheduleId, scheduleRules);
          return scheduleRules;
        })
      );
    });
    Promise.all(promises).finally(() => {
      setIsLoadingRules(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulesTable.entities]);

  const isLoading: boolean =
    schedulesTable.isLoading || isLoadingRules || !site;
  return (
    <>
      <ValidationDashboardHeader />
      <ValidationDashboardSubHeader
        selectedScheduleIds={selectedScheduleIds}
        onChangeSelectedSchedules={onSelectSchedules}
      />
      <RiverSpinner show={isLoading} />
      <div className={styles.schedulesContainer}>
        {schedulesTable.entities.map((obj) => {
          const schedule: IAdapterFolder = obj as IAdapterFolder;
          return (
            <ScheduleValidationRules
              schedule={schedule}
              rules={rules.current.get(schedule._id)}
              key={schedule._id}
            />
          );
        })}
      </div>
    </>
  );
};
