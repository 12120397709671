import {
  IAdapterBaseline,
  IAdapterFolderRef,
  IEntityObject,
} from "@river/interfaces";
import {
  TaskHelpersUiService,
  ObjectType,
  ITaskHelpers,
  IOperationsOverlayPropertyNames,
} from "../task-helpers-ui.service";
import { uiConstants, useEntityHelpers } from "../../helpers";

export class OracleCloudTaskHelpersUiService extends TaskHelpersUiService {
  getHelpers(): () => ITaskHelpers {
    return () => {
      const entityHelpers = useEntityHelpers();
      const { getAttributeValue } = entityHelpers;

      const getTaskFolder = (row: IEntityObject): IAdapterFolderRef => {
        let folderPath: string = "__folder";
        if (
          row[uiConstants.fields.rowType] ===
          uiConstants.rowType.resource_instance
        ) {
          folderPath =
            "WorkOrderOperationResource.WorkOrderOperationResourceInstance.__folder";
        }
        return getAttributeValue(row, folderPath) as IAdapterFolderRef;
      };

      const getTaskStartDate = (row: IEntityObject): string => {
        const folder: IAdapterFolderRef = getTaskFolder(row);
        return String(folder.start_date);
      };

      const getTaskEndDate = (row: IEntityObject): string => {
        const folder: IAdapterFolderRef = getTaskFolder(row);
        return String(folder.end_date);
      };

      const getBaselineStartDate = (
        row: any,
        selectedBaseline: IAdapterBaseline | undefined
      ): string => {
        const rowType = row[uiConstants.fields.rowType] as string;
        let baselineStartDate: string = "";
        if (selectedBaseline && row) {
          if (rowType === uiConstants.rowType.workOrder) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineStartDate = String(baseline.start_date);
              }
            });
          } else if (rowType === uiConstants.rowType.operation) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineStartDate = String(baseline.start_date);
              }
            });
          } else if (rowType === uiConstants.rowType.operation_resource) {
            row?.WorkOrderOperationResource.__baselines?.forEach(
              (baseline: any) => {
                if (baseline.baseline_id === selectedBaseline?._id) {
                  baselineStartDate = String(baseline.start_date);
                }
              }
            );
          } else if (rowType === uiConstants.rowType.resource_instance) {
            row?.WorkOrderOperationResource.WorkOrderOperationResourceInstance?.__baselines?.forEach(
              (baseline: any) => {
                if (baseline.baseline_id === selectedBaseline?._id) {
                  baselineStartDate = String(baseline.start_date);
                }
              }
            );
          }
        }
        return baselineStartDate;
      };

      const getBaselineEndDate = (
        row: any,
        selectedBaseline: IAdapterBaseline | undefined
      ): string => {
        const rowType = row[uiConstants.fields.rowType] as string;
        let baselineEndDate: string = "";
        if (selectedBaseline) {
          if (rowType === uiConstants.rowType.workOrder) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineEndDate = String(baseline.end_date);
              }
            });
          } else if (rowType === uiConstants.rowType.operation) {
            row?.__baselines?.forEach((baseline: any) => {
              if (baseline.baseline_id === selectedBaseline?._id) {
                baselineEndDate = String(baseline.end_date);
              }
            });
          } else if (rowType === uiConstants.rowType.operation_resource) {
            row?.WorkOrderOperationResource?.__baselines?.forEach(
              (baseline: any) => {
                if (baseline.baseline_id === selectedBaseline?._id) {
                  baselineEndDate = String(baseline.end_date);
                }
              }
            );
          } else if (rowType === uiConstants.rowType.resource_instance) {
            row?.WorkOrderOperationResource.WorkOrderOperationResourceInstance?.__baselines?.forEach(
              (baseline: any) => {
                if (baseline.baseline_id === selectedBaseline?._id) {
                  baselineEndDate = String(baseline.end_date);
                }
              }
            );
          }
        }
        return baselineEndDate;
      };

      const getGanttText = (row: IEntityObject): string => {
        const renderWorkOrderText = (): string => {
          const woNumber: string = getAttributeValue(row, "WorkOrderNumber");
          const woDescription: string =
            getAttributeValue(row, "WorkOrderDescription") ?? "";
          return `${woNumber} - ${woDescription}`;
        };

        const renderOperationText = (): string => {
          const startDate: Date = new Date(
            getAttributeValue(row, "__folder.start_date")
          );
          const opStartTime = new Intl.DateTimeFormat(navigator.language, {
            timeZone: "UTC",
            timeStyle: "short",
          }).format(startDate);
          // const opStartTime: string = `${startDate.getUTCHours()}:${startDate
          //   .getUTCMinutes()
          //   .toString()
          //   .padStart(2, "0")}`;
          const opDescription: string =
            getAttributeValue(row, "OperationDescription") ?? "";
          return `${opStartTime} - ${opDescription}`;
        };

        const renderInstanceText = (): string => {
          const laborInstanceName: string = getAttributeValue(
            row,
            "WorkOrderOperationResource.WorkOrderOperationResourceInstance.LaborInstanceName"
          );
          return `${laborInstanceName}`;
        };

        const renderResourceText = (): string => {
          const resourceCode: string = getAttributeValue(
            row,
            "WorkOrderOperationResource.ResourceCode"
          );
          return `${resourceCode}`;
        };

        const rowType = row[uiConstants.fields.rowType] as ObjectType;

        switch (rowType) {
          case uiConstants.rowType.workOrder:
            return renderWorkOrderText();
          case uiConstants.rowType.operation:
            return renderOperationText();
          case uiConstants.rowType.resource_instance:
            return renderInstanceText();
          case uiConstants.rowType.operation_resource:
            return renderResourceText();
        }
        return "";
      };

      const getOperationsOverlayPropertyNames =
        (): IOperationsOverlayPropertyNames => {
          return {
            to_Relations_path: "WorkOrderOperationRelation",
            OrderIdProp: "WorkOrderId",
            OrderNumberProp: "WorkOrderNumber",
            OrderPredecessorProp: "WorkOrderPredecessor",
            OrderSuccessorProp: "WorkOrderSuccessor",
            OperationPredecessorProp: "OperationPredecessor",
            OperationSuccessorProp: "OperationSuccessor",
            RelationTypeProp: "RelationType",
            OperationActivityProp: "OperationSequenceNumber",
            LagHoursProp: "DurationRelationHours",
          };
        };

      const getStartDateFieldName = (obj: IEntityObject): string =>
        "__folder.start_date";

      const getEndDateFieldName = (obj: IEntityObject): string =>
        "__folder.end_date";

      return {
        getTaskFolder,
        getTaskStartDate,
        getTaskEndDate,
        getBaselineStartDate,
        getBaselineEndDate,
        getGanttText,
        getOperationsOverlayPropertyNames,
        getStartDateFieldName,
        getEndDateFieldName,
      };
    };
  }
}
