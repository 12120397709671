import React from "react";
import { PageHeader } from "../../page-header";

export interface LookupsHeaderProps {
  pageTitle: string;
}

export const LookupsHeader: React.FC<LookupsHeaderProps> = (props) => {
  return <PageHeader pageTitle={props.pageTitle} />;
};
