import { ReactElement } from "react";
import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { TFunction } from "react-i18next";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers } from "../../helpers";
import {
  CraftsUiService,
  ICraftSelectorProps,
  IUseCraftLookupProps,
} from "../crafts-ui.service";
import { Column } from "react-data-grid";
import { SapCraftSelector } from "./sap-shared/sap-craft-selector";

export class SapCraftsUiService extends CraftsUiService {
  getCraftEntity = () => "workcenter";
  craftKeyGetter = (row: any): string => row["WorkCenterId"];

  getCraftLookupDefinition = (
    props: IUseCraftLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: props.t("shared.lookup:craft.title"),
      rowKeyGetter: this.craftKeyGetter,
      useTableProps: {
        columns: this.getCraftsSelectorColumns(
          props.t,
          props.RiverSelectColumn
        ),
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await props
            .adapterContext!.service.getAdapterService()
            .fetchCrafts(
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: props.customFilters,
              })
            );
        },
        dependencies: [!!site],
        saveKey: this.getCraftsLookupTableSaveKey(),
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: this.getCraftEntity(),
      },
    };
  };
  getCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => [
    RiverSelectColumn,
    {
      key: "WorkCenter",
      name: t("entity.workcenter:workcenter.WorkCenter"),
      width: 150,
    },
    {
      key: "WorkCenterType",
      name: t("entity.workcenter:workcenter.WorkCenterType"),
    },
    { key: "Plant", name: t("entity.workcenter:workcenter.Plant") },
    {
      key: "Category",
      name: t("entity.workcenter:workcenter.Category"),
    },
    {
      key: "WorkCenterDescr",
      name: t("entity.workcenter:workcenter.WorkCenterDescr"),
    },
  ];

  getAssignedCraftsSelectorColumns = (
    t: TFunction,
    RiverSelectColumn: Column<any, any>
  ) => this.getCraftsSelectorColumns(t, RiverSelectColumn);

  getCraftsUtilizationIds = (selectedCraftIds: string[]): string[] =>
    selectedCraftIds;

  renderCraftsSelector = (props: ICraftSelectorProps): ReactElement => (
    <SapCraftSelector {...props} />
  );
}
