import React, { ReactElement, useContext } from "react";
import { SupervisorScheduleContext } from "../../../../context";
import { SupervisorGanttHeaderPeriod } from "./supervisor-gantt-header-period";
import { IUseSupervisorScheduleGantt } from "../use-supervisor-schedule-gantt";
import { SUPERVISOR_SCHEDULE_TASKS_GANTT_CONTAINER_ID } from "../supervisor-schedule-gantt";
import { useSupervisorGanttRenderChecks } from "../use-supervisor-gantt-render-checks";
import styles from "./supervisor-schedule-gantt-header.module.scss";
import clsx from "clsx";

export const SupervisorScheduleGanttHeaderRenderer: React.FC =
  (): ReactElement => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const gantt: IUseSupervisorScheduleGantt =
      scheduleContext?.ganttRef.current!;
    const { periods } = gantt;
    const { shouldRender } = useSupervisorGanttRenderChecks({
      gantt,
      containerId: SUPERVISOR_SCHEDULE_TASKS_GANTT_CONTAINER_ID,
    });

    return (
      <>
        {shouldRender && (
          <div className={clsx([styles.root])}>
            {periods.map((period) => (
              <SupervisorGanttHeaderPeriod period={period} key={period.index} />
            ))}
          </div>
        )}
      </>
    );
  };
