import { FC } from "react";
import { GridHeader, GridActions, FilterDropdown } from "../../../shared";
import { useTranslation } from "@river/common-ui";
import { useEditOptionsAction } from "./system-cron-tasks-grid-actions";

export const SystemCronTasksGridHeader: FC = () => {
  const { t } = useTranslation();
  const editOptionsGridAction = useEditOptionsAction();

  return (
    <>
      <GridHeader>
        <GridActions>
          <span>{t("module.cron:system_cron_tasks.title")}</span>
          <FilterDropdown />
          {editOptionsGridAction.renderAction()}
        </GridActions>
      </GridHeader>
    </>
  );
};
