import { createContext } from "react";
import { IUseTable } from "../components/shared";

export interface IEntityDialogContext {
  attributesTable: IUseTable;
  relationsTable: IUseTable;
  indexesTable: IUseTable;
}

export const EntityDialogContext = createContext<IEntityDialogContext | null>(
  null
);
