import React, { ReactElement } from "react";
import { IFormRendererProps } from "../../../hooks";
import { LookupType, RiverLookup } from "../../../components/shared";
import { OracleEbsUserRoleDto } from "./use-oracle-ebs-user-role-form";
import { IEntityObject } from "@river/interfaces";

export const renderOracleEbsUserRolesForm = (
  props: IFormRendererProps
): ReactElement => {
  const { form } = props;
  const dto: OracleEbsUserRoleDto = form.dto as OracleEbsUserRoleDto;
  const { setDto } = form;

  const renderApplicationField = (): ReactElement => {
    return (
      <>
        <RiverLookup
          id={"APPLICATION_NAME"}
          lookup={{
            type: LookupType.APPLICATION,
          }}
          onSelect={(selectedApplication: IEntityObject) => {
            const newDto: OracleEbsUserRoleDto = {
              ...dto,
              APPLICATION_ID: selectedApplication.APPLICATION_ID as string,
              APPLICATION_NAME: selectedApplication.APPLICATION_NAME as string,
              RESPONSIBILITY_ID: "",
              RESPONSIBILITY_NAME: "",
            };
            Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
            setDto(newDto);
          }}
          inputProps={{ disabled: true }}
        />
      </>
    );
  };

  const renderResponsibilityField = (): ReactElement => {
    return (
      <>
        <RiverLookup
          id={"RESPONSIBILITY_NAME"}
          lookup={{
            type: LookupType.RESPONSIBILITY,
            customFilters: [
              {
                attribute_name: "RESPONSIBILITY_APPLICATION_ID",
                attribute_value: {
                  operator: "$eq",
                  value: dto.APPLICATION_ID,
                },
              },
            ],
          }}
          disabled={dto.APPLICATION_ID === ""}
          onSelect={(selectedResponsibility: IEntityObject) => {
            const newDto: OracleEbsUserRoleDto = {
              ...dto,
              RESPONSIBILITY_ID:
                selectedResponsibility.RESPONSIBILITY_ID as string,
              RESPONSIBILITY_NAME:
                selectedResponsibility.RESPONSIBILITY_NAME as string,
            };
            Object.setPrototypeOf(newDto, Object.getPrototypeOf(dto));
            setDto(newDto);
          }}
          inputProps={{ disabled: true }}
        />
      </>
    );
  };

  return (
    <>
      {renderApplicationField()}
      {renderResponsibilityField()}
    </>
  );
};
