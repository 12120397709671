import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { ComplianceSummaryFilterID } from "../../../../schedule-utilization-ui-service";

interface IOracleEbsSchedulingComplianceWOTypeFiltersProps {
  rows: any[];
  onSelect: (
    selectedObjects: any[],
    filterId: ComplianceSummaryFilterID
  ) => void;
  onDelete: (selectedObject: any, filterId: ComplianceSummaryFilterID) => void;
}

export const OracleEbsSchedulingComplianceWOTypeFilters: React.FC<
  IOracleEbsSchedulingComplianceWOTypeFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "MEANING",
          name: t("entity.wo_type:wo_type.MEANING"),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.ORACLE_EBS_WO_TYPE,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "LOOKUP_CODE",
            attribute_value: {
              operator: "$nin",
              value: props.rows.map((woType) => woType["LOOKUP_CODE"]),
            },
          },
        ],
      }}
      onDelete={(selectedObject) =>
        props.onDelete(selectedObject, ComplianceSummaryFilterID.WO_TYPES)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(selectedObjects, ComplianceSummaryFilterID.WO_TYPES);
      }}
      addFilterLabel={t("module.schedule:utilization_filters.add.wo_type")}
    />
  );
};
