import React from "react";
import { Column, RowsChangeData, SortColumn } from "react-data-grid";
import { RiverDataGrid } from "../shared";
import { IEntityObject } from "@river/interfaces";

export interface ISupervisorScheduleListProps {
  data: (IEntityObject & { is_selected?: boolean })[];
  columns: Column<any>[];
  sortColumns: SortColumn[];
  setSortColumns: (columns: SortColumn[]) => void;
  onRowsChange?: (rows: any[], rowData: RowsChangeData<any>) => void;
  setColumnSize: (index: number, width: number) => void;
  rowKeyGetter?: (row: IEntityObject) => string;
}

export const SupervisorScheduleList: React.FC<ISupervisorScheduleListProps> = (
  props
) => {
  return (
    <RiverDataGrid
      columns={props.columns}
      rows={props.data}
      sortColumns={props.sortColumns}
      onSortColumnsChange={(e) => {
        props.setSortColumns(e);
      }}
      onColumnResize={props.setColumnSize}
      onRowsChange={props.onRowsChange}
      rowKeyGetter={props.rowKeyGetter}
      defaultColumnOptions={{
        sortable: true,
        resizable: true,
      }}
    />
  );
};
