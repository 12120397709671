import {
  IMenuItem,
  ModuleKey,
  MenuItemID,
  useSidebarMenuDef,
} from "./use-sidebar-menu-def";
import { useContext, useEffect, useState, useRef } from "react";
import { AdapterUserContext, SidebarContext } from "../../context";
import { useLocation } from "react-router-dom";

export function useSidebarMenu() {
  const [currentAccordionKey, setCurrentAccordionKey] = useState<MenuItemID>();
  const sidebarContext = useContext(SidebarContext);
  const userContext = useContext(AdapterUserContext);
  const location = useLocation();
  const sidebarMenuDef = useSidebarMenuDef({});
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(
    sidebarMenuDef.menuItems
  );

  const itemsByIdRef = useRef<Map<MenuItemID, IMenuItem>>(
    new Map<MenuItemID, IMenuItem>()
  );
  const itemsByModuleKeyRef = useRef<Map<ModuleKey, IMenuItem>>(
    new Map<ModuleKey, IMenuItem>()
  );

  useEffect(() => {
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateCurrentAccordionKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccordionKey, location.pathname]);

  const initMenu = () => {
    const mapItems = (defs: IMenuItem[], parent?: IMenuItem) => {
      defs.forEach((def) => {
        if (parent) {
          Object.assign(def, { parent });
        }
        itemsByIdRef.current.set(def.id, def);
        if (def.moduleKey) {
          itemsByModuleKeyRef.current.set(def.moduleKey, def);
        }
        if (def.children) {
          mapItems(def.children, def);
        }
      });
    };

    const authorizeItems = () => {
      if (userContext) {
        userContext.userModules?.forEach((userModule) => {
          const menuItem = itemsByModuleKeyRef.current.get(
            userModule.module as ModuleKey
          );
          if (menuItem) {
            menuItem.enabled = true;
          }
        });
        sidebarMenuDef.menuItems.forEach((item) => {
          if (item.children) {
            item.enabled = item.children.filter((i) => i.enabled).length > 0;
          }
        });
      }
    };

    mapItems(sidebarMenuDef.menuItems);
    authorizeItems();

    sidebarContext?.setMenuItemsByModuleKey(itemsByModuleKeyRef.current);
  };

  const matchLinkToWithPathname = (item: IMenuItem) => {
    return location.pathname.startsWith(item?.linkTo ?? "");
  };

  const updateCurrentAccordionKey = () => {
    const urlMenuItem = Array.from(itemsByIdRef.current.values()).filter(
      (item) => matchLinkToWithPathname(item)
    )[0];
    setCurrentAccordionKey(urlMenuItem?.parent?.id || urlMenuItem?.id);
  };

  const updateMenuItems = () => {
    setMenuItems(
      sidebarMenuDef.menuItems.map((item) => {
        const isHomePage = location.pathname === "/home";
        const isCurrentAccordion = item.id === currentAccordionKey;
        const hasMatchingChild = item?.children?.some(
          (child) => child.id === currentAccordionKey
        );

        return {
          ...item,
          show: isHomePage || isCurrentAccordion || hasMatchingChild,
        };
      })
    );
  };

  return {
    menuItems,
    currentAccordionKey,
    setCurrentAccordionKey,
  };
}

export type IUseMenu = ReturnType<typeof useSidebarMenu>;
