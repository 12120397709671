import React, { ReactElement, useContext } from "react";
import { Avatar } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  ApplicationContext,
  IAdapterUserContext,
} from "../../../context";
import { IAccessTokenPayload, ILanguage } from "@river/interfaces";
import { useTranslation } from "react-i18next";
import {
  RiverDropdownList,
  IRiverListPopupItem,
  POPUP_SEPARATOR,
} from "../../shared";
import { useNavigate } from "react-router";
import styles from "./user-info.module.scss";
import clsx from "clsx";

export const UserInfo: React.FC = (): ReactElement => {
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const applicationContext = useContext(ApplicationContext);
  const user: IAccessTokenPayload = userContext?.user!;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getAvatarShorthand = (): string =>
    user?.display_name
      .split(" ")
      .map((token) => token.substr(0, 1).toUpperCase())
      .join("")
      .substring(0, 3);

  const AvatarIcon = () => (
    <Avatar className={styles.avatar}>{getAvatarShorthand()}</Avatar>
  );

  const isLanguageSelected = (language: ILanguage): boolean =>
    language.language ===
    userContext?.userProperties[AdapterUserContextProp.LANGUAGE];

  const getSelectedLanguageLabel = (): string => {
    const language = applicationContext?.languages?.find(
      (el) =>
        el.language ===
        userContext?.userProperties[AdapterUserContextProp.LANGUAGE]
    )!;
    return language?.name || "";
  };

  const items: IRiverListPopupItem[] = [
    {
      title: user.display_name,
      startIcon: AvatarIcon,
      onClick: () => {},
      classes: {
        root: clsx([styles.item, styles.userName]),
        title: styles.title,
      },
    },
    POPUP_SEPARATOR,
    {
      title: getSelectedLanguageLabel(),
      startIcon: LanguageOutlinedIcon,
      classes: { root: styles.item, title: styles.title },
      submenu: {
        classes: {
          container: styles.submenu,
          slider: styles.slider,
        },
        items: applicationContext!.languages?.map((language) => ({
          title: language.name,
          classes: { root: styles.item, title: styles.title },
          endIcon: isLanguageSelected(language)
            ? CheckOutlinedIcon
            : () => <></>,
          onClick: async () => {
            if (!isLanguageSelected(language)) {
              await userContext?.updateUserProperty(
                AdapterUserContextProp.LANGUAGE,
                language.language
              );
              window.location.reload();
            }
          },
        })),
      },
    },
    POPUP_SEPARATOR,
    {
      title: t("shared.user_menu:label.logout"),
      startIcon: LogoutOutlinedIcon,
      classes: { root: styles.item, title: styles.title },
      onClick: () => navigate("/logout"),
    },
  ];

  return (
    <RiverDropdownList
      toggle={AvatarIcon}
      items={items}
      classes={{
        popup: styles.popup,
        separator: styles.separator,
        startIconClass: styles.startIcon,
        endIconClass: styles.endIcon,
      }}
    />
  );
};
