import React, { useContext, useState } from "react";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { PrimaryButton } from "@river/common-ui";
import { TableContext } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { TimelinesDialog } from "../timelines-dialog";
import { IAdapterTimeline } from "@river/interfaces";
import { TimelinesAction } from "../timelines";

export interface ITimelinesSubHeader {
  openEditDialog: (timeline: IAdapterTimeline) => void;
}

export const TimelinesSubHeader: React.FC<ITimelinesSubHeader> = (props) => {
  const { t } = useTranslation();
  const [timelineDialogOpened, setTimelineDialogOpened] =
    useState<boolean>(false);
  const tableContext = useContext(TableContext);

  const openDialog = (): void => {
    tableContext?.table.setSelectedRowIds(new Set());
    setTimelineDialogOpened(true);
  };
  const closeDialog = (): void => setTimelineDialogOpened(false);

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.TIMELINES}
          action={TimelinesAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.timelines:button.add_timeline")}
          />
        </ProtectedAction>
      </div>
      <TimelinesDialog open={timelineDialogOpened} onClose={closeDialog} />
    </>
  );
};
