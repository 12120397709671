import React, { ReactElement, PropsWithChildren } from "react";
import styles from "./grid-actions.module.scss";
import clsx from "clsx";

interface IGridActionsProps extends PropsWithChildren {
  className?: string;
}

export const GridActions: React.FC<IGridActionsProps> = (
  props
): ReactElement => (
  <div className={clsx([styles.root, props.className])}>{props.children}</div>
);
