import React, { useContext, ReactElement } from "react";
import { TabProps } from "@mui/material/Tab";
import {
  SupervisorScheduleUiService,
  IGetDefaultSupervisorScheduleWorkOrderColumnsProps,
  SupervisorScheduleTasksTabId,
  useSupervisorScheduleWorkOrders,
  useSupervisorScheduleOperations,
  useSupervisorScheduleAssignments,
  useSupervisorScheduleTimecards,
  SupervisorScheduleAction,
  IUseSupervisorScheduleCurrentTableProps,
  SupervisorScheduleTaskTabKeyColumnMap,
  BacklogTabId,
  IOpMassUpdateDialogProps,
} from "../../services";
import { useTranslation } from "@river/common-ui";
import {
  SupervisorScheduleContext,
  TabContext,
  TableContext,
} from "../../context";
import { useGridActions } from "../supervisor-schedule-ui-service/grid-actions";
import {
  TabsProvider,
  EntityTableProvider,
  ColumnsProvider,
  IUseEntityTable,
  ReactElementProvider,
} from "../ui-service.types";
import {
  getWorkOrderIdFormatter,
  ReportsDropdown,
  RiverDropdownActions,
  useCompletionPercentageDialogAction,
  useRiverSelectColumn,
  useUserStatusAction,
} from "../../components/shared";
import { TextEditor } from "react-data-grid";
import { IWoMassUpdateDialogProps } from "../shared";
import { SapOpMassUpdateDialog, SapWoMassUpdateDialog } from "./sap-shared";
import { ModuleKey } from "../../components/sidebar-menu";
import ProtectedAction from "../../components/protected-action";
import { useTableCellRenderers } from "../../hooks";
import { SupervisorBaselineSelector } from "../../components/supervisor-schedule/supervisor-baseline-selector";

const DEFAULT_SAP_SCHEDULE_TAB: SupervisorScheduleTasksTabId =
  SupervisorScheduleTasksTabId.WORKORDERS;

export class SapSupervisorScheduleUiService extends SupervisorScheduleUiService {
  private getWorkOrderEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      woMassUpdateAction,
      jobPriorityScoreAction,
      moveSelectedRecordsAction,
      setBreakInAction,
      resetBreakInAction,
    } = useGridActions();
    const entity_name = "workorder";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    const { userStatusAction } = useUserStatusAction({
      entity_name,
    });

    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: woMassUpdateAction.title,
              startIcon: woMassUpdateAction.icon,
              onClick: woMassUpdateAction.onClick,
              action: SupervisorScheduleAction.WO_EDIT,
              disabled: !selectedIds,
            },
            {
              title: userStatusAction.title,
              startIcon: userStatusAction.icon,
              onClick: userStatusAction.onClick,
              action: SupervisorScheduleAction.WO_USER_STATUS,
              disabled: !selectedIds,
            },
            {
              title: jobPriorityScoreAction.title,
              startIcon: jobPriorityScoreAction.icon,
              onClick: jobPriorityScoreAction.onClick,
              action: SupervisorScheduleAction.WO_JPS,
              disabled: !selectedIds,
            },
            {
              title: moveSelectedRecordsAction.title,
              startIcon: moveSelectedRecordsAction.icon,
              onClick: moveSelectedRecordsAction.onClick,
              action: SupervisorScheduleAction.SCHEDULE,
              disabled: !selectedIds,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              action: SupervisorScheduleAction.WO_COMPLETION_PCT,
              disabled: !selectedIds,
            },
            {
              title: setBreakInAction.title,
              startIcon: setBreakInAction.icon,
              onClick: setBreakInAction.onClick,
              action: SupervisorScheduleAction.WO_BREAKIN,
              disabled: setBreakInAction.disabled,
            },
            {
              title: resetBreakInAction.title,
              startIcon: resetBreakInAction.icon,
              onClick: resetBreakInAction.onClick,
              action: SupervisorScheduleAction.WO_BREAKIN,
              disabled: resetBreakInAction.disabled,
            },
          ]}
        />
        {woMassUpdateAction.renderDialog()}
        {userStatusAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {moveSelectedRecordsAction.renderDialog()}
      </>
    );
  };

  private getWorkOrderViewActionsRenderer = (): ReactElementProvider => {
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const {
      materialRequirementsAction,
      checkMaterialShortageAction,
      downloadAttachmentsAction,
    } = useGridActions();
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: materialRequirementsAction.title,
              startIcon: materialRequirementsAction.icon,
              onClick: materialRequirementsAction.onClick,
              disabled: !selectedIds,
            },
            {
              title: checkMaterialShortageAction.title,
              startIcon: checkMaterialShortageAction.icon,
              onClick: checkMaterialShortageAction.onClick,
              action: SupervisorScheduleAction.WO_MATERIAL_SHORTAGE,
              disabled: !selectedIds,
            },
            {
              title: downloadAttachmentsAction.title,
              startIcon: downloadAttachmentsAction.icon,
              onClick: downloadAttachmentsAction.onClick,
              action: SupervisorScheduleAction.WO_PRINT,
              disabled: !selectedIds,
            },
          ]}
        />
        {materialRequirementsAction.renderDialog()}
        {downloadAttachmentsAction.renderDialog()}
      </>
    );
  };

  private getOperationEditActionsRenderer = (): ReactElementProvider => {
    const scheduleContext = useContext(SupervisorScheduleContext);
    const folderId: string = scheduleContext?.currentSchedule!._id!;
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;
    const { opMassUpdateAction, assignCalendarAction, unassignCalendarAction } =
      useGridActions();
    const entity_name = "operation";
    const { completionPercentageDialogAction } =
      useCompletionPercentageDialogAction();
    const { userStatusAction } = useUserStatusAction({
      entity_name,
    });
    return (): ReactElement => (
      <>
        <RiverDropdownActions
          module={ModuleKey.SUPERVISOR_SCHEDULES}
          items={[
            {
              title: opMassUpdateAction.title,
              startIcon: opMassUpdateAction.icon,
              onClick: opMassUpdateAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_EDIT,
            },
            {
              title: userStatusAction.title,
              startIcon: userStatusAction.icon,
              onClick: userStatusAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_USER_STATUS,
            },
            {
              title: assignCalendarAction.title,
              startIcon: assignCalendarAction.icon,
              onClick: assignCalendarAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_CALENDAR,
            },
            {
              title: unassignCalendarAction.title,
              startIcon: unassignCalendarAction.icon,
              onClick: unassignCalendarAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_CALENDAR,
            },
            {
              title: completionPercentageDialogAction.title,
              startIcon: completionPercentageDialogAction.icon,
              onClick: completionPercentageDialogAction.onClick,
              disabled: !selectedIds,
              action: SupervisorScheduleAction.OP_COMPLETION_PCT,
            },
          ]}
        />
        {opMassUpdateAction.renderDialog()}
        {userStatusAction.renderDialog()}
        {completionPercentageDialogAction.renderDialog({
          entity_name,
          folderId,
        })}
        {assignCalendarAction.renderDialog()}
      </>
    );
  };

  renderGridActions(): ReactElementProvider {
    return (): ReactElement => {
      const tabContext = useContext(TabContext);
      const currentTab = tabContext?.selectedTab;
      const {
        renderUnassignAction,
        renderValidationRulePopupAction,
        renderDependenciesDropdownActions,
        renderCreateOperationTimecardAction,
        renderCreateAssignmentTimecardAction,
      } = useGridActions();
      const renderWorkOrderEditActions = this.getWorkOrderEditActionsRenderer();
      const renderWorkOrderViewActions = this.getWorkOrderViewActionsRenderer();
      const renderOperationEditActions = this.getOperationEditActionsRenderer();

      const renderWorkOrderActions = (): ReactElement => (
        <>
          {renderWorkOrderEditActions()}
          {renderWorkOrderViewActions()}
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
        </>
      );
      const renderOperationActions = (): ReactElement => (
        <>
          {renderOperationEditActions()}
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.SCHEDULE}
          >
            {renderDependenciesDropdownActions()}
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.WO_ACTION}
          >
            {renderValidationRulePopupAction()}
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.CREATE_OPERATION_TIMECARD}
          >
            {renderCreateOperationTimecardAction()}
          </ProtectedAction>
        </>
      );
      const renderAssignmentActions = (): ReactElement => (
        <>
          {renderUnassignAction()}
          <ProtectedAction
            module={ModuleKey.SUPERVISOR_SCHEDULES}
            action={SupervisorScheduleAction.CREATE_ASSIGNMENT_TIMECARD}
          >
            {renderCreateAssignmentTimecardAction()}
          </ProtectedAction>
        </>
      );

      return (
        <>
          {currentTab === SupervisorScheduleTasksTabId.WORKORDERS &&
            renderWorkOrderActions()}
          {currentTab === SupervisorScheduleTasksTabId.OPERATIONS &&
            renderOperationActions()}
          {currentTab === SupervisorScheduleTasksTabId.ASSIGNMENTS &&
            renderAssignmentActions()}
          <ReportsDropdown />
          {currentTab !== SupervisorScheduleTasksTabId.TIMECARDS && (
            <SupervisorBaselineSelector />
          )}
        </>
      );
    };
  }

  getTabs = (): TabsProvider => (): TabProps[] => {
    const { t } = useTranslation();
    return [
      {
        label: t("module.supervisor_schedule:tab.work_orders.title"),
        value: SupervisorScheduleTasksTabId.WORKORDERS,
      },
      {
        label: t("module.supervisor_schedule:tab.operations.title"),
        value: SupervisorScheduleTasksTabId.OPERATIONS,
      },
      {
        label: t("module.supervisor_schedule:tab.assignments.title"),
        value: SupervisorScheduleTasksTabId.ASSIGNMENTS,
      },
      {
        label: t("module.supervisor_schedule:tab.timecards.title"),
        value: SupervisorScheduleTasksTabId.TIMECARDS,
      },
    ];
  };

  getDefaultTab = (): string => DEFAULT_SAP_SCHEDULE_TAB;

  useCurrentTable = (
    props: IUseSupervisorScheduleCurrentTableProps
  ): EntityTableProvider => {
    const { scheduleContext } = props;
    return () => {
      const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
      const workOrdersTable: IUseEntityTable = useSupervisorScheduleWorkOrders({
        draggable: false,
        scheduleContext,
      });
      const operationsTable: IUseEntityTable = useSupervisorScheduleOperations({
        scheduleContext,
      });
      const assignmentsTable: IUseEntityTable =
        useSupervisorScheduleAssignments({
          scheduleContext,
        });
      const timecardsTable: IUseEntityTable = useSupervisorScheduleTimecards();
      if (currentTab === SupervisorScheduleTasksTabId.WORKORDERS) {
        return workOrdersTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.OPERATIONS) {
        return operationsTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.ASSIGNMENTS) {
        return assignmentsTable;
      } else if (currentTab === SupervisorScheduleTasksTabId.TIMECARDS) {
        return timecardsTable;
      } else {
        return workOrdersTable;
      }
    };
  };

  getUserStatusResponseEntityName(): string {
    const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
    let entityName: string = "";
    if (currentTab === SupervisorScheduleTasksTabId.WORKORDERS) {
      entityName = "workorder";
    } else if (currentTab === SupervisorScheduleTasksTabId.OPERATIONS) {
      entityName = "operation";
    }
    return entityName;
  }

  getDefaultWorkOrderColumns(
    props: IGetDefaultSupervisorScheduleWorkOrderColumnsProps
  ): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const tableCellRenderers = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "Orderid",
          name: t("entity.workorder:workorder.Orderid"),
          width: 105,
          formatter: getWorkOrderIdFormatter({
            onWorkOrderClick: props.onWorkOrderClick,
            getTaskColor: props.getTaskColor,
            draggable: props.draggable,
            tableCellRenderers,
          }),
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "ShortText",
          name: t("entity.workorder:workorder.ShortText"),
          editor: TextEditor,
        },
        {
          key: "OrderType",
          name: t("entity.workorder:workorder.OrderType"),
        },
        {
          key: "CompCode",
          name: t("entity.workorder:workorder.CompCode"),
        },
        {
          key: "Plant",
          name: t("entity.workorder:workorder.Plant"),
        },
        {
          key: "Equipment",
          name: t("entity.workorder:workorder.Equipment"),
        },
        {
          key: "FunctLoc",
          name: t("entity.workorder:workorder.FunctLoc"),
        },
        {
          key: "MnWkCtr",
          name: t("entity.workorder:workorder.MnWkCtr"),
        },
        {
          key: "Priority",
          name: t("entity.workorder:workorder.Priority"),
          editor: TextEditor,
        },
        {
          key: "SysStatus",
          name: t("entity.workorder:workorder.SysStatus"),
        },
        {
          key: "__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
        {
          key: "__jps",
          name: t("entity.workorder:workorder.__jps"),
          width: 115,
        },
      ];
    };
  }

  getDefaultOperationColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "Orderid",
          name: t("entity.operation:operation.Orderid"),
          width: 105,
        },
        {
          key: "__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "Description",
          name: t("entity.operation:operation.Description"),
        },
        {
          key: "Activity",
          name: t("entity.operation:operation.Activity"),
        },
        {
          key: "WorkCntr",
          name: t("entity.operation:operation.WorkCntr"),
        },
        {
          key: "__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
      ];
    };
  }

  getDefaultResourcesColumns(): ColumnsProvider {
    return () => [];
  }

  getDefaultInstancesColumns(): ColumnsProvider {
    return () => [];
  }

  renderWoMassUpdateDialog = (
    props: IWoMassUpdateDialogProps
  ): ReactElement => <SapWoMassUpdateDialog {...props} />;

  renderOpMassUpdateDialog = (
    props: IOpMassUpdateDialogProps
  ): ReactElement => <SapOpMassUpdateDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return [
      "entity.planning_plant",
      "entity.assignment",
      "entity.wo_component",
      "entity.pm_activity_type",
      "entity.planner_group",
      "entity.priority",
      "entity.revision",
      "entity.system_condition",
      "entity.wbs",
      "entity.workcenter_utilization",
    ];
  }

  isTasksTabAssignable(): () => boolean {
    return (): boolean => {
      const currentTab: SupervisorScheduleTasksTabId = this.getCurrentTab()();
      return currentTab === SupervisorScheduleTasksTabId.OPERATIONS;
    };
  }

  /**
   * Column key mappings for matching target tab rows to selected source tab rows
   */
  getTaskTabKeyColumnMap = (): SupervisorScheduleTaskTabKeyColumnMap => {
    const woKey: string = "Orderid";
    const opKey: string = "Activity";

    return {
      [BacklogTabId.WORKORDERS]: {
        [BacklogTabId.OPERATIONS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woKey, targetField: woKey },
        ],
      },
      [BacklogTabId.OPERATIONS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.ASSIGNMENTS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
      [BacklogTabId.ASSIGNMENTS]: {
        [BacklogTabId.WORKORDERS]: [{ sourceField: woKey, targetField: woKey }],
        [BacklogTabId.OPERATIONS]: [
          { sourceField: woKey, targetField: woKey },
          { sourceField: opKey, targetField: opKey },
        ],
      },
    };
  };

  getDefaultTimecardsColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      return [
        {
          key: "Activity",
          name: t("entity.timecard:timecard.Activity"),
        },
        {
          key: "SubActivity",
          name: t("entity.timecard:timecard.SubActivity"),
        },
        {
          key: "PersonNumber",
          name: t("entity.timecard:timecard.PersonNumber"),
        },
        { key: "PersonName", name: t("entity.timecard:timecard.PersonName") },
        {
          key: "timecard_date",
          name: t("entity.timecard:timecard.timecard_date"),
        },
        {
          key: "timecard_hours",
          name: t("entity.timecard:timecard.timecard_hours"),
        },
        {
          key: "is_confirmed",
          name: t("entity.timecard:timecard.is_confirmed"),
        },
      ];
    };
  }

  getDefaultAssignmentColumns(): ColumnsProvider {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "to_Assignment.Orderid",
          name: t("entity.assignment:label.Orderid"),
          width: 105,
        },
        {
          key: "to_Assignment.__folder.committed",
          name: t("entity.folder_ref:folder_ref.committed"),
          width: 80,
        },
        {
          key: "to_Assignment.__folder.rescheduled",
          name: t("entity.folder_ref:folder_ref.rescheduled"),
          width: 80,
        },
        {
          key: "to_Assignment.Activity",
          name: t("entity.assignment:label.Activity"),
        },
        {
          key: "to_Assignment.PersNo",
          name: t("entity.assignment:label.PersNo"),
        },
        {
          key: "to_Assignment.PersonName",
          name: t("entity.assignment:label.PersonName"),
        },
        {
          key: "to_Assignment.__folder.start_date",
          name: t("entity.folder:folder.start_date"),
        },
        {
          key: "to_Assignment.__folder.end_date",
          name: t("entity.folder:folder.end_date"),
        },
      ];
    };
  }
}
