import React, { ReactElement, useCallback, useContext, useEffect } from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverFormSelect,
  IRiverSimpleSelectItem,
  IFieldValidationErrors,
  RiverSwitch,
} from "@river/common-ui";
import { AdapterRuleDto, IAdapterRule } from "@river/interfaces";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AdapterRuleActionDto } from "@river/interfaces/dist/adapter-rule/adapter-rule-action.dto";
import { Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { RuleTile } from "../../rule-tile";
import { RiverColorPicker } from "../../shared/river-color-picker";
import { RiverCodeInput } from "../../river-code-input";
import {
  RiverFormInstance,
  PropertyChangeHandler,
  PropertySimpleChangeHandler,
} from "../../../hooks";
import { useValidationRuleForm } from "./use-validation-rule-form";
import { useTranslation } from "@river/common-ui";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import styles from "./validation-rule-dialog.module.scss";
import clsx from "clsx";

const getUpdatedDisplayOptions = (
  oldOptions: string,
  newOptions: object
): string => {
  const options: any = JSON.parse(oldOptions);
  Object.assign(options, newOptions);
  return JSON.stringify(options);
};

interface IValidationRuleDialogProps {
  open: boolean;
  rule: IAdapterRule | null;
  onClose: (success: boolean) => void;
}

export const ValidationRuleDialog: React.FC<IValidationRuleDialogProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState = useContext(AdapterUiContext)!;

  const [codeEditorModel, setCodeEditorModel] = React.useState<string>("");

  useEffect(() => {
    if (props.open) {
      adapterContext
        ?.service!.getAdapterService()
        .getMonacoEditorLib(ruleDto.entity_name)
        .then((data) => {
          setCodeEditorModel(data.script);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const form: RiverFormInstance = useValidationRuleForm({
    rule: props.rule,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });

  const ruleDto = form.dto as AdapterRuleDto;
  const {
    validationErrors,
    getArrayPropertyChangeHandler,
    getArrayPropertySimpleChangeHandler,
    getArrayValidationErrors,
    setValidationErrors,
    setDto,
    submit,
    resetForm,
    isCreate,
  } = form;
  const { t } = useTranslation();

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    close(requireRefresh);
  };

  const close = (requiresRefresh?: boolean): void => {
    resetForm();
    props.onClose(!!requiresRefresh);
  };

  const renderEntityNameField = (): ReactElement => {
    const entityNames: string[] = ["workorder", "operation"];
    const items: IRiverSimpleSelectItem[] = entityNames.map((e) => {
      return { value: e, text: e };
    });
    return <RiverFormSelect id={"entity_name"} items={items} fullWidth />;
  };

  // number of days
  const renderTargetPeriodField = (): ReactElement => (
    <RiverTextInput
      id={"target_period"}
      inputProps={{
        type: "number",
      }}
    />
  );

  const clearActionFieldError = (actionIndex: number): void => {
    const fieldErrors = validationErrors.fields["actions"]?.childrenByIndex;
    if (fieldErrors) {
      delete fieldErrors[actionIndex];
      setValidationErrors(validationErrors);
    }
  };

  const deleteRuleAction = async (actionIndex: number): Promise<void> => {
    const updated: AdapterRuleDto = Object.assign({}, ruleDto);
    Object.setPrototypeOf(updated, Object.getPrototypeOf(ruleDto));
    updated.actions!.splice(actionIndex, 1);
    clearActionFieldError(actionIndex);
    setDto(updated);
  };

  const getBlankRuleAction = (): AdapterRuleActionDto => {
    const dto: AdapterRuleActionDto = new AdapterRuleActionDto();
    Object.assign(dto, {
      action_script: "",
      description: "",
      is_default: false,
    });
    return dto;
  };

  const addRuleAction = (): void => {
    const updated: AdapterRuleDto = Object.assign({}, ruleDto);
    Object.setPrototypeOf(updated, Object.getPrototypeOf(ruleDto));
    updated.actions!.push(getBlankRuleAction());
    setDto(updated);
  };

  const renderRuleAction = (
    action: AdapterRuleActionDto,
    actionIndex: number
  ): ReactElement => {
    const onActionPropertyChange: PropertyChangeHandler =
      getArrayPropertyChangeHandler("actions", action, actionIndex);
    const getChangeHandler: (propName: string) => PropertySimpleChangeHandler =
      getArrayPropertySimpleChangeHandler("actions", action, actionIndex);
    const getActionValidationErrors = (
      actionField: string
    ): IFieldValidationErrors =>
      getArrayValidationErrors("actions", actionIndex, actionField);

    return (
      <div key={actionIndex} className={styles.actionField}>
        <div className={styles.fieldBody}>
          <div className={styles.bottomActionRow}>
            <RiverTextInput
              id={"description"}
              className={styles.description}
              label={t("entity.rule_action_ref:rule_action_ref.description")}
              value={action.description}
              onChangeEvent={onActionPropertyChange}
              validationErrors={getActionValidationErrors("description")}
            />
            <div className={styles.defaultAction}>
              <span>
                {t("entity.rule_action_ref:rule_action_ref.is_default")}
              </span>
              <span className={styles.tooltipWrapper}>
                <HelpOutlineIcon className={styles.tooltipIcon} />
                <div className={styles.tooltipText}>
                  An Action set as Automatic will be automatically executed for
                  records that satisfy the criteria defined in the
                  Expression/Where Pipeline. For example, an email can be sent
                  out automatically when there's a parts shortage.Only one
                  Action can be set as Automatic.Actions that aren't automatic
                  can be executed manually on the Scheduling page.
                </div>
              </span>

              <RiverSwitch
                id={"is_default"}
                className={styles.defaultSwitch}
                checked={!!action.is_default}
                onChangeEvent={onActionPropertyChange}
              />
            </div>
          </div>
          <RiverCodeInput
            id={"action_script"}
            value={action.action_script}
            validationErrors={getActionValidationErrors("action_script")}
            onChange={getChangeHandler("action_script")}
            editorLibModel={codeEditorModel}
          />
        </div>
        {!form?.isReadOnly && (
          <CancelIcon
            className={clsx([styles.deleteActionIcon])}
            onClick={() => {
              deleteRuleAction(actionIndex);
            }}
          />
        )}
      </div>
    );
  };

  const renderRuleActions = (): ReactElement => (
    <div className={styles.ruleActions}>
      <div className={styles.sectionTitle}>
        {t("module.rules:label.rule_actions")}
      </div>
      <div className={styles.actions}>
        {ruleDto.actions!.map((action, actionIndex) => {
          return renderRuleAction(action, actionIndex);
        })}
      </div>
      {!form?.isReadOnly && (
        <Button
          variant="text"
          color="primary"
          className={styles.addActionButton}
          startIcon={<AddCircleIcon />}
          onClick={addRuleAction}
        >
          {t("module.rules:label.add_action")}
        </Button>
      )}
    </div>
  );

  const renderExpressionField = (): ReactElement => (
    <RiverTextInput
      id={"expression"}
      label={t("entity.rule:rule.expression")}
      fullWidth
      inputProps={{
        multiline: true,
        minRows: 6,
        maxRows: 12,
      }}
    />
  );

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.rules:label.create_rule")
      : t("module.rules:label.edit_rule");
  const getActionButtonText = (): string =>
    isCreate ? t("common.button:add") : t("common.button:save");

  const DialogRow = useCallback(
    (props: React.PropsWithChildren<{}>): ReactElement => (
      <div className={styles.dialogRow}>{props.children}</div>
    ),
    []
  );

  const updateRuleDisplayOptions = (newOptions: object): void => {
    const ruleDisplayOptions: object = JSON.parse(ruleDto.display_options!);
    Object.assign(ruleDisplayOptions, newOptions);
    const display_options: string = JSON.stringify(ruleDisplayOptions);
    setDto(
      Object.assign(new AdapterRuleDto(), {
        ...ruleDto,
        display_options,
      })
    );
  };

  const renderRuleColorPicker = (): ReactElement => {
    const DEFAULT_RULE_TITLE = t("module.rules:label.sample_rule_title");
    const SAMPLE_RULE_WO_COUNT = 12;
    return (
      <RiverColorPicker
        title={t("module.rules:label.rule_color")}
        dialogTitle={t("module.rules:rule_color_picker.dialog.title")}
        initialColor={JSON.parse(ruleDto.display_options!).color}
        onSelectColor={(color: string) => updateRuleDisplayOptions({ color })}
        renderPreview={(color: string): ReactElement => (
          <RuleTile
            rule={
              {
                rule: ruleDto.rule || DEFAULT_RULE_TITLE,
                display_options: getUpdatedDisplayOptions(
                  ruleDto.display_options!,
                  {
                    color,
                  }
                ),
              } as IAdapterRule
            }
            entityCount={SAMPLE_RULE_WO_COUNT}
          />
        )}
      />
    );
  };

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open && codeEditorModel !== ""}
      onClose={close}
      actionButtonText={getActionButtonText()}
      showActionsDivider={false}
      onSubmit={() => {
        submit();
      }}
      classes={{
        paper: styles.paper,
        content: clsx([
          styles.content,
          { [styles.readOnly]: form?.isReadOnly },
        ]),
      }}
      form={form}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <RiverTextInput id={"rule"} fullWidth />
          {renderEntityNameField()}
          <DialogRow>
            {renderRuleColorPicker()}
            {renderTargetPeriodField()}
          </DialogRow>
          {renderExpressionField()}
        </Grid>
        <Grid item xs={8}>
          {renderRuleActions()}
        </Grid>
      </Grid>
    </RiverDialog>
  );
};
