import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactElement,
  useMemo,
  useRef,
} from "react";
import { Grid, GridSize } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
  TabContext,
  ITabContext,
} from "../../../../context";
import {
  RiverDateTimeInput,
  IRiverDateTimeInputProps,
} from "../../../../components/shared";
import {
  RiverDialog,
  RiverSpinner,
  RiverSwitch,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import {
  IEntityObject,
  IOracleEbsAdapterWorkorderCompletion,
  OracleEbsAdapterWorkorderCompletionDto,
  OracleEbsAdapterWorkorderMeterReadingDto,
} from "@river/interfaces";
import { RiverFormInstance } from "../../../../hooks";
import {
  useEbsWoCompletionForm,
  IOracleEbsWoCompletionStandaloneFields,
} from "./use-ebs-wo-completion-form";
import CompletionErrorIcon from "./completion-error-icon";
import { useMeterReadings } from "./use-meter-readings";
import {
  WoCompletionContext,
  IWoCompletionContext,
} from "./wo-completion-context";
import { WoCompletionProgress } from "./wo-completion-progress";
import { WoCompletionTabs } from "./wo-completion-tabs";
import styles from "./oracle-ebs-wo-completion-dialog.module.scss";
import clsx from "clsx";

export interface IOracleEbsWorkOrderCompletionDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
  workOrders?: any[];
}

export type WoCompletionStatus = {
  _id: string;
  status: "success" | "error";
  errors?: string[];
  workOrder?: any;
};

export type WoCompletionMap = {
  [_id: string]: WoCompletionStatus;
};

type ProgressInfo = {
  total: number;
  processed: number;
};

export const OracleEbsWoCompletionDialog: React.FC<
  IOracleEbsWorkOrderCompletionDialogProps
> = (props: IOracleEbsWorkOrderCompletionDialogProps) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const formRef = useRef<RiverFormInstance | null>(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progressInfo, setProgressInfo] = useState<ProgressInfo | null>(null);
  const selectedWorkOrders: any[] =
    props.workOrders || tableContext?.table!.getSelectedRows()!;
  const [currentWorkOrderId, setCurrentWorkOrderId] = useState<string>("");
  const currentWorkOrder: IEntityObject = selectedWorkOrders.find(
    (wo) => wo["WIP_ENTITY_NAME"] === currentWorkOrderId
  );
  const [woCompletions, setWoCompletions] = useState<any[]>([]);
  const [completionStatusMap, setCompletionStatusMap] =
    useState<WoCompletionMap>({});

  // current completionStatusMap ref mirror - required for access from Promise.all().finally()
  const completionStatusMapRef = useRef<WoCompletionMap>(completionStatusMap);
  const updateCompletionStatusMap = (
    val: WoCompletionMap | ((prev: WoCompletionMap) => WoCompletionMap)
  ): void => {
    if (typeof val === "function") {
      setCompletionStatusMap((prev) => {
        const newVal: WoCompletionMap = val(prev);
        completionStatusMapRef.current = newVal;
        return newVal;
      });
    } else {
      setCompletionStatusMap(val);
      completionStatusMapRef.current = val;
    }
  };

  const dialogRef = useRef<HTMLDivElement>(null);

  const {
    form,
    renderWoStatusLookup,
    renderReconciliationCodeLookup,
    renderFailureCodeLookup,
    renderCauseCodeLookup,
    renderResolutionCodeLookup,
    renderLocatorLookup,
    renderLotLookup,
    renderSubinventoryLookup,
    setSelectedFailureCode,
    setSelectedCauseCode,
    setSelectedResolutionCode,
  } = useEbsWoCompletionForm({ formRef });

  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const { renderTable: renderMeterReadingsTable } = useMeterReadings({
    form,
    rows: currentDto.METER_READINGS || [],
  });

  const tabContext: ITabContext = useMemo(
    () => ({
      selectedTab: currentWorkOrderId,
      setSelectedTab: (tabId: string) => {
        setCurrentWorkOrderId(tabId);
      },
    }),
    [currentWorkOrderId]
  );

  const resetDialogState = useCallback((): void => {
    setWoCompletions([]);
    updateCompletionStatusMap({});
    form.resetForm();
  }, [form]);

  const closeDialog = useCallback(
    (closeDialogProps: { success: boolean }): void => {
      const { success } = closeDialogProps;
      resetDialogState();
      props.onClose(success);
    },
    [props, resetDialogState]
  );

  const woCompletionContext: IWoCompletionContext = useMemo(
    () => ({
      completionStatusMap,
      selectedWorkOrders,
      woCompletions,
      setWoCompletions,
      closeDialog,
    }),
    [
      completionStatusMap,
      selectedWorkOrders,
      woCompletions,
      setWoCompletions,
      closeDialog,
    ]
  );

  const renderWoWipEntityName = (): ReactElement => (
    <RiverTextInput
      id={"WO.WIP_ENTITY_NAME"}
      value={currentWorkOrder["WIP_ENTITY_NAME"] as string}
      fullWidth
      disabled
    />
  );

  const renderWoDescription = (): ReactElement => (
    <RiverTextInput
      id={"WO.DESCRIPTION"}
      value={currentWorkOrder["DESCRIPTION"] as string}
      fullWidth
      disabled
    />
  );

  const renderWoAssetNumber = (): ReactElement => (
    <RiverTextInput
      id={"WO.ASSET_NUMBER"}
      value={currentWorkOrder["ASSET_NUMBER"] as string}
      fullWidth
      disabled
    />
  );

  const renderWoAssetDescription = (): ReactElement => (
    <RiverTextInput
      id={"WO.ASSET_DESCRIPTION"}
      value={(currentWorkOrder["ASSET_DESCRIPTION"] || " ") as string}
      fullWidth
      disabled
    />
  );

  const renderWoStatus = (): ReactElement => (
    <RiverTextInput
      id={"WO.WORK_ORDER_STATUS"}
      value={currentWorkOrder["WORK_ORDER_STATUS"] as string}
      fullWidth
      disabled
    />
  );

  const renderFailureCodeRequired = (): ReactElement => (
    <RiverSwitch
      id="FAILURE_CODE_REQUIRED"
      disabled={true}
      // @ts-ignore
      checked={currentDto["FAILURE_CODE_REQUIRED"]}
      className={styles.failureCodeRequired}
    />
  );

  const renderDateTimeInput = (
    renderProps: IRiverDateTimeInputProps
  ): ReactElement => {
    const dtiProps: IRiverDateTimeInputProps = {
      ...renderProps,
      className: clsx([styles.dateField, renderProps.className]),
    };
    return <RiverDateTimeInput {...dtiProps} />;
  };

  const renderActualStartDate = (): ReactElement =>
    renderDateTimeInput({ id: "ACTUAL_START_DATE" });

  const renderShutdownStartDate = (): ReactElement =>
    renderDateTimeInput({ id: "SHUTDOWN_START_DATE" });

  const renderActualEndDate = (): ReactElement =>
    renderDateTimeInput({ id: "ACTUAL_END_DATE" });

  const renderShutdownEndDate = (): ReactElement =>
    renderDateTimeInput({ id: "SHUTDOWN_END_DATE" });

  const renderFailureDate = (): ReactElement =>
    renderDateTimeInput({
      id: "FAILURE_DATE",
      // @ts-ignore
      disabled: !!currentDto["FAILURE_CODE_READONLY"],
    });

  const renderCompletionComments = (): ReactElement => (
    <RiverTextInput
      id={"COMPLETION_COMMENTS"}
      inputProps={{
        multiline: true,
        minRows: 4,
        maxRows: 6,
      }}
      fullWidth
    />
  );

  const renderFailureComments = (): ReactElement => (
    <RiverTextInput
      id={"FAILURE_COMMENTS"}
      //@ts-ignore
      disabled={currentDto["FAILURE_CODE_READONLY"]}
      inputProps={{
        multiline: true,
        minRows: 4,
        maxRows: 6,
      }}
      fullWidth
    />
  );

  const renderField = (props: {
    id?: string;
    xs: GridSize;
    className?: string;
    fn: () => ReactElement;
  }): ReactElement => (
    <Grid
      item
      xs={props.xs}
      className={clsx([styles.fieldContainer, props.className])}
    >
      {props.fn()}
    </Grid>
  );

  const updateCurrentWoCompletion = (): void => {
    const woId: string = currentDto["_id"];
    const currentWoCompletion: IOracleEbsAdapterWorkorderCompletion =
      woCompletions.find((wc) => wc["_id"] === woId);
    if (currentWoCompletion) {
      Object.assign(currentWoCompletion, { ...currentDto });
    }
    setWoCompletions([...woCompletions]);
  };

  useEffect(() => {
    if (currentWorkOrderId && !!woCompletions.length) {
      updateCurrentWoCompletion();
      form.resetForm();
      showCurrentWoCompletion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkOrderId, woCompletions.length]);

  const showCurrentWoCompletion = (): void => {
    const woCompletion: IOracleEbsAdapterWorkorderCompletion =
      woCompletions.find((cd) => cd["_id"] === currentWorkOrder?.["_id"]);
    if (woCompletion) {
      const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
        new OracleEbsAdapterWorkorderCompletionDto(),
        {
          ...currentDto,
          ...woCompletion,
        }
      );
      form.setDto(newDto);

      form.setStandaloneFields({
        ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
        //@ts-ignore
        workOrderStatus: newDto["WORK_ORDER_STATUS"] as string,
        //@ts-ignore
        subinventoryDisplay: newDto["SUBINVENTORY_CODE"] || "",
        //@ts-ignore
        reconciliationMeaning: newDto["RECONCILIATION_MEANING"] || "",
        //@ts-ignore
        locatorCode: newDto["LOCATOR_CODE"] || "",
        //@ts-ignore
        lotDisplay: newDto["LOT_NUMBER"] || "",
        //@ts-ignore
        failureCodeDisplay: newDto["FAILURE_CODE"] || "",
        //@ts-ignore
        causeCodeDisplay: newDto["CAUSE_CODE"] || "",
        //@ts-ignore
        resolutionCodeDisplay: newDto["RESOLUTION_CODE"] || "",
      });
      setSelectedFailureCode({
        FAILURE_CODE: newDto["FAILURE_CODE"] || "",
      });
      setSelectedCauseCode({
        CAUSE_CODE: newDto["CAUSE_CODE"] || "",
      });
      setSelectedResolutionCode({
        RESOLUTION_CODE: newDto["RESOLUTION_CODE"] || "",
      });
    }
  };

  const loadCompletionDefaults = async (): Promise<void> => {
    let workOrderIds: string[] = [];
    if (props.workOrders) {
      workOrderIds = props.workOrders.map((wo) => wo["_id"]);
    } else {
      tableContext?.table.selectedRowIds.forEach((id: string) => {
        workOrderIds.push(id);
      });
    }

    try {
      setIsLoading(true);
      const defaults: any[] = await adapterContext!.service
        .getAdapterService()
        .getWorkorderCompletionDefaults({ entity_ids: workOrderIds });
      setWoCompletions(defaults);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      setCurrentWorkOrderId(selectedWorkOrders[0]?.["WIP_ENTITY_NAME"] || "");
      loadCompletionDefaults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const renderWoInfoSection = (): ReactElement => {
    return (
      <>
        {currentWorkOrder && (
          <div className={clsx([styles.woSection])}>
            <div className={styles.sectionTitle}>
              <span className={styles.label}>
                {t("shared.wo_completion:dialog.wo_section_title")}
              </span>
            </div>
            <Grid container className={styles.sectionContent}>
              <Grid container item xs={8}>
                {renderField({ xs: 4, fn: renderWoWipEntityName })}
                {renderField({ xs: 8, fn: renderWoDescription })}
                {renderField({ xs: 4, fn: renderWoAssetNumber })}
                {renderField({ xs: 8, fn: renderWoAssetDescription })}
              </Grid>
              <Grid container item xs={4}>
                {renderField({ xs: 9, fn: renderWoStatus })}
                {renderField({ xs: 12, fn: renderFailureCodeRequired })}
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  };

  const renderCompletionDetailsSection = (): ReactElement => {
    return (
      <div className={clsx([styles.woSection])}>
        <div className={styles.sectionTitle}>
          <span className={styles.label}>
            {t("shared.wo_completion:dialog.completion_details_section_title")}
          </span>
        </div>
        <Grid container className={styles.sectionContent}>
          <Grid container item xs={6}>
            <Grid item container xs={12}>
              {renderField({ xs: 6, fn: renderWoStatusLookup })}
            </Grid>
            {renderField({ xs: 6, fn: renderActualStartDate })}
            {renderField({ xs: 6, fn: renderShutdownStartDate })}
            {renderField({ xs: 6, fn: renderActualEndDate })}
            {renderField({ xs: 6, fn: renderShutdownEndDate })}
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              {renderField({ xs: 6, fn: renderSubinventoryLookup })}
            </Grid>
            <Grid item xs={12}>
              {renderField({ xs: 6, fn: renderLocatorLookup })}
            </Grid>
            <Grid item xs={12}>
              {renderField({ xs: 6, fn: renderLotLookup })}
            </Grid>
          </Grid>
          {renderField({ xs: 3, fn: renderReconciliationCodeLookup })}
          {renderField({
            xs: 6,
            fn: renderCompletionComments,
            className: styles.multiline,
          })}
        </Grid>
      </div>
    );
  };

  const renderFailureCodeSection = (): ReactElement => {
    return (
      <div className={clsx([styles.woSection])}>
        <div className={styles.sectionTitle}>
          <span className={styles.label}>
            {t("shared.wo_completion:dialog.failure_code_section_title")}
          </span>
        </div>
        <Grid container item xs={12}>
          {renderField({ xs: 3, fn: renderFailureCodeLookup })}
          {renderField({ xs: 3, fn: renderCauseCodeLookup })}
          <Grid item xs={6} container>
            {renderField({ xs: 6, fn: renderResolutionCodeLookup })}
          </Grid>
          {renderField({ xs: 3, fn: renderFailureDate })}
          {renderField({
            xs: 6,
            fn: renderFailureComments,
            className: styles.multiline,
          })}
        </Grid>
      </div>
    );
  };

  const renderMeterReadingsSection = (): ReactElement => {
    return (
      <div className={clsx([styles.woSection])}>
        <div className={styles.sectionTitle}>
          <span className={styles.label}>
            {t("shared.wo_completion:dialog.meter_readings_section_title")}
          </span>
        </div>
        <div className={clsx([styles.sectionContent, styles.meterReadings])}>
          {renderMeterReadingsTable()}
        </div>
      </div>
    );
  };

  const renderCurrentCompletionErrors = () => {
    const woId: string = (currentWorkOrder?.["_id"] || "") as string;
    const currentStatus: WoCompletionStatus = completionStatusMap[woId];
    const errors: string[] = currentStatus?.errors || [];
    return (
      <>
        {!!errors?.length && (
          <>
            <div className={styles.completionErrors}>
              <CompletionErrorIcon className={styles.icon} />
              <div className={styles.messageSection}>
                <span className={styles.errorHeader}>
                  {t("common.label:error")}
                </span>
                <span className={styles.errorMessages}>
                  {errors.map((message) => (
                    <span className={styles.errorMessage}>{message}</span>
                  ))}
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const formatWoCompletionDto = (woCompletion: any): any => {
    const formatted: OracleEbsAdapterWorkorderCompletionDto = {
      _id: "",
      STATUS_TYPE: 0,
      ACTUAL_START_DATE: new Date(),
      ACTUAL_END_DATE: new Date(),
      SHUTDOWN_START_DATE: null,
      SHUTDOWN_END_DATE: null,
      RECONCILIATION_CODE: null,
      COMPLETION_COMMENTS: null,
      SUBINVENTORY_CODE: null,
      LOCATOR_ID: null,
      LOT_NUMBER: null,
      FAILURE_ID: null,
      FAILURE_ENTRY_ID: null,
      FAILURE_DATE: null,
      FAILURE_CODE: null,
      CAUSE_CODE: null,
      RESOLUTION_CODE: null,
      FAILURE_COMMENTS: null,
      ATTRIBUTE1: null,
      ATTRIBUTE2: null,
      ATTRIBUTE3: null,
      ATTRIBUTE4: null,
      ATTRIBUTE5: null,
      ATTRIBUTE6: null,
      ATTRIBUTE7: null,
      ATTRIBUTE8: null,
      ATTRIBUTE9: null,
      ATTRIBUTE10: null,
      ATTRIBUTE11: null,
      ATTRIBUTE12: null,
      ATTRIBUTE13: null,
      ATTRIBUTE14: null,
      ATTRIBUTE15: null,
    };
    Object.keys(formatted).forEach((key) => {
      // @ts-ignore
      formatted[key] = woCompletion[key];
    });

    let meterReadings: any[] = [...(woCompletion["METER_READINGS"] || [])];
    meterReadings = meterReadings.map((mr) => {
      const meterReadingDto: OracleEbsAdapterWorkorderMeterReadingDto = {
        METER_ID: mr["METER_ID"],
        READING_VALUE: mr["READING_VALUE"],
      };
      return meterReadingDto;
    });
    formatted.METER_READINGS = meterReadings;
    return formatted;
  };

  const resetCompletionStatusMap = (completionsToReset: any[]): void => {
    completionsToReset.forEach((completion) => {
      const woId: string = completion["_id"];
      delete completionStatusMap[woId];
    });
    updateCompletionStatusMap({ ...completionStatusMap });
  };

  const updateAll = async (): Promise<any> => {
    updateCurrentWoCompletion();

    const promises: Promise<void>[] = [];

    const completionsToUpdate: any[] = woCompletions.filter((obj) => {
      const workOrder: any = selectedWorkOrders.find(
        (wo) => wo["_id"] === obj["_id"]
      );
      return workOrder?.["WORK_ORDER_STATUS"] !== "Complete";
    });
    resetCompletionStatusMap(completionsToUpdate);

    if (completionsToUpdate.length) {
      setProgressInfo({
        total: completionsToUpdate.length,
        processed: 0,
      });
    }

    completionsToUpdate.forEach((woCompletion) => {
      const woCompletionDto: any = formatWoCompletionDto(woCompletion);
      promises.push(
        adapterContext!.service
          .getAdapterService()
          .completeWorkorder(woCompletionDto)
          .then((updatedWorkOrder: any) => {
            const woId: string = woCompletionDto["_id"];
            const woStatus: WoCompletionStatus = {
              _id: woId,
              status: "success",
              workOrder: updatedWorkOrder,
            };
            updateCompletionStatusMap((prevMap) => {
              prevMap[woId] = woStatus;
              return { ...prevMap };
            });
          })
          .catch((err) => {
            const woId: string = woCompletionDto["_id"];
            const error: string | string[] =
              err.response?.data?.message ?? "Completion Failed";
            const errors: string[] = Array.isArray(error) ? error : [error];
            const woStatus: WoCompletionStatus = {
              _id: woId,
              status: "error",
              errors,
            };
            updateCompletionStatusMap((prevMap) => {
              prevMap[woId] = woStatus;
              return { ...prevMap };
            });
          })
          .finally(() => {
            setProgressInfo((prevInfo) => {
              return prevInfo
                ? {
                    ...prevInfo,
                    processed: prevInfo.processed + 1,
                  }
                : prevInfo;
            });
          })
      );
    });
    return Promise.all(promises).finally(() => {
      if (!promises.length) return;

      const updatedRows: {
        rowId: string;
        updatedRow: Partial<IEntityObject>;
      }[] = [];
      for (const [rowId, completionStatus] of Object.entries(
        completionStatusMapRef.current
      )) {
        if (completionStatus.status === "success") {
          updatedRows.push({ rowId, updatedRow: completionStatus.workOrder });
        }
      }
      tableContext?.table!.updateRows({
        rows: updatedRows,
        unselectRows: false,
      });
      setTimeout(() => {
        setProgressInfo(null);
      }, 1000);
    });
  };

  const render = (): ReactElement => (
    <TabContext.Provider value={tabContext}>
      <WoCompletionContext.Provider value={woCompletionContext}>
        <div className={styles.content}>
          <RiverSpinner show={isLoading} />
          <WoCompletionTabs />
          {renderCurrentCompletionErrors()}
          {renderWoInfoSection()}
          {renderCompletionDetailsSection()}
          {renderFailureCodeSection()}
          {renderMeterReadingsSection()}
          <WoCompletionProgress
            open={!!progressInfo}
            onClose={() => setProgressInfo(null)}
            {...progressInfo}
          />
        </div>
      </WoCompletionContext.Provider>
    </TabContext.Provider>
  );

  return (
    <RiverDialog
      title={t("shared.wo_completion:dialog.title")}
      open={props.open}
      onClose={() => {
        const success = false;
        tableContext?.table!.setSelectedRowIds(new Set());
        closeDialog({ success });
      }}
      actionButtonText={t("shared.wo_completion:dialog.update_all")}
      closeButtonText={t("common.label:close")}
      showActionsDivider={false}
      onSubmit={updateAll}
      form={form}
      classes={{
        paper: styles.paper,
      }}
      dialogProps={{
        ref: dialogRef,
      }}
    >
      {render()}
    </RiverDialog>
  );
};
