import React, { ReactElement, useState } from "react";
import { Languages } from "./languages";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

const ModuleWrapper: React.FC<ITranslationProps> = (
  props: ITranslationProps
): ReactElement => <>{props.tReady && <Languages />}</>;

export const LanguagesModule: React.FC = (props): ReactElement => {
  const namespaces: string[] = [
    "module.languages",
    "shared.lookup",
    "shared.grid_header",
    "shared.advanced_filters",
    "shared.saved_filters_dialog",
    "shared.save_filters_dialog",
    "shared.error_messages",
    "shared.user_menu",
    "common.button",
    "common.label",
    "shared.river_data_grid",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
