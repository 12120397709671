import { useContext, useEffect, useState } from "react";
import { AdapterDependencyActionDto, IEntityObject } from "@river/interfaces";
import { uiConstants } from "../../../../../helpers";
import { DEFAULT_ROW_HEIGHT as ROW_HEIGHT } from "../../../../shared";
import {
  ScheduleContext,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  TabContext,
} from "../../../../../context";
import { IUseScheduleGantt } from "../../use-schedule-gantt";
import { RelationType } from "./schedule-operations-gantt-overlay";
import { useNotification } from "@river/common-ui";
import {
  ScheduleTasksTabId,
  TableUiService,
  TaskHelpersUiService,
} from "../../../../../services";

export interface IOperationPosition {
  yPos: number;
  startXPercent: number;
  endXPercent: number;
}

export interface IDraggedObject {
  object?: IEntityObject;
  xPercent?: number;
  yPos?: number;
  virtual?: boolean;
}

export interface IUseScheduleOperationsOverlay {
  relations: IEntityObject[];
  getOperationByOrderIdAndActivity: (
    orderId: string,
    activity: string
  ) => IEntityObject;
  draggedObject: IDraggedObject | null;
  setDraggedObject: (object: IDraggedObject | null) => void;
  getStartXPercent: (operation: IEntityObject) => number;
  getEndXPercent: (operation: IEntityObject) => number;
  getYPos: (operation: IEntityObject) => number;
  operationsEqual: (op1: IEntityObject, op2: IEntityObject) => boolean;
  addVirtualRelation: (
    predecessor: IEntityObject,
    relationType: RelationType
  ) => void;
  removeVirtualRelation: () => void;
  getRelationsByPropertyValues: (query: any) => IEntityObject[];
  createDependencyFromVirtualRelation: (
    successorOperation: IEntityObject,
    successorDirection: "start" | "end"
  ) => Promise<void>;
  hasRightConnector: (operation: IEntityObject) => boolean;
  hasLeftConnector: (operation: IEntityObject) => boolean;
}

interface IUseScheduleOperationsOverlayProps {
  gantt: IUseScheduleGantt;
  data: IEntityObject[];
}

export const useScheduleOperationsOverlay = (
  props: IUseScheduleOperationsOverlayProps
): IUseScheduleOperationsOverlay => {
  const [relations, setRelations] = useState<IEntityObject[]>([]);
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const {
    getTaskStartDate,
    getTaskEndDate,
    getOperationsOverlayPropertyNames,
  } = taskHelpers.getHelpers()();
  const {
    to_Relations_path,
    OrderIdProp,
    OrderPredecessorProp,
    OrderSuccessorProp,
    OperationPredecessorProp,
    OperationSuccessorProp,
    OperationActivityProp,
    RelationTypeProp,
    OrderNumberProp,
  } = getOperationsOverlayPropertyNames();
  const tableContext = useContext(TableContext);
  const tabContext = useContext(TabContext);
  const isOperationsTab = (): boolean =>
    tabContext?.selectedTab === ScheduleTasksTabId.OPERATIONS;
  const notify = useNotification();
  const [draggedObject, setDraggedObject] = useState<IDraggedObject | null>(
    null
  );

  const setupRelations = (): void => {
    let discoveredRelations: IEntityObject[] = [];
    const isRelationDiscovered = (rel: IEntityObject): boolean =>
      discoveredRelations.findIndex(
        (obj) =>
          obj[OrderPredecessorProp] === rel[OrderPredecessorProp] &&
          obj[OrderSuccessorProp] === rel[OrderSuccessorProp] &&
          obj[OperationPredecessorProp] === rel[OperationPredecessorProp] &&
          obj[OperationSuccessorProp] === rel[OperationSuccessorProp]
      ) !== -1;

    props.data.forEach((operation) => {
      const rels: IEntityObject[] = operation[
        to_Relations_path
      ] as IEntityObject[];
      rels?.forEach((rel) => {
        if (!isRelationDiscovered(rel)) {
          discoveredRelations.push(rel);
        }
      });
    });

    discoveredRelations = discoveredRelations.filter((rel) => {
      const relationOrderId: string = rel[OrderNumberProp] as string;
      const operationPredecessor: string = rel[
        OperationPredecessorProp
      ] as string;
      const operationSuccessor: string = rel[OperationSuccessorProp] as string;
      const match: IEntityObject[] = props.data.filter((operation): boolean => {
        const operationOrderId: string = operation[OrderNumberProp] as string;
        const operationActivity: string = operation[
          OperationActivityProp
        ] as string;
        return (
          operationOrderId === relationOrderId &&
          [operationPredecessor, operationSuccessor].includes(operationActivity)
        );
      });
      return match.length > 0;
    });
    setRelations(discoveredRelations);
  };

  const getOperationByOrderIdAndActivity = (
    orderId: string,
    activity: string
  ): IEntityObject => {
    return props.data.filter((operation) => {
      const operationOrderId: string = operation[OrderNumberProp] as string;
      const operationActivity: string = operation[
        OperationActivityProp
      ] as string;
      return (
        String(orderId) === String(operationOrderId) &&
        String(activity) === String(operationActivity)
      );
    })[0];
  };

  const getStartXPercent = (operation: IEntityObject): number => {
    const operationStartDate: Date = new Date(getTaskStartDate(operation));
    return props.gantt.getXPercentFromDate(operationStartDate);
  };

  const operationsEqual = (op1: IEntityObject, op2: IEntityObject): boolean => {
    let equal: boolean = true;
    const matchProperties: string[] = [OrderIdProp, OperationActivityProp];
    matchProperties.forEach((prop) => {
      if (op1[prop] !== op2[prop]) {
        equal = false;
      }
    });
    return equal;
  };

  const getEndXPercent = (operation: IEntityObject): number => {
    const operationEndDate: Date = new Date(getTaskEndDate(operation));
    return props.gantt.getXPercentFromDate(operationEndDate);
  };

  const getYPos = (operation: IEntityObject): number => {
    const index: number = props.data.findIndex((op) =>
      operationsEqual(op, operation)
    );
    const rowYStart = index * ROW_HEIGHT;
    const rowYStartOffset = ROW_HEIGHT / 2;
    return rowYStart + rowYStartOffset;
  };

  useEffect(() => {
    setupRelations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const getRelationsByPropertyValues = (query: any): IEntityObject[] => {
    let result: IEntityObject[] = [];
    relations.forEach((rel) => {
      let match: boolean = true;
      if (
        Object.keys(query).findIndex((key) => rel[key] !== query[key]) !== -1
      ) {
        match = false;
      }
      if (match) result.push(rel);
    });
    return result;
  };

  const addVirtualRelation = (
    predecessor: IEntityObject,
    relationType: RelationType
  ): void => {
    const predecessorOrderId: string = predecessor[OrderNumberProp] as string;
    const predecessorActivity: string = predecessor[
      OperationActivityProp
    ] as string;

    const newRelations: IEntityObject[] = [...relations];
    newRelations.push({
      [OrderIdProp]: predecessorOrderId,
      [OrderPredecessorProp]: predecessorOrderId,
      [OperationPredecessorProp]: predecessorActivity,
      [OrderSuccessorProp]: "",
      [OperationSuccessorProp]: "virtual",
      [RelationTypeProp]: relationType,
    });
    setRelations(newRelations);

    setDraggedObject({
      virtual: true,
      xPercent: getStartXPercent(predecessor),
      yPos: getYPos(predecessor),
    });
  };

  const removeVirtualRelation = (): void => {
    const newRelations: IEntityObject[] = [...relations];
    const virtualRelationIndex: number = newRelations.findIndex(
      (rel) => rel[OperationSuccessorProp] === "virtual"
    );
    if (virtualRelationIndex !== -1) {
      newRelations.splice(virtualRelationIndex, 1);
    }
    setRelations(newRelations);
    setDraggedObject(null);
  };

  const getVirtualRelation = (): IEntityObject =>
    relations.find(
      (relation) => String(relation[OperationSuccessorProp]) === "virtual"
    )!;

  const createDependencyFromVirtualRelation = async (
    successorOperation: IEntityObject,
    successorDirection: string
  ): Promise<void> => {
    const virtualRelation: IEntityObject = getVirtualRelation();
    if (virtualRelation) {
      const predecessorOperation: IEntityObject =
        getOperationByOrderIdAndActivity(
          String(virtualRelation[OrderPredecessorProp]),
          String(virtualRelation[OperationPredecessorProp])
        );

      // can not have relation to itself
      if (
        predecessorOperation[OperationActivityProp] ===
          successorOperation[OperationActivityProp] &&
        predecessorOperation[OrderIdProp] === successorOperation[OrderIdProp]
      ) {
        return;
      }

      let dependency_type: RelationType = virtualRelation[
        RelationTypeProp
      ] as RelationType;
      if (
        [RelationType.START_TO_FINISH, RelationType.START_TO_START].indexOf(
          dependency_type
        ) !== -1
      ) {
        dependency_type =
          successorDirection === "start"
            ? RelationType.START_TO_START
            : RelationType.START_TO_FINISH;
      } else {
        dependency_type =
          successorDirection === "start"
            ? RelationType.FINISH_TO_START
            : RelationType.FINISH_TO_FINISH;
      }

      const payload: AdapterDependencyActionDto = {
        dependency_type,
        predecessor_id: String(predecessorOperation[uiConstants.fields._id]),
        successor_id: String(successorOperation[uiConstants.fields._id]),
        folder_id: scheduleContext?.currentSchedule?._id,
        lag_hours: 0,
      };
      try {
        tableContext?.table.forceLoadingState(true);
        const updatedRows = await adapterContext!.service
          .getAdapterService()
          .createDependency(payload);
        tableContext?.table?.updateRows({
          rows: updatedRows.map((updatedRow) => ({
            rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
            updatedRow,
          })),
        });
      } catch (message) {
        notify.error({ message });
      } finally {
        tableContext?.table.forceLoadingState(false);
      }
    }
  };

  const hasRightConnector = (operation: IEntityObject): boolean => {
    if (!isOperationsTab()) {
      return false;
    }
    const predecessorRelation: IEntityObject = getRelationsByPropertyValues({
      [OperationPredecessorProp]: operation[OperationActivityProp],
      [OrderPredecessorProp]: operation[OrderIdProp],
    }).find(
      (rel) =>
        [RelationType.FINISH_TO_START, RelationType.FINISH_TO_FINISH].indexOf(
          rel[RelationTypeProp] as RelationType
        ) !== -1
    )!;
    const successorRelation: IEntityObject = getRelationsByPropertyValues({
      [OperationSuccessorProp]: operation[OperationActivityProp],
      [OrderSuccessorProp]: operation[OrderIdProp],
    }).find(
      (rel) =>
        [RelationType.START_TO_FINISH, RelationType.FINISH_TO_FINISH].indexOf(
          rel[RelationTypeProp] as RelationType
        ) !== -1
    )!;
    return !predecessorRelation && !successorRelation;
  };

  const hasLeftConnector = (operation: IEntityObject): boolean => {
    if (!isOperationsTab()) {
      return false;
    }
    const operationsOverlay = scheduleContext?.operationsOverlayRef.current;
    if (operationsOverlay) {
      const predecessorRelation: IEntityObject = getRelationsByPropertyValues({
        [OperationPredecessorProp]: operation[OperationActivityProp],
        [OrderPredecessorProp]: operation[OrderIdProp],
      }).find(
        (rel) =>
          [RelationType.START_TO_START, RelationType.START_TO_FINISH].indexOf(
            rel[RelationTypeProp] as RelationType
          ) !== -1
      )!;
      const successorRelation: IEntityObject = getRelationsByPropertyValues({
        [OperationSuccessorProp]: operation[OperationActivityProp],
        [OrderSuccessorProp]: operation[OrderIdProp],
      }).find(
        (rel) =>
          [RelationType.START_TO_START, RelationType.FINISH_TO_START].indexOf(
            rel[RelationTypeProp] as RelationType
          ) !== -1
      )!;
      return !predecessorRelation && !successorRelation;
    }
    return false;
  };

  return {
    relations,
    getOperationByOrderIdAndActivity,
    draggedObject,
    setDraggedObject,
    getStartXPercent,
    getEndXPercent,
    getYPos,
    operationsEqual,
    addVirtualRelation,
    removeVirtualRelation,
    getRelationsByPropertyValues,
    createDependencyFromVirtualRelation,
    hasRightConnector,
    hasLeftConnector,
  };
};
