import React, { ReactElement, useContext, useState } from "react";
import { IAdapterCalendar, IAdapterShift } from "@river/interfaces";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  FilterDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { CalendarsAction } from "../calendars";
import { ModuleKey } from "../../sidebar-menu";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";

interface ICalendarsGridHeader {
  className?: string;
  selectedCalendar: IAdapterCalendar;
  shifts: IAdapterShift[];
}

export const CalendarsGridHeader: React.FC<ICalendarsGridHeader> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();

  useState<boolean>(false);
  const tableContext = useContext(TableContext);
  const notify = useNotification();

  const deleteSelectedCalendar = async (): Promise<any> => {
    if (props.selectedCalendar) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteCalendar(props.selectedCalendar._id);
        tableContext?.table.fetch();
        deleteCalendarConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteCalendarConfirmation = useSimpleDialog({
    title: t("module.calendars:dialog.delete_calendar"),
    message: t("module.calendars:message.confirm_calendar_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedCalendar,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.CALENDARS}
            action={CalendarsAction.DELETE}
          >
            <DeleteAction onClick={() => deleteCalendarConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteCalendarConfirmation.render()}
    </>
  );
};
