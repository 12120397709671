import React, { ReactElement, useContext } from "react";
import { RiverTextInput, RiverDialog, useNotification } from "@river/common-ui";
import { AdapterCrewDto, IAdapterCrew } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useCrewForm } from "./use-crew-form";
import { useTranslation } from "@river/common-ui";
import { LookupType } from "../../shared";
import { RiverTableLookup } from "../../shared/river-table-lookup";
import { IColumn } from "../../../interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { CrewsUiService } from "../../../services";

interface ICrewDialogProps {
  open: boolean;
  crew?: IAdapterCrew;
  onClose: (crew: IAdapterCrew | null) => void;
}

export const CrewDialog: React.FC<ICrewDialogProps> = (props): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CrewsUiService =
    adapterContext?.service!.getCrewsUiService()!;

  const form: RiverFormInstance = useCrewForm({
    crew: props.crew,
    onCreate: (crew) => {
      props.onClose(crew);
      resetForm();
    },
    onUpdate: () => props.onClose(null),
  });
  const { t } = useTranslation();
  const notify = useNotification();

  const { submit, resetForm, setDto, isReadOnly } = form;
  const crewDto = form.dto as AdapterCrewDto;

  const renderSupervisorSection = (): ReactElement => {
    const columns: IColumn[] = uiService.getSupervisorTableColumns()();
    return (
      <RiverTableLookup
        title={t("entity.crew:crew.supervisors")}
        buttonLabel={t("module.crews:button.add_supervisors")}
        rows={crewDto.supervisors}
        columns={columns}
        isReadOnly={isReadOnly}
        lookup={{
          type: LookupType.SUPERVISORS,
        }}
        singleSelect={true}
        onSelect={(selection) => {
          const selected = selection[0];
          const { display_name, user_name, _id } = selected;
          if (
            !crewDto.supervisors.find(
              (supervisor) => supervisor.user_id === _id
            )
          ) {
            setDto({
              ...crewDto,
              supervisors: [
                ...crewDto.supervisors,
                { display_name, user_name, user_id: _id },
              ],
            });
          } else {
            notify.error(
              t("module.crews:notification.supervisor_already_selected")
            );
          }
        }}
        onDelete={(supervisor) => {
          setDto({
            ...crewDto,
            supervisors: crewDto.supervisors?.filter(
              (item: any) => item.user_id !== supervisor.user_id
            ),
          });
        }}
      />
    );
  };

  const dialogTitle: string = !props.crew
    ? t("module.crews:button.add_crew")
    : t("module.crews:button.update_crew");

  const dialogButtonText: string = !props.crew
    ? t("common.button:next")
    : t("common.button:save");

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        props.onClose(null);
        resetForm();
      }}
      actionButtonText={dialogButtonText}
      onSubmit={submit}
      showActionsDivider={false}
      fullWidth
      form={form}
    >
      <RiverTextInput id={"crew"} fullWidth />
      <RiverTextInput id={"description"} fullWidth />
      {renderSupervisorSection()}
    </RiverDialog>
  );
};
