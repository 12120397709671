import React from "react";
import { RiverSVGProps } from "./types";
import { sanitizeSvgProps } from "./icon-utils";

export const LayoutIcon: React.FC<RiverSVGProps> = (props) => (
  <svg
    viewBox="0 0 580 580"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...sanitizeSvgProps(props)}
  >
    <title>{props.title}</title>
    <path
      d="M0,0v512h512V0H0z M469.333,128h-192V42.667h192V128z M277.333,277.333h192v64h-192V277.333z M277.333,234.667v-64h192v64
			H277.333z M42.667,42.667h192v426.667h-192V42.667z M277.333,469.333V384h192v85.333H277.333z"
    />
  </svg>
);
