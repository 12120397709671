import { ReactElement, useContext } from "react";
import jsonata from "jsonata";
import { AdapterUiService } from "../adapter-ui.service";
import {
  TabsProvider,
  EntityTableProvider,
  ColumnsProvider,
  ReactElementProvider,
  ColumnFiltersProvider,
  RiverDropdownActionsProvider,
  QueryAttributeGroupProvider,
} from "../ui-service.types";
import { IEntityObject, QueryAttributeGroupDto } from "@river/interfaces";
import {
  IWoMassUpdateDialogProps,
  IMaterialRequirementsDialogProps,
  MaterialRequirementsDialog,
  IOpMassUpdateDialogProps,
} from "../shared";

import { useSearchParams } from "react-router-dom";
import { commonModuleActions } from "../../hooks";
import { getBlankQuery, IUseTable } from "../../components/shared";
import {
  ISupervisorScheduleContext,
  TabContext,
  TableContext,
} from "../../context";
import { IColumnFilter } from "../../interfaces";
import { useTranslation } from "@river/common-ui";

enum ModuleAction {
  // General actions
  SCHEDULE = "SCHEDULE",
  WO_EDIT = "WO_EDIT",
  WO_JPS = "WO_JPS",
  WO_ACTION = "WO_ACTION",
  WO_POST = "WO_POST",
  WO_MATERIAL_SHORTAGE = "WO_MATERIAL_SHORTAGE",
  WO_PRINT = "WO_PRINT",
  CREATE_BASELINE = "CREATE_BASELINE",
  WO_AUTO_SCHEDULE = "WO_AUTO_SCHEDULE",
  OP_CALENDAR = "OP_CALENDAR",
  WO_COMPLETION_PCT = "WO_COMPLETION_PCT",
  OP_COMPLETION_PCT = "OP_COMPLETION_PCT",
  WO_BREAKIN = "WO_BREAKIN",

  // SAP-specific
  WO_USER_STATUS = "WO_USER_STATUS",
  OP_EDIT = "OP_EDIT",
  OP_USER_STATUS = "OP_USER_STATUS",

  // Oracle EBS-specific
  WO_COMPLETION = "WO_COMPLETION",

  // Timecard actions
  CREATE_OPERATION_TIMECARD = "CREATE_OPERATION_TIMECARD",
  CREATE_ASSIGNMENT_TIMECARD = "CREATE_ASSIGNMENT_TIMECARD",
  CREATE_RESOURCE_TIMECARD = "CREATE_RESOURCE_TIMECARD",
  CREATE_INSTANCE_TIMECARD = "CREATE_INSTANCE_TIMECARD",
}

export type SupervisorScheduleAction =
  | ModuleAction
  | commonModuleActions.GlobalFilterAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SupervisorScheduleAction = {
  ...ModuleAction,
  ...commonModuleActions.GlobalFilterAction,
};

export type SupervisorScheduleTasksTableRefs = {
  [key in SupervisorScheduleTasksTabId]?: IUseTable;
};

export type SupervisorScheduleTasksInitialFiltersOverrides = {
  [key in SupervisorScheduleTasksTabId]?: IColumnFilter[] | null;
};

export type SupervisorScheduleTasksInitialQueryOverrides = {
  [key in SupervisorScheduleTasksTabId]?: QueryAttributeGroupDto | null;
};

export interface ICreateSupervisorTasksTabSelectionQueryProps {
  sourceTab: SupervisorScheduleTasksTabId;
  targetTab: SupervisorScheduleTasksTabId;
  selectedRows: IEntityObject[];
}

export enum SupervisorScheduleViewId {
  LIST = "list",
  GANTT = "gantt",
}

export enum SupervisorScheduleTasksTabId {
  WORKORDERS = "workorders",
  ASSIGNMENTS = "assignments",
  OPERATIONS = "operations",
  RESOURCES = "resources",
  INSTANCES = "instances",
  TIMECARDS = "timecards",
}

export type SupervisorScheduleTaskTabKeyColumnMap = {
  [sourceTab in SupervisorScheduleTasksTabId]?: {
    [targetTab in SupervisorScheduleTasksTabId]?: {
      sourceField: string;
      targetField: string;
    }[];
  };
};

export const DEFAULT_SUPERVISOR_SCHEDULE_VIEW = SupervisorScheduleViewId.GANTT;
export const SupervisorScheduleViewURLParamName = "view";
export const SupervisorScheduleTasksTabURLParamName = "tab";

export interface IGetDefaultSupervisorScheduleWorkOrderColumnsProps {
  onWorkOrderClick: (workOrder: IEntityObject) => void;
  getTaskColor: (row: IEntityObject) => string;
  draggable?: boolean;
}

export interface IUseSupervisorScheduleCurrentTableProps {
  scheduleContext: ISupervisorScheduleContext;
}

export abstract class SupervisorScheduleUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  taskTableRefs: SupervisorScheduleTasksTableRefs = {};
  taskInitialFiltersOverrides: SupervisorScheduleTasksInitialFiltersOverrides =
    {};
  taskInitialQueryOverrides: SupervisorScheduleTasksInitialQueryOverrides = {};

  getCurrentTab = (): (() => SupervisorScheduleTasksTabId) => {
    return (): SupervisorScheduleTasksTabId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(SupervisorScheduleTasksTabURLParamName) ||
        this.getDefaultTab()) as SupervisorScheduleTasksTabId;
    };
  };

  getCurrentView = (): (() => SupervisorScheduleViewId) => {
    return (): SupervisorScheduleViewId => {
      const [searchParams] = useSearchParams();
      return (searchParams.get(SupervisorScheduleViewURLParamName) ||
        DEFAULT_SUPERVISOR_SCHEDULE_VIEW) as SupervisorScheduleViewId;
    };
  };

  getInitialTabSimpleFilters = (
    tabId: SupervisorScheduleTasksTabId
  ): ColumnFiltersProvider => {
    const initialFilterOverride: IColumnFilter[] =
      this.taskInitialFiltersOverrides[tabId]!;
    if (initialFilterOverride) {
      return () => initialFilterOverride;
    }

    const table: IUseTable = this.taskTableRefs[tabId]!;

    // restore column filters
    return (): IColumnFilter[] => (table ? [...table.columnFilters] : []);
  };

  getInitialTabQuery = (
    tabId: SupervisorScheduleTasksTabId
  ): QueryAttributeGroupProvider => {
    const initialQueryOverride: QueryAttributeGroupDto =
      this.taskInitialQueryOverrides[tabId]!;
    if (initialQueryOverride) {
      return () => initialQueryOverride;
    }

    const table: IUseTable = this.taskTableRefs[tabId]!;

    return (): QueryAttributeGroupDto =>
      table?.appliedTableFilter?.query || getBlankQuery();
  };

  createTasksTabSelectionQuery = (
    props: ICreateSupervisorTasksTabSelectionQueryProps
  ): QueryAttributeGroupDto => {
    const tabKeyMap: SupervisorScheduleTaskTabKeyColumnMap =
      this.getTaskTabKeyColumnMap();
    const fieldMappings: {
      sourceField: string;
      targetField: string;
    }[] = tabKeyMap[props.sourceTab]?.[props.targetTab] || [];
    const query: QueryAttributeGroupDto = { $or: [] };

    props.selectedRows.forEach((row) => {
      const rowQuery: QueryAttributeGroupDto = { $and: [] };
      fieldMappings.forEach((mapping) => {
        rowQuery.$and!.push({
          attribute_name: mapping.targetField,
          attribute_value: {
            operator: "$eq",
            value: jsonata(mapping.sourceField).evaluate(row),
          },
        });
      });
      query.$or!.push(rowQuery);
    });

    return query;
  };

  renderMaterialRequirementsDialog = (
    props: IMaterialRequirementsDialogProps
  ): ReactElement => <MaterialRequirementsDialog {...props} />;

  getI18nNamespaces(): string[] {
    return [
      "module.supervisor_schedules",
      "module.supervisor_schedule",
      "module.backlog",
      "shared.gantt",
      "shared.dependency_dialog",
      "shared.planning_plant_selector",
      "shared.user_menu",
      "shared.river_table_selector",
      "shared.river_custom_selector",
      "shared.grid_header",
      "shared.column_selector",
      "shared.advanced_filters",
      "shared.saved_filters_dialog",
      "shared.save_filters_dialog",
      "shared.error_messages",
      "shared.lookup",
      "shared.user_status_dialog",
      "shared.wo_mass_update_dialog",
      "shared.op_mass_update_dialog",
      "shared.completion_percentage",
      "shared.attachment",
      "shared.material_req",
      "shared.work_order_dialog",
      "shared.resource_selector",
      "shared.crew_selector",
      "shared.crafts",
      "shared.river_color_picker",
      "shared.move_work_orders_dialog",
      "shared.download_dialog",
      "shared.river_data_grid",
      "shared.craft_stats",
      "common.button",
      "common.label",
      "entity.folder",
      "entity.workorder",
      "entity.operation",
      "entity.timecard",
      "entity.availability_header",
      "entity.workcenter",
      "entity.folder_ref",
      "entity.baseline",
      "entity.crew_person",
      "entity.crew_utilization",
      "entity.person_utilization",
      "entity.baseline",
      "entity.calendar",
      "entity.crew",
      "shared.river_async_progress",
      "shared.async_actions",
      "shared.schedule_status",
      "shared.schedule_kpis",
      ...this.getErpSpecificI18nNamespaces(),
    ];
  }

  private getDynamicScheduleTableActionLabels = (): Record<
    SupervisorScheduleTasksTabId,
    string
  > => {
    const { t } = useTranslation();
    return {
      [SupervisorScheduleTasksTabId.WORKORDERS]: t(
        "module.supervisor_schedule:action_label.show_workorders_for_selected_records"
      ),
      [SupervisorScheduleTasksTabId.ASSIGNMENTS]: t(
        "module.supervisor_schedule:action_label.show_assignments_for_selected_records"
      ),
      [SupervisorScheduleTasksTabId.OPERATIONS]: t(
        "module.supervisor_schedule:action_label.show_operations_for_selected_records"
      ),
      [SupervisorScheduleTasksTabId.RESOURCES]: t(
        "module.supervisor_schedule:action_label.show_resources_for_selected_records"
      ),
      [SupervisorScheduleTasksTabId.INSTANCES]: t(
        "module.supervisor_schedule:action_label.show_instances_for_selected_records"
      ),
      [SupervisorScheduleTasksTabId.TIMECARDS]: t(
        "module.supervisor_schedule:action_label.show_timecards_for_selected_records"
      ),
    };
  };

  private getTabValues = (
    notIn?: SupervisorScheduleTasksTabId[]
  ): SupervisorScheduleTasksTabId[] => {
    return this.getTabs()()
      .filter((tab) => !notIn?.includes(tab.value))
      .map((tab) => tab.value);
  };

  getDynamicFilterActionsForCurrentTab = (): RiverDropdownActionsProvider => {
    const tabContext = useContext(TabContext);
    const { table } = useContext(TableContext)!;
    const selectedIds: boolean = table!.selectedRowIds?.size > 0;

    return () => {
      const sourceTab = this.getCurrentTab()();
      const tabs = this.getTabValues([sourceTab]);
      const actionLabels = this.getDynamicScheduleTableActionLabels();

      return tabs.map((targetTab) => ({
        title: actionLabels[targetTab],
        startIcon: () => <></>,
        disabled: !selectedIds,
        onClick: () => {
          const sourceTable: IUseTable = this.taskTableRefs[sourceTab]!;
          const selectedRows: IEntityObject[] = sourceTable.getSelectedRows();
          const selectionQuery: QueryAttributeGroupDto =
            this.createTasksTabSelectionQuery({
              sourceTab,
              targetTab,
              selectedRows,
            });
          this.taskInitialQueryOverrides[targetTab] = selectionQuery;
          tabContext?.setSelectedTab(targetTab);
        },
      }));
    };
  };

  //-----------------------
  // ERP-specific
  //-----------------------
  abstract useCurrentTable(
    props: IUseSupervisorScheduleCurrentTableProps
  ): EntityTableProvider;
  abstract getUserStatusResponseEntityName(): string;

  abstract getDefaultWorkOrderColumns(
    props: IGetDefaultSupervisorScheduleWorkOrderColumnsProps
  ): ColumnsProvider;

  abstract getDefaultOperationColumns(): ColumnsProvider;
  abstract getDefaultResourcesColumns(): ColumnsProvider;
  abstract getDefaultInstancesColumns(): ColumnsProvider;

  abstract renderWoMassUpdateDialog(
    props: IWoMassUpdateDialogProps
  ): ReactElement;

  abstract renderOpMassUpdateDialog(
    props: IOpMassUpdateDialogProps
  ): ReactElement;

  abstract renderGridActions(): ReactElementProvider;
  abstract getTabs(): TabsProvider;
  abstract getDefaultTab(): string;
  abstract getErpSpecificI18nNamespaces(): string[];
  abstract isTasksTabAssignable(): () => boolean;
  abstract getTaskTabKeyColumnMap(): SupervisorScheduleTaskTabKeyColumnMap;
  abstract getDefaultTimecardsColumns(): ColumnsProvider;
  abstract getDefaultAssignmentColumns(): ColumnsProvider;
}
