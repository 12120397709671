import React, { ReactElement, useContext, useEffect } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
  SidebarContext,
  SupervisorScheduleContext,
  TabContext,
  TableContext,
} from "../../../context";

import { helpers } from "../../../helpers";

import { RowsChangeData } from "react-data-grid";
import { SupervisorScheduleTasksTabHeader } from "./supervisor-schedule-tasks-tab-header";
import { SupervisorScheduleTasksGridHeader } from "./supervisor-schedule-tasks-grid-header";
import { SupervisorScheduleList } from "../supervisor-schedule-list";
import { SupervisorScheduleGantt } from "../supervisor-schedule-gantt";
import { useLocation, useNavigate } from "react-router";
import {
  SupervisorScheduleTasksTabId,
  SupervisorScheduleTasksTabURLParamName,
  SupervisorScheduleUiService,
} from "../../../services";
import { useTaskColor } from "../../../hooks";
import styles from "./supervisor-schedule-tasks.module.scss";
import clsx from "clsx";

import {
  DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW,
  SupervisorScheduleTasksViewId,
  SupervisorScheduleTasksViewURLParamName,
} from "./supervisor-schedule-gantt-header-options";

interface ISupervisorScheduleTasksProps {
  className?: string;
}

export const SupervisorScheduleTasks: React.FC<
  ISupervisorScheduleTasksProps
> = (props): ReactElement => {
  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const scheduleContext = useContext(SupervisorScheduleContext)!;
  const sidebarContext = useContext(SidebarContext);
  const notify = useNotification();
  const getTaskColor = useTaskColor();

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const currentTab: SupervisorScheduleTasksTabId = uiService.getCurrentTab()();
  const view: SupervisorScheduleTasksViewId =
    (params.get(
      SupervisorScheduleTasksViewURLParamName
    ) as SupervisorScheduleTasksViewId) ||
    DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW;

  const { entity: currentEntity, table: currentTable } =
    uiService.useCurrentTable({ scheduleContext })();
  uiService.taskTableRefs[currentTab] = currentTable;

  const currentEntityName =
    currentEntity?.entityDefinition?.entity.entity_name || "workorder";

  useEffect(() => {
    return () => {
      sidebarContext?.setIsShowingStats(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedTab = (tabId: string): void => {
    navigate(
      helpers.replaceOrSetUrlParam(
        location.pathname,
        params,
        SupervisorScheduleTasksTabURLParamName,
        tabId
      )
    );
  };

  scheduleContext.setCurrentScheduleTasksTabFnRef.current = setSelectedTab;

  if (!currentTable) {
    return <></>;
  }

  if (scheduleContext && scheduleContext.currentTasksTableRef) {
    scheduleContext.currentTasksTableRef.current = currentTable;
  }

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const _id = rows[rowData.indexes[0]]["_id"];
    const newValue = rows[rowData.indexes[0]][rowData.column.key];

    const update = async () => {
      try {
        await adapterContext!.service
          .getAdapterService()
          .updateEntityData("workorder", _id, {
            [rowData.column.key]: newValue,
          });
        currentTable.fetch();
      } catch (message) {
        console.log(message);
        notify.error({ message });
      }
    };

    update();
  };

  const renderView = () => {
    if (
      view === SupervisorScheduleTasksViewId.LIST ||
      currentTab === SupervisorScheduleTasksTabId.TIMECARDS
    ) {
      return (
        <SupervisorScheduleList
          setColumnSize={currentTable.setColumnSize}
          columns={currentTable.columns}
          data={currentTable.entities}
          onRowsChange={onRowsChange}
          sortColumns={currentTable.sortColumns}
          setSortColumns={currentTable.setSortColumns}
          rowKeyGetter={currentTable.rowKeyGetter}
        />
      );
    } else {
      return (
        <SupervisorScheduleGantt
          setColumnSize={currentTable.setColumnSize}
          columns={currentTable.columns}
          data={currentTable.entities}
          onRowsChange={onRowsChange}
          sortColumns={currentTable.sortColumns}
          setSortColumns={currentTable.setSortColumns}
          rowKeyGetter={currentTable.rowKeyGetter}
          getTaskColor={getTaskColor}
        />
      );
    }
  };

  const isLoading: boolean = currentTable.isLoading || !site;

  return (
    <TableContext.Provider
      value={{
        entityName: currentEntityName,
        table: currentTable,
        entity: currentEntity!,
      }}
    >
      <TabContext.Provider
        value={{
          selectedTab: currentTab,
          setSelectedTab,
        }}
      >
        <div className={clsx([styles.root, props.className])}>
          <SupervisorScheduleTasksTabHeader />
          <SupervisorScheduleTasksGridHeader />
          <RiverSpinner show={isLoading} />
          {renderView()}
        </div>
      </TabContext.Provider>
    </TableContext.Provider>
  );
};
