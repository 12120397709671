import React, { ReactElement, useContext } from "react";
import { useNotification } from "@river/common-ui";
import { CraftsUiService, userPreferencesService } from "../../../../services";
import {
  ITableFetchFunctionProps,
  RiverCustomSelector,
  useRiverSelectColumn,
} from "../../../../components/shared";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { Column } from "react-data-grid";
import { fetchHelpers } from "../../../../helpers";
import { useTranslation } from "@river/common-ui";
import { ICraftSelectorProps } from "../../../crafts-ui.service";

export const OracleCloudCraftSelector: React.FC<ICraftSelectorProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const notify = useNotification();

  const saveSelectedCrafts = async (
    selectedCraftIds: Set<string>
  ): Promise<void> => {
    try {
      if (props.isSupervisor) {
        await userPreferencesService.setSupervisorUtilizationCrafts(
          adapterContext!.service.getAdapterService(),
          Array.from(selectedCraftIds)
        );
      } else {
        await userPreferencesService.setUtilizationCrafts(
          adapterContext!.service.getAdapterService(),
          Array.from(selectedCraftIds)
        );
      }

      if (props.onChangeSelectedCrafts) {
        props.onChangeSelectedCrafts(selectedCraftIds);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  const entityName: string = uiService.getCraftEntity();
  const columns: Column<any>[] = uiService.getCraftsSelectorColumns(
    t,
    RiverSelectColumn
  );
  const assignedColumns: Column<any>[] =
    uiService.getAssignedCraftsSelectorColumns(t, RiverSelectColumn);

  const fetchAvailableCrafts = async (fetchProps: ITableFetchFunctionProps) => {
    fetchProps.nin = Array.from(props.selectedIds);
    return await adapterContext!.service.getAdapterService().fetchCrafts(
      fetchHelpers.getTableQuery({
        fetchProps,
        idPropName: "ResourceId",
      })
    );
  };

  const fetchAssignedCrafts = async (fetchProps: ITableFetchFunctionProps) => {
    fetchProps.ids = Array.from(props.selectedIds);
    return await adapterContext!.service.getAdapterService().fetchCrafts(
      fetchHelpers.getTableQuery({
        fetchProps,
        idPropName: "ResourceId",
      })
    );
  };

  const assignCrafts = async (assignedCraftIds: Set<string>): Promise<any> => {
    const selectedCraftIdsArr: string[] = Array.from(props.selectedIds);
    const assignedCraftsIdsArr: string[] = Array.from(assignedCraftIds).map(
      (id) => String(id)
    );
    const newCraftIdsArr: string[] =
      selectedCraftIdsArr.concat(assignedCraftsIdsArr);
    const newCraftIds: Set<string> = new Set(newCraftIdsArr);
    await saveSelectedCrafts(newCraftIds);
  };

  const unassignCrafts = async (
    unassignedCraftIds: Set<string>
  ): Promise<any> => {
    const selectedCraftIdsArr: string[] = Array.from(props.selectedIds);
    const unassignedCraftsIdsArr: string[] = Array.from(unassignedCraftIds).map(
      (id) => String(id)
    );
    const newCraftIdsArr: string[] = selectedCraftIdsArr.filter((id) => {
      return (
        unassignedCraftsIdsArr.findIndex((unassigned) => unassigned === id) ===
        -1
      );
    });
    const newCraftIds: Set<string> = new Set(newCraftIdsArr);
    await saveSelectedCrafts(newCraftIds);
  };

  return (
    <RiverCustomSelector
      isDialog={props.isDialog}
      open={props.open}
      onClose={props.onClose}
      dialogTitle={t("shared.crafts:title")}
      availableEntityName={entityName}
      assignedEntityName={entityName}
      availableTableColumns={columns}
      assignedTableColumns={assignedColumns}
      availableTableRowKeyGetter={uiService.craftKeyGetter}
      assignedTableRowKeyGetter={uiService.craftKeyGetter}
      fetchAvailableObjects={fetchAvailableCrafts}
      fetchAssignedObjects={fetchAssignedCrafts}
      assignAction={assignCrafts}
      unassignAction={unassignCrafts}
      refreshTriggers={[JSON.stringify(Array.from(props.selectedIds))]}
    />
  );
};
