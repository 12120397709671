import { FC, useContext, useState } from "react";
import { RiverDropdownList } from "../river-dropdown-list";
import styles from "./river-help-popup.module.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { RiverVideoDialog } from "../river-video-dialog";
import { IExternalLink } from "@river/interfaces";
import { useNotification, useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  ApplicationContext,
  IAdapterUiContextState,
  RiverModuleContext,
} from "../../../context";
import { ModuleKey } from "../../sidebar-menu";
import dataGridStyles from "../river-data-grid/river-data-grid.module.scss";

interface ISelectedVideo extends IExternalLink {
  videoUrl: string;
}

export const RiverHelpPopup: FC = () => {
  const [helpLinks, setHelpLinks] = useState<IExternalLink[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<ISelectedVideo | null>(
    null
  );

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();
  const moduleContext = useContext(RiverModuleContext);
  const moduleKey: ModuleKey = moduleContext?.moduleKey!;
  const applicationContext = useContext(ApplicationContext);

  const { t } = useTranslation();

  const fetchVideoUrl = async (externalLinkId: string): Promise<string> =>
    await adapterContext?.service
      .getAdapterService()
      .getHelpLinkContentUrl(moduleKey, externalLinkId)!;

  const fetchHelpLinks = async () => {
    try {
      const result = await adapterContext?.service
        .getAdapterService()
        .getModuleHelpLinks(moduleKey);
      if (result) {
        setHelpLinks(result);
      }
    } catch (message) {
      notify.error({ message });
    }
  };

  return (
    <>
      <RiverDropdownList
        toggle={<HelpOutlineIcon color="info" />}
        items={[
          {
            title: t("common.label:application_version", {
              version: applicationContext?.applicationVersion,
            }),
            classes: { root: styles.versionItem },
          },
          ...helpLinks.map((helpLink) => ({
            title: helpLink.description,
            onClick: async () => {
              try {
                const videoUrl: string = await fetchVideoUrl(helpLink._id);
                setSelectedVideo({ ...helpLink, videoUrl });
              } catch (message) {
                notify.error({ message });
              }
            },
          })),
        ]}
        classes={{
          toggle: styles.infoToggle,
          item: dataGridStyles.dataGridLink,
        }}
        onClick={async (popup) => {
          await fetchHelpLinks();
          popup.open();
        }}
      />
      {selectedVideo && (
        <RiverVideoDialog
          title={selectedVideo?.description}
          url={selectedVideo.videoUrl}
          onClose={() => setSelectedVideo(null)}
        />
      )}
    </>
  );
};
