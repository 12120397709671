import React, { ReactElement } from "react";
import {
  GridHeader,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
} from "../../../../components/shared";

export const OracleEbsExecutionMaterialRequirementsGridHeader: React.FC =
  (): ReactElement => (
    <GridHeader>
      <GridActions>
        <ColumnSelectorDropdown />
        <FilterDropdown />
      </GridActions>
    </GridHeader>
  );
