import React, { useState, ReactElement, useContext } from "react";
import { RiverDialog, RiverDialogButton } from "@river/common-ui";
import { GridHeader, RiverTabs } from "../../../shared";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { ScheduleSelector } from "../schedule-selector";
import { useTranslation } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { CraftsUiService } from "../../../../services";
import styles from "./customize-schedules-dialog.module.scss";

interface ICustomizeSchedulesDialog {
  open: boolean;
  onClose: () => void;
  selectedScheduleIds: Set<string>;
  onChangeSelectedScheduleIds: (scheduleIds?: Set<string>) => void;
  selectedCraftIds: Set<string>;
  onChangeSelectedCraftIds: (craftIds: Set<string>) => void;
}

enum CustomizeSchedulesDialogTabId {
  SCHEDULES = "schedules",
  CRAFTS = "crafts",
}
const DEFAULT_CUSTOMIZE_SCHEDULES_DIALOG_TAB_ID: CustomizeSchedulesDialogTabId =
  CustomizeSchedulesDialogTabId.SCHEDULES;

export const CustomizeSchedulesDialog: React.FC<ICustomizeSchedulesDialog> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const [currentTab, setCurrentTab] = useState<CustomizeSchedulesDialogTabId>(
    DEFAULT_CUSTOMIZE_SCHEDULES_DIALOG_TAB_ID
  );

  const handleClose = (): void => {
    setCurrentTab(DEFAULT_CUSTOMIZE_SCHEDULES_DIALOG_TAB_ID);
    props.onClose();
  };

  return (
    <>
      <RiverDialog
        title={t("shared.customize_schedules_dialog:title")}
        open={props.open}
        onClose={handleClose}
        actionsContent={
          <RiverDialogButton
            onClick={handleClose}
            text={t("common.button:close")}
          />
        }
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
        classes={{
          content: styles.content,
          paper: styles.paper,
        }}
      >
        <GridHeader>
          <RiverTabs
            tabs={[
              {
                label: t(
                  "shared.customize_schedules_dialog:tab.schedules.title"
                ),
                icon: <DynamicFeedIcon />,
                value: CustomizeSchedulesDialogTabId.SCHEDULES,
              },
              {
                label: t("shared.customize_schedules_dialog:tab.craft.title"),
                icon: <CenterFocusWeakIcon />,
                value: CustomizeSchedulesDialogTabId.CRAFTS,
              },
            ]}
            value={currentTab}
            onChange={(tab) =>
              setCurrentTab(tab as CustomizeSchedulesDialogTabId)
            }
          />
        </GridHeader>
        <div className={styles.tabContent}>
          {currentTab === CustomizeSchedulesDialogTabId.SCHEDULES && (
            <ScheduleSelector
              isDialog={false}
              selectedIds={props.selectedScheduleIds}
              onChangeSelectedIds={props.onChangeSelectedScheduleIds}
            />
          )}
          {currentTab === CustomizeSchedulesDialogTabId.CRAFTS && (
            <>
              {craftsUiService.renderCraftsSelector({
                isDialog: false,
                selectedIds: props.selectedCraftIds,
                onChangeSelectedCrafts: props.onChangeSelectedCraftIds,
              })}
            </>
          )}
        </div>
      </RiverDialog>
    </>
  );
};
