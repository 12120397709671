import React, { ReactElement } from "react";
import { TableContext } from "../context";
import { IAdapterRule, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { ruleHelpers, colorHelpers, useEntityHelpers } from "../helpers";
import { FormatterProps } from "react-data-grid";
import { IUseTable } from "../components/shared";
import styles from "../components/shared/river-data-grid/river-data-grid.module.scss";
import clsx from "clsx";

export interface IRenderCellContentProps {
  backgroundColor: string;
  fontColor: string;
}

export function useTableCellRenderers() {
  const { evaluateRuleExpression } = ruleHelpers;
  const entityHelpers = useEntityHelpers();

  /**
   * Returns the rule color for an entity object, attribute rules take precedence over row rules
   */
  const getRuleColorForEntity = (props: {
    rules: IAdapterRule[];
    entityObject: IEntityObject;
    attribute: string;
  }): string => {
    const { rules, entityObject, attribute } = props;
    const attributeRule = rules.find(
      (rule: IAdapterRule) => rule.attribute === attribute
    );
    const rowRule = rules.find(
      (rule: IAdapterRule) =>
        rule.rule_type === Constants.rule_type.table_row_color_rule
    );

    let ruleColor: string | null | undefined;

    if (rowRule) {
      ruleColor = evaluateRuleExpression(rowRule, entityObject);
    }

    if (attributeRule) {
      ruleColor =
        evaluateRuleExpression(attributeRule, entityObject) || ruleColor;
    }

    return ruleColor || "";
  };

  const renderCell = (props: {
    riverCellPaddingDisabled?: boolean;
    formatterProps: FormatterProps<any, any>;
    content: ReactElement | ((props: IRenderCellContentProps) => ReactElement);
    tooltip?: string;
    className?: string;
  }): ReactElement => {
    const { formatterProps, content } = props;
    const { row, column } = formatterProps;
    const { key } = column;
    const tooltip: string = props.tooltip ?? row[key];
    const riverCellPaddingDisabled: boolean = !!props?.riverCellPaddingDisabled;
    return (
      <TableContext.Consumer>
        {(tableContext) => {
          const table: IUseTable = tableContext!.table;
          let style: React.CSSProperties = {};
          let backgroundColor: string = "";
          let fontColor: string = "";
          let ruleBackgroundColor = getRuleColorForEntity({
            rules: table.colorRules,
            entityObject: row,
            attribute: key,
          });
          if (ruleBackgroundColor) {
            backgroundColor = ruleBackgroundColor;
            fontColor = colorHelpers.getHighContrastFontColor({
              backgroundColor,
            });
            style = {
              backgroundColor,
              color: fontColor,
            };
          }
          const cellContent: ReactElement =
            typeof content === "function"
              ? content({ backgroundColor, fontColor })
              : content;
          return (
            <div
              className={clsx([
                styles.riverCell,
                {
                  [styles.riverCellPadding]: !riverCellPaddingDisabled,
                },
                props.className,
              ])}
              title={tooltip}
              style={style}
            >
              {cellContent}
            </div>
          );
        }}
      </TableContext.Consumer>
    );
  };

  const renderCellText = (props: {
    formatterProps?: FormatterProps<any, any>;
    value?: any;
  }): ReactElement => {
    const { formatterProps } = props;
    const value = props.value ?? formatterProps?.row[formatterProps.column.key];
    return (
      <div
        className={styles.riverCellText}
        ref={(el) => {
          // If user double-clicked on text cell we need to select cell text
          // @ts-ignore
          if (!!el && !!formatterProps?.isEditor) {
            const selection = window.getSelection()!;
            const range = document.createRange();
            range.selectNodeContents(el!);
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }}
      >
        {value}
      </div>
    );
  };

  const renderTextCell = (props: {
    formatterProps: FormatterProps<any, any>;
    value?: any;
  }): ReactElement => {
    return (
      <TableContext.Consumer>
        {(tableContext) => {
          const { formatterProps } = props;
          const { row, column } = formatterProps;
          const { key } = column;
          const value: string =
            props.value ??
            entityHelpers.getFormattedEntityAttribute(row, key, tableContext!);
          const content: ReactElement = renderCellText({
            value,
            formatterProps,
          });
          return (
            <>
              {renderCell({
                formatterProps,
                content,
                tooltip: value,
                className: styles.riverTextCell,
              })}
            </>
          );
        }}
      </TableContext.Consumer>
    );
  };

  return {
    renderCell,
    renderCellText,
    renderTextCell,
  };
}

export type IUseTableCell = ReturnType<typeof useTableCellRenderers>;
