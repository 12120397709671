import React, { useEffect, useState, createRef, useContext } from "react";
import SplitterLayout from "react-splitter-layout";
import { Protected } from "../protected";
import {
  useNotification,
  authService,
  useEnv,
  IUseEnv,
  ISidebarCollapsedSetterOpts,
} from "@river/common-ui";
import { RiverDrawer, useRiverDrawer, IUseRiverDrawer } from "./river-drawer";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IKPIParamState,
  IStatsState,
  SidebarContext,
} from "../../context";
import { Sidebar } from "../sidebar";
import { Content } from "../content/content";
import { userPreferencesService } from "../../services";
import "react-splitter-layout/lib/index.css";
import { IMenuItem, ModuleKey } from "../sidebar-menu";
import { Route, Routes } from "react-router-dom";
import { RiverDrawerContext } from "../../context";
import styles from "./main.module.scss";
import clsx from "clsx";

export const Main: React.FC = () => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();

  const SIDEBAR_INIT_SIZE = isMobile ? window.outerWidth : 280;
  const SIDEBAR_MIN_SIZE = isMobile ? window.outerWidth : 280;
  const MIN_KPI_CONTAINER_WIDTH = 320;

  const [isCommandClicked, setIsCommandClicked] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [sidebarTransitionActive, setSidebarTransitionActive] =
    useState<boolean>(true);
  const [isShowingStats, setIsShowingStats] = useState(false);
  const [menuItemsByModuleKey, setMenuItemsByModuleKey] =
    useState<Map<ModuleKey, IMenuItem>>();
  const splitterLayoutRef = createRef<HTMLDivElement>();

  const [stats, setStats] = useState<IStatsState>({ charts: [] });
  const [kpiParams, setKPIParams] = useState<IKPIParamState>({
    startDate: new Date(Date.now()),
    personNumber: null,
    personName: null,
    workCenter: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const drawer: IUseRiverDrawer = useRiverDrawer();
  const { drawerOpened, drawerWidth } = drawer;

  const onSecondaryPaneSizeChange = async (value: number) => {
    if (value < SIDEBAR_MIN_SIZE) {
      if (!collapsed) {
        try {
          setCollapsed(true);
          await userPreferencesService.setSidebarCollapsedState(
            adapterContext!.service.getAdapterService(),
            true
          );
        } catch (message) {
          notify.error({ message });
        }
      }
    }
  };

  const loadSidebarState = async (): Promise<void> => {
    if (isMobile) return;
    try {
      const state: boolean =
        await userPreferencesService.getSidebarCollapsedState(
          adapterContext!.service.getAdapterService()
        );
      setCollapsed(state);
    } catch (message) {
      notify.error({ message });
    }
  };

  useEffect(() => {
    if (authService.isLoggedIn()) {
      loadSidebarState().then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    document.ondragover = (e) => {
      e.preventDefault();
    };

    document.ondrop = (e) => {
      if (!e.dataTransfer) return;
    };

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (ev: KeyboardEvent) => {
    setIsCommandClicked(ev.metaKey);
  };

  const onKeyUp = (ev: KeyboardEvent) => {
    setIsCommandClicked(ev.metaKey);
  };

  useEffect(() => {
    const sidebarPaneEl: HTMLElement =
      splitterLayoutRef.current?.querySelectorAll(
        ".layout-pane"
      )[0] as HTMLElement;
    if (sidebarPaneEl) {
      let newSidebarWidth: number;
      if (isShowingStats) {
        newSidebarWidth = Math.max(
          MIN_KPI_CONTAINER_WIDTH,
          sidebarPaneEl.offsetWidth
        );
      } else {
        newSidebarWidth = SIDEBAR_INIT_SIZE;
      }
      sidebarPaneEl.style.width = `${newSidebarWidth}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingStats]);

  const onSetCollapsed = async (opts: ISidebarCollapsedSetterOpts) => {
    setCollapsed(opts.collapsed);
    if (!isMobile) {
      if (!opts.skipSave) {
        try {
          await userPreferencesService.setSidebarCollapsedState(
            adapterContext!.service.getAdapterService(),
            opts.collapsed
          );
        } catch (message) {
          notify.error({ message });
        }
      }
    }
  };

  return (
    <>
      {!isLoading && (
        <SidebarContext.Provider
          value={{
            collapsed: collapsed,
            setCollapsed: onSetCollapsed,
            isCommandClicked: isCommandClicked,
            isShowingStats,
            setIsShowingStats,
            stats,
            setStats,
            kpiParams,
            setKPIParams,
            menuItemsByModuleKey,
            setMenuItemsByModuleKey,
          }}
        >
          <RiverDrawerContext.Provider value={{ drawer }}>
            <div className={styles.root}>
              <div
                className={styles.main}
                style={{
                  marginRight: drawerOpened ? 0 : `-${drawerWidth}px`,
                }}
                ref={splitterLayoutRef}
              >
                <SplitterLayout
                  customClassName={clsx([
                    styles.splitterLayout,
                    {
                      [styles.collapsed]: collapsed,
                      [styles.sidebarTransitionActive]: sidebarTransitionActive,
                      [styles.mobile]: isMobile,
                    },
                  ])}
                  percentage={false}
                  primaryIndex={1}
                  secondaryInitialSize={SIDEBAR_INIT_SIZE}
                  secondaryMinSize={SIDEBAR_MIN_SIZE}
                  onSecondaryPaneSizeChange={onSecondaryPaneSizeChange}
                  onDragStart={() => {
                    setSidebarTransitionActive(false);
                  }}
                  onDragEnd={() => {
                    setSidebarTransitionActive(true);
                  }}
                >
                  <div className={styles.sidebarWrapper}>
                    <Sidebar />
                  </div>
                  <div className={styles.contentWrapper}>
                    <Routes>
                      <Route
                        path="/*"
                        element={
                          <Protected>
                            <Content />
                          </Protected>
                        }
                      />
                    </Routes>
                  </div>
                </SplitterLayout>
              </div>
              <RiverDrawer />
            </div>
          </RiverDrawerContext.Provider>
        </SidebarContext.Provider>
      )}
    </>
  );
};
