import React, { ReactElement, useContext } from "react";
import { FormatterProps } from "react-data-grid";
import { SupervisorScheduleContext } from "../../../../context";
import { SupervisorGanttTask } from "./supervisor-gantt-task";
import { SupervisorGanttBaseline } from "./supervisor-gantt-baseline";
import { SupervisorGanttFormatterPeriod } from "./supervisor-gantt-formatter-period";
import { useSupervisorGanttRenderChecks } from "../use-supervisor-gantt-render-checks";
import { SUPERVISOR_SCHEDULE_TASKS_GANTT_CONTAINER_ID } from "../supervisor-schedule-gantt";
import { IUseSupervisorScheduleGantt } from "../use-supervisor-schedule-gantt";
import { IAdapterFolder } from "@river/interfaces";
import { useTranslation } from "@river/common-ui";
import styles from "./supervisor-schedule-gant-formatter.module.scss";
import clsx from "clsx";

export interface ISupervisorScheduleGanttFormatterProps
  extends FormatterProps<any> {
  getTaskColor: (row: any) => string;
}

export const SupervisorScheduleGanttFormatter: React.FC<
  ISupervisorScheduleGanttFormatterProps
> = (props) => {
  const { t } = useTranslation();
  const { row, getTaskColor } = props;
  const scheduleContext = useContext(SupervisorScheduleContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;

  const gantt: IUseSupervisorScheduleGantt = scheduleContext?.ganttRef.current!;
  const { shouldRender } = useSupervisorGanttRenderChecks({
    gantt,
    containerId: SUPERVISOR_SCHEDULE_TASKS_GANTT_CONTAINER_ID,
  });
  const { periods, ganttStart, ganttEnd, getXPercentFromDate } = gantt;

  const renderPeriods = (): ReactElement => {
    return (
      <div className={styles.periods}>
        {periods.map((period) => (
          <SupervisorGanttFormatterPeriod period={period} key={period.index} />
        ))}
      </div>
    );
  };

  const renderCurrentTimeMarker = (): ReactElement => {
    const today: Date = new Date();
    const time: number = today.getTime();
    let ganttContainsToday: boolean = false;
    let todayXPercent: number;
    if (time >= ganttStart.getTime() && time <= ganttEnd.getTime()) {
      ganttContainsToday = true;
      todayXPercent = getXPercentFromDate(today);
    }
    return (
      <>
        {ganttContainsToday && (
          <div
            title={t("shared.gantt:label.current_time")}
            className={clsx([styles.timeMarker, styles.currentTime])}
            style={{
              left: `${todayXPercent!}%`,
            }}
          />
        )}
      </>
    );
  };

  const renderScheduleStartTimeMarker = (): ReactElement => {
    const scheduleStart: Date = new Date(currentSchedule.start_date);
    const scheduleStartTime: number = scheduleStart.getTime();
    let ganttContainsScheduleStart: boolean = false;
    let scheduleStartXPercent: number;
    if (
      scheduleStartTime >= ganttStart.getTime() &&
      scheduleStartTime <= ganttEnd.getTime()
    ) {
      ganttContainsScheduleStart = true;
      scheduleStartXPercent = getXPercentFromDate(scheduleStart);
    }
    return (
      <>
        {ganttContainsScheduleStart && (
          <div
            title={t("shared.gantt:label.schedule_start_time")}
            className={clsx([styles.timeMarker, styles.scheduleStart])}
            style={{
              left: `${scheduleStartXPercent!}%`,
            }}
          />
        )}
      </>
    );
  };

  const renderScheduleEndTimeMarker = (): ReactElement => {
    const scheduleEnd: Date = new Date(currentSchedule.end_date);
    const scheduleEndTime: number = scheduleEnd.getTime();
    let ganttContainsScheduleEnd: boolean = false;
    let scheduleEndXPercent: number;
    if (
      scheduleEndTime >= ganttStart.getTime() &&
      scheduleEndTime <= ganttEnd.getTime()
    ) {
      ganttContainsScheduleEnd = true;
      scheduleEndXPercent = getXPercentFromDate(scheduleEnd);
    }
    return (
      <>
        {ganttContainsScheduleEnd && (
          <div
            title={t("shared.gantt:label.schedule_end_time")}
            className={clsx([styles.timeMarker, styles.scheduleEnd])}
            style={{
              left: `${scheduleEndXPercent!}%`,
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      {shouldRender && (
        <div className={styles.root}>
          {renderPeriods()}
          {renderCurrentTimeMarker()}
          {renderScheduleStartTimeMarker()}
          {renderScheduleEndTimeMarker()}
          <SupervisorGanttTask row={row} getTaskColor={getTaskColor} />
          <SupervisorGanttBaseline row={row} />
        </div>
      )}
    </>
  );
};
