import React, { ReactElement, useContext, useEffect } from "react";
import { RiverDialog, RiverSpinner, useEnv, IUseEnv } from "@river/common-ui";
import {
  IEntityObject,
  QueryAttributeDto,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import {
  IAdapterUserContext,
  AdapterUserContext,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../../context";
import {
  RiverDataGrid,
  useTable,
  IUseTable,
  IUseTableProps,
} from "../../../shared";
import { LookupGridHeader } from "./lookup-grid-header";
import { IUseEntity, UseEntityProps, useEntity } from "../../../../hooks";
import {
  IUsePersonLookupDef,
  usePersonLookupDef,
  IUseActivityTypesLookupDef,
  useActivityTypesLookupDef,
  useBaselineLookupDef,
  IUseBaselineLookupDef,
  IUseCalendarLookupDef,
  useCalendarLookupDef,
  IUseEquipmentLookupDef,
  useEquipmentLookupDef,
  IUseLanguageLookupDef,
  useLanguageLookupDef,
  IUseOperationsLookupDef,
  useOperationsLookupDef,
  IUsePlannerGroupsLookupDef,
  usePlannerGroupsLookupDef,
  IUsePriorityLookupDef,
  usePriorityLookupDef,
  IUseRevisionsLookupDef,
  useRevisionsLookupDef,
  IUseSchedulesLookupDef,
  useSchedulesLookupDef,
  IUseSystemConditionsLookupDef,
  useSystemConditionsLookupDef,
  IUseCraftsLookupDef,
  useCraftsLookupDef,
  IUseWbsElementLookupDef,
  useWbsElementLookupDef,
  useEntityLookupDef,
  IUsEntitiesLookupDef,
  IUseSupervisorsLookupDef,
  useSupervisorsLookupDef,
  IUseApplicationLookupDef,
  useApplicationLookupDef,
  useResponsibilityLookupDef,
  IUseResponsibilityLookupDef,
} from "./defs";
import {
  useOracleCloudWoStatusLookupDef,
  IUseOracleCloudWoStatusLookupDef,
  useOracleCloudWoTypeLookupDef,
  IUseOracleCloudWoTypeLookupDef,
  useOracleCloudWoSubtypeLookupDef,
  IUseOracleCloudWoSubtypeLookupDef,
} from "./defs/oracle-cloud-defs";
import {
  IUseJdeAddressBookLookupDef,
  useJdeAddressBookLookupDef,
  IUseJdeUserDefinedCodeLookupDef,
  useJdeUserDefinedCodeLookupDef,
} from "./defs/jde-defs";
import { uiConstants } from "../../../../helpers";
import { useParams } from "react-router-dom";
import { Column } from "react-data-grid";
import { useTranslation } from "@river/common-ui";
import {
  IUseOracleEbsPriorityLookupDef,
  IUseOracleEbsPlannerMaintenanceTypeLookupDef,
  IUseOracleEbsWoTypeLookupDef,
  IUseOracleEbsWoShutdownTypeLookupDef,
  IUseOracleEbsWoStatusLookupDef,
  IUseOracleEbsSubinventoryLookupDef,
  IUseOracleEbsLocatorLookupDef,
  IUseOracleEbsReconciliationLookupDef,
  IUseOracleEbsFailureCodeLookupDef,
  IUseOracleEbsCauseCodeLookupDef,
  IUseOracleEbsResolutionCodeLookupDef,
  IUseOracleEbsCompletionLotLookupDef,
  useOracleEbsPlannerMaintenanceTypeLookupDef,
  useOracleEbsPriorityLookupDef,
  useOracleEbsWoTypeLookupDef,
  useOracleEbsWoShutdownTypeLookupDef,
  useOracleEbsWoStatusLookupDef,
  useOracleEbsSubinventoryLookupDef,
  useOracleEbsReconciliationLookupDef,
  useOracleEbsFailureCodeLookupDef,
  useOracleEbsCauseCodeLookupDef,
  useOracleEbsResolutionCodeLookupDef,
  useOracleEbsLocatorLookupDef,
  useOracleEbsCompletionLotLookupDef,
} from "./defs/oracle-ebs-defs";
import {
  IUseOracleEbsDepartmentLookupDef,
  useOracleEbsDepartmentLookupDef,
} from "./defs/oracle-ebs-defs/oracle-ebs-department-lookup-def";
import styles from "./lookup-dialog.module.scss";
import clsx from "clsx";

const DEFAULT_ID_PROP_NAME = uiConstants.fields._id;
const DEFAULT_SINGLE_SELECT: boolean = false;

export interface ILookupDefinition {
  title: string;
  selectButtonText?: string;
  rowKey?: string;
  rowKeyGetter?: (row: IEntityObject) => string;
  useTableProps: Partial<IUseTableProps> &
    Pick<IUseTableProps, "columns" | "fetchFunction">;
  useEntityProps: Partial<UseEntityProps> & Pick<UseEntityProps, "entityName">;
}

export enum LookupType {
  EQUIPMENT = "equipment",
  CRAFTS = "crafts",
  PLANNER_GROUPS = "planner_groups",
  REVISIONS = "revisions",
  PRIORITY = "priority",
  SYSTEM_CONDITIONS = "system_conditions",
  PERSONS = "person",
  ACTIVITY_TYPES = "activity_types",
  CALENDAR = "calendar",
  OPERATIONS = "operations",
  SCHEDULES = "schedules",
  BASELINES = "baselines",
  LANGUAGES = "languages",
  WBS_ELEMENTS = "wbs",
  SUPERVISORS = "supervisors",
  APPLICATION = "application",
  RESPONSIBILITY = "responsibility",
  ENTITIES = "entities",
  ORACLE_CLOUD_WO_STATUS = "oracle_cloud_wo_status",
  ORACLE_CLOUD_WO_TYPE = "oracle_cloud_wo_type",
  ORACLE_CLOUD_WO_SUBTYPE = "oracle_cloud_wo_subtype",
  ORACLE_EBS_PRIORITY = "oracle_ebs_priority",
  ORACLE_EBS_WO_STATUS = "oracle_ebs_wo_status",
  ORACLE_EBS_WO_TYPE = "oracle_ebs_wo_type",
  ORACLE_EBS_WO_SHUTDOWN_TYPE = "oracle_ebs_wo_shutdown_type",
  ORACLE_EBS_PLANNER_MAINTENANCE = "oracle_ebs_planner_maintenance",
  ORACLE_EBS_DEPARTMENT = "oracle_ebs_department",
  ORACLE_EBS_SUBINVENTORY = "oracle_ebs_subinventory",
  ORACLE_EBS_RECONCILIATION = "oracle_ebs_reconciliation",
  ORACLE_EBS_FAILURE_CODE = "oracle_ebs_failure_code",
  ORACLE_EBS_CAUSE_CODE = "oracle_ebs_cause_code",
  ORACLE_EBS_RESOLUTION_CODE = "oracle_ebs_resolution_code",
  ORACLE_EBS_COMPLETION_LOCATOR = "oracle_ebs_completion_locator",
  ORACLE_EBS_COMPLETION_LOT = "oracle_ebs_completion_lot",
  JDE_ADDRESS_BOOK = "jde_address_book",
  JDE_USER_DEFINED_CODE = "jde_user_defined_code",
}

export interface ILookupProps {
  type: LookupType;
  title?: string;
  selectButtonText?: string;
  columns?: Column<any>[];
  selectedRowIds?: string[];
  customFilters?: QueryAttributeDto[];
  customQuery?: QueryAttributeGroupDto;
  entityName?: string;
}

interface ILookupDialog {
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedObjects: IEntityObject[]) => void;
  lookup: ILookupProps;
  singleSelect?: boolean;
}

export const LookupDialog: React.FC<ILookupDialog> = (props): ReactElement => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const singleSelect: boolean = props.singleSelect ?? DEFAULT_SINGLE_SELECT;
  const DEFAULT_SELECT_BUTTON_TEXT = t("common.button:select");
  const personLookupDef: IUsePersonLookupDef = usePersonLookupDef();

  const activityTypesLookupDef: IUseActivityTypesLookupDef =
    useActivityTypesLookupDef();

  const baselineLookupDef: IUseBaselineLookupDef = useBaselineLookupDef();

  const calendarLookupDef: IUseCalendarLookupDef = useCalendarLookupDef();

  const jdeAddressBookLookupDef: IUseJdeAddressBookLookupDef =
    useJdeAddressBookLookupDef();

  const jdeUserDefinedCodeLookupDef: IUseJdeUserDefinedCodeLookupDef =
    useJdeUserDefinedCodeLookupDef();

  const equipmentLookupDef: IUseEquipmentLookupDef = useEquipmentLookupDef();

  const languageLookupDef: IUseLanguageLookupDef = useLanguageLookupDef();

  const operationsLookupDef: IUseOperationsLookupDef = useOperationsLookupDef();

  const plannerGroupsLookupDef: IUsePlannerGroupsLookupDef =
    usePlannerGroupsLookupDef();

  const priorityLookupDef: IUsePriorityLookupDef = usePriorityLookupDef();

  const revisionsLookupDef: IUseRevisionsLookupDef = useRevisionsLookupDef();

  const schedulesLookupDef: IUseSchedulesLookupDef = useSchedulesLookupDef();

  const systemConditionsLookupDef: IUseSystemConditionsLookupDef =
    useSystemConditionsLookupDef();

  const craftsLookupDef: IUseCraftsLookupDef = useCraftsLookupDef();

  const wbsElementsLookupDef: IUseWbsElementLookupDef =
    useWbsElementLookupDef();

  const supervisorsLookupDef: IUseSupervisorsLookupDef =
    useSupervisorsLookupDef();

  const applicationLookupDef: IUseApplicationLookupDef =
    useApplicationLookupDef();

  const responsibilityLookupDef: IUseResponsibilityLookupDef =
    useResponsibilityLookupDef();

  const entitiesLookupDef: IUsEntitiesLookupDef = useEntityLookupDef();

  const oracleCloudWoStatusLookupDef: IUseOracleCloudWoStatusLookupDef =
    useOracleCloudWoStatusLookupDef();

  const oracleCloudWoTypeLookupDef: IUseOracleCloudWoTypeLookupDef =
    useOracleCloudWoTypeLookupDef();

  const oracleCloudWoSubtypeLookupDef: IUseOracleCloudWoSubtypeLookupDef =
    useOracleCloudWoSubtypeLookupDef();

  const oracleEbsPriorityLookupDef: IUseOracleEbsPriorityLookupDef =
    useOracleEbsPriorityLookupDef();

  const oracleEbsWoStatusLookupDef: IUseOracleEbsWoStatusLookupDef =
    useOracleEbsWoStatusLookupDef();

  const oracleEbsWoTypeLookupDef: IUseOracleEbsWoTypeLookupDef =
    useOracleEbsWoTypeLookupDef();

  const oracleEbsWoShutdownTypeLookupDef: IUseOracleEbsWoShutdownTypeLookupDef =
    useOracleEbsWoShutdownTypeLookupDef();

  const oracleEbsPlannerMaintenanceLookupDef: IUseOracleEbsPlannerMaintenanceTypeLookupDef =
    useOracleEbsPlannerMaintenanceTypeLookupDef();

  const oracleEbsDepartmentLookupDef: IUseOracleEbsDepartmentLookupDef =
    useOracleEbsDepartmentLookupDef();

  const oracleEbsSubinventoryLookupDef: IUseOracleEbsSubinventoryLookupDef =
    useOracleEbsSubinventoryLookupDef();

  const oracleEbsLocatorLookupDef: IUseOracleEbsLocatorLookupDef =
    useOracleEbsLocatorLookupDef();

  const oracleEbsCompletionLotLookupDef: IUseOracleEbsCompletionLotLookupDef =
    useOracleEbsCompletionLotLookupDef();

  const oracleEbsReconciliationLookupDef: IUseOracleEbsReconciliationLookupDef =
    useOracleEbsReconciliationLookupDef();

  const oracleEbsFailureCodeLookupDef: IUseOracleEbsFailureCodeLookupDef =
    useOracleEbsFailureCodeLookupDef();

  const oracleEbsCauseCodeLookupDef: IUseOracleEbsCauseCodeLookupDef =
    useOracleEbsCauseCodeLookupDef();

  const oracleEbsResolutionCodeLookupDef: IUseOracleEbsResolutionCodeLookupDef =
    useOracleEbsResolutionCodeLookupDef();

  let { schedule_id } = useParams<{ schedule_id: string }>();

  const userContext: IAdapterUserContext | null =
    React.useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const LOOKUP_DEFS: { [type in LookupType]: ILookupDefinition } = {
    [LookupType.EQUIPMENT]: equipmentLookupDef(userContext!, adapterContext!),
    [LookupType.CRAFTS]: craftsLookupDef({
      userContext: userContext!,
      adapterContext: adapterContext!,
      customFilters: props.lookup.customFilters,
      customQuery: props.lookup.customQuery,
    }),
    [LookupType.PLANNER_GROUPS]: plannerGroupsLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.REVISIONS]: revisionsLookupDef(userContext!, adapterContext!),
    [LookupType.PRIORITY]: priorityLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.entityName
    ),
    [LookupType.SYSTEM_CONDITIONS]: systemConditionsLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.PERSONS]: personLookupDef(props.lookup.customFilters),
    [LookupType.ACTIVITY_TYPES]: activityTypesLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.CALENDAR]: calendarLookupDef(userContext!, adapterContext!),
    [LookupType.JDE_ADDRESS_BOOK]: jdeAddressBookLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.JDE_USER_DEFINED_CODE]: jdeUserDefinedCodeLookupDef(
      adapterContext!,
      props.lookup.customFilters,
      props.lookup.customQuery
    ),
    [LookupType.OPERATIONS]: operationsLookupDef(userContext!, adapterContext!),
    [LookupType.SCHEDULES]: schedulesLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.BASELINES]: baselineLookupDef(
      userContext!,
      adapterContext!,
      schedule_id!
    ),
    [LookupType.LANGUAGES]: languageLookupDef(userContext!, adapterContext!),
    [LookupType.WBS_ELEMENTS]: wbsElementsLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.SUPERVISORS]: supervisorsLookupDef(
      userContext!,
      adapterContext!
    ),

    [LookupType.APPLICATION]: applicationLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.RESPONSIBILITY]: responsibilityLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ENTITIES]: entitiesLookupDef(adapterContext!),
    [LookupType.ORACLE_CLOUD_WO_STATUS]: oracleCloudWoStatusLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.ORACLE_CLOUD_WO_TYPE]: oracleCloudWoTypeLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.ORACLE_CLOUD_WO_SUBTYPE]: oracleCloudWoSubtypeLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.ORACLE_EBS_PRIORITY]: oracleEbsPriorityLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.ORACLE_EBS_WO_STATUS]: oracleEbsWoStatusLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_WO_TYPE]: oracleEbsWoTypeLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_WO_SHUTDOWN_TYPE]: oracleEbsWoShutdownTypeLookupDef(
      userContext!,
      adapterContext!
    ),
    [LookupType.ORACLE_EBS_PLANNER_MAINTENANCE]:
      oracleEbsPlannerMaintenanceLookupDef(userContext!, adapterContext!),
    [LookupType.ORACLE_EBS_DEPARTMENT]: oracleEbsDepartmentLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_SUBINVENTORY]: oracleEbsSubinventoryLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_COMPLETION_LOCATOR]: oracleEbsLocatorLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_COMPLETION_LOT]: oracleEbsCompletionLotLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_RECONCILIATION]: oracleEbsReconciliationLookupDef(
      userContext!,
      adapterContext!,
      props.lookup.customFilters
    ),
    [LookupType.ORACLE_EBS_FAILURE_CODE]: oracleEbsFailureCodeLookupDef(
      adapterContext!,
      props.lookup.customFilters
    ),

    [LookupType.ORACLE_EBS_CAUSE_CODE]: oracleEbsCauseCodeLookupDef(
      adapterContext!,
      props.lookup.customFilters
    ),

    [LookupType.ORACLE_EBS_RESOLUTION_CODE]: oracleEbsResolutionCodeLookupDef(
      adapterContext!,
      props.lookup.customFilters
    ),
  };

  const processOverrides = (
    lookupDef: ILookupDefinition
  ): ILookupDefinition => {
    const def: ILookupDefinition = { ...lookupDef };
    const lookup: ILookupProps = props.lookup;
    if (typeof lookup.title !== "undefined") {
      def.title = lookup.title!;
    }
    if (typeof lookup.selectButtonText !== "undefined") {
      def.selectButtonText = lookup.selectButtonText!;
    }
    if (typeof lookup.columns !== "undefined") {
      def.useTableProps.columns = lookup.columns!;
    }
    return def;
  };

  const lookupDef: ILookupDefinition = processOverrides(
    LOOKUP_DEFS[props.lookup.type]
  );

  const getRowKey = (row: IEntityObject): string => {
    let rowKey: string;
    if (lookupDef.rowKey) {
      rowKey = String(row[lookupDef.rowKey]);
    } else if (lookupDef.rowKeyGetter) {
      rowKey = lookupDef.rowKeyGetter(row);
    } else {
      rowKey = String(row[DEFAULT_ID_PROP_NAME]);
    }
    return rowKey;
  };

  const entity: IUseEntity = useEntity({ ...lookupDef.useEntityProps });
  const table: IUseTable = useTable({
    fetchOn: props.open,
    ...lookupDef.useTableProps,
    infiniteScrolling: true,
    rowKeyGetter: getRowKey,
  });

  const submit = (): void => {
    const selectedObjects: IEntityObject[] = table.getSelectedRows();
    props.onSubmit(selectedObjects);
  };

  useEffect(() => {
    props.lookup.selectedRowIds &&
      table.setSelectedRowIds(() => new Set(props.lookup.selectedRowIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lookup?.selectedRowIds]);

  const isLoading: boolean = table.isLoading;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <RiverDialog
        title={lookupDef.title}
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        actionButtonText={
          lookupDef.selectButtonText || DEFAULT_SELECT_BUTTON_TEXT
        }
        onSubmit={submit}
        classes={{
          paper: clsx([styles.paper, { [styles.mobile]: isMobile }]),
          content: styles.content,
          title: styles.dialogTitle,
          actions: styles.actions,
        }}
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
      >
        <RiverSpinner show={isLoading} />
        <LookupGridHeader />
        <RiverDataGrid
          disableSelectAll={true}
          singleSelect={singleSelect}
          className={styles.grid}
          columns={table.columns}
          rows={table.entities}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowKeyGetter={table.rowKeyGetter}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
        />
      </RiverDialog>
    </TableContext.Provider>
  );
};
