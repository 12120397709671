import React, { ReactElement, useEffect, useState } from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { PopoverOrigin } from "@mui/material/Popover/Popover";
import { Box, Slide } from "@mui/material";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import styles from "./river-list-popup.module.scss";
import clsx from "clsx";

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 0,
  horizontal: 0,
};

type SubmenuSlideDirection = "left" | "right";
const DEFAULT_SUBMENU_SLIDE_DIRECTION: SubmenuSlideDirection = "left";
const DEFAULT_OPEN_ON_HOVER = false;

export type Icon = React.ComponentType<any>;

export interface IRiverListPopupItemSubMenu {
  slideDirection?: SubmenuSlideDirection;
  openOnHover?: boolean;
  items: IRiverListPopupItem[];
  classes?: {
    container?: string;
    slider?: string;
  };
}

export interface IRiverListPopupItem {
  title: string;
  startIcon?: Icon;
  endIcon?: Icon;
  onClick?: () => void;
  disabled?: boolean;
  isSeparator?: boolean;
  submenu?: IRiverListPopupItemSubMenu;
  classes?: {
    root?: string;
    title?: string;
  };
}

export type AnchorEl = null | Element | (() => Element);

interface IRiverListPopup {
  title?: string;
  items: IRiverListPopupItem[];
  anchorEl: AnchorEl;
  onClose: () => void;
  onItemClickPreventClose?: boolean;
  popover?: Partial<PopoverProps>;
  classes?: {
    root?: string;
    item?: string;
    separator?: string;
    startIconClass?: string;
    endIconClass?: string;
  };
}

export const POPUP_SEPARATOR: IRiverListPopupItem = {
  title: "",
  onClick: () => {},
  isSeparator: true,
};

export const RiverListPopup: React.FC<IRiverListPopup> = (
  props
): ReactElement => {
  const [submenuOpened, setSubmenuOpened] = useState<boolean>(false);
  const [submenuTimeout, setSubmenuTimeout] = useState<null | NodeJS.Timeout>(
    null
  );

  function closeSubmenuWithTimeout() {
    return setTimeout(() => {
      setSubmenuOpened(false);
    }, 300);
  }

  function clearSubmenuTimeout() {
    if (submenuTimeout) {
      clearTimeout(submenuTimeout);
      setSubmenuTimeout(null);
    }
  }

  function openSubmenu() {
    clearSubmenuTimeout();
    setSubmenuOpened(true);
  }

  useEffect(() => {
    return () => {
      setSubmenuOpened(false);
      clearSubmenuTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ItemSeparator = (): ReactElement => (
    <div
      className={clsx([
        styles.item,
        styles.isSeparator,
        props.classes?.item,
        props.classes?.separator,
      ])}
    />
  );

  const renderItemContent = (item: IRiverListPopupItem): ReactElement => {
    const ItemStartIcon: Icon = item.startIcon!;
    const ItemEndIcon: Icon = item.endIcon!;
    return (
      <>
        <div className={styles.leftSection}>
          {ItemStartIcon && (
            <ItemStartIcon
              className={clsx([
                headerStyles.actionIcon,
                styles.itemIcon,
                styles.startIcon,
                props.classes?.startIconClass,
              ])}
            />
          )}
          <span className={clsx([styles.itemTitle, item.classes?.title])}>
            {item.title}
          </span>
        </div>
        {ItemEndIcon && (
          <ItemEndIcon
            className={clsx([
              headerStyles.actionIcon,
              styles.itemIcon,
              styles.endIcon,
              props.classes?.endIconClass,
            ])}
          />
        )}
      </>
    );
  };

  const renderItem = (
    item: IRiverListPopupItem,
    index: number
  ): ReactElement => (
    <div
      key={index}
      className={clsx([
        styles.item,
        { [styles.disabled]: item.disabled },
        !item.classes?.root && props.classes?.item,
        item.classes?.root,
      ])}
      onClick={(e) => {
        if (!item.disabled) {
          item.onClick?.();
          closeSubmenuWithTimeout();
        } else {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        if (!props.onItemClickPreventClose) {
          props.onClose();
        }
      }}
    >
      {renderItemContent(item)}
    </div>
  );

  const renderItemWithSubmenu = (
    item: IRiverListPopupItem,
    index: number
  ): ReactElement => {
    const itemProps: Partial<React.HTMLAttributes<HTMLDivElement>> = {
      onMouseLeave: () => {
        setSubmenuTimeout(closeSubmenuWithTimeout());
      },
    };
    const openOnHover: boolean =
      item.submenu?.openOnHover ?? DEFAULT_OPEN_ON_HOVER;
    if (openOnHover) {
      Object.assign(itemProps, {
        onMouseEnter: openSubmenu,
      });
    } else {
      Object.assign(itemProps, {
        onClick: openSubmenu,
      });
    }

    return (
      <div
        key={index}
        className={clsx([
          styles.item,
          styles.submenu,
          { [styles.disabled]: item.disabled },
          props.classes?.item,
          item.classes?.root,
        ])}
        {...itemProps}
      >
        {renderItemContent(item)}
        {renderSubmenuSlider(item)}
      </div>
    );
  };

  const renderSubmenuSlider = (item: IRiverListPopupItem): ReactElement => {
    const direction: SubmenuSlideDirection =
      item.submenu?.slideDirection ?? DEFAULT_SUBMENU_SLIDE_DIRECTION;

    return (
      <div
        className={clsx([
          styles.submenuSliderContainer,
          item.submenu?.classes?.container,
          direction === "right"
            ? styles.slideDirectionRight
            : styles.slideDirectionLeft,
        ])}
        onMouseEnter={clearSubmenuTimeout}
        onMouseLeave={() => {
          setSubmenuTimeout(closeSubmenuWithTimeout());
        }}
      >
        <Slide
          direction={direction}
          in={submenuOpened}
          mountOnEnter
          unmountOnExit
        >
          <div
            className={clsx([
              styles.submenuSlider,
              item.submenu?.classes?.slider,
            ])}
          >
            {item.submenu?.items.map((subitem, index) =>
              renderItem(subitem, index)
            )}
          </div>
        </Slide>
      </div>
    );
  };

  const renderPopupBody = (): ReactElement => (
    <Box className={styles.body}>
      <div className={styles.items}>
        {props.items.map((item, index) => {
          if (!!item.isSeparator) {
            return <ItemSeparator key={index} />;
          } else if (!!item.submenu) {
            return renderItemWithSubmenu(item, index);
          } else {
            return renderItem(item, index);
          }
        })}
      </div>
    </Box>
  );

  return (
    <Popover
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={props.popover?.anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
      transformOrigin={
        props.popover?.transformOrigin || DEFAULT_TRANSFORM_ORIGIN
      }
      classes={{
        paper: clsx([styles.root, props.classes?.root]),
      }}
    >
      {props.title && (
        <Box className={styles.header}>
          <div className={styles.popupTitle}>{props.title}</div>
        </Box>
      )}
      {renderPopupBody()}
    </Popover>
  );
};
