import React, { ReactElement, useContext } from "react";
import {
  IAdapterUiContextState,
  AdapterUiContext,
  ScheduleContext,
} from "../../../../context";
import {
  ScheduleUiService,
  userPreferencesService,
} from "../../../../services";
import { TabHeader } from "../../../shared";
import styles from "./schedule-tasks-tab-header.module.scss";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { useNotification, useTranslation } from "@river/common-ui";

export const ScheduleTasksTabHeader: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const scheduleContext = useContext(ScheduleContext);
  const { t } = useTranslation();
  const notify = useNotification();
  const renderScheduleTasksSectionToggle = (): ReactElement => {
    const { scheduleTasksExpanded, setScheduleTasksExpanded } =
      scheduleContext!;

    const handleSetScheduleTasksExpanded = async () => {
      const value = !scheduleTasksExpanded;
      setScheduleTasksExpanded(value);
      try {
        await userPreferencesService.setScheduleTaskExpanded(
          adapterContext!.service.getAdapterService(),
          value
        );
      } catch (message) {
        notify.error({ message });
      }
    };

    const tooltip = scheduleTasksExpanded
      ? t("common.label:collapse")
      : t("common.label:expand");
    const Toggle = scheduleTasksExpanded
      ? ExpandLessSharpIcon
      : ExpandMoreSharpIcon;
    return (
      <Toggle
        titleAccess={tooltip}
        className={styles.scheduleTasksToggle}
        onClick={handleSetScheduleTasksExpanded}
      />
    );
  };

  return (
    <TabHeader tabs={uiService.getTabs()()}>
      <div className={styles.customContent}>
        {renderScheduleTasksSectionToggle()}
      </div>
    </TabHeader>
  );
};
