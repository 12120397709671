import { ReactElement } from "react";
import { Column, FormatterProps, SelectColumn } from "react-data-grid";
import { useTableCellRenderers } from "../../../hooks";
import styles from "./river-data-grid.module.scss";

type FormatterFunction = (formatterProps: FormatterProps<any>) => ReactElement;

export const useRiverSelectColumn = () => {
  const { renderCell } = useTableCellRenderers();

  const RiverSelectColumn: Column<any> = {
    ...SelectColumn,
    formatter: (formatterProps: FormatterProps<any>) => {
      const defaultFormatter: FormatterFunction =
        SelectColumn.formatter as FormatterFunction;
      return renderCell({
        formatterProps,
        content: defaultFormatter(formatterProps),
      });
    },
    cellClass: styles.selectColumn,
    headerCellClass: styles.selectColumn,
  };

  return {
    RiverSelectColumn,
  };
};

export type IUseRiverSelectColumn = ReturnType<typeof useRiverSelectColumn>;
