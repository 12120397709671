import { useRiverForm, RiverFormInstance } from "../../../hooks";
import {
  AdapterDependencyActionDto,
  IEntityDefinition,
  IEntityObject,
} from "@river/interfaces";
import { useContext } from "react";
import {
  SupervisorScheduleContext,
  IAdapterUiContextState,
  AdapterUiContext,
  TableContext,
} from "../../../context";
import { Constants } from "@river/constants";
import { uiConstants } from "../../../helpers";
import { SupervisorScheduleAction } from "../../../services/supervisor-schedule-ui-service";
import { TaskHelpersUiService } from "../../../services/task-helpers-ui.service";
import { useAllowedAction } from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { TableUiService } from "../../../services";

export const dtoMetaDefinition: IEntityDefinition = {
  entity: {
    _id: "",
    adapter_type: "sap",
    entity_name: "dtoMetaDefinition",
  },
  attributes: [
    {
      _id: "lag_hours",
      adapter_type: "sap",
      entity_name: "dtoMetaDefinition",
      attribute_name: "lag_hours",
      data_type: Constants.data_type.double,
      description: "",
      cardinality: "one",
      is_persistent: true,
    },
  ],
  relations: [],
  indexes: [],
};

const getOperationId = (workorder: IEntityObject): string => {
  return String(workorder[uiConstants.fields._id]);
};

interface IOperationDependency {
  predecessor: IEntityObject;
  successor: IEntityObject;
  relation: IEntityObject;
}

export const useSupervisorOperationRelationForm = (props: {
  dependency?: IOperationDependency;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
}): RiverFormInstance => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const tableContext = useContext(TableContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const taskHelpers: TaskHelpersUiService =
    adapterContext?.service.getTaskHelpersUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const { getOperationsOverlayPropertyNames } = taskHelpers.getHelpers()();
  const {
    OrderPredecessorProp,
    OrderSuccessorProp,
    OperationPredecessorProp,
    OperationSuccessorProp,
    RelationTypeProp,
    LagHoursProp,
  } = getOperationsOverlayPropertyNames();
  const isScheduleActionAllowed = useAllowedAction()(
    ModuleKey.SUPERVISOR_SCHEDULES,
    SupervisorScheduleAction.SCHEDULE
  );

  const relation = props.dependency?.relation;
  const OrderPredecessor = relation?.[OrderPredecessorProp] || "";
  const OperationPredecessor = relation?.[OperationPredecessorProp] || "";
  const OrderSuccessor = relation?.[OrderSuccessorProp] || "";
  const OperationSuccessor = relation?.[OperationSuccessorProp] || "";
  const RelationType = relation?.[RelationTypeProp] || "";
  const LagHours = Number(relation?.[LagHoursProp]);
  return useRiverForm<AdapterDependencyActionDto, IEntityObject, Object>({
    initialDto: Object.assign(new AdapterDependencyActionDto(), {
      folder_id: scheduleContext?.currentSchedule?._id,
      predecessor_id: props.dependency?.predecessor
        ? getOperationId(props.dependency.predecessor)
        : "",
      successor_id: props.dependency?.successor
        ? getOperationId(props.dependency.successor)
        : "",
      dependency_type: props.dependency ? RelationType : "",
      lag_hours: LagHours || 0,
    }),
    entityDefinition: dtoMetaDefinition,
    instanceToEdit: relation,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    onDelete: props.onDelete,
    standalone: {
      fields: {
        OrderPredecessor,
        OperationPredecessor,
        OrderSuccessor,
        OperationSuccessor,
      },
      fieldDefs: [],
    },
    create: async (dto: AdapterDependencyActionDto): Promise<IEntityObject> => {
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .createDependency(dto);
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
      });
      return {} as IEntityObject;
    },
    update: async (dto: AdapterDependencyActionDto): Promise<void> => {
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .updateDependency(relation![uiConstants.fields._id] as string, dto);
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
      });
    },
    delete: async (): Promise<void> => {
      const dependencyId: string = String(relation!["_id"]);
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .deleteDependency(dependencyId);
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
      });
    },
    readOnly: !isScheduleActionAllowed,
  });
};
