import React, { ReactElement, useContext, useEffect, useRef } from "react";
import { IEntityObject } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  ScheduleContext,
} from "../../../../context";
import { IUseScheduleOperationsOverlay } from "./schedule-operations-gantt-overlay";
import { ScheduleOperationsGanttOverlay } from "./schedule-operations-gantt-overlay";
import { ScheduleUiService, ScheduleTasksTabId } from "../../../../services";
import { IUseScheduleGantt } from "../use-schedule-gantt";
import { DEFAULT_ROW_HEIGHT } from "../../../shared";
import styles from "./schedule-gantt-overlay.module.scss";

export interface IScheduleGanttOverlayProps {
  operationsOverlay: IUseScheduleOperationsOverlay;
  data: IEntityObject[];
}

export const ScheduleGanttOverlay: React.FC<IScheduleGanttOverlayProps> = (
  props
): ReactElement => {
  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt = scheduleContext!.ganttRef.current!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUiService =
    adapterContext?.service.getScheduleUiService()!;
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const currentTab: ScheduleTasksTabId = uiService.getCurrentTab()();

  useEffect(() => {
    scheduleContext?.setGanttOverlayScrollAreaRef(scrollAreaRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const top: number = e.currentTarget.scrollTop;
    scheduleContext?.entityTableRef?.current?.element?.scrollTo({ top });
  };

  return (
    <div ref={scrollAreaRef} className={styles.scrollArea} onScroll={onScroll}>
      <div
        className={styles.content}
        style={{
          height: `${props.data.length * DEFAULT_ROW_HEIGHT}px`,
        }}
      >
        {currentTab === ScheduleTasksTabId.OPERATIONS &&
          !!gantt.ganttRatios && (
            <ScheduleOperationsGanttOverlay overlay={props.operationsOverlay} />
          )}
      </div>
    </div>
  );
};
