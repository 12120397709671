import React, { useContext, useState } from "react";
import { RiverDataGrid } from "../shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import { AddUserDialog } from "./add-user-dialog";
import { UsersGridHeader } from "./users-grid-header";
import { UsersSubHeader } from "./users-sub-header";
import { UsersHeader } from "./users-header";
import { UserRolesUiService } from "../../services";
import { UserRolesDialog } from "./user-roles-dialog";
import { AdapterUserDto, IAdapterUser } from "@river/interfaces";
import { usePageCleanup } from "../../hooks";
import styles from "./users.module.scss";

export enum UsersAction {
  CREATE = "CREATE_USER",
  UPDATE = "UPDATE_USER",
  DELETE = "DELETE_USER",
}

export const Users: React.FC = () => {
  usePageCleanup();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const userRolesUiService: UserRolesUiService =
    adapterContext?.service!.getUserRolesUiService()!;

  const [addUserDialogOpened, setAddUserDialogOpened] =
    useState<boolean>(false);
  const [userRolesDialogOpened, setUserRolesDialogOpened] =
    useState<boolean>(false);

  const [userRoleToEdit, setUserRoleToEdit] = useState<AdapterUserDto | null>(
    null
  );

  const openAddUserDialog = (): void => setAddUserDialogOpened(true);
  const closeAddUserDialog = (): void => setAddUserDialogOpened(false);

  const openUserRolesDialog = (editObj?: IAdapterUser): void => {
    let objectToEdit: IAdapterUser = editObj!;
    if (!objectToEdit) {
      const selectedUserRoles: IAdapterUser[] =
        table.getSelectedRows() as IAdapterUser[];
      objectToEdit = selectedUserRoles[0];
    }
    setUserRoleToEdit(objectToEdit || null);
    setUserRolesDialogOpened(true);
  };
  const closeUserRoleDialog = (): void => setUserRolesDialogOpened(false);

  const { entity, table } = userRolesUiService.useUserRoles()();

  const isLoading: boolean = table.isLoading;
  return (
    <>
      <TableContext.Provider value={{ table, entity }}>
        <UsersHeader />
        <UsersSubHeader openAddUserDialog={openAddUserDialog} />
        <UsersGridHeader openUserRoleDialog={openUserRolesDialog} />
        <RiverSpinner show={isLoading} />
        <RiverDataGrid
          className={styles.grid}
          columns={table.columns}
          rows={table.entities}
          rowKeyGetter={table.rowKeyGetter}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          sortColumns={table.sortColumns}
          onSortColumnsChange={(e) => {
            table.setSortColumns(e);
          }}
          disableSelectAll={true}
          singleSelect={true}
        />
        <AddUserDialog
          open={addUserDialogOpened}
          onClose={async (success) => {
            closeAddUserDialog();
            if (success) {
              await table.refresh();
            }
          }}
          onNext={async (userRoleToEdit: IAdapterUser) => {
            closeAddUserDialog();
            openUserRolesDialog(userRoleToEdit);
            await table.refresh();
          }}
        />
        <UserRolesDialog
          userRole={userRoleToEdit}
          open={userRolesDialogOpened}
          onClose={(requiresRefresh) => {
            closeUserRoleDialog();
            if (requiresRefresh) table.refresh();
            table.setSelectedRowIds(new Set());
          }}
          onSubmit={() => {
            closeUserRoleDialog();
            table.refresh();
          }}
        />
      </TableContext.Provider>
    </>
  );
};
