import React, { useEffect, useState, useContext } from "react";
import { FormatterProps } from "react-data-grid";
import { RiverDataGrid } from "../shared";
import {
  IAdapterCalendar,
  IAdapterShift,
  IEntityObject,
} from "@river/interfaces";
import { CalendarsHeader } from "./calendars-header";
import { CalendarsSubHeader } from "./calendars-subheader";
import { RiverSpinner, useNotification } from "@river/common-ui";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
} from "../../context";
import { CalendarsGridHeader } from "./calendars-grid-header";
import { uiConstants } from "../../helpers";
import { CalendarDialog } from "./calendar-dialog";
import { CalendarsUiService } from "../../services";
import { usePageCleanup } from "../../hooks";

export enum CalendarsAction {
  CREATE = "CREATE_CALENDAR",
  UPDATE = "UPDATE_CALENDAR",
  DELETE = "DELETE_CALENDAR",
}

export const Calendars: React.FC = () => {
  usePageCleanup();

  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: CalendarsUiService =
    adapterContext?.service.getCalendarsUiService()!;

  const onCalendarClick = (formatterProps: FormatterProps<any>): void => {
    table.setSelectedRowIds(new Set());
    openCalendarDialog(formatterProps.row as IAdapterCalendar);
  };

  const { entity, table } = uiService.useCalendars({
    onCalendarClick,
  })();

  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  const [shifts, setShifts] = useState<IAdapterShift[]>([]);
  const [shiftsLoaded, setShiftsLoaded] = useState<boolean>(false);
  const notify = useNotification();

  const [selectedCalendar, setSelectedCalendar] =
    useState<IAdapterCalendar | null>(null);
  const [calendarDialogOpened, setCalendarDialogOpened] =
    useState<boolean>(false);

  const openCalendarDialog = (calendar: IAdapterCalendar): void => {
    setSelectedCalendar(calendar);
    setCalendarDialogOpened(true);
  };

  useEffect(() => {
    if (!shiftsLoaded) {
      getShifts();
    }
  });

  const getShifts = async () => {
    if (!site) return [];
    adapterContext!.service
      .getAdapterService()
      .fetchShifts()
      .then((result) => {
        setShifts(result as IAdapterShift[]);
        setShiftsLoaded(true);
      })
      .catch((message) => {
        notify.error({ message });
      });
  };

  const getSelectedCalendar = (): IAdapterCalendar => {
    let selected: IEntityObject;
    selected = table.entities.filter((entity: IEntityObject) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as unknown as IAdapterCalendar;
  };

  const isLoading: boolean = table.isLoading || !site;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <CalendarsHeader />
      <CalendarsSubHeader shifts={shifts} />
      <CalendarsGridHeader
        selectedCalendar={getSelectedCalendar()}
        shifts={shifts}
      />
      <RiverSpinner show={isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={table.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
      <CalendarDialog
        open={calendarDialogOpened}
        calendar={selectedCalendar}
        shifts={shifts}
        onClose={(success) => {
          setCalendarDialogOpened(false);
          if (success) table.fetch();
        }}
      />
    </TableContext.Provider>
  );
};
