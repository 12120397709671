import { ReactElement, useContext, useState } from "react";
import { useNotification, useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import { AdapterService } from "../../../../services";
import { useGridActionHelpers } from "../../../../helpers";
import { GenericAction } from "./generic-action";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { UserStatusDialog } from "../../../backlog/user-status-dialog";

export interface IUseUserStatusActionProps {
  entity_name: string;
  entity_ids?: string[];
  enableOn?: boolean;
}

export const useUserStatusAction = (props: IUseUserStatusActionProps) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("shared.user_status_dialog:title");

  const onClick = (entity_ids?: string[]): void => {
    tableContext?.table.forceLoadingState(true);
    adapterService
      .getUserStatusResponse({
        entity_name: props.entity_name,
        entity_ids:
          entity_ids ||
          props.entity_ids ||
          Array.from(tableContext?.table.selectedRowIds!),
      })
      .then((e) => {
        tableContext?.table.forceLoadingState(false);
        setDialogOpened(true);
      })
      .catch((message) => {
        tableContext?.table.forceLoadingState(false);
        notify.error({ message });
      });
  };

  const renderIcon = (iconProps?: any): ReactElement => (
    <GenericAction
      icon={SupervisedUserCircleIcon}
      enableOn={props.enableOn}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...iconProps}
    />
  );

  const renderDialog = (): ReactElement => (
    <UserStatusDialog
      open={dialogOpened}
      onClose={() => setDialogOpened(false)}
      {...props}
    />
  );

  const renderUserStatusAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderUserStatusAction,
    userStatusAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseUserStatusAction = ReturnType<typeof useUserStatusAction>;
