import { httpService } from "@river/common-ui";
import { Config } from "@river/config";
import { Constants } from "@river/constants";
import { ICustomerInfo } from "@river/interfaces";
import { AdapterUiService } from "./adapter-ui.service";
import { OracleCloudAdapterUiService } from "./oracle-cloud";
import { SapAdapterUiService } from "./sap";
import { OracleEbsAdapterUiService } from "./oracle-ebs";
import { JdeAdapterUiService } from "./jde";

// --------------
export class AdapterUiServiceFactory {
  // ------------
  static async createUiAdapter(subdomain: string): Promise<AdapterUiService> {
    const customerInfo =
      await AdapterUiServiceFactory.getCustomerInfo(subdomain);
    switch (customerInfo.customer.adapter_type) {
      case Constants.adapter_type.sap:
        return new SapAdapterUiService(customerInfo);

      case Constants.adapter_type.oracle_cloud:
        return new OracleCloudAdapterUiService(customerInfo);

      case Constants.adapter_type.oracle_ebs:
        return new OracleEbsAdapterUiService(customerInfo);

      case Constants.adapter_type.jde:
        return new JdeAdapterUiService(customerInfo);

      default:
        throw new Error("Invalid adapter type");
    }
  }

  // ------------
  protected static async getCustomerInfo(
    subdomain: string
  ): Promise<ICustomerInfo> {
    const response = await httpService.get(
      `${Config.web.REACT_APP_PLATFORM_API_URL}/api/platform/${subdomain}`
    );

    return response.data;
  }
}
