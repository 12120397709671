import React, { useContext } from "react";
import { IUseScheduleGantt } from "../../schedule-gantt/use-schedule-gantt";
import { ScheduleContext } from "../../../../context";
import { UtilizationGanttHeaderPeriod } from "./utilization-gantt-header-period";
import { useGanttRenderChecks } from "../../schedule-gantt";
import { SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID } from "../schedule-utilization-tables";
import styles from "./schedule-utilization-header-renderer.module.scss";
import clsx from "clsx";

export const ScheduleUtilizationHeaderRenderer: React.FC = () => {
  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt =
    scheduleContext?.utilizationGanttRef.current!;
  const { periods } = gantt;
  const { shouldRender } = useGanttRenderChecks({
    gantt,
    containerId: SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID,
  });

  return (
    <>
      {shouldRender && (
        <div className={clsx([styles.root])}>
          {periods.map((period) => (
            <UtilizationGanttHeaderPeriod period={period} key={period.index} />
          ))}
        </div>
      )}
    </>
  );
};
