import React, { ReactElement, useContext, useState } from "react";
import { IAdapterFolder } from "@river/interfaces";
import {
  GridHeader,
  EditAction,
  DeleteAction,
  GridActions,
  ColumnSelectorDropdown,
  FilterDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { SchedulesAction } from "../schedules";
import { ModuleKey } from "../../sidebar-menu";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { ScheduleDialog } from "../schedule-dialog";
import { useTranslation } from "@river/common-ui";

interface ISchedulesGridHeader {
  className?: string;
  selectedSchedule: IAdapterFolder;
}

export const SchedulesGridHeader: React.FC<ISchedulesGridHeader> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const [scheduleToEdit, setScheduleToEdit] = useState<IAdapterFolder | null>(
    props.selectedSchedule
  );
  const [scheduleDialogOpened, setScheduleDialogOpened] =
    useState<boolean>(false);
  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const openEditDialog = (): void => {
    if (props.selectedSchedule) {
      setScheduleToEdit(props.selectedSchedule);
      setScheduleDialogOpened(true);
    }
  };
  const closeDialog = (): void => setScheduleDialogOpened(false);

  const deleteSelectedSchedule = async (): Promise<any> => {
    if (props.selectedSchedule) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteFolder(props.selectedSchedule._id);
        tableContext?.table.refresh();
        deleteScheduleConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteScheduleConfirmation = useSimpleDialog({
    title: t("module.schedules:delete_dialog.title"),
    message: t("module.schedules:delete_dialog.message"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedSchedule,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={SchedulesAction.UPDATE}
          >
            <EditAction onClick={openEditDialog} />
          </ProtectedAction>
          <ProtectedAction
            module={ModuleKey.SCHEDULES}
            action={SchedulesAction.DELETE}
          >
            <DeleteAction onClick={() => deleteScheduleConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>

      {scheduleDialogOpened && (
        <ScheduleDialog
          open={scheduleDialogOpened}
          schedule={scheduleToEdit}
          onClose={(success) => {
            closeDialog();
            if (success) {
              tableContext?.table.fetch();
            }
          }}
        />
      )}
      {deleteScheduleConfirmation.render()}
    </>
  );
};
