import React, { SVGProps } from "react";

export const BaselineCheckmarkIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="currentColor"
      {...props}
    >
      <path d="M5.9375 10L10.8958 5.0625L9.83333 4L5.9375 7.875L4.16667 6.125L3.10417 7.1875L5.9375 10ZM1.5 14C1.0875 14 0.734375 13.8531 0.440625 13.5594C0.146875 13.2656 0 12.9125 0 12.5V1.5C0 1.0875 0.146875 0.734375 0.440625 0.440625C0.734375 0.146875 1.0875 0 1.5 0H12.5C12.9125 0 13.2656 0.146875 13.5594 0.440625C13.8531 0.734375 14 1.0875 14 1.5V12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H1.5Z" />
    </svg>
  );
};
