import React, { ReactElement, useEffect, useState } from "react";
import {
  RiverTextInput,
  RiverDialog,
  RiverDialogActionButton,
  RiverDateInput,
} from "@river/common-ui";
import { Box } from "@mui/material";
import {
  AdapterCalendarDto,
  IAdapterCalendar,
  IAdapterShift,
  AdapterCalendarDay,
} from "@river/interfaces";
import { CalendarTemplateDay } from "./calendar-template-day";
import { RiverFormInstance } from "../../../hooks";
import {
  useCalendarForm,
  MAX_NUMBER_OF_DAYS,
  StandaloneCalendarValidator,
} from "./use-calendar-form";
import styles from "./calendar-dialog.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

const getBlankCalendarDay = (): AdapterCalendarDay => {
  let initialDay: AdapterCalendarDay = new AdapterCalendarDay();
  Object.assign(initialDay, {
    shift: "",
    shift_id: null,
    start_hours: 0,
    start_minutes: 0,
    end_hours: 0,
    end_minutes: 0,
    duration_hours: 0,
    available_hours: 0,
  });
  return initialDay;
};

interface ICalendarDialogProps {
  calendar: IAdapterCalendar | null;
  open: boolean;
  onClose: (success: boolean) => void;
  shifts: IAdapterShift[];
}

export const CalendarDialog: React.FC<ICalendarDialogProps> = (
  props
): ReactElement => {
  const form: RiverFormInstance = useCalendarForm({
    calendar: props.calendar,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });
  const { t } = useTranslation();

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const calendarDto = form.dto as AdapterCalendarDto;
  const { validationErrors, submit, setDto, resetForm, isCreate } = form;
  const standalone: StandaloneCalendarValidator =
    form.standalone as StandaloneCalendarValidator;

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const generateTemplate = (numDays: number): void => {
    const newTemplate: AdapterCalendarDay[] = [];
    for (let i = 0; i < numDays; i++) {
      newTemplate.push(getBlankCalendarDay());
    }
    const newCalendar: AdapterCalendarDto = {
      ...calendarDto,
      template: newTemplate,
    };
    Object.setPrototypeOf(newCalendar, Object.getPrototypeOf(form));
    setDto(newCalendar);
  };

  const renderNumberOfDaysField = (): ReactElement => (
    <RiverTextInput
      id={"numberOfTemplateDays"}
      className={styles.numberOfDaysField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
          max: MAX_NUMBER_OF_DAYS,
        },
      }}
    />
  );

  const renderTemplateDetails = (): ReactElement => (
    <Box className={styles.templateDetails}>
      <RiverDateInput id={"reference_date"} />
      {renderNumberOfDaysField()}
      {!form.isReadOnly && (
        <RiverDialogActionButton
          className={styles.initializeButton}
          onClick={() => generateTemplate(standalone.numberOfTemplateDays)}
          text={t("common.button:initialize")}
          disabled={
            !!validationErrors?.fields["numberOfTemplateDays"] ||
            !standalone.numberOfTemplateDays
          }
        />
      )}
    </Box>
  );

  const renderTemplateHeader = (): ReactElement => {
    const len = calendarDto.template.length;
    const numCols = len < 7 ? len & 7 : 7;
    return (
      <Box className={styles.header}>
        {Array(numCols)
          .fill("")
          .map((el, index) => (
            <div key={index} className={styles.headerCol}>
              {`${t("module.calendars:label.day")} ${index + 1}`}
            </div>
          ))}
      </Box>
    );
  };

  const renderTemplate = (): ReactElement => (
    <>
      {calendarDto.template.length > 0 && (
        <Box className={styles.template}>
          <div className={styles.label}>
            {t("module.calendars:label.template")}
          </div>
          <Box className={styles.days}>
            {renderTemplateHeader()}
            <Box
              className={styles.inner}
              style={{
                width: Math.min(calendarDto.template.length * 178, 1220) + "px",
              }}
            >
              {calendarDto.template.map((day, index) => (
                <CalendarTemplateDay
                  key={index}
                  templateIndex={index}
                  day={day}
                  className={styles.day}
                  onUpdate={onDayUpdate}
                  {...props}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );

  const onDayUpdate = (
    day: AdapterCalendarDay,
    templateIndex: number
  ): void => {
    const newTemplate: AdapterCalendarDay[] = [...calendarDto.template];
    newTemplate.splice(templateIndex, 1, day);

    const newState: AdapterCalendarDto = {
      ...calendarDto,
      template: newTemplate,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(calendarDto));
    setDto(newState);
  };

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.calendars:dialog.create_calendar")
      : t("module.calendars:dialog.edit_calendar");
  const getActionButtonText = (): string =>
    isCreate ? t("common.button:add") : t("common.button:save");

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={isOpen}
      onClose={close}
      actionButtonText={getActionButtonText()}
      onSubmit={submit}
      classes={{
        content: clsx([styles.content, { [styles.readOnly]: form.isReadOnly }]),
        paper: styles.paper,
        title: styles.title,
      }}
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      <div className={styles.calendarFields}>
        <RiverTextInput id={"calendar"} className={styles.calendarNameField} />
        <RiverTextInput
          id={"description"}
          className={styles.calendarDescriptionField}
          fullWidth
        />
      </div>
      <p>
        {t("module.calendars:label.provide_a_start_date")}
        <br /> {t("module.calendars:label.clear_the_start_date")}
      </p>
      {renderTemplateDetails()}
      {renderTemplate()}
    </RiverDialog>
  );
};
