import {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Column } from "react-data-grid";
import {
  RiverDataGrid,
  ITableFetchFunctionProps,
  useTable,
  IUseTable,
  useRiverSelectColumn,
} from "../shared";
import { SupervisorCrewHeader } from "./supervisor-crew-header";
import { SupervisorCrewSubHeader } from "./supervisor-crew-sub-header";
import { SupervisorCrewGridHeader } from "./supervisor-crew-grid-header";
import * as dateFns from "date-fns";
import { IAdapterShift, IEntityObject } from "@river/interfaces";
import { RiverSpinner } from "@river/common-ui";
import { fetchHelpers, uiConstants } from "../../helpers";
import { useEntity, useTableCellRenderers } from "../../hooks";
import {
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
  AdapterUiContext,
} from "../../context";
import { addDays } from "date-fns";
import { useTranslation } from "@river/common-ui";
import { useParams } from "react-router";
import { SupervisorCrewEditorDrawer } from "./supervisor-crew-editor-drawer";
import {
  GenerateAvailabilityDialog,
  useAsyncGenerateAvailability,
} from "../availability";
import dataGridStyles from "../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./supervisor-crew.module.scss";
import clsx from "clsx";

export enum SupervisorCrewAction {
  SYNC_RESOURCES = "SYNC_RESOURCES",
}

const getInitDate = () => {
  const d = new Date();
  return new Date(
    Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
  );
};

export const SupervisorCrew: FC = () => {
  const entityName: string = "availability_header";
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell } = useTableCellRenderers();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<any | null>(null);
  const [activeRowDataIndex, setActiveRowDataIndex] = useState<number | null>(
    null
  );
  const [availabilityDialogOpened, setAvailabilityDialogOpened] =
    useState<boolean>(false);
  const userContext = useContext(AdapterUserContext);
  const adapterContext = useContext(AdapterUiContext);
  const uiService = adapterContext?.service.getAvailabilityUiService()!;
  const { crew } = useParams<{ crew: string }>();
  const site = userContext?.userProperties[AdapterUserContextProp.SITE];
  const entity = useEntity({
    entityName,
  });
  const startDateRef = useRef<Date>(getInitDate());
  const shiftsRef = useRef<IAdapterShift[]>([]);

  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const fetchShifts = async () => {
    shiftsRef.current = await adapterContext!.service
      .getAdapterService()
      .fetchShifts();
    forceUpdate();
  };

  useEffect(() => {
    if (site) {
      fetchShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const getShiftName = (shiftId: string) => {
    let shiftName = "";
    const shift = getShift(shiftId);
    if (shift) {
      shiftName = shift.shift;
    }
    return shiftName;
  };

  const getShift = (shiftId: string) => {
    return shiftsRef.current.filter((shift) => shift._id === shiftId)[0];
  };

  const getColumnDate = (index: number) => {
    const date = addDays(startDateRef.current, index);
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );
  };

  const formatHeaderDate = (index: number) => {
    const date: Date = addDays(startDateRef.current, index);
    return new Intl.DateTimeFormat(navigator.language, {
      timeZone: "UTC",
      month: "short",
      day: "2-digit",
    }).format(date);
  };

  const generateAvailabilityColumn = (index: number): Column<any> => {
    return {
      key: `${index}`,
      name: "",
      width: 130,
      headerCellClass: styles.availabilityHeaderCell,
      headerRenderer: (headerRendererProps) => {
        return (
          <>
            <div className={styles.date}>{formatHeaderDate(index)}</div>
          </>
        );
      },
      formatter: (formatterProps) => {
        let shiftName = "";
        let availableHours = "";
        const columnDate = dateFns.addDays(startDateRef.current, index);
        const data: IEntityObject = formatterProps.row.availability.find(
          (a: any) =>
            new Date(a.availability_date).getTime() === columnDate.getTime()
        );
        if (data) {
          shiftName = data.shift_id ? getShiftName(String(data.shift_id)) : "";
          availableHours =
            typeof data.available_hours !== "undefined"
              ? String(data.available_hours)
              : "";
        }
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div
                  onClick={() =>
                    handleOpenDrawer(
                      formatterProps.row,
                      formatterProps.column,
                      index
                    )
                  }
                  className={styles.customCellAvailability}
                >
                  <div className={styles.shiftName}>{shiftName}</div>
                  <div
                    className={clsx([
                      styles.hours,
                      dataGridStyles.dataGridLink,
                    ])}
                  >
                    {availableHours}
                  </div>
                </div>
              ),
            })}
          </>
        );
      },
    };
  };

  function handleCloseDrawer() {
    setActiveRowDataIndex(null);
    setActiveRow(null);
    setOpenDrawer(false);
  }

  const generateAdditionalColumns = (): Column<any>[] => {
    const availabilityColumns: Column<any>[] = [];
    for (let i = 0; i < 7; i++)
      availabilityColumns.push(generateAvailabilityColumn(i));
    return availabilityColumns;
  };

  const fetchFunction = async (fetchProps: ITableFetchFunctionProps) =>
    await adapterContext!.service
      .getAdapterService()
      .fetchAvailability(
        startDateRef.current,
        fetchHelpers.getTableQuery({ fetchProps }),
        crew
      );

  const table: IUseTable = useTable({
    entityName,
    saveKey: "availability",
    columns: uiService.getAvailabilityResourcesColumns(t) || [],
    fetchFunction,
    dependencies: [!!site],
    fetchOn: true,
    infiniteScrolling: true,
    fetchTriggers: [site, startDateRef.current],
  });

  const { generateAvailabilityProgress, doGenerateAvailability } =
    useAsyncGenerateAvailability({ table });

  function getCompareDateFormat(value: Date) {
    const tmp = new Date(value);
    return `${tmp.getFullYear()}-${tmp.getMonth() + 1}-${tmp.getDate()}`;
  }

  function handleOpenDrawer(row: any, column: any, index: number) {
    const columnDate = getColumnDate(Number(column.key));
    if (
      row.availability?.length > 0 &&
      row.availability
        .map((a: { availability_date: Date }) =>
          getCompareDateFormat(a.availability_date)
        )
        .indexOf(getCompareDateFormat(columnDate)) !== -1
    ) {
      setActiveRowDataIndex(index);
      setActiveRow(row);
      setOpenDrawer(true);
    } else {
      table.setSelectedRowIds(new Set([row._id]));
      setAvailabilityDialogOpened(true);
    }
  }

  const closeAvailabilityDialog = () => {
    table.setSelectedRowIds(new Set());
    setAvailabilityDialogOpened(false);
  };

  const isLoading = table.isLoading;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <SupervisorCrewHeader />
      <SupervisorCrewSubHeader crew={crew ?? ""} />
      <SupervisorCrewGridHeader
        setAvailabilityDialogOpened={setAvailabilityDialogOpened}
        startDate={startDateRef.current}
        onStartDateChange={(date) => {
          startDateRef.current = date;
          forceUpdate();
        }}
      />
      <RiverSpinner show={isLoading} />
      <RiverDataGrid
        className={styles.grid}
        columns={table.columns.concat([
          RiverSelectColumn,
          ...generateAdditionalColumns(),
        ])}
        rows={table.entities}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        rowKeyGetter={(row) => row[uiConstants.fields._id]}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
      {generateAvailabilityProgress.renderDialog()}
      {activeRow && activeRowDataIndex !== null && openDrawer && (
        <SupervisorCrewEditorDrawer
          activeRow={activeRow}
          shifts={shiftsRef.current}
          startDate={startDateRef.current}
          index={activeRowDataIndex}
          onClose={handleCloseDrawer}
          open={openDrawer}
        />
      )}
      {availabilityDialogOpened && (
        <GenerateAvailabilityDialog
          open={availabilityDialogOpened}
          onClose={closeAvailabilityDialog}
          doGenerateAvailability={doGenerateAvailability}
        />
      )}
    </TableContext.Provider>
  );
};
