import React from "react";
import { PageHeader } from "../../page-header";
import { useTranslation } from "@river/common-ui";

export const ValidationRulesHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageHeader pageTitle={t("module.rules:job_validation_rules.title")} />
  );
};
