import { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  BacklogContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useNotification } from "@river/common-ui";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IAdapterFolder } from "@river/interfaces";
import { useGridActionHelpers } from "../../../helpers";
import { TableUiService } from "../../table-ui.service";
import { useParams } from "react-router";

export const useRemoveFromScheduleAction = () => {
  const { t } = useTranslation();
  const backlogContext = useContext(BacklogContext);
  const currentSchedule: IAdapterFolder = backlogContext?.currentSchedule!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;

  const title: string = t("module.backlog:action_label.remove_from_schedule");

  const onClick = (): void => {
    tableContext?.table.forceLoadingState(true);
    adapterContext!.service
      .getAdapterService()
      .removeFromFolder(
        currentSchedule._id,
        "workorder",
        getSelectedRowsObjectIds(tableContext!.table)
      )
      .then(() => {
        tableContext?.table.refresh();
        backlogContext?.refreshSchedulePane!();
        notify.info({
          title: t("module.backlog:notification.work_order_removed.title"),
          message: t("module.backlog:notification.work_order_removed.message", {
            folder: currentSchedule.folder,
          }),
        });
      })
      .catch((message) => {
        notify.error({ message });
      })
      .finally(() => {
        tableContext?.table.forceLoadingState(false);
      });
  };

  const renderRemoveFromScheduleAction = (props?: any): ReactElement => (
    <GenericAction
      icon={HighlightOffIcon}
      enableOn={tableContext?.table!.selectedRowIds.size !== 0 && !!scheduleId}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderRemoveFromScheduleAction,
    removeFromScheduleAction: {
      icon: withSanitizedProps(renderRemoveFromScheduleAction),
      title,
      onClick,
    },
  };
};

export type IUseRemoveFromScheduleAction = ReturnType<
  typeof useRemoveFromScheduleAction
>;
