import React, { ReactElement, useContext } from "react";
import {
  RiverSpinner,
  RiverDialog,
  useNotification,
  RiverDialogButton,
  RiverDialogActionButton,
} from "@river/common-ui";
import { IAdapterUser, IEntityObject } from "@river/interfaces";
import { AddUserDialogGridHeader } from "./add-user-dialog-grid-header";
import { IUseTable, RiverDataGrid } from "../../shared";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { UsersUiService, UserRolesUiService } from "../../../services";
import styles from "./add-user-dialog.module.scss";

interface IAddUserDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
  onNext: (userRoleToEdit: IAdapterUser) => void;
}

export const AddUserDialog: React.FC<IAddUserDialogProps> = (
  props
): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotification();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const usersUiService: UsersUiService =
    adapterContext?.service!.getUsersUiService()!;
  const userRolesUiService: UserRolesUiService =
    adapterContext?.service!.getUserRolesUiService()!;

  const tableContext = useContext(TableContext);
  const userRolesTable: IUseTable = tableContext?.table!;

  const { entity: userEntity, table: usersTable } = usersUiService.useUsers({
    fetchOn: props.open,
  })();

  const resetDialogState = (): void => {
    usersTable.setSelectedRowIds(new Set());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const handleClose = () => {
    const success = false;
    closeDialog(success);
  };

  const getExistingUserRole = async (
    selectedUser: IEntityObject
  ): Promise<IAdapterUser | null> => {
    let existingUserRole: IAdapterUser | null = null;
    const existingUserRoles: IAdapterUser[] = await adapterContext!.service
      .getAdapterService()
      .fetchUsers({
        query: {
          $and: [
            {
              attribute_name: "user_name",
              attribute_value: {
                operator: "$eq",
                value: selectedUser[usersUiService.getUserNameKey()],
              },
            },
          ],
        },
      });
    if (existingUserRoles.length) {
      existingUserRole = existingUserRoles[0];
    }
    return existingUserRole;
  };

  const handleNext = async () => {
    const selectedUser: IEntityObject = usersTable.getSelectedRows()[0];
    try {
      usersTable.forceLoadingState(true);
      let userRole: IAdapterUser | null =
        await getExistingUserRole(selectedUser);
      if (!userRole) {
        userRole = await userRolesUiService.createUserRole(selectedUser);
      }
      resetDialogState();
      const selectedUserName: string = selectedUser[
        usersUiService.getUserNameKey()
      ] as string;
      await props.onNext(userRole);
      userRolesTable.setSelectedRowIds(new Set([selectedUserName]));
    } catch (message) {
      notify.error({ message });
    } finally {
      usersTable.forceLoadingState(false);
    }
  };

  const isLoading: boolean = usersTable.isLoading;
  return (
    <>
      <RiverDialog
        title={t("module.users:button.add_user")}
        open={props.open}
        actionButtonText={t("common.button:next")}
        closeButtonText={t("common.button:close")}
        onSubmit={handleNext}
        onClose={handleClose}
        classes={{
          paper: styles.paper,
          content: styles.content,
        }}
        showActionsDivider={false}
        dialogProps={{
          maxWidth: false,
        }}
        actionsContent={
          <>
            <RiverDialogButton
              onClick={handleClose}
              text={t("common.button:close")}
            />
            <RiverDialogActionButton
              onClick={handleNext}
              text={t("common.button:next")}
              disabled={usersTable.selectedRowIds.size === 0}
            />
          </>
        }
      >
        <RiverSpinner show={isLoading} />
        <TableContext.Provider
          value={{
            table: usersTable,
            entity: userEntity,
          }}
        >
          <AddUserDialogGridHeader className={styles.usersGridHeader} />
          <RiverDataGrid
            disableSelectAll={true}
            singleSelect={true}
            columns={usersTable.columns}
            rows={usersTable.entities}
            rowKeyGetter={usersTable.rowKeyGetter}
            className={styles.dataGrid}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            sortColumns={usersTable.sortColumns}
            onSortColumnsChange={(e) => {
              usersTable.setSortColumns(e);
            }}
          />
        </TableContext.Provider>
      </RiverDialog>
    </>
  );
};
