import { IRelationRendererProps } from "../../schedule-operations-gantt-overlay";
import {
  IVerticalLine,
  IHorizontalLine,
  IRelationConnectorEvents,
  ganttAbsoluteConstants,
} from "../../render-utils";
import { MouseEventHandler, useContext, useState } from "react";
import { IOperationPosition } from "../../use-schedule-operations-overlay";
import { ScheduleContext } from "../../../../../../../context";
import { IUseScheduleGantt } from "../../../../use-schedule-gantt";

export interface IUseStartToFinish extends IRelationConnectorEvents {
  predecessorStart?: IHorizontalLine;
  vertical?: {
    straight: IVerticalLine;
    curved: {
      top: IVerticalLine;
      middle: IHorizontalLine;
      bottom: IVerticalLine;
    };
  };
  successorFinish?: IHorizontalLine;
  isVerticalConnectorStraight?: () => boolean;
  selected: boolean;
}

export const useStartToFinish = (
  props: IRelationRendererProps
): IUseStartToFinish => {
  const scheduleContext = useContext(ScheduleContext);
  const gantt: IUseScheduleGantt = scheduleContext!.ganttRef.current!;
  const {
    MIN_HORIZONTAL_CONNECTOR_LENGTH: MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
    MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH:
      MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT,
  } = gantt.ganttRatios!;
  const { OUTWARD_CONNECTOR_Y_OFFSET, INWARD_CONNECTOR_Y_OFFSET } =
    ganttAbsoluteConstants;

  const [selected, setSelected] = useState<boolean>(false);

  const onConnectorMouseOver: MouseEventHandler = (event): void => {
    setSelected(true);
  };

  const onConnectorMouseOut: MouseEventHandler = (event): void => {
    setSelected(false);
  };

  const onConnectorClick: MouseEventHandler = (event): void => {
    event.stopPropagation();
    props.editRelation();
  };

  let hookValue: IUseStartToFinish = {
    selected,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  if (!props.predecessor || !props.successor) {
    let line: IHorizontalLine;
    let pos: IOperationPosition;
    if (props.predecessor) {
      pos = props.predecessorPos!;
      line = {
        yPos: pos.yPos,
        startXPercent:
          pos.startXPercent - MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
        endXPercent: pos.startXPercent,
      };
    } else {
      pos = props.successorPos!;
      line = {
        yPos: pos.yPos,
        startXPercent: pos.endXPercent,
        endXPercent: pos.endXPercent + MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT,
      };
    }

    const lineProp: string = props.predecessor
      ? "predecessorStart"
      : "successorFinish";
    hookValue = {
      ...hookValue,
      [lineProp]: line,
    };
  } else {
    // predecessor horizontal start connector position
    const preLeftStartXPercent: number =
      props.predecessorPos!.startXPercent -
      MIN_HORIZONTAL_CONNECTOR_LENGTH_PERCENT;
    const preLeftEndXPercent: number = props.predecessorPos!.startXPercent;
    const preY: number =
      props.predecessorPos!.yPos + OUTWARD_CONNECTOR_Y_OFFSET;

    const isVerticalConnectorStraight = (): boolean =>
      props.successorPos!.endXPercent +
        MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT <=
      preLeftStartXPercent;

    // successor horizontal finish connector position
    const succRightStartXPercent: number = props.successorPos!.endXPercent;
    const succRightEndXPercent: number = isVerticalConnectorStraight()
      ? preLeftStartXPercent
      : succRightStartXPercent + MIN_HORIZONTAL_ARROW_CONNECTOR_LENGTH_PERCENT;
    const succY: number = props.successorPos!.yPos + INWARD_CONNECTOR_Y_OFFSET;
    hookValue = {
      ...hookValue,
      predecessorStart: {
        yPos: preY,
        startXPercent: preLeftStartXPercent,
        endXPercent: preLeftEndXPercent,
        endXAbsoluteOffset: -1,
      },
      vertical: {
        straight: {
          yStart: preY,
          yEnd: succY,
          xPercent: preLeftStartXPercent,
        },
        curved: {
          top: {
            yStart: preY,
            yEnd: preY + (succY - preY) / 2,
            xPercent: preLeftStartXPercent,
          },
          middle: {
            yPos: preY + (succY - preY) / 2,
            startXPercent: preLeftStartXPercent,
            endXPercent: succRightEndXPercent,
          },
          bottom: {
            yStart: preY + (succY - preY) / 2,
            yEnd: succY,
            xPercent: succRightEndXPercent,
          },
        },
      },
      successorFinish: {
        yPos: succY,
        startXPercent: succRightStartXPercent,
        endXPercent: succRightEndXPercent,
        endXAbsoluteOffset: 1,
      },
      isVerticalConnectorStraight,
    };
  }
  return hookValue;
};
