import { useContext } from "react";
import { useWoMassUpdateAction } from "../../../components/shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import {
  useCheckMaterialShortageAction,
  useDownloadAttachmentsAction,
  useJobPriorityScoreAction,
  useMaterialRequirementsAction,
  useOpMassUpdateAction,
  useOpenErpWorkOrderAction,
  useUnassignAction,
  useValidationRulePopupAction,
  useDependenciesDropdownActions,
  useShowOperationsTabAction,
  useShowAssignmentsTabAction,
  useShowResourcesTabAction,
  useShowInstancesTabAction,
  useMoveSelectedRecordsAction,
  useAssignCalendarAction,
  useUnassignCalendarAction,
  useCreateOperationTimecardAction,
  useCreateAssignmentTimecardAction,
  useCreateResourceTimecardAction,
  useCreateInstanceTimecardAction,
  useSetBreakInAction,
  useResetBreakInAction,
} from "./";
import { SupervisorScheduleUiService } from "../supervisor-schedule-ui-service";

export const useGridActions = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const { renderCheckMaterialShortageAction, checkMaterialShortageAction } =
    useCheckMaterialShortageAction();
  const { renderDownloadAttachmentsAction, downloadAttachmentsAction } =
    useDownloadAttachmentsAction();
  const { renderJobPriorityScoreAction, jobPriorityScoreAction } =
    useJobPriorityScoreAction();
  const { renderMaterialRequirementsAction, materialRequirementsAction } =
    useMaterialRequirementsAction();
  const { renderWoMassUpdateAction, woMassUpdateAction } =
    useWoMassUpdateAction({
      renderWoMassUpdateDialog: uiService.renderWoMassUpdateDialog,
    });
  const { renderOpMassUpdateAction, opMassUpdateAction } =
    useOpMassUpdateAction();
  const { renderOpenErpWorkorderAction, openErpWorkorderAction } =
    useOpenErpWorkOrderAction();
  const { renderUnassignAction } = useUnassignAction();
  const { renderValidationRulePopupAction, validationRulePopupAction } =
    useValidationRulePopupAction();
  const { renderDependenciesDropdownActions } =
    useDependenciesDropdownActions();
  const { renderShowOperationsTabAction } = useShowOperationsTabAction();
  const { renderShowAssignmentsTabAction } = useShowAssignmentsTabAction();
  const { renderShowResourcesTabAction } = useShowResourcesTabAction();
  const { renderShowInstancesTabAction } = useShowInstancesTabAction();

  const { renderMoveSelectedRecordsAction, moveSelectedRecordsAction } =
    useMoveSelectedRecordsAction();

  const { renderAssignCalendarAction, assignCalendarAction } =
    useAssignCalendarAction();
  const { renderUnassignCalendarAction, unassignCalendarAction } =
    useUnassignCalendarAction();
  const { renderCreateResourceTimecardAction, createResourceTimecardAction } =
    useCreateResourceTimecardAction();
  const { renderCreateInstanceTimecardAction, createInstanceTimecardAction } =
    useCreateInstanceTimecardAction();
  const { renderCreateOperationTimecardAction, createOperationTimecardAction } =
    useCreateOperationTimecardAction();
  const {
    renderCreateAssignmentTimecardAction,
    createAssignmentTimecardAction,
  } = useCreateAssignmentTimecardAction();

  const { setBreakInAction, renderSetBreakInAction } = useSetBreakInAction();
  const { resetBreakInAction, renderResetBreakInAction } =
    useResetBreakInAction();

  return {
    renderSetBreakInAction,
    setBreakInAction,
    renderResetBreakInAction,
    resetBreakInAction,
    renderCheckMaterialShortageAction,
    checkMaterialShortageAction,
    renderDownloadAttachmentsAction,
    downloadAttachmentsAction,
    renderJobPriorityScoreAction,
    jobPriorityScoreAction,
    renderMaterialRequirementsAction,
    materialRequirementsAction,
    renderWoMassUpdateAction,
    woMassUpdateAction,
    renderOpMassUpdateAction,
    opMassUpdateAction,
    renderOpenErpWorkorderAction,
    openErpWorkorderAction,
    renderUnassignAction,
    renderValidationRulePopupAction,
    validationRulePopupAction,
    renderDependenciesDropdownActions,
    renderShowOperationsTabAction,
    renderShowAssignmentsTabAction,
    renderShowResourcesTabAction,
    renderShowInstancesTabAction,
    renderMoveSelectedRecordsAction,
    moveSelectedRecordsAction,
    renderAssignCalendarAction,
    assignCalendarAction,
    renderUnassignCalendarAction,
    unassignCalendarAction,
    renderCreateOperationTimecardAction,
    createOperationTimecardAction,
    renderCreateAssignmentTimecardAction,
    createAssignmentTimecardAction,
    renderCreateResourceTimecardAction,
    createResourceTimecardAction,
    renderCreateInstanceTimecardAction,
    createInstanceTimecardAction,
  };
};

export type IUseGridActions = ReturnType<typeof useGridActions>;
