import React from "react";
import { RiverDialog } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { useAdvancedFilters } from "./use-advanced-filters";
import { ITableFilter } from "../../../interfaces";
import { TableFilterScope } from "./use-advanced-filters-form";

interface ISaveAdvancedFilterDialogProps {
  open: boolean;
  filter?: ITableFilter;
  scope?: TableFilterScope;
  onSave?: () => void;
  onClose: () => void;
}

export const SaveAdvancedFilterDialog: React.FC<
  ISaveAdvancedFilterDialogProps
> = (props: ISaveAdvancedFilterDialogProps) => {
  const { t } = useTranslation();

  const advancedFilters = useAdvancedFilters({
    filter: props.filter,
    scope: props.scope,
    onSave: () => {
      closeDialog();
      props.onSave?.();
    },
  });

  const { save, form } = advancedFilters;

  const closeDialog = (): void => {
    form.resetForm();
    props.onClose();
  };

  const onSubmit = async (): Promise<void> => {
    await save();
  };

  return (
    <RiverDialog
      title={t("shared.save_filters_dialog:dialog_title")}
      open={props.open}
      onClose={closeDialog}
      onSubmit={onSubmit}
      closeButtonText={t("common.button:cancel")}
      actionButtonText={t("common.button:save")}
    >
      {advancedFilters.renderMetaSection()}
    </RiverDialog>
  );
};
