import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IBacklogContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { BacklogTabId, BacklogUiService } from "./backlog-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  IAdapterFolder,
  QueryAttributeDto,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import { AdapterService } from "../adapter.service";
import { getFolderQueryGroup } from "@river/util";
import { SitesUiService } from "../sites-ui.service";

interface IUseBacklogInstancesProps {
  backlogContext: IBacklogContext;
}

export const useBacklogInstances = (
  props: IUseBacklogInstancesProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const instancesTabId: BacklogTabId = BacklogTabId.INSTANCES;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const backlogUiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const sitesUiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const entityHelpers = useEntityHelpers();
  const userContext: IAdapterUserContext = useContext(AdapterUserContext)!;
  const organizationId: string | number =
    (
      userContext?.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id || 0;

  const backlogContext: IBacklogContext = props.backlogContext;
  const currentSchedule: IAdapterFolder = backlogContext.currentSchedule!;
  const currentTab: BacklogTabId = backlogUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getInitialQueryAttributeGroup = (): QueryAttributeGroupDto => {
    const organizationQueryAttribute: QueryAttributeDto = {
      attribute_name: sitesUiService.getSiteKeyColumn(),
      attribute_value: {
        operator: "$eq",
        value: organizationId,
      },
    };
    const elements: (QueryAttributeDto | QueryAttributeGroupDto)[] = [
      organizationQueryAttribute,
    ];
    if (currentSchedule) {
      elements.push(getFolderQueryGroup(currentSchedule, ""));
    }
    return {
      $and: elements,
    };
  };

  const fetchInstances = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getInitialQueryAttributeGroup(),
      }),
      $unwind: [
        "WorkOrderOperationResource",
        "WorkOrderOperationResource.WorkOrderOperationResourceInstance",
      ],
    };

    table.setLastRanQueryDto(query);

    const instances = await adapterService.searchEntityData("operation", query);
    const instanceRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.resource_instance
    );

    instances.forEach((inst) => {
      try {
        entityHelpers.setAttributeValue(
          inst,
          uiConstants.fields.rowType,
          uiConstants.rowType.resource_instance
        );
        entityHelpers.setAttributeValue(
          inst,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(inst, instanceRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing instance _id: ${inst._id}`);
        console.error(e);
      }
    });
    return instances;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `backlog.instances`,
    columns: backlogUiService.getDefaultInstancesColumns()(),
    fetchFunction: fetchInstances,
    dependencies: [!!site],
    fetchOn: currentTab === instancesTabId,
    initialSimpleFilters:
      backlogUiService.getInitialTabSimpleFilters(instancesTabId),
    initialQuery: backlogUiService.getInitialTabQuery(instancesTabId),
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    keepSelection: true,
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      backlogUiService.initialFiltersOverrides[instancesTabId] = null;
      backlogUiService.initialQueryOverrides[instancesTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
