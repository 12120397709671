import React from "react";
import { IRiverSidebarContext } from "@river/common-ui";
import {
  BarData,
  BarOptions,
  PieData,
  PieOptions,
} from "../interfaces/chart.interface";
import { ModuleKey, IMenuItem } from "../components/sidebar-menu";

export type ChartTypeCode = "pie" | "bar";
export type ChartTypes =
  | {
      type: "pie";
      data: PieData;
      options?: PieOptions;
    }
  | {
      type: "bar";
      data: BarData;
      options?: BarOptions;
    };

export interface IChart {
  chart: ChartTypes;
  title: string;
}

export interface IStatsState {
  charts: IChart[];
}

export interface IKPIParamState {
  startDate: Date;
  personNumber: string | null;
  personName: string | null;
  workCenter: string | null;
}

export interface ISidebarContext extends IRiverSidebarContext {
  isCommandClicked: boolean;
  isShowingStats: boolean;
  setIsShowingStats: (newValue: boolean) => void;
  stats: IStatsState;
  setStats: (newStats: IStatsState) => void;
  kpiParams: IKPIParamState;
  setKPIParams: (params: IKPIParamState) => void;
  menuItemsByModuleKey?: Map<ModuleKey, IMenuItem>;
  setMenuItemsByModuleKey: (items: Map<ModuleKey, IMenuItem>) => void;
}

export const SidebarContext = React.createContext<ISidebarContext | null>(null);
