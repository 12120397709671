// Stolen from: https://stackoverflow.com/a/3368118
import { DragEvent } from "react";

export namespace DndUtils {
  function roundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radiusParam?: number,
    fill?: boolean,
    stroke?: boolean
  ) {
    type Sides = { [index: string]: number };

    if (typeof stroke === "undefined") {
      stroke = true;
    }
    if (typeof radiusParam === "undefined") {
      radiusParam = 5;
    }
    const radius: Sides = {
      tl: radiusParam,
      tr: radiusParam,
      br: radiusParam,
      bl: radiusParam,
    };
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  export function dragWithCustomImage(event: DragEvent<any>, text: string) {
    const canvas: HTMLCanvasElement =
      (document.getElementById("canvas_drag_drop") as HTMLCanvasElement) ??
      document.createElement("canvas");
    canvas.width = 200;
    canvas.height = 50;
    canvas.id = "canvas_drag_drop";

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(255, 255, 255, 255)";
      ctx.strokeStyle = "#e9ebf0";
      ctx.lineWidth = 1;
      roundRect(ctx, 0, 0, canvas.width, canvas.height, 5, true, true);
      ctx.font = "15px Roboto";
      ctx.fillStyle = "black";
      ctx.fillText(text, 25, 28);
    }
    const dt = event.dataTransfer;
    dt.setDragImage(canvas, 0, 0);
    canvas.style.position = "absolute";
    canvas.style.left = "-100%";
    document.body.append(canvas);
  }
}
