import React, { ReactElement, useContext } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TableContext } from "../../../../../context";
import { PrimaryButton, IUseEnv, useEnv } from "@river/common-ui";
import {
  GridHeader,
  IGridHeaderStyles,
  GridActions,
  GenericAction,
  FilterDropdown,
} from "../../../index";
import styles from "../river-selector-grid-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

export interface IAvailableObjectsGridHeaderStyles {
  gridHeader?: IGridHeaderStyles;
}

interface IAvailableObjectsGridHeader {
  title: string;
  assignSelectedObjects: () => void;
  classes?: IAvailableObjectsGridHeaderStyles;
}

export const AvailableObjectsGridHeader: React.FC<
  IAvailableObjectsGridHeader
> = (props) => {
  const { t } = useTranslation();
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  const tableContext = useContext(TableContext);

  const renderAddArrow = (): ReactElement => (
    <GenericAction
      icon={ArrowForwardIcon}
      onClick={() => {
        if (tableContext?.table.selectedRowIds.size !== 0) {
          props.assignSelectedObjects();
        }
      }}
      enableOnSelection={true}
      title={t("shared.river_table_selector:label.add_selected")}
      customProps={{
        color: "primary",
      }}
    />
  );

  const renderAddButton = (): ReactElement => (
    <PrimaryButton
      onClick={() => {
        if (tableContext?.table.selectedRowIds.size !== 0) {
          props.assignSelectedObjects();
        }
      }}
      text={t("common.button:add")}
      className={styles.selectionButton}
      disabled={tableContext?.table.selectedRowIds.size === 0}
    />
  );

  return (
    <GridHeader
      title={props.title}
      classes={props.classes?.gridHeader}
      className={clsx([styles.root, { [styles.mobile]: isMobile }])}
    >
      <GridActions className={styles.entityActions}>
        <FilterDropdown />
        {!isMobile && renderAddArrow()}
        {isMobile && renderAddButton()}
      </GridActions>
    </GridHeader>
  );
};
