export const uiConstants = {
  fields: {
    rowType: "table_type",
    rowId: "table_id",
    _id: "_id",
  },
  rowType: {
    workOrder: "workorder",
    operation: "operation",
    assignment: "assignment",
    component: "component",
    operation_resource: "operation_resource",
    resource_instance: "resource_instance",
  },
  utilityColumnTypes: {
    selectRow: "select-row",
    actions: "actions",
  },
} as const;
