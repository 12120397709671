import { useTranslation } from "@river/common-ui";
import { useContext } from "react";
import { SystemCronTasksContext, TableContext } from "../../../../../context";
import { GenericAction, IGenericAction } from "../../../../shared";
import SettingsIcon from "@mui/icons-material/Settings";
import { useGridActionHelpers } from "../../../../../helpers";

export const useEditOptionsAction = () => {
  const { t } = useTranslation();
  const systemCronTasksContext = useContext(SystemCronTasksContext);
  const tableContext = useContext(TableContext);
  const table = tableContext!.table;
  const title: string = t("module.cron:dialog.system_cron.edit_options");
  const selectedItemId =
    table.selectedRowIds.size !== 0 && Array.from(table.selectedRowIds)[0];
  const selectedItem =
    !!selectedItemId &&
    table.entities.find((item) => item._id === selectedItemId);

  const disabled: boolean =
    //@ts-ignore
    !selectedItem || (selectedItem?.options?.length ?? 0) < 1;

  const { withSanitizedProps } = useGridActionHelpers();
  const onClick = () => {
    systemCronTasksContext?.setOpenOptionsDialog(true);
  };

  const renderIcon = (props?: IGenericAction) => (
    <GenericAction
      icon={SettingsIcon}
      enableOn={!disabled}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderAction = () => <>{renderIcon()}</>;

  return {
    renderAction,
    action: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
      disabled,
    },
  };
};

export type IUseEditOptionsAction = ReturnType<typeof useEditOptionsAction>;
