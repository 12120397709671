import React, { ReactElement } from "react";
import {
  RelationRenderer,
  IRelationRendererProps,
} from "../../supervisor-schedule-operations-gantt-overlay";
import { IUseStartToFinish, useStartToFinish } from "./use-start-to-finish";
import {
  IRelationConnectorEvents,
  LineArrow,
  renderHorizontalLine,
  renderVerticalLine,
} from "../../render-utils";
import genericStyles from "../../supervisor-schedule-operations-gantt-overlay.module.scss";
import clsx from "clsx";

export const StartToFinishRelationRenderer: RelationRenderer = (
  props: IRelationRendererProps
): ReactElement => {
  const startToFinish: IUseStartToFinish = useStartToFinish(props);
  const {
    predecessorStart,
    vertical,
    successorFinish,
    isVerticalConnectorStraight,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
    selected,
  } = startToFinish;

  const connectorsEventHandlers: IRelationConnectorEvents = {
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const renderPredecessorStartConnector = (): ReactElement => (
    <>
      {predecessorStart &&
        renderHorizontalLine({
          ...predecessorStart,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderVerticalConnector = (): ReactElement => (
    <>
      {vertical && (
        <>
          {isVerticalConnectorStraight!() && (
            <>
              {renderVerticalLine({
                ...vertical.straight,
                ...connectorsEventHandlers,
              })}
            </>
          )}
          {!isVerticalConnectorStraight!() && (
            <>
              {renderVerticalLine({
                ...vertical.curved.top,
                ...connectorsEventHandlers,
              })}
              {renderHorizontalLine({
                ...vertical.curved.middle,
                ...connectorsEventHandlers,
              })}
              {renderVerticalLine({
                ...vertical.curved.bottom,
                ...connectorsEventHandlers,
              })}
            </>
          )}
        </>
      )}
    </>
  );

  const renderSuccessorFinishConnector = (): ReactElement => (
    <>
      {successorFinish &&
        renderHorizontalLine({
          ...successorFinish,
          ...connectorsEventHandlers,
          arrow: LineArrow.LEFT,
        })}
    </>
  );
  return (
    <div
      className={clsx([
        genericStyles.relation,
        {
          [genericStyles.selected]: selected,
        },
      ])}
    >
      {renderPredecessorStartConnector()}
      {renderVerticalConnector()}
      {renderSuccessorFinishConnector()}
    </div>
  );
};
