import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import {
  AdapterBaselineDto,
  IAdapterBaseline,
  IAdapterFolder,
} from "@river/interfaces";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NotEquals } from "class-validator";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";

const DEFAULT_BASELINE_COLOR: string = "#ea5a5a";

interface IStandaloneValidator {
  baseline: string;
}

export const useSupervisorBaselineForm = (props: {
  retrievedBaselineName: string;
  schedule: IAdapterFolder;
  onCreate: () => void;
}): RiverFormInstance => {
  class StandaloneValidator {
    constructor(obj: IStandaloneValidator) {
      Object.assign(this, obj);
    }
    @NotEquals(props.retrievedBaselineName || !"", {
      message: (opts): string =>
        `validations:schedule.baseline_name?name=${opts.value}`,
    })
    baseline!: string;
  }
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<
    AdapterBaselineDto,
    IAdapterBaseline,
    StandaloneValidator
  >({
    initialDto: Object.assign(new AdapterBaselineDto(), {
      folder_id: props.schedule?._id,
      baseline: "",
      description: "",
      display_options: DEFAULT_BASELINE_COLOR,
    }),
    standalone: {
      fields: new StandaloneValidator({
        baseline: "",
      }),
      fieldDefs: [
        {
          fieldName: "calendarName",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },
    entityName: "baseline",
    onCreate: props.onCreate,
    create: async (dto: AdapterBaselineDto): Promise<any> => {
      return await adapterContext!.service
        .getAdapterService()
        .createFolderBaseline({
          ...dto,
          folder_id: props.schedule._id,
        } as AdapterBaselineDto);
    },
  });
};
