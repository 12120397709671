import React, { ReactElement, useContext, useState } from "react";
import { Availability } from "./availability";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { AvailabilityUiService } from "../../services";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <Availability />}</>
);

export const AvailabilityModule: React.FC = (): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: AvailabilityUiService =
    adapterContext!.service.getAvailabilityUiService();
  const namespaces: string[] = uiService.getI18nNamespaces();

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
