import React, { ReactElement } from "react";
import { IUseEntityTableParams } from "../../ui-service.types";
import { FormatterProps } from "react-data-grid";
import { TFunction } from "react-i18next";
import {
  ExternalResourcesUiService,
  IExternalResourceDialogProps,
} from "../../external-resources-ui.service";
import { OracleEbsExternalResourceDialog } from "./oracle-ebs-external-resource-dialog";
import dataGridStyles from "../../../components/shared/river-data-grid/river-data-grid.module.scss";
import { useTableCellRenderers } from "../../../hooks";

export class OracleEbsExternalResourcesUiService extends ExternalResourcesUiService {
  craftsArrayKey = "Resources";

  getTableParams = (
    onExternalResourceClick: (formatterProps: FormatterProps<any>) => void,
    t: TFunction
  ): IUseEntityTableParams => {
    const { renderCell, renderCellText } = useTableCellRenderers();
    return {
      entityName: "external_resource",
      columns: [
        {
          key: "INSTANCE_NAME",
          name: t("entity.external_resource:external_resource.INSTANCE_NAME"),
          formatter: (formatterProps: any) => (
            <>
              {renderCell({
                formatterProps,
                content: (
                  <div
                    className={dataGridStyles.dataGridLink}
                    onClick={() => {
                      onExternalResourceClick(formatterProps);
                    }}
                  >
                    {renderCellText({ formatterProps })}
                  </div>
                ),
              })}
            </>
          ),
        },
        {
          key: "Resources.RESOURCE_CODE",
          name: t("entity.resource_ref:resource_ref.RESOURCE_CODE"),
        },
        {
          key: "Agreement",
          name: t("entity.external_resource:external_resource.Agreement"),
        },
      ],
    };
  };

  renderExternalResourceDialog = (
    props: IExternalResourceDialogProps
  ): ReactElement => <OracleEbsExternalResourceDialog {...props} />;

  getErpSpecificI18nNamespaces(): string[] {
    return ["entity.department_resource", "entity.resource_ref"];
  }
}
