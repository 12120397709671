import { FC } from "react";
import { useTranslation } from "@river/common-ui";
import { PageHeader } from "../../page-header";

export const CronTasksHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <PageHeader
      pageTitle={t("module.cron:module.title")}
      pageTitleLink="/cron-tasks"
    />
  );
};
