import { SitesUiService } from "../sites-ui.service";
import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { IUserContextSite } from "../../context";

export class JdeSitesUiService extends SitesUiService {
  protected getSiteEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "business_unit",
      columns: [
        {
          key: "F0006_MCU",
          name: t("entity.business_unit:business_unit.F0006_MCU"),
        },
        {
          key: "F0006_DL01",
          name: t("entity.business_unit:business_unit.F0006_DL01"),
        },
        {
          key: "F0006_STYL",
          name: t("entity.business_unit:business_unit.F0006_STYL"),
        },
        {
          key: "F0006_CO",
          name: t("entity.business_unit:business_unit.F0006_CO"),
        },
      ],
    };
  };

  getSitePropertyFromErpRecord = (siteRec: any): IUserContextSite => {
    if (!siteRec) {
      return {
        site_id: "",
        site_code: "",
        description: "",
      };
    }
    const { F0006_MCU, F0006_DL01 } = siteRec;
    return {
      site_id: F0006_MCU,
      site_code: F0006_MCU,
      description: F0006_DL01,
    };
  };

  protected getSiteRowKey = (siteRec: any): any => {
    return siteRec[this.getSiteKeyColumn()];
  };

  getSiteKeyColumn = (): string => "F0006_MCU";
}
