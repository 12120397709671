import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { SchedulesAction } from "../schedules";
import { ScheduleDialog } from "../schedule-dialog";
import { TableContext } from "../../../context";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { useTranslation } from "@river/common-ui";

export const SchedulesSubHeader: React.FC = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const [scheduleDialogOpened, setScheduleDialogOpened] =
    useState<boolean>(false);
  const openDialog = (): void => {
    setScheduleDialogOpened(true);
  };
  const closeDialog = (): void => setScheduleDialogOpened(false);
  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction
          module={ModuleKey.SCHEDULES}
          action={SchedulesAction.CREATE}
        >
          <PrimaryButton
            onClick={openDialog}
            text={t("module.schedules:button.create_schedule")}
          />
        </ProtectedAction>
      </div>
      {scheduleDialogOpened && (
        <ScheduleDialog
          open={scheduleDialogOpened}
          schedule={null}
          onClose={(success) => {
            closeDialog();
            if (success) tableContext?.table.fetch();
          }}
        />
      )}
    </>
  );
};
