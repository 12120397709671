// ------------------
import * as dateFns from "date-fns";

// Implements similar behaviour as in C# "format" function
export const parseSapDateTime = (dateValue: any, timeValue?: string): any => {
  if (dateValue instanceof Date) {
    return dateValue;
  } else if (dateValue && (dateValue as string).startsWith("/Date(")) {
    let newValue = new Date(parseInt(dateValue.substr(6)));

    //timezone correction
    //newValue = dateFns.addMinutes(newValue, newValue.getTimezoneOffset());

    if (timeValue) {
      const hms = parseSapTime(timeValue);
      newValue = dateFns.add(newValue, hms);
    }

    return newValue;
  }

  return dateValue;
};

// ------------------
export const parseSapTime = (value: string): dateFns.Duration => {
  const hms = value.split(/[PT,H,M,S]/).filter((s) => s);

  return {
    hours: parseInt(hms[0]),
    minutes: parseInt(hms[1]),
    seconds: parseInt(hms[2]),
  };
};

// -----------------
export const convertToSapDateTime = (
  date: Date
): { date: string; time: string } => {
  let datePart = new Date(date);

  const timePart = {
    hours: datePart.getUTCHours(),
    minutes: datePart.getUTCMinutes(),
    seconds: datePart.getUTCSeconds(),
  };
  datePart = dateFns.addHours(datePart, -timePart.hours);
  datePart = dateFns.addMinutes(datePart, -timePart.minutes);
  datePart = dateFns.addSeconds(datePart, -timePart.seconds);
  datePart = dateFns.addMilliseconds(datePart, -datePart.getUTCMilliseconds());

  return {
    date: `/Date(${datePart.getTime()})/`,
    time: `PT${timePart.hours.toString().padStart(2, "0")}H${timePart.minutes
      .toString()
      .padStart(2, "0")}M${timePart.seconds.toString().padStart(2, "0")}S`,
  };
};
