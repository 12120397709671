import { QueryAttributeDto } from "@river/interfaces";
import { Column } from "react-data-grid";
import { TFunction } from "react-i18next";
import { ILookupDefinition } from "../components/shared";
import { AdapterUiService } from "./adapter-ui.service";

export interface IGetPersonColumnsProps {
  t: TFunction;
  styles: { [className: string]: string };
}

export abstract class PersonUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}
  abstract getPersonColumns(): () => Column<any>[];

  usePersonLookup = (
    customFilters?: QueryAttributeDto[]
  ): (() => ILookupDefinition) => this.getPersonLookupDefinition(customFilters);

  protected abstract getPersonLookupDefinition(
    customFilters?: QueryAttributeDto[]
  ): () => ILookupDefinition;

  abstract getPersonKey(): string;
}
