import { ICustomerInfo } from "@river/interfaces";

// ------------------
export const extractSubdomain = (
  hostname: string,
  baseHostName: string
): string => {
  // eliminate "port" from the baseHostName (if exists)
  const validHost = baseHostName.split(":")[0];
  const index = hostname.indexOf(validHost);

  const subdomain = hostname.substring(0, index - 1);

  return subdomain;
};

// -----------------
export const parseSubdomain = (
  subdomain?: string
): { customerHandle: string; environmentHandle: string } => {
  //
  const parts = subdomain?.split("-");
  if (parts?.length !== 2) {
    throw new Error(`Invalid subdomain: ${subdomain}`);
  }

  return {
    customerHandle: parts[1],
    environmentHandle: parts[0],
  };
};

// -----------------
export const createSubdomain = (customerInfo: ICustomerInfo): string => {
  return `${customerInfo.environment.handle}-${customerInfo.customer.handle}`;
};
