import { useTranslation } from "@river/common-ui";
import {
  ScheduleGanttTimeOptionID,
  IScheduleGanttTimeOption,
  ScheduleGanttTimeOptionGroupID,
  IScheduleGanttTimeOptionGroup,
} from "./types";

export const useScheduleGanttTime = () => {
  const { t } = useTranslation();

  const ganttTimeOptions: {
    [id in ScheduleGanttTimeOptionID]: IScheduleGanttTimeOption;
  } = {
    [ScheduleGanttTimeOptionID._24HOURS]: {
      groupId: ScheduleGanttTimeOptionGroupID.HOURS,
      id: ScheduleGanttTimeOptionID._24HOURS,
      value: 24,
      label: t("shared.gantt:label._24hours"),
    },
    [ScheduleGanttTimeOptionID._48HOURS]: {
      groupId: ScheduleGanttTimeOptionGroupID.HOURS,
      id: ScheduleGanttTimeOptionID._48HOURS,
      value: 48,
      label: t("shared.gantt:label._48hours"),
    },
    [ScheduleGanttTimeOptionID._72HOURS]: {
      groupId: ScheduleGanttTimeOptionGroupID.HOURS,
      id: ScheduleGanttTimeOptionID._72HOURS,
      value: 72,
      label: t("shared.gantt:label._72hours"),
    },
    [ScheduleGanttTimeOptionID._7DAYS]: {
      groupId: ScheduleGanttTimeOptionGroupID.DAYS,
      id: ScheduleGanttTimeOptionID._7DAYS,
      value: 7,
      label: t("shared.gantt:label._7days"),
    },
    [ScheduleGanttTimeOptionID._14DAYS]: {
      groupId: ScheduleGanttTimeOptionGroupID.DAYS,
      id: ScheduleGanttTimeOptionID._14DAYS,
      value: 14,
      label: t("shared.gantt:label._14days"),
    },
    [ScheduleGanttTimeOptionID._4WEEKS]: {
      groupId: ScheduleGanttTimeOptionGroupID.WEEKS,
      id: ScheduleGanttTimeOptionID._4WEEKS,
      value: 4,
      label: t("shared.gantt:label._4weeks"),
    },
    [ScheduleGanttTimeOptionID._8WEEKS]: {
      groupId: ScheduleGanttTimeOptionGroupID.WEEKS,
      id: ScheduleGanttTimeOptionID._8WEEKS,
      value: 8,
      label: t("shared.gantt:label._8weeks"),
    },
    [ScheduleGanttTimeOptionID._3MONTHS]: {
      groupId: ScheduleGanttTimeOptionGroupID.MONTHS,
      id: ScheduleGanttTimeOptionID._3MONTHS,
      value: 3,
      label: t("shared.gantt:label._3months"),
    },
    [ScheduleGanttTimeOptionID._6MONTHS]: {
      groupId: ScheduleGanttTimeOptionGroupID.MONTHS,
      id: ScheduleGanttTimeOptionID._6MONTHS,
      value: 6,
      label: t("shared.gantt:label._6months"),
    },
    [ScheduleGanttTimeOptionID._12MONTHS]: {
      groupId: ScheduleGanttTimeOptionGroupID.MONTHS,
      id: ScheduleGanttTimeOptionID._12MONTHS,
      value: 12,
      label: t("shared.gantt:label._12months"),
    },
  };

  const ganttTimeOptionGroups: {
    [id in ScheduleGanttTimeOptionGroupID]: IScheduleGanttTimeOptionGroup;
  } = {
    [ScheduleGanttTimeOptionGroupID.HOURS]: {
      id: ScheduleGanttTimeOptionGroupID.HOURS,
      label: t("shared.gantt:label.hours"),
      options: [
        ganttTimeOptions[ScheduleGanttTimeOptionID._24HOURS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._48HOURS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._72HOURS],
      ],
    },
    [ScheduleGanttTimeOptionGroupID.DAYS]: {
      id: ScheduleGanttTimeOptionGroupID.DAYS,
      label: t("shared.gantt:label.days"),
      options: [
        ganttTimeOptions[ScheduleGanttTimeOptionID._7DAYS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._14DAYS],
      ],
    },
    [ScheduleGanttTimeOptionGroupID.WEEKS]: {
      id: ScheduleGanttTimeOptionGroupID.WEEKS,
      label: t("shared.gantt:label.weeks"),
      options: [
        ganttTimeOptions[ScheduleGanttTimeOptionID._4WEEKS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._8WEEKS],
      ],
    },
    [ScheduleGanttTimeOptionGroupID.MONTHS]: {
      id: ScheduleGanttTimeOptionGroupID.MONTHS,
      label: t("shared.gantt:label.months"),
      options: [
        ganttTimeOptions[ScheduleGanttTimeOptionID._3MONTHS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._6MONTHS],
        ganttTimeOptions[ScheduleGanttTimeOptionID._12MONTHS],
      ],
    },
  };
  return {
    ganttTimeOptions,
    ganttTimeOptionGroups,
  };
};

export type IUseScheduleGanttTime = ReturnType<typeof useScheduleGanttTime>;
