import { TFunction } from "i18next";
import { IUseEntityTableParams } from "../ui-service.types";
import { UsersUiService } from "../users-ui.service";

export class OracleCloudUsersUiService extends UsersUiService {
  protected getUserEntityTableParams = (
    t: TFunction
  ): IUseEntityTableParams => {
    return {
      entityName: "user",
      columns: [
        { key: "Username", name: t("entity.user:user.Username") },
        { key: "Displayname", name: t("entity.user:user.Displayname") },
        { key: "Emailaddress", name: t("entity.user:user.Emailaddress") },
        { key: "Jobtitle", name: t("entity.user:user.Jobtitle") },
        { key: "Orgunitname", name: t("entity.user:user.Orgunitname") },
      ],
    };
  };

  getUserNameKey = () => "Username";

  getErpSpecificI18nNamespaces = () => [];
}
