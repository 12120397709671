import { useContext } from "react";

import { QueryAttributeDto } from "@river/interfaces";

import { ILookupDefinition } from "../../lookup-dialog";
import {
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../../../../../context";
import { PersonUiService } from "../../../../../../services";

export function usePersonLookupDef() {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: PersonUiService =
    adapterContext?.service!.getPersonUiService()!;

  return (customFilters?: QueryAttributeDto[]): ILookupDefinition =>
    uiService.usePersonLookup(customFilters)();
}

export type IUsePersonLookupDef = ReturnType<typeof usePersonLookupDef>;
