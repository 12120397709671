import {
  FC,
  useContext,
  ComponentType,
  HTMLAttributes,
  PropsWithChildren,
} from "react";
import { SidebarToggle } from "../sidebar";
import {
  ApplicationContext,
  IRiverDrawerContext,
  RiverDrawerContext,
  SidebarContext,
} from "../../context";
import { SiteSelector } from "../site-selector";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { UserInfo } from "./user-info";
import { Divider } from "@mui/material";
import { ModuleMenu } from "./module-menu";
import { HeaderLogo } from "./header-logo";
import { RiverHelpPopup } from "../shared";
import styles from "./page-header.module.scss";
import clsx from "clsx";

interface IPageHeaderProps extends PropsWithChildren {
  pageIcon?: FC;
  pageTitle: string;
  pageTitleLink?: string;
  classes?: {
    root?: string;
    sidebarToggle?: string;
    pageIcon?: string;
    pageTitle?: string;
    plantButton?: string;
    environmentInfo?: string;
  };
  showSiteSelector?: boolean;
  siteSelectorDisabled?: boolean;
  logo?: ComponentType<any>;
  logoSrc?: string;
  hideSidebarToggle?: boolean;
}

const VerticalDivider = () => (
  <Divider orientation="vertical" variant="middle" className={styles.divider} />
);

export const PageHeader: FC<IPageHeaderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const applicationContext = useContext(ApplicationContext);
  const sidebarContext = useContext(SidebarContext);
  const { drawer }: IRiverDrawerContext = useContext(RiverDrawerContext)!;
  const { drawerOpened, drawerWidth } = drawer;

  const PageIcon: FC<HTMLAttributes<any>> | undefined = props.pageIcon;

  const renderSidebarToggle = () => (
    <>
      {sidebarContext?.collapsed && (
        <SidebarToggle className={props.classes?.sidebarToggle} />
      )}
    </>
  );

  const renderPageIcon = () => (
    <>
      {PageIcon && (
        <PageIcon
          className={clsx([styles.pageIcon, props.classes?.pageIcon])}
        />
      )}
    </>
  );

  const renderEnvironmentInfo = () => {
    return (
      <span
        className={clsx([
          styles.environmentInfo,
          props.classes?.environmentInfo,
        ])}
      >
        {applicationContext?.customerInfo?.customer?.description} (
        {applicationContext?.customerInfo?.environment?.handle})
      </span>
    );
  };

  const renderPageTitle = () => {
    const isLinked: boolean =
      !!props.pageTitleLink && location.pathname !== props.pageTitleLink;
    return (
      <span
        className={clsx([
          styles.pageTitle,
          props.classes?.pageTitle,
          { [styles.linked]: isLinked },
        ])}
        onClick={() => {
          isLinked && navigate(props.pageTitleLink!);
        }}
      >
        {props.pageTitle}
      </span>
    );
  };

  return (
    <div
      className={clsx([styles.root, props.classes?.root])}
      style={{
        paddingRight: drawerOpened ? `${drawerWidth}px` : 0,
      }}
    >
      <div className={styles.titleSection}>
        {!props?.hideSidebarToggle && renderSidebarToggle()}
        <ModuleMenu />
        <HeaderLogo />
        {renderEnvironmentInfo()}
        <VerticalDivider />
        {renderPageIcon()}
        {renderPageTitle()}
      </div>

      <div className={styles.contentSection}>{props.children}</div>
      {(props.showSiteSelector ?? true) && (
        <>
          <SiteSelector disabled={props.siteSelectorDisabled} />
          <Divider
            orientation="vertical"
            variant="middle"
            className={styles.infoDivider}
          />
        </>
      )}
      <RiverHelpPopup />
      <Divider
        orientation="vertical"
        variant="middle"
        className={styles.infoDivider}
      />
      <UserInfo />
    </div>
  );
};
