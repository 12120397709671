import { RiverSpinner } from "@river/common-ui";
import { useContext, FC } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  TableContext,
} from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import { useEntity, useTableCellRenderers } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useTable,
} from "../shared";
import { SupervisorCrewsGridHeader } from "./supervisor-crews-grid-header";
import { SupervisorCrewsHeader } from "./supervisor-crews-header";
import dataGridStyles from "../../components/shared/river-data-grid/river-data-grid.module.scss";
import { AdapterCrewDto } from "@river/interfaces";
import { useNavigate } from "react-router";

export const SupervisorCrews: FC = () => {
  const entityName: string = "crew";
  const { t } = useTranslation();
  const { renderCell, renderCellText } = useTableCellRenderers();
  const adapterContext = useContext(AdapterUiContext);
  const userContext = useContext(AdapterUserContext);
  const navigate = useNavigate();
  const site = userContext?.userProperties[AdapterUserContextProp.SITE];

  function openCrewAvailability(rowData: AdapterCrewDto) {
    navigate(`/supervisor/crews/${rowData.crew}`);
  }

  const entity = useEntity({
    entityName,
  });

  const columns: Column<any>[] = [
    {
      key: "crew",
      name: t("entity.crew:crew.crew"),
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  openCrewAvailability(formatterProps.row);
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "description", name: t("entity.crew:crew.description") },
    {
      key: "supervisors.display_name",
      name: t("entity.crew_supervisor_ref:crew_supervisor_ref.display_name"),
    },
  ];

  const fetchFunction = async (fetchProps: ITableFetchFunctionProps) => {
    const result =
      (await adapterContext?.service.getAdapterService().fetchCrews(
        fetchHelpers.getTableQuery({ fetchProps })
        //userContext?.user.user_id
      )) || [];
    return result;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: "supervisor.crews",
    columns,
    fetchFunction,
    dependencies: [!!site],
    fetchOn: true,
    infiniteScrolling: true,
    rowKeyGetter: (row) => row[uiConstants.fields._id] as string,
  });

  const isLoading: boolean = table.isLoading || !site;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <SupervisorCrewsHeader />
      <SupervisorCrewsGridHeader />
      <RiverSpinner show={isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={false}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={(row) => row[uiConstants.fields._id]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
    </TableContext.Provider>
  );
};
