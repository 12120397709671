import { FC, ReactElement, useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../../context";
import {
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { uiConstants } from "../../../../helpers";
import { RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";
import { TableUiService } from "../../../../services";
import {
  useJdeOperationUpdateForm,
  JdeOperationMassUpdate,
} from "./use-jde-op-mass-update-form";
import { LookupType, RiverLookup } from "../../../../components/shared";

export interface IOpMassUpdateDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

export const JdeOpMassUpdateDialog: FC<IOpMassUpdateDialogProps> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();
  const notify = useNotification();
  const { t } = useTranslation();

  const form: RiverFormInstance = useJdeOperationUpdateForm({
    submit: async () => {
      const updatedRows = await submitMassUpdate();
      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, uiConstants.rowType.operation),
          updatedRow,
        })),
        unselectRows: false,
      });
    },
  });

  const {
    onStandaloneLookupSelect,
    submit,
    isProcessing,
    setStandaloneFields,
    resetForm,
  } = form;

  const massUpdateFields = form.standalone as JdeOperationMassUpdate;

  const resetDialogState = (): void => {
    resetForm();
  };

  const closeDialog = (success: boolean) => {
    resetDialogState();
    tableContext?.table?.setSelectedRowIds(new Set());
    props.onClose(success);
  };

  const submitMassUpdate = async (): Promise<any[]> => {
    const updateObject: { [key: string]: any } = {};
    Object.keys(massUpdateFields).forEach((prop) => {
      // @ts-ignore
      const val: any = massUpdateFields[prop];
      if (val !== "") {
        updateObject[prop] = val;
      }
    });
    const promises: Promise<any>[] = [];
    const errors: any[] = [];
    tableContext?.table.selectedRowIds.forEach((operationId: string) => {
      const workOrderId: string = tableContext?.table.entities.filter(
        (row) => row[uiConstants.fields.rowId] === operationId
      )[0][uiConstants.fields._id] as string;
      promises.push(
        adapterContext!.service
          .getAdapterService()
          .updateEntityData("operation", workOrderId, {
            ...updateObject,
          })
          .catch((err) => {
            errors.push(err);
          })
      );
    });
    return Promise.all(promises).finally(() => {
      if (errors.length) {
        notify.error({
          title: t(
            "shared.op_mass_update_dialog:notification.mass_update_failed"
          ),
          message: errors[0],
        });
      } else {
        const success = true;
        closeDialog(success);
      }
    }) as Promise<any>;
  };

  const renderOperationStatusField = (): ReactElement => (
    <RiverLookup
      id="F3112_OPST"
      fullWidth
      lookup={{
        type: LookupType.JDE_USER_DEFINED_CODE,
        customFilters: [
          {
            attribute_name: "F0005_SY",
            attribute_value: { operator: "$eq", value: "31" },
          },
          {
            attribute_name: "F0005_RT",
            attribute_value: { operator: "$eq", value: "OS" },
          },
        ],
      }}
      onSelect={(selectedStatus) => {
        const value = (selectedStatus?.F0005_KY ?? "") as string;
        setStandaloneFields({
          ...massUpdateFields,
          F3112_OPST: value.trim(),
        });
      }}
    />
  );

  const renderAddressNumberField = (): ReactElement => (
    <RiverLookup
      id="F3112_AN8"
      fullWidth
      lookup={{ type: LookupType.JDE_ADDRESS_BOOK }}
      onSelect={onStandaloneLookupSelect("F0101_AN8", "F3112_AN8")}
    />
  );

  const renderLaborHoursField = (): ReactElement => (
    <RiverTextInput
      id="F3112_RUNL"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderMachineHoursField = (): ReactElement => (
    <RiverTextInput
      id="F3112_RUNM"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderSetupHoursField = (): ReactElement => (
    <RiverTextInput
      id="F3112_SETL"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderQueueHoursField = (): ReactElement => (
    <RiverTextInput
      id="F3112_QUED"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderCrewSizeField = (): ReactElement => (
    <RiverTextInput
      id="F3112_SETC"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderMoveHoursField = (): ReactElement => (
    <RiverTextInput
      id="F3112_MOVD"
      fullWidth
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  return (
    <RiverDialog
      title={t("shared.op_mass_update_dialog:title")}
      open={props.open}
      onClose={() => {
        closeDialog(false);
      }}
      actionButtonText={t("common.button:update")}
      showActionsDivider={false}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderOperationStatusField()}
      {renderLaborHoursField()}
      {renderMachineHoursField()}
      {renderSetupHoursField()}
      {renderQueueHoursField()}
      {renderMoveHoursField()}
      {renderCrewSizeField()}
      {renderAddressNumberField()}
    </RiverDialog>
  );
};
