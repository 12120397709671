import { FC, ReactElement, useContext } from "react";
import { IAdapterRule } from "@river/interfaces";
import {
  DeleteAction,
  FilterDropdown,
  GridActions,
  GridHeader,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import {
  useNotification,
  useSimpleDialog,
  useTranslation,
} from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { AttachmentSelectionRulesAction } from "../attachment-selection-rules";

interface IAttachmentSelectionRulesGridHeaderProps {
  className?: string;
  selectedRule: IAdapterRule;
}

export const AttachmentSelectionRulesGridHeader: FC<
  IAttachmentSelectionRulesGridHeaderProps
> = (props): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const deleteSelectedRule = async (): Promise<any> => {
    if (props.selectedRule) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteRule(props.selectedRule._id);
        tableContext?.table.fetch();
        deleteRuleConfirmation.close();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteRuleConfirmation = useSimpleDialog({
    title: t("module.attachment-selection-rules:message.delete_rule.title"),
    message: t(
      "module.attachment-selection-rules:message.confirm_rule_deletion"
    ),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedRule,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.ATTACHMENT_SELECTION_RULES}
            action={AttachmentSelectionRulesAction.DELETE}
          >
            <DeleteAction onClick={() => deleteRuleConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteRuleConfirmation.render()}
    </>
  );
};
