import React, { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import SyncIcon from "@mui/icons-material/Sync";
import { AdapterService } from "../../adapter.service";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useNotification } from "@river/common-ui";

export const useSynchronizeWorkOrdersAction = () => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const notify = useNotification();

  const title: string = t(
    "module.backlog:action_label.synchronize_work_orders"
  );

  const onClick = (): void => {
    tableContext?.table.forceLoadingState(true);
    adapterService
      .synchronizeWorkorders()
      .then(() => {
        tableContext?.table.refresh();
        notify.success(
          t("module.backlog:notification.synchronize_work_orders_success")
        );
      })
      .catch((error) => {
        notify.error(error.response.data.message);
      })
      .finally(() => {
        tableContext?.table.forceLoadingState(false);
      });
  };

  const renderSynchronizeWorkOrdersAction = (
    disableOnClickEvent?: boolean
  ): ReactElement => (
    <GenericAction
      icon={SyncIcon}
      title={title}
      onClick={() => {
        if (!disableOnClickEvent) {
          onClick();
        }
      }}
    />
  );

  return {
    renderSynchronizeWorkOrdersAction,
    synchronizeWorkOrdersAction: {
      icon: (disableOnClickEvent?: boolean) =>
        renderSynchronizeWorkOrdersAction(disableOnClickEvent),
      title,
      onClick,
    },
  };
};

export type IUseSynchronizeWorkOrdersAction = ReturnType<
  typeof useSynchronizeWorkOrdersAction
>;
