import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUiContextState,
  IAdapterUserContext,
} from "../../../../../context";
import { SapAdapterService } from "../../../../../services/sap";
import { useTranslation } from "@river/common-ui";

export const useWbsElementLookupDef = () => {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();

  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    return {
      title: t("shared.lookup:wbs.title"),
      rowKey: "WBSElementInternalID",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "WBSElementExternalID",
            name: t("entity.wbs:wbs.WBSElementExternalID"),
            width: 150,
          },
          { key: "WBSDescription", name: t("entity.wbs:wbs.WBSDescription") },
          {
            key: "ProjectExternalID",
            name: t("entity.wbs:wbs.ProjectExternalID"),
          },
          {
            key: "ResponsiblePersonName",
            name: t("entity.wbs:wbs.ResponsiblePersonName"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as SapAdapterService
          ).fetchWbsElements(fetchHelpers.getTableQuery({ fetchProps }));
        },
        dependencies: [],
        saveKey: "wbs_elements.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "wbs",
      },
    };
  };
};

export type IUseWbsElementLookupDef = ReturnType<typeof useWbsElementLookupDef>;
