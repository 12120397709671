import { SupervisorScheduleGanttTimeOptionGroupID } from "../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";

export const getGanttStart = (
  selectedDate: Date,
  timeGroupId: SupervisorScheduleGanttTimeOptionGroupID,
  scheduleStartDate?: Date
): Date => {
  const ganttStartDate = new Date(selectedDate);
  switch (timeGroupId) {
    case SupervisorScheduleGanttTimeOptionGroupID.DAYS:
    case SupervisorScheduleGanttTimeOptionGroupID.WEEKS:
      const scheduleStartDateValue = scheduleStartDate?.getUTCDay();
      let minusDays;

      if (!!scheduleStartDateValue) {
        if (ganttStartDate.getUTCDay() >= scheduleStartDateValue) {
          minusDays = ganttStartDate.getUTCDay() - scheduleStartDateValue;
        } else {
          minusDays = 7 - (scheduleStartDateValue - ganttStartDate.getUTCDay());
        }
      } else {
        minusDays = ganttStartDate.getUTCDay();
      }

      ganttStartDate.setUTCDate(ganttStartDate.getUTCDate() - minusDays);

      return ganttStartDate;
    case SupervisorScheduleGanttTimeOptionGroupID.MONTHS:
      ganttStartDate.setUTCDate(1);
      return ganttStartDate;
  }
  return ganttStartDate;
};
