import React, { ReactElement, useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { Collapse } from "@mui/material";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleUtilizationContext,
} from "../../../../context";
import {
  IScheduleProgressFilters,
  SupervisorProgressSummaryFilterID,
  userPreferencesService,
} from "../../../../services";
import {
  OracleEbsSupervisorScheduleProgressWOTypeFilters,
  OracleEbsSupervisorScheduleProgressDepartmentFilters,
  OracleEbsSupervisorScheduleProgressResourceFilters,
} from "./filters";
import { ChartFiltersContainer } from "../../../../components/schedule/schedule-utilization/chart-filters/chart-filters-container/chart-filters-container";

export interface IOracleEbsSupervisorScheduleProgressFiltersDto
  extends IScheduleProgressFilters {
  [SupervisorProgressSummaryFilterID.WO_TYPES]: any[];
  [SupervisorProgressSummaryFilterID.DEPARTMENTS]: any[];
  [SupervisorProgressSummaryFilterID.RESOURCES]: any[];
}

export const OracleEbsSupervisorScheduleProgressFilters: React.FC =
  (): ReactElement => {
    const adapterContext: IAdapterUiContextState | null =
      useContext(AdapterUiContext);
    const [loading, setLoading] = useState<boolean>(false);
    const notify = useNotification();
    const supervisorScheduleUtilizationContext = useContext(
      SupervisorScheduleUtilizationContext
    );
    const {
      progressFiltersOpened,
      progressSummaryFilters,
      setProgressSummaryFilters,
    } = supervisorScheduleUtilizationContext!;
    const fetchProgressSummaryFilters = async () => {
      try {
        setLoading(true);
        const result: IOracleEbsSupervisorScheduleProgressFiltersDto =
          await userPreferencesService.getScheduleProgressFilters(
            adapterContext!.service.getAdapterService()
          );
        if (result) {
          setProgressSummaryFilters(result);
        }
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    const onDeleteProgressSummaryFilter = async (
      selectedObject: any,
      filterId: SupervisorProgressSummaryFilterID
    ) => {
      let columnId: string;
      if (filterId === SupervisorProgressSummaryFilterID.WO_TYPES) {
        columnId = "LOOKUP_CODE";
      } else if (filterId === SupervisorProgressSummaryFilterID.DEPARTMENTS) {
        columnId = "DEPARTMENT_ID";
      } else {
        columnId = "RESOURCE_ID";
      }

      const newDto: IOracleEbsSupervisorScheduleProgressFiltersDto = {
        ...(progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto),
        [filterId]: (progressSummaryFilters as any)[filterId]?.filter(
          (selected: any) => selected[columnId] !== selectedObject[columnId]
        ),
      };

      try {
        setLoading(true);
        await userPreferencesService.setScheduleProgressFilters(
          adapterContext!.service.getAdapterService(),
          newDto
        );
        setProgressSummaryFilters(newDto);
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    const onSelectProgressSummaryFilters = async (
      selectedObjects: any[],
      filterId: SupervisorProgressSummaryFilterID
    ) => {
      try {
        const payload: IOracleEbsSupervisorScheduleProgressFiltersDto = {
          ...(progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto),
          [filterId]: [
            ...(progressSummaryFilters as any)[filterId]!,
            ...selectedObjects,
          ],
        };
        setLoading(true);
        await userPreferencesService.setScheduleProgressFilters(
          adapterContext!.service.getAdapterService(),
          payload
        );
        setProgressSummaryFilters(payload);
      } catch (message) {
        notify.error({ message });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchProgressSummaryFilters();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderWOTypeFilters = () => (
      <OracleEbsSupervisorScheduleProgressWOTypeFilters
        rows={
          (
            progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
          )[SupervisorProgressSummaryFilterID.WO_TYPES]
        }
        onSelect={onSelectProgressSummaryFilters}
        onDelete={onDeleteProgressSummaryFilter}
      />
    );

    const renderDepartmentFilters = (): ReactElement => (
      <OracleEbsSupervisorScheduleProgressDepartmentFilters
        rows={
          (
            progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
          )[SupervisorProgressSummaryFilterID.DEPARTMENTS]
        }
        onSelect={onSelectProgressSummaryFilters}
        onDelete={onDeleteProgressSummaryFilter}
      />
    );

    const renderResourceFilters = (): ReactElement => (
      <OracleEbsSupervisorScheduleProgressResourceFilters
        rows={
          (
            progressSummaryFilters as IOracleEbsSupervisorScheduleProgressFiltersDto
          )[SupervisorProgressSummaryFilterID.RESOURCES]
        }
        onSelect={onSelectProgressSummaryFilters}
        onDelete={onDeleteProgressSummaryFilter}
      />
    );

    return (
      <Collapse
        in={progressFiltersOpened}
        orientation={"horizontal"}
        timeout={300}
      >
        <ChartFiltersContainer>
          {renderWOTypeFilters()}
          {renderDepartmentFilters()}
          {renderResourceFilters()}
          <RiverSpinner show={loading} />
        </ChartFiltersContainer>
      </Collapse>
    );
  };
