import { IUseEntityTable } from "../ui-service.types";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import {
  SupervisorScheduleUtilizationUiService,
  SupervisorScheduleUtilizationTabId,
} from "./supervisor-schedule-utilization-ui-service";
import { IAdapterFolder } from "@river/interfaces";
import {
  IUseTable,
  TableFetchFunction,
  useTable,
} from "../../components/shared";
import { getBlankEntity } from "../../hooks";
import { useNotification, useTranslation } from "@river/common-ui";

enum MetricCallID {
  SCHEDULED_WO_COMPLETED = "SCHEDULED_WO_COMPLETED",
  TOTAL_RETURNED_BREAK_INS = "TOTAL_RETURNED_BREAK_INS",
  TOTAL_RETURNED_WO = "TOTAL_RETURNED_WO",
  TOTAL_SCHEDULED_HRS = "TOTAL_SCHEDULED_HRS",
  TOTAL_ACTUAL_HRS = "TOTAL_ACTUAL_HRS",
}

interface MetricCallStatus {
  success: boolean;
  error?: any;
}

interface MetricResult<MetricCallID> {
  id: MetricCallID;
  status: MetricCallStatus;
  value?: any;
}

type ScheduledWOCompletedResult =
  MetricResult<MetricCallID.SCHEDULED_WO_COMPLETED>;
type TotalReturnedBreakInsResult =
  MetricResult<MetricCallID.TOTAL_RETURNED_BREAK_INS>;
type TotalReturnedWOResult = MetricResult<MetricCallID.TOTAL_RETURNED_WO>;
type TotalScheduledHrsResult = MetricResult<MetricCallID.TOTAL_SCHEDULED_HRS>;
type TotalActualHrsResult = MetricResult<MetricCallID.TOTAL_ACTUAL_HRS>;
type MetricsResultsMap = {
  [callID in MetricCallID]?: MetricResult<MetricCallID>;
};

enum MetricID {
  WO_COMPLETED = "WO_COMPLETED",
  WO_BREAK_IN = "WO_BREAK_IN",
  WO_SENT_BACK = "WO_SENT_BACK",
  SCHEDULED_HOURS = "SCHEDULED_HOURS",
  ACTUAL_HOURS = "ACTUAL_HOURS",
}

export interface IMetric {
  id: MetricID;
  title: string;
  value: number;
  description: string;
  color?: string;
}

interface IMetricDefinition {
  id: MetricID;
  success: boolean;
  getTitle(): string;
  getValue(): number;
  getDescription(): string;
  getColor?(): string;
}

type MetricDefinitions = { [metricID in MetricID]: IMetricDefinition };

export const useSupervisorScheduleUtilizationMetrics = (): IUseEntityTable => {
  const { t } = useTranslation();
  const notify = useNotification();
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService = adapterContext!.service.getAdapterService();
  const uiService: SupervisorScheduleUtilizationUiService =
    adapterContext?.service.getSupervisorScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: SupervisorScheduleUtilizationTabId =
    uiService.getCurrentTab()();
  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  function getPercentage(value: number, ofValue: number) {
    const calc = Number((value * 100) / ofValue);
    return isNaN(calc) ? Number(0).toFixed(2) : calc.toFixed(2);
  }

  const getWOCompletedMetricDefinition = (
    resultsMap: MetricsResultsMap
  ): IMetricDefinition => {
    const result: MetricResult<any> =
      resultsMap[MetricCallID.SCHEDULED_WO_COMPLETED]!;
    const woCompleted: number = result?.value?.completed_work_orders ?? 0;
    const woScheduled: number = result?.value?.scheduled_work_orders ?? 0;

    return {
      id: MetricID.WO_COMPLETED,
      success: !!result?.status.success,
      getTitle: () =>
        t(
          "module.supervisor_schedule:metrics.title.scheduled_work_orders_completed"
        ),
      getDescription: () =>
        t(
          "module.supervisor_schedule:metrics.description.scheduled_work_orders_completed",
          { percentage: getPercentage(woCompleted, woScheduled) }
        ),
      getValue: () => woCompleted,
    };
  };

  const getWOBreakInMetricDefinition = (
    resultsMap: MetricsResultsMap
  ): IMetricDefinition => {
    const result: MetricResult<any> =
      resultsMap[MetricCallID.TOTAL_RETURNED_BREAK_INS]!;
    const woBreakIns: number = result?.value?.break_in_work_orders ?? 0;
    return {
      id: MetricID.WO_BREAK_IN,
      success: !!result?.status.success,
      getTitle: () =>
        t("module.supervisor_schedule:metrics.title.break_in_work_orders"),
      getDescription: () =>
        t(
          "module.supervisor_schedule:metrics.description.break_in_work_orders",
          {
            percentage: getPercentage(
              woBreakIns,
              currentSchedule!.workorder_count ?? 0
            ),
          }
        ),
      getValue: () => woBreakIns,
    };
  };

  const getWOSentBackMetricDefinition = (
    resultsMap: MetricsResultsMap
  ): IMetricDefinition => {
    const result: MetricResult<any> =
      resultsMap[MetricCallID.TOTAL_RETURNED_WO]!;
    const woSentBack: number = result?.value?.returned_work_orders ?? 0;
    return {
      id: MetricID.WO_SENT_BACK,
      success: !!result?.status.success,
      getTitle: () =>
        t("module.supervisor_schedule:metrics.title.work_orders_sent_back"),
      getDescription: () =>
        t(
          "module.supervisor_schedule:metrics.description.work_orders_sent_back",
          {
            percentage: getPercentage(
              woSentBack,
              currentSchedule!.workorder_count ?? 0
            ),
          }
        ),
      getValue: () => woSentBack,
    };
  };

  const getScheduledHoursMetricDefinition = (
    resultsMap: MetricsResultsMap
  ): IMetricDefinition => {
    const result: MetricResult<any> =
      resultsMap[MetricCallID.TOTAL_SCHEDULED_HRS]!;
    const totalScheduledHours: number =
      result?.value?.total_hours_scheduled ?? 0;
    return {
      id: MetricID.SCHEDULED_HOURS,
      success: !!result?.status.success,
      getTitle: () =>
        t("module.supervisor_schedule:metrics.title.total_scheduled_hours"),
      getDescription: () =>
        t(
          "module.supervisor_schedule:metrics.description.total_scheduled_hours",
          {
            percentage: getPercentage(
              totalScheduledHours,
              currentSchedule!.duration ?? 0
            ),
          }
        ),
      getValue: () => totalScheduledHours,
    };
  };

  const getActualHoursMetricDefinition = (
    resultsMap: MetricsResultsMap
  ): IMetricDefinition => {
    const result: MetricResult<any> =
      resultsMap[MetricCallID.TOTAL_ACTUAL_HRS]!;
    const totalActualHours: number = result?.value?.total_hours_reported ?? 0;
    return {
      id: MetricID.ACTUAL_HOURS,
      success: !!result?.status.success,
      getTitle: () =>
        t("module.supervisor_schedule:metrics.title.total_actual_hours"),
      getDescription: () =>
        t("module.supervisor_schedule:metrics.description.total_actual_hours", {
          percentage: getPercentage(
            totalActualHours,
            currentSchedule!.duration ?? 0
          ),
        }),
      getValue: () => totalActualHours,
    };
  };

  const getMetricDefinitions = (
    resultsMap: MetricsResultsMap
  ): MetricDefinitions => ({
    [MetricID.WO_COMPLETED]: getWOCompletedMetricDefinition(resultsMap),
    [MetricID.WO_BREAK_IN]: getWOBreakInMetricDefinition(resultsMap),
    [MetricID.WO_SENT_BACK]: getWOSentBackMetricDefinition(resultsMap),
    [MetricID.SCHEDULED_HOURS]: getScheduledHoursMetricDefinition(resultsMap),
    [MetricID.ACTUAL_HOURS]: getActualHoursMetricDefinition(resultsMap),
  });

  const fetchCompletedWorkOrders = (): Promise<ScheduledWOCompletedResult> =>
    adapterService
      .getScheduledWorkOrdersCompleted(currentSchedule._id)
      .then((value) => {
        return {
          id: MetricCallID.SCHEDULED_WO_COMPLETED,
          status: { success: true },
          value,
        } as ScheduledWOCompletedResult;
      })
      .catch((error) => {
        return {
          id: MetricCallID.SCHEDULED_WO_COMPLETED,
          status: { success: false, error },
        } as ScheduledWOCompletedResult;
      });

  const fetchTotalReturnedBreakIns = (): Promise<TotalReturnedBreakInsResult> =>
    adapterService
      .getTotalReturnedBreakIns(currentSchedule._id)
      .then((value) => {
        return {
          id: MetricCallID.TOTAL_RETURNED_BREAK_INS,
          status: { success: true },
          value,
        } as TotalReturnedBreakInsResult;
      })
      .catch((error) => {
        return {
          id: MetricCallID.TOTAL_RETURNED_BREAK_INS,
          status: { success: false, error },
        } as TotalReturnedBreakInsResult;
      });

  const fetchTotalReturnedWorkOrders = (): Promise<TotalReturnedWOResult> =>
    adapterService
      .getTotalReturnedWorkorders(currentSchedule._id)
      .then((value) => {
        return {
          id: MetricCallID.TOTAL_RETURNED_WO,
          status: { success: true },
          value,
        } as TotalReturnedWOResult;
      })
      .catch((error) => {
        return {
          id: MetricCallID.TOTAL_RETURNED_WO,
          status: { success: false, error },
        } as TotalReturnedWOResult;
      });

  const fetchTotalScheduledHours = (): Promise<TotalScheduledHrsResult> =>
    adapterService
      .getTotalScheduledHours(currentSchedule._id)
      .then((value) => {
        return {
          id: MetricCallID.TOTAL_SCHEDULED_HRS,
          status: { success: true },
          value,
        } as TotalScheduledHrsResult;
      })
      .catch((error) => {
        return {
          id: MetricCallID.TOTAL_SCHEDULED_HRS,
          status: { success: false, error },
        } as TotalScheduledHrsResult;
      });

  const fetchTotalActualHours = (): Promise<TotalActualHrsResult> =>
    adapterService
      .getTotalScheduledHours(currentSchedule._id)
      .then((value) => {
        return {
          id: MetricCallID.TOTAL_ACTUAL_HRS,
          status: { success: true },
          value,
        } as TotalActualHrsResult;
      })
      .catch((error) => {
        return {
          id: MetricCallID.TOTAL_ACTUAL_HRS,
          status: { success: false, error },
        } as TotalActualHrsResult;
      });

  const generateMetrics = (resultsMap: MetricsResultsMap): IMetric[] => {
    const metricsToGenerate: MetricID[] = [
      MetricID.WO_COMPLETED,
      /*MetricID.WO_BREAK_IN,*/ // temporarily commented out as per Dejan's request
      MetricID.WO_SENT_BACK,
      MetricID.SCHEDULED_HOURS,
      MetricID.ACTUAL_HOURS,
    ];

    const metricDefinitions: MetricDefinitions =
      getMetricDefinitions(resultsMap);
    const generatedMetrics: IMetric[] = [];
    const failedMetrics: string[] = [];

    metricsToGenerate.forEach((metricId: MetricID) => {
      const def: IMetricDefinition = metricDefinitions[metricId];
      const metric: IMetric = {
        id: def.id,
        title: def.getTitle(),
        value: Math.round(def.getValue() * 100) / 100,
        description: def.getDescription(),
        color: def.getColor?.(),
      };
      if (def.success) {
        generatedMetrics.push(metric);
      } else {
        failedMetrics.push(metric.title);
      }
    });
    if (failedMetrics.length) {
      notify.error(
        t("module.supervisor_schedule:metrics.failed_generating", {
          metric_names: failedMetrics.join(", "),
        })
      );
    }

    return generatedMetrics;
  };

  const fetchFunction: TableFetchFunction<any> = async (): Promise<
    IMetric[]
  > => {
    const promises = [
      fetchCompletedWorkOrders(),
      fetchTotalReturnedBreakIns(),
      fetchTotalReturnedWorkOrders(),
      fetchTotalScheduledHours(),
      fetchTotalActualHours(),
    ];

    const results = await Promise.all(promises);

    const resultsMap: MetricsResultsMap = {};
    results.forEach((result: MetricResult<MetricCallID>) => {
      resultsMap[result.id] = result;
    });

    return generateMetrics(resultsMap);
  };

  const table: IUseTable = useTable({
    columns: [],
    fetchFunction,
    dependencies: [
      !!site,
      currentTab === SupervisorScheduleUtilizationTabId.METRICS,
    ],
    fetchOn: currentTab === SupervisorScheduleUtilizationTabId.METRICS,
    fetchTriggers: [currentSchedule],
    useAdvancedFilters: false,
  });

  return {
    entity: getBlankEntity(),
    table,
  };
};

export type IUseSupervisorScheduleUtilizationMetrics = ReturnType<
  typeof useSupervisorScheduleUtilizationMetrics
>;
