import React, { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import "chart.js/auto"; //ChartJS to register its necessary elements
import { Pie, Bar } from "react-chartjs-2";
import { IEntityObject } from "@river/interfaces";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  IChart,
  IKPIParamState,
  SidebarContext,
} from "../../context";
import { RiverNativeSelect } from "@river/common-ui";
import { CalendarIcon } from "../../icons";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "@river/common-ui";
import styles from "./stats.module.scss";

export function Stats() {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const sideBarContext = useContext(SidebarContext);

  const [workCenters, setWorkCenters] = useState<IEntityObject[]>([]);
  const [resources, setResources] = useState<IEntityObject[]>([]);

  const loadOptions = async () => {
    try {
      const workCenters = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("workcenter", {});
      setWorkCenters(workCenters);

      const resources = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("workcenterlabor", {});
      setResources(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderChart = (chart: IChart, index: number) => {
    return (
      <div className={styles.chart} key={index}>
        <div className={styles.title}>{chart.title}</div>
        <div className={styles.chartContainer}>{renderChartData(chart)}</div>
      </div>
    );
  };

  const renderChartData = (chart: IChart) => {
    switch (chart.chart.type) {
      case "pie":
        return (
          <Pie<number, string>
            options={chart.chart.options}
            data={chart.chart.data as any}
          />
        );
      case "bar":
        return (
          <Bar<number, string>
            options={chart.chart.options}
            data={chart.chart.data as any}
          />
        );
      default:
        return null;
    }
  };

  const setKPIParam = (update: Partial<IKPIParamState>) => {
    sideBarContext?.setKPIParams({
      ...sideBarContext.kpiParams,
      ...update,
    });
  };

  return (
    <div className={styles.stats}>
      <div className={styles.sectionTitle}>
        <SettingsIcon />
        <span className={styles.title}>
          {t("component.sidebar:label.scheduling_params")}
        </span>
      </div>

      <div className={styles.params}>
        <div className={styles.paramRow}>
          <div className={styles.paramLabel}>
            {t("component.sidebar:label.start_date")}
          </div>
          <input
            type="date"
            className={styles.paramValue}
            value={format(
              sideBarContext?.kpiParams.startDate ?? new Date(),
              "yyyy-MM-dd"
            )}
            onChange={(e) =>
              setKPIParam({
                startDate: new Date(Date.parse(e.target.value)),
              })
            }
          />
        </div>

        <div className={styles.paramRow}>
          <div className={styles.paramLabel}>
            {t("component.sidebar:label.craft")}
          </div>
          <RiverNativeSelect
            className={styles.paramValue}
            value={sideBarContext?.kpiParams.workCenter || ""}
            options={workCenters.map((wc) => ({
              label: wc["WorkCenter"] as string,
              value: wc["WorkCenter"] as string,
            }))}
            onChange={(workCenter) =>
              setKPIParam({
                workCenter: workCenter,
              })
            }
          />
        </div>

        <div className={styles.paramRow}>
          <div className={styles.paramLabel}>
            {t("component.sidebar:label.labor")}
          </div>
          <RiverNativeSelect
            className={styles.paramValue}
            value={sideBarContext?.kpiParams.personNumber || ""}
            options={resources.map((resource) => ({
              label: resource["PersonName"] as string,
              value: resource["PersonNumber"] as string,
            }))}
            onChange={(personNumber) =>
              setKPIParam({
                personNumber: personNumber,
                personName:
                  (resources.find(
                    (pers) => pers["PersonNumber"] === personNumber
                  )?.["PersonName"] as string) ?? "",
              })
            }
          />
        </div>
      </div>

      <div className={styles.sectionTitle}>
        <CalendarIcon />
        <span className={styles.title}>
          {t("component.sidebar:label.kpis")}
        </span>
      </div>

      <div className={styles.charts}>
        {sideBarContext?.stats.charts.map((chart, index) =>
          renderChart(chart, index)
        )}
      </div>
    </div>
  );
}
