import React, { ReactElement, useContext, useState } from "react";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import PaletteIcon from "@mui/icons-material/Palette";
import { SupervisorBaselineDialog } from "../../../components/supervisor-schedule";
import { ITableContext, TableContext } from "../../../context";
import { IUseTable } from "../../../components/shared";
import headerStyles from "../../../components/shared/common-header/common-header.module.scss";

export const useCreateBaselineAction = () => {
  const { t } = useTranslation();
  const tableContext: ITableContext = useContext(TableContext)!;
  const table: IUseTable = tableContext?.table;

  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t(
    "module.supervisor_schedule:action_label.create_baseline"
  );

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (): ReactElement => (
    <PaletteIcon
      titleAccess={title}
      className={headerStyles.actionIcon}
      onClick={() => {
        setDialogOpened(true);
      }}
    />
  );

  const renderDialog = (): ReactElement => (
    <SupervisorBaselineDialog
      open={dialogOpened}
      onClose={() => {
        setDialogOpened(false);
      }}
      onCreate={() => table.refresh()}
    />
  );

  const renderCreateBaselineAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderCreateBaselineAction,
    createBaselineAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseCreateBaselineAction = ReturnType<
  typeof useCreateBaselineAction
>;
