import React, { ReactElement } from "react";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./river-checkmark-formatter.module.scss";
import clsx from "clsx";

interface IRiverCheckmarkFormatter {
  checked: boolean;
  className?: string;
}

export const RiverCheckmarkFormatter: React.FC<IRiverCheckmarkFormatter> = (
  props
): ReactElement => (
  <>
    {props.checked && (
      <div className={clsx([styles.root, props.className])}>
        <CheckIcon />
      </div>
    )}
  </>
);
