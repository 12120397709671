import { ReactElement, useContext, useRef, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { useTranslation } from "@river/common-ui";
import { TableContext } from "../../../../context";
import { useGridActionHelpers } from "../../../../helpers";
import { GenericAction } from "./generic-action";
import {
  CompletionPercentageDialog,
  CompletionPercentageDialogEntityNameType,
} from "../../completion-percentage-dialog";

export interface UseCompletionPercentageDialogAction {
  enableOn?: boolean;
}

export const useCompletionPercentageDialogAction = (
  props?: UseCompletionPercentageDialogAction
) => {
  const { t } = useTranslation();
  const entityIdsRef = useRef<string[] | null>(null);
  const tableContext = useContext(TableContext);
  const { withSanitizedProps } = useGridActionHelpers();

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t("shared.completion_percentage:title");

  const onClick = (entity_ids?: string[]): void => {
    if (entity_ids) {
      entityIdsRef.current = entity_ids;
    } else {
      entityIdsRef.current = null;
    }
    setDialogOpened(true);
  };

  const renderIcon = (iconProps?: any): ReactElement => (
    <GenericAction
      icon={PercentIcon}
      enableOn={props?.enableOn}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...iconProps}
    />
  );

  const renderDialog = (dialogProps: {
    entity_name: CompletionPercentageDialogEntityNameType;
    folderId: string;
    entity_ids?: string[];
  }): ReactElement => {
    const { entity_name, folderId, entity_ids } = dialogProps;
    return (
      <CompletionPercentageDialog
        open={dialogOpened}
        folderId={folderId}
        onClose={(success) => {
          setDialogOpened(false);
          if (success) {
            tableContext?.table.refresh();
          }
        }}
        entity_name={entity_name}
        entity_ids={entityIdsRef.current || entity_ids!}
      />
    );
  };

  const renderCompletionPercentageDialogAction = (
    entity_name: CompletionPercentageDialogEntityNameType,
    folderId: string
  ): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog({ entity_name, folderId })}
    </>
  );

  return {
    renderCompletionPercentageDialogAction,
    completionPercentageDialogAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseCompletionPercentageDialogAction = ReturnType<
  typeof useCompletionPercentageDialogAction
>;
