var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./river-date-input.module.scss";
import { Box } from "@mui/material";
import { RiverTextInput } from "../river-text-input";
import clsx from "clsx";
import { stringifyDate } from "../form-utils";
import { useFormField } from "../../../hooks";
export const RiverDateInput = (props) => {
    const { className } = props, propsWithoutClassName = __rest(props, ["className"]);
    const { label, value } = useFormField(props);
    const inputProps = Object.assign({ type: "date" }, props === null || props === void 0 ? void 0 : props.inputProps);
    const inputType = inputProps.type;
    return (_jsxs(Box, { className: clsx([styles.dateField, props.className]), children: [_jsx("div", { className: styles.label, children: label }), _jsx(RiverTextInput, Object.assign({ className: styles.input, inputProps: inputProps }, propsWithoutClassName, { label: "", value: stringifyDate({ value: value, inputType }) }))] }));
};
