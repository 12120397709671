import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { IndexDto, IIndex } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { useTranslation } from "@river/common-ui";

export const useEntityIndexForm = (props: {
  entityName: string;
  index: IIndex | null;
  onCreate?: (index: IIndex) => void;
  onUpdate?: () => void;
}): RiverFormInstance => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const isCustomIndex: boolean = !props.index || !!props.index?.is_custom;

  return useRiverForm<IndexDto, IIndex, Object>({
    initialDto: Object.assign(new IndexDto(), {
      index_name: "",
      index_type: "",
      fields: [],
      options: "",
    }),
    instanceToEdit: props.index,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: IndexDto): Promise<IIndex> => {
      const parsedOptions = !!dto.options ? JSON.parse(dto.options) : undefined;

      return await adapterContext!.service
        .getAdapterService()
        .createEntityIndexDefinition(props.entityName, {
          ...dto,
          options: parsedOptions,
        });
    },
    update: async (dto: IndexDto): Promise<void> => {
      const parsedOptions = !!dto.options ? JSON.parse(dto.options) : undefined;

      await adapterContext!.service
        .getAdapterService()
        .updateEntityIndexDefinition(
          props.index!.entity_name!,
          props.index!._id,
          {
            ...dto,
            options: parsedOptions,
          }
        );
    },
    labels: {
      index_name: t("module.data_dictionary:dialog.entity_index.index_name"),
      index_type: t("module.data_dictionary:dialog.entity_index.index_type"),
      options: t("module.data_dictionary:dialog.entity_index.options"),
    },
    readOnly: !isCustomIndex,
  });
};
