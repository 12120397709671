import { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useNotification } from "@river/common-ui";
import { uiConstants, useGridActionHelpers } from "../../../helpers";
import { useParams } from "react-router";
import { TableUiService } from "../../table-ui.service";
import { ObjectType } from "../../task-helpers-ui.service";

export const useUnassignCalendarAction = () => {
  const { t } = useTranslation();
  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const getObjectId = tableUiService.getObjectId()();

  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t("module.schedule:dialog.unassing_calendar.button");

  const onClick = async (): Promise<void> => {
    try {
      const rowType: ObjectType = uiConstants.rowType.operation;
      tableContext?.table.forceLoadingState(true);
      const updatedRows = await adapterContext!.service
        .getAdapterService()
        .schedule({
          folder_id: scheduleId,
          entity_name: rowType,
          entity_ids: Array.from(tableContext?.table.selectedRowIds || []),
          assignment: null,
          remove_calendar: true,
        });

      tableContext?.table?.updateRows({
        rows: updatedRows.map((updatedRow) => ({
          rowId: getObjectId(updatedRow, rowType),
          updatedRow,
        })),
      });

      notify.success(
        t("module.schedule:notification.unassign_calendar_success")
      );
    } catch (message) {
      notify.error({ message });
    } finally {
      tableContext?.table.forceLoadingState(false);
    }
  };

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={EventBusyIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const renderUnassignCalendarAction = (): ReactElement => <>{renderIcon()}</>;

  return {
    renderUnassignCalendarAction,
    unassignCalendarAction: {
      icon: withSanitizedProps(renderIcon),
      title,
      onClick,
    },
  };
};

export type IUseUnassignCalendarAction = ReturnType<
  typeof useUnassignCalendarAction
>;
