import { useContext } from "react";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import {
  IAdapterFolder,
  AdapterFolderDto,
  IAdapterCalendar,
} from "@river/interfaces";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Equals } from "class-validator";
import { useTranslation } from "@river/common-ui";

interface IStandaloneValidator {
  calendarName: string;
}

export const useScheduleForm = (props: {
  schedule: IAdapterFolder | null;
  selectedCalendar: IAdapterCalendar | null;
  onCreate: (schedule: IAdapterFolder) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  class StandaloneValidator {
    constructor(obj: IStandaloneValidator) {
      Object.assign(this, obj);
    }
    @Equals(props.selectedCalendar?.calendar || "", {
      message: (opts): string =>
        `shared.error_messages:validation.calendar_name?name=${opts.value}`,
    })
    // @ts-ignore
    calendarName: string;
  }

  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  return useRiverForm<AdapterFolderDto, IAdapterFolder, StandaloneValidator>({
    initialDto: Object.assign(new AdapterFolderDto(), {
      folder: "",
      start_date: "",
      end_date: "",
      duration: 0,
      calendar_id: "",
      use_avail_to_schedule_assignments: false,
    }),
    standalone: {
      fields: new StandaloneValidator({
        calendarName: "",
      }),
      fieldDefs: [
        {
          fieldName: "calendarName",
          dataType: "string",
        },
      ],
      getValidatorInstance: (obj: IStandaloneValidator) =>
        new StandaloneValidator(obj),
    },
    entityName: "folder",
    instanceToEdit: props.schedule,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterFolderDto): Promise<IAdapterFolder> => {
      if (props.selectedCalendar) {
        dto.calendar_id = props.selectedCalendar._id;
      }
      return await adapterContext!.service
        .getAdapterService()
        .createFolder(dto);
    },
    update: async (dto: AdapterFolderDto): Promise<void> => {
      dto.calendar_id = props.selectedCalendar?._id || null;
      await adapterContext!.service
        .getAdapterService()
        .updateFolder(props.schedule!._id!, dto);
    },
    labels: {
      folder: t("module.schedules:schedule_dialog.label.schedule_name"),
      start_date: t("module.schedules:schedule_dialog.label.start_date"),
      end_date: t("module.schedules:schedule_dialog.label.end_date"),
      duration: t("module.schedules:schedule_dialog.label.duration"),
      calendarName: t("module.schedules:schedule_dialog.label.calendar"),
      use_avail_to_schedule_assignments: t(
        "module.schedules:schedule_dialog.label.use_avail"
      ),
    },
  });
};
