import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseOperationsLookupProps,
  OperationsUiService,
} from "../operations-ui.service";
import { SitesUiService } from "../sites-ui.service";

export class OracleCloudOperationsUiService extends OperationsUiService {
  getOperationsLookupDefinition = (
    props: IUseOperationsLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];
    const { t, adapterContext, RiverSelectColumn } = props;

    const SitesUiService: SitesUiService =
      adapterContext?.service.getSitesUiService()!;
    return {
      title: t("shared.lookup:operation.title"),
      rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "WorkOrderNumber",
            name: t("entity.operation:operation.WorkOrderNumber"),
            width: 105,
          },
          {
            key: "OperationSequenceNumber",
            name: t("entity.operation:operation.OperationSequenceNumber"),
          },
          {
            key: "OperationDescription",
            name: t("entity.operation:operation.OperationDescription"),
          },
          {
            key: "WorkOrder.AssetNumber",
            name: t("entity.operation:operation_workorder.AssetNumber"),
          },
          {
            key: "WorkOrder.WorkOrderStatusCode",
            name: t("entity.operation:operation_workorder.WorkOrderStatusCode"),
          },
          {
            key: "WorkCenterName",
            name: t("entity.operation:operation.WorkCenterName"),
          },
          {
            key: "WorkOrderOperationResource.ResourceCode",
            name: t("entity.operation:operation_resource.ResourceCode"),
          },
          {
            key: "PlannedStartDate",
            name: t("entity.operation:operation.PlannedStartDate"),
          },
          {
            key: "PlannedCompletionDate",
            name: t("entity.operation:operation.PlannedCompletionDate"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext.service
            .getAdapterService()
            .searchEntityData(
              "operation",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [
                  {
                    attribute_name: SitesUiService.getSiteKeyColumn(),
                    attribute_value: { operator: "$eq", value: site.site_id },
                  },
                ],
              })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "operation",
      },
    };
  };
}
