import React, { useEffect, useState, useRef, useContext } from "react";
import {
  RiverDialog,
  RiverDialogButton,
  useNotification,
} from "@river/common-ui";
import { IWorkPackage } from "@river/interfaces";
import { Constants } from "@river/constants";
import { RiverSpinner } from "@river/common-ui";
import styles from "./attachment-conversion-status.module.scss";
import { AdapterUiContext, IAdapterUiContextState } from "../../context";
import { useTranslation } from "@river/common-ui";

const WP_STATUS_DISPLAY: { [key: string]: string } = {
  [Constants.work_package_status.pending]: "Pending...",
  [Constants.work_package_status.merging]: "Merging...",
  [Constants.work_package_status.error]: "Error",
  [Constants.work_package_status.complete]: "Complete.",
};

export interface IWoAttachmentConversionStatusProps {
  workPackage: IWorkPackage;
  onComplete: (success: boolean) => void;
}

export const AttachmentConversionStatus: React.FC<
  IWoAttachmentConversionStatusProps
> = (props) => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const [show, setShow] = useState<boolean>(true);
  const [workPackage, setWorkPackage] = useState<IWorkPackage>(
    props.workPackage
  );
  const intervalRef = useRef<number>();
  const notify = useNotification();

  const { t } = useTranslation();

  useEffect(() => {
    intervalRef.current = window.setInterval(() => refreshWorkPackage(), 1000);
    return () => {
      window.clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshWorkPackage = async () => {
    try {
      const wp = await adapterContext!.service
        .getAdapterService()
        .getWorkPackageStatus(workPackage._id!);
      checkStatus(wp);
    } catch (message) {
      notify.error({ message });
      props.onComplete(false);
    }
  };

  const getWpStatusDisplay = (wp: IWorkPackage): string =>
    WP_STATUS_DISPLAY[wp.status?.code!];

  const checkStatus = (wp: IWorkPackage) => {
    setWorkPackage(wp);
    if (wp.status?.code === Constants.work_package_status.complete) {
      window.clearInterval(intervalRef.current);
      setTimeout(() => {
        setShow(false);
        props.onComplete(true);
      }, 600);
    }
  };

  const handleCancel = () => {
    setShow(false);
    props.onComplete(false);
  };

  const isLoading = (): boolean =>
    [
      Constants.work_package_status.complete,
      Constants.work_package_status.error,
    ].indexOf(workPackage.status?.code || "") === -1;

  return (
    <RiverDialog
      open={show}
      title={t("shared.attachment:dialog.work_package_status.title")}
      showTitleDivider={false}
      showActionsDivider={false}
      onClose={handleCancel}
      showXClose={false}
      actionsContent={
        <>
          {isLoading() && (
            <RiverDialogButton
              text={t("common.button:cancel")}
              onClick={handleCancel}
            />
          )}
        </>
      }
      classes={{
        content: styles.content,
        paper: styles.paper,
        actions: styles.actions,
      }}
    >
      <div className={styles.status}>{getWpStatusDisplay(workPackage)}</div>
      {isLoading() && (
        <div className={styles.spinnerWrapper}>
          <RiverSpinner show={true} />
        </div>
      )}
    </RiverDialog>
  );
};
