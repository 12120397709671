import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  QueryAttributeDto,
} from "@river/interfaces";

interface IUseLotLookupProps {
  form: RiverFormInstance;
  selectedLot: any;
  setSelectedLot: (locator: any) => void;
}

export function useLotLookup(props: IUseLotLookupProps) {
  const notify = useNotification();

  const { form, selectedLot, setSelectedLot } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  useEffect(() => {
    validateStandaloneField("lotDisplay");

    const LOT_NUMBER = selectedLot?.["LOT_NUMBER"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { LOT_NUMBER }
    );
    form.setDto(newDto);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLot]);

  const getCustomFilters = (): QueryAttributeDto[] => [
    {
      attribute_name: "INVENTORY_ITEM_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["INVENTORY_ITEM_ID"] as string,
      },
    },
  ];

  const fetchLot = async (text: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("completion_lot", {
          query: {
            $and: [
              ...getCustomFilters(),
              {
                attribute_name: "ORGANIZATION_ID",
                attribute_value: { operator: "$eq", value: site.site_id },
              },
              {
                attribute_name: "LOT_NUMBER",
                attribute_value: {
                  operator: "$eq",
                  value: text,
                },
              },
            ],
          },
        });
      const lot: any = results[0] || null;
      setSelectedLot(lot);
      validateStandaloneField("lotDisplay", text);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchLot = useCallback(
    debounce((text) => {
      fetchLot(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"lotDisplay"}
      fullWidth
      lookup={{
        type: LookupType.ORACLE_EBS_COMPLETION_LOT,
        customFilters: getCustomFilters(),
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchLot(event.target.value);
      }}
      onSelect={(selectedLot) => {
        const { LOT_NUMBER } = selectedLot;
        const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
          new OracleEbsAdapterWorkorderCompletionDto(),
          { ...currentDto },
          { LOT_NUMBER }
        );
        form.setDto(newDto);
        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          lotDisplay: LOT_NUMBER,
        });
      }}
    />
  );

  return {
    render,
  };
}

export type IUseLotLookup = ReturnType<typeof useLotLookup>;
