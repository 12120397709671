import { FC, ReactElement, useContext } from "react";
import { RiverDialog, RiverSpinner } from "@river/common-ui";
import { RiverFormInstance } from "../../../hooks";
import { IAdapterUiContextState, AdapterUiContext } from "../../../context";
import { useTranslation } from "@river/common-ui";
import { TimeCardUiService, ITimeCardDialogProps } from "../../../services";

export const ExecutionOperationsTimeCardDialog: FC<ITimeCardDialogProps> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: TimeCardUiService =
    adapterContext?.service!.getTimeCardUiService()!;

  const form: RiverFormInstance = uiService.getOperationsTimeCardForm({
    folderId: props.folderId,
    workorder: props.workorder,
    operation: props.operation,
    initialValues: props.initialValues,
    onCreate: () => {
      props.onCreate();
      closeAndRefresh();
    },
  })();

  const { t } = useTranslation();

  const { submit, resetForm, isProcessing } = form;

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const renderFormFields = (): ReactElement =>
    uiService.renderOperationsTimeCardFormFields({
      workorder: props.workorder,
      operation: props.operation,
      form,
    })();

  return (
    <RiverDialog
      title={t("common.label:timecard")}
      open={props.open}
      onClose={close}
      actionButtonText={t("common.button:create")}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderFormFields()}
    </RiverDialog>
  );
};
