import React, { useContext, useEffect, useState } from "react";
import {
  IAttribute,
  IEntity,
  IEntityObject,
  IIndex,
  IRelation,
} from "@river/interfaces";
import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverSpinner,
} from "@river/common-ui";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useTable,
} from "../../shared";
import { RiverFormInstance, IUseEntity, useEntity } from "../../../hooks";
import {
  DataDictDetailsTabId,
  DEFAULT_DATA_DICT_DETAILS_TAB_ID,
  EntityDialogGridHeader,
} from "./entity-dialog-grid-header";
import {
  AdapterUiContext,
  EntityDialogContext,
  IAdapterUiContextState,
  TabContext,
  TableContext,
} from "../../../context";
import { fetchHelpers, uiConstants } from "../../../helpers";
import {
  attributeEntityDef,
  IUseAttributeDef,
  useAttributeColumns,
} from "./defs/attribute-def";
import {
  IUseRelationDef,
  relationEntityDef,
  useRelationColumns,
} from "./defs/relation-def";
import {
  indexEntityDef,
  IUseIndexColumns,
  useIndexColumns,
} from "./defs/index-def";
import { EntityDetailsTab } from "./entity-details-tab";
import { useEntityForm } from "./use-entity-form";
import { EntityRelationDialog } from "../entity-relation-dialog";
import { EntityAttributeDialog } from "../entity-attribute-dialog";
import { EntityIndexDialog } from "../entity-index-dialog";
import styles from "./entity-dialog.module.scss";
import { useTranslation } from "@river/common-ui";

export interface IEntityDialogProps {
  entity?: IEntity | null;
  open: boolean;
  onClose: () => void;
  onCreate?: (entity: IEntity) => void;
  onUpdate?: () => void;
  tab?: DataDictDetailsTabId | null;
}

export const EntityDialog: React.FC<IEntityDialogProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState<DataDictDetailsTabId>(
    DEFAULT_DATA_DICT_DETAILS_TAB_ID
  );
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const { t } = useTranslation();

  const form: RiverFormInstance = useEntityForm({
    entity: props.entity!,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
  });
  const { resetForm, isCreate, isProcessing, submit } = form;
  const isCustomEntity: boolean = !props.entity || !!props.entity.customer_id;

  const [showEntityAttributeDialog, setShowEntityAttributeDialog] =
    useState<boolean>(false);
  const [showEntityRelationDialog, setShowEntityRelationDialog] =
    useState<boolean>(false);
  const [showEntityIndexDialog, setShowEntityIndexDialog] =
    useState<boolean>(false);
  const [selectedEntityObject, setSelectedEntityObject] =
    useState<IEntityObject | null>(null);

  useEffect(() => {
    setSelectedTab(props.tab || DEFAULT_DATA_DICT_DETAILS_TAB_ID);
  }, [props.tab]);

  const closeDialog = () => {
    props.onClose();
    resetForm();
    setSelectedTab(DataDictDetailsTabId.ENTITY_DETAILS);
    table.clear();
  };

  const fetchAtributes = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const attributes: any[] = await adapterContext!.service
      .getAdapterService()
      .getEntityAttributeDefinitions(
        props.entity!.entity_name,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return attributes as IEntityObject[];
  };

  const fetchRelations = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const relations: any[] = await adapterContext!.service
      .getAdapterService()
      .getEntityRelationDefinitions(
        props.entity!.entity_name,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return relations as IEntityObject[];
  };

  const fetchIndexes = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    const indexes: any[] = await adapterContext!.service
      .getAdapterService()
      .getEntityIndexDefinitions(
        props.entity!.entity_name,
        fetchHelpers.getTableQuery({ fetchProps })
      );
    return indexes as IEntityObject[];
  };

  const onAttributeNameClick = (attribute: IEntityObject): void => {
    setSelectedEntityObject(attribute);
    setShowEntityAttributeDialog(true);
  };

  const attributeDef: IUseAttributeDef = useAttributeColumns();
  const attributesTable: IUseTable = useTable({
    columns: attributeDef.attributeColumns(onAttributeNameClick),
    fetchFunction: fetchAtributes,
    fetchOn: selectedTab === DataDictDetailsTabId.ATTRIBUTES,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const onRelationNameClick = (relation: IEntityObject) => {
    setSelectedEntityObject(relation);
    setShowEntityRelationDialog(true);
  };

  const relationDef: IUseRelationDef = useRelationColumns();
  const relationsTable: IUseTable = useTable({
    columns: relationDef.relationColumns(onRelationNameClick),
    fetchFunction: fetchRelations,
    fetchOn: selectedTab === DataDictDetailsTabId.RELATIONS,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const onIndexNameClick = (index: IEntityObject) => {
    setSelectedEntityObject(index);
    setShowEntityIndexDialog(true);
  };
  const indexDef: IUseIndexColumns = useIndexColumns();
  const indexesTable: IUseTable = useTable({
    columns: indexDef.indexColumns(onIndexNameClick),
    fetchFunction: fetchIndexes,
    fetchOn: selectedTab === DataDictDetailsTabId.INDEXES,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  const table: IUseTable =
    selectedTab === DataDictDetailsTabId.ATTRIBUTES
      ? attributesTable
      : selectedTab === DataDictDetailsTabId.RELATIONS
        ? relationsTable
        : indexesTable;

  const attributesEntity = useEntity({
    entityName: "attributes_meta",
    definition: attributeEntityDef,
  });
  const relationsEntity = useEntity({
    entityName: "relations_meta",
    definition: relationEntityDef,
  });
  const indexesEntity = useEntity({
    entityName: "indexes_meta",
    definition: indexEntityDef,
  });

  const entity: IUseEntity =
    selectedTab === DataDictDetailsTabId.ATTRIBUTES
      ? attributesEntity
      : selectedTab === DataDictDetailsTabId.RELATIONS
        ? relationsEntity
        : indexesEntity;

  return (
    <>
      <EntityDialogContext.Provider
        value={{
          attributesTable,
          relationsTable,
          indexesTable,
        }}
      >
        <RiverDialog
          title={t("common.label:entity")}
          open={props.open}
          onClose={closeDialog}
          actionsContent={
            <>
              <RiverDialogButton
                onClick={closeDialog}
                text={t("common.button:close")}
              />
              {selectedTab === DataDictDetailsTabId.ENTITY_DETAILS &&
                isCustomEntity && (
                  <RiverDialogActionButton
                    onClick={submit}
                    text={
                      isCreate
                        ? t("common.button:create")
                        : t("common.button:update")
                    }
                    disabled={isProcessing}
                  />
                )}
            </>
          }
          classes={{
            paper: styles.paper,
            content: styles.content,
          }}
          showActionsDivider={
            selectedTab === DataDictDetailsTabId.ENTITY_DETAILS
          }
          dialogProps={{
            maxWidth: false,
          }}
        >
          <TabContext.Provider
            value={{
              selectedTab,
              setSelectedTab: (tabId: string) => {
                setSelectedTab(tabId as DataDictDetailsTabId);
              },
            }}
          >
            <TableContext.Provider value={{ table, entity }}>
              <EntityDialogGridHeader entity={props.entity} />
              {selectedTab === DataDictDetailsTabId.ENTITY_DETAILS && (
                <EntityDetailsTab form={form} />
              )}
              {selectedTab !== DataDictDetailsTabId.ENTITY_DETAILS && (
                <>
                  <RiverSpinner show={table.isLoading} />
                  <RiverDataGrid
                    columns={table.columns}
                    rows={table.entities}
                    className={styles.grid}
                    disableSelectAll={true}
                    singleSelect={true}
                    defaultColumnOptions={{
                      sortable: true,
                      resizable: true,
                    }}
                    rowKeyGetter={(row) => row[uiConstants.fields._id]}
                    sortColumns={table.sortColumns}
                    onSortColumnsChange={(e) => {
                      table.setSortColumns(e);
                    }}
                  />
                </>
              )}
            </TableContext.Provider>
          </TabContext.Provider>
        </RiverDialog>
        <EntityAttributeDialog
          open={showEntityAttributeDialog}
          entityName={props.entity?.entity_name!}
          attribute={
            showEntityAttributeDialog
              ? (selectedEntityObject as any as IAttribute)
              : null
          }
          onClose={(success) => {
            setShowEntityAttributeDialog(false);
            if (success) {
              table.refresh();
            }
          }}
        />
        <EntityRelationDialog
          open={showEntityRelationDialog}
          entityName={props.entity?.entity_name!}
          relation={
            showEntityRelationDialog
              ? (selectedEntityObject as any as IRelation)
              : null
          }
          onClose={(success) => {
            setShowEntityRelationDialog(false);
            if (success) {
              table.refresh();
            }
          }}
        />
        {props.entity?.entity_name && (
          <EntityIndexDialog
            open={showEntityIndexDialog}
            entityName={props.entity.entity_name}
            index={
              showEntityIndexDialog && selectedEntityObject
                ? ({
                    ...selectedEntityObject,
                    options: JSON.stringify(selectedEntityObject.options),
                  } as any as IIndex)
                : null
            }
            onClose={(success) => {
              setShowEntityIndexDialog(false);
              if (success) {
                table.refresh();
              }
            }}
          />
        )}
      </EntityDialogContext.Provider>
    </>
  );
};
