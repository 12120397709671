import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";

export function useOracleCloudWoStatusLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:wo_status.title"),
      rowKey: "WoStatusId",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "WoStatusCode",
            name: t("entity.wo_status:wo_status.WoStatusCode"),
          },
          {
            key: "WoStatusName",
            name: t("entity.wo_status:wo_status.WoStatusName"),
          },
          {
            key: "WoStatusDescription",
            name: t("entity.wo_status:wo_status.WoStatusDescription"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext!.service
            .getAdapterService()
            .searchEntityData(
              "wo_status",
              fetchHelpers.getTableQuery({ fetchProps })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "wo_status",
      },
    };
  };
}

export type IUseOracleCloudWoStatusLookupDef = ReturnType<
  typeof useOracleCloudWoStatusLookupDef
>;
