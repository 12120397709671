import React, { ReactElement, PropsWithChildren } from "react";
import { IUseEnv, useEnv } from "@river/common-ui";
import styles from "./grid-header.module.scss";
import clsx from "clsx";

export interface IGridHeaderStyles {
  root?: string;
  title?: string;
}

export interface IGridHeaderProps extends PropsWithChildren {
  title?: string;
  className?: string;
  classes?: IGridHeaderStyles;
}

export const GridHeader: React.FC<IGridHeaderProps> = (props): ReactElement => {
  const env: IUseEnv = useEnv();
  const { isMobile } = env;

  return (
    <div className={styles.root}>
      <div
        className={clsx([
          styles.topBar,
          { [styles.mobile]: isMobile },
          props.className,
          props.classes?.root,
        ])}
      >
        {props.title && (
          <div className={clsx([styles.title, props.classes?.title])}>
            {props.title}
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};
