import { ILookupDefinition } from "../../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../../river-data-grid";
import { fetchHelpers } from "../../../../../../helpers";
import {
  IUserContextSite,
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
} from "../../../../../../context";
import { useTranslation } from "@river/common-ui";
import { OracleEbsAdapterService } from "../../../../../../services/oracle-ebs";
import { QueryAttributeDto } from "@river/interfaces";

export function useOracleEbsReconciliationLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState,
    customFilters?: QueryAttributeDto[]
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("shared.lookup:reconciliation.title"),
      rowKey: "MEANING",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "MEANING",
            name: t("entity.reconciliation_code:reconciliation_code.MEANING"),
          },
          {
            key: "DESCRIPTION",
            name: t(
              "entity.reconciliation_code:reconciliation_code.DESCRIPTION"
            ),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await (
            adapterContext!.service.getAdapterService() as OracleEbsAdapterService
          ).searchEntityData(
            "reconciliation_code",
            fetchHelpers.getTableQuery({
              fetchProps,
              initialQueryAttributes: [...(customFilters || [])],
            })
          );
        },
        dependencies: [!!site],
        saveKey: "reconciliation_code.lookup",
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "reconciliation_code",
      },
    };
  };
}

export type IUseOracleEbsReconciliationLookupDef = ReturnType<
  typeof useOracleEbsReconciliationLookupDef
>;
