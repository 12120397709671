import { FC, ReactElement } from "react";
import {
  RiverDateInput,
  RiverDialog,
  RiverSpinner,
  RiverTextInput,
} from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import { ITimeCardDialogProps } from "../../../supervisor-timecard-ui.service";
import { useOracleEbsSupervisorInstanceTimecardForm } from "./use-oracle-ebs-supervisor-instance-timecard-form";
import styles from "./oracle-ebs-supervisor-instance-timecard-dialog.module.scss";

export const OracleEbsSupervisorInstanceTimeCardDialog: FC<
  ITimeCardDialogProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { form } = useOracleEbsSupervisorInstanceTimecardForm({
    folderId: props.folderId,
    operation: props.operation,
    onCreate: () => {
      props.onCreate();
      closeAndRefresh();
    },
  });

  const { submit, resetForm, isProcessing } = form;

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    closeDialog(requireRefresh);
  };

  const closeDialog = (requiresRefresh: boolean): void => {
    resetForm();
    props.onClose(requiresRefresh);
  };

  const close = (): void => {
    const requireRefresh: boolean = false;
    closeDialog(requireRefresh);
  };

  const renderLaborInstanceNameField = (): ReactElement => {
    return (
      <RiverTextInput
        disabled={true}
        id="laborInstanceName"
        fullWidth
        className={styles.field}
      />
    );
  };

  const renderTimeCardDateField = (): ReactElement => (
    <RiverDateInput id="timecard_date" className={styles.dateField} />
  );

  const renderTimeCardTimeField = (): ReactElement => (
    <RiverTextInput
      id="timecard_hours"
      className={styles.hoursField}
      inputProps={{
        type: "number",
        inputProps: {
          min: 1,
        },
      }}
    />
  );

  const renderTimeCardTimeFields = (): ReactElement => (
    <div className={styles.timeFields}>
      {renderTimeCardDateField()}
      {renderTimeCardTimeField()}
    </div>
  );

  const renderContent = (): ReactElement => (
    <>
      {renderLaborInstanceNameField()}
      {renderTimeCardTimeFields()}
    </>
  );

  return (
    <RiverDialog
      title={t("common.label:timecard")}
      open={props.open}
      onClose={close}
      actionButtonText={t("common.button:create")}
      onSubmit={submit}
      form={form}
    >
      <RiverSpinner show={isProcessing} />
      {renderContent()}
    </RiverDialog>
  );
};
