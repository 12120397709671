import React, { ReactElement, useContext } from "react";
import { TableContext } from "../../../../context";
import { DeleteIcon } from "../../../../icons";
import headerStyles from "../../common-header/common-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

export interface IDeleteAction {
  onClick: () => void;
}

export const DeleteAction: React.FC<IDeleteAction> = (props): ReactElement => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);
  const disabled = tableContext?.table.selectedRowIds.size === 0;

  return (
    <DeleteIcon
      title={t("shared.grid_header:label.delete")}
      onClick={() => {
        if (!disabled) {
          props.onClick();
        }
      }}
      className={clsx([
        headerStyles.actionIcon,
        { [headerStyles.disabled]: disabled },
      ])}
    />
  );
};
