import {
  ILookupDefinition,
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import { PersonUiService } from "../person-ui.service";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { fetchHelpers } from "../../helpers";
import { QueryAttributeDto } from "@river/interfaces";

export class OracleEbsPersonUiService extends PersonUiService {
  getPersonColumns = () => {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "INSTANCE_NAME",
          name: t(
            "entity.availability_header:availability_header.INSTANCE_NAME"
          ),
        },
        {
          key: "SERIAL_NUMBER",
          name: t(
            "entity.availability_header:availability_header.SERIAL_NUMBER"
          ),
        },
        {
          key: "Resources.RESOURCE_CODE",
          name: t("entity.resource_ref:resource_ref.RESOURCE_CODE"),
        },
        {
          key: "Resources.DEPARTMENT_CODE",
          name: t("entity.resource_ref:resource_ref.DEPARTMENT_CODE"),
        },
      ];
    };
  };

  getPersonLookupDefinition = (
    customFilters?: QueryAttributeDto[]
  ): (() => ILookupDefinition) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      const userContext: IAdapterUserContext | null =
        useContext(AdapterUserContext);
      const site: IUserContextSite =
        userContext?.userProperties[AdapterUserContextProp.SITE];
      const { t } = useTranslation();
      return {
        title: t("shared.lookup:person_def.title"),
        useTableProps: {
          columns: this.getPersonColumns()(),
          fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
            // const queryDto = fetchHelpers.getTableQuery({ fetchProps });
            // queryDto.query = {
            //   $and: [...(customFilters || []), queryDto.query || {}],
            // };

            return await adapterContext!.service
              .getAdapterService()
              .fetchCraftPeople(
                fetchHelpers.getTableQuery({
                  fetchProps,
                  initialQueryAttributes: customFilters,
                })
              );
          },
          dependencies: [!!site],
          useAdvancedFilters: false,
        },
        useEntityProps: {
          entityName: "availability_header",
        },
      };
    };
  };

  getPersonKey = () => "INSTANCE_NAME";
}
