import React from "react";
import { FormatterProps } from "react-data-grid";
import { useTableCellRenderers } from "../../../../../hooks";

interface IRiverDefaultTextFormatterProps {
  formatterProps: FormatterProps<any>;
}

export const RiverDefaultTextFormatter: React.FC<
  IRiverDefaultTextFormatterProps
> = (props) => {
  const { renderTextCell } = useTableCellRenderers();
  const { formatterProps } = props;

  return <>{renderTextCell({ formatterProps })}</>;
};
