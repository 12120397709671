import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import { CustomCronTaskCreateDto, ISystemEnvCronTask } from "@river/interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { Constants } from "@river/constants";
import { customCronTasksEntityDef } from "../custom-cron-task-def";

export const useCustomCronTaskForm = (props: {
  cron: ISystemEnvCronTask | null;
  onUpdate: () => void;
  onCreate: () => void;
}): RiverFormInstance => {
  const { cron, onUpdate, onCreate } = props;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  return useRiverForm<CustomCronTaskCreateDto, ISystemEnvCronTask, Object>({
    initialDto: Object.assign(new CustomCronTaskCreateDto(), {
      frequency: "* * * * *",
      enabled: true,
      description: "",
      action: "",
      task_code: "",
      task_type: Constants.cron_task_type.custom,
      name: "",
    }),
    entityName: "custom_cron_tasks",
    entityDefinition: customCronTasksEntityDef,
    instanceToEdit: cron,
    onUpdate,
    update: async (dto) => {
      await adapterContext!.service
        .getAdapterService()
        .updateCustomCronTask(cron?.task_code!, dto);
    },
    labels: {
      name: t("module.cron:cron_entity.name"),
      task_code: t("module.cron:cron_entity.task_code"),
      description: t("module.cron:cron_entity.description"),
      action: t("entity.rule_action_ref:rule_action_ref.action_script"),
    },
    onCreate,
    create: async (dto) => {
      return await adapterContext!.service
        .getAdapterService()
        //@ts-ignore
        .createCustomCronTask(dto.task_code, dto);
    },
  });
};
