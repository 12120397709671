import { useEffect, useRef, useContext } from "react";
import { AdapterUiContext, IAdapterUiContextState } from "../context";
import { IAdapterRule, IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";
import { ruleHelpers, uiConstants } from "../helpers";

const DEFAULT_TASK_COLOR = "orange";
export type TaskColorGetter = (workOrder: IEntityObject) => string;

export function useTaskColor(): TaskColorGetter {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const ganttColorRulesRef = useRef<IAdapterRule[]>([]);
  const { evaluateRuleExpression } = ruleHelpers;

  useEffect(() => {
    const fetchGanttColorRules = async (): Promise<void> => {
      ganttColorRulesRef.current = await adapterContext!.service
        .getAdapterService()
        .fetchRules(Constants.rule_type.gantt_color_rule);
    };
    fetchGanttColorRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (task: IEntityObject): string => {
    const rule: IAdapterRule = ganttColorRulesRef.current?.find(
      (rule) => rule.entity_name === task[uiConstants.fields.rowType]
    )!;
    return evaluateRuleExpression(rule, task) || DEFAULT_TASK_COLOR;
  };
}
