import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import { fetchHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  ScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import * as ganttUtils from "../../components/schedule/schedule-gantt/schedule-gantt-utils";
import {
  ScheduleUtilizationUiService,
  ScheduleUtilizationTabId,
  CraftsUiService,
} from "../../services";
import {
  IAdapterFolder,
  QueryDto,
  UtilizationPeriodType,
} from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";

interface IUseCraftUtilizationProps {
  selectedCraftIds: Set<string>;
}

export const useCraftUtilization = (
  props: IUseCraftUtilizationProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const craftsUiService: CraftsUiService =
    adapterContext?.service.getCraftsUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: ScheduleUtilizationTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const utilizationPeriodType: UtilizationPeriodType =
    utilizationHelpers.getUtilizationPeriodType(scheduleContext!);
  const utilizationPeriods: number = utilizationHelpers.getUtilizationPeriods(
    scheduleContext!
  );

  const fetchCraftUtilization = async (
    fetchProps: ITableFetchFunctionProps
  ) => {
    const selectedIds = craftsUiService.getCraftsUtilizationIds(
      Array.from(props.selectedCraftIds)
    );
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup:
          craftsUiService.getSelectedCraftsQuery(selectedIds),
      }),
    };
    if (!selectedIds.length) return [];
    return await adapterContext!.service
      .getAdapterService()
      .fetchCraftUtilization(
        currentSchedule._id,
        null, //baseline id
        ganttUtils.getGanttStart(
          scheduleContext?.selectedGanttDate!,
          scheduleContext?.ganttTimeOption?.groupId!,
          new Date(scheduleContext?.currentSchedule?.start_date!)
        ),
        utilizationPeriodType,
        utilizationPeriods,
        query
      );
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: uiService.getCraftUtilizationEntityName(),
      columns: uiService.getDefaultCraftUtilizationColumns()(),
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    entityName,
    saveKey: `utilization.crafts`,
    columns,
    fetchFunction: fetchCraftUtilization,
    fetchOn: currentTab === ScheduleUtilizationTabId.CRAFTS,
    dependencies: [
      !!site,
      !!utilizationPeriodType,
      !!currentSchedule,
      !!props.selectedCraftIds,
      currentTab === ScheduleUtilizationTabId.CRAFTS,
    ],
    clearBeforeFetch: true,
    fetchTriggers: [
      site,
      utilizationPeriodType,
      utilizationPeriods,
      currentSchedule,
      props.selectedCraftIds,
      scheduleContext?.selectedGanttDate,
    ],
    useAdvancedFilters: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
