import {
  registerDecorator,
  ValidationOptions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from "class-validator";
import { IAdapterUiContextState } from "../../context";
import { useTranslation } from "@river/common-ui";
import { AdapterService } from "../../services";

@ValidatorConstraint({ async: true })
export class IsPersonKeyExistsConstraint
  implements ValidatorConstraintInterface
{
  validate(personKey: any, args: ValidationArguments) {
    const params: {
      adapterContext: IAdapterUiContextState;
      isOptional: boolean;
    } = args.constraints[0];
    const { adapterContext, isOptional } = params;

    if (personKey === "" && isOptional) {
      return true;
    }

    const adapterService: AdapterService =
      adapterContext.service.getAdapterService();
    const attributeName: string = adapterContext.service
      .getPersonUiService()
      .getPersonKey();

    return adapterService
      .fetchCraftPeople({
        query: {
          $and: [
            {
              attribute_name: attributeName,
              attribute_value: {
                operator: "$in",
                value: [personKey],
              },
            },
          ],
        },
      })
      .then((results) => {
        return !!results.length;
      });
  }
}

export function IsPersonKeyExists(validationOptions?: ValidationOptions) {
  const { t } = useTranslation();
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: Object.assign(validationOptions || {}, {
        message:
          validationOptions?.message ||
          ((args: ValidationArguments) => {
            return t("module.supervisor_schedule:validation.person_number", {
              number: args.value,
            });
          }),
      }),
      constraints: [validationOptions?.context],
      validator: IsPersonKeyExistsConstraint,
    });
  };
}
