import React, { SVGProps } from "react";

export const CalendarIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-1 0 17 17"
      fill="currentColor"
      {...props}
    >
      <path d="M12.3333 1.99999H11.6666V0.666656H10.3333V1.99999H3.66659V0.666656H2.33325V1.99999H1.66659C0.933252 1.99999 0.333252 2.59999 0.333252 3.33332V14C0.333252 14.7333 0.933252 15.3333 1.66659 15.3333H12.3333C13.0666 15.3333 13.6666 14.7333 13.6666 14V3.33332C13.6666 2.59999 13.0666 1.99999 12.3333 1.99999ZM12.3333 14H1.66659V6.66666H12.3333V14ZM12.3333 5.33332H1.66659V3.33332H12.3333V5.33332Z" />
    </svg>
  );
};
