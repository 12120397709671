import React, { useContext, useState } from "react";
import { PrimaryButton } from "@river/common-ui";
import { ModuleKey } from "../../sidebar-menu";
import ProtectedAction from "../../protected-action";
import { LookupsAction } from "../lookups";
import { LookupDialog } from "../lookup-dialog";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { TableContext } from "../../../context";
import { useTranslation } from "@river/common-ui";

export interface LookupsSubHeaderProps {
  moduleKey: ModuleKey;
  lookupType: string;
}

export const LookupsSubHeader: React.FC<LookupsSubHeaderProps> = (props) => {
  const [lookupDialogOpened, setLookupDialogOpened] = useState<boolean>(false);
  const openDialog = (): void => {
    setLookupDialogOpened(true);
  };
  const closeDialog = (): void => setLookupDialogOpened(false);
  const tableContext = useContext(TableContext);
  const { t } = useTranslation();

  return (
    <>
      <div className={headerStyles.commonSubheader}>
        <ProtectedAction module={props.moduleKey} action={LookupsAction.CREATE}>
          <PrimaryButton
            onClick={openDialog}
            text={t("module.lookups:button.add_lookup")}
          />
        </ProtectedAction>
      </div>
      <LookupDialog
        lookup_type={props.lookupType}
        open={lookupDialogOpened}
        lookup={null}
        onClose={(success) => {
          closeDialog();
          if (success) tableContext?.table.fetch();
        }}
      />
    </>
  );
};
