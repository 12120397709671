import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
} from "../../context";
import { useContext } from "react";
import { IAdapterFolder, IAdapterRuleSummary } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  TableFetchFunction,
} from "../../components/shared";

interface IRulesFetchProviderProps {
  scheduleId?: string;
}

export const useSupervisorScheduleValidationRules = () => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;

  const getFetchRulesFn = (
    props?: IRulesFetchProviderProps
  ): TableFetchFunction<IAdapterRuleSummary> => {
    const scheduleId: string = props?.scheduleId || currentSchedule?._id;

    return async (
      fetchProps?: ITableFetchFunctionProps
    ): Promise<IAdapterRuleSummary[]> =>
      await adapterContext!.service
        .getAdapterService()
        .jobValidationFolderSummary({
          folder_id: scheduleId,
        });
  };

  return {
    getFetchRulesFn,
  };
};

export type IUseSupervisorScheduleValidationRules = ReturnType<
  typeof useSupervisorScheduleValidationRules
>;
