import { RiverFormInstance, useRiverForm } from "../../../hooks";
import { AdapterRuleDto, IAdapterRule } from "@river/interfaces";
import { useContext } from "react";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { ModuleKey } from "../../sidebar-menu";
import { Constants } from "@river/constants";
import { useAllowedAction } from "../../protected-action";
import { VisualIndicatorsRulesAction } from "../visual-indicators-rules";
import { useTranslation } from "@river/common-ui";

const DEFAULT_VISUAL_INDICATOR_COLOR: string = "#55a1c4";

const getInitialDisplayOptions = (): string =>
  JSON.stringify({ color: DEFAULT_VISUAL_INDICATOR_COLOR });

const DEFAULT_RULE_TYPE: string = Constants.rule_type.gantt_color_rule;

export const useVisualIndicatorsRuleForm = (props: {
  rule: IAdapterRule | null;
  onCreate: (rule: IAdapterRule) => void;
  onUpdate: () => void;
}): RiverFormInstance => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();

  const isActionAllowed = useAllowedAction();

  const readOnly: boolean = !isActionAllowed(
    ModuleKey.VISUAL_INDICATORS_RULES,
    VisualIndicatorsRulesAction.UPDATE
  );

  return useRiverForm<AdapterRuleDto, IAdapterRule, Object>({
    initialDto: Object.assign(new AdapterRuleDto(), {
      rule: "",
      rule_type: DEFAULT_RULE_TYPE,
      entity_name: "workorder",
      target_period: 0,
      expression: "",
      actions: [],
      display_options: getInitialDisplayOptions(),
      attribute: "",
    }),
    entityName: "rule",
    instanceToEdit: props.rule,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: AdapterRuleDto): Promise<IAdapterRule> => {
      dto.display_options = getInitialDisplayOptions();
      return await adapterContext!.service.getAdapterService().createRule(dto);
    },
    update: async (dto: AdapterRuleDto): Promise<void> => {
      dto.display_options = getInitialDisplayOptions();
      await adapterContext!.service
        .getAdapterService()
        .updateRule(props.rule!._id!, dto);
    },
    labels: {
      rule: t("entity.rule:rule.rule"),
      rule_type: t("entity.rule:rule.rule_type"),
      entity_name: t("entity.rule:rule.entity_name"),
      expression: t("entity.rule:rule.expression"),
      attribute: t("entity.rule:rule.attribute"),
    },
    readOnly,
  });
};
