import { IUseEntityTable } from "../ui-service.types";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  ISupervisorScheduleContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import {
  SupervisorScheduleUiService,
  SupervisorScheduleTasksTabId,
} from "./supervisor-schedule-ui-service";
import { TableUiService } from "../table-ui.service";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  IAdapterFolder,
  QueryAttributeDto,
  QueryAttributeGroupDto,
  QueryDto,
} from "@river/interfaces";
import { AdapterService } from "../adapter.service";
import { getFolderQueryGroup } from "@river/util";
import { SitesUiService } from "../sites-ui.service";

interface IUseSupervisorScheduleResourcesProps {
  scheduleContext: ISupervisorScheduleContext;
}

export const useSupervisorScheduleResources = (
  props: IUseSupervisorScheduleResourcesProps
): IUseEntityTable => {
  const entityName: string = "operation";
  const resourcesTabId: SupervisorScheduleTasksTabId =
    SupervisorScheduleTasksTabId.RESOURCES;
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterService: AdapterService =
    adapterContext?.service.getAdapterService()!;
  const scheduleUiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;
  const sitesUiService: SitesUiService =
    adapterContext?.service.getSitesUiService()!;
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;

  const entityHelpers = useEntityHelpers();
  const userContext: IAdapterUserContext = useContext(AdapterUserContext)!;
  const organizationId: string | number =
    (
      userContext?.userProperties[
        AdapterUserContextProp.SITE
      ] as IUserContextSite
    ).site_id || 0;

  const scheduleContext: ISupervisorScheduleContext = props.scheduleContext;
  const currentSchedule: IAdapterFolder = scheduleContext.currentSchedule!;
  const currentTab: SupervisorScheduleTasksTabId =
    scheduleUiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const getInitialQuery = (): QueryAttributeGroupDto => {
    const organizationQueryAttribute: QueryAttributeDto = {
      attribute_name: sitesUiService.getSiteKeyColumn(),
      attribute_value: {
        operator: "$eq",
        value: organizationId,
      },
    };

    return {
      $and: [
        getFolderQueryGroup(currentSchedule, ""),
        organizationQueryAttribute,
      ],
    };
  };

  const fetchResources = async (fetchProps: ITableFetchFunctionProps) => {
    const query: QueryDto = {
      ...fetchHelpers.getTableQuery({
        fetchProps,
        initialQueryAttributeGroup: getInitialQuery(),
      }),
      $unwind: [
        "WorkOrderOperationResource",
        "WorkOrderOperationResource.__folder",
      ],
    };

    const resources = await adapterService.searchEntityData("operation", query);
    const resourceRowIdAttribute: string = tableUiService.getRowIdAttribute(
      uiConstants.rowType.operation_resource
    );

    table.setLastRanQueryDto(query);

    resources.forEach((res) => {
      try {
        entityHelpers.setAttributeValue(
          res,
          uiConstants.fields.rowType,
          uiConstants.rowType.operation_resource
        );
        entityHelpers.setAttributeValue(
          res,
          uiConstants.fields.rowId,
          entityHelpers.getAttributeValue(res, resourceRowIdAttribute)
        );
      } catch (e) {
        console.error(`Error processing resource _id: ${res._id}`);
        console.error(e);
      }
    });
    return resources;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: `supervisor.schedule.resources`,
    columns: scheduleUiService.getDefaultResourcesColumns()(),
    fetchFunction: fetchResources,
    dependencies: [!!site],
    fetchOn: currentTab === resourcesTabId,
    initialSimpleFilters:
      scheduleUiService.getInitialTabSimpleFilters(resourcesTabId),
    initialQuery: scheduleUiService.getInitialTabQuery(resourcesTabId),
    keepSelection: true,
    rowKeyGetter: (row) => String(row[uiConstants.fields.rowId]),
    fetchTriggers: [currentSchedule?._id, site],
    infiniteScrolling: true,
    onClearFilters: () => {
      scheduleUiService.taskInitialFiltersOverrides[resourcesTabId] = null;
      scheduleUiService.taskInitialQueryOverrides[resourcesTabId] = null;
    },
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
