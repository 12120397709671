import { IUseEntityTable } from "../ui-service.types";
import { IColumn } from "../../interfaces";
import { fetchHelpers, uiConstants, useEntityHelpers } from "../../helpers";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IExecutionContext,
  IUserContextSite,
} from "../../context";
import { useContext, useEffect } from "react";
import { useEntity } from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import {
  ExecutionTabId,
  ExecutionUiService,
  EXECUTION_ASSIGNMENTS_TABLE_SAVE_KEY,
} from "./execution-ui.service";
import { useTranslation } from "@river/common-ui";
import { IEntityObject, QueryAttributeGroupDto } from "@river/interfaces";
import { IExecutionAction } from "../../components/execution/execution";

interface IUseExecutionAssignmentsProps {
  executionContext: IExecutionContext;
}

export const useExecutionAssignments = (
  props: IUseExecutionAssignmentsProps
): IUseEntityTable => {
  const { currentSchedule, currentWorkOrder, setCurrentActions } =
    props.executionContext;
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const entityHelpers = useEntityHelpers();
  const uiService: ExecutionUiService =
    adapterContext?.service.getExecutionUiService()!;
  const currentTab: ExecutionTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const assignmentActions: IExecutionAction[] = [
    {
      label: t("common.label:timecard"),
      onClick: () => {
        console.log("Assignment Timecard");
      },
    },
  ];

  useEffect(() => {
    if (currentTab === ExecutionTabId.ASSIGNMENTS) {
      setCurrentActions(assignmentActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const entityName: string = "operation";

  const columns: IColumn[] =
    uiService.getDefaultAssignmentColumns(assignmentActions)();

  const getInitialQuery = (): QueryAttributeGroupDto => {
    if (!currentSchedule) {
      return {};
    } else {
      return uiService.getInitialQuery({
        currentSchedule: currentSchedule,
        currentWorkOrderRef: currentWorkOrder,
      });
    }
  };

  const fetchAssignments = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrder) return [];
    const assignments = await adapterContext!.service
      .getAdapterService()
      .searchEntityData("operation", {
        ...fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributeGroup: getInitialQuery(),
        }),
        $unwind: ["to_Assignment"],
      });
    assignments.forEach((as) => {
      entityHelpers.setAttributeValue(
        as,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(as, "to_Assignment._id")
      );
    });
    return assignments;
  };

  const table: IUseTable = useTable({
    saveKey: EXECUTION_ASSIGNMENTS_TABLE_SAVE_KEY,
    columns,
    fetchFunction: fetchAssignments,
    dependencies: [!!site, currentTab === ExecutionTabId.ASSIGNMENTS],
    fetchOn: currentTab === ExecutionTabId.ASSIGNMENTS,
    fetchTriggers: [site, currentWorkOrder],
    clearBeforeFetch: true,
    useAdvancedFilters: false,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
