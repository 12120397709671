import React, { SVGProps } from "react";

export const GenericAvatarIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8.00195"
        cy="8.00098"
        r="7.75"
        fill="white"
        stroke="url(#paint0_linear_3073_31229)"
        strokeWidth="0.5"
      />
      <path
        d="M8.00195 8.00195C9.10695 8.00195 10.002 7.10695 10.002 6.00195C10.002 4.89695 9.10695 4.00195 8.00195 4.00195C6.89695 4.00195 6.00195 4.89695 6.00195 6.00195C6.00195 7.10695 6.89695 8.00195 8.00195 8.00195ZM8.00195 9.00195C6.66695 9.00195 4.00195 9.67195 4.00195 11.002V12.002H12.002V11.002C12.002 9.67195 9.33695 9.00195 8.00195 9.00195Z"
        fill="url(#paint1_linear_3073_31229)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3073_31229"
          x1="16.002"
          y1="16.001"
          x2="-3.05945"
          y2="8.53524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#456CDA" />
          <stop offset="1" stopColor="#28C0DE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3073_31229"
          x1="12.002"
          y1="12.002"
          x2="2.47125"
          y2="8.26908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#456CDA" />
          <stop offset="1" stopColor="#28C0DE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
