import { RiverSpinner } from "@river/common-ui";
import { useContext, useState } from "react";
import { Column } from "react-data-grid";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
  TableContext,
} from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseEntity,
  useEntity,
  usePageCleanup,
  useTableCellRenderers,
} from "../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverDataGrid,
  useRiverSelectColumn,
  useTable,
} from "../shared";
import { CrewsGridHeader } from "./crews-grid-header";
import { CrewsHeader } from "./crews-header";
import { CrewsSubHeader } from "./crews-subheader";
import { IAdapterCrew } from "@river/interfaces";
import { EditCrewDialog } from "./edit-crew-dialog";
import dataGridStyles from "../../components/shared/river-data-grid/river-data-grid.module.scss";

export const Crews: React.FC = () => {
  usePageCleanup();

  const entityName: string = "crew";
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const { renderCell, renderCellText } = useTableCellRenderers();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const [editCrewDialogOpened, setEditCrewDialogOpened] =
    useState<boolean>(false);
  const [crewToEdit, setCrewToEdit] = useState<IAdapterCrew | null>();

  const entity: IUseEntity = useEntity({
    entityName,
  });

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "crew",
      name: t("entity.crew:crew.crew"),
      formatter: (formatterProps) => {
        return (
          <>
            {renderCell({
              formatterProps,
              content: (
                <div
                  className={dataGridStyles.dataGridLink}
                  onClick={() => {
                    table.setSelectedRowIds(new Set());
                    openEditDialog(formatterProps.row);
                  }}
                >
                  {renderCellText({ formatterProps })}
                </div>
              ),
            })}
          </>
        );
      },
    },
    { key: "description", name: t("entity.crew:crew.description") },
    {
      key: "supervisors.display_name",
      name: t("entity.crew_supervisor_ref:crew_supervisor_ref.display_name"),
    },
  ];

  const fetchCrews = async (fetchProps: ITableFetchFunctionProps) => {
    const result =
      (await adapterContext?.service
        .getAdapterService()
        .fetchCrews(fetchHelpers.getTableQuery({ fetchProps }))) || [];
    return result;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: "crews",
    columns,
    fetchFunction: fetchCrews,
    dependencies: [!!site],
    fetchOn: true,
    infiniteScrolling: true,
    rowKeyGetter: (row) => row[uiConstants.fields._id] as string,
    useAdvancedFilters: false,
  });

  const openEditDialog = (crew: IAdapterCrew): void => {
    setCrewToEdit(crew);
    setEditCrewDialogOpened(true);
  };
  const isLoading: boolean = table.isLoading || !site;
  return (
    <TableContext.Provider value={{ table, entity }}>
      <CrewsHeader />
      <CrewsSubHeader openEditDialog={openEditDialog} />
      <CrewsGridHeader />
      <RiverSpinner show={isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={(row) => row[uiConstants.fields._id]}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
        onSortColumnsChange={(e) => {
          table.setSortColumns(e);
        }}
      />
      <EditCrewDialog
        open={editCrewDialogOpened}
        crew={crewToEdit!}
        onClose={(success) => {
          setEditCrewDialogOpened(false);
          if (success) table.refresh();
        }}
      />
    </TableContext.Provider>
  );
};
