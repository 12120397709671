import React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "@river/common-ui";
import styles from "./logout-callback.module.scss";

export const LogoutCallback: React.FC = () => {
  const { t } = useTranslation("component.logout_callback");

  return (
    <div className={styles.container}>
      {t("component.logout_callback:close_window_message")}
      <br />
      <br />
      <PrimaryButton variant="contained" color="primary" href="/login">
        {t("component.logout_callback:button.login")}
      </PrimaryButton>
    </div>
  );
};
