import {
  ITableFetchFunctionProps,
  ILookupDefinition,
} from "../../components/shared";
import { AdapterUserContextProp, IUserContextSite } from "../../context";
import { fetchHelpers, uiConstants } from "../../helpers";
import {
  IUseOperationsLookupProps,
  OperationsUiService,
} from "../operations-ui.service";
import { SitesUiService } from "../sites-ui.service";

export class OracleEbsOperationsUiService extends OperationsUiService {
  getOperationsLookupDefinition = (
    props: IUseOperationsLookupProps
  ): ILookupDefinition => {
    const site: IUserContextSite =
      props.userContext?.userProperties[AdapterUserContextProp.SITE];
    const { t, adapterContext, RiverSelectColumn } = props;
    const SitesUiService: SitesUiService =
      adapterContext?.service.getSitesUiService()!;
    return {
      title: t("shared.lookup:operation.title"),
      rowKeyGetter: (row) => String(row[uiConstants.fields._id]),
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "WIP_ENTITY_NAME",
            name: t("entity.operation:operation.WIP_ENTITY_NAME"),
            width: 105,
          },
          {
            key: "OPERATION_SEQ_NUM",
            name: t("entity.operation:operation.OPERATION_SEQ_NUM"),
          },
          {
            key: "DESCRIPTION",
            name: t("entity.operation:operation.DESCRIPTION"),
          },
          {
            key: "DEPARTMENT_CODE",
            name: t("entity.operation:operation.DEPARTMENT_CODE"),
          },
          {
            key: "SHUTDOWN_TYPE_DISP",
            name: t("entity.operation:operation.SHUTDOWN_TYPE_DISP"),
          },
          {
            key: "WorkOrder.INSTANCE_NUMBER",
            name: t(
              "entity.operation_workorder:operation_workorder.INSTANCE_NUMBER"
            ),
          },
          {
            key: "WorkOrder.WORK_ORDER_STATUS",
            name: t(
              "entity.operation_workorder:operation_workorder.WORK_ORDER_STATUS"
            ),
          },
          {
            key: "FIRST_UNIT_START_DATE",
            name: t("entity.operation:operation.FIRST_UNIT_START_DATE"),
          },
          {
            key: "LAST_UNIT_COMPLETION_DATE",
            name: t("entity.operation:operation.LAST_UNIT_COMPLETION_DATE"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          return await adapterContext.service
            .getAdapterService()
            .searchEntityData(
              "operation",
              fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [
                  {
                    attribute_name: SitesUiService.getSiteKeyColumn(),
                    attribute_value: { operator: "$eq", value: site.site_id },
                  },
                ],
              })
            );
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "operation",
      },
    };
  };
}
