import { FC, useState, MouseEvent, useContext } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { Popover } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs, { Dayjs, ManipulateType } from "dayjs";
import { useTranslation } from "@river/common-ui";
import { SupervisorScheduleContext } from "../../../../../context";
import { SupervisorScheduleGanttTimeOptionGroupID } from "../supervisor-schedule-gantt-time-options";
import styles from "./supervisor-schedule-gantt-calendar-selector.module.scss";

export const SupervisorScheduleGanttCalendarSelector: FC = () => {
  const { t } = useTranslation();
  const scheduleContext = useContext(SupervisorScheduleContext);

  const ganttStartDate = scheduleContext!.selectedGanttDate;
  const calStartDate = dayjs(ganttStartDate)
    .add(ganttStartDate!.getTimezoneOffset(), "minutes")
    .toDate();

  const [anchorElGanttCalendar, setAnchorElGanttCalendar] =
    useState<HTMLDivElement | null>(null);
  const dayJsUnitMapping: {
    [key in SupervisorScheduleGanttTimeOptionGroupID]: ManipulateType;
  } = {
    [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: "day",
    [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: "hour",
    [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: "month",
    [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: "week",
  };

  const handleClickGanttCalendar = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorElGanttCalendar(event.currentTarget);
  };

  const handleCloseGanttCalendar = () => {
    setAnchorElGanttCalendar(null);
  };

  const handleGoForward = () => {
    const { selectedGanttDate, ganttTimeOption } = scheduleContext!;
    const { groupId, value } = ganttTimeOption!;
    const dayJsUnit: ManipulateType = dayJsUnitMapping[groupId];
    const newDate: Date = dayjs(selectedGanttDate)
      .add(value, dayJsUnit)
      .toDate();
    scheduleContext?.setSelectedGanttDate(newDate);
  };

  const handleGoBackward = () => {
    const { selectedGanttDate, ganttTimeOption } = scheduleContext!;
    const { groupId, value } = ganttTimeOption!;
    const dayJsUnit: ManipulateType = dayJsUnitMapping[groupId];
    const newDate: Date = dayjs(selectedGanttDate)
      .subtract(value, dayJsUnit)
      .toDate();
    scheduleContext?.setSelectedGanttDate(newDate);
  };

  function handleOnAccept(value: Dayjs | null) {
    if (value) {
      scheduleContext?.setSelectedGanttDate(
        value.subtract(calStartDate.getTimezoneOffset(), "minutes").toDate()
      );
    }
    handleCloseGanttCalendar();
  }

  const openGanttCalendar = !!anchorElGanttCalendar;
  const idGanttCalendar = openGanttCalendar
    ? "gantt-calendar-popover"
    : undefined;
  return (
    <>
      <div className={styles.root}>
        <div className={styles.icon_container} onClick={handleGoBackward}>
          <ChevronLeftOutlinedIcon fontSize="small" />
        </div>
        <div
          aria-describedby={idGanttCalendar}
          className={styles.icon_container}
          onClick={handleClickGanttCalendar}
        >
          <CalendarMonthRoundedIcon fontSize="small" />
        </div>
        <Popover
          id={idGanttCalendar}
          open={openGanttCalendar}
          anchorEl={anchorElGanttCalendar}
          onClose={handleCloseGanttCalendar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StaticDatePicker
            onAccept={handleOnAccept}
            className={styles.calendar}
            slotProps={{
              toolbar: { className: styles.calendar_toolbar },
              day: {
                classes: {
                  selected: styles.calendar_selected_day,
                  today: styles.calendar_today,
                },
              },
              actionBar: {
                classes: {
                  root: styles.calendar_action_btn,
                },
              },
            }}
            localeText={{
              cancelButtonLabel: t("common.button:cancel"),
              okButtonLabel: t("common.button:apply"),
              toolbarTitle: t("common.label:select_date"),
            }}
            //defaultValue={dayjs(scheduleContext?.selectedGanttDate)}
            defaultValue={dayjs(calStartDate)}
            onClose={handleCloseGanttCalendar}
          />
        </Popover>
        <div className={styles.icon_container} onClick={handleGoForward}>
          <ChevronRightOutlinedIcon fontSize="small" />
        </div>
      </div>
    </>
  );
};
