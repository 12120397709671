import React from "react";
import { GridHeader, GridActions, FilterDropdown } from "../../../shared";
import styles from "./user-roles-dialog-grid-header.module.scss";
import clsx from "clsx";
import { useTranslation } from "@river/common-ui";

interface IUserRolesDialogGridHeaderProps {
  className?: string;
}

export const UserRolesDialogGridHeader: React.FC<
  IUserRolesDialogGridHeaderProps
> = (props) => {
  const { t } = useTranslation();

  return (
    <GridHeader
      title={t("entity.role:role.role")}
      className={clsx([styles.root, props.className])}
    >
      <GridActions>
        <FilterDropdown />
      </GridActions>
    </GridHeader>
  );
};
