import React, { useState, useEffect, useContext, ReactElement } from "react";
import { IAdapterFolder, QueryDto } from "@river/interfaces";

import { Box } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { CraftStats, ICraftStatProps } from "../../../shared";

import { useNavigate, useParams } from "react-router";
import { helpers, uiConstants } from "../../../../helpers";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import {
  BacklogAction,
  BacklogUiService,
  CraftsUiService,
  ScheduleUtilizationUiService,
} from "../../../../services";
import { ModuleKey } from "../../../sidebar-menu";
import ProtectedAction from "../../../protected-action";
import dataGridStyles from "../../../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./schedule-item.module.scss";
import clsx from "clsx";

export enum ScheduleItemColor {
  RED = "red",
  BLUE = "blue",
  GREEN = "green",
  PURPLE = "purple",
  ORANGE = "orange",
  LIGHT_BLUE = "lightBlue",
  GRASS_GREEN = "grassGreen",
  LIGHT_PURPLE = "lightPurple",
}
export const randomScheduleItemColors: ScheduleItemColor[] = [
  ScheduleItemColor.RED,
  ScheduleItemColor.BLUE,
  ScheduleItemColor.GREEN,
  ScheduleItemColor.PURPLE,
  ScheduleItemColor.ORANGE,
  ScheduleItemColor.LIGHT_BLUE,
  ScheduleItemColor.GRASS_GREEN,
  ScheduleItemColor.LIGHT_PURPLE,
];
const DEFAULT_COLOR: ScheduleItemColor = ScheduleItemColor.RED;

interface IScheduleItemProps {
  schedule: IAdapterFolder;
  doSchedule: (schedule: IAdapterFolder, workOrderIds?: string[]) => void;
  color?: ScheduleItemColor;
  onRefreshRequired: () => void;
  onRemoveSchedule: () => void;
  selectedCraftIds: Set<string>;
}

export const ScheduleItem: React.FC<IScheduleItemProps> = (props) => {
  const selectedScheduleId = useParams<{ schedule_id: string }>().schedule_id;
  const isItemSelected = selectedScheduleId === props.schedule._id;
  const notify = useNotification();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [craftStats, setCraftStats] = useState<Array<ICraftStatProps>>([]);

  const navigate = useNavigate();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const scheduleUtilizationUiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const backlogUiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const craftsUiService: CraftsUiService =
    adapterContext?.service!.getCraftsUiService()!;
  const backlogRoute: string = backlogUiService.getBacklogRoute()();
  const dndEvents = {
    onDrop: async (e: React.DragEvent<any>): Promise<void> => {
      const data = helpers.parseDragData(e);
      const workOrderIds = data.workOrderIds as string[];
      props.doSchedule(props.schedule, workOrderIds);
      e.preventDefault();
    },
    onDragEnter: (e: React.DragEvent<any>): void => {
      //...
    },
    onDragLeave: (e: React.DragEvent<any>): void => {
      //...
    },
  };

  const scheduleNameClick = (e: React.MouseEvent<any>): void => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/schedules/${props.schedule._id}`);
  };

  const removeSchedule = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    props.onRemoveSchedule();
  };

  const renderAccordion = (): ReactElement => (
    <MuiAccordion
      square
      expanded={expanded}
      onChange={async () => {
        if (!expanded) {
          await fetchWcUtilization();
        }
        setExpanded(!expanded);
      }}
      classes={{
        root: styles.accordionRoot,
        expanded: styles.expanded,
      }}
    >
      <MuiAccordionSummary
        classes={{
          root: styles.accordionSummaryRoot,
          content: styles.accordionSummaryContent,
          expanded: styles.expanded,
        }}
      >
        <div className={styles.captionContainer}>
          {expanded ? (
            <KeyboardArrowUpIcon classes={{ root: styles.toggleIcon }} />
          ) : (
            <KeyboardArrowDownIcon classes={{ root: styles.toggleIcon }} />
          )}
          <div
            className={clsx([
              styles.scheduleCaption,
              dataGridStyles.dataGridLink,
            ])}
            onClick={scheduleNameClick}
          >
            <div title={props.schedule.folder} className={styles.scheduleName}>
              {props.schedule.folder}
            </div>
            <div
              className={styles.itemsCount}
            >{`(${props.schedule.workorder_count})`}</div>
          </div>
        </div>
        <CloseIcon
          className={clsx([styles.removeScheduleIcon])}
          onClick={removeSchedule}
        />
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
        <CraftStats items={craftStats} />
      </MuiAccordionDetails>
    </MuiAccordion>
  );

  const fetchWcUtilization = async () => {
    const craftStatLabelColumn =
      scheduleUtilizationUiService.getCraftUtilizationStatLabelColumn();

    const queryWithIds: QueryDto = {
      query: craftsUiService.getSelectedCraftsQuery(
        Array.from(props.selectedCraftIds)
      ),
    };

    try {
      setIsLoading(true);
      let result = await adapterContext!.service
        .getAdapterService()
        .fetchCraftUtilization(
          props.schedule._id,
          null,
          props.schedule.start_date,
          "FOLDER",
          0,
          queryWithIds
        );
      let wcTotalUtilizationResults: Array<ICraftStatProps> = [];

      result.forEach((wcItem) => {
        let newObj: ICraftStatProps = {
          label: wcItem[craftStatLabelColumn],
          value: Number(
            (
              Math.round(wcItem.utilization[0].scheduled_hours * 100) / 100
            ).toFixed(2)
          ),
          outOf: Number(
            (
              Math.round(wcItem.utilization[0].available_hours * 100) / 100
            ).toFixed(2)
          ),
        };
        wcTotalUtilizationResults.push(newObj);
      });

      setCraftStats(wcTotalUtilizationResults);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const renderWOListButton = (): ReactElement => (
    <Button
      onClick={() => {
        navigate(`${backlogRoute}/${props.schedule[uiConstants.fields._id]}`);
      }}
      classes={{
        root: clsx([styles.footerButtonRoot, styles.woListButton]),
      }}
    >
      Work Orders List
    </Button>
  );

  const renderBackToBacklogButton = (): ReactElement => (
    <Button
      startIcon={<CloseIcon className={styles.backToLogIconRoot} />}
      onClick={() => {
        navigate(backlogRoute);
      }}
      classes={{
        root: clsx([styles.footerButtonRoot, styles.woListButton]),
        startIcon: styles.backToBacklogIcon,
      }}
    >
      BACK
    </Button>
  );

  const renderScheduleButton = (): ReactElement => (
    <Button
      onClick={() => props.doSchedule(props.schedule)}
      classes={{
        root: clsx([styles.footerButtonRoot, styles.scheduleButton]),
      }}
    >
      Schedule
    </Button>
  );

  const renderFooter = (): ReactElement => (
    <div className={styles.footer}>
      {isItemSelected ? renderBackToBacklogButton() : renderWOListButton()}
      <ProtectedAction
        module={ModuleKey.BACKLOG}
        action={BacklogAction.SCHEDULE}
      >
        <div className={styles.dividerContainer}>
          <Divider
            orientation="vertical"
            classes={{ root: styles.dividerRoot }}
          />
        </div>
        {renderScheduleButton()}
      </ProtectedAction>
    </div>
  );

  useEffect(() => {
    if (expanded) {
      fetchWcUtilization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.schedule.workorder_count]);

  return (
    <Box
      className={clsx([
        styles.root,
        props.color || DEFAULT_COLOR,
        { [styles.selected]: isItemSelected },
      ])}
      {...dndEvents}
    >
      <div className={styles.colorMarker} />
      <div className={styles.body}>
        {renderAccordion()}
        {renderFooter()}
      </div>
      <RiverSpinner show={isLoading} />
    </Box>
  );
};
