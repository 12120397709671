import React, { ReactElement, useState } from "react";
import { JobPriorityRules } from "./job-priority-rules";
import { ITranslationProps } from "@river/common-ui";
import { withTranslation } from "react-i18next";

const ModuleWrapper: React.FC<ITranslationProps> = (props): ReactElement => (
  <>{props.tReady && <JobPriorityRules />}</>
);

export const JobPriorityRulesModule: React.FC = (): ReactElement => {
  const namespaces: string[] = [
    "module.rules",
    "shared.planning_plant_selector",
    "shared.user_menu",
    "shared.column_selector",
    "shared.river_color_picker",
    "shared.grid_header",
    "shared.advanced_filters",
    "shared.saved_filters_dialog",
    "shared.save_filters_dialog",
    "shared.error_messages",
    "shared.river_table_selector",
    "common.button",
    "common.label",
    "entity.rule",
    "entity.rule_action_ref",
    "shared.river_data_grid",
  ];

  const [Component] = useState<React.ComponentType<any>>(() =>
    withTranslation(namespaces)(ModuleWrapper)
  );

  return <Component />;
};
