import React, { ReactElement, useContext } from "react";
import { GenericAction } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useNotification } from "@river/common-ui";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useGridActionHelpers } from "../../../helpers";

export const useCheckMaterialShortageAction = () => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const title: string = t(
    "module.supervisor_schedule:action_label.check_material_shortage"
  );

  const onClick = (): void => {
    adapterContext!.service
      .getAdapterService()
      .checkMaterialShortage(Array.from(tableContext?.table.selectedRowIds!))
      .then(() => {
        tableContext?.table.refresh();
        notify.success(
          t(
            "module.supervisor_schedule:notification.success_check_material_shortage"
          )
        );
      })
      .catch((message) => {
        notify.error({ message });
      });
  };

  const renderCheckMaterialShortageAction = (props?: any): ReactElement => (
    <GenericAction
      icon={FeedbackIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  return {
    renderCheckMaterialShortageAction,
    checkMaterialShortageAction: {
      icon: withSanitizedProps(renderCheckMaterialShortageAction),
      title,
      onClick,
    },
  };
};

export type IUseCheckMaterialShortageAction = ReturnType<
  typeof useCheckMaterialShortageAction
>;
