import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { IEntityObject } from "@river/interfaces";
import { useContext } from "react";
import { IAdapterUiContextState, AdapterUiContext } from "../../../../context";
import { useTranslation } from "@river/common-ui";
import {
  AdapterUiService,
  AdapterService,
  IExternalResource,
  IUseExternalResourcesFormProps,
} from "../../../../services";
import { useAllowedAction } from "../../../../components/protected-action";
import { ModuleKey } from "../../../../components/sidebar-menu";
import { ExternalResourceAction } from "../../../../components/external-resources";
import { ArrayNotEmpty, IsNotEmpty } from "class-validator";

export const useJdeExternalResourceForm = (
  props: IUseExternalResourcesFormProps
): RiverFormInstance => {
  const { t } = useTranslation();

  class JdeExternalResourceDto implements IExternalResource {
    @IsNotEmpty()
    // @ts-ignore
    Agreement: string;

    @IsNotEmpty()
    // @ts-ignore
    F0101_ALPH: string;

    @ArrayNotEmpty({
      message: t(
        "module.external_resource:notification.needs_at_least_one_craft",
        {
          craft: t("shared.crafts:label.craft"),
        }
      ),
    })
    // @ts-ignore
    WorkCenters: IEntityObject[];

    // Other ERP specific attributes
    [s: string]: any;
  }

  const isActionAllowed = useAllowedAction();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const adapterUiService: AdapterUiService = adapterContext?.service!;
  const adapterService: AdapterService = adapterUiService.getAdapterService();

  return useRiverForm<JdeExternalResourceDto, IExternalResource, Object>({
    initialDto: Object.assign(new JdeExternalResourceDto(), {
      Agreement: "",
      F0101_ALPH: "",
      WorkCenters: [],
    }),
    entityName: "external_resource",
    instanceToEdit: props.resource,
    onCreate: props.onCreate,
    onUpdate: props.onUpdate,
    create: async (dto: JdeExternalResourceDto): Promise<IExternalResource> => {
      return (await adapterService.createExternalResource(
        dto
      )) as IExternalResource;
    },
    update: async (dto: JdeExternalResourceDto): Promise<void> => {
      await adapterService.updateExternalResource(
        props.resource?._id! as string,
        dto
      );
    },
    labels: {
      Agreement: t("entity.external_resource:external_resource.Agreement"),
      F0101_ALPH: t("shared.crafts:label.external_resource"),
    },
    readOnly: !isActionAllowed(
      ModuleKey.EXTERNAL_RESOURCES,
      ExternalResourceAction.UPDATE
    ),
  });
};
