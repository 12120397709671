import { FC, useContext } from "react";
import { SidebarMenu } from "../sidebar-menu";
import { SidebarContext } from "../../context";
import { Stats } from "./stats";
import { Button } from "@mui/material";
import {
  LogoutIcon,
  ITranslationProps,
  RiverSidebar,
  useEnv,
} from "@river/common-ui";
import { withTranslation, useTranslation } from "react-i18next";
import styles from "./sidebar.module.scss";
import clsx from "clsx";

interface ISidebar extends ITranslationProps {
  className?: string;
}

const Sidebar: FC<ISidebar> = (props) => {
  const { t } = useTranslation();
  const sidebarContext = useContext(SidebarContext)!;
  const { tReady: translationsReady } = props;
  const env = useEnv();
  const { isMobile } = env;

  if (!translationsReady) return <></>;

  return (
    <>
      <RiverSidebar
        context={sidebarContext}
        className={clsx([
          styles.sidebar,
          props.className,
          { [styles.mobile]: isMobile },
        ])}
      >
        {sidebarContext?.isShowingStats ? (
          <Stats />
        ) : (
          <>
            <SidebarMenu className={styles.menu} />
            <Button
              classes={{
                root: styles.logoutButton,
              }}
              href="/Logout"
              disableRipple={true}
              startIcon={<LogoutIcon className={styles.logoutIcon} />}
            >
              <div className={styles.logoutLabel}>
                {t("component.sidebar:button.logout")}
              </div>
            </Button>
          </>
        )}
      </RiverSidebar>
    </>
  );
};

export default withTranslation(["component.sidebar"])(Sidebar);
