import React, { useState, useEffect, ReactElement, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { RiverDialog, RiverFormContext } from "@river/common-ui";
import { RiverFormInstance } from "../../../hooks";
import { userPreferencesService } from "../../../services";
import chroma from "chroma-js";
import { RiverTextInput } from "@river/common-ui";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { RiverIconButton } from "../river-icon-button";
import { useTranslation } from "@river/common-ui";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styles from "./river-color-picker.module.scss";
import clsx from "clsx";

const DEFAULT_COLOR: string = "#55a1c4";
const DEFAULT_SHOW_COPY_BUTTON: boolean = true;

export interface IRiverColorPickerProps {
  title?: string;
  dialogTitle?: string;
  initialColor: string;
  className?: string;
  classes?: {
    previewContainer?: string;
  };
  renderPreview?: (color: string) => ReactElement; // renders entire preview
  defaultPreviewContent?: (color: string) => ReactElement; // renders withing default preview container
  showCopyButton?: boolean; // show copy button in default preview
  onSelectColor: (color: string) => void;
  withoutTitle?: boolean;
}

const colorPresets = [
  "#ffff00",
  "#ffa500",
  "#008000",
  "#ff0000",
  "#800080",
  "#0000ff",
  "#a52a2a",
];

export const RiverColorPicker: React.FC<IRiverColorPickerProps> = (props) => {
  const { t } = useTranslation();
  const DEFAULT_TITLE = t("shared.river_color_picker:title");
  const DEFAULT_DIALOG_TITLE = t("shared.river_color_picker:dialog.title");
  const title: string = props.title || DEFAULT_TITLE;
  const dialogTitle: string = props.dialogTitle || DEFAULT_DIALOG_TITLE;
  const isDefaultPreview: boolean = !props.renderPreview;
  const showCopyButton: boolean =
    props.showCopyButton ?? (DEFAULT_SHOW_COPY_BUTTON && isDefaultPreview);

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const [color, setColor] = useState<string>(props.initialColor);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [recentColors, setRecentColors] = useState<string[]>([]);
  const form: RiverFormInstance = useContext(RiverFormContext)
    ?.form as RiverFormInstance;

  const renderPalette = (
    colors: string[],
    className?: string
  ): ReactElement => (
    <div className={clsx([styles.colorPalette, className])}>
      {colors.map((preUsed, index) => (
        <button
          key={index}
          className={styles.colorButton}
          style={{ background: preUsed }}
          onClick={() => setColor(preUsed)}
        />
      ))}
    </div>
  );

  const renderDefaultPreview = (color: string): ReactElement => (
    <div
      className={styles.defaultColorPreview}
      style={{ backgroundColor: color }}
    >
      {props.defaultPreviewContent?.(color)}
    </div>
  );

  const renderPreview = (color: string): ReactElement => (
    <div
      className={clsx([
        styles.previewContainer,
        props.classes?.previewContainer,
      ])}
      onClick={() => {
        if (!form?.isReadOnly) {
          setShowDialog(true);
        }
      }}
    >
      {props.renderPreview?.(color) || renderDefaultPreview(color)}
    </div>
  );

  useEffect(() => {
    setColor(props.initialColor);
  }, [props.initialColor]);

  useEffect(() => {
    if (showDialog) {
      const loadRecentRuleColors = async (): Promise<void> => {
        const colors: string[] =
          await userPreferencesService.getRecentRuleColors(
            adapterContext!.service.getAdapterService()
          );
        setRecentColors(colors || []);
      };
      loadRecentRuleColors();
    } else {
      setColor(props.initialColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  const copyColorToClipboard = (): void => {
    navigator.clipboard.writeText(color);
  };

  return (
    <div
      className={clsx([
        styles.root,
        props.className,
        { [styles.readOnly]: form?.isReadOnly },
      ])}
    >
      {!props.withoutTitle && (
        <div className={styles.selectorTitle}>{title}</div>
      )}

      <div className={styles.selectorContainer}>
        {renderPreview(props.initialColor)}
        {showCopyButton && (
          <RiverIconButton
            label={t("shared.river_color_picker:button.copy")}
            icon={ContentCopyIcon}
            onClick={() => copyColorToClipboard()}
            className={styles.showColorButton}
          />
        )}
      </div>

      <RiverDialog
        open={showDialog}
        title={dialogTitle}
        showTitleDivider={false}
        onClose={() => {
          setShowDialog(false);
        }}
        classes={{
          paper: styles.dialogPaper,
          content: styles.dialogContent,
        }}
        actionButtonText={t("common.button:select")}
        onSubmit={() => {
          if (color === props.initialColor) {
            setShowDialog(false);
            return;
          }
          if (chroma.valid(color)) {
            if (recentColors.length < colorPresets.length) {
              recentColors.push(color);
            } else {
              recentColors.pop();
              recentColors.splice(0, 0, color);
            }
            userPreferencesService.setRecentRuleColors(
              adapterContext!.service.getAdapterService(),
              recentColors
            );
            props.onSelectColor(color);
            setShowDialog(false);
          }
        }}
      >
        {renderPreview(color)}
        <div className={styles.colorPickerContainer}>
          <HexColorPicker
            className={styles.colorPicker}
            color={
              chroma.valid(color)
                ? chroma(color).hex()
                : chroma(DEFAULT_COLOR).hex()
            }
            onChange={setColor}
          />
          {renderPalette(colorPresets, styles.predefined)}
          {renderPalette(recentColors)}
          <RiverTextInput
            id={"color"}
            value={color}
            className={styles.selectedColor}
            onChange={(value: string) => {
              setColor(value);
            }}
            error={!chroma.valid(color)}
            fullWidth={true}
            inputProps={{
              className: styles.colorInput,
              endAdornment: (
                <IconButton
                  onClick={() => copyColorToClipboard()}
                  title={t("shared.river_color_picker:button.copy")}
                >
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
          />
        </div>
      </RiverDialog>
    </div>
  );
};
