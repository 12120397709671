import { AdapterAvailabilityUpdateDto } from "@river/interfaces";
import { useRiverForm, RiverFormInstance } from "../../../hooks";
import { useTranslation } from "@river/common-ui";

export const useAvailabilityScheduleForDialogForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();

  return useRiverForm<Object, Object, AdapterAvailabilityUpdateDto>({
    standalone: {
      fields: Object.assign(new AdapterAvailabilityUpdateDto(), {
        start_date: new Date(),
        end_date: new Date(),
        available_hours: 0,
        shift_id: "",
        availability_header_id: "",
      }),
      fieldDefs: [
        { fieldName: "start_date", dataType: "date" },
        { fieldName: "end_date", dataType: "date" },
        { fieldName: "available_hours", dataType: "number" },
        { fieldName: "shift_id", dataType: "string" },
        { fieldName: "availability_header_id", dataType: "string" },
      ],
    },
    submit: props.submit,
    labels: {
      start_date: t(
        "module.availability:availability_schedule_for_dialog.start_date"
      ),
      end_date: t(
        "module.availability:availability_schedule_for_dialog.end_date"
      ),
      available_hours: t(
        "module.availability:availability_schedule_for_dialog.hours"
      ),
      shift_id: t("module.availability:availability_schedule_for_dialog.shift"),
    },
  });
};
