import { FC, useState, useContext } from "react";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  CustomCronTasksContext,
  TableContext,
} from "../../../context";
import { Column } from "react-data-grid";

import { IEntityObject, ISystemEnvCronTask } from "@river/interfaces";
import { RiverSpinner, useTranslation } from "@river/common-ui";
import { IUseEntity, useEntity, useTableCellRenderers } from "../../../hooks";
import {
  ITableFetchFunctionProps,
  IUseTable,
  RiverSwitch,
  RiverDataGrid,
  useTable,
  useRiverSelectColumn,
} from "../../shared";
import { fetchHelpers, uiConstants } from "../../../helpers";
import { useAllowedAction } from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { CustomCronTasksGridHeader } from "./custom-cron-tasks-grid-header";
import { CustomCronTaskDialog } from "./custom-cron-task-dialog";
import { customCronTasksEntityDef } from "./custom-cron-task-def";
import dataGridStyles from "../../shared/river-data-grid/river-data-grid.module.scss";
import styles from "./custom-cron-tasks.module.scss";
import clsx from "clsx";

export enum ModuleActionCustomCronTasks {
  CREATE_CUSTOM_CRON_TASKS = "CREATE_CUSTOM_CRON_TASKS",
  UPDATE_CUSTOM_CRON_TASKS = "UPDATE_CUSTOM_CRON_TASKS",
  DELETE_CUSTOM_CRON_TASKS = "DELETE_CUSTOM_CRON_TASKS",
}

enum CronTaskStatus {
  IDLE = "idle",
  SUCCESS = "success",
  FAILURE = "failure",
  RUNNING = "running",
}

export const CustomCronTasks: FC = () => {
  const entityName: string = '"custom_cron_tasks"';
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selected, setSelected] = useState<ISystemEnvCronTask | null>(null);
  const isActionAllowed = useAllowedAction();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];
  const { renderCell, renderCellText } = useTableCellRenderers();

  const statusTranslationsMap: { [status in CronTaskStatus]: string } = {
    [CronTaskStatus.IDLE]: t("module.cron:core_status.idle"),
    [CronTaskStatus.SUCCESS]: t("module.cron:core_status.success"),
    [CronTaskStatus.FAILURE]: t("module.cron:core_status.failure"),
    [CronTaskStatus.RUNNING]: t("module.cron:core_status.running"),
  };

  const entity: IUseEntity = useEntity({
    entityName,
    definition: customCronTasksEntityDef,
  });

  function handleOnClickCronTaskCode(data: ISystemEnvCronTask) {
    table.setSelectedRowIds(new Set());
    setSelected(data);
    setOpenDialog(true);
  }

  async function handleOnChangeEnabled(data: ISystemEnvCronTask) {
    try {
      await adapterContext!.service
        .getAdapterService()
        .updateCustomCronTask(data?.task_code!, {
          ...data,
          enabled: !data?.enabled,
        });
      table.refresh();
    } catch (error) {
      console.error(error);
    }
  }

  const renderStatus = (status: CronTaskStatus) => {
    const StatusComponent = (props: { className: string; name: string }) => (
      <div className={styles.statusCell}>
        <span className={clsx([props.className, styles.status])}>
          {props.name}
        </span>
      </div>
    );

    switch (status) {
      case CronTaskStatus.IDLE:
        return (
          <StatusComponent
            name={statusTranslationsMap[CronTaskStatus.IDLE]}
            className={styles.status_idle}
          />
        );
      case CronTaskStatus.SUCCESS:
        return (
          <StatusComponent
            name={statusTranslationsMap[CronTaskStatus.SUCCESS]}
            className={styles.status_success}
          />
        );
      case CronTaskStatus.FAILURE:
        return (
          <StatusComponent
            name={statusTranslationsMap[CronTaskStatus.FAILURE]}
            className={styles.status_failure}
          />
        );
      case CronTaskStatus.RUNNING:
        return (
          <StatusComponent
            name={statusTranslationsMap[CronTaskStatus.RUNNING]}
            className={styles.status_running}
          />
        );
    }
  };

  const columns: Column<any>[] = [
    RiverSelectColumn,
    {
      key: "task_code",
      name: t("module.cron:cron_entity.task_code"),
      width: 150,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div
                className={dataGridStyles.dataGridLink}
                onClick={() => {
                  if (
                    isActionAllowed(
                      ModuleKey.CRON_TASKS,
                      ModuleActionCustomCronTasks.UPDATE_CUSTOM_CRON_TASKS
                    )
                  ) {
                    handleOnClickCronTaskCode(formatterProps.row);
                  }
                }}
              >
                {renderCellText({ formatterProps })}
              </div>
            ),
          })}
        </>
      ),
    },
    { key: "name", name: t("module.cron:cron_entity.name") },
    { key: "description", name: t("module.cron:cron_entity.description") },
    { key: "action", name: t("module.cron:cron_entity.action") },
    {
      key: "frequency",
      name: t("module.cron:cron_entity.frequency"),
      width: 160,
    },
    {
      key: "last_execution_date",
      name: t("module.cron:cron_entity.last_execution_date"),
    },
    {
      key: "status.code",
      name: t("module.cron:status_ref.code"),
      width: 140,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: renderStatus(formatterProps.row?.status?.code),
            tooltip:
              statusTranslationsMap[
                formatterProps.row?.status?.code as CronTaskStatus
              ],
          })}
        </>
      ),
    },
    {
      key: "enabled",
      name: t("module.cron:cron_entity.enabled"),
      width: 80,
      formatter: (formatterProps) => (
        <>
          {renderCell({
            formatterProps,
            content: (
              <div className={styles.enabledCell}>
                <RiverSwitch
                  checked={formatterProps.row.enabled}
                  onChange={() => handleOnChangeEnabled(formatterProps.row)}
                  disabled={
                    !isActionAllowed(
                      ModuleKey.CRON_TASKS,
                      ModuleActionCustomCronTasks.UPDATE_CUSTOM_CRON_TASKS
                    )
                  }
                />
              </div>
            ),
            tooltip: formatterProps.row.enabled
              ? t("common.label:true")
              : t("common.label:false"),
          })}
        </>
      ),
    },
  ];

  const fetchCustomCronTasks = async (fetchProps: ITableFetchFunctionProps) => {
    return (await adapterContext!.service
      .getAdapterService()
      .fetchCustomCronTasks(
        fetchHelpers.getTableQuery({ fetchProps })
      )) as unknown as Promise<IEntityObject[]>;
  };

  const table: IUseTable = useTable({
    entityName,
    saveKey: "custom_cron_tasks",
    columns,
    infiniteScrolling: true,
    fetchFunction: fetchCustomCronTasks,
    fetchTriggers: [site],
    fetchOn: true,
  });

  const isLoading = table.isLoading;

  return (
    <>
      <CustomCronTasksContext.Provider
        value={{
          openDialog,
          setOpenDialog,
          selected,
          setSelected,
        }}
      >
        <TableContext.Provider value={{ table, entity }}>
          <CustomCronTasksGridHeader />
          <RiverSpinner show={isLoading} />
          <RiverDataGrid
            disableSelectAll={true}
            singleSelect={true}
            columns={table.columns}
            rows={table.entities}
            rowKeyGetter={(row) => row[uiConstants.fields._id]}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            sortColumns={table.sortColumns}
            onSortColumnsChange={(e) => {
              table.setSortColumns(e);
            }}
          />
          {!!openDialog && <CustomCronTaskDialog />}
        </TableContext.Provider>
      </CustomCronTasksContext.Provider>
    </>
  );
};
