import React, { ReactElement, useContext } from "react";
import { RiverDropdownActions, IRiverDropdownActionsItem } from "../index";
import CheckIcon from "@mui/icons-material/Check";
import { BlankIcon } from "../../../icons";
import { IUseTable } from "../../shared";
import { IColumnLayout, ITablePreferences } from "../../../interfaces";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { userPreferencesService } from "../../../services";
import { useNotification } from "@river/common-ui";

const DEFAULT_SHOULD_APPLY_LAYOUT = true;

interface IColumnLayoutDropdown {
  defaultAction: IRiverDropdownActionsItem;
  table: IUseTable;
  selectedLayout?: IColumnLayout;
  onSelectLayout?: (layout: IColumnLayout) => void;
  shouldApplySelectedLayout?: boolean;
}

export const ColumnLayoutDropdown: React.FC<IColumnLayoutDropdown> = (
  props
): ReactElement => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const selectedLayout: IColumnLayout =
    props.selectedLayout || props.table.currentLayout!;
  const notify = useNotification();

  const onSelectLayout = (layout: IColumnLayout): void => {
    if (layout.name === selectedLayout?.name) return;

    const shouldApplyLayout: boolean =
      props.shouldApplySelectedLayout ?? DEFAULT_SHOULD_APPLY_LAYOUT;
    if (shouldApplyLayout) {
      applyLayout(layout);
    }
    props.onSelectLayout?.(layout);
  };

  const applyLayout = async (selectedLayout: IColumnLayout): Promise<void> => {
    const tableSaveKey: string = props.table.dbSaveKey;
    const tablePreferences: ITablePreferences =
      await userPreferencesService.getTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
      });
    tablePreferences.columns = selectedLayout.columns;
    tablePreferences.currentLayoutName = selectedLayout.name;
    try {
      await userPreferencesService.setTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey,
        tablePreferences,
      });
      props.table.applyTablePreferences(tablePreferences);
    } catch (message) {
      notify.error({ message });
    }
  };

  const items: IRiverDropdownActionsItem[] = [
    {
      ...props.defaultAction,
    },
    ...props.table.layouts.map((layout) => {
      return {
        title: layout.name,
        startIcon: layout.name === selectedLayout?.name ? CheckIcon : BlankIcon,
        onClick: () => onSelectLayout(layout),
      };
    }),
  ];

  return <RiverDropdownActions items={items} />;
};
