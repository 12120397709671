import React, { ReactElement, useContext } from "react";
import { SupervisorScheduleGanttTimeOptions } from "./supervisor-schedule-gantt-time-options";
import {
  SupervisorScheduleGanttToggle,
  SupervisorScheduleTasksViewId,
  SupervisorScheduleTasksViewURLParamName,
  DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW,
} from "./supervisor-schedule-gantt-toggle";
import { SupervisorScheduleGanttCalendarSelector } from "./supervisor-schedule-gantt-calendar-selector";
import { Divider } from "@mui/material";
import { useLocation } from "react-router";
import { SupervisorScheduleContext } from "../../../../context";
import styles from "./supervisor-schedule-gantt-header-options.module.scss";

export const SupervisorScheduleGanttHeaderOptions: React.FC =
  (): ReactElement => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const view =
      params.get(SupervisorScheduleTasksViewURLParamName) ||
      DEFAULT_SUPERVISOR_SCHEDULE_TASKS_VIEW;
    const scheduleContext = useContext(SupervisorScheduleContext);

    const renderCalendarOptions = (): ReactElement => (
      <>
        <SupervisorScheduleGanttTimeOptions />
        <SupervisorScheduleGanttCalendarSelector />
        <Divider orientation="vertical" className={styles.divider} />
      </>
    );

    const shouldRenderCalendarOptions = (): boolean => {
      let toRender: boolean = false;
      if (
        view === SupervisorScheduleTasksViewId.GANTT ||
        scheduleContext?.utilizationGanttRef.current
      ) {
        toRender = true;
      }
      return toRender;
    };

    return (
      <div className={styles.root}>
        {shouldRenderCalendarOptions() && renderCalendarOptions()}
        <SupervisorScheduleGanttToggle />
      </div>
    );
  };
