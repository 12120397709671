import { ISupervisorScheduleContext } from "../../../context";
import { UtilizationPeriodType } from "@river/interfaces";
import { SupervisorScheduleGanttTimeOptionGroupID } from "../supervisor-schedule-tasks/supervisor-schedule-gantt-header-options";

export namespace supervisorUtilizationHelpers {
  export const getUtilizationPeriodType = (
    scheduleContext: ISupervisorScheduleContext
  ): UtilizationPeriodType => {
    let utilizationPeriodType: UtilizationPeriodType;
    const timeGroupId: SupervisorScheduleGanttTimeOptionGroupID =
      scheduleContext?.ganttTimeOption?.groupId!;
    if (timeGroupId) {
      utilizationPeriodType = {
        [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: "HOUR",
        [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: "DAY",
        [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: "WEEK",
        [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: "MONTH",
      }[timeGroupId] as UtilizationPeriodType;
    }
    return utilizationPeriodType;
  };

  export const getUtilizationPeriods = (
    scheduleContext: ISupervisorScheduleContext
  ): number => {
    return scheduleContext?.ganttTimeOption?.value || 0;
  };

  export const getRandomGanttAvatarURL = (index: number): string =>
    `/sample-avatars/avatar${(index % 4) + 1}.svg`;
}
