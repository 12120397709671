import React from "react";
import { FormatterProps } from "react-data-grid";
import { useTableCellRenderers } from "../../../../../hooks";
import { RiverCheckmarkFormatter } from "../river-checkmark-formatter";
import jsonata from "jsonata";
import { useTranslation } from "@river/common-ui";

interface IRiverDefaultTextFormatterProps {
  formatterProps: FormatterProps<any>;
  checked?: boolean;
}

export const RiverDefaultBooleanFormatter: React.FC<
  IRiverDefaultTextFormatterProps
> = (props) => {
  const { t } = useTranslation();
  const { renderCell } = useTableCellRenderers();
  const { formatterProps } = props;
  const { row, column } = formatterProps;
  const checked: boolean = jsonata(column.key).evaluate(row);
  const tooltip: string = checked
    ? t("common.label:true")
    : t("common.label:false");

  return (
    <>
      {renderCell({
        formatterProps,
        content: <RiverCheckmarkFormatter checked={checked} />,
        tooltip,
      })}
    </>
  );
};
