import React, { useContext, useEffect, useState } from "react";
import { RiverSpinner, useNotification } from "@river/common-ui";
import { IAdapterFolder } from "@river/interfaces";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  SidebarContext,
  TableContext,
  TabContext,
  BacklogContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
  IBacklogContext,
  ITableContext,
  ITabContext,
} from "../../context";
import { AdapterService, BacklogTabId } from "../../services";
import { helpers } from "../../helpers";
import { Guid } from "@river/util";

import { RowsChangeData } from "react-data-grid";
import { BacklogHeader } from "./backlog-header";
import { BacklogBody } from "./backlog-body";
import { BacklogSubHeader } from "./backlog-sub-header";
import { BacklogTabHeader } from "./backlog-tab-header";
import { BacklogGridHeader } from "./backlog-grid-header";
import { useNavigate, useParams, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { BacklogUiService } from "../../services";
import { BacklogTabURLParamName } from "../../services";
import { usePageCleanup } from "../../hooks";
import { RiverDataGrid } from "../shared";

export const Backlog: React.FC = () => {
  usePageCleanup();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext!.service.getBacklogUiService();
  const adapterService: AdapterService =
    adapterContext!.service.getAdapterService();

  const scheduleId: string = useParams<{ schedule_id: string }>().schedule_id!;
  const location = useLocation();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  const currentTab: BacklogTabId = uiService.getCurrentTab()();

  const [currentSchedule, setCurrentSchedule] = useState<IAdapterFolder | null>(
    null
  );
  const [schedulePaneRefreshGuid, setSchedulePaneRefreshGuid] =
    useState<string>();

  const backlogContext: IBacklogContext = {
    currentSchedule,
    schedulePaneRefreshGuid,
    refreshSchedulePane: () => setSchedulePaneRefreshGuid(Guid.newGuid),
    refreshCurrentTable: () => currentTable.refresh(),
  };

  const { entity: currentEntity, table: currentTable } =
    uiService.useCurrentTable({ backlogContext })();

  uiService.tableRefs[currentTab] = currentTable;

  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];

  const sidebarContext = useContext(SidebarContext);
  const notify = useNotification();

  // Temporarily commented loading KPI data
  /*const [availabilityUtilization, setAvailabilityUtilization] =
    useState<IAvailabilityUtilizationResult | null>();*/

  useEffect(() => {
    if (!!scheduleId) {
      adapterService.getFolderById(scheduleId).then((schedule) => {
        setCurrentSchedule(schedule);
      });
    } else {
      setCurrentSchedule(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);

  const currentEntityName =
    currentEntity?.entityDefinition?.entity.entity_name || "workorder";

  // Temporarily commented loading KPI data
  /* useEffect(() => {
    kpiService.loadKPIs(
      adapterContext!,
      sidebarContext!,
      currentTable!,
      tab,
      availabilityUtilization!
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable?.entities, sidebarContext?.kpiParams]); */

  useEffect(() => {
    return () => {
      sidebarContext?.setIsShowingStats(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentTable) {
    return null;
  }

  const onRowsChange = (rows: any[], rowData: RowsChangeData<any>) => {
    const _id = rows[rowData.indexes[0]]["_id"];
    const newValue = rows[rowData.indexes[0]][rowData.column.key];
    const entityName = rows[rowData.indexes[0]].table_type;

    const update = async () => {
      try {
        const updatedRow: any = await adapterService.updateEntityData(
          entityName,
          _id,
          {
            [rowData.column.key]: newValue,
          }
        );
        currentTable.updateRow({ rowId: _id, updatedRow });
      } catch (message) {
        console.log(message);
        notify.error({ message });
      }
    };

    update();
  };

  const tabContext: ITabContext = {
    selectedTab: currentTab,
    setSelectedTab: (tabId: string) => {
      navigate(
        helpers.replaceOrSetUrlParam(
          location.pathname,
          searchParams,
          BacklogTabURLParamName,
          tabId
        )
      );
    },
  };

  const tableContext: ITableContext = {
    entityName: currentEntityName,
    table: currentTable,
    entity: currentEntity!,
  };

  const isLoading: boolean = currentTable.isLoading || !site;

  return (
    <BacklogContext.Provider value={backlogContext}>
      <TableContext.Provider value={tableContext}>
        <BacklogHeader />
        <BacklogBody setIsLoading={currentTable.forceLoadingState}>
          <BacklogSubHeader />
          <TabContext.Provider value={tabContext}>
            <BacklogTabHeader />
            <BacklogGridHeader />
            <RiverSpinner show={isLoading} />
            <RiverDataGrid
              columns={currentTable.columns}
              rows={currentTable.entities}
              sortColumns={currentTable.sortColumns}
              onSortColumnsChange={currentTable.setSortColumns}
              onColumnResize={currentTable.setColumnSize}
              onRowsChange={onRowsChange}
              rowKeyGetter={currentTable.rowKeyGetter}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
          </TabContext.Provider>
        </BacklogBody>
      </TableContext.Provider>
    </BacklogContext.Provider>
  );
};
