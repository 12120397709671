import { useRiverForm, RiverFormInstance } from "../../../hooks";
import {
  IColumnFilter,
  ITableFilter,
  ITablePreferences,
} from "../../../interfaces";
import { IQueryAttribute, IQueryAttributeGroup } from "@river/interfaces";
import {
  AttributeGroupOperator,
  getGroupElements,
} from "./use-advanced-filters";
import { useTranslation } from "react-i18next";
import { IsNotEmpty } from "class-validator";
import { useContext } from "react";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { gridHelpers, IUseTable } from "../river-data-grid";
import { userPreferencesService } from "../../../services";
import { useNotification } from "@river/common-ui";

interface IUseAdvancedFiltersFormProps {
  filter?: ITableFilter | null;
  scope: TableFilterScope;
  onSave?: () => void;
}
export enum TableFilterScope {
  LOCAL = "local",
  GLOBAL = "global",
}

export interface IAdvancedFiltersForm {
  name: string;
  scope: TableFilterScope;
  isDefault: boolean;
  query: IQueryAttributeGroup;
  simpleFiltersAttributeGroup: IQueryAttributeGroup;
}

export class AdvancedFiltersForm {
  @IsNotEmpty()
  name!: string;

  @IsNotEmpty()
  scope!: TableFilterScope;

  isDefault!: boolean;
  query!: IQueryAttributeGroup;

  simpleFiltersAttributeGroup!: IQueryAttributeGroup;

  constructor(obj: IAdvancedFiltersForm) {
    Object.assign(this, obj);
  }
}

export const DEFAULT_FILTER_NAME: string = "";
export const DEFAULT_FILTER_SCOPE: TableFilterScope = TableFilterScope.LOCAL;
export const DEFAULT_IS_FILTER_DEFAULT: boolean = false;

export const useAdvancedFiltersForm = (
  props: IUseAdvancedFiltersFormProps
): RiverFormInstance => {
  const DEFAULT_FILTER_QUERY: IQueryAttributeGroup = {
    [AttributeGroupOperator.AND]: [],
  };
  const { t } = useTranslation();
  const notify = useNotification();
  const tableContext = useContext(TableContext);
  const table: IUseTable = tableContext?.table!;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const form: RiverFormInstance = useRiverForm<
    Object,
    Object,
    AdvancedFiltersForm
  >({
    standalone: {
      fields: new AdvancedFiltersForm({
        name: props?.filter?.name || DEFAULT_FILTER_NAME,
        scope: props.scope || DEFAULT_FILTER_SCOPE,
        isDefault: !!(
          props?.filter?.name && props?.filter?.name === table.defaultFilterName
        ),
        simpleFiltersAttributeGroup: gridHelpers.getSimpleFiltersQueryGroup(
          props?.filter?.simpleFilters || []
        ),
        query: props?.filter?.query || DEFAULT_FILTER_QUERY,
      }),
      fieldDefs: [
        { fieldName: "name", dataType: "string" },
        { fieldName: "isDefault", dataType: "boolean" },
        { fieldName: "scope", dataType: "string" },
      ],
    },
    submit: () => saveFilter(),
    labels: {
      name: t("shared.advanced_filters:label.filterName"),
      isDefault: t("shared.advanced_filters:label.isDefault"),
      scope: t("shared.advanced_filters:label.scope"),
      [TableFilterScope.LOCAL]: t("shared.advanced_filters:label.local_list"),
      [TableFilterScope.GLOBAL]: t("shared.advanced_filters:label.global_list"),
    },
  });

  const fields: AdvancedFiltersForm = form.standalone as AdvancedFiltersForm;
  const { name, isDefault, query, scope, simpleFiltersAttributeGroup } = fields;

  const updateFiltersArray = (tablePreferences: ITablePreferences): void => {
    const filters: ITableFilter[] = tablePreferences.filters || [];

    // filter to save
    let newFilter: ITableFilter = {
      name,
      query,
    };

    const simpleFiltersAttributes: IQueryAttribute[] = getGroupElements(
      simpleFiltersAttributeGroup
    ) as IQueryAttribute[];

    const simpleFilters: IColumnFilter[] = simpleFiltersAttributes.map(
      (attribute) => ({
        field: attribute.attribute_name,
        operator: attribute.attribute_value.operator,
        value: attribute.attribute_value.value,
      })
    );
    newFilter = {
      ...newFilter,
      simpleFilters,
    };

    // replacement indexes
    const editFilterIndex: number = props.filter
      ? filters.findIndex((filter) => filter.name === props.filter!.name)
      : -1;
    const sameNameFilterIndex: number = filters.findIndex(
      (filter) => filter.name === name
    );

    if (editFilterIndex !== -1) {
      // update existing filter
      filters.splice(editFilterIndex, 1, newFilter);
    } else {
      // create new filter
      filters.push(newFilter);
    }
    // remove existing with same name
    if (sameNameFilterIndex !== -1 && editFilterIndex !== sameNameFilterIndex) {
      filters.splice(sameNameFilterIndex, 1);
    }

    tablePreferences.filters = filters;
  };

  const removeFilter = (
    preferences: ITablePreferences,
    filterToRemove: ITableFilter
  ): void => {
    const { filters } = preferences;
    const index: number = filters!.findIndex(
      (filter) => filter.name === filterToRemove.name
    );
    filters!.splice(index, 1);
  };

  const loadPreferences = async (props?: {
    global?: boolean;
  }): Promise<any> => {
    const isGlobal: boolean = !!props?.global;
    let tablePreferences: ITablePreferences =
      await userPreferencesService.getTablePreferences({
        adapter: adapterContext!.service.getAdapterService(),
        tableSaveKey: table.dbSaveKey,
        isGlobal,
      });
    if (!tablePreferences) {
      tablePreferences = isGlobal
        ? table.getDefaultGlobalPreferences()
        : table.getDefaultTablePreferences();
    }
    return tablePreferences;
  };

  const savePreferences = (params: {
    preferences: ITablePreferences;
    global?: boolean;
  }): Promise<void> => {
    const { preferences: tablePreferences, global } = params;
    return userPreferencesService.setTablePreferences({
      adapter: adapterContext!.service.getAdapterService(),
      tableSaveKey: table.dbSaveKey,
      tablePreferences,
      isGlobal: global,
    });
  };

  const updateDefaultFilterName = (preferences: ITablePreferences): boolean => {
    let updated: boolean = false;
    const { defaultFilterName } = preferences;
    const isDefaultFilter: boolean = name === defaultFilterName;
    if (isDefaultFilter) {
      if (!isDefault) {
        delete preferences.defaultFilterName;
        updated = true;
      }
    } else {
      if (isDefault) {
        preferences.defaultFilterName = name;
        updated = true;
      }
    }
    return updated;
  };

  const saveFilter = async (): Promise<void> => {
    try {
      const localPreferences: ITablePreferences = await loadPreferences();
      const globalPreferences: ITablePreferences = await loadPreferences({
        global: true,
      });
      let localSaveRequired: boolean =
        updateDefaultFilterName(localPreferences);
      let globalSaveRequired: boolean = false;

      if (scope === TableFilterScope.LOCAL) {
        updateFiltersArray(localPreferences);
        localSaveRequired = true;

        // If updated from Global to Local, delete from global filters
        if (props.filter && props.scope === TableFilterScope.GLOBAL) {
          removeFilter(globalPreferences, props.filter);
          globalSaveRequired = true;
        }
      } else {
        updateFiltersArray(globalPreferences);
        globalSaveRequired = true;

        // If updated from Local to Global, delete from local filters
        if (props.filter && props.scope === TableFilterScope.LOCAL) {
          removeFilter(localPreferences, props.filter);
          localSaveRequired = true;
        }
      }

      if (localSaveRequired) {
        await savePreferences({ preferences: localPreferences });
        table.applyTablePreferences(localPreferences);
      }
      if (globalSaveRequired) {
        await savePreferences({
          preferences: globalPreferences!,
          global: true,
        });
      }

      notify.success(t("shared.advanced_filters:notification.filters_saved"));
      props.onSave?.();
    } catch (message) {
      notify.error({ message });
    }
  };

  return form;
};
