import React, { ReactElement, useContext, useState } from "react";
import { GenericAction } from "../../../components/shared";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNotification, useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  SupervisorScheduleContext,
  TableContext,
} from "../../../context";
import { SupervisorTimeCardUiService } from "../../supervisor-timecard-ui.service";
import { IEntityObject } from "@river/interfaces";

export const useCreateAssignmentTimecardAction = () => {
  const { t } = useTranslation();
  const notify = useNotification();
  const { withSanitizedProps } = useGridActionHelpers();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const timecardUiService: SupervisorTimeCardUiService =
    adapterContext?.service.getSupervisorTimeCardUiService()!;

  const [timecardDialogOpened, setTimecardDialogOpened] =
    useState<boolean>(false);

  const title: string = t(
    "module.supervisor_schedule:action_label.create_timecard"
  );

  const onClick = (): void => setTimecardDialogOpened(true);

  const renderIcon = (props?: any): ReactElement => (
    <GenericAction
      icon={AccessTimeIcon}
      enableOnSelection={true}
      title={title}
      onClick={onClick}
      {...props}
    />
  );

  const scheduleContext = useContext(SupervisorScheduleContext);
  const { currentSchedule } = scheduleContext!;
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const selectedResource: IEntityObject = table?.getSelectedRows()[0]!;

  const renderDialog = (): ReactElement => (
    <>
      {timecardDialogOpened &&
        timecardUiService.renderAssignmentTimecardDialog({
          open: timecardDialogOpened,
          folderId: currentSchedule?._id!,
          operation: selectedResource,
          onClose: () => setTimecardDialogOpened(false),
          onCreate: () => {
            notify.success(
              t("module.supervisor_schedule:tab.timecards.success")
            );
            table?.setSelectedRowIds(new Set());
          },
        })}
    </>
  );

  const renderCreateAssignmentTimecardAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderCreateAssignmentTimecardAction,
    createAssignmentTimecardAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseCreateAssignmentTimecardAction = ReturnType<
  typeof useCreateAssignmentTimecardAction
>;
