import React, { ReactElement, useContext, useState } from "react";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  EditAction,
  FilterDropdown,
  ColumnSelectorDropdown,
} from "../../shared";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import { CrewsAction, TableUiService } from "../../../services";
import { AdapterCrewDto, IAdapterCrew } from "@river/interfaces";
import { CrewDialog } from "../crew-dialog";

export const CrewsGridHeader: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const [crewDialogOpened, setCrewDialogOpened] = useState<boolean>(false);
  const [selectedCrew, setSelectedCrew] = useState<
    AdapterCrewDto | undefined
  >();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  useState<boolean>(false);
  const tableUiService: TableUiService =
    adapterContext?.service.getTableUiService()!;
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();
  const getSelectedRowsObjectIds = tableUiService.getSelectedRowsObjectIds();

  const editSelectedCrew = async () => {
    if (table?.selectedRowIds) {
      openDialog();
      const crew = table.getSelectedRows()[0];
      setSelectedCrew(crew as AdapterCrewDto);
    }
  };

  const deleteSelectedCrew = async (): Promise<any> => {
    if (table?.selectedRowIds) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteCrew(getSelectedRowsObjectIds(table)[0]);
        tableContext?.table.refresh();
        deleteCrewConfirmation.close();
        notify.success(t("module.crews:notification.crew_deletion"));
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteCrewConfirmation = useSimpleDialog({
    title: t("module.crews:message.delete_crew.title"),
    message: t("module.crews:message.confirm_crew_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedCrew,
  });

  const openDialog = (): void => {
    setCrewDialogOpened(true);
  };
  const closeDialog = (): void => setCrewDialogOpened(false);
  return (
    <>
      <GridHeader>
        <GridActions>
          <ColumnSelectorDropdown />
          <FilterDropdown />
          <ProtectedAction module={ModuleKey.CREWS} action={CrewsAction.DELETE}>
            <EditAction onClick={editSelectedCrew} />
            <DeleteAction onClick={() => deleteCrewConfirmation.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteCrewConfirmation.render()}
      <CrewDialog
        open={crewDialogOpened}
        crew={selectedCrew as IAdapterCrew}
        onClose={() => {
          closeDialog();
          table?.refresh();
        }}
      />
    </>
  );
};
