import React, { ReactElement, PropsWithChildren } from "react";
import { RiverTabs } from "../river-tabs";
import { TabProps } from "@mui/material/Tab";
import styles from "./tab-header.module.scss";

export interface ITabHeaderProps extends PropsWithChildren {
  tabs: TabProps[];
}

export const TabHeader: React.FC<ITabHeaderProps> = (props): ReactElement => {
  return (
    <div className={styles.root}>
      <RiverTabs tabs={props.tabs} />
      {props.children}
    </div>
  );
};
