import React, { ReactElement, useContext, useState } from "react";
import { EditAction } from "../../../components/shared";
import { useTranslation } from "@river/common-ui";
import { useGridActionHelpers } from "../../../helpers";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import { SupervisorScheduleUiService } from "../../supervisor-schedule-ui-service";

export const useOpMassUpdateAction = () => {
  const { t } = useTranslation();
  const { withSanitizedProps } = useGridActionHelpers();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: SupervisorScheduleUiService =
    adapterContext?.service.getSupervisorScheduleUiService()!;

  const [dialogOpened, setDialogOpened] = useState<boolean>(false);

  const title: string = t(
    "module.supervisor_schedule:action_label.op_mass_update"
  );

  const onClick = (): void => setDialogOpened(true);

  const renderIcon = (props?: any): ReactElement => (
    <EditAction title={title} onClick={onClick} {...props} />
  );

  const renderDialog = (): ReactElement => (
    <>
      {uiService.renderOpMassUpdateDialog({
        open: dialogOpened,
        onClose: () => {
          setDialogOpened(false);
        },
      })}
    </>
  );

  const renderOpMassUpdateAction = (): ReactElement => (
    <>
      {renderIcon()}
      {renderDialog()}
    </>
  );

  return {
    renderOpMassUpdateAction,
    opMassUpdateAction: {
      icon: withSanitizedProps(renderIcon),
      renderDialog,
      title,
      onClick,
    },
  };
};

export type IUseOpMassUpdateAction = ReturnType<typeof useOpMassUpdateAction>;
