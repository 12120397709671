import { useContext } from "react";
import { useWoMassUpdateAction } from "../../../components/shared";
import { AdapterUiContext, IAdapterUiContextState } from "../../../context";
import {
  useCheckMaterialShortageAction,
  useDownloadAttachmentsAction,
  useJobPriorityScoreAction,
  useMaterialRequirementsAction,
  useOpMassUpdateAction,
  useOpenErpWorkOrderAction,
  useRemoveFromScheduleAction,
  useUnassignAction,
  useShowOperationsTabAction,
  useShowAssignmentsTabAction,
  useShowResourcesTabAction,
  useShowInstancesTabAction,
} from "./";
import { BacklogUiService } from "../backlog-ui-service";

export const useGridActions = () => {
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: BacklogUiService =
    adapterContext?.service.getBacklogUiService()!;
  const { renderCheckMaterialShortageAction, checkMaterialShortageAction } =
    useCheckMaterialShortageAction();
  const { renderDownloadAttachmentsAction, downloadAttachmentsAction } =
    useDownloadAttachmentsAction();
  const { renderJobPriorityScoreAction, jobPriorityScoreAction } =
    useJobPriorityScoreAction();
  const { renderMaterialRequirementsAction, materialRequirementsAction } =
    useMaterialRequirementsAction();
  const { renderWoMassUpdateAction, woMassUpdateAction } =
    useWoMassUpdateAction({
      renderWoMassUpdateDialog: uiService.renderWoMassUpdateDialog,
    });
  const { renderOpMassUpdateAction, opMassUpdateAction } =
    useOpMassUpdateAction();
  const { renderOpenErpWorkorderAction, openErpWorkorderAction } =
    useOpenErpWorkOrderAction();
  const { renderRemoveFromScheduleAction, removeFromScheduleAction } =
    useRemoveFromScheduleAction();
  const { renderUnassignAction } = useUnassignAction();
  const { renderShowOperationsTabAction } = useShowOperationsTabAction();
  const { renderShowAssignmentsTabAction } = useShowAssignmentsTabAction();
  const { renderShowResourcesTabAction } = useShowResourcesTabAction();
  const { renderShowInstancesTabAction } = useShowInstancesTabAction();

  return {
    renderCheckMaterialShortageAction,
    checkMaterialShortageAction,
    renderDownloadAttachmentsAction,
    downloadAttachmentsAction,
    renderJobPriorityScoreAction,
    jobPriorityScoreAction,
    renderMaterialRequirementsAction,
    materialRequirementsAction,
    renderWoMassUpdateAction,
    woMassUpdateAction,
    renderOpMassUpdateAction,
    opMassUpdateAction,
    renderOpenErpWorkorderAction,
    openErpWorkorderAction,
    renderRemoveFromScheduleAction,
    removeFromScheduleAction,
    renderUnassignAction,
    renderShowOperationsTabAction,
    renderShowAssignmentsTabAction,
    renderShowResourcesTabAction,
    renderShowInstancesTabAction,
  };
};

export type IUseGridActions = ReturnType<typeof useGridActions>;
