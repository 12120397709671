import {
  ILookupDefinition,
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import { PersonUiService } from "../person-ui.service";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { fetchHelpers } from "../../helpers";

export class OracleCloudPersonUiService extends PersonUiService {
  getPersonColumns = () => {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      return [
        RiverSelectColumn,
        {
          key: "LaborInstanceName",
          name: t(
            "entity.availability_header:availability_header.LaborInstanceName"
          ),
        },
        {
          key: "Resources.ResourceCode",
          name: t("entity.availability_header:availability_header.Resources"),
        },
      ];
    };
  };

  getPersonLookupDefinition = (): (() => ILookupDefinition) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      const userContext: IAdapterUserContext | null =
        useContext(AdapterUserContext);
      const site: IUserContextSite =
        userContext?.userProperties[AdapterUserContextProp.SITE];
      const { t } = useTranslation();
      return {
        title: t("shared.lookup:person_def.title"),
        useTableProps: {
          columns: this.getPersonColumns()(),
          fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
            return await adapterContext!.service
              .getAdapterService()
              .fetchCraftPeople(fetchHelpers.getTableQuery({ fetchProps }));
          },
          dependencies: [!!site],
          useAdvancedFilters: false,
        },
        useEntityProps: {
          entityName: "availability_header",
        },
      };
    };
  };

  getPersonKey = () => "LaborInstanceName";
}
