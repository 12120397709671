import {
  AdapterTimeCardDto,
  IAdapterTimeCard,
  IEntityObject,
} from "@river/interfaces";
import { RiverFormInstance } from "../hooks";
import { AdapterUiService } from "./adapter-ui.service";
import {
  ReactElementProvider,
  RiverFormInstanceProvider,
} from "./ui-service.types";
import { ExecutionTabId } from "./execution-ui-service";

export interface ITimeCardDialogProps {
  open: boolean;
  folderId: string;
  workorder: IEntityObject;
  operation: IEntityObject;
  onClose: (success: boolean) => void;
  onCreate: () => void;
  initialValues?: Partial<AdapterTimeCardDto> | null;
}

export interface ITimeCardFormProps {
  timecard?: IAdapterTimeCard;
  folderId: string;
  workorder: IEntityObject;
  operation: IEntityObject;
  onCreate?: (timecard: IAdapterTimeCard) => void;
  onUpdate?: () => void;
  initialValues?: Partial<AdapterTimeCardDto> | null;
}

export interface ICompletionPercentageFormProps {
  folderId: string;
  workorder: IEntityObject;
  operation: IEntityObject;
}

export interface IRenderTimeCardFieldsProps {
  workorder: IEntityObject;
  operation: IEntityObject;
  form: RiverFormInstance;
}

export interface IRenderTimecardDialogProps extends ITimeCardFormProps {
  open: boolean;
  onClose(): void;
  onCreate(): void;
}

export abstract class TimeCardUiService {
  constructor(protected readonly adapterUiService: AdapterUiService) {}

  abstract renderTimecardDialog(
    props: IRenderTimecardDialogProps,
    activeTab: ExecutionTabId
  ): ReactElementProvider;

  abstract getOperationsTimeCardForm(
    props: ITimeCardFormProps
  ): RiverFormInstanceProvider;

  abstract renderOperationsTimeCardFormFields(
    props: IRenderTimeCardFieldsProps
  ): ReactElementProvider;

  abstract getInstanceTimeCardForm(
    props: ITimeCardFormProps
  ): RiverFormInstanceProvider;

  abstract renderInstanceTimeCardFormFields(
    props: IRenderTimeCardFieldsProps
  ): ReactElementProvider;
}
