import { IUseEntityTable, IUseEntityTableParams } from "../ui-service.types";
import { TFunction, useTranslation } from "react-i18next";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
  ScheduleContext,
} from "../../context";
import { useContext } from "react";
import { useEntity } from "../../hooks";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import * as ganttUtils from "../../components/schedule/schedule-gantt/schedule-gantt-utils";
import {
  ScheduleUtilizationUiService,
  ScheduleUtilizationTabId,
} from "../../services";
import { IAdapterFolder, UtilizationPeriodType } from "@river/interfaces";
import {
  ITableFetchFunctionProps,
  IUseTable,
  useTable,
} from "../../components/shared";
import { fetchHelpers } from "../../helpers";

interface IUseScheduleUtilizationCrewsProps {
  selectedCrewIds: Set<string>;
}

export const useScheduleUtilizationCrews = (
  props: IUseScheduleUtilizationCrewsProps
): IUseEntityTable => {
  const { t } = useTranslation();
  const scheduleContext = useContext(ScheduleContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ScheduleUtilizationUiService =
    adapterContext?.service.getScheduleUtilizationUiService()!;
  const currentSchedule: IAdapterFolder = scheduleContext?.currentSchedule!;
  const currentTab: ScheduleUtilizationTabId = uiService.getCurrentTab()();

  const site: IUserContextSite =
    useContext(AdapterUserContext)?.userProperties[AdapterUserContextProp.SITE];

  const utilizationPeriodType: UtilizationPeriodType =
    utilizationHelpers.getUtilizationPeriodType(scheduleContext!);
  const utilizationPeriods: number = utilizationHelpers.getUtilizationPeriods(
    scheduleContext!
  );

  const fetchCrewUtilization = async (fetchProps: ITableFetchFunctionProps) => {
    return await adapterContext!.service
      .getAdapterService()
      .fetchCrewUtilization(
        currentSchedule._id,
        null, // baseline id
        ganttUtils.getGanttStart(
          scheduleContext?.selectedGanttDate!,
          scheduleContext?.ganttTimeOption?.groupId!,
          new Date(scheduleContext?.currentSchedule?.start_date!)
        ),
        utilizationPeriodType,
        utilizationPeriods,
        fetchHelpers.getTableQuery({
          fetchProps,
          initialQueryAttributes: [
            {
              attribute_name: "crew",
              attribute_value: {
                operator: "$in",
                value: Array.from(props.selectedCrewIds),
              },
            },
          ],
        })
      );
  };

  const getTableParams = (t: TFunction): IUseEntityTableParams => {
    return {
      entityName: "crew_utilization",
      columns: [
        {
          key: "crew",
          name: t("entity.crew_utilization:crew_utilization.crew"),
        },
        {
          key: "description",
          name: t("entity.crew_utilization:crew_utilization.description"),
        },
      ],
    };
  };

  const { entityName, columns } = getTableParams(t);

  const table: IUseTable = useTable({
    entityName,
    saveKey: "utilization.crews",
    columns,
    fetchFunction: fetchCrewUtilization,
    fetchOn: currentTab === ScheduleUtilizationTabId.CREWS,
    dependencies: [
      !!site,
      !!utilizationPeriodType,
      !!currentSchedule,
      currentTab === ScheduleUtilizationTabId.CREWS,
    ],
    clearBeforeFetch: true,
    fetchTriggers: [
      site,
      utilizationPeriodType,
      utilizationPeriods,
      currentSchedule,
      props.selectedCrewIds,
      scheduleContext?.selectedGanttDate,
    ],
    rowKeyGetter: (row) => String(row["crew"]),
    useAdvancedFilters: true,
    infiniteScrolling: true,
  });

  return {
    entity: useEntity({ entityName }),
    table,
  };
};
