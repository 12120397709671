import {
  ILookupDefinition,
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../components/shared";
import { Column } from "react-data-grid";
import { Avatar } from "../../components/schedule/schedule-utilization/schedule-utilization-tables/avatar";
import { utilizationHelpers } from "../../components/schedule/schedule-utilization/utilization-helpers";
import clsx from "clsx";
import { PersonUiService } from "../person-ui.service";
import {
  AdapterUiContext,
  AdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IAdapterUserContext,
  IUserContextSite,
} from "../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";
import { fetchHelpers } from "../../helpers";

import styles from "../../components/shared/river-lookup/lookup-dialog/defs/person-lookup/person-lookup.module.scss";
import { useTableCellRenderers } from "../../hooks";

export class SapPersonUiService extends PersonUiService {
  getPersonColumns = (): (() => Column<any>[]) => {
    return () => {
      const { t } = useTranslation();
      const { RiverSelectColumn } = useRiverSelectColumn();
      const { renderCell } = useTableCellRenderers();
      return [
        RiverSelectColumn,
        {
          key: "PersonName",
          name: t("entity.availability_header:availability_header.PersonName"),
          formatter: (formatterProps: any) => {
            return (
              <>
                {renderCell({
                  formatterProps,
                  content: (
                    <div className={clsx([styles.person])}>
                      <Avatar
                        url={utilizationHelpers.getRandomGanttAvatarURL(
                          Number(formatterProps.row.PersonNumber)
                        )}
                      />
                      <div>{formatterProps.row[formatterProps.column.key]}</div>
                    </div>
                  ),
                })}
              </>
            );
          },
        },
        {
          key: "PersonNumber",
          name: t(
            "entity.availability_header:availability_header.PersonNumber"
          ),
        },
        {
          key: "WorkCenters.WorkCenter",
          name: t("entity.availability_header:availability_header.WorkCenters"),
        },
      ];
    };
  };

  getPersonLookupDefinition = (): (() => ILookupDefinition) => {
    return () => {
      const adapterContext: IAdapterUiContextState | null =
        useContext(AdapterUiContext);
      const userContext: IAdapterUserContext | null =
        useContext(AdapterUserContext);
      const site: IUserContextSite =
        userContext?.userProperties[AdapterUserContextProp.SITE];
      const { t } = useTranslation();
      return {
        title: t("shared.lookup:person_def.title"),
        useTableProps: {
          columns: this.getPersonColumns()(),
          fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
            return await adapterContext!.service
              .getAdapterService()
              .fetchCraftPeople(fetchHelpers.getTableQuery({ fetchProps }));
          },
          dependencies: [!!site],
          useAdvancedFilters: false,
        },
        useEntityProps: {
          entityName: "availability_header",
        },
      };
    };
  };

  getPersonKey = () => "PersonNumber";
}
