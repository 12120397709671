import React, { ReactElement } from "react";
import { RiverTextInput, RiverDialog } from "@river/common-ui";
import { IAdapterLookup } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useLookupForm } from "./use-lookup-form";
import { useTranslation } from "@river/common-ui";
import styles from "./lookup-dialog.module.scss";
import clsx from "clsx";

interface LookupDialogProps {
  lookup_type: string;
  open: boolean;
  lookup: IAdapterLookup | null;
  onClose: (success: boolean) => void;
}

export const LookupDialog: React.FC<LookupDialogProps> = (
  props
): ReactElement => {
  const form: RiverFormInstance = useLookupForm({
    lookup_type: props.lookup_type,
    lookup: props.lookup,
    onCreate: () => closeAndRefresh(),
    onUpdate: () => closeAndRefresh(),
  });

  const { submit, resetForm, isCreate } = form;
  const { t } = useTranslation();

  const closeAndRefresh = (): void => {
    const requireRefresh: boolean = true;
    close(requireRefresh);
  };

  const close = (requiresRefresh?: boolean): void => {
    resetForm();
    props.onClose(!!requiresRefresh);
  };

  const getDialogTitle = (): string =>
    isCreate
      ? t("module.lookups:label.create_lookup")
      : t("module.lookups:label.edit_lookup");

  const getActionButtonText = (): string =>
    isCreate ? t("common.button:add") : t("common.button:save");

  return (
    <RiverDialog
      title={getDialogTitle()}
      open={props.open}
      onClose={close}
      actionButtonText={getActionButtonText()}
      showActionsDivider={false}
      onSubmit={submit}
      classes={{
        paper: styles.paper,
        content: clsx([
          styles.content,
          { [styles.readOnly]: form?.isReadOnly },
        ]),
      }}
      form={form}
    >
      <RiverTextInput id={"value"} fullWidth />
      <RiverTextInput id={"description"} fullWidth />
    </RiverDialog>
  );
};
