import { ReactElement } from "react";
import {
  IRenderTimecardDialogProps,
  ITimeCardDialogProps,
  SupervisorTimeCardUiService,
} from "../../supervisor-timecard-ui.service";
import { OracleEbsSupervisorResourceTimeCardDialog } from "./oracle-ebs-supervisor-resource-timecard-dialog";
import { OracleEbsSupervisorInstanceTimeCardDialog } from "./oracle-ebs-supervisor-instance-timecard-dialog";

export class OracleEbsSupervisorTimeCardUiService extends SupervisorTimeCardUiService {
  renderOperationTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderAssignmentTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <></>;

  renderResourceTimecardDialog = (
    props: ITimeCardDialogProps
  ): ReactElement => <OracleEbsSupervisorResourceTimeCardDialog {...props} />;

  renderInstanceTimecardDialog = (
    props: IRenderTimecardDialogProps
  ): ReactElement => <OracleEbsSupervisorInstanceTimeCardDialog {...props} />;
}
