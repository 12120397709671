import React, { useState, useContext, useEffect } from "react";
import {
  IUseTable,
  DeleteAction,
  GridActions,
  GridHeader,
  FilterDropdown,
} from "../../shared";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { IEntity } from "@river/interfaces";
import { uiConstants } from "../../../helpers";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { DataDictionaryAction } from "../data-dict";
import { ModuleKey } from "../../sidebar-menu";

interface IDataDictGridHeader {}

export const DataDictGridHeader: React.FC<IDataDictGridHeader> = (props) => {
  const { t } = useTranslation();
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  const tableContext = useContext(TableContext);
  const notify = useNotification();

  const getSelectedEntity = (): IEntity | undefined => {
    const table: IUseTable = tableContext?.table!;
    let selected: any = table.entities.filter((entity) =>
      table.selectedRowIds.has(entity[uiConstants.fields._id] as string)
    )[0];
    return selected as IEntity;
  };
  const [selectedEntity, setSelectedEntity] = useState<IEntity | undefined>(
    getSelectedEntity()
  );

  const isCustomEntitySelected = (): boolean => !!selectedEntity?.customer_id;

  useEffect(() => {
    setSelectedEntity(getSelectedEntity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContext?.table.selectedRowIds]);

  const deleteSelectedEntity = async (): Promise<void> => {
    try {
      if (isCustomEntitySelected()) {
        await adapterContext!.service
          .getAdapterService()
          .deleteEntityDefinition(selectedEntity?.entity_name!);
        tableContext?.table.refresh();
      }
    } catch (message) {
      notify.error({ message });
    } finally {
      deleteEntityConfirmationDialog.close();
    }
  };

  const deleteEntityConfirmationDialog = useSimpleDialog({
    title: t("module.data_dictionary:message.delete_entity.title"),
    message: t("module.data_dictionary:message.confirm_entity_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedEntity,
  });

  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction
            module={ModuleKey.DATA_DICTIONARY}
            action={DataDictionaryAction.DELETE_ENTITY}
          >
            <DeleteAction
              onClick={() => deleteEntityConfirmationDialog.open()}
            />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteEntityConfirmationDialog.render()}
    </>
  );
};
