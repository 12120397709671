import { FC, useState, useContext } from "react";
import { RiverDataGrid } from "../shared";
import { IAdapterShift } from "@river/interfaces";
import { ShiftsHeader } from "./shifts-header";
import { ShiftsSubHeader } from "./shifts-subheader";
import { ShiftsGridHeader } from "./shifts-grid-header";
import { FormatterProps } from "react-data-grid";
import {
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
} from "../../context";
import { RiverSpinner } from "@river/common-ui";
import { ShiftDialog } from "./shift-dialog";
import { ShiftsUiService } from "../../services";
import { usePageCleanup } from "../../hooks";

export enum ShiftsAction {
  CREATE = "CREATE_SHIFT",
  UPDATE = "UPDATE_SHIFT",
  DELETE = "DELETE_SHIFT",
}

export const Shifts: FC = () => {
  usePageCleanup();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const uiService: ShiftsUiService =
    adapterContext?.service.getShiftsUiService()!;
  const [selectedShift, setSelectedShift] = useState<IAdapterShift | null>(
    null
  );
  const [shiftDialogOpened, setShiftDialogOpened] = useState<boolean>(false);

  const onShiftClick = (formatterProps: FormatterProps<any>): void => {
    table.setSelectedRowIds(new Set());
    setSelectedShift(formatterProps.row as IAdapterShift);
    setShiftDialogOpened(true);
  };

  const { entity, table } = uiService.useShifts({
    onShiftClick,
  })();

  return (
    <TableContext.Provider value={{ table, entity }}>
      <ShiftsHeader />
      <ShiftsSubHeader />
      <ShiftsGridHeader />
      <RiverSpinner show={table.isLoading} />
      <RiverDataGrid
        disableSelectAll={true}
        singleSelect={true}
        columns={table.columns}
        rows={table.entities}
        rowKeyGetter={table.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={table.sortColumns}
      />
      <ShiftDialog
        open={shiftDialogOpened}
        shift={selectedShift}
        onClose={(success) => {
          setShiftDialogOpened(false);
          if (success) {
            table.refresh();
          }
        }}
      />
    </TableContext.Provider>
  );
};
