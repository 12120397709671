import { RiverFormInstance, useRiverForm } from "../../../../hooks";
import {
  ISystemCronTaskWithEnv,
  SystemEnvCronTaskDto,
} from "@river/interfaces";
import { AdapterUiContext } from "../../../../context";
import { useContext } from "react";
import { useTranslation } from "@river/common-ui";

export const useSystemCronTasksEditOptionsDialogForm = (props: {
  cron: ISystemCronTaskWithEnv | null;
  onUpdate: () => void;
}): RiverFormInstance => {
  const { cron, onUpdate } = props;
  const { t } = useTranslation();
  const adapterContext = useContext(AdapterUiContext);

  return useRiverForm<SystemEnvCronTaskDto, ISystemCronTaskWithEnv, Object>({
    initialDto: Object.assign(new SystemEnvCronTaskDto(), {
      frequency: "* * * * *",
      enabled: true,
      description: "",
      options: [],
    }),
    entityName: "system_cron_tasks",
    instanceToEdit: cron,
    onUpdate,
    update: async (dto: SystemEnvCronTaskDto) => {
      await adapterContext!.service
        .getAdapterService()
        .updateSystemCronTask(cron?.task_code!, dto);
    },
    labels: {
      cron: t("module.cron:cron.edit"),
    },
  });
};
