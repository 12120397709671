import { FC } from "react";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import styles from "./supervisor-crew-sub-header.module.scss";
import { useTranslation } from "@river/common-ui";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import clsx from "clsx";

interface ISupervisorCrewSubHeaderProps {
  crew: string;
}

export const SupervisorCrewSubHeader: FC<ISupervisorCrewSubHeaderProps> = (
  props
) => {
  const { crew } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderBreadcrumb = () => (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={styles.breadcrumbs}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <span
        onClick={() => navigate("/supervisor/crews")}
        className={styles.link}
      >
        {t("component.sidebar:menu_item.supervisor_crews")}
      </span>
      <span className={styles.bold}>{crew}</span>
    </Breadcrumbs>
  );

  return (
    <div className={clsx([headerStyles.commonSubheader, styles.root])}>
      <div className={styles.content}>
        <div className={styles.leftSection}>{renderBreadcrumb()}</div>
      </div>
    </div>
  );
};
