import { AdapterService } from "./adapter.service";
import { ITableFilter, ITablePreferences } from "../interfaces";

export enum UserPreferences {
  BACKLOG_SCHEDULES = "backlog.schedules",
  UTILIZATION_CRAFTS = "utilization.crafts",
  UTILIZATION_PEOPLE = "utilization.people",
  UTILIZATION_CREWS = "utilization.crews",
  SUPERVISOR_UTILIZATION_CRAFTS = "supervisor.utilization.crafts",
  SUPERVISOR_UTILIZATION_PEOPLE = "supervisor.people",
  SUPERVISOR_UTILIZATION_CREWS = "supervisor.crews",
  SIDEBAR_COLLAPSED = "sidebar.collapsed",
  SHOW_SCHEDULE_STATUS_CHANGE_CONFIRMATION = "schedule.show_status_change_confirmation",
  USER_PROPERTIES = "user.properties",
  VALIDATION_DASHBOARD_SCHEDULES = "validation.dashboard.schedules",
  RECENT_RULE_COLORS = "recent.rule.colors",
  EXECUTION_SCHEDULE = "execution.schedule",
  SCHEDULE_PROGRESS_FILTERS = "schedule.progress.filters",
  SCHEDULE_COMPLIANCE_FILTERS = "schedule.compliance.filters",
  SCHEDULE_HORIZONTAL_SPLITTER_POSITION = "schedule.horizontal.splitter.position",
  SCHEDULE_VERTICAL_SPLITTER_POSITION = "schedule.vertical.splitter.position",
  SCHEDULE_TASKS_EXPANDED = "schedule.tasks.expanded",
  AVAILABILITY_VERTICAL_SPLITTER_POSITION = "availability.vertical.splitter.position",
  REPORT_SCHEDULE = "report.schedule",
}

// --------------
export const set = async (props: {
  adapter: AdapterService;
  key: string;
  value: any;
  isGlobal?: boolean;
}): Promise<void> => {
  const { adapter, key, value, isGlobal } = props;
  if (isGlobal) {
    await adapter.saveGlobalPreference(key, value);
  } else {
    await adapter.saveUserPreference(key, value);
  }
};

export const get = async (props: {
  adapter: AdapterService;
  key: string;
  isGlobal?: boolean;
}): Promise<any> => {
  const { adapter, key, isGlobal } = props;
  if (isGlobal) {
    return await adapter.getGlobalPreference(key);
  } else {
    return await adapter.getUserPreference(key);
  }
};

// -------------
export const setBacklogSchedules = async (
  adapter: AdapterService,
  scheduleIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.BACKLOG_SCHEDULES,
    value: scheduleIds,
  });

// -------------
export const getBacklogSchedules = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.BACKLOG_SCHEDULES });

// -------------
export const setUtilizationCrafts = async (
  adapter: AdapterService,
  workCenterIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.UTILIZATION_CRAFTS,
    value: workCenterIds,
  });

// -------------
export const getUtilizationCrafts = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.UTILIZATION_CRAFTS });

// -------------
export const setUtilizationPeople = async (
  adapter: AdapterService,
  resourceIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.UTILIZATION_PEOPLE,
    value: resourceIds,
  });

// -------------
export const getUtilizationPeople = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.UTILIZATION_PEOPLE });

// -------------
export const setUtilizationCrews = async (
  adapter: AdapterService,
  crewIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.UTILIZATION_CREWS,
    value: crewIds,
  });

// -------------
export const getUtilizationCrews = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.UTILIZATION_CREWS });

// -------------
export const setSupervisorUtilizationCrafts = async (
  adapter: AdapterService,
  workCenterIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SUPERVISOR_UTILIZATION_CRAFTS,
    value: workCenterIds,
  });

export const getSupervisorUtilizationCrafts = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.SUPERVISOR_UTILIZATION_CRAFTS });

// -------------
export const setSupervisorUtilizationPeople = async (
  adapter: AdapterService,
  resourceIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SUPERVISOR_UTILIZATION_PEOPLE,
    value: resourceIds,
  });

export const getSupervisorUtilizationPeople = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.SUPERVISOR_UTILIZATION_PEOPLE });

// -------------
export const setSupervisorUtilizationCrews = async (
  adapter: AdapterService,
  crewIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SUPERVISOR_UTILIZATION_CREWS,
    value: crewIds,
  });

export const getSupervisorUtilizationCrews = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.SUPERVISOR_UTILIZATION_CREWS });

// -------------
export const setSidebarCollapsedState = async (
  adapter: AdapterService,
  collapsed: boolean
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SIDEBAR_COLLAPSED,
    value: collapsed,
  });

// -------------
export const getSidebarCollapsedState = async (
  adapter: AdapterService
): Promise<boolean> => get({ adapter, key: UserPreferences.SIDEBAR_COLLAPSED });

// -------------
export const getShowScheduleStatusChangeConfirmation = async (
  adapter: AdapterService
): Promise<boolean> =>
  get({
    adapter,
    key: UserPreferences.SHOW_SCHEDULE_STATUS_CHANGE_CONFIRMATION,
  });

export const setShowScheduleStatusChangeConfirmation = async (
  adapter: AdapterService,
  value: boolean
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SHOW_SCHEDULE_STATUS_CHANGE_CONFIRMATION,
    value,
  });

// -------------
export const setUserProperties = async (
  adapter: AdapterService,
  properties: object
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.USER_PROPERTIES,
    value: properties,
  });

// -------------
export const getUserProperties = async (
  adapter: AdapterService
): Promise<object> => get({ adapter, key: UserPreferences.USER_PROPERTIES });

// -------------
export const setValidationDashboardSchedules = async (
  adapter: AdapterService,
  scheduleIds: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.VALIDATION_DASHBOARD_SCHEDULES,
    value: scheduleIds,
  });

// -------------
export const getValidationDashboardSchedules = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.VALIDATION_DASHBOARD_SCHEDULES });

// -------------
export const setRecentRuleColors = async (
  adapter: AdapterService,
  colors: string[]
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.RECENT_RULE_COLORS,
    value: colors,
  });

// -------------
export const getRecentRuleColors = async (
  adapter: AdapterService
): Promise<string[]> =>
  get({ adapter, key: UserPreferences.RECENT_RULE_COLORS });

// -------------
export const setExecutionSchedule = async (
  adapter: AdapterService,
  scheduleId: string
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.EXECUTION_SCHEDULE,
    value: scheduleId,
  });

// -------------
export const getExecutionSchedule = async (
  adapter: AdapterService
): Promise<string> => get({ adapter, key: UserPreferences.EXECUTION_SCHEDULE });

// -------------
export const setScheduleProgressFilters = async (
  adapter: AdapterService,
  value: any
): Promise<void> =>
  set({
    adapter,
    key: UserPreferences.SCHEDULE_PROGRESS_FILTERS,
    value,
  });

// -------------
export const getScheduleProgressFilters = async (
  adapter: AdapterService
): Promise<any> =>
  get({ adapter, key: UserPreferences.SCHEDULE_PROGRESS_FILTERS });

// -------------
export const setScheduleComplianceFilters = async (
  adapter: AdapterService,
  value: any
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.SCHEDULE_COMPLIANCE_FILTERS,
    value,
  });

// -------------
export const getScheduleComplianceFilters = async (
  adapter: AdapterService
): Promise<any> =>
  get({ adapter, key: UserPreferences.SCHEDULE_COMPLIANCE_FILTERS });
// -------------
export const setTablePreferences = async (props: {
  adapter: AdapterService;
  tableSaveKey: string;
  tablePreferences: ITablePreferences;
  isGlobal?: boolean;
}): Promise<void> => {
  const { adapter, tableSaveKey, tablePreferences, isGlobal } = props;
  const filters: ITableFilter[] = tablePreferences.filters ?? [];
  const preferences: object = Object.assign({}, tablePreferences, {
    filters: filters.map((filter) => ({
      ...filter,
      // need to stringify queries due to $and/$or dollar sign issue with MongoDB
      // https://www.mongodb.com/docs/v4.0/core/document/#field-names
      query: JSON.stringify(filter.query),
    })),
  });
  await set({
    adapter,
    key: tableSaveKey,
    value: preferences,
    isGlobal,
  });
};

// -------------
export const getTablePreferences = async (props: {
  adapter: AdapterService;
  tableSaveKey: string;
  isGlobal?: boolean;
}): Promise<any> => {
  const { adapter, tableSaveKey, isGlobal } = props;
  const processTablePreferences = (preferences: any): any => {
    if (preferences) {
      const filters: { name: string; query: string }[] =
        preferences["filters"] ?? [];
      preferences["filters"] = filters.map((filter) => {
        let query: any = JSON.parse(filter.query);
        return {
          ...filter,
          // queries are stringified due to $and/$or dollar sign issue with MongoDB
          // https://www.mongodb.com/docs/v4.0/core/document/#field-names
          query,
        };
      });
    }
    return preferences;
  };

  let preferences: any = await get({ adapter, key: tableSaveKey, isGlobal });
  if (preferences) {
    preferences = processTablePreferences({ ...preferences });
  }
  return preferences;
};

// -------------
export const getHorizontalScheduleSplitterPosition = async (
  adapter: AdapterService
): Promise<any> =>
  get({ adapter, key: UserPreferences.SCHEDULE_HORIZONTAL_SPLITTER_POSITION });

// -------------
export const setHorizontalScheduleSplitterPosition = async (
  adapter: AdapterService,
  value: number
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.SCHEDULE_HORIZONTAL_SPLITTER_POSITION,
    value,
  });

// -------------
export const getVerticalScheduleSplitterPosition = async (
  adapter: AdapterService
): Promise<any> =>
  get({ adapter, key: UserPreferences.SCHEDULE_VERTICAL_SPLITTER_POSITION });

// -------------
export const setVerticalScheduleSplitterPosition = async (
  adapter: AdapterService,
  value: number
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.SCHEDULE_VERTICAL_SPLITTER_POSITION,
    value,
  });

// -------------
export const getVerticalAvailabilitySplitterPosition = async (
  adapter: AdapterService
): Promise<any> =>
  get({
    adapter,
    key: UserPreferences.AVAILABILITY_VERTICAL_SPLITTER_POSITION,
  });

// -------------
export const setVerticalAvailabilitySplitterPosition = async (
  adapter: AdapterService,
  value: number
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.AVAILABILITY_VERTICAL_SPLITTER_POSITION,
    value,
  });

// -------------
export const getScheduleTaskExpanded = async (
  adapter: AdapterService
): Promise<any> =>
  get({ adapter, key: UserPreferences.SCHEDULE_TASKS_EXPANDED });

// -------------
export const setScheduleTaskExpanded = async (
  adapter: AdapterService,
  value: boolean
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.SCHEDULE_TASKS_EXPANDED,
    value,
  });

// -------------
export const getReportScheduleId = async (
  adapter: AdapterService
): Promise<any> => get({ adapter, key: UserPreferences.REPORT_SCHEDULE });

// -------------
export const setReportScheduleId = async (
  adapter: AdapterService,
  value: string
): Promise<any> =>
  set({
    adapter,
    key: UserPreferences.REPORT_SCHEDULE,
    value,
  });
