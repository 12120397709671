import React, { ReactElement, useContext, useState } from "react";
import { RiverTextInput, useNotification } from "@river/common-ui";
import { IAttribute } from "@river/interfaces";
import { EditorProps, FormatterProps } from "react-data-grid";
import { AdapterUiContext, TableContext } from "../../../../../context";
import { useTableCellRenderers } from "../../../../../hooks";
import styles from "./river-default-number-editor.module.scss";

interface IRiverDefaultNumberEditorProps {
  editorProps: EditorProps<any>;
}

interface IEditorProps {
  editorProps: EditorProps<any>;
  attribute: IAttribute;
}

const SAVE_KEYBOARD_KEYS = ["Enter", "NumpadEnter"];

const Editor: React.FC<IEditorProps> = (props): ReactElement => {
  const adapterContext = useContext(AdapterUiContext);
  const tableContext = useContext(TableContext);
  const table = tableContext?.table;
  const notify = useNotification();

  const [inputValue, setInputValue] = useState<number>(
    props.editorProps?.row[props.attribute?.attribute_name!] || 0
  );

  const updateInputValue = async (): Promise<any> => {
    try {
      table?.forceLoadingState(true);
      const id = props.editorProps?.row._id;
      await adapterContext!.service
        .getAdapterService()
        .updateEntityData(props.attribute?.entity_name || "", id, {
          [props.attribute?.attribute_name!]: inputValue,
        });
      table?.refresh();
    } catch (message) {
      notify.error({ message });
    } finally {
      table?.forceLoadingState(false);
    }
  };
  return (
    <div className={styles.root}>
      <RiverTextInput
        id={"river_cell_number"}
        value={inputValue}
        onChangeEvent={(event) => {
          setInputValue(Number(event.target.value));
        }}
        inputProps={{
          type: "number",
          onBlur: updateInputValue,
          onKeyDown: async (event) => {
            if (SAVE_KEYBOARD_KEYS.indexOf(event.code) !== -1) {
              await updateInputValue();
            }
          },
          className: styles.input,
        }}
      />
    </div>
  );
};

export const RiverDefaultNumberEditor: React.FC<
  IRiverDefaultNumberEditorProps
> = (props) => {
  const { editorProps } = props;
  const { column } = editorProps;
  const { renderCell } = useTableCellRenderers();

  return (
    <>
      {renderCell({
        formatterProps: editorProps as unknown as FormatterProps<any>,
        content: (
          <TableContext.Consumer>
            {(tableContext) => {
              const attribute = tableContext?.entity.attributesByName.get(
                column.key
              );

              return (
                <Editor editorProps={editorProps} attribute={attribute!} />
              );
            }}
          </TableContext.Consumer>
        ),
      })}
    </>
  );
};
