import React, { ReactElement } from "react";
import {
  RelationRenderer,
  IRelationRendererProps,
} from "../../supervisor-schedule-operations-gantt-overlay";
import { IUseFinishToFinish, useFinishToFinish } from "./use-finish-to-finish";
import {
  IRelationConnectorEvents,
  LineArrow,
  renderHorizontalLine,
  renderVerticalLine,
} from "../../render-utils";
import genericStyles from "../../supervisor-schedule-operations-gantt-overlay.module.scss";
import clsx from "clsx";

export const FinishToFinishRelationRenderer: RelationRenderer = (
  props: IRelationRendererProps
): ReactElement => {
  const finishToFinish: IUseFinishToFinish = useFinishToFinish(props);
  const {
    predecessorFinish,
    vertical,
    successorFinish,
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
    selected,
  } = finishToFinish;

  const connectorsEventHandlers: IRelationConnectorEvents = {
    onConnectorMouseOver,
    onConnectorMouseOut,
    onConnectorClick,
  };

  const renderPredecessorStartConnector = (): ReactElement => (
    <>
      {predecessorFinish &&
        renderHorizontalLine({
          ...predecessorFinish,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderVerticalConnector = (): ReactElement => (
    <>
      {vertical &&
        renderVerticalLine({
          ...vertical,
          ...connectorsEventHandlers,
        })}
    </>
  );

  const renderSuccessorStartConnector = (): ReactElement => (
    <>
      {successorFinish &&
        renderHorizontalLine({
          ...successorFinish,
          ...connectorsEventHandlers,
          arrow: LineArrow.LEFT,
        })}
    </>
  );

  return (
    <div
      className={clsx([
        genericStyles.relation,
        {
          [genericStyles.selected]: selected,
        },
      ])}
    >
      {renderPredecessorStartConnector()}
      {renderVerticalConnector()}
      {renderSuccessorStartConnector()}
    </div>
  );
};
