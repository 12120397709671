import React from "react";

import {
  RiverDialog,
  RiverDialogActionButton,
  RiverDialogButton,
  RiverFormSelect,
  RiverTextInput,
} from "@river/common-ui";
import { IRelation } from "@river/interfaces";
import { RiverFormInstance } from "../../../hooks";
import { useEntityRelationForm } from "./use-entity-relation-form";
import styles from "./entity-relation-dialog.module.scss";
import { useTranslation } from "@river/common-ui";

export interface IEntityRelationDialogProps {
  open: boolean;
  entityName: string;
  onClose: (success: boolean) => void;
  relation?: IRelation | null;
}

export const EntityRelationDialog: React.FC<IEntityRelationDialogProps> = (
  props
) => {
  const { t } = useTranslation();

  const form: RiverFormInstance = useEntityRelationForm({
    entityName: props.entityName,
    relation: props.relation!,
    onCreate: () => {
      const success = true;
      closeDialog(success);
    },
    onUpdate: () => {
      const success = true;
      closeDialog(success);
    },
  });

  const { resetForm, submit } = form;

  const closeDialog = (success: boolean): void => {
    resetForm();
    props.onClose(success);
  };

  const dialogTitle = !form.isReadOnly
    ? props.relation
      ? t("module.data_dictionary:dialog.edit_relation.title")
      : t("module.data_dictionary:dialog.create_relation.title")
    : t("module.data_dictionary:dialog.relation_details.title");
  const actionButtonText = props.relation
    ? t("common.button:save")
    : t("common.button:create");

  return (
    <RiverDialog
      title={dialogTitle}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      onSubmit={submit}
      classes={{
        paper: styles.paper,
        content: styles.content,
      }}
      actionsContent={
        <>
          <RiverDialogButton
            onClick={() => {
              const success = false;
              closeDialog(success);
            }}
            text={t("common.button:close")}
          />
          {!form.isReadOnly && (
            <RiverDialogActionButton onClick={submit} text={actionButtonText} />
          )}
        </>
      }
      showActionsDivider={false}
      dialogProps={{
        maxWidth: false,
      }}
      form={form}
    >
      <>
        <RiverTextInput id={"relation_name"} />
        <RiverTextInput id={"child_entity_name"} />
        <RiverFormSelect
          id={"relation_type"}
          items={[
            {
              text: t("module.data_dictionary:label.one_to_one"),
              value: "one-to-one",
            },
            {
              text: t("module.data_dictionary:label.one_to_many"),
              value: "one-to-many",
            },
          ]}
        />
        <RiverTextInput
          id="lookup"
          inputProps={{
            multiline: true,
            minRows: 4,
            maxRows: 12,
          }}
        />
      </>
    </RiverDialog>
  );
};
