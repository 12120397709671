import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface IOracleEbsWorkOrderMassUpdate {
  priorityName?: string;
  PRIORITY?: number | null;
  USER_DEFINED_STATUS_ID?: number | null;
  userStatusName?: number | null;
  SHUTDOWN_TYPE?: number | null;
  shutdownTypeName?: string;
  WORK_ORDER_TYPE?: number | null;
  workOrderTypeName?: string;
  plannerMaintenanceName?: string;
  PLANNER_MAINTENANCE?: number | null;
  TAGOUT_REQUIRED?: TagoutRequiredSelection;
  FIRM_PLANNED_FLAG?: FirmPlannedFlagSelection;
}

export enum TagoutRequiredSelection {
  Y = "Y",
  N = "N",
  NO_UPDATE = "NO_UPDATE",
}

export enum FirmPlannedFlagSelection {
  ONE = "1",
  TWO = "2",
  NO_UPDATE = "NO_UPDATE",
}

export class OracleCloudWorkOrderMassUpdate {
  constructor(obj: IOracleEbsWorkOrderMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useOracleEbsWoMassUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, OracleCloudWorkOrderMassUpdate>({
    standalone: {
      fields: new OracleCloudWorkOrderMassUpdate({
        priorityName: "",
        workOrderTypeName: "",
        shutdownTypeName: "",
        plannerMaintenanceName: "",
        TAGOUT_REQUIRED: TagoutRequiredSelection.NO_UPDATE,
        FIRM_PLANNED_FLAG: FirmPlannedFlagSelection.NO_UPDATE,
      }),
      fieldDefs: [
        { fieldName: "priorityName", dataType: "string" },
        { fieldName: "PRIORITY", dataType: "number" },
        { fieldName: "USER_DEFINED_STATUS_ID", dataType: "number" },
        { fieldName: "userStatusName", dataType: "number" },
        { fieldName: "workOrderTypeName", dataType: "string" },
        { fieldName: "WORK_ORDER_TYPE", dataType: "number" },
        { fieldName: "shutdownTypeName", dataType: "string" },
        { fieldName: "SHUTDOWN_TYPE", dataType: "number" },
        { fieldName: "plannerMaintenanceName", dataType: "string" },
        { fieldName: "PLANNER_MAINTENANCE", dataType: "number" },
      ],
    },
    submit: props.submit,
    labels: {
      TAGOUT_REQUIRED: t("shared.wo_mass_update_dialog:label.tagout_required"),
      FIRM_PLANNED_FLAG: t(
        "shared.wo_mass_update_dialog:label.firm_planned_flag"
      ),
      priorityName: t("entity.workorder:workorder.PRIORITY_DISP"),
      userStatusName: t("entity.workorder:workorder.WORK_ORDER_STATUS"),
      workOrderTypeName: t("entity.workorder:workorder.WORK_ORDER_TYPE_DISP"),
      shutdownTypeName: t("entity.workorder:workorder.SHUTDOWN_TYPE_DISP"),
      plannerMaintenanceName: t(
        "entity.workorder:workorder.PLANNER_MAINTENANCE_MEANING"
      ),
    },
  });
};
