import React, { useContext } from "react";
import { PageHeader } from "../../page-header";
import headerStyles from "../../shared/common-header/common-header.module.scss";
import { TableContext } from "../../../context";
import { useTranslation } from "@river/common-ui";

interface SchedulesHeaderProps {}

export const SchedulesHeader: React.FC<SchedulesHeaderProps> = (props) => {
  const { t } = useTranslation();
  const tableContext = useContext(TableContext);

  return (
    <PageHeader pageTitle={t("module.schedules:module.title")}>
      {!tableContext?.table.isLoading && (
        <span className={headerStyles.numberOfEntries}>
          {`(${tableContext?.table.entities?.length})`}
        </span>
      )}
    </PageHeader>
  );
};
