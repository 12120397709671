import React, { useContext } from "react";
import { IAdapterRole } from "@river/interfaces";
import {
  GridHeader,
  DeleteAction,
  GridActions,
  FilterDropdown,
} from "../../shared";
import { useNotification, useSimpleDialog } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
  TableContext,
} from "../../../context";
import { useTranslation } from "@river/common-ui";
import ProtectedAction from "../../protected-action";
import { ModuleKey } from "../../sidebar-menu";
import { RolesAction } from "../roles";

interface IRolesGridHeader {
  setEditRoleDialogOpened(open: boolean): void;
  roleToEdit: IAdapterRole | null;
}

export const RolesGridHeader: React.FC<IRolesGridHeader> = (props) => {
  const { t } = useTranslation();

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const notify = useNotification();
  const tableContext = useContext(TableContext);

  const deleteSelectedRole = async (): Promise<any> => {
    if (props.roleToEdit) {
      try {
        await adapterContext!.service
          .getAdapterService()
          .deleteRole(props.roleToEdit._id);
        tableContext?.table.fetch();
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  const deleteRoleConfirmationDialog = useSimpleDialog({
    title: t("module.roles:message.delete_role.title"),
    message: t("module.roles:message.confirm_role_deletion"),
    confirmButtonText: t("common.button:delete"),
    onConfirm: deleteSelectedRole,
  });
  return (
    <>
      <GridHeader>
        <GridActions>
          <FilterDropdown />
          <ProtectedAction module={ModuleKey.ROLES} action={RolesAction.DELETE}>
            <DeleteAction onClick={() => deleteRoleConfirmationDialog.open()} />
          </ProtectedAction>
        </GridActions>
      </GridHeader>
      {deleteRoleConfirmationDialog.render()}
    </>
  );
};
