import { useRiverForm, RiverFormInstance } from "../../../../hooks";
import { useTranslation } from "@river/common-ui";

export interface IOracleCloudWorkOrderMassUpdate {
  WorkOrderStatusCode?: string;
  WorkOrderTypeCode?: string;
  WorkOrderSubTypeCode?: string;
  WorkOrderPriority?: string;
}

export class OracleCloudWorkOrderMassUpdate {
  constructor(obj: IOracleCloudWorkOrderMassUpdate) {
    Object.assign(this, obj);
  }
}

export const useOracleCloudWoMassUpdateForm = (props: {
  submit: () => Promise<void>;
}): RiverFormInstance => {
  const { t } = useTranslation();
  return useRiverForm<Object, Object, OracleCloudWorkOrderMassUpdate>({
    standalone: {
      fields: new OracleCloudWorkOrderMassUpdate({
        WorkOrderStatusCode: "",
        WorkOrderTypeCode: "",
        WorkOrderSubTypeCode: "",
        WorkOrderPriority: "",
      }),
      fieldDefs: [
        { fieldName: "WorkOrderStatusCode", dataType: "string" },
        { fieldName: "WorkOrderTypeCode", dataType: "string" },
        { fieldName: "WorkOrderSubTypeCode", dataType: "string" },
        { fieldName: "WorkOrderPriority", dataType: "string" },
      ],
    },
    submit: props.submit,
    labels: {
      WorkOrderStatusCode: t("entity.workorder:workorder.WorkOrderStatusCode"),
      WorkOrderTypeCode: t("entity.workorder:workorder.WorkOrderTypeCode"),
      WorkOrderSubTypeCode: t(
        "entity.workorder:workorder.WorkOrderSubTypeCode"
      ),
      WorkOrderPriority: t("entity.workorder:workorder.WorkOrderPriority"),
    },
  });
};
