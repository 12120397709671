import { ILookupDefinition } from "../lookup-dialog";
import {
  ITableFetchFunctionProps,
  useRiverSelectColumn,
} from "../../../river-data-grid";
import { fetchHelpers } from "../../../../../helpers";
import {
  IAdapterUserContext,
  AdapterUserContextProp,
  IAdapterUiContextState,
  IUserContextSite,
} from "../../../../../context";
import { useTranslation } from "@river/common-ui";
import { IEntityObject } from "@river/interfaces";
import { Constants } from "@river/constants";

export function useSupervisorsLookupDef() {
  const { t } = useTranslation();
  const { RiverSelectColumn } = useRiverSelectColumn();
  return (
    userContext: IAdapterUserContext,
    adapterContext: IAdapterUiContextState
  ): ILookupDefinition => {
    const site: IUserContextSite =
      userContext?.userProperties[AdapterUserContextProp.SITE];

    return {
      title: t("entity.crew:crew.supervisors"),
      rowKey: "display_name",
      useTableProps: {
        columns: [
          RiverSelectColumn,
          {
            key: "display_name",
            name: t(
              "entity.crew_supervisor_ref:crew_supervisor_ref.display_name"
            ),
          },
          {
            key: "roles",
            name: t("module.crews:label.roles"),
          },
        ],
        fetchFunction: async (fetchProps: ITableFetchFunctionProps) => {
          const roles = await adapterContext!.service
            .getAdapterService()
            .fetchRoles({
              ...fetchHelpers.getTableQuery({
                fetchProps: { columnFilters: [], sortColumns: [] },
                initialQueryAttributes: [
                  {
                    attribute_name: "license",
                    attribute_value: {
                      operator: "$eq",
                      value: Constants.license_type.supervisor,
                    },
                  },
                ],
              }),
            });
          const supervisors: string[] = roles.map(
            (supervisor) => supervisor.role
          );
          const result = await adapterContext!.service
            .getAdapterService()
            .fetchUsers({
              ...fetchHelpers.getTableQuery({
                fetchProps,
                initialQueryAttributes: [
                  {
                    attribute_name: "roles",
                    attribute_value: {
                      operator: "$in",
                      value: supervisors,
                    },
                  },
                ],
              }),
            });
          return result as unknown as IEntityObject[];
        },
        dependencies: [!!site],
        useAdvancedFilters: false,
      },
      useEntityProps: {
        entityName: "user_role",
      },
    };
  };
}

export type IUseSupervisorsLookupDef = ReturnType<
  typeof useSupervisorsLookupDef
>;
