export const Constants = {
  auth: {
    access_jwt_strategy_name: "access",
    refresh_jwt_strategy_name: "refresh",
    api_key_strategy_name: "api_key",
    saml2_strategy_name: "saml2",
    oauth2_strategy_name: "oauth2",
  },

  adapter_type: {
    sap: "sap",
    oracle_cloud: "oracle-cloud",
    oracle_ebs: "oracle-ebs",
    jde: "jde",
    maximo: "maximo",
  },

  authentication_type: {
    basic: "basic",
    oauth2: "oauth2",
    saml2: "saml2",
  },

  usage_action: {
    login: "login",
    attachment_hierarchy: "attachment_hierarchy",
    attachment_content: "attachment_content",
    generate_work_package: "generate_work_package",
    generate_report: "generate_report",
    create_entity_def: "create_entity_def",
    update_entity_def: "update_entity_def",
    delete_entity_def: "delete_entity_def",
    create_attribute_def: "create_attribute_def",
    update_attribute_def: "update_attribute_def",
    delete_attribute_def: "delete_attribute_def",
    create_relation_def: "create_relation_def",
    update_relation_def: "update_relation_def",
    delete_relation_def: "delete_relation_def",
    create_index_def: "create_index_def",
    update_index_def: "update_index_def",
    delete_index_def: "delete_index_def",
    create: "create",
    update: "update",
    delete: "delete",
    add_language: "add_language",
    language_status: "language_status",
    delete_language: "delete_language",
    create_translation: "create_translation",
    schedule: "schedule",
    auto_schedule: "auto_schedule",
    remove_from_schedule: "remove_from_schedule",
    assign: "assign",
    unassign: "unassign",
    commit_schedule: "commit_schedule",
    create_dependency: "create_dependency",
    update_dependency: "update_dependency",
    delete_dependency: "delete_dependency",
    auto_dependencies: "auto_dependencies",
    update_user_status: "update_user_status",
    material_shortage: "material_shortage",
    sync_resources: "sync_resources",
    generate_availability: "generate_availability",
    job_priority_score: "job_priority_score",
    job_validation_action: "job_validation_action",
    generate_timeline_schedules: "generate_timeline_schedules",
    set_completion_percent: "set_completion_percent",
    set_breakin: "set_breakin",
    //sync: "sync",
    help_link: "help_link",
  },

  license_type: {
    admin: "ADMIN",
    scheduler: "SCHEDULER",
    supervisor: "SUPERVISOR",
    craftsman: "CRAFTSMAN",
  },

  // adapter: {
  //   sap: {
  //     module: {
  //       config: 'CONFIG',
  //       scheduling: 'SCHEDULING'
  //     }
  //   },
  //   maximo: {
  //     module: {
  //       config: 'CONFIG',
  //       scheduling: 'SCHEDULING'
  //     }
  //   }
  // },

  request_headers: {
    //customer_id: "x-river-customer-id",
    //customer_environment: "x-river-customer-env",
    api_key: "x-river-api-key",
    river_erp_header: "x-river-erp-header",
  },

  cookies: {
    river_erp_cookie: "x-river-erp-cookie",
    river_refresh_token: "x-river-refresh-token",
  },

  platform_db_connection_name: "$RIVER_PLATFORM_CONNECTION",

  // platform_module: {
  //   customers: 'P_CUSTOMERS',
  //   security: 'P_SECURITY',
  // },

  // platform_db_model_name: {
  //   token_storage: 'TOKEN_STORAGE',
  //   cluster: 'CLUSTER',
  //   customer: 'CUSTOMER',
  //   environment: 'ENVIRONMENT',
  //   env_options: 'ENV_OPTIONS',
  //   user: 'USER',
  //   user_pwd: 'USER_PWD',
  //   entity: 'ENTITY',
  //   attribute: 'ATTRIBUTE',
  //   relation: 'REALTION',
  //   index: 'INDEX',
  //   role: 'ROLE'
  // },

  customer_status: {
    active: "active",
    inactive: "inactive",
  },

  cron_task_status: {
    idle: "idle",
    running: "running",
  },

  cron_task_run_status: {
    success: "success",
    error: "error",
  },

  /** Types of cron tasks. */
  cron_task_type: {
    job_priority_score: "job_priority_score",

    material_shortage: "material_shortage",

    mobile_transactions: "mobile_transactions",

    schedule_status_processing: "schedule_status_processing",

    purge_usage_actions: "purge_usage_actions",
    validation_rules: "validation_rules",

    /** Custom runs custom user-defined Javascript. */
    custom: "custom",
  },

  schedule_statuses: {
    draft: "draft",
    committed: "committed",
    in_progress: "in_progress",
    complete: "complete",
    closed: "closed",
  },

  module_status: {
    licensed: "licensed",
    requested: "requested",
    none: "none",
  },

  token_type: {
    refresh: "refresh",
  },

  token_owner_type: {
    user: "user",
  },

  // role_type: {
  //   platform: 'platform',
  //   customer: 'customer'
  // },

  // user_type: {
  //   platform: 'platform',
  //   customer: 'customer'
  // },
  owner_type: {
    platform: "platform",
    customer: "customer",
  },

  work_package_delivery_method: {
    download: "download",
    email: "email",
  },

  work_package_delivery_status: {
    pending: "pending",
    complete: "complete",
  },

  work_package_status: {
    pending: "pending",
    merging: "merging",
    complete: "complete",
    error: "error",
  },

  work_package_file_status: {
    download_pending: "download_pending",
    download_complete: "download_complete",
    conversion_pending: "conversion_pending",
    conversion_complete: "conversion_complete",
    attached: "attached", //could not convert to PDF, insert the original content to the resulting PDF as attachment
    error: "error",
  },

  work_package_file_content_type: {
    text: "text",
    binary: "binary",
    url: "url",
  },

  report_status: {
    pending: "pending",
    generating: "generating",
    complete: "complete",
    error: "error",
  },

  report_type: {
    excel_basic: "excel_basic",
    excel_schedule_table_gantt: "excel_schedule_table_gantt",
    excel_weekly_schedule_categorized: "excel_weekly_schedule_categorized",
    excel_weekly_schedule_resource: "excel_weekly_schedule_resource",
  },

  // folder_type: {
  //   schedule: 'schedule',
  //   backlog: 'backlog'
  // },

  folder_type: {
    schedule: "schedule", // day-to-day maintenance
    project: "project",
  },

  folder_status: {
    draft: "draft",
    committed: "committed",
    closed: "closed",
  },

  data_type: {
    object_id: "object_id",
    string: "string",
    double: "double",
    // lower: 'lower',
    // upper: 'upper',
    boolean: "boolean",
    date: "date",
    sap_date: "sap_date",
    sap_time: "sap_time",
    datetime: "datetime",
    entity: "entity",
    any: "any",
  },

  /** Types of inputs for table rows fields. */
  input_type: {
    none: "none",

    /** Text is just simple plain text entry. */
    text: "text",

    /** Number is for entering integers and doubles. */
    number: "number",

    /** Dropdown from a list of options. Only one is selected at a time. */
    dropdown: "dropdown",

    /** Multi-select from a list of options. Zero or more options can be selected at a time. */
    multi_select: "multi_select",

    /** Label from a list of options. Zero of more options can be selected. Options can have custom color defined. */
    value_list: "value_list",

    /** A simple boolean toggle. */
    checkbox: "checkbox",
  },

  attribute_cardinality: {
    one: "one",
    many: "many",
  },

  relation_type: {
    one_to_one: "one-to-one",
    one_to_many: "one-to-many",
  },

  index_type: {
    regular: "regular",
    wildcard: "wildcard",
  },

  index_sort_type: {
    ascending: "ascending", // 1
    descending: "descending", // -1
    include: "include", // 1, wildcard index
    exclude: "exclude", // 0, wildcard index
  },

  rule_type: {
    validation_rule: "validation_rule",
    job_priority_rule: "job_priority_rule",
    gantt_color_rule: "gantt_color_rule",
    table_row_color_rule: "table_row_color_rule",
    table_cell_color_rule: "table_cell_color_rule",
    attachment_selection_rule: "attachment_selection_rule",
    //languages: "languages",
  },

  lookup_type: {
    availability_exception_reason: "availability_exception_reason",
  },

  // platform_permissions: {
  //   VIEW_PLATFORM_USERS             : 'VIEW_PLATFORM_USERS',
  //   CREATE_PLATFORM_USERS           : 'CREATE_PLATFORM_USERS',
  //   UPDATE_PLATFORM_USERS           : 'UPDATE_PLATFORM_USERS',
  //   DELETE_PLATFORM_USERS           : 'DELETE_PLATFORM_USERS',

  //   VIEW_CUSTOMERS                  : 'VIEW_CUSTOMERS',
  //   CREATE_CUSTOMER                 : 'CREATE_CUSTOMER',
  //   UPDATE_CUSTOMER                 : 'UPDATE_CUSTOMER',
  //   DELETE_CUSTOMER                 : 'DELETE_CUSTOMER',

  //   VIEW_CUSTOMER_ROLES             : 'VIEW_CUSTOMER_ROLES',
  //   CREATE_CUSTOMER_ROLES           : 'CREATE_CUSTOMER_ROLES',
  //   UPDATE_CUSTOMER_ROLES           : 'UPDATE_CUSTOMER_ROLES',
  //   DELETE_CUSTOMER_ROLES           : 'DELETE_CUSTOMER_ROLES',

  //   VIEW_CUSTOMER_USERS             : 'VIEW_CUSTOMER_USERS',
  //   CREATE_CUSTOMER_USERS           : 'CREATE_CUSTOMER_USERS',
  //   UPDATE_CUSTOMER_USERS           : 'UPDATE_CUSTOMER_USERS',
  //   DELETE_CUSTOMER_USERS           : 'DELETE_CUSTOMER_USERS',

  //   VIEW_CUSTOMER_ENVIRONMENTS      : 'VIEW_CUSTOMER_ENVIRONMENTS',
  //   CREATE_CUSTOMER_ENVIRONMENTS    : 'CREATE_CUSTOMER_ENVIRONMENTS',
  //   UPDATE_CUSTOMER_ENVIRONMENTS    : 'UPDATE_CUSTOMER_ENVIRONMENTS',
  //   DELETE_CUSTOMER_ENVIRONMENTS    : 'DELETE_CUSTOMER_ENVIRONMENTS',
  // }

  mobile_transaction_status: {
    pending: "pending",
    resubmit: "resubmit",
    success: "success",
    error: "error",
  },

  mobile_transaction_transaction_type: {
    workorder: "workorder",
    timecard: "timecard",
  },

  async_action_status: {
    pending: "pending",
    running: "running",
    success: "success",
    error: "error",
    cancelled: "cancelled",
  },

  async_action_progress_type: {
    none: "none", // no progress reported, just wait
    percentage: "percentage", // progress 58%
    processed_records: "processed_records", // processed x records so far
    processed_of_total_records: "processed_of_total", // processed x of y records
  },
};
