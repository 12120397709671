import { useTranslation } from "@river/common-ui";
import {
  SupervisorScheduleGanttTimeOptionID,
  ISupervisorScheduleGanttTimeOption,
  SupervisorScheduleGanttTimeOptionGroupID,
  ISupervisorScheduleGanttTimeOptionGroup,
} from "./types";

export const useSupervisorScheduleGanttTime = () => {
  const { t } = useTranslation();

  const ganttTimeOptions: {
    [id in SupervisorScheduleGanttTimeOptionID]: ISupervisorScheduleGanttTimeOption;
  } = {
    [SupervisorScheduleGanttTimeOptionID._24HOURS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.HOURS,
      id: SupervisorScheduleGanttTimeOptionID._24HOURS,
      value: 24,
      label: t("shared.gantt:label._24hours"),
    },
    [SupervisorScheduleGanttTimeOptionID._48HOURS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.HOURS,
      id: SupervisorScheduleGanttTimeOptionID._48HOURS,
      value: 48,
      label: t("shared.gantt:label._48hours"),
    },
    [SupervisorScheduleGanttTimeOptionID._72HOURS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.HOURS,
      id: SupervisorScheduleGanttTimeOptionID._72HOURS,
      value: 72,
      label: t("shared.gantt:label._72hours"),
    },
    [SupervisorScheduleGanttTimeOptionID._7DAYS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.DAYS,
      id: SupervisorScheduleGanttTimeOptionID._7DAYS,
      value: 7,
      label: t("shared.gantt:label._7days"),
    },
    [SupervisorScheduleGanttTimeOptionID._14DAYS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.DAYS,
      id: SupervisorScheduleGanttTimeOptionID._14DAYS,
      value: 14,
      label: t("shared.gantt:label._14days"),
    },
    [SupervisorScheduleGanttTimeOptionID._4WEEKS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.WEEKS,
      id: SupervisorScheduleGanttTimeOptionID._4WEEKS,
      value: 4,
      label: t("shared.gantt:label._4weeks"),
    },
    [SupervisorScheduleGanttTimeOptionID._8WEEKS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.WEEKS,
      id: SupervisorScheduleGanttTimeOptionID._8WEEKS,
      value: 8,
      label: t("shared.gantt:label._8weeks"),
    },
    [SupervisorScheduleGanttTimeOptionID._3MONTHS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.MONTHS,
      id: SupervisorScheduleGanttTimeOptionID._3MONTHS,
      value: 3,
      label: t("shared.gantt:label._3months"),
    },
    [SupervisorScheduleGanttTimeOptionID._6MONTHS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.MONTHS,
      id: SupervisorScheduleGanttTimeOptionID._6MONTHS,
      value: 6,
      label: t("shared.gantt:label._6months"),
    },
    [SupervisorScheduleGanttTimeOptionID._12MONTHS]: {
      groupId: SupervisorScheduleGanttTimeOptionGroupID.MONTHS,
      id: SupervisorScheduleGanttTimeOptionID._12MONTHS,
      value: 12,
      label: t("shared.gantt:label._12months"),
    },
  };

  const ganttTimeOptionGroups: {
    [id in SupervisorScheduleGanttTimeOptionGroupID]: ISupervisorScheduleGanttTimeOptionGroup;
  } = {
    [SupervisorScheduleGanttTimeOptionGroupID.HOURS]: {
      id: SupervisorScheduleGanttTimeOptionGroupID.HOURS,
      label: t("shared.gantt:label.hours"),
      options: [
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._24HOURS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._48HOURS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._72HOURS],
      ],
    },
    [SupervisorScheduleGanttTimeOptionGroupID.DAYS]: {
      id: SupervisorScheduleGanttTimeOptionGroupID.DAYS,
      label: t("shared.gantt:label.days"),
      options: [
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._7DAYS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._14DAYS],
      ],
    },
    [SupervisorScheduleGanttTimeOptionGroupID.WEEKS]: {
      id: SupervisorScheduleGanttTimeOptionGroupID.WEEKS,
      label: t("shared.gantt:label.weeks"),
      options: [
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._4WEEKS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._8WEEKS],
      ],
    },
    [SupervisorScheduleGanttTimeOptionGroupID.MONTHS]: {
      id: SupervisorScheduleGanttTimeOptionGroupID.MONTHS,
      label: t("shared.gantt:label.months"),
      options: [
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._3MONTHS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._6MONTHS],
        ganttTimeOptions[SupervisorScheduleGanttTimeOptionID._12MONTHS],
      ],
    },
  };
  return {
    ganttTimeOptions,
    ganttTimeOptionGroups,
  };
};

export type IUseGanttTime = ReturnType<typeof useSupervisorScheduleGanttTime>;
