import React, { useContext } from "react";
import { IUseSupervisorScheduleGantt } from "../../supervisor-schedule-gantt/use-supervisor-schedule-gantt";
import { SupervisorScheduleContext } from "../../../../context";
import { SupervisorUtilizationGanttHeaderPeriod } from "./supervisor-utilization-gantt-header-period";
import { useSupervisorGanttRenderChecks } from "../../supervisor-schedule-gantt";
import { SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID } from "../supervisor-schedule-utilization-tables";
import styles from "./supervisor-schedule-utilization-header-renderer.module.scss";
import clsx from "clsx";

export const SupervisorScheduleUtilizationHeaderRenderer: React.FC = () => {
  const scheduleContext = useContext(SupervisorScheduleContext);
  const gantt: IUseSupervisorScheduleGantt =
    scheduleContext?.utilizationGanttRef.current!;
  const { periods } = gantt;
  const { shouldRender } = useSupervisorGanttRenderChecks({
    gantt,
    containerId: SUPERVISOR_SCHEDULE_UTILIZATION_GANTT_CONTAINER_ID,
  });

  return (
    <>
      {shouldRender && (
        <div className={clsx([styles.root])}>
          {periods.map((period) => (
            <SupervisorUtilizationGanttHeaderPeriod
              period={period}
              key={period.index}
            />
          ))}
        </div>
      )}
    </>
  );
};
