import { format } from "date-fns";
import { IEntityObject, IKpiConfig } from "@river/interfaces";
import { IAvailabilityUtilizationResult } from "../components/backlog/availability-helpers";
import { ChartTypeCode, IChart } from "../context";
import {
  BarData,
  BarDataDataset,
  PieData,
  PieDataDataset,
} from "../interfaces/chart.interface";
import { KPI_LABEL_COLOR } from "../services/kpi.service";

export namespace statsHelper {
  interface IColor {
    r: number;
    g: number;
    b: number;
  }

  interface IParams {
    seed: string;
    rows: IEntityObject[];
    labelField: string;
    valueField?: string;
  }

  export function createPieChartData(params: IParams): PieData {
    const countMap = createCountMap(params);

    let labels: string[] = [];
    let dataset: PieDataDataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
    };

    const random = createRandomizer(params.seed);

    for (const mapKey in countMap) {
      labels.push(mapKey ? mapKey : "None");

      dataset.data.push(countMap[mapKey]);

      const backgroundColor = getRandomColor(random);
      const borderColor = addColors(backgroundColor, {
        r: -20,
        g: -20,
        b: -20,
      });

      (dataset.backgroundColor as string[]).push(
        colorToHaxString(backgroundColor)
      );
      (dataset.borderColor as string[]).push(colorToHaxString(borderColor));
    }

    let data: PieData = {
      labels: labels,
      datasets: [dataset],
    };
    return data;
  }

  export function createBarChartData(
    params: IParams & { label: string }
  ): BarData {
    const countMap = createCountMap(params);

    let labels: string[] = [];
    let dataset: BarDataDataset = {
      data: [],
      backgroundColor: [],
      borderColor: [],
      borderWidth: 1,
      label: params.label,
    };

    const random = createRandomizer(params.seed);

    for (const mapKey in countMap) {
      labels.push(mapKey ? mapKey : "None");

      dataset.data.push(countMap[mapKey]);

      const backgroundColor = getRandomColor(random);
      const borderColor = addColors(backgroundColor, {
        r: -20,
        g: -20,
        b: -20,
      });

      (dataset.backgroundColor as string[]).push(
        colorToHaxString(backgroundColor)
      );
      (dataset.borderColor as string[]).push(colorToHaxString(borderColor));
    }

    let data: BarData = {
      labels: labels,
      datasets: [dataset],
    };
    return data;
  }

  export function createAvailabilityUtilizationData(params: {
    seed: string;
    availabilityUtilization: IAvailabilityUtilizationResult;
  }): BarData {
    const random = createRandomizer(params.seed);

    let labels: string[] = [];
    let availabilityDataset: BarDataDataset = {
      label: "Availability",
      data: [],
      backgroundColor: colorToHaxString(getRandomColor(random)),
    };
    let utilizationDataset: BarDataDataset = {
      label: "Utilization",
      data: [],
      backgroundColor: colorToHaxString(getRandomColor(random)),
    };

    for (const record of params.availabilityUtilization.records) {
      labels.push(record.name);
      availabilityDataset.data.push(record.availability);
      utilizationDataset.data.push(record.utilization);
    }

    let barData: BarData = {
      labels: labels,
      datasets: [availabilityDataset, utilizationDataset],
    };

    return barData;
  }

  export function createKPIFromKPIData(
    params: { name: string; colors: string[] },
    kpiConfig: IKpiConfig
  ): IChart {
    const chart: IChart = {
      title: params.name,
      chart: {
        data: kpiConfig.data,
        type: kpiConfig.type as ChartTypeCode,
        options: {
          plugins: {
            legend: {
              labels: {
                color: KPI_LABEL_COLOR,
              },
            },
          },
          scales: {
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                color: KPI_LABEL_COLOR,
              } as any,
            },
            x: {
              stacked: true,
              ticks: {
                color: KPI_LABEL_COLOR,
              },
            },
          },
        },
      },
    };

    const labels = chart.chart.data.labels ?? [];
    for (var i = 0; i < (labels?.length ?? 0); i++) {
      const date = new Date(Date.parse(labels[i]));
      labels[i] = format(date, "yyyy-MM-dd");
    }

    let index = 0;
    for (const dataset of chart.chart.data.datasets) {
      // const color = colorToHaxString(getRandomColor(random));
      dataset.backgroundColor = [params.colors[index]];

      index++;
    }
    // chart.chart.data.datasets[0].col

    return chart;
  }

  function createCountMap(params: IParams) {
    const countMap: { [index: string]: number } = {};

    for (const row of params.rows) {
      const label = row[params.labelField] as string;
      const value = params.valueField ? (row[params.valueField] as number) : 1;
      const valueNumber = parseFloat(String(value)) ?? value;

      if (countMap[label]) {
        countMap[label] = countMap[label] + valueNumber;
      } else {
        countMap[label] = valueNumber;
      }
    }

    return countMap;
  }

  function colorToHaxString(color: IColor) {
    const { r, g, b } = color;

    return "rgb(" + r + "," + g + "," + b + ")";
  }

  function addColors(left: IColor, right: IColor) {
    return {
      r: left.r + right.r,
      g: left.g + right.g,
      b: left.b + right.b,
    };
  }

  function getRandomColor(random: () => number) {
    var r = getRandomInt(random, 50, 220);
    var g = getRandomInt(random, 50, 220);
    var b = getRandomInt(random, 50, 220);

    return { r, g, b };
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  function getRandomInt(random: () => number, min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(random() * (max - min + 1)) + min;
  }

  function xmur3(str: string) {
    for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
      h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
      h = (h << 13) | (h >>> 19);
    }
    return function () {
      h = Math.imul(h ^ (h >>> 16), 2246822507);
      h = Math.imul(h ^ (h >>> 13), 3266489909);
      return (h ^= h >>> 16) >>> 0;
    };
  }

  function sfc32(a: number, b: number, c: number, d: number) {
    return function () {
      a >>>= 0;
      b >>>= 0;
      c >>>= 0;
      d >>>= 0;
      var t = (a + b) | 0;
      a = b ^ (b >>> 9);
      b = (c + (c << 3)) | 0;
      c = (c << 21) | (c >>> 11);
      d = (d + 1) | 0;
      t = (t + d) | 0;
      c = (c + t) | 0;
      return (t >>> 0) / 4294967296;
    };
  }

  function createRandomizer(seedValue: string) {
    const seed = xmur3(seedValue);
    const rand = sfc32(seed(), seed(), seed(), seed());

    return rand;
  }
}
