import {
  FC,
  ReactElement,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { ExecutionHeader } from "./execution-header";
import { ExecutionSubHeader } from "./execution-subheader";
import { ExecutionTabHeader } from "./execution-tab-header";
import { ExecutionGridHeader } from "./execution-grid-header";
import { WoPane } from "./wo-pane";
import {
  IAdapterUserContext,
  AdapterUserContext,
  AdapterUserContextProp,
  ExecutionContext,
  TabContext,
  TableContext,
  IAdapterUiContextState,
  AdapterUiContext,
  IUserContextSite,
  ITableContext,
  IExecutionContext,
  ITabContext,
} from "../../context";
import {
  RiverSpinner,
  useEnv,
  IUseEnv,
  useNotification,
  useSimpleDialog,
  UseSimpleDialog,
} from "@river/common-ui";
import {
  AdapterTimeCardDto,
  IAdapterFolder,
  IEntityObject,
  QueryAttributeGroupDto,
} from "@river/interfaces";
import { useNavigate, useLocation } from "react-router";
import { helpers, useEntityHelpers, uiConstants } from "../../helpers";
import {
  ExecutionTabId,
  ExecutionTabURLParamName,
  ExecutionUiService,
  IExecutionActions,
  IFetchExecutionOperationsProps,
  TimeCardUiService,
  userPreferencesService,
} from "../../services";
import {
  CompletionPercentageDialogEntityNameType,
  ITableFetchFunctionProps,
  RiverDataGrid,
  useCompletionPercentageDialogAction,
  useUserStatusAction,
} from "../shared";
import { useTranslation } from "@river/common-ui";
import { ExecutionDetails } from "./execution-details";
import { ExecutionCardView } from "./execution-card-view";
import { usePageCleanup } from "../../hooks";
import styles from "./execution.module.scss";
import clsx from "clsx";
import { ModuleKey } from "../sidebar-menu";

export enum ExecutionActions {
  // General actions
  WO_COMPLETION_PCT = "WO_COMPLETION_PCT",
  OP_COMPLETION_PCT = "OP_COMPLETION_PCT",
  WO_EDIT = "WO_EDIT",

  // SAP-specific
  WO_USER_STATUS = "WO_USER_STATUS",
  OP_USER_STATUS = "OP_USER_STATUS",

  // EBS-specific
  WO_COMPLETION = "WO_COMPLETION",
  WO_UNCOMPLETE = "WO_UNCOMPLETE",
}

export interface IExecutionAction {
  label: string;
  onClick: (obj: IEntityObject) => void;
  isHidden?: (operation: IEntityObject) => boolean;
  isDisabled?: (operation: IEntityObject) => boolean;
  module?: ModuleKey;
  action?: string;
}
export type OngoingExecutions = Map<string, Date>;

const OPERATION_EXECUTIONS_LOCAL_STORAGE_KEY = "operationExecutions";
export const loadOperationExecutions = (): OngoingExecutions => {
  const executions: OngoingExecutions = new Map();
  const obj = JSON.parse(
    localStorage.getItem(OPERATION_EXECUTIONS_LOCAL_STORAGE_KEY) || "{}"
  );
  Object.keys(obj).forEach((operationId) => {
    executions.set(operationId, new Date(obj[operationId]));
  });
  return executions;
};
export const saveOperationExecutions = (executions: OngoingExecutions): void =>
  localStorage.setItem(
    OPERATION_EXECUTIONS_LOCAL_STORAGE_KEY,
    JSON.stringify(Object.fromEntries(executions))
  );

export const Execution: FC = (): ReactElement => {
  usePageCleanup();

  const env: IUseEnv = useEnv();
  const { isMobile } = env;
  const ongoingExecutionsRef = useRef<OngoingExecutions>(new Map());
  const [executionStopInitiated, setExecutionStopInitiated] =
    useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [currentSchedule, setCurrentSchedule] = useState<IAdapterFolder | null>(
    null
  );
  const [currentActions, setCurrentActions] = useState<IExecutionAction[] | []>(
    []
  );
  const userContext: IAdapterUserContext | null =
    useContext(AdapterUserContext);
  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);
  const timecardUiService: TimeCardUiService =
    adapterContext?.service!.getTimeCardUiService()!;
  const uiService: ExecutionUiService =
    adapterContext?.service!.getExecutionUiService()!;
  const site: IUserContextSite =
    userContext?.userProperties[AdapterUserContextProp.SITE];
  const isInitialScheduleLoad = useRef<boolean>(false);
  const [currentWorkOrder, setCurrentWorkOrder] =
    useState<IEntityObject | null>(null);
  const currentWorkOrderRef = useRef<IEntityObject | null>(null);
  const [currentOperation, setCurrentOperation] =
    useState<IEntityObject | null>(null);
  const [timecardDialogOpened, setTimecardDialogOpened] =
    useState<boolean>(false);
  const [timecardInitialValues, setTimecardInitialValues] =
    useState<Partial<AdapterTimeCardDto> | null>(null);
  const notify = useNotification();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const currentTab = uiService.getCurrentTab()();
  adapterContext?.service.getTaskHelpersUiService()!;
  const operationCompletionDialog: UseSimpleDialog = useSimpleDialog();
  const entityHelpers = useEntityHelpers();

  const getCurrentEntityName: () => CompletionPercentageDialogEntityNameType =
    () => {
      switch (currentTab) {
        case ExecutionTabId.DETAILS:
          return "workorder";
        case ExecutionTabId.OPERATIONS:
          return "operation";
        default:
          return "workorder";
      }
    };

  const { completionPercentageDialogAction } =
    useCompletionPercentageDialogAction({
      enableOn: true,
    });

  const entityIds: string[] = currentWorkOrderRef?.current?._id
    ? [currentWorkOrderRef.current._id.toString()]
    : [];
  const { userStatusAction } = useUserStatusAction({
    entity_name: getCurrentEntityName(),
    entity_ids: entityIds,
    enableOn: true,
  });

  const { t } = useTranslation();

  const fetchExecutionOperations: (
    props: IFetchExecutionOperationsProps
  ) => Promise<any> = uiService.getExecutionFetchOperations()();

  useEffect(() => {
    if (currentSchedule && !isInitialScheduleLoad.current) {
      userPreferencesService.setExecutionSchedule(
        adapterContext!.service.getAdapterService(),
        currentSchedule._id
      );
    }
    isInitialScheduleLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSchedule]);

  const init = async (): Promise<void> => {
    let preferredScheduleId: string | null = null;
    try {
      preferredScheduleId = await userPreferencesService.getExecutionSchedule(
        adapterContext!.service.getAdapterService()
      );
    } catch (message) {
      notify.error({ message });
    }

    if (preferredScheduleId) {
      try {
        const preferredSchedule = await adapterContext!.service
          .getAdapterService()
          .getFolderById(preferredScheduleId);
        isInitialScheduleLoad.current = true;
        setCurrentSchedule(preferredSchedule);
      } catch (message) {
        notify.error({ message });
      }
    }
  };

  useEffect(() => {
    init().then(() => {
      ongoingExecutionsRef.current = loadOperationExecutions();
      setInitialized(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInitialQuery = (): QueryAttributeGroupDto => {
    if (!currentSchedule) {
      return {};
    } else {
      return uiService.getInitialQuery({
        currentSchedule: currentSchedule,
        currentWorkOrderRef: currentWorkOrderRef.current,
      });
    }
  };

  const fetchOperations = async (
    fetchProps: ITableFetchFunctionProps
  ): Promise<IEntityObject[]> => {
    if (!currentWorkOrderRef.current) return [];
    const operations = await fetchExecutionOperations({
      initialQuery: getInitialQuery(),
      fetchProps,
    });
    operations.forEach((op: IEntityObject) => {
      entityHelpers.setAttributeValue(
        op,
        uiConstants.fields.rowId,
        entityHelpers.getAttributeValue(op, "to_Operation._id")
      );
    });
    return operations;
  };

  const createExecution = (workOrder: IEntityObject) => {
    if (ongoingExecutionsRef.current.size) {
      operationCompletionDialog.open({
        title: t("module.execution:message.stop_executions_title"),
        message: t("module.execution:message.stop_execution_message", {
          ongoing_tasks: ongoingExecutionsRef.current.size,
        }),
        declineButtonText: t("common.button:no"),
        confirmButtonText: t("common.button:yes"),
        onDecline: () => {
          doCreateExecution(workOrder);
        },
        onConfirm: async () => {
          const firstOngoingOperationId: string = ongoingExecutionsRef.current
            .keys()
            .next().value;
          const res: IEntityObject[] = await fetchOperations({
            columnFilters: [],
            sortColumns: [],
            ids: [currentWorkOrderRef.current!._id as string],
          });
          if (res.length) {
            const workOrder: IEntityObject = res.find(
              (wo) =>
                (wo.to_Operation as IEntityObject)._id ===
                firstOngoingOperationId
            )!;
            stopExecution(workOrder);
          }
        },
      });
    } else {
      doCreateExecution(workOrder);
    }
  };

  const doCreateExecution = (operation: IEntityObject) => {
    ongoingExecutionsRef.current.set(operation._id as string, new Date());
    saveOperationExecutions(ongoingExecutionsRef.current);
    notify.success(t("module.execution:notification.success_start_execution"));
  };

  const stopExecution = (operation: IEntityObject) => {
    setCurrentOperation(operation);
    setExecutionStopInitiated(true);
    const executionStartDate: Date = ongoingExecutionsRef.current.get(
      operation._id as string
    )!;
    let currentDate = new Date();
    const diff = currentDate.valueOf() - executionStartDate.valueOf();
    const calculatedHours = Math.floor(diff / (1000 * 60 * 60));
    const timecardHours = calculatedHours === 0 ? 1 : calculatedHours;

    setTimecardInitialValues({
      timecard_hours: timecardHours,
      timecard_date: executionStartDate,
    });
    setTimecardDialogOpened(true);
  };

  const deleteExecution = (operation: IEntityObject) => {
    ongoingExecutionsRef.current.delete(operation._id as string);
    saveOperationExecutions(ongoingExecutionsRef.current);
  };

  const isExecutionOngoing = (operation: IEntityObject): boolean =>
    ongoingExecutionsRef.current.has(operation._id as string);

  let onRowClick: (row: any, col: any) => void;

  const executionActions: IExecutionActions = {
    setCurrentOperation,
    setTimecardDialogOpened,
    createExecution,
    deleteExecution,
    stopExecution,
    isExecutionOngoing,
  };

  const tabContext: ITabContext = {
    selectedTab: currentTab,
    setSelectedTab: (tabId: string) => {
      navigate(
        helpers.replaceOrSetUrlParam(
          location.pathname,
          params,
          ExecutionTabURLParamName,
          tabId
        )
      );
    },
  };

  const executionContext: IExecutionContext = {
    initialized,
    executionActions,
    currentSchedule,
    setCurrentSchedule,
    currentWorkOrder,
    setCurrentWorkOrder: (workOrder) => {
      currentWorkOrderRef.current = workOrder; // need this as a ref too, for column access
      setCurrentWorkOrder(workOrder);
    },
    currentActions,
    setCurrentActions,
    completionPercentageDialogAction,
    userStatusAction,
  };

  const { entity: currentEntity, table: currentTable } =
    uiService.useCurrentTable({ executionContext })();

  let currentEntityName: string | undefined =
    currentEntity?.entityDefinition?.entity.entity_name;

  const renderTable = (): ReactElement => {
    return (
      <RiverDataGrid
        columns={currentTable.columns}
        rows={currentTable.entities}
        rowKeyGetter={currentTable.rowKeyGetter}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        sortColumns={currentTable.sortColumns}
        onRowClick={onRowClick}
        className={styles.dataGrid}
      />
    );
  };

  const renderExecutionView = (): ReactElement => {
    const { currentActions } = executionContext;
    const { isMobile } = env;

    if (currentTab === ExecutionTabId.DETAILS) {
      return (
        <>
          <ExecutionGridHeader />
          <ExecutionDetails />
        </>
      );
    } else if (currentTab === ExecutionTabId.MATERIAL) {
      return uiService.renderExecutionMaterialRequirements();
    } else if (uiService.getIsTableView()) {
      return (
        <>
          <ExecutionGridHeader />
          {!isMobile && renderTable()}
          {isMobile && <ExecutionCardView actions={currentActions} />}
        </>
      );
    } else {
      return <></>;
    }
  };

  const tableContext: ITableContext = {
    entityName: currentEntityName,
    table: currentTable,
    entity: currentEntity!,
  };

  const renderTimecardDialog = (): ReactElement => {
    return timecardUiService.renderTimecardDialog(
      {
        folderId: currentSchedule?._id!,
        workorder: currentWorkOrder!,
        operation: currentOperation!,
        initialValues: timecardInitialValues,
        open: timecardDialogOpened,
        onClose: () => {
          setTimecardDialogOpened(false);
          setCurrentOperation(null);
          setExecutionStopInitiated(false);
          setTimecardInitialValues(null);
        },
        onCreate: () => {
          if (executionStopInitiated && currentOperation) {
            deleteExecution(currentOperation);
            notify.success(
              t(
                "module.execution:notification.timecard_created_execution_stopped"
              )
            );
          } else {
            notify.success(t("module.execution:notification.timecard_created"));
          }
        },
      },
      tabContext.selectedTab as ExecutionTabId
    )();
  };

  const isLoading: boolean = currentTable.isLoading || !site;
  return (
    <>
      <ExecutionContext.Provider value={executionContext}>
        <TableContext.Provider value={tableContext}>
          <ExecutionHeader />
          <div className={clsx([styles.body, { [styles.mobile]: isMobile }])}>
            <TabContext.Provider value={tabContext}>
              <WoPane />
              <div className={styles.content}>
                {isMobile && <ExecutionSubHeader />}
                <ExecutionTabHeader />
                <RiverSpinner show={isLoading} />
                {renderExecutionView()}
              </div>
            </TabContext.Provider>
          </div>
          {timecardDialogOpened && renderTimecardDialog()}
          {operationCompletionDialog.render()}
        </TableContext.Provider>
      </ExecutionContext.Provider>
    </>
  );
};
