import React, { useContext, useEffect, useCallback, ReactElement } from "react";
import { RiverFormInstance } from "../../../../../hooks";
import { useNotification } from "@river/common-ui";
import {
  AdapterUiContext,
  IAdapterUiContextState,
} from "../../../../../context";
import { LookupType, RiverLookup } from "../../../../../components/shared";
import { IOracleEbsWoCompletionStandaloneFields } from "../use-ebs-wo-completion-form";
import { debounce } from "ts-debounce";
import {
  OracleEbsAdapterWorkorderCompletionDto,
  QueryAttributeDto,
} from "@river/interfaces";

interface IUseFailureCodeLookupProps {
  form: RiverFormInstance;
  selectedFailureCode: any;
  setSelectedFailureCode: (failureCode: any) => void;
}

export function useFailureCodeLookup(props: IUseFailureCodeLookupProps) {
  const notify = useNotification();

  const { form, selectedFailureCode, setSelectedFailureCode } = props;
  const {
    onStandalonePropertyChange,
    validateStandaloneField,
    validationErrors,
  } = form;
  const currentDto: OracleEbsAdapterWorkorderCompletionDto =
    form.dto as OracleEbsAdapterWorkorderCompletionDto;

  const adapterContext: IAdapterUiContextState | null =
    useContext(AdapterUiContext);

  useEffect(() => {
    validateStandaloneField("failureCodeDisplay");

    const FAILURE_CODE = selectedFailureCode?.["FAILURE_CODE"] || "";
    const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
      new OracleEbsAdapterWorkorderCompletionDto(),
      { ...currentDto },
      { FAILURE_CODE }
    );
    form.setDto(newDto);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFailureCode]);

  useEffect(() => {
    validateStandaloneField("causeCodeDisplay");
    validateStandaloneField("resolutionCodeDisplay");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDto.FAILURE_CODE]);

  const getCustomFilters = (): QueryAttributeDto[] => [
    {
      attribute_name: "SET_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["FAILURE_SET_ID"] as string,
      },
    },
    {
      attribute_name: "INVENTORY_ITEM_ID",
      attribute_value: {
        operator: "$eq",
        //@ts-ignore
        value: currentDto["INVENTORY_ITEM_ID"] as string,
      },
    },
  ];

  const fetchFailureCode = async (text: string): Promise<void> => {
    try {
      const results: any[] = await adapterContext!.service
        .getAdapterService()
        .searchEntityData("failure_code", {
          query: {
            $and: [
              ...getCustomFilters(),
              {
                attribute_name: "FAILURE_CODE",
                attribute_value: {
                  operator: "$eq",
                  value: text,
                },
              },
            ],
          },
        });
      const failureCode: any = results[0] || null;
      setSelectedFailureCode(failureCode);
      validateStandaloneField("failureCodeDisplay", text);
    } catch (message) {
      notify.error({ message });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchFailureCode = useCallback(
    debounce((text) => {
      fetchFailureCode(text);
    }, 600),
    [validationErrors]
  );

  const render = (): ReactElement => (
    <RiverLookup
      id={"failureCodeDisplay"}
      fullWidth
      //@ts-ignore
      disabled={currentDto["FAILURE_CODE_READONLY"]}
      lookup={{
        type: LookupType.ORACLE_EBS_FAILURE_CODE,
        customFilters: getCustomFilters(),
      }}
      onChangeEvent={(event) => {
        onStandalonePropertyChange({ noValidate: true })(event);
        debounceFetchFailureCode(event.target.value);
      }}
      onSelect={(selectedFailureCode) => {
        const { FAILURE_CODE } = selectedFailureCode;
        const newDto: OracleEbsAdapterWorkorderCompletionDto = Object.assign(
          new OracleEbsAdapterWorkorderCompletionDto(),
          { ...currentDto },
          { FAILURE_CODE }
        );
        form.setDto(newDto);

        form.setStandaloneFields({
          ...(form.standalone as IOracleEbsWoCompletionStandaloneFields),
          failureCodeDisplay: FAILURE_CODE,
        });
        setSelectedFailureCode(selectedFailureCode);
      }}
    />
  );

  return {
    render,
  };
}

export type IUseFailureCodeLookup = ReturnType<typeof useFailureCodeLookup>;
