import React, { ReactElement } from "react";
import { LookupType } from "../../../../../components/shared";
import { ChartLookupFilter } from "../../../../../components/schedule/schedule-utilization/chart-filters";
import { useTranslation } from "@river/common-ui";
import { useTableCellRenderers } from "../../../../../hooks";
import { SupervisorProgressSummaryFilterID } from "../../../../supervisor-schedule-utilization-ui-service";

interface IOracleEbsSupervisorScheduleProgressResourceFiltersProps {
  rows: any[];
  onSelect: (
    selectedObjects: any[],
    filterId: SupervisorProgressSummaryFilterID
  ) => void;
  onDelete: (
    selectedObject: any,
    filterId: SupervisorProgressSummaryFilterID
  ) => void;
}

export const OracleEbsSupervisorScheduleProgressResourceFilters: React.FC<
  IOracleEbsSupervisorScheduleProgressResourceFiltersProps
> = (props): ReactElement => {
  const { t } = useTranslation();
  const { renderTextCell } = useTableCellRenderers();

  const resourceLookupCodes = Array.isArray(props.rows)
    ? props.rows.map((resource) => resource["RESOURCE_ID"])
    : [];

  return (
    <ChartLookupFilter
      rows={props.rows}
      columns={[
        {
          key: "RESOURCE_CODE",
          name: t(
            "entity.department_resource:department_resource.RESOURCE_CODE"
          ),
          formatter: (formatterProps) => renderTextCell({ formatterProps }),
          resizable: false,
          width: 100,
        },
      ]}
      lookup={{
        type: LookupType.CRAFTS,
        selectedRowIds: [],
        customFilters: [
          {
            attribute_name: "RESOURCE_ID",
            attribute_value: {
              operator: "$nin",
              value: resourceLookupCodes,
            },
          },
        ],
      }}
      onDelete={(selected) =>
        props.onDelete(selected, SupervisorProgressSummaryFilterID.RESOURCES)
      }
      onSelect={(selectedObjects) => {
        props.onSelect(
          selectedObjects,
          SupervisorProgressSummaryFilterID.RESOURCES
        );
      }}
      addFilterLabel={t(
        "module.supervisor_schedule:utilization_filters.add_resource_filter"
      )}
    />
  );
};
